import { StylesConfig } from 'react-select'

import { SelectValue } from '../BaseSelect'

const styles: StylesConfig<SelectValue, false> = {
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    display: 'inline',
    backgroundColor: 'white',
    border: 'none',
    boxShadow: 'none',
    caretColor: '#f97316',
    color: '',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    'outline:focus-visible': 'none',
    ...(state.isDisabled
      ? {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
        }
      : {}),
    '&:hover': {
      borderColor: '#af6693',
    },
  }),
  menuList: (defaultStyles) => ({
    ...defaultStyles,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#9ca3af',
      fontWeight: 400,
    }
  },
  clearIndicator: () => {
    return {
      display: 'none',
    }
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    }
  },
  indicatorsContainer: () => {
    return {
      display: 'none',
    }
  },
  dropdownIndicator: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: state.isFocused
        ? '#7a004b'
        : state.isDisabled
        ? '#999999'
        : '#000000',
      '&:hover': { color: state.isDisabled ? '#999999' : '#7a004b' },
    }
  },
  menu: (defaultStyles) => {
    return {
      ...defaultStyles,
      margin: 0,
      width: 'auto',
      boxShadow: `0 4px 6px -2px rgba(0, 0, 0, 0.05), 
        0 10px 15px -3px rgba(0, 0, 0, 0.1)`,
      ...{
        border: '1px solid #e4e4e7',
      },
    }
  },
  option: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      whiteSpace: 'nowrap',
      height: 40,
      paddingLeft: 0,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: !state.isFocused ? '' : '#f3f4f6',
      '&:hover': {
        backgroundColor: '#f3f4f6',
        cursor: 'pointer',
      },
    }
  },
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      height: '100%',
      color: '#1f2937',
      fontWeight: 500,
      padding: '0.5rem 0.75rem',
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  input: (defaultStyles) => {
    return {
      ...defaultStyles,
      margin: 0,
      padding: 0,
    }
  },
}

export default styles
