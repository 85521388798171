import { FC } from 'react'
import cx from 'classnames'

import { Edit } from 'common/icons'

interface Props {
  value: string
  onEditClick: () => void
  'data-cy'?: string
  className?: string
}

const EditableValue: FC<Props> = ({
  value,
  onEditClick,
  'data-cy': dataCy,
  className,
}) => {
  const doesIncludeSpaces = /\s/g.test(value)

  return (
    <>
      <div
        data-cy={dataCy}
        className={cx(
          `mr-6 text-2xl lg:text-3xl text-coolGray-800 font-medium`,
          { 'break-words': doesIncludeSpaces },
          { 'break-all': !doesIncludeSpaces },
          className
        )}
      >
        {value}
      </div>
      <button
        data-cy={`${dataCy}-edit`}
        aria-label="edit"
        onClick={onEditClick}
      >
        <Edit />
      </button>
    </>
  )
}

export default EditableValue
