import { ChangeEvent } from 'react'

import Input from 'common/components/Input'

type Props = {
  value: string
  onChange: (value: string) => void
  'data-cy': string
}

const SearchInput = ({ value, onChange, 'data-cy': dataCy }: Props) => {
  return (
    <Input
      type="search"
      value={value}
      placeholder="Search"
      data-cy={dataCy}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value)
      }
      clear={() => onChange('')}
      className={`h-10 w-full px-12 py-2 text-sm text-coolGray-400
        placeholder-sm placeholder-coolGray-800 placeholder-opacity-50
        border border-solid border-coolGray-300 bg-coolGray-50 focus:outline-none
        focus:placeholder-coolGray-50
        focus:border-coolGray-300 focus:bg-coolGray-50 focus:bg-opacity-10`}
    />
  )
}

export default SearchInput
