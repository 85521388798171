import { useCallback } from 'react'
import copy from 'copy-to-clipboard'
import { ResponseVariant } from 'workflow/Insights/Insights'
import { openTweakLine } from 'workflow/Workflow.actions'

import { successToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'

const useTableActions = () => {
  const dispatch = useAppDispatch()

  const onCopy = useCallback((text: string) => {
    copy(text)
    successToast('Variant copied successfully')
  }, [])

  const onRequestChange = useCallback(
    (variant: ResponseVariant) => {
      dispatch(openTweakLine(variant))
    },
    [dispatch]
  )

  return {
    onCopy,
    onRequestChange,
  }
}

export default useTableActions
