import { FC } from 'react'
import { useParams } from 'react-router'

import DetailsPanel from 'common/components/detailsPanel'

import ArchiveConfirmButton from './components/archiveConfirmButton'
import DeactivateConfirmButton from './components/deactivateConfirmButton'
import ReactivateConfirmButton from './components/reactivateConfirmButton'
import UnarchiveConfirmButton from './components/unarchiveConfirmButton'

type Props = {
  isArchived: boolean
  isActive: boolean
  isExpanded: boolean
  setIsExpanded: (val: boolean) => void
}

const ProjectDetailsPanel: FC<Props> = ({
  isArchived,
  isActive,
  isExpanded,
  setIsExpanded,
}) => {
  const { accountId, projectId } =
    useParams<{ accountId: string; projectId: string }>()

  return (
    <DetailsPanel isExpanded={isExpanded} setIsExpanded={setIsExpanded}>
      {isArchived ? (
        <UnarchiveConfirmButton
          isExpanded={isExpanded}
          accountId={accountId}
          projectId={projectId}
        />
      ) : (
        <ArchiveConfirmButton
          isExpanded={isExpanded}
          accountId={accountId}
          projectId={projectId}
        />
      )}
      {isActive ? (
        <DeactivateConfirmButton
          isExpanded={isExpanded}
          accountId={accountId}
          projectId={projectId}
        />
      ) : (
        <ReactivateConfirmButton
          isExpanded={isExpanded}
          accountId={accountId}
          projectId={projectId}
        />
      )}
    </DetailsPanel>
  )
}

export default ProjectDetailsPanel
