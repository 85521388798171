import { useQuery } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { getProjectConfig } from '../api'
import { projectKeys } from '../api/queryKeys'

const useProjectConfigQuery = ({
  accountId,
  projectId,
}: {
  accountId: string
  projectId: string
}) => {
  return useQuery(
    projectKeys.projectConfig({ accountId, projectId }),
    () =>
      getProjectConfig(accountId, projectId).then((res) => res.data.project),
    {
      refetchOnWindowFocus: false,
      onError: () => errorToast('Failed to load project config'),
    }
  )
}

export default useProjectConfigQuery
