import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useVariantInsights } from 'features/unifiedFlow/contentPage/generate/context/VariantInsightsContext'
import { hideRightPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

import RightPanel from '../../components/layout/RightPanel'

import Insights from './Insights'
import VariantInspector from './VariantInspector'
const RightSection = () => {
  const dispatch = useAppDispatch()
  const {
    rightPanelRef,
    state: { isVariantSelected },
  } = useVariantInsights()

  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )

  const {
    isLoading,
    isWaitingState: { isWaiting },
  } = useAppSelector((state) => state.campaignStates)

  const getState = () => {
    if (isVariantSelected) {
      return 'variantInspector'
    } else {
      return 'insights'
    }
  }

  const state = getState()

  return (
    <RightPanel
      scrollableParentId="main-content-page"
      onOutsideClick={() => dispatch(hideRightPanel())}
      visibility={rightPanelVisibility}
    >
      <div ref={rightPanelRef} className="h-full">
        {
          {
            variantInspector:
              isLoading || isWaiting ? <Spinner /> : <VariantInspector />,
            insights: <Insights />,
          }[state]
        }
      </div>
    </RightPanel>
  )
}

export default RightSection
