import cx from 'classnames'

import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'

type Props = {
  data: {
    genericVariant: string
    personalizedVariant: string
    attributes: { [key: string]: string }[]
  }[]
}

const DemoTable = ({ data }: Props) => {
  const columns: Column<{
    variantId: string
    genericVariant: string
    personalizedVariant: string
    attributes: { [key: string]: string }[]
  }>[] = [
    {
      Header: 'Generic variants',
      accessor: 'genericVariant',
      disableSortBy: true,
      isResizable: true,
      minWidth: 50,
      width: 80,
      Cell: ({ value }) => {
        return <BaseCell className="p-3 whitespace-pre-line">{value}</BaseCell>
      },
    },
    {
      Header: 'Personalized variants',
      accessor: 'personalizedVariant',
      disableSortBy: true,
      isResizable: true,
      minWidth: 50,
      width: 80,
      Cell: ({ value }) => {
        return (
          <BaseCell className="p-3 whitespace-pre-line bg-maroon-30 w-full group-hover:bg-maroon-50">
            {value}
          </BaseCell>
        )
      },
    },
    {
      Header: 'Attributes',
      accessor: 'attributes',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <BaseCell className="p-3 gap-2 flex-wrap leading-6">
            {value.map((attribute, index) => {
              const [key, value] = Object.entries(attribute)[0]
              return (
                <span key={index}>
                  <span className="text-maroon-300 capitalize">{key}</span>
                  <span className="px-1 ml-1 bg-maroon-50 text-maroon-400 font-semibold">
                    {value}
                  </span>
                </span>
              )
            })}
          </BaseCell>
        )
      },
    },
  ]

  return (
    <TableWidget.Widget
      className={cx({ 'pt-6': data.length === 0 })}
      columns={columns}
      data={data}
    />
  )
}
export default DemoTable
