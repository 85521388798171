import { useMutation, useQueryClient } from '@tanstack/react-query'

import { campaignKeys } from 'features/campaigns/api/queryKeys'

import { updateProjectAction } from '../api'
import { projectKeys } from '../api/queryKeys'

const useUpdateProjectActionMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(updateProjectAction, {
    onSuccess: (_, { accountId, projectId }) => {
      queryClient.invalidateQueries(projectKeys.projects(accountId))
      queryClient.invalidateQueries(
        projectKeys.projectConfig({ accountId, projectId })
      )
      queryClient.invalidateQueries(campaignKeys.projects())
    },
  })
}

export default useUpdateProjectActionMutation
