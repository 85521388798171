import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  parseISO,
} from 'date-fns'
import moment, { Moment } from 'moment'

export const formatDateToIsoString = (date: string | undefined) => {
  if (!date) {
    return
  }
  // check if date comes in ISO_8601 standard and reverse if not
  const isISOformat = Number(date.split('-')[0]) >= 1000
  const dateStringToUse = isISOformat
    ? date
    : date.split('-').reverse().join('-')
  return parseISO(dateStringToUse.toString()).toISOString()
}

export const formatDate = (date: string | undefined, dateFormat?: string) => {
  if (!date) {
    return
  }

  // check if date comes in ISO_8601 standard and reverse if not
  const isISOformat = Number(date.split('-')[0]) >= 1000
  const dateStringToUse = isISOformat
    ? date
    : date.split('-').reverse().join('-')
  return format(
    parseISO(dateStringToUse.toString()),
    dateFormat || 'd MMM yyyy'
  )
}

export const formatIsoDate = (
  isoDate: string | undefined
): string | undefined => {
  return isoDate && moment(isoDate).format('YYYY-MM-DD')
}

export const formatDateToAgo = (date: Date): string => {
  const now = new Date()
  const seconds = differenceInSeconds(now, date)
  const minutes = differenceInMinutes(now, date)
  const hours = differenceInHours(now, date)
  const days = differenceInDays(now, date)
  const weeks = differenceInWeeks(now, date)
  const months = differenceInMonths(now, date)
  const years = differenceInYears(now, date)

  if (seconds < 60) {
    return `${seconds} Sec ago`
  }
  if (minutes < 60) {
    return `${minutes} Min ago`
  }
  if (hours < 24) {
    return `Today ${format(date, 'h:mmaaa')}`
  }
  if (days < 2) {
    return `Yesterday`
  }
  if (months === 0 && weeks === 0) {
    return `${days} ${days > 1 ? 'days' : 'day'} ago`
  }
  if (weeks > 0 && weeks <= 4) {
    return `${weeks} ${weeks > 1 ? 'Weeks' : 'Week'} ago`
  }
  if (months < 12) {
    return `${months} ${months > 1 ? 'Months' : 'Month'} ago`
  }
  return `${years} ${months > 1 ? 'Years' : 'Year'} ago`
}

export function isYesterdayOrBefore(date: Moment) {
  return date.isBefore(moment().startOf('day'))
}

export function isSameDate(
  date1: string,
  date2: string,
  precision: 'day' | 'month' | 'year'
) {
  const date1Parsed = parseISO(date1)
  const date2Parsed = parseISO(date2)

  switch (precision) {
    case 'day':
      return isSameDay(date1Parsed, date2Parsed)
    case 'month':
      return isSameMonth(date1Parsed, date2Parsed)
    case 'year':
      return isSameYear(date1Parsed, date2Parsed)
  }
}
