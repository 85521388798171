import BaseCell from './Base'

type Props = {
  value?: number | string | null
}

const PercentageCell = ({ value }: Props) => {
  return value !== undefined ? (
    <BaseCell>
      <span>{value}%</span>
    </BaseCell>
  ) : null
}

export default PercentageCell
