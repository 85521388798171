import {
  HoursDropdownOptions,
  MinutesDropdownOptions,
} from './EpsilonIntegration.types'

export const hoursDropdownOptions: Array<HoursDropdownOptions> = [
  {
    value: 1,
    label: '01 hour',
  },
  {
    value: 2,
    label: '02 hours',
  },
  {
    value: 3,
    label: '03 hours',
  },
  {
    value: 4,
    label: '04 hours',
  },
  {
    value: 5,
    label: '05 hours',
  },
  {
    value: 6,
    label: '06 hours',
  },
  {
    value: 7,
    label: '07 hours',
  },
  {
    value: 8,
    label: '08 hours',
  },
  {
    value: 9,
    label: '09 hours',
  },
  {
    value: 10,
    label: '10 hours',
  },
  {
    value: 11,
    label: '11 hours',
  },
  {
    value: 12,
    label: '12 hours',
  },
]

export const minutesDropdownOptions: Array<MinutesDropdownOptions> = [
  {
    value: 0,
    label: '00 minutes',
  },
  {
    value: 15,
    label: '15 minutes',
  },
  {
    value: 30,
    label: '30 minutes',
  },
  {
    value: 45,
    label: '45 minutes',
  },
]
