import { useEffect, useState } from 'react'

import { fetchUsageReport } from 'common/api/reporting'
import * as GraphWidget from 'common/components/GraphWidget'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import { InfoAction } from 'common/components/WidgetHeader'
import { WidgetIncrementalError } from 'common/components/WidgetMessage'
import { useAppSelector } from 'common/hooks/redux'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import { selectEndDate, selectStartDate } from '../../../store/dashboardSlice'

import ActivityGauge from './ActivityGauge'

export type GraphData = {
  label: string
  value: number
}[]

interface Props {
  accountId: string
  distributionChannel: string[]
  projectIds: string[]
}

const NumberOfCampaignsWidget = ({
  accountId,
  distributionChannel,
  projectIds,
}: Props) => {
  const { chartRefCallback } = useChartRefCallback()

  const startDate = useAppSelector(selectStartDate)
  const endDate = useAppSelector(selectEndDate)
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined)
  const [graphData, setGraphData] = useState<GraphData | undefined>(undefined)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const selectedTimeIndex = 0

  useEffect(() => {
    function fetchData() {
      return fetchUsageReport({
        accountId,
        periodType: 'aggregate',
        projectIds,
        usageType: ['campaigns'],
        startDate,
        endDate,
        distributionChannel: distributionChannel.map((v) => v.toLowerCase()),
      })
    }

    setHasError(false)
    fetchData()
      .then(({ data: { data } }) => {
        const campaignsIndex = 0

        const campaigns = data.map((v) => v.values[campaignsIndex])
        const totalNumberOfCampaigns = campaigns.reduce(
          (accumulator, { value }) => value + accumulator,
          0
        )
        setTotalCount(totalNumberOfCampaigns)

        const campaignsPerDistributionChannel: {
          [key: string]: { label: string; value: number }
        } = data.reduce((accumulator, currenValue) => {
          return currenValue.values.reduce((acc, value) => {
            if (!value.distribution_channel) {
              return acc
            }
            const sumForDistributionChannel = accumulator[
              value.distribution_channel
            ]
              ? value.value + accumulator[value.distribution_channel]?.value
              : value.value
            return {
              [value.distribution_channel]: {
                label: value.label,
                value: sumForDistributionChannel,
              },
              ...acc,
            }
          }, {})
        }, {})
        const campaignsPerDistributionChannelInPercentage = Object.values(
          campaignsPerDistributionChannel
        ).map(({ label, value }) => ({
          label:
            label.toLowerCase() === 'landing page' ? 'Web & App' : `${label}`,
          value,
        }))

        campaignsPerDistributionChannelInPercentage.sort(
          (a, b) => b.value - a.value
        )
        const NB_DISTRIBUTION_CHANNEL_NOT_AGGREGATED = 5
        if (
          campaignsPerDistributionChannelInPercentage.length >
          NB_DISTRIBUTION_CHANNEL_NOT_AGGREGATED
        ) {
          const sumOtherValues =
            campaignsPerDistributionChannelInPercentage.reduce(
              (accumulator, currentValue, index) => {
                if (index + 1 > NB_DISTRIBUTION_CHANNEL_NOT_AGGREGATED) {
                  return accumulator + currentValue.value
                }
                return accumulator
              },
              0
            )
          const aggregatedData =
            campaignsPerDistributionChannelInPercentage.reduce<
              {
                label: string
                value: number
              }[]
            >((accumulator, currentValue, index) => {
              if (index + 1 > NB_DISTRIBUTION_CHANNEL_NOT_AGGREGATED) {
                if (index === NB_DISTRIBUTION_CHANNEL_NOT_AGGREGATED) {
                  return [
                    ...accumulator,
                    { label: 'Other', value: sumOtherValues },
                  ]
                } else {
                  return accumulator
                }
              }
              return [...accumulator, currentValue]
            }, [])

          setGraphData(aggregatedData)
        } else {
          setGraphData(campaignsPerDistributionChannelInPercentage)
        }
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, distributionChannel, endDate, projectIds, startDate])

  return (
    <GraphWidget.Widget
      title={
        <span data-cy="number-of-campaigns-widget-title">
          {`${totalCount || ''}`} Experiments
        </span>
      }
      className="min-h-full"
      actions={
        !isLoading &&
        !hasError && (
          <InfoAction
            message={`The total number of experiments you’ve created across 
              different channels using Jacquard’s awesome AI.`}
          />
        )
      }
    >
      {isLoading && (
        <WidgetLoader
          data-cy="number-of-campaigns-widget-loader"
          size="small"
        />
      )}
      {!isLoading && totalCount !== undefined && graphData && (
        <ActivityGauge
          total={totalCount}
          selectedTimeIndex={selectedTimeIndex}
          results={graphData}
          ref={chartRefCallback}
        />
      )}
      {hasError && <WidgetIncrementalError />}
    </GraphWidget.Widget>
  )
}

export default NumberOfCampaignsWidget
