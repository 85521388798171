import FormItem from 'common/components/FormItem'
import RadioGroup from 'common/components/radioGroup'

import { CommonFieldPropsGeneric, RadioType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please select an option.',
}

type Props = CommonFieldPropsGeneric & {
  value: RadioType['value']
  options: RadioType['options']
  componentProps: RadioType['componentProps']
}

const Radio = ({
  name,
  label,
  value,
  options,
  tooltip,
  errorCode,
  isDisabled,
  componentProps,
  onChange,
}: Props) => {
  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
      style={componentProps?.style}
    >
      <RadioGroup
        name={name}
        onChange={onChange}
        options={options}
        value={value}
        variant="withBackground"
        isDisabled={isDisabled}
        {...componentProps}
      />
    </FormItem>
  )
}

export default Radio
