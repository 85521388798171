import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'
import { unifiedFlowPaths } from 'app/navigation/paths'
import cx from 'classnames'

import AvatarList from 'common/components/AvatarList'
import Badge from 'common/components/Badge'
import Button from 'common/components/button/Button'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { TableData } from 'common/components/table/Table'
import Tooltip from 'common/components/Tooltip'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate, formatDateToAgo } from 'common/helpers/date'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { Bin, ChevronDown, ChevronUp } from 'common/icons'

type SubRow = {
  id: string
  name: string
  project: string
  template: string
  campaign_id: string
  experimentType: string
  owner: string
  createdDate: string
  sendDate: string
  status: string
}

type LibraryEntry = {
  id: string
  name: string
  status: string
  createdDate: string
  isLegacy: boolean
  subRows: SubRow[]
}

type Library = LibraryEntry[]

type Props = {
  content: Library
  onDelete: Dispatch<SetStateAction<string>>
}

const ContentLibraryTable = ({ content, onDelete }: Props) => {
  const history = useHistory()

  const columns: Column<LibraryEntry>[] = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'name',
        width: 280,
        Cell: ({ row, toggleAllRowsExpanded }) => {
          return (
            <BaseCell
              className={cx('gap-3 w-full', {
                'ml-7': row.depth > 0,
                'cursor-pointer': row.depth === 0,
              })}
              onClick={() =>
                row.depth === 0 &&
                history.push(`${unifiedFlowPaths.home}/${row.original.id}/`)
              }
            >
              {row.depth === 0 && (
                <Button
                  variant="icon"
                  className="flex-shrink-0"
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleAllRowsExpanded(false)
                    row.toggleRowExpanded(!row.isExpanded)
                  }}
                >
                  {row.isExpanded ? (
                    <ChevronUp size={4} />
                  ) : (
                    <ChevronDown size={4} />
                  )}
                </Button>
              )}
              <div className="flex w-full justify-between items-center">
                <p>{row.values.name}</p>
                {row.original.isLegacy && (
                  <Badge
                    text="Legacy"
                    size="small"
                    className="ml-2 flex-shrink-0"
                  />
                )}
              </div>
            </BaseCell>
          )
        },
      },
      {
        Header: 'Template',
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows.find((subRow) => subRow.template)?.template ?? ''
            : row.template
        },
        disableSortBy: true,
        Cell: ({ value, row }) => {
          const tooltipItemsToShow = row.subRows
            .map((subRow) => subRow.values.Template)
            .filter((element) => element !== '')
            .slice(1)

          return (
            <BaseCell>
              {!row.isExpanded && (
                <>
                  <span>{value}</span>
                  {tooltipItemsToShow.length > 0 && (
                    <Tooltip overlay={tooltipItemsToShow.join(', ')}>
                      <span className="flex items-center justify-center min-w-10 min-h-10 ml-2 rounded-full border-2 border-white bg-coolGray-50 text-coolGray-400">
                        +{tooltipItemsToShow.length}
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Type',
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows.map((element) => element.experimentType)
            : row.experimentType
        },
        disableSortBy: true,
        Cell: ({ value, row }) => {
          const badgesToRender =
            row.depth === 0 ? new Set<string>(value) : value.split()
          return (
            <BaseCell className="flex flex-wrap gap-2 py-1">
              {!row.isExpanded &&
                [...badgesToRender]
                  .filter((element) => row.depth > 0 || element !== 'content')
                  .map((element, index) => (
                    <Badge
                      key={index}
                      text={element}
                      size="small"
                      variant="optimize"
                    />
                  ))}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Send Date',
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows.find((subRow) => subRow.sendDate)?.sendDate ?? ''
            : row.sendDate
        },
        Cell: ({ value, row }) => {
          const tooltipItemsToShow = row.subRows
            .filter((subRow, index) => index > 0 && subRow.original.sendDate)
            .map((subRow, index) => {
              const date = subRow.original.sendDate
              return !isNaN(new Date(date).getTime()) ? (
                <div key={index}>{formatDate(date)}</div>
              ) : null
            })

          return (
            <BaseCell>
              {!row.isExpanded && value && (
                <>
                  {formatDate(value)}
                  {tooltipItemsToShow.length > 1 && (
                    <Tooltip overlay={tooltipItemsToShow}>
                      <span className="ml-2 min-w-7 text-coolGray-400 font-bold">
                        +{tooltipItemsToShow.slice(1).length}
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Created',
        width: 100,
        accessor: 'createdDate',
        Cell: ({ value, row }) => {
          return (
            <BaseCell>
              {!row.isExpanded && formatDateToAgo(new Date(value))}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Owner',
        width: 100,
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows?.map((element) => element.owner)
            : [row.owner]
        },
        disableSortBy: true,
        Cell: ({ value, row }) => {
          const userNames = value.reduce((acc, curr) => {
            if (curr !== '' && !acc.includes(curr)) {
              acc.push(curr)
            }
            return acc
          }, [])

          return (
            !row.isExpanded && (
              <BaseCell className="flex justify-center">
                <AvatarList userNames={userNames} />
              </BaseCell>
            )
          )
        },
      },
      {
        Header: 'Status',
        width: 180,
        accessor: (row: LibraryEntry | SubRow) => {
          return row.status
        },
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <BaseCell
              className={cx('w-full flex group', {
                'justify-between': !row.isExpanded,
                'justify-end': row.isExpanded,
              })}
            >
              {!row.isExpanded && (
                <div className="flex items-center">
                  <span
                    className={cx('px-1 text-3 font-medium uppercase', {
                      'bg-maroon-600 text-white': value === 'New Language',
                      'bg-maroon-400 text-white': value === 'Draft',
                      'bg-maroon-300 text-white': value === 'Pending Approval',
                      'bg-maroon-200 text-white': value === 'Missing Results',
                      'bg-maroon-100 text-black': value === 'Live',
                      'text-black': value === 'Completed',
                    })}
                  >
                    {value}
                  </span>
                </div>
              )}
              {row.depth === 0 && (
                <Button
                  className="invisible flex-shrink-0 group-hover:visible"
                  onClick={() => onDelete(row.original.id)}
                >
                  <Bin />
                </Button>
              )}
            </BaseCell>
          )
        },
      },
    ],
    [history, onDelete]
  )

  const isLastSubRow = <T extends object>({
    row,
    rows,
  }: {
    row: Row<T>
    rows: Row<T>[]
  }) => {
    const parentRow = rows.find((item) =>
      item.subRows?.some((subRow) => subRow.id === row.id)
    )

    return parentRow?.subRows?.slice(-1)[0]?.id === row.id
  }

  const { screenWidth } = useWindowDimensions()

  const generateRowClassName = useCallback(
    <T extends TableData>({ row, rows }: { row: Row<T>; rows: Row<T>[] }) => {
      return cx({
        'bg-maroon-50 hover:bg-maroon-50 border border-maroon-500':
          row.isExpanded,
        'bg-maroon-30 hover:bg-maroon-50 border-l border-r border-maroon-500':
          row.depth > 0,
        'border-b border-maroon-500': isLastSubRow({ row, rows }),
        'hover:bg-maroon-30': !row.original.hasError,
        'w-fit': screenWidth < 1110,
      })
    },
    [screenWidth]
  )

  return (
    <div data-testid="content-table" className="pb-12">
      <TableWidget.Widget
        columns={columns}
        data={content}
        firstUseText="Nothing to see here… There is no content to show."
        disableSubRowArrow={true}
        generateRowClassName={generateRowClassName}
        paginateExpandedRows={false}
      />
    </div>
  )
}

export default ContentLibraryTable
