import { generatePath, useHistory } from 'react-router'
import { styleGuidePath } from 'app/navigation/paths'

import GridContainer from 'common/components/grid/GridContainer'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { selectBrandVoice } from 'features/admin/styleGuide/store/styleGuideSlice'

import { BrandVoice, markAsDefault } from '../../../store/brandVoiceSlice'

import LibraryCard from './LibraryCard'

type Props = {
  brandVoices: BrandVoice[]
  onDuplicate: (brandVoice: BrandVoice) => void
}

const LibraryGrid = ({ brandVoices, onDuplicate }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const handleContentClick = (brandVoiceId: string) => {
    dispatch(selectBrandVoice(brandVoiceId))
    history.push(
      generatePath(styleGuidePath, {
        accountId,
        brandVoiceId,
      })
    )
  }

  return (
    <GridContainer>
      {brandVoices?.map((brandVoice: BrandVoice) => (
        <div
          key={brandVoice.id}
          onClick={() => handleContentClick(brandVoice.id)}
          className="cursor-pointer"
          aria-label="Saved content card"
        >
          <LibraryCard
            title={brandVoice.name}
            createdDate={new Date(brandVoice.createdDate)}
            isDefault={brandVoice.isDefault}
            onEdit={(e) => {
              e.stopPropagation()

              history.push(
                generatePath(styleGuidePath, {
                  accountId,
                  brandVoiceId: brandVoice.id,
                })
              )
            }}
            onDuplicate={(e) => {
              e.stopPropagation()
              onDuplicate(brandVoice)
            }}
            onMarkDefault={(e) => {
              e.stopPropagation()
              dispatch(
                markAsDefault({
                  accountId,
                  brandVoiceId: brandVoice.id,
                  name: brandVoice.name,
                })
              )
            }}
          />
        </div>
      ))}
    </GridContainer>
  )
}

export default LibraryGrid
