import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import helpers from 'workflow/utils/helpers'

import { EditableValue, EditingValue } from 'common/components/editableInput'
import FormError from 'common/components/formError'
import { errorToast, successToast } from 'common/components/toastNotification'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'
import { useAppSelector } from 'common/hooks/redux'
import { Node } from 'common/interfaces/nodes'
import useUpdateNodeMutation from 'features/content/api/mutations/useUpdateNodeMutation'

let maxInputWidth = 0
const BUTTON_AND_PADDING = 85

type Props = {
  currentNode: Node
}

const NameInput = ({ currentNode }: Props) => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const nameWrapperRef = useRef<HTMLDivElement>(null)

  const [value, setValue] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const userId = useAppSelector((state) => state.authStates.user_id)

  const updateNodeMutation = useUpdateNodeMutation()

  useLayoutEffect(() => {
    if (isEditing) {
      let maxWidth = nameWrapperRef.current?.clientWidth

      if (maxWidth) {
        maxWidth = maxWidth - BUTTON_AND_PADDING
      }
      if (!maxInputWidth && maxWidth) {
        maxInputWidth = maxWidth
      }
      if (maxInputWidth) {
        helpers.applyInputWidth(maxInputWidth)
      }
    }
  }, [isEditing, value])

  useEffect(() => {
    if (isEditing) {
      nameInputRef.current?.focus()
    }
  }, [isEditing])

  useEffect(() => {
    setIsEditing(false)
  }, [currentNode])

  useEffect(() => {
    if (value.length > 0 && !isInputFocused) {
    }
  }, [isInputFocused, value])

  const startEditing = () => {
    setIsEditing(true)
    setValue(stripForbiddenCharacters(currentNode.name))
  }

  const onBlur = () => {
    setIsInputFocused(false)

    setError(undefined)
    setIsEditing(false)
  }

  const validateName = (value: string) => {
    let validationError

    if (!value) {
      validationError = 'Please enter node name'
    }

    return validationError
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newNodeName = event.target.value
    const validationError = validateName(newNodeName)

    setValue(stripForbiddenCharacters(newNodeName))
    if (validationError) {
      setError(validationError)
    } else {
      setError(undefined)
    }
  }

  const saveName = () => {
    if (value.length === 0) {
      return
    }

    updateNodeMutation.mutate(
      {
        userId,
        nodeId: parseInt(currentNode.id),
        updatedName: value,
      },
      {
        onSuccess: () => {
          successToast('Node renamed successfully')
        },
        onError: () => errorToast('Failed to rename node'),
      }
    )

    setIsEditing(false)
    setIsInputFocused(false)
    setError(undefined)
  }

  return (
    <div className="w-4/5">
      <div
        className="flex justify-between items-center pb-2"
        ref={nameWrapperRef}
      >
        <div className="flex items-center">
          {isEditing ? (
            <form
              data-cy="node-name-form"
              onSubmit={(event) => {
                event.preventDefault()
                saveName()
              }}
            >
              <EditingValue
                ref={nameInputRef}
                data-cy="node-name"
                value={value}
                savedValue={currentNode.name}
                placeholder="Enter node name…"
                shouldShowSaveButton={isEditing}
                onSaveClick={saveName}
                onChange={onChange}
                onBlur={onBlur}
                setIsFocused={setIsInputFocused}
                className="text-3xl"
              />
            </form>
          ) : (
            <EditableValue
              data-cy="node-name"
              value={updateNodeMutation.isLoading ? value : currentNode.name}
              onEditClick={startEditing}
              className="text-3xl"
            />
          )}
        </div>
      </div>
      {isEditing && (
        <div className="h-4 text-coolGray-400 mb-4">
          {isEditing && isInputFocused && <>{value.length} / 128</>}
        </div>
      )}
      {error && <FormError>{error}</FormError>}
    </div>
  )
}

export default NameInput
