import Alert from 'common/components/alert'

const Error = () => (
  <Alert type="error" className="flex items-center mt-6 w-max">
    Save error - our server did not respond, please try again or contact your
    customer services representative.
  </Alert>
)

export default Error
