import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import helpers from 'workflow/utils/helpers'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import StatusCard, { Step } from '../components/card/StatusCard'

import {
  cancelSetup,
  checkFacebookTokenExists,
  clearStatusState,
  getCampaign,
  getFBStatus,
  setError,
  setLoading,
  updateFbCampaignStatus,
  updateFBData,
  updateFBLogin,
  updateScheduleStatus,
  updateStatusDataFetch,
} from './store/facebookSlice'

export interface Props {
  inlineText?: boolean
  showButton?: boolean
  cleanStateOnUnmount?: boolean
}

interface State {
  statusSteps: Step[]
  liveCampaignTitle: string
  completeMessage: string
  liveCampaignSubtitle: string
  isCampaignCompleted: boolean
  isCampaignLive: boolean
}

declare const window: any

export default function FacebookStatus({
  inlineText,
  showButton,
  cleanStateOnUnmount,
}: Props) {
  const STATUS_STEPS: Step[] = [
    {
      title: 'Jacquard experiment scheduled',
      subtitle: '',
      status: 'complete',
    },
    {
      title: 'Jacquard setting up experiment on Facebook',
      subtitle: '0 of 5 ads have been created',
      status: 'processing',
    },
    {
      title: 'Facebook review',
      subtitle: '',
      status: 'pending',
    },
    {
      title: 'Experiment now live on Facebook',
      subtitle: '',
      status: 'pending',
    },
  ]

  const initialState: State = {
    statusSteps: STATUS_STEPS,
    liveCampaignTitle: 'Experiment now live on Facebook',
    completeMessage: '',
    liveCampaignSubtitle: '5 of 5 ads are live',
    isCampaignCompleted: false,
    isCampaignLive: false,
  }
  const dispatch = useAppDispatch()

  const [state, setState] = useState<State>(initialState)
  const {
    facebook: {
      fbStatus,
      fbConnected,
      campaignData,
      isCampaignScheduled,
      isStatusDataFetching,
    },
    campaignStates: {
      campaignData: phraseeCampaign,
      campaignData: { project_id, _id: currentCampaignId },
    },
  } = useAppSelector((state) => state)

  useEffect(() => {
    if (fbConnected && !campaignData) {
      dispatch(setLoading({ field: 'fbCampaign', value: true }))
      getCampaign({
        campaign_id: currentCampaignId,
      })
        .then((res) => {
          dispatch(updateFBData(res?.data?.data))
        })
        .catch(() => dispatch(setError('Facebook campaign fetch failed')))
        .finally(() =>
          dispatch(setLoading({ field: 'fbCampaign', value: false }))
        )
    }
    if (!fbConnected && project_id) {
      dispatch(setLoading({ field: 'fbAuth', value: true }))
      checkFacebookTokenExists({ project_id })
        .then((data) => {
          dispatch(
            updateFBLogin({
              fbConnected: true,
              fbUserName: data?.data?.data?.displayName,
              fbUserId: '',
            })
          )
        })
        .catch(() => {
          if (fbConnected) {
            dispatch(setError('Facebook campaign status fetch failed'))
          }
        })
        .finally(() => dispatch(setLoading({ field: 'fbAuth', value: false })))
    }

    if (
      (campaignData?.fb_start_date && campaignData?.fb_end_date) ||
      (isCampaignScheduled && !isStatusDataFetching)
    ) {
      dispatch(updateScheduleStatus(true))
    }

    if (fbStatus) {
      const { campaign, status } = fbStatus
      const num_splits = phraseeCampaign?.sl?.length
      const step1 = state.statusSteps[0]
      const step2 = state.statusSteps[1]
      const step3 = state.statusSteps[2]
      const step4 = state.statusSteps[3]
      let campaignLive = false
      let campaignComplete = false

      if (campaign?.step4) {
        step1.status = 'complete'
      }
      if (status) {
        if (status.setup_count === num_splits) {
          step2.status = 'complete'
          step2.subtitle = ''
        } else {
          step2.status = status.setup_count > 0 ? 'processing' : 'pending'
          step2.subtitle =
            status.setup_count > 0
              ? `${status.setup_count} of ${num_splits} ads ${
                  status.setup_count > 1 ? 'have' : 'has'
                } been created`
              : ''
        }
        if (status.active_count === num_splits) {
          step3.status = 'complete'
          step3.subtitle = `${status.active_count} of ${num_splits} ads
          have passed review`
        } else {
          step3.status = status.active_count > 0 ? 'processing' : 'pending'
          step3.subtitle =
            status.active_count > 0
              ? `${status.active_count} of ${num_splits} ads ${
                  status.active_count === 1 ? 'has' : 'have'
                } passed review`
              : ''
        }

        if (status.campaign_active) {
          step4.status = 'processing'
          step4.subtitle = `${status.active_count} of ${
            status.setup_count
          } ads ${status.active_count > 1 ? 'are' : 'is'} live`
          campaignLive = true
        }
        if (status.completed) {
          step4.status = 'complete'
          campaignComplete = true
        }
      }

      setState({
        ...state,
        isCampaignCompleted: campaignComplete,
        isCampaignLive: campaignLive,
        statusSteps: [step1, step2, step3, step4],
        completeMessage:
          campaignComplete && campaignData?.fb_end_date
            ? `This experiment was completed on ${moment(
                campaignData.fb_end_date
              ).format('DD MMMM YYYY')}`
            : '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fbConnected,
    fbStatus,
    campaignData?.fb_ad_account_id,
    campaignData?.fb_campaign_id,
    campaignData?.fb_adset_id,
    campaignData?.fb_ad_id,
    campaignData?.is_ad_study,
    campaignData?.fb_start_date,
    campaignData?.fb_end_date,
    phraseeCampaign?.name,
    campaignData?.name,
    isCampaignScheduled,
    currentCampaignId,
    project_id,
    dispatch,
  ])

  const INTERVAL = 5000
  const fetchStatus = () => {
    if (currentCampaignId && fbConnected && isCampaignScheduled) {
      getFBStatus({ campaign_id: currentCampaignId }).then((res) => {
        setState(initialState)
        helpers.cancellAllIntrvals(window.fbInterval)
        window.fbInterval = undefined
        dispatch(updateFbCampaignStatus(res?.data?.data))
        dispatch(updateStatusDataFetch(true))

        if (!window.fbInterval) {
          window.fbInterval = setInterval(() => {
            fetchStatus()
          }, INTERVAL)
        }
      })
    }
  }
  useEffect(() => {
    fetchStatus()
    return () => {
      setState(initialState)
      if (cleanStateOnUnmount) {
        dispatch(clearStatusState())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCampaignId,
    fbConnected,
    isCampaignScheduled,
    dispatch,
    cleanStateOnUnmount,
  ])

  return isCampaignScheduled &&
    isStatusDataFetching &&
    fbStatus &&
    fbConnected ? (
    <StatusCard
      steps={state.statusSteps}
      buttonText="Cancel Setup"
      onSubmit={() => {
        dispatch(setLoading({ field: 'fbCancel', value: true }))
        cancelSetup({ campaign_id: currentCampaignId })
          .then(() => {
            dispatch(setLoading({ field: 'fbCampaign', value: true }))
            getCampaign({
              campaign_id: currentCampaignId,
            }).then((res) => {
              dispatch(updateFBData(res?.data?.data))
              dispatch(updateScheduleStatus(false))
            })
          })
          .catch(() => dispatch(setError('Facebook campaign cancel failed')))
          .finally(() => {
            dispatch(setLoading({ field: 'fbCancel', value: false }))
            dispatch(setLoading({ field: 'fbCampaign', value: false }))
          })
      }}
      isCampaignComplete={state.isCampaignCompleted}
      isCampaignLive={state.isCampaignLive}
      liveCampaignTitle={state.liveCampaignTitle}
      liveCampaignSubtitle={state.liveCampaignSubtitle}
      completeMessage={state.completeMessage}
      inlineText={inlineText}
      showButton={showButton}
    />
  ) : null
}
