import { IntegrationLogsResponse } from '@phrasee/phrasee-typings/typings/integrations/logs'
import { useQuery } from '@tanstack/react-query'

import { getLogs } from '../api'
import { integrationKeys } from '../queryKeys'

const useGetLogsQuery = (contentId?: string) => {
  const getLogsQuery = useQuery<IntegrationLogsResponse>(
    integrationKeys.logs(contentId),
    () => getLogs({ contentId }),
    { enabled: !!contentId }
  )

  const { isLoading, data } = getLogsQuery
  const getLogsState = () => {
    if (isLoading) {
      return 'loading'
    } else if (!data?.length) {
      return 'empty'
    } else {
      return 'hasLogs'
    }
  }

  return {
    ...getLogsQuery,
    logsState: getLogsState(),
  }
}

export default useGetLogsQuery
