import { useEffect, useState } from 'react'

import { connectInternalApi } from 'common/api'
import * as GraphWidget from 'common/components/GraphWidget'
import LineGraph, { LineData } from 'common/components/LineGraph/LineGraph'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { InfoAction } from 'common/components/WidgetHeader'
import { WidgetError } from 'common/components/WidgetMessage'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import { GraphDataResponse } from '../../performance/interfaces'
import { WidgetProps } from '../interfaces'

const SentimentsOvertimeWidget = ({
  accountId,
  projectIds,
  startDate,
  endDate,
}: WidgetProps) => {
  const [results, setResults] = useState<LineData[]>([])
  const [labels, setLabels] = useState<string[]>([])

  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { chart, chartRefCallback } = useChartRefCallback()

  useEffect(() => {
    function fetchData() {
      return connectInternalApi.get<GraphDataResponse>(
        `v1/core/reporting/reporting/accounts/${accountId}/insights/sentiment/report/graph-data/line`,
        {
          params: {
            project_ids: projectIds,
            start_date: startDate,
            end_date: endDate,
            reporting_level: 'project',
          },
        }
      )
    }

    setResults([])
    setIsLoading(true)
    setHasError(false)

    fetchData()
      .then((graphResponse) => {
        const {
          data: { data: graphData, xAxis },
        } = graphResponse

        const labels = xAxis.categories
        const data = graphData.find(
          ({ name }) => name.toLowerCase() === 'average'
        )?.data

        if (data) {
          setResults(data)
          setLabels(labels)
        }
      })
      .catch((err) => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, projectIds, startDate, endDate])

  const hasResults = !hasError && !isLoading

  const title = 'Sentiments over time'

  return (
    <GraphWidget.Widget
      type="inner"
      title={
        <div>
          <div className="text-coolGray-800 font-medium text-xl mb-4 mr-4">
            {title}
          </div>
          <p className="text-coolGray-500 text-sm font-normal">
            How the sentiments within your language have changed over time.
          </p>
        </div>
      }
      actions={
        hasResults && (
          <div className="inline-flex">
            <ChartWidgetMenu
              chart={chart}
              title={title}
              problematicExports={['print']}
              exportOptions={{
                legend: { symbolWidth: 16 },
              }}
            />
            <InfoAction
              message="There might be changes in how you employ these sentiments over 
              time, this graph shows those changes. You can see how your audience might be 
              engaging differently by selecting ‘winners only’."
            />
          </div>
        )
      }
    >
      {isLoading && (
        <WidgetLoader data-cy="sentiments-overtime-widget-loader" />
      )}
      {hasError && <WidgetError className="mb-6" />}
      {hasResults && (
        <LineGraph data={results} xAxisLabels={labels} ref={chartRefCallback} />
      )}
    </GraphWidget.Widget>
  )
}

export default SentimentsOvertimeWidget
