import { useQuery } from '@tanstack/react-query'

import { getTags } from 'common/api/tagsApi'
import Modal, { ModalProps } from 'common/components/Modal'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import Tooltip from 'common/components/Tooltip'
import TableWidget from 'common/components/widget/tableWidget/TableWidget'
import { Tag } from 'common/interfaces/tags'
import { contentKeys } from 'features/content/api/queryKeys'

const columns: Column<Tag>[] = [
  {
    Header: 'Tag',
    accessor: 'tag',
  },
  {
    Header: 'Number of tagged phrases',
    accessor: 'taggedPhrasesCount',
  },
]

const treeViewColumns: Column<Tag>[] = [
  ...columns,
  {
    Header: 'Nodes',
    accessor: 'taggedNodes',
    Cell: ({ value }) => {
      const nodeNames = value?.map((node) => node.name).join(', ')

      return (
        <BaseCell>
          <Tooltip
            overlay={nodeNames}
            placement="left"
            overlayStyle={{ maxWidth: 300 }}
          >
            <div className="line-clamp-4">{nodeNames}</div>
          </Tooltip>
        </BaseCell>
      )
    },
  },
]

type Props = ModalProps & {
  isTreeView: boolean
  nodeId: string
}

const TagsModal = ({ isTreeView, nodeId, ...props }: Props) => {
  const params = isTreeView ? { rootNodeId: nodeId } : { nodeIds: [nodeId] }

  const { status, data } = useQuery<Tag[]>(contentKeys.tags(params), () => {
    return getTags(params)
  })

  return (
    <Modal title="Tags" {...props}>
      <TableWidget.Widget
        key={isTreeView ? 'treeView' : 'listView'}
        isLoading={status === 'loading'}
        hasError={status === 'error'}
        columns={isTreeView ? treeViewColumns : columns}
        data={data}
        hasWidgetMenu={false}
        initialState={{ pageSize: isTreeView ? 5 : 10 }}
        firstUseText="This node contains no tags."
      >
        <TableWidget.Header>
          <TableWidget.Filter />
        </TableWidget.Header>
      </TableWidget.Widget>
    </Modal>
  )
}

export default TagsModal
