import cx from 'classnames'

import styles from './LinearLoader.module.css'

type Props = {
  backgroundColor?: 'gray' | 'transparent'
}

const LinearLoader = ({ backgroundColor = 'gray' }: Props) => (
  <span
    className={cx('relative overflow-hidden block h-1 z-0 w-full', {
      'bg-transparent': backgroundColor === 'transparent',
      'bg-coolGray-200': backgroundColor === 'gray',
    })}
  >
    <span className={cx('bg-maroon-300', styles.span1)}></span>
    <span className={cx('bg-maroon-300', styles.span2)}></span>
  </span>
)

export default LinearLoader
