import cond from 'lodash/cond'
import stubTrue from 'lodash/stubTrue'

import Button from 'common/components/button'
import { Variant } from 'common/components/button/Button'
import { useAppSelector } from 'common/hooks/redux'
import {
  Alert as AlertIcon,
  ArrowRight as ArrowRightIcon,
  CheckCircle as CheckCircleIcon,
} from 'common/icons'

import { useExperimentForm } from '../context/ExperimentFormContext'

import { TemplateInfoWithInternalId } from './AddedComponentList'

type OptimizeButtonProps = {
  template: TemplateInfoWithInternalId
}
const OptimizeButton = ({ template }: OptimizeButtonProps) => {
  const { dispatch } = useExperimentForm()

  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )

  const currentTemplate = templatesToAdd.find(
    (item) => item.internalId === template.internalId
  )
  const isSetupExperimentCompleted = currentTemplate?.status === 'complete'
  const isSetupExperimentInCompleted = currentTemplate?.status === 'incomplete'
  const isSetupExperimentNotStarted = currentTemplate?.status === 'pristine'

  const getOptimizeButtonVariant = () => {
    if (isSetupExperimentCompleted) {
      return 'success' as Variant
    } else if (isSetupExperimentInCompleted) {
      return 'primary' as Variant
    } else {
      return undefined
    }
  }
  const getOptimizeContent = cond([
    [
      () => isSetupExperimentCompleted,
      () => (
        <div className="flex items-center">
          <CheckCircleIcon
            isDefaultColor={false}
            className="text-green-400 mr-2"
            size={4}
          />
          <span>Setup complete</span>
        </div>
      ),
    ],
    [
      () => isSetupExperimentInCompleted,
      () => (
        <div className="flex items-center">
          <AlertIcon
            isDefaultColor={false}
            className="text-orange-400 mr-2"
            size={4}
          />
          <span>Set up experiment</span>
        </div>
      ),
    ],
    [() => isSetupExperimentNotStarted, () => <span>Set up experiment</span>],
    [stubTrue, () => null],
  ])

  const content = getOptimizeContent()

  return (
    <Button
      ghost={true}
      variant={getOptimizeButtonVariant()}
      className="w-full flex justify-between"
      onClick={() => {
        dispatch({
          type: 'set_experiment_form_template',
          value: template,
        })
      }}
      suffixIcon={
        <ArrowRightIcon isDefaultColor={false} size={4} className="ml-auto" />
      }
    >
      {content}
    </Button>
  )
}

export default OptimizeButton
