import { connectInternalApi } from 'common/api'

import {
  Currency,
  InitializePageResponse,
  LanguageSettingsResponse,
} from './types'

export async function fetchProjectCombinedLanguage(
  projectId: string,
  brandVoiceId?: string
): Promise<LanguageSettingsResponse> {
  const response = await connectInternalApi.get<{
    data: LanguageSettingsResponse
  }>(`v1/core/monorail/projects/${projectId}/combined_language_settings`, {
    params: {
      return_inheritance: true,
      ...(brandVoiceId ? { brand_voice_id: brandVoiceId } : {}),
    },
  })

  return response.data.data
}

export async function fetchCurrenciesList(): Promise<Currency[]> {
  const response = await connectInternalApi.get<Currency[]>(
    'v1/core/monorail/currency'
  )

  return response.data
}

export async function updateProjectSettings(
  projectId: string,
  settings: {
    regionId: string | null
    brandVoiceId: string | null
    languageSettings: LanguageSettingsResponse
  }
): Promise<InitializePageResponse> {
  const { regionId, brandVoiceId, languageSettings } = settings
  const response = await connectInternalApi.patch<{ data }>(
    `v1/core/monorail/projects/${projectId}`,
    {
      region_id: regionId,
      brand_voice_id: brandVoiceId,
      language_settings: languageSettings,
    }
  )
  return {
    projectConfig: response.data.data,
    languageSettings: response.data.data.language_settings,
  }
}
