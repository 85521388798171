import { routesPermissions } from 'common/auth/permissions'
import { Settings } from 'common/icons'
import ToneOfVoice from 'features/admin/brandVoice/views/toneOfVoice/ToneOfVoice'
import StyleGuide from 'features/admin/styleGuide/StyleGuide'

import { Subroute, TabbedNavRoute } from './interfaces'
import { dispatchClickNavItem } from './navigationSlice'
import { styleGuidePath, toneOfVoicePath } from './paths'

const styleGuideSubroute: Subroute = {
  icon: Settings,
  key: 'style-guide',
  name: 'Style Guide Setup',
  component: StyleGuide,
  to: styleGuidePath,
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showStyleGuide'],
    type: 'hide',
  },
  accessPermission: routesPermissions.brandVoiceSettingsView,
}

const toneOfVoiceSubroute: Subroute = {
  icon: Settings,
  key: 'tone-of-voice',
  name: 'Tone of Voice',
  component: ToneOfVoice,
  to: toneOfVoicePath,
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showBrandTonePage'],
    type: 'hide',
  },
  accessPermission: routesPermissions.brandVoiceSettingsView,
}
export const brandVoiceSubroutes: TabbedNavRoute[] = [
  styleGuideSubroute,
  toneOfVoiceSubroute,
]
