import { createApi, createAxiosInstance } from 'common/api'

export type CustomerAttributesResponse = {
  fileName: string
  type: string
  created: string
  result: Object[] // can really be anything that the customer uploads
}

export const getCustomerAttributes = async ({
  accountId,
  baseUrl,
}: {
  accountId: string
  baseUrl: string
}): Promise<CustomerAttributesResponse> => {
  const dataIngestionClient = createAxiosInstance(baseUrl)
  const api = createApi(dataIngestionClient)
  const result = await api.get<CustomerAttributesResponse>(
    `accounts/${accountId}/data/customer`
  )

  return result.data
}

export const deleteCustomerAttributes = async ({
  accountId,
  baseUrl,
}: {
  accountId: string
  baseUrl: string
}): Promise<void> => {
  const dataIngestionClient = createAxiosInstance(baseUrl)
  const api = createApi(dataIngestionClient)
  await api.delete<void>(`accounts/${accountId}/data/customer`)
}

export type ProductCatalogResponse = {
  _id: string
  file_name: string // name of the file
  type: string
  created: string
  categories: { productCategories: Object } // can really be anything
}

export const getProductCatalog = async ({
  accountId,
  baseUrl,
}: {
  accountId: string
  baseUrl: string
}): Promise<ProductCatalogResponse | null> => {
  const dataIngestionClient = createAxiosInstance(baseUrl)
  const api = createApi(dataIngestionClient)
  const result = await api.get<ProductCatalogResponse | null>(
    `accounts/${accountId}/data/product/category`
  )

  return result.data
}
