import { useState } from 'react'

import { SelectValue } from 'common/components/autcomplete/Autocomplete'
import Button from 'common/components/button'
import Widget from 'common/components/Widget'
import { useAppSelector } from 'common/hooks/redux'
import useContent from 'features/futurama/contentPage/hooks/useContent'

import useSelectTeams from '../../../../api/queries/useSelectTeams'
import useSelectUsers from '../../../../api/queries/useSelectUsers'

import ContentApprovalCard from './contentApprovalCard/ContentApprovalCard'
import ApproversAutocomplete from './ApproversAutocomplete'

const progressLabels = {
  approval_ready: 'Draft',
  'awaiting-approval': 'Awaiting approval',
  approved: 'Approved',
  rejected: 'Rejected',
}

const progressColors = {
  approval_ready: 'bg-coolGray-500',
  'awaiting-approval': 'bg-blue-500',
  approved: 'bg-green-500',
  rejected: 'bg-red-500',
}

const SubmitForApproval = () => {
  const { content } = useContent()
  const labelBackgroundClass = progressColors[content?.approval_progress ?? '']

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const [approvers, setApprovers] = useState<SelectValue[]>([])

  const users = useSelectUsers(accountId, content?._id ?? '')
  const teams = useSelectTeams(accountId, content?._id ?? '')

  return (
    <Widget data-cy="submit-for-approval">
      <span className="flex flex-row gap-4 mb-6">
        <h1 className="text-xl font-medium">Submit for Approval</h1>
        <span
          className={`text-xs font-bold ${labelBackgroundClass} text-white py-0.5 px-1 self-center`}
        >
          {progressLabels[content?.approval_progress ?? '']}
        </span>
      </span>
      <span className="flex flex-row items-center gap-4">
        <label className="text-sm font-medium text-coolGray-500">
          Approver/s:
        </label>
        <ApproversAutocomplete
          options={[...(users ?? []), ...(teams ?? [])]}
          values={approvers}
          setValues={(approvers) => setApprovers(approvers)}
          searchPlaceholder="Type name to filter"
          tagButtonLabel="All selections used"
        />
      </span>
      <span className="flex flex-row items-center gap-4 mt-4 mb-8">
        <label className="text-sm font-medium text-coolGray-500">
          Content title:
        </label>
        <h2 className="text-lg font-medium">{content?.display_name}</h2>
      </span>
      <h3 className="text-sm font-medium mb-2">Content needing approval</h3>
      <ContentApprovalCard
        title={content?.display_name ?? ''}
        contentType={content?.content_type ?? ''}
        elements={content?.elements ?? []}
        progress={content?.approval_progress ?? 'awaiting-approval'}
        onApprove={() => alert('Approved')}
        onReject={() => alert('Rejected')}
      />
      <span className="flex flex-row justify-end items-center gap-4 mt-8">
        <div className="text-base font-medium text-coolGray-800">
          Add an approver, maybe a comment and then...
        </div>
        <Button variant="primary">Submit for approval</Button>
      </span>
    </Widget>
  )
}

export default SubmitForApproval
