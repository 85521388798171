import { FC, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import ChannelPicker from 'common/components/channelPicker'
import Checkbox from 'common/components/checkbox'
import FormItem from 'common/components/FormItem'
import RadioGroup from 'common/components/radioGroup'
import { DistributionChannel } from 'common/interfaces/projects'
import { getFieldError } from 'features/projects/helpers'
import {
  DistributionChannelSettings,
  DistributionTypeOptionValue,
  DistributionTypeSettings,
  ProjectType,
  ProjectTypeSettings,
  SettingField,
} from 'features/projects/interface'

import FormCard, { FormCardTitle } from '../formCard'

type Props = {
  isCreating: boolean
  typeSettings: ProjectTypeSettings | undefined
  distributionChannelSettings: DistributionChannelSettings | undefined
  distributionTypeSettings: DistributionTypeSettings | undefined
  sandboxModeSettings: SettingField | undefined
}

const ProjectTypeCard: FC<Props> = ({
  isCreating,
  typeSettings,
  distributionChannelSettings,
  distributionTypeSettings,
  sandboxModeSettings,
}) => {
  const formState = useFormState()
  const form = useForm()
  const { change: formChange } = form
  const { distributionType } = formState.values

  useEffect(() => {
    if (distributionType && distributionTypeSettings?.options) {
      const isSelectedOptionAvailable = distributionTypeSettings.options.some(
        ({ value }) => value === distributionType
      )

      if (!isSelectedOptionAvailable) {
        formChange('distributionType', undefined)
      }
    }
  }, [formChange, distributionType, distributionTypeSettings?.options])

  return (
    <FormCard data-cy="project-type-card">
      <div className="flex justify-between items-start">
        <div>
          <FormCardTitle title="What type of project is this?" />
          {typeSettings?.show && (
            <Field<ProjectType>
              name="projectType"
              initialValue={
                isCreating ? typeSettings.options[0].value : undefined
              }
            >
              {({ input, meta }) => (
                <FormItem className="mt-4" error={getFieldError(meta)}>
                  <RadioGroup<ProjectType>
                    data-cy="project-type"
                    value={input.value}
                    name="project-type"
                    options={typeSettings.options}
                    onChange={input.onChange}
                  />
                </FormItem>
              )}
            </Field>
          )}
        </div>

        {sandboxModeSettings?.show && (
          <div className="p-4 border border-coolGray-300 bg-coolGray-50">
            <Field<boolean>
              name="sandboxModeEnabled"
              initialValue={isCreating ? false : undefined}
            >
              {({ input, meta }) => (
                <FormItem error={getFieldError(meta)}>
                  <Checkbox
                    data-cy="sandbox-mode-checkbox"
                    label="Sandbox mode"
                    isChecked={input.value}
                    onChange={input.onChange}
                  />
                </FormItem>
              )}
            </Field>
          </div>
        )}
      </div>

      {distributionChannelSettings?.show &&
      distributionChannelSettings?.options?.length ? (
        <Field<DistributionChannel> name="distributionChannel">
          {({ input, meta }) => (
            <FormItem className="my-8" error={getFieldError(meta)}>
              <ChannelPicker
                value={input.value}
                options={distributionChannelSettings.options.map(
                  ({ value }) => value
                )}
                onChange={input.onChange}
              />
            </FormItem>
          )}
        </Field>
      ) : null}

      {distributionTypeSettings?.show &&
      distributionTypeSettings.options?.length ? (
        <>
          <FormCardTitle title="Message type" />
          <Field<DistributionTypeOptionValue> name="distributionType">
            {({ input, meta }) => (
              <FormItem error={getFieldError(meta)}>
                <RadioGroup<DistributionTypeOptionValue>
                  data-cy="distribution-type"
                  className="mt-4"
                  value={input.value}
                  name="distribution-type"
                  options={distributionTypeSettings.options}
                  onChange={input.onChange}
                />
              </FormItem>
            )}
          </Field>
        </>
      ) : null}
    </FormCard>
  )
}

export default ProjectTypeCard
