import React from 'react'
import * as Highcharts from 'highcharts/highstock'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'
import { yAxisConfig } from 'common/helpers/ui/graph'

import styles from './UsageGraph.module.css'

export interface UsageGraphData {
  data: number[]
  label: string
}

interface Props {
  variations?: UsageGraphData
  campaigns?: UsageGraphData
  categories: string[]

  ref: React.RefCallback<ChartInstance>
}

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
})

export default React.forwardRef<ChartInstance, Props>(
  ({ variations, campaigns, categories }, ref) => {
    const options: Highcharts.Options = {
      title: undefined,
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          theme: {
            stroke: 'none',
          },
        },
      },
      navigator: {
        series: {
          color: '#61bec7',
          type: 'areaspline',
        },
      },
      xAxis: {
        labels: {
          style: {
            color: '#6b7280',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 0.8,
          },
        },
        categories,
      },
      yAxis: {
        ...yAxisConfig,
        labels: {
          format: '{value}',
          style: {
            color: '#6b7280',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 0.8,
          },
          padding: 0,
          align: 'right',
          reserveSpace: true,
        },
      },
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
        },
        spacing: [10, 0, 10, 0],
      },
      tooltip: {
        shared: true,
        split: false,
        useHTML: false,
        borderRadius: 5,
        padding: 16,
        backgroundColor: '#ffffff',
        shadow: true,
        formatter: function () {
          const series = this.points?.map(
            (point) =>
              `<span style="color:${point.color}; font-size: 16px; margin-right: 6px;">
              ■
            </span>
            ${point.y} ${point.series.options.name}</br>
          `,
            ''
          )
          return series
        },
      },
      legend: {
        y: 14,
        enabled: true,
        useHTML: true,
        itemStyle: {
          fontSize: '16px',
          fontWeight: 'normal',
          color: '#1f2937',
          opacity: 0.8,
          lineHeight: '24px',
        },
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 0,
        symbolPadding: 15,
        margin: 0,
        padding: 24,
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          ...({ borderRadiusTopLeft: 3, borderRadiusTopRight: 3 } as any),
          stickyTracking: false,
          dataGrouping: {},
          lineWidth: 2,
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      series:
        variations && campaigns
          ? [
              {
                type: 'column',
                name: 'Variations',
                data: variations.data,
                color: '#fbbf24',
                maxPointWidth: 70,
              },
              {
                type: 'line',
                name: 'Experiments',
                data: campaigns.data,
                color: '#b45309',
              },
            ]
          : [],
    }
    return (
      <div className={styles.chart}>
        <HighchartsReact
          ref={ref}
          highcharts={Highcharts}
          constructorType="chart"
          options={options}
          immutable={true}
        />
      </div>
    )
  }
)
