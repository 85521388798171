import { useAppSelector } from 'common/hooks/redux'

import useGetContentQuery from '../../api/queries/useGetContentQuery'

const useContent = () => {
  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )

  const contentQuery = useGetContentQuery(activeContentId)

  return { content: contentQuery.data }
}

export default useContent
