import { Content, Element } from '@phrasee/phrasee-typings'
import {
  ComponentElement,
  Variant,
} from '@phrasee/phrasee-typings/typings/futurama/element'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import helpers from 'workflow/utils/helpers'

import { isTemplateElement } from 'features/futurama/api/interfaces'

import { getContentHasVariants, getElementHasVariants } from '../helpers'

const getFileContentForContentCsv = (
  componentElements: ComponentElement[]
): string[][] => {
  const clonedComponentElements = cloneDeep(componentElements)

  const maxVariantsLength = Math.max(
    ...clonedComponentElements.map((element) => element.variants?.length || 0)
  )

  const getHeader = (element) => {
    return element.display_name || element.name
  }

  const headers = clonedComponentElements.map((element) => getHeader(element))

  function sortVariantsBySelection(
    a: Variant,
    b: Variant,
    selectedVariants: number[] | undefined
  ) {
    const aIsSelected = selectedVariants?.includes(a.variant_id)
    const bIsSelected = selectedVariants?.includes(b.variant_id)

    if (aIsSelected && !bIsSelected) {
      return -1
    } else if (!aIsSelected && bIsSelected) {
      return 1
    } else {
      return 0
    }
  }

  const sortedComponentElements = clonedComponentElements.map((element) => {
    const parentVariants = element?.variants?.filter(
      (variant) => variant.parent_variant_id === undefined
    )
    let sortedVariants: Variant[] = []

    parentVariants?.forEach((parentVariant) => {
      sortedVariants.push(parentVariant)

      const childVariants = element?.variants
        ?.filter(
          (variant) => variant.parent_variant_id === parentVariant.variant_id
        )
        ?.sort((a, b) => a.sortOrder - b.sortOrder)
      if (childVariants) {
        sortedVariants = sortedVariants.concat(childVariants)
      }
    })

    sortedVariants?.sort((a, b) =>
      sortVariantsBySelection(a, b, element.selected_variants)
    )

    return { ...element, variants: sortedVariants }
  })

  const dataRows: string[][] = []

  for (let rowIndex = 0; rowIndex < maxVariantsLength; rowIndex++) {
    let hasSelection = false
    const row = sortedComponentElements.map((element) => {
      const { selected_variants, variants } = element

      if (selected_variants && selected_variants[rowIndex]) {
        const selectedVariantId = selected_variants[rowIndex]
        const matchedVariant = variants?.find(
          (variant) => variant.variant_id === selectedVariantId
        )

        if (matchedVariant) {
          hasSelection = true
          return matchedVariant.text
        }
      } else if (variants && variants[rowIndex]) {
        return variants[rowIndex].text
      }

      return ''
    })

    if (hasSelection) {
      row.push('selected')
    }
    dataRows.push(row)
  }

  return [headers, ...dataRows]
}

const getFileContent = (element: Element, content: Content | undefined) => {
  const hasContentVariants = getContentHasVariants(content)
  if (!hasContentVariants || !content) {
    return []
  }

  const componentElements = isTemplateElement(element)
    ? (element.elements as ComponentElement[])
    : [element]

  return getFileContentForContentCsv(componentElements)
}

const getFileName = (element: Element, content: Content | undefined) => {
  return `${content?.display_name}_${
    element.display_name || element.name
  }_${moment().format('MM/DD/YYYY')}`
}

export const contentDownloadCsv = (content: Content | undefined) => {
  content?.elements?.forEach((element) => {
    if (getElementHasVariants(element)) {
      helpers.downloadCsv(
        getFileName(element, content),
        getFileContent(element, content)
      )
    }
  })
}

export const contentDownloadXls = (content: Content | undefined) => {
  content?.elements?.forEach((element) => {
    if (getElementHasVariants(element)) {
      helpers.downloadXls(
        getFileName(element, content),
        getFileContent(element, content)
      )
    }
  })
}
