import { useState } from 'react'

import RouterPrompt, { Props as RouterPromptProps } from './RouterPrompt'

type Props = Omit<RouterPromptProps, 'mode' | 'onCancel'>

const RouterPromptStay = (props: Props) => {
  const [resetRouterPrompt, setResetRouterPrompt] = useState(0)

  return (
    <RouterPrompt
      key={resetRouterPrompt}
      mode="stay"
      onCancel={() => {
        setResetRouterPrompt(resetRouterPrompt + 1)
        return true
      }}
      {...props}
    />
  )
}

export default RouterPromptStay
