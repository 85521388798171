import InfoMessage from './InfoMessage'

const NoTopicsOrNoMatch = () => {
  return (
    <>
      <InfoMessage
        textLines={[
          "Jacquard couldn't find any topics that were a good match.",
        ]}
        caption="You can add a topic by clicking over here. Or just click the Generate and watch the magic happen."
      />
    </>
  )
}
export default NoTopicsOrNoMatch
