import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { CampaignMetricsResponse, getCampaignMetrics } from '../api'
import { contentKeys } from '../queryKeys'

const useGetCampaignMetricsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getContentQuery = useQuery<CampaignMetricsResponse>(
    contentKeys.campaignMetrics(accountId),
    () => getCampaignMetrics(accountId),
    {
      staleTime: Infinity,
      enabled: !!accountId,
    }
  )

  return getContentQuery
}

export default useGetCampaignMetricsQuery
