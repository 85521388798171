import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { hideWorkflow } from 'features/futurama/store/contentSlice'

import LeftPane from '../components/LeftPane'

import WorkflowContent from './components/WorkflowContent'

const Workflow = () => {
  const dispatch = useAppDispatch()

  const isWorkflowVisible = useAppSelector(
    (state) => state.content.isWorkflowVisible
  )

  return (
    <LeftPane
      isVisible={isWorkflowVisible}
      onOutsideClick={() => dispatch(hideWorkflow())}
    >
      <WorkflowContent />
    </LeftPane>
  )
}

export default Workflow
