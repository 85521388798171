import cx from 'classnames'

import Button from 'common/components/button'

type Props = {
  title: string
  subtitle: string
  isDisabled?: boolean
}

const SourceButton = ({ title, subtitle, isDisabled }: Props) => {
  return (
    <div className="flex-1">
      <Button
        size="xl"
        disabled={isDisabled}
        className={cx(
          'h-full w-full p-6 bg-coolGray-50 border border-coolGray-300 border-dashed flex flex-col justify-items-center items-center justify-between'
        )}
      >
        <div className="flex gap-4 items-center">
          <span className="text-coolGray-800 font-bold text-base">{title}</span>
        </div>
        <div className="items-center text-base text-coolGray-400">
          {subtitle}
        </div>
      </Button>
    </div>
  )
}
export default SourceButton
