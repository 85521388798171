import { useState } from 'react'
import { useForm } from 'react-final-form'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { Add } from 'common/icons'
import { FinalMetric } from 'features/projects/interface'

import AddMetricsModal, { ModalOption } from '../addMetricsModal'

type Option = ModalOption<FinalMetric>
type Props = {
  options: Option[]
  selectedValues: FinalMetric[]
}

const AddFinalMetricsModalButton = ({ options, selectedValues }: Props) => {
  const { change: formChange } = useForm()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const hasOptionalMetrics = options.some((option) => !option.default)

  return (
    <>
      <ButtonWithTooltip
        tooltip={!hasOptionalMetrics}
        tooltipText="No optional metrics available"
        data-cy="add-final-metrics-button"
        className="ml-6"
        variant="primary"
        prefixIcon={<Add isDefaultColor={false} />}
        onClick={() => setIsModalOpen(true)}
        disabled={!hasOptionalMetrics}
      >
        Final metric
      </ButtonWithTooltip>
      <AddMetricsModal<FinalMetric>
        data-cy="add-final-metrics"
        title="Add final metrics"
        applyButtonText="Add final metrics"
        isOpen={isModalOpen}
        onAddClick={(selectedValues) => {
          formChange('finalMetrics', selectedValues)
          setIsModalOpen(false)
        }}
        onCancelClick={() => setIsModalOpen(false)}
        options={options}
        selectedValues={selectedValues}
      />
    </>
  )
}

export default AddFinalMetricsModalButton
