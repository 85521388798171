import {
  Approval,
  Complete,
  Email,
  ListIcon as List,
  MissingData,
  Push,
  Settings,
} from 'common/icons'

import { SubRoutes } from './interfaces'

export const staticCampaignSubRoutes: SubRoutes = [
  {
    icon: List,
    title: 'All experiments',
    key: 'all',
    type: 'status',
  },
  {
    icon: Settings,
    title: 'Setup incomplete',
    key: 'not_completed',
    type: 'status',
  },
  {
    icon: Approval,
    title: 'Requires approval',
    key: 'not_approved',
    type: 'status',
  },
  {
    icon: MissingData,
    title: 'Missing results',
    key: 'missing_results',
    type: 'status',
  },
  {
    icon: Complete,
    title: 'Completed',
    key: 'completed',
    type: 'status',
  },
  {
    icon: Email,
    title: 'Email',
    key: 'email',
    type: 'distributionChannel',
  },
  {
    icon: Push,
    title: 'Push',
    key: 'push_notification',
    type: 'distributionChannel',
  },
]
