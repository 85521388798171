import { ErrorBoundaryPropsWithComponent } from 'react-error-boundary'
import LogRocket from 'logrocket'

import { isProduction } from 'common/helpers/environment'

export function initLogger() {
  try {
    if (isProduction) {
      if (process.env.REACT_APP_LOGROCKET_APP) {
        LogRocket.init(process.env.REACT_APP_LOGROCKET_APP)
      }
    }
  } catch (e) {
    // allow the application to load if logrocket fails to initialize
  }
}

type UserParams = {
  userId: string
  firstName: string
  lastName: string
  email: string
  accountId: string
}

export function identifyUser({
  userId,
  firstName,
  lastName,
  email,
  accountId,
}: UserParams) {
  try {
    LogRocket.identify(userId, {
      fname: firstName,
      lname: lastName,
      email,
      accountId,
    })
  } catch (err) {
    // allow app to run if logrocket fails to identify the user
  }
}

type CaptureExceptionType = {
  (message: string): ErrorBoundaryPropsWithComponent['onError']
}

export const captureException: CaptureExceptionType =
  (message) => (error, errorInfo) => {
    LogRocket.captureException(error, {
      tags: {
        message,
      },
      extra: {
        errorInfo: errorInfo.componentStack,
      },
    })
  }

export default LogRocket
