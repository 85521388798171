/* eslint-disable max-lines */

import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import type { Moment } from 'moment'
import moment from 'moment-timezone'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'
import { showBanner } from 'workflow/common/common.actions'

import Alert from 'common/components/alert'
import Button from 'common/components/button/Button'
import ConfirmationCard from 'common/components/ConfirmationCard'
import DatePickerAntd from 'common/components/DatePicker'
import Input from 'common/components/Input'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import TimePicker from 'common/components/TimePicker'
import Tooltip from 'common/components/Tooltip'
import { Copy as CopyIcon, Time as TimeIcon } from 'common/icons'

import {
  clearMovableInkTestData,
  fetchMovableInkApiKeys,
  updateMovableInkIntegrationData,
} from '../../../Workflow.actions'

import styles from '../Integrations.module.css'

const FormItem = Form.Item

const TOOLTIPS = {
  startDate: `This is the approximate time when Jacquard should start updating 
  results for this experiment.`,
  endDate: `This is the approximate time when Jacquard should stop updating 
  results for this experiment.`,
  clearData: 'Clear Movable Ink test data before the campaign goes live.',
}
const ERROR_MESSAGES = {
  fetchingKeysError: 'Unable to retrieve API keys, please contact support',
  noKeysAvailable: `There are no API keys configured for your account. Please 
  speak to support or your Customer Success Manager.`,
  invalidDateTime: 'Please select a valid start and end date',
  sendDateBeforeEndDate: 'The end date should be later than the start date',
}

export const initialState = {
  selectedApiKey: '',
  startDate: undefined,
  startTime: undefined,
  endDate: undefined,
  endTime: undefined,
  isClearDataConfirmVisible: false,
  formValidationError: '',
  isSaved: false,
  successfullyUpdated: false,
  dataUpdateError: undefined,
}

class MovableInkIntegration extends React.Component<any, any> {
  TimePicker: any

  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const { fetchApiKeys, integrationCampaignData, sendDate } = this.props

    // fetch API Keys for movable ink integration
    fetchApiKeys()

    // get expected send and end date and times
    let startDateTime =
      integrationCampaignData?.integration_data?.expected_start_time
    let endDateTime =
      integrationCampaignData?.integration_data?.expected_end_time

    if (startDateTime && endDateTime) {
      this.setState({ isSaved: true })
    }

    if (typeof startDateTime === 'undefined') {
      startDateTime = moment(sendDate, 'DD-MM-YYYY').format('Y-MM-DD')
      endDateTime = moment(sendDate, 'DD-MM-YYYY')
        .add(4, 'day')
        .format('Y-MM-DD')
    }

    if (moment(startDateTime).isValid()) {
      const startDate = moment(startDateTime).format('Y-MM-DD')
      const startTime = moment(startDateTime).format('HH:mm')
      this.setState({
        startDate,
        startTime,
      })
    }

    if (moment(endDateTime).isValid()) {
      const endDate = moment(endDateTime).format('Y-MM-DD')
      const endTime = moment(endDateTime).format('HH:mm')
      this.setState({
        endDate,
        endTime,
      })
    }
  }

  showApiKeyCopiedTooltip = () => {
    // show tooltip only when a non-empty key was copied
    const { selectedApiKey } = this.state
    if (selectedApiKey) {
      this.setState({
        showCopiedApiKeyToolTip: true,
      })

      setTimeout(() => {
        this.setState({
          showCopiedApiKeyToolTip: false,
        })
      }, 1000)
    }
  }

  updateSelectedApiKey = (value) => {
    this.setState({
      selectedApiKey: value,
    })
  }

  clearTestData = () => {
    const { campaignId, clearTestData, clearingMovableInkTestData } = this.props

    if (!clearingMovableInkTestData) {
      // fetch test data for this movable ink campaign
      clearTestData(campaignId)
    }
  }

  onDateTimeChange = (value, fieldName) => {
    this.setState({
      [fieldName]: value,
    })
  }

  handleSuccessfulUpdate = () => {
    this.setState({ successfullyUpdated: true })
  }

  handleFailedUpdate = (err: string) => {
    this.setState({ dataUpdateError: err })
  }

  updateMovableIntegrationData = () => {
    const { startDate, startTime, endDate, endTime } = this.state
    const { campaignId, updateMovableInkIntegrationData } = this.props
    let formValidationError = ''

    const startDateTime = moment(`${startDate} ${startTime}`)
    const endDateTime = moment(`${endDate} ${endTime}`)
    if (startDateTime.isValid() && endDateTime.isValid()) {
      if (!endDateTime.isAfter(startDateTime)) {
        formValidationError = ERROR_MESSAGES.sendDateBeforeEndDate
      }
    } else {
      formValidationError = ERROR_MESSAGES.invalidDateTime
    }

    this.setState({
      formValidationError,
    })
    // if form data is valid, send it to server
    if (!formValidationError) {
      updateMovableInkIntegrationData(
        campaignId,
        startDateTime.toDate(),
        endDateTime.toDate(),
        this.handleSuccessfulUpdate,
        this.handleFailedUpdate
      )
    }
  }

  disableDate = (current: Moment | null): boolean => {
    const { sendDate } = this.props
    return current ? current < moment(sendDate, 'DD-MM-YYYY') : false
  }

  render() {
    const {
      campaignId,
      fetchingMovableInkApiKeys,
      movableInkApiKeys,
      fetchingMovableInkApiKeysError,
      clearingMovableInkTestData,
      updatingMovableInkIntegrationData,
      showBanner,
    } = this.props

    const numApiKeys = Object.keys(movableInkApiKeys).length

    const {
      selectedApiKey,
      isClearDataConfirmVisible,
      formValidationError,
      startDate,
      startTime,
      endDate,
      endTime,
      successfullyUpdated,
      dataUpdateError,
      isSaved,
    } = this.state

    const copyButtonStyles = `ant-btn-lg w-14 px-4 border-coolGray-100 
    bg-white text-coolGray-400 hover:bg-maroon-50 hover:border-maroon-300
    hover:text-maroon-300`

    const accessTokenOptions: SelectValue[] = [
      ...(movableInkApiKeys.accountV2Token
        ? [
            {
              label: `Account (V2) - ${movableInkApiKeys.accountV2Token}`,
              value: `${movableInkApiKeys.accountV2Token}`,
            },
          ]
        : []),
      ...(movableInkApiKeys.personal
        ? [
            {
              label: `Personal (V1) - ${movableInkApiKeys.personal}`,
              value: `${movableInkApiKeys.personal}`,
            },
          ]
        : []),
      ...(movableInkApiKeys.default
        ? [
            {
              label: `Default (V1) - ${movableInkApiKeys.default}`,
              value: `${movableInkApiKeys.default}`,
            },
          ]
        : []),
    ]

    return (
      <>
        <Drawer.Content>
          <Form>
            <p className="mb-6 font-medium text-coolGray-500">
              Connect your Moveable Ink account with your Jacquard experiment.
            </p>

            {isSaved && (
              <Alert
                type="success"
                className="flex items-center mb-6 font-medium"
              >
                This experiment is currently saved and receiving data from
                Movable Ink.
              </Alert>
            )}

            <FormItem label="Campaign ID:" className="mb-8">
              <div className="flex">
                <Input
                  value={campaignId}
                  className="mr-4"
                  type="text"
                  variant="default"
                />
                <CopyToClipboard
                  text={campaignId}
                  onCopy={() =>
                    showBanner({
                      content: 'Campaign ID copied successfully',
                      type: 'success',
                    })
                  }
                >
                  <Tooltip overlay="Copy campaign ID">
                    <Button
                      className={copyButtonStyles}
                      data-cy="movableInk-copy-button"
                    >
                      <CopyIcon />
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </FormItem>

            <FormItem label="Access token:" className="mb-2">
              <Spinner isSpinning={fetchingMovableInkApiKeys}>
                <div className="flex">
                  <SingleSelect
                    placeholder="Select access token"
                    className="required mr-4 w-69"
                    isSearchable
                    value={selectedApiKey}
                    onChange={(val) => {
                      this.setState({
                        selectedApiKey: val?.value,
                      })
                    }}
                    options={accessTokenOptions}
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                  />
                  {!!numApiKeys && (
                    <CopyToClipboard
                      text={selectedApiKey}
                      onCopy={() =>
                        showBanner({
                          content: 'Access token copied successfully',
                          type: 'success',
                        })
                      }
                    >
                      <Tooltip overlay="Copy selected access token">
                        <Button
                          className={copyButtonStyles}
                          data-cy="movableInk-copy-button"
                        >
                          <CopyIcon />
                        </Button>
                      </Tooltip>
                    </CopyToClipboard>
                  )}
                </div>
                {!!numApiKeys || fetchingMovableInkApiKeys ? (
                  <GuidingText
                    text={`Select a long-life API token from the available ones
            on your account to use in Movable Ink. If one isn't available, 
            message us on live chat to have one set up.`}
                  />
                ) : (
                  <label className="error">
                    {ERROR_MESSAGES.noKeysAvailable}
                  </label>
                )}
              </Spinner>
              {!!fetchingMovableInkApiKeysError && (
                <label className="error">
                  {ERROR_MESSAGES.fetchingKeysError}
                </label>
              )}
            </FormItem>
            <FormItem
              className="required mt-6"
              label="When are you sending this experiment?"
            >
              <div className="flex justify-between">
                <Tooltip
                  overlay={TOOLTIPS.startDate}
                  trigger="focus"
                  placement="left"
                >
                  <DatePickerAntd
                    allowClear={false}
                    disabledDate={this.disableDate}
                    ariaLabel="start-date"
                    value={startDate ? moment(startDate, 'Y-MM-DD') : undefined}
                    onChange={(date: Moment | null) => {
                      if (date) {
                        this.onDateTimeChange(
                          moment(date).format('Y-MM-DD'),
                          'startDate'
                        )
                      }
                    }}
                  />
                </Tooltip>
                <TimePicker
                  allowClear={false}
                  data-test-id="start-time"
                  className={styles.integrationTimePicker}
                  format="HH:mm"
                  minuteStep={15}
                  suffixIcon={<TimeIcon size={6} />}
                  value={startTime ? moment(startTime, 'HH:mm') : undefined}
                  ref={this.TimePicker}
                  onChange={(val: Moment | null) =>
                    this.onDateTimeChange(
                      val ? moment(val, 'HH:mm').format('HH:mm') : '',
                      'startTime'
                    )
                  }
                />
              </div>
              <GuidingText text={TOOLTIPS.startDate} />
            </FormItem>
            <FormItem
              className="mb-8 required"
              label="When will this experiment be completed?"
            >
              <div className="flex justify-between">
                <Tooltip
                  overlay={TOOLTIPS.endDate}
                  trigger="focus"
                  placement="left"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <DatePickerAntd
                    allowClear={false}
                    disabledDate={this.disableDate}
                    data-test-id="end-date"
                    ariaLabel="end-date"
                    value={endDate ? moment(endDate, 'Y-MM-DD') : undefined}
                    onChange={(date: Moment | null) => {
                      if (date) {
                        this.onDateTimeChange(
                          moment(date).format('Y-MM-DD'),
                          'endDate'
                        )
                      }
                    }}
                  />
                </Tooltip>
                <Tooltip
                  overlay={TOOLTIPS.endDate}
                  trigger="focus"
                  placement="left"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <TimePicker
                    allowClear={false}
                    data-test-id="end-time"
                    className={styles.integrationTimePicker}
                    format="HH:mm"
                    minuteStep={15}
                    suffixIcon={<TimeIcon size={6} />}
                    ref={this.TimePicker}
                    value={endTime ? moment(endTime, 'HH:mm') : undefined}
                    onChange={(val: Moment | null) =>
                      this.onDateTimeChange(
                        val ? moment(val, 'HH:mm').format('HH:mm') : '',
                        'endTime'
                      )
                    }
                  />
                </Tooltip>
              </div>
            </FormItem>
            {formValidationError && (
              <label className="error">{formValidationError}</label>
            )}

            {isClearDataConfirmVisible ? (
              <div className="mt-4 mb-4">
                <ConfirmationCard
                  isLoading={clearingMovableInkTestData}
                  isDisabled={false}
                  cardTitle="Slow down tiger!"
                  cardText={
                    <>
                      Are you sure you want to{' '}
                      <span className="font-bold">clear all test data</span>?
                    </>
                  }
                  buttonText="Yip, clear it"
                  rejectButtonText="Noooooo!"
                  onReject={() =>
                    this.setState({
                      isClearDataConfirmVisible: false,
                    })
                  }
                  onSubmit={this.clearTestData}
                />
              </div>
            ) : (
              <div
                className="mt-4 mb-4 p-4 bg-gray-50
          border border-blueGray-300 font-medium"
              >
                <div className="text-coolGray-800 mb-4">Clear test data</div>
                <div className="mb-6 text-coolGray-400">
                  This will clear any data currently saved against this
                  experiment.
                  <span className="text-coolGray-600 font-bold">
                    {' '}
                    Please remove any Movable Ink test data before going live.
                  </span>
                </div>

                <div className="flex flex-wrap justify-end">
                  <Button
                    data-cy="movableInk-clear-test-button"
                    variant="primary"
                    loading={clearingMovableInkTestData}
                    data-test-id="createVariantsBtn"
                    onClick={() =>
                      this.setState({
                        isClearDataConfirmVisible: true,
                      })
                    }
                  >
                    Clear test data
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </Drawer.Content>
        <Drawer.Footer error={dataUpdateError}>
          <Button
            data-cy="movableInk-save-button"
            variant={successfullyUpdated ? 'success' : 'primary'}
            loading={updatingMovableInkIntegrationData}
            onClick={this.updateMovableIntegrationData}
          >
            {successfullyUpdated ? 'Saved' : 'Save'}
          </Button>
        </Drawer.Footer>
      </>
    )
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    fetchApiKeys: () => dispatch(fetchMovableInkApiKeys()),
    clearTestData: (campaignId) =>
      dispatch(clearMovableInkTestData(campaignId)),
    updateMovableInkIntegrationData: (
      campaignId,
      startDateTime,
      endDateTime,
      handleSuccess,
      handleError
      // eslint-disable-next-line max-params
    ) =>
      dispatch(
        updateMovableInkIntegrationData(
          campaignId,
          startDateTime,
          endDateTime,
          handleSuccess,
          handleError
        )
      ),
    showBanner: (config: any) => dispatch(showBanner(config)),
  }
}

export function mapStateToProps(state) {
  const {
    currentCampaignId,
    campaignName,
    projectId,
    fetchingMovableInkApiKeys,
    movableInkApiKeys,
    fetchingMovableInkApiKeysError,
    clearingMovableInkTestData,
    updatingMovableInkIntegrationData,
    campaignData: { campaign_data: integrationCampaignData },
    campaignData: {
      questions: {
        SEND_DATE: { value: sendDate },
      },
    },
  } = state.campaignStates
  return {
    campaignId: currentCampaignId,
    campaignName,
    projectId,
    fetchingMovableInkApiKeys,
    movableInkApiKeys,
    fetchingMovableInkApiKeysError,
    clearingMovableInkTestData,
    updatingMovableInkIntegrationData,
    integrationCampaignData,
    sendDate,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovableInkIntegration)
