import { useEffect, useState } from 'react'
import {
  DistributionChannel,
  DistributionType,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { gql } from 'graphql-request'

import { fetchGraphQL } from 'common/api'
import ErrorPage from 'common/components/error/ErrorPage'
import NewAccount from 'common/components/holdingPages/NewAccount'
import NoResults from 'common/components/holdingPages/NoResults'
import PageContainer from 'common/components/PageContainer'
import Spinner from 'common/components/spinner'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'

import Welcome from './holdingPages/Welcome'
import { ChannelOption, fetchFilters, Filters, ProductOption } from './api'
import Content from './Content'
import Header from './Header'

export type SelectedProduct = DistributionType | undefined

const getCampaignsCount = (accountId: string) => {
  const query = gql`
    query GetAllCampaignsCount($filter: CampaignFilter) {
      data: campaigns(filter: $filter) {
        count
      }
    }
  `

  const variables = {
    filter: { accountId },
  }

  return fetchGraphQL<{ count: number }>({ query, variables })
}

const Performance = () => {
  useDocumentTitle('Reports - Performance | Jacquard')

  const {
    widgetList,
    error: reportError,
    product: stateProduct,
  } = useAppSelector((state) => state.reports)
  const { projects } = useAppSelector((state) => state.campaigns)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [enabledFilters, setEnabledFilters] = useState<Filters[]>([])
  const [products, setProducts] = useState<ProductOption[]>([])
  const [channels, setChannels] = useState<ChannelOption[]>([])
  const [campaignsCount, setCampaignsCount] = useState<number>()
  const [selectedProduct, setSelectedProduct] = useState<SelectedProduct>(
    stateProduct || undefined
  )

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        setHasError(false)
        const result = await fetchFilters(selectedProduct && [selectedProduct])
        setEnabledFilters(result.filters)

        if (result.products.length) {
          setProducts(result.products)
        } else {
          setSelectedProduct(projects[0].distributionType)
        }

        const channelOptions: ChannelOption[] = result.channels.map(
          ({ id, name }) => ({
            id: id.toLowerCase() as DistributionChannel,
            name,
          })
        )

        setChannels(channelOptions)

        if (!selectedProduct) {
          const countResponse = await getCampaignsCount(accountId)
          setCampaignsCount(countResponse.count)
        }
      } catch {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [selectedProduct, projects, accountId])

  const hasResults = !!widgetList
  const hasNoResults = widgetList?.widgets.length === 0

  const getState = () => {
    if (reportError) {
      return 'reportError'
    } else if (campaignsCount === 0) {
      return 'noCampaigns'
    } else if (hasNoResults) {
      return 'noResults'
    } else if (hasResults) {
      return 'hasResults'
    } else {
      return 'welcome'
    }
  }
  const state = getState()

  if (hasError) {
    return <ErrorPage />
  }

  const isInitialLoad = isLoading && !selectedProduct

  return (
    <PageContainer>
      {isInitialLoad ? (
        <Spinner />
      ) : (
        <div className="flex flex-col h-full">
          <Header
            title="Performance"
            isLoadingFilters={isLoading}
            enabledFilters={enabledFilters}
            products={products}
            channels={channels}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
          {
            {
              reportError: <ErrorPage />,
              noCampaigns: <NewAccount />,
              noResults: <NoResults />,
              hasResults: <Content />,
              welcome: <Welcome />,
            }[state]
          }
        </div>
      )}
    </PageContainer>
  )
}

export default Performance
