import { useCallback, useRef } from 'react'
import useWebSocket from 'react-use-websocket'

import tokenFetcher from 'common/auth/tokenFetcher'
import { useAppSelector } from 'common/hooks/redux'
import Logger from 'common/services/Logger'

const REACT_APP_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL as string

export type WsResponseBase = {
  status: 'success' | 'error'
  topics: string[]
}

const useWebsockets = () => {
  const user_id = useAppSelector((state) => state.authStates.user_id)
  const tokenRef = useRef<string>()

  const getSocketUrl = useCallback(async () => {
    tokenRef.current = await tokenFetcher.getAccessToken()
    return REACT_APP_WEBSOCKET_URL
  }, [])

  return useWebSocket(getSocketUrl, {
    share: true,
    queryParams: {
      user_id,
      ...(tokenRef.current && { token: tokenRef.current }),
    },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 2000,
    onError: (e) => {
      Logger.error('Websocket error', e)
    },
  })
}

export default useWebsockets
