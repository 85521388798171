import { useState } from 'react'
import { components, GroupBase, OptionProps, SingleValue } from 'react-select'

import Autocomplete from 'common/components/autcomplete'
import Button from 'common/components/button/Button'
import HighlightText from 'common/components/HighlightText'
import Modal from 'common/components/Modal'
import { buildBreadcrumbs } from 'common/components/nodes/Breadcrumbs'
import useDebounce from 'common/hooks/useDebounce'
import { Node, NodeFilterOption as FilterOption } from 'common/interfaces/nodes'

import { moveAutocompleteCustomStyles } from './filters/FilterStyles'

interface Props {
  isOpen: boolean
  onCancel: () => void
  currentNode: Node
  onClickNext: ({
    nodeId,
    parentNodeId,
  }: {
    nodeId: number
    parentNodeId: number
  }) => void
  nodes: Node[]
}

const containsSearchValue = (text: string, searchValue?: string) => {
  if (!searchValue) {
    return false
  }

  return text
    .replaceAll(' ', '')
    .toLowerCase()
    .includes(searchValue.replaceAll(' ', '').toLowerCase())
}

export const Option = ({
  isSelected,
  children,
  data,
  ...rest
}: OptionProps<FilterOption, false, GroupBase<FilterOption>>) => {
  const { path, highlightedLabel } = data
  return (
    <components.Option isSelected={isSelected} data={data} {...rest}>
      <div
        className="flex items-center px-4 py-2 "
        role="option"
        aria-selected={isSelected}
      >
        <div>
          <div className="font-medium text-coolGray-800 mb-1 ">
            {highlightedLabel}
          </div>
          <div className="font-normal text-coolGray-500 ">{path}</div>
        </div>
      </div>
    </components.Option>
  )
}

const MoveNodesModal = ({
  isOpen,
  onCancel,
  currentNode,
  onClickNext,
  nodes,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string | undefined>('')
  const [targetNodePath, setTargetNodePath] = useState<string>()
  const [options, setOptions] = useState<FilterOption[]>([])
  const [targetNodeId, setTargetNodeId] = useState<string>('')

  const { breadcrumbsString: currentNodePath } = buildBreadcrumbs(
    nodes,
    currentNode.id
  )

  const getOptions = () => {
    const newOptions: FilterOption[] = []

    nodes
      .filter((node) => node.id !== currentNode.id)
      .forEach((node) => {
        if (!containsSearchValue(node.name, searchValue)) {
          return
        }
        const { breadcrumbsString } = buildBreadcrumbs(nodes, node.id)

        newOptions.push({
          label: node.name,
          highlightedLabel: (
            <HighlightText text={node.name} searchValue={searchValue} />
          ),
          value: node.id,
          path: breadcrumbsString
            .substring(0, breadcrumbsString.lastIndexOf('/'))
            .trim(),
        })
      })

    newOptions.sort((a, b) => a.label.localeCompare(b.label))
    setOptions(newOptions)
  }

  const debouncedGetOptions = useDebounce(getOptions, 500)

  const onSearch = (value: string) => {
    if (value === '') {
      setTargetNodePath(undefined)
    }
    setSearchValue(value)
    debouncedGetOptions()
  }

  const onChange = (option: SingleValue<FilterOption>) => {
    if (option) {
      const { value, path, label } = option
      setTargetNodePath(`${path} / ${label}`)
      setTargetNodeId(value)
    }
  }
  return (
    <Modal
      visible={isOpen}
      centered
      closable={false}
      footer={null}
      style={{ maxWidth: 500 }}
    >
      <div className="flex">
        <h1 className="mt-2 text-2xl font-bold text-coolGray-800">
          Move node to...
        </h1>
      </div>

      <div className="mt-4 mb-4 text-lg text-coolGray-500">
        Select the parent node you would like to move this node under. Any
        children of this node will be moved too.
      </div>

      <div>
        <p className="font-medium mb-2"> Node name</p>

        <Autocomplete
          placeholder="Find a node"
          data-cy="nodes-search"
          options={options ? options : []}
          onSearch={onSearch}
          showSearchIcon={false}
          components={{ Option }}
          value={
            searchValue ? { value: searchValue, label: searchValue } : undefined
          }
          customStyles={moveAutocompleteCustomStyles({
            availableOptions:
              searchValue !== undefined && searchValue.length > 0,
          })}
          className="mb-2"
          onChange={onChange}
        />
        {targetNodePath && (
          <>
            <div className="font-normal text-coolGray-500  ml-2">
              Current path: {currentNodePath}
            </div>
            <div className="font-normal text-maroon-500  ml-2">
              New path: {targetNodePath} / {currentNode.name}
            </div>
          </>
        )}
      </div>

      <div className="flex justify-end mt-10">
        <Button
          ghost
          className="mr-4 text-base text-coolGray-400 border border-coolGray-100 "
          onClick={onCancel}
          data-cy="confirm-cancel-button"
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={() =>
            onClickNext({
              nodeId: parseInt(currentNode.id),
              parentNodeId: parseInt(targetNodeId),
            })
          }
          data-cy="move-nodes-next-button"
        >
          Next
        </Button>
      </div>
    </Modal>
  )
}

const MoveNodesModalWithResetState = (props: Props) => (
  <MoveNodesModal key={`${props.isOpen}`} {...props} />
)

export default MoveNodesModalWithResetState
