import { SelectValue } from 'common/components/BaseSelect'
import Footer from 'common/components/layout/Footer'
import { useAppSelector } from 'common/hooks/redux'

import { Mode } from '../store/insightsSlice'

import DateComparison from './dateComparison/DateComparison'
import ProjectComparison from './projectComparison/ProjectComparison'
import BasicReport from './BasicReport'

type Props = {
  project: string
  startDate: string
  endDate: string
  projects: SelectValue[]
}

const Content = ({ project, startDate, endDate, projects }: Props) => {
  const { mode: selectedMode } = useAppSelector((state) => state.insights)

  const getReport = (mode: Mode) => {
    const reportPerMode: Record<Mode, React.ReactElement> = {
      basicReport: (
        <BasicReport
          project={project}
          startDate={startDate}
          endDate={endDate}
        />
      ),
      projectComparison: (
        <ProjectComparison
          startDate={startDate}
          endDate={endDate}
          projects={projects}
        />
      ),
      dateRangeComparison: (
        <DateComparison startDate={startDate} endDate={endDate} />
      ),
    }
    return reportPerMode[mode]
  }

  return (
    <>
      {getReport(selectedMode)}
      <Footer />
    </>
  )
}

export default Content
