import React from 'react'
import * as Highcharts from 'highcharts/highstock'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'
import { yAxisConfig } from 'common/helpers/ui/graph'

export interface Serie {
  data: number[][]
  name: string
}

interface Props {
  data: Serie[]
  xAxisLabel: string
  yAxisLabel: string
  ref: React.RefCallback<ChartInstance>
  pointFormat: string
}

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
})

export default React.forwardRef<ChartInstance, Props>(
  ({ xAxisLabel, yAxisLabel, data, pointFormat }, ref) => {
    const options: Highcharts.Options = {
      title: undefined,
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          theme: {
            stroke: 'none',
          },
        },
      },
      xAxis: {
        title: {
          text: xAxisLabel,
          margin: 24,
          style: {
            fontSize: '14px',
            color: '#1f2937',
            fontWeight: '500',
          },
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      },
      yAxis: {
        ...yAxisConfig,

        offset: 24,
        opposite: true,
        title: {
          text: yAxisLabel,
          margin: 24,
          x: 10,
          rotation: 270,
          style: {
            fontSize: '14px',
            color: '#1f2937',
            fontWeight: '500',
          },
        },
        labels: {
          style: {
            color: '#6b7280',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 0.8,
          },
          padding: 0,
          align: 'right',
          reserveSpace: true,
        },
      },
      chart: {
        type: 'scatter',
        zooming: {
          type: 'xy',
        },
        style: {
          fontFamily: 'Roboto',
        },
        spacing: [10, 0, 10, 0],
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 3,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)',
              },
            },
          },
          tooltip: {
            headerFormat: '',
            pointFormat,
          },
        },
      },
      series: data.map((r) => ({
        type: 'scatter',
        name: r.name,
        data: r.data,
        color: '#901356',
      })),
    }
    return (
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        constructorType="chart"
        options={options}
        immutable={true}
      />
    )
  }
)
