import { FC } from 'react'

import { ArrowLeft } from 'common/icons'
import Link from 'common/typography/Link'

export type SubNavProps = {
  preTitle?: React.ReactNode
  title?: string
  backLink?: string
  children?: React.ReactNode
}

const SubNavBar: FC<SubNavProps> = ({
  title,
  children,
  backLink,
  preTitle,
}) => {
  return (
    <div
      data-cy="subnav"
      className="box-border bg-maroon-700 h-14 border-b border-solid border-coolGray-100 
    flex justify-center items-center"
    >
      <div className="flex justify-between items-center font-medium w-full py-4 px-6 uppercase hover:text-yellow-20 mr-4 0">
        <div className="flex items-center text-white">
          {backLink ? (
            <Link to={backLink}>
              <ArrowLeft
                className="mr-4 text-white hover:text-yellow-200"
                isDefaultColor={false}
              />
            </Link>
          ) : undefined}
          {preTitle}
          <h3 className="text-lg text-white">{title}</h3>
        </div>
        <nav className="flex items-center gap-8 text-sm text-white">
          {children}
        </nav>
      </div>
    </div>
  )
}

export default SubNavBar
