import { useEffect } from 'react'
import { generatePath, Redirect, Route, useLocation } from 'react-router-dom'
import { brandVoicePath } from 'app/navigation/paths'
import { brandVoiceSubroutes } from 'app/navigation/staticSubRoutes.brandVoice'
import CustomSwitch from 'app/router/Switch'

import Spinner from 'common/components/spinner'
import { errorToast } from 'common/components/toastNotification'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { initializePage } from '../brandVoice/store/brandVoiceSlice'

import EmptyBrandVoice from './components/EmptyStyleGuide'
import SubNavigation from './components/SubNavigation'
import Library from './views/Library'

const BrandVoice = () => {
  useDocumentTitle('Brand Voice | Jacquard')
  const dispatch = useAppDispatch()
  const { pathname: currentPath } = useLocation()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const isLoading = useAppSelector((state) => state.brandVoice.isLoading)

  const brandVoices = useAppSelector(
    (state) => state.brandVoice.editedContent.brandVoices
  )

  const brandVoiceUrl = generatePath(brandVoicePath, {
    accountId,
  })

  useEffect(() => {
    dispatch(initializePage()).catch((err) => {
      errorToast('Failed to load Brand Voice')
    })
  }, [dispatch])

  return (
    <div className="flex flex-col h-full overflow-y-auto">
      {isLoading ? (
        <Spinner isSpinning={isLoading} />
      ) : (
        <>
          {currentPath === brandVoiceUrl ? (
            brandVoices.length > 0 ? (
              <Library />
            ) : (
              <EmptyBrandVoice />
            )
          ) : undefined}
          <CustomSwitch>
            {brandVoiceSubroutes.map(({ key, to, component: Component }) => (
              <Route key={key} path={to}>
                <SubNavigation accountId={accountId} />
                <Component />
              </Route>
            ))}
            <Redirect
              exact
              from={brandVoicePath}
              to={generatePath(brandVoicePath, {
                accountId,
              })}
            />
          </CustomSwitch>
        </>
      )}
    </div>
  )
}

export default BrandVoice
