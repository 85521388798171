import { useHistory } from 'react-router'

import { useAppDispatch } from 'common/hooks/redux'
import { hideTemplates } from 'features/futurama/store/contentSlice'

import ContentTemplateCard from '../ContentTemplateCard/ContentTemplateCard'
import { ContentTypesByTab, TemplateInfo } from '../interfaces'

import { TemplatesMode } from './Templates'

type Props = {
  mode: TemplatesMode
  templates: TemplateInfo[]
  contentTypes: ContentTypesByTab
}

const TemplatesGrid = ({ templates, contentTypes, mode }: Props) => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const onTemplateClick = (templateId: string) => {
    history.push(`/content/create/${templateId}`)
    dispatch(hideTemplates())
  }

  return (
    <div>
      {Object.keys(contentTypes).map((type) => {
        const templatesOfType = templates.filter(
          (template) => template.contentType.toLowerCase() === type
        )
        if (templatesOfType.length === 0) {
          return null
        }
        return (
          <div key={type} className="mb-12">
            <h2 className="text-lg font-medium text-coolGray-800 mb-4 capitalize-first">
              {type}
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
              {templatesOfType.map(
                ({
                  id,
                  icon,
                  displayName,
                  description,
                  disabled,
                  contentType,
                }) => (
                  <div
                    key={id}
                    onClick={
                      disabled || mode === 'add'
                        ? undefined
                        : () => onTemplateClick(id)
                    }
                    className={disabled ? '' : 'cursor-pointer'}
                  >
                    <ContentTemplateCard
                      templateId={id}
                      elementName={displayName}
                      iconType={icon}
                      mode={mode}
                      header={displayName}
                      description={description}
                      isFavorited={false}
                      onFavoriteClick={() => alert('not implemented')}
                      disabled={disabled}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TemplatesGrid
