import CreatableSelect, { Options } from 'common/components/CreatableSelect'
import FormItem from 'common/components/FormItem'

import { CommonFieldPropsGeneric, TaggedInputType } from '../interfaces'

type Props = CommonFieldPropsGeneric & {
  value: TaggedInputType['value']
  min: TaggedInputType['min']
  max: TaggedInputType['max']
  options: Options
}

const TaggedInput = ({
  label,
  value,
  name,
  min,
  max,
  options,
  tooltip,
  errorCode,
  isDisabled,
  placeholder,
  onChange,
}: Props) => {
  const ERROR_MESSAGES = {
    required: 'Please select an option.',
    min: `Please select at least ${min} option${min === 1 ? '' : 's'}.`,
  }

  const setValues = (values: Options) => {
    onChange(values.length > 0 ? values.map((val) => val.value) : undefined)
  }

  const values = value
    ? value.map((val) => ({ label: val, value: val }))
    : options

  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      <CreatableSelect
        min={min}
        max={max}
        values={values}
        options={values}
        setValues={setValues}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </FormItem>
  )
}

export default TaggedInput
