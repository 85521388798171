import cx from 'classnames'

type Props = {
  className?: string
  minValue: number
  maxValue: number
  value: number
}

const CharactersCounter = ({ className, minValue, maxValue, value }: Props) => {
  return (
    <div className={className}>
      <span
        className={cx({
          'text-coolGray-400': value === 0,
          'text-red-400': (value > 0 && value < minValue) || value > maxValue,
          'text-green-600': value >= minValue && value < maxValue,
        })}
      >
        {value}
        {value < minValue ? ` of ${minValue} min characters - ` : undefined}
        {value >= minValue && value <= maxValue
          ? ` / ${minValue} min characters - `
          : undefined}
        {value > maxValue ? ` of ${maxValue} max characters - ` : undefined}
      </span>
      <span className="text-coolGray-800">
        {value < minValue ? 'Too little content, add some more.' : undefined}
        {value >= minValue && value <= maxValue
          ? "Wowzers, great content. Let's do this!"
          : undefined}
        {value > maxValue ? 'Too much content, remove a little.' : undefined}
      </span>
    </div>
  )
}

export default CharactersCounter
