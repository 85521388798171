import React, { ChangeEvent, useEffect, useState } from 'react'
import cx from 'classnames'
import { showBanner } from 'workflow/common/common.actions'
import apiUtil from 'workflow/utils/Api'

import Button from 'common/components/button/Button'
import Input from 'common/components/Input'
import Modal from 'common/components/Modal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { SmallTick } from 'common/icons'
import { Project } from 'features/campaigns/api/interface'
import {
  getActiveProjects,
  getSelectedProject,
  removeSelectedFromCampaigns,
} from 'features/campaigns/store/campaignSlice'

interface Props {
  open: boolean
  close: () => void
}

const MoveModal = ({ open, close }: Props) => {
  const selectedCampaigns = useAppSelector(
    (state) => state.campaigns.selectedCampaigns
  )
  const selectedProject = useAppSelector(getSelectedProject)
  const projects = useAppSelector(getActiveProjects)
  const dispatch = useAppDispatch()

  const compatibleProjects = projects.filter(
    ({ id, type, distributionChannel, assignedGrammars }) => {
      const doesIdMatch = id === selectedProject?.id
      const doesTypeMatch = type === selectedProject?.type
      const doesChannelMatch =
        distributionChannel === selectedProject?.distributionChannel
      const areGrammarsCompatible = assignedGrammars.every((val) =>
        selectedProject?.assignedGrammars.includes(val)
      )

      return (
        !doesIdMatch &&
        (doesTypeMatch || doesChannelMatch) &&
        areGrammarsCompatible
      )
    }
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [projectOptions, setProjectOptions] =
    useState<Project[] | undefined>(compatibleProjects)
  const [selectedMoveProject, setSelectedMoveProject] =
    useState<string | undefined>(undefined)

  useEffect(() => {
    if (search.length) {
      const filteredProjects = compatibleProjects.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )

      setProjectOptions(filteredProjects)
    } else {
      setProjectOptions(compatibleProjects)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const moveCampaigns = () => {
    setIsLoading(true)
    const count = selectedCampaigns.length

    apiUtil('campaigns/move', {
      method: 'POST',
      body: {
        campaign_id: selectedCampaigns,
        new_project_id: selectedMoveProject,
        old_project_id: selectedProject?.id,
      },
    })
      .then(() => {
        dispatch(
          showBanner({
            content: `${count} ${
              count === 1 ? 'Experiment' : 'Experiments'
            } successfully moved`,
            type: 'success',
          })
        )
        dispatch(removeSelectedFromCampaigns())
        setSelectedMoveProject(undefined)
        close()
      })
      .catch((error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Modal
      visible={true}
      centered
      closable={false}
      footer={null}
      bodyStyle={{ padding: 0, width: 520 }}
    >
      <div data-cy="move-campaigns-modal" className="pt-6 px-6">
        <div className="flex">
          <h1 className="mt-2 text-2xl font-bold text-coolGray-800">
            Move experiments
          </h1>
        </div>

        <div className="mt-4 mb-1 text-coolGray-700">
          Where would you like to move your experiments to?
        </div>

        <Input
          type="search"
          value={search}
          placeholder="Search"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setSearch(event.target.value)
          }
          clear={() => {
            setSearch('')
          }}
          className={`w-full px-12 py-2 text-sm text-coolGray-800 
              placeholder-sm placeholder-coolGray-400 border font-medium
              border-coolGray-300 bg-coolGray-100 focus:outline-none
              focus:placeholder-maroon-300 focus:border-maroon-500 
              focus:bg-maroon-300 focus:bg-opacity-10`}
          iconClassName="opacity-50"
          data-cy="move-project-search"
        />

        <ul className="max-h-52 overflow-y-auto my-4">
          {projectOptions?.length
            ? projectOptions.map(({ id, name }) => {
                const isSelected = id === selectedMoveProject

                return (
                  <li
                    key={id}
                    onClick={() => setSelectedMoveProject(id)}
                    className={cx(
                      `h-10 flex items-center justify-between px-5 py-2 text-sm 
                  font-rob text-coolGray-800 hover:bg-coolGray-100 
                  cursor-pointer`,
                      {
                        'text-maroon-500': isSelected,
                      }
                    )}
                    data-cy="move-project-option"
                  >
                    <span>{name}</span>
                    {isSelected && <SmallTick state="selected" />}
                  </li>
                )
              })
            : undefined}

          {!projectOptions?.length && search.length ? (
            <div className="text-coolGray-800">No projects match search</div>
          ) : undefined}
          {!compatibleProjects?.length ? (
            <div className="text-coolGray-800">No compatible projects</div>
          ) : undefined}
        </ul>
      </div>

      <div className="flex justify-end p-6 shadow-top">
        <Button
          ghost
          className="mr-4"
          onClick={() => {
            setSelectedMoveProject(undefined)
            close()
          }}
          data-cy="move-campaigns-cancel"
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            moveCampaigns()
          }}
          loading={isLoading}
          disabled={!selectedMoveProject}
          data-cy="move-campaigns-confirm"
        >
          Go for it, move them
        </Button>
      </div>
    </Modal>
  )
}

export default MoveModal
