import { useMutation, useQueryClient } from '@tanstack/react-query'

import { contentKeys } from '../queryKeys'
import { moveNode } from '..'

const useMoveNodeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(moveNode, {
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.nodes())
    },
  })
}

export default useMoveNodeMutation
