import { CampaignData } from '@phrasee/phrasee-typings/Graphql/interfaces'
import moment from 'moment'

/**
 * Generate the description text for the test mode banner
 * @param { boolean } isScheduled - has the campaign been scheduled yet
 * @param { CampaignData } campaignData - the campaign data from the campaign object in mongo
 * @returns { string } - the description text for the test mode banner
 */
export const getTestModeDescription = (
  isScheduled: boolean,
  campaignData: CampaignData | undefined
): string => {
  let testModeDescription =
    'Test mode not available until the campaign has been scheduled.'
  if (
    isScheduled &&
    moment(moment()).isBefore(moment(campaignData?.campaign_start_time))
  ) {
    testModeDescription =
      'This experiment is now in test mode. Data is being received, but optimization is off until the start date when test mode will be automatically turned off.'
  } else if (
    isScheduled &&
    !campaignData?.bandit_data?.dynamic_optimization_paused
  ) {
    testModeDescription =
      'This will place this experiment into test mode. Data will be received, but optimization will be off until you turn test mode off.'
  } else if (
    isScheduled &&
    campaignData?.bandit_data?.dynamic_optimization_paused
  ) {
    testModeDescription =
      'This experiment is now in test mode. Data is being received, but optimization is off.'
  }
  return testModeDescription
}
