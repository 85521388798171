import { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import cx from 'classnames'

import Button from 'common/components/button'
import CheckboxComponent from 'common/components/checkbox/Checkbox'
import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import Modal from 'common/components/Modal'
import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner/Spinner'
import Table, { Column } from 'common/components/table'
import { errorToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'
import { ReactComponent as RemoveIcon } from 'common/icons/remove/error.svg'
import { PrivacyRegion } from 'features/accounts/api'
import useDataIngestionWebsocketSubscription from 'features/admin/dataSources/useDataIngestionWebsocketSubscription'

import useGetAccountQuery from '../api/queries/useGetAccountQuery'

import UploadDisclaimer from './UploadDisclaimer'

type Props = {
  isOpen: boolean
  fileName: string
  data: string[][]
  headers: string[]
  onCancel: () => void
  onImport: (values: {
    fileName: string
    customerIdColumn: string
    columnsToExclude: string[]
    privacyRegion: PrivacyRegion
  }) => void
}

const validateFileName = (value: string | undefined) =>
  value?.trim() === undefined || value.trim().length === 0
    ? 'Required'
    : undefined

const validateCustomerIdColumn = (value: string | undefined) =>
  value === '' ? 'Required' : undefined

const validatePrivacyRegion = (value: PrivacyRegion | undefined) =>
  value === undefined ? 'Required' : undefined

const CsvCustomerPreview = ({
  isOpen,
  fileName,
  data,
  headers,
  onCancel,
  onImport,
}: Props) => {
  useDataIngestionWebsocketSubscription('customer')
  const [columnsToExclude, setColumnsToExclude] = useState<string[]>([])
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('')
  const [isRegionConfirmed, setIsRegionConfirmed] = useState(false)
  const [privacyRegion, setPrivacyRegion] =
    useState<PrivacyRegion | undefined>(undefined)
  const [hasShownErrorToast, setHasShownErrorToast] = useState(false)

  const handleSave = (values: {
    fileName: string
    customerIdColumn: string
    columnsToExclude: string[]
    privacyRegion: PrivacyRegion
  }) => {
    onImport(values)
  }

  const accountQuery = useGetAccountQuery()

  useEffect(() => {
    if (accountQuery.isError && !hasShownErrorToast) {
      const errorMessage =
        accountQuery.error instanceof Error
          ? accountQuery.error.message
          : 'Could not retrieve privacy regions, please try again'
      errorToast(errorMessage, { autoClose: false })
      setHasShownErrorToast(true)
    }

    if (!accountQuery.isError) {
      setHasShownErrorToast(false)
    }
  }, [accountQuery, hasShownErrorToast])

  useEffect(() => {
    accountQuery.data?.length === 1 &&
      setPrivacyRegion(accountQuery.data[0].value)
  }, [accountQuery])

  const handleColumnExclude = (column: string) => {
    setColumnsToExclude((prevState) => {
      if (prevState.includes(column)) {
        return prevState.filter((excludedColumn) => excludedColumn !== column)
      }
      return [...prevState, column]
    })
  }

  const columns: Column[] = headers.map((value) => {
    const isDisabled = columnsToExclude.includes(value)

    return {
      Header: value,
      accessor: value,
      width: 200,
      isDisabled,
      columnAction:
        value !== selectedCustomerId ? (
          <Tooltip
            overlay={
              isDisabled
                ? 'Excluded column from import / use'
                : 'Exclude column from import / use'
            }
            placement="top"
          >
            <Button
              variant="icon"
              data-cy="exclude-column-button"
              onClick={(event) => {
                event.stopPropagation()
                handleColumnExclude(value)
              }}
            >
              <RemoveIcon
                height={16}
                width={16}
                className={cx('group-hover:visible hover:text-maroon-300', {
                  invisible: !isDisabled,
                  'text-maroon-300': isDisabled,
                })}
              />
            </Button>
          </Tooltip>
        ) : null,
    }
  })

  return (
    <Form<{
      fileName: string
      customerIdColumn: string
      columnsToExclude: string[]
      privacyRegion: PrivacyRegion
    }>
      onSubmit={handleSave}
      initialValues={{
        fileName: fileName.substring(0, fileName.lastIndexOf('.')) || fileName,
        columnsToExclude: columnsToExclude,
        customerIdColumn: selectedCustomerId,
        privacyRegion,
      }}
      render={({ handleSubmit, hasValidationErrors }) => {
        return (
          <Modal
            visible={isOpen}
            centered
            onClose={() => {
              onCancel()
            }}
            isResponsive
            className="block"
            style={{ maxWidth: '80vw' }}
            footer={
              <div className="my-3">
                <Button
                  data-cy="cancel-button"
                  className="mr-4 pointer-events-auto"
                  ghost
                  onClick={() => {
                    onCancel()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={(event) => {
                    handleSubmit(event)
                  }}
                  disabled={
                    hasValidationErrors || !isRegionConfirmed || !privacyRegion
                  }
                >
                  Import
                </Button>
              </div>
            }
          >
            <Spinner isSpinning={accountQuery.isLoading}>
              <div className="text-2xl flex flex-grow-0 flex-row gap-4 mb-6">
                <div className="font-bold">{fileName}</div>
              </div>

              <form onSubmit={handleSubmit} className="text-sm flex gap-6">
                <Field<string | undefined>
                  name="fileName"
                  validate={validateFileName}
                  render={({ input, meta }) => (
                    <FormItem
                      className="max-w-100"
                      label="Data source name"
                      guidingText="This will be displayed in the data source table."
                      htmlFor="fileName"
                      error={meta.error}
                    >
                      <Input
                        type="text"
                        id="fileName"
                        variant="default"
                        className="text-coolGray-800"
                        value={input.value}
                        name={input.name}
                        onChange={input.onChange}
                        maxLength={50}
                        required
                      />
                    </FormItem>
                  )}
                />
                <Field<string>
                  name="customerIdColumn"
                  validate={() => validateCustomerIdColumn(selectedCustomerId)}
                  render={({ meta }) => {
                    return (
                      <FormItem
                        label="Customer ID column"
                        htmlFor="customerIdColumn"
                        className="max-w-75"
                        error={meta.error}
                      >
                        <SingleSelect
                          id="customerIdColumn"
                          onChange={(val) => {
                            val !== null && setSelectedCustomerId(val.value)
                          }}
                          value={selectedCustomerId}
                          isSearchable={true}
                          required
                          options={headers
                            .filter(
                              (header) => !columnsToExclude.includes(header)
                            )
                            .map((value) => {
                              return {
                                label: value,
                                value,
                              }
                            })}
                        />
                      </FormItem>
                    )
                  }}
                />
              </form>

              <Table columns={columns} data={data} />
              <div>
                <Field<string>
                  name="privacyRegion"
                  validate={() => validatePrivacyRegion(privacyRegion)}
                  render={({ meta }) => {
                    return (
                      <FormItem
                        label="Region"
                        htmlFor="privacyRegion"
                        className="max-w-75 mt-6 mb-4"
                        error={meta.error}
                      >
                        <SingleSelect
                          placeholder="Select your privacy region"
                          value={privacyRegion}
                          options={accountQuery.data}
                          onChange={(value) =>
                            value &&
                            setPrivacyRegion(value?.value as PrivacyRegion)
                          }
                        />
                      </FormItem>
                    )
                  }}
                />
                <div className="flex items-center">
                  <CheckboxComponent
                    isChecked={isRegionConfirmed}
                    onChange={() => setIsRegionConfirmed(!isRegionConfirmed)}
                    isDisabled={!privacyRegion}
                  />
                  <UploadDisclaimer />
                </div>
              </div>
            </Spinner>
          </Modal>
        )
      }}
    />
  )
}

const CsvCustomerPreviewWithResetState = (props: Props) => (
  <CsvCustomerPreview key={`${props.isOpen}`} {...props} />
)
export default CsvCustomerPreviewWithResetState
