import cx from 'classnames'

type Props = {
  title: string
  content: string | JSX.Element
  className?: string
  hasOutline?: boolean
  variant?: 'highlighted' | 'default'
}

const InsightsCard = ({
  title,
  className,
  content,
  variant = 'default',
  hasOutline = false,
}: Props) => {
  return (
    <div className="py-3" role="article" aria-labelledby={title}>
      <span className="text-xs font-medium leading-4 mb-2 block">{title}</span>
      <div
        className={cx(
          'relative flex',
          {
            'border shadow-sm p-2.5 px-4': hasOutline,
            'border-blue-600 bg-blue-100 ':
              variant === 'highlighted' && hasOutline,
            'border-coolGray-300 ': variant === 'default' && hasOutline,
          },
          className
        )}
      >
        {content}
      </div>
    </div>
  )
}

export default InsightsCard
