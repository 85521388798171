//TODO replace this file and use src/common/api.ts

import { Store } from 'redux/store'

import tokenFetcher from 'common/auth/tokenFetcher'
import { isTest as isTestingEnv } from 'common/helpers/environment'

const DENTALPLAN_API_URL = process.env.REACT_APP_END_POINT
const CONNECT_API_URL = process.env.REACT_APP_CONNECT_END_POINT
const CONNECT_API_URL_INTERNAL =
  process.env.REACT_APP_CONNECT_INTERNAL_END_POINT

let store: Store | undefined = undefined

export function injectStoreToFetchApi(_store: Store) {
  store = _store
}

// Custom API error to throw
function ApiError(error: any) {
  let response
  // We are trying to parse response
  try {
    response = JSON.parse(error)
  } catch (e) {
    response = error
  }
  return {
    message: response?.message,
    status: response?.status,
    data: response?.data,
    name: response?.name,
  }
}

function ApiSuccess(responseData: any) {
  return responseData.data
    ? {
        message: responseData.message,
        status: responseData.status,
        data: responseData.data,
      }
    : responseData
}

// API wrapper function
const ApiUtil = async (
  path: string,
  userOptions: any = {},
  apiOptions: {
    useConnectApi?: boolean
    internal?: boolean
    noContentType?: boolean
    noRedirectOnUnauthorised?: boolean
  } = {
    useConnectApi: false,
    internal: false,
    noContentType: false,
  }
) => {
  const token: string = await tokenFetcher.getAccessToken()

  // Define default options
  const defaultOptions = {
    mode: 'cors',
    cache: 'default',
  }

  // Define default headers
  const defaultHeaders = {
    ...(!apiOptions.noContentType && { 'Content-Type': 'application/json' }),
    // Accept: 'application/json',
  }

  const options = {
    // Merge options
    ...defaultOptions,
    ...userOptions,
    // Merge headers
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
    },
  }

  // Build Url
  const connectUrl = apiOptions.internal
    ? CONNECT_API_URL_INTERNAL
    : CONNECT_API_URL
  const url = `${
    apiOptions.useConnectApi ? connectUrl : DENTALPLAN_API_URL
  }/${path}`

  // Detect is we are uploading a file
  const isFile = typeof window !== 'undefined' && options.body instanceof File
  // Stringify JSON data
  // If body is not a file
  if (
    options.body &&
    typeof options.body === 'object' &&
    !isFile &&
    !apiOptions.noContentType &&
    !isTestingEnv
  ) {
    if (!options?.body?.token) {
      // this should not be needed but some endpoints are not using
      // the Authorization header but pass the token in the payload
      options.body.token = token
    }
    options.body = JSON.stringify(options.body)
  }

  if (store) {
    options.headers = {
      ...options.headers,
      'x-account-id': store.getState().authStates.accountId,
    }
  }

  const optionsWithHeader = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  }

  // Variable which will be used for storing response
  let response: any = null
  return (
    fetch(url, optionsWithHeader)
      .then((responseObject) => {
        // Saving response for later use in lower scopes
        response = responseObject
        // exclude node.js test environment
        const isCsvContent =
          !isTestingEnv &&
          response?.headers?.get('content-type').toString().includes('text')
        if (
          [401, 403].includes(response.status) &&
          !apiOptions.noRedirectOnUnauthorised
        ) {
          window.location.href = '/401'
        }
        if (response.status < 200 || response.status >= 300 || isCsvContent) {
          // Get response as text
          return response.text()
        }
        // Get response as json
        return response.json()
      })
      // "parsedResponse" will be either text or javascript object depending if
      // "response.text()" or "response.json()" got called in the upper scope
      .then((parsedResponse) => {
        // Check for HTTP error codes
        if (response.status < 200 || response.status >= 300) {
          // Throw error
          throw parsedResponse
        }
        // Request succeeded
        return ApiSuccess(parsedResponse)
      })
      .catch((error) => {
        throw ApiError(error)
      })
  )
}

export default ApiUtil
