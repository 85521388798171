import { Maybe } from 'graphql/jsutils/Maybe'

// Used to narrow down the return type of find() from a discriminated union array
export function discriminate<
  K extends PropertyKey,
  V extends string | number | boolean
>(discriminantKey: K, discriminantValue: V) {
  return <T extends Record<K, any>>(
    obj: T & Record<K, V extends T[K] ? T[K] : V>
  ): obj is Extract<T, Record<K, V>> =>
    obj[discriminantKey] === discriminantValue
}

// based on https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
export function removeMaybe<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

//based on https://stackoverflow.com/questions/66989605/convert-maybestring-to-string
export const removeMaybeAsList = <T>(items: Maybe<T>[]): T[] =>
  items.filter((x) => !!x) as T[]
