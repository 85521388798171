import { FC } from 'react'

type Props = {
  title: string
  isOptional?: boolean
  subtitle?: string
}

const Title: FC<Props> = ({ title, subtitle, isOptional = false }) => {
  return (
    <>
      <div className="mb-2 text-xl text-coolGray-800 font-medium">
        {title}
        {isOptional && (
          <span className="text-sm text-coolGray-500"> (Optional)</span>
        )}
      </div>
      {subtitle && <div className="text-coolGray-500">{subtitle}</div>}
    </>
  )
}

export default Title
