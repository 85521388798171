import { useMemo } from 'react'
import camelCase from 'lodash/camelCase'

import { useAppSelector } from 'common/hooks/redux'

import { getDetailsSectionFields } from './detailsSectionUtils'

const DetailsSection = () => {
  const typeOfIntegration = useAppSelector(
    (state) => state.campaignStates.typeOfIntegration
  )

  const campaign = useAppSelector((state) => state.campaignStates)

  const detailsSectionFields = useMemo(() => {
    const mergedData = {
      ...campaign.campaignData,
      typeOfIntegration,
    }
    return getDetailsSectionFields(mergedData)
  }, [campaign, typeOfIntegration])

  return (
    <div aria-label="Details Information" data-testid="detailsSectionContainer">
      <p className="mb-2 uppercase font-medium text-sm text-base-700">
        Details
      </p>
      {detailsSectionFields.map(({ label, value }) => (
        <div
          className="flex my-0.25 w-full text-sm font-light text-base-700"
          key={camelCase(label)}
          data-testid={`${camelCase(`${camelCase(label)}detailsSectionField`)}`}
        >
          <span className="w-2/5">{`${label}:`}</span>
          <span className="ml-4 w-3/5">{value}</span>
        </div>
      ))}
      <hr className="border-dashed my-4 border-coolGray-300" />
    </div>
  )
}

export default DetailsSection
