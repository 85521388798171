import { generatePath, Link } from 'react-router-dom'
import { clickNavItem } from 'app/navigation/navigationSlice'
import { selectedCampaignPath } from 'app/navigation/paths'
import cx from 'classnames'
import { getQuestions } from 'workflow/Workflow.actions'

import Alert from 'common/components/alert'
import Avatar from 'common/components/Avatar'
import Tooltip from 'common/components/Tooltip'
import { formatDate } from 'common/helpers/date'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Alert as AlertIcon, Checkbox, CreatedDate, Sent } from 'common/icons'
import {
  selectCampaign,
  toggleCheckedCampaign,
} from 'features/campaigns/store/campaignSlice'
import { AvatarColor } from 'features/profile/AvatarColorPicker'

import { Campaign } from '../../api/interface'

import ProjectName from './ProjectName'

interface Props {
  campaign: Campaign
  color: AvatarColor
  isSelected: boolean
}

export const getAcronym = (str: string): string => {
  return str
    .split(/\s/)
    .reduce(
      (response: string, word: string) => (response += word.slice(0, 1)),
      ''
    )
    .toUpperCase()
}

const CampaignNavItem = ({ campaign, color, isSelected }: Props) => {
  const dispatch = useAppDispatch()
  const isEditing = useAppSelector((state) => state.campaigns.isEditing)
  const selectedCampaigns = useAppSelector(
    (state) => state.campaigns.selectedCampaigns
  )
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const {
    id,
    name,
    created,
    sent,
    status,
    user,
    grammarId,
    projectName,
    isMismatchingConfiguration,
  } = campaign
  const handleCheckboxChange = (e) => {
    dispatch(toggleCheckedCampaign(id))
  }

  const displaySelectedStyle = isSelected && !isEditing

  const navItem = (
    <div className="flex-grow" data-cy="campaign-nav-item-content">
      {isMismatchingConfiguration && (
        <Alert type="info" className="mb-2 flex items-center">
          <AlertIcon
            isDefaultColor={false}
            className="mr-1 text-black"
            size={4}
          />
          <div>Newest project settings not applied</div>
        </Alert>
      )}
      <div className="flex">
        <div className="flex-grow min-w-0">
          <ProjectName name={projectName} />

          <div
            data-cy="campaign-nav-item-name"
            className={cx(
              `max-w-16 mr-2 mb-2 text-base tracking-tight line-clamp-4 
              break-words`,
              { 'text-coolGray-800': !displaySelectedStyle },
              {
                'text-coolGray-800 group-hover:text-coolGray-800':
                  displaySelectedStyle,
              }
            )}
          >
            {name}
          </div>
        </div>
        <div>
          {user?.firstName && user?.lastName && (
            <Tooltip
              overlay={`${user.firstName} ${user.lastName}`}
              placement="left"
            >
              <Avatar
                firstName={user?.firstName}
                lastName={user?.lastName}
                color={color}
                className="w-6 h-6 text-xs"
                data-cy="campaign-nav-item-creator"
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div
        className={cx('flex items-center text-xs text-coolGray-400', {
          'text-coolGray-400 group-hover:text-coolGray-400':
            displaySelectedStyle,
        })}
      >
        <div className="flex items-center">
          <CreatedDate size={4} />
          <span data-cy="campaign-nav-item-created-date" className="ml-2">
            {formatDate(created)}
          </span>
        </div>
        {sent && (
          <div className="flex items-center ml-4">
            <Sent size={4} />
            <span data-cy="campaign-nav-item-sent-date" className="ml-2">
              {formatDate(sent)}
            </span>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div
      className={cx('px-6 py-4 border-b border-coolGray-200', {
        flex: isEditing,
        'hover:bg-maroon-50': !isEditing,
        'bg-maroon-50 hover:bg-maroon-50 border-maroon-500':
          displaySelectedStyle,
      })}
      data-cy="campaign-nav-item"
    >
      {isEditing && (
        <div
          data-cy="campaign-nav-item-checkbox"
          onClick={handleCheckboxChange}
          className="mr-4 cursor-pointer"
        >
          <Checkbox
            state={selectedCampaigns.includes(id) ? 'selected' : 'default'}
          />
        </div>
      )}

      {isEditing ? (
        navItem
      ) : (
        <Link
          to={generatePath(selectedCampaignPath, { accountId, campaignId: id })}
          className="block box-border group flex-grow"
          onClick={() => {
            dispatch(clickNavItem())
            !isEditing && dispatch(selectCampaign(id))
            !isEditing &&
              status === 'not_completed' &&
              dispatch(getQuestions(id, grammarId))
          }}
        >
          {navItem}
        </Link>
      )}
    </div>
  )
}

export default CampaignNavItem
