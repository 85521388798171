import { Content } from '@phrasee/phrasee-typings'
import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getContent } from '../api'
import { contentKeys } from '../queryKeys'

// TODO: remove this when Content type gets updated
export type ContentWithProgress = Content & {
  approval_progress:
    | 'approval-ready'
    | 'awaiting-approval'
    | 'approved'
    | 'rejected'
}

const useGetContentQuery = <T = ContentWithProgress>(
  contentId: string | undefined,
  {
    select,
  }: {
    select?: (data) => T
  } = {}
) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getContentQuery = useQuery(
    contentKeys.content(contentId!),
    () => getContent({ contentId: contentId!, accountId }),
    {
      staleTime: Infinity,
      enabled: !!contentId,
      select,
    }
  )

  return getContentQuery
}

export default useGetContentQuery
