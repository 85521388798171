import { useQuery } from '@tanstack/react-query'

import { fetchAllBrandTones } from '../api'
import { brandToneKeys } from '../queryKeys'

const useGetAllBrandTonesQuery = () => {
  const getBrandTonesQuery = useQuery(brandToneKeys.list(), fetchAllBrandTones)
  return getBrandTonesQuery
}

export default useGetAllBrandTonesQuery
