import { FC, useEffect, useState } from 'react'

import Modal from 'common/components/Modal'
import { errorToast } from 'common/components/toastNotification'
import { Topic } from 'common/components/topics/interfaces'
import { useAppSelector } from 'common/hooks/redux'
import { getReplacers } from 'features/campaigns/create/contentGeneration/api'

import AddSpecifics from './steps/addSpecifics'
import SelectTopics from './steps/selectTopics'

const getNodeIds = (topics: Topic[]): string[] =>
  topics
    .filter(({ type }) => type === 'topic')
    .map(({ id }) => id.split('-')[1])

type Step = 'nodes' | 'replacers'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AddTopicModal: FC<Props> = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState<Step>('nodes')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [replacers, setReplacers] = useState<Topic[]>()

  const currentTopics = useAppSelector((state) => state.topics.topics)

  useEffect(() => {
    if (currentStep === 'nodes' || isOpen) {
      setSearchTerm('')
    }
  }, [currentStep, isOpen])

  useEffect(() => {
    // don't fetch when changing replacers on step 2
    if (currentStep === 'nodes' && isOpen) {
      ;(async () => {
        setIsLoading(true)

        try {
          const result = await getReplacers(getNodeIds(currentTopics))

          setReplacers(result)
        } catch (error) {
          errorToast('Failed to load replacers')
        } finally {
          setIsLoading(false)
        }
      })()
    }
  }, [currentStep, isOpen, currentTopics])

  const onDoneClick = () => {
    if (replacers?.length) {
      setCurrentStep('replacers')
    } else {
      onClose()
    }
  }

  const resetState = () => {
    onClose()
    setCurrentStep('nodes')
    setSearchTerm('')
  }

  return (
    <Modal
      visible={isOpen}
      footer={null}
      size="large"
      destroyOnClose
      onClose={onClose}
    >
      {
        {
          nodes: (
            <SelectTopics
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              onDoneClick={onDoneClick}
            />
          ),
          replacers: (
            <AddSpecifics
              isLoading={isLoading}
              replacers={replacers}
              onPreviousClick={() => setCurrentStep('nodes')}
              onFinishedClick={resetState}
            />
          ),
        }[currentStep]
      }
    </Modal>
  )
}

export default AddTopicModal
