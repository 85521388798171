import { useCallback } from 'react'

import { TeamWithColour } from 'common/api/teamsApi'
import Button from 'common/components/button/Button'
import Modal from 'common/components/Modal'
import { SmallTick } from 'common/icons'

import style from './ApprovalRequestSentModal.module.css'

interface ApprovalRequestSentModalProps {
  isVisible: boolean
  updateIsVisible: (isVisible: boolean) => void
  teams: TeamWithColour[]
}

const ApprovalRequestSentModal = ({
  isVisible,
  updateIsVisible,
  teams,
}: ApprovalRequestSentModalProps) => {
  const handleCloseModal = useCallback(
    () => updateIsVisible(false),
    [updateIsVisible]
  )

  return (
    <Modal
      className={style.modal}
      maskClosable
      centered
      bodyStyle={{
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '0px',
      }}
      data-cy="approval-request-sent-modal"
      title={
        <h1 className="mx-2 my-2 text-2xl font-rob text-coolGray-800 flex items-center">
          Approval sent
        </h1>
      }
      wrapClassName="center-approval-request-sent-modal"
      visible={isVisible}
      onClose={handleCloseModal}
      footer={
        <div className="flex flex-row-reverse">
          <Button
            className="ml-4"
            data-cy="close-approval-request-send-modal-button"
            variant="primary"
            onClick={handleCloseModal}
          >
            Great, all done
          </Button>
        </div>
      }
    >
      <div className="flex flex-col">
        <span className="pb-4">
          <span className="font-medium text-coolGray-400">
            We have sent an email to request approval for your optimized
            variant(s) to the team(s):{' '}
          </span>
          <span className="font-medium text-maroon-300 break-words">
            {teams.map((team) => team.name).join(', ')}
          </span>
          <span className="font-medium text-coolGray-400">.</span>
        </span>
        <div className="font-medium py-2 text-coolGray-800">
          Included in the email:
        </div>
        <div className="flex items-center p-1 font-medium">
          <span>
            <SmallTick className="text-maroon-300 mr-2" />
          </span>
          <span className="text-coolGray-800">CampaignName</span>
        </div>
        <div className="flex items-center p-1 font-medium">
          <span>
            <SmallTick className="text-maroon-300 mr-2" />
          </span>
          <span className="text-coolGray-800">CampaignID</span>
        </div>
        <div className="flex items-center p-1 font-medium">
          <span>
            <SmallTick className="text-maroon-300 mr-2" />
          </span>
          <span className="text-coolGray-800">All variants being tested</span>
        </div>
        <span className="py-4 text-coolGray-400">
          The approver(s) will need to approve the language and reply directly
          to your email. Once they have approved the language, click on the
          "Approve and continue" button in the experiment's Language Approval
          tab.
        </span>
      </div>
    </Modal>
  )
}

export default ApprovalRequestSentModal
