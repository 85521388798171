import { useState } from 'react'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button/Button'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import Widget from 'common/components/Widget'
import { useAppSelector } from 'common/hooks/redux'
import { Favorite } from 'common/icons'

import { TemplatesMode } from '../components/Templates'

import SelectBox from './SelectBox'

export type ContentTemplateCardProps = {
  templateId: string
  iconType: string
  elementName: string
  mode: TemplatesMode
  header: string
  description: string
  meta?: string
  isFavorited: boolean
  onFavoriteClick: () => void
  disabled?: boolean
}

const ContentTemplateCard = ({
  templateId,
  elementName,
  iconType,
  mode,
  header,
  description,
  meta,
  isFavorited,
  onFavoriteClick,
  disabled,
}: ContentTemplateCardProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const flags = useFlags()

  const templatesToAdd = useAppSelector((state) => state.content.templatesToAdd)
  const templateToAdd = templatesToAdd.find(
    (template) => template.id === templateId
  )

  const shouldShowSelectBox =
    !disabled &&
    mode === 'add' &&
    ((templateToAdd && templateToAdd.count > 0) || isHovered)

  return (
    <div
      role="presentation"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Widget
        type={isHovered ? 'raised' : 'basic'}
        className={cx('h-full', {
          'border-maroon-500': shouldShowSelectBox,
        })}
      >
        <div className="flex mb-4 justify-between items-center">
          <div className="flex justify-center">
            <ContentTypeBubble name={elementName} iconType={iconType} />
            <span
              className={cx(
                'text-base font-mediumml-2 flex items-center ml-1',
                disabled ? 'text-coolGray-500' : 'text-coolGray-800'
              )}
            >
              {header}
            </span>
            {disabled && (
              <span className="flex items-center self-center ml-2 px-1 bg-blue-500 text-xs font-bold text-blue-50 h-5 w-20 break-keep  whitespace-nowrap">
                Coming soon
              </span>
            )}
          </div>
          {shouldShowSelectBox && <SelectBox templateId={templateId} />}
          {flags.showFavoriteIcon &&
          mode === 'select' &&
          (isFavorited || isHovered) ? (
            <Button onClick={onFavoriteClick}>
              <Favorite
                className="text-coolGray-400"
                state={isFavorited ? 'selected' : 'default'}
              />
            </Button>
          ) : null}
        </div>
        <div
          className={cx(
            'text-sm',
            disabled ? 'text-coolGray-400' : 'text-coolGray-600'
          )}
        >
          {description}
        </div>

        <p className="text-xs text-coolGray-400 mt-1">{meta}</p>
      </Widget>
    </div>
  )
}

export default ContentTemplateCard
