import { useEffect } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'

import { useAppDispatch } from 'common/hooks/redux'
import { setPreviousStep } from 'features/unifiedFlow/store/unifiedFlowSlice'

const useTrackUnifiedFlowSteps = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  useEffect(() => {
    let currentPath = history.location.pathname

    const unListen = history.listen((location) => {
      const matchedKey = Object.entries(unifiedFlowPaths).find(([, path]) => {
        return matchPath(currentPath, {
          path,
          strict: true,
          exact: true,
        })
      })

      dispatch(setPreviousStep({ previousStep: matchedKey?.[0] }))

      currentPath = location.pathname
    })

    return () => {
      unListen()
    }
  }, [history, dispatch])
}

export default useTrackUnifiedFlowSteps
