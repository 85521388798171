import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'
import { PrivacyRegion } from 'features/accounts/api'
import { getAccountsList } from 'features/accounts/api'

import { keys } from '../queryKeys'

const useGetAccountQuery = () => {
  const userId = useAppSelector((state) => state.authStates.user_id)
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const getAccountQuery = useQuery(
    keys.account({ accountId, userId }),
    () => getAccountsList(userId),
    {
      select: (data) => {
        const currentAccount = data.find((item) => item.id === accountId)
        const privacyRegionOptions = currentAccount?.privacyRegions?.map(
          (option: PrivacyRegion) => {
            return {
              label: option,
              value: option,
            }
          }
        )

        if (!privacyRegionOptions || privacyRegionOptions.length === 0) {
          throw new Error(
            "We're missing your region info for data privacy. Please contact support to resolve this. Thank you!"
          )
        }

        return privacyRegionOptions
      },
    }
  )

  return getAccountQuery
}

export default useGetAccountQuery
