import Alert from 'common/components/alert'
import ConfirmationCard from 'common/components/ConfirmationCard'
import { useAppDispatch } from 'common/hooks/redux'

import DeleteVariantsCard from '../components/card/DeleteVariantsCard'
import UnscheduleCampaignCard from '../components/card/UnscheduleCampaignCard'
import Scheduler from '../components/scheduler/Scheduler'
import SelectComponent from '../components/select/SelectComponent'

import {
  setError,
  setLoading,
  unlinkCampaign,
  unscheduleCampaign,
} from './store/optimizelySlice'
import CreateVariants from './CreateVariants'
import initialValidationErrors, { initialState } from './OptimizelyIntegration'

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT || 'YYYY-MM-DD'

interface PrimaryGoal {
  id: 'conversion_pageView' | 'conversion_clicks'
  name: 'Page View' | 'Clicks'
}

export default function DrawerContent({
  experimentsList,
  onDateTimeChange,
  currentState,
  campaignId,
  isLoading,
  projectId,
  timeZone,
  setState,
}) {
  const PRIMARY_GOALS: PrimaryGoal[] = [
    {
      id: 'conversion_pageView',
      name: 'Page View',
    },
    {
      id: 'conversion_clicks',
      name: 'Clicks',
    },
  ]

  const dispatch = useAppDispatch()
  const {
    endTime,
    endDate,
    startDate,
    startTime,
    errorMessages,
    isVariantsCreated,
    selectedProject,
    selectedPrimaryGoal,
    showUnlinkConfirmation,
    showUnscheduleConfirmation,
    isExperimentScheduled,
    selectedExperiment,
  } = currentState

  return (
    <>
      {selectedProject && (
        <SelectComponent
          isLoading={isLoading.experiments}
          selectOptions={experimentsList}
          isSelectDisabled={
            isLoading.experiments || isExperimentScheduled || isVariantsCreated
          }
          dataTestId="selectExperiment"
          onChange={(val) =>
            setState({ ...currentState, selectedExperiment: val })
          }
          selectValue={selectedExperiment}
          labelTitle="Optimizely Experiment"
          bottomText={`This is the Optimizely Experiment
          that contains your configured variation to be
          used this Jacquard experiment.`}
          errorMessage={errorMessages.selectExperiment}
          defaultOption="Select"
        />
      )}
      {isVariantsCreated ? (
        <>
          <Alert className="flex items-center" type="success" showIcon>
            Create and send variations to Optimizely
          </Alert>
          {showUnlinkConfirmation ? (
            <div className="mt-4 mb-4">
              <ConfirmationCard
                isDisabled={false}
                cardText={`Are you sure want to unlink this experiment?
              If you do this you will have to setup a
              new Optimizely experiment.`}
                cardTitle="Unlink this... really?"
                buttonText="Yes please, unlink"
                rejectButtonText="Nooo!"
                isLoading={isLoading.variants}
                onSubmit={() => {
                  dispatch(setLoading({ field: 'variants', value: true }))
                  unlinkCampaign(projectId, campaignId)
                    .then(() => {
                      setState({ ...initialState, campaignUnlinked: true })
                    })
                    .catch((err) => {
                      dispatch(setError(err.message))
                    })
                    .finally(() =>
                      dispatch(setLoading({ field: 'variants', value: false }))
                    )
                }}
                onReject={() =>
                  setState({ ...currentState, showUnlinkConfirmation: false })
                }
              />
            </div>
          ) : (
            <DeleteVariantsCard
              isDisabled={isExperimentScheduled}
              cardText={`This will unlink your Jacquard experiment
              from your Optimizely Experiment but
              will not delete the variations.`}
              buttonText="Unlink"
              onSubmit={() =>
                setState({ ...currentState, showUnlinkConfirmation: true })
              }
            />
          )}
          <SelectComponent
            isLoading={false}
            isSelectDisabled={isExperimentScheduled}
            selectOptions={PRIMARY_GOALS}
            dataTestId="selectPrimaryGoal"
            onChange={(val) =>
              setState({ ...currentState, selectedPrimaryGoal: val })
            }
            labelTitle="Primary goal"
            bottomText={`This is the Primary Goal you 
            saved within your Optimizely Experiment.`}
            selectValue={selectedPrimaryGoal}
            errorMessage={errorMessages.primaryGoal}
            defaultOption="Select"
          />
          {isVariantsCreated && selectedPrimaryGoal && (
            <>
              <Scheduler
                minuteStep={15}
                endDate={endDate}
                endTime={endTime}
                timeFormat="HH:mm"
                timeZone={timeZone}
                dateFormat={DATE_FORMAT}
                errorMessages={errorMessages}
                integrationType="Optimizely"
                isEndDateDisabled={
                  isExperimentScheduled ||
                  !isVariantsCreated ||
                  !startDate ||
                  !startTime
                }
                isEndTimeDisabled={
                  isExperimentScheduled ||
                  !isVariantsCreated ||
                  !startDate ||
                  !startTime ||
                  !endDate
                }
                isStartDateDisabled={
                  isExperimentScheduled || !isVariantsCreated
                }
                isStartTimeDisabled={
                  isExperimentScheduled || !isVariantsCreated || !startDate
                }
                startDate={startDate}
                startTime={startTime}
                onChange={onDateTimeChange}
                title="Data collection"
                endTimeTitle="When will you end the A/B test?"
                startTimeTitle="When will you start the A/B test?"
              />
              {isExperimentScheduled && (
                <>
                  {showUnscheduleConfirmation ? (
                    <div className="mt-4 mb-4">
                      <ConfirmationCard
                        isLoading={isLoading.scheduler}
                        isDisabled={false}
                        cardText={`Are you sure you want to Stop collecting data?
                      If you do this you will have to setup a new schedule.`}
                        cardTitle="Slow down tiger!"
                        buttonText="Yes, stop collecting"
                        rejectButtonText="Nooo!"
                        onReject={() =>
                          setState({
                            ...currentState,
                            showUnscheduleConfirmation: false,
                          })
                        }
                        onSubmit={() => {
                          dispatch(
                            setLoading({ field: 'scheduler', value: true })
                          )
                          unscheduleCampaign(projectId, campaignId)
                            .then(() => {
                              setState({
                                ...currentState,
                                showUnscheduleConfirmation: false,
                                isExperimentScheduled: false,
                                startDate: undefined,
                                startTime: undefined,
                                endDate: undefined,
                                endTime: undefined,
                                selectedPrimaryGoal: undefined,
                                errorMessages: initialValidationErrors,
                                campaignUnscheduled: true,
                              })
                            })
                            .catch((err) => {
                              dispatch(setError(err.message))
                            })
                            .finally(() =>
                              dispatch(
                                setLoading({ field: 'scheduler', value: false })
                              )
                            )
                        }}
                      />
                    </div>
                  ) : (
                    <UnscheduleCampaignCard
                      dataTestId="unscheduleBtn"
                      cardText={`This will stop importing data from Optimizely.
                      This will not delete any data you have already received.`}
                      cardTitle="Stop collecting data"
                      buttonText="Stop collecting data"
                      onSubmit={() =>
                        setState({
                          ...currentState,
                          showUnscheduleConfirmation: true,
                        })
                      }
                    />
                  )}
                </>
              )}
              <div className="font-medium">Scheduling</div>
              <div className="mt-2 text-gray-400">
                All scheduling for your experiment will be managed inside
                Optimizely.
              </div>
            </>
          )}
        </>
      ) : (
        <CreateVariants
          isVariantsCreated={isVariantsCreated}
          selectedProject={selectedProject}
          selectedExperiment={selectedExperiment}
          isLoading={isLoading}
          projectId={projectId}
          campaignId={campaignId}
          setState={setState}
          currentState={currentState}
        />
      )}
    </>
  )
}
