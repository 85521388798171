import { FC, useState } from 'react'

import Button from 'common/components/button/Button'
import Label from 'common/components/Label'
import { ChevronLeft } from 'common/icons'

export const addTeamModalText = {
  createTeamButton: 'Create team',
  addUserButton: 'User',
}

interface Props {
  onSyncWithForm: (emails: string) => void
  onClose: () => void
  onClickSingleView: () => void
  emails: string[]
}

const AddMultipleTeamModal: FC<Props> = ({
  onSyncWithForm,
  onClose,
  onClickSingleView,
  emails,
}) => {
  const [textAreaValue, setTextAreaValue] = useState<string>(
    emails.filter((email) => !!email).join(', ')
  )

  return (
    <div className="w-123 m-6">
      <Button
        variant="link"
        className="text-maroon-500 font-medium flex justify-center mb-6"
        onClick={() => onClickSingleView()}
      >
        <ChevronLeft className="pr-2 w-7" /> Back to create team
      </Button>
      <div className="flex mb-6">
        <h1 className="mt-2 text-2xl font-bold text-coolGray-800">
          Add multiple users
        </h1>
      </div>

      <form className="mt-4 mb-14 text-sm text-coolGray-500 grid ">
        <Label>Email address</Label>
        <textarea
          rows={10}
          className="border border-coolGray-300 focus:border-maroon-500 hover:border-maroon-300 outline-none px-3 py-1 font-medium text-coolGray-800"
          placeholder="eg. ross@jacquard.com, colleen@jacquard.com, dave@jacquard.com "
          value={textAreaValue}
          onChange={(event) => setTextAreaValue(event.target.value)}
        />
        <span className="text-coolGray-400 mt-3">
          Seperate emails by a comma ( , )
        </span>
      </form>

      <div className="flex justify-end text-base">
        <Button
          className="mr-4 text-base text-coolGray-400"
          onClick={() => {
            setTextAreaValue('')
            onClose()
          }}
          data-cy="cancel"
          ghost
        >
          Cancel
        </Button>

        <Button
          variant="primary"
          onClick={() => {
            onSyncWithForm(textAreaValue)
          }}
          data-cy="delete-token-confirm"
        >
          Add users
        </Button>
      </div>
    </div>
  )
}

export default AddMultipleTeamModal
