import { Template } from '@phrasee/phrasee-typings/typings/futurama/template'
import { useQuery } from '@tanstack/react-query'

import { getTemplate } from '../api'
import { contentKeys } from '../queryKeys'

const useGetTemplateQuery = (templateId?: string) => {
  const getTemplateQuery = useQuery<Template>(
    contentKeys.template(templateId!),
    () => getTemplate(templateId!),
    {
      staleTime: Infinity,
      enabled: !!templateId,
    }
  )

  return getTemplateQuery
}

export default useGetTemplateQuery
