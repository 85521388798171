/* eslint-disable max-lines */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import moment, { Moment } from 'moment-timezone'
import {
  disableDate,
  getDisabledHoursArray,
  getDisabledMinutesArray,
  getDisabledStartMinutes,
} from 'workflow/utils/helpers'

import Button from 'common/components/button/Button'
import DatePickerAntd from 'common/components/DatePicker'
import Label from 'common/components/Label'
import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import TimePicker from 'common/components/TimePicker'
import Tooltip from 'common/components/Tooltip'
import { File as FileIcon } from 'common/icons'
import { closeDrawer } from 'features/campaigns/store/campaignSlice'

import { EmarsysCampaign, InputState } from '../../../interface'
import {
  AsyncThunkDispatch,
  fetchEmarsysCampaign,
  pushSplitsToEmarsys,
  scheduleEmarsysCampaign,
} from '../../../Workflow.actions'
import HelpError from '../HelpError'

const FormItem = Form.Item

const LABEL_TEXT = {
  campaign: 'Select campaign:',
  testSendDateTime:
    'Enter the date/time when your split test emails will be sent',
  testRetrievalDateTime:
    'Enter the date/time for Jacquard to retrieve your Split test results',
  finalRetrievalDateTime:
    'Enter the date/time for Jacquard to retrieve your Final test results',
  timeZone: 'Emarsys campaign time zone: ',
}

const TOOLTIP_TEXT = {
  campaign: 'Select a draft campaign that has all required fields completed.',
  createTestVersion:
    'This will create the test versions and populate the subject lines for testing purposes before scheduling',
  testStartDate: 'Select the date/time you want to start the experiment',
  testRetrievalStartTime:
    'Select the date/time you want to retrieve your Split test result',
  finalRetrievalStartTime:
    'Select the date/time you want to retrieve your Final result',
}

interface CheetahIntegrationState {
  [key: string]: any
  isLoading: boolean
  campaign: InputState
  campaignTestStartDate: InputState
  campaignTestStartTime: InputState
  campaignTestEndDate: InputState
  campaignTestEndTime: InputState
  campaignFinalEndDate: InputState
  campaignFinalEndTime: InputState
  isCampaignScheduled: boolean
}

export interface Props {
  integrationData: any
  campaignData: any
  isLoadingSchedule: boolean
  projectId: string
  currentCampaignId: string
  emarsysCampaigns: EmarsysCampaign[]
  fetchEmarsysCampaign: (
    projectId: string,
    espCampaignId: string,
    callback: Function
  ) => (dispatch: Function) => void
  pushSplitsToEmarsys: (
    projectId: string,
    campaignId: string,
    emarsysData: {
      emarsysCampaignId: string
      emarsysCampaigns: EmarsysCampaign[]
    }
  ) => (dispatch: Function) => void
  isFetchingEmarsysCampaigns: boolean
  isPushingSplitToEmarsys: boolean
  scheduleEmarsysCampaign: (
    projectId: string,
    campaignId: string,
    payload: any,
    setIsScheduled: Function,
    closeDrawer: Function
  ) => Promise<void>
  closeDrawer: () => () => void
  timeZone: string
  match: any
}

const TIME_FORMAT = 'YYYY-MM-DDTHH:mm:00.000[Z]'
const initialState = {
  isLoading: true,
  campaign: {
    value: undefined,
    isInError: false,
    message: 'Please select an existing campaign.',
  },
  campaignTestStartDate: {
    value: undefined,
    isInError: false,
    message: '',
  },
  campaignTestStartTime: {
    value: undefined,
    isInError: false,
    message: '',
  },
  campaignTestEndDate: {
    value: undefined,
    isInError: false,
    message: '',
  },
  campaignTestEndTime: {
    value: undefined,
    isInError: false,
    message: '',
  },
  campaignFinalEndTime: {
    value: undefined,
    isInError: false,
    message: '',
  },
  campaignFinalEndDate: {
    value: undefined,
    isInError: false,
    message: '',
  },
  isRetrieveCheetahResultsModalVisible: false,
  retrievingCheetahResults: false,
  emarsysCampaginName: '',
  isCampaignScheduled: false,
}

export class EmarsysInt extends Component<Props & {}, CheetahIntegrationState> {
  constructor(props: any) {
    super(props)
    this.state = initialState
  }

  componentDidMount(): void {
    const { fetchEmarsysCampaign, integrationData, projectId } = this.props
    const espCampaignId = integrationData?.emarsys_campaign_id
    if (projectId) {
      fetchEmarsysCampaign(
        projectId,
        espCampaignId,
        this.autoSelectMatchingCampaign
      )
    }
  }

  autoSelectMatchingCampaign = (espCampaignId: string) => {
    const { integrationData, timeZone } = this.props
    const emarsysCampaignName =
      integrationData?.emarsys_campaign_data?.name || ''
    const testStartTime = integrationData?.campaignStartTestDate
    const testEndTime = integrationData?.campaignTestDate
    const finalEndTime = integrationData?.campaignFinalDate

    if (testEndTime && finalEndTime && emarsysCampaignName) {
      this.setState({ isCampaignScheduled: true })
    }

    const getTimeValue = (
      value: string | undefined,
      timeZone: string,
      isTimeOnly = false
    ): Moment | string | undefined => {
      if (!value) {
        return undefined
      }

      const date = moment(value).tz(timeZone)
      if (!isTimeOnly) {
        return date
      }

      return `${date.hour()}:${date.minutes()}`
    }

    this.setState((state) => {
      const isTimeOnly = true
      return {
        campaignTestStartDate: {
          ...state.campaignTestStartDate,
          value: getTimeValue(testStartTime, timeZone),
        },
        campaignTestStartTime: {
          ...state.campaigntestEndTime,
          value: getTimeValue(testStartTime, timeZone, isTimeOnly),
        },
        campaignTestEndDate: {
          ...state.campaignTestEndDate,
          value: getTimeValue(testEndTime, timeZone),
        },
        campaignTestEndTime: {
          ...state.campaigntestEndTime,
          value: getTimeValue(testEndTime, timeZone, isTimeOnly),
        },
        campaignFinalEndDate: {
          ...state.camp,
          value: getTimeValue(finalEndTime, timeZone),
        },
        campaignFinalEndTime: {
          ...state.campaignEndTime,
          value: getTimeValue(finalEndTime, timeZone, isTimeOnly),
        },
        campaign: {
          ...state.campaign,
          value: espCampaignId,
        },
        emarsysCampaignName,
      }
    })
  }

  onChangeUpdateState = (value: any, type: string): void => {
    const { [type]: unMutedState } = this.state
    this.setState({
      [type]: {
        ...unMutedState,
        value,
        isInError: false,
      },
    })
    // update Cheetah campaign name if that was updated
    if (type === 'campaign') {
      const { emarsysCampaigns } = this.props
      const selectedCheetahCampaign = emarsysCampaigns.find(
        (item) => item.campaignId.toString() === value
      )
      const emarsysCampaignName = selectedCheetahCampaign?.campaignName || ''
      this.setState({
        emarsysCampaignName,
      })
    }
  }

  isFormValid = () => {
    const {
      campaign,
      campaignTestEndDate,
      campaignTestEndTime,
      campaignFinalEndDate,
      campaignFinalEndTime,
      campaignTestStartDate,
      campaignTestStartTime,
    } = this.state
    const { timeZone } = this.props
    if (!campaign.value) {
      this.setState({
        campaign: {
          ...this.state.campaign,
          isInError: true,
        },
      })
      return false
    }
    if (
      !campaignTestStartDate.value ||
      campaignTestStartDate.value === 'Invalid date'
    ) {
      this.setState({
        campaignTestStartDate: {
          ...this.state.campaignTestStartDate,
          message: 'Please select an expected send date.',
          isInError: true,
        },
      })
      return false
    }
    if (
      !campaignTestStartTime.value ||
      campaignTestStartTime.value === 'Invalid date'
    ) {
      this.setState({
        campaignTestStartTime: {
          ...this.state.campaignTestStartTime,
          message: 'Please select an expected send date.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignTestEndDate: {
        ...this.state.campaignTestEndDate,
        message: '',
        isInError: false,
      },
    })
    if (
      !campaignTestEndDate.value ||
      campaignTestEndDate.value === 'Invalid date'
    ) {
      this.setState({
        campaignTestEndDate: {
          ...this.state.campaignTestEndDate,
          message: 'Please select an expected end date.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignTestEndDate: {
        ...this.state.campaignTestEndDate,
        message: '',
        isInError: false,
      },
    })
    if (
      !campaignTestEndTime.value ||
      campaignTestEndTime.value === 'Invalid date'
    ) {
      this.setState({
        campaignTestEndTime: {
          ...this.state.campaignTestEndTime,
          message: 'Please select an expected end time.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignTestEndTime: {
        ...this.state.campaignTestEndTime,
        message: '',
        isInError: false,
      },
    })
    if (
      !campaignFinalEndDate.value ||
      campaignFinalEndDate.value === 'Invalid date'
    ) {
      this.setState({
        campaignFinalEndDate: {
          ...this.state.campaignFinalEndDate,
          message: 'Please select experiment end date.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignFinalEndDate: {
        ...this.state.campaignFinalEndDate,
        message: '',
        isInError: false,
      },
    })
    if (
      !campaignFinalEndTime.value ||
      campaignFinalEndTime.value === 'Invalid date'
    ) {
      this.setState({
        campaignFinalEndTime: {
          ...this.state.campaignFinalEndTime,
          message: 'Please select experiment end time.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignFinalEndTime: {
        ...this.state.campaignFinalEndTime,
        message: '',
        isInError: false,
      },
    })
    const startTestTimeArray = campaignTestStartTime.value.split(':')
    const endTestTimeArray = campaignTestEndTime.value.split(':')
    const endFinalTimeArray = campaignFinalEndTime.value.split(':')
    const campaignTestStartDateTime = moment(campaignTestStartDate.value).set({
      hours: startTestTimeArray[0],
      minutes: startTestTimeArray[1],
    })
    const campaignTestEndDateTime = moment(campaignTestEndDate.value).set({
      hours: endTestTimeArray[0],
      minutes: endTestTimeArray[1],
    })
    const campaignFinalEndDateTime = moment(campaignFinalEndDate.value).set({
      hours: endFinalTimeArray[0],
      minutes: endFinalTimeArray[1],
    })
    if (campaignTestStartDateTime.format() < moment().tz(timeZone).format()) {
      this.setState({
        campaignTestStartDate: {
          ...this.state.campaignTestStartDate,
          isInError: true,
          message: 'This time is not available',
        },
      })
      return false
    }
    if (
      campaignTestEndDateTime.isBefore(
        campaignTestStartDateTime.tz(timeZone).add(1, 'hours')
      )
    ) {
      this.setState({
        campaignTestEndDate: {
          ...this.state.campaignTestEndDate,
          isInError: true,
          message: 'This time is not available',
        },
      })
      return false
    }
    this.setState({
      campaignStartTime: {
        ...this.state.campaignStartTime,
        isInError: false,
        message: '',
      },
    })
    if (
      campaignFinalEndDateTime.isBefore(campaignTestEndDateTime.tz(timeZone))
    ) {
      this.setState({
        campaignFinalEndDate: {
          ...this.state.campaignFinalEndDate,
          isInError: true,
          message: 'This time is not available',
        },
      })
      return false
    }
    this.setState({
      campaignEndTime: {
        ...this.state.campaignEndTime,
        isInError: false,
        message: '',
      },
    })
    return true
  }

  clickHandler = () => {
    const { scheduleEmarsysCampaign, projectId, currentCampaignId, timeZone } =
      this.props
    const {
      campaign,
      campaignTestEndDate,
      campaignTestEndTime,
      campaignFinalEndDate,
      campaignFinalEndTime,
      campaignTestStartDate,
      campaignTestStartTime,
    } = this.state
    if (this.isFormValid()) {
      const testStartTimeArray = campaignTestStartTime.value.split(':')
      const testEndTimeArray = campaignTestEndTime.value.split(':')
      const finalEndTimeArray = campaignFinalEndTime.value.split(':')

      const campaignTestStartDateTime = moment(campaignTestStartDate.value)
        .tz(timeZone)
        .hour(testStartTimeArray[0])
        .minute(testStartTimeArray[1])
        .utc()
        .format(TIME_FORMAT)

      const campaignTestEndDateTime = moment(campaignTestEndDate.value)
        .tz(timeZone)
        .hour(testEndTimeArray[0])
        .minute(testEndTimeArray[1])
        .utc()
        .format(TIME_FORMAT)

      const campaignEndDateAndTime = moment(campaignFinalEndDate.value)
        .tz(timeZone)
        .hour(finalEndTimeArray[0])
        .minute(finalEndTimeArray[1])
        .utc()
        .format(TIME_FORMAT)

      return scheduleEmarsysCampaign(
        projectId,
        currentCampaignId,
        {
          test_start_time: campaignTestStartDateTime,
          test_end_time: campaignTestEndDateTime,
          final_end_time: campaignEndDateAndTime,
          emarsys_campaign_id: campaign.value,
        },
        () => {
          this.setState({ isCampaignScheduled: true })
        },
        () => {
          closeDrawer()
        }
      )
    }
    return true
  }

  render() {
    const {
      campaign,
      campaignTestEndDate,
      campaignTestEndTime,
      campaignFinalEndDate,
      campaignFinalEndTime,
      campaignTestStartDate,
      campaignTestStartTime,
      emarsysCampaignName,
      isCampaignScheduled,
    } = this.state
    const {
      isFetchingEmarsysCampaigns,
      isPushingSplitToEmarsys,
      emarsysCampaigns,
      pushSplitsToEmarsys,
      projectId,
      campaignData,
      isLoadingSchedule,
      timeZone,
      integrationData,
    } = this.props
    const selectedCampaign = emarsysCampaignName
    const isConnectedCampaign = Boolean(emarsysCampaignName)
    const espTimeZone =
      integrationData?.esp_campaign_timezone || timeZone || null
    let canUpdateDetails = false
    // We need that information to know if the user can re save the details. ( If the test start Date time is not passed )
    if (isCampaignScheduled) {
      const startTestDate = integrationData?.campaignStartTestDate
      if (
        moment().tz(espTimeZone).isBefore(moment(startTestDate).tz(espTimeZone))
      ) {
        canUpdateDetails = true
      }
    }

    return (
      <>
        <Drawer.Content>
          <Form>
            <Spinner isSpinning={isFetchingEmarsysCampaigns}>
              <FormItem
                help={
                  campaign.isInError ? (
                    <HelpError>{campaign.message}</HelpError>
                  ) : null
                }
                label={LABEL_TEXT.campaign}
              >
                <Tooltip
                  overlay={TOOLTIP_TEXT.campaign}
                  trigger="hover"
                  placement="right"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <SingleSelect
                    isLoading={isFetchingEmarsysCampaigns}
                    isSearchable
                    isDisabled={isConnectedCampaign && isCampaignScheduled}
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    value={
                      emarsysCampaigns.find(
                        (campaign) =>
                          campaign.campaignName === emarsysCampaignName
                      )?.campaignId
                    }
                    data-testid="Select"
                    placeholder="Select an unscheduled campaign"
                    onChange={(val) => {
                      val &&
                        this.onChangeUpdateState(
                          val.value.toString(),
                          'campaign'
                        )
                    }}
                    options={emarsysCampaigns?.map((campaign) => ({
                      label: campaign.campaignName,
                      value: campaign.campaignId,
                    }))}
                  />
                </Tooltip>
              </FormItem>
              <FormItem>
                <Tooltip
                  overlay={TOOLTIP_TEXT.createTestVersion}
                  trigger="hover"
                  placement="left"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <Button
                    data-test-id="export-file"
                    data-cy="emarsys-export-file-button"
                    onClick={() =>
                      pushSplitsToEmarsys(projectId, campaignData._id, {
                        emarsysCampaignId: campaign.value,
                        emarsysCampaigns,
                      })
                    }
                    loading={isPushingSplitToEmarsys}
                    disabled={!campaign?.value}
                    variant="primary"
                    prefixIcon={<FileIcon isDefaultColor={false} size={5} />}
                  >
                    Create Test Versions
                  </Button>
                </Tooltip>
              </FormItem>
              <small className="text-coolGray-400">
                {LABEL_TEXT.timeZone}
                {espTimeZone}
              </small>
              <FormItem
                help={
                  campaignTestStartDate.isInError ||
                  campaignTestStartTime.isInError ? (
                    <HelpError>
                      {campaignTestStartDate.message ||
                        campaignTestStartTime.message}
                    </HelpError>
                  ) : null
                }
              >
                <Label children={LABEL_TEXT.testSendDateTime} />
                <div className="display-flex">
                  <Tooltip
                    overlay={TOOLTIP_TEXT.testStartDate}
                    trigger="hover"
                    placement="left"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <DatePickerAntd
                      ariaLabel="startDatePicker"
                      disabled={
                        (isCampaignScheduled && !canUpdateDetails) ||
                        !selectedCampaign
                      }
                      value={campaignTestStartDate.value}
                      disabledDate={(current: Moment | null): boolean =>
                        disableDate(
                          current,
                          false,
                          campaignTestStartDate,
                          timeZone
                        )
                      }
                      onChange={(date: Moment | null) => {
                        if (date) {
                          this.onChangeUpdateState(
                            moment(date),
                            'campaignTestStartDate'
                          )
                        }
                      }}
                    />
                  </Tooltip>
                  <div style={{ margin: '0 10px' }} />
                  <Tooltip
                    overlay={TOOLTIP_TEXT.testStartDate}
                    placement="right"
                    trigger="hover"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <TimePicker
                      data-test-id="startTimePicker"
                      disabled={
                        (isCampaignScheduled && !canUpdateDetails) ||
                        !selectedCampaign ||
                        !campaignTestStartDate.value
                      }
                      disabledHours={() => {
                        const dateFormatted = moment(
                          campaignTestStartDate.value
                        )
                          .tz(timeZone)
                          .format('YYYY-MM-DD')
                        const timeFormatted = moment(
                          campaignTestStartDate.value
                        )
                          .tz(timeZone)
                          .format('HH:mm')
                        if (
                          campaignTestStartDate.value &&
                          (!moment(campaignTestStartDate.value).isSame(
                            moment().tz(timeZone),
                            'day'
                          ) ||
                            moment(campaignTestStartDate.value).date() >
                              moment().tz(timeZone).date())
                        ) {
                          return []
                        }
                        return getDisabledHoursArray(
                          dateFormatted,
                          timeFormatted,
                          0
                        )
                      }}
                      disabledMinutes={(selectedHour: number) => {
                        // If selected date is in future do not disable anything and return.
                        if (
                          campaignTestStartDate.value &&
                          timeZone &&
                          (!moment(campaignTestStartDate.value).isSame(
                            moment().tz(timeZone),
                            'day'
                          ) ||
                            moment(campaignTestStartDate.value).date() >
                              moment().tz(timeZone).date())
                        ) {
                          return []
                        }
                        const dateFormatted = moment()
                          .tz(timeZone)
                          .format('YYYY-MM-DD')
                        const timeFormatted = moment()
                          .tz(timeZone)
                          .format('HH:mm')
                        return getDisabledMinutesArray(
                          selectedHour,
                          dateFormatted,
                          timeFormatted,
                          0
                        )
                      }}
                      className="flex-one"
                      format="HH:mm"
                      minuteStep={15}
                      onChange={(val: Moment) =>
                        this.onChangeUpdateState(
                          val ? moment(val, 'HH:mm').format('HH:mm') : val,
                          'campaignTestStartTime'
                        )
                      }
                      value={
                        campaignTestStartTime.value
                          ? moment(campaignTestStartTime.value, 'HH:mm')
                          : undefined
                      }
                    />
                  </Tooltip>
                </div>
              </FormItem>
              <FormItem
                help={
                  campaignTestEndDate.isInError ||
                  campaignTestEndTime.isInError ? (
                    <HelpError>
                      {campaignTestEndDate.message ||
                        campaignTestEndTime.message}
                    </HelpError>
                  ) : null
                }
              >
                <Label children={LABEL_TEXT.testRetrievalDateTime} />
                <div className="display-flex">
                  <Tooltip
                    overlay={TOOLTIP_TEXT.testRetrievalStartTime}
                    trigger="hover"
                    placement="left"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <DatePickerAntd
                      ariaLabel="startDatePicker"
                      disabled={
                        (isCampaignScheduled && !canUpdateDetails) ||
                        !selectedCampaign ||
                        !campaignTestStartDate.value ||
                        !campaignTestStartTime.value
                      }
                      value={campaignTestEndDate.value}
                      disabledDate={(current: Moment | null): boolean => {
                        return (
                          moment(current).format() <
                          moment(campaignTestStartDate.value).format()
                        )
                      }}
                      onChange={(date: Moment | null) => {
                        if (date) {
                          this.onChangeUpdateState(
                            moment(date),
                            'campaignTestEndDate'
                          )
                        }
                      }}
                    />
                  </Tooltip>
                  <div style={{ margin: '0 10px' }} />
                  <Tooltip
                    overlay={TOOLTIP_TEXT.testRetrievalStartTime}
                    placement="right"
                    trigger="hover"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <TimePicker
                      data-test-id="startTimePicker"
                      disabled={
                        (isCampaignScheduled && !canUpdateDetails) ||
                        !selectedCampaign ||
                        !campaignTestStartDate.value ||
                        !campaignTestStartTime.value ||
                        !campaignTestEndDate.value
                      }
                      disabledHours={() => {
                        const dateFormatted = moment(
                          campaignTestStartDate.value
                        )
                          .tz(timeZone)
                          .format('YYYY-MM-DD')
                        const timeFormatted = campaignTestStartTime.value
                        if (
                          campaignTestEndDate.value &&
                          (!moment(campaignTestEndDate.value).isSame(
                            moment(campaignTestStartDate.value).tz(timeZone),
                            'day'
                          ) ||
                            moment(campaignTestEndDate.value).date() >
                              moment(campaignTestStartDate.value)
                                .tz(timeZone)
                                .date())
                        ) {
                          return []
                        }
                        return getDisabledHoursArray(
                          dateFormatted,
                          timeFormatted,
                          60
                        )
                      }}
                      disabledMinutes={(selectedHour) =>
                        getDisabledStartMinutes(
                          selectedHour,
                          campaignTestEndDate,
                          timeZone
                        )
                      }
                      className="flex-one"
                      format="HH:mm"
                      minuteStep={15}
                      onChange={(val: any) =>
                        this.onChangeUpdateState(
                          val ? moment(val, 'HH:mm').format('HH:mm') : val,
                          'campaignTestEndTime'
                        )
                      }
                      value={
                        campaignTestEndTime.value
                          ? moment(campaignTestEndTime.value, 'HH:mm')
                          : undefined
                      }
                    />
                  </Tooltip>
                </div>
              </FormItem>
              <FormItem
                help={
                  campaignFinalEndDate.isInError ||
                  campaignFinalEndTime.isInError ? (
                    <HelpError>
                      {campaignFinalEndDate.message ||
                        campaignFinalEndTime.message}
                    </HelpError>
                  ) : null
                }
              >
                <Label children={LABEL_TEXT.finalRetrievalDateTime} />
                <div className="display-flex">
                  <Tooltip
                    overlay={TOOLTIP_TEXT.finalRetrievalStartTime}
                    trigger="hover"
                    placement="left"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <DatePickerAntd
                      disabled={
                        (isCampaignScheduled && !canUpdateDetails) ||
                        !selectedCampaign ||
                        !campaignTestStartDate.value ||
                        !campaignTestStartTime.value ||
                        !campaignTestEndDate.value ||
                        !campaignTestEndTime.value
                      }
                      disabledDate={(current: Moment | null): boolean => {
                        return (
                          moment(current).format() <
                          moment(campaignTestEndDate.value).format()
                        )
                      }}
                      ariaLabel="endDatePicker"
                      value={campaignFinalEndDate.value}
                      onChange={(date: Moment | null) => {
                        if (date) {
                          this.onChangeUpdateState(
                            moment(date),
                            'campaignFinalEndDate'
                          )
                        }
                      }}
                    />
                  </Tooltip>
                  <div style={{ margin: '0 10px' }} />
                  <Tooltip
                    overlay={TOOLTIP_TEXT.finalRetrievalStartTime}
                    placement="right"
                    trigger="hover"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <TimePicker
                      disabled={
                        (isCampaignScheduled && !canUpdateDetails) ||
                        !selectedCampaign ||
                        !campaignTestStartDate.value ||
                        !campaignTestStartTime.value ||
                        !campaignTestEndDate.value ||
                        !campaignTestEndTime.value ||
                        !campaignFinalEndDate.value
                      }
                      data-test-id="endTimePicker"
                      disabledHours={() => {
                        const dateFormatted = moment(
                          campaignTestStartDate.value
                        )
                          .tz(timeZone)
                          .format('YYYY-MM-DD')
                        const timeFormatted = campaignTestStartTime.value
                        if (
                          campaignTestEndDate.value &&
                          (!moment(campaignTestEndDate.value).isSame(
                            moment(campaignTestStartDate.value).tz(timeZone),
                            'day'
                          ) ||
                            moment(campaignTestEndDate.value).date() >
                              moment(campaignTestStartDate.value)
                                .tz(timeZone)
                                .date())
                        ) {
                          return []
                        }
                        return getDisabledHoursArray(
                          dateFormatted,
                          timeFormatted,
                          60
                        )
                      }}
                      disabledMinutes={(selectedHour) =>
                        getDisabledStartMinutes(
                          selectedHour,
                          campaignTestEndDate,
                          timeZone
                        )
                      }
                      className="flex-one"
                      format="HH:mm"
                      minuteStep={15}
                      value={
                        campaignFinalEndTime.value
                          ? moment(campaignFinalEndTime.value, 'HH:mm')
                          : undefined
                      }
                      onChange={(val: any) =>
                        this.onChangeUpdateState(
                          val ? moment(val, 'HH:mm').format('HH:mm') : val,
                          'campaignFinalEndTime'
                        )
                      }
                    />
                  </Tooltip>
                </div>
              </FormItem>
            </Spinner>
          </Form>
        </Drawer.Content>
        <Drawer.Footer>
          <Button
            data-test-id="Submit"
            data-cy="emarsys-save-button"
            onClick={() => this.clickHandler()}
            loading={isLoadingSchedule}
            variant="primary"
            disabled={
              (isCampaignScheduled && !canUpdateDetails) ||
              !selectedCampaign ||
              !campaignTestEndDate.value ||
              !campaignTestEndTime.value ||
              !campaignFinalEndDate.value
            }
          >
            Save details
          </Button>
        </Drawer.Footer>
      </>
    )
  }
}

function mapStateToProps(state: any, ownsProps: any): any {
  const {
    projectId,
    currentCampaignId,
    emarsysCampaigns,
    isWaitingState,
    campaignName,
    campaignData,
    timeZone,
  } = state.campaignStates
  return {
    isLoadingSchedule:
      isWaitingState.isWaitingFor === 'schedule' && isWaitingState.isWaiting,
    isFetchingEmarsysCampaigns:
      isWaitingState.isWaitingFor === 'fetchingEmarsysCampaigns' &&
      isWaitingState.isWaiting,
    isPushingSplitToEmarsys:
      isWaitingState.isWaitingFor === 'pushSplitsToEmarsys' &&
      isWaitingState.isWaiting,
    emarsysCampaigns,
    projectId,
    currentCampaignId,
    timeZone,
    campaignName,
    campaignData,
    integrationData: campaignData?.campaign_data?.integration_data,
  }
}

function mapDispatchToProps(dispatch: AsyncThunkDispatch) {
  return {
    fetchEmarsysCampaign: (
      projectId: string,
      espCampaignId: string,
      cb: Function
    ) => {
      dispatch(fetchEmarsysCampaign(projectId, espCampaignId, cb))
    },
    pushSplitsToEmarsys: (
      projectId: string,
      campaignId: string,
      emarsysData: {
        emarsysCampaignId: string
        emarsysCampaigns: EmarsysCampaign[]
      }
    ) => {
      dispatch(pushSplitsToEmarsys(projectId, campaignId, emarsysData))
    },
    scheduleEmarsysCampaign: (
      projectId: string,
      campaignId: string,
      payload: any,
      setIsScheduled: Function,
      closeDrawer: Function
      // eslint-disable-next-line max-params
    ) => {
      dispatch(
        scheduleEmarsysCampaign(
          projectId,
          campaignId,
          payload,
          setIsScheduled,
          closeDrawer
        )
      )
    },
  }
}

const EmarsysIntegration: any = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    EmarsysInt
  )
)

export default EmarsysIntegration
