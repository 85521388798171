import {
  graphConfig,
  Highcharts,
} from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/Graphs'

import HighchartsReact from 'common/components/AutoResizeChart'

interface UpliftResult {
  day: string
  unique_opens_rate: number
  unique_opens?: number
  unique_clicks_rate?: number
  unique_clicks?: number
  number_of_recipients?: number
  direct_open?: number
  direct_open_rate?: number
  total_opens?: number
  total_opens_rate?: number
  unique_influenced_opens?: number
  unique_influenced_opens_rate?: number
}

interface Props {
  results: UpliftResult[]
}

const UpliftGraph = (props: Props) => {
  const { results } = props
  const dateToMms = new Date(results[0]?.day).valueOf()

  const options: Highcharts.Options = {
    ...graphConfig,
    xAxis: {
      ordinal: true,
      tickPositions: results.map((result: UpliftResult) =>
        new Date(result.day).valueOf()
      ),
      type: 'datetime',
      labels: {
        step: 1,
        format: '{value: %e %b}',
        style: {
          ...(graphConfig.xAxis as Highcharts.XAxisOptions)?.labels?.style,
        },
      },
      gridLineColor: '#e4e4e4',
    },
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Roboto',
      },
      marginLeft: 0,
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: false,
      borderRadius: 5,
      padding: 16,
      backgroundColor: '#ffffff',
      shadow: true,
    },
    plotOptions: {
      column: {
        pointStart: dateToMms,
      },
      series: {
        stickyTracking: false,
        dataGrouping: {},
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: 'column',
        name: 'Open rate',
        data: results.map((result: UpliftResult) => {
          return {
            name: new Date(result.day).toUTCString(),
            y: result.unique_opens_rate,
            x: new Date(result.day).valueOf(),
          }
        }),
        color: '#e4e4e4',
        pointStart: dateToMms,
      },
      {
        type: 'column',
        name: 'Click rate',
        data: results.map((result: UpliftResult) => {
          return {
            name: new Date(result.day).toUTCString(),
            y: result.unique_clicks_rate,
            x: new Date(result.day).valueOf(),
          }
        }),
        color: '#61bec7',
        pointStart: dateToMms,
      },
      {
        type: 'column',
        name: 'Total Open rate',
        data: results.map((result: UpliftResult) => {
          return {
            name: new Date(result.day).toUTCString(),
            y: result.total_opens_rate,
            x: new Date(result.day).valueOf(),
          }
        }),
        color: '#61bec7',
        pointStart: dateToMms,
      },
      {
        type: 'column',
        name: 'Direct Open rate',
        data: results.map((result: UpliftResult) => {
          return {
            name: new Date(result.day).toUTCString(),
            y: result.direct_open_rate,
            x: new Date(result.day).valueOf(),
          }
        }),
        color: '#61bec7',
        pointStart: dateToMms,
      },
      {
        type: 'column',
        name: 'Influenced Open rate',
        data: results.map((result: UpliftResult) => {
          return {
            name: new Date(result.day).toUTCString(),
            y: result.unique_influenced_opens_rate,
            x: new Date(result.day).valueOf(),
          }
        }),
        color: '#61bec7',
        pointStart: dateToMms,
      },
    ],
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="chart"
      options={options}
    />
  )
}

export default UpliftGraph
