import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'

import { mapContentTypeToIcon } from './ContentTypeIcon'

type Props = {
  iconType: string
  size?: 'small' | 'medium'
  className?: string
}

export const ContentIcon = ({
  iconType,
  size = 'medium',
  className,
}: Props) => {
  iconType = iconType ? iconType.toLowerCase() : ''
  const Icon = mapContentTypeToIcon[iconType]
  return Icon ? (
    <Icon
      isDefaultColor={false}
      className={cx(
        { 'text-coolGray-400': !className },
        {
          'w-6': size === 'medium',
          'w-4': size === 'small',
        },
        className
      )}
    />
  ) : null
}

const ContentTypeBubble = ({
  name,
  iconType,
  size = 'medium',
}: Props & { name?: string }) => {
  return (
    <div data-testid="type-bubble">
      <Tooltip overlay={name} show={name !== undefined}>
        <span
          className={cx(
            'flex items-center justify-center rounded-full border-2 border-white bg-coolGray-50',
            {
              'w-10 h-10': size === 'medium',
              'w-8 h-8': size === 'small',
            }
          )}
        >
          <ContentIcon size={size} iconType={iconType} />
        </span>
      </Tooltip>
    </div>
  )
}

export default ContentTypeBubble
