import { Redirect, Route } from 'react-router-dom'
import { futuramaContentsPageSubRoute } from 'app/navigation/staticSubRoutes.futurama'
import CustomSwitch from 'app/router/Switch'

import ContentView from './contentPage/ContentView'
import CreateContent from './contentPage/CreateContent'

const ContentPage = () => {
  const subroutes = futuramaContentsPageSubRoute

  return (
    <div className="flex flex-col h-full w-full relative bg-coolGray-100 overflow-y-auto">
      <CustomSwitch>
        {subroutes?.map(({ key, to, component: Component }) => (
          <Route key={key} path={to} exact>
            <Component />
          </Route>
        ))}
        <Route key="create-content" path="/content/create/:templateId">
          <CreateContent />
        </Route>
        <Route key="content-view" path="/content/:contentId">
          <ContentView />
        </Route>
        {subroutes?.length && (
          <Redirect exact from="/content/*" to="/content" />
        )}
      </CustomSwitch>
    </div>
  )
}

export default ContentPage
