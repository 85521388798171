import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { contentKeys } from '../queryKeys'
import { createContent } from '..'

const useCreateContentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(createContent, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content) //TODO: invalidate all contents
    },
  })
}

export default useCreateContentMutation
