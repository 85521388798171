import GlobalErrorContent from './GlobalErrorContent'

const ErrorPage = () => {
  return (
    <div className="flex h-full w-full flex-1 justify-center items-center">
      <GlobalErrorContent />
    </div>
  )
}

export default ErrorPage
