import { useCallback, useEffect, useState } from 'react'
import ControlInput from 'workflow/common/controlInput'
import { SubjectLine } from 'workflow/interface'
import { updateControlSubjectLine } from 'workflow/Workflow.actions'

import Button from 'common/components/button'
import Modal from 'common/components/Modal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import style from './TweakControlVariant.module.css'

const TweakControlVariant = ({
  controlSubjectLine,
  isVisible,
  updateIsVisible,
  newlinesInVariants,
}: {
  controlSubjectLine: SubjectLine | undefined
  isVisible: boolean
  updateIsVisible: (isVisible: boolean) => void
  newlinesInVariants?: { min?: number; max?: number }
}) => {
  const dispatch = useAppDispatch()
  const [newControlText, setNewControlText] = useState(
    controlSubjectLine?.text ?? ''
  )
  const [didPressButton, setDidPressButton] = useState(false)
  const { isLoading } = useAppSelector((state) => state.campaignStates)
  const handleCloseModal = useCallback(
    () => updateIsVisible(false),
    [updateIsVisible]
  )

  const onPressUpdate = () => {
    if (!isLoading && controlSubjectLine) {
      const newControlSubjectLine: SubjectLine = {
        ...controlSubjectLine,
        text: newControlText,
      }
      dispatch(updateControlSubjectLine(newControlSubjectLine))
      setDidPressButton(true)
    }
  }

  const handleInputChange = (value: string) => {
    setNewControlText(value)
  }

  useEffect(() => {
    if (!isLoading && isVisible && didPressButton) {
      handleCloseModal()
      setNewControlText('')
      setDidPressButton(false)
    }
  }, [isLoading, isVisible, didPressButton, handleCloseModal])

  useEffect(() => {
    if (controlSubjectLine) {
      setNewControlText(controlSubjectLine.text)
    }
  }, [controlSubjectLine])

  return (
    <Modal
      className={style.modal}
      maskClosable
      centered
      bodyStyle={{
        paddingLeft: '24px',
        paddingRight: '24px',
        width: '500px',
        height: '300px',
      }}
      data-cy="tweak-control-variant-modal"
      title={
        <h1 className="mx-2 my-2 text-2xl font-rob text-coolGray-800 flex items-center">
          Edit the control
        </h1>
      }
      wrapClassName="center-tweak-control-modal"
      visible={isVisible}
      onClose={handleCloseModal}
      footer={
        <div className="flex flex-row-reverse">
          <Button
            className="ml-4"
            data-cy="update-control-button"
            variant="primary"
            onClick={onPressUpdate}
            disabled={isLoading || !newControlText}
            loading={isLoading}
          >
            Update Control
          </Button>

          <Button
            data-cy="cancel-update-control-button"
            ghost
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            Cancel
          </Button>
        </div>
      }
    >
      <div className="flex flex-col h-full">
        <span className="font-medium pb-1 text-coolGray-700">New Control</span>
        <ControlInput
          name="newControlText"
          data-cy="new-control-text"
          className="w-full px-3 py-2 text-sm text-coolGray-800 bg-gray-50
          border border-blueGray-200 focus:outline-none
          focus:placeholder-maroon-300 focus:border-maroon-500"
          placeholder=""
          onChange={handleInputChange}
          value={newControlText}
          maxRows={newlinesInVariants?.max}
        />
      </div>
    </Modal>
  )
}

export default TweakControlVariant
