import { ComponentProps } from 'react'
import cx from 'classnames'

import Button from 'common/components/button'

import Tooltip from './Tooltip'

type ButtonProps = ComponentProps<typeof Button>

interface ButtonWithTooltipProps extends ButtonProps {
  tooltip: boolean
  tooltipText: string
  placement?: string
}

const ButtonWrapper = ({ children, className, ...rest }: ButtonProps) => {
  return (
    <Button
      // pointer events none fix tooltips not disappearing
      className={cx(
        'disabled:pointer-events-none disabled:cursor-not-allowed',
        className
      )}
      {...rest}
    >
      {children}
    </Button>
  )
}

export const ButtonWithTooltip = ({
  tooltip,
  tooltipText,
  disabled,
  placement,
  ...rest
}: ButtonWithTooltipProps) => {
  return (
    <>
      {tooltip ? (
        <Tooltip
          overlay={tooltipText}
          overlayClassName="max-w-xs break-words"
          placement={placement}
        >
          {/* must be wrapped because disabled button does not 
          trigger mouse events */}
          <div
            className={cx('flex items-stretch', {
              'cursor-not-allowed': disabled,
            })}
          >
            <ButtonWrapper disabled={disabled} {...rest} />
          </div>
        </Tooltip>
      ) : (
        <ButtonWrapper disabled={disabled} {...rest} />
      )}
    </>
  )
}

export default ButtonWithTooltip
