import { useEffect } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import type { StaticRoute, Subroute } from 'app/navigation/interfaces'
import { selectedCampaignPath } from 'app/navigation/paths'
import {
  accountsRoute,
  dashboardRoute,
  homeRoute,
} from 'app/navigation/staticRoutes'
import {
  adminRoutes,
  createContentRoutes,
  topNavStaticRoutes,
} from 'app/navigation/topNavStaticRoutes'
import { getIsUserTypeAuthorized } from 'app/router/routesUtils'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { canAccessAccount, getIsAuthorized } from 'common/auth/permissions'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { BellNotification as BellIcon } from 'common/icons'
import { ReactComponent as Logo } from 'common/icons/logo/logo.svg'
import { ReactComponent as LogoSmall } from 'common/icons/logo/logo-small.svg'
import useProjectsQuery from 'features/campaigns/queries/useProjectsQuery'
import { onLoadPageCampaign } from 'features/campaigns/store/campaignSlice'

import AccountSelector from './AccountSelect'
import AvatarButton from './AvatarButton'
import CreateButton from './CreateButton'
import NavItems from './NavItems'
import ResponsiveMenu from './ResponsiveNavItem'

const TopNavBar = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const flags = useFlags()
  useProjectsQuery()

  const { permissions, accounts, userType, accountId, accountCategory } =
    useAppSelector((state) => state.authStates)
  const { selectedCampaignId } = useAppSelector((state) => state.campaigns)
  const filterAuthorisedRoutes = (routes: StaticRoute[]) => {
    const availableStaticRoutes = routes.filter(
      ({
        accessPermission,
        accessFlags,
        accessLevel,
        shouldValidateAccountAccess,
      }) => {
        const isAuthorised = flags.rolesAndPermissions
          ? getIsAuthorized(permissions, accessPermission)
          : getIsUserTypeAuthorized(userType, accessLevel)

        const isAccessFlagEnabled =
          accessFlags &&
          accessFlags.flags.length > 0 &&
          accessFlags.type === 'disable'
            ? true
            : isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
        if (accessPermission && shouldValidateAccountAccess) {
          return (
            isAuthorised &&
            isAccessFlagEnabled &&
            canAccessAccount(accountCategory, accessPermission)
          )
        }
        return isAuthorised && isAccessFlagEnabled
      }
    )

    let availableSubRoutes: Subroute[] | undefined
    const allAvailableRoutes = availableStaticRoutes.map((staticRoute) => {
      if (staticRoute?.subroutes) {
        availableSubRoutes = staticRoute.subroutes.filter(
          ({ accessFlags, accessPermission, shouldValidateAccountAccess }) => {
            const isAuthorized = flags.rolesAndPermissions
              ? getIsAuthorized(permissions, accessPermission)
              : true

            if (accessPermission && shouldValidateAccountAccess) {
              return (
                isAuthorized &&
                isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags) &&
                canAccessAccount(accountCategory, accessPermission)
              )
            }
            return (
              isAuthorized &&
              isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
            )
          }
        )
      }

      return {
        ...staticRoute,
        subroutes: availableSubRoutes?.length ? availableSubRoutes : undefined,
      }
    })

    const filterUndefinedSubRoutes = (
      routeName: string[],
      staticRoutes: StaticRoute[]
    ) =>
      staticRoutes.filter(
        ({ name, subroutes }) => !(routeName.includes(name) && !subroutes)
      )

    return filterUndefinedSubRoutes([adminRoutes.name], allAvailableRoutes)
  }

  const routeMatchCampaignId = useRouteMatch<{
    campaignId: string
  }>({
    path: [selectedCampaignPath],
  })

  useEffect(() => {
    if (routeMatchCampaignId) {
      const { campaignId } = routeMatchCampaignId.params

      if (!campaignId && selectedCampaignId) {
        history.push(
          generatePath(selectedCampaignPath, {
            accountId,
            campaignId: selectedCampaignId,
          })
        )
      }
      if (campaignId && selectedCampaignId !== campaignId) {
        dispatch(
          onLoadPageCampaign({
            campaignId,
          })
        )
      }
    }
  }, [dispatch, history, routeMatchCampaignId, selectedCampaignId, accountId])

  const topNavRoutes = [
    flags.showHomePage ? homeRoute : dashboardRoute,
    ...topNavStaticRoutes,
  ]

  return (
    <div className="box-border shadow-bottom z-100 bg-maroon-500 h-14 flex justify-between items-center flex-none pr-6 pl-6">
      <div className="flex font-medium gap-6 h-9">
        <div className="hidden md:flex items-center">
          <Logo />
        </div>
        <ResponsiveMenu staticRoutes={filterAuthorisedRoutes(topNavRoutes)} />
        <div className="flex items-center md:hidden">
          <LogoSmall />
        </div>
        <CreateButton
          createContentRoutes={filterAuthorisedRoutes(createContentRoutes)}
        />
        <NavItems staticRoutes={filterAuthorisedRoutes(topNavRoutes)} />
      </div>

      <div className="flex gap-2 h-9 font-medium items-center min-w-0">
        <AccountSelector
          accountsRoute={accountsRoute}
          accounts={accounts || []}
        />
        {flags.showContent && (
          <BellIcon className="w-6 h-6 hover:text-gray-100" />
        )}
        <AvatarButton />
      </div>
    </div>
  )
}

export default TopNavBar
