import { useAppSelector } from 'common/hooks/redux'
import PhraseeXIntegration from 'features/campaigns/integration-options/PhraseeX'

import { shouldShowThePhraseeXIntegrationPanel } from './helpers'
import SFMCIntegration from './SfmcIntegration'

const SFMCIntegrationManager = () => {
  const campaignData = useAppSelector(
    (state: any) => state.campaignStates?.campaignData
  )

  return shouldShowThePhraseeXIntegrationPanel(campaignData) ? (
    <PhraseeXIntegration />
  ) : (
    <SFMCIntegration />
  )
}

export default SFMCIntegrationManager
