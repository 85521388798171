import { ReactNode, useState } from 'react'
import cx from 'classnames'

import Content from './Content'

import styles from './Scrollable.module.css'

export type ScrollPosition = 'top' | 'middle' | 'bottom' | 'none'

type Props = {
  header?: ReactNode
  content: ReactNode
  contentClassName?: string
  footerClassName?: string
  footer?: ReactNode
  isFullHeight?: boolean
}

const Scrollable = ({
  header,
  content,
  contentClassName,
  footer,
  footerClassName,
  isFullHeight = true,
}: Props) => {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('none')
  return (
    // conditional h-full fixes layout issue on edit linguo project page
    // TODO isFullHeight prop should be removed after switching to using top nav bar
    <div className={cx('flex flex-col', { 'h-full': isFullHeight })}>
      {header && (
        <div className="relative">
          <div
            className={cx('flex justify-between bg-white', {
              [styles.shadowInnerTop]:
                scrollPosition !== 'top' && scrollPosition !== 'none',
            })}
          >
            {header}
          </div>
        </div>
      )}
      <Content
        scrollPosition={scrollPosition}
        onScroll={setScrollPosition}
        className={contentClassName}
      >
        {content}
      </Content>

      {footer && (
        <div
          className={cx(`sticky bottom-0`, footerClassName, {
            [styles.shadowInnerBottom]:
              scrollPosition !== 'bottom' && scrollPosition !== 'none',
          })}
        >
          {footer}
        </div>
      )}
    </div>
  )
}

export default Scrollable
