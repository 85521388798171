import Button from 'common/components/button/Button'
import { ArrowRight } from 'common/icons'

const UpgradeAccountComponent = () => {
  return (
    <div
      data-cy="upgrade-account-widget"
      className="max-h-full p-6 box-border shadow-widget bg-white mb-6 md:mb-10"
    >
      <div className="flex flex-col justify-between items-start gap-4">
        <div className="flex items-center text-coolGray-50 font-bold text-xs py-0.5 px-1 bg-coolGray-500">
          Upgrade
        </div>

        <div className="font-medium text-2xl text-coolGray-800">
          Get started with Optimisation
        </div>

        <div className="font-normal text-base text-coolGray-500 pb-2">
          Create experiments that benefit your customer and drive conversion.
        </div>

        <Button
          variant="primary"
          data-cy="upgrade-account-project-button"
          onClick={() => window.open('https://phrasee.co/pricing/')}
          suffixIcon={<ArrowRight size={4.5} isDefaultColor={false} />}
        >
          Upgrade now
        </Button>
      </div>
    </div>
  )
}

export default UpgradeAccountComponent
