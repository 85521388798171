import ConfirmationModal, {
  ConfirmationModalProps,
} from 'common/components/confirmationModal/ConfirmationModal'

type Props = Pick<ConfirmationModalProps, 'onCancel' | 'onConfirm'>

const DeleteConfirmationModal = ({ onCancel, onConfirm }: Props) => {
  return (
    <ConfirmationModal
      data-cy="delete-confirmation-modal"
      title="Delete this content?"
      confirmationText="You are about to delete this content. Are you sure you want to proceed?"
      confirmButtonText="Delete content"
      cancelButtonText="Cancel"
      open
      hasConfirmationSlider={false}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default DeleteConfirmationModal
