import { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import {
  graphConfig,
  Highcharts,
} from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/Graphs'

import HighchartsReact from 'common/components/AutoResizeChart'
import { useDeepCompare, useShallowCompare } from 'common/hooks/custom'
import { replaceNewLineEmojiAsLineBreak } from 'common/variantsUtils'

type DataItem = {
  minutes_since_start: number
  open_rate?: number
  click_rate?: number
  timestamp: string
  variant_id: string
  variant_text: string
  color: string
}

type Data = Array<DataItem>
const makeHighChartsData = (
  data: Array<Array<DataItem>>,
  variantColorMapping: Record<string, any>,
  selectedRowsIds: Array<string>
) => {
  return data.map((arr: Data) => {
    if (!Array.isArray(arr)) {
      return []
    }
    return arr
      .map((currObj: DataItem) => {
        return {
          ...currObj,
          name: replaceNewLineEmojiAsLineBreak(currObj.variant_text),
          color: variantColorMapping.get(currObj.variant_id)?.color ?? '#000',
          y: currObj.open_rate ?? currObj.click_rate ?? 0,
          id: currObj.variant_id,
        }
      })
      .filter((variant: any) => {
        return selectedRowsIds?.length
          ? selectedRowsIds.includes(variant.id)
          : []
      })
  })
}

const BarChart = ({
  results,
  selectedRows,
  variantColorMapping,
  template,
  currentIndex,
  setCurrentIndex,
  shouldEnableRestartButton,
}) => {
  const selectedRowsIds = selectedRows?.map((row: any) => row._id)
  const highChartsData = makeHighChartsData(
    results,
    variantColorMapping,
    selectedRowsIds
  )
  const currentDataSet = highChartsData[currentIndex]
  const [chartOptions, setChartOptions] = useState<any>({
    ...graphConfig,
    chart: {
      type: 'bar',
      style: {
        fontFamily: 'Roboto',
      },
      margin: [20, 0, 30, 0],
      plotBorderWidth: 1,
      plotBorderColor: '#e4e4e4',
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: false,
        style: {
          ...(graphConfig.xAxis as Highcharts.XAxisOptions)?.labels?.style,
        },
      },
      gridLineColor: '#e4e4e4',
    },
    plotOptions: {
      series: {
        animation: {
          duration: 400,
        },
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: { fontWeight: 'normal', fontSize: '10px' },
        },
      },
    },
    tooltip: {
      shared: false,
      split: false,
      useHTML: false,
      padding: 16,
      borderRadius: 5,
      backgroundColor: '#ffffff',
      shadow: true,
    },
    series: [
      {
        name: 'Open Rate',
        dataSorting: {
          enabled: true,
        },
        data: currentDataSet,
      },
    ],
  })
  const hasChangedSelectedRows = useShallowCompare(selectedRows)
  const hasChangedData = useDeepCompare(highChartsData)
  const indexedDataLength = highChartsData.length - 1
  const metricName =
    template === 'lil_bandit_open_rate_leaderboard' ? 'Open Rate' : 'Click Rate'
  useEffect(() => {
    const graphTimer = setInterval(() => {
      if (highChartsData[currentIndex]) {
        setChartOptions({
          ...chartOptions,
          series: [
            {
              name: metricName,
              dataSorting: {
                enabled: true,
              },
              data: currentDataSet?.length
                ? currentDataSet.filter((variant: any) => {
                    return selectedRowsIds
                      ? selectedRowsIds?.includes(variant.id)
                      : currentDataSet
                  })
                : [],
            },
          ],
        })
      }
      shouldEnableRestartButton(false)
      if (currentIndex < indexedDataLength) {
        setCurrentIndex(currentIndex + 1)
      } else {
        shouldEnableRestartButton(true)
        clearInterval(graphTimer)
        return
      }
    }, 500)
    if (hasChangedSelectedRows || hasChangedData) {
      setChartOptions({
        ...chartOptions,
        series: [
          {
            name: metricName,
            dataSorting: {
              enabled: true,
            },
            data: currentDataSet?.length
              ? currentDataSet.filter((variant: any) =>
                  selectedRowsIds?.includes(variant.id)
                )
              : [],
          },
        ],
      })
    }
    return () => {
      clearInterval(graphTimer)
      if (currentIndex === indexedDataLength) {
        shouldEnableRestartButton(true)
      }
    }
    // FIXME there might be a bug here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, hasChangedSelectedRows, selectedRows, hasChangedData])

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="chart"
        // cloneDeep fixes crash when changing and resetting filters
        options={cloneDeep(chartOptions)}
      />
    </>
  )
}

export default BarChart
