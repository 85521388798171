import Button from 'common/components/button/Button'
import Input from 'common/components/Input'
import { ReactComponent as AddDefault } from 'common/icons/add/default.svg'

type Props = {
  onAddTeamClick: () => void
  onSearch: (value: string) => void
  searchTeamValue: string
}
const Header = ({ onAddTeamClick, onSearch, searchTeamValue }: Props) => (
  <>
    <Input
      type="search"
      value={searchTeamValue}
      placeholder="Search"
      data-cy="team-search"
      onChange={(e) => {
        onSearch(e.target.value)
      }}
      clear={() => {
        onSearch('')
      }}
      className={`h-10 w-full pl-10 py-2 text-sm text-coolGray-800 
        placeholder-sm placeholder-coolGray-800 placeholder-opacity-50 
        border border-blueGray-300 bg-white focus:outline-none 
        focus:text-maroon-300 focus:placeholder-maroon-300 
        focus:border-maroon-500 focus:bg-maroon-300 focus:bg-opacity-10`}
    />
    <Button
      data-cy="create-team-button"
      aria-label="Create a new team"
      variant="primary"
      onClick={() => onAddTeamClick()}
      className="text-base"
      prefixIcon={<AddDefault width={24} height={24} />}
    >
      Team
    </Button>
  </>
)

export default Header
