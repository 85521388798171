import { routesPermissions } from 'common/auth/permissions'
import AdminRegionSettings from 'features/admin/regions/RegionSettings'
import AdminNodes from 'features/content/nodes'

import { TabbedNavRoute } from './interfaces'
import { nodesPath, regionSettingsPath } from './paths'

export const regionSettingsRoute: TabbedNavRoute = {
  key: 'region',
  name: 'Region settings',
  to: regionSettingsPath,
  component: AdminRegionSettings,
  accessPermission: routesPermissions.contentView,
}

export const nodesRoute: TabbedNavRoute = {
  key: 'nodes',
  name: 'Nodes',
  to: nodesPath,
  component: AdminNodes,
}

export const languageSubroutes: TabbedNavRoute[] = [
  regionSettingsRoute,
  nodesRoute,
]
