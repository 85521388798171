/* eslint-disable max-lines */
import { batch } from 'react-redux'
import { Action } from 'redux'
import { RootState } from 'redux/store'
import { ThunkDispatch } from 'redux-thunk'

import { getTeams } from 'common/api/teamsApi'
import { Topic } from 'common/components/topics/interfaces'
import { Cancel } from 'features/campaigns/components/details/CancelButton'
import { updateTopics } from 'features/campaigns/create/contentGeneration/store/contentGenerationSlice'
import {
  addNewCampaign,
  closeDrawer,
  updateCampaignName,
} from 'features/campaigns/store/campaignSlice'

import * as types from '../redux/actionTypes'
import { showBanner } from '../workflow/common/common.actions'

import { VisualisationTemplates } from './CampaignSummary/PhraseeXContent/AdvancedVisualisations/types'
import { CampaignType } from './LanguageApproval/LanguageApproval'
import apiUtil from './utils/Api'
import helpers from './utils/helpers'
import {
  AdvancedVisualisationsDataParams,
  CampaignSetupUiPayback,
  CheetahPatchPayload,
  CheetahRetrieveResultsPayload,
  DistributionChannelType,
  DynamicOptimizationResultType,
  EmarsysCampaign,
  EpsilonCancelCampaignPayload,
  EpsilonCreateSplitsPayload,
  EpsilonInitializeReactCampaignPayload,
  EpsilonScheduleCampaignPayload,
  FacebookResultsPayload,
  Project,
  SplitSizeData,
  SubjectLine,
  UserInput,
} from './interface'

const BANNER_MESSAGES = {
  initializeReactEpsilonCampaignSuccess: 'Experiment successfully initialized',
  epsilonPushVariantsSuccess: 'Variants successfully pushed to Epsilon',
  scheduleEpsilonCampaignSuccess: 'Experiment successfully scheduled',
  deleteEpsilonVariantsSuccess: 'Variants successfully deleted from Epsilon',
  cancelEpsilonCampaignSuccess: 'Experiment cancelled successfully',
  unapprovedCampaign:
    'This message is not approved within Epsilon. You must approve prior to scheduling.',
}

const { REACT_APP_CONNECT_INTERNAL_V1_URL } = process.env

export type AsyncThunkDispatch = ThunkDispatch<RootState, {}, Action>
const makeQueryParam = (resultType?: DynamicOptimizationResultType) => {
  const dataFilter = {
    raw: '?data_filter=raw',
    mature: '?data_filter=mature',
    test: '?data_filter=test',
  }[resultType || '']
  return dataFilter || ''
}
export const saveCampaignName = (
  campaignId,
  newName,
  campaignValidationRules
) => {
  const path = 'campaigns/update-campaign-name'
  const requestBody = {
    campaign_id: campaignId,
    campaign_name: newName,
  }
  const validationResult = helpers.validateCampaignName(
    newName,
    campaignValidationRules
  )
  if (validationResult.isValid) {
    return (dispatch) => {
      dispatch({
        type: types.IS_SAVING,
        isSaving: true,
      })
      return apiUtil(path, {
        method: 'POST',
        body: requestBody,
      })
        .then(() => {
          dispatch({
            type: types.RENAME_CAMPAIGN_SUCCESS,
            isSaving: false,
            newName,
          })

          dispatch(updateCampaignName({ id: campaignId, name: newName }))
        })
        .catch((error) => {
          dispatch({
            type: types.RENAME_CAMPAIGN_FAIL,
            isSaving: false,
            error: error?.message,
          })
        })
    }
  }
  return (dispatch) => {
    dispatch({
      type: types.RENAME_CAMPAIGN_FAIL,
      isSaving: false,
      error: validationResult.validationError,
    })
  }
}

export const setProjectId = (val: string) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_PROJECT_ID,
      id: val,
    })
  }
}

export const setDistributionChannel = (val: DistributionChannelType) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_DISTRIBUTION_CHANNEL,
      distributionChannel: val,
    })
  }
}

export const renameCampaign = (val: string) => {
  return (dispatch) => {
    dispatch({
      type: types.RENAME_CAMPAIGN_SUCCESS,
      newName: val,
    })
  }
}

// Campaign Setup
export async function getUIObjectFromProject(
  projectId: string,
  showBanner: Function
): Promise<CampaignSetupUiPayback> {
  return apiUtil(
    `v1/core/main/projects/${projectId}/setup-campaign-ui`,
    {
      method: 'GET',
    },
    { useConnectApi: true, internal: true, noContentType: false }
  )
    .then((response: any) => {
      return response.data
    })
    .catch((error: any) => {
      showBanner({ content: error.message, type: 'error' })
    })
}
export async function getProjectList(
  distributionChannelType: DistributionChannelType,
  userId: number,
  showBanner: any
): Promise<any> {
  return apiUtil(
    `v1/core/main/users/${userId}/projects?distribution_channel=${distributionChannelType}`,
    {
      method: 'GET',
    },
    { useConnectApi: true, internal: true, noContentType: false }
  )
    .then((response: any) => {
      const projects: Project[] = response.data.projects
      return {
        typeSelected: true,
        distributionChannelType,
        projectsList: projects,
      }
    })
    .catch((error: any) => {
      showBanner({ content: error.message, type: 'error' })
    })
}
export async function retrieveSplitSizeData(
  projectSelected: string,
  splitSizeData: any
): Promise<SplitSizeData | undefined> {
  return apiUtil(
    `projects/${projectSelected}/split_calculator`,
    {
      method: 'POST',
      body: { ...splitSizeData },
    },
    { useConnectApi: false, internal: false, noContentType: false }
  ).then((response: { data: SplitSizeData }) => {
    if (response.data) {
      return response.data
    }
  })
}
export async function retrieveDistributionChannel(
  userId: number,
  showBanner: any
) {
  return apiUtil(
    `v1/core/main/users/${userId}/setup_campaign_ui`,
    {
      method: 'GET',
    },
    { useConnectApi: true, internal: true, noContentType: false }
  )
    .then((response: any) => {
      return response.data.distribution_channels
    })
    .catch((error: any) => {
      showBanner({ content: error.message, type: 'error' })
    })
}
// End CampaignSetup
// Campaign Summary
export const createConfig = (
  campaignId: string,
  projectId: string,
  integrationType: string,
  stack: string,
  cancelType: Cancel | undefined
  // eslint-disable-next-line max-params
) => {
  const isSfmcInt = integrationType === 'sfmcv2'
  const sailthruApiPath = `nedward/unschedule/${campaignId}?project_id=${projectId}`
  const sfmcApiPath = 'maude/cancel'
  const acousticApiPath = 'rod-and-tod/cancel-task'
  const apiPath = isSfmcInt ? sfmcApiPath : sailthruApiPath
  const httpMethod = isSfmcInt ? 'DELETE' : 'PUT'
  const params = {
    campaign_id: campaignId,
    stack,
  }
  if (
    integrationType === 'ibm' ||
    integrationType === 'acoustic' ||
    integrationType === 'ibm watson'
  ) {
    return {
      path: acousticApiPath,
      config: {
        method: 'PATCH',
        body: {
          phraseeProjectID: projectId,
          campaignID: campaignId,
          cancel_type: cancelType,
        },
      },
    }
  }
  return {
    path: apiPath,
    config: {
      method: httpMethod,
      ...(isSfmcInt && { body: params }),
    },
  }
}
export const checkBeforeCancel = (campaignId: string) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'checkBeforeCancel',
        isWaiting: true,
      },
    })
    const apiPath = `rod-and-tod/${campaignId}/cancel-status/`
    return apiUtil(apiPath, {
      method: 'GET',
    })
      .then((response: any) => {
        dispatch({
          type: types.CHECK_BEFORE_CANCEL_SUCCESS,
          acousticCampaignScheduled: true,
          mailingExists: response.data.mailingExists,
          splitsSent: response.data.splitsSent,
          folderName: response.data.folderName,
          isWaitingState: {
            isWaitingFor: 'checkBeforeCancel',
            isWaiting: false,
          },
        })
        return response
      })
      .catch((error: any) => {
        dispatch({
          type: types.CHECK_BEFORE_CANCEL_FAILED,
          acousticCampaignScheduled: false,
          mailingExists: null,
          splitsHasBeenSent: null,
          folderName: null,
          isWaitingState: {
            isWaitingFor: 'checkBeforeCancel',
            isWaiting: false,
          },
        })
      })
  }
}
export const resetAcousticData = () => ({
  type: types.RESET_ACOUSTIC_DATA,
})
export const cancelSends = (
  campaignId: string,
  projectId: string,
  integrationType: string,
  stack: string,
  type: Cancel | undefined,
  onSuccess?: () => void
  // eslint-disable-next-line max-params
) => {
  const options = createConfig(
    campaignId,
    projectId,
    integrationType,
    stack,
    type
  )
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'cancel',
        isWaiting: true,
      },
      sendsCancelled: false,
    })
    return apiUtil(options.path, options.config)
      .then((response: any) => {
        dispatch({
          type: types.CAMPAIGN_CANCELLED_SUCCESSFULLY,
          isWaitingState: {
            isWaitingFor: 'cancel',
            isWaiting: false,
          },
          cancelResponse: response.data,
          sendsCancelled: true,
          integration: integrationType,
          message: response.message,
        })

        onSuccess && onSuccess()
      })
      .catch((error: any) => {
        dispatch({
          type: types.CAMPAIGN_CANCEL_FAILED,
          isWaitingState: {
            isWaitingFor: 'cancel',
            isWaiting: false,
          },
          error: error.message,
        })
      })
  }
}
export const loadResultsDetails = (
  campaignId: string,
  primaryGoal?: string,
  resultType?: DynamicOptimizationResultType
) => {
  return (dispatch) => {
    dispatch({
      type: types.RESULTS_DETAILS_IS_LOADING,
      isLoading: true,
    })
    const apiPath = `campaigns/${campaignId}/results${makeQueryParam(
      resultType
    )}`
    return apiUtil(apiPath, {
      method: 'GET',
    })
      .then((response: any) => {
        let { data } = response
        const columns = data?.split_results?.columns ?? data?.variants?.columns
        if (primaryGoal) {
          data = {
            ...response.data,
            split_results: {
              ...response.data?.split_results,
              columns: filterColumnsByPrimaryGoal(columns),
            },
          }
        }
        dispatch({
          type: types.RESULTS_DETAILS_LOADED,
          isLoading: false,
          results: {
            ...response.data,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.RESULTS_DETAILS_FAILED_LOADING,
          isLoading: false,
          results: null,
          error: error.message,
        })
      })
  }

  function filterColumnsByPrimaryGoal(columns: any[]) {
    if (!primaryGoal) {
      return columns
    }
    switch (primaryGoal) {
      case 'conversion_clicks':
      case 'conversion_pageView':
      case 'conversion_viewedMbox':
        return columns.filter((item) => item.key !== 'page_views')
      case 'engagement_pageView':
        return columns.filter(
          (item) =>
            item.key === 'variant' ||
            item.key === 'num_recipients' ||
            item.key === 'page_views'
        )
      default:
        return columns
    }
  }
}

export const updateResultsVariantStatus = (
  variantId: string,
  variantStatus: string
) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_RESULTS_VARIANTS_STATUS,
      updateData: {
        variantId,
        variantStatus,
      },
    })
  }
}

// TODO: loadFacebookResults must be refactored
export const loadFacebookResults = (
  data: FacebookResultsPayload,
  subjectLines: any[]
) => {
  return async (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      isLoading: true,
    })
    const apiPath = `phacebook/results`
    apiUtil(apiPath, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    })
      .then((response: any) => {
        if (response?.data?.length) {
          const responseData = response?.data.map((item: any) => ({
            ...item,
            sl_id:
              subjectLines.find((sl) => sl.text === item.sl_text)?._id ||
              item._id,
          }))
          const columns: any[] = [
            {
              dataIndex: 'variant',
              key: 'variant',
              title: 'Variants',
            },
          ]
          const values: any = []
          responseData[0]?.columns.forEach((item: any) =>
            columns.push({
              dataIndex: item.name,
              key: item.name,
              title: item.name,
            })
          )
          responseData?.forEach((item: any, index: number) => {
            const rowData = {
              variant: item.sl_text,
              _id: item.sl_id,
              key: index,
              ownsl: item.ownsl,
            }
            item?.columns.forEach((col) => {
              rowData[col.name] =
                col.value && col.value !== 'NaN' ? col.value : 0
            })
            values.push(rowData)
          })
          const data = {
            variants: {
              columns,
              values,
            },
          }
          dispatch({
            type: types.RESULTS_DETAILS_LOADED,
            isLoading: false,
            results: data,
          })
        } else {
          apiUtil(`campaigns/${data.campaign_id}/results`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          })
            .then((response: any) => {
              dispatch({
                type: types.RESULTS_DETAILS_LOADED,
                isLoading: false,
                results: response.data,
              })
            })
            .catch((error: any) => {
              dispatch({
                type: types.RESULTS_DETAILS_FAILED_LOADING,
                isLoading: false,
                results: null,
                error: error.message,
              })
            })
        }
      })
      .catch((error: any) => {
        dispatch({
          type: types.RESULTS_DETAILS_FAILED_LOADING,
          isLoading: false,
          results: null,
          error: error.message,
        })
      })
  }
}

export const loadSummaryDetails = (campaignId: string, projectId: string) => {
  return (dispatch) => {
    const apiPath = `nedward/campaign/summary/${campaignId}?project_id=${projectId}`
    return apiUtil(apiPath, {
      method: 'GET',
    })
      .then((response: any) => {
        dispatch({
          type: types.SUMMARY_DETAILS_LOADED,
          isLoading: false,
          summary: response.data,
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.SUMMARY_DETAILS_FAILED_LOADING,
          isLoading: false,
          summary: null,
          error: error.message,
        })
      })
  }
}
export const updateSummary = (summaryObject: any) => {
  return {
    type: types.UPDATE_SUMMARY,
    summary: summaryObject,
    isWaitingState: {
      isWaitingFor: 'cancel',
      isWaiting: false,
    },
  }
}
// End Campaign Summary
// Language Generation
/**
 * Callled when the user presses the Generate to generate push notifications on step 2.
 * @param {string} token - The users access token
 * @param {string} campaignId - Id of the campaign created in step 1
 * @param {string} project_id - Id of the project
 * @param {Object} questions_and_ans - Object containing step 2 questions and answers
 * @param {boolean} regenerate - Indicates whether we should regenerate push notifs
 */

export function generateSubjectLines(config: any) {
  return (dispatch) => {
    const {
      token,
      campaign_id,
      project_id,
      questions_and_ans,
      regenerate,
      subjectlines,
      isNoAdBody,
      currencyPattern,
      skipPoochieValidation,
    } = config
    dispatch({
      type: types.STEP_TWO_UPDATE_NER_VALIDATION_MODAL,
      message: '',
      visible: false,
    })
    if (regenerate) {
      dispatch({
        type: types.STEP_TWO_SUBMITTING,
        loading: true,
      })
      const requestBody = {
        save_and_exit: false,
        save_and_continue: true,
        step: 'language_generation',
        data: {
          project_id,
          campaign_id,
          questions: questions_and_ans,
          isNoAdBody,
          currency_pattern: currencyPattern,
          skipPoochieValidation,
        },
      }
      apiUtil('campaigns', {
        method: 'PATCH',
        body: requestBody,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: any) => {
          dispatch({
            type: types.STEP_TWO_SUCCESS_ANIMATION,
            slGeneratingStatus: true,
          })
          const { data } = response
          // check if poochie validation failed
          // in case of fail, show modal with customer_message
          // also check that skipPoochieValidation was not set to true
          // skipPoochieValidation = true shows that user chose to continue after fail
          const nerValidation = data.ner_validation || {}
          if (
            nerValidation.status === 'fail' &&
            requestBody.data.skipPoochieValidation !== true
          ) {
            let customerMessage = ''
            try {
              customerMessage =
                data.ner_validation.test_result.customer_message[0]
                  .customer_msg_text
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err)
            }
            dispatch({
              type: types.STEP_TWO_UPDATE_NER_VALIDATION_MODAL,
              message: customerMessage,
              visible: true,
            })
          } else {
            dispatch({
              type: types.UPDATE_CAMPAIGN_DATA,
              campaignData: response.data,
              isSaveExit: response.data.save_and_exit,
              isSaveContinue: response.data.save_and_continue,
              isWaitingState: {
                isWaitingFor: 'saveCampaign',
                isWaiting: false,
              },
            })
            dispatch({
              type: types.STEP_TWO_SUBMIT_SUCCESS,
              loading: false,
              subjectlines: response.data,
              regenerate,
              campaignData: {
                steps: response.data.steps,
              },
            })
          }
        })
        .catch((error: any) => {
          dispatch({
            type: types.STEP_TWO_SUBMIT_ERROR,
            message: error.message,
            loading: false,
          })
        })
    } else {
      dispatch({
        type: types.STEP_TWO_SUCCESS_ANIMATION,
        slGeneratingStatus: true,
      })
      dispatch({
        type: types.SET_WORKFLOW_NAVIGATION,
        isUserInput: false,
        chosenStep: 'language_approval',
      })
      dispatch({
        type: types.STEP_TWO_SUBMIT_SUCCESS,
        loading: false,
        regenerate: false,
        subjectlines,
      })
    }
  }
}
/**
 * Setter for the days to go prop
 * @param {Object} daysToGoDate - Moment object
 */
export function setDaysToGo(daysToGoDate: Date | string) {
  return (dispatch) => {
    dispatch({ type: types.STEP_TWO_SET_DAYS_TO_GO, daysToGoDate })
  }
}
/**
 * Setter for the generator animation values
 * @param {string} imagePath - The image to show in the animation
 * @param {string} text - The text to show below the image
 */
export function setAnimation(imagePath: string, text: string) {
  return (dispatch) => {
    dispatch({
      type: types.STEP_TWO_SET_ANIMATION_IMAGE,
      animationImage: imagePath,
      animationText: text,
    })
  }
}
/**
 * Setter for the animation percentage progress value for the generator animation
 * @param {Number} progress - The percentage progress that has been made
 */
export function setAnimationPercentage(progress: number) {
  return (dispatch) => {
    dispatch({
      type: types.STEP_TWO_SET_ANIMATION_PERCENTAGE,
      percentage: progress,
    })
  }
}
/**
 * Update the animation progress
 * @param {Number} animationStep
 */
export function updatePushAnimationProgress(animationStep: number) {
  return (dispatch) => {
    dispatch({
      type: types.STEP_TWO_UPDATE_GENERATION_ANIMATION_STEP,
      slAnimationProgress: animationStep,
    })
  }
}
/**
 * Called when the user makes changes to input values in step 2
 * @param {Object} values - Object containing new values
 * @param {Object} conditionalHiddenStatus
 * - Contains the new status for fields which can be conditionally hidden
 * @param {Object} conditionalOpenParents
 * - Contains the parents of conditional fields which are currently open
 * @param {Object} validationErrors - Contains updated validation errors to show to the user
 * @param {Boolean} isQuestionLoaded - Tells if question was loaded
 * @param {Object} secondaryValues - Values that may also be sent as part of the generation request
 */
// eslint-disable-next-line max-params
export function setNewValues(
  values: any,
  conditionalHiddenStatus: any,
  conditionalOpenParents: any,
  validationErrors: any,
  isQuestionLoaded: boolean,
  secondaryValues: any
) {
  return (dispatch) => {
    dispatch({
      type: types.STEP_TWO_SET_NEW_VALUES,
      values,
      conditionalHiddenStatus,
      conditionalOpenParents,
      validationErrors,
      isQuestionLoaded,
      secondaryValues,
    })
  }
}
/**
 * API call for getting the questions for a given grammar
 * @param {string} token - The users access token
 * @param {string} campaignId - Id of the campaign created in step 1
 * @param {string} grammarId - Id of the grammar selected in step 1
 */
export function getQuestions(campaignId: string, grammarId: string) {
  return (dispatch) => {
    const requestBody = {
      grammar_id: grammarId,
      campaign_id: campaignId,
    }
    dispatch({ type: types.STEP_TWO_QUESTIONS_LOADING, loading: true })
    apiUtil('campaigns/fetch_questions', {
      method: 'POST',
      body: requestBody,
    })
      .then((response: any) => {
        dispatch({
          type: types.STEP_TWO_QUESTIONS_SUCCESS,
          questions: response.data.fields,
          grammar: response.data.name,
          currencyPattern: response.data.currency_pattern || '',
          loading: false,
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.STEP_TWO_QUESTIONS_ERROR,
          message: `Error in fetching projects: ${error.message}`,
          loading: false,
        })
      })
  }
}
export function getCampaignCommon(data: any) {
  return function (dispatch) {
    dispatch({
      type: types.CAMPAIGN_LOADING,
      loading: true,
    })
    apiUtil('campaigns/view', {
      method: 'POST',
      body: data,
    })
      .then((response: any) => {
        const campaign_data = response.data || {}
        campaign_data.id = data.campaign_id
        dispatch({
          type: types.CAMPAIGN_LOADING_SUCCESS_STEP_TWO,
          campaign_data,
          loading: false,
        })
      })
      .catch((error: any) => {
        if (error.status === 401) {
          dispatch({
            type: types.CAMPAIGN_LOADING_ERROR,
            campaignViewErr: true,
            errMsg: error.message,
            loading: false,
          })
        } else {
          dispatch({
            type: types.CAMPAIGN_LOADING_ERROR,
            campaignViewErr: false,
            errMsg: error.message,
            loading: false,
          })
        }
      })
  }
}
/**
 * Called when we need to update whether the regenerate modal is visible
 * @param {boolean} regenerateModalVisible - True if the modal should be visible
 */
export function updateRegenerateModalVisible(regenerateModalVisible: boolean) {
  return (dispatch) => {
    dispatch({
      type: types.STEP_TWO_UPDATE_REGENERATE_MODAL,
      regenerateModalVisible,
    })
  }
}
/**
 * Updates the validation errors
 * @param {Object} validationErrors - The updated validation errors
 */
export function updateValidationErrors(validationErrors: any) {
  return (dispatch) => {
    dispatch({
      type: types.STEP_TWO_UPDATE_VALIDATION_ERRORS,
      validationErrors,
    })
    return Promise.resolve()
  }
}
/**
 * Called when the user presses the button to edit step 2
 * @param {Object} data - The edit data object
 */
export function editStepTwo(data: any) {
  return function (dispatch) {
    dispatch({
      type: types.STEP_EDITING,
      current_step: data.current_step,
      previous_step: data.previous_step,
      currentStep: data.currentStep,
      previousStep: data.previousStep,
      editing_step: data.editing_step,
    })
    if (!data.isCancel) {
      dispatch({ type: types.CURRENT_EDITING_STEP, step: 2 })
    }
  }
}

export function alignTopCampaignStep(step: any) {
  return function (dispatch) {
    dispatch({
      type: types.CAMPAIGN_LOAD_ON_TOP,
      step,
    })
  }
}
// show/hide the ner validation modal, update the message
export function updateNerValidationModal(config: any) {
  return {
    type: types.STEP_TWO_UPDATE_NER_VALIDATION_MODAL,
    message: config.message,
    visible: config.visible,
  }
}
// End of Language Generation
// New campaign data endpoint

export const fetchCampaignData = (campaignId: string) => {
  return async (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    dispatch({
      type: types.CAMPAIGN_DETAILS_LOADING,
      typeOfIntegration: '',
      isWaitingState: {
        isWaitingFor: 'campaignsLoaded',
        isWaiting: true,
      },
    })
    return apiUtil(`campaigns/${campaignId}?includeDeleted=true`, {
      method: 'GET',
    })
      .then((response: any) => {
        if (getState().campaigns.isNewCampaign) {
          return
        }
        return dispatch({
          type: types.CAMPAIGN_DETAILS_LOADED,
          isLoading: false,
          campaignData: response.data,
          isWaitingState: {
            isWaitingFor: 'campaignsLoaded',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        return dispatch({
          type: types.CAMPAIGN_DETAILS_FAILED_LOADING,
          error: error.message,
          isLoading: false,
          isWaitingState: {
            isWaitingFor: 'campaignsLoaded',
            isWaiting: false,
          },
        })
      })
  }
}

export function fetchLatestVariants(campaignId: string) {
  return (dispatch) => {
    return apiUtil(`campaigns/${campaignId}?includeDeleted=true`, {
      method: 'GET',
    })
      .then((response: any) => {
        return dispatch({
          type: types.LATEST_VARIANTS_LOADED,
          variants: response.data.sl,
        })
      })
      .catch((error: any) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
  }
}
export function downloadCheetahTXTFile(id: string, token: string) {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      csv: null,
      isWaitingState: {
        isWaitingFor: 'downloadTXT',
        isWaiting: true,
      },
    })
    apiUtil(`v1/intecampaigns/${id}/txt`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        dispatch({
          type: types.DOWNLOAD_RESUlTS_FILE,
          csv: response,
          isWaitingState: {
            isWaitingFor: 'downloadCSV',
            isWaiting: false,
          },
        })
      })
      .catch((error: Error) => {
        dispatch({
          type: types.DOWNLOAD_RESUlTS_FILE_FAIL,
          error: error.message,
          csv: null,
          isWaitingState: {
            isWaitingFor: 'downloadCSV',
            isWaiting: false,
          },
        })
      })
  }
}
export function downloadCSVTemplate(id: string) {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      csv: null,
      isWaitingState: {
        isWaitingFor: 'downloadCSV',
        isWaiting: true,
      },
    })
    apiUtil(`campaigns/${id}/getResultsCSV`, {
      method: 'GET',
    })
      .then((response: any) => {
        dispatch({
          type: types.DOWNLOAD_RESUlTS_FILE,
          csv: response,
          isWaitingState: {
            isWaitingFor: 'downloadCSV',
            isWaiting: false,
          },
        })
      })
      .catch((error: Error) => {
        dispatch({
          type: types.DOWNLOAD_RESUlTS_FILE_FAIL,
          error: error.message,
          csv: null,
          isWaitingState: {
            isWaitingFor: 'downloadCSV',
            isWaiting: false,
          },
        })
      })
  }
}
export function uploadCSVTemplate(id: string, csv: File) {
  const formdata = new FormData()
  formdata.append('results', csv)
  return (dispatch: any) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'uploadFile',
        isWaiting: true,
      },
    })
    apiUtil(
      `campaigns/${id}/results-csv`,
      {
        method: 'POST',
        body: formdata,
        headers: {
          Accept: '*/*',
        },
      },
      { useConnectApi: false, internal: false, noContentType: true }
    )
      .then((response: any) => {
        dispatch({
          type: types.UPLOAD_RESUlTS_FILE,
          results: response.data,
          warnings: response.data.warnings,
          isWaitingState: {
            isWaitingFor: 'uploadFile',
            isWaiting: false,
          },
        })
        dispatch(fetchCampaignData(id))
      })
      .catch((error: Error) => {
        dispatch({
          type: types.UPLOAD_RESUlTS_FILE_FAIL,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'uploadFile',
            isWaiting: false,
          },
        })
      })
  }
}

export const removeManagedCampaignFile = (campaignId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'unlinkManagedCampaignFile',
        isWaiting: true,
      },
    })
    await apiUtil(
      `campaigns/${campaignId}/remove-managed-file`,
      {
        method: 'PATCH',
        body: {},
        headers: {
          Accept: '*/*',
        },
      },
      { useConnectApi: false, internal: false, noContentType: true }
    )
      .then((response: any) => {
        dispatch({
          type: types.UNLINK_MANAGED_FILE,
          isWaitingState: {
            isWaitingFor: 'unlinkManagedCampaignFile',
            isWaiting: false,
          },
        })
      })
      .catch((error: Error) => {
        dispatch({
          type: types.UNLINK_MANAGED_FILE_FAIL,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'unlinkManagedCampaignFile',
            isWaiting: false,
          },
        })
      })
  }
}

export const uploadManagedCampaignFile = (
  campaignId: string,
  file: any,
  updloadList: any
) => {
  const formdata = new FormData()
  formdata.append('file', file)
  return async (dispatch: any) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'uploadManagedCampaignFile',
        isWaiting: true,
      },
    })
    await apiUtil(
      `campaigns/${campaignId}/managed-file`,
      {
        method: 'POST',
        body: formdata,
        contentType: 'multipart/form-data',
        headers: {
          Accept: '*/*',
        },
      },
      { useConnectApi: false, internal: false, noContentType: true }
    )
      .then(() => {
        updloadList('done')
        dispatch({
          type: types.UPLOAD_MANAGED_FILE,
          isWaitingState: {
            isWaitingFor: 'uploadManagedCampaignFile',
            isWaiting: false,
          },
        })
      })
      .catch((error: Error) => {
        updloadList('error')
        dispatch({
          type: types.UPLOAD_MANAGED_FILE_FAIL,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'uploadManagedCampaignFile',
            isWaiting: false,
          },
        })
      })
  }
}

export const fetchAccountTeamsList = (accountId: string) => {
  return async (dispatch: any) => {
    return await getTeams(accountId)
      .then((response) => {
        dispatch({
          type: types.FETCH_ACCOUNT_TEAMS_LIST_SUCCESS,
          isLoading: false,
          teamsList: response.data,
        })
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_ACCOUNT_TEAMS_LIST_FAIL,
          error: error.message,
          isLoading: false,
        })
      })
  }
}

export const fetchNfmAndTweakLimit = (campaignId: string, line: any) => {
  return (dispatch: any) => {
    return apiUtil(`campaigns/${campaignId}`, {
      method: 'GET',
    })
      .then((response: any) => {
        dispatch({
          type: types.FETCH_NFM_TWEAK_LIMIT_SUCCESS,
          isLoading: false,
          nfmUsed: response.data.nfm_used,
          tweakUsed: response.data.tweak_used,
          newlinesInVariants:
            response.data.campaign_configuration.validation_rules
              .newlines_in_variants,
          line,
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_NFM_TWEAK_LIMIT_FAIL,
          error: error.message,
          isLoading: false,
        })
      })
  }
}

export const clickCloseBoomModal = () => {
  return {
    type: types.CLICK_CLOSE_BOOM_MODAL,
  }
}

export function setUserInputForNavigation(config: UserInput) {
  return function (dispatch) {
    dispatch({
      type: types.SET_WORKFLOW_NAVIGATION,
      isUserInput: config.isUserInput,
      chosenStep: config.chosenStep,
    })
  }
}
/**
 * @param {string} token - The users access token
 * @param {Object} data: {
    save_and_exit: bool,
    save_and_continue: bool,
    step: string,
 }
 */
export function saveCampaign(data: any) {
  let isWaitingFor

  if (data.save_and_exit) {
    isWaitingFor = 'SaveAndExit'
  } else if (data.save_and_continue) {
    isWaitingFor = 'SaveAndContinue'
  }

  return (dispatch: any, getState: () => RootState) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor,
        isWaiting: true,
      },
    })
    return apiUtil('campaigns', {
      method: 'PATCH',
      body: data,
    })
      .then((response: any) => {
        const campaignData = response.data

        dispatch({
          type: types.UPDATE_CAMPAIGN_DATA,
          campaignData,
          isSaveExit: data.save_and_exit,
          isSaveContinue: data.save_and_continue,
          isWaitingState: {
            isWaitingFor,
            isWaiting: false,
          },
        })

        const { isNewCampaign } = getState().campaigns

        if (isNewCampaign) {
          const { firstName, lastName } = getState().authStates
          dispatch(
            addNewCampaign({
              campaign: {
                id: campaignData._id,
                name: campaignData.name,
                projectId: campaignData.project_id,
                projectName: campaignData.project_name,
                distributionChannel:
                  campaignData.campaign_configuration.distribution_channel,
                status: 'not_completed',
                created: campaignData.created,
                userId: campaignData.user_id,
                grammarId: campaignData.grammar_id,
                user: { firstName, lastName },
                isMismatchingConfiguration: false,
              },
            })
          )
        }

        return true
      })
      .catch((error: Error) => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_DATA_FAIL,
          error: error.message,
          isWaitingState: {
            isWaitingFor,
            isWaiting: false,
          },
        })

        return false
      })
  }
}

/** This action modifies a control subject line using the subjectline PATCH endpoint
 *  and updates the redux store with the new control/human subjectline
 * @param { SubjectLine } subjectLine - Control/Human SubjectLine object where subjectLine.ownsl = true
 */
export function updateControlSubjectLine(subjectLine: SubjectLine) {
  return async (dispatch: any) => {
    dispatch({
      type: types.CONTROL_SUBJECT_LINE_LOADING,
    })
    try {
      const response = await apiUtil(
        `v1/core/monorail/variants/${subjectLine._id}`,
        { method: 'PATCH', body: subjectLine },
        {
          useConnectApi: true,
          internal: true,
        }
      )
      dispatch({
        type: types.CONTROL_SUBJECT_LINE_UPDATED,
        newSubjectLine: response.data,
      })
    } catch (error: any) {
      dispatch({
        type: types.CONTROL_SUBJECT_LINE_FAILED,
        error: typeof error === 'string' ? error : error.message,
      })
    }
  }
}

// The old campaign data endpoint
export function getCampaign(data: any) {
  return function (dispatch) {
    dispatch({
      type: types.LANGUAGE_APPROVAL_CAMPAIGN_LOADING,
      languageApprovalLoading: true,
    })
    apiUtil('campaigns/view', { method: 'POST', body: data })
      .then((response: any) => {
        const campaign_data = response.data || {}
        campaign_data.id = data.campaign_id
        dispatch({
          type: types.LANGUAGE_APPROVAL_CAMPAIGN_LOADING_SUCCESS,
          campaign_data,
          languageApprovalLoading: false,
        })
      })
      .catch((error: any) => {
        if (error.status === 401) {
          dispatch({
            type: types.LANGUAGE_APPROVAL_CAMPAIGN_LOADING_ERROR,
            campaignViewErr: true,
            errMsg: error.message,
            languageApprovalLoading: false,
          })
        } else {
          dispatch({
            type: types.LANGUAGE_APPROVAL_CAMPAIGN_LOADING_ERROR,
            campaignViewErr: false,
            errMsg: error.message,
            languageApprovalLoading: false,
          })
        }
      })
  }
}
export function tweakALine(data: any, campaignType: CampaignType) {
  return async function (dispatch) {
    dispatch({
      type: types.LANGUAGE_APPROVAL_TWEAK_LINE_LOADING,
      tweakingLoading: true,
    })
    let urlToTweakLine

    if (campaignType === 'linguo') {
      urlToTweakLine = `subjectline/${data.line_id}/feedback`
      data.feedback_type = 'tweak'
      data.old_sl_id = data.line_id
      data.replace = !data.feedbackOnly
    } else {
      if (data.feedbackOnly) {
        urlToTweakLine = 'subjectline/tweak-subjectline-feedback-only'
      } else {
        urlToTweakLine = 'subjectline/tweak-subjectline'
      }
    }

    return apiUtil(urlToTweakLine, { method: 'POST', body: data })
      .then((response: any) => {
        if (!data.feedbackOnly) {
          dispatch({
            type: types.LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS,
            tweakedData: data,
            modifiedData: response.data,
            showTweakLine: false,
            lineBeingTweaked: response.data,
            tweakingLoading: false,
          })
        } else {
          dispatch({
            type: types.LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS_FEEDBACK_ONLY,
            showTweakLine: false,
            tweakingLoading: false,
            tweakedLineData: {
              id: data.line_id,
              why_tweak: data.why_tweak,
            },
          })
          dispatch(
            showBanner({
              content: 'Your feedback has been requested.',
              type: 'success',
            })
          )
        }
      })
      .catch((error: Error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.LANGUAGE_APPROVAL_TWEAK_LINE_ERROR,
          tweakingLoading: false,
        })
      })
      .finally(() =>
        // fulfilled or rejected, re-fetch latest variants from DB
        // so that UI knows which ones still need approval after tweaking a line
        dispatch(fetchLatestVariants(data?.campaign_id))
      )
  }
}

export function rejectALine(
  data: any,
  campaignType: CampaignType,
  linguoData: { isLinguo: boolean; isInitialized: boolean } = {
    isLinguo: false,
    isInitialized: false,
  }
) {
  return async function (dispatch) {
    let urlToRejectLine: string

    dispatch({
      type: types.LANGUAGE_APPROVAL_REJECT_LINE,
      rejectLineLoading: true,
    })
    if (campaignType === 'linguo') {
      urlToRejectLine = `subjectline/${data.line_id}/feedback`
      data.feedback_type = 'reject'
      data.replace = !data.feedbackOnly
    } else {
      if (data.feedbackOnly) {
        urlToRejectLine = 'subjectline/reject-subjectline-feedback-only'
      } else {
        urlToRejectLine = 'subjectline/reject-subjectline'
      }
    }
    return apiUtil(urlToRejectLine, { method: 'POST', body: data })
      .then((response: any) => {
        if (!data.feedbackOnly) {
          dispatch({
            type: types.LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS,
            rejectedData: data,
            newData: response.data,
            rejectLineLoading: false,
            showTweakLine: false,
            lineBeingTweaked: {},
            linguoData,
          })
        } else {
          dispatch({
            type: types.LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS_FEEDBACK_ONLY,
            rejectLineLoading: false,
            showTweakLine: false,
            rejectedLineData: {
              id: data.line_id,
              why_dislike: data.why_dislike,
            },
          })
          dispatch(
            showBanner({
              content: 'Your feedback has been requested.',
              type: 'success',
            })
          )
        }
      })
      .catch((error: Error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.LANGUAGE_APPROVAL_REJECT_LINE_ERROR,
          rejectLineLoading: false,
        })
        throw error
      })
  }
}
export function openTweakLine(line: any) {
  return function (dispatch) {
    dispatch({
      type: types.LANGUAGE_APPROVAL_OPEN_TWEAK_LINE,
      line,
      showTweakLine: true,
    })
  }
}

export function closeTweakLine() {
  return function (dispatch) {
    dispatch({
      type: types.LANGUAGE_APPROVAL_CLOSE_TWEAK_LINE,
      showTweakLine: false,
      lineBeingTweaked: {},
    })
  }
}
export function saveLanguageApproval(data: any, hasUnsavedData: boolean) {
  return async function (dispatch) {
    dispatch({
      type: types.LANGUAGE_APPROVAL_SAVING,
      languageApprovalLoading: true,
    })
    return apiUtil('campaigns', {
      method: 'PATCH',
      body: data,
    })
      .then((response: any) => {
        if (hasUnsavedData) {
          dispatch(
            showBanner({
              content: 'Feedback saved successfully.',
              type: 'success',
            })
          )
          dispatch({
            type: types.LANGUAGE_APPROVAL_SAVING,
            languageApprovalLoading: false,
          })
        } else {
          dispatch({
            type: types.LANGUAGE_APPROVAL_SAVING_SUCCESS,
            campaignData: response.data,
            languageApprovalLoading: false,
          })
        }
      })
      .catch((error: Error) => {
        dispatch({
          type: types.LANGUAGE_APPROVAL_SAVING_ERROR,
          message: error.message,
          languageApprovalLoading: false,
        })
        dispatch(
          showBanner({
            content: `There was an error. ${error.message || ''}`,
            type: 'error',
          })
        )
      })
  }
}

export function fetchMovableInkApiKeys() {
  return function (dispatch) {
    dispatch({
      type: types.FETCHING_MOVABLE_INK_API_KEYS,
      fetchingMovableInkApiKeys: true,
    })
    apiUtil('users/movable-ink-api-keys', {
      method: 'GET',
    })
      .then((response: any) => {
        dispatch({
          type: types.FETCHING_MOVABLE_INK_API_KEYS_SUCCESS,
          keys: response.data,
        })
      })
      .catch((error: Error) => {
        dispatch({
          type: types.FETCHING_MOVABLE_INK_API_KEYS_ERROR,
          message: error.message,
        })
      })
  }
}

export function clearMovableInkTestData(campaignId: string) {
  const data: any = {}
  data.campaignId = campaignId
  return function (dispatch) {
    dispatch({
      type: types.CLEARING_MOVABLE_INK_TEST_DATA,
    })
    apiUtil(`campaigns/${campaignId}/clear-movable-ink-test-data`, {
      method: 'DELETE',
      body: data,
    })
      .then((response: any) => {
        dispatch(
          showBanner({
            content: 'Movable Ink test data has been cleared.',
            type: 'success',
          })
        )
        dispatch({
          type: types.CLEAR_MOVABLE_INK_TEST_DATA_SUCCESS,
          keys: response.data,
        })
      })
      .catch((error: Error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.CLEAR_MOVABLE_INK_TEST_DATA_ERROR,
          message: error.message,
        })
      })
  }
}

// eslint-disable-next-line max-params
export function updateMovableInkIntegrationData(
  campaignId: string,
  startDateTime: any,
  endDateTime: any,
  handleSuccess: () => void,
  handleError: (err: string) => void
) {
  const data: any = {}
  data.campaignId = campaignId
  data.startDateTime = startDateTime
  data.endDateTime = endDateTime

  return function (dispatch) {
    dispatch({
      type: types.UPDATING_MOVABLE_INK_INTEGRATION_DATA,
    })
    apiUtil(`campaigns/${campaignId}/update-movable-ink-integration-data`, {
      method: 'PUT',
      body: data,
    })
      .then((response: any) => {
        const integrationData = {
          expected_start_time: startDateTime,
          expected_end_time: endDateTime,
        }
        dispatch(
          showBanner({
            content: 'Movable Ink integration data has been updated.',
            type: 'success',
          })
        )
        dispatch({
          type: types.UPDATE_MOVABLE_INK_INTEGRATION_DATA_SUCCESS,
          integrationData,
        })
        handleSuccess()
      })
      .catch((error: Error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.UPDATE_MOVABLE_INK_INTEGRATION_DATA_ERROR,
          message: error.message,
        })
        handleError(error.message)
      })
  }
}

// Integration options actions
const failedToFetchConvertedMessage =
  'There has been a network timeout issue while scheduling. However an experiment' +
  ' may have been scheduled, please go to the dashboard to check and cancel & reschedule' +
  ' if necessary.'

export const resetReduxForm = () => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_REDUX_FORM,
    })
  }
}

export const shareAMPScriptAction = (
  campaign_id: string,
  campaign_name: string,
  ampScript: string,
  recipients: any,
  token: string
  // eslint-disable-next-line max-params
) => {
  return async () => {
    const payload = {
      notification_type: 'sfmc-approvals',
      campaign_id,
      properties: {
        campaign: campaign_name,
        approvals_amp_script: ampScript,
      },
      recipients,
      notification_recipients: 'only-request-recipients',
    }
    await apiUtil('notifications/notify/', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }
}
export const scheduleAcousticCampaign = (
  params: any,
  onResponse: () => void
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'schedule',
        isWaiting: true,
      },
    })
    dispatch({
      type: types.SUBMITTED_FOR_SCHEDULING,
    })
    return apiUtil('rod-and-tod/schedule-splits-background', {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then((response: any) => {
        dispatch({
          type: types.SCHEDULE_ACOUSTIC_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'schedule',
            isWaiting: false,
          },
          scheduleResponse: response.data,
          acousticCampaignScheduled: true,
        })
      })
      .catch((error: any) => {
        const isPossibleNetworkFailure = !error.status && !error.data
        dispatch({
          type: types.SCHEDULE_ACOUSTIC_CAMPAIGN_FAIL,
          isWaitingState: {
            isWaitingFor: 'schedule',
            isWaiting: false,
          },
          error: isPossibleNetworkFailure
            ? failedToFetchConvertedMessage
            : error.message,
        })
      })
      .finally(() => onResponse())
  }
}
export const resetActionsStates = (actionType: string) => {
  return (dispatch) => {
    dispatch({
      type: actionType,
    })
  }
}
export const getMID = (project_id: string) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_MID_LOADING,
      isWaitingState: {
        isWaiting: true,
        isWaitingFor: types.GET_MID_LOADING,
      },
      midList: null,
      midListError: null,
    })
    const url = `maude/project/${project_id}/business_units`
    try {
      const response = await apiUtil(url, {
        method: 'GET',
      })
      dispatch({
        type: types.GET_MID_SUCCESS,
        isWaitingState: {
          isWaiting: false,
          isWaitingFor: types.GET_MID_LOADING,
        },
        midList: response.data,
        midListError: null,
      })
    } catch (error) {
      dispatch({
        type: types.GET_MID_ERROR,
        isWaitingState: {
          isWaiting: false,
          isWaitingFor: types.GET_MID_LOADING,
        },
        midList: null,
        midListError: error,
      })
    }
  }
}

export const fetchApprovalsAMPScript = (
  campaign_id: string,
  project_id: string,
  campaign_name: string,
  human_line: string,
  num_splits: number,
  token: string
  // eslint-disable-next-line max-params
) => {
  return async (dispatch) => {
    dispatch({
      type: types.AMP_SCRIPT_LOADING,
    })
    const payload = {
      campaign_id,
      project_id,
      campaign_name,
      human_line,
      num_splits,
    }
    try {
      const response = await apiUtil('maude/generate_amp_script', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      dispatch({
        type: types.AMP_SCRIPT_LOAD_SUCCESS,
        ampScript: response.data.data,
      })
    } catch (error) {
      dispatch({
        type: types.AMP_SCRIPT_LOAD_ERROR,
        error,
      })
    }
  }
}

export const fetchUnscheduledCampaigns = (projectId: string) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      isLoading: true,
    })
    return apiUtil(`nedward/campaign/unscheduled?project_id=${projectId}`, {
      method: 'GET',
    })
      .then((response: any) => {
        dispatch({
          type: types.UNSCHEDULED_CAMPAIGNS_LOADED,
          isLoading: false,
          listCampaigns: response.data,
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.UNSCHEDULED_CAMPAIGNS_FAILED_LOADING,
          error: error.message,
          isLoading: false,
        })
      })
  }
}

export const setCampaignToken = (campaignToken: string) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_CAMPAIGN_TOKEN,
      campaignToken,
    })
  }
}

export const scheduleSFMCCampaign = (params: any, token: string) => {
  return (dispatch) => {
    const process =
      params.type === 'resource_creation' ? 'resource_creation' : 'schedule'
    dispatch({
      type: types.RESET_ENGAGE_SFMC_STATE,
    })
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: process,
        isWaiting: true,
      },
    })
    return apiUtil('maude/schedule', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        dispatch({
          type: types.SCHEDULE_SFMC_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: process,
            isWaiting: false,
          },
          scheduleResponse: response.data,
        })
      })
      .catch((error: any) => {
        const isPossibleNetworkFailure = !error.status && !error.data
        dispatch({
          type: types.SCHEDULE_SFMC_CAMPAIGN_FAIL,
          isWaitingState: {
            isWaitingFor: process,
            isWaiting: false,
          },
          engageSfmcError: isPossibleNetworkFailure
            ? failedToFetchConvertedMessage
            : error.message,
        })
      })
  }
}
export const updateSubjectLineSailthruCampaign = (params: any) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      isLoading: true,
    })
    return apiUtil('nedward/blast/zephyrscript', {
      method: 'PUT',
      body: {
        split_campaign_date_time: params.scheduleDateTime,
        final_campaign_id: params.finalCampaign.toString(),
        split_campaign_id: params.splitCampaign.toString(),
        time_before_to_schedule_final: params.hoursBeforeFinalDateTime,
        project_id: params.projectId,
        campaign_id: params.campaignId,
      },
    })
      .then((response: any) => {
        dispatch(showBanner({ content: response.data, type: 'success' }))
        dispatch({
          type: types.UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_SUCCESS,
          isLoading: false,
        })
      })
      .catch((error: any) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_FAIL,
          isLoading: false,
        })
      })
  }
}
export const fetchSendDefinitions = (
  clientId: string,
  token: string,
  stack = 's10',
  projectId: string
  // eslint-disable-next-line max-params
) => {
  return (dispatch) => {
    dispatch({
      type: types.SEND_DEFINITION_LIST_IS_LOADING,
      isSendDefinitionLoading: true,
    })
    return apiUtil(
      `maude/send_definition?client_id=${clientId}&stack=${stack}&project_id=${projectId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response: any) => {
        dispatch({
          type: types.USER_INITIATED_EMAILS_LOADED,
          isSendDefinitionLoading: false,
          isScheduleButtonDisabled: false,
          emailList: response.data,
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.USER_INITIATED_EMAILS_FETCH_FAILED,
          isSendDefinitionLoading: false,
          isScheduleButtonDisabled: true,
          engageSfmcError: error.message,
          isWaitingState: {
            isWaiting: false,
            isWaitingFor: types.USER_INITIATED_EMAILS_FETCH_FAILED,
          },
        })
      })
  }
}
export const setSplitCampaignId = (splitCampaignId: string) => {
  return {
    type: types.SET_SPLIT_CAMPAIGN_ID,
    payload: splitCampaignId,
  }
}
export const setFinalCampaignId = (finalCampaignId: string) => {
  return {
    type: types.SET_FINAL_CAMPAIGN_ID,
    payload: finalCampaignId,
  }
}
export const setTimeBeforeFinalDate = (time: any) => {
  return {
    type: types.SET_TIME_BEFORE_FINAL_DATE,
    payload: time,
  }
}

export const setDateForScheduleCampaign = (date: any) => {
  return {
    type: types.SET_SCHEDULE_DATE,
    payload: date,
  }
}

export const setTimeForScheduleCampaign = (time: any) => {
  return {
    type: types.SET_SCHEDULE_TIME,
    payload: time,
  }
}

export const getAdvancedVisualisationsData = ({
  requestedGraphTemplate,
  campaignId,
  shouldResetState,
  resultType,
}: AdvancedVisualisationsDataParams) => {
  if (shouldResetState) {
    return async (dispatch: Function) => {
      dispatch({
        type: types.ADVANCED_VISUALISATIONS_RESET,
        advancedVisualisationsLoading: false,
        advancedVisualisationsData: {},
        advancedVisualisationsError: false,
      })
    }
  }
  const makeInternalUrl = (visualisationTemplate: VisualisationTemplates) => {
    let url = `v1/core/reporting/reporting/campaigns/${campaignId}/data/`
    const queryParams = makeQueryParam(resultType)
    switch (visualisationTemplate) {
      case 'lil_bandit_open_rates_over_time':
        url += `openrates${queryParams}`
        break
      case 'lil_bandit_click_rates_over_time':
        url += `clickrates${queryParams}`
        break
      case 'lil_bandit_sent_per_variant':
        url += `totalsent${queryParams}`
        break
      case 'lil_bandit_open_per_variant':
        url += `totalopened${queryParams}`
        break
      case 'lil_bandit_open_rate_leaderboard':
        url += `leaderboard${queryParams}&metric=opens&max_data_points=20`
        break
      case 'lil_bandit_click_rate_leaderboard':
        url += `leaderboard${queryParams}&metric=clicks&max_data_points=20`
        break
      case 'lil_bandit_uplift':
        url += `daily-performance${queryParams}`
        break
      case 'lil_bandit_total_opens_over_time':
        url += `rates${queryParams}&rate=total_opens_rate`
        break
      case 'lil_bandit_num_influenced_over_time':
        url += `rates${queryParams}&rate=influenced_open_rate`
        break
      case 'lil_bandit_direct_open_over_time':
        url += `rates${queryParams}&rate=direct_open_rate`
        break
      case 'lil_bandit_impression_over_time':
        url += `rates${queryParams}&rate=impressions_rate`
        break
      case 'lil_bandit_click_button_1_rate':
        url += `rates${queryParams}&rate=click_btn1_rate`
        break
      case 'lil_bandit_click_button_2_rate':
        url += `rates${queryParams}&rate=click_btn2_rate`
        break
      default:
        url = ''
    }
    return url
  }

  const advancedVisualisationsData: any = {}
  return async (dispatch: Function) => {
    dispatch({
      type: types.ADVANCED_VISUALISATIONS_LOADING,
      advancedVisualisationsLoading: true,
      advancedVisualisationsError: false,
    })
    const options = {
      mode: 'cors',
      default: 'default',
      method: 'GET',
    } as const
    const url = makeInternalUrl(requestedGraphTemplate)
    if (!url) {
      return dispatch({
        type: types.ADVANCED_VISUALISATIONS_FAIL,
        advancedVisualisationsError: true,
        advancedVisualisationsLoading: false,
        advancedVisualisationsData: {},
      })
    }
    apiUtil(url, options, {
      useConnectApi: true,
      internal: true,
      noContentType: true,
    })
      .then((responseObj) => {
        advancedVisualisationsData[requestedGraphTemplate] = {
          ...responseObj,
          results: responseObj?.results,
          average: responseObj?.average || responseObj?.average_rate,
        }

        return dispatch({
          type: types.ADVANCED_VISUALISATIONS_SUCCESS,
          advancedVisualisationsData: {
            campaignId,
            ...advancedVisualisationsData,
          },
          advancedVisualisationsLoading: false,
          advancedVisualisationsError: false,
        })
      })
      .catch(() => {
        dispatch({
          type: types.ADVANCED_VISUALISATIONS_FAIL,
          advancedVisualisationsError: true,
          advancedVisualisationsLoading: false,
          advancedVisualisationsData: {},
        })
      })
  }
}

export const updateResultsTable = (newResult: any) => {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_RESULTS_TABLE,
      newResult,
    })
  }
}

export const setSubjetsLine = (subjectsLine: string) => {
  return {
    type: types.SET_SUBJECTS_LINE,
    payload: subjectsLine,
  }
}

export const setFilteredVariants = (filteredVariants: SubjectLine[]) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_FILTERED_SUMMARY_TABLE_VARIANTS,
      filteredVariants,
    })
  }
}

// Mapp Integration
export const updateMappMessageId = (
  projectId: string,
  mapMessageId: string,
  campaignId: string,
  variantId: string,
  usePatch = false
  // eslint-disable-next-line max-params
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      updatedMappMessage: null,
      isWaitingState: {
        isWaiting: true,
        isWaitingFor: 'UPDATE_MAPP_MESSAGES',
      },
    })
    const params = usePatch ? '' : `&phraseeVariantID=${variantId}`
    return apiUtil(
      `v1/integrations/mapp/messages/drafts/${mapMessageId}?projectId=${projectId}${params}`,
      {
        method: usePatch ? 'PATCH' : 'POST',
        body: {
          phraseeCampaignId: campaignId,
          phraseeVariantId: variantId,
        },
      },
      {
        useConnectApi: true,
        internal: true,
        noContentType: false,
      }
    )
      .then((response: any) => {
        dispatch({
          type: types.UPDATE_MAPP_MESSAGES_SUCCESS,
          updatedMappMessage: response,
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.UPDATE_MAPP_MESSAGES_FAIL,
          error: error.message,
          updatedMappMessage: null,
        })
      })
      .finally(() => {
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaiting: false,
            isWaitingFor: 'UPDATE_MAPP_MESSAGES',
          },
        })
      })
  }
}
export const fetchMappMessages = (
  projectId: string,
  shouldCloseMappDrawer = false
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaiting: true,
        isWaitingFor: 'FETCH_MAPP_MESSAGES',
      },
    })
    return apiUtil(
      `v1/integrations/mapp/messages/drafts?projectId=${projectId}`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        if (shouldCloseMappDrawer) {
          dispatch(
            showBanner({
              content: 'Message saved sucessfully!',
              type: 'success',
            })
          )
        }
        dispatch({
          type: types.FETCH_MAPP_MESSAGES_SUCCESS,
          draftMessages: response?.sort((a: any, b: any) => {
            const aDate = new Date(a.creationDate).getTime()
            const bDate = new Date(b.creationDate).getTime()
            return bDate - aDate
          }),
          shouldCloseMappDrawer,
        })
      })
      .catch((error: any) => {
        dispatch(
          showBanner({
            content: `Failed to load Mapp messages: ${error.message}`,
            type: 'error',
          })
        )
        dispatch({
          type: types.FETCH_MAPP_MESSAGES_FAIL,
          error: error.message,
          shouldCloseMappDrawer,
          draftMessages: [],
        })
      })
      .finally(() => {
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaiting: false,
            isWaitingFor: 'FETCH_MAPP_MESSAGES',
          },
        })
      })
  }
}
// End Mapp
// End Integration options actions
// Campaign status
export const fetchCampaignBackgroundStatus = (campaignId: string) => {
  return (dispatch: AsyncThunkDispatch, getState: () => RootState) => {
    const apiPath = `campaigns/${campaignId}/summary-ui/`
    return apiUtil(apiPath, {
      method: 'GET',
    })
      .then((response: any) => {
        if (getState().campaigns.isNewCampaign) {
          return
        }

        const steps = response.data.status
        const campaignDataSteps = response.data.steps
        const { campaign_progress, campaign_data } = response.data
        const lastStep = steps[steps.length - 1]
        dispatch({
          type: types.FETCH_CAMPAIGN_BG_STATUS_SUCCESS,
          steps,
          campaignDataSteps,
          shouldStopPolling: lastStep.status === 'finish',
          campaign_progress,
          campaign_data,
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_CAMPAIGN_BG_STATUS_FAIL,
          error: error.message,
        })
      })
  }
}
// End of Campaign status

// Cheetah Integration
export const fetchCheetahCampaign = (
  projectId: string,
  page?: number,
  count?: number
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'fetchingCheetahCampaigns',
        isWaiting: true,
      },
    })

    let endpoint = `v1/integrations/cheetah/esp_campaigns/${projectId}`
    if (page && count) {
      endpoint += `?page=${page}&count=${count}`
    }

    return apiUtil(
      endpoint,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.RETRIEVE_CHEETAH_CAMPAIGN_SUCCESS,
          campaigns: response.data,
          isWaitingState: {
            isWaitingFor: 'fetchingCheetahCampaigns',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.RETRIEVE_CHEETAH_CAMPAIGN_ERROR,
          message: error.message,
          isWaitingState: {
            isWaitingFor: 'fetchingCheetahCampaigns',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

export const fetchSingleCheetahCampaign = (
  campaignId: string,
  projectId: string
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'fetchingSingleCheetahCampaign',
        isWaiting: true,
      },
    })

    const endpoint = `v1/integrations/cheetah/campaigns/${campaignId}?project_id=${projectId}`

    return apiUtil(
      endpoint,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_SUCCESS,
          campaign: response.data,
          timeZone: response.data.campParam.sendSchedule.timeZone,
          cheetahTimeZone: response.data.campParam.sendSchedule.espTimeZone,
          isWaitingState: {
            isWaitingFor: 'fetchingSingleCheetahCampaign',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_ERROR,
          message: error.message,
          isWaitingState: {
            isWaitingFor: 'fetchingSingleCheetahCampaign',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

export const updateCheetahCampaign = (
  campaignId: string,
  payload: CheetahPatchPayload,
  closeDrawer: Function
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'schedule',
        isWaiting: true,
      },
    })
    return apiUtil(
      `v1/integrations/cheetah/campaigns/${campaignId}`,
      {
        method: 'PATCH',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch(showBanner({ content: response.data, type: 'success' }))
        dispatch({
          type: types.SCHEDULE_CHEETAH_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'schedule',
            isWaiting: false,
          },
          scheduleResponse: null,
        })
        closeDrawer()
      })
      .catch((error: any) => {
        dispatch({
          type: types.SCHEDULE_CHEETAH_CAMPAIGN_FAIL,
          isWaitingState: {
            isWaitingFor: 'schedule',
            isWaiting: false,
          },
          error: error.message,
        })
      })
  }
}

export const retrieveCheetahCampaignTimeZone = (
  campaignId: string,
  projectId: string
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'retrieveTimeZone',
        isWaiting: true,
      },
    })
    return apiUtil(
      `v1/integrations/cheetah/campaigns/${campaignId}?project_id=${projectId}`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.RETRIEVE_CHEETAH_CAMPAIGN_TIMEZONE,
          timeZone: response.data.campParam.sendSchedule.timeZone,
          cheetahTimeZone: response.data.campParam.sendSchedule.espTimeZone,
        })
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaitingFor: 'retrieveTimeZone',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaitingFor: 'retrieveTimeZone',
            isWaiting: false,
          },
        })
      })
  }
}

export const retrieveCheetahCampaignResults = (
  campaignId: string,
  payload: CheetahRetrieveResultsPayload,
  closeDrawer: () => () => void
) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'retrieveResults',
        isWaiting: true,
      },
    })
    return apiUtil(
      `v1/integrations/cheetah/campaigns/results/${campaignId}`,
      {
        method: 'POST',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.RETRIEVE_CHEETAH_CAMPAIGN_RESULTS_SUCCESS,
          results: response.data,
        })
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaitingFor: 'retrieveResults',
            isWaiting: false,
          },
        })
        closeDrawer()
      })
      .catch((error: any) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaitingFor: 'retrieveResults',
            isWaiting: false,
          },
        })
      })
  }
}
// Time zone by project is
export const fetchTimeZone = (projectId: string) => {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_TIME_ZONE_STARTED,
      isWaitingState: {
        isWaitingFor: 'fetchingTimeZone',
        isWaiting: true,
      },
      timeZone: '',
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/braze/projects/${projectId}/account_timezone`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.FETCH_TIME_ZONE_SUCCESS,
          timeZone: response,
          isWaitingState: {
            isWaitingFor: 'fetchingTimeZone',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_TIME_ZONE_ERROR,
          error: error.message,
          timeZone: '',
          isWaitingState: {
            isWaitingFor: 'fetchingTimeZone',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

// Cancel braze campaign
export const cancelBrazeCampaign = (
  campaignId: string,
  callback: () => void
) => {
  return (dispatch) => {
    dispatch({
      type: types.CANCEL_BRAZE_CAMPAIGN_STARTED,
      isWaitingState: {
        isWaitingFor: 'cancelBrazeCampaign',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/braze/campaigns/${campaignId}/cancel`,
      {
        method: 'DELETE',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then(() => {
        dispatch({
          type: types.CANCEL_BRAZE_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'cancelBrazeCampaign',
            isWaiting: false,
          },
        })
        callback()
      })
      .catch((error: any) => {
        dispatch({
          type: types.CANCEL_BRAZE_CAMPAIGN_ERROR,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'cancelBrazeCampaign',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

// Braze integration
export const fetchBrazeCampaigns = (
  projectId: string,
  distributionChannel: string,
  includeCampaigns: Array<string>
) => {
  return (dispatch) => {
    dispatch({
      type: types.FETCH_BRAZE_CAMPAIGNS_STARTED,
      isWaitingState: {
        isWaitingFor: 'fetchingBrazeCampaigns',
        isWaiting: true,
      },
      brazeCampaigns: [],
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/braze/projects/${projectId}/campaigns?channel=${distributionChannel}&include=${includeCampaigns}`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.FETCH_BRAZE_CAMPAIGNS_SUCCESS,
          brazeCampaigns: response,
          isWaitingState: {
            isWaitingFor: 'fetchingBrazeCampaigns',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_BRAZE_CAMPAIGNS_ERROR,
          error: error.message,
          brazeCampaigns: [],
          isWaitingState: {
            isWaitingFor: 'fetchingBrazeCampaigns',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

/**
 * This method schedules Braze campaign
 * @param {string} campaignId - Id of the campaign created in step 1
 * @param {string} token - The users access token
 * @param {object} data - The data object to be sent
 */
export const syncBrazeCampaign = (data: any, campaignId: string) => {
  return (dispatch) => {
    dispatch({
      type: types.SCHEDULE_BRAZE_CAMPAIGNS_STARTED,
      error: null,
      brazeResponse: null,
      isWaitingState: {
        isWaitingFor: 'syncBraze',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/braze/campaigns/${campaignId}/braze_campaigns`,
      {
        method: 'POST',
        body: data,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.SCHEDULE_BRAZE_CAMPAIGNS_SUCCESS,
          brazeResponse: response.data,
          error: null,
          isWaitingState: {
            isWaitingFor: 'syncBraze',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.SCHEDULE_BRAZE_CAMPAIGNS_ERROR,
          error: error.message,
          brazeResponse: null,
          isWaitingState: {
            isWaitingFor: 'syncBraze',
            isWaiting: false,
          },
        })
      })
  }
}

// END BRAZE INT

/**
 * This method retrieves the TXT file to update he SL to Cheetah
 * @param {string} campaignId - Id of the campaign created in step 1
 */
export const downloadTXTFile = (currentCampaignId: string) => {
  return (dispatch) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'download',
        isWaiting: true,
      },
    })
    return apiUtil(
      `v1/integrations/cheetah/campaigns/${currentCampaignId}/txt`,
      {
        method: 'GET',
        headers: {},
      },
      { useConnectApi: true, internal: true, noContentType: true }
    )
      .then((response: any) => {
        dispatch({
          type: types.RETRIEVE_TXT_CHEETAH_CAMPAIGN_SUCCESS,
          response,
          currentCampaignId,
        })
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaitingFor: 'download',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaitingFor: 'download',
            isWaiting: false,
          },
        })
      })
  }
}

// Epsilon integration business units list
export const fetchEpsilonBusinessUnits = (projectId: string) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.FETCH_EPSILON_BUSINESS_UNITS,
      isWaitingState: {
        isWaitingFor: 'fetchingEpsilonBusinessUnits',
        isWaiting: true,
      },
      epsilonBusinessUnits: [],
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/business_units`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        // const data = await response.json();
        dispatch({
          type: types.FETCH_EPSILON_BUSINESS_UNITS_SUCCESS,
          epsilonBusinessUnits: response,
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonBusinessUnits',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_EPSILON_BUSINESS_UNITS_ERROR,
          error: error.message,
          epsilonBusinessUnits: [],
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonBusinessUnits',
            isWaiting: false,
          },
        })
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
  }
}

export const fetchEpsilonSeedList = (
  projectId: string,
  businessUnitId: string
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.FETCH_EPSILON_SEED_LIST_START,
      isWaitingState: {
        isWaitingFor: 'fetchingEpsilonSeedList',
        isWaiting: true,
      },
      epsilonSeedList: [],
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/business-units/${businessUnitId}/seed-list`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.FETCH_EPSILON_SEED_LIST_SUCCESS,
          epsilonSeedList: response,
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonSeedList',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_EPSILON_SEED_LIST_ERROR,
          error: error.message,
          epsilonSeedList: [],
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonSeedList',
            isWaiting: false,
          },
        })
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
  }
}

// Epsilon integration folders
export const fetchEpsilonFolders = (
  projectId: string,
  businessUnitId: string
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.FETCH_EPSILON_FOLDERS,
      isWaitingState: {
        isWaitingFor: 'fetchingEpsilonFolders',
        isWaiting: true,
      },
      epsilonFolders: [],
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/business_units/${businessUnitId}/folders`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.FETCH_EPSILON_FOLDERS_SUCCESS,
          epsilonFolders: response,
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonFolders',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_EPSILON_FOLDERS_ERROR,
          error: error.message,
          epsilonFolders: [],
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonFolders',
            isWaiting: false,
          },
        })
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
  }
}

// Epsilon integration sequences list
export const fetchEpsilonSequences = (
  projectId: string,
  businessUnitId: string,
  folderId: string,
  include: string
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.FETCH_EPSILON_SEQUENCES,
      isWaitingState: {
        isWaitingFor: 'fetchingEpsilonSequences',
        isWaiting: true,
      },
      epsilonSequences: [],
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/business_units/${businessUnitId}/folders/${folderId}/campaigns?tags=phrasee&include=${include}&type=sequence`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.FETCH_EPSILON_SEQUENCES_SUCCESS,
          epsilonSequences: response,
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonSequences',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_EPSILON_SEQUENCES_ERROR,
          error: error.message,
          epsilonSequences: [],
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonSequences',
            isWaiting: false,
          },
        })
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
  }
}

// Epsilon integration campaigns list
export const fetchEpsilonCampaigns = (
  projectId: string,
  businessUnitId: string,
  folderId: string,
  include: string
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.FETCH_EPSILON_CAMPAIGNS,
      isWaitingState: {
        isWaitingFor: 'fetchingEpsilonCampaigns',
        isWaiting: true,
      },
      epsilonCampaigns: [],
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/business_units/${businessUnitId}/folders/${folderId}/campaigns?tags=phrasee&include=${include}`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.FETCH_EPSILON_CAMPAIGNS_SUCCESS,
          epsilonCampaigns: response,
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonCampaigns',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_EPSILON_CAMPAIGNS_ERROR,
          error: error.message,
          epsilonCampaigns: [],
          isWaitingState: {
            isWaitingFor: 'fetchingEpsilonCampaigns',
            isWaiting: false,
          },
        })
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
  }
}

// Initialize React Epsilon campaign
export const initializeReactEpsilonCampaign = (
  projectId: string,
  campaignId: string,
  payload: EpsilonInitializeReactCampaignPayload,
  callback: any
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'initializeReactEpsilonCampaign',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/campaigns/${campaignId}/react/initialize`,
      {
        method: 'POST',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then(() => {
        dispatch({
          type: types.IS_WAITING,
          isWaitingState: {
            isWaitingFor: 'initializeReactEpsilonCampaign',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: BANNER_MESSAGES.epsilonPushVariantsSuccess,
            type: 'success',
          })
        )
        callback()
      })
      .catch((error: any) => {
        dispatch({
          type: types.IS_WAITING,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'initializeReactEpsilonCampaign',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: 'Failed to initialize Epsilon experiment',
            type: 'error',
          })
        )
      })
  }
}

// Push variants to Epsilon
export const pushVariantsToEpsilon = (
  projectId: string,
  campaignId: string,
  payload: EpsilonCreateSplitsPayload,
  callback: any
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.PUSH_VARIANTS_TO_EPSILON,
      isWaitingState: {
        isWaitingFor: 'pushVariantsToEpsilon',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/campaigns/${campaignId}/variants`,
      {
        method: 'POST',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then(() => {
        dispatch({
          type: types.PUSH_VARIANTS_TO_EPSILON_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'pushVariantsToEpsilon',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: BANNER_MESSAGES.epsilonPushVariantsSuccess,
            type: 'success',
          })
        )
        callback()
      })
      .catch((error: any) => {
        dispatch({
          type: types.PUSH_VARIANTS_TO_EPSILON_ERROR,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'pushVariantsToEpsilon',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: 'Failed to push variants to Epsilon',
            type: 'error',
          })
        )
      })
  }
}

// Delete variants from Epsilon
export const deleteVariantsFromEpsilon = (
  projectId: string,
  campaignId: string,
  payload: EpsilonCreateSplitsPayload,
  callback: any
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.DELETE_VARIANTS_FROM_EPSILON,
      isWaitingState: {
        isWaitingFor: 'deleteVariantsFromEpsilon',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/campaigns/${campaignId}/variants`,
      {
        method: 'DELETE',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then(() => {
        dispatch({
          type: types.DELETE_VARIANTS_FROM_EPSILON_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'deleteVariantsFromEpsilon',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: BANNER_MESSAGES.deleteEpsilonVariantsSuccess,
            type: 'success',
          })
        )
        callback()
      })
      .catch((error: any) => {
        dispatch({
          type: types.DELETE_VARIANTS_FROM_EPSILON_ERROR,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'deleteVariantsFromEpsilon',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: 'Failed to delete variants from Epsilon',
            type: 'error',
          })
        )
      })
  }
}

// Fetch Epsilon time zone
export const fetchEpsilonTimeZone = (projectId: string) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.FETCH_EPSILON_TIME_ZONE,
      isWaitingState: {
        isWaitingFor: 'fetchEpsilonTimeZone',
        isWaiting: true,
      },
      epsilonTimeZone: '',
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/account_timezone`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.FETCH_EPSILON_TIME_ZONE_SUCCESS,
          epsilonTimeZone: response,
          isWaitingState: {
            isWaitingFor: 'fetchEpsilonTimeZone',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.FETCH_EPSILON_TIME_ZONE_ERROR,
          error: error.message,
          epsilonTimeZone: '',
          isWaitingState: {
            isWaitingFor: 'fetchEpsilonTimeZone',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: 'Failed to fetch Epsilon timezone',
            type: 'error',
          })
        )
      })
  }
}

// Schedule Epsilon campaign
export const scheduleEpsilonCampaign = (
  projectId: string,
  campaignId: string,
  payload: EpsilonScheduleCampaignPayload,
  callback: any
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.SCHEDULE_EPSILON_CAMPAIGN,
      isWaitingState: {
        isWaitingFor: 'scheduleEpsilonCampaign',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/campaigns/${campaignId}/deployments`,
      {
        method: 'POST',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then(() => {
        dispatch({
          type: types.SCHEDULE_EPSILON_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'scheduleEpsilonCampaign',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: BANNER_MESSAGES.scheduleEpsilonCampaignSuccess,
            type: 'success',
          })
        )
        dispatch(closeDrawer())
        callback()
      })
      .catch((error: any) => {
        const { message } = error
        let newMessage = message
        if (
          message ===
          'This message is currently inactive and cannot be deployed'
        ) {
          newMessage = BANNER_MESSAGES.unapprovedCampaign
        }
        dispatch({
          type: types.SCHEDULE_EPSILON_CAMPAIGN_ERROR,
          error: newMessage,
          isWaitingState: {
            isWaitingFor: 'scheduleEpsilonCampaign',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: 'Failed to schedule Epsilon experiment',
            type: 'error',
          })
        )
      })
  }
}

export const cancelEpsilonReactCampaign = (
  projectId: string,
  campaignId: string,
  buisnessId: string,
  callback: any
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.CANCEL_EPSILON_CAMPAIGN,
      isWaitingState: {
        isWaitingFor: 'cancelEpsilonCampaign',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/campaigns/${campaignId}/react/cancel`,
      {
        method: 'POST',
        body: { business_unit_id: buisnessId },
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then(() => {
        dispatch({
          type: types.CANCEL_EPSILON_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'cancelEpsilonCampaign',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: BANNER_MESSAGES.cancelEpsilonCampaignSuccess,
            type: 'success',
          })
        )
        callback()
      })
      .catch((error: any) => {
        dispatch({
          type: types.CANCEL_EPSILON_CAMPAIGN_ERROR,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'cancelEpsilonCampaign',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

// Cancel Epsilon campaign
export const cancelEpsilonCampaign = (
  projectId: string,
  campaignId: string,
  payload: EpsilonCancelCampaignPayload,
  callback: any
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.CANCEL_EPSILON_CAMPAIGN,
      isWaitingState: {
        isWaitingFor: 'cancelEpsilonCampaign',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects/${projectId}/campaigns/${campaignId}/deployments`,
      {
        method: 'DELETE',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then(() => {
        dispatch({
          type: types.CANCEL_EPSILON_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'cancelEpsilonCampaign',
            isWaiting: false,
          },
        })
        dispatch(
          showBanner({
            content: BANNER_MESSAGES.cancelEpsilonCampaignSuccess,
            type: 'success',
          })
        )
        callback()
      })
      .catch((error: any) => {
        dispatch({
          type: types.CANCEL_EPSILON_CAMPAIGN_ERROR,
          error: error.message,
          isWaitingState: {
            isWaitingFor: 'cancelEpsilonCampaign',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

export const resetEpsilonState = () => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_EPSILON_STATE,
    })
  }
}

// Emarsys integration

export const fetchEmarsysCampaign = (
  projectId: string,
  espCampaignId: string,
  callback: Function
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'fetchingEmarsysCampaigns',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/emarsys/projects/${projectId}/campaigns`,
      {
        method: 'GET',
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch({
          type: types.RETRIEVE_EMARSYS_CAMPAIGN_SUCCESS,
          timeZone: response.timeZone,
          campaigns: response.campaigns,
          isWaitingState: {
            isWaitingFor: 'fetchingEmarsysCampaigns',
            isWaiting: false,
          },
        })
        if (espCampaignId) {
          callback(espCampaignId)
        }
      })
      .catch((error: any) => {
        dispatch({
          type: types.RETRIEVE_EMARSYS_CAMPAIGN_ERROR,
          message: error.message,
          isWaitingState: {
            isWaitingFor: 'fetchingEmarsysCampaigns',
            isWaiting: false,
          },
        })
        showBanner({ content: error.message, type: 'error' })
      })
  }
}

export const pushSplitsToEmarsys = (
  projectId: string,
  campaignId: string,
  emarsysData: {
    emarsysCampaignId: string
    emarsysCampaigns: EmarsysCampaign[]
  }
) => {
  return (dispatch: Function) => {
    const isCampaignAlreadyInUse = emarsysData.emarsysCampaigns.find(
      (c) => c.campaignId === emarsysData.emarsysCampaignId
    )?.alreadyUsed
    if (isCampaignAlreadyInUse) {
      dispatch({
        type: types.PUSH_SPLIT_TO_EMARSYS_FAIL,
        isWaitingState: {
          isWaitingFor: 'pushSplitsToEmarsys',
          isWaiting: false,
        },
      })
      dispatch(
        showBanner({
          content: 'Variants have already been pushed to that campaign',
          type: 'error',
        })
      )
      return
    }
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'pushSplitsToEmarsys',
        isWaiting: true,
      },
    })

    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/emarsys/projects/${projectId}/campaigns/${campaignId}/splits`,
      {
        method: 'POST',
        body: {
          emarsys_campaign_id: emarsysData.emarsysCampaignId,
        },
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch(showBanner({ content: response.data, type: 'success' }))
        dispatch({
          type: types.PUSH_SPLIT_TO_EMARSYS_SUCCESS,
          timeZone: response.timeZone,
          campaigns: response.campaigns,
          isWaitingState: {
            isWaitingFor: 'pushSplitsToEmarsys',
            isWaiting: false,
          },
        })
      })
      .catch((error: any) => {
        dispatch({
          type: types.PUSH_SPLIT_TO_EMARSYS_FAIL,
          isWaitingState: {
            isWaitingFor: 'pushSplitsToEmarsys',
            isWaiting: false,
          },
        })
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
  }
}

export const scheduleEmarsysCampaign = (
  projectId: string,
  campaignId: string,
  payload: any,
  setIsScheduled: Function,
  closeDrawer: Function
  // eslint-disable-next-line max-params
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.IS_WAITING,
      isWaitingState: {
        isWaitingFor: 'schedule',
        isWaiting: true,
      },
    })
    return apiUtil(
      `${REACT_APP_CONNECT_INTERNAL_V1_URL}/emarsys/projects/${projectId}/campaigns/${campaignId}/deployments`,
      {
        method: 'POST',
        body: payload,
      },
      { useConnectApi: true, internal: true, noContentType: false }
    )
      .then((response: any) => {
        dispatch(showBanner({ content: response.data, type: 'success' }))
        dispatch({
          type: types.SCHEDULE_CHEETAH_CAMPAIGN_SUCCESS,
          isWaitingState: {
            isWaitingFor: 'schedule',
            isWaiting: false,
          },
          scheduleResponse: null,
        })
        setIsScheduled()
        closeDrawer()
      })
      .catch((error: any) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        dispatch({
          type: types.SCHEDULE_CHEETAH_CAMPAIGN_FAIL,
          isWaitingState: {
            isWaitingFor: 'schedule',
            isWaiting: false,
          },
          message: error.message,
        })
      })
  }
}

export const clickToggleCampaignDetails = (value: boolean) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.TOGGLE_DETAILS_PANEL,
      value,
    })
  }
}

export const setDynamicOptimizationResultType = (
  dynamicOptimizationResultType: DynamicOptimizationResultType | undefined
) => {
  return (dispatch: Function) => {
    dispatch({
      type: types.SET_DYNAMIC_OPTIMIZATIONS_RESULT_TYPE,
      dynamicOptimizationResultType,
    })
  }
}

export const updateContentGenerationData = (
  variants: SubjectLine[],
  topics?: Topic[]
) => {
  return (dispatch: Function) => {
    batch(() => {
      dispatch(updateTopics(topics || []))
      dispatch({
        type: types.LATEST_VARIANTS_LOADED,
        variants,
      })
    })
  }
}
