import React from 'react'

import CheckboxGroup from 'common/components/checkbox/CheckboxGroup'

type Props = {
  defaultValue: string | undefined
  name: string
  type: string
  required?: boolean
  disabled?: boolean
  onChange: Function
  max_limit?: number
  list: string[]
}
class SelectList extends React.Component<Props, any> {
  static defaultProps = {
    max_limit: 0,
    required: false,
  }

  constructor(props: any) {
    super(props)
    this.state = {
      value: props.defaultValue || '',
    }
  }

  myOnChange(e: any) {
    const { value } = this.state
    const { onChange, name, type, required, max_limit } = this.props
    let valuesArray = value === '' ? [] : value.split(';')
    if (e.target.checked) {
      valuesArray.push(e.target.value)
    } else {
      valuesArray = valuesArray.filter((item: any) => item !== e.target.value)
    }
    this.setState(
      {
        value: valuesArray.join(';'),
      },
      () => {
        onChange(name, type, valuesArray.join(';'), required, { max_limit })
      }
    )
  }

  render() {
    const { defaultValue, list, disabled } = this.props
    const checkedValues = (defaultValue || '').split(';')

    const fullList = list.map((option: any) => {
      // Support for being able to have values that differ from the label
      if (Array.isArray(option)) {
        return {
          label: option[0],
          value: option[1] ? option[1] : option[0],
        }
      }
      // Support the legacy way of defining word_list's
      return {
        label: option,
        value: option,
      }
    })
    return (
      <CheckboxGroup
        onChange={(e) => this.myOnChange(e)}
        options={fullList.map((item) => ({
          ...item,
          isChecked: checkedValues.indexOf(item.value) !== -1,
          isDisabled: disabled,
        }))}
      />
    )
  }
}

export default SelectList
