import { ReactNode } from 'react'
import cx from 'classnames'

type Props = {
  className?: string
  children: ReactNode
}

const PageHeader = ({ className, children }: Props) => {
  return (
    <div
      className={cx('flex lg:mt-4 mb-3 sm:items-center', className)}
      data-cy="page-header"
    >
      {children}
    </div>
  )
}

export default PageHeader
