import { useState } from 'react'
import {
  generatePath,
  Link,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router-dom'
import type { Route } from 'app/navigation/interfaces'
import { allPaths } from 'app/navigation/staticRoutes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'

import { getIsAuthorized, routesPermissions } from 'common/auth/permissions'
import Button from 'common/components/button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Account, getSelectedAccountName } from 'features/auth/store/authSlice'
import { changeAccountId } from 'features/auth/store/authSlice'
import useProjectsQuery from 'features/campaigns/queries/useProjectsQuery'

import AccountSelectButtonContent from './components/AccountSelectButtonContent'
import SearchInput from './components/SearchInput'

import styles from './AccountSelect.module.css'

interface Props {
  accounts: Account[]
  accountsRoute: Route
}

const filterAccountsBySearch = (accounts: Account[], searchValue: string) =>
  accounts.filter(({ name }) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  )

const AccountSelector = ({ accounts, accountsRoute }: Props) => {
  const flags = useFlags()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const { accountId, permissions } = useAppSelector((state) => state.authStates)
  const accountName = useAppSelector(getSelectedAccountName)

  const { refetch: refetchProjects } = useProjectsQuery()
  const [searchValue, setSearchValue] = useState<string>('')

  const updatePath = (newAccountId: string) => {
    const to = allPaths.find((path) =>
      matchPath(pathname, {
        path,
        exact: true,
        strict: true,
      })
    )
    to && history.replace(generatePath(to, { accountId: newAccountId }))
  }

  const onSelectAccount = async (id: string): Promise<void> => {
    updatePath(id)
    await dispatch(changeAccountId(id))
    refetchProjects()
  }

  const hasAccountsViewPermissions = getIsAuthorized(
    permissions,
    routesPermissions.accountsView
  )

  const filteredAccounts = searchValue
    ? filterAccountsBySearch(accounts, searchValue)
    : accounts

  const isMultiAccount = accounts.length > 1

  return isMultiAccount && flags.multiAccountAccess ? (
    <Dropdown
      data-cy="account-selector"
      overlayClassName={styles.container}
      trigger={['click']}
      onVisibleChange={(visible) => setIsDropdownVisible(visible)}
      overlay={() => {
        return (
          <Menu
            className="shadow box-border z-40 pt-2 border border-solid border-coolGray-300 text-black"
            selectable={true}
            selectedKeys={[accountId]}
          >
            <div className="pt-2 px-4 pb-4">
              <SearchInput
                data-cy="account-search"
                value={searchValue}
                onChange={(value) => setSearchValue(value)}
              />
            </div>
            {filteredAccounts.map(({ id, name }) => (
              <MenuItem
                data-cy={`${name}-account-menu-item`}
                key={id}
                onClick={() => onSelectAccount(id)}
                className="bg-white pt-4 px-4 pb-2 hover:bg-coolGray-50 hover:text-maroon-500 cursor-pointer"
              >
                {name}
              </MenuItem>
            ))}
            {hasAccountsViewPermissions && (
              <div
                data-cy="edit-accounts-menu-item"
                className="pt-4 px-4 pb-4 border-t border-coolGray-200 cursor-pointer "
              >
                <Link to={accountsRoute.to}>Edit Accounts</Link>
              </div>
            )}
          </Menu>
        )
      }}
    >
      <Button data-cy={`${accountName}-button`} className="min-w-0 text-white">
        <AccountSelectButtonContent
          accountName={accountName}
          isDropdownVisible={isDropdownVisible}
        />
      </Button>
    </Dropdown>
  ) : (
    <Button data-cy={`${accountName}-button`} className="min-w-0 text-white">
      <AccountSelectButtonContent
        accountName={accountName}
        isDropdownVisible={isDropdownVisible}
      />
    </Button>
  )
}

export default AccountSelector
