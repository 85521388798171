import React from 'react'

import Button from 'common/components/button/Button'

export interface Props {
  cardText: string
  buttonText: string
  isDisabled: boolean
  onSubmit: () => void
}

export default function DeleteVariantsCard({
  onSubmit,
  cardText,
  isDisabled,
  buttonText,
}: Props) {
  return (
    <div className="mt-4 mb-4 flex-grow flex flex-row">
      <div className="mr-4 mt-4 mb-4 text-gray-400">{cardText}</div>
      <div className="flex flex-wrap content-center ml-4">
        <Button
          variant="secondary"
          disabled={isDisabled}
          data-test-id="deleteVariantsBtn"
          data-cy="delete-variant-button"
          onClick={() => onSubmit()}
        >
          <span className="inline-block">{buttonText}</span>
        </Button>
      </div>
    </div>
  )
}
