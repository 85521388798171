import { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from 'react'
import cx from 'classnames'

import { Close, Search } from 'common/icons'
import { ReactComponent as RemoveDefault } from 'common/icons/remove/error.svg'
import { ReactComponent as SmallTickDefault } from 'common/icons/smallTick/default.svg'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
  value?: string
  className?: string
  'data-cy'?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  iconClassName?: string
  clear?: () => void
  isValid?: boolean
  hasError?: boolean
  // TODO make the variant mandatory and do not allow styling outside this component when we have the design system
  variant?: 'default' | 'small'
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type,
    value,
    className,
    'data-cy': dataCy,
    onChange,
    clear,
    iconClassName,
    isValid,
    hasError,
    variant,
    disabled,
    ...rest
  } = props
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const commonClasses = `focus:shadow focus:border hover:border 
           inline-block bg-coolGray-50 
           outline-none border font-medium`
  const hasIconOnRight = type === 'search'
  const hasIconOnLeft = clear || hasError || isValid

  return (
    <div className="relative flex-1 flex items-center">
      {type === 'search' && (
        <div className="absolute p-2">
          <Search
            state={isFocused ? 'selected' : 'default'}
            className={cx(iconClassName, { 'opacity-100': isFocused })}
          />
        </div>
      )}

      <input
        className={cx(className, {
          [`${commonClasses} h-10 w-full px-3 py-1 `]: variant === 'default',
          [`${commonClasses} w-9 px-2 py-0 `]: variant === 'small',
          'pl-10': hasIconOnRight,
          'pr-8': hasIconOnLeft,
          'focus:shadow focus:border-maroon-500 hover:border-maroon-300 border-coolGray-300 ':
            (variant === 'default' || variant === 'small') &&
            !hasError &&
            !disabled,
          'text-red-400 border-red-500': variant === 'default' && hasError,
          'cursor-not-allowed': disabled,
        })}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={ref}
        type={type}
        data-cy={dataCy}
        disabled={disabled}
        {...rest}
      />

      {hasError && (
        <RemoveDefault
          className="text-red-400 absolute right-2 inline-block z-10"
          width={24}
          height={24}
        />
      )}
      {isValid && (
        <SmallTickDefault
          className="text-green-500 absolute right-2 inline-block z-10"
          width={24}
          height={24}
        />
      )}
      {clear && value && value.length > 0 && (
        <button
          aria-label="Clear input"
          className="absolute right-2 z-10"
          onMouseDown={clear}
        >
          <Close
            state={isFocused ? 'selected' : 'default'}
            size={4}
            className={cx(iconClassName, { 'opacity-100': isFocused })}
          />
        </button>
      )}
    </div>
  )
})

export default Input
