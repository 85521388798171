import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from 'common/components/button'
import Footer from 'common/components/layout/Footer'
import { useAppDispatch } from 'common/hooks/redux'
import { Add } from 'common/icons'

import { showTemplates } from '../store/contentSlice'

import Templates from './components/Templates'

const EmptyState = () => {
  const history = useHistory<{ data: string }>()
  const { location } = history
  const dispatch = useAppDispatch()

  const [showOverlay, setShowOverlay] = useState<boolean>(false)
  const [selectedTemplateTab, setSelectedTemplateTab] = useState<string>('all')

  useEffect(() => {
    const type = location.state?.data
    if (type) {
      setSelectedTemplateTab(type)
      dispatch(showTemplates())
    }
  }, [location, dispatch])

  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-warmGray-50">
      <h2 className="text-xl font-medium mb-4">
        Welcome to Content, where we make your copywriting dreams come true!
      </h2>
      <p className="text-base text-coolGray-400 mb-8">No content created...</p>
      <Button
        className="mb-4"
        variant="primary"
        prefixIcon={<Add isDefaultColor={false} className="w-4 h-4" />}
        onClick={() => setShowOverlay(true)}
      >
        Start creating content
      </Button>
      <Footer />
      <Templates
        isVisible={showOverlay}
        onClose={() => setShowOverlay(false)}
        intialSelectedTab={selectedTemplateTab}
      />
    </div>
  )
}

export default EmptyState
