import cx from 'classnames'

type Props = {
  className?: string
}

const HelpError: React.FC<Props> = ({ className, children }) => (
  <span className={cx('text-red-600', className)}>{children}</span>
)

export default HelpError
