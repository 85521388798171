import { ChangeEvent } from 'react'
import cx from 'classnames'

import { SelectValue } from '../BaseSelect'

import CheckboxComponent from './Checkbox'

type CheckboxGroupOption = SelectValue & {
  isChecked?: boolean
  isDisabled?: boolean
}

export type CheckboxGroupProps = {
  options: CheckboxGroupOption[]
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const CheckboxGroup = ({ options, onChange }: CheckboxGroupProps) => {
  const isSingleOption = options.length === 1

  return (
    <div className="flex flex-wrap gap-2">
      {options.map((option: CheckboxGroupOption) => (
        <div
          key={option.value}
          className={cx('border border-coolGray-300', {
            'mr-2': !isSingleOption,
            'bg-maroon-50 border-maroon-500': option.isChecked,
            'hover:bg-maroon-50 hover:border-maroon-300': !option.isDisabled,
            'bg-coolGray-50 cursor-not-allowed': option.isDisabled,
            'cursor-pointer': !option.isDisabled,
          })}
        >
          <CheckboxComponent
            isChecked={option.isChecked || false}
            onChange={(_, e) => onChange(e)}
            label={option.label}
            isDisabled={option.isDisabled}
            value={option.value}
            labelClassName={cx({
              'text-maroon-500': option.isChecked,
              'hover:text-maroon-600': !option.isDisabled,
            })}
            className="p-2"
          />
        </div>
      ))}
    </div>
  )
}

export default CheckboxGroup
