import { useHistory } from 'react-router-dom'

import Button from 'common/components/button'
import { useAppSelector } from 'common/hooks/redux'
import { Add } from 'common/icons'

const NoIntegrations = () => {
  const history = useHistory()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return (
    <>
      <span className="text-coolGray-400 text-sm">
        You haven't configured any integrations within your account. Set one up
        to send your content to you CEP or ESP.
      </span>
      <div className="flex flex-col justify-center items-center mt-4 p-6 border border-coolGray-200 bg-coolGray-50">
        <div className="text-coolGray-400 text-sm">
          Connect an integration and start sharing your message.
        </div>
        <Button
          className="mt-4"
          variant="primary"
          prefixIcon={<Add isDefaultColor={false} />}
          onClick={() =>
            history.push(`/admin/accounts/${accountId}/integrations`)
          }
        >
          Configure integration
        </Button>
      </div>
    </>
  )
}

export default NoIntegrations
