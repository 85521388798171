import cx from 'classnames'
import LineGraph from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/Graphs/LineGraph'
import { getColorForIndex } from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/graphsColorPalette'

import * as GraphWidget from 'common/components/GraphWidget'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import { WidgetError, WidgetFirstUse } from 'common/components/WidgetMessage'

import { RatesResult } from './ReactCampaignRates'

export const lineGraphMessages = {
  noData: `Nothing to see here... when this experiment has data available we'll show you a jazzy graph.`,
}

const getVariantColorMapping = (data?: RatesResult) => {
  if (!data) {
    return undefined
  }

  const variantColorMap = new Map()
  data.results.forEach((variant, index) => {
    variantColorMap.set(variant.id, {
      color: getColorForIndex({ index }),
      index,
    })
  })

  return variantColorMap
}

type Props = {
  title: string
  hasError?: boolean
  graphData?: RatesResult
  isLoading?: boolean
}

const LineGraphWidget = ({ title, hasError, isLoading, graphData }: Props) => {
  const getState = () => {
    if (hasError) {
      return 'error'
    } else if (isLoading) {
      return 'loading'
    } else if (!graphData || graphData.results.length === 0) {
      return 'noData'
    } else {
      return 'showGraph'
    }
  }

  return (
    <GraphWidget.Widget
      // fixes scroll jump when reloading data on firefox
      style={{ minHeight: '450px' }}
      title={title}
      className="mt-6 pt-4 pb-0 pl-4 pr-0"
      titleClassName={cx('-mb-4 ml-4', { 'ml-6': !hasError })}
    >
      {
        {
          loading: (
            <div className="flex flex-1 mt-4 ml-2 mr-6">
              <WidgetLoader className="flex-1" />
            </div>
          ),

          error: <WidgetError className="my-6 mr-6 ml-2" />,
          noData: (
            <WidgetFirstUse
              className="my-6 mr-6 ml-2"
              text={lineGraphMessages.noData}
            />
          ),
          showGraph: (
            <LineGraph
              results={graphData?.results || []}
              average={graphData?.average_rate}
              variantColorMapping={getVariantColorMapping(graphData)}
              highchartProps={{ immutable: true }}
              navigatorDefaultRange={10 * 24 * 3600 * 1000} // 10 Days
            />
          ),
        }[getState()]
      }
    </GraphWidget.Widget>
  )
}

export default LineGraphWidget
