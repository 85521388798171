import { useState } from 'react'
import { Field, Form } from 'react-final-form'

import CheckboxComponent from 'common/components/checkbox'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import FormError from 'common/components/formError'
import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import Pathway from 'common/components/nodes/Pathway'
import { Node } from 'common/interfaces/nodes'

import NodesFilter from './filters/NodesFilter'

type Props = {
  nodes: Node[]
  selectedNodeId?: string
  updateSelectedNodeId: (nodeId?: string) => void
  onSubmit: ({
    nodeName,
    parentNode,
    isPrivate,
    isDisplayed,
  }: {
    nodeName: string
    parentNode: string
    isPrivate: boolean
    isDisplayed: boolean
  }) => void
}

const AddNodeForm = ({
  nodes,
  selectedNodeId,
  updateSelectedNodeId,
  onSubmit,
}: Props) => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)

  const validateName = (value: string) => {
    if (!value) {
      return 'Please enter node name'
    }
  }

  const validateParentNode = (value: string) => {
    if (!value) {
      return 'Please select parent node'
    }
  }

  return (
    <Form
      initialValues={{
        parentNode: selectedNodeId,
        nodeName: '',
        isPrivate: false,
        isDisplayed: true,
      }}
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      render={({ handleSubmit, values, form }) => (
        <form
          id="create-project-form"
          className="px-6 pt-12 lg:pt-8"
          onSubmit={handleSubmit}
        >
          <div className="mb-4 text-lg font-bold">Step 1 - name your node</div>
          <div className="flex">
            <Field<string> name="nodeName" validate={validateName}>
              {({ input, meta }) => (
                <FormItem
                  label="Node name"
                  error={meta.touched ? meta.error : undefined}
                  className="flex-none mr-6"
                >
                  <Input
                    data-cy="node-name-input"
                    type="text"
                    variant="default"
                    className="bg-white max-w-sm"
                    value={input.value}
                    onChange={input.onChange}
                  />
                </FormItem>
              )}
            </Field>
            <Field<boolean> name="isPrivate">
              {({ input }) => (
                <CheckboxComponent
                  data-cy="node-private-checkbox"
                  label="Private"
                  className="mr-6"
                  isChecked={input.value || false}
                  onChange={(e) => {
                    input.onChange(e)
                    setIsConfirmationModalVisible(true)
                  }}
                />
              )}
            </Field>
            <Field<boolean> name="isDisplayed">
              {({ input }) => (
                <CheckboxComponent
                  data-cy="node-display-checkbox"
                  label="Display"
                  isChecked={input.value || false}
                  onChange={(e) => input.onChange(e)}
                />
              )}
            </Field>
          </div>
          <div className="flex justify-between items-end">
            <Field<string> name="parentNode" validate={validateParentNode}>
              {({ meta }) => (
                <div>
                  <div className="mt-2 mb-5 text-lg font-bold">
                    Step 2 - Select the parent node
                  </div>
                  {selectedNodeId && nodes && (
                    <Pathway
                      selectedNodeId={selectedNodeId}
                      nodes={nodes}
                      updateSelectedNodeId={updateSelectedNodeId}
                    />
                  )}
                  {meta.touched && <FormError>{meta.error}</FormError>}
                </div>
              )}
            </Field>
            <ConfirmationModal
              open={isConfirmationModalVisible}
              title={
                values.isPrivate
                  ? 'Are you sure you want to mark this node as private?'
                  : 'Are you sure you want to mark this node as public?'
              }
              data-cy="node-private-confirmation-modal"
              confirmationText={
                values.isPrivate
                  ? 'Private nodes can not be assigned children and can only be used by projects they are specifically assigned to. Add it to the project settings that require access.'
                  : 'Public nodes can be used in generations whenever they are part of a project’s assigned trees.'
              }
              onCancel={() => {
                form.change('isPrivate', !values.isPrivate)
                setIsConfirmationModalVisible(false)
              }}
              onConfirm={() => setIsConfirmationModalVisible(false)}
              confirmButtonText="Assign"
            />

            <NodesFilter updateSelectedNodeId={updateSelectedNodeId} />
          </div>
        </form>
      )}
    />
  )
}

export default AddNodeForm
