import { useState } from 'react'

import SingleSelectCell, {
  SingleSelectCellProps,
} from 'common/components/table/cells/SingleSelect'
import TextEditableCell, {
  TextEditableCellProps,
} from 'common/components/table/cells/TextEditable'

export function useFocusEditableCell({
  rowIndex = 0,
  EditableCell = TextEditableCell,
}:
  | { rowIndex?: number; EditableCell?: typeof TextEditableCell }
  | undefined = {}) {
  const [shouldFocus, setShouldFocus] = useState(false)
  const TextEditableCellWithFocus = <T extends object>({
    value,
    row,
    ...others
  }: TextEditableCellProps<T>) =>
    EditableCell({
      ...others,
      value,
      row,
      hasAutoFocus: row.index === rowIndex && shouldFocus,
      onCellChange: (values) => {
        others.onCellChange(values)
      },
    })

  return {
    TextEditableCellWithFocus,
    editableCellFocus: () => setShouldFocus(true),
    editableCellLoseFocus: () => setShouldFocus(false),
  }
}

export function useFocusSelectCell({
  rowIndex = 0,
  EditableCell = SingleSelectCell,
}:
  | { rowIndex?: number; EditableCell?: typeof SingleSelectCell }
  | undefined = {}) {
  const [shouldFocus, setShouldFocus] = useState(false)
  const SelectCellWithFocus = <T extends object>({
    value,
    row,
    ...others
  }: SingleSelectCellProps<T>) =>
    EditableCell({
      ...others,
      value,
      row,
      hasAutoFocus: row.index === rowIndex && shouldFocus,
      onBlur: () => setShouldFocus(false),
    })

  return {
    SelectCellWithFocus,
    editableCellFocus: () => setShouldFocus(true),
    editableCellLoseFocus: () => setShouldFocus(false),
  }
}
