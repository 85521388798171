import cx from 'classnames'

interface Props {
  title: string
  className?: string
}

const PageTitle = ({ title, className }: Props) => {
  return (
    <h1
      className={cx(
        'text-gray-900 text-3xl font-medium pt-3 sm:pt-0 mr-6 w-max whitespace-nowrap',
        className
      )}
    >
      {title}
    </h1>
  )
}

export default PageTitle
