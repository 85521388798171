import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { integrationWorkflowCardClicked } from 'features/futurama/store/contentSlice'

import { useContent } from '../hooks'

import IntegrationLogSection from './IntegrationLogSection'
import IntegrationSection from './IntegrationSection'
import Workflow from './Workflow'

const IntegrationPage = () => {
  const dispatch = useAppDispatch()

  const { content } = useContent()

  const integrationSelectedElementId = useAppSelector(
    (state) => state.content.integrationSelectedElementId
  )

  useEffect(() => {
    if (!integrationSelectedElementId) {
      dispatch(
        integrationWorkflowCardClicked({
          elementId: content?.elements[0].element_id,
        })
      )
    }
  }, [content?.elements, dispatch, integrationSelectedElementId])

  return (
    <>
      <Workflow />
      <IntegrationSection />
      <IntegrationLogSection />
    </>
  )
}

export default IntegrationPage
