import { useEffect, useState } from 'react'

import SummaryWidget from 'common/components/SummaryWidget/SummaryWidget'
import { formatDate } from 'common/helpers/date'
import { useAppSelector } from 'common/hooks/redux'
import { Mode } from 'features/reports/store/insightsSlice'

import { fetchCampaigns, fetchCampaignsVariants } from '../api'

interface Values {
  projectName: string | undefined
  startDate: string
  endDate: string
  campaignsCount: number | undefined
  variantsCount: number | undefined
}

interface Props {
  project: string
  startDate: string
  endDate: string
  mode: Mode
}

const SummaryWidgetContainer = ({
  project,
  startDate,
  endDate,
  mode,
}: Props) => {
  const { projects } = useAppSelector((state) => state.campaigns)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const projectName = projects.find(({ id }) => id === project)?.name

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [values, setValues] = useState<Values>({
    projectName,
    startDate,
    endDate,
    campaignsCount: 0,
    variantsCount: 0,
  })

  const items = [
    ...(mode === 'basicReport'
      ? [
          {
            label: 'Project name',
            value: values.projectName,
            shouldTruncate: true,
          },
          {
            label: 'Date range',
            value:
              values.startDate &&
              values.endDate &&
              `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`,
            className: 'whitespace-nowrap',
          },
        ]
      : []),
    { label: 'Experiments', value: values.campaignsCount },
    { label: 'Variants', value: values.variantsCount },
  ]

  useEffect(() => {
    ;(async function () {
      setIsLoading(true)
      setHasError(false)

      try {
        const { campaigns } = await fetchCampaigns(
          project,
          startDate,
          endDate,
          accountId
        )
        const campaignIds = campaigns.map(({ _id }) => _id)

        const campaignsCount = campaignIds.length

        fetchCampaignsVariants({ campaignIds })
          .then((variants) => {
            setValues({
              projectName,
              startDate,
              endDate,
              campaignsCount,
              variantsCount: variants.length,
            })
          })
          .catch(() => setHasError(true))
          .finally(() => setIsLoading(false))
      } catch {
        setHasError(true)
      }
    })()
  }, [accountId, project, projectName, startDate, endDate])

  return (
    <SummaryWidget
      className="mt-0 mb-0"
      items={items}
      isLoading={isLoading}
      hasError={hasError}
      widgetType={mode === 'projectComparison' ? 'inner' : undefined}
    />
  )
}

export default SummaryWidgetContainer
