import {
  Connector,
  ConnectSessionsRequest,
  ConnectSessionsResponse,
  ListAccountsRequest,
  ListAccountsResponse,
} from '@phrasee/phrasee-typings'

import { connectInternalApi } from 'common/api'

export type IntegrationProvider = {
  id: string
  name: string
  category: string
  logoUrl?: string
}

export const getAvailableIntegrations = async (): Promise<
  IntegrationProvider[]
> => {
  const result = await connectInternalApi.get<Connector[]>(
    'v1/content-integrations/connectors'
  )

  return result.data.map((integration) => ({
    id: integration.provider,
    name: integration.provider_name,
    category: integration.category,
    logoUrl: integration.resources?.images?.logo_url,
  }))
}

export const getReactHubToken = async ({
  provider,
  accountId,
  accountName,
  userName,
}: ConnectSessionsRequest): Promise<string> => {
  const result = await connectInternalApi.post<ConnectSessionsResponse>(
    'v1/content-integrations/connect_sessions',
    {
      provider,
      accountId,
      accountName,
      userName,
    }
  )

  return result.data.token
}

export const listAccounts = async ({
  accountId,
  provider,
}: ListAccountsRequest): Promise<ListAccountsResponse> => {
  const result = await connectInternalApi.get<ListAccountsResponse>(
    'v1/content-integrations/accounts',
    {
      params: {
        accountId,
        provider,
      },
    }
  )

  return result.data
}
