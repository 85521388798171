import { Column, Row } from 'react-table'
import Papa from 'papaparse'
import helpers from 'workflow/utils/helpers'

import { formatNumber } from 'common/helpers/numeric'

type Value = string | number | undefined

type ExportColumn<T extends object> = Column<T> & {
  exportValue: string
}

export function getExportFileBlob<T extends object>({
  columns,
  data: rowData,
  fileType,
  fileName,
}: {
  columns: ExportColumn<T>[]
  data: Value[][]
  fileType: 'csv'
  fileName: string
}): void {
  if (fileType === 'csv') {
    let data = rowData
    const footerRow: Value[] = []
    const headerNames: string[] = columns.map((col) => {
      if (col.Footer) {
        footerRow.push(col.footerExportValue)
      }
      return col.exportValue ?? ''
    })

    if (footerRow.length) {
      data = [...data, footerRow]
    }

    const csvString = Papa.unparse({ fields: headerNames, data })
    helpers.downloadCsvDeprecated(fileName, csvString)
  }
}

export const getColumnSum = <T extends object>(
  data: T[] | undefined,
  columnAccessor: string
): number | undefined =>
  data?.reduce((acc, row) => {
    const value = row[columnAccessor]

    if (typeof value === 'number' && value !== null && value !== undefined) {
      return acc + row[columnAccessor]
    } else {
      return acc
    }
  }, 0)

export const getAverageColumnValue = <T extends object>(
  data: T[] | undefined,
  columnAccessor: string
): string => {
  let averageValue = '0'
  let rowWithValuesCount = 0

  if (!data?.length) {
    return averageValue
  }

  const sum = data.reduce((acc, row) => {
    const value = row[columnAccessor]

    if (typeof value === 'number' && value !== null && value !== undefined) {
      rowWithValuesCount++
      return acc + row[columnAccessor]
    } else {
      return acc
    }
  }, 0)

  if (rowWithValuesCount) {
    averageValue = formatNumber(sum / rowWithValuesCount, {
      maximumFractionDigits: 2,
    })
  }

  return averageValue
}

export const getCellValue = <T extends object>(
  row: Row<T>,
  column: Column<T>
  // id = accessor unless a different id is defined for the column
) => (column.id ? row.values[column.id] : undefined)

export const extendPageRowIndex = <T extends object>(
  page: Row<T>[]
): Row<T>[] => {
  let pageSubRowIndex = 0
  let pageRowIndex = 0

  return page.map((row: Row<T>) => ({
    ...row,
    pageRowIndex: pageRowIndex++,
    subRows: row.subRows.map((subRow: Row<T>) => ({
      ...subRow,
      pageRowIndex: pageSubRowIndex++,
    })),
  }))
}
