import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { Tag } from 'common/interfaces/tags'

import { Phrase } from '../interfaces'
import { contentKeys } from '../queryKeys'
import { addTagToPhrase } from '..'

type MutationContext = { previousPhrase: Phrase }

const useAddTagToPhraseMutation = (selectedNodeId) => {
  const queryClient = useQueryClient()

  return useMutation<
    unknown,
    unknown,
    { phraseId: number; tag: Tag; userId: string },
    MutationContext
  >(addTagToPhrase, {
    onMutate: async ({ phraseId, tag }) => {
      await queryClient.cancelQueries(contentKeys.phrases(selectedNodeId))

      const previousPhrase = queryClient
        .getQueryData<Phrase[]>(contentKeys.phrases(selectedNodeId))
        ?.find((phrase) => phrase.phraseId === phraseId)

      queryClient.setQueryData<Phrase[]>(
        contentKeys.phrases(selectedNodeId),
        (old) => {
          return old?.map((phrase) =>
            phrase.phraseId === phraseId
              ? {
                  ...phrase,
                  tags: [...(phrase.tags || []), tag],
                }
              : phrase
          )
        }
      )

      return { previousPhrase } as MutationContext
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData<Phrase[]>(
        contentKeys.phrases(selectedNodeId),
        (old) => {
          return old?.map((phrase) =>
            phrase.phraseId === context?.previousPhrase.phraseId
              ? context.previousPhrase
              : phrase
          )
        }
      )

      showErrorToast(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.phrases(selectedNodeId))
      queryClient.invalidateQueries(
        contentKeys.tags({ nodeIds: [selectedNodeId] })
      )
    },
  })
}

export default useAddTagToPhraseMutation
