import { useMemo, useState } from 'react'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import ConfirmationModal from 'common/components/confirmationModal'
import { Column } from 'common/components/table'
import TextEditableCell from 'common/components/table/cells/TextEditable'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useFocusSelectCell } from 'common/hooks/table'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'

import useGetReplacersQuery from '../api/queries/useGetReplacersQuery'
import {
  addMergeTag,
  deleteMergeTagRow,
  updateMergeTagCell,
} from '../store/nodeSlice'

type Row = {
  id: number
  replacer: string
  tag: string
}

const MergeTagsWidget = () => {
  const dispatch = useAppDispatch()
  const editedMergeTags = useAppSelector(
    (state) => state.projectNodes.editedData.mergeTags
  )
  const editedDefaultReplacers = useAppSelector(
    (state) => state.projectNodes.editedData.defaultReplacers
  )
  const defaultReplacers = editedDefaultReplacers.map(
    ({ replacer }) => replacer
  )
  const mergeTagsReplacers = editedMergeTags.map(({ replacer }) => replacer)
  const { data: replacers, status } = useGetReplacersQuery()
  const replacerOptions = replacers?.filter(
    ({ id }) => !defaultReplacers.includes(id)
  )

  const [rowIdToDelete, setRowIdToDelete] =
    useState<number | undefined>(undefined)
  const [resetFilterKey, setResetFilterKey] = useState(0)
  const handleDeleteCancel = () => {
    setRowIdToDelete(undefined)
  }

  const handleDeleteConfirm = () => {
    setRowIdToDelete(undefined)
    if (rowIdToDelete !== undefined) {
      dispatch(deleteMergeTagRow(rowIdToDelete))
    }
  }
  const { SelectCellWithFocus, editableCellFocus, editableCellLoseFocus } =
    useFocusSelectCell()
  const canAddNewRow = replacerOptions?.filter(
    ({ id }) => !mergeTagsReplacers.includes(id)
  ).length

  const columns: Column<Row>[] = useMemo(
    () => [
      {
        accessor: 'replacer',
        disableSortBy: true,
        Header: 'Replacer',
        Cell: (cellProps) => {
          return (
            <SelectCellWithFocus<Row>
              row={cellProps.row}
              column={cellProps.column}
              value={cellProps.value}
              onCellChange={cellProps.onCellChange}
              singleSelectProps={{
                options:
                  replacerOptions
                    ?.filter(
                      (replacer) =>
                        !editedMergeTags.some(
                          (row) => row.replacer === replacer.id
                        ) || replacer.id === cellProps.value
                    )
                    .map(({ name, id }) => ({
                      label: name,
                      value: id,
                    })) ?? [],
              }}
            />
          )
        },
      },
      {
        accessor: 'tag',
        disableSortBy: true,
        Header: 'Merge tag',
        Cell: TextEditableCell,
      },
      {
        ...generateDeleteButtonColumn<Row>({
          onClick: (row) => {
            setRowIdToDelete(row.id)
          },
          buttonLabel: 'Delete',
        }),
        width: 50,
      },
    ],
    [SelectCellWithFocus, editedMergeTags, replacerOptions]
  )

  return (
    <div className="mt-8">
      <TableWidget.Widget
        data-cy="merge-tags-widget"
        columns={columns}
        isLoading={status === 'loading'}
        data={editedMergeTags}
        firstUseText="No merge tag added yet"
        onCellChange={({ rowIndex, columnId, value }) => {
          dispatch(updateMergeTagCell({ rowIndex, columnId, value }))
          editableCellLoseFocus()
          setResetFilterKey((prev) => prev + 1)
        }}
      >
        <TableWidget.Header
          title="Merge tags"
          subtitle="Set what merge tag text should be used for replacers within this project."
        >
          <ButtonWithTooltip
            tooltip={!canAddNewRow}
            tooltipText="All replacers have been configured"
            className="mr-4"
            variant="primary"
            data-cy="add-merge-tag"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => {
              dispatch(addMergeTag())
              editableCellFocus()
              setResetFilterKey((prev) => prev + 1)
            }}
            disabled={!canAddNewRow}
          >
            Replacer
          </ButtonWithTooltip>
          <TableWidget.Filter key={resetFilterKey} />
        </TableWidget.Header>
      </TableWidget.Widget>
      <ConfirmationModal
        open={rowIdToDelete !== undefined}
        title="Remove merge tags row!"
        data-cy="merge-tags-row-delete-modal"
        confirmationText="Are you sure you want to remove this row?"
        onCancel={() => handleDeleteCancel()}
        onConfirm={() => handleDeleteConfirm()}
      />
    </div>
  )
}

export default MergeTagsWidget
