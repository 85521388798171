import { useAppSelector } from 'common/hooks/redux'
import { TemplateInfo } from 'features/unifiedFlow/contentsPage/interfaces'

const useTemplateTypesBlocked = (templates: TemplateInfo[]) => {
  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )
  const firstTemplateToAdd = templates.find(
    (template) => template.id === templatesToAdd[0]?.id
  )

  const typesOfTemplatesBlocked =
    firstTemplateToAdd === undefined
      ? 'none'
      : firstTemplateToAdd.contentType === 'Ecommerce'
      ? 'non-ecommerce'
      : 'ecommerce'

  return typesOfTemplatesBlocked
}

export default useTemplateTypesBlocked
