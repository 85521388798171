import React, { useLayoutEffect, useRef } from 'react'
import cx from 'classnames'

type ScrollPosition = 'top' | 'middle' | 'bottom' | 'none'

const Content: React.FC<{
  className?: string
  scrollPosition: ScrollPosition
  onScroll: (value: ScrollPosition) => void
}> = ({ children, scrollPosition, onScroll, className }) => {
  const contentRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { scrollHeight, scrollTop, clientHeight } = entry.target
        let currentScrollPosition
        if (scrollTop === 0 && clientHeight === scrollHeight) {
          currentScrollPosition = 'none'
        } else {
          if (scrollTop === 0) {
            currentScrollPosition = 'top'
          } else if (Math.round(scrollTop + clientHeight) === scrollHeight) {
            currentScrollPosition = 'bottom'
          } else {
            currentScrollPosition = 'middle'
          }
        }
        onScroll(currentScrollPosition)
      }
    })
    const container = contentRef.current
    if (container) {
      observer.observe(container)
    }
    return () => {
      if (container) {
        observer.unobserve(container)
      }
    }
  }, [onScroll])

  const handleScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    const current = event.currentTarget
    if (!current) {
      return
    }

    const { height } = current.getBoundingClientRect()
    const scrollHeight = current.scrollHeight
    const scrollTop = current.scrollTop

    let currentScrollPosition
    if (scrollTop === 0) {
      currentScrollPosition = 'top'
    } else if (Math.round(scrollTop + height) === scrollHeight) {
      currentScrollPosition = 'bottom'
    } else {
      currentScrollPosition = 'middle'
    }

    if (currentScrollPosition !== scrollPosition) {
      onScroll(currentScrollPosition)
    }
  }

  return (
    <div
      className={cx('overflow-y-auto flex-1', className)}
      ref={contentRef}
      onScroll={handleScroll}
    >
      {children}
    </div>
  )
}

export default Content
