import { FC } from 'react'
import cx from 'classnames'

import Avatar from 'common/components/Avatar'
import { DETAILS_TRANSITION_DURATION } from 'common/components/detailsPanel'
import { useAppSelector } from 'common/hooks/redux'
import { getSelectedCampaignUser } from 'features/campaigns/store/campaignSlice'

interface Props {
  isExpanded: boolean
}

const User: FC<Props> = ({ isExpanded }) => {
  const user = useAppSelector(getSelectedCampaignUser)
  const {
    profile: { avatarColor },
    user_id: currentUserId,
  } = useAppSelector((state) => state.authStates)

  if (user?.firstName && user.lastName) {
    return (
      <div
        className={cx(`w-74 flex  transition-all duration-300`, {
          'py-6 border-b border-coolGray-200': isExpanded,
        })}
      >
        <Avatar
          firstName={user.firstName}
          lastName={user.lastName}
          color={currentUserId === user?.id ? avatarColor : undefined}
          className={cx(
            `transition-all duration-${DETAILS_TRANSITION_DURATION}`,
            {
              'w-10 h-10 text-lg': isExpanded,
              'w-6.5 h-6.5 text-xs': !isExpanded,
            }
          )}
        />

        <div
          className={cx(
            `ml-4 transition-opacity duration-${DETAILS_TRANSITION_DURATION}`,
            {
              'opacity-100': isExpanded,
              'opacity-0': !isExpanded,
            }
          )}
        >
          <div
            data-cy="campaign-owner-user-name"
            className="text-lg font-bold text-coolGray-800"
          >
            {user.firstName} {user.lastName}
          </div>
          <div className="text-xs text-coolGray-500">Owner</div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default User
