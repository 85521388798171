import { ContentListItemElement } from '@phrasee/phrasee-typings'
import { TestedContentSection } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { ExperimentTypeStatus } from '@phrasee/phrasee-typings/typings/futurama/experiment_type'

export type ContentTypesByTab = {
  all: string[]
  email: string[]
  social: string[]
  messaging: string[]
  articles: string[]
  web: string[]
  ads: string[]
  ecommerce: string[]
}

export const contentTypesByTab: ContentTypesByTab = {
  all: [],
  email: [
    'marketing email',
    'email headline',
    'email pre-header',
    'email cta',
    'email subject line',
    'cold email',
  ],
  social: ['facebook ad', 'instagram ad'],
  messaging: ['push title', 'push body', 'sms'],
  articles: [
    'heading',
    'section titles',
    'section paragraphs',
    'bullet points',
    'article',
  ],
  web: ['website call to action', 'website headline', 'website banner'],
  ads: ['google responsive search ad', 'google responsive display ad'],
  ecommerce: ['product description', 'product features'],
}

export type ContentInfo = {
  id: string
  name: string
  createdDate: string
  lastModified: string
  owner: string
  contributors: string[]
  contentType: string
  elements: ContentListItemElement[]
}

export type TemplateInfo = {
  id: string
  icon: string
  name: string
  displayName: string
  description: string
  contentType: string
  disabled?: boolean
  optimization?: ExperimentTypeOptions
  personalization?: ExperimentTypeOptions
}

type ExperimentTypeOptions = {
  status: ExperimentTypeStatus
  contentType?: TestedContentSection
}
