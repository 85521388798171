import { Folder as FolderIcon } from 'common/icons'
import FuturamaContentsPage from 'features/futurama/contentsPage'

import { Subroute } from './interfaces'
import { dispatchClickNavItem } from './navigationSlice'

export const futuramaContentsPageSubRoute: Subroute[] = [
  {
    icon: FolderIcon,
    key: 'content',
    name: 'Content',
    component: FuturamaContentsPage,
    to: '/content',
    onClick: dispatchClickNavItem,
  },
]
