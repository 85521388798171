import cx from 'classnames'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { hideRightPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

import RightPanel from '../../components/layout/RightPanel'

import IntegrationLogSection from './IntegrationLogSection'
import OptimizeIntegrationSection from './OptimizeIntegrationSection'

type Props = {
  showOptimizeView: boolean
}
const RightSection = ({ showOptimizeView }: Props) => {
  const dispatch = useAppDispatch()

  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )

  return showOptimizeView ? (
    <OptimizeIntegrationSection />
  ) : (
    <RightPanel
      visibility={rightPanelVisibility}
      scrollableParentId="main-content-page"
      onOutsideClick={() => dispatch(hideRightPanel())}
      className={cx('h-full')}
    >
      <IntegrationLogSection />
    </RightPanel>
  )
}

export default RightSection
