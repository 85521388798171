import { Fragment } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  hideWorkflow,
  integrationWorkflowCardClicked,
} from 'features/futurama/store/contentSlice'

import LeftPane from '../components/LeftPane'
import { getElementGenerationComplete } from '../helpers'
import useContentOrTemplate from '../hooks/useContentOrTemplate'
import VerticalLine from '../workflow/components/VerticalLine'
import WorkflowCard from '../workflow/components/WorkflowCard'

const Workflow = () => {
  const dispatch = useAppDispatch()

  const { template, content } = useContentOrTemplate()
  const elements = template?.elements || content?.elements

  const selectedElementId = useAppSelector(
    (state) => state.content.integrationSelectedElementId
  )
  const isWorkflowVisible = useAppSelector(
    (state) => state.content.isWorkflowVisible
  )

  return (
    <LeftPane
      isVisible={isWorkflowVisible}
      onOutsideClick={() => dispatch(hideWorkflow())}
    >
      {elements &&
        elements.map((element, index) => {
          const { element_id, name, display_name, icon, status } = element
          const isElementDisabled =
            status === 'disabled' ||
            !getElementGenerationComplete(content, element)

          return (
            <Fragment key={element_id}>
              <WorkflowCard
                element={element}
                title={display_name || name}
                icon={icon}
                isDisabled={isElementDisabled}
                isSelected={
                  element_id === selectedElementId && !isElementDisabled
                }
                onClick={() =>
                  !isElementDisabled &&
                  dispatch(
                    integrationWorkflowCardClicked({
                      elementId: element_id,
                    })
                  )
                }
              />
              {index < elements.length - 1 && <VerticalLine />}
            </Fragment>
          )
        })}
    </LeftPane>
  )
}

export default Workflow
