import { NumericCell } from 'common/components/table/cells'
import { Column } from 'common/components/table/Table'

import TableWidget from '../widget/tableWidget'

import { CategoryPerformance } from './api'

type Props = {
  data: CategoryPerformance[] | undefined
}

const columns: Column<{
  category: string
  controlRecipients: number
  controlOpens: number
  controlOpenRate: number
  controlClicks: number
  controlClickRate: number
  personalizedRecipients: number
  personalizedOpens: number
  personalizedOpenRate: number
  personalizedClicks: number
  personalizedClickRate: number
  openUplift: number
  clickUplift: number
}>[] = [
  {
    Header: 'Category',
    accessor: 'category',
    minWidth: 150,
    maxWidth: 355,
    isResizable: true,
    width: 250,
  },
  {
    Header: 'Control Recipients',
    accessor: 'controlRecipients',
    Cell: NumericCell,
    width: 190,
  },
  {
    Header: 'Control Opens',
    accessor: 'controlOpens',
    Cell: NumericCell,
    width: 190,
  },
  {
    Header: 'Control Open Rate',
    accessor: 'controlOpenRate',
    Cell: NumericCell,
    width: 190,
  },
  { Header: 'Control Clicks', accessor: 'controlClicks', Cell: NumericCell },
  {
    Header: 'Control Click Rate',
    accessor: 'controlClickRate',
    Cell: NumericCell,
    width: 190,
  },
  {
    Header: 'Personalized Recipients',
    accessor: 'personalizedRecipients',
    Cell: NumericCell,
    width: 210,
  },
  {
    Header: 'Personalized Opens',
    accessor: 'personalizedOpens',
    Cell: NumericCell,
    width: 190,
  },
  {
    Header: 'Personalized Open Rate',
    accessor: 'personalizedOpenRate',
    Cell: NumericCell,
    width: 220,
  },
  {
    Header: 'Personalized Clicks',
    accessor: 'personalizedClicks',
    Cell: NumericCell,
    width: 190,
  },
  {
    Header: 'Personalized Click Rate',
    accessor: 'personalizedClickRate',
    Cell: NumericCell,
    width: 220,
  },
  {
    Header: 'Open Uplift',
    accessor: 'openUplift',
    Cell: NumericCell,
    width: 190,
  },
  {
    Header: 'Click Uplift',
    accessor: 'clickUplift',
    Cell: NumericCell,
    width: 190,
  },
]

const CategoryPerformanceTable = ({ data }: Props) => {
  const dataMapped: {
    category: string
    controlRecipients: number
    controlOpens: number
    controlOpenRate: number
    controlClicks: number
    controlClickRate: number
    personalizedRecipients: number
    personalizedOpens: number
    personalizedOpenRate: number
    personalizedClicks: number
    personalizedClickRate: number
    openUplift: number
    clickUplift: number
  }[] =
    data?.map((performanceData) => ({
      category: performanceData.category.join(' / '),
      controlRecipients: performanceData.control_sends,
      controlClickRate: performanceData.control_click_rate,
      controlClicks: performanceData.control_clicks,
      controlOpenRate: performanceData.control_open_rate,
      controlOpens: performanceData.control_opens,
      clickUplift: performanceData.click_uplift,
      openUplift: performanceData.open_uplift,
      personalizedClickRate: performanceData.personalized_click_rate,
      personalizedClicks: performanceData.personalized_clicks,
      personalizedOpenRate: performanceData.personalized_open_rate,
      personalizedOpens: performanceData.personalized_opens,
      personalizedRecipients: performanceData.personalized_sends,
    })) ?? []

  return <TableWidget.Widget columns={columns} data={dataMapped} />
}

export default CategoryPerformanceTable
