import { useState } from 'react'

import ErrorPage from 'common/components/error/ErrorPage'
import PageContainer from 'common/components/PageContainer'
import PageTitle from 'common/components/PageTitle'
import Spinner from 'common/components/spinner'
import { useAppSelector } from 'common/hooks/redux'

import useListAccountsQuery from '../api/queries/useListAccountsQuery'

import AccountsPage from './components/AccountsPage'
import EmptyState from './components/EmptyState'
import SelectIntegrationOverlay from './components/SelectIntegrationOverlay'

const Integrations = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const {
    data: accounts,
    isLoading,
    isError,
    refetch,
  } = useListAccountsQuery(accountId)
  const [
    isSelectIntegrationOverlayVisible,
    setIsSelectIntegrationOverlayVisible,
  ] = useState(false)

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (isError) {
      return 'error'
    } else if (accounts?.length ?? 0 > 1) {
      return 'data'
    } else {
      return 'empty'
    }
  }

  const state = getState()

  return (
    <PageContainer className="max-w-318 sm:mx-auto pt-10 px-6 justify-start">
      <div>
        <PageTitle title="Integrations" />
      </div>
      <div className="text-coolGray-400 mt-2">
        Make the most of Jacquard integrations and do even more.
      </div>
      {
        {
          loading: (
            <div className="w-full h-82">
              <Spinner />
            </div>
          ),
          error: <ErrorPage />,
          data: (
            <AccountsPage
              accounts={accounts!}
              onAddClick={() => setIsSelectIntegrationOverlayVisible(true)}
            />
          ),
          empty: (
            <EmptyState
              onAddClick={() => setIsSelectIntegrationOverlayVisible(true)}
            />
          ),
        }[state]
      }
      <SelectIntegrationOverlay
        isOpen={isSelectIntegrationOverlayVisible}
        onClose={() => {
          refetch()
          setIsSelectIntegrationOverlayVisible(false)
        }}
      />
    </PageContainer>
  )
}

export default Integrations
