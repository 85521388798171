import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface NodesState {
  highlightedPhraseId: number | undefined
}

export const initialState: NodesState = {
  highlightedPhraseId: undefined,
}

const nodesSlice = createSlice({
  name: 'linguoNodes',
  initialState,
  reducers: {
    phraseHighlighted: (state, action: PayloadAction<number | undefined>) => {
      state.highlightedPhraseId = action.payload
    },
    clearHighlightedPhrase: (state) => {
      state.highlightedPhraseId = undefined
    },
  },
})

export const { phraseHighlighted, clearHighlightedPhrase } = nodesSlice.actions
export default nodesSlice.reducer
