import ConfirmationModal from 'common/components/confirmationModal'

type Props = {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}

const DeletePhraseModal = ({ isOpen, onConfirm, onClose }: Props) => {
  return (
    <ConfirmationModal
      data-cy="delete-phrases-confirm-modal"
      title="Delete phrases?"
      confirmationText="If you delete a phrase it will no longer be available for generation in any project."
      confirmButtonText="Delete now"
      hasConfirmationSlider
      open={isOpen}
      onCancel={() => onClose()}
      onConfirm={() => {
        onConfirm()
        onClose()
      }}
    />
  )
}

export default DeletePhraseModal
