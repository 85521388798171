import { connectInternalApi } from 'common/api'

const TEMPORARY_FIXED_CONTENT_UPDATE_ID = 3

export type EndNode = { displayName: string; nodeId: number }

export const getEndNodeSuggestions = async (
  startNodeId: string
): Promise<EndNode[]> => {
  const result = await connectInternalApi.get<{
    data: { displayName: string; nodeId: number }[]
  }>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/trees/segment/${startNodeId}`
  )

  return result.data.data
}
