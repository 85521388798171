export const contentKeys = {
  all: ['unified-flow'] as const,
  contents: () => [...contentKeys.all, 'contents'] as const,
  content: (id: string) => [...contentKeys.contents(), id] as const,
  templates: (accountId: string) =>
    [...contentKeys.all, 'template', accountId] as const,
  projectConfiguration: (projectId: string) =>
    [...contentKeys.all, 'projectConfiguration', projectId] as const,
  campaigns: () => [...contentKeys.all, 'campaigns'] as const,
  campaign: (id: string) => [...contentKeys.campaigns(), id] as const,
  contentLibrary: (accountId: string) =>
    [...contentKeys.all, 'contentLibrary', accountId] as const,
}
