import { useEffect } from 'react'
import {
  generatePath,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import {
  brandVoicePath,
  editToneOfVoicePath,
  generateToneOfVoicePath,
} from 'app/navigation/paths'
import { toneOfVoiceSubroutes } from 'app/navigation/staticSubRoutes.toneOfVoice'
import CustomSwitch from 'app/router/Switch'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button'
import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Scrollable from 'common/components/scrollable'
import Spinner from 'common/components/spinner'
import Widget from 'common/components/Widget'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Edit as EditIcon, Magic2 as MagicHatIcon } from 'common/icons'
import { initializePage } from 'features/admin/styleGuide/store/styleGuideSlice'

import useGetBrandVoiceDetailsQuery from '../../api/queries/useGetBrandVoiceDetailsQuery'
import { getTonesWithColorInfo } from '../../store/brandVoiceSlice'

import ToneCard from './components/ToneCard'

const ToneOfVoice = () => {
  useDocumentTitle('Tone of Voice | Jacquard')

  const { path } = useRouteMatch()
  const generateToneOfVoiceMatch = useRouteMatch({
    path: [generateToneOfVoicePath],
  })
  const dispatch = useAppDispatch()
  const flags = useFlags()
  const history = useHistory()
  const { accountId, brandVoiceId } =
    useParams<{ accountId: string; brandVoiceId: string }>()

  const { data: brandVoice, isLoading: isLoadingBrandVoiceDetails } =
    useGetBrandVoiceDetailsQuery(accountId, brandVoiceId)

  const isLoading = useAppSelector((state) => state.styleGuide.isLoading)
  const regionId = useAppSelector((state) => state.styleGuide.regionId)

  const brandVoiceTones = getTonesWithColorInfo(brandVoice)
  const toneCount = brandVoiceTones.length

  const selectedBrandVoiceId = useAppSelector(
    (state) => state.styleGuide.selectedBrandVoiceId
  )

  const {
    breakEmojiInheritance: isEmojiInheritanceDisabled,
    showBrandIndustry: isBrandIndustryVisible,
  } = flags

  const isGenerateToneOfVoicePath =
    !!generateToneOfVoiceMatch && generateToneOfVoiceMatch.isExact

  useEffect(() => {
    if (!selectedBrandVoiceId) {
      dispatch(
        initializePage({
          isEmojiInheritanceDisabled,
          isBrandTonePageVisible: flags.showBrandTonePage ?? false,
          selectedBrandVoiceId: brandVoiceId,
          isBrandIndustryVisible,
        })
      )
    }
  }, [
    accountId,
    dispatch,
    isEmojiInheritanceDisabled,
    flags.showBrandTonePage,
    isBrandIndustryVisible,
    brandVoiceId,
    selectedBrandVoiceId,
  ])

  useEffect(() => {
    if (!regionId && !isLoading) {
      history.push(
        generatePath(brandVoicePath, {
          accountId,
        })
      )
    }
  }, [accountId, history, isLoading, regionId])

  useEffect(() => {
    if (
      !isGenerateToneOfVoicePath &&
      toneCount === 0 &&
      !isLoadingBrandVoiceDetails
    ) {
      history.push(
        generatePath(generateToneOfVoicePath, {
          accountId,
          brandVoiceId,
        })
      )
    }
  }, [
    isGenerateToneOfVoicePath,
    accountId,
    brandVoiceId,
    history,
    isLoadingBrandVoiceDetails,
    toneCount,
  ])

  return (
    <CustomSwitch>
      <Route exact path={path}>
        <>
          <Scrollable
            isFullHeight={false}
            content={
              <PageContainer className="max-w-318 sm:mx-auto px-6 pt-12 lg:pt-8">
                <PageHeader>
                  <div
                    data-cy="header"
                    className="flex justify-between items-center w-full"
                  >
                    <div>
                      <PageTitle title="Tone of Voice" />
                      <div className="text-coolGray-400">
                        This lets Jacquard know what we should or should not use
                        in the generation of your content.
                      </div>
                    </div>
                    <div className=" text-right w-140">
                      <Button
                        data-cy="edit-tone-button"
                        data-testid="edit-tone-button"
                        aria-label="Edit"
                        ghost={true}
                        onClick={() => {
                          history.push(
                            generatePath(editToneOfVoicePath, {
                              accountId,
                              brandVoiceId,
                            })
                          )
                        }}
                        className="text-base"
                        prefixIcon={<EditIcon isDefaultColor={false} />}
                      >
                        Edit
                      </Button>
                      <Button
                        data-cy="regenerate-tone-button"
                        data-testid="regenerate-tone-button"
                        aria-label="Regenerate tone"
                        ghost={true}
                        onClick={() => {
                          history.push(
                            generatePath(generateToneOfVoicePath, {
                              accountId,
                              brandVoiceId,
                            })
                          )
                        }}
                        className="text-base ml-4"
                        prefixIcon={<MagicHatIcon isDefaultColor={false} />}
                      >
                        Re-generate brand voice
                      </Button>
                    </div>
                  </div>
                </PageHeader>
                <Widget type="basic" className="mt-6">
                  <Spinner isSpinning={isLoadingBrandVoiceDetails}>
                    <div
                      className={cx('grid gap-4 grid-cols-1', {
                        'sm:grid-cols-2': toneCount === 2 || toneCount === 4,
                        'sm:grid-cols-3': toneCount === 3,
                        'sm:grid-cols-6': toneCount === 5,
                      })}
                    >
                      {brandVoiceTones?.map((tone) => (
                        <ToneCard
                          className={
                            toneCount === 5
                              ? cx({
                                  'col-span-2': tone.order < 3,
                                  'col-span-3': tone.order >= 3,
                                })
                              : undefined
                          }
                          isSelected={true}
                          key={tone.id}
                          tone={tone}
                        />
                      ))}
                    </div>
                  </Spinner>
                </Widget>

                <Footer />
              </PageContainer>
            }
          />
        </>
      </Route>
      {[...toneOfVoiceSubroutes].map(({ key, to, component: Component }) => (
        <Route key={key} path={to} exact>
          <Component />
        </Route>
      ))}
    </CustomSwitch>
  )
}

export default ToneOfVoice
