import { useEffect, useState } from 'react'
import moment from 'moment'

import { connectInternalApi } from 'common/api'
import { fetchUsageReport, ReportResponse } from 'common/api/reporting'
import TextLoader from 'common/components/loaders/TextLoader'
import ProgressBar from 'common/components/ProgressBar'
import { WidgetError } from 'common/components/WidgetMessage'
import Emoji from 'common/emoji'
import { useAppSelector } from 'common/hooks/redux'

import { selectEndDate, selectStartDate } from '../../../store/dashboardSlice'

const FeedbackEmoji = ({ progress }: { progress: number }) => {
  let result

  if (progress <= 10) {
    result = <Emoji symbol="🥰" />
  } else if (progress > 10 && progress <= 20) {
    result = <Emoji symbol="🙂" />
  } else if (progress > 20 && progress <= 40) {
    result = <Emoji symbol="😐" />
  } else {
    result = <Emoji symbol="😬" />
  }

  return result
}

interface Variations {
  mostProductivePeriod: string
  variations: number
  totalVariations: number
}

const initialVariations: Variations = {
  mostProductivePeriod: '',
  variations: 0,
  totalVariations: 0,
}

interface Props {
  accountId: string
  projectIds: string[]
}

const FeedbackWidget: React.FC<Props> = ({ accountId, projectIds }) => {
  const startDate = useAppSelector(selectStartDate)
  const endDate = useAppSelector(selectEndDate)
  const [editsPercentage, setEditsPercentage] = useState(0)
  const [rejectionsPercentage, setRejectionsPercentage] = useState(0)
  const [variations, setVariations] = useState<Variations>(initialVariations)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    function fetchVariationData() {
      return fetchUsageReport({
        accountId,
        usageType: ['campaigns', 'variants'],
        projectIds,
        startDate,
        endDate,
      })
    }

    function fetchPercentageData() {
      return connectInternalApi.get<ReportResponse>(
        `v1/core/reporting/reporting/accounts/${accountId}/feedback/report`,
        {
          params: {
            period_type: 'aggregate',
            project_ids: projectIds,
            start_date: startDate,
            end_date: endDate,
          },
        }
      )
    }

    setIsLoading(true)
    setHasError(false)
    Promise.all([fetchPercentageData(), fetchVariationData()])
      .then((response) => {
        const [percentageData, variationData] = response

        const percentageValues = percentageData.data.data[0]?.values
        setRejectionsPercentage(percentageValues?.[2].value || 0)
        setEditsPercentage(percentageValues?.[3].value || 0)

        const periodType = variationData.data.filters.period_type
        let totalVariations = 0
        let mostProductivePeriodData = {
          mostProductivePeriod: '',
          variations: 0,
        }
        variationData.data.data.forEach((item) => {
          const periodLabel = item.period_label
          const periodVariations = item.values[1].value
          totalVariations += periodVariations

          if (mostProductivePeriodData.variations < periodVariations) {
            let periodLabelText: string
            switch (periodType) {
              case 'weekly':
                periodLabelText = `week started on ${moment(
                  periodLabel,
                  'DD MMM YY'
                ).format('DD MMMM YYYY')}`
                break
              case 'monthly':
                periodLabelText = `month was ${moment(
                  periodLabel,
                  'MMM YY'
                ).format('MMMM YYYY')}`
                break
              case 'yearly':
                periodLabelText = `year was ${periodLabel}`
                break

              default:
                periodLabelText = ''
                break
            }
            mostProductivePeriodData = {
              mostProductivePeriod: periodLabelText,
              variations: periodVariations,
            }
          }
        })

        setVariations({
          ...mostProductivePeriodData,
          totalVariations,
        })
      })
      .catch(() => {
        // setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, projectIds, endDate, startDate])

  return (
    <div className="flex-col">
      {isLoading && <TextLoader />}
      {hasError && <WidgetError />}
      {!hasError && !isLoading && (
        <>
          <div className="text-coolGray-800 text-base font-normal">
            For the period, you have racked up{' '}
            <span className="font-bold">{variations.totalVariations} </span>
            copy {variations.totalVariations === 1
              ? 'variation'
              : 'variations'}{' '}
            and your most productive
            <span className="font-bold">
              {' '}
              {variations.mostProductivePeriod}
            </span>{' '}
            with <span className="font-bold">{variations.variations}</span>.
            Nice!
          </div>
          <div className="mt-12">
            <div className="flex justify-between items-center mb-4">
              <span className="text-xl text-coolGray-800 font-medium">
                {rejectionsPercentage}% Rejections
              </span>
              <span className="text-3xl">
                <FeedbackEmoji progress={rejectionsPercentage} />
              </span>
            </div>
            <ProgressBar progress={rejectionsPercentage} />
            <div className="flex justify-between items-center mb-4 mt-6">
              <span className="text-xl text-coolGray-800 font-medium">
                {editsPercentage}% Edits
              </span>
              <span className="text-3xl">
                <FeedbackEmoji progress={editsPercentage} />
              </span>
            </div>
            <ProgressBar progress={editsPercentage} />
          </div>
        </>
      )}
    </div>
  )
}

export default FeedbackWidget
