import { FallbackProps } from 'react-error-boundary'

const Error: React.FC<FallbackProps> = () => {
  return (
    <div className="h-full bg-white flex justify-center items-center">
      <div
        className="mx-auto text-coolGray-800 relative"
        style={{ width: '400px', top: '-60px' }}
      >
        <div className="text-4xl font-bold">
          Something has gone ever so slightly wrong.
        </div>
        <div className="my-6 text-base font-medium">
          Unable to retrieve the selected experiment.
        </div>
        <div className="font-thin">
          Try refreshing the page or select another experiment. If that doesn’t
          work chat with us, we can fix just about anything.{' '}
        </div>
      </div>
    </div>
  )
}

export default Error
