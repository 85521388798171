import cx from 'classnames'
import Dropdown from 'rc-dropdown'
import Menu, { Divider, Item as MenuItem } from 'rc-menu'

import { ArrowDropdownDown } from 'common/icons'

import Button, { ButtonProps } from './button/Button'

import 'rc-dropdown/assets/index.css'

export interface ButtonOptionsProps {
  key: string
  label?: string
  onClick?: () => void
  type?: 'item' | 'divider'
  isDisabled?: boolean
}

interface ButtonWithDropdownProps extends ButtonProps {
  animation?: string
  placement?: string
  overlayClassName?: string
  options: ButtonOptionsProps[] | undefined
}

const CustomMenu = ({
  options,
}: {
  options: ButtonOptionsProps[] | undefined
}) => (
  <Menu className="shadow box-border py-2 z-40" selectable={false}>
    {options?.map((option) => {
      const { key, isDisabled, label, onClick, type = 'item' } = option
      return type === 'item' ? (
        <MenuItem
          disabled={isDisabled}
          key={key}
          onClick={onClick}
          className={cx(
            {
              'cursor-not-allowed pointer-events-auto': isDisabled,
              'cursor-pointer': !isDisabled,
            },
            'bg-white h-8 pl-4 pr-8 hover:bg-coolGray-50 text-coolGray-800'
          )}
        >
          {label}
        </MenuItem>
      ) : (
        <Divider className="my-4" key={key} />
      )
    })}
  </Menu>
)

export const ButtonWithDropdown = ({
  animation = 'slide-up',
  options,
  className,
  children,
  variant,
  overlayClassName,
  placement = 'bottomRight',
  ...rest
}: ButtonWithDropdownProps) => {
  return (
    <Dropdown
      overlayClassName={overlayClassName}
      trigger={['click']}
      overlay={CustomMenu({ options })}
      animation={animation}
      placement={placement}
    >
      <Button
        className={className}
        variant={variant}
        suffixIcon={<ArrowDropdownDown isDefaultColor={false} />}
        {...rest}
      >
        {children}
      </Button>
    </Dropdown>
  )
}

export default ButtonWithDropdown
