import React from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import TextLoader from 'common/components/loaders/TextLoader'
import MetricItem from 'common/components/MetricItem'
import Widget, { WidgetProps } from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  Calendar as CalendarIcon,
  CheckCampaign as CheckCampaignIcon,
  Complete as CompleteIcon,
  Reaction as ReactionIcon,
} from 'common/icons'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import useGetCampaignMetricsQuery from 'features/homePage/api/queries/useGetCampaignMetricsQuery'

interface Props extends WidgetProps {
  title: React.ReactChild | React.ReactFragment
}

const MetricsCard = ({ title, ...rest }: Props) => {
  const { data, status } = useGetCampaignMetricsQuery()
  const { accountId } = useAppSelector((state) => state.authStates)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const hasNoExperimentYet =
    data?.live?.count === 0 &&
    data?.scheduled?.count === 0 &&
    data?.needApproval?.count === 0 &&
    data?.completed?.count === 0

  return (
    <Widget {...rest}>
      <WidgetHeader
        title={title}
        actions={
          hasNoExperimentYet && (
            <button
              className="group ml-5 gap-2 flex items-center text-coolGray-500 hover:text-maroon-300"
              onClick={() => {
                history.push(generatePath('/campaigns', { accountId }))
                dispatch(clickCreateCampaign())
              }}
            >
              Create an experiment
              <ArrowRightIcon
                className="text-black group-hover:text-maroon-500"
                isDefaultColor={false}
                size={4}
              />
            </button>
          )
        }
      />
      {hasNoExperimentYet && (
        <div className="box-border text-sm p-4 bg-gray-50 border-solid border border-gray-300 text-center mb-4">
          No experiments yet! Run your first experiment now.
        </div>
      )}
      {
        {
          loading: (
            <div
              data-testid="loader"
              data-cy="list-widget-loader"
              className="px-6 py-4"
            >
              <TextLoader />
            </div>
          ),
          error: <div className="px-4 py-2">Loading Metrics failed</div>,
          success: (
            <div className="grid grid-cols-2 gap-4">
              <MetricItem
                icon={<ReactionIcon isDefaultColor={false} />}
                text={`${data?.live?.count ?? 0} Live`}
              />
              <MetricItem
                icon={<CalendarIcon isDefaultColor={false} />}
                text={`${data?.scheduled?.count ?? 0} Scheduled`}
              />
              <MetricItem
                icon={<CheckCampaignIcon isDefaultColor={false} />}
                text={`${data?.needApproval?.count ?? 0} Awaiting approval`}
              />
              <MetricItem
                icon={<CompleteIcon isDefaultColor={false} />}
                text={`${data?.completed?.count ?? 0} Completed`}
              />
            </div>
          ),
        }[status]
      }
    </Widget>
  )
}

export default MetricsCard
