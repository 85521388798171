import { DistributionChannel as CampaignDistributionChannel } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { DistributionChannel as ProjectDistributionChannel } from 'common/interfaces/projects'

export const formatDistributionChannel = (
  val: ProjectDistributionChannel | CampaignDistributionChannel
): string => {
  if (val === 'push_notification') {
    return 'Push'
  } else if (val === 'sms') {
    return 'SMS'
  } else if (val === 'email') {
    return 'Email subject lines'
  } else {
    return val?.replace(/_/g, ' ') ?? ''
  }
}
