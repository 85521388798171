import { ReactNode } from 'react'
import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'
import { Info } from 'common/icons'

export interface WidgetHeaderProps {
  title: ReactNode
  actions?: ReactNode
  className?: string
  subtitle?: string
}

const WidgetHeader = ({
  title,
  subtitle,
  actions,
  className,
}: WidgetHeaderProps) => (
  <div className={cx('flex justify-between', className)}>
    <div className="mb-4">
      <div className="text-coolGray-800 font-medium text-xl mr-4 capitalize-first">
        {title}
        {subtitle && (
          <div className="text-coolGray-400 mt-1 text-sm font-normal">
            {subtitle}
          </div>
        )}
      </div>
    </div>
    <div>{actions}</div>
  </div>
)

export default WidgetHeader

export const InfoAction = ({ message }: { message: ReactNode }) => (
  <Tooltip overlay={message} overlayClassName="max-w-52">
    <div data-cy="widget-tooltip">
      <Info size={6} className="opacity-50 text-coolGray-400" />
    </div>
  </Tooltip>
)
