export const variantStatusSelectOptions = {
  live: [
    {
      label: 'Live',
      value: 'live',
    },
    {
      label: 'Dropped',
      value: 'dropped',
    },
  ],
  approved: [
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Dropped',
      value: 'dropped',
    },
  ],
  dropped: [
    {
      label: 'Dropped',
      value: 'dropped',
    },
    {
      label: 'Approved',
      value: 'approved',
    },
  ],
  pending: [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
  ],
}

export const variantStatusFilters = [
  {
    text: 'Approved',
    value: 'approved',
  },
  {
    text: 'Dropped',
    value: 'dropped',
  },
  {
    text: 'Live',
    value: 'live',
  },
  {
    text: 'Pending',
    value: 'pending',
  },
]

export function getRoundedNumber(value: any, decimalPlaces = 2) {
  return typeof value === 'number' && !Number.isInteger(value)
    ? value.toFixed(decimalPlaces)
    : value
}
