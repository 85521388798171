import { Column } from 'common/components/table'
import { OnCellChange } from 'common/components/table'
import {
  NumericEditableCell,
  SingleSelectCell,
} from 'common/components/table/cells'
import TableWidget from 'common/components/widget/tableWidget'

type Props = {
  finalTrackedMetrics: {
    displayName: string
    metric: string
    value: number | undefined
  }[]
  variants: { name: string; value }[]
  selectedVariantId: string | undefined
  onCellChange: OnCellChange
}

const WinningVariantResults: React.FC<Props> = ({
  finalTrackedMetrics,
  variants,
  selectedVariantId = '',
  onCellChange,
}) => {
  const variantColumn: Column = {
    Header: 'Winning Variant',
    accessor: 'variant',
    isResizable: true,
    width: 500,
    disableSortBy: true,
    Cell: (values) => (
      <SingleSelectCell
        {...values}
        value={selectedVariantId}
        onCellChange={values.onCellChange}
        singleSelectProps={{
          isClearable: true,
          options: variants.map((variant) => ({
            label: variant.name,
            value: variant.value,
          })),
        }}
      />
    ),
  }

  const columnMetrics: Column[] = finalTrackedMetrics.map(
    ({ displayName, metric }, index) => ({
      Header: displayName,
      width: 180,
      accessor: metric,
      isResizable: false,
      isEditable: true,
      disableSortBy: true,
      align: index < finalTrackedMetrics.length - 1 ? 'center' : 'right',
      Cell: NumericEditableCell,
    })
  )
  const data = [
    {
      variant:
        variants.find((variant) => variant.value === selectedVariantId)?.name ??
        '',
      ...finalTrackedMetrics.reduce(
        (acc, { metric, value }) => ({
          ...acc,
          [metric]: metric === variantColumn.accessor ? '' : value,
        }),
        {}
      ),
    },
  ]

  const columns = [variantColumn, ...columnMetrics]
  return (
    <>
      <TableWidget.Widget
        className="mt-8"
        columns={columns}
        disablePagination
        data={data}
        onCellChange={({ columnId, rowIndex, value }) => {
          onCellChange({ rowIndex, columnId, value })
        }}
      >
        <TableWidget.Header title="Winning variants results (optional)"></TableWidget.Header>
      </TableWidget.Widget>
      <ul className="text-coolGray-500 mt-8 list-decimal list-inside">
        <li>Select a winning variant from the drop-down.</li>
        <li>Edit your results by clicking the cell you would like to edit.</li>
        <li>Hit the return key when you are done. </li>
        <li>Once you are happy with all the results click Save.</li>
      </ul>
    </>
  )
}

export default WinningVariantResults
