import Button from 'common/components/button'
import { Fragment } from 'common/components/topics/interfaces'
import { highlightTextFragments } from 'common/helpers/string'
import { useAppSelector } from 'common/hooks/redux'
import { replaceNewLineEmojiAsLineBreak } from 'common/variantsUtils'

type Props = {
  controlText: string
  description: string | undefined
  fragments: Fragment[]
  isAdvancedSettingsShown: boolean
  onAdvancedSettingsClick?: () => void
}

const ControlVariant = ({
  controlText,
  description,
  fragments,
  isAdvancedSettingsShown,
  onAdvancedSettingsClick,
}: Props) => {
  const descriptionFragments = useAppSelector(
    (state) => state.contentGeneration.descriptionFragments
  )

  const enchancedControlText = highlightTextFragments(controlText, fragments)
  const enchancedControlTextWithNewLines =
    replaceNewLineEmojiAsLineBreak(enchancedControlText)

  const enchancedDescriptionText = highlightTextFragments(
    description || '',
    descriptionFragments
  )
  const enchancedDescriptionTextWithNewLines = replaceNewLineEmojiAsLineBreak(
    enchancedDescriptionText
  )

  return (
    <div className="p-6 font-medium text-lg text-gray-800 border-b border-dashed border-coolGray-300 bg-coolGray-50 bg-opacity-25">
      <div className="flex mb-9 items-baseline justify-between">
        {description ? (
          <div>Your control variant and content description</div>
        ) : (
          <div>
            Your control variant{' '}
            <span className="ml-4 font-normal text-sm text-gray-500">
              You configured this on step 1.
            </span>
          </div>
        )}
        {isAdvancedSettingsShown && (
          <Button
            variant="primary"
            onClick={onAdvancedSettingsClick}
            data-cy="advanced-settings-button"
          >
            Advanced settings
          </Button>
        )}
      </div>
      <div
        className="font-normal"
        dangerouslySetInnerHTML={{ __html: enchancedControlTextWithNewLines }}
      />
      {description && (
        <div
          className="pt-6 mt-6 font-normal border-t border-dashed border-coolGray-300"
          dangerouslySetInnerHTML={{
            __html: enchancedDescriptionTextWithNewLines,
          }}
        />
      )}
    </div>
  )
}

export default ControlVariant
