import { avatarColors } from 'features/profile/AvatarColorPicker'

import OverlappingItems from './overlappingItems/OverlappingItems'
import Avatar from './Avatar'

interface Props {
  userNames: string[]
}

const AvatarList = ({ userNames }: Props) => {
  return userNames.length > 0 ? (
    <OverlappingItems
      size="small"
      itemNames={userNames}
      items={userNames.map((user, key) => {
        const names = user

        if (!names || names?.length === 0) {
          return <></>
        }

        return (
          <Avatar
            key={key}
            firstName={names[0]}
            lastName={names[1]}
            color={avatarColors[key % avatarColors.length]}
            className="w-8 h-8 border-2 text-xs border-white"
          />
        )
      })}
    />
  ) : null
}

export default AvatarList
