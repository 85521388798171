import React from 'react'

const getShadow = (verticalShadow) => {
  return `0 ${verticalShadow}px 9px rgba(0, 0, 0, 0.16)`
}

type Props = {
  symbol: string
  verticalShadow?: string
  className?: string
}

const Emoji: React.FC<Props> = ({
  symbol,
  className,
  verticalShadow = '13',
}) => {
  return (
    <span
      className={className}
      style={{ textShadow: getShadow(verticalShadow) }}
      role="img"
    >
      {symbol}
    </span>
  )
}

export default Emoji
