import { Node, Region } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'

export async function fetchRegion(regionId: string): Promise<Region> {
  const response = await connectInternalApi.get<{ data: Region }>(
    `v1/core/monorail/regions/${regionId}`
  )
  return response.data.data
}

export async function updateRegion(region: Region): Promise<Region> {
  const response = await connectInternalApi.patch<{ data: Region }>(
    `v1/core/monorail/regions/${region.id}`,
    region
  )
  return response.data.data
}

export async function fetchPronounsNodes(
  contentUpdateId: number
): Promise<Node[]> {
  const response = await connectInternalApi.get<{ data: Node[] }>(
    `v1/language/content-updates/${contentUpdateId}/nodes`
  )
  return response.data.data
}
