import { useEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { hideRightPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

const RightPanelEmpty = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(hideRightPanel('none'))
  }, [dispatch])

  return (
    <div
      data-testid="rightPanelEmpty"
      className="hidden lg:flex w-90 ml-6 min-h-full"
    ></div>
  )
}

export default RightPanelEmpty
