/* eslint-disable max-lines */

import {
  DEFAULT_TOAST_DURATION,
  errorToast,
  successToast,
  warningToast,
} from 'common/components/toastNotification'

import * as newActionTypes from '../../redux/actionTypes'
import apiUtil from '../../workflow/utils/Api'
import { TypeOfBanner } from '../../workflow/utils/enum'

import * as types from './common.actionTypes'

export function setCampaignType(campaignType: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.CURRENT_CAMPAIGN_TYPE_CHOSEN,
      campaignType,
    })
  }
}

// added to uti the step two

/**
 * Callled when the user presses the save and exit button on step 2
 * @param {object} data - The data object to save
 */
export function saveAndExit(data: any) {
  return (dispatch: any) => {
    apiUtil('campaigns/save-campaign-step2', {
      method: 'POST',
      body: data,
    })
      .then(() => {
        dispatch({ type: types.SAVE_CAMPAIGN_STEP2_SUCCESS })
      })
      .catch((error) => {
        dispatch({
          type: types.SAVE_CAMPAIGN_STEP2_ERROR,
          message: error.message,
        })
      })
  }
}

/**
 * Callled when the user presses the Generate to generate push notifications on step 2.
 * @param {string} campaignId - Id of the campaign created in step 1
 * @param {string} project_id - Id of the project
 * @param {Object} questions_and_ans - Object containing step 2 questions and answers
 * @param {boolean} regenerate - Indicates whether we should regenerate push notifs
 */
export function generateSubjectLines(obj: any) {
  return (dispatch: any) => {
    const {
      campaign_id,
      project_id,
      questions_and_ans,
      regenerate,
      subjectlines,
      steps,
      isNoAdBody,
      currencyPattern,
      skipPoochieValidation,
    } = obj

    dispatch({
      type: types.STEP_TWO_UPDATE_NER_VALIDATION_MODAL,
      message: '',
      visible: false,
    })
    if (regenerate) {
      dispatch({ type: types.STEP_TWO_SUBMITTING, loading: true })
      const requestBody = {
        project_id,
        campaign_id,
        questions: questions_and_ans,
        isNoAdBody,
        currency_pattern: currencyPattern,
        skipPoochieValidation,
      }
      apiUtil('campaigns/generate_subjectlines', {
        method: 'POST',
        body: requestBody,
      })
        .then((response) => {
          dispatch({
            type: types.STEP_TWO_SUCCESS_ANIMATION,
            slGeneratingStatus: true,
          })
          const { data } = response
          // check if poochie validation failed
          // in case of fail, show modal with customer_message
          // also check that skipPoochieValidation was not set to true
          // skipPoochieValidation = true shows that user chose to continue after fail
          const nerValidation = data.ner_validation || {}
          if (nerValidation.status === 'fail') {
            let customerMessage = ''
            try {
              customerMessage =
                data.ner_validation.test_result.customer_message[0]
                  .customer_msg_text
            } catch (err) {
              // eslint-disable-next-line no-console
              console.error(err)
            }

            dispatch({
              type: types.STEP_TWO_UPDATE_NER_VALIDATION_MODAL,
              message: customerMessage,
              visible: true,
            })
          } else {
            setTimeout(() => {
              dispatch({
                type: types.STEP_TWO_SUBMIT_SUCCESS,
                loading: false,
                subjectlines: response.data,
                regenerate,
              })
            }, 3000)

            dispatch({
              type: types.STEP_EDITING_DONE,
              previous_step: steps.previous_step,
              current_step: steps.current_step,
              editing_step: 2,
              currentStep: steps.currentStep,
              previousStep: steps.previousStep,
            })
            dispatch({ type: types.CURRENT_EDITING_STEP, step: 0 })
          }
        })
        .catch((error) => {
          dispatch({
            type: types.STEP_TWO_SUBMIT_ERROR,
            message: error.message,
            loading: false,
          })
        })
    } else {
      dispatch({
        type: types.STEP_EDITING_DONE,
        previous_step: steps.previous_step,
        current_step: steps.current_step,
        editing_step: 2,
        currentStep: steps.currentStep,
        previousStep: steps.previousStep,
      })
      dispatch({ type: types.CURRENT_EDITING_STEP, step: 0 })
      dispatch({
        type: types.STEP_TWO_SUBMIT_SUCCESS,
        loading: false,
        regenerate: false,
        subjectlines,
      })
    }
  }
}

/**
 * Setter for the days to go prop
 * @param {Object} daysToGoDate - Moment object
 */
export function setDaysToGo(daysToGoDate: any) {
  return (dispatch: any) => {
    dispatch({ type: types.STEP_TWO_SET_DAYS_TO_GO, daysToGoDate })
  }
}

/**
 * Setter for the generator animation values
 * @param {string} imagePath - The image to show in the animation
 * @param {string} text - The text to show below the image
 */
export function setAnimation(imagePath: any, text: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.STEP_TWO_SET_ANIMATION_IMAGE,
      animationImage: imagePath,
      animationText: text,
    })
  }
}

/**
 * Setter for the animation percentage progress value for the generator animation
 * @param {Number} progress - The percentage progress that has been made
 */
export function setAnimationPercentage(progress: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.STEP_TWO_SET_ANIMATION_PERCENTAGE,
      percentage: progress,
    })
  }
}

/**
 * Update the animation progress
 * @param {Number} animationStep
 */
export function updatePushAnimationProgress(animationStep: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.STEP_TWO_UPDATE_GENERATION_ANIMATION_STEP,
      slAnimationProgress: animationStep,
    })
  }
}

/**
 * Called when the user makes changes to input values in step 2
 * @param {Object} values - Object containing new values
 * @param {Object} conditionalHiddenStatus
 * - Contains the new status for fields which can be conditionally hidden
 * @param {Object} conditionalOpenParents
 * - Contains the parents of conditional fields which are currently open
 * @param {Object} validationErrors - Contains updated validation errors to show to the user
 * @param {Boolean} isQuestionLoaded - Tells if question was loaded
 */
// eslint-disable-next-line max-params
export function setNewValues(
  values: any,
  conditionalHiddenStatus: any,
  conditionalOpenParents: any,
  validationErrors: any,
  isQuestionLoaded: any
) {
  return (dispatch: any) => {
    dispatch({
      type: types.STEP_TWO_SET_NEW_VALUES,
      values,
      conditionalHiddenStatus,
      conditionalOpenParents,
      validationErrors,
      isQuestionLoaded,
      secondaryValues: {},
    })
  }
}

/**
 * API call for getting the questions for a given grammar
 * @param {string} campaignId - Id of the campaign created in step 1
 * @param {string} grammarId - Id of the grammar selected in step 1
 */
export function getQuestions(campaignId: any, grammarId: any) {
  return (dispatch: any) => {
    const requestBody = {
      grammar_id: grammarId,
      campaign_id: campaignId,
    }

    dispatch({ type: types.STEP_TWO_QUESTIONS_LOADING, loading: true })

    apiUtil('campaigns/fetch_questions', {
      method: 'POST',
      body: requestBody,
    })
      .then((response) => {
        dispatch({
          type: types.STEP_TWO_QUESTIONS_SUCCESS,
          questions: response.data.fields,
          grammar: response.data.name,
          currencyPattern: response.data.currency_pattern || '',
          loading: false,
        })
      })
      .catch((error) => {
        dispatch({
          type: types.STEP_TWO_QUESTIONS_ERROR,
          message: `Error in fetching projects: ${error.message}`,
          loading: false,
        })
      })
  }
}

export function getCampaignCommon(data: any) {
  return function (dispatch: any) {
    dispatch({
      type: types.CAMPAIGN_LOADING,
      loading: true,
    })
    apiUtil('campaigns/view', {
      method: 'POST',
      body: data,
    })
      .then((response) => {
        const campaign_data = response.data || {}
        campaign_data.id = data.campaign_id
        dispatch({
          type: types.CAMPAIGN_LOADING_SUCCESS_STEP_TWO,
          campaign_data,
          loading: false,
        })
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch({
            type: types.CAMPAIGN_LOADING_ERROR,
            campaignViewErr: true,
            errMsg: error.message,
            loading: false,
          })
        } else {
          dispatch({
            type: types.CAMPAIGN_LOADING_ERROR,
            campaignViewErr: false,
            errMsg: error.message,
            loading: false,
          })
        }
      })
  }
}

/**
 * Called when the user presses the OK button after being shown a step 2 generation error
 */
export function acknowledgeGenerationError() {
  return (dispatch: any) => {
    dispatch({ type: types.STEP_TWO_GENERATION_ERROR_ACKNOWLEDGED })
  }
}

/**
 * Called when we need to update whether the regenerate modal is visible
 * @param {boolean} regenerateModalVisible - True if the modal should be visible
 */
export function updateRegenerateModalVisible(regenerateModalVisible: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.STEP_TWO_UPDATE_REGENERATE_MODAL,
      regenerateModalVisible,
    })
  }
}

/**
 * Updates the validation errors
 * @param {Object} validationErrors - The updated validation errors
 */
export function updateValidationErrors(validationErrors: any) {
  return (dispatch: any) => {
    dispatch({
      type: types.STEP_TWO_UPDATE_VALIDATION_ERRORS,
      validationErrors,
    })
    return Promise.resolve()
  }
}

/**
 * Called when the user presses the button to edit step 2
 * @param {Object} data - The edit data object
 */
export function editStepTwo(data: any) {
  return function (dispatch: any) {
    dispatch({
      type: types.STEP_EDITING,
      current_step: data.current_step,
      previous_step: data.previous_step,
      currentStep: data.currentStep,
      previousStep: data.previousStep,
      editing_step: data.editing_step,
    })
    if (!data.isCancel) {
      dispatch({ type: types.CURRENT_EDITING_STEP, step: 2 })
    }
  }
}

export function alignTopCampaignStep(step: any) {
  return function (dispatch: any) {
    dispatch({
      type: types.CAMPAIGN_LOAD_ON_TOP,
      step,
    })
  }
}

export function resetBanner() {
  return function (dispatch: any) {
    dispatch({
      type: newActionTypes.RESET_BANNER,
    })
  }
}

export function resetModal() {
  return function (dispatch: any) {
    dispatch({
      type: newActionTypes.RESET_MODAL,
    })
  }
}

// TODO: remove this function
export function showBanner({
  content,
  type,
  duration = DEFAULT_TOAST_DURATION / 1000,
}: any) {
  return function (dispatch: any) {
    duration = duration * 1000
    let fallbackMessage
    // If message is not defined then we define a fallback message.
    if (!content) {
      fallbackMessage = type === TypeOfBanner.SUCCESS ? 'Success' : 'Error'
    }
    const text = !content ? fallbackMessage : content
    if (type === TypeOfBanner.WARNING) {
      warningToast(text, {
        autoClose: duration,
        onClose: () => dispatch(resetBanner()),
      })
    }
    if (type === TypeOfBanner.SUCCESS) {
      successToast(text, {
        autoClose: duration,
        onClose: () => dispatch(resetBanner()),
      })
    }
    if (type === TypeOfBanner.ERROR) {
      errorToast(text, {
        autoClose: duration,
        onClose: () => dispatch(resetBanner()),
      })
    }
    dispatch({
      type: newActionTypes.DISPLAY_BANNER,
      message: !content ? fallbackMessage : content,
      typeOfBanner: type,
    })
  }
}

// show/hide the ner validation modal, update the message
export function updateNerValidationModal({ message, visible }: any) {
  return {
    type: types.STEP_TWO_UPDATE_NER_VALIDATION_MODAL,
    message,
    visible,
  }
}

// eslint-disable-next-line max-params
export function notifyWithMandrill(
  campaignData: any,
  notification_type: any,
  properties: any,
  recipients: string | undefined
) {
  return (dispatch: any) => {
    apiUtil('notifications/notify/', {
      method: 'POST',
      body: {
        campaign_id: campaignData._id,
        notification_type,
        notification_recipients: recipients,
        properties,
      },
    })
      .then(() => {
        dispatch(
          showBanner({
            content:
              'Request received successfully, the team will notify you when the experiment is ready for approval.',
            type: 'success',
          })
        )
      })
      .catch(() => {
        dispatch(
          showBanner({
            content:
              'Error notifying Jacquard, please try again. If the issue persists, contact your customer success manager with the request.',
            type: 'error',
          })
        )
      })
  }
}
