import React, { useState } from 'react'
import { InputNumber } from 'antd'
import { gql } from 'graphql-request'
import { showBanner } from 'workflow/common/common.actions'

import { fetchGraphQL } from 'common/api'
import Button from 'common/components/button/Button'
import Modal from 'common/components/Modal'
import { currencyToSymbol } from 'common/helpers/currency'
import { useAppDispatch } from 'common/hooks/redux'

interface RevenueInformationResponse {
  average_order: { value: number; iso_currency: string; iso_language: string }
  open_conversion_percentage: number
}

interface Props {
  isVisible: boolean
  defaultAverageOrder: number | undefined
  defaultOpenConversionPercentage: number | undefined
  onClose: () => void
  updateConfig: (
    order: number | undefined,
    conversion: number | undefined
  ) => void
  currency: string
}

const IncrementalRevenueConfigurationModal = ({
  isVisible,
  defaultAverageOrder,
  defaultOpenConversionPercentage,
  onClose,
  updateConfig,
  currency,
}: Props) => {
  const dispatch = useAppDispatch()

  const [averageOrder, setAverageOrder] = useState(defaultAverageOrder)
  const [openConversionPercentage, setOpenConversionPercentage] = useState(
    defaultOpenConversionPercentage
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const dynamicCurrency = currencyToSymbol[currency]

  async function saveConfig(): Promise<RevenueInformationResponse> {
    const query = gql`
      mutation updateRevenueInformation($id: RevenueInformationRequest) {
        updateRevenueInformation(revenueInformation: $id) {
          average_order {
            value
            iso_currency
            iso_language
          }
          open_conversion_percentage
        }
      }
    `

    const variables = {
      id: {
        average_order_value: averageOrder,
        open_conversion_percentage: openConversionPercentage,
      },
    }

    return fetchGraphQL<RevenueInformationResponse>({ query, variables })
  }

  const onSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (e) => {
    e.preventDefault()
    setIsLoading(true)

    saveConfig()
      .then(() => {
        dispatch(
          showBanner({
            content: 'Incremental revenue settings saved successfully',
            type: 'success',
          })
        )
        updateConfig(averageOrder, openConversionPercentage)
        onClose()
      })
      .catch(() => {
        dispatch(
          showBanner({
            content: 'Failed to save incremental revenue settings',
            type: 'error',
          })
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Modal
      destroyOnClose={true}
      visible={isVisible}
      maskClosable
      onClose={() => onClose()}
      centered
      closable={false}
      className="p-0"
      bodyStyle={{ backgroundColor: 'white', borderRadius: 6 }}
      footer={null}
    >
      <form onSubmit={onSubmit}>
        <div className="flex flex-col text-coolGray-800 ">
          <h1 className="text-2xl font-bold mb-2">
            Incremental revenue settings
          </h1>
          <div className="mb-8 font-medium">
            We estimate incremental revenue by determining how many extra opens
            Jacquard has generated and then multiply that by the value of an
            extra open (conversion rate * average order value).
          </div>
          <div className="mb-6">
            <div className="font-medium mb-1">
              {`Your average order value in ${dynamicCurrency} (AOV)`}
            </div>
            <InputNumber
              data-cy="average-order"
              className="placeholder-coolGray-400"
              onChange={(value) => setAverageOrder(value)}
              defaultValue={defaultAverageOrder}
              placeholder={`${dynamicCurrency}48.00`}
              precision={0}
              min={0}
              decimalSeparator="."
              formatter={(value) =>
                value
                  ? `${dynamicCurrency} ${String(value).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )}`
                  : ''
              }
              parser={(value) => value?.replace(/([^\d])|(,*)/g, '') ?? ''}
            />
            <div className="text-coolGray-400 mt-2">
              What's the average order value of customers on site.
            </div>
          </div>
          <div>
            <div className="text-coolGray-800 font-medium mb-1">
              Open-to-purchase conversion rate
            </div>
            <InputNumber
              data-cy="open-conversion-percentage"
              className="text-coolGray-800 placeholder-coolGray-400"
              onChange={(value) => setOpenConversionPercentage(value)}
              defaultValue={openConversionPercentage}
              placeholder="23.00%"
              min={0}
              max={100}
              formatter={(value) => (value ? `${value}%` : '')}
              parser={(value) =>
                value === undefined ? '' : value.replace('%', '')
              }
            />
            <div className="text-coolGray-400 mt-2">
              What percentage of people who open an email go on to convert.
            </div>
          </div>
          <div className="mt-12 self-end">
            <Button
              ghost
              className="mr-4 text-maroon-500 font-medium"
              onClick={() => {
                onClose()
              }}
              data-cy="incremental-revenue-options-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="font-medium"
              type="submit"
              disabled={!(averageOrder && openConversionPercentage)}
              data-cy="incremental-revenue-options-save"
              loading={isLoading}
            >
              Save it!
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default IncrementalRevenueConfigurationModal
