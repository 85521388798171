import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import cx from 'classnames'

import { stringToNumber } from 'common/helpers/numeric'

import Input, { InputProps } from '../Input'
import Label from '../Label'

import {
  addPostfixAndPrefix,
  addZeroes,
  moveCaretBack,
  parseValueWithRange,
  removeNonNumericChars,
} from './helpers'

export const numericInputStyles = `h-10 px-3 py-2 text-sm bg-gray-50
            border border-blueGray-300 focus:outline-none
            focus:placeholder-maroon-300 focus:border-maroon-500`

type Props = {
  label?: string
  precision?: number
  prefix?: string
  postfix?: string
  value?: number
  min?: number
  max?: number
  onChange: (value?: number) => void
  isWithoutStyles?: boolean
  formatValue?: (value: string) => string
  'data-cy'?: string
  autoResize?: {
    paddingPx?: number
  }
} & Omit<InputProps, 'type' | 'value' | 'handleChange' | 'onChange'>

const NumericInput = ({
  id,
  label,
  precision = 2,
  prefix,
  postfix,
  value,
  min,
  max,
  className,
  'data-cy': dataCy,
  onChange,
  formatValue,
  isWithoutStyles = false,
  autoResize,
  ...rest
}: Props) => {
  const [stateValue, setStateValue] = useState<string>(
    value !== undefined ? String(value) : ''
  )
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value

      if (prefix || postfix || formatValue) {
        value = removeNonNumericChars(value)
      }

      if (Number(value) > Number.MAX_SAFE_INTEGER) {
        return
      }

      const numberRegex =
        precision > 0
          ? new RegExp(`^\\d+\\.?\\d{0,${precision}}$`, 'g')
          : new RegExp(`^[0-9]+$`)

      if (value.match(numberRegex) || !value) {
        const valueWithPostfixAndPrefix = addPostfixAndPrefix(
          value,
          postfix,
          prefix
        )
        setStateValue(valueWithPostfixAndPrefix)

        if (postfix && inputRef.current) {
          moveCaretBack(inputRef.current, valueWithPostfixAndPrefix.length)
        }

        onChange(stringToNumber(value))
      } else if (postfix && inputRef.current) {
        moveCaretBack(inputRef.current, event.target.value.length)
      }
    },
    [onChange, precision, prefix, postfix, formatValue]
  )

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const value = removeNonNumericChars(event.target.value)

    const valueWithRange = parseValueWithRange(value, min, max)
    const valueWithZeros = addZeroes(valueWithRange, precision)

    onChange(stringToNumber(valueWithZeros))

    setStateValue(addPostfixAndPrefix(valueWithZeros, postfix, prefix))
  }

  useEffect(() => {
    const stringValue = Number.isNaN(value)
      ? String(min || '0')
      : value !== undefined
      ? String(value)
      : ''
    setStateValue(addPostfixAndPrefix(stringValue, postfix, prefix))
  }, [value, prefix, postfix, min])

  const displayValue = formatValue ? formatValue(stateValue) : stateValue
  return (
    <>
      {/* TODO: remove label, use FormItem instead */}
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input
        className={cx(
          {
            [numericInputStyles]: !isWithoutStyles,
            'text-coolGray-400': rest.disabled,
            'text-coolGray-800': !rest.disabled,
          },
          className
        )}
        onChange={handleChange}
        type="text"
        id={id}
        data-cy={dataCy}
        ref={inputRef}
        value={formatValue ? formatValue(stateValue) : stateValue}
        onBlur={onBlur}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            inputRef.current?.blur()
          }
        }}
        {...rest}
        style={
          autoResize !== undefined
            ? {
                ...rest.style,
                width: `calc(${Math.max(displayValue.length, 1)}ch + ${
                  (autoResize.paddingPx ?? 0) * 2
                }px`,
              }
            : rest.style
        }
      ></Input>
    </>
  )
}

export default NumericInput
