import Button, { ButtonProps } from 'common/components/button'

export interface Props extends Omit<ButtonProps, 'data-cy'> {
  cardTitle: string
  cardText: string
  buttonText: string
  isDisabled: boolean
  onSubmit: () => void
  isLoading: boolean
}

export default function CreateVariantsCard({
  cardTitle,
  cardText,
  buttonText,
  isDisabled,
  onSubmit,
  isLoading,
}: Props) {
  return (
    <div
      className="mt-4 mb-4 bg-coolGray-50
      p-4 border border-gray-200"
    >
      <div className="font-bold mb-4">{cardTitle}</div>
      <div className="mb-4 text-coolGray-400">{cardText}</div>
      <div className="justify-end flex flex-wrap">
        <Button
          variant="primary"
          disabled={isDisabled}
          loading={isLoading}
          data-testid="create-variants-button"
          data-cy="create-variants-submit-button"
          onClick={() => onSubmit()}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
