import { useMutation } from '@tanstack/react-query'

import { postPasswordChangeRequest } from '../api'

const useRequestPasswordChange = () => {
  const url = window.location.href
  const params = new URLSearchParams(new URL(url).search)
  const errorDescription = params.get('error_description')

  const email = errorDescription
    ? decodeURIComponent(errorDescription).split('RESET_PASSWORD:')[1]
    : undefined

  const mutation = useMutation({
    mutationFn: () => postPasswordChangeRequest(email),
  })

  return mutation
}

export default useRequestPasswordChange
