import { ChangeEvent, useEffect, useReducer } from 'react'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'
import { SubjectLine } from 'workflow/interface'
import helpers from 'workflow/utils/helpers'
import {
  fetchApprovalsAMPScript,
  shareAMPScriptAction,
} from 'workflow/Workflow.actions'

import Button from 'common/components/button/Button'
import Card from 'common/components/Card'
import Input from 'common/components/Input'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Copy as CopyIcon, Email as EmailIcon } from 'common/icons'

import HelpError from '../HelpError'
import SuccessButton from '../SuccessButton'

const { copyToClipboard } = helpers

const SUCCESS_TIMEOUT = 2000

type Props = {
  projectId: string
  campaignId: string
  campaignName: string
  token: string
  ampScript?: string
}

type State = {
  showEmailAddress: boolean
  showSuccess: boolean
  isSending: boolean
  showCopySuccess: boolean
  emailAddresses?: string
  error?: string
}

type Action =
  | { type: 'set_show_copy_success'; value: boolean }
  | { type: 'set_error'; error?: string }
  | { type: 'set_is_sending'; value: boolean }
  | { type: 'email_change'; emailAddresses: string }
  | { type: 'show_success' }
  | { type: 'send_success' }
  | { type: 'show_email_address' }
  | { type: 'cancel' }

const initialState: State = {
  showEmailAddress: false,
  showSuccess: false,
  isSending: false,
  showCopySuccess: false,
  emailAddresses: undefined,
  error: undefined,
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_show_copy_success':
      return { ...state, showCopySuccess: action.value }
    case 'set_error':
      return { ...state, error: action.error }
    case 'set_is_sending':
      return { ...state, isSending: action.value }
    case 'show_success':
      return { ...state, showSuccess: true }
    case 'show_email_address':
      return { ...state, showEmailAddress: true }
    case 'email_change':
      return {
        ...state,
        emailAddresses: action.emailAddresses,
        error: undefined,
      }
    case 'send_success':
      return {
        ...state,
        showSuccess: false,
        showEmailAddress: false,
        emailAddresses: undefined,
      }
    case 'cancel':
      return {
        ...state,
        error: undefined,
        showEmailAddress: false,
        emailAddresses: undefined,
      }
    default:
      return state
  }
}

const AmpScript = ({
  projectId,
  campaignId,
  campaignName,
  token,
  ampScript,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    showEmailAddress,
    showSuccess,
    isSending,
    showCopySuccess,
    emailAddresses,
    error,
  } = state

  const appDispatch = useAppDispatch()
  const { sl }: { sl: SubjectLine[] } = useAppSelector(
    (state) => state.campaignStates.campaignData
  )
  const { loadingAmpScript } = useAppSelector((state) => state.campaignStates)

  const copyScript = () => {
    copyToClipboard(ampScript)
    dispatch({ type: 'set_show_copy_success', value: true })

    setTimeout(() => {
      dispatch({ type: 'set_show_copy_success', value: false })
    }, SUCCESS_TIMEOUT)
  }

  useEffect(() => {
    if (!ampScript) {
      appDispatch(
        fetchApprovalsAMPScript(
          campaignId,
          projectId,
          campaignName,
          sl.find((el) => el.ownsl)?.text || '',
          sl.length,
          token
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSend = async () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const recipientsList = emailAddresses
      ? emailAddresses
          .split(',')
          .filter((el) => emailRegex.test(el.trim()))
          .map((el) => el.trim())
      : []

    if (recipientsList.length === 0) {
      dispatch({
        type: 'set_error',
        error: 'Please enter a valid email address',
      })
      return
    }

    dispatch({
      type: 'set_is_sending',
      value: true,
    })
    try {
      await appDispatch(
        shareAMPScriptAction(
          campaignId,
          campaignName,
          ampScript!,
          recipientsList,
          token
        )
      )

      dispatch({ type: 'show_success' })
      setTimeout(() => {
        dispatch({ type: 'send_success' })
      }, SUCCESS_TIMEOUT)
    } catch (error: any) {
      dispatch({ type: 'set_error', error: error?.message })
    } finally {
      dispatch({ type: 'set_is_sending', value: false })
    }
  }

  const handleEmailchange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'email_change', emailAddresses: event.target?.value })
  }

  const handleCancel = () => {
    dispatch({ type: 'cancel' })
  }

  return (
    <Card className="flex flex-col my-6">
      <div className="text-coolGray-800 font-medium">AMPScript</div>
      <div className="text-gray-500 font-medium my-4">
        Your AMPScript is used inside Salesforce and will help pull the winner
        from the Jacquard database.
      </div>
      {showEmailAddress && (
        <div className="mb-6">
          <Input
            variant="default"
            data-test-id="email-recipients"
            type="text"
            hasError={!!error}
            placeholder="Email address to send to"
            onChange={handleEmailchange}
          />
          {error ? (
            <HelpError className="block mt-3">{error}</HelpError>
          ) : (
            <GuidingText
              className="mt-3"
              text="To enter multiple email addresses,
           seperate them by using a comma."
            />
          )}
        </div>
      )}
      <div className="self-end text-white">
        {!showEmailAddress && !showCopySuccess && (
          <Spinner isSpinning={loadingAmpScript}>
            <Button
              data-cy="amp-script-email-button"
              variant="primary"
              className="mr-4"
              onClick={() => dispatch({ type: 'show_email_address' })}
              prefixIcon={<EmailIcon isDefaultColor={false} className="mr-4" />}
            >
              Email
            </Button>
            <Button
              data-cy="amp-script-copy-button"
              variant="primary"
              className="text-base"
              onClick={copyScript}
              prefixIcon={<CopyIcon isDefaultColor={false} className="mr-4" />}
            >
              Copy
            </Button>
          </Spinner>
        )}
        {!showEmailAddress && showCopySuccess && (
          <SuccessButton text="Copied to clipboard" variant="primary" />
        )}
        {showEmailAddress && !showSuccess && (
          <>
            <Button
              ghost
              className="mr-4 bg-white"
              onClick={handleCancel}
              data-cy="amp-script-cancel-share-button"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              loading={isSending}
              onClick={handleSend}
              data-cy="amp-script-share-button"
            >
              {isSending ? 'Sending' : 'Send'}
            </Button>
          </>
        )}
        {showEmailAddress && showSuccess && <SuccessButton text="Sent" />}
      </div>
    </Card>
  )
}

export default AmpScript
