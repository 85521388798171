import { useEffect, useRef, useState } from 'react'

import Tooltip from 'common/components/Tooltip'

type Props = {
  accountName: string | undefined
  isDropdownVisible: boolean
}

const AccountSelectButton = ({ accountName, isDropdownVisible }: Props) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)
  const buttonContentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const checkOverflow = () => {
      const container = buttonContentRef.current
      if (container) {
        const hasHorizontalOverflow =
          container.scrollWidth > container.clientWidth
        setIsEllipsisActive(hasHorizontalOverflow)
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [])

  return accountName ? (
    <Tooltip
      placement="bottom"
      overlayClassName="max-w-xs break-words"
      data-cy="account-select-tooltip"
      overlay={accountName}
      show={isEllipsisActive && !isDropdownVisible}
    >
      <div className="truncate" ref={buttonContentRef}>
        {accountName}
      </div>
    </Tooltip>
  ) : null
}

export default AccountSelectButton
