import { IntegrationAccount } from '@phrasee/phrasee-typings'

import GridContainer from 'common/components/grid/GridContainer'
import { avatarColors } from 'features/profile/AvatarColorPicker'

import AccountCard from './AccountCard'

type Props = { accounts: IntegrationAccount[] }

const AccountsGrid = ({ accounts }: Props) => {
  return (
    <GridContainer>
      {accounts.map(({ id, provider, logoUrl, userName, createdAt }, index) => (
        <AccountCard
          key={id}
          name={provider}
          logoUrl={logoUrl}
          userName={userName}
          createdAt={createdAt}
          avatarColor={avatarColors[index % avatarColors.length]}
        />
      ))}
    </GridContainer>
  )
}

export default AccountsGrid
