import cx from 'classnames'

type Props = {
  className?: string
  children?: React.ReactNode
}

const TemplatesConfigPanelMain = ({ className, children }: Props) => {
  return (
    <div
      className={cx(
        'flex border-coolGray-300 border-b-1 border-t-1 overflow-y-auto overflow-x-hidden flex-1',
        className
      )}
    >
      {children}
    </div>
  )
}

export default TemplatesConfigPanelMain
