import { useMemo } from 'react'
import cond from 'lodash/cond'
import filter from 'lodash/filter'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import stubTrue from 'lodash/stubTrue'
import helpers from 'workflow/utils/helpers'

import Badge from 'common/components/Badge'
import Spinner from 'common/components/spinner'
import { useAppSelector } from 'common/hooks/redux'

import ElementCardIcon from '../../components/ElementCardIcon'
import { useSelectedElement } from '../../hooks'

import { filterSlVariantsByBanditStatus } from './helpers'

const OptimizeView = () => {
  const { data: selectedElement } = useSelectedElement()
  const { campaignData } = useAppSelector((state) => state.campaignStates)
  const isLoadingCampaignData = useAppSelector(
    (state) => state.campaignStates.isLoading
  )
  const variantsIdsToBeApproved = useAppSelector(
    (state) => state.campaignStates.variantsToBeApproved
  )

  const variantsToBeApproved = useMemo(() => {
    return filter(campaignData.sl, ({ _id }) =>
      variantsIdsToBeApproved.includes(_id)
    )
  }, [campaignData, variantsIdsToBeApproved])

  if (!selectedElement) {
    return null
  }

  const name = selectedElement.display_name || selectedElement.name
  const isBanditCampaign = helpers.isBanditCampaign(campaignData)

  const slCollection = sortBy(
    filter(get(campaignData, 'sl', []), (item) => !item.ownsl),
    'sort_order'
  )

  const getVariants = cond([
    [
      () => isBanditCampaign && variantsToBeApproved.length > 0,
      () =>
        filterSlVariantsByBanditStatus(slCollection, ['pending', 'dropped']),
    ],
    [
      () => isBanditCampaign && variantsToBeApproved.length === 0,
      () => filterSlVariantsByBanditStatus(slCollection, ['dropped']),
    ],
    [() => !isBanditCampaign, () => slCollection],
    [stubTrue, () => []],
  ])

  const variants = getVariants()
  const controlVariant = campaignData?.sl?.find((variant) => variant.ownsl)

  return isLoadingCampaignData ? (
    <Spinner className="m-auto" />
  ) : (
    <section className="p-6 bg-white border w-full flex flex-col">
      <header>
        <h5 className="text-coolGray-800 text-xl font-medium mb-2.5">{`Launch: ${name}`}</h5>
        <p className="text-coolGray-400 text-sm mb-8 font-normal">
          We can add some notes about what we do with integrations and why it is
          important.
        </p>
      </header>

      <article>
        <h6 className="text-sm font-medium text-coolGray-800 mb-2">Content</h6>
        <div className="p-4 border border-coolGray-400 border-dashed rounded-md">
          <div className="p-4 bg-coolGray-50 rounded-md">
            <div className="flex items-center">
              {selectedElement.icon && (
                <ElementCardIcon icon={selectedElement.icon} size={6} />
              )}
              <span className="ml-2 text-base text-coolGray-800 font-medium">
                {name}
              </span>
            </div>
            <hr className="my-4 text-coolGray-200 border-dashed" />
            <div className="px-6" role="list" aria-label="Variant list">
              {variants?.map((variant, idx) => (
                <p
                  key={variant._id}
                  className="flex items-center text-sm text-coolGray-600 py-4 border-b"
                  role="listitem"
                >
                  <span className="mr-2">{`${idx + 1}.`}</span>
                  <span>{variant.text}</span>
                </p>
              ))}
              {!!controlVariant && (
                <div
                  className="flex items-center py-4"
                  role="listitem"
                  aria-label="Control variant"
                >
                  <Badge
                    size="small"
                    className="mr-2 text-white uppercase"
                    text="Control"
                    variant="neutral"
                  />
                  <span>{controlVariant.text}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}

export default OptimizeView
