import cx from 'classnames'

import Checkbox from 'common/components/checkbox'
import Label from 'common/components/Label'
import RadioGroup from 'common/components/radioGroup'
import Spinner from 'common/components/spinner'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import PunctuationTag from 'features/projects/views/editProject/views/content/components/punctuationWidget/PunctuationTag'

import {
  clickAddPunctuation,
  clickCapitablizeAfterColon,
  clickCapitablizeAfterDash,
  clickCapitablizeAfterEllipsis,
  clickChangeDashStyle,
  clickCurlyApostrophes,
  clickRemovePunctuation,
  clickSpaceAfterEllipsis,
  clickSpaceBeforeEllipsis,
  DEFAULT_PUNCTUATION,
} from '../store/styleGuideSlice'

import PunctuationMessage from './PunctuationMessage'

const SUGGESTION_PUNCTUATION = DEFAULT_PUNCTUATION.concat(['!', '…', ':', '|'])
const DASH_OPTIONS = [
  { label: 'None', value: '' },
  { label: '- Hyphen', value: '-' },
  { label: '– En dash', value: '–' },
  { label: '— Em dash', value: '—' },
]

type Props = {
  className?: string
}

const PunctuationWidget = ({ className }: Props) => {
  const dispatch = useAppDispatch()

  const {
    isLoading,
    editedContent: { punctuation },
  } = useAppSelector((state) => state.styleGuide)

  return (
    <Spinner isSpinning={isLoading}>
      <Widget
        type="basic"
        className={cx(
          'grid grid-cols-1 sm:grid-cols-3 grid-flow-col gap-x-6 gap-y-0',
          className
        )}
      >
        <WidgetHeader
          title="Punctuation"
          subtitle="Let us know what punctuation marks we can use when generating your content."
          className="col-start-1 row-span-1 col-span-2"
        />
        <PunctuationMessage className="col-start-1 sm:col-start-3 row-span-2 col-span-2 mb-4" />
        <div className="col-start-1 row-span-1 col-span-2">
          <PunctuationTag
            title=""
            tags={punctuation.marks.map((value) => ({
              value,
              label: value,
              isDisabled: DEFAULT_PUNCTUATION.some((defaultValue) => {
                return defaultValue === value
              }),
            }))}
            suggestions={SUGGESTION_PUNCTUATION}
            onAddClick={(value: string) => {
              dispatch(clickAddPunctuation(value))
            }}
            onRemoveClick={(value: string) => {
              dispatch(clickRemovePunctuation(value))
            }}
            data-cy="style-guide-punctuation"
            hasTagButtonLabel={true}
            tagButtonLabel="Punctuation"
          />
          <Label className="mt-6">Dash style</Label>
          <div className="text-coolGray-400">
            Select the dash style you want Jacquard to use. We default to Em
            dash unless changed.
          </div>
          <RadioGroup
            name="dash-style"
            className="mt-6"
            onChange={(value) => {
              dispatch(clickChangeDashStyle(value))
            }}
            options={DASH_OPTIONS}
            value={punctuation.dash}
            variant="normal"
            isDisabled={false}
          />
          <Checkbox
            data-cy="use-curly-apostrophes"
            isChecked={punctuation.hasCurlyApostrophes}
            className="mt-6"
            onChange={(isChecked: boolean) => {
              dispatch(clickCurlyApostrophes(isChecked))
            }}
            label={
              <>
                <span className="font-medium">Use curly apostrophes</span> (E.g.
                Our sale’s biggest discounts)
              </>
            }
          ></Checkbox>
          <Checkbox
            data-cy="space-before-ellipses"
            isChecked={punctuation.hasSpaceBeforeEllipsis}
            className="mt-6"
            onChange={(isChecked: boolean) => {
              dispatch(clickSpaceBeforeEllipsis(isChecked))
            }}
            label={
              <>
                <span className="font-medium">
                  Insert a space before ellipses
                </span>{' '}
                (E.g. Hey there …)
              </>
            }
          ></Checkbox>
          <Checkbox
            data-cy="space-after-ellipses"
            isChecked={punctuation.hasSpaceAfterEllipsis}
            className="mt-6"
            onChange={(isChecked: boolean) => {
              dispatch(clickSpaceAfterEllipsis(isChecked))
            }}
            label={
              <>
                <span className="font-medium">
                  Insert a space after ellipses
                </span>{' '}
                (E.g. Hey there… check out our biggest sale)
              </>
            }
          ></Checkbox>
          <Checkbox
            data-cy="capitalize-following-ellipsis"
            isChecked={punctuation.isCapitalizingAfterEllipsis}
            className="mt-6"
            onChange={(isChecked: boolean) => {
              dispatch(clickCapitablizeAfterEllipsis(isChecked))
            }}
            label={
              <>
                <span className="font-medium">
                  Capitalize words following ellipsis
                </span>{' '}
                (E.g. Hey there… Check out our biggest sale)
              </>
            }
          ></Checkbox>
          <Checkbox
            data-cy="capitalize-after-dashes"
            isChecked={punctuation.isCapitalizingAfterDash}
            className="mt-6"
            onChange={(isChecked: boolean) => {
              dispatch(clickCapitablizeAfterDash(isChecked))
            }}
            label={
              <>
                <span className="font-medium">
                  Capitalize words after dashes
                </span>{' '}
                (E.g. Hey there - Check out our biggest sale)
              </>
            }
          ></Checkbox>
          <Checkbox
            data-cy="capitalize-after-colons"
            isChecked={punctuation.isCapitalizingAfterColon}
            className="mt-6"
            onChange={(isChecked: boolean) => {
              dispatch(clickCapitablizeAfterColon(isChecked))
            }}
            label={
              <>
                <span className="font-medium">
                  Capitalize words after colons
                </span>{' '}
                (E.g. Hey there: Check out our biggest sale)
              </>
            }
          ></Checkbox>
        </div>
      </Widget>
    </Spinner>
  )
}

export default PunctuationWidget
