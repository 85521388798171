import { useAppSelector } from 'common/hooks/redux'

import useGetContentQuery from '../../api/queries/useGetContentQuery'
import useGetTemplateQuery from '../../api/queries/useGetTemplateQuery'

const useContentOrTemplate = () => {
  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )

  const activeTemplateId = useAppSelector(
    (state) => state.content.activeTemplateId
  )

  const templateQuery = useGetTemplateQuery(activeTemplateId)
  const contentsQuery = useGetContentQuery(activeContentId)

  return {
    name: templateQuery.data?.name || contentsQuery.data?.name || '',
    display_name:
      templateQuery.data?.display_name ||
      contentsQuery.data?.display_name ||
      '',
    template: templateQuery.data,
    content: contentsQuery.data,
    isLoading: templateQuery.isLoading || contentsQuery.isLoading,
    isError: templateQuery.isError || contentsQuery.isError,
  }
}

export default useContentOrTemplate
