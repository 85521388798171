import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { fetchProjects } from '../api'
import { projectKeys } from '../api/queryKeys'
import { Project, ResponseProject } from '../interface'

const useProjectsQuery = (accountId: string) => {
  return useQuery(
    projectKeys.projects(accountId),
    ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      const promise = fetchProjects(accountId, source)

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      return promise
    },
    {
      select: (data: ResponseProject[]): Project[] =>
        data.map(
          ({
            _id,
            name,
            created,
            last_updated,
            project_configuration,
            deleted,
            archived,
          }) => ({
            id: _id,
            name,
            created,
            lastUpdated: last_updated,
            isDeleted: deleted,
            isArchived: archived,
            distributionChannel:
              project_configuration?.campaign_configurations
                ?.distribution_channel ?? 'email',
            distributionType:
              project_configuration?.campaign_configurations?.distribution_type,
          })
        ),
    }
  )
}

export default useProjectsQuery
