import { useQuery } from '@tanstack/react-query'
import Content from 'app/Content'

import { canAccessAccount, routesPermissions } from 'common/auth/permissions'
import Footer from 'common/components/layout/Footer'
import PageHeader from 'common/components/PageHeader'
import Spinner from 'common/components/spinner'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import useGetContentsQuery from 'features/futurama/api/queries/useGetContentsQuery'
import ContentKpi from 'features/homePage/components/ContentKpi'

import { contentKeys } from './api/queryKeys'
import QuickCards from './components/QuickCards'
import RecentContents from './components/RecentContents'
import RecentExperiments from './components/RecentExperiments'
import Todo from './components/ToDo'
import TodoEmpty from './components/ToDoEmpty'
import UpgradeAccountComponent from './components/UpgradeComponent'
import OptimizationMetrics from './optimizationWidgets/Optimization'
import { fetchRecentlyCreated, fetchTodos, getContentKpi } from './api'
const EXPERIMENTS_LIST_SIZE = 8
const TO_DO_LIST_SIZE = 8

const HomePage = () => {
  useDocumentTitle('Home | Jacquard')

  const {
    accountId,
    user_id: userId,
    accountCategory,
  } = useAppSelector((state) => state.authStates)
  const { screenWidth } = useWindowDimensions()

  const { data: dataExperiments, status: statusExperiments } = useQuery(
    [accountId],
    async () => await fetchRecentlyCreated(0, EXPERIMENTS_LIST_SIZE, accountId)
  )

  const { data: kpiContent, status: kpiContentStatus } = useQuery(
    contentKeys.contentKpi(accountId),
    async () => await getContentKpi(accountId)
  )

  const experiments = dataExperiments ? dataExperiments.campaigns : []

  const { data: dataTodos, status: statusToDos } = useQuery(
    [accountId, userId],
    async () =>
      await fetchTodos({
        page: 0,
        pageSize: TO_DO_LIST_SIZE,
        userId,
        accountId,
      })
  )
  const todos = dataTodos ? dataTodos.campaigns : []

  const { data: contents, status: statusContents } = useGetContentsQuery()

  const hasAccessToExperiments = canAccessAccount(
    accountCategory,
    routesPermissions.experimentsView
  )

  const isLoading =
    statusExperiments === 'loading' ||
    statusToDos === 'loading' ||
    statusContents === 'loading' ||
    kpiContentStatus === 'loading'

  const layoutMd = (
    <div>
      {hasAccessToExperiments && <OptimizationMetrics accountId={accountId} />}
      {!hasAccessToExperiments && <UpgradeAccountComponent />}
      <div className="flex flex-row justify-between gap-6 mb-10">
        <div className="w-full flex flex-1 overflow-hidden">
          <ContentKpi
            title="Content Generation"
            kpiContent={kpiContent}
            isLoading={kpiContentStatus === 'loading'}
          />
        </div>
        {hasAccessToExperiments && (
          <div className="w-full flex flex-1 overflow-hidden">
            {todos.length > 0 ? (
              <Todo campaigns={todos} status={statusToDos} />
            ) : statusToDos !== 'loading' ? (
              <TodoEmpty />
            ) : undefined}
          </div>
        )}
      </div>

      <div className="flex flex-row justify-between gap-6 mb-10">
        {hasAccessToExperiments && (
          <div className="w-full flex flex-1 overflow-hidden">
            {experiments.length > 0 && (
              <RecentExperiments
                campaigns={experiments}
                status={statusExperiments}
              />
            )}
          </div>
        )}
        <div className="w-full flex flex-1 overflow-hidden">
          {contents && contents.length > 0 && (
            <RecentContents contents={contents} status={statusContents} />
          )}
        </div>
      </div>
    </div>
  )

  const layoutSm = (
    <div className="flex flex-col sm:flex-row sm:gap-10">
      <div className="flex-1 overflow-hidden">
        {!hasAccessToExperiments && <UpgradeAccountComponent />}
        {hasAccessToExperiments && (
          <OptimizationMetrics accountId={accountId} />
        )}
        {experiments.length > 0 && hasAccessToExperiments && (
          <div className="mb-6 sm:mb-0">
            <RecentExperiments
              campaigns={experiments}
              status={statusExperiments}
            />
          </div>
        )}
      </div>
      <div className="flex-1 overflow-hidden">
        <div className="mb-6">
          <ContentKpi
            title="Content Generation"
            kpiContent={kpiContent}
            isLoading={kpiContentStatus === 'loading'}
          />
        </div>
        {hasAccessToExperiments && (
          <>
            {todos.length > 0 ? (
              <div className="mb-6">
                <Todo campaigns={todos} status={statusToDos} />
              </div>
            ) : statusToDos !== 'loading' ? (
              <div className="mb-6">
                <TodoEmpty />
              </div>
            ) : null}
          </>
        )}
        {contents && contents.length > 0 && (
          <RecentContents contents={contents} status={statusContents} />
        )}
      </div>
    </div>
  )

  return (
    <Content>
      <div className="max-w-screen-2xl mx-auto w-full relative">
        <PageHeader className="flex flex-wrap justify-between w-full mb-10 lg:mb-16">
          <QuickCards />
        </PageHeader>
        <Spinner isSpinning={isLoading} hasWrapperFullHeight={false}>
          {screenWidth >= 1200 && layoutMd}
          {screenWidth < 1200 && layoutSm}
          <Footer />
        </Spinner>
      </div>
    </Content>
  )
}

export default HomePage
