import { useEffect, useState } from 'react'
import { useIMask } from 'react-imask'
import cx from 'classnames'
import { MaskedRange } from 'imask'
import moment from 'moment'

import { Column, OnCellChange, Row } from 'common/components/table'
import { commonClassName } from 'common/components/table/cells/TextEditable'

const getFormatedValue = (value: string) => {
  if (!value) {
    return ''
  }
  const [startDate, endDate = ''] = value.split(' - ')

  return `${moment(startDate, 'DD/MM').format('D MMM')} to ${moment(
    endDate,
    'DD/MM'
  ).format('D MMM')}`
}

const getDates = (value: string) => {
  let start: moment.Moment | undefined, end: moment.Moment | undefined
  const [startDate, endDate = ''] = value.split(' - ')
  const [startDay, startMonth = ''] = startDate.split('/')
  const [endDay, endMonth = ''] = endDate.split('/')
  if (startDay && startMonth && endDay && endMonth) {
    start = moment(`${startMonth}/${startDay}`, 'MM/DD')
    end = moment(`${endMonth}/${endDay}`, 'MM/DD')
  }
  return { start, end }
}

type Props<T extends {}> = {
  value: string
  row: Row<T>
  column: Column<T>
  onCellChange: OnCellChange
}

const DateRange = <T extends {}>({
  value: initialValue,
  row,
  column: { id },
  onCellChange,
}: Props<T>) => {
  const [error, setError] = useState<string>('')
  const [hasFocus, setHasFocus] = useState(false)

  const onComplete = (val: string) => {
    const { start, end } = getDates(val)

    if (!start?.isValid() || !end?.isValid()) {
      setError('Invalid date')
    }
  }

  const onBlur = () => {
    if (!id) {
      throw new Error('The column id is missing')
    }

    let dateRangeValue = value
    let isDateRangeValid = true

    setError('')
    const { start, end } = getDates(dateRangeValue)

    if (start && end) {
      if (start.isValid() && end.isValid() && start.isAfter(end)) {
        dateRangeValue = `${end.format('DD/MM')} - ${start.format('DD/MM')}`
      } else if (!start.isValid() || !end.isValid()) {
        isDateRangeValid = false
      }
    } else if (dateRangeValue) {
      isDateRangeValid = false
    }

    if (!error && isDateRangeValid) {
      onCellChange({ rowIndex: row.index, columnId: id, value: dateRangeValue })
    } else {
      setValue(initialValue)
      setError('')
    }
  }

  const momentFormat = 'DD/MM - DD/MM'
  const options: IMask.AnyMaskedOptions = {
    mask: momentFormat,
    blocks: {
      MM: {
        mask: MaskedRange,
        from: 1,
        to: 12,
        autofix: 'pad',
      },
      DD: {
        mask: MaskedRange,
        from: 1,
        to: 31,
        autofix: 'pad',
      },
    },
  }
  const { ref, value, setValue } = useIMask(options, { onComplete })

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue, setValue])

  return (
    <div
      className="w-full"
      onClick={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
    >
      {hasFocus ? (
        <>
          <input
            autoFocus={hasFocus}
            className={cx(
              commonClassName,
              'block w-full focus-visible:border-maroon-500 focus:bg-maroon-50 caret-maroon-300',
              {
                'h-full': !error,
              }
            )}
            type="text"
            ref={ref}
            value={value}
            data-cy={`phrase-date-range-${row.index}`}
            onBlur={onBlur}
            placeholder="DD/MM - DD/MM"
          />
          {error && <div className="text-xs text-red-400">{error}</div>}
        </>
      ) : (
        <div
          className={cx(
            commonClassName,
            `flex items-center w-full hover:border-coolGray-400 h-full`
          )}
        >
          {getFormatedValue(value)}
        </div>
      )}
    </div>
  )
}

export default DateRange
