import { ReactNode } from 'react'
import Steps from 'rc-steps'

import 'rc-steps/assets/index.css'
import styles from './Steps.module.css'

interface Props {
  children: ReactNode
}

export const Step = Steps.Step

const StepsComponent = ({ children }: Props) => {
  return (
    <Steps className={styles.steps} direction="vertical">
      {children}
    </Steps>
  )
}

export default StepsComponent
