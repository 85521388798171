import ButtonWithDropdown from 'common/components/ButtonWithDropdown'
import { Add as AddIcon } from 'common/icons'

type Props = {
  onClick: () => void
  description: string
}

const UploadButtonWithDropdown = ({ onClick, description }: Props) => (
  <ButtonWithDropdown
    overlayClassName="capitalize"
    variant="primary"
    suffixIcon={false}
    options={[
      {
        key: 'csv_file',
        label: 'CSV file',
        onClick: onClick,
        isDisabled: false,
      },
      {
        key: 'database',
        label: 'Database',
        onClick: () => {},
        isDisabled: true,
      },
    ]}
    prefixIcon={<AddIcon className="h-4 w-4" isDefaultColor={false} />}
  >
    {description}
  </ButtonWithDropdown>
)

export default UploadButtonWithDropdown
