import FormItem from 'common/components/FormItem'
import NumericInput from 'common/components/numericInput'

import { CommonFieldPropsGeneric, NumericType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please enter a value.',
}

type Props = CommonFieldPropsGeneric & { value: NumericType['value'] }

const Numeric = ({
  label,
  name,
  value,
  tooltip,
  errorCode,
  isDisabled,
  placeholder,
  onChange,
}: Props) => {
  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      <NumericInput
        id={name}
        className="w-25"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={isDisabled}
      />
    </FormItem>
  )
}

export default Numeric
