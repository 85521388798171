import { batch } from 'react-redux'
import { Element } from '@phrasee/phrasee-typings/typings/futurama/element'
import { SplitCalculationType } from '@phrasee/phrasee-typings/typings/futurama/split_calculation_config'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import uniqueId from 'lodash/uniqueId'
import { RootState } from 'redux/store'
import { SplitSizeData } from 'workflow/interface'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import { isComponentElement } from '../api/interfaces'
import { RightPaneVisibility } from '../contentPage/components/layout/RightPanel'
export const MAX_TEMPLATES_TO_ADD = 10

interface UnifiedFlowContentState {
  activeContentId?: string
  selectedElementId?: number | string
  areElementsDisabled: boolean
  rightPanelVisibility: RightPaneVisibility
  isLeftPanelVisible: boolean | undefined
  shouldShowUnsavedModal?: boolean
  openUnsavedModal?: boolean
  pendingSelection?: {
    elementId?: number | string
  }
  previousStep: string | undefined
}

interface UnifiedFlowState extends UnifiedFlowContentState {
  showTemplateView: boolean
  templatesToAdd: TemplateToAdd[]
}

export interface TemplateToAdd {
  id: string
  count: number
  internalId: string
  status: 'pristine' | 'incomplete' | 'complete'
  isOptimized?: boolean
  splitConfiguration?: {
    type: SplitCalculationType
    listSize?: number
    splitNumber?: number
    splitCalculationResult?: SplitSizeData
  }
}

const contentInitialState: UnifiedFlowContentState = {
  selectedElementId: undefined,
  activeContentId: undefined,
  areElementsDisabled: false,
  rightPanelVisibility: 'auto',
  isLeftPanelVisible: undefined,
  shouldShowUnsavedModal: undefined,
  openUnsavedModal: undefined,
  pendingSelection: undefined,
  previousStep: undefined,
}

const initialState: UnifiedFlowState = {
  showTemplateView: false,
  templatesToAdd: [],
  ...contentInitialState,
}

export const selectElementAndFetchCampaignData = createAsyncThunk<
  void,
  Element,
  { state: RootState }
>(
  'unifiedFlow/selectElementAndFetchCampaignData',
  async (element: Element, thunkAPI) => {
    batch(() => {
      thunkAPI.dispatch(selectElement({ elementId: element.element_id }))
      if (isComponentElement(element) && !!element?.campaign_id) {
        thunkAPI.dispatch(fetchCampaignData(element.campaign_id))
      }
    })
  }
)

const unifiedFlowSlice = createSlice({
  name: 'unifiedFlow',
  initialState,
  reducers: {
    resetUnsavedModal: (state) => {
      state.shouldShowUnsavedModal = undefined
      state.openUnsavedModal = undefined
    },
    unifiedContentInitialize: (
      state,
      action: PayloadAction<Partial<UnifiedFlowState>>
    ) => {
      state.activeContentId = action.payload.activeContentId
      state.selectedElementId = action.payload.selectedElementId
    },
    selectElement: (
      state,
      action: PayloadAction<{
        elementId?: number | string
        shouldShowUnsavedModal?: boolean
      }>
    ) => {
      const { elementId, shouldShowUnsavedModal } = action.payload

      state.shouldShowUnsavedModal =
        shouldShowUnsavedModal ?? state.shouldShowUnsavedModal

      if (state.shouldShowUnsavedModal) {
        state.openUnsavedModal = true
        state.pendingSelection = { elementId }
      } else {
        state.selectedElementId = elementId
      }
    },
    userInputFormHasChanged: (
      state,
      action: PayloadAction<{ hasChanged: boolean; isGlobalSettings?: boolean }>
    ) => {
      const { hasChanged, isGlobalSettings } = action.payload
      if (hasChanged && isGlobalSettings) {
        state.areElementsDisabled = true
      } else if (hasChanged && !isGlobalSettings) {
        state.areElementsDisabled = false
        state.shouldShowUnsavedModal = true
      } else {
        state.shouldShowUnsavedModal = false
        state.areElementsDisabled = false
      }
    },
    toggleOpenUnsavedModal: (state, action: PayloadAction<boolean>) => {
      state.openUnsavedModal = action.payload
    },
    inputFormLeave: (state) => {
      state.selectedElementId = state.pendingSelection?.elementId
      state.pendingSelection = undefined
      state.openUnsavedModal = false
      state.shouldShowUnsavedModal = false
    },
    toggleElementsDisabled: (state, action: PayloadAction<boolean>) => {
      state.areElementsDisabled = action.payload
    },
    resetContentPageState: (state) => {
      Object.keys(contentInitialState).forEach((key) => {
        state[key] = contentInitialState[key]
      })
    },
    showTemplates: (state) => {
      state.showTemplateView = true
    },
    hideTemplates: (state) => {
      state.showTemplateView = false
      state.templatesToAdd = []
    },

    showRightPanel: (
      state,
      action: PayloadAction<RightPaneVisibility | undefined>
    ) => {
      state.rightPanelVisibility = action.payload || 'visible'
    },
    hideRightPanel: (
      state,
      action: PayloadAction<RightPaneVisibility | undefined>
    ) => {
      state.rightPanelVisibility = action.payload || 'hidden'
    },
    showLeftPanel: (state) => {
      state.isLeftPanelVisible = true
    },
    hideLeftPanel: (state) => {
      state.isLeftPanelVisible = false
    },
    cancelTemplatesAdd: (state) => {
      state.templatesToAdd = []
      state.showTemplateView = false
    },
    resetTemplatesToAdd: (state) => {
      state.templatesToAdd = []
    },
    postAddTemplate: (
      state,
      action: PayloadAction<Partial<UnifiedFlowState>>
    ) => {
      state.templatesToAdd = []
      state.showTemplateView = false
      state.selectedElementId = action.payload.selectedElementId
    },
    addTemplate(
      state,
      action: PayloadAction<{
        templateId: string
        isOptimized: boolean
      }>
    ) {
      const templatesToAddCount = state.templatesToAdd.length
      if (templatesToAddCount >= MAX_TEMPLATES_TO_ADD) {
        return
      }
      const internalId = uniqueId()
      state.templatesToAdd.push({
        id: action.payload.templateId,
        isOptimized: action.payload.isOptimized,
        count: 1,
        internalId,
        status: 'pristine',
      })
    },
    removeTemplate(
      state,
      action: PayloadAction<{ internalId?: string; templateId?: string }>
    ) {
      if (action.payload.internalId) {
        state.templatesToAdd = state.templatesToAdd.filter(
          (t) => t.internalId !== action.payload.internalId
        )
      } else if (action.payload.templateId) {
        const lastIndex = state.templatesToAdd
          .slice()
          .reverse()
          .findIndex((template) => template.id === action.payload.templateId)

        if (lastIndex !== -1) {
          const indexToRemove = state.templatesToAdd.length - 1 - lastIndex
          state.templatesToAdd.splice(indexToRemove, 1)
        }
      }
    },
    templateSetupComplete: (
      state,
      action: PayloadAction<{
        internalId: string
        status: TemplateToAdd['status']
      }>
    ) => {
      const template = state.templatesToAdd.find(
        (t) => t.internalId === action.payload.internalId
      )
      if (template) {
        template.status = action.payload.status
      }
    },
    updateTemplateSplitConfiguration: (
      state,
      action: PayloadAction<{
        internalId: string
        splitConfiguration: TemplateToAdd['splitConfiguration']
      }>
    ) => {
      const template = state.templatesToAdd.find(
        (t) => t.internalId === action.payload.internalId
      )
      if (template) {
        template.splitConfiguration = action.payload.splitConfiguration
      }
    },
    setPreviousStep: (
      state,
      action: PayloadAction<{
        previousStep: string | undefined
      }>
    ) => {
      state.previousStep = action.payload.previousStep
    },
  },
})

export const {
  resetUnsavedModal,
  unifiedContentInitialize,
  selectElement,
  resetContentPageState,
  userInputFormHasChanged,
  toggleElementsDisabled,
  showTemplates,
  hideTemplates,
  showRightPanel,
  hideRightPanel,
  showLeftPanel,
  hideLeftPanel,
  addTemplate,
  postAddTemplate,
  removeTemplate,
  cancelTemplatesAdd,
  resetTemplatesToAdd,
  toggleOpenUnsavedModal,
  inputFormLeave,
  templateSetupComplete,
  updateTemplateSplitConfiguration,
  setPreviousStep,
} = unifiedFlowSlice.actions

/*
 * Selectors
 */

export const selectTemplatesToAddWithOptimization = (
  state: RootState
): TemplateToAdd[] =>
  state.unifiedFlow.templatesToAdd.filter((template) => template.isOptimized)
export default unifiedFlowSlice.reducer
