import React from 'react'
import * as Highcharts from 'highcharts/highstock'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'
import { yAxisConfig } from 'common/helpers/ui/graph'

export interface BarData {
  name: string
  color: string
  value: number
}

interface Props {
  data: BarData[]
  labelRotationDegrees?: number
  labelFontSize: string

  ref: React.Ref<ChartInstance>
}

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
})

export default React.forwardRef<ChartInstance, Props>(
  ({ data, labelRotationDegrees = 0, labelFontSize }, ref) => {
    const options: Highcharts.Options = {
      title: undefined,
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          theme: {
            stroke: 'none',
          },
        },
      },
      navigator: {
        series: {
          color: '#61bec7',
          type: 'areaspline',
        },
      },
      xAxis: {
        type: 'category',
        lineWidth: 0,
        labels: {
          rotation: labelRotationDegrees,
          style: {
            textOverflow: 'none', // fixes not displaying labels on small screens
            fontSize: labelFontSize,
            fontFamily: 'Roboto',
          },
        },
      },
      yAxis: yAxisConfig,
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
        },
        spacing: [10, 0, 10, 0],
      },
      tooltip: {
        shared: true,
        split: false,
        useHTML: false,
        borderRadius: 5,
        padding: 16,
        backgroundColor: '#ffffff',
        shadow: true,
        formatter: function () {
          const series = this.points?.map(
            (point) =>
              `<span style="color:${point.color}; font-size: 16px; margin-right: 6px;">
              ■
            </span>
            ${point.y} ${point.key}</br>
          `,
            ''
          )
          return series
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          ...({ borderRadiusTopLeft: 3, borderRadiusTopRight: 3 } as any),
          stickyTracking: false,
          lineWidth: 2,
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      series: [
        {
          type: 'column',
          data: data.map((obj) => ({ ...obj, y: obj.value })),
        },
      ],
    }
    return (
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        constructorType="chart"
        options={options}
        immutable={true}
      />
    )
  }
)
