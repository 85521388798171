import { useCallback, useMemo, useState } from 'react'
import { StatusMutator } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { Table as TableAntd } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEqual from 'lodash/isEqual'

import Badge from 'common/components/Badge'
import Table, { CellValue, Column } from 'common/components/table'
import Base from 'common/components/table/cells/Base'
import Filter from 'common/components/table/filters/Filter'
import Tooltip from 'common/components/Tooltip'
import {
  CheckCircle as CheckCircleIcon,
  Clock as ClockIcon,
  Stop as StopIcon,
  Thumb as ThumbIcon,
} from 'common/icons'

import VariantSentimentHighlighter from './VariantSentimentHighlighter'

import styles from './SentimentAnalysisTable.module.css'
import tableStyles from 'common/components/table/AntdTable.module.css'

const { Column: ColumnAntd, ColumnGroup } = TableAntd

const VARIANTS_WIDTH = 400
const DEFAULT_FILTERS = ['live']

export type Data = {
  [key: string]:
    | number
    | string
    | { word: string; score: number }
    | {}
    | undefined
} & {
  color: string
} & {
  variant: string
} & {
  weightsPerSentiment: { [key: string]: { word: string; score: number }[] } | {}
} & {
  status?: StatusMutator
}

export type Row = Data & { key: string }

type Props = {
  data: Row[]
  selectedRowKeys: string[]
  onChangeSelectRow: (selectedRowKeys: string[]) => void
  isReactCampaign: boolean
  selectedSentiment: string
}

const defaultFilters = [{ id: 'status', value: DEFAULT_FILTERS }]
type TableRow = {
  variant: string
  status: string
  impressed: string
  helpful: string
  curious: string
  excited: string
  surprising: string
  appreciative: string
  urgent: string
}

const SentimentAnalysisTable = ({
  data,
  selectedRowKeys,
  onChangeSelectRow,
  isReactCampaign,
  selectedSentiment,
}: Props) => {
  const flags = useFlags()
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<
    string[] | undefined
  >(isReactCampaign ? DEFAULT_FILTERS : undefined)

  const columns: Column<TableRow>[] = useMemo(
    () => [
      {
        Header: 'Variant',
        accessor: 'variant',
        width: VARIANTS_WIDTH,
        Cell: (cellValue: CellValue) => {
          const { variant, weightsPerSentiment, isControlVariant } =
            cellValue.row.original
          return (
            <Base>
              <div className="flex items-center" data-testid="variant-cell">
                <div>
                  <VariantSentimentHighlighter
                    variant={variant}
                    weights={
                      selectedSentiment
                        ? weightsPerSentiment[selectedSentiment] ?? []
                        : []
                    }
                  />
                </div>
                {isControlVariant && (
                  <Badge
                    className="ml-6 uppercase"
                    size="small"
                    text="Control"
                    variant="neutral"
                  />
                )}
              </div>
            </Base>
          )
        },
      },
      {
        Header: 'Sentiment scores',
        columns: [
          isReactCampaign
            ? {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: true,
                aggregate: 'count',
                Aggregated: ({ value }) => `${value} Names`,
                width: 90,
                Cell: (cellValue: CellValue) => {
                  const { status } = cellValue.row.original
                  return (
                    <Base className="justify-center">
                      <Tooltip overlay={status}>
                        <span>{mapIconsToStatus(status)}</span>
                      </Tooltip>
                    </Base>
                  )
                },
                Filter: Filter({
                  defaultFilters: defaultFilters[0].value,
                }),
                filter: 'includesSome',
              }
            : undefined,
          {
            Header: 'Impressed',
            accessor: 'impressed',
            aggregate: 'uniqueCount',
            Aggregated: ({ value }) => `${value} Unique Names`,
          },
          {
            Header: 'Helpful',
            accessor: 'helpful',
            aggregate: 'uniqueCount',
            Aggregated: ({ value }) => `${value} Unique Names`,
          },
          {
            Header: 'Curious',
            accessor: 'curious',
            aggregate: 'uniqueCount',
            Aggregated: ({ value }) => `${value} Unique Names`,
          },
          {
            Header: 'Excited',
            accessor: 'excited',
            aggregate: 'uniqueCount',
            Aggregated: ({ value }) => `${value} Unique Names`,
          },
          {
            Header: 'Surprising',
            accessor: 'surprising',
            aggregate: 'uniqueCount',
            Aggregated: ({ value }) => `${value} Unique Names`,
          },
          {
            Header: 'Appreciative',
            accessor: 'appreciative',
          },
          {
            Header: 'Urgent',
            accessor: 'urgent',
          },
        ].filter(Boolean),
      },
    ],
    [isReactCampaign, selectedSentiment]
  )
  const handleSelectRow = useCallback(
    (rows: Row[]) => {
      if (
        !isEqual(
          rows.map((row) => row.key),
          selectedRowKeys
        )
      ) {
        onChangeSelectRow(rows.map((row) => row.variant))
      }
    },
    [onChangeSelectRow, selectedRowKeys]
  )
  const initialState = useMemo(
    () => ({
      selectedRowIds: selectedRowKeys.reduce(
        (previousValue, key) => ({
          ...previousValue,
          [data.findIndex((value) => value.key === key)]: true,
        }),
        {}
      ),
      filters: defaultFilters,
    }),
    [data, selectedRowKeys]
  )
  const getCheckboxProps = useCallback((row) => {
    return {
      backgroundColor: row.original.color,
    }
  }, [])

  return (
    <div className="bg-white shadow-md m-6">
      {flags.useNewTableComponent ? (
        <Table
          data-test-id="engage-sentiments-table"
          columns={columns}
          data={data}
          initialState={initialState}
          autoResetFilters={false}
          hasSelectableRows={true}
          onSelectRow={handleSelectRow}
          getCheckboxProps={getCheckboxProps}
        />
      ) : (
        <TableAntd<
          | {
              key: string
            } & Data
        >
          rowSelection={{
            getCheckboxProps: (record: Data) => {
              return {
                className: tableStyles.checkbox,
                style: { color: record?.color },
                name: record.color,
              }
            },
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
              onChangeSelectRow(selectedRowKeys as string[])
            },
          }}
          onRow={() => ({
            'data-testid': 'table-row',
          })}
          bordered={false}
          pagination={false}
          data-test-id="engage-sentiments-table"
          dataSource={data}
          size="middle"
          scroll={{ x: true }}
          className={styles.wrapper}
          // eslint-disable-next-line max-params
          onChange={(pagination, filters, sorter, extra) => {
            const updatedStatusFilters = filters.status

            // select top 3 variants when changing filters
            // fixes not being able to deselect filtered out variants from graph
            if (updatedStatusFilters !== selectedStatusFilters) {
              const { currentDataSource: updatedData } = extra
              const updatedSelectedRows = updatedData
                .slice(0, 3)
                .map(({ key }) => key)

              onChangeSelectRow(updatedSelectedRows)
              setSelectedStatusFilters(filters.status)
            }
          }}
        >
          <ColumnAntd
            title="Variant"
            dataIndex="variant"
            key="variant"
            fixed
            width={VARIANTS_WIDTH}
            align="left"
            sorter={(a: Data, b: Data) => a.variant.localeCompare(b.variant)}
            className="vertical-align--middle text-white-space--preline"
            sortDirections={['ascend', 'descend']}
            render={(variant, record) => (
              <div className="flex items-center" data-testid="variant-cell">
                <div>
                  <VariantSentimentHighlighter
                    variant={variant}
                    weights={
                      selectedSentiment
                        ? record.weightsPerSentiment[selectedSentiment] ?? []
                        : []
                    }
                  />
                </div>
                {record.isControlVariant && (
                  <Badge
                    className="ml-6 uppercase"
                    size="small"
                    text="Control"
                    variant="neutral"
                  />
                )}
              </div>
            )}
          />

          <ColumnGroup title="Sentiment scores">
            {isReactCampaign ? (
              <ColumnAntd
                title="Status"
                dataIndex="status"
                key="status"
                render={(elt) => (
                  <Tooltip overlay={elt}>
                    <span>{mapIconsToStatus(elt)}</span>
                  </Tooltip>
                )}
                onFilter={(value: string, record: Data) => {
                  return record.status === value
                }}
                filters={[
                  {
                    text: 'Approved',
                    value: 'approved',
                  },
                  {
                    text: 'Dropped',
                    value: 'dropped',
                  },
                  {
                    text: 'Live',
                    value: 'live',
                  },
                  {
                    text: 'Pending',
                    value: 'pending',
                  },
                ]}
                defaultFilteredValue={DEFAULT_FILTERS}
              />
            ) : undefined}
            <ColumnAntd
              title="Impressed"
              dataIndex="impressed"
              key="impressed"
            />
            <ColumnAntd title="Helpful" dataIndex="helpful" key="helpful" />
            <ColumnAntd title="Curious" dataIndex="curious" key="curious" />
            <ColumnAntd title="Excited" dataIndex="excited" key="excited" />
            <ColumnAntd
              title="Surprising"
              dataIndex="surprising"
              key="surprising"
            />
            <ColumnAntd
              title="Appreciative"
              dataIndex="appreciative"
              key="appreciative"
            />
            <ColumnAntd title="Urgent" dataIndex="urgent" key="urgent" />
          </ColumnGroup>
        </TableAntd>
      )}
    </div>
  )
}

export default SentimentAnalysisTable

const mapIconsToStatus = (status: StatusMutator) => {
  switch (status) {
    case 'pending':
      return (
        <ClockIcon
          isDefaultColor={false}
          className="text-coolGray-400"
          size={4}
        />
      )
    case 'approved':
      return (
        <ThumbIcon isDefaultColor={false} className="text-lime-500" size={4} />
      )
    case 'live':
      return (
        <CheckCircleIcon
          isDefaultColor={false}
          className="text-maroon-500"
          size={4}
        />
      )
    case 'dropped':
      return (
        <StopIcon
          isDefaultColor={false}
          className="text-coolGray-400"
          size={4}
        />
      )
    default:
      return <>status</>
  }
}
