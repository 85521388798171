import { useMutation, useQueryClient } from '@tanstack/react-query'

import { updateElement } from '../api'
import { contentKeys } from '../queryKeys'

const useUpdateElementMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(updateElement, {
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
    },
  })
}

export default useUpdateElementMutation
