import { useMutation, useQueryClient } from '@tanstack/react-query'

import { movePhrasesBulk } from '../api'
import { contentKeys } from '../queryKeys'

const useBulkMovePhraseMutation = (selectedNodeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(movePhrasesBulk, {
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.phrases(selectedNodeId))
    },
  })
}

export default useBulkMovePhraseMutation
