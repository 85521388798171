import { useAppSelector } from 'common/hooks/redux'

import { getContentHasVariants } from '../../helpers'
import { useContent } from '../../hooks'

import ExportMenuButton from './ExportMenuButton'

const HeaderActions = () => {
  const { content } = useContent()
  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  return (
    <div className="flex items-center">
      {selectedElementId && getContentHasVariants(content) && (
        <ExportMenuButton />
      )}
    </div>
  )
}

export default HeaderActions
