import { DatePickerProps } from 'antd/lib/date-picker/interface'

import DatePicker from 'common/components/datePicker/DatePicker'

const DatePickerComponent = (
  props: Pick<
    DatePickerProps,
    'allowClear' | 'value' | 'onChange' | 'disabled' | 'disabledDate'
  > & { openDirection?: 'up' | 'down'; ariaLabel?: string }
) => {
  return (
    <DatePicker
      ariaLabel={props.ariaLabel}
      date={props.value === undefined ? null : props.value}
      onDateChange={(date) => {
        props.onChange?.(date, '')
      }}
      openDirection={props.openDirection}
      id="date-picker"
      showClearDate={props.allowClear}
      disabled={props.disabled}
      isOutsideRange={props.disabledDate}
      numberOfMonths={1}
      verticalHeight={372}
      isTodayVisible={true}
    />
  )
}

export default DatePickerComponent
