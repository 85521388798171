import Alert from 'common/components/alert'

type FooterProps = {
  error?: string
}

export const Footer: React.FC<FooterProps> = ({ error, children }) => {
  return (
    <>
      {error && (
        <Alert type="error" className="self-stretch mb-6">
          {error}
        </Alert>
      )}
      <div className="flex">{children}</div>
    </>
  )
}
