import { FieldMetaState } from 'react-final-form'
import { generatePath, matchPath } from 'react-router-dom'
import {
  brandVoicePath,
  editToneOfVoicePath,
  generateToneOfVoicePath,
  styleGuidePath,
  toneOfVoicePath,
} from 'app/navigation/paths'

const NAME_MAX_LENGTH = 50

export const validateBrandVoiceName = (
  name: string,
  existingNames: string[] | undefined
) => {
  const trimmedName = name.trim()
  const isTaken = existingNames
    ?.map((n) => n.toLowerCase())
    .includes(trimmedName.toLowerCase())
  const isExceedLength = trimmedName.length > NAME_MAX_LENGTH
  const isWhiteSpaces = trimmedName.trim().length === 0

  const error = isTaken
    ? 'This name already exists within your account.'
    : isExceedLength
    ? `The name contains more than ${NAME_MAX_LENGTH} characters.`
    : isWhiteSpaces
    ? 'The name cannot contain only whitespaces.'
    : undefined

  return error
}

export const getFieldError = <T>(meta: FieldMetaState<T>): string | undefined =>
  meta.dirty && (meta.error || meta.submitError)
    ? meta.error || meta.submitError.message
    : undefined

export const createBrandVoiceBackLink = (
  pathname: string,
  params: { brandVoiceId: string; accountId: string },
  additionalParams: { toneCount: number }
) => {
  const { accountId, brandVoiceId } = params
  const { toneCount } = additionalParams

  const brandVoiceNavigationPaths = [
    { path: styleGuidePath, previousPath: brandVoicePath },
    { path: toneOfVoicePath, previousPath: styleGuidePath },
    {
      path: generateToneOfVoicePath,
      previousPath: toneCount === 0 ? styleGuidePath : toneOfVoicePath,
    },
    { path: editToneOfVoicePath, previousPath: toneOfVoicePath },
  ]

  let navigationLink = '/'
  brandVoiceNavigationPaths.some(({ path, previousPath }) => {
    if (matchPath(pathname, { path, exact: true, strict: true })) {
      navigationLink = generatePath(previousPath, {
        accountId,
        brandVoiceId,
      })
      return true
    }
    return false
  })

  return navigationLink
}
