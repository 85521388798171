import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router'
import { styleGuidePath } from 'app/navigation/paths'

import OptionsMenuButton from 'common/components/optionsMenuButton'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import Tag from 'common/components/Tag'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate } from 'common/helpers/formatDate'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { selectBrandVoice } from 'features/admin/styleGuide/store/styleGuideSlice'

import { BrandVoice } from '../../../store/brandVoiceSlice'

import MenuOptions from './MenuOptions'

type Props = {
  brandVoices: BrandVoice[]
  onDuplicate: (brandVoice: BrandVoice) => void
}

function calculateTitleWidth(screenWidth: number): number {
  if (screenWidth < 1024) {
    return 412
  } else if (screenWidth < 1920) {
    return 492
  } else {
    return 792
  }
}

const LibraryTable = ({ brandVoices, onDuplicate }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { screenWidth } = useWindowDimensions()
  const [titleWidth, setTitleWidth] = useState<number>(
    calculateTitleWidth(screenWidth)
  )

  useLayoutEffect(() => {
    setTitleWidth(calculateTitleWidth(screenWidth))
  }, [screenWidth])

  const handleContentClick = useCallback(
    (brandVoiceId: string) => {
      dispatch(selectBrandVoice(brandVoiceId))

      const targetPath = styleGuidePath

      history.push(
        generatePath(targetPath, {
          accountId,
          brandVoiceId,
        })
      )
    },
    [accountId, dispatch, history]
  )

  const columns: Column<BrandVoice>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }) => (
          <BaseCell>
            {value}
            {row.original['isDefault'] ? (
              <Tag text="Default" backgroundColor="bg-coolGray-500 ml-4" />
            ) : undefined}
          </BaseCell>
        ),
        width: titleWidth,
      },
      {
        Header: 'Created',
        accessor: 'createdDate',
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        align: 'left',
        width: 160,
      },
      {
        Header: 'Last modified',
        accessor: (row: BrandVoice) =>
          row.lastModified ? row.lastModified : row.createdDate,
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        align: 'right',
        width: 160,
      },
      {
        accessor: 'id',
        align: 'right',
        disableSortBy: true,
        shouldOverflow: true,
        width: 60,
        Cell: ({ row }) => (
          <BaseCell>
            <OptionsMenuButton
              name="row options"
              options={<MenuOptions rowProps={row} onDuplicate={onDuplicate} />}
              overflow="hidden"
            />
          </BaseCell>
        ),
      },
    ],
    [onDuplicate, titleWidth]
  )

  return (
    <TableWidget.Widget
      columns={columns}
      data={brandVoices}
      onRowClick={(brandVoice) => handleContentClick(brandVoice.id)}
    />
  )
}

export default LibraryTable
