import ConfirmationModal from 'common/components/confirmationModal'

type Props = {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}

const DeleteNodeModal = ({ isOpen, onConfirm, onClose }: Props) => {
  return (
    <ConfirmationModal
      data-cy="delete-nodes-confirm-modal"
      title="Delete node?"
      confirmationText="This may affect project configurations. If you delete a node it won’t be used in generation and will be removed from all projects it’s assigned to."
      confirmButtonText="Delete now"
      hasConfirmationSlider
      open={isOpen}
      onCancel={() => onClose()}
      onConfirm={() => {
        onConfirm()
        onClose()
      }}
    />
  )
}

export default DeleteNodeModal
