import Button from '../button/Button'
import CheckboxComponent from '../checkbox/Checkbox'

type Props = {
  onApply: () => void
  onClickReset: () => void
  onClickSelectAll: () => void
  isSelectAllChecked: boolean
  isSelectAllIndeterminate?: boolean
  isResetDisabled: boolean
  isSelectAllDisabled: boolean
  isApplyDisabled: boolean
  title: string
}

const CatalogHeader = ({
  onApply,
  onClickSelectAll,
  onClickReset,
  isSelectAllChecked,
  isSelectAllIndeterminate,
  isResetDisabled,
  isSelectAllDisabled,
  title,
  isApplyDisabled,
}: Props) => {
  return (
    <div className="p-6 flex justify-between">
      <div className="text-coolGray-800 font-medium self-center ">{title}</div>
      <div className="flex gap-4">
        <CheckboxComponent
          isDisabled={isSelectAllDisabled}
          isChecked={isSelectAllChecked}
          indeterminate={isSelectAllIndeterminate}
          onChange={() => {
            onClickSelectAll()
          }}
          label="Select all"
        />
        <Button
          variant="link"
          ghost
          onClick={() => onClickReset()}
          disabled={isResetDisabled}
        >
          Reset
        </Button>
        <Button
          variant="primary"
          onClick={() => onApply()}
          disabled={isApplyDisabled}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

export default CatalogHeader
