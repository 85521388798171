import ConfirmationModal from 'common/components/confirmationModal'

type Props = {
  onCancel: () => void
  onConfirm: () => void
  isOpen: boolean
}

const UploadConfirmationModal = ({ onCancel, onConfirm, isOpen }: Props) => (
  <ConfirmationModal
    onCancel={onCancel}
    onConfirm={onConfirm}
    open={isOpen}
    confirmationText="Are you sure you want to leave without importing the data?"
    cancelButtonText="Stop, don’t do it"
    confirmButtonText="Close"
    title="Are you sure?"
  />
)

export default UploadConfirmationModal
