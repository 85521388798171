import { useQuery } from '@tanstack/react-query'

import { useAppDispatch } from 'common/hooks/redux'

import { campaignKeys } from '../api/queryKeys'
import { fetchProjectsAsync } from '../store/campaignSlice'

const useProjectsQuery = () => {
  const dispatch = useAppDispatch()

  return useQuery(
    campaignKeys.projects(),
    () => dispatch(fetchProjectsAsync()).unwrap(),
    {
      staleTime: Infinity,
    }
  )
}

export default useProjectsQuery
