import Button from 'common/components/button'
import Card from 'common/components/Card'

interface RetrieveResultsCardProps {
  retrieveResults: () => void
  isLoading: boolean
}

export default function RetrieveResultsCard({
  retrieveResults,
  isLoading,
}: RetrieveResultsCardProps) {
  return (
    <Card>
      <div className="font-bold mb-4">
        Retrieve your Airship experiment results
      </div>
      <div className="mb-4 text-coolGray-400">
        Select an experiment in the dropdown and pull the results.
      </div>
      <div className="justify-end flex flex-wrap">
        <Button
          variant="primary"
          loading={isLoading}
          data-testid="retrieve-results-button"
          onClick={retrieveResults}
        >
          Retrieve results
        </Button>
      </div>
    </Card>
  )
}
