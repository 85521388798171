import InsightsCard from './InsightsCard'
import InsightsCategories from './InsightsCategories'

const Insights = () => {
  return (
    <div className="p-6 text-coolGray-800">
      <div
        aria-level={1}
        role="heading"
        className="text-xl font-medium mb-3 leading-7"
      >
        Insights
      </div>
      <InsightsCard
        title="Jacquard generation"
        content={
          <>
            Our Generation Engine produced hundreds of unique variants from your
            brief. Then our Neural Network ranks the variants and selects which
            should be used based on an optimized experimental design.
          </>
        }
      />
      <InsightsCard
        hasOutline={true}
        title="Experiment design"
        content={
          <>
            Each optimization experiment is specifically designed to maximize
            potential uplift and ongoing learnings.
            <br />
            Any changes you make to the selected variants will have an effect on
            the outcome of the experiment.
          </>
        }
      />
      <InsightsCategories />
    </div>
  )
}

export default Insights
