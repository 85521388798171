import { Node, NodeResponse, NodeTreeMetadata } from 'common/interfaces/nodes'

import { connectInternalApi } from './api'

const ROOT_NODE_ID = 1
const TEMPORARY_FIXED_CONTENT_UPDATE_ID = 3

export const getNodes = async (): Promise<{
  nodes: Node[]
  metadata: NodeTreeMetadata
}> => {
  const result = await connectInternalApi.get<NodeResponse>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/trees/5`,
    {
      params: {
        include_metadata: true,
        includeNodes: [0],
      },
    }
  )

  result.data.data.sort((a, b) => (a.node > b.node ? 1 : -1))

  const nodes: Node[] = result.data.data
    .filter(
      (node, _, array) =>
        node.node_parent_id === ROOT_NODE_ID ||
        array.find((n) => n.node_id === node.node_parent_id)
    )
    .map((node, _, array) => ({
      id: node.node_id.toString(),
      parent: node.node_parent_id.toString(),
      name: node.display_name || node.node,
      value: array.filter((n) => n.node_parent_id === node.node_id).length || 1,
      isPrivate: node.private,
      isDisplayed: node.display,
    }))

  return { nodes, metadata: result.data.metadata }
}
