import React from 'react'
import { Progress } from 'antd'

type Props = {
  progress: number
  display: string
}
type State = {
  animationImage: string | undefined
  animationText: string
  percentage: number
}
class GeneratorAnimation extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      animationImage: undefined,
      animationText: '',
      percentage: 0,
    }
  }

  componentWillReceiveProps(nextProps: any) {
    const { progress, display } = this.props
    if (nextProps.progress !== progress || nextProps.display !== display) {
      this.progressStatusBar(nextProps.progress)
    }
  }

  progressStatusBar = (prog: any) => {
    let progress = prog
    progress *= 25
    if (progress <= 25) {
      this.setState({
        animationImage: '/images/generation_1.svg',
        animationText: 'Generating millions of language variants...',
      })
    } else if (progress <= 50) {
      this.setState({
        animationImage: '/images/generation_2.svg',
        animationText: "Processing through Jacquard's deep learning engine...",
      })
    } else if (progress <= 75) {
      this.setState({
        animationImage: '/images/generation_3.svg',
        animationText: 'Selecting optimal set for testing...',
      })
    } else {
      this.setState({
        animationImage: '/images/generation_4.svg',
        animationText: '',
      })
    }
    this.setState({
      percentage: progress,
    })
  }

  render() {
    const { display } = this.props
    const { animationImage, animationText, percentage } = this.state
    const successProgress = percentage === 100 ? percentage : 0
    return (
      <>
        {display && (
          <div
            style={{ display: display ? 'flex' : 'none' }}
            id="sl-generator-animation"
          >
            <div className="animation-wrap">
              <div className="animation-image">
                <img src={animationImage} alt={animationText} />
              </div>
              <div className="animation-text">{animationText}</div>
              <div className="animation-progress-bar z-index--10">
                <Progress
                  percent={percentage}
                  strokeWidth={15}
                  status="active"
                  successPercent={successProgress}
                  showInfo={false}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default GeneratorAnimation
