import { ChangeEvent } from 'react'
import { TableToggleCommonProps } from 'react-table'

import Checkbox from '../checkbox'

const TableCheckbox = ({
  checked,
  onChange,
  ...rest
}: TableToggleCommonProps) => {
  const customOnChange = (_, event: ChangeEvent) => {
    if (onChange) {
      onChange(event)
    }
  }
  return (
    <Checkbox
      isChecked={checked ?? false}
      onChange={customOnChange}
      {...rest}
    />
  )
}

export default TableCheckbox
