import { useEffect } from 'react'
import { useParams } from 'react-router'

import ErrorPage from 'common/components/error/ErrorPage'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { isComponentElement } from '../api/interfaces'
import useGetTemplateQuery from '../api/queries/useGetTemplateQuery'
import { contentPageInitialize } from '../store/contentSlice'

import { START_HERE_COMPONENT_ID } from './content/Content'
import ContentPage from './ContentPage'

const CreateContent = () => {
  const dispatch = useAppDispatch()
  const { templateId } = useParams<{ templateId: string }>()
  const activeTemplateId = useAppSelector(
    (state) => state.content.activeTemplateId
  )
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )

  const { status, data: template } = useGetTemplateQuery(templateId)

  const componentElements = template?.elements?.filter(isComponentElement)

  useEffect(() => {
    if (template && !activeTemplateId && !selectedElementId) {
      const componentElement = componentElements?.find(
        ({ status }) => status === 'selected'
      )

      dispatch(
        contentPageInitialize({
          activeTemplateId: templateId,
          selectedElementId:
            componentElement?.element_id || START_HERE_COMPONENT_ID,
        })
      )
    }
  }, [
    activeTemplateId,
    componentElements,
    dispatch,
    selectedElementId,
    template,
    templateId,
  ])

  const getState = () => {
    if (status === 'error') {
      return 'error'
    } else if (!!template && !!activeTemplateId && !!selectedElementId) {
      return 'success'
    } else {
      return 'loading'
    }
  }

  const state = getState()

  return (
    <>
      {
        {
          loading: <Spinner />,
          success: <ContentPage />,
          error: <ErrorPage />,
        }[state]
      }
    </>
  )
}

export default CreateContent
