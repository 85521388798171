import React, { useEffect, useState } from 'react'
import { DistributionType } from '@phrasee/phrasee-typings/Graphql/interfaces'

import {
  connectInternalApi,
  createCancelTokenSource,
  isCancel,
} from 'common/api'
import { PeriodType } from 'common/api/reporting'
import { IncrementalEventsData } from 'common/components/IncrementalEventsGraph'
import BaseIncrementalEventsWidget from 'common/components/IncrementalEventsWidget'
import { useAppSelector } from 'common/hooks/redux'

import {
  selectEndDate,
  selectStartDate,
} from '../../../../store/dashboardSlice'

interface Props {
  title: string
  accountId: string
  eventTypes: string[]
  testingMethod: string[]
  projectIds: string[]
  distributionType?: DistributionType
}

const IncrementalEventsWidget = ({
  title,
  accountId,
  eventTypes,
  testingMethod,
  projectIds,
  distributionType,
}: Props) => {
  const startDate = useAppSelector(selectStartDate)
  const endDate = useAppSelector(selectEndDate)
  const [results, setResults] = useState<IncrementalEventsData[]>([])
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    // TODO could be applied to all components making the AJAX call
    const source = createCancelTokenSource()
    function fetchData() {
      return connectInternalApi.get<{
        data: any
        filters: { start_date: string; period_type: PeriodType }
        xAxis: { categories: string[] }
      }>(
        `v1/core/reporting/reporting/accounts/${accountId}/incremental/report/graph-data`,
        {
          cancelToken: source.token,
          params: {
            event_type: eventTypes.filter(
              (eType) => eType !== 'num_influenced_open'
            ),
            project_ids: projectIds,
            testing_method: testingMethod,
            start_date: startDate,
            end_date: endDate,
            distribution_type: distributionType,
          },
        }
      )
    }
    setIsLoading(true)
    setHasError(false)

    let isCancelled = false
    fetchData()
      .then((graphResponse) => {
        const { data: graphData, xAxis } = graphResponse.data

        setXAxisLabels(xAxis.categories)

        setResults(
          graphData.map((gData, index) => {
            return {
              data: gData.data,
              name: gData.name,
            }
          })
        )
      })
      .catch((err) => {
        if (isCancel(err)) {
          isCancelled = true
        } else {
          setHasError(true)
        }
      })
      .finally(() => {
        if (!isCancelled) {
          setIsLoading(false)
        }
      })
    return () => {
      source.cancel('Unmounting')
    }
  }, [
    accountId,
    distributionType,
    endDate,
    eventTypes,
    projectIds,
    startDate,
    testingMethod,
  ])

  return (
    <BaseIncrementalEventsWidget
      hasError={hasError}
      info="Track incremental performance across your channels and their key metrics."
      isLoading={isLoading}
      data={results}
      title={title}
      xAxisLabels={xAxisLabels}
    />
  )
}

export default IncrementalEventsWidget
