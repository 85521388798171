import type { Option } from 'common/components/tags/Tags'
import Tags from 'common/components/tags/Tags'

type Props = {
  title: string
  subTitle: string
  tags: Option<string>[]
  suggestions: string[]
  onAddClick: (value: string) => void
  onRemoveClick: (value: string) => void
  tagButtonLabel?: string
  isDisabled?: boolean
  ['data-cy']: string
}

const NodeTags = ({
  title,
  subTitle,
  tags,
  suggestions,
  onAddClick,
  onRemoveClick,
  tagButtonLabel,
  'data-cy': dataCy,
}: Props) => {
  return (
    <div>
      <div className="text-coolGray-800 mb-1 font-bold">{title}</div>
      <div className="text-coolGray-400 mb-2">{subTitle}</div>

      <div className="bg-coolGray-50 p-2 border border-coolGray-300 max-w-152">
        <Tags
          data-cy={dataCy}
          isFreeText={false}
          tags={tags}
          tagButtonLabel={tagButtonLabel}
          hasTagButtonLabel={!!tagButtonLabel}
          suggestions={suggestions}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
      </div>
    </div>
  )
}

export default NodeTags
