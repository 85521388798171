import {
  CSSProperties,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
} from 'react'
import { Select } from 'antd'

import Label from './Label'

// !!! WARNING Please use SingleSelect or MultiSelect when building
// new components as we are planning to remove antd from the project

interface Props {
  id?: string
  label?: string
  mode?: 'default' | 'multiple' | 'tags' | undefined
  size?: 'large' | 'small' | 'default' | undefined
  value?: string | string[] | number | undefined
  defaultValue?: string | string[] | number | undefined
  placeholder?: string | ReactNode
  className?: string
  onChange: (event) => void
  onFocus?: () => void
  children: ReactNode
  maxTagCount?: number
  maxTagPlaceholder?: string
  filterOption?: (
    inputValue: string,
    option: ReactElement<any, string | JSXElementConstructor<any>>
  ) => boolean
  allowClear?: boolean
  menuItemSelectedIcon?: ReactNode
  dropdownClassName?: string
  dropdownRender?: (menu: ReactNode) => ReactNode
  showSearch?: boolean
  loading?: boolean
  disabled?: boolean
  showArrow?: boolean
  optionFilterProp?: 'children'
  style?: CSSProperties
  dataTestId?: string
  open?: boolean
}

const SelectComponent = ({
  id,
  label,
  children,
  style,
  dataTestId,
  ...rest
}: Props) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Select
        style={style}
        data-testid={dataTestId}
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        aria-disabled={rest.disabled}
        {...rest}
      >
        {children}
      </Select>
    </>
  )
}

export { default as Option } from './SelectOption'
export default SelectComponent
