import Highcharts from 'highcharts/highstock'
import inRange from 'lodash/inRange'
import isEmpty from 'lodash/isEmpty'

import { SENTIMENTS } from 'features/unifiedFlow/contentPage/generate/constants'

import styles from './SentimentAnalysisChart.module.css'

export interface SentimentAnalysisChartVariables {
  selectedSentiment?: string
  series: { name: string; value: number }[]
  onClickSentiment: ({
    name,
    value,
  }: {
    name: string
    value: string | number
  }) => void
}

const START_ANGLE = -77

const CHART_COLORS_MAPPER = {
  [SENTIMENTS.IMPRESSED]: styles.impressed,
  [SENTIMENTS.HELPFUL]: styles.helpful,
  [SENTIMENTS.CURIOUS]: styles.curious,
  [SENTIMENTS.EXITED]: styles.excited,
  [SENTIMENTS.SURPRISING]: styles.surprising,
  [SENTIMENTS.APPRECIATIVE]: styles.appreciative,
  [SENTIMENTS.URGENT]: styles.urgent,
}

const CATEGORIES = [
  SENTIMENTS.URGENT,
  SENTIMENTS.IMPRESSED,
  SENTIMENTS.HELPFUL,
  SENTIMENTS.CURIOUS,
  SENTIMENTS.EXITED,
  SENTIMENTS.SURPRISING,
  SENTIMENTS.APPRECIATIVE,
]

function formatLabels(
  value: string | number,
  selectedSentiment: string | undefined
) {
  return `<span class=${
    value === selectedSentiment ? 'font-bold' : ''
  }>${value}</span>`
}

export function getSentimentAnalysisChartOptions({
  series,
  onClickSentiment,
  selectedSentiment,
}: SentimentAnalysisChartVariables) {
  const chartOptions: Highcharts.Options = {
    lang: {
      noData: 'No sentiments available at this time ',
    },
    noData: {
      useHTML: true,
      style: {
        fontSize: '14px',
        color: '#4B5563',
        width: 160,
        textAlign: 'center',
        fontWeight: '400',
      },
    },
    chart: {
      width: 326,
      height: 326,
      polar: true,
      events: {
        render: function () {
          const ticks = this.xAxis[0].ticks
          const length = this.xAxis[0].categories.length
          let rotation = START_ANGLE

          for (const tick in ticks) {
            if (
              rotation &&
              (inRange(rotation, START_ANGLE, 90) || rotation >= 270)
            ) {
              ticks[tick].label?.attr({
                rotation: rotation,
              })
            } else {
              ticks[tick].label?.attr({
                rotation: (rotation + 180) % 360,
              })
            }

            rotation = rotation + 360 / length
          }
        },
      },
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      tickInterval: 1,
      min: 0,
      max: CATEGORIES.length,
      tickmarkPlacement: 'between',
      categories: CATEGORIES,
      showEmpty: false,
      lineWidth: 1,
      lineColor: '#e5e7eb',
      labels: {
        align: 'center',
        useHTML: true,
        formatter: function ({ value }) {
          return formatLabels(value, selectedSentiment)
        },
        style: {
          color: '#4b5563',
          fontSize: '10px',
          textTransform: 'capitalize',
        },
      },
    },
    pane: {
      startAngle: START_ANGLE,
      background: [
        {
          backgroundColor: isEmpty(series) ? '#F9FAFB' : 'transparent',
          borderWidth: 0,
        },
      ],
    },
    yAxis: {
      angle: -START_ANGLE,
      min: 0,
      lineWidth: 0,
      tickInterval: 1,
      labels: {
        rotation: 0,
        style: {
          color: '#1f384d',
          fontSize: '8px',
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: undefined,
    plotOptions: {
      series: {
        pointInterval: 1,
        borderWidth: 1,
        pointPlacement: 'between',
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              onClickSentiment({ name: this.name, value: this.category })
            },
          },
        },
      },
      column: {
        pointPadding: 0,
        groupPadding: 0,
      },
    },
    series: [
      {
        type: 'column',
        data: series.map(({ name, value }, index: number) => {
          const className = CHART_COLORS_MAPPER[name]

          return {
            x: index + 0.5,
            y: value,
            name: name,
            className,
          }
        }),
      },
    ],
  }

  return chartOptions
}
