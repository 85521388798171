import { Regions } from 'common/interfaces/regions'

import { connectInternalApi } from './api'

export async function fetchRegionsList(): Promise<Regions> {
  const response = await connectInternalApi.get<{ data: Regions }>(
    `v1/core/monorail/regions?pageSize=150`
  )
  return response.data.data
}
