import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import {
  ConditionalValue,
  FieldType,
} from 'common/components/dynamicFields/interfaces'
import { SelectValue } from 'common/components/singleSelect'
import { Topic } from 'common/components/topics/interfaces'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/futurama/api/interfaces'
import useCreateContentMutation from 'features/futurama/api/mutations/useCreateContentMutation'
import useGenerateVariantsMutation from 'features/futurama/api/mutations/useGenerateVariantsMutation'
import useUpdateContentMutation from 'features/futurama/api/mutations/useUpdateContentMutation'
import {
  toggleElementsDisabled,
  userInputFormHasChanged,
} from 'features/futurama/store/contentSlice'

import { getGenerateWsTopic } from '../../ContentPage'
import {
  getContentHasVariants,
  getElementHasVariants,
  getElementIdForGeneration,
  getFirstComponentElement,
  setVariantsLoadingState,
} from '../../helpers'
import {
  useContentOrTemplate,
  useSelectedElement,
  useTopics,
} from '../../hooks'

import ActionButtons from './bottomActionButtons/ActionButtons'
import BrandVoiceSettings from './brandVoiceSettings'
import FormLeaveModal from './FormLeaveModal'
import GeneratedTopics from './GeneratedTopics'
import GenerationInputForm from './GenerationInputForm'
import RegenerateModal from './RegenerateModal'

export type FormSubmitAction = (
  formValues: Record<string, ConditionalValue<FieldType> | undefined>
) => Promise<void>
export type PageState =
  | 'formChanged'
  | 'formChangedWithoutTopics'
  | 'topicsChanged'
  | 'showContent'
  | 'showGeneratedContentWithoutTopics'
  | 'showGeneratedContent'
  | 'showContentWithoutTopics'
  | 'showTemplateWithoutTopics'
  | 'showTemplate'

const StartHere = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const [regenerateModalState, setRegenerateModalState] = useState<{
    isOpen: boolean
    formValues?: Record<string, string | number | string[] | undefined>
  }>({
    isOpen: false,
    formValues: undefined,
  })

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { template, content, display_name } = useContentOrTemplate()

  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )
  const showComponentConfiguration = useAppSelector(
    (state) => state.content.showComponentConfiguration
  )

  const { data: topics } = useTopics(activeContentId)
  const { data: selectedElement } = useSelectedElement()

  const createContentMutation = useCreateContentMutation()
  const updateContentMutation = useUpdateContentMutation()
  const generateVariantsMutation = useGenerateVariantsMutation()

  const isGlobalSettings = !showComponentConfiguration
  const name = template?.name || content?.name
  const displayName = showComponentConfiguration
    ? selectedElement?.display_name || selectedElement?.name
    : display_name || name
  const description = template?.description || content?.description
  const brandVoiceId = showComponentConfiguration
    ? selectedElement?.user_input?.brand_voice_id
    : content?.user_input.brand_voice_id
  const userInputForm = showComponentConfiguration
    ? selectedElement?.user_input?.user_input_form.filter(
        (field) => field.name !== 'content_name'
      ) ||
      content?.user_input.user_input_form.filter(
        (field) => field.name !== 'content_name'
      )
    : template?.user_input_form || content?.user_input.user_input_form

  const [selectedTopics, setSelectedTopics] = useState<Topic[]>(topics ?? [])
  const [hasFormChanged, setHasFormChanged] = useState(false)

  const shouldUseTopics = template?.requires_ner || content?.requires_ner

  const hasVariants = useMemo(() => {
    if (showComponentConfiguration) {
      return getElementHasVariants(selectedElement)
    } else {
      return getContentHasVariants(content)
    }
  }, [content, selectedElement, showComponentConfiguration])

  useEffect(() => {
    const isWaitingForVariants = content?.elements?.some(
      (element) =>
        isComponentElement(element) && element.nlg_status === 'pending'
    )
    if (!isWaitingForVariants) {
      setSelectedTopics(topics ?? [])
    }
  }, [content?.elements, topics])

  const revertTopics = () => {
    setSelectedTopics(topics ?? [])
  }

  const generateTopics: FormSubmitAction = async (formValues) => {
    if (!template) {
      return
    }

    const updatedTemplate = cloneDeep(template)

    updatedTemplate.user_input_form?.forEach((field) => {
      if (formValues[field.name] != null) {
        field.value = formValues[field.name]
      }
    })

    const name = updatedTemplate.user_input_form?.find(
      (field) => field.name === 'content_name'
    )?.value as string | undefined

    updatedTemplate.name = name || ''

    await createContentMutation.mutateAsync(
      { accountId, template: updatedTemplate },
      {
        onSuccess: (newContent) => {
          history.replace(`/content/${newContent._id}`)
        },
      }
    )
  }

  const refreshTopics: FormSubmitAction = async (formValues) => {
    if (!content) {
      return
    }

    const userInputForm = cloneDeep(content.user_input.user_input_form)

    userInputForm.forEach((field) => {
      if (formValues[field.name] != null) {
        field.value = formValues[field.name]
      } else {
        delete field.value
      }
    })

    if (isGlobalSettings) {
      dispatch(toggleElementsDisabled(true))
    }
    await updateContentMutation.mutateAsync(
      {
        accountId,
        userInputForm,
        contentId: content._id,
        forceNer: true,
      },
      {
        onSettled: () => {
          dispatch(toggleElementsDisabled(false))
        },
      }
    )
  }

  const generateVariantsWithoutTopics: FormSubmitAction = async (
    formValues
  ) => {
    if (!template) {
      return
    }

    const selectedBrandVoice = formValues.brand_voice as SelectValue | undefined
    const updatedTemplate = cloneDeep(template)

    updatedTemplate.user_input_form?.forEach((field) => {
      if (formValues[field.name] != null) {
        field.value = formValues[field.name]
      }
    })

    const name = updatedTemplate.user_input_form?.find(
      (field) => field.name === 'content_name'
    )?.value as string | undefined

    updatedTemplate.name = name || ''

    await createContentMutation.mutateAsync(
      {
        accountId,
        template: updatedTemplate,
        brandVoiceId: selectedBrandVoice?.value,
      },
      {
        onSuccess: (newContent) => {
          const elementId = getFirstComponentElement(newContent)?.element_id
          if (elementId) {
            const wsTopic = getGenerateWsTopic(newContent._id, elementId)

            setVariantsLoadingState(queryClient, newContent._id, elementId)

            generateVariantsMutation.mutate({
              accountId,
              wsTopic,
              contentId: newContent._id,
              elementId,
            })
            history.replace(`/content/${newContent._id}`)
          }
        },
      }
    )
  }

  const generateVariants: FormSubmitAction = async (formValues) => {
    if (!content) {
      return
    }

    const selectedBrandVoice = formValues.brand_voice as SelectValue | undefined
    const elementId = getElementIdForGeneration(content, selectedElement)
    if (elementId) {
      const wsTopic = getGenerateWsTopic(content._id, elementId)

      setVariantsLoadingState(queryClient, content._id, elementId)

      const userInputForm = selectedElement
        ? cloneDeep(selectedElement.user_input?.user_input_form)
        : undefined
      if (userInputForm) {
        userInputForm.forEach((field) => {
          if (formValues[field.name] != null) {
            field.value = formValues[field.name]
          } else {
            delete field.value
          }
        })
      }

      generateVariantsMutation.mutate({
        topics: selectedTopics,
        accountId,
        wsTopic,
        contentId: content._id,
        elementId,
        isCustomSettings: !!selectedElement,
        userInputForm,
        brandVoiceId: selectedBrandVoice?.value,
      })
    }
  }

  const regenerateVariantsWithoutTopics: FormSubmitAction = async (
    formValues
  ) => {
    if (!content) {
      return
    }

    const selectedBrandVoice = formValues.brand_voice as SelectValue | undefined
    if (
      content.elements.length > 1 &&
      isGlobalSettings &&
      !regenerateModalState.isOpen
    ) {
      setRegenerateModalState({ isOpen: true, formValues })
      return
    }

    const userInputForm = selectedElement
      ? cloneDeep(selectedElement.user_input?.user_input_form)
      : cloneDeep(content.user_input.user_input_form)

    if (userInputForm) {
      userInputForm.forEach((field) => {
        if (formValues[field.name] != null) {
          field.value = formValues[field.name]
        } else {
          delete field.value
        }
      })
    }

    if (isGlobalSettings) {
      dispatch(toggleElementsDisabled(true))
    }

    const elementId = getElementIdForGeneration(content, selectedElement)
    if (elementId) {
      const wsTopic = getGenerateWsTopic(content._id, elementId)

      setVariantsLoadingState(queryClient, content._id, elementId)

      generateVariantsMutation.mutate(
        {
          accountId,
          wsTopic,
          contentId: content._id,
          elementId,
          userInputForm,
          isCustomSettings: !!selectedElement,
          brandVoiceId: selectedBrandVoice?.value,
        },
        {
          onSuccess: () => {
            dispatch(userInputFormHasChanged(false))
          },
        }
      )
    }
  }

  const getState = (): PageState => {
    const hasTopicsChanged = !isEqual(topics, selectedTopics)
    if (content && hasFormChanged && !shouldUseTopics && hasVariants) {
      return 'formChangedWithoutTopics'
    } else if (content && hasFormChanged && shouldUseTopics) {
      return 'formChanged'
    } else if (content && hasTopicsChanged && hasVariants) {
      return 'topicsChanged'
    } else if (content && !shouldUseTopics && hasVariants) {
      return 'showGeneratedContentWithoutTopics'
    } else if (content && !shouldUseTopics && !hasVariants) {
      return 'showContentWithoutTopics'
    } else if (content && hasVariants) {
      return 'showGeneratedContent'
    } else if (content) {
      return 'showContent'
    } else if (!shouldUseTopics) {
      return 'showTemplateWithoutTopics'
    } else {
      return 'showTemplate'
    }
  }

  const state = getState()

  useEffect(() => {
    if (
      state === 'topicsChanged' ||
      state === 'formChanged' ||
      state === 'formChangedWithoutTopics'
    ) {
      dispatch(userInputFormHasChanged(true))
    } else {
      dispatch(userInputFormHasChanged(false))
    }
  }, [dispatch, state])

  return (
    <div>
      {/* TODO: Use <Widget/> */}
      <div className="mb-8 border border-coolGray-300 p-6 bg-white">
        <div className="text-xl font-medium text-coolGray-800">
          {displayName} settings
        </div>
        <div
          className="text-sm text-coolGray-500 mt-1 mb-6"
          data-testid="content-description"
        >
          {description}
        </div>
        {userInputForm && (
          <GenerationInputForm
            pageState={state}
            fieldsConfiguration={userInputForm}
            brandVoiceId={brandVoiceId}
            onChange={setHasFormChanged}
            refreshTopics={refreshTopics}
            generateTopics={generateTopics}
            generateVariants={generateVariants}
            generateVariantsWithoutTopics={generateVariantsWithoutTopics}
            regenerateVariantsWithoutTopics={regenerateVariantsWithoutTopics}
          >
            <GeneratedTopics
              topics={selectedTopics}
              pageState={state}
              setSelectedTopics={setSelectedTopics}
            />
            <BrandVoiceSettings />
            <ActionButtons
              pageState={state}
              topics={topics ?? []}
              revertTopics={revertTopics}
              isCreatingContent={createContentMutation.isLoading}
              isUpdatingContent={updateContentMutation.isLoading}
              isGeneratingVariants={generateVariantsMutation.isLoading}
            />
          </GenerationInputForm>
        )}
      </div>
      <FormLeaveModal />
      <RegenerateModal
        isOpen={regenerateModalState.isOpen}
        onConfirm={() => {
          if (regenerateModalState.formValues) {
            regenerateVariantsWithoutTopics(regenerateModalState.formValues)
            setRegenerateModalState({ isOpen: false })
          }
        }}
        onClose={() => setRegenerateModalState({ isOpen: false })}
      />
      <div className="h-3"></div>
    </div>
  )
}

export default StartHere
