import { CampaignData, SplitSizeData } from '../../interface'
import { retrieveSplitSizeData } from '../../Workflow.actions'

type ExpectedEngagementRates = {
  [key: string]: { value: number }
}

interface Fields {
  selectionMetric: string
  listSize?: number
  baselineOpenRate?: number
  baselineClickRate?: number
  maxSplitNumber?: number
  minSplitNumber?: number
}

export interface CalculatorResponse {
  result?: SplitSizeData
  campaignData?: CampaignData
  expectedEngagementRates?: ExpectedEngagementRates
}

export const calculateSplitResult = async (
  projectId: string,
  campaignId: string | undefined,
  fields: Fields
): Promise<CalculatorResponse> => {
  const {
    listSize,
    selectionMetric,
    baselineOpenRate,
    baselineClickRate,
    maxSplitNumber,
    minSplitNumber,
  } = fields

  let result: SplitSizeData | undefined
  let expectedEngagementRates: ExpectedEngagementRates | undefined
  let campaignData: CampaignData | undefined

  if (projectId && selectionMetric && listSize !== undefined) {
    const payload = {
      campaign_id: campaignId,
      selection_metric: selectionMetric,
      list_size: listSize,
      baseline_open_rate: baselineOpenRate,
      baseline_click_rate: baselineClickRate,
      max_split_number: maxSplitNumber,
      min_split_number: minSplitNumber,
    }

    const splitCalculatorResult = await retrieveSplitSizeData(
      projectId,
      payload
    )

    if (splitCalculatorResult?.split_result) {
      result = splitCalculatorResult
      campaignData = splitCalculatorResult.campaign_data

      splitCalculatorResult?.campaign_data?.expected_engagement_rates.forEach(
        (rate) => {
          expectedEngagementRates = {
            ...expectedEngagementRates,
            [rate.type]: { value: rate.true_value || 0 },
          }
        }
      )
    }
  }

  return { result, expectedEngagementRates, campaignData }
}
