import { startCase } from 'lodash'

import ContentTypeBubble from 'common/components/ContentTypeBubble'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import type { Variant, VariantsData } from 'features/personalization/api'

type Props = {
  channelId?: string
  count: VariantsData['count']
  data: Variant[]
}

export const VariantTable = ({ channelId, count, data }: Props) => {
  const headers = {
    push: ['sends', 'directOpens', 'totalOpens'],
    email: ['sends', 'opens', 'clicks'],
  }

  const generateHeaders = (channelId: string) =>
    channelId &&
    headers[channelId].map((key) => ({
      Header: startCase(key),
      accessor: key,
    }))

  const columns: Column<Variant>[] = [
    {
      Header: 'Variants',
      accessor: 'variantText',
      isResizable: true,
      width: 500,
      disableSortBy: true,
    },
    {
      Header: 'Channel',
      accessor: 'channel',
      id: 'channelType',
      disableFilters: true,
      Cell: ({ value }) => {
        return (
          <BaseCell>
            <div className="flex justify-center ">
              <ContentTypeBubble iconType={value} name={value} />
            </div>
          </BaseCell>
        )
      },
    },
    ...(channelId ? generateHeaders(channelId) : []),
  ]
  return (
    <TableWidget.Widget<Variant>
      columns={columns}
      data={data}
      firstUseText="No variants to display"
    >
      <TableWidget.Header
        title="Variants"
        subtitle={`${data.length} ${
          data.length === 1 ? 'total variant' : 'total variants'
        } of ${count} available based on the selected filters`}
      >
        <TableWidget.Filter />
      </TableWidget.Header>
    </TableWidget.Widget>
  )
}

export default VariantTable
