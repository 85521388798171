import InfoMessage from './InfoMessage'

// TODO: This is a temporary solution until we get a formal design
const TopicsError = () => {
  return (
    <InfoMessage
      textLines={[
        'Oh dear! We were unable to load recommended topics, try refreshing the page.',
        'If the problem persists, chat to us.',
      ]}
    />
  )
}
export default TopicsError
