import { GroupBase, StylesConfig } from 'react-select'

export const MAX_OPTIONS_MENU_WIDTH = 450 // px

export const styles = <
  Option,
  isMulti extends boolean,
  Group extends GroupBase<Option>
>({
  optionsPosition,
  isInsideTable,
  isCreatable,
  isMinimized,
}: {
  optionsPosition: 'left' | 'right'
  isInsideTable?: boolean
  isCreatable?: boolean
  isMinimized?: boolean
}): StylesConfig<Option, isMulti, Group> => {
  return {
    menuPortal: (defaultStyles) => ({
      ...defaultStyles,
      zIndex: 9999,
    }),
    container: (defaultStyles) => ({
      ...defaultStyles,
      height: isInsideTable ? '100%' : 'auto',
    }),
    control: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        width: '100%',
        height: isInsideTable ? '100%' : 40,
        flexWrap: 'nowrap',
        ...(isInsideTable ? { border: 'none !important' } : {}),
        borderColor: state.isFocused ? '#af6693' : '#d1d5db',
        backgroundColor: isInsideTable ? 'transparent' : '#f9fafb',
        ...(state.isDisabled
          ? {
              cursor: 'not-allowed',
              pointerEvents: 'auto',
            }
          : { cursor: 'pointer' }),
        boxShadow:
          state.isFocused && !isInsideTable ? '0 2px 4px 0 #af6693' : 'none',

        '&:hover': {
          borderColor: state.isDisabled ? '#d1d5db' : '#af6693',
        },
      }
    },
    placeholder: (defaultStyles, state) => {
      const size = state.selectProps['size']
      return {
        ...defaultStyles,
        color: state.isFocused ? '#d1d5db' : '#9ca3af',
        fontWeight: 500,
        whiteSpace: 'nowrap',
        ...(size === 'large' && { fontSize: '16px' }),
      }
    },
    clearIndicator: (defaultStyles, state) => {
      const display =
        state.selectProps.isClearable && state.selectProps.value
          ? 'block'
          : 'none'
      return {
        ...defaultStyles,
        display,
      }
    },
    indicatorSeparator: () => {
      return {
        display: 'none',
      }
    },
    dropdownIndicator: (defaultStyles, state) => {
      const display =
        state.selectProps.isClearable && state.selectProps.value
          ? 'none'
          : 'block'
      return {
        ...defaultStyles,
        display,
        color:
          state.isFocused && !isInsideTable
            ? '#7a004b'
            : state.isDisabled
            ? '#999999'
            : '#000000',
        '&:hover': { color: state.isDisabled ? '#999999' : '#7a004b' },
      }
    },
    menu: (defaultStyles) => {
      const position = optionsPosition === 'right' ? { right: 0 } : {}
      return {
        ...defaultStyles,
        margin: 0,
        width: 'max-content',
        minWidth: '100%',
        display: 'inline-block',
        border: '1px solid #e4e4e7',
        boxShadow: `0 4px 6px -2px rgba(0, 0, 0, 0.05), 
      0 10px 15px -3px rgba(0, 0, 0, 0.1)`,
        ...position,
        maxWidth: MAX_OPTIONS_MENU_WIDTH,
        zIndex: 9999,
      }
    },
    option: () => {
      return {}
    },
    valueContainer: (defaultStyles, state) => {
      const { size } = state.selectProps

      return {
        ...defaultStyles,
        height: '100%',
        color: '#1f2937',
        ...(isInsideTable ? { paddingLeft: 0 } : {}),
        fontWeight: 500,
        padding: isCreatable ? '0.5rem' : '0.5rem 0.75rem',
        transition: '0.7s padding',
        ...(isMinimized ? { padding: 0 } : {}),
        ...(size === 'large' && { fontSize: '16px' }),
      }
    },
    singleValue: (defaultStyles) => {
      return {
        ...defaultStyles,
        whiteSpace: 'pre-line',
      }
    },
    multiValue: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#ffffff',
        backgroundColor: '#901356',
        borderRadius: '4px',
        margin: '0 8px 0 0',
        zIndex: 9999,
      }
    },
    multiValueLabel: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#ffffff',
      }
    },
    multiValueRemove: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#af6693',
        '&:hover': {
          color: '#ffffff',
          backgroundColor: '#901356',
        },
      }
    },
    input: (defaultStyles) => {
      return {
        ...defaultStyles,
        margin: 0,
        padding: 0,
      }
    },
  }
}
