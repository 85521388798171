import { useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { revenueSettingsPath } from 'app/navigation/paths'
import { getIsUserTypeAuthorized } from 'app/router/routesUtils'
import { AxiosResponse } from 'axios'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'

import {
  ReportSummaryItemValue,
  ReportSummaryResponse,
} from 'common/api/reporting'
import {
  accountSettingsPermissions,
  getIsAuthorized,
} from 'common/auth/permissions'
import ActionIcon from 'common/components/ActionIcon'
import { Column } from 'common/components/table'
import {
  AverageCurrencyPerPageCell,
  CurrencyCell,
  CurrencyCellvalue,
  TotalCurrencyPerPageCell,
} from 'common/components/table/cells'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { InfoAction } from 'common/components/WidgetHeader'
import { IsoCurrency } from 'common/helpers/currency'
import { useAppSelector } from 'common/hooks/redux'
import { Settings } from 'common/icons'

import {
  fetchIncrementalRevenueSummaryReport,
  WidgetIncrementalRevenueResponse,
} from './api'

type TableItem = {
  name: string
  allTime: CurrencyCellvalue
  forPeriod: CurrencyCellvalue
  perCampaign: CurrencyCellvalue
  annualProjection: CurrencyCellvalue
}

const mapToTableData = (
  data: ReportSummaryItemValue[],
  projectCurrencyMap: Record<string, IsoCurrency | undefined>,
  globalIsoCurrency: IsoCurrency = IsoCurrency.USD
): TableItem[] => {
  return data.map((item) => {
    const isoCurrency = projectCurrencyMap[item.name] || globalIsoCurrency

    return {
      name: item.name,
      allTime: { currencyValue: item.values[0].value, isoCurrency },
      forPeriod: { currencyValue: item.values[1].value, isoCurrency },
      perCampaign: { currencyValue: item.values[2].value, isoCurrency },
      annualProjection: { currencyValue: item.values[3].value, isoCurrency },
    }
  })
}

type Props = {
  widgetData: WidgetIncrementalRevenueResponse
}

const IncrementalRevenueWidget = ({ widgetData }: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [showFooter, setShowFooter] = useState<boolean>(false)
  const [tableData, setTableData] = useState<TableItem[]>()

  const history = useHistory()
  const {
    showAdminPage,
    showAdminAccountSettingsPage,
    showRevenueReporting,
    rolesAndPermissions,
  } = useFlags()

  const { accountId, permissions, userType } = useAppSelector(
    (state) => state.authStates
  )
  const {
    projects: projectIds,
    campaigns,
    campaignList,
    channel,
    startDate,
    endDate,
  } = useAppSelector((state) => state.reports)

  useEffect(() => {
    const getProjectCampaignIds = (projectId: string): string[] => {
      return campaignList && campaignList.length > 0
        ? campaignList
            .filter((campaign) => campaign.projectId === projectId)
            .map((campaign) => campaign.id)
        : []
    }

    ;(async () => {
      try {
        setHasError(false)
        setIsLoading(true)

        const globalData = widgetData.tabs[0]
        const globalConfiguration = widgetData.tabs[0].configuration

        const promises: Promise<AxiosResponse<ReportSummaryResponse>>[] = []
        const projectCurrencyMap: Record<string, IsoCurrency | undefined> = {}
        let selectedProjectIds = [...projectIds]
        let hasSingleCurrency = true
        const hasGlobalConfiguration = !isEmpty(globalConfiguration)

        widgetData.tabs.forEach((tab, index) => {
          if (index !== 0) {
            const configuration = tab.configuration
            if (configuration) {
              projectCurrencyMap[tab.display_name] = configuration.iso_currency
              selectedProjectIds = selectedProjectIds.filter(
                (id) => id !== tab.id
              )

              if (
                configuration.iso_currency !== globalConfiguration?.iso_currency
              ) {
                hasSingleCurrency = false
              }

              const projectId = tab.id
              let selectedCampaignIds: string[] = []
              if (campaigns.length > 0 && campaignList) {
                const projectCampaignIds = getProjectCampaignIds(projectId)

                selectedCampaignIds = campaigns.filter((campaignId) =>
                  projectCampaignIds?.includes(campaignId)
                )
              }

              promises.push(
                fetchIncrementalRevenueSummaryReport({
                  accountId,
                  openConversionPercentage:
                    configuration.open_conversion_percentage,
                  clickConversionPercentage:
                    configuration.click_conversion_percentage,
                  clickValue: configuration.click_value,
                  openValue: configuration.open_value,
                  averageOrderValue: configuration.average_order_value,
                  channel,
                  projectIds: [projectId],
                  campaignIds: selectedCampaignIds,
                  testingMethod: tab.testing_method,
                  reportingLevel: 'project',
                  startDate,
                  endDate,
                })
              )
            }
          }
        })

        if (selectedProjectIds.length > 0 && hasGlobalConfiguration) {
          let selectedCampaignIds: string[] = []

          if (campaigns.length > 0 && campaignList) {
            selectedProjectIds.forEach((projectId) => {
              const projectCampaignIds = getProjectCampaignIds(projectId)

              selectedCampaignIds = [
                ...selectedCampaignIds,
                ...campaigns.filter((campaignId) =>
                  projectCampaignIds?.includes(campaignId)
                ),
              ]
            })
          }

          promises.push(
            fetchIncrementalRevenueSummaryReport({
              accountId,
              openConversionPercentage:
                globalConfiguration?.open_conversion_percentage,
              clickConversionPercentage:
                globalConfiguration?.click_conversion_percentage,
              clickValue: globalConfiguration?.click_value,
              openValue: globalConfiguration?.open_value,
              averageOrderValue: globalConfiguration?.average_order_value,
              channel,
              projectIds: selectedProjectIds,
              campaignIds: selectedCampaignIds,
              testingMethod: globalData.testing_method,
              reportingLevel: 'project',
              startDate,
              endDate,
            })
          )
        }

        if (promises.length > 0) {
          const result = await Promise.all(promises)
          const tabData = result.reduce((acc, response) => {
            return response.data.data.length > 0
              ? [...acc, ...response.data.data[0].values.slice(1)]
              : acc
          }, [] as ReportSummaryItemValue[])

          setTableData(
            mapToTableData(
              tabData,
              projectCurrencyMap,
              globalConfiguration?.iso_currency
            )
          )
          setShowFooter(hasSingleCurrency)
        } else {
          setTableData([])
        }
      } catch (e) {
        setHasError(true)
      }
      setIsLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountId,
    channel,
    endDate,
    projectIds,
    campaigns,
    startDate,
    widgetData,
  ])

  const columns: Column<TableItem>[] = useMemo(
    () => [
      {
        Header: 'Project name',
        accessor: 'name',
        ...(showFooter && { Footer: <BaseCell>Total</BaseCell> }),
      },
      {
        Header: 'All time',
        accessor: 'allTime',
        align: 'right',
        Cell: CurrencyCell,
        ...(showFooter && {
          Footer: TotalCurrencyPerPageCell,
        }),
      },
      {
        Header: 'For period',
        accessor: 'forPeriod',
        align: 'right',
        Cell: CurrencyCell,
        ...(showFooter && {
          Footer: TotalCurrencyPerPageCell,
        }),
      },
      {
        Header: 'Avg. per experiment',
        accessor: 'perCampaign',
        align: 'right',
        Cell: CurrencyCell,
        ...(showFooter && {
          Footer: AverageCurrencyPerPageCell,
        }),
      },
    ],
    [showFooter]
  )

  const hasAccountSettingsPermission = rolesAndPermissions
    ? getIsAuthorized(
        permissions,
        accountSettingsPermissions.accountSettingsView
      )
    : getIsUserTypeAuthorized(userType, 'superhero')
  const showSettings =
    hasAccountSettingsPermission &&
    showAdminPage &&
    showAdminAccountSettingsPage &&
    showRevenueReporting

  return (
    <TableWidget.Widget
      isLoading={isLoading}
      hasError={hasError}
      columns={columns}
      data={tableData}
      disablePagination={true}
      firstUseText="Configure me to view incremental revenue performance."
    >
      <TableWidget.Header title="Incremental revenue">
        {showSettings ? (
          <ActionIcon
            data-testid="incremental-revenue-table-settings-icon"
            onClick={() =>
              history.push(
                generatePath(revenueSettingsPath, {
                  accountId,
                })
              )
            }
          >
            <Settings className="mr-4 opacity-50" />
          </ActionIcon>
        ) : null}
        <TableWidget.Menu />
        <InfoAction
          message={
            "This shows a summary incremental revenue report for each project you selected. If you've configured different metrics for the projects these will be used when calculating the incremental revenue."
          }
        />
      </TableWidget.Header>
    </TableWidget.Widget>
  )
}

export default IncrementalRevenueWidget
