const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
const FACEBOOK_CONNECT_URL = process.env.REACT_APP_FACEBOOK_CONNECT_URL

;(function (document, script, id) {
  const fjs: any = document.getElementsByTagName(script)[0]
  if (document.getElementById(id)) {
    return
  }
  const js: any = document.createElement(script)
  js.id = id
  js.src = FACEBOOK_CONNECT_URL
  fjs?.parentNode.insertBefore(js, fjs)
})(document, 'script', 'facebook-jssdk')
;(window as any).fbAsyncInit = function () {
  ;(window as any).FB.init({
    appId: FACEBOOK_APP_ID,
    cookie: true,
    xfbml: true,
    version: 'v16.0',
    status: false,
  })
}

export {}
