import { Field, Form } from 'react-final-form'
import {
  ComponentElement,
  ConnectContent,
  Element,
} from '@phrasee/phrasee-typings'

import Button from 'common/components/button'
import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'

const validateAssetName = (value?: string) => {
  if (!value) {
    return 'Required'
  } else if (/\s/.test(value)) {
    return 'Spaces are not allowed in the asset name'
  } else {
    return undefined
  }
}

const getAssets = (
  element: Element
): { name: string; id: number; isDisabled: boolean }[] => {
  if (isComponentElement(element)) {
    return [
      {
        name: element.display_name || element.name,
        id: element.element_id,
        isDisabled: element.integration_status === 'finished',
      },
    ]
  } else if (isTemplateElement(element)) {
    return element.elements.map((nestedElement) => ({
      name: nestedElement.display_name || nestedElement.name || '',
      id: nestedElement.element_id,
      isDisabled:
        (nestedElement as ComponentElement).integration_status === 'finished',
    }))
  } else {
    return []
  }
}

type Props = {
  element: Element
  isDisabled: boolean
  onConnectClick: (content: ConnectContent[]) => Promise<{} | undefined>
}

const IntegrationAssets = ({ element, isDisabled, onConnectClick }: Props) => {
  const assets = getAssets(element)
  const areAllAssetsDisabled = assets.every((asset) => asset.isDisabled)

  const handleSave = (values) => {
    const connectContent: ConnectContent[] = Object.keys(values).map(
      (value) => {
        const elementId = Number(value.split('-')[1])
        const assetName = values[value]
        let variantText = ''
        if (isComponentElement(element)) {
          variantText = (
            element.variants
              ?.filter((variant) =>
                element.selected_variants?.includes(variant.variant_id)
              )
              ?.map((variant) => variant.text) || []
          ).join('\n')
        } else if (isTemplateElement(element)) {
          const foundElement = element.elements.find(
            (nestedElement) => nestedElement.element_id === elementId
          )
          if (foundElement && isComponentElement(foundElement)) {
            variantText = (
              foundElement.variants
                ?.filter((variant) =>
                  foundElement.selected_variants?.includes(variant.variant_id)
                )
                ?.map((variant) => variant.text) || []
            ).join('\n')
          }
        }

        return { elementId, assetName, value: variantText }
      }
    )
    return onConnectClick(connectContent)
  }

  return (
    <Form
      onSubmit={handleSave}
      render={({ handleSubmit, pristine, form }) => (
        <form onSubmit={handleSubmit} className="text-sm">
          <div className="flex flex-col">
            <hr className="my-8 text-coolGray-200" />
            <span className="text-coolGray-800 font-medium mb-3">Assets</span>
            <span className="text-sm text-coolGray-400 mb-8">
              Confirm the names of the assets you’re sending
            </span>
            {assets.map(({ name, id, isDisabled }) => {
              const fieldState = form.getFieldState(`element-${id}`)
              if (isDisabled && fieldState) {
                fieldState?.change(undefined)
                form.resetFieldState(`element-${id}`)
              }
              return (
                <Field<string>
                  name={`element-${id}`}
                  key={id}
                  validate={!isDisabled ? validateAssetName : undefined}
                  render={({ input, meta }) => (
                    <FormItem
                      label={name}
                      htmlFor={`element-${id}`}
                      error={
                        meta.touched && (meta.error || meta.submitError)
                          ? meta.error || meta.submitError
                          : undefined
                      }
                    >
                      <Input
                        type="text"
                        id={`element-${id}`}
                        variant="default"
                        className="text-coolGray-800"
                        disabled={isDisabled}
                        value={input.value}
                        name={input.name}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                      />
                    </FormItem>
                  )}
                />
              )
            })}
          </div>
          <div className="flex justify-end items-center gap-4 mt-8">
            <Button
              type="submit"
              variant="primary"
              disabled={isDisabled || pristine || areAllAssetsDisabled}
              data-cy="content-integration-connect-button"
            >
              Connect
            </Button>
          </div>
        </form>
      )}
    />
  )
}

export default IntegrationAssets
