import React from 'react'
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table'
import Menu, { Item as MenuItem } from 'rc-menu'
import { MenuInfo } from 'rc-menu/lib/interface'

import CheckboxComponent from 'common/components/checkbox/Checkbox'

const renderMenuItem = (
  items: ColumnFilterItem[] | undefined,
  selectedKeys: React.Key[] | undefined
) => {
  if (!items || !selectedKeys) {
    return null
  }
  // We still need trade key as string since Menu render need string
  const internalSelectedKeys = (selectedKeys || []).map((key) => key.toString())

  return items.map((item) => {
    const input = (
      <CheckboxComponent
        isChecked={internalSelectedKeys.indexOf(item.value.toString()) >= 0}
        onChange={() => {}}
      />
    )

    return (
      <MenuItem key={item.value}>
        <div className="flex py-1 px-3">
          {input}
          <span>{item.text}</span>
        </div>
      </MenuItem>
    )
  })
}

const handleMenuItemClick = (
  info: MenuInfo,
  selectedKeys: FilterDropdownProps['selectedKeys'],
  setSelectedKeys: FilterDropdownProps['setSelectedKeys']
) => {
  if (!selectedKeys || !setSelectedKeys) {
    return
  }
  if (!info.keyPath || info.keyPath.length < 1) {
    return
  }
  if (selectedKeys && selectedKeys.indexOf(info.key) >= 0) {
    // deselect menu item
    const updatedKeys = selectedKeys.filter((key) => key !== info.key)
    setSelectedKeys(updatedKeys as string[])
  } else {
    // select menu item
    setSelectedKeys([...selectedKeys, info.key] as string[])
  }
}

const handleResetFilter = (
  setSelectedKeys: FilterDropdownProps['setSelectedKeys'],
  valueToResetTo: string[],
  confirm: FilterDropdownProps['confirm']
) => {
  if (setSelectedKeys && confirm) {
    setSelectedKeys(valueToResetTo)
    confirm()
  }
}

export const CustomFilterDropdown = ({
  selectedKeys,
  setSelectedKeys,
  filters,
  confirm,
  defaultFilteredValue,
}: FilterDropdownProps & { defaultFilteredValue: string[] }) => {
  return (
    <>
      <div className="py-1">
        <Menu
          onClick={(info) =>
            handleMenuItemClick(info, selectedKeys, setSelectedKeys)
          }
        >
          {renderMenuItem(filters, selectedKeys)}
        </Menu>
      </div>
      <div className="ant-table-filter-dropdown-btns">
        <button
          className="ant-table-filter-dropdown-link confirm outline-none"
          onClick={confirm}
        >
          OK
        </button>
        <button
          className="ant-table-filter-dropdown-link clear outline-none"
          onClick={() =>
            handleResetFilter(setSelectedKeys, defaultFilteredValue, confirm)
          }
        >
          Reset
        </button>
      </div>
    </>
  )
}
