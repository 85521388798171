import cx from 'classnames'

type OverlayType = 'global' | 'local'
interface Props {
  onClick?: () => void
  className?: string
  innerClassName?: string
  isContentCentered?: boolean
  bgColor?: string
  type?: OverlayType
  children?: React.ReactNode
}

function Overlay({
  type = 'global',
  onClick,
  className,
  innerClassName,
  isContentCentered = true,
  children,
  bgColor = 'bg-gray-500',
}: Props) {
  return (
    <div
      data-testid="overlay"
      className={cx(
        `bg-opacity-75 transition-opacity z-30 flex`,
        { 'absolute h-full w-full top-0 left-0': type === 'local' },
        { 'justify-center items-center': isContentCentered },
        { 'fixed inset-0': type === 'global' },
        { 'cursor-pointer': onClick },
        className,
        bgColor
      )}
      onClick={onClick}
    >
      <div className={cx('z-60', innerClassName)}>{children}</div>
    </div>
  )
}

export default Overlay
