import { useQuery } from '@tanstack/react-query'

import { TreeNode } from 'common/components/catalog/Catalog'
import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { CustomerAttributes, fetchMetrics } from '../api'
import { personalizationKeys } from '../queryKeys'

const useGetMetricsQuery = ({
  channelId,
  personalizationId,
  customerAttributes,
  productCategories,
}: {
  channelId: string | undefined
  personalizationId: string
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const query = useQuery(
    personalizationKeys.metrics({
      accountId,
      channelId,
      customerAttributes,
      personalizationId,
      productCategories,
    }),
    () =>
      fetchMetrics({
        accountId,
        channelId,
        personalizationId,
        customerAttributes: customerAttributes,
        productCategories: productCategories,
      }),
    {
      onError: () => {
        errorToast('Something went wrong. Please try again.')
      },
      cacheTime: 0,
      staleTime: 0,
    }
  )

  return query
}

export default useGetMetricsQuery
