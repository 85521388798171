import { useQuery } from '@tanstack/react-query'

import { GetUserResponse, getUsers } from 'features/admin/users/all/api'
import { contentKeys } from 'features/futurama/api/queryKeys'

const useSelectUsers = (accountId: string, contentId: string) => {
  const { data: users } = useQuery({
    queryKey: [contentKeys.contentTeams(contentId, accountId)],
    queryFn: () => getUsers(accountId).then((res) => res.data),
    select: (data: GetUserResponse[]) => {
      return data
        .filter(
          ({ role, isSuspended }) =>
            ['admin', 'user', 'approver'].includes(role) && !isSuspended
        )
        .map(({ _id, first_name, last_name }) => ({
          value: _id,
          label: `${first_name} ${last_name}`,
        }))
    },
  })

  return users
}

export default useSelectUsers
