import cx from 'classnames'
import inRange from 'lodash/inRange'

import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'

type Props = {
  variant: string
  weights: [string, number][]
}

const VariantSentimentHighlighter = ({ variant, weights }: Props) => {
  const wordsToScore = weights.reduce((previous, currentValue) => {
    const [word, score] = currentValue
    return {
      ...previous,
      [word]: score,
    }
  }, {})
  return (
    <>
      {variant
        .split(' ')
        .map<React.ReactNode>((word, index) =>
          wordsToScore[word] > 0 ? (
            <span
              key={index}
              className={cx('bg-maroon-300 ', {
                'bg-opacity-80 ': inRange(wordsToScore[word], 0.75, 1),
                'bg-opacity-60 ': inRange(wordsToScore[word], 0.5, 0.75),
                'bg-opacity-30 ': inRange(wordsToScore[word], 0.25, 0.5),
                'bg-opacity-20 ': inRange(wordsToScore[word], 0, 0.25),
              })}
              data-test-d="highlighWord"
            >
              {replaceNewLineEmojiAsElement(word)}
            </span>
          ) : (
            replaceNewLineEmojiAsElement(word)
          )
        )
        .reduce((prev, curr) => [prev, ' ', curr])}
    </>
  )
}

export default VariantSentimentHighlighter
