import useContentOrTemplate from './useContentOrTemplate'

const useIsMultistep = () => {
  const { template, content } = useContentOrTemplate()

  return {
    isMultistep: template?.multi_step || content?.multi_step,
  }
}
export default useIsMultistep
