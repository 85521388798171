import { Element } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import ElementActionsMenu from './ElementActionsMenu'
import ElementCardIcon from './ElementCardIcon'

type Props = {
  title: string
  className?: string
  content?: string | JSX.Element | null
  element?: Element
  icon?: string
  isRemoveOptionShown?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  hasError?: boolean
  isApproved?: boolean
  isConfigurable?: boolean
  hasSmallTitle?: boolean
  hasElementActionsMenu?: boolean
  showCustomBriefIcon?: boolean
  onClick?: () => void
}

const ElementCard = ({
  title,
  className,
  content,
  element,
  icon,
  isRemoveOptionShown,
  isDisabled = false,
  isSelected = false,
  hasError = false,
  isApproved = false,
  isConfigurable = false,
  hasSmallTitle = false,
  hasElementActionsMenu = true,
  showCustomBriefIcon = true,
  onClick,
}: Props) => {
  const hasContent = !!content

  return (
    <div
      onClick={onClick}
      className={cx(
        'relative flex items-center border group',
        {
          'p-5': !hasContent,
          'p-4': hasContent,
          'bg-coolGray-100 border-coolGray-200 cursor-not-allowed': isDisabled,
          'bg-white border-coolGray-300 cursor-pointer hover:border-maroon-300':
            !isDisabled,
          'border-1.5 border-maroon-500': isSelected,
          'bg-white border-red-500 cursor-pointer ': !isDisabled && hasError,
        },
        className
      )}
      aria-selected={isSelected}
      aria-label={isApproved ? 'Approved' : undefined}
      role="tab"
    >
      {icon && (
        <ElementCardIcon
          icon={icon}
          className={cx('mr-4', {
            'text-maroon-300': isSelected,
            'text-red-400': hasError,
          })}
        />
      )}
      <div className={cx('flex flex-col font-medium overflow-hidden')}>
        <span
          className={cx('leading-4', {
            'text-sm  text-coolGray-800': !isDisabled && !hasSmallTitle,
            'text-sm  text-coolGray-400': isDisabled && !hasSmallTitle,
            'text-xs text-coolGray-500': hasSmallTitle && !isDisabled,
            'text-xs text-coolGray-400': hasSmallTitle && isDisabled,
          })}
          aria-disabled={isDisabled}
        >
          {title}
        </span>

        {hasContent && <div className="min-h-5 mt-2">{content}</div>}
      </div>
      <ElementActionsMenu
        hasError={hasError}
        isApproved={isApproved}
        isConfigurable={isConfigurable}
        isRemoveOptionShown={isRemoveOptionShown}
        isSelected={isSelected}
        element={element}
        hasElementActionsMenu={hasElementActionsMenu}
        showCustomBriefIcon={showCustomBriefIcon}
      />
    </div>
  )
}

export default ElementCard
