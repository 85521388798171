import { useQuery } from '@tanstack/react-query'

import { getPhrases } from '../api'
import { Phrase } from '../interfaces'
import { contentKeys } from '../queryKeys'

const useGetPhrasesQuery = (selectedNodeId?: string, enabled?: boolean) => {
  const getPhrasesQuery = useQuery<Phrase[]>(
    contentKeys.phrases(selectedNodeId),
    () => getPhrases(selectedNodeId),
    { enabled }
  )

  return getPhrasesQuery
}

export default useGetPhrasesQuery
