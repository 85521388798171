import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'

import AnimatedOverlay from 'common/components/animatedOverlay'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Spinner from 'common/components/spinner'
import Tabs, { addCountToTabName, Tab } from 'common/components/tabs'
import Tooltip from 'common/components/Tooltip'
import { MoreOptions } from 'common/icons'
import useGetTemplatesQuery from 'features/unifiedFlow/api/queries/useGetTemplatesQuery'

import { contentTypesByTab, TemplateInfo } from '../../contentsPage/interfaces'
import Searchbox from '../Searchbox'

import TemplatesConfigPanel from './configPanel/TemplateConfigPanel'
import { ExperimentFormProvider } from './context/ExperimentFormContext'
import useTemplateTypesBlocked from './hooks/useTemplateTypesBlocked'
import ExperimentMenu, { experimentType } from './experimentMenu'
import TemplatesGrid from './TemplatesGrid'

import styles from './Templates.module.css'

type Props = {
  isVisible: boolean
  intialSelectedTab: string
  onClose: () => void
}
const Templates = ({ isVisible, onClose, intialSelectedTab }: Props) => {
  const { data, status } = useGetTemplatesQuery()
  const tabsRef = useRef<HTMLDivElement>(null)

  const [selectedTab, setSelectedTab] = useState(intialSelectedTab)
  const [searchContentValue, setSearchContentValue] = useState<string>('')

  const [experimentTypes, setExperimentTypes] = useState<string[]>([])

  useEffect(() => {
    setSelectedTab(intialSelectedTab)
  }, [intialSelectedTab])

  const filterByContentType = useCallback(
    ({ contentType }: TemplateInfo) =>
      selectedTab === 'all' || selectedTab === 'experimentType'
        ? true
        : contentType.toLowerCase() === selectedTab,
    [selectedTab]
  )

  const filterByExperimentType = useCallback(
    ({ optimization, personalization }: TemplateInfo) =>
      experimentTypes.length === 0
        ? true
        : (experimentTypes.includes(experimentType.optimize) &&
            optimization?.status !== 'disabled') ||
          (experimentTypes.includes(experimentType.personalize) &&
            personalization?.status !== 'disabled'),
    [experimentTypes]
  )

  const filterByName = useCallback(
    ({ displayName }: TemplateInfo) =>
      displayName
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchContentValue.replaceAll(' ', '').toLowerCase()),
    [searchContentValue]
  )

  const allFiltered = useMemo(() => {
    return (data ?? []).filter(filterByName)
  }, [data, filterByName])

  const experiementsFiltered = useMemo(() => {
    return (data ?? []).filter(filterByExperimentType).filter(filterByName)
  }, [data, filterByExperimentType, filterByName])

  const templatesFiltered = useMemo(() => {
    return (data ?? [])
      .filter(filterByContentType)
      .filter(filterByExperimentType)
      .filter(filterByName)
  }, [data, filterByContentType, filterByExperimentType, filterByName])

  const typesOfTemplatesBlocked = useTemplateTypesBlocked(allFiltered)

  const tabs: Tab[] = [
    {
      key: 'all',
      name: addCountToTabName({
        tabName: 'All',
        count: data?.length || 0,
      }),
      content:
        allFiltered.length === 0 && searchContentValue !== '' ? (
          <div className="text-5xl font-bold text-coolGray-800 leading-normal">
            There are no templates matching your search, please try again
          </div>
        ) : (
          <TemplatesGrid
            templates={allFiltered}
            contentTypes={contentTypesByTab}
            experimentTypes={[]}
          />
        ),
      isDisabled: false,
    },
    {
      key: 'experimentType',
      name: '',
      menu: (
        <ExperimentMenu
          getPopupContainer={(node) => tabsRef?.current || node}
          onApply={(selectedExperiments) => {
            setExperimentTypes(selectedExperiments)
          }}
        >
          {addCountToTabName({
            tabName: 'Experiment Type',
            count: experiementsFiltered.length || 0,
          })}
        </ExperimentMenu>
      ),
      content:
        experiementsFiltered.length > 0 ? (
          <TemplatesGrid
            contentTypes={contentTypesByTab}
            templates={experiementsFiltered}
            experimentTypes={experimentTypes}
          />
        ) : (
          <div className="text-5xl font-bold text-coolGray-800 leading-normal">
            {searchContentValue === '' && experimentTypes.length === 0
              ? 'There are no templates matching your filtering, please select an experiment type'
              : 'There are no templates matching your search, please try again'}
          </div>
        ),
      isDisabled: false,
    },
    ...Object.keys(contentTypesByTab)
      .filter((category) => category !== 'all')
      .map((category) => {
        const isBlocked =
          (category !== 'ecommerce' &&
            typesOfTemplatesBlocked === 'non-ecommerce') ||
          (category === 'ecommerce' && typesOfTemplatesBlocked === 'ecommerce')

        return {
          key: category,
          name: '',
          menu: (
            <Tooltip
              overlay="Incompatible with selected template(s)"
              placement="top"
              show={isBlocked}
            >
              <div>
                {addCountToTabName({
                  tabName: category,
                  count:
                    data
                      ?.filter(filterByExperimentType)
                      .filter(
                        (item) => item.contentType.toLowerCase() === category
                      ).length || 0,
                })}
              </div>
            </Tooltip>
          ),
          content:
            templatesFiltered.length > 0 ? (
              <TemplatesGrid
                contentTypes={contentTypesByTab}
                templates={templatesFiltered}
                experimentTypes={experimentTypes}
              />
            ) : (
              <div className="text-5xl font-bold text-coolGray-800 leading-normal">
                {searchContentValue === '' && experimentTypes.length === 0
                  ? 'There are no saved templates for this category'
                  : 'There are no templates matching your search, please try again'}
              </div>
            ),
          isDisabled: isBlocked,
        }
      }),
  ]

  return (
    <AnimatedOverlay
      title="Add a Template"
      className="overflow-hidden"
      onClose={onClose}
      isVisible={isVisible}
      isHeaderSticky={true}
    >
      {
        {
          success: (
            <div className="flex flex-1 overflow-hidden">
              <div className="flex flex-1 flex-col bg-warmGray-50 w-full overflow-y-auto px-4 sm:px-6">
                <div className="flex justify-between items-stretch relative mt-12">
                  <div className={cx('w-full', styles.templateTabs)}>
                    <Tabs
                      ref={tabsRef}
                      tabs={tabs}
                      defaultActiveKey={selectedTab}
                      activeKey={selectedTab}
                      onTabClick={(tab) => {
                        setSelectedTab(tab)
                      }}
                      variant="filter"
                      moreIcon={<MoreOptions />}
                    />
                  </div>

                  <div
                    className={cx(
                      'flex absolute top-0 right-0 items-start',
                      styles.search
                    )}
                  >
                    <Searchbox
                      className="w-full"
                      placeholder="Find template"
                      data-cy="template-search"
                      onSearchContentValue={setSearchContentValue}
                      options={data?.map((template: TemplateInfo) => ({
                        value: template.id,
                        label: template.displayName,
                      }))}
                    />
                  </div>
                </div>
                <Footer className="pt-0" />
              </div>
              {isVisible && (
                <div className={cx('flex', styles.templatesConfigPanel)}>
                  <ExperimentFormProvider>
                    <TemplatesConfigPanel />
                  </ExperimentFormProvider>
                </div>
              )}
            </div>
          ),
          loading: <Spinner />,
          error: <ErrorPage />,
        }[status]
      }
    </AnimatedOverlay>
  )
}

export default Templates
