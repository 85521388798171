// TODO refactor to use the Drawer component
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import cx from 'classnames'
import InputResults from 'workflow/CampaignSummary/InputResults/InputResults'
import AcousticIntegration from 'workflow/CampaignSummary/IntegrationOptions/AcousticIntegration/AcousticIntegration'
import BrazeIntegration from 'workflow/CampaignSummary/IntegrationOptions/BrazeIntegration/BrazeIntegration'
import CheetahIntegration from 'workflow/CampaignSummary/IntegrationOptions/CheetahIntegration/CheetahIntegration'
import EmarsysIntegration from 'workflow/CampaignSummary/IntegrationOptions/EmarsysIntegration/EmarsysIntegration'
import EpsilonIntegration from 'workflow/CampaignSummary/IntegrationOptions/EpsilonIntegration/EpsilonIntegration'
import EpsilonReactIntegration from 'workflow/CampaignSummary/IntegrationOptions/EpsilonIntegration/EpsilonReactIntegration'
import MappIntegration from 'workflow/CampaignSummary/IntegrationOptions/MappIntegration/MappIntegration'
import MovableInkIntegration from 'workflow/CampaignSummary/IntegrationOptions/MovableInkIntegration/MovableInkIntegration'
import SailthruIntegration from 'workflow/CampaignSummary/IntegrationOptions/SailthruIntegration/SailthruIntegration'
import SFMCIntegration from 'workflow/CampaignSummary/IntegrationOptions/SfmcIntegration/SfmcIntegrationManager'

import Alert from 'common/components/alert'
import Overlay from 'common/components/Overlay'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Close as CloseIcon } from 'common/icons'
import { hideIntercom, showIntercom } from 'features/auth/store/authSlice'
import AdobeTargetIntegration from 'features/campaigns/integration-options/AdobeTarget/AdobeTargetIntegration'
import AirshipIntegration from 'features/campaigns/integration-options/Airship/AirshipIntegration'
import CordialIntegration from 'features/campaigns/integration-options/Cordial/CordialIntegration'
import FacebookIntegration from 'features/campaigns/integration-options/Facebook/FacebookIntegration'
import OptimizelyIntegration from 'features/campaigns/integration-options/Optimizely/OptimizelyIntegration'
import PhraseeX from 'features/campaigns/integration-options/PhraseeX'
import ResponsysIntegration from 'features/campaigns/integration-options/Responsys/ResponsysIntegration'
import { closeDrawer } from 'features/campaigns/store/campaignSlice'

import styles from './Drawer.module.css'

type ScrollPosition = 'top' | 'middle' | 'bottom' | 'none'
type DrawerContextType = {
  scrollPosition: ScrollPosition
  setScrollPosition: (value: ScrollPosition) => void
}

export const getESPTitle = (drawer: string): string | undefined => {
  return {
    adobe_campaign_classic: 'Adobe Campaign Classic',
    acoustic: 'Acoustic',
    adobetarget: 'Adobe Target',
    braze: 'Braze',
    cheetah: 'Cheetah',
    emarsys: 'Emarsys',
    epsilon: 'Epsilon',
    epsilon_react: 'Epsilon',
    facebook: 'Facebook',
    cordial: 'Cordial',
    airship: 'Airship',
    ibm: 'Acoustic',
    'ibm watson': 'Acoustic',
    'input-results': 'Input Results',
    mapp: 'Mapp',
    movable_ink: 'Movable Ink',
    optimizely: 'Optimizely',
    phraseex: 'Dynamic Optimization',
    responsys: 'Responsys',
    sailthru: 'Schedule',
    salesforce: 'Salesforce',
    sfmcv2: 'Salesforce',
  }[drawer]
}

const DrawerContext = React.createContext<DrawerContextType>({
  scrollPosition: 'none',
  setScrollPosition: () => undefined,
})

function Drawer() {
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>('none')
  const dispatch = useAppDispatch()
  const drawer = useAppSelector((state) => state.campaigns.drawer)
  const location = useLocation()

  useEffect(() => {
    if (drawer) {
      dispatch(hideIntercom())
    }
    return () => {
      if (drawer) {
        dispatch(showIntercom())
      }
    }
  }, [dispatch, drawer])

  useEffect(() => {
    dispatch(closeDrawer())
  }, [dispatch, location])

  const getContent = () => {
    switch (drawer) {
      case 'input-results':
        return <InputResults onCloseDrawer={() => dispatch(closeDrawer())} />
      case 'sfmcv2':
      case 'salesforce':
        return <SFMCIntegration />
      case 'sailthru':
        return <SailthruIntegration />
      case 'cheetah':
        return (
          <CheetahIntegration closeDrawer={() => dispatch(closeDrawer())} />
        )
      case 'mapp':
        return <MappIntegration />
      case 'epsilon':
        return <EpsilonIntegration />
      case 'epsilon_react':
        return <EpsilonReactIntegration />
      case 'braze':
        return <BrazeIntegration closeDrawer={() => dispatch(closeDrawer())} />
      case 'acoustic':
      case 'ibm':
      case 'ibm watson':
        return <AcousticIntegration />
      case 'movable_ink':
        return <MovableInkIntegration />
      case 'adobetarget':
        return <AdobeTargetIntegration />
      case 'facebook':
        return <FacebookIntegration />
      case 'cordial':
        return <CordialIntegration />
      case 'airship':
        return <AirshipIntegration />
      case 'optimizely':
        return <OptimizelyIntegration />
      case 'adobe_campaign_classic':
      case 'phraseex':
        return <PhraseeX />
      case 'emarsys':
        return <EmarsysIntegration />
      case 'responsys':
        return <ResponsysIntegration />
    }
  }

  const onCloseDrawer = () => {
    dispatch(closeDrawer())
  }

  if (drawer) {
    const drawerTitle: string = getESPTitle(drawer) || drawer
    return (
      <DrawerContext.Provider value={{ scrollPosition, setScrollPosition }}>
        <div
          className={cx(
            'right-0 h-screen font-rob bg-white z-50',
            'fixed top-13.5'
          )}
        >
          <div className="relative">
            <div
              className={cx('flex justify-between px-9 pt-6 pb-3 bg-white', {
                [styles.shadowInnerTop]:
                  scrollPosition !== 'top' && scrollPosition !== 'none',
              })}
            >
              <div className="text-2xl font-medium text-coolGray-800">
                {drawerTitle}
              </div>
              <button
                data-cy="drawer-close-button"
                className="opacity-50"
                onClick={() => onCloseDrawer()}
              >
                <CloseIcon />
              </button>
            </div>
          </div>

          <div
            className={cx(
              'flex flex-col justify-between w-106 pb-4 relative',
              styles.contentWrapper
            )}
          >
            {getContent()}
          </div>
        </div>

        <Overlay onClick={() => onCloseDrawer()} />
      </DrawerContext.Provider>
    )
  } else {
    return null
  }
}

export const Content: React.FC = ({ children }) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { scrollPosition, setScrollPosition } = useContext(DrawerContext)

  useEffect(() => {
    if (contentRef.current) {
      const { height } = contentRef.current.getBoundingClientRect()
      const scrollHeight = contentRef.current.scrollHeight

      if (height < scrollHeight) {
        setScrollPosition('top')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    const current = event.currentTarget

    const { height } = current.getBoundingClientRect()
    const scrollHeight = current.scrollHeight
    const scrollTop = current.scrollTop

    let currentScrollPosition: ScrollPosition
    if (scrollTop === 0) {
      currentScrollPosition = 'top'
    } else if (scrollTop + height === scrollHeight) {
      currentScrollPosition = 'bottom'
    } else {
      currentScrollPosition = 'middle'
    }

    if (currentScrollPosition !== scrollPosition) {
      setScrollPosition(currentScrollPosition)
    }
  }

  return (
    <div className={styles.content} ref={contentRef} onScroll={onScroll}>
      {children}
    </div>
  )
}

type FooterProps = {
  className?: string
  error?: string
}

export const Footer: React.FC<FooterProps> = ({
  className,
  error,
  children,
}) => {
  const { scrollPosition } = useContext(DrawerContext)

  return (
    <div
      className={cx(styles.footer, className, {
        [styles.shadowInnerBottom]:
          scrollPosition !== 'bottom' && scrollPosition !== 'none',
      })}
    >
      {error && (
        <Alert type="error" className="self-stretch mb-6">
          {error}
        </Alert>
      )}
      <div className="flex">{children}</div>
    </div>
  )
}

export default Drawer
