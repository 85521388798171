import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'

import { useTweakVariant } from '../context/TweakVariantContext'

const VariantText = () => {
  const { selectedLine } = useTweakVariant()

  return (
    <p className="px-6 py-3 bg-maroon-50 text-maroon-500 font-normal border border-maroon-500 text-base">
      {replaceNewLineEmojiAsElement(selectedLine.text)}
    </p>
  )
}

export default VariantText
