import { TemplateElement } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/futurama/api/interfaces'
import { workflowCardClicked } from 'features/futurama/store/contentSlice'

import CardIcon from '../../components/CardIcon'
import { useContentOrTemplate } from '../../hooks'

import ActionsMenu from './ActionsMenu'
import WorkflowCard from './WorkflowCard'
type Props = {
  element: TemplateElement
  isConfigurable?: boolean
}

const WorkflowTemplateCard = ({ element, isConfigurable }: Props) => {
  const dispatch = useAppDispatch()
  const { template } = useContentOrTemplate()
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const areElementsDisabled = useAppSelector(
    (state) => state.content.areElementsDisabled
  )

  const { icon, display_name, elements } = element

  const hasTemplate = !!template
  const isTemplateElementSelected = element.elements
    .filter(isComponentElement)
    .some((element) => element.element_id === selectedElementId)
  const isTemplateSelected = element.element_id === selectedElementId

  return (
    <div
      className={cx('p-4 border group', {
        'border-maroon-500': isTemplateElementSelected || isTemplateSelected,
        'border-coolGray-300': !isTemplateElementSelected,
        'bg-white': !areElementsDisabled,
        'bg-coolGray-100': areElementsDisabled,
      })}
    >
      <div className="flex mb-2">
        {icon && (
          <CardIcon
            icon={icon}
            className={cx({ 'text-maroon-300': isTemplateElementSelected })}
          />
        )}
        <div
          className={cx('ml-4 text-base  font-medium overflow-hidden', {
            'text-coolGray-400': areElementsDisabled,
            'text-coolGray-800': !areElementsDisabled,
          })}
        >
          {display_name}
        </div>
        <ActionsMenu
          isConfigurable={isConfigurable || false}
          isDisabled={areElementsDisabled}
          isSelected={isTemplateSelected}
          element={element}
        />
      </div>
      {elements &&
        elements
          .filter(isComponentElement)
          .map(({ element_id, name, display_name, status }) => {
            const isElementDisabled =
              hasTemplate || areElementsDisabled || status === 'disabled'

            return (
              <WorkflowCard
                className="mb-2"
                key={element_id}
                title={display_name || name}
                isDisabled={isElementDisabled}
                isSelected={
                  selectedElementId === element_id && !isElementDisabled
                }
                onClick={() =>
                  !isElementDisabled &&
                  dispatch(
                    workflowCardClicked({
                      elementId: element_id,
                    })
                  )
                }
              />
            )
          })}
    </div>
  )
}

export default WorkflowTemplateCard
