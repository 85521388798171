import { LanguageSettings as LanguageSettingsResponse } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'
import { Replacer } from 'common/components/topics/interfaces'
import { NodeResponse } from 'common/interfaces/nodes'
import { ProjectConfig } from 'common/interfaces/projects'

import { Node } from '../interfaces'

const ROOT_NODE_ID = 1
const TEMPORARY_FIXED_CONTENT_UPDATE_ID = 3

export type { LanguageSettingsResponse }
export type InitializePageResponse = {
  projectConfig: ProjectConfig
  languageSettings: LanguageSettingsResponse
}

export async function fetchProjectCombinedLanguage(
  projectId: string
): Promise<LanguageSettingsResponse> {
  const response = await connectInternalApi.get<{
    data: LanguageSettingsResponse
  }>(`v1/core/monorail/projects/${projectId}/combined_language_settings`)

  return response.data.data
}

export const getNodes = async (): Promise<Node[]> => {
  const result = await connectInternalApi.get<NodeResponse>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/trees/5`,
    {
      params: {
        includeNodes: [0],
      },
    }
  )

  result.data.data.sort((a, b) => (a.node > b.node ? 1 : -1))

  const nodes: Node[] = result.data.data
    .filter(
      (node, _, array) =>
        node.node_parent_id === ROOT_NODE_ID ||
        array.find((n) => n.node_id === node.node_parent_id)
    )
    .map((node, _, array) => ({
      id: node.node_id.toString(),
      parent: node.node_parent_id.toString(),
      name: node.display_name || node.node,
      node: node.node,
      value: array.filter((n) => n.node_parent_id === node.node_id).length || 1,
      isPrivate: node.private,
    }))

  return nodes
}

export async function updateProjectSettings(
  projectId: string,
  settings: {
    languageSettings: LanguageSettingsResponse
  }
): Promise<InitializePageResponse> {
  const { languageSettings } = settings
  const response = await connectInternalApi.patch<{ data }>(
    `v1/core/monorail/projects/${projectId}`,
    {
      language_settings: languageSettings,
    }
  )
  return {
    projectConfig: response.data.data,
    languageSettings: response.data.data.language_settings,
  }
}

export async function getReplacers(): Promise<Replacer[]> {
  const response = await connectInternalApi.get<{ data: Replacer[] }>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/replacers?input_type=settings`
  )
  return response.data.data
}
