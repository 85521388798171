type Props = {
  progress?: number
  strokeWidth?: number
}

const ProgressBar: React.FC<Props> = ({ progress = 0, strokeWidth = 3 }) => {
  const strokeDashoffset = 100 - progress
  const yPosition = strokeWidth / 2

  return (
    <svg viewBox={`0 0 100 ${strokeWidth}`} width="100%" height="100%">
      <line
        x1="2"
        x2="98"
        y1={yPosition}
        y2={yPosition}
        stroke="#b8c3c9"
        className="opacity-20"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line
        x1="2"
        x2="98"
        y1={yPosition}
        y2={yPosition}
        stroke="#fbbf24"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray="100 100"
        strokeDashoffset={strokeDashoffset}
      />
    </svg>
  )
}

export default ProgressBar
