import * as Highcharts from 'highcharts/highstock'

import { yAxisConfig } from 'common/helpers/ui/graph'

import BarGraph from './BarGraph'
import OpenRatesOverTime from './OpenRatesOverTime'
import StackedAreaGraph from './StackedAreaGraph'
import UpliftGraph from './UpliftGraph'

const graphConfig: Highcharts.Options = {
  xAxis: {
    labels: {
      style: {
        color: '#1f384d',
        fontSize: '12px',
        fontWeight: '500',
        opacity: 0.8,
      },
    },
  },
  yAxis: {
    ...yAxisConfig,
    tickInterval: 10,
    labels: {
      format: '{value}%',
      style: {
        color: '#1f384d',
        fontSize: '12px',
        fontWeight: '500',
        opacity: 0.8,
      },
      align: 'center' as const,
      reserveSpace: true,
    },
    gridLineColor: '#e4e4e4',
    gridLineDashStyle: 'Solid',
  },
  title: {
    text: undefined,
    // float: true,
    align: 'left' as const,
  },
  scrollbar: {
    enabled: false,
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  legend: {
    enabled: false,
  },
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
    buttons: {},
  },
  navigation: {
    buttonOptions: {
      theme: {
        stroke: 'none',
      },
    },
  },
  rangeSelector: {
    inputDateFormat: '%e %b %Y',
    inputSpacing: 0,
    inputPosition: {
      align: 'left' as const,
      x: 24,
      y: 13,
    },
    buttonPosition: {
      align: 'right' as const,
      x: -5,
      y: 6,
    },
    buttonTheme: {
      fill: 'none',
      stroke: '#e4e4e4',
      'stroke-width': 1,
      style: {
        color: '#575757',
        backgroundColor: '#ffffff',
        fontFamily: 'Roboto',
      },
      padding: 7,
      states: {
        hover: {
          color: '#f97316',
        },
        select: {
          style: {
            color: '#f97316',
            fontWeight: 'normal',
          },
        },
      },
    },
    inputBoxBorderColor: 'none',
    inputBoxWidth: 80,
    inputStyle: {
      color: '#6b7280',
      fontSize: '14px',
    },
    labelStyle: {
      // styles the arrow
      color: '#f97316',
      fontWeight: 'bold',
      fontSize: '16px',
      transform: 'translate(84px, 0px)',
    },
  },
}

export {
  BarGraph,
  StackedAreaGraph,
  OpenRatesOverTime,
  UpliftGraph,
  graphConfig,
  Highcharts,
}
