import { useEffect } from 'react'
import { Form } from 'react-final-form'

import Badge from 'common/components/Badge'
import { DropdownType } from 'common/components/dynamicFields/interfaces'
import { useMoveIntercomButton } from 'common/components/intercom/useMoveIntercomButton'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import useGetSplitCalculatorConfigurationQuery from 'features/unifiedFlow/api/queries/useGetSplitCalculatorConfigurationQuery'
import { setFormError } from 'features/unifiedFlow/components/templateSelection/configPanel/TemplateConfigPanel'
import useProjectOptions from 'features/unifiedFlow/components/templateSelection/setupExperiment/hooks/useProjectOptions'
import RightPanel from 'features/unifiedFlow/contentPage/components/layout/RightPanel'
import { useSelectedElement } from 'features/unifiedFlow/contentPage/hooks'
import {
  hideRightPanel,
  showRightPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import ExperimentSettingsFormContent from './ExperimentSettingsFormContent'

const ExperimentSettings = () => {
  const dispatch = useAppDispatch()
  useMoveIntercomButton()
  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )

  const { data: selectedElement } = useSelectedElement()
  const optimizationContentType = selectedElement?.optimization_content_type

  const projectOptions = useProjectOptions({ optimizationContentType })

  const optimizationSettings =
    selectedElement && isComponentElement(selectedElement)
      ? selectedElement?.optimization_settings?.configuration
      : undefined

  const selectedProjectId = optimizationSettings?.project_id

  const { data: projectSplitCalculatorConfiguration, isLoading } =
    useGetSplitCalculatorConfigurationQuery({
      projectId: selectedProjectId,
    })

  const splitCalculatorConfiguration =
    projectSplitCalculatorConfiguration?.splitCalculation

  const selectionMetricConfiguration =
    splitCalculatorConfiguration?.fields.find(
      (field) => field.name === 'selection_metric'
    ) as DropdownType

  const initialProject = projectOptions?.find(
    (project) => project.value === selectedProjectId
  )

  useEffect(() => {
    dispatch(showRightPanel('auto'))
  }, [dispatch])

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else {
      return 'data'
    }
  }

  const state = getState()

  return (
    <>
      {optimizationSettings && (
        <RightPanel
          scrollableParentId="main-content-page"
          onOutsideClick={() => dispatch(hideRightPanel())}
          visibility={rightPanelVisibility}
          className="z-10"
        >
          {
            {
              loading: <Spinner isFullPage />,
              data: (
                <div className="flex flex-col h-full text-coolGray-800">
                  <div className="flex items-center justify-between px-6 pt-6">
                    <div className="font-medium text-xl">
                      Experiment settings
                    </div>
                    <Badge text="Optimize" variant="optimize" size="normal" />
                  </div>
                  <div className="font-medium text-coolGray-500 mt-1 px-6">
                    Modify your experiment details here
                  </div>
                  <Form
                    initialValues={{
                      project: initialProject,
                      experimentName: optimizationSettings.campaign_name,
                      sendDate: optimizationSettings.iso_send_date,
                      controlSubjectLine: optimizationSettings.own_subject_line,
                      listSize:
                        optimizationSettings.split_calculation.list_size,
                      splitNumber:
                        optimizationSettings.split_calculation.configuration
                          ?.split_number,
                      selectionMetric:
                        selectionMetricConfiguration?.options.find(
                          (option) =>
                            option.value ===
                            optimizationSettings.split_calculation
                              .selection_metric
                        ),
                      openRate:
                        optimizationSettings.split_calculation
                          .baseline_open_rate,
                      clickRate:
                        optimizationSettings.split_calculation
                          .baseline_click_rate,
                    }}
                    mutators={{
                      setError: setFormError,
                    }}
                    onSubmit={() => {}}
                    keepDirtyOnReinitialize
                    render={({ handleSubmit }) => (
                      <form
                        id="workflow-edit-form"
                        onSubmit={handleSubmit}
                        className="flex flex-col h-full overflow-hidden"
                      >
                        <ExperimentSettingsFormContent
                          optimizationContentType={optimizationContentType}
                          optimizationSettings={optimizationSettings}
                          projectSplitCalculatorConfiguration={
                            projectSplitCalculatorConfiguration
                          }
                        />
                      </form>
                    )}
                  />
                </div>
              ),
            }[state]
          }
        </RightPanel>
      )}
    </>
  )
}

export default ExperimentSettings
