import { useCallback, useEffect, useState } from 'react'
import { components, GroupBase, OptionProps } from 'react-select'
import cx from 'classnames'

import useGetNodesQuery from 'common/api/queries/useGetNodesQuery'
import Autocomplete from 'common/components/autcomplete'
import HighlightText from 'common/components/HighlightText'
import { buildBreadcrumbs } from 'common/components/nodes/Breadcrumbs'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { useAppDispatch } from 'common/hooks/redux'
import useDebounce from 'common/hooks/useDebounce'
import {
  CascadeFlow as CascadeFlowIcon,
  MessageBubble as MessageBubbleIcon,
} from 'common/icons'
import { NodeFilterOption as FilterOption } from 'common/interfaces/nodes'
import useGetPhrasesQuery from 'features/content/api/queries/useGetPhrasesQuery'

import { phraseHighlighted } from '../../store/nodesSlice'

import Filter from './Filter'
import {
  nodesAutocompleteCustomStyles,
  selectCustomStyles,
} from './FilterStyles'

const findOptions = [
  { label: 'Find', value: 'find' },
  { label: 'Nodes', value: 'nodes' },
  { label: 'Phrases', value: 'phrases' },
]

type Props = {
  updateSelectedNodeId: (nodeId?: string) => void
}

interface NodesFilterOption extends FilterOption {
  type: 'node' | 'phrase'
}

const MINIMUM_SEARCH_LENGTH = 3

const getCanSearch = (searchValue: string | undefined) =>
  searchValue && searchValue.length >= MINIMUM_SEARCH_LENGTH

const containsSearchValue = (text: string, searchValue?: string) => {
  if (!searchValue) {
    return false
  }

  return text
    .replaceAll(' ', '')
    .toLowerCase()
    .includes(searchValue.replaceAll(' ', '').toLowerCase())
}

export const Option = <T,>({
  isSelected,
  children,
  data,
  ...rest
}: OptionProps<T, false, GroupBase<T>>) => {
  // @ts-ignore TODO: fix this autocomplete component should be generic
  const { type, path, highlightedLabel } = data as FilterOption
  return (
    <components.Option isSelected={isSelected} data={data} {...rest}>
      <div
        className="flex items-center px-4 py-2"
        role="option"
        aria-selected={isSelected}
      >
        <div className="mr-5">
          {type === 'node' ? <CascadeFlowIcon /> : <MessageBubbleIcon />}
        </div>
        <div>
          <div className="font-medium text-coolGray-800 mb-1">
            {highlightedLabel}
          </div>
          <div className="font-normal text-coolGray-500">{path}</div>
        </div>
      </div>
    </components.Option>
  )
}

const NodesFilter = ({ updateSelectedNodeId }: Props) => {
  const [findOption, setFindOption] = useState<SelectValue>(findOptions[0])
  const [searchValue, setSearchValue] = useState<string | undefined>('')
  const [options, setOptions] = useState<NodesFilterOption[]>([])
  const canSearch = getCanSearch(searchValue)

  const { data: nodes } = useGetNodesQuery()
  const { data: phrases } = useGetPhrasesQuery()

  const dispatch = useAppDispatch()

  const getOptions = useCallback(() => {
    const newOptions: NodesFilterOption[] = []

    if (canSearch) {
      if (findOption.value === 'nodes' || findOption.value === 'find') {
        nodes?.nodes.forEach((node) => {
          if (!containsSearchValue(node.name, searchValue)) {
            return
          }
          const { breadcrumbsString } = buildBreadcrumbs(nodes.nodes, node.id)

          newOptions.push({
            label: node.name,
            highlightedLabel: (
              <HighlightText text={node.name} searchValue={searchValue} />
            ),
            value: node.id,
            path: breadcrumbsString
              .substring(0, breadcrumbsString.lastIndexOf('/'))
              .trim(),
            type: 'node',
          })
        })
      }

      if (findOption.value === 'phrases' || findOption.value === 'find') {
        phrases?.forEach((phrase) => {
          if (!containsSearchValue(phrase.phrase, searchValue)) {
            return
          }
          const { breadcrumbsString } = buildBreadcrumbs(
            nodes?.nodes || [],
            String(phrase.nodeId)
          )
          newOptions.push({
            label: phrase.phrase,
            highlightedLabel: (
              <HighlightText text={phrase.phrase} searchValue={searchValue} />
            ),
            value: String(phrase.phraseId),
            path: breadcrumbsString,
            type: 'phrase',
          })
        })
      }
    }

    newOptions.sort((a, b) => a.label.localeCompare(b.label))
    setOptions(newOptions)
  }, [canSearch, findOption.value, nodes?.nodes, phrases, searchValue])

  useEffect(() => {
    if (canSearch) {
      getOptions()
    }
  }, [canSearch, findOption.value, getOptions])

  const debouncedGetOptions = useDebounce(getOptions, 500)

  const onSearch = (value: string) => {
    setSearchValue(value)
    debouncedGetOptions()
  }

  return (
    <Filter>
      <SingleSelect
        options={findOptions}
        value={findOption.value}
        customStyles={selectCustomStyles}
        onChange={(newOption) =>
          setFindOption(
            findOptions.find((option) => option.value === newOption?.value) ||
              findOptions[0]
          )
        }
      />
      <Autocomplete
        className={cx('focus-within:w-106 transition-width', {
          'w-60': !searchValue,
          'w-106': searchValue,
        })}
        customStyles={nodesAutocompleteCustomStyles({
          availableOptions: searchValue !== undefined && searchValue.length > 0,
        })}
        placeholder="Word or phrase"
        showSearchIcon={false}
        data-cy="nodes-phrases-searchbox"
        options={canSearch && options ? options : []}
        value={
          searchValue ? { value: searchValue, label: searchValue } : undefined
        }
        defaultInputValue={searchValue}
        onSearch={onSearch}
        backspaceRemovesValue={true}
        components={{ Option }}
        onChange={(option) => {
          if (!option) {
            updateSelectedNodeId(undefined)
            dispatch(phraseHighlighted(undefined))
            return
          }
          const { value, type } = option as NodesFilterOption

          if (type === 'node') {
            updateSelectedNodeId(value)
          } else if (type === 'phrase') {
            const phrase = phrases?.find(
              (phrase) => phrase.phraseId === Number(value)
            )

            updateSelectedNodeId(
              phrase?.nodeId ? String(phrase.nodeId) : undefined
            )
            dispatch(phraseHighlighted(phrase?.phraseId))
          }
        }}
      />
    </Filter>
  )
}

export default NodesFilter
