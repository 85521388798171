type Props = {
  children?: React.ReactNode
}

const TemplatesConfigPanelFooter = ({ children }: Props) => {
  return (
    <div className="mt-auto bg-white border-coolGray-300 border-b-1 flex overflow-x-hidden">
      {children}
    </div>
  )
}

export default TemplatesConfigPanelFooter
