import { forwardRef } from 'react'
import {
  components,
  ControlProps,
  GroupBase,
  InputProps,
  OptionProps,
  SingleValue,
  SingleValueProps,
  StylesConfig,
} from 'react-select'
import cx from 'classnames'

import { SmallTick } from 'common/icons'

import type { BaseProps, SelectRef, SelectValue } from '../BaseSelect'
import BaseSelect from '../BaseSelect'

type Props<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<BaseProps<Option, IsMulti, Group>, 'value'> & {
  value?: string
  customStyles?: StylesConfig<SelectValue, false>
  'data-testid'?: string
  'data-cy'?: string
  title: string
}

export type SingleSelectRef = SelectRef<
  SelectValue,
  false,
  GroupBase<SelectValue>
>

const Value = <T,>({
  innerProps,
  ...props
}: SingleValueProps<T, false, GroupBase<T>>) => {
  return (
    <>
      <div
        className={cx({
          'text-coolGray-400': props.isDisabled,
        })}
      >
        {(props.selectProps as any).title}
        <span
          className={cx('mx-1', {
            'text-coolGray-400': props.isDisabled,
            'text-maroon-300': !props.isDisabled,
          })}
        >
          :
        </span>
      </div>
      <components.SingleValue
        {...props}
        className={cx('mr-1 ', {
          'text-maroon-600': !props.isDisabled,
          'text-coolGray-400': props.isDisabled,
        })}
        innerProps={{
          ...innerProps,
          // @ts-ignore
          'data-testid': 'selected-value-label',
        }}
      />
    </>
  )
}
const Input = <T,>(props: InputProps<T, false, GroupBase<T>>) => (
  <components.Input {...{ 'data-lpignore': true }} {...props} />
)

export const Control = <T,>({
  innerProps,
  ...props
}: ControlProps<T, false>) => (
  <components.Control
    {...props}
    className={cx('bg-maroon-50 inline-flex w-auto group', {
      'border-maroon-500 hover:border-maroon-600': !props.isDisabled,
      'border-coolGray-300': props.isDisabled,
    })}
    innerProps={{
      ...innerProps,
      // @ts-ignore
      'data-testid': props.selectProps['data-testid'],
      'data-cy': props.selectProps['data-cy'],
    }}
  />
)

export const Option = <T,>({
  isSelected,
  children,
  ...rest
}: OptionProps<T, false, GroupBase<T>>) => {
  return (
    <components.Option isSelected={isSelected} {...rest}>
      <div
        className={cx(
          `mx-3 px-3 py-2 flex justify-between items-center hover:bg-gray-100 whitespace-pre-line 
          cursor-pointer`,
          {
            'text-coolGray-800': !isSelected,
            'text-maroon-500': isSelected,
          }
        )}
      >
        <div className="mr-4" role="option" aria-selected={isSelected}>
          {children}
        </div>
        <div className="w-6 h-6">
          <SmallTick
            state="selected"
            className={cx('w-6 h-6 text-selected-icon', {
              hidden: !isSelected,
            })}
          />
        </div>
      </div>
    </components.Option>
  )
}

const SingleSelectTag = forwardRef<
  SingleSelectRef,
  Props<SelectValue, false, GroupBase<SelectValue>>
>(
  (
    { options, value, menuPlacement = 'auto', isSearchable = false, ...rest },
    ref
  ) => {
    let selectedOption: SelectValue | null = null
    options?.forEach((option) => {
      if ('value' in option && option.value === value) {
        selectedOption = option
      } else if ('options' in option) {
        selectedOption =
          option.options.find((groupOption) => groupOption.value === value) ??
          selectedOption
      }
    })

    return (
      <BaseSelect<SelectValue, false>
        ref={ref}
        value={selectedOption}
        options={options}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        isSearchable={isSearchable}
        classNames={{
          valueContainer: () => 'flex flex-nowrap text-xs font-medium pr-0',
          dropdownIndicator: ({ isDisabled }) =>
            cx(
              'pl-0',
              isDisabled
                ? 'text-base-300'
                : 'text-maroon-300 group-hover:text-maroon-600'
            ),
        }}
        components={{
          Option,
          SingleValue: Value,
          Input,
          Control,
          IndicatorSeparator: null,
        }}
        menuPortalTarget={document.body}
        {...rest}
      />
    )
  }
)

export type { SingleValue, SelectValue }
export default SingleSelectTag
