import { parseNode, parseReplacer } from 'common/components/topics/helpers'
import { Fragment, Replacer, Topic } from 'common/components/topics/interfaces'
import { Node } from 'common/components/topics/interfaces'

import { ContentGenerationPayload } from './interfaces'

export const parseTopics = (
  nodes: Node[] = [],
  replacers: Replacer[] = []
): Topic[] => [...nodes.map(parseNode), ...replacers.map(parseReplacer)]

export const getContentGenerationPayload = ({
  topics,
  fragments,
  descriptionFragments,
  numSplits,
}: {
  topics: Topic[]
  fragments: Fragment[]
  descriptionFragments: Fragment[]
  numSplits?: number
}) => {
  const payload: ContentGenerationPayload = {
    contentUpdateId: 3, // TODO: Remove hardcoding when BE is ready
    numVariants: numSplits || 0,
    nodes: [],
    replacers: [],
    fragments,
    descriptionFragments,
  }
  topics.forEach((topic) => {
    if (topic.type === 'topic') {
      payload.nodes.push({
        nodeId: topic.original.nodeId,
        node: topic.original.node,
        nodeName: topic.name,
        displayName: topic.name,
        isConstant: topic.isConstant,
      })
    } else {
      payload.replacers.push({
        replacerId: topic.original.replacerId,
        replacer: topic.original.replacer,
        displayName: topic.original.displayName,
        isConstant: topic.isConstant,

        replacerDefinition: {
          ...topic.original.replacerDefinition,
          form_definition: topic.configuration,
        },
        value: topic.name,
      })
    }
  })

  return payload
}
