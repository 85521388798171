import { Fragment } from 'react'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { isSameDate } from 'common/helpers/date'
import { ChevronRight } from 'common/icons'
import CustomerAttributesDateRangePicker from 'features/personalization/components/report/CustomerAttributesDateRangePicker'
import CustomerAttributesSlider from 'features/personalization/components/report/CustomerAttributesSlider'

import CheckboxComponent from '../checkbox/Checkbox'

import { TreeNode, TypedEntryState } from './Catalog'
import MenuItem from './MenuItem'

type Props = {
  items: TreeNode | string[]
  checkedItems?: TreeNode | string[]
  selectedPath: string[]
  onClickCheckbox: ((item: string, depth: number) => void) | undefined
  onSelect: (item: string, depth: number) => void
  depth: number
  isSearching: boolean
  onDateChange?: ({ key, value }: { key: string; value: string[] }) => void
  dateRangePerKey: TypedEntryState<string>
  onSliderChange?: ({ key, value }: { key: string; value: number[] }) => void
  sliderPerKey: TypedEntryState<number>
}

const checkDataType = (value: TreeNode): string | undefined =>
  value && typeof value.type === 'string' ? value.type : undefined

const RecursiveSubMenu = ({
  items,
  checkedItems,
  selectedPath,
  onSelect,
  onClickCheckbox,
  depth,
  isSearching,
  onDateChange,
  dateRangePerKey,
  onSliderChange,
  sliderPerKey,
}: Props) => {
  const dataType = items && checkDataType(items[selectedPath[depth]])

  if (
    (depth !== 0 && selectedPath.length < depth) ||
    (Array.isArray(items) && items.length === 0)
  ) {
    return null
  }

  const isIndeterminate = ({
    item,
    datePicker = [],
    slider = [],
    dataType,
  }: {
    item: string
    datePicker: string[] | undefined
    slider: number[] | undefined
    dataType: string | undefined
  }) => {
    if (dataType === 'date') {
      const [startDate, endDate] = datePicker
      const { startDate: defaultStartDate, endDate: defaultEndDate } =
        items[item]
      return !(
        isSameDate(startDate, defaultStartDate, 'day') &&
        isSameDate(endDate, defaultEndDate, 'day')
      )
    }
    if (dataType === 'number') {
      const {
        min: defaultMinNumber,
        max: defaultMaxNumber,
      }: { min: number; max: number } = items[item]

      const [startValue, endValue] = slider

      return !(startValue === defaultMinNumber && endValue === defaultMaxNumber)
    }
    return false
  }

  const isChecked = (item: string) => {
    if (!checkedItems) {
      return false
    }
    return Array.isArray(checkedItems)
      ? checkedItems.some((current) => current === item)
      : checkedItems[item] !== undefined
  }

  return (
    <>
      <div
        className={cx('overflow-y-auto overflow-x-hidden', {
          'max-h-150 w-100': !isSearching,
          'w-full': isSearching,
        })}
      >
        {Array.isArray(items) ? (
          <>
            {items.map((item, index) => (
              <MenuItem
                key={index}
                className="hover:bg-blueGray-200 hover:font-medium cursor-pointer truncate text-left"
              >
                <div className="flex items-center">
                  {onClickCheckbox && (
                    <CheckboxComponent
                      isChecked={isChecked(item)}
                      onChange={() => {
                        onClickCheckbox(item, depth)
                      }}
                    />
                  )}
                  <span className="truncate">{item}</span>
                </div>
              </MenuItem>
            ))}
          </>
        ) : (
          Reflect.ownKeys(items).map((item) => {
            if (typeof item === 'symbol') {
              return undefined
            }
            const parentItem = items[item]
            const parentSelectedItem = checkedItems?.[item]
            const datePicker = dateRangePerKey[item]
            const slider = sliderPerKey[item]
            return (
              <Fragment key={item}>
                <MenuItem
                  className={cx(
                    'hover:bg-blueGray-200 cursor-pointer hover:font-medium w-full break-words',
                    {
                      'bg-blueGray-200 font-medium':
                        selectedPath[depth] === item,
                    }
                  )}
                  onSelect={() => {
                    onSelect(item, depth)
                  }}
                >
                  <div className="flex items-center">
                    {onClickCheckbox && (
                      <CheckboxComponent
                        isChecked={isChecked(item)}
                        indeterminate={
                          (isChecked(item) &&
                            isIndeterminate({
                              datePicker: datePicker?.value,
                              slider: slider?.value,
                              item,
                              dataType:
                                'type' in parentItem
                                  ? (parentItem.type as string) // FIXME remove casting
                                  : undefined,
                            })) ||
                          (Array.isArray(parentItem) &&
                            Array.isArray(parentSelectedItem) &&
                            parentItem.length !== parentSelectedItem.length)
                        }
                        onChange={() => {
                          onClickCheckbox(item, depth)
                        }}
                      />
                    )}
                    <div className="flex justify-between gap-4 flex-1 min-w-0">
                      <div className="truncate text-left">{item}</div>
                    </div>
                    <ChevronRight
                      className={cx({
                        hidden: isEmpty(items[item]),
                      })}
                      size={4}
                    />
                  </div>
                </MenuItem>
              </Fragment>
            )
          })
        )}
      </div>
      {dataType === 'date' ? (
        <CustomerAttributesDateRangePicker
          key={selectedPath[depth]}
          items={items}
          depth={depth}
          selectedPath={selectedPath}
          onValueChange={({ value }) => {
            return onDateChange?.({
              key: selectedPath[depth],
              value,
            })
          }}
          typedEntryState={dateRangePerKey}
        />
      ) : dataType === 'number' ? (
        <CustomerAttributesSlider
          key={selectedPath[depth]}
          items={items}
          selectedPath={selectedPath}
          depth={depth}
          onValueChange={({ value }) => {
            onSliderChange?.({
              key: selectedPath[depth],
              value,
            })
          }}
          typedEntryState={sliderPerKey}
        />
      ) : (
        <RecursiveSubMenu
          items={items[selectedPath[depth]]}
          onSelect={onSelect}
          checkedItems={checkedItems?.[selectedPath[depth]]}
          onClickCheckbox={onClickCheckbox}
          selectedPath={selectedPath}
          depth={depth + 1}
          isSearching={isSearching}
          dateRangePerKey={dateRangePerKey}
          sliderPerKey={sliderPerKey}
        />
      )}
    </>
  )
}

export default RecursiveSubMenu
