type Props = {
  type: 'experiment' | 'project'
}

const titles = {
  experiment: 'No experiment selected',
  project: 'No project selected',
}

const texts = {
  experiment: 'Select an experiment to view all the nitty, gritty details.',
  project: 'Select a project to view all the nitty, gritty details.',
}

const BlankScreen: React.FC<Props> = ({ type }) => {
  return (
    <div className="h-full bg-white text-coolGray-800 flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <div className="w-106 py-12">
          <div className="text-4xl font-bold">{titles[type]}</div>
          <div className="mt-6 text-base">{texts[type]}</div>
        </div>
      </div>
    </div>
  )
}

export default BlankScreen
