import RouterPromptStay from 'app/router/RouterPromptStay'

import Overlay from 'common/components/Overlay'
import Topics from 'common/components/topics'
import { Topic } from 'common/components/topics/interfaces'

import { PageState } from './StartHere'

type Props = {
  topics: Topic[]
  pageState: PageState
  setSelectedTopics: (topics: Topic[]) => void
}

const GeneratedTopics = ({ topics, pageState, setSelectedTopics }: Props) => {
  return (
    <>
      {(pageState === 'showContent' ||
        pageState === 'showGeneratedContent' ||
        pageState === 'formChanged' ||
        pageState === 'topicsChanged') && (
        <div className="relative">
          {pageState === 'formChanged' && (
            <Overlay type="local" className="bg-opacity-90" bgColor="bg-white">
              <div className="flex flex-col justify-center items-center mb-8">
                <div className="font-bold text-base text-coolGray-500 mb-2">
                  You made some changes, please refresh your topics
                </div>
                <div className="text-sm text-coolGray-400">
                  If you refresh the content you've generated will be lost.
                </div>
              </div>
            </Overlay>
          )}
          <div className="my-6 border border-dashed border-coolGray-300"></div>
          <Topics
            topics={topics}
            onChange={(newTopics) => setSelectedTopics(newTopics)}
          />
        </div>
      )}
      <RouterPromptStay
        shouldShow={pageState === 'topicsChanged'}
        onOK={() => Promise.resolve(true)}
      />
    </>
  )
}

export default GeneratedTopics
