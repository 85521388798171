import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { errorToast } from 'common/components/toastNotification'

import { fetchCurrenciesList } from '../api'
import { currencyKeys } from '../queryKeys/currencyKeys'

const useCurrencyListQuery = () => {
  return useQuery(
    currencyKeys.currencysList(),
    ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      const promise = fetchCurrenciesList()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      return promise
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: () => errorToast('Failed to load currency options'),
    }
  )
}

export default useCurrencyListQuery
