import { useQuery } from '@tanstack/react-query'

import { getAvailableIntegrations } from '../api'
import { integrationsKeys } from '../queryKeys'

const useGetAvailableIntegrationsQuery = () => {
  const getIntegrationsQuery = useQuery(
    integrationsKeys.list(),
    getAvailableIntegrations
  )
  return getIntegrationsQuery
}

export default useGetAvailableIntegrationsQuery
