/* eslint-disable max-lines */
export const START_NEW_CAMPAIGN = 'START_NEW_CAMPAIGN'

export const SET_PROJECT_ID = 'SET_PROJECT_ID'
export const SET_DISTRIBUTION_CHANNEL = 'SET_DISTRIBUTION_CHANNEL'

export const IS_LOADING = 'IS_LOADING'
export const IS_WAITING = 'IS_WAITING'

export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE'

export const CAMPAIGNS_LOADED = 'CAMPAIGNS_LOADED'
export const CAMPAIGNS_FAILED_LOADING = 'CAMPAIGNS_FAILED_LOADING'

export const REPORT_LOADED = 'REPORT_LOADED'
export const REPORT_RESET = 'REPORT_RESET'
export const REPORT_FAILED_LOADING = 'REPORT_FAILED_LOADING'

export const CAMPAIGN_STATS_LOADED = 'CAMPAIGN_STATS_LOADED'
export const CAMPAIGN_STATS_FAILED_LOADING = 'CAMPAIGN_STATS_FAILED_LOADING'

export const CAMPAIGN_DELETE_SUCCESS = 'CAMPAIGN_DELETE_SUCCESS'
export const CAMPAIGN_DELETE_ERROR = 'CAMPAIGN_DELETE_ERROR'

export const MOVE_CAMPAIGNS_IS_LOADING = 'MOVE_CAMPAIGNS_IS_LOADING'
export const MOVE_CAMPAIGNS_SUCCESS = 'MOVE_CAMPAIGNS_SUCCESS'
export const MOVE_CAMPAIGNS_FAILURE = 'MOVE_CAMPAIGNS_FAILURE'

export const FETCH_SUBJECT_LINES_SUCCESS = 'FETCH_SUBJECT_LINES_SUCCESS'
export const FETCH_SUBJECT_LINES_ERROR = 'FETCH_SUBJECT_LINES_ERROR'
export const CAMPAIGN_IS_LOADING = 'CAMPAIGN_IS_LOADING'
export const SEND_DEFINITION_LIST_IS_LOADING = 'SEND_DEFINITION_LIST_IS_LOADING'
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS'
export const GENERATE_REPORT_ERROR = 'GENERATE_REPORT_ERROR'
export const REPORT_IS_LOADING = 'REPORT_IS_LOADING'
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT'

export const CONTROL_SUBJECT_LINE_LOADING = 'CONTROL_SUBJECT_LINE_LOADING'
export const CONTROL_SUBJECT_LINE_UPDATED = 'CONTROL_SUBJECT_LINE_UPDATED'
export const CONTROL_SUBJECT_LINE_FAILED = 'CONTROL_SUBJECT_LINE_FAILED'

export const CSV_IS_LOADING = 'CSV_IS_LOADING'
export const EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS'
export const EXPORT_CSV_FAILURE = 'EXPORT_CSV_FAILURE'

export const SUMMARY_DETAILS_LOADED = 'SUMMARY_DETAILS_LOADED'
export const SUMMARY_DETAILS_FAILED_LOADING = 'SUMMARY_DETAILS_FAILED_LOADING'

export const RESULTS_DETAILS_IS_LOADING = 'RESULTS_DETAILS_IS_LOADING'
export const RESULTS_DETAILS_LOADED = 'RESULTS_DETAILS_LOADED'
export const RESULTS_DETAILS_FAILED_LOADING = 'RESULTS_DETAILS_FAILED_LOADING'

export const UPDATE_RESULTS_VARIANTS_STATUS = 'UPDATE_RESULTS_VARIANTS_STATUS'

export const UPDATE_SUMMARY = 'UPDATE_SUMMARY'

export const CAMPAIGN_DETAILS_LOADING = 'CAMPAIGN_DETAILS_LOADING'
export const CAMPAIGN_DETAILS_LOADED = 'CAMPAIGN_DETAILS_LOADED'
export const CAMPAIGN_DETAILS_FAILED_LOADING = 'CAMPAIGN_DETAILS_FAILED_LOADING'

export const SET_CAMPAIGN_TOKEN = 'SET_CAMPAIGN_TOKEN'

export const LATEST_VARIANTS_LOADED = 'LATEST_VARIANTS_LOADED'

export const FETCH_NFM_TWEAK_LIMIT_SUCCESS = 'FETCH_NFM_TWEAK_LIMIT_SUCCESS'
export const FETCH_NFM_TWEAK_LIMIT_FAIL = 'FETCH_NFM_TWEAK_LIMIT_FAIL'

export const FETCH_ACCOUNT_TEAMS_LIST_SUCCESS =
  'FETCH_ACCOUNT_TEAMS_LIST_SUCCESS'
export const FETCH_ACCOUNT_TEAMS_LIST_FAIL = 'FETCH_ACCOUNT_TEAMS_LIST_FAIL'

export const SEND_TEAMS_LIST_APPROVAL_EMAIL_SUCCESS =
  'SEND_TEAMS_LIST_APPROVAL_EMAIL_SUCCESS'
export const SEND_TEAMS_LIST_APPROVAL_EMAIL_FAIL =
  'SEND_TEAMS_LIST_APPROVAL_EMAIL_FAIL'

export const CLICK_CLOSE_BOOM_MODAL = 'CLICK_CLOSE_BOOM_MODAL'

export const UNSCHEDULED_CAMPAIGNS_LOADED = 'UNSCHEDULED_CAMPAIGNS_LOADED'
export const UNSCHEDULED_CAMPAIGNS_FAILED_LOADING =
  'UNSCHEDULED_CAMPAIGNS_FAILED_LOADING'

export const CAMPAIGN_CANCELLED_SUCCESSFULLY = 'CAMPAIGN_CANCELLED_SUCCESSFULLY'
export const CAMPAIGN_CANCEL_FAILED = 'CAMPAIGN_CANCEL_FAILED'

export const CHECK_BEFORE_CANCEL_SUCCESS = 'CHECK_BEFORE_CANCEL_SUCCESS'
export const CHECK_BEFORE_CANCEL_FAILED = 'CHECK_BEFORE_CANCEL_FAILED'
export const RESET_ACOUSTIC_DATA = 'RESET_ACOUSTIC_DATA '

export const SET_CURRENT_CAMPAIGN_ID = 'SET_CURRENT_CAMPAIGN_ID'
export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID'
export const SET_SPLIT_CAMPAIGN_ID = 'SET_SPLIT_CAMPAIGN_ID'
export const SET_FINAL_CAMPAIGN_ID = 'SET_FINAL_CAMPAIGN_ID'
export const SET_TIME_BEFORE_FINAL_DATE = 'SET_TIME_BEFORE_FINAL_DATE'
export const SET_SCHEDULE_DATE = 'SET_SCHEDULE_DATE'
export const SET_SCHEDULE_TIME = 'SET_SCHEDULE_TIME'
export const SET_SUBJECTS_LINE = 'SET_SUBJECTS_LINE'
export const SET_TYPE_INTEGRATION = 'SET_TYPE_INTEGRATION'
export const RESET_REDUX_FORM = 'RESET_REDUX_FORM'

export const DISPLAY_BANNER = 'DISPLAY_BANNER'
export const RESET_BANNER = 'RESET_BANNER'

export const RESET_MODAL = 'RESET_MODAL'

export const USER_INITIATED_EMAILS_LOADED = 'USER_INITIATED_EMAILS_LOADED'
export const USER_INITIATED_EMAILS_FETCH_FAILED =
  'USER_INITIATED_EMAILS_FETCH_FAILED'

export const SCHEDULE_SFMC_CAMPAIGN_SUCCESS = 'SCHEDULE_SFMC_CAMPAIGN_SUCCESS'
export const SCHEDULE_SFMC_CAMPAIGN_FAIL = 'SCHEDULE_SFMC_CAMPAIGN_FAIL'

export const UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_SUCCESS =
  'UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_SUCCESS'
export const UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_FAIL =
  'UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_FAIL'

export const SFMC_LOGIN_SUCCESS = 'SFMC_LOGIN_SUCCESS'

export const RENAME_CAMPAIGN_SUCCESS = 'RENAME_CAMPAIGN_SUCCESS'
export const RENAME_CAMPAIGN_FAIL = 'RENAME_CAMPAIGN_FAIL'
export const IS_SAVING = 'IS_SAVING'

export const AMP_SCRIPT_LOADING = 'AMP_SCRIPT_LOADING'
export const AMP_SCRIPT_LOAD_SUCCESS = 'AMP_SCRIPT_LOAD_SUCCESS'
export const AMP_SCRIPT_LOAD_ERROR = 'AMP_SCRIPT_LOAD_ERROR'
// Background Status
export const SUBMITTED_FOR_SCHEDULING = 'SUBMITTED_FOR_SCHEDULING'
export const FETCH_CAMPAIGN_BG_STATUS_SUCCESS =
  'FETCH_CAMPAIGN_BG_STATUS_SUCCESS'
export const FETCH_CAMPAIGN_BG_STATUS_FAIL = 'FETCH_CAMPAIGN_BG_STATUS_FAIL'
// Acoustic campaign
export const SCHEDULE_ACOUSTIC_CAMPAIGN_SUCCESS =
  'SCHEDULE_ACOUSTIC_CAMPAIGN_SUCCESS'
export const SCHEDULE_ACOUSTIC_CAMPAIGN_FAIL = 'SCHEDULE_ACOUSTIC_CAMPAIGN_FAIL'
// New workflow
export const FETCH_DISTRIBUTION_CHANNEL_LOADED =
  'FETCH_DISTRIBUTION_CHANNEL_LOADED'
export const FETCH_DISTRIBUTION_CHANNEL_LOADING =
  'FETCH_DISTRIBUTION_CHANNEL_LOADING'

export const FETCH_PROJECT_LIST_LOADED = 'FETCH_PROJECT_LIST_LOADED'
export const FETCH_PROJECT_LIST_LOADING = 'FETCH_PROJECT_LIST_LOADING'

export const LANGUAGE_APPROVAL_CAMPAIGN_LOADING_SUCCESS =
  'LANGUAGE_APPROVAL_CAMPAIGN_LOADING_SUCCESS'
export const LANGUAGE_APPROVAL_CAMPAIGN_LOADING_ERROR =
  'LANGUAGE_APPROVAL_CAMPAIGN_LOADING_ERROR'
export const LANGUAGE_APPROVAL_TWEAK_LINE_LOADING =
  'LANGUAGE_APPROVAL_TWEAK_LINE_LOADING'
export const LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS =
  'LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS'
export const LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS_FEEDBACK_ONLY =
  'LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS_FEEDBACK_ONLY'
export const LANGUAGE_APPROVAL_TWEAK_LINE_ERROR =
  'LANGUAGE_APPROVAL_TWEAK_LINE_ERROR'
export const LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS =
  'LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS'
export const LANGUAGE_APPROVAL_REJECT_LINE = 'LANGUAGE_APPROVAL_REJECT_LINE'
export const LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS_FEEDBACK_ONLY =
  'LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS_FEEDBACK_ONLY'
export const LANGUAGE_APPROVAL_REJECT_LINE_ERROR =
  'LANGUAGE_APPROVAL_REJECT_LINE_ERROR'
export const LANGUAGE_APPROVAL_OPEN_TWEAK_LINE =
  'LANGUAGE_APPROVAL_OPEN_TWEAK_LINE'
export const LANGUAGE_APPROVAL_CLOSE_TWEAK_LINE =
  'LANGUAGE_APPROVAL_CLOSE_TWEAK_LINE'
export const LANGUAGE_APPROVAL_SAVING = 'LANGUAGE_APPROVAL_SAVING'
export const LANGUAGE_APPROVAL_SAVING_SUCCESS =
  'LANGUAGE_APPROVAL_SAVING_SUCCESS'
export const LANGUAGE_APPROVAL_SAVING_ERROR = 'LANGUAGE_APPROVAL_SAVING_ERROR'
export const LANGUAGE_APPROVAL_CAMPAIGN_LOADING =
  'LANGUAGE_APPROVAL_CAMPAIGN_LOADING'

export const UPDATE_CAMPAIGN_DATA = 'UPDATE_CAMPAIGN_DATA'
export const UPDATE_CAMPAIGN_DATA_FAIL = 'UPDATE_CAMPAIGN_DATA_FAIL'

export const SET_WORKFLOW_NAVIGATION = 'SET_WORKFLOW_NAVIGATION'

export const GET_PROJECT_LIST_LOADED = 'GET_PROJECT_LIST_LOADED'
export const GET_PROJECT_LIST_LOADING = 'GET_PROJECT_LIST_LOADING'
// Language generation
/* ------- stepTwo action types starts------ */
export const STEP_TWO_QUESTIONS_LOADING = 'STEP_TWO_QUESTIONS_LOADING'
export const STEP_TWO_QUESTIONS_SUCCESS = 'STEP_TWO_QUESTIONS_SUCCESS'
export const STEP_TWO_QUESTIONS_ERROR = 'STEP_TWO_QUESTIONS_ERROR'

export const STEP_TWO_SUBMITTING = 'STEP_TWO_SUBMITTING'
export const STEP_TWO_SUBMIT_SUCCESS = 'STEP_TWO_SUBMIT_SUCCESS'
export const STEP_TWO_SUCCESS_ANIMATION = 'STEP_TWO_SUCCESS_ANIMATION'
export const STEP_TWO_SUBMIT_ERROR = 'STEP_TWO_SUBMIT_ERROR'
export const STEP_TWO_GENERATION_ERROR_ACKNOWLEDGED =
  'STEP_TWO_GENERATION_ERROR_ACKNOWLEDGED'

export const SAVE_CAMPAIGN_STEP2_SUCCESS = 'SAVE_CAMPAIGN_STEP2_SUCCESS'
export const SAVE_CAMPAIGN_STEP2_ERROR = 'SAVE_CAMPAIGN_STEP2_ERROR'

export const STEP_TWO_SET_DAYS_TO_GO = 'STEP_TWO_SET_DAYS_TO_GO'
export const STEP_TWO_SET_NEW_VALUES = 'STEP_TWO_SET_NEW_VALUES'
export const STEP_TWO_UPDATE_GENERATION_ANIMATION_STEP =
  'STEP_TWO_UPDATE_GENERATION_ANIMATION_STEP'
export const STEP_TWO_SET_ANIMATION_IMAGE = 'STEP_TWO_SET_ANIMATION_IMAGE'
export const STEP_TWO_SET_ANIMATION_PERCENTAGE =
  'STEP_TWO_SET_ANIMATION_PERCENTAGE'
export const STEP_TWO_UPDATE_REGENERATE_MODAL =
  'STEP_TWO_UPDATE_REGENERATE_MODAL'
export const STEP_TWO_UPDATE_VALIDATION_ERRORS =
  'STEP_TWO_UPDATE_VALIDATION_ERRORS'
export const STEP_TWO_EDITING = 'STEP_TWO_EDITING'

/* -------- campaign action type starts ----*/

export const CAMPAIGN_LOADING_SUCCESS_STEP_TWO =
  'CAMPAIGN_LOADING_SUCCESS_STEP_TWO'
export const CAMPAIGN_LOAD_ON_TOP = 'CAMPAIGN_LOAD_ON_TOP'
export const CAMPAIGN_LOADING = 'step_campaign_loading'
export const CAMPAIGN_LOADING_ERROR = 'step_campaign_loading_error'
export const UPLOAD_RESUlTS_FILE = 'UPLOAD_RESUlTS_FILE'
export const UPLOAD_RESUlTS_FILE_FAIL = 'UPLOAD_RESUlTS_FILE_FAIL'
export const DOWNLOAD_RESUlTS_FILE = 'DOWNLOAD_RESUlTS_FILE'
export const DOWNLOAD_RESUlTS_FILE_FAIL = 'DOWNLOAD_RESUlTS_FILE_FAIL'
export const UPLOAD_MANAGED_FILE = 'UPLOAD_MANAGED_FILE'
export const UPLOAD_MANAGED_FILE_FAIL = 'UPLOAD_MANAGED_FILE_FAIL'
export const UNLINK_MANAGED_FILE = 'UNLINK_MANAGED_FILE'
export const UNLINK_MANAGED_FILE_FAIL = 'UNLINK_MANAGED_FILE_FAIL'

/* -------- campaign action type ends ----*/

export const STEP_EDITING = 'step_editing'
export const STEP_EDITING_DONE = 'step_editing_done'
export const CURRENT_EDITING_STEP = 'current_editing_step'

/* ----------Movable Ink setup action types start---------------- */
export const FETCHING_MOVABLE_INK_API_KEYS = 'FETCHING_MOVABLE_INK_API_KEYS'
export const FETCHING_MOVABLE_INK_API_KEYS_SUCCESS =
  'FETCHING_MOVABLE_INK_API_KEYS_SUCCESS'
export const FETCHING_MOVABLE_INK_API_KEYS_ERROR =
  'FETCHING_MOVABLE_INK_API_KEYS_ERROR'

export const CLEARING_MOVABLE_INK_TEST_DATA = 'CLEARING_MOVABLE_INK_TEST_DATA'
export const CLEAR_MOVABLE_INK_TEST_DATA_SUCCESS =
  'CLEAR_MOVABLE_INK_TEST_DATA_SUCCESS'
export const CLEAR_MOVABLE_INK_TEST_DATA_ERROR =
  'CLEAR_MOVABLE_INK_TEST_DATA_ERROR'

export const UPDATING_MOVABLE_INK_INTEGRATION_DATA =
  'UPDATING_MOVABLE_INK_INTEGRATION_DATA'
export const UPDATE_MOVABLE_INK_INTEGRATION_DATA_SUCCESS =
  'UPDATE_MOVABLE_INK_INTEGRATION_DATA_SUCCESS'
export const UPDATE_MOVABLE_INK_INTEGRATION_DATA_ERROR =
  'UPDATE_MOVABLE_INK_INTEGRATION_DATA_ERROR'
/* ----------Movable Ink setup action types end---------------- */

/* ----------Cheetah action types start---------------- */
export const RETRIEVE_CHEETAH_CAMPAIGN = 'RETRIEVE_CHEETAH_CAMPAIGN'
export const RETRIEVE_CHEETAH_CAMPAIGN_SUCCESS =
  'RETRIEVE_CHEETAH_CAMPAIGN_SUCCESS'
export const RETRIEVE_CHEETAH_CAMPAIGN_ERROR = 'RETRIEVE_CHEETAH_CAMPAIGN_ERROR'
export const RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_SUCCESS =
  'RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_SUCCESS'
export const RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_ERROR =
  'RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_ERROR'
export const SCHEDULE_CHEETAH_CAMPAIGN_SUCCESS =
  'SCHEDULE_CHEETAH_CAMPAIGN_SUCCESS'
export const SCHEDULE_CHEETAH_CAMPAIGN_FAIL = 'SCHEDULE_CHEETAH_CAMPAIGN_FAIL'
export const RETRIEVE_CHEETAH_CAMPAIGN_TIMEZONE =
  'RETRIEVE_CHEETAH_CAMPAIGN_TIMEZONE'
export const RETRIEVE_CHEETAH_CAMPAIGN_RESULTS_SUCCESS =
  'RETRIEVE_CHEETAH_CAMPAIGN_RESULTS_SUCCESS'
export const RETRIEVE_TXT_CHEETAH_CAMPAIGN_SUCCESS =
  'RETRIEVE_TXT_CHEETAH_CAMPAIGN_SUCCESS'
/* ----------Cheetah action types end---------------- */

/* ----------Braze action types start---------------- */
export const FETCH_BRAZE_CAMPAIGNS_STARTED = 'FETCH_BRAZE_CAMPAIGNS_STARTED'
export const FETCH_BRAZE_CAMPAIGNS_SUCCESS = 'FETCH_BRAZE_CAMPAIGNS_SUCCESS'
export const FETCH_BRAZE_CAMPAIGNS_ERROR = 'FETCH_BRAZE_CAMPAIGNS_ERROR'
export const SCHEDULE_BRAZE_CAMPAIGNS_STARTED =
  'SCHEDULE_BRAZE_CAMPAIGNS_STARTED'
export const SCHEDULE_BRAZE_CAMPAIGNS_SUCCESS =
  'SCHEDULE_BRAZE_CAMPAIGNS_SUCCESS'
export const SCHEDULE_BRAZE_CAMPAIGNS_ERROR = 'SCHEDULE_BRAZE_CAMPAIGNS_ERROR'
export const CANCEL_BRAZE_CAMPAIGN_STARTED = 'CANCEL_BRAZE_CAMPAIGN_STARTED'
export const CANCEL_BRAZE_CAMPAIGN_SUCCESS = 'CANCEL_BRAZE_CAMPAIGN_SUCCESS'
export const CANCEL_BRAZE_CAMPAIGN_ERROR = 'CANCEL_BRAZE_CAMPAIGN_ERROR'
/* ----------Braze action types end---------------- */

/* ----------Mapp setup action types start---------------- */
export const FETCH_MAPP_MESSAGES_SUCCESS = 'FETCH_MAPP_MESSAGES_SUCCESS'
export const FETCH_MAPP_MESSAGES_FAIL = 'FETCH_MAPP_MESSAGES_FAIL'
export const UPDATE_MAPP_MESSAGES_SUCCESS = 'UPDATE_MAPP_MESSAGES_SUCCESS'
export const UPDATE_MAPP_MESSAGES_FAIL = 'UPDATE_MAPP_MESSAGES_FAIL'
/* ----------Mapp setup action types end---------------- */

export const STEP_TWO_UPDATE_NER_VALIDATION_MODAL =
  'STEP_TWO_UPDATE_NER_VALIDATION_MODAL'

/* -------------- Time zone setup ----------------------- */
export const FETCH_TIME_ZONE_STARTED = 'FETCH_TIME_ZONE_STARTED'
export const FETCH_TIME_ZONE_SUCCESS = 'FETCH_TIME_ZONE_SUCCESS'
export const FETCH_TIME_ZONE_ERROR = 'FETCH_TIME_ZONE_ERROR'
/* ----------Advanced visualisation action types start---------------- */
export const ADVANCED_VISUALISATIONS_RESET = 'ADVANCED_VISUALISATIONS_RESET'
export const ADVANCED_VISUALISATIONS_LOADING = 'ADVANCED_VISUALISATIONS_LOADING'
export const ADVANCED_VISUALISATIONS_SUCCESS = 'ADVANCED_VISUALISATIONS_SUCCESS'
export const ADVANCED_VISUALISATIONS_FAIL = 'ADVANCED_VISUALISATIONS_FAIL'
export const UPDATE_RESULTS_TABLE = 'UPDATE_RESULTS_TABLE'
/* ----------Advanced visualisation action types end---------------- */

/* ----------Results Table Variants types start--------------------- */
export const SET_FILTERED_SUMMARY_TABLE_VARIANTS =
  'SET_FILTERED_SUMMARY_TABLE_VARIANTS'
/* ----------Results Table Variants types end--------------------- */

/* ----------Epsilon action types start---------------- */
export const FETCH_EPSILON_BUSINESS_UNITS = 'FETCH_EPSILON_BUSINESS_UNITS'
export const FETCH_EPSILON_BUSINESS_UNITS_SUCCESS =
  'FETCH_EPSILON_BUSINESS_UNITS_SUCCESS'
export const FETCH_EPSILON_BUSINESS_UNITS_ERROR =
  'FETCH_EPSILON_BUSINESS_UNITS_ERROR'

export const FETCH_EPSILON_FOLDERS = 'FETCH_EPSILON_FOLDERS'
export const FETCH_EPSILON_FOLDERS_SUCCESS = 'FETCH_EPSILON_FOLDERS_SUCCESS'
export const FETCH_EPSILON_FOLDERS_ERROR = 'FETCH_EPSILON_FOLDERS_ERROR'

export const FETCH_EPSILON_CAMPAIGNS = 'FETCH_EPSILON_CAMPAIGNS'
export const FETCH_EPSILON_CAMPAIGNS_SUCCESS = 'FETCH_EPSILON_CAMPAIGNS_SUCCESS'
export const FETCH_EPSILON_CAMPAIGNS_ERROR = 'FETCH_EPSILON_CAMPAIGNS_ERROR'

export const FETCH_EPSILON_SEQUENCES = 'FETCH_EPSILON_SEQUENCES'
export const FETCH_EPSILON_SEQUENCES_SUCCESS = 'FETCH_EPSILON_SEQUENCES_SUCCESS'
export const FETCH_EPSILON_SEQUENCES_ERROR = 'FETCH_EPSILON_SEQUENCES_ERROR'

export const FETCH_EPSILON_SEED_LIST_START = 'FETCH_EPSILON_SEED_LIST_START'
export const FETCH_EPSILON_SEED_LIST_SUCCESS = 'FETCH_EPSILON_SEED_LIST_SUCCESS'
export const FETCH_EPSILON_SEED_LIST_ERROR = 'FETCH_EPSILON_SEED_LIST_ERROR'

export const PUSH_VARIANTS_TO_EPSILON = 'PUSH_VARIANTS_TO_EPSILON'
export const PUSH_VARIANTS_TO_EPSILON_SUCCESS =
  'PUSH_VARIANTS_TO_EPSILON_SUCCESS'
export const PUSH_VARIANTS_TO_EPSILON_ERROR = 'PUSH_VARIANTS_TO_EPSILON_ERROR'

export const DELETE_VARIANTS_FROM_EPSILON = 'DELETE_VARIANTS_FROM_EPSILON'
export const DELETE_VARIANTS_FROM_EPSILON_SUCCESS =
  'DELETE_VARIANTS_FROM_EPSILON_SUCCESS'
export const DELETE_VARIANTS_FROM_EPSILON_ERROR =
  'DELETE_VARIANTS_FROM_EPSILON_ERROR'

export const SCHEDULE_EPSILON_CAMPAIGN = 'SCHEDULE_EPSILON_CAMPAIGN'
export const SCHEDULE_EPSILON_CAMPAIGN_SUCCESS =
  'SCHEDULE_EPSILON_CAMPAIGN_SUCCESS'
export const SCHEDULE_EPSILON_CAMPAIGN_ERROR = 'SCHEDULE_EPSILON_CAMPAIGN_ERROR'

export const FETCH_EPSILON_TIME_ZONE = 'FETCH_EPSILON_TIME_ZONE'
export const FETCH_EPSILON_TIME_ZONE_SUCCESS = 'FETCH_EPSILON_TIME_ZONE_SUCCESS'
export const FETCH_EPSILON_TIME_ZONE_ERROR = 'FETCH_EPSILON_TIME_ZONE_ERROR'

export const CANCEL_EPSILON_CAMPAIGN = 'CANCEL_EPSILON_CAMPAIGN'
export const CANCEL_EPSILON_CAMPAIGN_SUCCESS = 'CANCEL_EPSILON_CAMPAIGN_SUCCESS'
export const CANCEL_EPSILON_CAMPAIGN_ERROR = 'CANCEL_EPSILON_CAMPAIGN_ERROR'

export const RESET_EPSILON_STATE = 'RESET_EPSILON_STATE'
/* ----------Epsilon action types end---------------- */

/* ----------Emarsys action types start---------------- */
export const RETIEVE_EMARSYS_CAMPAIGN = 'RETIEVE_EMARSYS_CAMPAIGN'
export const RETRIEVE_EMARSYS_CAMPAIGN_SUCCESS =
  'RETIEVE_EMARSYS_CAMPAIGN_SUCCESS'
export const RETRIEVE_EMARSYS_CAMPAIGN_ERROR = 'RETIEVE_EMARSYS_CAMPAIGN_ERROR'
export const SCHEDULE_EMARSYS_CAMPAIGN_SUCCESS =
  'SCHEDULE_EMARSYS_CAMPAIGN_SUCCESS'
export const SCHEDULE_EMARSYS_CAMPAIGN_FAIL = 'SCHEDULE_EMARSYS_CAMPAIGN_FAIL'
export const PUSH_SPLIT_TO_EMARSYS_SUCCESS = 'PUSH_SPLIT_TO_EMARSYS_SUCCESS'
export const PUSH_SPLIT_TO_EMARSYS_FAIL = 'PUSH_SPLIT_TO_EMARSYS_FAIL'
export const RETRIEVE_EMARSYS_CAMPAIGN_TIMEZONE =
  'RETRIEVE_EMARSYS_CAMPAIGN_TIMEZONE'
export const RETRIEVE_EMARSYS_CAMPAIGN_RESULTS_SUCCESS =
  'RETRIEVE_EMARSYS_CAMPAIGN_RESULTS_SUCCESS'
/* ----------Emarsys action types end---------------- */

export const GET_MID_LOADING = 'GET_MID_LOADING'
export const GET_MID_SUCCESS = 'GET_MID_SUCCESS'
export const GET_MID_ERROR = 'GET_MID_ERROR'

export const RESET_ENGAGE_SFMC_STATE = 'RESET_ENGAGE_SFMC_STATE'
export const RESET_CHEETAH_STATE = 'RESET_CHEETAH_STATE'

export const TOGGLE_DETAILS_PANEL = 'TOGGLE_DETAILS_PANEL'

export const SET_DYNAMIC_OPTIMIZATIONS_RESULT_TYPE =
  'SET_DYNAMIC_OPTIMIZATIONS_RESULT_TYPE'
