import React, { MouseEventHandler } from 'react'
import { Progress } from 'antd'
import { ProgressProps } from 'antd/lib/progress'

import Button from 'common/components/button'
import { Close as CloseIcon } from 'common/icons'

type Props = {
  display: boolean
  message: string
  generationErrorCancel: Function
}

type State = {
  display: 'block' | 'none'
  status: boolean
  animationText?: string
  type?: ProgressProps['status']
  generationErrorCancel: MouseEventHandler<HTMLElement>
  buttonDisplay: string
  message?: string
}

class GenerationError extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      display: props.display ? 'block' : 'none',
      status: true,
      animationText: props.message,
      type: 'exception',
      generationErrorCancel: props.generationErrorCancel,
      buttonDisplay: 'block',
    }
    this.progressStatusBar = this.progressStatusBar.bind(this)
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState(
      {
        display: nextProps.display ? 'block' : 'none',
        message: nextProps.message,
      },
      () => {
        this.progressStatusBar()
      }
    )
  }

  progressStatusBar() {
    const { status, message } = this.state
    if (!status) {
      this.setState(() => ({
        animationText: message,
        type: 'exception',
        buttonDisplay: 'block',
      }))
    }
  }

  render() {
    const {
      display,
      type,
      buttonDisplay,
      animationText,
      generationErrorCancel,
    } = this.state

    return (
      <div id="scheduler-animation" style={{ display }}>
        <div className="animation-wrap">
          <div className="animation-image">
            <CloseIcon
              isDefaultColor={false}
              size={12}
              className="text-red-400"
            />
          </div>
          <div className="animation-text">{animationText}</div>
          <div className="animation-progress-bar">
            <Progress
              percent={100}
              strokeWidth={15}
              status={type}
              showInfo={false}
            />
          </div>
          <div
            className="animation-error-btn"
            style={{ display: buttonDisplay }}
          >
            <Button
              variant="primary"
              onClick={generationErrorCancel}
              data-cy="generation-error-ok-button"
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default GenerationError
