import HoldingPage from './Page'

const NoResults = () => {
  return (
    <HoldingPage>
      <HoldingPage.Title>Oh dear me! No results to show…</HoldingPage.Title>
      <HoldingPage.SubTitle className="max-w-212 ">
        Try another combination and maybe tweak the date range a little.
      </HoldingPage.SubTitle>
    </HoldingPage>
  )
}

export default NoResults
