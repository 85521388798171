export const contentKeys = {
  all: ['content'] as const,
  nodes: () => [...contentKeys.all, 'nodes'] as const,
  nodeMetadata: (selectedNodeId: string) =>
    [...contentKeys.all, 'node', 'metadata', { selectedNodeId }] as const,
  emptyNodes: () => [...contentKeys.all, 'emptyNodes'] as const,
  phrases: (selectedNodeId?: string) =>
    [...contentKeys.all, 'phrases', { selectedNodeId }] as const,
  replacers: () => [...contentKeys.all, 'replacers'] as const,
  tags: (params: { nodeIds?: string[]; rootNodeId?: string }) =>
    [...contentKeys.all, 'tags', params] as const,
}
