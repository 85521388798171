import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const SectionTitle = ({ children }: Props) => {
  return (
    <div className="mb-4 text-lg text-coolGray-800 font-bold">{children}</div>
  )
}

export default SectionTitle
