import ldReducer from 'app/launchDarklySlice'
import navigationReducer from 'app/navigation/navigationSlice'
import WorkflowCampaignReducer from 'workflow/Workflow.reducer'

import topicsSliceReducer from 'common/components/topics/store/topicsSlice'
import brandVoiceReducer from 'features/admin/brandVoice/store/brandVoiceSlice'
import dataSourceReducer from 'features/admin/dataSources/store/dataSourceSlice'
import regionsReducer from 'features/admin/regions/store/regionsSlice'
import styleGuideReducer from 'features/admin/styleGuide/store/styleGuideSlice'
import adminUsersReducer from 'features/admin/users/store/usersSlice'
import sfmcLoginReducer from 'features/auth/sfmc-auth/sfmc-auth.reducer'
import authReducer from 'features/auth/store/authSlice'
import contentGenerationReducer from 'features/campaigns/create/contentGeneration/store/contentGenerationSlice'
import adobeTargetReducer from 'features/campaigns/integration-options/AdobeTarget/store/adobeTargetSlice'
import facebookReducer from 'features/campaigns/integration-options/Facebook/store/facebookSlice'
import optimizelyReducer from 'features/campaigns/integration-options/Optimizely/store/optimizelySlice'
import campaignReducer from 'features/campaigns/store/campaignSlice'
import nodesReducer from 'features/content/nodes/store/nodesSlice'
import dashboardReducer from 'features/dashboard/store/dashboardSlice'
import contentReducer from 'features/futurama/store/contentSlice'
import contentSettingsReducer from 'features/projects/views/editProject/views/content/store/contentSettingsSlice'
import projectNodeReducer from 'features/projects/views/editProject/views/nodes/store/nodeSlice'
import insightsReducer from 'features/reports/store/insightsSlice'
import reportsReducer from 'features/reports/store/reportsSlice'
import unifiedFlowReducer from 'features/unifiedFlow/store/unifiedFlowSlice'

const reducers = {
  reducer: {
    flagStates: ldReducer,
    campaignStates: WorkflowCampaignReducer,
    authStates: authReducer,
    campaigns: campaignReducer,
    sfmcUserStates: sfmcLoginReducer,
    navigation: navigationReducer,
    dashboard: dashboardReducer,
    reports: reportsReducer,
    insights: insightsReducer,
    adobeTarget: adobeTargetReducer,
    facebook: facebookReducer,
    optimizely: optimizelyReducer,
    adminUsers: adminUsersReducer,
    content: contentReducer,
    unifiedFlow: unifiedFlowReducer,
    contentGeneration: contentGenerationReducer,
    contentSettings: contentSettingsReducer,
    projectNodes: projectNodeReducer,
    regions: regionsReducer,
    nodes: nodesReducer,
    topics: topicsSliceReducer,
    brandVoice: brandVoiceReducer,
    styleGuide: styleGuideReducer,
    dataSource: dataSourceReducer,
  },
}

export default reducers
