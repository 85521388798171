import {
  Article as ArticleIcon,
  ContentCard as ContentCardIcon,
  Display as DisplayIcon,
  ECommerce as ECommerceIcon,
  Email as EmailIcon,
  Facebook as FacebookIcon,
  Google as GoogleIcon,
  InApp as InAppIcon,
  Instagram as InstagramIcon,
  Push as PushIcon,
  Sms as SmsIcon,
  SmsLove as SmsLoveIcon,
  StartFlag as StartFlagIcon,
  Website as WebsiteIcon,
} from 'common/icons'

export type ContentType = keyof typeof mapContentTypeToIcon

export const mapContentTypeToIcon = {
  google: GoogleIcon,
  startFlag: StartFlagIcon,
  email: EmailIcon,
  push_notification: PushIcon,
  sms: SmsLoveIcon,
  landing_page: WebsiteIcon,
  web: WebsiteIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  ads: GoogleIcon,
  mobile: SmsIcon,
  messaging: SmsIcon,
  adwords: GoogleIcon,
  'marketing email': EmailIcon,
  'email headline': EmailIcon,
  'email pre-header': EmailIcon,
  'email cta': EmailIcon,
  'email subject line': EmailIcon,
  'cold email': EmailIcon,
  'facebook ad': FacebookIcon,
  social: FacebookIcon,
  'instagram ad': InstagramIcon,
  push: PushIcon,
  'push title': PushIcon,
  'push body': PushIcon,
  heading: ArticleIcon,
  'section titles': ArticleIcon,
  'section paragraphs': ArticleIcon,
  'bullet points': ArticleIcon,
  article: ArticleIcon,
  articles: ArticleIcon,
  'website call to action': WebsiteIcon,
  'website headline': WebsiteIcon,
  'website banner': WebsiteIcon,
  'google responsive search ad': GoogleIcon,
  'google responsive display ad': GoogleIcon,
  'product description': ECommerceIcon,
  'product features': ECommerceIcon,
  products: ECommerceIcon,
  ecommerce: ECommerceIcon,
  email_in_body: EmailIcon,
  social_ad: InstagramIcon,
  web_and_app: WebsiteIcon,
  display: DisplayIcon,
  content_card: ContentCardIcon,
  in_app_message: InAppIcon,
} as const

type Props = {
  contentType: ContentType | undefined
  className?: string
}

export const ContentTypeIcon = ({ contentType, className }: Props) => {
  const Icon = contentType
    ? mapContentTypeToIcon[contentType.toLowerCase()]
    : undefined

  if (!Icon) {
    return <span className="ml-6"></span>
  }

  return <Icon isDefaultColor={false} className={className} />
}
