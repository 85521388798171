import {
  CordialBatchMessage,
  CordialExperimentType,
  CordialIntegrationData,
} from './types'

interface State {
  batchMessagesList: CordialBatchMessage[] | undefined
  selectedBatchMessage: string | undefined
  selectedExperimentType: CordialExperimentType
  isExperimentCreated: boolean
  isExperimentDeleted: boolean
  championSendTimeHours: string | undefined
  championSendTimeMinutes: string | undefined
  isBatchMessagesLoading: boolean
  isCreateExperimentButtonLoading: boolean
  isDeleteExperimentButtonLoading: boolean
  error: string | undefined
}

type CordialReducerActions =
  | { type: 'get_batch_messages' }
  | { type: 'get_batch_messages_success'; value: CordialBatchMessage[] }
  | { type: 'get_batch_messages_error'; value: string }
  | { type: 'create_experiment' }
  | { type: 'create_experiment_success' }
  | { type: 'create_experiment_error'; value: string }
  | { type: 'delete_experiment' }
  | { type: 'delete_experiment_success' }
  | { type: 'delete_experiment_error'; value: string }
  | { type: 'select_batch_message'; value: string }
  | { type: 'select_experiment_type'; value: CordialExperimentType }
  | { type: 'select_champion_send_time_hours'; value: string }
  | { type: 'select_champion_send_time_minutes'; value: string }
  | { type: 'load_saved_batch_message'; value: CordialIntegrationData }

export const initialState: State = {
  batchMessagesList: undefined,
  selectedBatchMessage: undefined,
  selectedExperimentType: undefined,
  isExperimentCreated: false,
  isExperimentDeleted: false,
  championSendTimeHours: undefined,
  championSendTimeMinutes: undefined,
  error: undefined,
  isBatchMessagesLoading: false,
  isCreateExperimentButtonLoading: false,
  isDeleteExperimentButtonLoading: false,
}

export const cordialReducer = (state: State, action: CordialReducerActions) => {
  switch (action.type) {
    case 'get_batch_messages':
      return { ...state, isBatchMessagesLoading: true }
    case 'create_experiment':
      return { ...state, isCreateExperimentButtonLoading: true }
    case 'create_experiment_success':
      return {
        ...state,
        isCreateExperimentButtonLoading: false,
        isExperimentCreated: true,
      }
    case 'create_experiment_error':
      return {
        ...state,
        isCreateExperimentButtonLoading: false,
        isExperimentCreated: false,
        error: action.value,
      }
    case 'select_batch_message':
      return { ...state, selectedBatchMessage: action.value }
    case 'select_experiment_type':
      return { ...state, selectedExperimentType: action.value }
    case 'select_champion_send_time_hours':
      return { ...state, championSendTimeHours: action.value }
    case 'select_champion_send_time_minutes':
      return { ...state, championSendTimeMinutes: action.value }
    case 'get_batch_messages_success':
      return {
        ...state,
        batchMessagesList: action.value,
        isBatchMessagesLoading: false,
      }
    case 'get_batch_messages_error':
      return { ...state, isBatchMessagesLoading: false, error: action.value }
    case 'load_saved_batch_message':
      return {
        ...state,
        isExperimentCreated: true,
        selectedBatchMessage: action.value.batchMessageId,
        selectedExperimentType: action.value.experimentType,
        championSendTimeHours: action.value.championSendHours,
        championSendTimeMinutes: action.value.championSendMinutes,
      }
    case 'delete_experiment':
      return {
        ...state,
        isDeleteExperimentButtonLoading: true,
      }
    case 'delete_experiment_success':
      return {
        ...state,
        isDeleteExperimentButtonLoading: false,
        selectedBatchMessage: undefined,
        selectedExperimentType: undefined,
        championSendTimeHours: undefined,
        championSendTimeMinutes: undefined,
        isExperimentCreated: false,
        isExperimentDeleted: true,
      }
    case 'delete_experiment_error':
      return {
        ...state,
        isDeleteExperimentButtonLoading: false,
        error: action.value,
      }
  }
}
