import { useCallback, useMemo, useState } from 'react'

import Badge from 'common/components/Badge'
import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import Spinner from 'common/components/spinner'
import Table, { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import Tooltip from 'common/components/Tooltip'
import { formatDate } from 'common/helpers/date'
import { useAppSelector } from 'common/hooks/redux'
import {
  ChevronDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
  Spinner as SpinnerIcon,
} from 'common/icons'

import { CustomerAttributesResponse } from '../api'
import useDeleteCustomerAttributesMutation from '../api/mutations/useDeleteCustomerAttributesMutation'
import useGetCustomerAttributesQuery from '../api/queries/useGetCustomerAttributesQuery'
import useDataIngestionWebsocketStatus from '../useDataIngestionWebsocketStatus'

type CustomerAttributesColumn = Pick<
  CustomerAttributesResponse,
  'created' | 'fileName' | 'type'
> & { id: undefined; status: undefined }

type Props = {
  baseUrl: string
}

const CustomerAttributesTable = ({ baseUrl }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const customerFileState = useAppSelector(
    (state) => state.dataSource.fileStates.customer
  )
  const fileName = useAppSelector(
    (state) => state.dataSource.fileStates.customer.fileName
  )
  const isUploading = useAppSelector(
    (state) => state.dataSource.fileStates.customer.isUploading
  )

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  const customerAttributesQuery = useGetCustomerAttributesQuery({
    baseUrl,
    accountId,
  })
  const customerAttributes = customerAttributesQuery.data

  const deleteQuery = useDeleteCustomerAttributesMutation(accountId)
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false)

  const [processingState, setProcessingState] =
    useState<undefined | 'success' | 'failed' | 'processing'>(undefined)

  useDataIngestionWebsocketStatus({
    topic: 'customer',
    onSuccess: () => {
      setProcessingState('success')
      customerAttributesQuery.refetch()
      setIsCollapsed(false)
    },
  })

  const generateStatusColumn =
    useCallback((): Column<CustomerAttributesColumn> => {
      return {
        Header: 'Status',
        accessor: 'status',
        align: 'right',
        maxWidth: 200,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <BaseCell>
              <Badge
                text={
                  processingState === 'failed'
                    ? 'Import failed'
                    : 'Importing file...'
                }
                variant={processingState === 'failed' ? 'danger' : 'info'}
              />
            </BaseCell>
          )
        },
      }
    }, [processingState])

  const columns: Column<CustomerAttributesColumn>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'fileName',
        width: 600,
        minWidth: 200,
        Cell: ({ row }) => {
          return (
            <BaseCell className="bg-coolGray-50">
              <Button
                disabled={isUploading || processingState === 'failed'}
                className="hover:text-maroon-500 focus:outline-none focus-visible:outline-black group -ml-4 max-w-min"
                onClick={() => setIsCollapsed(!isCollapsed)}
                prefixIcon={
                  isUploading || isCollapsed || processingState === 'failed' ? (
                    <ChevronRightIcon size={4} />
                  ) : (
                    <ChevronDownIcon isDefaultColor={false} size={4} />
                  )
                }
              >
                <span className="overflow-hidden truncate">
                  {isUploading ? fileName : row.original.fileName}
                </span>
              </Button>
            </BaseCell>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: 80,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            !isUploading && (
              <BaseCell className="uppercase">{row.original.type}</BaseCell>
            )
          )
        },
      },
      {
        Header: 'Created',
        accessor: 'created',
        width: 80,
        minWidth: 110,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            !isUploading && (
              <BaseCell>{formatDate(row.original.created)}</BaseCell>
            )
          )
        },
      },
      ...(isUploading || processingState === 'failed'
        ? [generateStatusColumn()]
        : []),
      {
        Header: 'Actions',
        width: 80,
        minWidth: 110,
        disableSortBy: true,
        ...(isUploading
          ? {
              accessor: 'id',
              align: 'right',
              Cell: (row) => {
                return (
                  <BaseCell>
                    <Tooltip overlay={`Processing file ${fileName}`}>
                      <SpinnerIcon className="animate-spin" />
                    </Tooltip>
                  </BaseCell>
                )
              },
            }
          : {
              ...generateDeleteButtonColumn<CustomerAttributesColumn>({
                onClick: () => setIsConfirmDeleteOpen(true),
                buttonLabel: 'Delete',
                hasTooltip: true,
                isVisibleOnlyOnHover: false,
                isDefaultColor: false,
              }),
            }),
      },
    ],
    [isCollapsed, generateStatusColumn, processingState, fileName, isUploading]
  )

  const determineStatus = () => {
    if (isUploading || processingState === 'processing') {
      return 'Importing file...'
    }

    switch (processingState) {
      case 'failed':
        return 'Import failed'
      case 'success':
        return 'Import successful'
      default:
        return 'Ready for import'
    }
  }

  const dataWithStatus = {
    ...(customerAttributes?.fileName?.length
      ? customerAttributes
      : customerFileState),
    status: determineStatus(),
  }

  return (
    <div className="sm:-ml-6 sm:-mr-6">
      {((dataWithStatus?.data?.length ?? 0) > 0 || isUploading) && (
        <Spinner
          isSpinning={
            customerAttributesQuery.isLoading || deleteQuery.isLoading
          }
        >
          <Table
            columns={columns}
            data={[{ id: 1, ...dataWithStatus }]} // id is required for the actions (Delete button).
            className="mt-6 "
            rowClassName="bg-coolGray-50 "
          />
          {!isUploading && !isCollapsed && processingState !== 'processing' && (
            <Table
              className="bg-coolGray-50 pt-2"
              columns={
                dataWithStatus?.columns?.map((value, index) => ({
                  Header: value,
                  accessor: value,
                  ...(index === 0
                    ? { width: 400, minWidth: 200, isResizable: true }
                    : undefined),
                })) ?? []
              }
              data={dataWithStatus?.data ?? []}
              onSwapColumns={() => {}}
            />
          )}
        </Spinner>
      )}
      <ConfirmationModal
        title="Delete this file?"
        confirmationText="Are you sure you want to delete this file?"
        confirmButtonText="Delete file"
        open={isConfirmDeleteOpen}
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onConfirm={() => {
          setIsConfirmDeleteOpen(false)
          deleteQuery.mutate({ accountId, baseUrl })
        }}
      />
    </div>
  )
}

export default CustomerAttributesTable
