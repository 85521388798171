/* eslint-disable max-lines */
import React, { ReactNode } from 'react'
import { Button, Cascader, Form, Select, Upload } from 'antd'
import moment, { Moment } from 'moment'

import DatePicker from 'common/components/datePicker/DatePicker'
import Input from 'common/components/Input'
import RadioGroup from 'common/components/radioGroup'
import Tags from 'common/components/tags'
import TimePickerComponent from 'common/components/TimePicker'
import { isYesterdayOrBefore } from 'common/helpers/date'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'
import { Time as TimeIcon } from 'common/icons'

import GuidingText from '../../GuidingText'

import DaysToGo from './DaysToGo/Daystogo'
import SelectList from './SelectList/Selectlist'
import SelectRadio from './SelectRadio/Selectradio'
import ValidationError from './ValidationError/Validationerror'
import YesNo from './YesNo/Yesno'
import SingleSelectRadioCheckbox from './singleSelectRadioCheckbox'

import styles from './Questions.module.css'

const FormItem = Form.Item
const { Option } = Select

const { REACT_APP_DEFAULT_DATE_FORMAT, REACT_APP_DEFAULT_DATE_TIME_FORMAT } =
  process.env

if (REACT_APP_DEFAULT_DATE_FORMAT === undefined) {
  throw new Error('REACT_APP_DEFAULT_DATE_FORMAT is required')
}

export const CalendarIcon = () => (
  <i
    aria-label="icon: calendar"
    className="anticon anticon-calendar ant-calendar-picker-icon"
  >
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon/Calendar/Default</title>
      <g
        id="Icon/Calendar/Default"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g
          id="calendar"
          transform="translate(1.000000, 1.000000)"
          stroke="#1F2937"
          stroke-width="1.5"
        >
          <rect
            id="Rectangle"
            x="0"
            y="2.93333333"
            width="22"
            height="19.0666667"
            rx="1.5"
          ></rect>
          <line x1="0" y1="8.8" x2="22" y2="8.8" id="Path"></line>
          <line
            x1="5.86666667"
            y1="5.13333333"
            x2="5.86666667"
            y2="0"
            id="Path"
          ></line>
          <line
            x1="16.1333333"
            y1="5.13333333"
            x2="16.1333333"
            y2="0"
            id="Path"
          ></line>
        </g>
      </g>
    </svg>
  </i>
)

export const getCascaderOptions = (data: any) => {
  // Iterate recursively over the cascader options, and return a mapping from their value to their name
  // This will be required later on when generating
  const valueNameMapping: any = {}
  const getValueNameMapping = (optionList: any) => {
    if (!Array.isArray(optionList)) {
      return
    }
    optionList.forEach((option) => {
      valueNameMapping[option?.value] = option?.name
      if (option?.children) {
        getValueNameMapping(option.children)
      }
    })
  }
  getValueNameMapping(data.option_list)
  return valueNameMapping
}

type Props = {
  data: any
  validationError: string
  value: any
  conditionalHiddenStatus?: boolean
  conditionalParentOpenStatus: boolean
  onChange: Function
  daysToGoDate?: any
  isUploading?: boolean
  token?: string
  campaignId?: string
  uploadManagedCampaignFile?: Function
  removeManagedCampaignFile?: Function
  hideGuidingText?: boolean
  autoFocus?: boolean
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  disabled: boolean
}
class Questions extends React.Component<Props, any> {
  constructor(props: Props, context: any) {
    super(props, context)
    this.state = {
      fileList: [],
      tags:
        props.value && typeof props.value === 'string'
          ? props.value.split(';')
          : [],
    }
  }

  static defaultProps = {
    conditionalHiddenStatus: true,
  }

  disabledDateTime = (current: Moment | null, allowPast: any) => {
    return allowPast || current === null ? null : isYesterdayOrBefore(current)
  }

  addCascaderValueIfMissing = (optionList: any) => {
    // Iterate recursively through cascader options, and add a value if it's missing
    if (!Array.isArray(optionList)) {
      return
    }
    return optionList.map((option) => {
      const newOption = option
      if (option?.children) {
        newOption.children = this.addCascaderValueIfMissing(option.children)
      }
      if (!newOption?.value && newOption?.label) {
        return { ...newOption, value: newOption.label }
      }
      return newOption
    })
  }

  // Update the uploadList display under the upload button.
  // Take 2 parameter but the second one is optional, if it is not passed we only update the status.
  updateList(status: any, file: any = undefined) {
    const {
      state,
      state: { fileList },
    } = this
    if (file) {
      this.setState({
        ...state,
        fileList: [
          {
            ...file,
            status,
          },
        ],
      })
    } else {
      const updatedFileList = fileList[0]
      updatedFileList.status = status
      this.setState({
        ...state,
        fileList: [updatedFileList],
      })
    }
  }

  resetList() {
    const { state } = this
    this.setState({
      ...state,
      fileList: [],
    })
  }

  datePickerChecker = (dateString: Moment | null, format: string) => {
    let transformedDateString
    if (moment(dateString).isValid()) {
      transformedDateString = moment(dateString).format(format)
    } else {
      transformedDateString = ''
    }
    return transformedDateString
  }

  render() {
    const {
      data,
      validationError,
      onChange,
      value,
      daysToGoDate,
      conditionalHiddenStatus,
      conditionalParentOpenStatus,
      isUploading,
      uploadManagedCampaignFile,
      removeManagedCampaignFile,
      hideGuidingText,
      autoFocus,
      onFocus,
      onBlur,
      disabled,
    } = this.props
    const defaultValue = value
    const { fileList, tags } = this.state
    let input: ReactNode = null

    if (!REACT_APP_DEFAULT_DATE_TIME_FORMAT) {
      throw new Error('REACT_APP_DEFAULT_DATE_TIME_FORMAT is required')
    }

    const timeFormat = 'HH:mm'
    switch (data.type) {
      case 'text_number':
      case 'text_percentage':
      case 'text_currency':
      case 'text_integer':
      case 'text_short':
      case 'text_long':
        input = (
          <Input
            data-cy={data.type}
            type="text"
            className="text-base max-w-112"
            variant="default"
            name={data.name}
            value={defaultValue || ''}
            defaultValue={defaultValue || ''}
            placeholder={data.placeholder}
            onChange={(e) => {
              onChange(
                data.name,
                data.type,
                stripForbiddenCharacters(e.target.value),
                data.required
              )
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            autoFocus={autoFocus}
            disabled={disabled}
          />
        )
        break

      case 'text_list':
        input = (
          <Tags<string>
            isFreeText
            data-cy={data.type}
            onRemoveClick={(value) => {
              const updatedTags = tags.filter((tag) => tag !== value)
              onChange(
                data.name,
                data.type,
                updatedTags.join(';'),
                data.required
              )
              this.setState({ tags: updatedTags })
            }}
            onAddClick={(value) => {
              const updatedTags = [...tags, value]
              onChange(
                data.name,
                data.type,
                updatedTags.join(';'),
                data.required
              )
              this.setState({ tags: updatedTags })
            }}
            tags={tags.map((value) => ({ value, label: value }))}
            suggestions={data.suggested_words}
            maxLength={20}
            onInputChange={stripForbiddenCharacters}
            isDisabled={disabled}
          />
        )
        break
      case 'upload':
        input = (
          <Upload
            data-cy={data.type}
            accept=".jpg,.jpeg,.pdf,.gif,.png"
            data-test-id="file-upload"
            disabled={isUploading}
            customRequest={(data) => {
              uploadManagedCampaignFile?.(data.file, this.updateList.bind(this))
            }}
            onChange={(e) => {
              if (e.file.status === 'removed') {
                return
              }
              this.updateList('uploading', e.file)
              onChange(data.name, data.type, e.file.name, data.required)
            }}
            onRemove={(e) => {
              this.resetList()
              onChange(data.name, data.type, null, data.required)
              removeManagedCampaignFile?.()
            }}
            multiple={false}
            fileList={fileList}
            showUploadList={{
              showDownloadIcon: false,
              showRemoveIcon: true,
            }}
          >
            <Button disabled={disabled}>Click to Upload</Button>
          </Upload>
        )
        break
      case 'date_time':
        const dateTime =
          defaultValue !== '' && defaultValue !== undefined
            ? moment(defaultValue, REACT_APP_DEFAULT_DATE_TIME_FORMAT)
            : null
        input = (
          <>
            <div className="flex gap-4">
              <DatePicker
                data-cy={data.type}
                date={dateTime}
                id="date-time-picker"
                numberOfMonths={1}
                onDateChange={(currentDate) => {
                  const transformedDateString = this.datePickerChecker(
                    currentDate,
                    REACT_APP_DEFAULT_DATE_TIME_FORMAT
                  )
                  onChange(
                    data.name,
                    data.type,
                    transformedDateString,
                    data.required
                  )
                }}
                showClearDate
                disabled={disabled}
                isOutsideRange={(date) =>
                  this.disabledDateTime(date, data.allow_past) ?? false
                }
                isTodayVisible={true}
              />
              <TimePickerComponent
                value={dateTime ?? undefined}
                allowClear={false}
                format={timeFormat}
                style={{ width: '100%' }}
                disabled={disabled}
                className={styles.timePicker}
                onChange={(dateObj) => {
                  const transformedDateString = this.datePickerChecker(
                    dateObj,
                    REACT_APP_DEFAULT_DATE_TIME_FORMAT
                  )
                  onChange(
                    data.name,
                    data.type,
                    transformedDateString,
                    data.required
                  )
                }}
                suffixIcon={<TimeIcon size={6} />}
              />
            </div>
          </>
        )
        break
      case 'date':
        const date1 =
          defaultValue !== '' && defaultValue !== undefined
            ? moment(defaultValue, 'DD-MM-YYYY')
            : null
        input = (
          <>
            <DatePicker
              data-cy={data.type}
              date={date1}
              id={data.name}
              numberOfMonths={1}
              onDateChange={(currentDate) => {
                const transformedDateString = this.datePickerChecker(
                  currentDate,
                  'DD-MM-YYYY'
                )
                onChange(
                  data.name,
                  data.type,
                  transformedDateString,
                  data.required
                )
              }}
              showClearDate
              disabled={disabled}
              isOutsideRange={(date) =>
                this.disabledDateTime(date, data.allow_past) ?? false
              }
              isTodayVisible={true}
            />
            <GuidingText text={data.tooltip} />
          </>
        )
        break

      case 'daystogo':
        let date
        if (defaultValue !== '' && defaultValue !== undefined) {
          date = moment(defaultValue, 'DD-MM-YYYY')
        } else {
          date = defaultValue
        }
        input = (
          <DaysToGo
            data-cy={data.type}
            value={date}
            defaultValue={defaultValue}
            format={REACT_APP_DEFAULT_DATE_FORMAT ?? ''}
            name={data.name}
            daysToGoDate={daysToGoDate}
            type={data.type}
            required={data.required || false}
            onChange={onChange}
            disabled={disabled}
          />
        )
        break

      case 'select_single': {
        const optionsList: any = []
        data.option_list.forEach((option: any, index: any) => {
          if (index === 0) {
            const curOption: any = {}
            curOption.value = ''
            curOption.label = data.include_no ? 'No' : 'Select an option'
            optionsList.push(curOption)
          }
          const curOption: any = {}
          const optionVal = option[0]
          curOption.label = optionVal
          curOption.value =
            typeof option[1] !== 'undefined' ? option[1] : option[0]
          optionsList.push(curOption)
        })
        input = (
          <Select
            data-cy={data.type}
            value={defaultValue}
            defaultValue={defaultValue}
            onChange={(values: any) => {
              onChange(data.name, data.type, values, data.required)
            }}
            showSearch
            filterOption={(inputs, option) =>
              (option.props.children as string)
                .toLowerCase()
                .indexOf(inputs.toLowerCase()) >= 0
            }
            disabled={disabled}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          >
            {optionsList.map((option: any, index: any) => (
              <Option
                data-cy={option.label}
                key={index.toString()}
                value={option.value}
              >
                {option.label}
              </Option>
            ))}
          </Select>
        )
        break
      }

      case 'select_single_radio':
        input = ''
        break
      case 'select_single_checkbox':
        input = (
          <SingleSelectRadioCheckbox
            data={data}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={onChange}
          />
        )
        break

      case 'yes_no':
        input = (
          <>
            <YesNo
              defaultValue={defaultValue}
              name={data.name}
              type={data.type}
              onChange={onChange}
              disabled={disabled}
            />
          </>
        )
        break

      case 'select_list':
        input = (
          <SelectList
            data-cy={data.type}
            defaultValue={defaultValue}
            list={data.word_list}
            max_limit={data.max_limit}
            name={data.name}
            type={data.type}
            required={data.required}
            onChange={onChange}
            disabled={disabled}
          />
        )
        break

      case 'select_radio':
      case 'select_radio_integer':
      case 'select_radio_percentage':
      case 'select_radio_currency':
        input = (
          <>
            <SelectRadio
              data-cy={data.type}
              defaultValue={defaultValue}
              name={data.name}
              type={data.type}
              onChange={onChange}
              placeholder={data.placeholder || ''}
              disabled={disabled}
            />
            {hideGuidingText ? null : <GuidingText text={data.tooltip} />}
          </>
        )
        break
      case 'cascader':
        data.option_list = this.addCascaderValueIfMissing(data.option_list)
        const valueNameMapping = getCascaderOptions(data)
        input = (
          <Cascader
            name={data.name}
            allowClear={false}
            options={data.option_list}
            onChange={(val) => {
              onChange(data.name, data.type, val, data.required, {
                valueNameMapping,
              })
            }}
            placeholder={data.placeholder || 'Please select'}
            // @ts-ignore
            showSearch={(inputValue: any, path: any) => {
              return path.some(
                (option: any) =>
                  option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                  -1
              )
            }}
            defaultValue={[defaultValue]}
            value={defaultValue}
            disabled={disabled}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          />
        )
        break
      case 'radio_button':
        input = (
          <>
            <RadioGroup
              data-cy={data.type}
              name={data.name}
              onChange={(newValue) =>
                onChange(data.name, data.type, newValue, data.required)
              }
              value={defaultValue}
              options={data.option_list}
              isDisabled={disabled}
              variant="withBackground"
            />
            {hideGuidingText ? null : <GuidingText text={data.tooltip} />}
          </>
        )
        break
      default:
        input = ''
        break
    }
    let classNames = `question-items ${data.type} m-b--20`
    classNames += data.required ? ' required' : ''
    classNames += validationError ? ' error' : ''
    classNames += conditionalHiddenStatus === false ? ' conditional-child' : ''
    classNames +=
      conditionalParentOpenStatus === true ? ' conditional-parent' : ''
    const selectRadioTypes = [
      'select_radio',
      'select_radio_integer',
      'select_radio_currency',
      'select_radio_percentage',
      'radio_button',
    ]

    const label = data.text ? (
      data.required ? (
        data.text
      ) : (
        <React.Fragment>
          {data.text}
          <span className="text-coolGray-400"> (optional)</span>
        </React.Fragment>
      )
    ) : null

    const tooltip = data.tooltip ? (
      data.required || data.text ? (
        data.tooltip
      ) : (
        <React.Fragment>
          <span className="text-coolGray-400">(Optional) </span>
          {data.tooltip}
        </React.Fragment>
      )
    ) : null

    if (selectRadioTypes.includes(data.type)) {
      return (
        <FormItem label={label} className={classNames}>
          {input}
          <ValidationError message={validationError} />
        </FormItem>
      )
    }
    return (
      <FormItem label={label} className={classNames}>
        {data.type === 'date' || data.type === 'daystogo' ? (
          <>
            {input}
            {hideGuidingText ? null : (
              <ValidationError message={validationError} />
            )}
          </>
        ) : (
          <>
            {input}
            {hideGuidingText ? null : (
              <>
                <ValidationError message={validationError} />
                <GuidingText text={tooltip} />
              </>
            )}
          </>
        )}
      </FormItem>
    )
  }
}

export default Questions
