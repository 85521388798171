import { ChangeEvent, ClipboardEvent, KeyboardEvent, useState } from 'react'
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea'
import cx from 'classnames'
import { Rule } from 'workflow/interface'

import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'
import { replaceNewLineEmojiAsString } from 'common/variantsUtils'

type Props = Omit<TextAreaProps, 'onChange'> & {
  rules?: Rule[]
  value?: string
  className?: string
  maxRows?: number
  onChange: (value: string, originalValue: string) => void
  onLineBreaksWarning?: () => void
  'data-cy'?: string
}

const countNumberOfBreakLines = (text?: string) => {
  return text?.match(/\n|↵/g)?.length || 0
}

const replaceBreakLineCharsWithPhraseeBreakSymbol = (text: string) => {
  return text?.replace(/(?:\r|\n|\r\n)/g, '〽')
}

const ControlInput = ({
  className,
  onChange,
  onLineBreaksWarning,
  maxRows,
  rows,
  autoSize,
  value,
  rules,
  'data-cy': dataCy,
  ...rest
}: Props) => {
  const [count, setCount] = useState(value?.length || 0)
  const onPressEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    const value = (event.target as HTMLTextAreaElement).value
    const numberOfBreakLines = countNumberOfBreakLines(value)

    if (
      maxRows !== undefined &&
      event.key === 'Enter' &&
      numberOfBreakLines >= maxRows
    ) {
      event.preventDefault()
    }
  }

  const handleOnPaste = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    const value = event.clipboardData?.getData('Text')
    const numberOfBreakLines = countNumberOfBreakLines(value)

    if (
      maxRows !== undefined &&
      numberOfBreakLines > maxRows &&
      onLineBreaksWarning
    ) {
      onLineBreaksWarning()
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = (event.target as HTMLTextAreaElement).value
    const strippedValue = replaceBreakLineCharsWithPhraseeBreakSymbol(
      stripForbiddenCharacters(value)
    )

    if (maxCharacterCount && strippedValue.length > maxCharacterCount) {
      return
    }
    setCount(strippedValue.length)
    onChange(strippedValue, value)
  }

  const autoSizeValue = autoSize
    ? autoSize
    : !rows
    ? { minRows: 1.334, maxRows: 5 } // 1.334 equals 38px
    : undefined

  const maxCharacterCount = rules?.find((rule) => rule.max)?.max

  return (
    <div className={cx({ 'flex flex-col relative -top-5': maxCharacterCount })}>
      {maxCharacterCount && (
        <div className="self-end mb-2 text-coolGray-400">
          {count} / {maxCharacterCount}
        </div>
      )}
      <TextArea
        data-cy={dataCy}
        className={cx(
          'focus:border-maroon-500 outline-none shadow-none disabled:border-coolGray-300',
          { 'hover:border-maroon-300': !rest.disabled },
          className
        )}
        autoSize={autoSizeValue}
        onPaste={handleOnPaste}
        onPressEnter={onPressEnter}
        onChange={handleOnChange}
        value={replaceNewLineEmojiAsString(value ?? '')}
        rows={rows}
        {...rest}
      />
    </div>
  )
}

export default ControlInput
