import { useFlags } from 'launchdarkly-react-client-sdk'

import StyleGuide from '../styleGuide'

import BrandVoice from './BrandVoice'

const BrandVoiceRedirect = () => {
  const flags = useFlags()
  return flags.showBrandTonePage ? <BrandVoice /> : <StyleGuide />
}

export default BrandVoiceRedirect
