import { useMemo, useState } from 'react'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import ConfirmationModal from 'common/components/confirmationModal'
import { Column } from 'common/components/table'
import TextEditableCell from 'common/components/table/cells/TextEditable'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useFocusSelectCell } from 'common/hooks/table'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'

import useGetReplacersQuery from '../../api/queries/useGetReplacersQuery'
import {
  addDefaultReplacerRow,
  deleteDefaultReplacerRow,
  updateDefaultReplacerCell,
} from '../../store/nodeSlice'

type Row = {
  id: number
  replacer: string
  value: string
}

const DefaultReplacersWidget = () => {
  const { SelectCellWithFocus, editableCellFocus, editableCellLoseFocus } =
    useFocusSelectCell()
  const dispatch = useAppDispatch()
  const editedDefaultReplacers = useAppSelector(
    (state) => state.projectNodes.editedData.defaultReplacers
  )
  const editedMergeTags = useAppSelector(
    (state) => state.projectNodes.editedData.mergeTags
  )
  const defaultReplacers = editedDefaultReplacers.map(
    ({ replacer }) => replacer
  )
  const mergeTagsReplacers = editedMergeTags.map(({ replacer }) => replacer)
  const { data: replacers, isLoading } = useGetReplacersQuery()
  const defaultReplacerOptions = replacers?.filter(
    ({ id }) => !mergeTagsReplacers.includes(id)
  )
  const canAddNewRow = !!defaultReplacerOptions?.filter(
    ({ id }) => !defaultReplacers.includes(id)
  ).length

  const [rowIdToDelete, setRowIdToDelete] =
    useState<number | undefined>(undefined)
  const [resetFilterKey, setResetFilterKey] = useState(0)

  const columns: Column<Row>[] = useMemo(() => {
    return [
      {
        accessor: 'replacer',
        Header: 'Replacer',
        disableSortBy: true,
        Cell: (props) => {
          const { value, row, column } = props

          return (
            <SelectCellWithFocus<Row>
              data-cy="replacer-select"
              className="pl-6 w-full"
              value={value}
              row={row}
              column={column}
              singleSelectProps={{
                name: 'replacer-select',
                options:
                  defaultReplacerOptions
                    ?.filter(
                      (replacer) =>
                        !editedDefaultReplacers.some(
                          (row) => row.replacer === replacer.id
                        ) || replacer.id === props.value
                    )
                    .map(({ name, id }) => ({
                      label: name,
                      value: id,
                    })) ?? [],
                isInsideTable: true,
                isSearchable: true,
              }}
              onCellChange={props.onCellChange}
            />
          )
        },
      },
      {
        accessor: 'value',
        Header: 'Default text',
        disableSortBy: true,
        Cell: TextEditableCell,
      },
      {
        ...generateDeleteButtonColumn<Row>({
          onClick: (row) => {
            setRowIdToDelete(row.id)
          },
          buttonLabel: 'Delete',
        }),
        width: 50,
      },
    ]
  }, [SelectCellWithFocus, defaultReplacerOptions, editedDefaultReplacers])

  const handleDeleteCancel = () => {
    setRowIdToDelete(undefined)
  }

  const handleDeleteConfirm = () => {
    setRowIdToDelete(undefined)
    if (rowIdToDelete !== undefined) {
      dispatch(deleteDefaultReplacerRow(rowIdToDelete))
    }
  }

  return (
    <>
      <TableWidget.Widget
        data-cy="default-replacers-widget"
        className="mt-8"
        isLoading={isLoading}
        columns={columns}
        data={editedDefaultReplacers}
        firstUseText="No default replacer added yet"
        onCellChange={({ rowIndex, columnId, value }) => {
          dispatch(updateDefaultReplacerCell({ rowIndex, columnId, value }))
          editableCellLoseFocus()
          setResetFilterKey((prev) => prev + 1)
        }}
      >
        <TableWidget.Header
          title="Default replacers"
          subtitle="If there are replacers that should always be filled with specific text, set the defaults here.
          Select the replacer and then enter the text to be used in generations."
        >
          <ButtonWithTooltip
            tooltip={!canAddNewRow}
            tooltipText="All replacers have been configured"
            className="mr-4"
            variant="primary"
            data-cy="add-default-replacer-button"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => {
              dispatch(addDefaultReplacerRow())
              editableCellFocus()
              setResetFilterKey((prev) => prev + 1)
            }}
            disabled={!canAddNewRow}
          >
            Replacer
          </ButtonWithTooltip>
          <TableWidget.Filter key={`${resetFilterKey}`} />
        </TableWidget.Header>
      </TableWidget.Widget>

      <ConfirmationModal
        data-cy="default-replacer-row-delete-modal"
        open={rowIdToDelete !== undefined}
        title="Remove default replacer row!"
        confirmationText="Are you sure you want to remove this row?"
        onCancel={() => handleDeleteCancel()}
        onConfirm={() => handleDeleteConfirm()}
      />
    </>
  )
}

export default DefaultReplacersWidget
