import { useEffect, useRef, useState } from 'react'
import {
  DistributionChannel,
  DistributionType,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import isEqual from 'lodash/isEqual'

import Button from 'common/components/button/Button'
import { MultiSelectValue } from 'common/components/MultiSelect'
import PageTitle from 'common/components/PageTitle'
import SingleSelect, {
  SelectValue,
  SingleValue,
} from 'common/components/singleSelect'
import { formatIsoDate } from 'common/helpers/date'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import CampaignSelector from '../components/CampaignSelector'
import DateRangePicker from '../components/DateRangePicker'
import { applyFiltersAndData, setError } from '../store/reportsSlice'

import ProjectsSelect from './components/projectsSelect'
import { ChannelOption, fetchWidgetList, Filters, ProductOption } from './api'
import { SelectedProduct } from './Performance'

import styles from './Header.module.css'

export const headerText = {
  productSelectLabel: 'Type',
  channelSelectLabel: 'Channel',
  runReportButtonText: 'Run report',
}

interface Props {
  title: string
  isLoadingFilters: boolean
  enabledFilters: Filters[]
  products: ProductOption[]
  channels: ChannelOption[]
  selectedProduct: SelectedProduct
  setSelectedProduct: (val: DistributionType) => void
}

const mapOptionToMultiSelect = <T extends { id; name }>(
  options: T[]
): MultiSelectValue[] =>
  options.map(({ id, name }) => ({ id, label: name, value: id }))

// TODO: remove this once BE changes are implemented
const mapProductOptionToMultiSelect = (
  options: ProductOption[]
): MultiSelectValue[] => {
  const renamedOptions = options.map((option) => {
    let newName

    switch (option.name) {
      case 'Engage':
        newName = 'Broadcast'
        break
      case 'React':
        newName = 'Triggered'
        break
      default:
        newName = option.name
    }

    return { ...option, name: newName }
  })

  return mapOptionToMultiSelect<ProductOption>(renamedOptions)
}

const Header = ({
  title,
  isLoadingFilters,
  enabledFilters,
  products,
  channels,
  selectedProduct,
  setSelectedProduct,
}: Props) => {
  const dispatch = useAppDispatch()

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const {
    channel: stateChannel,
    projects: stateProjects,
    campaigns: stateCampaigns,
    startDate: stateStartDate,
    endDate: stateEndDate,
  } = useAppSelector((state) => state.reports)

  const [selectedChannel, setSelectedChannel] =
    useState<ChannelOption | undefined>(stateChannel)
  const [selectedProjects, setSelectedProjects] = useState<string[]>(
    stateProjects || []
  )
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>(
    stateCampaigns || []
  )
  const [selectedStartDate, setSelectedStartDate] = useState<
    string | undefined
  >(stateStartDate || undefined)
  const [selectedEndDate, setSelectedEndDate] = useState<string | undefined>(
    stateEndDate || undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setSelectedChannel(stateChannel)
  }, [stateChannel])

  useEffect(() => {
    if (channels.length === 1) {
      setSelectedChannel(channels[0])
    }
  }, [channels])

  const isProductFilterEnabled = enabledFilters.includes('product')
  const isChannelFilterEnabled = enabledFilters.includes('channel')
  const isProjectFilterEnabled = enabledFilters.includes('project')

  const isReact = selectedProduct === 'AlwaysOn'
  const hasSelectedProduct = isProductFilterEnabled
    ? selectedProduct !== undefined
    : true
  const hasSelectedChannel = isChannelFilterEnabled
    ? selectedChannel !== undefined
    : true
  const hasSelectedProjects = isProjectFilterEnabled
    ? selectedProjects.length
    : true

  const isDateRangeDisabled = isReact
    ? true
    : !hasSelectedProduct || !hasSelectedChannel || !hasSelectedProjects

  const isButtonDisabled =
    !hasSelectedProduct ||
    !hasSelectedChannel ||
    !hasSelectedProjects ||
    (!isReact && (!selectedStartDate || !selectedEndDate)) // For React projects, the calendar is disabled

  const flexWrapperRef = useRef<HTMLDivElement>(null)

  const onChangeProduct = (val: SingleValue<SelectValue>) => {
    if (val?.value !== selectedProduct) {
      setSelectedProduct(val?.value as DistributionType)
      setSelectedChannel(undefined)
      setSelectedProjects([])
      setSelectedCampaigns([])
      if (val?.value === 'AlwaysOn') {
        setSelectedStartDate(undefined)
        setSelectedEndDate(undefined)
      }
    }
  }

  const onChangeChannel = (val: SingleValue<SelectValue>) => {
    if (val?.value && val?.label && val.value !== selectedChannel?.id) {
      setSelectedChannel({
        id: val.value as DistributionChannel,
        name: val.label,
      })
      setSelectedProjects([])
      setSelectedCampaigns([])
      applyDates(undefined, undefined)
    }
  }

  const onChangeProjects = (values: string[]) => {
    if (!isEqual(values, selectedProjects)) {
      setSelectedProjects(values)
      setSelectedCampaigns([])

      if (!values.length) {
        applyDates(undefined, undefined)
      }
    }
  }

  const onChangeCampaigns = (values: string[]) => {
    setSelectedCampaigns(values)
  }

  const runReport = async () => {
    setIsLoading(true)

    try {
      dispatch(setError(false))
      const result = await fetchWidgetList({
        accountId,
        projectIds: selectedProjects,
        campaignIds: selectedCampaigns,
        startDate: formatIsoDate(selectedStartDate),
        endDate: formatIsoDate(selectedEndDate),
      })

      dispatch(
        applyFiltersAndData({
          widgetList: result.data,
          product: selectedProduct,
          channel: selectedChannel,
          projects: selectedProjects,
          campaigns: selectedCampaigns,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        })
      )
    } catch {
      dispatch(setError(true))
    } finally {
      setIsLoading(false)
    }
  }

  const applyDates = (start: string | undefined, end: string | undefined) => {
    setSelectedStartDate(start)
    setSelectedEndDate(end)
  }

  const shouldApplySendDateRange =
    !isReact && (hasSelectedProjects || selectedCampaigns.length)

  return (
    <div className="lg:mt-4 mb-10">
      <PageTitle title={title} className="mb-8" />

      <div className="flex flex-wrap gap-y-2 gap-x-4" ref={flexWrapperRef}>
        {isProductFilterEnabled && (
          <SingleSelect
            className="w-36 flex-none"
            data-cy="reports-type-select"
            name="types"
            label={headerText.productSelectLabel}
            placeholder="All types"
            onChange={onChangeProduct}
            value={selectedProduct}
            options={mapProductOptionToMultiSelect(products)}
          />
        )}
        {isChannelFilterEnabled && (
          <SingleSelect
            className="w-36 flex-none"
            data-cy="reports-channel-select"
            name="channels"
            label={headerText.channelSelectLabel}
            placeholder="All channels"
            onChange={onChangeChannel}
            value={selectedChannel?.id}
            options={mapOptionToMultiSelect<ChannelOption>(channels)}
            isDisabled={
              (isProductFilterEnabled && selectedProduct === undefined) ||
              isLoadingFilters
            }
          />
        )}
        <div className={styles.break1}></div>
        {isProjectFilterEnabled && (
          <ProjectsSelect
            className="min-w-50 flex-1"
            data-cy="reports-project-select"
            selectedItems={selectedProjects}
            onApply={onChangeProjects}
            isDisabled={isChannelFilterEnabled && selectedChannel === undefined}
            selectedProduct={selectedProduct}
            selectedChannel={selectedChannel}
          />
        )}
        <div className={styles.break2}></div>
        <CampaignSelector
          className="min-w-50 flex-1"
          selectedItems={selectedCampaigns}
          onApply={onChangeCampaigns}
          placeholder="All experiments"
          projectFilter={selectedProjects}
          channelFilter={selectedChannel?.id}
          productFilter={selectedProduct}
          isDisabled={isProjectFilterEnabled && selectedProjects.length === 0}
          applyDates={shouldApplySendDateRange ? applyDates : undefined}
        />
        <DateRangePicker
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          applyDates={applyDates}
          isDisabled={isDateRangeDisabled}
          hasTooltip={isReact}
          tooltipText="Date range is only available for Broadcast reports"
          flexWrapperRef={flexWrapperRef}
          areDefaultOptionsDisabled={!!selectedCampaigns.length}
        />
        <Button
          className="self-end"
          data-cy="run-performance-report-button"
          data-testid="run-performance-report-button"
          variant="primary"
          disabled={isButtonDisabled}
          onClick={runReport}
          loading={isLoading}
        >
          <span className="text-base hidden md:inline">
            {headerText.runReportButtonText}
          </span>
          <span className="text-base inline md:hidden">Run</span>
        </Button>
      </div>
    </div>
  )
}

export default Header
