import { DistributionType } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { AxiosResponse } from 'axios'

import { connectInternalApi } from './api'

export type PeriodType = 'weekly' | 'monthly' | 'yearly' | 'aggregate'
export type ReportingLevel = 'account' | 'project' | 'campaign'
type CalculationType = 'cumulative' | 'summary'

interface Filters {
  account_id: string
  project_ids: string[]
  start_date: string
  end_date: string
  period_type: PeriodType
}

interface ReportItemValue {
  label: string
  value: number
  distribution_channel: string
}

interface ReportUpliftLeveledItemValue {
  name: string
  values: ItemValue[]
}

interface ReportItem {
  period_label: string
  values: ReportItemValue[]
}

export interface ReportUpliftLeveledItem {
  period_label: string
  values: ReportUpliftLeveledItemValue[]
}

export interface ReportResponse {
  data: ReportItem[]
  filters: Filters
}

export interface ReportLeveledResponse {
  data: ReportUpliftLeveledItem[]
  filters: Filters
}

interface ReportSummaryItem {
  period_label: string
  values: ReportSummaryItemValue[]
}

export interface ReportSummaryItemValue {
  name: string
  values: ItemValue[]
}

export interface ItemValue {
  label: string
  value: number
}

export interface ReportSentimentResponse {
  data: {
    data: ReportUpliftLeveledItem[]
  }
  filters: Filters
}

export interface ReportSummaryResponse {
  data: ReportSummaryItem[]
  filters: Filters
}

export type BaseParams = {
  periodType?: string
  projectIds?: string[]
  startDate?: string
  endDate?: string
}

export type UpliftType =
  | 'email_opens'
  | 'push_direct_opens'
  | 'push_influenced_opens'
  | 'email_clicks'
  | 'sms_clicks'
  | 'inemail_clicks'
  | 'conversions'
  | 'revenue'

export const fetchUsageReport = ({
  accountId,
  usageType,
  projectIds,
  periodType,
  distributionChannel,
  startDate,
  endDate,
}: {
  accountId: string
  usageType?: ('campaigns' | 'variants')[]
  distributionChannel?: string[]
} & Partial<BaseParams>) => {
  const optionalParams =
    startDate && endDate ? { start_date: startDate, end_date: endDate } : {}
  return connectInternalApi.get<ReportResponse>(
    `v1/core/reporting/reporting/accounts/${accountId}/usage/report`,
    {
      params: {
        usage_type: usageType,
        project_ids: projectIds,
        period_type: periodType,
        distribution_channel: distributionChannel,
        ...optionalParams,
      },
    }
  )
}

export const fetchUpliftReport = <T extends ReportingLevel | undefined>({
  accountId,
  projectIds,
  periodType,
  upliftType,
  testingMethod,
  startDate,
  endDate,
  reportingLevel,
  distributionType,
}: {
  accountId: string
  testingMethod?: string[]
  upliftType?: UpliftType[]
  reportingLevel?: T
  distributionType?: DistributionType
} & BaseParams): Promise<
  AxiosResponse<T extends undefined ? ReportResponse : ReportLeveledResponse>
> => {
  const optionalParams =
    startDate && endDate ? { start_date: startDate, end_date: endDate } : {}
  return connectInternalApi.get(
    `v1/core/reporting/reporting/accounts/${accountId}/uplift/report`,
    {
      params: {
        period_type: periodType,
        uplift_type: upliftType,
        testing_method: testingMethod,
        project_ids: projectIds,
        reporting_level: reportingLevel,
        by_level: !!reportingLevel,
        distribution_type: distributionType,
        ...optionalParams,
      },
    }
  )
}

export const fetchIncrementalReport = ({
  accountId,
  projectIds,
  periodType,
  eventType,
  testingMethod,
  calculationType,
  reportingLevel,
  startDate,
  endDate,
}: {
  accountId: string
  eventType: string[]
  testingMethod: string[]
  calculationType: CalculationType
  reportingLevel?: ReportingLevel
} & BaseParams): Promise<AxiosResponse<ReportResponse>> => {
  const optionalParams =
    startDate && endDate ? { start_date: startDate, end_date: endDate } : {}
  return connectInternalApi.get(
    `v1/core/reporting/reporting/accounts/${accountId}/incremental/report`,
    {
      params: {
        period_type: periodType,
        event_type: eventType,
        testing_method: testingMethod,
        project_ids: projectIds,
        calculation_type: calculationType,
        reporting_level: reportingLevel,
        ...optionalParams,
      },
    }
  )
}
