import React from 'react'
import * as Highcharts from 'highcharts/highstock'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'
import { getLabelWithCheckbox, yAxisConfig } from 'common/helpers/ui/graph'

export interface IncrementalEventsData {
  data: number[]
  name: string
  average: number
}

interface Props {
  data: IncrementalEventsData[]
  xAxisLabels: string[]
  ref: React.RefCallback<ChartInstance>
  type: 'opens' | 'clicks'
}

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
})

const colorPerIndex = {
  0: '#2dd4bf',
  1: '#901356',
  2: '#38bdf8',
  3: '#a78bfa',
  4: '#f87171',
  5: '#f472b6',
  6: '#a3e635',
}

export default React.forwardRef<ChartInstance, Props>(
  ({ xAxisLabels, data, type }, ref) => {
    const options: Highcharts.Options = {
      title: undefined,
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          theme: {
            stroke: 'none',
          },
        },
      },
      navigator: {
        series: {
          color: '#61bec7',
          type: 'areaspline',
        },
      },
      xAxis: {
        categories: xAxisLabels,
        labels: {
          style: {
            color: '#6b7280',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 0.8,
          },
        },
      },
      yAxis: {
        ...yAxisConfig,
        labels: {
          formatter: function (axis) {
            if (this.value > 1000) {
              return `${Highcharts.numberFormat(
                (axis.value as number) / 1000,
                0
              )}K`
            }
            return Highcharts.numberFormat(axis.value as number, 0)
          },
          style: {
            color: '#6b7280',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 0.8,
          },
          padding: 0,
          align: 'right',
          reserveSpace: true,
        },
      },
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
        },
        spacing: [10, 0, 10, 0],
      },
      tooltip: {
        shared: true,
        split: false,
        useHTML: true,
        borderRadius: 5,
        backgroundColor: '#ffffff',
        shadow: true,
        formatter: function () {
          const series = this.points?.map(
            (point) =>
              `<span style="color:${
                point.color
              }; font-size: 16px; margin-right: 6px;">■</span>
            ${point.y} ${(point.series.options as any).labelTooltip}</br>`,
            ''
          )

          const hasNegativePoint = this.points?.some(({ y }) => y && y < 0)

          if (hasNegativePoint && series?.length) {
            const message = `<div style="margin-top: 6px">Negative ${type} may occur on non-primary metrics for experiments.</div>`
            return [...series, message]
          } else {
            return series
          }
        },
      },
      legend: {
        y: 14,
        enabled: true,
        useHTML: true,
        labelFormatter: getLabelWithCheckbox(),
        itemStyle: {
          fontSize: '16px',
          fontWeight: 'normal',
          color: '#1f2937',
          opacity: 0.8,
          lineHeight: '32px',
        },
        symbolWidth: 0,
        margin: 0,
        padding: 22,
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          stickyTracking: false,
          dataGrouping: {},
          lineWidth: 2,
          states: {
            hover: {
              enabled: false,
            },
          },
          marker: {
            enabled: true,
            symbol: 'circle',
            fillColor: '#ffffff',
            lineColor: undefined,
            lineWidth: 2,
            radius: 4,
          },
        },
      },
      series: data.map((r, index) => ({
        type: 'spline',
        name: `<span style='font-size: 16px;'>${r.name}</span>`,
        data: r.data,
        labelTooltip: r.name,
        color: colorPerIndex[index],
      })),
    }
    return (
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        constructorType="chart"
        options={options}
        immutable={true}
      />
    )
  }
)
