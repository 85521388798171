import React from 'react'
import cx from 'classnames'

type Props = {
  className?: string
}

const PageContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <div
      className={cx(
        className,
        'flex flex-col w-full flex-1 xl:mx-auto xl:max-w-363 relative'
      )}
    >
      {children}
    </div>
  )
}

export default PageContainer
