import { useMutation, useQueryClient } from '@tanstack/react-query'

import { replaceVariant } from '../api'
import { contentKeys } from '../queryKeys'

const useReplaceVariantMutation = (contentId) => {
  const queryClient = useQueryClient()

  return useMutation(replaceVariant, {
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.content(contentId))
    },
  })
}

export default useReplaceVariantMutation
