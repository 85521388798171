import React, { useEffect, useRef, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import ExperimentFilterPanel from 'app/navigation/ExperimentFilterPanel'
import { openFilterPanel } from 'app/navigation/navigationSlice'
import { campaignsPath } from 'app/navigation/paths'
import { staticCampaignSubRoutes } from 'app/navigation/staticSubRoutes.campaigns'

import {
  experimentsPermissions,
  getIsAuthorized,
} from 'common/auth/permissions'
import Spinner from 'common/components/spinner'
import SubNav, { Count, Error, Header } from 'common/components/subNav'
import SearchInput from 'common/components/subNav/components/searchInput'
import Tags from 'common/components/tags'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { FiltersCentered as Filter } from 'common/icons'
import {
  CAMPAIGNS_PAGE_SIZE,
  clickDoneCampaigns,
  clickEditCampaigns,
  fetchCampaignsByPageAsync,
  markAsScrolledToCampaign,
  selectSearchFilter,
  selectStatusFilter,
} from 'features/campaigns/store/campaignSlice'
import { AvatarColor, avatarColors } from 'features/profile/AvatarColorPicker'

import { InlineError } from '../errors'

import CampaignNavItem from './CampaignNavItem'

const AVATAR_COLORS = avatarColors

interface Props {
  initialItemCount?: number
}

const memoizedColorPerUser = () => {
  const cache: { [key: string]: AvatarColor } = {}

  return (id: string, index: number): AvatarColor => {
    const color = cache[id]

    if (!color) {
      cache[id] = AVATAR_COLORS[index % AVATAR_COLORS.length]
      return cache[id]
    }

    return color
  }
}

const getUserColor = memoizedColorPerUser()

const CampaignNav = ({ initialItemCount }: Props) => {
  const dispatch = useAppDispatch()
  const {
    count: campaignsCount,
    campaigns,
    status,
    selectedCampaignId,
    selectedProjectName,
    shouldScrollToCampaignId,
    isEditing,
    filters,
    isNewCampaign,
    isCampaignNavCollapsed,
  } = useAppSelector((state) => state.campaigns)
  const {
    profile: { avatarColor },
    user_id: currentUserId,
    accountId,
    permissions,
  } = useAppSelector((state) => state.authStates)
  const { isFilterNavCollapsed } = useAppSelector((state) => state.navigation)

  const selectedCampaignStateFilter =
    selectedProjectName || filters.distributionChannel || filters.status

  const hasEditPermission = getIsAuthorized(
    permissions,
    experimentsPermissions.edit
  )

  const [search, setSearch] = useState<string>('')
  const [nextPage, setNextPage] = useState<number>(0)

  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const headerRef = useRef<HTMLDivElement>(null)

  const numberOfPages = campaignsCount
    ? Math.ceil(campaignsCount / CAMPAIGNS_PAGE_SIZE)
    : undefined

  const loadMore = () => {
    if ((numberOfPages && nextPage > numberOfPages) || status === 'loading') {
      return
    }

    dispatch(
      fetchCampaignsByPageAsync({
        pageNumber: nextPage,
      })
    )

    setNextPage(nextPage + 1)
  }

  useEffect(() => {
    const promise = dispatch(fetchCampaignsByPageAsync({ pageNumber: 1 }))
    setNextPage(2)

    return () => {
      promise.abort()
    }
  }, [dispatch, filters, accountId])

  useEffect(() => {
    // throttle the request using timeout
    const timeoutID = setTimeout(() => {
      dispatch(selectSearchFilter(search))
    }, 500)

    return () => {
      clearTimeout(timeoutID)
    }
  }, [search, dispatch])

  useEffect(() => {
    const campaignIndex = campaigns.findIndex(
      (campaign) => campaign.id === selectedCampaignId
    )
    const hasFoundCampaignIndex = campaignIndex !== -1
    if (
      hasFoundCampaignIndex &&
      campaigns.length > 0 &&
      status !== 'loading' &&
      selectedCampaignId &&
      shouldScrollToCampaignId
    ) {
      virtuosoRef.current?.scrollToIndex(campaignIndex)
      dispatch(markAsScrolledToCampaign())
    }
  }, [
    status,
    campaigns,
    dispatch,
    selectedCampaignId,
    shouldScrollToCampaignId,
  ])

  const Row = (index: number) => {
    const item = campaigns[index]
    const { id, name, userId } = item

    const color =
      userId === currentUserId ? avatarColor : getUserColor(userId, index)

    // average char count that breaks to more than 4 lines
    // not a perfect solution as different chars have different width
    const isTooltipShown = name.length >= 86

    return (
      <Tooltip
        key={id}
        show={isTooltipShown}
        overlay={name}
        placement="left"
        overlayClassName="max-w-xs break-words"
      >
        <CampaignNavItem
          key={id}
          color={color}
          campaign={item}
          isSelected={id === selectedCampaignId}
        />
      </Tooltip>
    )
  }

  const getFooter = () => {
    switch (status) {
      case 'failed':
        return InlineError
      case 'loading':
        return () => <Spinner className="m-4" data-testid="next-page-loader" />
      default:
        return undefined
    }
  }

  const getUiStatus = () => {
    if (campaignsCount === undefined && status === 'loading') {
      return 'loading'
    } else if (!campaignsCount && status === 'failed') {
      return 'failed'
    } else {
      return 'data'
    }
  }

  const removeExperimentFilter = () => {
    dispatch(selectStatusFilter('all'))
  }

  const getExperimentFilterName = (filter) => {
    if (selectedProjectName) {
      return filter
    }
    const filtersList = staticCampaignSubRoutes.filter(
      (route) => route.key === filter
    )

    return filtersList[0].title
  }

  const hasNoData = !campaignsCount && status === 'idle'
  const hasNoSearchResults =
    search.length && status === 'idle' && campaignsCount === 0

  return (
    <>
      {!isFilterNavCollapsed && <ExperimentFilterPanel />}
      <SubNav isCollapsed={isCampaignNavCollapsed} hasOverlay={isNewCampaign}>
        {
          {
            loading: <Spinner />,
            data: (
              <>
                <Header ref={headerRef}>
                  <div className="mb-4 flex justify-between items-start">
                    <Link
                      to={generatePath(campaignsPath, { accountId })}
                      onClick={() => {
                        dispatch(openFilterPanel())
                      }}
                    >
                      <Filter />
                    </Link>

                    <Count value={campaignsCount as number} name="experiment" />
                    {hasEditPermission && (
                      <Link
                        to={generatePath(campaignsPath, { accountId })}
                        className="text-base text-coolGray-800 font-medium opacity-50"
                        onClick={() => {
                          dispatch(
                            isEditing
                              ? clickDoneCampaigns()
                              : clickEditCampaigns()
                          )
                        }}
                        data-cy="toggle-edit"
                      >
                        {isEditing ? 'Done' : 'Edit'}
                      </Link>
                    )}
                  </div>

                  <SearchInput
                    data-cy="campaign-search"
                    value={search}
                    onChange={(value) => setSearch(value)}
                  />

                  {selectedCampaignStateFilter &&
                    selectedCampaignStateFilter !== 'all' && (
                      <div className="mt-4">
                        <Tags
                          isFreeText={false}
                          onRemoveClick={() => {
                            removeExperimentFilter()
                          }}
                          tags={[
                            {
                              label: getExperimentFilterName(
                                selectedCampaignStateFilter
                              ),
                              value: selectedCampaignStateFilter,
                            },
                          ]}
                          data-cy="experiment-filter-tag"
                          maxLength={40}
                        />
                      </div>
                    )}
                </Header>
                {hasNoSearchResults ? (
                  <div
                    data-cy="no-search-results-message"
                    className="pt-4 px-4"
                  >
                    No experiments match search
                  </div>
                ) : hasNoData ? (
                  <div data-cy="no-data-message" className="pt-4 px-4">
                    No experiments
                  </div>
                ) : undefined}
                <Virtuoso
                  key={JSON.stringify({ filters })} // used to handle https://github.com/petyosi/react-virtuoso/issues/182
                  ref={virtuosoRef}
                  style={{ maxHeight: 'calc(100vh - 210px)' }}
                  totalCount={campaigns.length}
                  endReached={() => {
                    loadMore()
                  }}
                  initialItemCount={initialItemCount}
                  overscan={200}
                  itemContent={Row}
                  data-cy="campaign-nav-list"
                  components={{
                    Footer: getFooter(),
                  }}
                />
              </>
            ),
            failed: <Error name="experiment" />,
          }[getUiStatus()]
        }
      </SubNav>
    </>
  )
}

export default React.memo(CampaignNav)
