import { useQuery } from '@tanstack/react-query'

import Modal, { ModalProps } from 'common/components/Modal'
import { Column } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget/TableWidget'
import { Node } from 'common/interfaces/nodes'
import { getEmptyNodes } from 'features/content/api'
import { contentKeys } from 'features/content/api/queryKeys'

const columns: Column<Node>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
]

const EmptyNodesModal = (props: ModalProps) => {
  const { status, data } = useQuery<Node[]>(
    contentKeys.emptyNodes(),
    getEmptyNodes
  )

  return (
    <Modal title="Empty nodes" {...props}>
      <TableWidget.Widget
        isLoading={status === 'loading'}
        hasError={status === 'error'}
        columns={columns}
        data={data}
        hasWidgetMenu={false}
      >
        <TableWidget.Header>
          <TableWidget.Filter />
        </TableWidget.Header>
      </TableWidget.Widget>
    </Modal>
  )
}

export default EmptyNodesModal
