import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import {
  hideLeftPanel,
  selectElementAndFetchCampaignData,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import ElementCard from '../../components/ElementCard'
import ExperimentTypeBadges from '../../components/ExperimentTypeBadges'
import LeftPanel from '../../components/layout/LeftPanel'
import { getOptimizeElements } from '../../helpers'
import { useContent } from '../../hooks'

const LeftSection = () => {
  const dispatch = useAppDispatch()
  const { content } = useContent()

  const isLeftPanelVisible = useAppSelector(
    (state) => state.unifiedFlow.isLeftPanelVisible
  )

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  const optimizeElements = getOptimizeElements(content?.elements)

  // TODO: HH-4889 Add badges with proper data when we connect with the backend

  return (
    <LeftPanel
      isVisible={isLeftPanelVisible}
      onOutsideClick={() => dispatch(hideLeftPanel())}
    >
      {optimizeElements.map((element) => {
        const { element_id, name, display_name, icon, status } = element
        const isElementDisabled = status === 'disabled'
        const badgesState = getExperimentBadgesState(element)

        return (
          <ElementCard
            className="mb-4"
            key={element_id}
            element={element}
            title={display_name || name}
            icon={icon}
            isRemoveOptionShown={false}
            isDisabled={isElementDisabled}
            isSelected={element_id === selectedElementId && !isElementDisabled}
            isConfigurable={false}
            hasElementActionsMenu={false}
            content={badgesState && <ExperimentTypeBadges {...badgesState} />}
            onClick={() =>
              !isElementDisabled &&
              dispatch(selectElementAndFetchCampaignData(element))
            }
          />
        )
      })}
    </LeftPanel>
  )
}

export default LeftSection
