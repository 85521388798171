import cx from 'classnames'

import Badge from 'common/components/Badge'

type Props = {
  isAwaitingBrief: boolean
  isOptimizable: boolean
  isPersonalizable: boolean
  className?: string
}

const ExperimentTypeBadges = ({
  isAwaitingBrief,
  isOptimizable,
  isPersonalizable,
  className,
}: Props) => {
  return (
    <div className={cx('flex flex-wrap gap-1', className)}>
      {isAwaitingBrief && (
        <Badge text="Awaiting brief" variant="warning" size="small" />
      )}
      {isOptimizable && (
        <Badge text="Optimize" variant="optimize" size="small" />
      )}
      {isPersonalizable && (
        <Badge text="Personalize" variant="personalize" size="small" />
      )}
    </div>
  )
}

export default ExperimentTypeBadges
