import { useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { isEqual } from 'lodash'

import Button from 'common/components/button'
import Spinner from 'common/components/spinner'
import Widget from 'common/components/Widget'
import { ReactComponent as Refresh } from 'common/icons/refresh/default.svg'

import useGetVariantsQuery from '../api/queries/useGetVariantsQuery'
import useDemoData from '../hooks/useDemoData'

import DemoTable from './DemoTable'
import SingleSelectField from './SingleSelectField'

export type FormValues = {
  previousVariantsIds: string[]
  brand: string
  channel: string
}

const DemoContent = () => {
  const { brands, channels } = useDemoData()

  const [formState, setFormState] = useState<FormValues>({
    previousVariantsIds: [],
    brand: '',
    channel: '',
  })

  const variantsQuery = useGetVariantsQuery({
    prevVariantsIds: formState.previousVariantsIds,
    brand: formState.brand,
    channel: formState.channel,
  })

  return (
    <Form<FormValues>
      initialValues={{
        brand: brands?.[0].value,
        channel: channels?.[0].value,
      }}
      onSubmit={() => {}}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} className="pb-5">
            <div className="flex justify-between mb-3">
              <div className="flex flex-col gap-1.5 max-w-318">
                <h2 className="text-xl font-medium uppercase">
                  {brands?.find((brand) => brand.value === values.brand)?.name}
                </h2>
                <p>
                  {
                    brands?.find((brand) => brand.value === values.brand)
                      ?.description
                  }
                </p>
              </div>
              <div className="flex gap-6 mt-2 ml-6">
                <SingleSelectField
                  name="brand"
                  label="Brand"
                  options={brands ?? []}
                  className="w-48"
                />
                <SingleSelectField
                  name="channel"
                  label="Channel"
                  options={channels ?? []}
                  className="w-48"
                />
              </div>
            </div>
            <div className="flex justify-end mb-2">
              <Button
                variant="link"
                className="uppercase"
                onClick={() => {
                  setFormState((prevState) => {
                    return {
                      ...prevState,
                      previousVariantsIds:
                        variantsQuery.data?.map(
                          (variant) => variant.variantId
                        ) ?? [],
                    }
                  })
                }}
              >
                Regenerate variants
                <Refresh className="ml-2" width={24} height={24} />
              </Button>
            </div>
            {variantsQuery.isLoading ? (
              <Widget className="flex w-full justify-center min-h-200  bg-white">
                <Spinner />
              </Widget>
            ) : (
              <DemoTable data={variantsQuery.data ?? []} />
            )}
            <FormSpy<FormValues>
              onChange={(formState) => {
                if (formState.values) {
                  setFormState((prevState) => ({
                    ...prevState,
                    ...formState.values,
                    ...(!isEqual(values, formState.values) && {
                      previousVariantsIds: [],
                    }),
                  }))
                }
              }}
            />
          </form>
        )
      }}
    />
  )
}

export default DemoContent
