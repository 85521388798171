import { Element } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import CardIcon from '../../components/CardIcon'

import ActionsMenu from './ActionsMenu'

type Props = {
  title: string
  className?: string
  description?: string
  element?: Element
  icon?: string
  isDisabled?: boolean
  isSelected?: boolean
  isConfigurable?: boolean
  onClick?: () => void
}

const WorkflowCard = ({
  title,
  className,
  description,
  element,
  icon,
  isDisabled = false,
  isSelected = false,
  isConfigurable = false,
  onClick,
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        'relative flex items-center p-4 border border-coolGray-300 group',
        className,
        {
          'bg-coolGray-100 border-coolGray-200 cursor-not-allowed': isDisabled,
          'bg-white border-coolGray-300 cursor-pointer hover:border-maroon-300':
            !isDisabled,
          'border-1.5 border-maroon-500': isSelected,
        }
      )}
    >
      {icon && (
        <CardIcon
          icon={icon}
          className={cx('mr-4', { 'text-maroon-300': isSelected })}
        />
      )}
      <div className="font-medium overflow-hidden">
        <div
          className={cx({
            'text-base text-coolGray-800': !description && !isDisabled,
            'text-base text-coolGray-400': !description && isDisabled,
            'text-xs text-coolGray-500 mb-1':
              description && !isDisabled && !isSelected,
            'text-xs text-maroon-500 mb-1':
              description && !isDisabled && isSelected,
            'text-xs text-coolGray-400 mb-1': description && isDisabled,
          })}
          aria-disabled={isDisabled}
        >
          {title}
        </div>
        {description && (
          <div
            className={cx('text-base truncate', {
              'text-coolGray-400': isDisabled,
              'text-coolGray-800': !isDisabled,
            })}
          >
            {description}
          </div>
        )}
      </div>
      <ActionsMenu
        isConfigurable={isConfigurable}
        isDisabled={isDisabled}
        isSelected={isSelected}
        element={element}
      />
    </div>
  )
}

export default WorkflowCard
