import { useEffect, useState } from 'react'
import { DashboardMode as DashboardModeBackend } from '@phrasee/phrasee-typings/Graphql/interfaces'
import Content from 'app/Content'

import { showErrorToast } from 'common/api/helpers'
import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Tabs, { addCountToTabName, Tab } from 'common/components/tabs'
import { successToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { callbackOAuth } from 'features/auth/store/authSlice'

import AccountsDrawer, {
  AccountsDrawerProps,
} from './components/accountsDrawer'
import Header from './components/header'
import Table from './components/table'
import { PrivacyRegions } from './api'
import api from './api'

export type DashboardMode = DashboardModeBackend

type AccountStatus = 'active' | 'archived' | 'deactivated'

export interface Account {
  id: string
  companyName: string
  projectsCount: number
  campaignsCount: number
  usersCount: number
  status: AccountStatus
  industry?: string
  logoUrl?: string
  accountCategory?: string
  dashboardMode?: DashboardMode
  privacyRegions?: PrivacyRegions
}

const Accounts = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [accounts, setAccounts] = useState<Account[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [modalState, setModalState] = useState<
    Pick<AccountsDrawerProps, 'isOpen' | 'mode' | 'account'>
  >({
    isOpen: false,
    mode: 'create',
  })

  const userId = useAppSelector((state) => state.authStates.user_id)
  const dispatch = useAppDispatch()

  const filteredBySearchAccounts = searchTerm
    ? accounts.filter((account) =>
        account.companyName
          .replaceAll(' ', '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase().replaceAll(' ', ''))
      )
    : accounts
  const activeAccounts = filteredBySearchAccounts.filter(
    (account) => account.status === 'active'
  )
  const activeAccountsCount = accounts.filter(
    (account) => account.status === 'active'
  ).length
  const deactivatedAccounts = filteredBySearchAccounts.filter(
    (account) => account.status === 'deactivated'
  )
  const deactivatedAccountsCount = accounts.filter(
    (account) => account.status === 'deactivated'
  ).length
  const archivedAccounts = filteredBySearchAccounts.filter(
    (account) => account.status === 'archived'
  )
  const archivedAccountsCount = accounts.filter(
    (account) => account.status === 'archived'
  ).length

  const updateAccount = async (
    id: string,
    params: Partial<Account>
  ): Promise<void> => {
    try {
      setIsLoading(true)
      await api.updateAccountStatus(id, params)

      const updatedAccounts = accounts.map((account) => {
        if (account.id === id) {
          return { ...account, ...params }
        } else {
          return account
        }
      })

      setAccounts(updatedAccounts)
    } catch (error: any) {
      showErrorToast(error)
    } finally {
      setIsLoading(false)
    }
  }

  const deactivateAccount = (id: string): void => {
    updateAccount(id, {
      status: 'deactivated',
    })
  }

  const reactivateAccount = (id: string): void => {
    updateAccount(id, {
      status: 'active',
    })
  }

  const archiveAccount = (id: string): void => {
    updateAccount(id, {
      status: 'archived',
    })
  }

  const editAccount = (account: Account): void => {
    setModalState({
      isOpen: true,
      mode: 'edit',
      account,
    })
  }

  const addMeToAccount = async (id: string): Promise<void> => {
    try {
      setIsLoading(true)
      await api.addUserToAccount({ accountId: id, userId })
      dispatch(callbackOAuth(true))
      successToast('Success! Account added')
    } catch (error: any) {
      showErrorToast(error)
    } finally {
      setIsLoading(false)
    }
  }

  const removeMeFromAccount = async (id: string): Promise<void> => {
    try {
      setIsLoading(true)
      await api.removeUserFromAccount({ accountId: id, userId })
      dispatch(callbackOAuth(true))
      successToast('Success! The account has been removed')
    } catch (error: any) {
      showErrorToast(error)
    } finally {
      setIsLoading(false)
    }
  }

  const commonTableProps = {
    isLoading,
    hasError,
    editAccount,
    archiveAccount,
    deactivateAccount,
    reactivateAccount,
    addMeToAccount,
    removeMeFromAccount,
  }

  const tabs: Tab[] = [
    {
      key: 'active',
      name: addCountToTabName({
        tabName: 'Active',
        count: activeAccountsCount,
      }),
      content: (
        <Table
          {...commonTableProps}
          accounts={activeAccounts}
          title="Active accounts"
        />
      ),
    },
    {
      key: 'deactivated',
      name: addCountToTabName({
        tabName: 'Deactivated',
        count: deactivatedAccountsCount,
      }),
      content: (
        <Table
          {...commonTableProps}
          accounts={deactivatedAccounts}
          title="Deactivated"
        />
      ),
    },
    {
      key: 'archived',
      name: addCountToTabName({
        tabName: 'Archived',
        count: archivedAccountsCount,
      }),
      content: (
        <Table
          {...commonTableProps}
          accounts={archivedAccounts}
          title="Archived"
        />
      ),
    },
  ]

  const getAccountsList = async () => {
    try {
      setIsLoading(true)
      const response = await api.getAccountsList(userId)

      setAccounts(response)
    } catch (e) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getAccountsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <Content>
      <PageContainer>
        <PageHeader>
          <PageTitle title="Accounts" />
        </PageHeader>
        <div className="relative">
          <Tabs
            tabs={tabs.filter((tab) => !tab.isDisabled)}
            defaultActiveKey="active"
            variant="inPage"
            moreIcon={null}
          />
          <div className="flex gap-3 absolute top-5 right-0">
            <Header
              accounts={accounts}
              onSearch={setSearchTerm}
              onAdd={() => setModalState({ isOpen: true, mode: 'create' })}
            />
          </div>
        </div>
        <AccountsDrawer
          {...modalState}
          onClose={(shouldRefetch) => {
            setModalState((prev) => ({ ...prev, isOpen: false }))
            if (shouldRefetch) {
              getAccountsList()
            }
          }}
        />
        <Footer />
      </PageContainer>
    </Content>
  )
}

export default Accounts
