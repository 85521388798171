import { ExperimentTypeStatus } from '@phrasee/phrasee-typings/typings/futurama/experiment_type'
import cx from 'classnames'

import Badge from 'common/components/Badge'
import Tooltip from 'common/components/Tooltip'
import { Encrypted } from 'common/icons'

type ExperimentTypeProps = {
  name:
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'neutral'
    | 'optimize'
    | 'personalize'
  status: ExperimentTypeStatus
  className?: string
}

const ExperimentType = ({ name, status, className }: ExperimentTypeProps) => {
  const isBlocked = status === 'blocked'
  const isDisabled = status === 'disabled'

  const text = (
    <>
      {isBlocked && <Encrypted isDefaultColor={false} size={4} />}
      {name}
    </>
  )

  return !isDisabled ? (
    <Tooltip
      overlay="Talk to us about upgrading"
      placement="top"
      show={isBlocked}
    >
      <Badge
        text={text}
        variant={name}
        size="small"
        className={cx('capitalize mr-2 inline-block border-0', className)}
      />
    </Tooltip>
  ) : null
}

export default ExperimentType
