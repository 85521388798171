import cx from 'classnames'

import { Edit, Switch } from 'common/icons'

import { useTweakVariant } from '../context/TweakVariantContext'

import Item from './Item'

const TypeSwitch = () => {
  const {
    selectedState,
    updateState,
    feedbackEditEnabled,
    feedbackRejectEnabled,
  } = useTweakVariant()

  return (
    <div className="flex">
      {feedbackRejectEnabled && (
        <Item
          data-testid="replace-type"
          className={cx({
            ' mr-6': feedbackEditEnabled,
          })}
          onClick={() => updateState({ selectedState: 'replace' })}
          icon={Switch}
          title="Replace"
          isSelected={selectedState === 'replace'}
        />
      )}
      {feedbackEditEnabled && (
        <Item
          data-testid="edit-type"
          onClick={() => updateState({ selectedState: 'edit' })}
          icon={Edit}
          title="Edit"
          isSelected={selectedState === 'edit'}
        />
      )}
    </div>
  )
}

export default TypeSwitch
