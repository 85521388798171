import { NLGStatus } from '@phrasee/phrasee-typings'
import { AnyAction, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import * as types from 'redux/actionTypes'

import { CancelToken } from 'common/api'
import { Fragment, Topic } from 'common/components/topics/interfaces'

import { getContentCards } from '../api'
import { parseTopics } from '../api/helpers'

interface ContentGenerationState {
  topics: Topic[]
  currentTopics: Topic[]
  fragments: Fragment[]
  descriptionFragments: Fragment[]
  hasErrorLoadingTopics: boolean
  nlgStatus?: NLGStatus
}

export const initialState: ContentGenerationState = {
  topics: [],
  currentTopics: [],
  fragments: [],
  descriptionFragments: [],
  hasErrorLoadingTopics: false,
  nlgStatus: undefined,
}

export const loadContentCards = createAsyncThunk(
  'contentGeneration/getCards',
  async ({
    campaignId,
    humanLine,
    cancelToken,
  }: {
    campaignId: string
    humanLine: string
    cancelToken: CancelToken
  }) => {
    return await getContentCards(campaignId, humanLine, cancelToken)
  }
)

const contentGenerationSlice = createSlice({
  name: 'contentGeneration',
  initialState,
  reducers: {
    revertTopics: (state) => {
      state.currentTopics = state.topics
    },
    updateTopics: (state, action: PayloadAction<Topic[]>) => {
      state.topics = action.payload
      state.currentTopics = action.payload
      state.nlgStatus = 'finished'
    },
    updateCurrentTopics: (state, action: PayloadAction<Topic[]>) => {
      state.currentTopics = action.payload
    },
    generationStarted: (state) => {
      state.nlgStatus = 'pending'
    },
    generationError: (state) => {
      state.nlgStatus = 'error'
    },
  },
  extraReducers: (builder) => {
    const campaignDetailsReducer = (state, action: AnyAction) => {
      const parsedTopics = parseTopics(
        action.campaignData.contentGeneration?.nodes,
        action.campaignData.contentGeneration?.replacers
      )

      state.topics = parsedTopics
      state.currentTopics = parsedTopics
      state.fragments = action.campaignData.contentGeneration?.fragments || []
      state.descriptionFragments =
        action.campaignData.contentGeneration?.descriptionFragments || []
      state.hasErrorLoadingTopics = false
      state.nlgStatus =
        action.campaignData.contentGeneration?.nlg_status || state.nlgStatus
    }

    builder
      .addCase(loadContentCards.pending, (state) => {
        state.hasErrorLoadingTopics = false
      })
      .addCase(loadContentCards.fulfilled, (state, action) => {
        const { topics, fragments, descriptionFragments } = action.payload

        state.fragments = fragments
        state.descriptionFragments = descriptionFragments
        state.topics = topics
        state.currentTopics = topics
      })
      .addCase(loadContentCards.rejected, (state) => {
        state.hasErrorLoadingTopics = true
      })
      .addCase(types.CAMPAIGN_DETAILS_LOADED, campaignDetailsReducer)
      .addCase(types.UPDATE_CAMPAIGN_DATA, campaignDetailsReducer)
      .addCase('topics/resetCurrentState', (state) => {
        state.currentTopics = state.topics
      })
  },
})

export const {
  updateTopics,
  updateCurrentTopics,
  revertTopics,
  generationStarted,
  generationError,
} = contentGenerationSlice.actions

export default contentGenerationSlice.reducer
