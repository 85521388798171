import { useForm } from 'react-final-form'
import { generatePath, useHistory } from 'react-router-dom'
import {
  ContentCreationDto,
  MessageCreationContentDto,
  MessageCreationOptimizeDto,
} from '@phrasee/phrasee-typings/typings/futurama/content_creation'
import { unifiedFlowPaths } from 'app/navigation/paths'
import moment from 'moment'

import Button from 'common/components/button'
import { useMoveIntercomButton } from 'common/components/intercom/useMoveIntercomButton'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import useCreateContentMutation from 'features/unifiedFlow/api/mutations/useCreateContentMutation'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'
import {
  hideTemplates,
  MAX_TEMPLATES_TO_ADD,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getFormValue } from './helpers'

const { REACT_APP_DEFAULT_DATE_FORMAT } = process.env

const TemplatesToAddBar = () => {
  const form = useForm()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const createContentMutation = useCreateContentMutation()

  const { content } = useContent()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )
  const templatesToAddCount = templatesToAdd.length

  const getIsFormValid = () => {
    const workflowNameState = form.getFieldState('workflowName')
    if (!workflowNameState?.valid) {
      return false
    }

    const isFormValid = templatesToAdd.every((template) => {
      return template.status === 'complete'
    })

    return isFormValid
  }

  const handleCreateContent = () => {
    const formState = form.getState()

    const workflowName = getFormValue({ formState, name: 'workflowName' })
    const elements = templatesToAdd.map(
      ({ id, internalId, splitConfiguration }) => {
        const experimentType = getFormValue({
          formState,
          name: 'experimentType',
          templateInternalId: internalId,
        })

        if (experimentType === 'content') {
          const brandVoiceId = getFormValue({
            formState,
            name: 'content.brandVoice',
            templateInternalId: internalId,
          })?.value

          return {
            template_id: id,
            type: 'content' as MessageCreationContentDto['type'],
            configuration: {
              brand_voice_id: brandVoiceId,
            },
          }
        } else {
          const ownSubjectLine = getFormValue({
            formState,
            name: 'experiment.controlSubjectLine',
            templateInternalId: internalId,
          })
          const campaignName = getFormValue({
            formState,
            name: 'experiment.experimentName',
            templateInternalId: internalId,
          })
          const projectId = getFormValue({
            formState,
            name: 'experiment.project',
            templateInternalId: internalId,
          })?.value
          const sendDate = getFormValue({
            formState,
            name: 'experiment.sendDate',
            templateInternalId: internalId,
          })
          const selectionMetric = getFormValue({
            formState,
            name: 'experiment.selectionMetric',
            templateInternalId: internalId,
          })?.value
          const listSize =
            splitConfiguration?.type === 'fixed'
              ? splitConfiguration?.listSize
              : getFormValue({
                  formState,
                  name: 'experiment.listSize',
                  templateInternalId: internalId,
                })
          const openRate = getFormValue({
            formState,
            name: 'experiment.openRate',
            templateInternalId: internalId,
          })
          const clickRate = getFormValue({
            formState,
            name: 'experiment.clickRate',
            templateInternalId: internalId,
          })
          const splitNumber =
            splitConfiguration?.splitNumber ||
            getFormValue({
              formState,
              name: 'experiment.splitNumber',
              templateInternalId: internalId,
            })
          const splitCalculationResult =
            splitConfiguration?.splitCalculationResult

          return {
            template_id: id,
            type: 'optimize' as MessageCreationOptimizeDto['type'],
            configuration: {
              own_subject_line: ownSubjectLine,
              campaign_name: campaignName,
              project_id: projectId,
              iso_send_date: moment
                .parseZone(sendDate, REACT_APP_DEFAULT_DATE_FORMAT)
                .toISOString(),
              split_calculation: {
                type: splitConfiguration?.type,
                ...(selectionMetric && { selection_metric: selectionMetric }),
                ...(splitNumber && { split_number: splitNumber }),
                ...(listSize && { list_size: listSize }),
                ...(openRate && { baseline_open_rate: openRate }),
                ...(clickRate && { baseline_click_rate: clickRate }),
                ...(splitCalculationResult && {
                  configuration: splitCalculationResult,
                }),
              },
            },
          }
        }
      }
    )

    const payload: ContentCreationDto = {
      workflow_name: workflowName,
      elements,
    }

    createContentMutation.mutate(
      {
        accountId,
        payload,
      },
      {
        onSuccess: (newContent) => {
          dispatch(hideTemplates())
          history.push(
            generatePath(unifiedFlowPaths.brief, { contentId: newContent._id })
          )
        },
      }
    )
  }

  const alreadyAddedTemplatesCount = content?.elements.length
    ? content.elements.length - 1
    : 0
  const selectedText = `${templatesToAddCount} of ${
    MAX_TEMPLATES_TO_ADD - alreadyAddedTemplatesCount
  } selected`
  const isFormValid = getIsFormValid()
  const isCtaDisabled = templatesToAddCount === 0 || !isFormValid

  useMoveIntercomButton()

  return (
    <div className="flex items-center my-2 p-6 w-full">
      <div className="text-coolGray-800 font-medium text-xl">
        {selectedText}
      </div>
      <Button
        data-cy="add-templates-button"
        className="ml-auto"
        disabled={isCtaDisabled || createContentMutation.isLoading}
        variant="primary"
        loading={createContentMutation.isLoading}
        suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
        onClick={handleCreateContent}
      >
        Next
      </Button>
    </div>
  )
}

export default TemplatesToAddBar
