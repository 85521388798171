import { useState } from 'react'
import { MenuItem } from '@szhsin/react-menu'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal'
import ErrorPage from 'common/components/error/ErrorPage'
import OptionMenuButton from 'common/components/optionsMenuButton'
import Table, { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Add } from 'common/icons'

import NoResults from '../holdingPages/NoResults'
import { clickDeleteTeam, clickDeleteTeamMember } from '../store/usersSlice'

import { MemberRow } from './TeamsNew'
import Title from './Title'

type Props = {
  onEditTeam: ({ teamId }: { teamId: string }) => void
  searchTeamValue: string
}

const Teams = ({ onEditTeam, searchTeamValue }: Props) => {
  const { hasTeamTabError, teams } = useAppSelector((state) => state.adminUsers)
  const dispatch = useAppDispatch()

  const [teamIdToDelete, setTeamIdToDelete] = useState<string>()
  const [userToDelete, setUserToDelete] = useState<string>()
  const flags = useFlags()

  const teamsFiltered = teams.filter(({ name }) =>
    name
      .replaceAll(' ', '')
      .toLocaleLowerCase()
      .startsWith(searchTeamValue.replaceAll(' ', '').toLocaleLowerCase())
  )
  const columns: Column<MemberRow>[] = [
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <BaseCell className=" h-full flex items-center justify-between">
          <span>{value}</span>
        </BaseCell>
      ),
    },
    generateDeleteButtonColumn<MemberRow>({
      onClick: (row) => {
        setTeamIdToDelete(flags.showNewTeamPage ? row.teamId : row.id)
        setUserToDelete(flags.showNewTeamPage ? row.id : row.email)
      },
      buttonLabel: 'Delete member',
    }),
  ]

  if (hasTeamTabError) {
    return <ErrorPage />
  }
  const options = (teamId: string) => (
    <>
      <MenuItem
        data-cy="delete-team-option"
        key="1"
        onClick={() => {
          setTeamIdToDelete(teamId)
        }}
      >
        Delete team?
      </MenuItem>
    </>
  )

  if (teams.length > 0 && teamsFiltered.length === 0) {
    return (
      <div className="text-5xl font-bold text-coolGray-800">
        No results found for '{searchTeamValue}'
      </div>
    )
  }
  return teams.length === 0 ? (
    <NoResults />
  ) : (
    <div className="grid gap-y-10">
      {teamsFiltered.map(({ id, members, name }) => (
        <Widget key={id}>
          <WidgetHeader
            title={
              <Title name={name} id={id} teams={teams} members={members} />
            }
            actions={
              <>
                <div className="inline-flex">
                  <Button
                    variant="icon"
                    data-cy="add-users-to-team"
                    aria-label="Add users to the team"
                    onClick={() => {
                      onEditTeam({ teamId: id })
                    }}
                  >
                    <Add />
                  </Button>
                  <div className="ml-5 flex items-center">
                    <OptionMenuButton
                      name="widget menu"
                      options={options(id)}
                    />
                  </div>
                </div>
              </>
            }
          />
          {members.length > 0 ? (
            <Table
              columns={columns}
              data={members.map((email) => ({ email, id }))}
            />
          ) : (
            <div>No members</div>
          )}
        </Widget>
      ))}
      <ConfirmationModal
        data-cy="delete-confirmation-modal"
        title={
          userToDelete
            ? 'Remove user from team?'
            : `Delete team ${
                teams.find(({ id }) => teamIdToDelete === id)?.name ?? ''
              }?`
        }
        confirmationText={
          userToDelete
            ? 'This is sad… this user will be removed from this team and if they where added to any experiments, as part of this team, they will be removed from there too.'
            : 'This is all great but… all experiments that use this team will have this team removed and you will need to add another team per experiment if you need them. No sweat really, its not critical.'
        }
        confirmButtonText={userToDelete ? 'Remove them' : 'Delete it already'}
        cancelButtonText={userToDelete ? "Oh, I can't do it" : 'Cancel'}
        open={teamIdToDelete !== undefined}
        onConfirm={() => {
          if (teamIdToDelete) {
            if (userToDelete) {
              dispatch(
                clickDeleteTeamMember({
                  teamId: teamIdToDelete,
                  id: userToDelete,
                  showNewTeamPage: flags.showNewTeamPage,
                })
              )
            } else {
              dispatch(
                clickDeleteTeam({
                  teamId: teamIdToDelete,
                  showNewTeamPage: flags.showNewTeamPage,
                })
              )
            }
          }
          setTeamIdToDelete(undefined)
          setUserToDelete(undefined)
        }}
        onCancel={() => {
          setTeamIdToDelete(undefined)
          setUserToDelete(undefined)
        }}
      />
    </div>
  )
}

export default Teams
