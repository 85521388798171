import { ChangeEvent, forwardRef } from 'react'
import cx from 'classnames'

import Button from 'common/components/button/Button'
import Input from 'common/components/Input'

interface Props {
  value: string
  savedValue: string
  placeholder: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
  setIsFocused?: (value: boolean) => void
  onSaveClick?: () => void
  shouldShowSaveButton?: boolean
  inputLabel?: string
  'data-cy'?: string
  className?: string
}

const EditingValue = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      savedValue,
      placeholder,
      shouldShowSaveButton = true,
      onChange,
      onBlur,
      onSaveClick,
      setIsFocused,
      inputLabel,
      'data-cy': dataCy,
      className,
    },
    nameInputRef
  ) => {
    return (
      <div className="flex items-center">
        <Input
          data-cy={`${dataCy}-input`}
          type="text"
          aria-label={inputLabel}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={() => setIsFocused && setIsFocused(true)}
          onBlur={onBlur}
          className={cx(
            `autoSize mr-6 text-2xl lg:text-3xl text-coolGray-800 placeholder-coolGray-800 focus:outline-none caret-maroon-300 font-medium`,
            className
          )}
          ref={nameInputRef}
        />
        {shouldShowSaveButton && (
          <Button
            data-cy={`${dataCy}-save`}
            onMouseDown={onSaveClick}
            type="submit"
            variant="primary"
            size="small"
            disabled={value === savedValue || !value.length}
          >
            Save
          </Button>
        )}
      </div>
    )
  }
)

export default EditingValue
