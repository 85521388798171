/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import * as Drawer from 'app/IntegrationDrawer'
import moment, { Moment } from 'moment-timezone'
import { showBanner } from 'workflow/common/common.actions'

import { connectInternalApi } from 'common/api'
import Checkbox from 'common/components/checkbox'
import EmailsSelect, { EmailOptions } from 'common/components/EmailsSelect'
import Form from 'common/components/Form'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Time as TimeIcon } from 'common/icons'

import { Date, Time } from '../components/scheduler/interface'
import Scheduler from '../components/scheduler/Scheduler'

import CancelCard from './components/CancelCard'
import CreateEmails from './components/CreateEmails'
import Footer from './components/Footer'

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT || 'YYYY-MM-DD'
export const URL = `${process.env.REACT_APP_CONNECT_INTERNAL_V1_URL}/responsys/`

interface State {
  isStraightSplitTest: boolean
  selectedCampaign?: string
  startDate: Date
  startTime?: Time
  endDate: Date
  endTime?: Time
  waitHours?: number
  isAutodeployEnabled: boolean
  areProgressNotificationsEnabled: boolean
  notificationInterval: number
  notificationRecipients?: EmailOptions
}

export const notificationIntervalOptions: number[] = [
  10000, 50000, 100000, 500000, 1000000,
]

export type Status = 'idle' | 'loading' | 'success'

const testLengthOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const SUCCESS_STATE_TIMEOUT = 3000

const getUtcString = (
  date: Date,
  time?: Time,
  timeZone?: string
): string | undefined => {
  if (timeZone && date && time) {
    return moment()
      .tz(timeZone)
      .set({
        year: date.year(),
        month: date.month(),
        date: date.date(),
        hours: time.hours(),
        minutes: time.minutes(),
      })
      .utc()
      .format()
  }
}

export const isDatePassed = (dateA: Moment, dateB: Moment) => {
  return dateB?.isAfter(dateA)
}

export default function ResponsysIntegration() {
  const dispatch = useAppDispatch()
  const { projectId, campaignData } = useAppSelector(
    (state) => state.campaignStates
  )
  const campaignId = useAppSelector(
    (state) => state.campaigns.selectedCampaignId
  )
  const integrationData = campaignData?.campaign_data?.integration_data
  const scheduleConfig = integrationData?.responsysSchedule
  const selectedCampaign = integrationData?.responsysCampaignNames?.find(
    (name) => !name.includes('_split_')
  )
  const [timeZone, setTimeZone] = useState<string>()
  const [isFetchingTimezone, setIsFetchingTimezone] = useState<boolean>(false)
  const [hasCreatedEmails, setHasCreatedEmails] = useState<boolean>(
    !!selectedCampaign
  )
  const [scheduleCampaignStatus, setScheduleCampaignStatus] =
    useState<Status>('idle')
  const [state, setState] = useState<State>({
    selectedCampaign,
    isStraightSplitTest: !!integrationData?.isStraightSplit,
    startDate: scheduleConfig?.startDate
      ? moment(scheduleConfig?.startDate)
      : null,
    startTime: scheduleConfig?.startDate
      ? moment(scheduleConfig?.startDate)
      : undefined,
    endDate: scheduleConfig?.endDate ? moment(scheduleConfig?.endDate) : null,
    endTime: scheduleConfig?.endDate
      ? moment(scheduleConfig?.endDate)
      : undefined,
    waitHours: scheduleConfig?.waitPeriod,
    isAutodeployEnabled: scheduleConfig?.isAutodeployEnabled ?? true,
    areProgressNotificationsEnabled:
      !!scheduleConfig?.notificationRecipients.length,
    notificationInterval:
      scheduleConfig?.notificationInterval ?? notificationIntervalOptions[0],
    notificationRecipients:
      scheduleConfig?.notificationRecipients?.map((v) => {
        return { value: v, label: v }
      }) || [],
  })
  const [isScheduled, setIsScheduled] = useState<boolean>(!!scheduleConfig)
  const {
    startDate,
    startTime,
    endDate,
    endTime,
    waitHours,
    isAutodeployEnabled,
    areProgressNotificationsEnabled,
    notificationInterval,
    notificationRecipients,
    isStraightSplitTest,
  } = state

  const curentUtcTimePassedStartedDate = isDatePassed(
    moment.utc(scheduleConfig?.startDate),
    moment().utc()
  )

  useEffect(() => {
    if (!timeZone && hasCreatedEmails) {
      setIsFetchingTimezone(true)

      const fetchTimezone = async () => {
        return connectInternalApi.get<string>(
          `${URL}projects/${projectId}/get-timezone`
        )
      }

      fetchTimezone()
        .then((res) => {
          setTimeZone(res.data)
          setState({
            ...state,
            startTime: startTime?.tz(res.data!),
            endTime: endTime?.tz(res.data!),
          })
        })
        .catch(() =>
          dispatch(
            showBanner({
              content: 'Failed to fetch time zone',
              type: 'error',
            })
          )
        )
        .finally(() => setIsFetchingTimezone(false))
    }
  }, [
    hasCreatedEmails,
    timeZone,
    projectId,
    dispatch,
    endTime,
    startTime,
    state,
  ])

  let hasFilledRequiredFields: boolean =
    !!startDate && !!startTime && !!endDate && !!endTime

  if (!isStraightSplitTest && !waitHours) {
    hasFilledRequiredFields = false
  }

  const onChange = (name, value) => {
    setState({ ...state, [name]: value })
  }

  const scheduleCampaign = () => {
    setScheduleCampaignStatus('loading')

    connectInternalApi
      .post(
        `${URL}projects/${projectId}/campaigns/${campaignId}/schedule-an-email`,
        {
          isAutodeployEnabled,
          notificationRecipients: notificationRecipients?.map(({ value }) => {
            return value
          }),
          startDate: getUtcString(startDate, startTime, timeZone),
          endDate: getUtcString(endDate, endTime, timeZone),
          waitPeriod: isStraightSplitTest ? undefined : `${waitHours}h`,
          notificationInterval: `CHUNK_${notificationInterval / 1000}K`,
        }
      )
      .then(() => {
        setIsScheduled(true)
        setScheduleCampaignStatus('success')

        setTimeout(() => {
          setScheduleCampaignStatus('idle')
        }, SUCCESS_STATE_TIMEOUT)
      })
      .catch(() => {
        setScheduleCampaignStatus('idle')
        dispatch(
          showBanner({
            content: 'Failed to schedule experiment',
            type: 'error',
          })
        )
      })
  }

  const cancelCampaign = () => {
    setScheduleCampaignStatus('loading')

    connectInternalApi
      .delete(
        `${URL}projects/${projectId}/campaigns/${campaignId}/delete-all-campaign-schedules`
      )
      .then(() => {
        setIsScheduled(false)
        dispatch(
          showBanner({
            content: 'Experiment cancelled successfully',
            type: 'success',
          })
        )
        hasFilledRequiredFields = false
      })
      .catch(() => {
        dispatch(
          showBanner({
            content: 'Failed to cancel experiment',
            type: 'error',
          })
        )
      })
      .finally(() => setScheduleCampaignStatus('idle'))
  }

  const handleNotificationRecipientUpdate = (values: EmailOptions) => {
    setState({ ...state, notificationRecipients: values })
  }

  return (
    <>
      <Drawer.Content>
        <div className="mb-8 font-medium text-coolGray-500">
          Schedule your Jacquard experiment with your Responsys account.
        </div>
        <Form>
          <CreateEmails
            projectId={projectId}
            campaignId={campaignId}
            state={{
              isStraightSplitTest,
              selectedCampaign: state?.selectedCampaign,
            }}
            onChange={onChange}
            hasCreatedEmails={hasCreatedEmails}
            setHasCreatedEmails={setHasCreatedEmails}
            isScheduled={isScheduled}
          />

          <Spinner isSpinning={isFetchingTimezone}>
            {hasCreatedEmails && (
              <>
                <Scheduler
                  icon={<TimeIcon />}
                  title="Optimization schedule"
                  endTimeTitle="When would you like to end this experiment?"
                  startTimeTitle="When would you like to start this experiment?"
                  startDate={startDate}
                  startTime={startTime ? moment(startTime, 'HH:mm') : undefined}
                  onChange={(val, type) => onChange(type, val)}
                  endDate={endDate}
                  endTime={endTime ? moment(endTime, 'HH:mm') : undefined}
                  timeFormat="HH:mm"
                  minuteStep={15}
                  timeZone={timeZone}
                  dateFormat={DATE_FORMAT}
                  integrationType="Responsys"
                  isEndDateDisabled={isScheduled || !startDate || !startTime}
                  isEndTimeDisabled={
                    isScheduled || !startDate || !startTime || !endDate
                  }
                  isStartDateDisabled={isScheduled}
                  isStartTimeDisabled={isScheduled || !startDate}
                  testLength={
                    isStraightSplitTest
                      ? undefined
                      : {
                          value: waitHours,
                          options: testLengthOptions.map((option) => {
                            return { id: option, name: `${option} hrs` }
                          }),
                          onChange: (val: number) => onChange('waitHours', val),
                          isDisabled: isScheduled,
                        }
                  }
                  autoDeploy={
                    isStraightSplitTest
                      ? undefined
                      : {
                          checked: isAutodeployEnabled,
                          isDisabled: isScheduled,
                          onChange: (isChecked: boolean) =>
                            onChange('isAutodeployEnabled', isChecked),
                        }
                  }
                  delayInDaysToRetrieveTheFinalResult={1}
                  delayInHourToRetrieveSplitResult={7200}
                />

                {!isStraightSplitTest && (
                  <Checkbox
                    className="mt-6"
                    data-cy="progress-notifications-checkbox"
                    label="Send progress notifications"
                    isChecked={areProgressNotificationsEnabled}
                    isDisabled={isScheduled}
                    onChange={(isChecked: boolean) =>
                      onChange('areProgressNotificationsEnabled', isChecked)
                    }
                  />
                )}

                {areProgressNotificationsEnabled && (
                  <EmailsSelect
                    values={notificationRecipients as EmailOptions}
                    setValues={handleNotificationRecipientUpdate}
                  />
                )}
              </>
            )}
          </Spinner>
        </Form>
        {isScheduled && !curentUtcTimePassedStartedDate && (
          <CancelCard cancelCampaign={cancelCampaign} />
        )}
      </Drawer.Content>
      {hasFilledRequiredFields && (
        <Footer
          status={scheduleCampaignStatus}
          isScheduled={isScheduled}
          scheduleCampaign={scheduleCampaign}
        />
      )}
    </>
  )
}
