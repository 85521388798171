import React from 'react'

import { useAppDispatch } from 'common/hooks/redux'

import CreateVariantsCard from '../components/card/CreateVariantsCard'

import { createOffers, setError, setLoading } from './store/adobeTargetSlice'

export default function CreateOffersCard({
  isOffersCreated,
  selectedActivity,
  isLoading,
  projectId,
  campaignId,
  setState,
  currentState,
}) {
  const dispatch = useAppDispatch()

  return !isOffersCreated && selectedActivity ? (
    <CreateVariantsCard
      isDisabled={false}
      isLoading={isLoading.offers}
      cardText={`This will create the Offer variants to be placed within
        your Adobe Target Activity.`}
      cardTitle="Create and send offers"
      buttonText={isLoading.offers ? 'Creating' : 'Create + send'}
      onSubmit={() => {
        dispatch(setLoading({ field: 'offers', value: true }))
        createOffers(projectId, campaignId, {
          activityId: selectedActivity,
        })
          .then(() => {
            setState({
              ...currentState,
              isOffersCreated: true,
              campaignUnlinked: false,
            })
            dispatch(setLoading({ field: 'offers', value: false }))
          })
          .catch((err) => {
            dispatch(setLoading({ field: 'offers', value: false }))
            dispatch(setError(err.message))
          })
      }}
    />
  ) : null
}
