import { ReactNode } from 'react'
import cx from 'classnames'

type Props = {
  children: ReactNode
  onSelect?: () => void
  className?: string
}

const MenuItem = ({ children, onSelect, className }: Props) => (
  <button
    onClick={onSelect}
    className={cx(
      className,
      cx(
        'pl-6 pr-4 py-4 border border-coolGray-200 focus-visible:border-transparent focus:outline-none block w-full',
        'border-b-0 border-l-0 first:border-t-0 last:border-b-1'
      )
    )}
  >
    {children}
  </button>
)

export default MenuItem
