import { Content } from '@phrasee/phrasee-typings'

import { useAppSelector } from 'common/hooks/redux'
import useGetContentQuery from 'features/futurama/api/queries/useGetContentQuery'

import { getAllComponentElements } from '../helpers'

const useSelectedComponentElement = () => {
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )

  return useGetContentQuery(activeContentId, {
    select: (content: Content) => {
      const allComponentElements = getAllComponentElements(content)

      const selectedElement = allComponentElements.find(
        (element) => element.element_id === selectedElementId
      )

      return selectedElement
    },
  })
}
export default useSelectedComponentElement
