import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'

type ToggleButtonGroupItem = {
  name: string
  disabled?: boolean
  tooltipMessage?: string
}
type Props = {
  onChange: (name) => void
  items: ToggleButtonGroupItem[]
  value?: ToggleButtonGroupItem['name']
  className?: string
}

const ToggleButtonGroup = ({ value, items, onChange, className }: Props) => {
  return (
    <div className="flex" role="group">
      {items.map(({ name, disabled, tooltipMessage }, index: number) => (
        <Tooltip
          key={name}
          overlay={tooltipMessage}
          placement="top"
          show={disabled}
        >
          {/* Added a fragment to ensure the tooltip is displayed even when the button is disabled */}
          <>
            <button
              type="button"
              key={name}
              onClick={() => {
                if (!disabled) {
                  onChange(name)
                }
              }}
              className={cx(
                'px-2 py-1 text-xs font-medium border hover:bg-coolGray-50 focus:outline-none focus:ring-0',
                {
                  '-ml-0.25': index !== 0,
                  'text-coolGray-600 bg-white border-coolGray-300 z-10':
                    !disabled && name !== value,
                  'text-coolGray-400 bg-coolGray-100 border-coolGray-100 cursor-not-allowed':
                    disabled,
                  'text-maroon-500 focus:border-maroon-300 border-maroon-300 z-20':
                    name === value,
                },
                className
              )}
              disabled={disabled}
            >
              {name}
            </button>
          </>
        </Tooltip>
      ))}
    </div>
  )
}

export default ToggleButtonGroup
