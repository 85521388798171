import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import AddTemplateButton from '../../components/AddTemplateButton'
import ElementCard from '../../components/ElementCard'
import ExperimentTypeBadges from '../../components/ExperimentTypeBadges'
import { useContent } from '../../hooks'
import useCheckVariants from '../hooks/useCheckVariants'

export const BRIEF_ELEMENT_ID = 'brief'

const ElementsList = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { hasVariants } = useCheckVariants()
  const { content } = useContent()

  const elements = content?.elements || []

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )
  const areElementsDisabled = useAppSelector(
    (state) => state.unifiedFlow.areElementsDisabled
  )

  const isRemoveOptionShown = content?.elements && content.elements.length > 1
  const isOnlyOneElement = content?.elements && content.elements.length === 1

  const handleElementClick = ({ isElementDisabled, elementId }) => {
    if (isElementDisabled) {
      return
    }

    if (isOnlyOneElement && hasVariants) {
      history.push(
        generatePath(unifiedFlowPaths.generate, {
          contentId: content?._id,
        })
      )
    }

    dispatch(selectElement({ elementId }))
  }

  return (
    <>
      <ElementCard
        title="Brief"
        className="mb-4"
        icon="startFlag"
        hasElementActionsMenu={false}
        isSelected={selectedElementId === BRIEF_ELEMENT_ID}
        onClick={() => dispatch(selectElement({ elementId: BRIEF_ELEMENT_ID }))}
      />
      {elements.map((element) => {
        const { element_id, name, display_name, icon, status } = element
        const isElementDisabled = areElementsDisabled || status === 'disabled'
        const badgesState = getExperimentBadgesState(element)

        return (
          <ElementCard
            className="mb-4"
            key={element_id}
            element={element}
            title={display_name || name}
            icon={icon}
            isRemoveOptionShown={isRemoveOptionShown}
            isDisabled={isElementDisabled}
            isSelected={element_id === selectedElementId && !isElementDisabled}
            isConfigurable={true}
            content={badgesState && <ExperimentTypeBadges {...badgesState} />}
            hasElementActionsMenu={true}
            onClick={() =>
              handleElementClick({ isElementDisabled, elementId: element_id })
            }
          />
        )
      })}
      <AddTemplateButton isDisabled={areElementsDisabled} />
    </>
  )
}

export default ElementsList
