import { generatePath } from 'react-router-dom'
import { dataSourcesPath } from 'app/navigation/paths'

import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import {
  DataType,
  fileProcessError,
  fileProcessSuccess,
} from 'features/admin/dataSources/store/dataSourceSlice'
import useDataIngestionWebsocketStatus, {
  WsResponse,
} from 'features/admin/dataSources/useDataIngestionWebsocketStatus'

const useDataIngestionStatusNotification = () => {
  const dispatch = useAppDispatch()

  const wsCallbacks = (type: DataType) => ({
    onSuccess: ({
      accountId,
      wsResponse,
    }: {
      accountId: string
      wsResponse: WsResponse
    }) => {
      dispatch(fileProcessSuccess({ type }))
      successToast(
        <div>
          File {wsResponse.filename} has been successfully processed |{' '}
          <a href={generatePath(dataSourcesPath, { accountId })}>Show me</a>
        </div>,
        { autoClose: false }
      )
    },
    onFailure: ({
      accountId,
      wsResponse,
    }: {
      accountId: string
      wsResponse: WsResponse
    }) => {
      dispatch(fileProcessError({ type }))
      errorToast(
        <div>
          File {wsResponse.filename} process un-successful |{' '}
          <a href={generatePath(dataSourcesPath, { accountId })}> Show me</a>
        </div>,
        { autoClose: false }
      )
    },
  })

  useDataIngestionWebsocketStatus({
    topic: 'product',
    ...wsCallbacks('product'),
  })

  useDataIngestionWebsocketStatus({
    topic: 'customer',
    ...wsCallbacks('customer'),
  })
}

export default useDataIngestionStatusNotification
