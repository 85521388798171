import React from 'react'

import Tooltip from 'common/components/Tooltip'
import YesNoButton from 'common/components/YesNoButton'

type Props = {
  name: string
  type: string
  disabled: boolean
  onChange: Function
  specialValues?: [string, string]
  shouldShowTooltipWith?: string
  defaultValue: string | boolean
}

class YesNo extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  static defaultProps = {
    defaultValue: true,
  }

  onClick(e: any) {
    const { name, type, onChange } = this.props
    const checked = e.target.value === 'yes'
    onChange(name, type, !!checked)
  }

  render() {
    const {
      specialValues,
      shouldShowTooltipWith,
      defaultValue,
      disabled = false,
    } = this.props
    const checked = !!defaultValue

    return (
      <Tooltip
        overlay={shouldShowTooltipWith}
        trigger="hover"
        placement="right"
        overlayStyle={{ maxWidth: 226 }}
      >
        <YesNoButton
          selectedValue={checked ? 'yes' : 'no'}
          onClick={this.onClick}
          isDisabled={disabled}
          values={specialValues}
        />
      </Tooltip>
    )
  }
}

export default YesNo
