export const EMPTY_OPTION = {
  label: '--',
  value: '',
}

export const PART_OF_SPEACH_OPTIONS = [
  {
    label: 'Adjective',
    value: 'adj',
  },
  {
    label: 'Adposition',
    value: 'adp',
  },
  {
    label: 'Adverb',
    value: 'adv',
  },
  {
    label: 'Auxiliary',
    value: 'aux',
  },
  {
    label: 'Conjunction',
    value: 'conj',
  },
  {
    label: 'Coordinating conjunction',
    value: 'cconj',
  },
  {
    label: 'Determiner',
    value: 'det',
  },
  {
    label: 'Interjection',
    value: 'intj',
  },
  {
    label: 'Noun',
    value: 'noun',
  },
  {
    label: 'Numeral',
    value: 'num',
  },
  {
    label: 'Particle',
    value: 'part',
  },
  {
    label: 'Pronoun',
    value: 'pron',
  },
  {
    label: 'Proper noun',
    value: 'propn',
  },
  {
    label: 'Punctuation',
    value: 'punct',
  },
  {
    label: 'Subordinating conjunction',
    value: 'sconj',
  },
  {
    label: 'Symbol',
    value: 'sym',
  },
  {
    label: 'Verb',
    value: 'verb',
  },
  {
    label: 'Other',
    value: 'x',
  },
  {
    label: 'End of line',
    value: 'eol',
  },
  {
    label: 'Space',
    value: 'space',
  },
]

export const splitSentenceIntoPartOfSpeach = (sentence: string): string[] => {
  // split by brackets, symbol, punctuation and separator
  return sentence
    .toLowerCase()
    .split(/(\[.*?\]|\p{S}|\p{punct}|\p{Z})/u)
    .filter((value) => !RegExp(/\p{Z}/u).exec(value) && value !== '')
}
