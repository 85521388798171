import moment, { Moment } from 'moment'

import { handlePercentValue } from 'common/helpers/numeric'

import {
  confidence,
  highPerformers,
  optionNoNewLanguage,
  singleBest,
} from './components/advancedOptions/constants'
import { AdvancedSettingsState } from './advancedOptionsReducer'
import {
  AdvancedSettingsPayload,
  BeSmartWhenToIntroduceVariants,
  IOptimizationSettingsObj,
} from './types'

/**
 * Used to disable days for the end date datepicker component in the PhraseeX drawer
 * A campaign can't end less then 72 hours before the start time or more than 7 days after a engage campaign
 * @param { Moment | null } selectedDate - the current date selected
 * @param { string } campaignDistributionType - the distribution type of the campaign
 * @param { Moment | null } startDate - the start date of the campaign
 * @returns { boolean } - true if the date is disabled, false otherwise
 */
export const isDisabledEndDate = (
  selectedDate: Moment | null,
  campaignDistributionType: string,
  startDate: Moment | null
): boolean => {
  const isBroadCastCampaign = campaignDistributionType === 'broadcast'

  if (!!selectedDate && selectedDate < moment().add(-1, 'days').endOf('day')) {
    return true
  }

  if (!!selectedDate) {
    if (startDate && isBroadCastCampaign) {
      return (
        selectedDate < moment(startDate).add(72, 'hours').endOf('day') ||
        selectedDate > moment(startDate).add(7, 'days').endOf('day')
      )
    } else if (startDate && !isBroadCastCampaign) {
      return selectedDate < moment(startDate).add(72, 'hours').endOf('day')
    } else if (!startDate && isBroadCastCampaign) {
      return (
        selectedDate < moment().add(-1, 'days').add(72, 'hours').endOf('day') ||
        selectedDate > moment().add(7, 'days').endOf('day')
      )
    } else if (!startDate && !isBroadCastCampaign) {
      return (
        selectedDate < moment().add(-1, 'days').add(72, 'hours').endOf('day')
      )
    }
  }
  return false
}

/**
 * This will return a string if the start and send time do not conform to a set of business rules
 * Read the error strings for business logic rules
 * @param { string } optimizationStartTime - The campaigns optimization start time
 * @param { string } startDateTime - The new schedule start time
 * @param { string } endDateTime - The new schedule end time
 * @param { string } campaignDistributionType - The distribution type of the campaign
 * @returns { string | null } - null if there is no error or a string with the error text
 */
export const submissionError = (
  optimizationStartTime: string,
  startDateTime: string,
  endDateTime: string,
  campaignDistributionType: string
  // eslint-disable-next-line max-params
): string | null => {
  if (
    moment(optimizationStartTime).diff(moment(startDateTime), 'minutes') !==
      0 &&
    moment(startDateTime).diff(moment(), 'seconds') < 0
  ) {
    return 'Start date cannot be in the past.'
  }

  if (moment(startDateTime).isAfter(moment(endDateTime))) {
    return 'Start date cannot be after the end date'
  }

  if (
    campaignDistributionType === 'broadcast' &&
    moment(endDateTime).diff(moment(startDateTime), 'days') > 7
  ) {
    return 'End date cannot be more than 7 days after the start date'
  }
  return null
}

const getHeadToHeadDaysForAMode = ({
  showDOAutomaticOptions,
  optimizationSettingsMode,
  daysBeforeAddingLines,
  isBroadCastCampaign,
  languageController,
  isDaysBeforeAddingLinesEnabled,
}: {
  showDOAutomaticOptions: boolean | undefined
  optimizationSettingsMode: 'single_best' | 'confidence'
  daysBeforeAddingLines: number | undefined
  isBroadCastCampaign: boolean
  languageController: { mode: BeSmartWhenToIntroduceVariants } | undefined
  isDaysBeforeAddingLinesEnabled: boolean
}) => {
  if (showDOAutomaticOptions) {
    return !isBroadCastCampaign && languageController?.mode === 'h2h_and_wait'
      ? daysBeforeAddingLines
      : undefined
  }
  if (optimizationSettingsMode === 'single_best') {
    return isDaysBeforeAddingLinesEnabled && !isBroadCastCampaign
      ? daysBeforeAddingLines
      : undefined
  }
  if (optimizationSettingsMode === 'confidence') {
    return isDaysBeforeAddingLinesEnabled ? daysBeforeAddingLines : undefined
  }

  return undefined
}

export const createAdvancedSettingsPayload = (
  advancedSettingsState: AdvancedSettingsState,
  campaignDistributionType: string,
  showDOAutomaticOptions?: boolean
): AdvancedSettingsPayload => {
  const {
    optimizationMetric,
    isDropBadVariantsEnabled,
    isDropHumanControlEnabled,
    minSendsToHumanControlInputValue,
    isDaysBeforeAddingLinesEnabled,
    daysBeforeAddingLines,
    highPerformersNumber,
    proportionSendToWinnerNumber,
    optimizationMode,
    testAudienceSizeValue,
    beSmartWhenToIntroduceVariants,
    integrationOptimizationMetric,
    integrationOptimizationAudience,
  } = advancedSettingsState
  if (!optimizationMetric) {
    throw new Error(
      `Project configuration is invalid. Please contact administrator.`
    )
  }
  const isBroadCastCampaign = campaignDistributionType === 'broadcast'
  const hasIntegrationConfiguration = !!(
    integrationOptimizationAudience && integrationOptimizationMetric
  )
  const defaultPayload = {
    drop_bad_variants: isBroadCastCampaign ? false : isDropBadVariantsEnabled,
    allow_control_drop: isDropHumanControlEnabled,
    performance_metric: optimizationMetric,
    ...(hasIntegrationConfiguration && {
      optimization_metric: integrationOptimizationMetric,
      optimization_audience: integrationOptimizationAudience,
    }),
    optimization_settings: {
      min_hc_percent: handlePercentValue(
        'float',
        minSendsToHumanControlInputValue
      ),
      test_audience_size: !isBroadCastCampaign
        ? handlePercentValue('float', testAudienceSizeValue)
        : undefined,
    },
    ...(showDOAutomaticOptions && {
      language_controller: {
        mode:
          isBroadCastCampaign || optimizationMode === 'random_high_performers'
            ? optionNoNewLanguage.value
            : beSmartWhenToIntroduceVariants,
      },
    }),
  }

  const optimizationSettingsObj: IOptimizationSettingsObj = {
    single_best: {
      mode: singleBest.value,
      head_to_head_days: getHeadToHeadDaysForAMode({
        isBroadCastCampaign,
        showDOAutomaticOptions,
        optimizationSettingsMode: singleBest.value,
        daysBeforeAddingLines,
        languageController: defaultPayload.language_controller,
        isDaysBeforeAddingLinesEnabled,
      }),
    },
    random_high_performers: {
      mode: highPerformers.value,
      pool_size: highPerformersNumber,
    },
    confidence: {
      mode: confidence.value,
      head_to_head_winner_prop: handlePercentValue(
        'float',
        proportionSendToWinnerNumber
      ),
      head_to_head_days: getHeadToHeadDaysForAMode({
        isBroadCastCampaign,
        showDOAutomaticOptions,
        optimizationSettingsMode: singleBest.value,
        daysBeforeAddingLines,
        languageController: defaultPayload.language_controller,
        isDaysBeforeAddingLinesEnabled,
      }),
    },
  }

  if (!optimizationSettingsObj[optimizationMode]) {
    throw new Error(`Optimization mode "${optimizationMode}" is not supported.`)
  }
  return {
    ...defaultPayload,
    optimization_settings: {
      ...defaultPayload.optimization_settings,
      ...optimizationSettingsObj[optimizationMode],
    },
  }
}
