import { RevenueInformationResponse } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { gql } from 'graphql-request'

import { fetchGraphQL } from 'common/api'
import { isoCurrencyToLanguage } from 'common/helpers/currency'

import { mapRevenueInformationResponse } from './helpers'
import { RevenueSettingsValues } from './interfaces'

export const getAccountData = async () => {
  const query = gql`
    query getAccount {
      data: account {
        account_data {
          average_order {
            value
            iso_currency
            iso_language
          }
          open_conversion_percentage
          click_conversion_percentage
          open_value
          click_value
        }
      }
    }
  `
  const response = await fetchGraphQL<{
    account_data: RevenueInformationResponse | null
  }>({
    query,
  })

  return response.account_data
    ? mapRevenueInformationResponse(response.account_data)
    : undefined
}

export const getProjectExceptions = async (accountId: string) => {
  const query = gql`
    query projects($filter: ProjectFilter) {
      data: projects(filter: $filter) {
        id: _id
        name
        project_data {
          average_order {
            value
            iso_currency
            iso_language
          }
          open_conversion_percentage
          click_conversion_percentage
          open_value
          click_value
        }
      }
    }
  `

  const variables = {
    filter: {
      withRevenueInformation: true,
      accountId,
    },
  }

  const response = await fetchGraphQL<
    { id: string; name: string; project_data: RevenueInformationResponse }[]
  >({ query, variables })

  return response.map((r) => {
    return {
      projectId: r.id,
      isSaved: true,
      ...mapRevenueInformationResponse(r.project_data),
    }
  }) as RevenueSettingsValues[]
}

export const saveGlobalSettings = async ({
  currency,
  type,
  averageOrderValue,
  value,
}: RevenueSettingsValues) => {
  const query = gql`
    mutation updateRevenueInformation($info: RevenueInformationRequest) {
      data: updateRevenueInformation(revenueInformation: $info) {
        average_order {
          value
          iso_currency
          iso_language
        }
        open_conversion_percentage
        click_conversion_percentage
        open_value
        click_value
      }
    }
  `

  const variables = {
    info: {
      average_order_value: averageOrderValue,
      average_order_currency: currency,
      average_order_language: isoCurrencyToLanguage[currency],
      [type]: value,
    },
  }

  const response = await fetchGraphQL<RevenueInformationResponse>({
    query,
    variables,
  })
  return mapRevenueInformationResponse(response)
}

export const saveProjectSettings = async (
  values: RevenueSettingsValues,
  projectId?: string
) => {
  if (projectId === undefined) {
    throw new Error('Project ID is required')
  }

  const query = gql`
    mutation updateRevenueInformationForProject(
      $info: RevenueInformationRequest
      $projectId: String
    ) {
      data: updateRevenueInformationForProject(
        revenueInformation: $info
        projectId: $projectId
      ) {
        average_order {
          value
          iso_currency
          iso_language
        }
        open_conversion_percentage
        click_conversion_percentage
        open_value
        click_value
      }
    }
  `

  const { currency, type, averageOrderValue, value } = values
  const info = {
    average_order_value: averageOrderValue,
    average_order_currency: currency,
    average_order_language: isoCurrencyToLanguage[currency],
    [type]: value,
  }
  const variables = {
    info,
    projectId,
  }

  const response = await fetchGraphQL<RevenueInformationResponse>({
    query,
    variables,
  })
  return mapRevenueInformationResponse(response)
}

export const deleteProjectSettings = async (projectId: string) => {
  const query = gql`
    mutation deleteRevenueInformationForProject(
      $revenueInformation: RevenueInformationRequest
      $projectId: String
    ) {
      deleteRevenueInformationForProject(
        revenueInformation: $revenueInformation
        projectId: $projectId
      ) {
        average_order {
          value
          iso_currency
          iso_language
        }
        open_conversion_percentage
        click_conversion_percentage
        open_value
        click_value
      }
    }
  `

  const variables = {
    revenueInformation: {
      average_order_value: 1,
      open_conversion_percentage: 1,
      click_conversion_percentage: 1,
      open_value: 1,
      click_value: 1,
      average_order_currency: '1',
      average_order_language: '1',
    },
    projectId,
  }

  return fetchGraphQL<RevenueInformationResponse>({ query, variables })
}

export const deleteGlobalSettings = async () => {
  const query = `mutation deleteRevenueInformation { deleteRevenueInformation }`

  return fetchGraphQL<RevenueInformationResponse>({ query })
}
