import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import PageLeaveModal, { Mode } from 'common/components/pageLeaveModal'

export type Props = {
  shouldShow: boolean
  isSaving?: boolean
  onOK: () => Promise<boolean>
  onCancel: () => boolean
  mode?: Mode
  confirmationText?: string
}

const RouterPrompt = ({
  shouldShow,
  onOK,
  onCancel,
  mode = 'leave',
  isSaving = false,
  confirmationText,
}: Props) => {
  const history = useHistory()

  const [showPrompt, setShowPrompt] = useState(false)
  const [currentPath, setCurrentPath] = useState('')
  const unblockHistoryRef = useRef(() => {})

  useEffect(() => {
    if (shouldShow) {
      unblockHistoryRef.current()
      unblockHistoryRef.current = history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        setShowPrompt(true)
        // See https://github.com/remix-run/history/blob/v4.10.1/docs/Navigation.md
        return 'true'
      })
    } else {
      unblockHistoryRef.current()
    }

    return () => {
      unblockHistoryRef.current()
    }
  }, [history, shouldShow])

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await onOK()
      if (canRoute) {
        unblockHistoryRef.current()
        history.push(currentPath)
      }
    }
  }, [currentPath, history, onOK, unblockHistoryRef])

  const handleCancel = useCallback(() => {
    if (onCancel) {
      const canRoute = onCancel()
      if (canRoute) {
        if (mode === 'leave') {
          unblockHistoryRef.current()
          history.push(currentPath)
        }
        if (mode === 'stay') {
          setShowPrompt(false)
          unblockHistoryRef.current()
        }
      }
    }
    setShowPrompt(false)
  }, [currentPath, history, mode, onCancel])

  return showPrompt ? (
    <PageLeaveModal
      isOpen={showPrompt}
      isSaving={isSaving}
      onConfirm={handleOK}
      onCancel={handleCancel}
      mode={mode}
      confirmationText={confirmationText}
    />
  ) : null
}

export default RouterPrompt
