import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'

import { createContent } from '../api'
import { contentKeys } from '../queryKeys'

const useCreateContentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(createContent, {
    onError: (error) => {
      showErrorToast(error)
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
    },
  })
}

export default useCreateContentMutation
