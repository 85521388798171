import TabWidget from 'common/components/TabWidget'

import { DailyPerformance } from '../../../interfaces'

import ReactPerformanceWidget from './ReactPerformanceWidget'

type Props = {
  dailyPerformance: DailyPerformance
  accountId: string
  firstUse: boolean
}

const ReactPerformance: React.FC<Props> = ({
  dailyPerformance,
  accountId,
  firstUse,
}) => {
  return (
    <div className="mt-10">
      <div
        className={`text-xl font-medium
text-coolGray-800 mb-4`}
      >
        {dailyPerformance.display_name}
      </div>
      <TabWidget
        tabs={dailyPerformance.tabs!.map(({ id, display_name }) => ({
          name: display_name,
          content: (
            <div key="1" className="pr-6 flex-auto">
              <ReactPerformanceWidget
                title={dailyPerformance.display_name}
                accountId={accountId}
                campaignId={id}
              />
            </div>
          ),
        }))}
        firstUse={firstUse}
      />
    </div>
  )
}

export default ReactPerformance
