import { memo, useCallback, useState } from 'react'

import { Column } from 'common/components/table'
import {
  NumericCell,
  NumericEditableCell,
  TotalPageValueCell,
} from 'common/components/table/cells'
import BaseCell from 'common/components/table/cells/Base'
import MismatchCell, {
  OnClickValidation,
} from 'common/components/table/cells/Mismatch'
import { OnSwapColumns } from 'common/components/table/Table'
import TableWidget from 'common/components/widget/tableWidget'
import { ProcessedResults } from 'features/campaigns/api'

type Props = {
  processedResults: ProcessedResults
  onChangeValue: (params: {
    rowIndex: number
    name: string
    value: number | undefined
  }) => void
  onClickKeep: OnClickValidation
  onClickRevert: OnClickValidation
  onSwapColumns: OnSwapColumns
}

const SplitResultsTable = ({
  processedResults,
  onChangeValue,
  onClickKeep,
  onClickRevert,
  onSwapColumns,
}: Props) => {
  const [canShowMismatchMessage, setCanShowMismatchMessage] = useState(true)
  const handleCellChange = useCallback(
    ({ columnId, rowIndex, value }) => {
      onChangeValue({ rowIndex, name: columnId, value: value as number })
      setCanShowMismatchMessage(false)
    },
    [onChangeValue]
  )

  const variantColumn: Column = {
    Header: 'Variants',
    accessor: 'variant',
    isResizable: true,
    width: 500,
    Cell: MismatchCell({
      onClickKeep: (args) => {
        setCanShowMismatchMessage(true)
        onClickKeep(args)
      },
      onClickRevert: (args) => {
        setCanShowMismatchMessage(true)
        onClickRevert(args)
      },
      canShowMessage: canShowMismatchMessage,
    }),
    Footer: ({ page }) => {
      return (
        <BaseCell>
          <span>
            {page.length > 1
              ? `Totals for ${page.length} variants`
              : `Totals for ${page.length} variant`}
          </span>
        </BaseCell>
      )
    },
  }
  const columns: Column[] = [
    variantColumn,
    ...processedResults.columns.map(
      ({ isEditable, name, displayName, type }) => ({
        Header: displayName,
        accessor: name,
        align: 'right' as any,
        Cell: isEditable ? NumericEditableCell : NumericCell,
        isEditable,
        Footer: type === 'integer' ? TotalPageValueCell : <span></span>,
      })
    ),
  ]
  const data = processedResults.rows.map(({ data, variant, error }) =>
    data.reduce(
      (previousValue, { name, value }) => ({
        ...previousValue,
        [name]: value,
        hasError: error !== undefined,
        originalVariant: error?.originalVariant,
      }),
      { variant }
    )
  )

  return (
    <TableWidget.Widget
      columns={columns}
      disablePagination
      data={data}
      pageSize={data.length}
      onCellChange={handleCellChange}
      onSwapColumns={onSwapColumns}
    >
      <TableWidget.Header title="Split results"></TableWidget.Header>
    </TableWidget.Widget>
  )
}

// prevent to display the mismatch message when the confirmation close modal appears
export default memo(SplitResultsTable)
