import { Content } from '@phrasee/phrasee-typings'
import isEmpty from 'lodash/isEmpty'

import { parseNode, parseReplacer } from 'common/components/topics/helpers'
import { Fragment, Topic } from 'common/components/topics/interfaces'
import useGetContentQuery from 'features/unifiedFlow/api/queries/useGetContentQuery'

const useTopics = (contentId?: string, elementId?: number) => {
  return useGetContentQuery<
    { topics: Topic[]; fragments: Fragment[] } | undefined
  >(contentId, {
    select: (content: Content) => {
      const selectedElement = content.elements.find(
        (element) => element.element_id === elementId
      )
      const nodes = selectedElement
        ? selectedElement.user_input?.nodes
        : content.user_input.nodes
      const replacers = selectedElement
        ? selectedElement.user_input?.replacers
        : content.user_input.replacers
      const entities = selectedElement
        ? selectedElement.user_input?.entities
        : content.user_input.entities

      if (
        nodes === undefined &&
        replacers === undefined &&
        entities === undefined
      ) {
        return undefined
      }

      const topics: Topic[] = []
      const fragments: Fragment[] =
        entities?.map((entity) => ({
          category: entity.replacer?.displayName || 'Topic',
          name: entity.fragment,
        })) || []

      if (!isEmpty(nodes) || !isEmpty(replacers)) {
        nodes?.forEach((node) => {
          topics.push(parseNode(node))
        })
        replacers?.forEach((replacer) => {
          topics.push(parseReplacer(replacer))
        })
      }

      return { topics, fragments }
    },
  })
}

export default useTopics
