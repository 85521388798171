import { Content } from '@phrasee/phrasee-typings/typings/futurama/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'

import { errorToast } from 'common/components/toastNotification'
import { getAllComponentElements } from 'features/futurama/contentPage/helpers'

import { isComponentElement, isTemplateElement } from '../interfaces'
import { contentKeys } from '../queryKeys'
import { updateVariant } from '..'

type MutationContext = { previousContent: Content }

const useUpdateVariantMutation = (contentId) => {
  const queryClient = useQueryClient()

  return useMutation<
    void,
    unknown,
    {
      accountId: string
      contentId: string
      elementId: number
      variantId: number
      updatedVariantText: string
    },
    MutationContext
  >(updateVariant, {
    onMutate: async ({
      contentId,
      elementId,
      variantId,
      updatedVariantText,
    }) => {
      await queryClient.cancelQueries(contentKeys.content(contentId))

      const previousContent = queryClient.getQueryData<Content>(
        contentKeys.content(contentId)
      )

      queryClient.setQueryData<Content>(
        contentKeys.content(contentId),
        (old) => {
          if (old) {
            const elements = cloneDeep(old?.elements)
            const componentElements = getAllComponentElements(old)

            const variants = componentElements?.find(
              (element) => element.element_id === elementId
            )?.variants

            const updatedVariants = variants?.map((variant) =>
              variant.variant_id === variantId
                ? { ...variant, text: updatedVariantText }
                : variant
            )

            const updatedElements = elements?.map((element) => {
              if (isTemplateElement(element)) {
                const componentElements = cloneDeep(element.elements)
                  .filter(isComponentElement)
                  .map((componentElement) =>
                    componentElement.element_id === elementId
                      ? { ...componentElement, variants: updatedVariants }
                      : componentElement
                  )

                return { ...element, elements: componentElements }
              }

              if (element.element_id === elementId) {
                return { ...element, variants: updatedVariants }
              }

              return element
            })
            return { ...old, elements: updatedElements }
          }
        }
      )

      return { previousContent } as MutationContext
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData<Content>(
        contentKeys.content(contentId),
        (old) => {
          if (!old) {
            return old
          }

          return context?.previousContent
        }
      )
      errorToast('Something went wrong. Please try again.')
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.content(contentId))
    },
  })
}

export default useUpdateVariantMutation
