import isArray from 'lodash/isArray'
import { SubjectLine } from 'workflow/interface'

import { CancelToken, connectInternalApi, restApi } from 'common/api'
import { FieldType } from 'common/components/dynamicFields/interfaces'
import { ConfigurationState } from 'common/components/topics/components/nodeConfiguration/interfaces'
import { parseNode, parseReplacer } from 'common/components/topics/helpers'
import { Fragment, Topic } from 'common/components/topics/interfaces'
import { Node } from 'common/components/topics/interfaces'

import { getContentGenerationPayload } from './helpers'
import { ContentCardsResponse, ReplacerResponse } from './interfaces'

export const getContentCards = async (
  campaignId: string,
  humanLine: string,
  cancelToken: CancelToken
): Promise<{
  fragments: Fragment[]
  descriptionFragments: Fragment[]
  topics: Topic[]
}> => {
  const result = await connectInternalApi.post<{ data: ContentCardsResponse }>(
    `v1/core/main/campaigns/${campaignId}/language/ner`,
    { cancelToken }
  )
  const fragments: Fragment[] = []
  const descriptionFragments: Fragment[] = []
  const topics: Topic[] = []

  const entities = result.data.data.entities
  entities?.control_variant?.forEach((entity) => {
    fragments.push({
      category: entity.replacer?.displayName || 'Topic',
      name: entity.fragment,
    })
  })
  entities?.user_description?.forEach((entity) => {
    descriptionFragments.push({
      category: entity.replacer?.displayName || 'Topic',
      name: entity.fragment,
    })
  })

  entities?.nodes?.forEach((node) => {
    if (!topics.find((topic) => topic.id === `topic-${node.nodeId}`)) {
      topics.push(parseNode(node))
    }
  })

  entities?.replacers?.forEach((replacer) => {
    topics.push(parseReplacer(replacer))
  })

  return { fragments, descriptionFragments, topics }
}

export const getReplacerValue = async (
  request: ConfigurationState<FieldType>,
  replacerId: string,
  campaignId?: string
): Promise<string> => {
  const updatedRequest = request.map((item) => ({
    ...item,
    value: isArray(item.value) ? item.value.join(';') : item.value,
  }))

  // TODO: Use the gateway API once it's ready
  const result = await restApi.post<{ data: { value: string } }>(
    `campaigns/language/replacers/${replacerId}/value`,
    updatedRequest,
    { params: { campaign_id: campaignId } }
  )

  return result.data.data.value
}

export const getNodes = async (
  campaignId: string,
  searchTerm: string,
  cancelToken: CancelToken
): Promise<Topic[]> => {
  const result = await restApi.get<{ data: Node[] }>(
    `campaigns/language/semantic-search`,
    {
      cancelToken,
      params: {
        campaignId: campaignId || undefined,
        query: searchTerm || undefined,
      },
    }
  )

  return result.data.data.map((node) => ({
    id: `topic-${node.nodeId}`,
    name: node.displayName ?? node.node,
    type: 'topic',
    category: 'Topic',
    original: node,
  }))
}

export const getReplacers = async (nodeIds: string[]): Promise<Topic[]> => {
  const response = await connectInternalApi.get<ReplacerResponse>(
    `v1/language/content-updates/3/replacers?includeAncestry=true&node_ids=${nodeIds}`
  )

  const result = response.data.data
    .filter(({ replacerDefinition }) => Object.keys(replacerDefinition).length)
    .map((replacer) => parseReplacer(replacer))

  return result
}

export const getVariants = async ({
  campaignId,
  topics,
  fragments,
  descriptionFragments,
  numSplits,
  wsTopics,
}: {
  campaignId: string
  topics: Topic[]
  fragments: Fragment[]
  descriptionFragments: Fragment[]
  numSplits: number
  wsTopics: string[]
}): Promise<SubjectLine[]> => {
  const payload = getContentGenerationPayload({
    topics,
    fragments,
    descriptionFragments,
    numSplits,
  })
  const updatedPayload = {
    ...payload,
    topics: wsTopics,
    replacers: payload.replacers.map((replacer) => ({
      ...replacer,
      value: isArray(replacer.value)
        ? replacer.value.join(';')
        : replacer.value,
    })),
  }

  const result = await connectInternalApi.post<SubjectLine[]>(
    `v1/core/main/campaigns/${campaignId}/language/nlg`,
    updatedPayload,
    {
      params: {
        async_request: true,
      },
    }
  )

  return result.data
}

export const getVariantsLlm = async ({
  campaignId,
  numSplits,
  wsTopics,
}: {
  campaignId: string
  numSplits: number
  wsTopics: string[]
}): Promise<SubjectLine[]> => {
  const result = await connectInternalApi.post<SubjectLine[]>(
    `v1/core/main/campaigns/${campaignId}/language/nlg`,
    {
      contentUpdateId: 3, // TODO: Remove hardcoding when BE is ready
      numVariants: numSplits || 0,
      topics: wsTopics,
    },
    {
      params: {
        async_request: true,
      },
    }
  )

  return result.data
}
