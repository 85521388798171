import React from 'react'

import ConfirmationCard from 'common/components/ConfirmationCard'
import { useAppDispatch } from 'common/hooks/redux'

import UnscheduleCampaignCard from '../components/card/UnscheduleCampaignCard'
import Scheduler from '../components/scheduler/Scheduler'
import SelectComponent from '../components/select/SelectComponent'

import {
  setError,
  setLoading,
  unscheduleCampaign,
} from './store/adobeTargetSlice'
import initialValidationErrors from './AdobeTargetIntegration'
import CreateOffersCard from './CreateOffersCard'
import DeleteOffersCard from './DeleteOffersCard'

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT || 'YYYY-MM-DD'

interface PrimaryGoal {
  id:
    | 'conversion_pageView'
    | 'conversion_clicks'
    | 'conversion_viewedMbox'
    | 'engagement_pageView'
  name:
    | 'Conversion: Page View'
    | 'Conversion: Clicks'
    | 'Conversion: Viewed an mbox'
    | 'Engagement: Page View'
}

export default function DrawerContent({
  onDateTimeChange,
  currentState,
  campaignId,
  isLoading,
  projectId,
  timeZone,
  setState,
}) {
  const PRIMARY_GOALS: PrimaryGoal[] = [
    {
      id: 'conversion_pageView',
      name: 'Conversion: Page View',
    },
    {
      id: 'conversion_clicks',
      name: 'Conversion: Clicks',
    },
    {
      id: 'conversion_viewedMbox',
      name: 'Conversion: Viewed an mbox',
    },
    {
      id: 'engagement_pageView',
      name: 'Engagement: Page View',
    },
  ]

  const dispatch = useAppDispatch()
  const {
    endTime,
    endDate,
    startDate,
    startTime,
    errorMessages,
    isOffersCreated,
    selectedActivity,
    isActivityScheduled,
    selectedPrimaryGoal,
    showUnlinkConfirmation,
    showUnscheduleConfirmation,
  } = currentState

  return isOffersCreated ? (
    <>
      <DeleteOffersCard
        isLoading={isLoading}
        projectId={projectId}
        campaignId={campaignId}
        setState={setState}
        currentState={currentState}
        showUnlinkConfirmation={showUnlinkConfirmation}
        isActivityScheduled={isActivityScheduled}
      />
      <SelectComponent
        isLoading={false}
        isSelectDisabled={isActivityScheduled}
        selectOptions={PRIMARY_GOALS}
        dataTestId="selectPrimaryGoal"
        onChange={(val) =>
          setState({ ...currentState, selectedPrimaryGoal: val })
        }
        labelTitle="Primary goal"
        bottomText={`This is the Primary Goal you 
            saved within your Adobe Target Activity.`}
        selectValue={selectedPrimaryGoal}
        errorMessage={errorMessages.primaryGoal}
        defaultOption="Select"
      />
      {isOffersCreated && selectedPrimaryGoal && (
        <>
          <Scheduler
            minuteStep={15}
            endDate={endDate}
            endTime={endTime}
            timeFormat="HH:mm"
            timeZone={timeZone}
            dateFormat={DATE_FORMAT}
            errorMessages={errorMessages}
            integrationType="Adobe Target"
            isEndDateDisabled={
              isActivityScheduled ||
              !isOffersCreated ||
              !startDate ||
              !startTime
            }
            isEndTimeDisabled={
              isActivityScheduled ||
              !isOffersCreated ||
              !startDate ||
              !startTime ||
              !endDate
            }
            isStartDateDisabled={isActivityScheduled || !isOffersCreated}
            isStartTimeDisabled={
              isActivityScheduled || !isOffersCreated || !startDate
            }
            startDate={startDate}
            startTime={startTime}
            onChange={onDateTimeChange}
            title="Data collection"
            endTimeTitle="When will you end the A/B test?"
            startTimeTitle="When will you start the A/B test?"
          />
          {isActivityScheduled && (
            <>
              {showUnscheduleConfirmation ? (
                <div className="mt-4 mb-4">
                  <ConfirmationCard
                    isLoading={isLoading.scheduler}
                    isDisabled={false}
                    cardText={`Are you sure you want to Stop collecting data?
              If you do this you will have to setup a new schedule.`}
                    cardTitle="Slow down tiger!"
                    buttonText="Yes, stop collecting"
                    rejectButtonText="Nooo!"
                    onReject={() =>
                      setState({
                        ...currentState,
                        showUnscheduleConfirmation: false,
                      })
                    }
                    onSubmit={() => {
                      dispatch(setLoading({ field: 'scheduler', value: true }))
                      unscheduleCampaign(projectId, campaignId)
                        .then(() => {
                          setState({
                            ...currentState,
                            showUnscheduleConfirmation: false,
                            isActivityScheduled: false,
                            startDate: undefined,
                            startTime: undefined,
                            endDate: undefined,
                            endTime: undefined,
                            selectedPrimaryGoal: undefined,
                            errorMessages: initialValidationErrors,
                            campaignUnscheduled: true,
                          })
                          dispatch(
                            setLoading({ field: 'scheduler', value: false })
                          )
                        })
                        .catch((err) => {
                          dispatch(
                            setLoading({ field: 'scheduler', value: false })
                          )
                          dispatch(setError(err.message))
                        })
                    }}
                  />
                </div>
              ) : (
                <UnscheduleCampaignCard
                  isDisabled={false}
                  dataTestId="unscheduleBtn"
                  cardText={`This will stop importing data from Adobe.
              This will not delete any data you have already received.`}
                  cardTitle="Stop collecting data"
                  buttonText="Stop collecting data"
                  onSubmit={() =>
                    setState({
                      ...currentState,
                      showUnscheduleConfirmation: true,
                    })
                  }
                />
              )}
            </>
          )}
          <div className="font-medium">Scheduling</div>
          <div className="mt-2 text-gray-400">
            All scheduling for your experiment will be managed inside Adobe
            Target.
          </div>
        </>
      )}
    </>
  ) : (
    <CreateOffersCard
      isOffersCreated={isOffersCreated}
      selectedActivity={selectedActivity}
      isLoading={isLoading}
      projectId={projectId}
      campaignId={campaignId}
      setState={setState}
      currentState={currentState}
    />
  )
}
