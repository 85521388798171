import cx from 'classnames'

import styles from './CheckboxIcon.module.css'

type Props = {
  className?: string
  backgroundColor?: string
  state: 'selected' | 'default'
}

const CheckboxIcon = ({ className, state, backgroundColor }: Props) => {
  return state === 'selected' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cx(className, 'hover:opacity-50', {
        [styles.checkbox]: !!backgroundColor,
      })}
    >
      <path
        fill={backgroundColor ?? 'none'}
        fillRule="evenodd"
        fillOpacity={backgroundColor ? '0.5' : '1'}
        stroke="white"
        strokeWidth="1"
        d="M20 4c0-2.382-1.788-4-4-4H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V4zm-3.25 2.597L8.629 15.53a1 1 0 0 1-1.41 0l-3.923-3.9a1 1 0 0 1 1.41-1.418l3.218 3.199 7.447-8.02c.368-.348.898-.63 1.38-.081.323.366.323.796 0 1.288z"
        transform="translate(2 2)"
      />
      <path
        fill="none"
        fillRule="evenodd"
        stroke={backgroundColor ?? 'currentColor'}
        strokeWidth="1.5"
        d="M20 4c0-2.382-1.788-4-4-4H4a4 4 0 0 0-4 4v12a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V4z "
        transform="translate(2 2)"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={cx(className, 'hover:opacity-50')}
    >
      <path
        fill={backgroundColor ?? 'none'}
        fillRule="evenodd"
        fillOpacity={backgroundColor ? '0.5' : '1'}
        stroke={backgroundColor ?? 'currentColor'}
        strokeWidth="1.5"
        d="M16 .75c.916 0 1.747.334 2.339.926.57.57.911 1.375.911 2.324v12a3.24 3.24 0 0 1-.952 2.298A3.24 3.24 0 0 1 16 19.25H4a3.24 3.24 0 0 1-2.298-.952A3.24 3.24 0 0 1 .75 16V4c0-.897.364-1.71.952-2.298A3.24 3.24 0 0 1 4 .75z"
        transform="translate(2 2)"
      />
    </svg>
  )
}

export default CheckboxIcon
