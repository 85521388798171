import Checkbox from 'common/components/checkbox'
import Label from 'common/components/Label'
import Slider from 'common/components/slider/Slider'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  clickIsUsingBeginEmoji,
  clickIsUsingDoubleEmoji,
  clickIsUsingEndEmoji,
  updateEmojiProbability,
} from '../store/styleGuideSlice'

type Props = {
  className?: string
}

const EmojiUseWidget = ({ className }: Props) => {
  const dispatch = useAppDispatch()

  const emojiSettings = useAppSelector(
    (state) => state.styleGuide.editedContent.emojiSettings
  )
  const isNeverProbability = emojiSettings.probability === 0

  return (
    <Widget data-cy="emoji-use-widget" className={className}>
      <WidgetHeader
        title="Emoji use"
        subtitle="Jacquard will only generate emojis for appropriate content types, for example, email subject lines and push messages. Move the slider to ‘Never’ if you do not want emojis to generate. "
      />
      <Label>How often should Jacquard use these in generations?</Label>
      <div className="text-coolGray-400 sm:w-72 pt-2">
        <Slider
          minLabel="Never"
          maxLabel="All the time"
          min={0}
          max={100}
          value={emojiSettings.probability}
          onChange={(value) => {
            dispatch(updateEmojiProbability(value as number))
          }}
        />
      </div>
      <Checkbox
        data-cy="use-emoji-left"
        isChecked={emojiSettings.isUsingBeginEmoji}
        isDisabled={isNeverProbability || emojiSettings.isUsingDoubleEmoji}
        className="mt-6"
        onChange={(isChecked: boolean) => {
          dispatch(clickIsUsingBeginEmoji(isChecked))
        }}
        label={
          <>
            <span className="font-medium">
              Use emojis at the beginning of a line
            </span>{' '}
            (E.g. 😀 Our sale’s biggest discounts)
          </>
        }
      ></Checkbox>
      <Checkbox
        data-cy="use-emoji-right"
        isChecked={emojiSettings.isUsingEndEmoji}
        isDisabled={isNeverProbability || emojiSettings.isUsingDoubleEmoji}
        className="mt-6"
        onChange={(isChecked: boolean) => {
          dispatch(clickIsUsingEndEmoji(isChecked))
        }}
        label={
          <>
            <span className="font-medium">Use emojis at the end of a line</span>{' '}
            (E.g. Our sale’s biggest discounts 😀)
          </>
        }
      ></Checkbox>
      <Checkbox
        data-cy="use-emoji-double"
        isChecked={emojiSettings.isUsingDoubleEmoji}
        isDisabled={isNeverProbability}
        className="mt-6"
        onChange={(isChecked: boolean) => {
          dispatch(clickIsUsingDoubleEmoji(isChecked))
        }}
        label={
          <>
            <span className="font-medium">
              Use emojis at the both ends of a line
            </span>{' '}
            (E.g. 😀 Our sale’s biggest discounts 😀)
          </>
        }
      ></Checkbox>
    </Widget>
  )
}

export default EmojiUseWidget
