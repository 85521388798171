import {
  toast,
  ToastContainer,
  ToastContent,
  ToastOptions,
} from 'react-toastify'

import { Info, MissingData, Tick } from 'common/icons'

export type Options = Omit<ToastOptions, 'type' | 'icon'>

const successToast = (message: ToastContent, options?: Options) =>
  toast(message, {
    ...options,
    icon: <Tick className="text-white" isDefaultColor={false} />,
    type: 'success',
  })

const errorToast = (message: ToastContent, options?: Options) =>
  toast(message, {
    ...options,
    icon: <Info className="text-white" isDefaultColor={false} />,
    type: 'error',
  })

const infoToast = (message: ToastContent, options?: Options) =>
  toast(message, {
    ...options,
    icon: <Info className="text-white" isDefaultColor={false} />,
    type: 'info',
  })

const warningToast = (message: ToastContent, options?: Options) =>
  toast(message, {
    ...options,
    icon: <MissingData className="text-amber-700" isDefaultColor={false} />,
    type: 'warning',
  })

export { ToastContainer, successToast, errorToast, infoToast, warningToast }
