import { FC, useState } from 'react'
import cx from 'classnames'

import Overlay from 'common/components/Overlay'
import { useAppSelector } from 'common/hooks/redux'
import { SidePanelHide, SidePanelShow } from 'common/icons'

import Button from './components/button'
import { DETAILS_TRANSITION_DURATION } from './constants'

interface Props {
  isExpanded: boolean
  setIsExpanded: (val: boolean) => void
}

const DetailsPanel: FC<Props> = ({ isExpanded, setIsExpanded, children }) => {
  const { isNavCollapsed } = useAppSelector((state) => state.navigation)
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)

  const hasCollapsed = !isExpanded && !isTransitioning

  const toggleWidth = () => {
    setIsExpanded(!isExpanded)

    if (isExpanded) {
      setIsTransitioning(true)
      setTimeout(() => {
        setIsTransitioning(false)
      }, DETAILS_TRANSITION_DURATION)
    }
  }

  return (
    <>
      <div
        data-cy="details-panel"
        className={cx(
          `h-screen fixed right-0 flex flex-col pt-4 pb-24 pl-6 bg-gray-50 shadow-inner overflow-x-hidden overflow-y-auto transition-width duration-${DETAILS_TRANSITION_DURATION} top-13.5`,
          { 'w-80': isExpanded },
          { 'w-18': !isExpanded },
          { 'z-40': isNavCollapsed }
        )}
      >
        <Button
          data-cy="toggle-details-panel"
          onClick={toggleWidth}
          title={`${hasCollapsed ? 'Show' : 'Hide'} panel`}
          icon={isExpanded ? <SidePanelHide /> : <SidePanelShow />}
          isExpanded={isExpanded}
        />
        {children}
      </div>
      <Overlay
        className={cx('md:hidden', { hidden: !isExpanded })}
        onClick={() => setIsExpanded(false)}
      />
    </>
  )
}

export default DetailsPanel
