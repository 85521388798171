import { useQuery } from '@tanstack/react-query'

import { TreeNode } from 'common/components/catalog/Catalog'
import { useAppSelector } from 'common/hooks/redux'

import { CustomerAttributes, fetchVariants } from '../api'
import { personalizationKeys } from '../queryKeys'

const transformVariant = (variant: any) => {
  const { channel, variantText, sends } = variant
  const additionalFields =
    'opens' in variant
      ? { clicks: variant.clicks, opens: variant.opens }
      : { totalOpens: variant.totalOpens, directOpens: variant.directOpens }
  return { channel, variantText, sends, ...additionalFields }
}

const useGetVariantsQuery = ({
  personalizationId,
  customerAttributes,
  productCategories,
}: {
  personalizationId: string
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const query = useQuery(
    personalizationKeys.variants({
      accountId,
      personalizationId,
      customerAttributes,
      productCategories,
    }),
    () =>
      fetchVariants({
        accountId,
        personalizationId,
        customerAttributes: customerAttributes,
        productCategories: productCategories,
      }),
    {
      select: ({ data: { totalCount, filteredCount, items } }) => {
        return {
          totalCount: totalCount,
          filteredCount: filteredCount,
          variants: items.map(transformVariant),
        }
      },
    }
  )

  return query
}

export default useGetVariantsQuery
