import { useEffect, useState } from 'react'
import {
  DistributionChannel,
  DistributionType,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { gql } from 'graphql-request'
import { unionBy } from 'lodash'
import moment from 'moment'
import { showBanner } from 'workflow/common/common.actions'

import { createCancelTokenSource, fetchGraphQL, isCancel } from 'common/api'
import MultiSelect, { MultiSelectValue } from 'common/components/MultiSelect'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { setCampaignList } from '../store/reportsSlice'

const CAMPAIGNS_PAGE_SIZE = 50
export const campaignSelectorText = {
  label: 'Experiments',
}
const SEND_DATE_FORMAT = 'DD-MM-YYYY'

interface Props {
  selectedItems: string[]
  onApply: (val: string[]) => void
  applyDates?: (startDate?: string, endDate?: string) => void
  productFilter: DistributionType | undefined
  channelFilter: DistributionChannel | undefined
  projectFilter: string[]
  className?: string
  isDisabled?: boolean
  placeholder?: string
}

export interface Campaign extends MultiSelectValue {
  send_date: string
  projectId: string
}

interface CampaignsResponse {
  campaigns: Campaign[]
  earliestCampaignSendDate: string
  latestCampaignSendDate: string
  count: number
}

interface Filter {
  status: 'completed'
  accountId: string
  name?: string
  projectsList?: string[]
  distributionChannelList?: DistributionChannel
  distributionType?: DistributionType
}

const CampaignSelector = ({
  selectedItems: selectedIds,
  onApply,
  applyDates,
  projectFilter,
  productFilter,
  channelFilter,
  className,
  isDisabled,
  placeholder = 'No experiments',
}: Props) => {
  const dispatch = useAppDispatch()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [cachedCampaigns, setCachedCampaigns] = useState<Campaign[]>([])
  const [campaignsCount, setCampaignsCount] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [nextPage, setNextPage] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [sendDates, setSendDates] = useState<[string, string]>()
  const [selectedCampaigns, setSelectedCampaigns] = useState<Campaign[]>([])

  const numberOfPages = campaignsCount
    ? Math.ceil(campaignsCount / CAMPAIGNS_PAGE_SIZE)
    : undefined

  useEffect(() => {
    setCampaigns([])
    setNextPage(1)
  }, [projectFilter, search])

  useEffect(() => {
    const source = createCancelTokenSource()

    const fetchCampaigns = async () => {
      if (!isDisabled) {
        setIsLoading(true)
        let filter: Filter = {
          status: 'completed',
          name: search,
          accountId,
        }

        if (productFilter !== undefined) {
          filter = { ...filter, distributionType: productFilter }
        }
        if (channelFilter !== undefined) {
          filter = { ...filter, distributionChannelList: channelFilter }
        }
        if (projectFilter.length > 0) {
          filter = { ...filter, projectsList: projectFilter }
        }

        const fetchCampaignsQuery = function (): Promise<CampaignsResponse> {
          const query = gql`
            query performanceSelectorCampaigns(
              $page: Int
              $pageSize: Int
              $filter: CampaignFilter
            ) {
              data: campaigns(
                page: $page
                pageSize: $pageSize
                filter: $filter
              ) {
                campaigns {
                  id: _id
                  value: _id
                  label: name
                  send_date
                  projectId: project_id
                }
                earliestCampaignSendDate
                latestCampaignSendDate
                count
              }
            }
          `
          const variables = {
            page: nextPage,
            pageSize: CAMPAIGNS_PAGE_SIZE,
            filter,
          }

          return fetchGraphQL<CampaignsResponse>(
            { query, variables },
            { cancelToken: source.token }
          )
        }

        fetchCampaignsQuery()
          .then(
            ({
              campaigns: responseCampaigns,
              earliestCampaignSendDate,
              latestCampaignSendDate,
              count,
            }: CampaignsResponse) => {
              setCampaignsCount(count)
              setCampaigns((campaigns) => [...campaigns, ...responseCampaigns])

              setCachedCampaigns((campaigns) =>
                unionBy(campaigns, responseCampaigns, 'id')
              )
              dispatch(setCampaignList([...campaigns, ...responseCampaigns]))

              if (applyDates && nextPage === 1) {
                applyDates(earliestCampaignSendDate, latestCampaignSendDate)
                setSendDates([earliestCampaignSendDate, latestCampaignSendDate])
              }
            }
          )
          .catch((err) => {
            if (!isCancel(err)) {
              dispatch(
                showBanner({
                  type: 'error',
                  content: 'Failed to load experiment options',
                })
              )
            }
          })
          .finally(() => setIsLoading(false))
      }
    }

    fetchCampaigns()

    return () => source.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    setCampaigns,
    nextPage,
    projectFilter,
    search,
    productFilter,
    channelFilter,
    isDisabled,
    accountId,
  ])

  const loadMore = () => {
    if (numberOfPages && nextPage < numberOfPages && !isLoading) {
      setNextPage(nextPage + 1)
    }
  }

  const handleApply = (ids: string[]): void => {
    const updatedSelectedCampaigns = cachedCampaigns.filter(({ id }) =>
      ids.includes(id)
    )
    onApply(ids)
    setSelectedCampaigns(updatedSelectedCampaigns)

    if (!applyDates) {
      return
    } else if (ids.length) {
      const sortedSendDates = updatedSelectedCampaigns
        .map(({ send_date }) => moment(send_date, SEND_DATE_FORMAT))
        .sort((a, b) => a.diff(b))
        .map((date) => date.toISOString())
      const firstSendDate = sortedSendDates[0]
      const lastSendDate = sortedSendDates[sortedSendDates.length - 1]

      applyDates(firstSendDate, lastSendDate)
    } else if (sendDates) {
      applyDates(sendDates[0], sendDates[1])
    }
  }

  return (
    <MultiSelect<MultiSelectValue>
      data-cy="reports-campaign-select"
      className={className}
      // to have selected options displayed at top, `items` must include the selected campaigns
      // selectedCampaigns need to be kept track of and merged because of BE search and pagination
      items={unionBy(
        campaigns,
        selectedCampaigns.filter(({ label }) =>
          label.toLowerCase().includes(search.toLowerCase())
        ),
        'id'
      )}
      selectedItems={selectedIds}
      name="experiments"
      label={campaignSelectorText.label}
      placeholder={placeholder}
      loadMore={loadMore}
      isLoading={isLoading}
      onApply={handleApply}
      minOptions={1}
      onSearch={setSearch}
      isDisabled={isDisabled}
    />
  )
}

export default CampaignSelector
