import { Field, Form } from 'react-final-form'
import { generatePath, useHistory } from 'react-router'
import { styleGuidePath } from 'app/navigation/paths'
import cx from 'classnames'

import useRegionsListQuery from 'common/api/queries/useRegionsListQuery'
import Button from 'common/components/button'
import CheckboxComponent from 'common/components/checkbox'
import ErrorPage from 'common/components/error/ErrorPage'
import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { errorToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { getFieldError, validateBrandVoiceName } from '../helpers'
import { BrandVoice, createBrandVoice } from '../store/brandVoiceSlice'

type Props = {
  className?: string
  clonedBrandVoice?: BrandVoice
  onClose: () => void
}

const CreateWidget = ({ className, clonedBrandVoice, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const {
    editedContent: { brandVoices },
  } = useAppSelector((state) => state.brandVoice)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const brandVoiceNames = brandVoices.map((brandVoice) => {
    return brandVoice.name
  })

  const { data: regionsData, status: regionStatus } = useRegionsListQuery()
  const regionOptions = regionsData?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const validateName = (value: string | undefined) => {
    return !value
      ? 'Please enter the brand voice name.'
      : validateBrandVoiceName(value, brandVoiceNames)
  }

  const validateRegion = (value: SelectValue | undefined) => {
    return !value?.value ? 'Please select your region.' : undefined
  }

  const isFirstBrandVoice = brandVoices.length === 0

  if (regionStatus === 'error') {
    return <ErrorPage />
  }

  return (
    <div className="w-123">
      <Form
        initialValues={{
          name: clonedBrandVoice ? `${clonedBrandVoice.name} - COPY` : '',
          region: clonedBrandVoice
            ? {
                label: regionOptions?.find(
                  (region) =>
                    region.value.toLowerCase() ===
                    clonedBrandVoice.regionId.toLowerCase()
                )?.label,
                value: clonedBrandVoice.regionId,
              }
            : undefined,
          isDefault: isFirstBrandVoice,
        }}
        onSubmit={() => {}}
        render={({ submitting: isSubmitting, hasValidationErrors, values }) => (
          <Widget type="basic" className={cx(className)}>
            <WidgetHeader title="Create a new Brand Voice" />
            <form
              onSubmit={async (e) => {
                e.preventDefault()
                if (hasValidationErrors) {
                  errorToast(
                    'Please complete all necessary fields before saving.'
                  )
                } else {
                  const brandVoice = {
                    name: values.name.trim(),
                    lastModified: '',
                    isDefault: values.isDefault ?? false,
                    regionId: values.region.value,
                    tones: [],
                  }
                  const result = await dispatch(
                    createBrandVoice({
                      brandVoice,
                      clonedBrandVoice,
                    })
                  ).unwrap()

                  const newBrandVoice = result.find(
                    (voice) => voice.name === brandVoice.name
                  )

                  onClose()

                  history.push(
                    generatePath(styleGuidePath, {
                      accountId,
                      brandVoiceId: newBrandVoice?.id ?? '',
                    })
                  )
                }
              }}
            >
              <div className="divide-y-1 divide-dashed">
                <Field<string> name="name" validate={validateName}>
                  {({ input, meta }) => (
                    <FormItem
                      label="Give your Brand Voice a name"
                      htmlFor="name"
                      error={getFieldError(meta)}
                    >
                      <Input
                        data-cy="brand-voice-name"
                        className="w-full mt-2"
                        type="text"
                        variant="default"
                        id="name"
                        name="name"
                        placeholder="Your Brand Voice name here"
                        value={input.value}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                      />
                    </FormItem>
                  )}
                </Field>
                <div className="pt-4">
                  <Field<SelectValue> name="region" validate={validateRegion}>
                    {({ input, meta }) => (
                      <FormItem
                        label="Region"
                        htmlFor="region"
                        error={getFieldError(meta)}
                      >
                        <div className="text-coolGray-400">
                          Select a region below. This will set certain default
                          settings in your content generations.
                        </div>
                        <SingleSelect
                          className="w-full mt-2"
                          data-cy="region-select"
                          name="region"
                          placeholder="Select your region"
                          value={input.value.value}
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                          options={regionOptions}
                          menuPortalTarget={document.body}
                          isDisabled={!!clonedBrandVoice}
                        />
                      </FormItem>
                    )}
                  </Field>
                  <Field<boolean> name="isDefault">
                    {({ input }) => (
                      <div className="w-52">
                        <Tooltip
                          overlay="Default brand voice must be selected. Add more to change your default."
                          show={isFirstBrandVoice}
                        >
                          <CheckboxComponent
                            data-cy="default-checkbox"
                            label="Mark as default"
                            className="mr-6"
                            isChecked={input.value || isFirstBrandVoice}
                            onChange={(e) => {
                              input.onChange(e)
                            }}
                            isDisabled={isFirstBrandVoice || !!clonedBrandVoice}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </Field>

                  <div className="text-coolGray-400 mt-2">
                    Your default voice will be automatically selected when
                    generating content. This should be the Brand Voice you use
                    most often.
                  </div>
                </div>
              </div>

              <div className="flex justify-end items-center mb-4 mt-6">
                <Button
                  data-cy="generate-tone-button"
                  data-testid="generate-tone-button"
                  aria-label="Generate tone"
                  ghost
                  onClick={onClose}
                  className="text-base mr-4"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  data-cy="generate-tone-button"
                  data-testid="generate-tone-button"
                  aria-label="Generate tone"
                  variant="primary"
                  className="text-base"
                  disabled={isSubmitting || hasValidationErrors}
                >
                  Save
                </Button>
              </div>
            </form>
          </Widget>
        )}
      />
    </div>
  )
}

export default CreateWidget
