import React from 'react'

import Spinner from 'common/components/spinner'
interface Props {
  children: React.ReactNode
  isLoading: boolean
  'data-testid'?: string
}

const ChartContainer = ({
  children,
  isLoading,
  'data-testid': dataTestId = 'chartContainer',
}: Props) => {
  return (
    <div
      className="relative flex items-center justify-center"
      data-testid={dataTestId}
    >
      <Spinner isSpinning={isLoading}>{children}</Spinner>
    </div>
  )
}

export default ChartContainer
