const BriefTitle = () => {
  return (
    <>
      <div className="text-xl font-medium text-coolGray-800">Brief</div>
      <div className="text-sm text-coolGray-500 mb-6">
        Input a short description and Jacquard will generate an optimized brief.
      </div>
    </>
  )
}

export default BriefTitle
