import React from 'react'
import { connect } from 'react-redux'

import ConfirmationCard from 'features/campaigns/integration-options/components/card/ConfirmationCard'
import UnscheduleCampaignCard from 'features/campaigns/integration-options/components/card/UnscheduleCampaignCard'

export interface Props {
  campaignId?: string
  campaignData?: any
  onCancel: () => void
}

interface State {
  showConfirmationCard: boolean
}

export const initialState: State = {
  showConfirmationCard: false,
}

class CancelCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  render() {
    const { onCancel } = this.props
    const { showConfirmationCard } = this.state

    return showConfirmationCard ? (
      <ConfirmationCard
        isDisabled={false}
        cardText={`Are you sure want to cancel this experiment?
                If you do this you will have to setup a
                new schedule.`}
        cardTitle="Whooooaaaaaa!"
        buttonText="Yes, cancel"
        rejectButtonText="Nooo!"
        isLoading={false}
        onSubmit={onCancel}
        onReject={() => this.setState({ showConfirmationCard: false })}
      />
    ) : (
      <UnscheduleCampaignCard
        isDisabled={false}
        dataTestId="unscheduleBtn"
        cardText={`This will stop importing data from Braze and
                cancel this experiment in Jacquard.`}
        cardTitle="Cancel experiment"
        buttonText="Cancel"
        onSubmit={() => this.setState({ showConfirmationCard: true })}
      />
    )
  }
}

export function mapStateToProps(state: any) {
  const { currentCampaignId, campaignData } = state.campaignStates

  return {
    campaignId: currentCampaignId,
    campaignData,
  }
}

export default connect(mapStateToProps, null)(CancelCard)
