import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { contentKeys } from '../queryKeys'
import { selecteElement } from '..'

const useSelectElementMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(selecteElement, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
    },
  })
}

export default useSelectElementMutation
