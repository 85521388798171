import { Node, NodeTreeMetadata } from 'common/interfaces/nodes'

import { NodeMetadata } from '../../api/interfaces'

import EmptyNodesModal from './EmptyNodesModal'
import ReportingBox from './ReportingBox'
import TagsModal from './TagsModal'

type Props = {
  node: Node
  isTreeView: boolean
  metadata?: NodeTreeMetadata | NodeMetadata
}

const Reporting = ({ node, metadata, isTreeView }: Props) => {
  return (
    <div className="mb-8">
      {/* TODO: Wait for the endpoint and fix in another PR */}
      {/* <div className="text-base text-coolGray-400 mt-2">
        Last updated 2 October 2021 by Steven Jones.{' '}
        <span className="text-maroon-300">View changes</span>
      </div> */}

      <div className="flex mt-4">
        <ReportingBox
          title="Total phrases"
          value={metadata?.totalPhrasesCount}
        />
        <ReportingBox
          title="Generic phrases"
          value={metadata?.genericPhrasesCount}
        />
        <ReportingBox
          title="Tags"
          value={metadata?.uniqueTagsCount}
          modal={<TagsModal isTreeView={isTreeView} nodeId={node.id} />}
        />
        <ReportingBox
          title="Replacers"
          value={metadata?.uniqueReplacersCount}
        />
        {metadata && 'emptyNodesCount' in metadata && (
          <ReportingBox
            title="Empty nodes"
            value={metadata?.emptyNodesCount}
            modal={<EmptyNodesModal />}
          />
        )}
      </div>
    </div>
  )
}

export default Reporting
