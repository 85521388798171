import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'

const DemoHeader = () => {
  return (
    <PageHeader className="mb-8">
      <div className="pt-6">
        <PageTitle title="Personalized Campaign" />
      </div>
    </PageHeader>
  )
}

export default DemoHeader
