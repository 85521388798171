import { useAppSelector } from 'common/hooks/redux'

import ContentTable from './components/ContentTable'
import StartHere from './components/StartHere'
import SubmitForApproval from './components/submitForApproval'

export const START_HERE_COMPONENT_ID = 'startHere'
export const APPROVAL_COMPONENT_ID = 'awaiting_for_approval'

const componentMap = {
  [START_HERE_COMPONENT_ID]: StartHere,
  [APPROVAL_COMPONENT_ID]: SubmitForApproval,
}

const Content = () => {
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const showComponentConfiguration = useAppSelector(
    (state) => state.content.showComponentConfiguration
  )

  const SelectedComponent = showComponentConfiguration
    ? StartHere
    : componentMap[selectedElementId || START_HERE_COMPONENT_ID] ?? ContentTable

  return (
    <div className="flex-1 pt-6 px-6">
      <SelectedComponent />
    </div>
  )
}

export default Content
