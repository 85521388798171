/* eslint-disable max-lines */

import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import type { Moment } from 'moment'
import moment from 'moment-timezone'
import { bindActionCreators } from 'redux'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'
import helpers from 'workflow/utils/helpers'

import Button from 'common/components/button/Button'
import DatePickerAntd from 'common/components/DatePicker'
import MultiSelect from 'common/components/MultiSelect'
import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import TimePicker from 'common/components/TimePicker'
import Tooltip from 'common/components/Tooltip'
import { Time as TimeIcon } from 'common/icons'

import {
  AsyncThunkDispatch,
  cancelBrazeCampaign,
  fetchBrazeCampaigns,
  fetchCampaignData,
  fetchTimeZone,
  syncBrazeCampaign,
} from '../../../Workflow.actions'

import CancelCard from './CancelCard'

import styles from '../Integrations.module.css'

const { shouldShowNextHour } = helpers
const FormItem = Form.Item

const TOOLTIP_TEXT = {
  dropdown: 'Connect a Braze campaign for Jacquard to retrieve results',
  startTime: 'This is the time that your Braze campaign is scheduled to start.',
  testEndTime:
    'This is the time that Braze will end the multi-variate test and ' +
    'Jacquard will collect the test results.',
  campaignEndTime:
    'This is the time that Jacquard will collect the results of the final send',
  draft:
    'Jacquard has matched the following draft messages based on their ' +
    'External ID',
  buttonDefault:
    'To ensure accurate test results, make sure these details match Braze.',
}

export interface Props {
  actions: any
  flags: LDFlagSet
  projectId: string
  campaignId: string
  brazeCampaigns: any[]
  isLoadingCampaigns: boolean
  isUpdatingMessages: boolean
  maxMultiselectAmount: number | undefined
  timeZone: string
  distributionChannel: string
  campaignData: any
  closeDrawer: () => void
  footerstyles: React.CSSProperties
  getCampaignData: (campaignId: string) => Promise<void>
  updateBrazeCampaign: (
    data: any,
    campaignId: string,
    callback: () => void
  ) => Promise<void>
}

interface State {
  selectedCampaign: Array<string> | string | undefined
  campaignStartDate: any
  campaignStartTime: any
  campaignEndDate: any
  campaignEndTime: any
  testEndDate: any
  testEndTime: any
  isMultiSelect: boolean
  errorMessages: any
  timeZone: string | undefined
}

export const initialState: State = {
  selectedCampaign: [],
  campaignStartDate: undefined,
  campaignStartTime: undefined,
  campaignEndDate: undefined,
  campaignEndTime: undefined,
  testEndDate: undefined,
  testEndTime: undefined,
  isMultiSelect: false,
  errorMessages: {},
  timeZone: undefined,
}

export class BrazeIntegration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const {
      actions,
      projectId,
      maxMultiselectAmount,
      distributionChannel,
      campaignData,
    } = this.props

    const selectedBrazeCampaigns =
      campaignData?.campaign_data?.braze_campaign_id || []
    if (projectId && distributionChannel) {
      actions.fetchBrazeCampaigns(
        projectId,
        distributionChannel,
        selectedBrazeCampaigns
      )
    }
    if (projectId) {
      actions.fetchTimeZone(projectId)
    }
    this.setState({
      isMultiSelect: !!(maxMultiselectAmount && maxMultiselectAmount > 1),
    })
  }

  componentDidUpdate() {
    const { timeZone: propsTimeZone, campaignData } = this.props
    const { timeZone: stateTimeZone } = this.state

    if (
      propsTimeZone &&
      typeof propsTimeZone === 'string' &&
      !stateTimeZone &&
      campaignData
    ) {
      const selectedBrazeCampaigns =
        campaignData?.campaign_data?.braze_campaign_id || []
      this.setState({
        timeZone: propsTimeZone,
        selectedCampaign: selectedBrazeCampaigns,
        campaignStartDate: campaignData?.campaign_data?.campaign_start_time
          ? moment
              .utc(campaignData?.campaign_data?.campaign_start_time)
              .tz(propsTimeZone)
          : moment().tz(propsTimeZone),
        campaignStartTime: campaignData?.campaign_data?.campaign_start_time
          ? moment
              .utc(campaignData?.campaign_data?.campaign_start_time)
              .tz(propsTimeZone)
          : moment().tz(propsTimeZone),
        testEndDate: campaignData?.campaign_data?.optimization_end_time
          ? moment
              .utc(campaignData?.campaign_data?.optimization_end_time)
              .tz(propsTimeZone)
          : moment().add(1, 'hour').tz(propsTimeZone),
        testEndTime: campaignData?.campaign_data?.optimization_end_time
          ? moment
              .utc(campaignData?.campaign_data?.optimization_end_time)
              .tz(propsTimeZone)
          : moment().add(1, 'hour').tz(propsTimeZone),
        campaignEndDate: campaignData?.campaign_data?.campaign_end_time
          ? moment
              .utc(campaignData.campaign_data.campaign_end_time)
              .tz(propsTimeZone)
          : moment().add(4, 'days').tz(propsTimeZone),
        campaignEndTime: campaignData?.campaign_data?.campaign_end_time
          ? moment
              .utc(campaignData?.campaign_data?.campaign_end_time)
              .tz(propsTimeZone)
          : moment().add(4, 'days').tz(propsTimeZone),
      })
    }
  }

  componentWillUnmount() {
    const { campaignId, getCampaignData } = this.props
    getCampaignData(campaignId)
  }

  onChangeUpdateState = (val: any, type: string) => {
    const { maxMultiselectAmount } = this.props
    const { selectedCampaign, isMultiSelect } = this.state

    let campaigns: Array<string>

    if (type === 'campaign' && maxMultiselectAmount && isMultiSelect) {
      campaigns = val.length <= maxMultiselectAmount ? val : selectedCampaign
    }
    if (type === 'campaign' && !isMultiSelect && val) {
      campaigns = [val]
    }
    let campaignEndDateTime = type === 'campaign_end_date' ? val : undefined
    let campaignTestEndTime = type === 'test_end_date' ? val : undefined
    if (type === 'campaign_start_date') {
      campaignEndDateTime = moment(val).add(4, 'days')
      campaignTestEndTime = val
    }
    this.setState((state) => {
      return {
        ...state,
        ...(type === 'campaign' && { selectedCampaign: campaigns }),
        ...(type === 'campaign_start_date' && { campaignStartDate: val }),
        ...(type === 'campaign_start_time' && { campaignStartTime: val }),
        ...((type === 'campaign_end_date' ||
          type === 'campaign_start_date') && {
          campaignEndDate: campaignEndDateTime,
        }),
        ...(type === 'campaign_end_time' && { campaignEndTime: val }),
        ...((type === 'test_end_date' || type === 'campaign_start_date') && {
          testEndDate: campaignTestEndTime,
        }),
        ...(type === 'test_end_time' && { testEndTime: val }),
      }
    })
  }

  disableDate = (current: any, compareStartDate = false) => {
    const { campaignStartDate, timeZone } = this.state
    if (timeZone && current.tz) {
      let dateToCompare = moment().tz(timeZone).format('YYYY-MM-DD')
      if (compareStartDate) {
        dateToCompare = moment(campaignStartDate)
          .tz(timeZone)
          .format('YYYY-MM-DD')
      }
      return (
        current && current.tz(timeZone).format('YYYY-MM-DD') < dateToCompare
      )
    }
    return false
  }

  getDisabledHours = (): any => {
    const { campaignStartDate, timeZone } = this.state
    if (
      campaignStartDate &&
      timeZone &&
      !moment(campaignStartDate).isSame(moment().tz(timeZone), 'day') &&
      moment(campaignStartDate).format() > moment().tz(timeZone).format()
    ) {
      return []
    }
    if (timeZone) {
      const dateFormatted = moment().tz(timeZone).format('Y-MM-DD')
      const timeFormatted = moment().tz(timeZone).format('HH:mm')
      return this.getDisabledHoursArray(dateFormatted, timeFormatted)
    }
  }

  getTestTimeDisabledHours = (): any => {
    const { campaignStartDate, campaignStartTime, testEndDate, timeZone } =
      this.state

    const selectedDate = testEndDate
    const startDate = campaignStartDate
    const startTime = campaignStartTime
    const dateFormatted = startDate.format('Y-MM-DD')
    const timeFormatted = startTime.format('HH:mm')
    if (startDate.isSame(selectedDate, 'day') && timeZone) {
      return this.getDisabledHoursArray(dateFormatted, timeFormatted)
    }
    return []
  }

  getCampaignEndDisabledHours = (): any => {
    const { testEndDate, testEndTime, campaignEndDate } = this.state
    const { timeZone } = this.props

    const selectedDate = campaignEndDate
    const startDate = testEndDate
    const startTime = testEndTime
    const dateFormatted = startDate.format('Y-MM-DD')
    const timeFormatted = startTime.format('HH:mm')
    if (startDate.isSame(selectedDate, 'day') && timeZone) {
      return this.getDisabledHoursArray(dateFormatted, timeFormatted, true)
    }
    return []
  }

  getDisabledHoursArray = (
    dateFormatted: any,
    timeFormatted: any,
    allowSameTime?: boolean
  ) => {
    const extraTime = allowSameTime ? 0 : 60
    const hourIncludingExtraTime = moment(`${dateFormatted} ${timeFormatted}`)
      .add(extraTime, 'minutes')
      .hour()
    const minutesIncludingTimeZoneAndExtraTime = moment(
      `${dateFormatted} ${timeFormatted}`
    )
      .add(extraTime, 'minutes')
      .minutes()
    const hoursToDisable = shouldShowNextHour(
      minutesIncludingTimeZoneAndExtraTime
    )
      ? hourIncludingExtraTime + 1
      : hourIncludingExtraTime
    // if selected date is in the future, do not disable hours.
    return [...Array(hoursToDisable).keys()]
  }

  getDisabledMinutesArray = (
    selectedHour: any,
    dateFormatted: any,
    timeFormatted: any
  ) => {
    const extraTime = 60
    const minutesIncludingExtraTime = moment(
      `${dateFormatted} ${timeFormatted}`
    )
      .add(extraTime, 'minutes')
      .minutes()
    const nowHourIncludingTimeZoneAndExtraTime = moment(
      `${dateFormatted} ${timeFormatted}`
    )
      .add(extraTime, 'minutes')
      .hour()
    // When user chooses a future hour, we need to figure
    // out disabled minutes for the new hour:
    if (
      selectedHour &&
      selectedHour - nowHourIncludingTimeZoneAndExtraTime >= 1
    ) {
      return []
    }
    const minutesToDisable = shouldShowNextHour(minutesIncludingExtraTime)
      ? 0
      : minutesIncludingExtraTime
    // If NOT, figure out the UI.
    return [...Array(minutesToDisable).keys()]
  }

  getDisabledMinutes = (selectedHour: number): any => {
    const { campaignStartDate, timeZone } = this.state
    // If selected date is in future do not disable anything and return.
    if (campaignStartDate && moment(campaignStartDate) > moment()) {
      return []
    }
    if (timeZone) {
      const dateFormatted = moment().tz(timeZone).format('Y-MM-DD')
      const timeFormatted = moment().tz(timeZone).format('HH:mm')
      return this.getDisabledMinutesArray(
        selectedHour,
        dateFormatted,
        timeFormatted
      )
    }
    return []
  }

  getTestTimeDisabledMinutes = (selectedHour: number): any => {
    const { campaignStartDate, campaignStartTime, testEndDate, timeZone } =
      this.state
    const selectedDate = testEndDate
    const startDate = campaignStartDate
    const startTime = campaignStartTime
    const dateFormatted = startDate.tz(timeZone).format('Y-MM-DD')
    const timeFormatted = startTime.tz(timeZone).format('HH:mm')
    // If selected date is in future do not disable anything and return.
    if (startDate.isSame(selectedDate, 'day') && timeZone) {
      return this.getDisabledMinutesArray(
        selectedHour,
        dateFormatted,
        timeFormatted
      )
    }
    return []
  }

  getCampaignEndDisabledMinutes = (selectedHour: number): any => {
    const { testEndDate, testEndTime, campaignEndDate } = this.state
    const { timeZone } = this.props
    const selectedDate = campaignEndDate
    const startDate = testEndDate
    const startTime = testEndTime
    const dateFormatted = startDate.tz(timeZone).format('Y-MM-DD')
    const timeFormatted = startTime.tz(timeZone).format('HH:mm')
    // If selected date is in future do not disable anything and return.
    if (startDate.isSame(selectedDate, 'day') && timeZone) {
      return this.getDisabledMinutesArray(
        selectedHour,
        dateFormatted,
        timeFormatted
      )
    }
    return []
  }

  validateInputsOnSubmit = () => {
    const {
      selectedCampaign,
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
      testEndDate,
      testEndTime,
      timeZone,
    } = this.state
    const errorMessages = {
      selectedCampaign: '',
      campaignStartDate: '',
      campaignStartTime: '',
      campaignEndDate: '',
      campaignEndTime: '',
      testEndDate: '',
      testEndTime: '',
    }

    let isFormValid = true

    if (!selectedCampaign || selectedCampaign.length === 0) {
      isFormValid = false
      errorMessages.selectedCampaign = 'This field is required'
    }
    if (!campaignStartDate || !campaignStartTime) {
      isFormValid = false
      errorMessages.campaignStartDate =
        'Experiment start date and time fields are required'
    }
    if (!campaignEndDate || !campaignEndTime) {
      isFormValid = false
      errorMessages.campaignEndDate =
        'Experiment end date and time fields are required'
    }
    if (!testEndDate || !testEndTime) {
      isFormValid = false
      errorMessages.testEndDate =
        'Experiment test end date and time fields are required'
    }
    if (
      campaignStartDate &&
      campaignStartTime &&
      campaignEndDate &&
      campaignEndTime &&
      testEndDate &&
      testEndTime
    ) {
      const startDateTime = moment(campaignStartDate).set({
        hours: campaignStartTime.hours(),
        minutes: campaignStartTime.minutes(),
        second: 0,
      })
      const testDateTime = moment(testEndDate).set({
        hours: testEndTime.hours(),
        minutes: testEndTime.minutes(),
        second: 0,
      })
      const endDateTime = moment(campaignEndDate).set({
        hours: campaignEndTime.hours(),
        minutes: campaignEndTime.minutes(),
        second: 0,
      })
      if (
        timeZone &&
        startDateTime.isSame(moment().tz(timeZone), 'day') &&
        (this.getDisabledHours().includes(startDateTime.hours()) ||
          this.disableDate(campaignStartDate, false))
      ) {
        errorMessages.campaignStartDate = 'This time is not available'
        isFormValid = false
      }
      if (this.disableDate(testDateTime, true)) {
        errorMessages.testEndDate = 'This date is not available'
        isFormValid = false
      }
      if (startDateTime.add(60, 'minutes').format() > testDateTime.format()) {
        errorMessages.testEndDate =
          'Test time should be at least 1 hour greather than start time'
        isFormValid = false
      }
      if (testDateTime.format() > endDateTime.format()) {
        errorMessages.campaignEndDate =
          'Experiment end time cannot be less than test time'
        isFormValid = false
      }
    }

    this.setState({ errorMessages })
    return isFormValid
  }

  handleSubmit = async () => {
    if (!this.validateInputsOnSubmit()) {
      return
    }
    const {
      selectedCampaign,
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
      testEndDate,
      testEndTime,
    } = this.state

    const { campaignId, closeDrawer, updateBrazeCampaign } = this.props
    const campaignStartDateFormatted = moment(campaignStartDate)
      .set({
        hour: campaignStartTime?.hour(),
        minute: campaignStartTime?.minutes(),
        second: Number('00'),
      })
      .utc()
      .format()
    const campaignEndDateFormatted = moment(campaignEndDate)
      .set({
        hour: campaignEndTime?.hour(),
        minute: campaignEndTime?.minutes(),
        second: Number('00'),
      })
      .utc()
      .format()
    const testEndDateFormatted = moment(testEndDate)
      .set({
        hour: testEndTime?.hour(),
        minute: testEndTime?.minutes(),
        second: Number('00'),
      })
      .utc()
      .format()
    const payloadData = {
      braze_campaign_id: selectedCampaign,
      campaign_start_time: campaignStartDateFormatted,
      campaign_end_time: campaignEndDateFormatted,
      optimization_start_time: campaignStartDateFormatted,
      optimization_end_time: testEndDateFormatted,
    }

    updateBrazeCampaign(payloadData, campaignId, closeDrawer)
  }

  render() {
    const {
      selectedCampaign,
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
      testEndDate,
      testEndTime,
      isMultiSelect,
      errorMessages,
      timeZone,
    } = this.state

    const {
      brazeCampaigns,
      isLoadingCampaigns,
      maxMultiselectAmount,
      isUpdatingMessages,
      campaignData,
      actions,
      campaignId,
      flags,
    } = this.props

    return (
      <>
        <Drawer.Content>
          <Form colon={false}>
            <Spinner isSpinning={isLoadingCampaigns || !timeZone}>
              <FormItem
                label="Braze campaign"
                help={errorMessages.selectedCampaign || undefined}
                className={
                  errorMessages.selectedCampaign ? 'error required' : 'required'
                }
              >
                {isMultiSelect ? (
                  <MultiSelect
                    maxOptions={maxMultiselectAmount}
                    data-testid="SelectMatch"
                    placeholder="Select a campaign"
                    name="campaigns"
                    className={styles.integrationSelect}
                    onApply={(val) =>
                      val?.length > 0 &&
                      this.onChangeUpdateState(val, 'campaign')
                    }
                    selectedItems={
                      selectedCampaign
                        ? Array.isArray(selectedCampaign)
                          ? selectedCampaign
                          : [selectedCampaign]
                        : []
                    }
                    items={brazeCampaigns?.map((campaign) => ({
                      label: campaign.braze_campaign_name,
                      value: campaign.braze_campaign_name,
                      id: campaign.braze_campaign_id,
                    }))}
                    menuPortalTarget={document.body}
                  />
                ) : (
                  <SingleSelect
                    isClearable
                    isSearchable
                    data-testid="SelectMatch"
                    placeholder="Select a campaign"
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    className={styles.integrationSelect}
                    onChange={(val) =>
                      this.onChangeUpdateState(val?.value, 'campaign')
                    }
                    value={
                      Array.isArray(selectedCampaign)
                        ? selectedCampaign[0]
                        : selectedCampaign
                    }
                    options={brazeCampaigns?.map((campaign) => ({
                      label: campaign.braze_campaign_name,
                      value: campaign.braze_campaign_id,
                    }))}
                  />
                )}
                <GuidingText text={TOOLTIP_TEXT.dropdown} className="mb-7" />
              </FormItem>
              {timeZone && (
                <p
                  className="text-gray-700 font-medium"
                  data-test-id="timeZone"
                >
                  Braze Account Timezone - {timeZone}
                </p>
              )}
              <p className="text-gray-400 mt-1 mb-8">
                Input your experiment time details below based on your Braze
                Account Timezone shown.
              </p>
              <FormItem
                label="Experiment start time"
                className={
                  errorMessages.campaignStartDate ||
                  errorMessages.campaignStartTime
                    ? 'error required'
                    : 'required'
                }
                help={
                  errorMessages.campaignStartDate ||
                  errorMessages.campaignStartTime ||
                  undefined
                }
              >
                <div className="flex gap-4">
                  <Tooltip
                    overlay={TOOLTIP_TEXT.startTime}
                    trigger="focus"
                    placement="left"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <DatePickerAntd
                      disabled={
                        !timeZone ||
                        !selectedCampaign ||
                        !selectedCampaign.length
                      }
                      disabledDate={(current: Moment | null): boolean =>
                        this.disableDate(current, false)
                      }
                      data-test-id="startDatePicker"
                      ariaLabel="startDatePicker"
                      value={campaignStartDate || undefined}
                      allowClear={false}
                      onChange={(date: Moment | null) => {
                        if (date) {
                          this.onChangeUpdateState(
                            moment(date, 'DD-MM-YYYY'),
                            'campaign_start_date'
                          )
                        }
                      }}
                    />
                  </Tooltip>
                  <Tooltip overlay="Time" placement="right">
                    <TimePicker
                      disabled={
                        !timeZone ||
                        !selectedCampaign ||
                        !selectedCampaign.length ||
                        !campaignStartDate
                      }
                      disabledHours={() => this.getDisabledHours()}
                      allowClear={false}
                      disabledMinutes={(selectedHour) => {
                        return this.getDisabledMinutes(selectedHour)
                      }}
                      data-test-id="startTimePicker"
                      className={styles.integrationTimePicker}
                      format="HH:mm"
                      suffixIcon={<TimeIcon size={6} />}
                      minuteStep={15}
                      value={campaignStartTime || undefined}
                      onChange={(val: Moment) =>
                        val
                          ? this.onChangeUpdateState(
                              moment(val, 'HH:mm'),
                              'campaign_start_time'
                            )
                          : undefined
                      }
                    />
                  </Tooltip>
                </div>
              </FormItem>
              <FormItem
                label="Test end time"
                className={
                  errorMessages.testEndDate || errorMessages.testEndTime
                    ? 'error required'
                    : 'required'
                }
                help={
                  errorMessages.testEndDate ||
                  errorMessages.testEndTime ||
                  undefined
                }
              >
                <div className="flex gap-4">
                  <Tooltip
                    overlay={TOOLTIP_TEXT.testEndTime}
                    trigger="focus"
                    placement="left"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <DatePickerAntd
                      disabled={
                        !timeZone ||
                        !selectedCampaign ||
                        !selectedCampaign.length ||
                        !campaignStartDate ||
                        !campaignStartTime
                      }
                      disabledDate={(current: Moment | null): boolean =>
                        this.disableDate(current, true)
                      }
                      allowClear={false}
                      data-test-id="testDatePicker"
                      value={testEndDate || undefined}
                      onChange={(date: Moment | null) => {
                        if (date) {
                          this.onChangeUpdateState(
                            moment(date, 'DD-MM-YYYY'),
                            'test_end_date'
                          )
                        }
                      }}
                    />
                  </Tooltip>
                  <Tooltip overlay="Time" placement="right">
                    <TimePicker
                      disabled={
                        !timeZone ||
                        !selectedCampaign ||
                        !selectedCampaign.length ||
                        !campaignStartDate ||
                        !campaignStartTime ||
                        !testEndDate
                      }
                      allowClear={false}
                      disabledHours={() => this.getTestTimeDisabledHours()}
                      disabledMinutes={(selectedHour) => {
                        return this.getTestTimeDisabledMinutes(selectedHour)
                      }}
                      data-test-id="testTimePicker"
                      className={styles.integrationTimePicker}
                      format="HH:mm"
                      suffixIcon={<TimeIcon size={6} />}
                      minuteStep={15}
                      value={testEndTime || undefined}
                      onChange={(val: Moment) =>
                        this.onChangeUpdateState(
                          val === undefined ? undefined : moment(val, 'HH:mm'),
                          'test_end_time'
                        )
                      }
                    />
                  </Tooltip>
                </div>
              </FormItem>
              <FormItem
                label="Experiment end time"
                className={
                  errorMessages.campaignEndDate || errorMessages.campaignEndTime
                    ? 'error required'
                    : 'required'
                }
                help={
                  errorMessages.campaignEndDate ||
                  errorMessages.campaignEndTime ||
                  undefined
                }
              >
                <div className="flex gap-4">
                  <Tooltip
                    overlay={TOOLTIP_TEXT.campaignEndTime}
                    trigger="focus"
                    placement="left"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <DatePickerAntd
                      disabled={
                        !timeZone ||
                        !selectedCampaign ||
                        !selectedCampaign.length ||
                        !campaignStartDate ||
                        !campaignStartTime ||
                        !testEndDate ||
                        !testEndTime
                      }
                      allowClear={false}
                      disabledDate={(current: Moment | null): boolean =>
                        this.disableDate(current, true)
                      }
                      data-test-id="endDatePicker"
                      ariaLabel="endDatePicker"
                      value={campaignEndDate || undefined}
                      onChange={(date: Moment | null) => {
                        if (date) {
                          this.onChangeUpdateState(
                            moment(date, 'DD-MM-YYYY'),
                            'campaign_end_date'
                          )
                        }
                      }}
                    />
                  </Tooltip>
                  <Tooltip overlay="Time" placement="right">
                    <TimePicker
                      disabled={
                        !timeZone ||
                        !selectedCampaign ||
                        !selectedCampaign.length ||
                        !campaignStartDate ||
                        !campaignStartTime ||
                        !testEndDate ||
                        !testEndTime ||
                        !campaignEndDate
                      }
                      allowClear={false}
                      data-test-id="endTimePicker"
                      disabledHours={() => this.getCampaignEndDisabledHours()}
                      disabledMinutes={(selectedHour) => {
                        return this.getCampaignEndDisabledMinutes(selectedHour)
                      }}
                      className={styles.integrationTimePicker}
                      format="HH:mm"
                      suffixIcon={<TimeIcon size={6} />}
                      minuteStep={15}
                      value={campaignEndTime || undefined}
                      onChange={(val: Moment) =>
                        this.onChangeUpdateState(
                          val === undefined ? undefined : moment(val, 'HH:mm'),
                          'campaign_end_time'
                        )
                      }
                    />
                  </Tooltip>
                </div>
              </FormItem>
              {flags.showBrazeCancelBtn &&
                campaignData?.campaign_data?.braze_campaign_id &&
                campaignData?.campaign_data?.campaign_end_time &&
                campaignData?.campaign_data?.campaign_start_time &&
                campaignData?.campaign_data?.optimization_end_time &&
                campaignData?.campaign_data?.optimization_start_time && (
                  <CancelCard
                    onCancel={() =>
                      actions.cancelBrazeCampaign(campaignId, () => {
                        this.setState(initialState)
                      })
                    }
                  />
                )}
            </Spinner>
          </Form>
        </Drawer.Content>
        <Drawer.Footer>
          <Tooltip
            overlay={TOOLTIP_TEXT.buttonDefault}
            trigger="hover"
            placement="right"
            overlayStyle={{ maxWidth: 226 }}
          >
            <Button
              data-cy="braze-save-button"
              variant="primary"
              disabled={isLoadingCampaigns}
              loading={isUpdatingMessages}
              onClick={() => this.handleSubmit()}
              data-test-id="submit"
            >
              Save
            </Button>
          </Tooltip>
        </Drawer.Footer>
      </>
    )
  }
}

export function mapDispatchToProps(dispatch: AsyncThunkDispatch) {
  return {
    getCampaignData: async (campaignId: string) => {
      await dispatch(fetchCampaignData(campaignId))
    },
    updateBrazeCampaign: async (
      data: any,
      campaignId: string,
      callback: () => void
    ) => {
      await dispatch(syncBrazeCampaign(data, campaignId))
      callback()
    },
    actions: bindActionCreators(
      {
        fetchBrazeCampaigns,
        fetchTimeZone,
        cancelBrazeCampaign,
      },
      dispatch
    ),
  }
}

export function mapStateToProps(state: any, ownsProps: any) {
  const {
    currentCampaignId,
    projectId,
    brazeCampaigns,
    campaignData: {
      campaign_configuration: {
        distribution_channel,
        integration_options: { max_multiselect_amount },
      },
    },
    isWaitingState: { isWaitingFor, isWaiting },
    campaignData,
    timeZone,
  } = state.campaignStates

  return {
    campaignId: currentCampaignId,
    projectId,
    brazeCampaigns,
    isLoadingCampaigns: isWaitingFor === 'fetchingBrazeCampaigns' && isWaiting,
    maxMultiselectAmount: max_multiselect_amount,
    timeZone,
    distributionChannel: distribution_channel,
    campaignData,
    closeDrawer: ownsProps.closeDrawer,
    flags: state.flagStates.flags,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrazeIntegration)
