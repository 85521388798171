import UnscheduleCampaignCard from '../../components/card/UnscheduleCampaignCard'

interface Props {
  cancelCampaign: () => void
}

export default function CancelCard({ cancelCampaign }: Props) {
  return (
    <UnscheduleCampaignCard
      dataTestId="cancel-campaign"
      onSubmit={() => cancelCampaign()}
      cardTitle="Cancel experiment schedule"
      cardText={`This will stop importing data from Responsys and 
        cancel this experiment schedule. This will not delete any data 
        you have already received. `}
      buttonText="Cancel"
    />
  )
}
