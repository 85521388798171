import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { deleteCustomerAttributes } from '../api'
import { keys } from '../queryKeys'

const useDeleteCustomerAttributesMutation = (accountId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteCustomerAttributes,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: keys.customerAttributes(accountId),
      })
    },
    onError: () => {
      errorToast('The customer attributes could not be deleted')
    },
  })
}

export default useDeleteCustomerAttributesMutation
