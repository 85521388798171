import { createSlice } from '@reduxjs/toolkit'

import { connectInternalApi } from 'common/api'
import { SelectOption } from 'features/campaigns/integration-options/components/select/SelectComponent'

interface SchedulePayload {
  test_start_time: string
  test_end_time: string
  primary_goal: string
}

interface CommonResponse {
  config: any
  data: any
  headers: any
  request: any
  status: number
}

interface Project {
  optimizely_project_id: number
  optimizely_project_name: string
}

interface Experiment {
  optimizely_experiment_id: number
  optimizely_experiment_name: string
}

interface OptimizelyState {
  projectsList: SelectOption[] | undefined
  experimentsList: SelectOption[] | undefined
  timeZone: string | undefined
  isLoading: {
    projects: boolean
    experiments: boolean
    variants: boolean
    scheduler: boolean
    timezone: boolean
  }
  error: string
}

interface CreateOffersPayload {
  optimizely_experiment_id: number | undefined
  optimizely_project_id: number | undefined
}

export const initialState: OptimizelyState = {
  projectsList: undefined,
  experimentsList: undefined,
  timeZone: undefined,
  isLoading: {
    projects: false,
    experiments: false,
    variants: false,
    scheduler: false,
    timezone: false,
  },
  error: '',
}

export const optimizelySlice = createSlice({
  name: 'optimizely',
  initialState,
  reducers: {
    updateProjects: (state, action) => {
      state.projectsList = action.payload.map((project: Project) => ({
        id: project.optimizely_project_id,
        name: project.optimizely_project_name,
      }))
    },
    updateExperiments: (state, action) => {
      state.experimentsList = action.payload.map((experiment: Experiment) => ({
        id: experiment.optimizely_experiment_id,
        name: experiment.optimizely_experiment_name,
      }))
    },
    setLoading: (state, action) => {
      state.isLoading[action.payload.field] = action.payload.value
    },
    setTimezone: (state, action) => {
      state.timeZone = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
})

export const {
  updateProjects,
  updateExperiments,
  setLoading,
  setTimezone,
  setError,
} = optimizelySlice.actions

export async function fetchProjects(projectId: string) {
  return connectInternalApi.get<CommonResponse>(
    `v1/integrations/optimizely/projects/${projectId}/optimizely-projects`
  )
}

export async function fetchExperiments(
  projectId: string,
  optimizelyProjectId: number
) {
  return connectInternalApi.get<CommonResponse>(
    `v1/integrations/optimizely/projects/${projectId}/optimizely-projects/${optimizelyProjectId}/experiments`
  )
}

export async function createVariants(
  projectId: string,
  campaignId: string,
  data: CreateOffersPayload
) {
  return connectInternalApi.post<CommonResponse>(
    `v1/integrations/optimizely/projects/${projectId}/campaigns/${campaignId}/create-splits`,
    data
  )
}

export async function unlinkCampaign(projectId: string, campaignId: string) {
  return connectInternalApi.delete<CommonResponse>(
    `v1/integrations/optimizely/projects/${projectId}/campaigns/${campaignId}/unlink`
  )
}

export async function fetchTimezone(projectId: string, campaignId: string) {
  return connectInternalApi.get<string>(
    `v1/integrations/optimizely/projects/${projectId}/campaigns/${campaignId}/timezone`
  )
}

export function scheduleCampaign(
  projectId: string,
  campaignId: string,
  data: SchedulePayload
) {
  return connectInternalApi.post<CommonResponse>(
    `v1/integrations/optimizely/projects/${projectId}/campaigns/${campaignId}/schedule`,
    data
  )
}

export function unscheduleCampaign(projectId: string, campaignId: string) {
  return connectInternalApi.delete<CommonResponse>(
    `v1/integrations/optimizely/projects/${projectId}/campaigns/${campaignId}/schedule`
  )
}

export default optimizelySlice.reducer
