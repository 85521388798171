import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { regionsKeys } from '../queryKeys/regionsKeys'
import { fetchRegionsList } from '../regionsApi'

const useRegionsListQuery = () => {
  return useQuery(
    regionsKeys.regionsList(),
    ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      const promise = fetchRegionsList()

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      return promise
    },
    { refetchOnWindowFocus: false, refetchOnReconnect: false }
  )
}

export default useRegionsListQuery
