/* eslint-disable max-lines */
import {
  Emoji,
  LanguageSettings,
  WordReplacementPattern,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { RootState } from 'redux/store'

import { SelectValue } from 'common/components/singleSelect'
import { errorToast } from 'common/components/toastNotification'
import { removeMaybe, removeMaybeAsList } from 'common/helpers/typeUtils'
import { changeAccountId } from 'features/auth/store/authSlice'

import {
  AccountResponse,
  getAccountConfig,
  getAccountLanguageSettings,
  getAllIndustries,
  StyleGuideResponse,
  updateAccount,
  updateBrandVoice,
  updateStyleGuide,
} from '../api'

export const DEFAULT_PUNCTUATION = ['?', ',', '.']
const LEAD_PUNCTUATION = ['.', ',', '!', '|', ':', '…', '-', '–', '—']
const MID_PUNCTUATION = ['.', ',', '!', '|', '…', '-', '–', '—']
const END_PUNCTUATION = ['.', '!', '|', '…']

type Punctuation = {
  marks: string[]
  dash: string
  hasCurlyApostrophes: boolean
  hasSpaceBeforeEllipsis: boolean
  hasSpaceAfterEllipsis: boolean
  isCapitalizingAfterColon: boolean
  isCapitalizingAfterDash: boolean
  isCapitalizingAfterEllipsis: boolean
}

type AlternativeWord = {
  id: number
  originalWord: string
  partOfSpeech: { label: string; value: string }[]
  replacements: string[]
}

type Vocabulary = {
  forbiddenWords: string[]
}

type Stylistics = {
  options: string[]
  wrapLeaders: boolean
  wrapVariants: boolean
  wrappingProbability: number
}

type EmojiSettings = {
  probability: number
  isUsingBeginEmoji: boolean
  isUsingEndEmoji: boolean
  isUsingDoubleEmoji: boolean
}

type Content = {
  brandVoiceName: string | undefined
  punctuation: Punctuation
  alternativeWords: (AlternativeWord & { hasError: boolean })[]
  vocabulary: Vocabulary
  capitalization: {
    isTitleCase: boolean
    uppercaseProbability: number
    canUppercaseVariants: boolean
    uppercaseWords: string[]
  }
  stylistics: Stylistics
  emojiSettings: EmojiSettings
  emojis: Emoji[]
  industry: string
}
interface BrandVoice {
  id: string
  name: string
  regionId: string
  languageSetttings: Content
  created: string
  isDefault: boolean
  lastUpdated: string
  brandTones: {
    description: string
    examples: string[]
    instruction: string
    name: string
    id: string
  }
}

export interface State {
  isLoading: boolean
  hasError: boolean
  isSaving: boolean
  rawContent: StyleGuideResponse | undefined
  rawAccountLanguageSettings: LanguageSettings | undefined
  originalBrandVoices: BrandVoice[]
  editedContent: Content
  brandVoices: BrandVoice[]
  selectedBrandVoiceId: string | undefined
  editedBrandVoiceName: string | undefined
  regionId: string | undefined
  isBrandTonePageVisible: boolean
  isEmojiInheritanceDisabled: boolean
  isBrandIndustryVisible: boolean
  industries: SelectValue[]
}

export const initialState: State = {
  isLoading: true,
  hasError: false,
  isSaving: false,
  selectedBrandVoiceId: undefined,
  editedBrandVoiceName: undefined,
  regionId: undefined,
  isBrandTonePageVisible: false,
  isEmojiInheritanceDisabled: false,
  rawContent: undefined,
  rawAccountLanguageSettings: undefined,
  editedContent: {
    brandVoiceName: undefined,
    punctuation: {
      marks: [],
      dash: '',
      hasCurlyApostrophes: false,
      hasSpaceAfterEllipsis: false,
      hasSpaceBeforeEllipsis: false,
      isCapitalizingAfterColon: false,
      isCapitalizingAfterDash: false,
      isCapitalizingAfterEllipsis: false,
    },
    alternativeWords: [],
    vocabulary: {
      forbiddenWords: [],
    },
    capitalization: {
      isTitleCase: false,
      uppercaseProbability: 0.3 * 100,
      canUppercaseVariants: false,
      uppercaseWords: [],
    },
    stylistics: {
      options: [],
      wrapLeaders: true,
      wrapVariants: true,
      wrappingProbability: 0.3 * 100,
    },
    emojiSettings: {
      probability: 0,
      isUsingBeginEmoji: true,
      isUsingEndEmoji: true,
      isUsingDoubleEmoji: false,
    },
    emojis: [],
    industry: '',
  },
  brandVoices: [],
  originalBrandVoices: [],
  isBrandIndustryVisible: false,
  industries: [],
}

const getBrandVoiceNameById = (
  brandVoices: BrandVoice[],
  brandVoiceId: string | undefined
): string | undefined => {
  if (!brandVoiceId || brandVoices.length === 0) {
    return
  }
  return brandVoices.find((brandVoice) => brandVoice.id === brandVoiceId)?.name
}

const isAlternativeWordValid = (alternativeWord: AlternativeWord) => {
  return (
    alternativeWord.originalWord.length > 0 &&
    alternativeWord.partOfSpeech.length > 0
  )
}

const getDuplicatedAlternativeWords = (alternativeWords: AlternativeWord[]) => {
  const concatPartOfSpeech = (current: AlternativeWord) =>
    current.partOfSpeech.map((word) => word.value).join('-')

  const originalWordsWithPartOfSpeech = alternativeWords.reduce(
    (accumulator, current) => {
      return {
        ...accumulator,
        [`${current.originalWord}-${concatPartOfSpeech(current)}`]:
          (accumulator[
            `${current.originalWord}-${concatPartOfSpeech(current)}`
          ] ?? 0) + 1,
      }
    },
    {}
  )

  const isDuplicateWord = (word: AlternativeWord) => {
    return (
      originalWordsWithPartOfSpeech[
        `${word.originalWord}-${concatPartOfSpeech(word)}`
      ] > 1
    )
  }

  const duplicateAlternativeWords = alternativeWords.filter((word) =>
    isDuplicateWord(word)
  )

  return duplicateAlternativeWords
}

export const initializePage = createAsyncThunk<
  { accountConfig: any; languageSettings: StyleGuideResponse } & {
    isBrandTonePageVisible: boolean
    isEmojiInheritanceDisabled: boolean
    selectedBrandVoiceId: string | undefined
    isBrandIndustryVisible: boolean
    industries: SelectValue[]
  },
  {
    isEmojiInheritanceDisabled: boolean
    isBrandTonePageVisible: boolean
    selectedBrandVoiceId: string | undefined
    isBrandIndustryVisible: boolean
  },
  { state: RootState }
>(
  'admin/styleGuide/initializePage',
  async (
    {
      isBrandTonePageVisible,
      isEmojiInheritanceDisabled,
      selectedBrandVoiceId,
      isBrandIndustryVisible,
    },
    { getState }
  ) => {
    const state = getState()
    const { accountId } = state.authStates

    const accountConfig = await getAccountConfig(accountId)
    const languageSettings = await getAccountLanguageSettings(
      accountId,
      selectedBrandVoiceId
    )
    const industries = (await getAllIndustries()).map((industry) => ({
      label: industry.name,
      value: industry.name,
    }))

    return {
      accountConfig,
      languageSettings,
      isEmojiInheritanceDisabled,
      isBrandTonePageVisible,
      selectedBrandVoiceId,
      isBrandIndustryVisible,
      industries,
    }
  }
)

export const saveRegion = createAsyncThunk<
  any,
  { accountId: string; regionId: string; isEmojiInheritanceDisabled: boolean },
  { state: RootState }
>(
  'admin/styleGuide/saveRegion',
  async (
    { accountId, regionId, isEmojiInheritanceDisabled },
    { getState, dispatch }
  ) => {
    const state = getState()
    const {
      isBrandTonePageVisible,
      selectedBrandVoiceId,
      isBrandIndustryVisible,
    } = state.styleGuide
    if (isBrandTonePageVisible && selectedBrandVoiceId) {
      const selectedBrandVoice = state.styleGuide.brandVoices.find(
        (brandVoice) => brandVoice.id === selectedBrandVoiceId
      )
      const isDefault = selectedBrandVoice?.isDefault ?? false
      const name = selectedBrandVoice?.name
      await updateBrandVoice({
        accountId,
        brandVoiceId: selectedBrandVoiceId,
        data: {
          name,
          regionId,
          isDefault,
        },
      })
      dispatch(
        initializePage({
          isBrandTonePageVisible: isBrandTonePageVisible,
          isEmojiInheritanceDisabled,
          selectedBrandVoiceId,
          isBrandIndustryVisible,
        })
      )
      return { region_id: regionId }
    } else {
      const result = await updateAccount({
        accountId,
        data: {
          region_id: regionId,
          ...(state.styleGuide.rawAccountLanguageSettings && {
            language_settings: state.styleGuide.rawAccountLanguageSettings,
          }),
        },
      })
      dispatch(
        initializePage({
          isBrandTonePageVisible: isBrandTonePageVisible,
          isEmojiInheritanceDisabled,
          selectedBrandVoiceId: undefined,
          isBrandIndustryVisible,
        })
      )
      return result
    }
  }
)

const mapContentToLanguageSettings = (
  editedContent: Content,
  rawContent: StyleGuideResponse | undefined
): LanguageSettings => {
  const punctuation = rawContent?.punctuation || {}
  const capitalization = rawContent?.capitalization || {}
  const vocabulary = rawContent?.vocabulary || {}
  const emojiFrequency = rawContent?.emoji_frequency || {}

  const marksFilter = (reference: string[]) =>
    editedContent.punctuation.marks.filter((value) => reference.includes(value))

  const isAlternativeWordEmptyRow = (alternativeWord: AlternativeWord) =>
    !alternativeWord.originalWord.trim().length &&
    !alternativeWord.replacements.length

  const alternativeWordsWithoutEmptyRows =
    editedContent.alternativeWords.filter(
      (synonym) => !isAlternativeWordEmptyRow(synonym)
    )
  const wrappingProbability = editedContent.stylistics.wrappingProbability / 100
  const stylisticsOptions =
    wrappingProbability > 0 ? editedContent.stylistics.options : []
  const wrapLeaders = wrappingProbability > 0
  const wrapVariants = wrappingProbability > 0

  const data: LanguageSettings = {
    punctuation: {
      ...punctuation,
      space_before_elps: editedContent.punctuation.hasSpaceBeforeEllipsis,
      space_after_elps: editedContent.punctuation.hasSpaceAfterEllipsis,
      punctuation_options: {
        ...punctuation.punctuation_options,
        lead_punctuation:
          editedContent.punctuation.dash === ''
            ? marksFilter(LEAD_PUNCTUATION)
            : marksFilter(LEAD_PUNCTUATION).concat(
                editedContent.punctuation.dash
              ),
        mid_punctuation:
          editedContent.punctuation.dash === ''
            ? marksFilter(MID_PUNCTUATION)
            : marksFilter(MID_PUNCTUATION).concat(
                editedContent.punctuation.dash
              ),
        end_punctuation: marksFilter(END_PUNCTUATION),
        curly_apostrophe: editedContent.punctuation.hasCurlyApostrophes,
      },
      nonfunctional_options: stylisticsOptions.map(addSpaceInTheMiddle),
      wrapping_probability: wrappingProbability,
      wrap_leaders: wrapLeaders,
      wrap_variants: wrapVariants,
    },
    capitalization: {
      ...capitalization,
      title_case: editedContent.capitalization.isTitleCase,
      uppercase_variants: editedContent.capitalization.canUppercaseVariants,
      uppercase_probability:
        editedContent.capitalization.uppercaseProbability / 100,
      uppercase_words:
        editedContent.capitalization.uppercaseProbability > 0
          ? editedContent.capitalization.uppercaseWords
          : [],
      caps_after_colon: editedContent.punctuation.isCapitalizingAfterColon,
      caps_after_dash: editedContent.punctuation.isCapitalizingAfterDash,
      caps_after_elps: editedContent.punctuation.isCapitalizingAfterEllipsis,
    },
    vocabulary: {
      ...vocabulary,
      incompatible_words:
        rawContent?.vocabulary?.incompatible_words?.map(
          //@ts-ignore remove when the FF is removed
          (words) => words.map((word) => (word.value ? word.value : word))
        ) ?? [],
      forbidden_words: editedContent.vocabulary.forbiddenWords,
      word_replacements: [
        ...alternativeWordsWithoutEmptyRows.map((synonym) => ({
          phrase: synonym.originalWord,
          pattern: [
            [
              ...synonym.partOfSpeech.map((pos) => ({
                LOWER: pos.label,
                ...(pos.value === ''
                  ? undefined
                  : { POS: pos.value.toUpperCase() }),
              })),
            ],
          ],
          replacements: synonym.replacements,
        })),
      ],
    },
    emoji_frequency: {
      ...emojiFrequency,
      left_emojis: editedContent.emojiSettings.isUsingBeginEmoji
        ? editedContent.emojiSettings.probability / 100
        : 0,
      right_emojis: editedContent.emojiSettings.isUsingEndEmoji
        ? editedContent.emojiSettings.probability / 100
        : 0,
      double_emojis: editedContent.emojiSettings.isUsingDoubleEmoji,
    },
    emojis_list:
      editedContent?.emojis?.filter((emoji) => emoji?.lookup?.trim() !== '') ??
      [],
    industry: editedContent?.industry || '',
  }
  return data
}

const processInitializePageResponse = (
  styleGuideResponse: StyleGuideResponse,
  isEmojiInheritanceDisabled: boolean,
  selectedBrandVoiceName: string | undefined
): Content => {
  const punctuation = styleGuideResponse?.punctuation
  const punctuationOptions = punctuation?.punctuation_options || {}
  const capitalization = styleGuideResponse?.capitalization

  const punctuationList = DEFAULT_PUNCTUATION.concat(
    removeMaybeAsList(punctuationOptions.lead_punctuation ?? []),
    removeMaybeAsList(punctuationOptions.mid_punctuation ?? []),
    removeMaybeAsList(punctuationOptions.end_punctuation ?? [])
  )

  const marks = punctuationList.filter((value, index) => {
    return (
      punctuationList.indexOf(value) === index &&
      value !== undefined &&
      value !== '-' && //hyphen
      value !== '–' && //en-dash
      value !== '—' //em-dash
    )
  })

  const dashes = punctuationList.filter((element) => {
    return (
      element === '-' || // hyphen
      element === '–' || // en-dash
      element === '—' // em-dash
    )
  })
  const dash = dashes?.[0] ?? ''

  const emojiFrequency = styleGuideResponse?.emoji_frequency

  const beginProbability =
    emojiFrequency?.left_emojis ?? Number.NEGATIVE_INFINITY
  const endProbability =
    emojiFrequency?.right_emojis ?? Number.NEGATIVE_INFINITY

  const emojiProbability =
    beginProbability > 0
      ? beginProbability
      : endProbability >= 0
      ? endProbability
      : 0.3

  const alternativeWords =
    styleGuideResponse?.vocabulary?.word_replacements
      ?.filter(removeMaybe)
      .map((wordReplacement, index) => {
        return {
          originalWord:
            wordReplacement?.phrase ??
            (
              wordReplacement?.pattern?.flat(
                Infinity
              )?.[0] as WordReplacementPattern
            )?.LOWER ??
            '',
          partOfSpeech: removeMaybeAsList(wordReplacement?.pattern ?? [])
            ?.flat(Infinity)
            ?.map((pos: any) => ({
              label: pos.LOWER,
              value: pos.POS?.toLowerCase() ?? '',
            })),
          id: index,
          replacements:
            wordReplacement?.replacements
              ?.filter(removeMaybe)
              ?.filter(String) ?? [],
          hasError: false,
        }
      }) ?? []
  return {
    brandVoiceName: selectedBrandVoiceName,
    punctuation: {
      marks: marks,
      dash: dash,
      hasCurlyApostrophes: punctuationOptions.curly_apostrophe ?? false,
      hasSpaceAfterEllipsis: punctuation?.space_after_elps ?? true,
      hasSpaceBeforeEllipsis: punctuation?.space_before_elps ?? false,
      isCapitalizingAfterColon: capitalization?.caps_after_colon ?? false,
      isCapitalizingAfterDash: capitalization?.caps_after_dash ?? false,
      isCapitalizingAfterEllipsis: capitalization?.caps_after_elps ?? false,
    },
    alternativeWords,
    vocabulary: {
      forbiddenWords:
        (
          removeMaybeAsList(
            styleGuideResponse?.vocabulary?.forbidden_words ?? []
          ) as any
        )
          // @ts-ignore remove when the FF is removed
          ?.filter((word) => {
            return word.value ? word.inherited === false : true
          })
          .map((word) => (word.value ? word.value : word)) ?? [],
    },
    capitalization: {
      isTitleCase: capitalization?.title_case ?? false,
      uppercaseProbability:
        (capitalization?.uppercase_probability ?? 0.3) * 100,
      canUppercaseVariants: capitalization?.uppercase_variants ?? false,
      uppercaseWords: (capitalization?.uppercase_words as string[]) ?? [],
    },
    stylistics: {
      // TODO: update lovejoy
      // @ts-ignore
      options: styleGuideResponse?.punctuation?.nonfunctional_options ?? [
        '** **',
      ],
      wrappingProbability: (punctuation?.wrapping_probability ?? 0.3) * 100,
    },
    emojiSettings: {
      probability: emojiProbability * 100,
      isUsingBeginEmoji:
        beginProbability > 0 || beginProbability === Number.NEGATIVE_INFINITY,
      isUsingEndEmoji:
        endProbability > 0 || beginProbability === Number.NEGATIVE_INFINITY,
      isUsingDoubleEmoji: emojiFrequency?.double_emojis ?? false,
    },
    emojis: isEmojiInheritanceDisabled
      ? styleGuideResponse?.emojis_list?.filter(removeMaybe) ?? []
      : [],
    industry: styleGuideResponse?.industry || '',
  }
}

function addSpaceInTheMiddle(value: string) {
  if (value.length > 1 && value.length % 2 === 0) {
    const middle = Math.floor(value.length / 2)
    return `${value.slice(0, middle)} ${value.slice(middle)}`
  }
  return value
}

export const saveContent = createAsyncThunk<
  {
    languageSettingsResponse: StyleGuideResponse
    brandVoicesResponse?: AccountResponse['brand_voices']
  },
  { accountId: string },
  { state: RootState }
>('admin/styleGuide/save', async ({ accountId }, { getState }) => {
  const state = getState()

  const { editedContent, rawContent, isBrandIndustryVisible } = state.styleGuide

  const isAlternativeWordEmptyRow = (alternativeWord: AlternativeWord) =>
    !alternativeWord.originalWord.trim().length &&
    !alternativeWord.replacements.length

  const alternativeWordsWithoutEmptyRows =
    editedContent.alternativeWords.filter(
      (synonym) => !isAlternativeWordEmptyRow(synonym)
    )
  const wrappingProbability = editedContent.stylistics.wrappingProbability / 100
  const stylisticsOptions =
    wrappingProbability > 0 ? editedContent.stylistics.options : []
  if (
    alternativeWordsWithoutEmptyRows.some(
      (alternativeWord) => !isAlternativeWordValid(alternativeWord)
    )
  ) {
    return Promise.reject(
      'Some alternative words are invalid, please fill the original word and part of speech.'
    )
  }
  if (
    getDuplicatedAlternativeWords(alternativeWordsWithoutEmptyRows).length > 0
  ) {
    return Promise.reject(
      'Some alternative words are duplicated, please make sure that each combination of original word and part of speech is unique.'
    )
  }

  if (wrappingProbability > 0 && stylisticsOptions.length === 0) {
    return Promise.reject(
      'Please select at least one Stylistic option to be used.'
    )
  }

  if (
    editedContent.emojiSettings.probability &&
    !editedContent.emojiSettings.isUsingBeginEmoji &&
    !editedContent.emojiSettings.isUsingEndEmoji
  ) {
    return Promise.reject('Please select at least one Emoji option to be used.')
  }

  // TODO: remove FF code when FF is live and stable
  const selectedIndustry = editedContent.industry || ''
  if (isBrandIndustryVisible && !selectedIndustry) {
    return Promise.reject('Please select an industry.')
  }

  const data: LanguageSettings = mapContentToLanguageSettings(
    editedContent,
    rawContent
  )

  // TODO: remove FF code when FF is live and stable
  if (!isBrandIndustryVisible) {
    delete data.industry
  }

  if (state.styleGuide.isBrandTonePageVisible) {
    if (!state.styleGuide.selectedBrandVoiceId) {
      throw new Error('Brand voice not selected')
    }
    const updatedBrandVoiceName =
      state.styleGuide.editedContent.brandVoiceName?.trim()
    if (!updatedBrandVoiceName) {
      throw new Error(`Brand voice name is not valid`)
    }

    const brandVoice = state.styleGuide.brandVoices.find(
      (brandVoice) => brandVoice.id === state.styleGuide.selectedBrandVoiceId
    )
    if (!brandVoice) {
      throw new Error(
        `Brand voice not found: ${state.styleGuide.selectedBrandVoiceId}`
      )
    }
    const account = await updateBrandVoice({
      accountId,
      brandVoiceId: brandVoice.id,
      data: {
        name: updatedBrandVoiceName,
        regionId: brandVoice.regionId,
        languageSettings: data,
        isDefault: brandVoice.isDefault,
      },
    })
    return {
      languageSettingsResponse: data,
      brandVoicesResponse: removeMaybeAsList(account.brand_voices ?? []) ?? [],
    }
    // TODO return the updated brand voice
  } else {
    await updateStyleGuide({ accountId, data })
  }
  return { languageSettingsResponse: data }
})

export const styleGuideSlice = createSlice({
  name: 'admin/styleGuide',
  initialState,
  reducers: {
    selectRegion: (state, action: PayloadAction<string>) => {
      state.regionId = action.payload
    },
    resetContent: (state) => {
      if (state.rawContent) {
        state.editedContent = processInitializePageResponse(
          state.rawContent,
          state.isEmojiInheritanceDisabled,
          getBrandVoiceNameById(state.brandVoices, state.selectedBrandVoiceId)
        )
      }
    },
    clickSpaceBeforeEllipsis: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.hasSpaceBeforeEllipsis = action.payload
    },
    clickSpaceAfterEllipsis: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.hasSpaceAfterEllipsis = action.payload
    },
    clickCapitablizeAfterColon: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.isCapitalizingAfterColon = action.payload
    },
    clickCapitablizeAfterDash: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.isCapitalizingAfterDash = action.payload
    },
    clickCapitablizeAfterEllipsis: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.isCapitalizingAfterEllipsis =
        action.payload
    },
    clickCurlyApostrophes: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.hasCurlyApostrophes = action.payload
    },
    clickAddPunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.marks.push(action.payload)
    },
    clickRemovePunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.marks =
        state.editedContent.punctuation.marks.filter(
          (punctuation) => punctuation !== action.payload
        )
    },
    clickChangeDashStyle: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.dash = action.payload
    },
    addAlternativeWordsRow: (state) => {
      state.editedContent.alternativeWords = [
        {
          originalWord: '',
          partOfSpeech: [],
          replacements: [],
          id: 0,
          hasError: false,
        },
        ...state.editedContent.alternativeWords.map((synonym) => ({
          ...synonym,
          id: synonym.id + 1,
        })),
      ]
    },
    updateAlternativeWordsCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const synonym = state.editedContent.alternativeWords[rowIndex]
      synonym[columnId] = value
      synonym.hasError = synonym.hasError
        ? !isAlternativeWordValid(synonym)
        : false
    },
    deleteAlternativeWordsRow: (state, action: PayloadAction<number>) => {
      state.editedContent.alternativeWords =
        state.editedContent.alternativeWords
          .filter((_, index) => index !== action.payload)
          .map((synonym, index) => ({ ...synonym, id: index }))
    },
    deactivateAlternativeWordsRows: (
      state,
      action: PayloadAction<number[]>
    ) => {
      const isRowDeactivated = (rowIndex: number) =>
        action.payload.includes(rowIndex)

      state.editedContent.alternativeWords =
        state.editedContent.alternativeWords.map((synonym) =>
          isRowDeactivated(synonym.id)
            ? {
                ...synonym,
                replacements: [],
              }
            : synonym
        )
    },

    clickAddForbiddenWord: (state, action: PayloadAction<string>) => {
      const value = action.payload
      state.editedContent.vocabulary.forbiddenWords.push(value)
    },
    clickTitleCase: (state) => {
      state.editedContent.capitalization.isTitleCase = true
    },
    clickRemoveForbiddenWord: (state, action: PayloadAction<string>) => {
      state.editedContent.vocabulary.forbiddenWords =
        state.editedContent.vocabulary.forbiddenWords.filter(
          (word) => word !== action.payload
        )
    },
    clickSentenceCase: (state) => {
      state.editedContent.capitalization.isTitleCase = false
    },
    updateUppercaseProbability: (state, action: PayloadAction<number>) => {
      state.editedContent.capitalization.uppercaseProbability = action.payload
    },
    tickUpperCaseVariants: (state, action: PayloadAction<boolean>) => {
      state.editedContent.capitalization.canUppercaseVariants = action.payload
    },
    addUppercaseWord: (state, action: PayloadAction<string>) => {
      state.editedContent.capitalization.uppercaseWords.push(action.payload)
    },
    removeUppercaseWord: (state, action: PayloadAction<string>) => {
      state.editedContent.capitalization.uppercaseWords =
        state.editedContent.capitalization.uppercaseWords.filter(
          (word) => word !== action.payload
        )
    },
    updateStylisticsWrappingProbability: (
      state,
      action: PayloadAction<number>
    ) => {
      state.editedContent.stylistics.wrappingProbability = action.payload
    },
    updateStylisticsOptions: (
      state,
      action: PayloadAction<{ isChecked: boolean; value: string }>
    ) => {
      let stylisticsOptions = state?.editedContent?.stylistics?.options ?? []
      const { isChecked, value } = action.payload
      if (isChecked && !stylisticsOptions.includes(value)) {
        stylisticsOptions.push(value)
      }
      if (!isChecked) {
        stylisticsOptions = stylisticsOptions.filter(
          (option) => option !== value
        )
      }
      state.editedContent.stylistics.options = stylisticsOptions
    },

    updateEmojiProbability: (state, action: PayloadAction<number>) => {
      const probability = action.payload
      const emojiSettings = state.editedContent.emojiSettings

      state.editedContent.emojiSettings.probability = probability

      if (probability === 0) {
        emojiSettings.isUsingBeginEmoji = false
        emojiSettings.isUsingEndEmoji = false
        emojiSettings.isUsingDoubleEmoji = false
      }
    },
    clickIsUsingBeginEmoji: (state, action: PayloadAction<boolean>) => {
      state.editedContent.emojiSettings.isUsingBeginEmoji = action.payload
    },
    clickIsUsingEndEmoji: (state, action: PayloadAction<boolean>) => {
      state.editedContent.emojiSettings.isUsingEndEmoji = action.payload
    },
    clickIsUsingDoubleEmoji: (state, action: PayloadAction<boolean>) => {
      const emojiSettings = state.editedContent.emojiSettings
      const isUsingDoubleEmoji = action.payload

      emojiSettings.isUsingDoubleEmoji = isUsingDoubleEmoji
      if (isUsingDoubleEmoji === true) {
        emojiSettings.isUsingBeginEmoji = true
        emojiSettings.isUsingEndEmoji = true
      }
    },
    selectBrandVoice: (state, action: PayloadAction<string>) => {
      state.selectedBrandVoiceId = action.payload
      const brandVoice = state.brandVoices.find(
        (brandVoice) => brandVoice.id === action.payload
      )
      if (brandVoice) {
        state.editedContent = brandVoice.languageSetttings
        state.regionId = brandVoice.regionId
      }
    },
    addEmojiRow: (state) => {
      state.editedContent.emojis = [
        {
          lookup: '',
          values: [],
        },
        ...state.editedContent.emojis,
      ]
    },
    updateEmojiCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const emoji = state.editedContent.emojis[rowIndex]
      emoji[columnId] = value
    },
    deactivateEmojis: (state, action: PayloadAction<Emoji[]>) => {
      const selectedEmojis = action.payload
      state.editedContent.emojis = state.editedContent.emojis.map((emoji) => {
        const isSelectedEmoji = selectedEmojis.some((selectedEmoji) => {
          return selectedEmoji.lookup === emoji.lookup
        })
        if (!isSelectedEmoji) {
          return { ...emoji }
        }

        return {
          ...emoji,
          values: [],
        }
      })
    },
    changeDefaultRegion: (state, action: PayloadAction<boolean>) => {
      const selectedBrandVoice = state.brandVoices.find(
        (brandVoice) => brandVoice.id === state.selectedBrandVoiceId
      )
      if (selectedBrandVoice) {
        selectedBrandVoice.isDefault = action.payload
      }
    },
    updateIndustry: (state, action: PayloadAction<string>) => {
      state.editedContent.industry = action.payload
    },
    updateBrandVoiceName: (state, action: PayloadAction<string>) => {
      state.editedContent.brandVoiceName = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializePage.pending, (state) => {
        state.hasError = false
        state.isLoading = true
      })
      .addCase(initializePage.fulfilled, (state, action) => {
        state.isLoading = false
        state.isEmojiInheritanceDisabled =
          action.payload.isEmojiInheritanceDisabled
        state.isBrandTonePageVisible = action.payload.isBrandTonePageVisible
        state.isBrandIndustryVisible = action.payload.isBrandIndustryVisible
        state.selectedBrandVoiceId = action.payload.selectedBrandVoiceId
        state.brandVoices =
          action.payload.accountConfig.brand_voices?.map((brandVoice) => ({
            id: brandVoice.id,
            name: brandVoice.name,
            regionId: brandVoice.region_id.toLowerCase(),
            languageSetttings: processInitializePageResponse(
              brandVoice.language_setttings,
              state.isEmojiInheritanceDisabled,
              brandVoice.name
            ),
            created: brandVoice.created,
            isDefault: brandVoice.default,
            lastUpdated: brandVoice.last_updated,
            brandTones: brandVoice.brand_tones,
          })) ?? []
        state.originalBrandVoices = [...state.brandVoices]
        state.rawContent = action.payload.languageSettings
        state.editedContent = processInitializePageResponse(
          action.payload.languageSettings,
          state.isEmojiInheritanceDisabled,
          getBrandVoiceNameById(state.brandVoices, state.selectedBrandVoiceId)
        )
        state.industries = action.payload?.industries || []
        if (!action.payload.isBrandTonePageVisible) {
          state.rawAccountLanguageSettings =
            action.payload.accountConfig.language_settings
          state.regionId = action.payload.accountConfig.region_id?.toLowerCase()
        } else {
          const brandVoice = state.brandVoices.find(
            (brandVoice) =>
              brandVoice.id === action.payload.selectedBrandVoiceId
          )
          if (brandVoice) {
            state.regionId = brandVoice.regionId.toLowerCase()
            state.editedBrandVoiceName = brandVoice.name
          }
        }
      })
      .addCase(initializePage.rejected, (state) => {
        state.isLoading = false
        state.hasError = true
        errorToast('Failed to load Style Guide')
      })
    builder
      .addCase(saveRegion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(saveRegion.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(saveContent.pending, (state) => {
        state.isSaving = true
      })
      .addCase(saveContent.fulfilled, (state, action) => {
        state.isSaving = false
        if (action.payload.brandVoicesResponse) {
          state.brandVoices =
            (action.payload.brandVoicesResponse as any)?.map((brandVoice) => {
              return {
                id: brandVoice.id,
                name: brandVoice.name,
                regionId: brandVoice.region_id.toLowerCase(),
                languageSetttings: processInitializePageResponse(
                  brandVoice.language_settings,
                  state.isEmojiInheritanceDisabled,
                  brandVoice.name
                ),
                created: brandVoice.created,
                isDefault: brandVoice.default,
                lastUpdated: brandVoice.last_updated,
                brandTones: brandVoice.brand_tones,
              }
            }) ?? []
          state.originalBrandVoices = [...state.brandVoices]
        }
        state.rawContent = action.payload.languageSettingsResponse
        state.editedContent = processInitializePageResponse(
          action.payload.languageSettingsResponse,
          state.isEmojiInheritanceDisabled,
          getBrandVoiceNameById(state.brandVoices, state.selectedBrandVoiceId)
        )
      })
      .addCase(saveContent.rejected, (state) => {
        state.isSaving = false

        const duplicatedAlternativeWords = getDuplicatedAlternativeWords(
          state.editedContent.alternativeWords
        )
        state.editedContent.alternativeWords =
          state.editedContent.alternativeWords.map((alternativeWord) => ({
            ...alternativeWord,
            hasError:
              !isAlternativeWordValid(alternativeWord) ||
              duplicatedAlternativeWords.includes(alternativeWord),
          }))
      })
      .addCase(changeAccountId.pending, () => {
        return initialState
      })
  },
})

/*
 * Actions
 */
export const {
  selectRegion,
  resetContent,
  clickSpaceBeforeEllipsis,
  clickSpaceAfterEllipsis,
  clickCapitablizeAfterColon,
  clickCapitablizeAfterDash,
  clickCapitablizeAfterEllipsis,
  clickCurlyApostrophes,
  clickAddPunctuation,
  clickRemovePunctuation,
  clickChangeDashStyle,

  addAlternativeWordsRow,
  updateAlternativeWordsCell,
  deleteAlternativeWordsRow,
  deactivateAlternativeWordsRows,

  clickAddForbiddenWord,
  clickRemoveForbiddenWord,
  clickTitleCase,
  clickSentenceCase,
  updateUppercaseProbability,
  tickUpperCaseVariants,
  addUppercaseWord,
  removeUppercaseWord,
  updateStylisticsWrappingProbability,
  updateStylisticsOptions,

  updateEmojiProbability,
  clickIsUsingBeginEmoji,
  clickIsUsingEndEmoji,
  clickIsUsingDoubleEmoji,
  selectBrandVoice,
  addEmojiRow,
  deactivateEmojis,
  updateEmojiCell,
  changeDefaultRegion,
  updateIndustry,
  updateBrandVoiceName,
} = styleGuideSlice.actions

/*
 * Selectors
 */
export const selectIsDefaultRegionSelected = (state: RootState): boolean => {
  const selectedBrandVoiceId = state.styleGuide.selectedBrandVoiceId
  return (
    state.styleGuide.brandVoices.find(
      (brandVoice) => brandVoice.id === selectedBrandVoiceId
    )?.isDefault ?? false
  )
}
export const selectIsOriginalDefaultRegionSelected = (
  state: RootState
): boolean => {
  const selectedBrandVoiceId = state.styleGuide.selectedBrandVoiceId
  return (
    state.styleGuide.originalBrandVoices.find(
      (brandVoice) => brandVoice.id === selectedBrandVoiceId
    )?.isDefault ?? false
  )
}

export const selectHasBeenEdited = (state: RootState): boolean =>
  !isEqual(selectOriginalContent(state), state.styleGuide.editedContent) ||
  !isEqual(state.styleGuide.originalBrandVoices, state.styleGuide.brandVoices)

export const selectOriginalContent = (state: RootState): Content | undefined =>
  state.styleGuide.rawContent &&
  processInitializePageResponse(
    state.styleGuide.rawContent,
    state.styleGuide.isEmojiInheritanceDisabled,
    getBrandVoiceNameById(
      state.styleGuide.brandVoices,
      state.styleGuide.selectedBrandVoiceId
    )
  )

export default styleGuideSlice.reducer
