import cx from 'classnames'

import Overlay from 'common/components/Overlay'

type Props = {
  isCollapsed?: boolean
  hasOverlay?: boolean
}

const SubNav: React.FC<Props> = ({
  children,
  isCollapsed = false,
  hasOverlay = false,
}) => {
  return (
    <div
      className={cx(
        `h-screen bg-warmGray-50 flex-none overflow-hidden z-40 relative 
         md:block md:left-0 md:z-auto border-l w-85`,
        {
          'border-none': hasOverlay,
          'md:w-85 border-r': !isCollapsed,
          'md:w-0': isCollapsed,
        }
      )}
    >
      {hasOverlay && <Overlay type="local" />}
      {children}
    </div>
  )
}

export default SubNav
