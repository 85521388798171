import { StatusMutator } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { ResponseVariant } from 'workflow/Insights/Insights'

export const filterSlVariantsByBanditStatus = (
  sl: ResponseVariant[],
  excludedStatuses: StatusMutator[]
) => {
  return sl.filter(
    (item) =>
      !!item?.bandit_status &&
      !excludedStatuses.includes(item?.bandit_status.status)
  )
}
