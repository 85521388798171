import cx from 'classnames'

import Button from 'common/components/button'
import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Add, Minus } from 'common/icons'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'
import {
  addTemplate,
  MAX_TEMPLATES_TO_ADD,
  removeTemplate,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

const IconWrapper = ({ children }: { children: React.ReactNode }) => (
  <div
    className={cx(
      `flex justify-center items-center text-coolGray-400 w-6 h-6.5
      border border-coolGray-300`
    )}
  >
    {children}
  </div>
)

const getIconClassName = (isDisabled: boolean) =>
  cx('w-6 h-6.5 p-1', {
    'hover:text-white hover:bg-maroon-300 hover:border-maroon-300': !isDisabled,
    'hover:text-coolGray-400 hover:bg-coolGray-100 hover:border-coolGray-100':
      isDisabled,
  })

type Props = {
  templateId: string
  isOptimized: boolean
}

const SelectBox = ({ templateId, isOptimized }: Props) => {
  const dispatch = useAppDispatch()
  const { content } = useContent()
  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )

  const handleAdd = () => {
    dispatch(addTemplate({ templateId, isOptimized }))
  }

  const handleMinus = () => {
    dispatch(removeTemplate({ templateId }))
  }

  const alreadyAddedTemplatesCount = content?.elements.length
    ? content.elements.length - 1
    : 0
  const templatesToAddCount = templatesToAdd.length
  const isAddingDisabled =
    templatesToAddCount + alreadyAddedTemplatesCount >= MAX_TEMPLATES_TO_ADD
  const templateCount = templatesToAdd.filter(
    (template) => template.id === templateId
  ).length
  const isMinusDisabled = templateCount === 0

  return (
    <div className="flex items-center ">
      <IconWrapper>
        <Button
          variant="icon"
          onClick={() => handleMinus()}
          disabled={isMinusDisabled}
          aria-label="subtract"
        >
          <Minus
            isDefaultColor={false}
            className={getIconClassName(isMinusDisabled)}
          />
        </Button>
      </IconWrapper>
      <div
        className="flex justify-center items-center h-6.5 w-6 mx-2 bg-coolGray-50 outline-none
                      border border-coolGray-300 font-medium text-coolGray-800 select-none"
      >
        {templateCount}
      </div>
      <IconWrapper>
        <ButtonWithTooltip
          variant="icon"
          onClick={() => handleAdd()}
          disabled={isAddingDisabled}
          tooltip={isAddingDisabled}
          tooltipText="Template limit reached"
          aria-label="add"
        >
          <Add
            isDefaultColor={false}
            className={getIconClassName(isAddingDisabled)}
          />
        </ButtonWithTooltip>
      </IconWrapper>
    </div>
  )
}

export default SelectBox
