import { useHistory, useParams } from 'react-router'

import ActionIcon from 'common/components/ActionIcon'
import Badge from 'common/components/Badge'
import { useAppSelector } from 'common/hooks/redux'
import { ArrowLeft } from 'common/icons'

import useGetPersonalizationsQuery from '../api/queries/useGetPersonalizationsQuery'

import HeaderActions from './HeaderActions'

type Props = {
  trainingContent?: 'contextual' | 'personalized'
}

const Header = ({ trainingContent }: Props) => {
  const history = useHistory()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { data: personalizations = [] } = useGetPersonalizationsQuery()
  const { id } = useParams<{ id: string }>()
  const personalizationName = personalizations.find((e) => e.id === id)?.name

  const badgeTextPerTrainingContent = {
    contextual: 'Serving contextual content',
    personalized: 'Serving personalized content',
  } as const

  return (
    <div className="flex flex-none bg-maroon-700 items-center border-b-1 border-coolGray-200 h-14 py-2 px-6 uppercase text">
      <div className="flex-1 flex justify-between">
        <div className="flex justify-center items-center">
          <ActionIcon
            onClick={() =>
              history.push(`/accounts/${accountId}/reports/personalization`)
            }
          >
            <ArrowLeft
              className="cursor-pointer text-white hover:text-yellow-100"
              isDefaultColor={false}
            />
          </ActionIcon>
          <span className="text-lg font-medium text-white ml-2 break-all">
            {personalizationName}
            {trainingContent && (
              <Badge
                className="ml-2"
                text={badgeTextPerTrainingContent[trainingContent]}
              />
            )}
          </span>
        </div>
        <HeaderActions />
      </div>
    </div>
  )
}

export default Header
