import { useCallback } from 'react'
import helpers from 'workflow/utils/helpers'
import {
  closeTweakLine,
  rejectALine,
  tweakALine,
} from 'workflow/Workflow.actions'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import type {
  CampaignType,
  RejectLineData,
  TweakLineData,
  TweakVariant,
} from './types'

const { isAManagedCampaign, isBanditInitialised } = helpers

const useTweakVariantModal = (): TweakVariant => {
  const dispatch = useAppDispatch()

  const {
    campaignData,
    tweakingLoading,
    rejectLineLoading,
    showTweakLine,
    lineBeingTweaked,
  } = useAppSelector((state) => state.campaignStates)

  const onRejectLine = useCallback(
    (rejectLineData: RejectLineData, campaignType: CampaignType) => {
      const data = {
        ...rejectLineData,
        campaign_id: campaignData._id,
        // here we update the isFeedbackOnly property IF we are dealing with a managed campaign.
        ...(isAManagedCampaign(campaignData) ? { feedbackOnly: true } : {}),
      }

      const linguoData =
        campaignType === 'linguo'
          ? {
              isLinguo: true,
              isInitialized: isBanditInitialised(campaignData),
            }
          : undefined

      dispatch(rejectALine(data, campaignType, linguoData))
    },
    [campaignData, dispatch]
  )

  const onTweakLine = useCallback(
    (tweakLineData: TweakLineData, campaignType: CampaignType) => {
      const isFeedbackOnly =
        campaignData?.campaign_configuration?.feedback_settings?.edit
          ?.feedback_only

      const data = {
        ...tweakLineData,
        campaign_id: campaignData._id,
        ...(isFeedbackOnly ? { feedbackOnly: true } : {}),
      }

      dispatch(tweakALine(data, campaignType))
    },
    [campaignData, dispatch]
  )

  const closeTweakLines = useCallback(() => {
    dispatch(closeTweakLine())
  }, [dispatch])

  return {
    isVisible: showTweakLine,
    isTweakingLoading: tweakingLoading,
    isRejectLineLoading: rejectLineLoading,
    feedbackSettings: campaignData?.campaign_configuration?.feedback_settings,
    newlinesInVariants:
      campaignData?.campaign_configuration?.validation_rules
        ?.newlines_in_variants,
    tweakingLine: lineBeingTweaked,
    onRejectLine,
    onTweakLine,
    onClose: closeTweakLines,
  }
}

export default useTweakVariantModal
