import { generatePath, useHistory } from 'react-router-dom'

import { ContentTypeIcon } from 'common/components/ContentTypeIcon'
import { useAppDispatch } from 'common/hooks/redux'
import { ContentInfo } from 'features/futurama/contentsPage/interfaces'
import { showTemplates } from 'features/futurama/store/contentSlice'
import ListWidget, {
  ListWidgetAction,
  ListWidgetItem,
} from 'features/homePage/components/listWidget'

const CONTENTS_LIST_SIZE = 8

type Props = {
  contents: ContentInfo[]
  status: 'error' | 'success' | 'loading'
}

const RecentContents = ({ contents, status }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const createAction: ListWidgetAction = {
    label: 'Create content',
    onClick: () => {
      history.push(generatePath('/content'))
      dispatch(showTemplates())
    },
  }

  const sortByCreatedDate = (contents: ContentInfo[]) => {
    const comparer = (a: ContentInfo, b: ContentInfo) => {
      if (a['createdDate'] === b['createdDate']) {
        return 0
      }

      return a['createdDate'] < b['createdDate'] ? 1 : -1
    }

    return contents.sort(comparer)
  }

  const sortedContent = sortByCreatedDate(contents)

  return (
    <ListWidget
      title="Recent content"
      className="w-full h-full"
      data-cy="recent-contents-widget"
      action={createAction}
      isLoading={status === 'loading'}
      error={
        status === 'error' ? (
          <div className="px-4 py-2">Loading Content failed</div>
        ) : null
      }
    >
      <ul>
        {sortedContent.slice(0, CONTENTS_LIST_SIZE).map((item, index) => (
          <ListWidgetItem
            key={item.id}
            onClick={() => {
              history.push(generatePath(`/content/${item.id}`))
            }}
            data-cy={`recent-content-widget-link-${index}`}
          >
            <span className="group-hover:text-maroon-300">
              <ContentTypeIcon contentType={item.elements[0].icon as any} />
            </span>
            <span className="text-coolGray-800 ml-3">{item.name}</span>
          </ListWidgetItem>
        ))}
      </ul>
    </ListWidget>
  )
}

export default RecentContents
