import { useState } from 'react'
import { Element } from '@phrasee/phrasee-typings/typings/futurama/element'
import cx from 'classnames'

import ActionIcon from 'common/components/ActionIcon'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Settings as SettingsIcon } from 'common/icons'
import { componentConfigurationClicked } from 'features/futurama/store/contentSlice'

import VerticalDotMenu from './VerticalDotMenu'

type Props = {
  isConfigurable: boolean
  isDisabled: boolean
  isSelected: boolean
  element?: Element
}

const ActionsMenu = ({
  isConfigurable,
  isDisabled,
  element,
  isSelected,
}: Props) => {
  const dispatch = useAppDispatch()
  const [isVerticalMenuOpen, setIsVerticalMenuOpen] = useState(false)

  const showComponentConfiguration = useAppSelector(
    (state) => state.content.showComponentConfiguration
  )

  const isCustomized = element?.settings_customisation

  return (
    <div className="mr-0 ml-auto flex items-start">
      {isConfigurable && (
        <VerticalDotMenu
          isSelected={isSelected}
          element={element}
          isEditHidden={isCustomized}
          isRemoveHidden={element && element.element_id === 1}
          isMenuOpen={isVerticalMenuOpen}
          onMenuChange={({ open }) => setIsVerticalMenuOpen(open)}
          onEditClick={() => {
            dispatch(
              componentConfigurationClicked({ elementId: element?.element_id })
            )
          }}
        />
      )}
      {isConfigurable && !isDisabled && isCustomized && (
        <ActionIcon
          onClick={(e) => {
            dispatch(
              componentConfigurationClicked({ elementId: element?.element_id })
            )
            e.stopPropagation()
          }}
        >
          <SettingsIcon
            isDefaultColor={false}
            size={6}
            className={cx('hover:text-maroon-300', {
              'text-coolGray-400':
                !isSelected || (isSelected && !showComponentConfiguration),
              'text-maroon-300': isSelected && showComponentConfiguration,
            })}
          />
        </ActionIcon>
      )}
    </div>
  )
}

export default ActionsMenu
