import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { BrandVoice } from '../../store/brandVoiceSlice'
import { getBrandVoices } from '../api'
import { brandVoiceKeys } from '../queryKeys'

const useGetBrandVoicesQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getBrandVoicesQuery = useQuery<BrandVoice[]>(
    brandVoiceKeys.list(accountId),
    () => getBrandVoices({ accountId }),
    {
      staleTime: 10 * 60 * 1000,
      refetchOnMount: 'always',
    }
  )

  return getBrandVoicesQuery
}

export default useGetBrandVoicesQuery
