export const contentKeys = {
  all: ['futurama-content'] as const, // TODO: change to 'content'
  contents: () => [...contentKeys.all, 'contents'] as const,
  content: (id: string) => [...contentKeys.contents(), id] as const,
  contentTeams: (contentId: string, accountId: string) =>
    [...contentKeys.content(contentId), 'contentTeams', accountId] as const,
  contentUsers: (contentId: string, accountId: string) =>
    [...contentKeys.content(contentId), 'contentUsers', accountId] as const,
  templates: () => [...contentKeys.all, 'template'] as const,
  template: (id: string) => [...contentKeys.templates(), id] as const,
}
