import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import {
  ContentLibraryElementResponse,
  ContentLibraryEntryResponse,
  getContentLibrary,
} from '../api'
import { contentKeys } from '../queryKeys'

const statusPriority = {
  'New Language': 1,
  Draft: 2,
  'Pending Approval': 3,
  'Missing Results': 4,
  Live: 5,
  Completed: 6,
}

const sortByStatusAndDate = <
  T extends ContentLibraryEntryResponse | ContentLibraryElementResponse
>(
  a: T,
  b: T
) => {
  const statusComparison = statusPriority[a.status] - statusPriority[b.status]
  if (statusComparison !== 0) {
    return statusComparison
  }
  return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
}

type ContentLibraryElement = {
  id: string
  name: string
  project: string
  template: string
  campaign_id: string
  experimentType: string
  owner: string
  createdDate: string
  sendDate: string
  status: string
}

export type ContentLibraryEntry = {
  id: string
  name: string
  status: string
  createdDate: string
  isLegacy: boolean
  subRows: ContentLibraryElement[]
}

const useGetContentLibrary = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const getContentLibraryQuery = useQuery<
    ContentLibraryEntryResponse[],
    unknown,
    ContentLibraryEntry[]
  >(contentKeys.contentLibrary(accountId), () => getContentLibrary(accountId), {
    select: (data) =>
      data.sort(sortByStatusAndDate).map((item) => ({
        id: item.id,
        name: item.name,
        status: item.status,
        createdDate: item.createdDate,
        isLegacy: item.is_legacy,
        subRows: item.elements
          .map((element) => ({
            id: element.id,
            name: element.name,
            project: element.project,
            template: element.template,
            campaign_id: element.campaign_id,
            experimentType: element.experimentType,
            owner: element.owner,
            createdDate: element.createdDate,
            sendDate: element.sendDate ?? '',
            status: element.status,
          }))
          .sort(sortByStatusAndDate),
      })),
    refetchOnWindowFocus: false,
  })
  return getContentLibraryQuery
}

export default useGetContentLibrary
