import cx from 'classnames'

import Button from 'common/components/button'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as Eye } from 'common/icons/eye/default.svg'
import { ReactComponent as EyeNo } from 'common/icons/eyeNo/default.svg'
import {
  hideRightPanel,
  showRightPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

const RightPanelButton = () => {
  const dispatch = useAppDispatch()

  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )

  if (rightPanelVisibility === 'none') {
    return null
  }
  return (
    <div className="ml-7">
      <Button
        className={cx('text-yellow-200 hover:text-white', {
          hidden: rightPanelVisibility === 'hidden',
          'hidden lg:block': rightPanelVisibility === 'auto',
        })}
        prefixIcon={
          <Tooltip overlay="Hide panel">
            <EyeNo width={24} height={24} />
          </Tooltip>
        }
        onClick={() => dispatch(hideRightPanel())}
      />
      <Button
        className={cx('text-white hover:text-yellow-200', {
          hidden: rightPanelVisibility === 'visible',
          'lg:hidden': rightPanelVisibility === 'auto',
        })}
        prefixIcon={
          <Tooltip overlay="Show panel">
            <Eye width={24} height={24} />
          </Tooltip>
        }
        onClick={() => dispatch(showRightPanel())}
      />
    </div>
  )
}

export default RightPanelButton
