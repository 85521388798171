import axios, { AxiosResponse } from 'axios'

export const postPasswordChangeRequest = async (email: string | undefined) => {
  const url = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`
  const payload = {
    email,
    connection: 'mongo',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  }

  if (payload.email === undefined) {
    throw new Error('Could not get your email, try logging in again.')
  }

  const result: AxiosResponse<{ data: string }> = await axios.post(
    url,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return result.data
}
