import { ChangeEvent, useEffect, useRef, useState } from 'react'
import helpers from 'workflow/utils/helpers'
import { renameCampaign, saveCampaignName } from 'workflow/Workflow.actions'

import {
  experimentsPermissions,
  getIsAuthorized,
} from 'common/auth/permissions'
import { EditableValue, EditingValue } from 'common/components/editableInput'
import FormError from 'common/components/formError'
import Input from 'common/components/Input'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

let maxInputWidth = 0

const NameInput = () => {
  const dispatch = useAppDispatch()
  const campaign = useAppSelector((state) => state.campaignStates)
  const { isNewCampaign } = useAppSelector((state) => state.campaigns)
  const { campaignName: name } = campaign

  const { permissions } = useAppSelector((state) => state.authStates)
  const hasEditPermission = getIsAuthorized(
    permissions,
    experimentsPermissions.edit
  )

  const nameInputRef = useRef<HTMLInputElement>(null)
  const nameWrapperRef = useRef<HTMLDivElement>(null)

  const [value, setValue] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

  useEffect(() => {
    let maxWidth = nameWrapperRef.current?.clientWidth

    if (maxWidth) {
      maxWidth = maxWidth - 85 // substract button and padding
    }
    if (!maxInputWidth && maxWidth) {
      maxInputWidth = maxWidth
    }
    if (maxInputWidth) {
      helpers.applyInputWidth(maxInputWidth)
    }
  })

  useEffect(() => {
    if (isNewCampaign) {
      setIsEditing(true)
      nameInputRef.current?.focus()
    } else {
      setIsEditing(false)
    }
  }, [isNewCampaign])

  useEffect(() => {
    if (isEditing) {
      nameInputRef.current?.focus()
    }
  }, [isEditing])

  useEffect(() => {
    if (isNewCampaign && value.length > 0 && !isInputFocused) {
      dispatch(renameCampaign(value.trim()))
    }
  }, [isInputFocused, isNewCampaign, value, dispatch])

  const startEditing = () => {
    setIsEditing(true)
    setValue(stripForbiddenCharacters(name))
  }

  const onBlur = () => {
    setIsInputFocused(false)

    if (isNewCampaign) {
      if (!value.length) {
        setError('Please enter experiment name')
      } else {
        setError(undefined)
      }
    } else {
      setError(undefined)
      setIsEditing(false)
    }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newCampaignName = event.target.value
    const { isValid, validationError } =
      helpers.validateCampaignName(newCampaignName)

    if (isValid) {
      setValue(stripForbiddenCharacters(newCampaignName))
      setError(undefined)
    } else {
      setError(validationError)
    }
  }

  const onSubmit = () => {
    if (isNewCampaign) {
      dispatch(renameCampaign(value.trim()))
      nameInputRef.current?.blur()
    } else {
      saveName()
    }
  }

  const saveName = () => {
    const { campaignData, campaignValidationRules } = campaign

    dispatch(
      saveCampaignName(campaignData._id, value.trim(), campaignValidationRules)
    )
    setIsEditing(false)
    setIsInputFocused(false)
    setError(undefined)
  }

  return (
    <>
      <div
        className="w-full py-2 flex justify-between items-center"
        ref={nameWrapperRef}
      >
        <div className="flex items-center">
          {hasEditPermission ? (
            isEditing ? (
              <form
                data-cy="campaign-name-form"
                onSubmit={(event) => {
                  event.preventDefault()
                  onSubmit()
                }}
              >
                <EditingValue
                  ref={nameInputRef}
                  data-cy="campaign-name"
                  value={value}
                  savedValue={name}
                  placeholder="Enter an experiment name…"
                  shouldShowSaveButton={!isNewCampaign}
                  onSaveClick={saveName}
                  onChange={onChange}
                  onBlur={onBlur}
                  setIsFocused={setIsInputFocused}
                />
              </form>
            ) : (
              <EditableValue
                data-cy="campaign-name"
                value={name}
                onEditClick={startEditing}
              />
            )
          ) : (
            <Input
              type="text"
              data-cy="campaign-name"
              className={`autoSize mr-6 text-2xl lg:text-3xl
            text-coolGray-800 placeholder-coolGray-800 focus:outline-none
            caret-maroon-300 font-medium`}
              value={name}
              disabled
            />
          )}
        </div>
      </div>
      <div className="h-4 text-coolGray-400">
        {isEditing && isInputFocused && <>{value.length} / 128</>}
      </div>
      {error && <FormError>{error}</FormError>}
    </>
  )
}

export default NameInput
