import { useEffect } from 'react'
import cx from 'classnames'

import { useAppDispatch } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { showLeftPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

type Props = {
  isVisible?: boolean
  children: React.ReactNode
  onOutsideClick?: () => void
  'data-testid'?: string
}

const LeftPanel = ({
  isVisible,
  children,
  onOutsideClick,
  'data-testid': dataTestId = 'leftPanel',
}: Props) => {
  const dispatch = useAppDispatch()
  const { screenWidth } = useWindowDimensions()

  useEffect(() => {
    if (screenWidth > 1200) {
      dispatch(showLeftPanel())
    }
  }, [dispatch, screenWidth])
  return (
    <div
      className={cx({
        hidden: isVisible === false,
      })}
      data-testid={dataTestId}
    >
      <div
        className={cx(
          'z-60 fixed right-0 w-full h-full bg-transparent md:hidden',
          {
            'pointer-events-none': !isVisible,
          }
        )}
        onClick={onOutsideClick}
      />
      <div
        className={cx(
          'z-60 fixed left-0 h-full shadow-xl shadow-offset-right md:shadow-none md:static md:flex border-r md:border-r-0 flex-col bg-white md:bg-warmGray-50 w-90 px-6 pt-6',
          {
            flex: isVisible,
            hidden: isVisible === false,
            'hidden md:flex': isVisible === undefined,
          }
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default LeftPanel
