import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Content from 'app/Content'
import cx from 'classnames'

import Footer from 'common/components/layout/Footer'
import Overlay from 'common/components/Overlay'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Welcome from 'common/components/welcome'
import Widget from 'common/components/Widget'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'

import PasswordUpdate from './PasswordUpdate'
import ProfileUpdate from './ProfileUpdate'

const Profile = () => {
  const {
    firstName: userFirstName,
    lastName: userLastName,
    profile: { avatarColor },
    intercomInfo: { email, job_title: jobTitle, phonenumber: phoneNumber },
  } = useAppSelector((state) => state.authStates)

  const [isSideBarVisible, setIsSideBarVisible] = useState<boolean>(false)

  const { user } = useAuth0()

  /**
   * If user.sub starts with 'auth0', it means the user is an original Jacquard user.
   * If user.sub starts with an enterprise or a social connection provider name, e.g. 'microsoftlive', 'google' or 'tesco',
   * it means the user logged in via an enterprise or a social connection.
   * The option to change password should only be available to original Jacquard users.
   * Other users should change their passwords at their provider.
   */
  const isOriginalPhraseeUser: boolean | undefined =
    user?.sub?.startsWith('auth0')

  useDocumentTitle('Profile | Jacquard')

  return (
    <Content>
      <PageContainer>
        <PageHeader>
          <PageTitle title="Account" />
        </PageHeader>

        <>
          <div className="grid grid-flow-row auto-cols-fr gap-6 mb-10 max-w-318">
            <div className=" sm:flex mr-6 w-full">
              <Widget>
                <Welcome
                  firstName={userFirstName || ''}
                  lastName={userLastName || ''}
                  firstUse={false}
                  showSignoutButton
                />
              </Widget>
            </div>

            <Widget>
              <h2 className="text-gray-900 text-xl font-medium  mb-6">
                Profile
              </h2>
              <ProfileUpdate
                firstname={userFirstName || ''}
                lastname={userLastName || ''}
                email={email ?? ''}
                jobTitle={jobTitle || ''}
                phoneNumber={phoneNumber || ''}
                avatarColor={avatarColor}
              />
            </Widget>

            {isOriginalPhraseeUser && (
              <Widget>
                <div className="flex justify-between align-middle">
                  <div className="col-span-1 my-auto">
                    <h2 className="text-gray-900 text-xl font-medium">
                      Change password
                    </h2>
                    <p className="mt-1 text-coolGray-500 text-sm">
                      To update your password please follow the instructions
                      that will be sent to your e-mail.
                    </p>
                  </div>
                  <PasswordUpdate />
                </div>
              </Widget>
            )}
          </div>

          <Footer />
        </>
      </PageContainer>

      <Overlay
        className={cx('lg:hidden', {
          hidden: !isSideBarVisible,
        })}
        onClick={() => setIsSideBarVisible(!isSideBarVisible)}
      />
    </Content>
  )
}

export default Profile
