import { Link, LinkProps } from 'react-router-dom'
import cx from 'classnames'

type HoldingPageSubComponents = {
  Title: React.FC<ClassNameProps>
  SubTitle: React.FC<ClassNameProps>
  TextBold: React.FC
  TextLink: React.FC<OptionProps>
  Text: React.FC<ClassNameProps>
}

interface ClassNameProps {
  className?: string
}

type OptionProps = {
  linkTo: LinkProps['to']
  onClick?: () => void
}

const HoldingPage: React.FC<ClassNameProps> & HoldingPageSubComponents = ({
  className,
  children,
}) => {
  return (
    <div className={cx('mt-20 text-coolGray-800', className)}>{children}</div>
  )
}

const Title: React.FC<ClassNameProps> = ({ className, children }) => {
  return <h1 className={cx('text-6xl font-bold', className)}>{children}</h1>
}

const SubTitle: React.FC<ClassNameProps> = ({ className, children }) => {
  return <h2 className={cx('mt-12 text-4xl', className)}>{children}</h2>
}

const TextBold: React.FC = ({ children }) => {
  return <p className="mt-8 text-2xl font-medium">{children}</p>
}

const Text: React.FC<ClassNameProps> = ({ className, children }) => {
  return <p className={cx('mt-4 text-3xl font-light', className)}>{children}</p>
}

const TextLink: React.FC<OptionProps> = ({ linkTo, onClick, children }) => {
  return (
    <p className="mt-4">
      <Link
        to={linkTo}
        className="text-2xl font-light hover:underline"
        onClick={onClick}
      >
        {children}
      </Link>
    </p>
  )
}

HoldingPage.Title = Title
HoldingPage.SubTitle = SubTitle
HoldingPage.TextBold = TextBold
HoldingPage.TextLink = TextLink
HoldingPage.Text = Text

export default HoldingPage
