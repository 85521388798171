import { TestedContentSection } from '@phrasee/phrasee-typings/Graphql/interfaces'

import {
  clicksToSends,
  DEFAULT_DAYS_BEFORE_ADDING_LINES,
  DEFAULT_HIGH_PERFORMERS_NUMBER,
  DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_BROADCAST,
  DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_TRIGGER,
  DEFAULT_PROPORTION_SENT_TO_WINNER_NUMBER,
  DEFAULT_TEST_AUDIENCE_SIZE_BROADCAST,
  DEFAULT_TEST_AUDIENCE_SIZE_TRIGGER,
  opensToSends,
  optionAutomatic,
  optionHeadToHeadAndWait,
  singleBest,
} from './components/advancedOptions/constants'
import {
  BeSmartWhenToIntroduceVariants,
  OptimizationMetric,
  OptimizationMode,
} from './types'

export type AdvancedSettingsActions =
  | { type: 'set_min_sends_to_human_control_value'; value: number | undefined }
  | {
      type: 'set_is_min_sends_to_human_control_enabled'
      value: boolean
      isBroadcastCampaign: boolean
    }
  | { type: 'set_is_drop_bad_variants_enabled'; value: boolean }
  | { type: 'set_is_drop_human_control_enabled'; value: boolean }
  | { type: 'set_optimization_mode'; value: OptimizationMode }
  | { type: 'set_optimization_metric'; value: OptimizationMetric }
  | { type: 'set_high_performers_number'; value: number }
  | { type: 'set_proportion_send_to_winner_number'; value: number | undefined }
  | { type: 'set_is_days_before_adding_lines_enabled'; value: boolean }
  | { type: 'set_days_before_adding_lines'; value: number | undefined }
  | { type: 'set_is_advanced_options_disabled'; value: boolean }
  | { type: 'set_is_in_body'; value: boolean }
  | { type: 'set_has_tracked_click_metrics'; value: boolean }
  | { type: 'set_test_audience_size_value'; value: number | undefined }
  | {
      type: 'set_test_audience_size_enabled'
      value: boolean
      isBroadcastCampaign: boolean
    }
  | {
      type: 'set_be_smart_when_to_introduce_variants_value'
      value: BeSmartWhenToIntroduceVariants
    }
  | {
      type: 'set_integration_optimization_metrics'
      value: {
        integrationOptimizationMetric: string
        integrationOptimizationAudience: string
      }
    }

export interface AdvancedSettingsState {
  minSendsToHumanControlInputValue: number | undefined
  isMinSendsToHumanControlEnabled: boolean
  isDropBadVariantsEnabled: boolean
  isDropHumanControlEnabled: boolean
  optimizationMode: OptimizationMode
  optimizationMetric: OptimizationMetric
  highPerformersNumber: number
  proportionSendToWinnerNumber: number | undefined
  daysBeforeAddingLines: number | undefined
  isDaysBeforeAddingLinesEnabled: boolean
  isAdvancedOptionsDisabled: boolean
  isInBody: boolean | undefined
  testedContentSection: TestedContentSection | undefined
  hasTrackedClickMetrics: boolean | undefined
  isTestAudienceSizeEnabled: boolean
  testAudienceSizeValue: number | undefined
  beSmartWhenToIntroduceVariants: BeSmartWhenToIntroduceVariants
  integrationOptimizationMetric?: string
  integrationOptimizationAudience?: string
}

export const advancedSettingsReducer = (
  state: AdvancedSettingsState,
  action: AdvancedSettingsActions
): AdvancedSettingsState => {
  switch (action.type) {
    case 'set_min_sends_to_human_control_value':
      return { ...state, minSendsToHumanControlInputValue: action.value }
    case 'set_is_min_sends_to_human_control_enabled':
      if (action.value === false) {
        return {
          ...state,
          isMinSendsToHumanControlEnabled: false,
          minSendsToHumanControlInputValue: 0,
        }
      }
      return {
        ...state,
        isMinSendsToHumanControlEnabled: action.value,
        minSendsToHumanControlInputValue: action.isBroadcastCampaign
          ? DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_BROADCAST
          : DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_TRIGGER,
      }
    case 'set_is_drop_bad_variants_enabled':
      return { ...state, isDropBadVariantsEnabled: action.value }
    case 'set_is_drop_human_control_enabled':
      return { ...state, isDropHumanControlEnabled: action.value }
    case 'set_optimization_mode':
      return { ...state, optimizationMode: action.value }
    case 'set_optimization_metric':
      return { ...state, optimizationMetric: action.value }
    case 'set_high_performers_number':
      return { ...state, highPerformersNumber: action.value }
    case 'set_proportion_send_to_winner_number':
      return { ...state, proportionSendToWinnerNumber: action.value }
    case 'set_days_before_adding_lines':
      return { ...state, daysBeforeAddingLines: action.value }
    case 'set_is_days_before_adding_lines_enabled':
      if (action.value === false) {
        return {
          ...state,
          isDaysBeforeAddingLinesEnabled: false,
          daysBeforeAddingLines: undefined,
        }
      }

      return {
        ...state,
        isDaysBeforeAddingLinesEnabled: action.value,
        daysBeforeAddingLines: DEFAULT_DAYS_BEFORE_ADDING_LINES,
      }
    // not used right now as we are using the loading state
    case 'set_is_advanced_options_disabled':
      return { ...state, isAdvancedOptionsDisabled: action.value }
    case 'set_is_in_body':
      return { ...state, isInBody: action.value }
    case 'set_has_tracked_click_metrics':
      return { ...state, hasTrackedClickMetrics: action.value }
    case 'set_test_audience_size_value':
      return { ...state, testAudienceSizeValue: action.value }
    case 'set_test_audience_size_enabled':
      if (action.value === false) {
        return {
          ...state,
          isTestAudienceSizeEnabled: false,
          testAudienceSizeValue: 0,
        }
      }
      return {
        ...state,
        isTestAudienceSizeEnabled: action.value,
        testAudienceSizeValue: action.isBroadcastCampaign
          ? DEFAULT_TEST_AUDIENCE_SIZE_BROADCAST
          : DEFAULT_TEST_AUDIENCE_SIZE_TRIGGER,
      }
    case 'set_be_smart_when_to_introduce_variants_value':
      if (action.value === optionHeadToHeadAndWait.value) {
        return {
          ...state,
          beSmartWhenToIntroduceVariants: action.value,
          daysBeforeAddingLines: DEFAULT_DAYS_BEFORE_ADDING_LINES,
        }
      }
      return {
        ...state,
        beSmartWhenToIntroduceVariants: action.value,
        daysBeforeAddingLines: undefined,
      }
    case 'set_integration_optimization_metrics':
      return {
        ...state,
        integrationOptimizationMetric:
          action.value.integrationOptimizationMetric,
        integrationOptimizationAudience:
          action.value.integrationOptimizationAudience,
      }
    default:
      return state
  }
}

export const getOptimizationMetricDependingOnSelectionMetric = (
  selectionMetric: string | undefined
): OptimizationMetric => {
  switch (selectionMetric) {
    case 'clicks_to_sends':
    case 'click_rate':
      return 'clicks-to-sends'
    case 'phrasee_score':
      return 'phrasee-score'
    case 'clicks_to_opens':
      return 'clicks-to-opens'
    case 'conversion_rate':
    case 'influenced_open_rate':
    default:
      return 'opens-to-sends'
  }
}

export const getOptimizationMetric = (
  selectionMetric: string | undefined,
  hasTrackedClickMetrics: boolean | undefined,
  isInBody: boolean | undefined
): OptimizationMetric => {
  // HG-1388 depending on configuration set default optimization metric value.
  if (hasTrackedClickMetrics && isInBody) {
    return clicksToSends.value
  } else if (!hasTrackedClickMetrics && isInBody) {
    return undefined
  } else if (!hasTrackedClickMetrics && !isInBody) {
    return opensToSends.value
  }

  // HG-1725 default optimization value if campaign does not match conditions above.
  return getOptimizationMetricDependingOnSelectionMetric(selectionMetric)
}

export const initialAdvancedOptionsState = ({
  selectionMetric,
  isInBody,
  testedContentSection,
  hasTrackedClickMetrics,
  isBroadcastCampaign,
  showDOAutomaticOptions,
}: {
  selectionMetric: string | undefined
  isInBody: boolean | undefined
  hasTrackedClickMetrics: boolean | undefined
  isBroadcastCampaign: boolean | undefined
  testedContentSection: TestedContentSection | undefined
  showDOAutomaticOptions: boolean | undefined
}): AdvancedSettingsState => {
  const optimizationMetricInitialValue = getOptimizationMetric(
    selectionMetric,
    hasTrackedClickMetrics,
    isInBody
  )

  return {
    minSendsToHumanControlInputValue: isBroadcastCampaign
      ? DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_BROADCAST
      : DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_TRIGGER,
    isMinSendsToHumanControlEnabled: true,
    isDropBadVariantsEnabled: !isBroadcastCampaign,
    isDropHumanControlEnabled: false,
    optimizationMode: singleBest.value,
    optimizationMetric: optimizationMetricInitialValue,
    highPerformersNumber: DEFAULT_HIGH_PERFORMERS_NUMBER,
    proportionSendToWinnerNumber: DEFAULT_PROPORTION_SENT_TO_WINNER_NUMBER,
    daysBeforeAddingLines: showDOAutomaticOptions
      ? undefined
      : DEFAULT_DAYS_BEFORE_ADDING_LINES,
    isDaysBeforeAddingLinesEnabled: !showDOAutomaticOptions,
    isAdvancedOptionsDisabled: false,
    isInBody: isInBody,
    testedContentSection,
    hasTrackedClickMetrics: hasTrackedClickMetrics,
    isTestAudienceSizeEnabled: true,
    testAudienceSizeValue: DEFAULT_TEST_AUDIENCE_SIZE_TRIGGER,
    beSmartWhenToIntroduceVariants: optionAutomatic.value,
  }
}
