import { FC } from 'react'
import cx from 'classnames'
import Modal from 'rc-dialog'
import { IDialogPropTypes } from 'rc-dialog/lib/IDialogPropTypes'

import { Close } from 'common/icons'

import 'rc-dialog/assets/index.css'
import styles from './Modal.module.css'

export type ModalProps = IDialogPropTypes & {
  centered?: boolean
  hasMargin?: boolean
  isResponsive?: boolean
  size?: 'small' | 'large'
}

const ModalComponent: FC<ModalProps> = ({
  children,
  wrapClassName,
  centered,
  hasMargin = true,
  isResponsive = false,
  size = 'small',
  ...rest
}) => {
  return (
    <Modal
      closeIcon={<Close isDefaultColor={false} />}
      {...rest}
      wrapClassName={cx(wrapClassName, styles.modal, {
        [styles.centered]: centered,
        [styles.withMargin]: hasMargin,
        [styles.withoutMargin]: !hasMargin,
        [styles.responsive]: isResponsive,
        [styles.large]: size === 'large',
      })}
    >
      {children}
    </Modal>
  )
}

export default ModalComponent
