import MultiGraphWrapper from 'common/components/MultiGraphWrapper'

import { WidgetIncrementalEventsResponse } from './api'
import IncrementalEventsWidget from './IncrementalEventsWidget'
import ReportItem from './ReportItem'

type Props = {
  widgetData: WidgetIncrementalEventsResponse
}

const IncrementalEventsSection = ({ widgetData }: Props) => {
  const openIncrementalData = widgetData.graphs?.find(
    (graph) =>
      graph.id === 'widget_incremental_opens' ||
      graph.id === 'widget_incremental_direct_opens'
  )
  const openGraphConfiguration = openIncrementalData?.configuration
  const clickIncrementalData = widgetData.graphs?.find(
    (graph) =>
      graph.id === 'widget_incremental_clicks' ||
      graph.id === 'widget_incremental_influenced_opens'
  )
  const clickGraphConfiguration = clickIncrementalData?.configuration

  return (
    <ReportItem title="Incremental events" withShadowWrapper>
      <MultiGraphWrapper>
        {openIncrementalData && openGraphConfiguration && (
          <IncrementalEventsWidget
            name={openIncrementalData.display_name}
            tooltip="Estimated cumulative incremental opens displayed by period."
            configuration={openGraphConfiguration}
          />
        )}
        {clickIncrementalData && clickGraphConfiguration && (
          <IncrementalEventsWidget
            name={clickIncrementalData.display_name}
            tooltip="Estimated cumulative incremental clicks displayed by period."
            configuration={clickGraphConfiguration}
          />
        )}
      </MultiGraphWrapper>
    </ReportItem>
  )
}

export default IncrementalEventsSection
