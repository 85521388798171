import { TableInstance } from '..'

import { getTotalPageValue } from './helpers'
import NumericCell from './Numeric'

const TotalPageValueCell = <T extends object>(props: TableInstance<T>) => {
  const averageValue = getTotalPageValue(props)

  return <NumericCell value={averageValue} />
}

export default TotalPageValueCell
