import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { toneOfVoicePath } from 'app/navigation/paths'
import { sortBy } from 'lodash'

import BottomToolbar from 'common/bottomToolbar'
import Autocomplete from 'common/components/autcomplete'
import Button from 'common/components/button'
import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Scrollable from 'common/components/scrollable'
import Spinner from 'common/components/spinner'
import Widget from 'common/components/Widget'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch } from 'common/hooks/redux'

import useUpdateBrandTonesForBrandVoiceMutation from '../api/mutations/useUpdateBrandTonesForBrandVoiceMutation'
import useGetAllBrandTonesQuery from '../api/queries/useGetAllBrandTonesQuery'
import useGetBrandVoiceDetailsQuery from '../api/queries/useGetBrandVoiceDetailsQuery'
import {
  getTonesWithColorInfo,
  resetContent,
  Tone,
} from '../store/brandVoiceSlice'

import ToneCard from './toneOfVoice/components/ToneCard'

const MAX_USED_TONES = 5

const EditTones = () => {
  const { accountId, brandVoiceId } =
    useParams<{ accountId: string; brandVoiceId: string }>()
  const { data: brandVoice, status } = useGetBrandVoiceDetailsQuery(
    accountId,
    brandVoiceId
  )

  const isLoading = status === 'loading'

  const brandVoiceTones = getTonesWithColorInfo(brandVoice)
  const assignedToneIds = brandVoiceTones?.map((tone) => tone.id)

  useDocumentTitle('Edit Tone of Voice | Jacquard')

  const dispatch = useAppDispatch()

  const { data: brandTones } = useGetAllBrandTonesQuery()

  const [selectedToneIds, setSelectedToneIds] = useState<string[]>([])

  useEffect(() => {
    setSelectedToneIds(assignedToneIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedToneIds.length])

  const history = useHistory()

  const onDoneEditing = () => {
    history.push(
      generatePath(toneOfVoicePath, {
        accountId,
        brandVoiceId,
      })
    )
  }

  const hasBeenEdited =
    assignedToneIds.length !== selectedToneIds.length ||
    !assignedToneIds.every((assignedToneId) =>
      selectedToneIds.includes(assignedToneId)
    )

  const [searchValue, setSearchValue] = useState<string>('')

  const onSearchValue = (search: string) => {
    setSearchValue(search)
  }

  const filteredBrandTones = useMemo(() => {
    const filterByTitle = ({ title }: Tone) =>
      title
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchValue.replaceAll(' ', '').toLowerCase())

    return sortBy(brandTones?.filter(filterByTitle), ['title'])
  }, [searchValue, brandTones])

  const { mutate, isLoading: isSaving } =
    useUpdateBrandTonesForBrandVoiceMutation(
      onDoneEditing,
      accountId,
      brandVoiceId
    )

  if (isLoading) {
    return <Spinner isSpinning={isLoading} />
  }
  return (
    <Scrollable
      isFullHeight={false}
      content={
        <PageContainer className="max-w-318 sm:mx-auto px-6 pt-12 lg:pt-8">
          <PageHeader>
            <div
              data-cy="header"
              className="flex justify-between items-center w-full"
            >
              <div>
                <PageTitle title="Tone of Voice" />
                <div className="text-coolGray-400">
                  This lets Jacquard know what we should or should not use in
                  the generation of your content.
                </div>
              </div>
              <Autocomplete
                data-cy="search-tone"
                data-testid="search-tone"
                className="w-60"
                placeholder="Search"
                options={brandTones?.map((tone) => ({
                  label: tone.title,
                  value: tone.id,
                }))}
                onSearch={onSearchValue}
                backspaceRemovesValue={true}
              />
            </div>
          </PageHeader>
          <Widget type="basic" className="mt-6">
            {filteredBrandTones.length === 0 && searchValue !== '' ? (
              <div className="text-5xl font-bold text-coolGray-800 leading-normal">
                No tones match the search criteria
              </div>
            ) : undefined}
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
              {filteredBrandTones.map((tone) => {
                const isSelected = (id: string): boolean =>
                  selectedToneIds.includes(id)
                return (
                  <ToneCard
                    key={tone.id}
                    tone={tone}
                    isEditable={true}
                    isSelected={isSelected(tone.id)}
                    isDisabled={
                      !isSelected(tone.id) &&
                      selectedToneIds.length >= MAX_USED_TONES
                    }
                    onToggle={(id: string): void => {
                      let updatedToneIds: string[] = []
                      if (selectedToneIds.includes(id)) {
                        updatedToneIds = selectedToneIds.filter(
                          (toneId) => toneId !== id
                        )
                      } else {
                        updatedToneIds = [...selectedToneIds, id]
                      }
                      setSelectedToneIds(updatedToneIds)
                    }}
                  />
                )
              })}
            </div>
          </Widget>
          <Footer />
        </PageContainer>
      }
      footer={
        hasBeenEdited ? (
          <BottomToolbar className="justify-center">
            <div className="max-w-318 xl:max-w-363 w-full flex justify-between">
              <div className="flex justify-between">
                <Button
                  data-cy="cancel-selected-brand-tones-button"
                  variant="danger"
                  className="mr-4 pointer-events-auto"
                  ghost
                  disabled={isSaving}
                  onClick={onDoneEditing}
                >
                  Cancel
                </Button>
                <p>
                  <span className="block font-bold">
                    {selectedToneIds.length} of {MAX_USED_TONES} selected
                  </span>
                  <span className="block text-xs">
                    Select up to {MAX_USED_TONES} tones
                  </span>
                </p>
              </div>
              <div>
                <Button
                  data-cy="revert-selected-brand-tones-button"
                  variant="danger"
                  className="mr-4 pointer-events-auto"
                  ghost
                  disabled={isSaving}
                  onClick={() => {
                    setSelectedToneIds(assignedToneIds)
                    dispatch(resetContent())
                  }}
                >
                  Revert
                </Button>
                <Button
                  data-cy="save-selected-brand-tones-button"
                  variant="success"
                  ghost
                  loading={isSaving}
                  disabled={isSaving}
                  onClick={() => {
                    mutate({
                      accountId,
                      brandVoiceId,
                      brandToneIds: selectedToneIds,
                    })
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </BottomToolbar>
        ) : undefined
      }
    />
  )
}

export default EditTones
