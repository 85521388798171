import { ReactElement } from 'react'
import {
  Menu,
  MenuButton as MenuButtonRM,
  MenuButtonProps,
  MenuDivider,
  MenuHeader as MenuHeaderRM,
  MenuHeaderProps,
  MenuItem as MenuItemRM,
  MenuItemProps,
  MenuProps,
  SubMenu as SubMenuRM,
  SubMenuProps,
} from '@szhsin/react-menu'
import cx from 'classnames'

import '@szhsin/react-menu/dist/core.css'
import '@szhsin/react-menu/dist/index.css'
import styles from './MenuButton.module.css'

const menuItemClassName = cx(
  styles.menuItem,
  'h-10 text-coolGray-800 hover:bg-coolGray-100'
)

const MenuItem = (props: MenuItemProps) => (
  <MenuItemRM {...props} className={menuItemClassName} />
)

const SubMenu = (props: SubMenuProps) => (
  <SubMenuRM
    {...props}
    menuClassName="mt-2 mr-2 shadow-2xl border border-coolGray-200 "
    itemProps={{ className: menuItemClassName }}
    offsetY={-7}
  />
)

const MenuHeader = (props: MenuHeaderProps) => (
  <MenuHeaderRM {...props} className="text-xs normal-case" />
)

interface ButtonProps extends MenuButtonProps {
  'data-cy'?: string
}

type Props = {
  buttonProps?: ButtonProps
  options: ReactElement
} & Partial<MenuProps>

const MenuButton = ({ buttonProps, options, menuButton, ...rest }: Props) => {
  return (
    <Menu
      menuClassName={cx('shadow-2xl', styles.menu)}
      // TODO: replace all instances of buttonProps with menuButton
      menuButton={menuButton ? menuButton : <MenuButtonRM {...buttonProps} />}
      {...rest}
    >
      {options}
    </Menu>
  )
}

export { MenuDivider, MenuItem, SubMenu, MenuHeader }
export default MenuButton
