import { IntegrationLogsResponse } from '@phrasee/phrasee-typings/typings/integrations/logs'
import { useQuery } from '@tanstack/react-query'

import { getLogs } from '../api'
import { integrationKeys } from '../queryKeys'

const useGetLogsQuery = (contentId?: string) => {
  const getLogsQuery = useQuery<IntegrationLogsResponse>(
    integrationKeys.logs(contentId),
    () => getLogs({ contentId }),
    { enabled: !!contentId }
  )

  return getLogsQuery
}

export default useGetLogsQuery
