import Button from 'common/components/button/Button'
import Card from 'common/components/Card'

interface CreateExperimentCardProps {
  createExperiment: () => void
  isCreateButtonDisabled: boolean
  isLoading: boolean
}

export default function CreateExperimentCard({
  createExperiment,
  isCreateButtonDisabled,
  isLoading,
}: CreateExperimentCardProps) {
  return (
    <Card>
      <div className="font-bold mb-4">Create experiment</div>
      <div className="mb-4 text-coolGray-400">
        This will create the Experiment within your selected Cordial message.
        This will not schedule your message.
      </div>
      <div className="justify-end flex flex-wrap">
        <Button
          variant="primary"
          disabled={isCreateButtonDisabled}
          loading={isLoading}
          data-testid="create-variants-button"
          onClick={createExperiment}
        >
          Create experiment
        </Button>
      </div>
    </Card>
  )
}
