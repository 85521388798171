import FormItem from 'common/components/FormItem'
import Select from 'common/components/Select'
import { default as Option } from 'common/components/SelectOption'

export interface Props {
  className?: string
  errorMessage?: string
  selectValue?: string | number
  defaultOption?: string
  bottomText?: string
  placeholder?: string
  labelTitle: string
  isSelectDisabled?: boolean
  selectOptions: SelectOption[] | undefined
  dataTestId?: string
  isLoading?: boolean
  allowClear?: boolean
  onChange: (val: any) => void
}

export interface SelectOption {
  id: string | number
  name: string | number
}

export default function SelectComponent({
  className,
  errorMessage,
  labelTitle,
  isSelectDisabled,
  selectValue,
  selectOptions,
  defaultOption,
  dataTestId,
  bottomText,
  placeholder,
  isLoading = false,
  allowClear = true,
  onChange,
}: Props) {
  const selectFilter = (input: any, option: any): boolean => {
    return (
      option?.props?.children?.props?.children
        .toLowerCase()
        .indexOf(input?.toLowerCase()) >= 0
    )
  }

  return (
    <FormItem
      label={labelTitle}
      error={errorMessage}
      className={className}
      guidingText={bottomText}
    >
      <Select
        showSearch
        allowClear={allowClear}
        loading={isLoading}
        optionFilterProp="children"
        filterOption={selectFilter}
        disabled={isSelectDisabled}
        defaultValue={defaultOption}
        value={selectValue || defaultOption}
        onChange={(val: string | number) => onChange(val)}
        placeholder={placeholder}
        dataTestId={dataTestId}
      >
        {defaultOption ? (
          <Option
            key={defaultOption}
            value={defaultOption}
            label={defaultOption}
            disabled
          >
            <span>{defaultOption}</span>
          </Option>
        ) : undefined}
        {selectOptions &&
          selectOptions.map(({ id, name }) => (
            <Option value={id} key={id} label={name}>
              <span>{name}</span>
            </Option>
          ))}
      </Select>
    </FormItem>
  )
}
