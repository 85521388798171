import { useQuery } from '@tanstack/react-query'

import { getTeams, Team } from 'common/api/teamsApi'
import { contentKeys } from 'features/futurama/api/queryKeys'

const useSelectTeams = (accountId: string, contentId: string) => {
  const { data: teams } = useQuery(
    contentKeys.contentTeams(contentId, accountId),
    () => getTeams(accountId).then((res) => res.data),
    {
      select: (data: Team[]) => {
        return data.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      },
    }
  )

  return teams
}

export default useSelectTeams
