import { ComponentElement, Element } from '@phrasee/phrasee-typings'

import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'

import ElementCardIcon from '../../components/ElementCardIcon'

type Props = {
  element: Element
}

const IntegrationContent = ({ element }: Props) => {
  const name = element.display_name || element.name
  const componentElementVariants = isComponentElement(element)
    ? element.variants?.filter((variant) =>
        element.selected_variants?.includes(variant.variant_id)
      ) || []
    : []

  return (
    <>
      <span className="text-sm text-coolGray-800 font-medium">Content</span>
      <div className="mt-2 p-4 border border-coolGray-400 border-dashed">
        <div className="p-4 bg-coolGray-50">
          <div className="flex items-center">
            {element.icon && <ElementCardIcon icon={element.icon} size={6} />}
            <span className="ml-2 text-base text-coolGray-800 font-medium">
              {name}
            </span>
          </div>
          <hr className="my-4 text-coolGray-200 border-dashed" />
          {isComponentElement(element) &&
            componentElementVariants?.length > 0 && (
              <div className="text-coolGray-800 font-medium text-xs">
                Messaging
              </div>
            )}
          {isComponentElement(element) &&
            componentElementVariants?.map((variant) => (
              <span
                key={variant.variant_id}
                className="text-sm text-coolGray-600 whitespace-pre-line"
              >
                {variant.text}
              </span>
            ))}
          {isTemplateElement(element) &&
            element.elements.map((nestedElement) => {
              const variants =
                (nestedElement as ComponentElement).variants?.filter(
                  (variant) =>
                    (
                      nestedElement as ComponentElement
                    ).selected_variants?.includes(variant.variant_id)
                ) || []
              return (
                <div key={nestedElement.element_id}>
                  <div className="text-coolGray-800 font-medium text-xs">
                    {nestedElement.display_name || nestedElement.name}
                  </div>
                  {variants.map((variant) => (
                    <div
                      key={variant.variant_id}
                      className="text-sm text-coolGray-600 last:mb-4 whitespace-pre-line"
                    >
                      {variant.text}
                    </div>
                  ))}
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default IntegrationContent
