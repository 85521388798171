import { Content } from '@phrasee/phrasee-typings/typings/futurama/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { deleteVariant } from '../api'
import { isComponentElement } from '../interfaces'
import { contentKeys } from '../queryKeys'

type MutationContext = { previousContent: Content }

const useDeleteVariantMutation = (contentId) => {
  const queryClient = useQueryClient()

  return useMutation<
    void,
    unknown,
    {
      accountId: string
      contentId: string
      elementId: number
      variantId: number
    },
    MutationContext
  >(deleteVariant, {
    onMutate: async ({ contentId, elementId, variantId }) => {
      await queryClient.cancelQueries(contentKeys.content(contentId))

      const previousContent = queryClient.getQueryData<Content>(
        contentKeys.content(contentId)
      )

      queryClient.setQueryData<Content>(
        contentKeys.content(contentId),
        (old) => {
          if (old) {
            const elements = old?.elements
            const variants = elements
              ?.filter(isComponentElement)
              ?.find((element) => element.element_id === elementId)?.variants

            const updatedVariants = variants?.filter(
              (variant) => variant.variant_id !== variantId
            )

            const updatedElements = elements?.map((element) =>
              element.element_id === elementId
                ? { ...element, variants: updatedVariants }
                : element
            )
            return { ...old, elements: updatedElements }
          }
        }
      )

      return { previousContent } as MutationContext
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData<Content>(
        contentKeys.content(contentId),
        (old) => {
          if (!old) {
            return old
          }

          return context?.previousContent
        }
      )
      errorToast('Something went wrong. Please try again.')
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.content(contentId))
    },
  })
}

export default useDeleteVariantMutation
