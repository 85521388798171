import { Campaign as GraphQLResponseCampaigns } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { AxiosResponse } from 'axios'
import { gql } from 'graphql-request'

import { connectInternalApi, fetchGraphQL } from 'common/api'
import {
  BaseParams,
  ReportingLevel,
  ReportLeveledResponse,
} from 'common/api/reporting'

interface Sentiment {
  score: number
}

export interface Sentiments {
  appreciative: Sentiment | null
  curious: Sentiment | null
  excited: Sentiment | null
  helpful: Sentiment | null
  impressed: Sentiment | null
  surprising: Sentiment | null
  urgent: Sentiment | null
}

export interface Variant {
  campaign_id: string
  text: string
  send_date: string
  sentiments: Sentiments
}

export type VariantsResponse = Variant[]

export async function fetchCampaignsVariants({
  campaignIds,
  winnersOnly,
}: {
  campaignIds: string[]
  winnersOnly?: boolean
}): Promise<VariantsResponse> {
  const query = gql`
    query reportingVariants($filter: VariantFilter) {
      data: variants(filter: $filter) {
        campaign_id
        send_date
        text
        sentiments {
          appreciative {
            score
          }
          curious {
            score
          }
          excited {
            score
          }
          helpful {
            score
          }
          impressed {
            score
          }
          surprising {
            score
          }
          urgent {
            score
          }
        }
      }
    }
  `

  const variables = {
    filter: {
      campaignsList: campaignIds,
      ...(winnersOnly ? { is_winner: winnersOnly } : {}),
    },
  }

  return fetchGraphQL<VariantsResponse>({ query, variables })
}

export interface ResponseCampaign
  extends Pick<GraphQLResponseCampaigns, '_id' | 'name'> {
  _id: string
  name: string
  project_id: string
}

export interface CampaignsResponse {
  campaigns: ResponseCampaign[]
}

// eslint-disable-next-line max-params
export async function fetchCampaigns(
  projectId: string,
  startDate: string,
  endDate: string,
  accountId: string
): Promise<CampaignsResponse> {
  const query = gql`
    query reportingCampaigns(
      $filter: CampaignFilter
      $pageSize: Int
      $page: Int
    ) {
      data: campaigns(page: $page, pageSize: $pageSize, filter: $filter) {
        campaigns {
          _id
          name
          project_id
        }
      }
    }
  `

  const variables = {
    filter: {
      status: 'completed',
      projectId,
      startDate,
      endDate,
      distributionChannelList: ['email', 'push_notification', 'sms', 'adwords'],
      accountId,
    },
    // fetch all campaigns in one page
    pageSize: 100000,
    pageCount: 1,
  }

  return fetchGraphQL<CampaignsResponse>({ query, variables })
}

export const fetchWordPerformanceReport = ({
  accountId,
  projectIds,
  startDate,
  endDate,
  reportingLevel,
}: {
  accountId: string
  reportingLevel: ReportingLevel
} & BaseParams): Promise<AxiosResponse<ReportLeveledResponse>> => {
  return connectInternalApi.get(
    `v1/core/reporting/reporting/accounts/${accountId}/insights/word-performance/report`,
    {
      params: {
        project_ids: projectIds,
        start_date: startDate,
        end_date: endDate,
        reporting_level: reportingLevel,
      },
    }
  )
}

export type EmojiPerformanceReportResponse = {
  data: {
    period_label: string
    values: { name: string; values: { label: string; value: number }[] }[]
  }[]
}

export const fetchEmojiPerformanceReport = ({
  accountId,
  projectIds,
  startDate,
  endDate,
  reportingLevel,
}: {
  accountId: string
  reportingLevel: ReportingLevel
} & BaseParams): Promise<AxiosResponse<EmojiPerformanceReportResponse>> => {
  return connectInternalApi.get(
    `v1/core/reporting/reporting/accounts/${accountId}/insights/emoji-performance/report`,
    {
      params: {
        project_ids: projectIds,
        start_date: startDate,
        end_date: endDate,
        reporting_level: reportingLevel,
      },
    }
  )
}

export type OpenByLengthGraphDataResponse = {
  series: {
    name: string
    data: [number, number][]
  }[]
}

export const fetchOpenByLengthGraphData = ({
  accountId,
  projectIds,
  startDate,
  endDate,
}: {
  accountId: string
} & BaseParams): Promise<AxiosResponse<OpenByLengthGraphDataResponse>> => {
  return connectInternalApi.get(
    `v1/core/reporting/reporting/accounts/${accountId}/insights/variant-length/report/graph-data`,
    {
      params: {
        project_ids: projectIds,
        start_date: startDate,
        end_date: endDate,
      },
    }
  )
}
