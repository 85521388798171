import { ProjectOptionsConfig } from '../interface'

type ProjectOptionsParams = ProjectOptionsConfig & {
  accountId: string
}

export const projectKeys = {
  projects: (accountId: string) => ['projectsNav', accountId] as const,
  projectTypeOptions: (accountId: string) =>
    ['projectTypeOptions', accountId] as const,
  projectOptions: (params: ProjectOptionsParams) =>
    ['projectOptions', { ...params }] as const,
  projectUsers: (accountId: string) => ['projectUsers', accountId] as const,
  projectConfig: ({
    accountId,
    projectId,
  }: {
    accountId: string
    projectId: string
  }) => ['projectConfig', accountId, projectId] as const,
}
