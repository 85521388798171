import LanguageApproval from 'workflow/LanguageApproval/LanguageApproval'

import { useAppSelector } from 'common/hooks/redux'

type Props = {
  containerElement: HTMLDivElement
}

const ContentApproval = ({ containerElement }: Props) => {
  const campaignData = useAppSelector(
    (state) => state.campaignStates.campaignData
  )

  return (
    <div className="mt-8">
      <div className="font-medium text-xl text-gray-800">Content approval</div>
      <div className="font-normal text-sm text-gray-500 mt-1">
        This is your optimised content created by Jacquard.
      </div>
      <LanguageApproval
        campaignData={campaignData}
        type="linguo"
        actionBarContainerElement={containerElement}
      />
    </div>
  )
}

export default ContentApproval
