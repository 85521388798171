import { useQuery } from '@tanstack/react-query'

import { getCustomerAttributes } from '../api'
import { keys } from '../queryKeys'

export type CustomerAttributes = {
  fileName: string
  type: string
  created: string
  columns: string[]
  data: Object[]
}

const useGetCustomerAttributesQuery = ({
  baseUrl,
  accountId,
}: {
  baseUrl: string
  accountId: string
}) => {
  const getCustomerAttributesQuery = useQuery(
    keys.customerAttributes(accountId),
    () => getCustomerAttributes({ accountId, baseUrl }),
    {
      enabled: !!accountId,
      retry: false,
      select: (data): CustomerAttributes => {
        const columns =
          data.result.length > 0 ? Object.keys(data.result[0]) : []
        const columnsOrdered = [
          'customer_id',
          ...columns.filter((column) => column !== 'customer_id'),
        ]
        return {
          fileName: data.fileName,
          type: data.type,
          created: data.created,
          columns: columnsOrdered,
          data: data.result,
        }
      },
      meta: {
        errorMessage: 'The customer attributes could not be loaded',
      },
    }
  )

  return getCustomerAttributesQuery
}

export default useGetCustomerAttributesQuery
