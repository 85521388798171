import { generatePath, useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import { getAllElements } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import useVariantsToApproveCountQuery from '../../contentPage/hooks/useVariantsToApproveCountQuery'
import { approveElement } from '../api'
import { isComponentElement } from '../interfaces'
import { contentKeys } from '../queryKeys'

const useApproveElementMutation = ({ redirectTo }: { redirectTo: string }) => {
  const dispatch = useAppDispatch()
  const { invalidateAll: invalidateAllVariantsToApproveCountQuery } =
    useVariantsToApproveCountQuery()
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation(approveElement, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
      invalidateAllVariantsToApproveCountQuery()

      const allComponentElements =
        getAllElements(content).filter(isComponentElement)

      const notApprovedElements = allComponentElements.filter(
        (element) => !element.approved
      )

      if (notApprovedElements.length > 0) {
        dispatch(
          selectElement({ elementId: notApprovedElements[0].element_id })
        )
      } else {
        history.push(generatePath(redirectTo, { contentId: content?._id }))
      }
    },
  })
}

export default useApproveElementMutation
