import { useState } from 'react'
import { generatePath, useHistory } from 'react-router'
import { selectedCampaignPath } from 'app/navigation/paths'
import { showBanner } from 'workflow/common/common.actions'
import apiUtil from 'workflow/utils/Api'

import ConfirmationCard from 'common/components/ConfirmationCard'
import Modal from 'common/components/Modal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { addNewCampaign } from 'features/campaigns/store/campaignSlice'

import { Campaign } from '../../api/interface'

type Props = {
  isCampaignCloningModalOpen: boolean
  onClose: () => void
  campaignId: string
}

const CloningModal: React.FC<Props> = ({
  isCampaignCloningModalOpen,
  onClose,
  campaignId,
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const campaigns = useAppSelector((state) => state.campaigns.campaigns)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const initialCampaign = campaigns.find((camp) => camp.id === campaignId)
  const initialCampaignIndex = campaigns.findIndex(
    (camp) => camp.id === campaignId
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const cloneCampaign = () => {
    if (!initialCampaign) {
      dispatch(
        showBanner({
          type: 'error',
          content: 'Experiment not found!',
        })
      )
      return onClose()
    }

    const path: string = `campaigns/${campaignId}/clone`

    setIsLoading(true)

    return apiUtil(path, {
      method: 'POST',
    })
      .then(async (response) => {
        const campaignResponse = response.data
        const clonedCampaignId: string = campaignResponse._id

        const clonedCampaign: Campaign = {
          id: clonedCampaignId,
          created: campaignResponse.created,
          distributionChannel:
            campaignResponse.campaign_configuration.distribution_channel,
          name: campaignResponse.name,
          grammarId: campaignResponse.grammar_id,
          projectId: campaignResponse.project_id,
          projectName: initialCampaign.projectName,
          status: initialCampaign.status,
          userId: campaignResponse.user_id,
          sent: initialCampaign.sent,
          user: initialCampaign.user,
          isMismatchingConfiguration:
            initialCampaign.isMismatchingConfiguration,
        }

        dispatch(
          addNewCampaign({
            campaign: clonedCampaign,
            indexAt: initialCampaignIndex,
          })
        )
        dispatch(
          showBanner({
            type: 'success',
            content: 'Experiment cloned successfully',
          })
        )

        // avoid banner flickering and make smoother transition to cloned campaign route
        setTimeout(() => {
          history.push(
            generatePath(selectedCampaignPath, {
              accountId,
              campaignId: clonedCampaignId,
            })
          )
        }, 500)
      })
      .catch((error) =>
        dispatch(
          showBanner({
            type: 'error',
            content: error.message,
          })
        )
      )
      .finally(() => {
        setIsLoading(false)
        onClose()
      })
  }

  return (
    <Modal
      centered
      closable={false}
      footer={null}
      hasMargin={false}
      visible={isCampaignCloningModalOpen}
      bodyStyle={{ width: 520 }}
    >
      <ConfirmationCard
        isDisabled={false}
        cardText="Are you sure you want to clone this experiment?"
        cardTitle="Clone experiment"
        buttonText="Confirm"
        rejectButtonText="Cancel"
        isLoading={isLoading}
        onSubmit={() => cloneCampaign()}
        onReject={onClose}
      />
    </Modal>
  )
}

export default CloningModal
