import { useEffect } from 'react'

import Alert from 'common/components/alert'
import Button from 'common/components/button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { AlertCircle, Close as CloseIcon } from 'common/icons'
import { toggleDeleteDefaultBrandVoiceAlertVisibility } from 'features/admin/brandVoice/store/brandVoiceSlice'

const DeletingDefaultBrandVoiceAlert = () => {
  const dispatch = useAppDispatch()
  const isDeleteDefaultBrandVoiceAlertVisible = useAppSelector(
    (state) => state.brandVoice.isDeleteDefaultBrandVoiceAlertVisible
  )
  useEffect(
    () => () => {
      dispatch(toggleDeleteDefaultBrandVoiceAlertVisibility(false))
    },
    [dispatch]
  )

  if (!isDeleteDefaultBrandVoiceAlertVisible) {
    return null
  }

  return (
    <Alert type="error" className="mt-2 mb-8 rounded">
      <div
        className="mb-1 flex items-center"
        data-testid="deletingDefaultBrandVoiceAlert"
      >
        <AlertCircle
          isDefaultColor={false}
          className="text-RAGred mr-2"
          size={5}
        />
        <span className="text-white font-normal text-base">
          Deleting your default Brand Voice
        </span>
        <Button
          variant="icon"
          size="small"
          className="ml-auto -mt-1"
          onClick={() =>
            dispatch(toggleDeleteDefaultBrandVoiceAlertVisibility(false))
          }
          prefixIcon={
            <CloseIcon
              isDefaultColor={false}
              className="text-white mr-2"
              size={4}
            />
          }
        />
      </div>
      <div className="text-white font-normal text-xs mx-6">
        As this is marked as your default Brand Voice, it cannot be deleted
        until you nominate another Brand voice as your default.
      </div>
    </Alert>
  )
}

export default DeletingDefaultBrandVoiceAlert
