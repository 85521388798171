import { useMutation, useQueryClient } from '@tanstack/react-query'

import { contentKeys } from '../queryKeys'
import { createPhrasesBulk } from '..'

const useBulkCreatePhraseMutation = (selectedNodeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(createPhrasesBulk, {
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.phrases(selectedNodeId))
    },
  })
}

export default useBulkCreatePhraseMutation
