import { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { selectedCampaignPath } from 'app/navigation/paths'

import { connectInternalApi } from 'common/api'
import * as GraphWidget from 'common/components/GraphWidget'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { InfoAction } from 'common/components/WidgetHeader'
import { WidgetError } from 'common/components/WidgetMessage'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import ReactPerformanceGraph, {
  ReactPerformanceResult,
} from './ReactPerformanceGraph'
import ReactVariantsWidget from './ReactVariantsWidget'

interface Props {
  title: string
  accountId: string
  campaignId: string
}

const ReactPerformanceWidget = ({ title, accountId, campaignId }: Props) => {
  const [results, setResults] = useState<ReactPerformanceResult[]>([])
  const history = useHistory()
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { chart, chartRefCallback } = useChartRefCallback()

  useEffect(() => {
    function fetchData() {
      return connectInternalApi.get<{
        results: { click_rate: number; open_rate: number; day: string }[]
      }>(
        `v1/core/reporting/reporting/campaigns/${campaignId}/data/daily-performance`,
        {
          params: { data_filter: 'mature' },
        }
      )
    }

    setHasError(false)
    fetchData()
      .then((graphResponse) => {
        const { results } = graphResponse.data

        setResults(results)
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, campaignId])

  const hasResults = !hasError && !isLoading

  return (
    <>
      <GraphWidget.Widget
        className="pt-6 pl-6"
        type="inner"
        title={
          <div>
            {title}
            <button
              className="ml-9 text-maroon-500 text-sm"
              onClick={() => {
                history.push(
                  generatePath(selectedCampaignPath, {
                    accountId,
                    campaignId,
                  })
                )
              }}
            >
              View experiment
            </button>
          </div>
        }
        actions={
          hasResults && (
            <div className="inline-flex">
              <ChartWidgetMenu chart={chart} title={title} />
              <InfoAction
                message={`This is a quick snapshot of your live 
                experiments. View their daily open and click rate performance 
                and real-time language updates.`}
              />
            </div>
          )
        }
      >
        <div className="flex flex-col sm:flex-row">
          <div className="flex-auto">
            {isLoading && (
              <WidgetLoader data-cy="react-performance-widget-loader" />
            )}
            {hasError && <WidgetError className="mb-6" />}
            {hasResults ? (
              <ReactPerformanceGraph results={results} ref={chartRefCallback} />
            ) : undefined}
          </div>

          <ReactVariantsWidget campaignId={campaignId} />
        </div>
      </GraphWidget.Widget>
    </>
  )
}

export default ReactPerformanceWidget
