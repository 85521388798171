import { Campaign } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'

import { AirshipExperiment } from './types'

interface SaveIntegrationDataPayload {
  experimentId: string
  pushId: string
}

export async function getAirshipExperiments(projectId: string) {
  return connectInternalApi.get<AirshipExperiment[]>(
    `v1/integrations/airship/projects/${projectId}/experiments`
  )
}

export async function saveAirshipCampaignIntegrationData(
  projectId: string,
  campaignId: string,
  data: SaveIntegrationDataPayload
) {
  return connectInternalApi.post<Campaign>(
    `v1/integrations/airship/projects/${projectId}/campaigns/${campaignId}/initialize`,
    data
  )
}

export async function getAirshipExperimentResults(
  projectId: string,
  campaignId: string
) {
  return connectInternalApi.post<boolean>(
    `v1/integrations/airship/projects/${projectId}/campaigns/${campaignId}/sync-results`
  )
}
