import { Content } from '@phrasee/phrasee-typings/typings/futurama/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { selectVariant } from '../api'
import { isComponentElement, isTemplateElement } from '../interfaces'
import { contentKeys } from '../queryKeys'

type MutationContext = { previousContent: Content }

const useSelectVariantMutation = (contentId: string | undefined) => {
  const queryClient = useQueryClient()

  return useMutation<
    Content,
    unknown,
    {
      accountId: string
      contentId: string
      elementId: number
      variantId: number
    },
    MutationContext
  >(selectVariant, {
    onMutate: async ({ contentId, elementId, variantId }) => {
      await queryClient.cancelQueries(contentKeys.content(contentId))

      const previousContent = queryClient.getQueryData<Content>(
        contentKeys.content(contentId)
      )

      queryClient.setQueryData<Content>(
        contentKeys.content(contentId),
        (old) => {
          if (old) {
            const updatedElements = old?.elements?.map((element) => {
              if (isTemplateElement(element)) {
                const componentElements = element.elements
                  .filter(isComponentElement)
                  .map((componentElement) =>
                    componentElement.element_id === elementId
                      ? { ...componentElement, selected_variants: [variantId] }
                      : componentElement
                  )

                return { ...element, elements: componentElements }
              }

              if (element.element_id === elementId) {
                return { ...element, selected_variants: [variantId] }
              }

              return element
            })

            return { ...old, elements: updatedElements }
          }
        }
      )
      return { previousContent } as MutationContext
    },
    onError: (error, variables, context) => {
      if (contentId) {
        queryClient.setQueryData<Content>(
          contentKeys.content(contentId),
          (old) => {
            if (!old) {
              return old
            }

            return context?.previousContent
          }
        )
      }
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (content) => {
      if (contentId) {
        queryClient.setQueryData(contentKeys.content(contentId), content)
      }
    },
  })
}

export default useSelectVariantMutation
