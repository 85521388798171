import { TableInstance } from '..'

import CurrencyCell from './Currency'
import { getAverageCurrencyPerPage } from './helpers'

const AverageCurrencyPerPage = <T extends object>(props: TableInstance<T>) => {
  const averageValue = getAverageCurrencyPerPage(props)

  return <CurrencyCell value={averageValue} />
}

export default AverageCurrencyPerPage
