import { ConnectContent } from '@phrasee/phrasee-typings'

import LoadingOverlay from 'common/components/LoadingOverlay'
import Spinner from 'common/components/spinner'
import { useAppSelector } from 'common/hooks/redux'
import useListAccountsQuery from 'features/admin/accountSettings/api/queries/useListAccountsQuery'

import { useContent, useSelectedElement } from '../../hooks'

import useConnectContentMutation from './api/mutations/useConnectContentMutation'
import useGetLogsQuery from './api/queries/useGetLogsQuery'
import IntegrationAssets from './IntegrationAssets'
import IntegrationContent from './IntegrationContent'
import IntegrationSelection from './IntegrationSelection'
import MultipleIntegrations from './MultipleIntegrations'
import NoIntegrations from './NoIntegrations'

const IntegrationSection = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { content } = useContent()
  const { data: accounts, isLoading } = useListAccountsQuery(accountId)
  const { refetch: refetchLogs } = useGetLogsQuery(content?._id)

  const connectContentMutation = useConnectContentMutation()

  const { data: selectedElement } = useSelectedElement()

  if (!selectedElement) {
    return null
  }

  const elementIntegrationAccountId = selectedElement.integration_account_id

  const selectedIntegrationAccount =
    accounts?.find((account) => account.id === elementIntegrationAccountId) ||
    (accounts?.length === 1 ? accounts[0] : undefined)

  const selectedElementName =
    selectedElement.display_name || selectedElement.name

  const onConnect = async (connectContent: ConnectContent[]) => {
    if (content && selectedIntegrationAccount) {
      const response = await connectContentMutation.mutateAsync({
        accountId,
        integrationAccountId: selectedIntegrationAccount.id,
        contentId: content._id,
        elementId: selectedElement.element_id,
        content: connectContent,
      })

      refetchLogs()
      if (response.failures?.length) {
        const errors = {}
        response.failures.forEach((failure) => {
          errors[`element-${failure.elementId}`] =
            'Connection failed. Change the asset name and try again.'
        })

        return errors
      }
    }
  }

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (selectedIntegrationAccount) {
      return 'single'
    } else if (accounts && accounts.length > 1) {
      return 'multiple'
    } else {
      return 'empty'
    }
  }

  const state = getState()

  return (
    <div
      data-testid="integrationSection"
      className="p-6 border border-coolGray-300 bg-white flex-1 self-start relative"
    >
      <LoadingOverlay
        title="Connecting"
        isLoading={connectContentMutation.isLoading}
        phrases={['Preparing your content...']}
        overlayType="local"
      />
      <h5 className="text-xl text-coolGray-800 font-medium mb-4">
        Integration: {selectedElementName}
      </h5>
      {
        {
          loading: <Spinner />,
          single: (
            <IntegrationSelection
              account={selectedIntegrationAccount!}
              accounts={accounts!}
              contentId={content!._id}
              elementId={selectedElement.element_id}
            />
          ),
          multiple: (
            <MultipleIntegrations
              accounts={accounts!}
              contentId={content!._id}
              elementId={selectedElement.element_id}
            />
          ),
          empty: <NoIntegrations />,
        }[state]
      }
      <hr className="my-8 text-coolGray-200" />
      <IntegrationContent element={selectedElement} />
      <IntegrationAssets
        key={selectedElement.element_id}
        element={selectedElement}
        onConnectClick={onConnect}
        isDisabled={!selectedIntegrationAccount}
      />
    </div>
  )
}

export default IntegrationSection
