import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import LogRocket from 'logrocket'

import reducers from './rootReducer'

export const generateStore = () =>
  configureStore({
    ...reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: true,
        immutableCheck: true,
      }).concat(
        LogRocket.reduxMiddleware() as ReturnType<typeof getDefaultMiddleware>
      ),
  })

export const store = generateStore()

export type Store = typeof store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
