import { FC, useEffect, useState } from 'react'
import cx from 'classnames'

import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'
import Steps, { Step } from 'common/components/Steps'
import { formatDate } from 'common/helpers/date'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as Cancel } from 'common/icons/cancel/default.svg'
import { ReactComponent as Complete } from 'common/icons/complete/default.svg'
import { ReactComponent as SpinnerIcon } from 'common/icons/spinner/default.svg'
import { ReactComponent as Tick } from 'common/icons/tick/default.svg'
import { ReactComponent as Time } from 'common/icons/time/default.svg'

import {
  checkBeforeCancel as checkStatus,
  resetAcousticData,
} from '../../Workflow.actions'

import { isUncheduledOnAcoustic } from './helpers'

interface Props {
  campaignProgress: any
}

const AcousticStatus: FC<Props> = ({ campaignProgress }) => {
  const dispatch = useAppDispatch()

  const [hasLoadedInitialStatus, setHasLoadedInitialStatus] =
    useState<boolean>(false)

  const { selectedCampaignId } = useAppSelector((state) => state.campaigns)
  const {
    inputResultsAt,
    mailingExists,
    splitsSent,
    campaignData,
    steps,
    isWaitingState,
  } = useAppSelector((state) => state.campaignStates)

  const {
    final_send_cancelled,
    test_results_received,
    test_results_manually_entered,
    test_schedule_started,
    test_scheduled,
  } = campaignProgress
  const { tobesent: autoDeploy } = campaignData

  const error = steps.find(({ status }) => status === 'error')
  const isCompleted =
    (test_results_received || test_results_manually_entered) && inputResultsAt
  const unscheduledOnAcoustic = isUncheduledOnAcoustic(
    campaignProgress,
    splitsSent,
    mailingExists
  )
  const isFinalMailingCancelled = final_send_cancelled && autoDeploy
  const isCancelled = isFinalMailingCancelled && unscheduledOnAcoustic

  useEffect(() => {
    if (
      !hasLoadedInitialStatus &&
      isWaitingState.isWaitingFor === 'checkBeforeCancel' &&
      !isWaitingState.isWaiting
    ) {
      setHasLoadedInitialStatus(true)
    }
  }, [
    isWaitingState.isWaiting,
    isWaitingState.isWaitingFor,
    hasLoadedInitialStatus,
  ])

  useEffect(() => {
    const fetchStatus = () => {
      if (selectedCampaignId) {
        dispatch(checkStatus(selectedCampaignId))
      }
    }

    if (test_schedule_started) {
      fetchStatus()

      const timer = setInterval(() => {
        fetchStatus()
      }, 5000)

      return () => clearTimeout(timer)
    } else if (
      typeof splitsSent !== 'undefined' &&
      typeof mailingExists !== 'undefined'
    ) {
      // fixes disabled drawer inputs when creating a campaign
      // if an acoustic campaign was selected previously
      dispatch(resetAcousticData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCampaignId, test_schedule_started])

  const iconStyle = 'inline-block h-6 w-6 min-w-6'

  if (!hasLoadedInitialStatus && test_schedule_started) {
    // fixes showing wrong status while initial checkBeforeCancel hasn't loaded
    return <Spinner />
  } else if (error) {
    return (
      <Alert type="error">
        <span className="font-bold">{error.title} error</span> -{' '}
        {error.description}
      </Alert>
    )
  } else if (splitsSent && isCompleted) {
    return (
      <Alert type="info">
        <div className="flex items-center">
          <Complete className={cx(iconStyle, 'mr-4')} />
          <span>
            <span className="font-bold">Experiment complete</span> - This
            experiment was completed on{' '}
            {formatDate(inputResultsAt, 'd MMMM yyyy')}.
          </span>
        </div>
      </Alert>
    )
  } else if (isCancelled) {
    return (
      <Alert type="info">
        <div className="flex items-center">
          <Cancel className={cx(iconStyle, 'mr-4')} />
          <span>
            <span className="font-bold">Experiment cancelled</span> - Final
            mailing cancelled and splits have been unscheduled on Acoustic. You
            can make changes and then resubmit the schedule.
          </span>
        </div>
      </Alert>
    )
  } else if (unscheduledOnAcoustic) {
    return (
      <Alert type="warn">
        <div>The splits have been unscheduled on Acoustic.</div>
        <div>You can make changes and then resubmit the schedule.</div>
      </Alert>
    )
  } else if (isFinalMailingCancelled) {
    return (
      <Alert type="info">
        <div className="flex items-center">
          <Cancel className={cx(iconStyle, 'mr-4')} />
          <span>
            <span className="font-bold">Cancelled the final mailing</span> -
            This experiment is currently scheduled to collect data from
            Acoustic.
          </span>
        </div>
      </Alert>
    )
  } else if (test_schedule_started && !test_scheduled) {
    return (
      <Alert type="success">
        <Steps>
          <Step
            className="text-white"
            title="Jacquard setting up experiment on Acoustic"
            icon={<SpinnerIcon className={cx(iconStyle, 'text-white')} />}
          />
          <Step
            className="text-white"
            title="Jacquard experiment scheduled"
            icon={<Time className={cx(iconStyle, 'text-white')} />}
          />
        </Steps>
        <div>
          You can leave and come back later once the experiment is setup. We
          will pop you over an email once it's ready.
        </div>
      </Alert>
    )
  } else if (mailingExists && !splitsSent && !isCompleted && !isCancelled) {
    return (
      <Alert type="success">
        <div>
          <div className="mb-4 flex items-center">
            <Tick className={cx(iconStyle, 'mr-4')} />
            <span>Jacquard experiment scheduled.</span>
          </div>

          <div>
            This experiment is currently scheduled to collect data from
            Acoustic.
          </div>
        </div>
        <div className="mt-4 mb-2 font-bold">
          To make changes to the experiment:
        </div>
        <ol className="pl-4 list-decimal">
          <li className="pl-3">
            Go to your Acoustic account and cancel the current schedule.
          </li>
          <li className="pl-3">
            Return to this screen and make your edits. Try refreshing your
            screen if the fields aren’t accessible.
          </li>
          <li className="pl-3">Resubmit the schedule.</li>
        </ol>
      </Alert>
    )
  } else if (mailingExists && splitsSent && !isCompleted && !isCancelled) {
    return (
      <Alert type="success">
        <div className="mb-4 flex items-center">
          <Tick className={cx(iconStyle, 'mr-4')} />
          <span>Jacquard experiment sent.</span>
        </div>
        <div>
          This experiment is currently saved and collecting data from Acoustic.
        </div>
      </Alert>
    )
  } else {
    return null
  }
}

export default AcousticStatus
