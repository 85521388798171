import { ReactNode } from 'react'

import {
  incrementalOpen,
  incrementalOpenMachineExcluded,
  openUplift,
  openUpliftMachineExcluded,
} from '../constants'

import Cell from './Cell'

type Props = {
  reports: [string, string | number | null][]
  children: ReactNode
}

const PerformanceReport = ({ reports, children }: Props) => {
  return (
    <>
      {reports.map(([key, value]) => {
        let formattedValue = value?.toLocaleString('en') || 'NA'

        const isUpliftValue = key.toLowerCase().includes('uplift')
        if (isUpliftValue && formattedValue !== 'NA') {
          formattedValue = `${formattedValue}%`
        }
        if (key === openUpliftMachineExcluded) {
          key = openUplift
        }
        if (key === incrementalOpenMachineExcluded) {
          key = incrementalOpen
        }
        return (
          <Cell
            key={key}
            title={key}
            value={formattedValue}
            valueClassName="text-gray-800"
          />
        )
      })}
      {children}
    </>
  )
}

export default PerformanceReport
