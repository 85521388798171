import { Content } from '@phrasee/phrasee-typings'
import isEmpty from 'lodash/isEmpty'

import { parseNode, parseReplacer } from 'common/components/topics/helpers'
import { Topic } from 'common/components/topics/interfaces'
import useGetContentQuery from 'features/futurama/api/queries/useGetContentQuery'

const useTopics = (contentId?: string) => {
  return useGetContentQuery<Topic[]>(contentId, {
    select: (content: Content) => {
      const topics: Topic[] = []

      if (
        !isEmpty(content.user_input.nodes) ||
        !isEmpty(content.user_input.replacers)
      ) {
        content.user_input.nodes?.forEach((node) => {
          topics.push(parseNode(node))
        })
        content.user_input.replacers?.forEach((replacer) => {
          topics.push(parseReplacer(replacer))
        })
      } else {
        content.user_input.entities?.forEach((entity) => {
          if (entity.nodes) {
            entity.nodes.forEach((node) => {
              topics.push(parseNode(node))
            })
          }
          if (entity.replacer) {
            topics.push(parseReplacer(entity.replacer))
          }
        })
      }
      return topics
    },
  })
}

export default useTopics
