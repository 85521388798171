import HoldingPage from 'common/components/holdingPages/Page'

type Props = {
  title: string
}

const Welcome = ({ title }: Props) => {
  return (
    <HoldingPage className="max-w-363">
      <HoldingPage.Title>{title}</HoldingPage.Title>
      <h2 className="mt-12 text-xl max-w-212">
        <p>
          Reporting works best with lots of data. If the selected period has
          fewer than 15 experiments it might not tell the full story and it
          might be slightly skewed.
        </p>
        <p className="mt-10">
          Remember! What works for one audience, or for a particular time of
          year, might not be what works forever. The key to keeping your results
          high is to continue testing!
        </p>
      </h2>
    </HoldingPage>
  )
}

export default Welcome
