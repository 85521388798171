import { useEffect, useState } from 'react'
import moment from 'moment'

import { createCancelTokenSource } from 'common/api'
import { ReportUpliftLeveledItem } from 'common/api/reporting'
import { useAppSelector } from 'common/hooks/redux'
import { fetchCampaigns } from 'features/campaigns/api'

import {
  ProjectSummaryResponse,
  useActiveProjects,
  useGetBroadcastMetricsQuery,
  useGetTriggerMetricsQuery,
} from '../../api'
import { useGetMetrics } from '../../hooks/useGetMetrics'

import KPICard from './KPICard'

export type CampaignTypes = 'broadcast' | 'trigger'

interface Props {
  accountId: string
  campaignType: CampaignTypes
}

const calculateMetric = (data: ProjectSummaryResponse, metricName: string) => {
  const results = data?.email_projects?.reduce((acc, currentValue) => {
    if (currentValue[metricName]) {
      acc += currentValue[metricName]
    }
    return acc
  }, 0)
  return results
}

const campaignTypes = {
  broadcast: {
    distributionType: 'broadcast',
    metricsQuery: useGetBroadcastMetricsQuery,
  },
  trigger: {
    distributionType: 'AlwaysOn',
    metricsQuery: useGetTriggerMetricsQuery,
  },
}

const CampaignMetrics = ({ accountId, campaignType }: Props) => {
  const { dashboardMode } = useAppSelector((state) => state.authStates)
  const source = createCancelTokenSource()

  const { data: activeProjectsData, hasGQLError: activeProjectsError } =
    useActiveProjects(
      accountId,
      source,
      campaignTypes[campaignType].distributionType
    )

  const activeProjectIds = activeProjectsData ?? []

  const { queryData, hasApiError } = useGetMetrics({
    accountId,
    dashboardMode,
    activeProjectIds,
    getMetricsQuery: campaignTypes[campaignType].metricsQuery,
  })

  const [results, setResults] = useState({
    totalIncrementalOpens: 0,
    totalIncrementalClicks: 0,
    totalCampaigns: 0,
    averageOpensUplift: 0,
    averageClicksUplift: 0,
  })

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (queryData) {
      const data = queryData.data.data
      if (!isLoading) {
        setIsLoading(true)
      }
      if (Object.keys(data).length === 0) {
        setIsLoading(false)
      } else if ('email_projects' in data && Object.keys(data).length > 0) {
        const newResults = {
          ...results,
          totalIncrementalOpens: calculateMetric(data, 'incremental_opens'),
          totalIncrementalClicks: calculateMetric(data, 'incremental_clicks'),
          totalCampaigns: calculateMetric(data, 'number_of_campaigns'),
        }
        setResults(newResults)
        setIsLoading(false)
      } else if (Array.isArray(data)) {
        fetchCampaigns({
          variables: {
            page: 1,
            pageSize: 10,
            filter: {
              startDate: moment().subtract(12, 'months').toISOString(),
              endDate: moment().toISOString(),
              accountId,
              projectsList: activeProjectIds,
            },
          },
          source,
        })
          .then((res) => {
            const foundEntry = (data as ReportUpliftLeveledItem[]).find(
              (entry) => entry.period_label
            )
            if (foundEntry) {
              const upliftsObject = foundEntry.values.find(
                (value) => value.name === 'Average'
              )

              if (upliftsObject) {
                const emailOpens = upliftsObject.values.find(
                  (value) => value.label === 'Email Opens'
                )
                const emailClicks = upliftsObject.values.find(
                  (value) => value.label === 'Email Clicks'
                )
                const newResults = {
                  ...results,
                  averageOpensUplift: emailOpens?.value || 0,
                  averageClicksUplift: emailClicks?.value || 0,
                  totalCampaigns: res.count,
                }
                setResults(newResults)
                setIsLoading(false)
              }
            }
            setIsLoading(false)
          })
          .catch(() => {
            setIsLoading(false)
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData])

  const hasError = activeProjectsError || hasApiError
  const totalProjects = activeProjectIds ? activeProjectIds.length : 0

  return (
    <KPICard
      campaignType={campaignType}
      hasError={hasError}
      isLoading={isLoading}
      totalProjects={totalProjects}
      totalIncrementalOpens={results.totalIncrementalOpens}
      totalIncrementalClicks={results.totalIncrementalClicks}
      totalCampaigns={results.totalCampaigns}
      averageClicksUplift={results.averageClicksUplift}
      averageOpensUplift={results.averageOpensUplift}
    />
  )
}

export default CampaignMetrics
