import { StylesConfig } from 'react-select'

import { SelectValue } from 'common/components/BaseSelect'

export const selectCustomStyles: StylesConfig<SelectValue, false> = {
  control: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      height: 'auto',
    }
  },
}
