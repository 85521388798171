import { ChangeEvent, FocusEventHandler } from 'react'
import cx from 'classnames'

import Input from 'common/components/Input'

type Props = {
  value: string
  type?: 'email' | 'password' | 'tel' | 'text'

  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
  hasError?: boolean
  disabled?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  ['data-cy']: string
}

function ProfileInput({
  value,
  type = 'text',
  hasError,
  disabled,
  handleChange,
  onBlur,
  onFocus,
  'data-cy': dataCy,
}: Props) {
  return (
    <Input
      type={type}
      data-cy={dataCy}
      className={cx(
        `h-10 w-full px-3 py-2 text-sm text-coolGray-800 caret-maroon-300 mt-1
     placeholder-sm placeholder-coolGray-800 placeholder-opacity-50 font-medium
     border border-blueGray-300 bg-coolGray-50 focus:outline-none 
     focus:bg-opacity-10 disabled:text-coolGray-400 disabled:bg-coolGray-100`,
        {
          [`focus:placeholder-maroon-300
            focus:border-maroon-500`]: !hasError,
          [`focus:text-red-400 focus:placeholder-red-400
            focus:border-red-400 `]: hasError,
        }
      )}
      value={value}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    />
  )
}
export default ProfileInput
