import React, { Fragment } from 'react'
import cx from 'classnames'
import RCTooltip from 'rc-tooltip'

import 'rc-tooltip/assets/bootstrap.css'
import styles from './Tooltip.module.css'

type Props = {
  show?: boolean
}
type RCTooltipProps = React.ComponentPropsWithRef<typeof RCTooltip>

const Tooltip: React.FC<Props & RCTooltipProps> = ({
  show = true,
  placement = 'top',
  children,
  overlayClassName,
  ...props
}) => {
  const child = React.Children.only(children)

  // Rc-tooltip requires a child node that accepts an onMouseEnter,onMouseLeave,
  // onFocus, onClick event. Due to that, if the child node is not a native dom
  // element, we wrap it with a div. In future iterations we could pass the
  // wrapper type as a prop.
  const tooltipChild =
    React.isValidElement(child) && typeof child.type === 'string' ? (
      child
    ) : (
      <div>{child}</div>
    )

  return (
    <Fragment>
      {show ? (
        <RCTooltip
          placement={placement}
          overlayClassName={cx(styles.overlay, overlayClassName)}
          destroyTooltipOnHide
          {...props}
        >
          {tooltipChild}
        </RCTooltip>
      ) : (
        child
      )}
    </Fragment>
  )
}

export default Tooltip
