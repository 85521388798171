import { generatePath, useHistory } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { brandVoicePath } from 'app/navigation/paths'

import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { toggleDeleteConfirmationModalVisibility } from 'features/admin/brandVoice/store/brandVoiceSlice'

import { removeBrandVoice } from '../../store/brandVoiceSlice'
import { deleteBrandVoice } from '../api'
import { brandVoiceKeys } from '../queryKeys'

const useDeleteBrandVoiceMutation = (brandVoiceId: string) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const dispatch = useAppDispatch()
  const history = useHistory()

  return useMutation(deleteBrandVoice, {
    mutationKey: brandVoiceKeys.delete(accountId, brandVoiceId),
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: () => {
      successToast('Brand Voice successfully deleted.')
      dispatch(removeBrandVoice(brandVoiceId))
      dispatch(toggleDeleteConfirmationModalVisibility(false))
      history.push(generatePath(brandVoicePath, { accountId }))
    },
  })
}

export default useDeleteBrandVoiceMutation
