import { useState } from 'react'

import Button from 'common/components/button/Button'
import Card from 'common/components/Card'
import { errorToast, successToast } from 'common/components/toastNotification'

type Props = {
  title: string
  subTitle: string
  actionButton: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined
      }
    >
    text: string
    isDisabled?: boolean
  }
  onClick: () => Promise<void>
  successMessage: string
}

const ActionCard = ({
  title,
  subTitle,
  actionButton: { text: actionText, icon: Icon, isDisabled },
  onClick,
  successMessage,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleButtonClick = () => {
    ;(async () => {
      try {
        setIsLoading(true)
        await onClick()
        successToast(successMessage)
      } catch (e) {
        errorToast('Something went wrong, try again or contact us')
      } finally {
        setIsLoading(false)
      }
    })()
  }
  return (
    <Card>
      <div className="pb-4 text-coolGray-800 font-medium">{title}</div>
      <div className="pb-10 text-coolGray-800">{subTitle}</div>

      <div className="flex justify-end">
        <Button
          variant="primary"
          prefixIcon={<Icon width={24} height={24} />}
          loading={isLoading}
          disabled={isDisabled}
          onClick={() => handleButtonClick()}
        >
          <span className="text-base">{actionText}</span>
        </Button>
      </div>
    </Card>
  )
}

export default ActionCard
