import CenterPanel from '../../components/layout/CenterPanel'

import IntegrationSection from './IntegrationSection'
import OptimizeView from './OptimizeView'

type Props = {
  showOptimizeView: boolean
}
const CenterSection = ({ showOptimizeView }: Props) => {
  return (
    <CenterPanel>
      {showOptimizeView ? <OptimizeView /> : <IntegrationSection />}
    </CenterPanel>
  )
}

export default CenterSection
