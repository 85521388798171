import { useCallback, useEffect, useState } from 'react'

import Button from 'common/components/button/Button'
import Widget from 'common/components/Widget'
import { useAppSelector } from 'common/hooks/redux'
import { Add } from 'common/icons'

import { getProjectExceptions } from './api'
import Error from './Error'
import { defaultRevenueSettings } from './helpers'
import { RevenueSettingsValues } from './interfaces'
import RevenueSettings from './RevenueSettings'

const ProjectRevenueExceptions = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [projectExceptions, setProjectExceptions] = useState<
    RevenueSettingsValues[]
  >([])

  const addException = () => {
    const canAddException = projectExceptions.every(
      (exception) => exception.isSaved
    )
    if (canAddException) {
      setProjectExceptions((previousProjectExceptions) => [
        ...previousProjectExceptions,
        { ...defaultRevenueSettings, isSaved: false },
      ])
    }
  }

  const loadProjectExceptions = useCallback(async () => {
    try {
      setHasError(false)
      setIsLoading(true)

      const response = await getProjectExceptions(accountId)
      setProjectExceptions(response)
    } catch {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }, [accountId])

  const onSave = (newProjectException: RevenueSettingsValues) => {
    setProjectExceptions((previousExceptions) => {
      const newProjectExceptionIndex = previousExceptions.findIndex(
        (exception) => exception.projectId === newProjectException.projectId
      )
      if (newProjectExceptionIndex !== -1) {
        return [
          ...previousExceptions.slice(0, newProjectExceptionIndex),

          newProjectException,
          ...previousExceptions.slice(newProjectExceptionIndex + 1),
        ]
      } else {
        return [...previousExceptions.slice(0, -1), newProjectException]
      }
    })
  }

  useEffect(() => {
    loadProjectExceptions()
  }, [loadProjectExceptions])

  return (
    <Widget
      data-cy="admin-revenue-reporting-exceptions"
      className="max-w-318 mt-10"
    >
      <div className="flex justify-between">
        <div>
          <h4 className="text-base font-bold text-coolGray-800">
            Project revenue exceptions
          </h4>
          <p className="mt-1 text-coolGray-500 text-sm">
            These will apply to only the project selected and will overide the
            global settings set above.
          </p>
        </div>
        <Button variant="primary" onClick={addException}>
          <Add isDefaultColor={false} className="mr-4" />
          Add exception
        </Button>
      </div>
      {hasError && <Error />}
      {[...projectExceptions].map((projectException, index) => (
        <RevenueSettings
          scope="project"
          className="mt-6"
          isLoading={isLoading}
          settings={projectException}
          key={index}
          index={index}
          onSave={onSave}
          disallowedProjectIds={projectExceptions
            .map((exception) => exception.projectId || '')
            .filter((id) => id !== projectException.projectId)}
          onDelete={loadProjectExceptions}
        />
      ))}
    </Widget>
  )
}

export default ProjectRevenueExceptions
