import cx from 'classnames'

type Props = {
  text: string | JSX.Element
  variant?:
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'neutral'
    | 'optimize'
    | 'personalize'
  size?: 'small' | 'normal'
  className?: string
}

const Badge = ({
  className,
  text,
  size = 'normal',
  variant = 'neutral',
}: Props) => {
  return (
    <span
      className={cx(
        'whitespace-nowrap uppercase',
        {
          'text-xs py-0.5 px-1': size === 'small',
          'text-sm py-1 px-2': size === 'normal',
          'bg-RAGGreen text-white': variant === 'success',
          'bg-RAGamber text-black': variant === 'warning',
          'bg-RAGred text-white': variant === 'danger',
          'bg-maroon-500 text-white': variant === 'info',
          'bg-base-100 text-black': variant === 'neutral',
          'bg-pink-50 text-maroon-400 border border-maroon-400':
            variant === 'optimize' || variant === 'personalize',
        },
        className
      )}
    >
      {text}
    </span>
  )
}

export default Badge
