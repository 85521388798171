import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchAllAttachedProjectsForBrandVoice } from '../api'
import { brandVoiceKeys } from '../queryKeys'

const useGetAllAttachedProjectsForBrandVoice = (brandVoiceId: string) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { data, isFetching } = useQuery({
    queryKey: brandVoiceKeys.attachedProjects(accountId, brandVoiceId),
    queryFn: () =>
      fetchAllAttachedProjectsForBrandVoice({ accountId, brandVoiceId }),
    enabled: !!brandVoiceId && !!accountId,
  })

  const projects = useMemo(() => data?.projects || [], [data])

  return { data: projects, isFetching }
}

export default useGetAllAttachedProjectsForBrandVoice
