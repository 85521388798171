import { useEffect, useState } from 'react'

import * as GraphWidget from 'common/components/GraphWidget'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import { ScatterPlot } from 'common/components/scatterPlot'
import { WidgetType } from 'common/components/Widget'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { InfoAction } from 'common/components/WidgetHeader'
import { WidgetError } from 'common/components/WidgetMessage'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import { fetchOpenByLengthGraphData } from '../api'
import { WidgetProps } from '../interfaces'

const OpenRateByLengthWidget = ({
  accountId,
  projectIds,
  startDate,
  endDate,
  setHasData,
  type,
}: WidgetProps & {
  setHasData?: (value: boolean) => void
  type: WidgetType
}) => {
  const [results, setResults] = useState<{ data: number[][]; name: string }[]>(
    []
  )

  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { chartRefCallback, chart } = useChartRefCallback()

  useEffect(() => {
    setResults([])
    setIsLoading(true)
    setHasError(false)

    fetchOpenByLengthGraphData({
      accountId,
      projectIds,
      startDate,
      endDate,
    })
      .then((graphResponse) => {
        const graphData = graphResponse.data.series.map((serie) => ({
          name: serie.name,
          data: serie.data.map((a) => [a[0], Number(a[1].toFixed(2))]),
        }))

        if (graphData === undefined || graphData.length === 0) {
          setHasData?.(false)
          setResults([])
        } else if (graphData) {
          setHasData?.(true)
          setResults(graphData)
        }
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, projectIds, startDate, endDate, setHasData])

  const title = 'Open rate by length'

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (hasError) {
      return 'error'
    } else if (results && results.length > 0) {
      return 'results'
    } else {
      return 'noResults'
    }
  }

  const state = getState()

  if (state === 'noResults') {
    return null
  }

  return (
    <GraphWidget.Widget
      type={type}
      title={
        <div>
          <div className="text-coolGray-800 font-medium text-xl mb-4 mr-4">
            {title}
          </div>
          <p className="text-coolGray-500 text-sm font-normal">
            The open rate by length shows how length might correlate to open
            rate across variants and experiments.
          </p>
        </div>
      }
      actions={
        (state === 'results' && chart && (
          <div className="inline-flex">
            <ChartWidgetMenu chart={chart} title={title} />
            <InfoAction message="Testing a range of variant lengths increases experiment diversity, and diversity influences the uplift achieved. Many linguistic factors combine to affect performance; character count alone doesn’t tend to correlate with performance. High performers can be long as well as short - so keep testing!" />
          </div>
        )) ??
        undefined
      }
    >
      {
        {
          error: <WidgetError className="mb-6" />,
          loading: <WidgetLoader data-cy="open-rate-by-length-widget-loader" />,
          results: (
            <ScatterPlot
              data={results}
              ref={chartRefCallback}
              xAxisLabel="Length (number of characters)"
              yAxisLabel="Normalized open rate"
              pointFormat="{point.x} Characters <br> {point.y}% Open rate"
            />
          ),
        }[state]
      }
    </GraphWidget.Widget>
  )
}

export default OpenRateByLengthWidget
