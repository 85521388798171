import Spinner from 'common/components/spinner'
import { useAppSelector } from 'common/hooks/redux'

import DetailsSection from './DetailsSection'
import ExperimentSection from './ExperimentSection'
import UserSection from './UserSection'

const CampaignDetails = () => {
  const isLoadingCampaignData = useAppSelector(
    (state) => state.campaignStates.isLoading
  )

  if (isLoadingCampaignData) {
    return <Spinner className="m-auto" />
  }

  return (
    <div
      className="m-6 p-4 border border-gray-300 bg-coolGray-50 gap-1"
      role="contentinfo"
    >
      <UserSection />
      <DetailsSection />
      <ExperimentSection />
    </div>
  )
}

export default CampaignDetails
