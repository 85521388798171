import { ReactNode } from 'react'
import cx from 'classnames'

import TextLoader from 'common/components/loaders/TextLoader'
import Widget from 'common/components/Widget'
import { ArrowRight as ArrowRightIcon } from 'common/icons'

export type ListWidgetAction = {
  label: string
  onClick: () => void
}

type Props = {
  title: string
  action?: ListWidgetAction
  isLoading: boolean
  error: ReactNode
  className?: string
  children: ReactNode
}

const ListWidget = ({
  title,
  action,
  isLoading,
  error,
  className,
  children,
}: Props) => {
  const getState = () => {
    if (isLoading) {
      return 'isLoading'
    } else if (error) {
      return 'error'
    } else {
      return 'results'
    }
  }
  const state = getState()

  return (
    <div className={cx('flex flex-col', className)}>
      <div className="flex justify-between">
        <span className="mb-4 text-coolGray-800 font-medium text-xl mr-4 capitalize-first">
          {title}
        </span>
        {action && (
          <button
            className="group ml-5 gap-2 mb-4 flex items-center text-coolGray-500 hover:text-maroon-300"
            onClick={action.onClick}
          >
            {action.label}
            <ArrowRightIcon
              className="group-hover:text-maroon-300"
              isDefaultColor={false}
              size={4}
            />
          </button>
        )}
      </div>
      <Widget
        type="table"
        className={cx('py-2 h-full max-h-88 overflow-auto', className)}
      >
        {
          {
            isLoading: (
              <div
                data-testid="loader"
                data-cy="list-widget-loader"
                className="px-6 py-4"
              >
                <TextLoader />
              </div>
            ),
            error,
            results: children,
          }[state]
        }
      </Widget>
    </div>
  )
}

export default ListWidget
