import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import helpers from 'workflow/utils/helpers'

import { ApiMember, Member, Team } from 'common/api/teamsApi'
import Button from 'common/components/button/Button'
import Input from 'common/components/Input'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch } from 'common/hooks/redux'
import { Edit } from 'common/icons'

import { clickUpdateTeamName } from '../store/usersSlice'

import { isTeamNameAlreadyUsed } from './modal/addTeamFormContent/AddTeamFormContent'

import styles from './Title.module.css'

type Props = {
  name: string
  id: string
  teams: Team[]
  members: Member[] | string[] | ApiMember[]
  titleClassName?: string
}

const Title = ({ name, id, titleClassName, teams, members }: Props) => {
  const dispatch = useAppDispatch()
  const [isEditing, setIsEditing] = useState(false)
  const [nameInput, setNameInput] = useState(name)
  const flags = useFlags()

  const nameInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const maxInputWidth = 400

    if (maxInputWidth) {
      helpers.applyInputWidth(maxInputWidth)
    }
  })

  useEffect(() => {
    if (isEditing) {
      nameInputRef.current?.focus()
    }
  }, [isEditing])

  const isNameAlreadyUsed = isTeamNameAlreadyUsed(nameInput, teams, name)

  const hasErrors = nameInput === name || !nameInput.length || isNameAlreadyUsed

  return (
    <div className="flex text-xl">
      {isEditing ? (
        <form
          data-cy="team-name-form"
          className={cx('flex items-center gap-4 ml-6', { hidden: !isEditing })}
          onBlur={(e) => {
            if (
              !isParentTargeted(e.relatedTarget as Element, e.currentTarget)
            ) {
              // Do not blur when clicked inside the form to be able to subnit
              // setIsEditing(false)
            }
          }}
          onSubmit={(event) => {
            event.preventDefault()
            dispatch(
              clickUpdateTeamName({
                name: nameInput,
                teamId: id,
                members,
                showNewTeamPage: flags.showNewTeamPage,
              })
            )
            setIsEditing(false)
          }}
        >
          <div>
            <Input
              data-cy="team-name-input"
              type="text"
              value={nameInput}
              placeholder="Enter the team's name"
              onChange={(event) => setNameInput(event.target.value)}
              className={`h-10 w-88 px-2 py-2 text-sm text-coolGray-800 flex items-center
              placeholder-sm placeholder-coolGray-800 placeholder-opacity-50 
              border border-blueGray-300 bg-white focus:outline-none 
              focus:text-maroon-300 focus:placeholder-maroon-300 
              focus:border-maroon-500 focus:bg-maroon-100 focus:bg-opacity-10`}
              ref={nameInputRef}
              clear={() => {
                setNameInput('')
              }}
              hasError={hasErrors}
            />
            {isNameAlreadyUsed && (
              <label className="mt-1 text-red-400 text-sm font-normal absolute">
                This team name already exists!
              </label>
            )}
          </div>
          <Button
            ghost
            className="text-base"
            onClick={() => {
              setIsEditing(false)
            }}
            data-cy="move-campaigns-cancel"
          >
            Cancel
          </Button>

          <Button
            data-cy="team-name-save"
            type="submit"
            variant="primary"
            disabled={hasErrors}
          >
            Save
          </Button>
        </form>
      ) : (
        <div
          className={cx('flex ', {
            hidden: isEditing,
          })}
        >
          <Tooltip overlay="Edit name" align={{ offset: [0, 10] }}>
            <Button
              data-cy="team-name-edit"
              aria-label="Edit team's name"
              className={cx('flex items-center pl-0', styles.editableButton)}
              onClick={(e) => {
                nameInputRef.current?.focus()
                setIsEditing(true)
                setNameInput(name)
              }}
            >
              <>
                <div
                  data-cy="team-name"
                  className={cx(
                    'mr-3 font-medium max-w-sm truncate',
                    titleClassName
                  )}
                >
                  {name}
                </div>
                <Edit size={5} />
              </>
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default Title

function isParentTargeted(element: Element | null, target: Element) {
  if (!element) {
    return false
  }
  if (element !== target) {
    return isParentTargeted(element.parentElement, target)
  } else {
    return true
  }
}
