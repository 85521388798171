import { TableInstance } from '..'

import CurrencyCell from './Currency'
import { getTotalCurrencyPerPage } from './helpers'

const TotalCurrencyPerPage = <T extends object>(props: TableInstance<T>) => {
  const totalValue = getTotalCurrencyPerPage(props)

  return <CurrencyCell value={totalValue} />
}

export default TotalCurrencyPerPage
