import BrazeIntegration from 'workflow/CampaignSummary/IntegrationOptions/BrazeIntegration/BrazeIntegration'
import SFMCIntegration from 'workflow/CampaignSummary/IntegrationOptions/SfmcIntegration/SfmcIntegrationManager'

import Spinner from 'common/components/spinner'
import { useAppSelector } from 'common/hooks/redux'
import PhraseeX from 'features/campaigns/integration-options/PhraseeX/PhraseeXIntegration'

export const getESPTitle = (drawer: string): string | undefined => {
  return {
    phraseex: 'Dynamic Optimization',
    salesforce: 'Salesforce',
    sfmcv2: 'Salesforce',
    braze: 'Braze',
  }[drawer]
}

const OptimizeIntegrationSection = () => {
  const isLoadingCampaignData = useAppSelector(
    (state) => state.campaignStates.isLoading
  )

  const integrationType = useAppSelector(
    (state) =>
      state.campaignStates.campaignData.campaign_configuration
        ?.integration_options?.type
  )

  const getIntegrationContent = () => {
    switch (integrationType) {
      case 'sfmcv2':
      case 'salesforce':
        return <SFMCIntegration />
      case 'phraseex':
      case 'adobe_campaign_classic':
        return <PhraseeX />
      case 'braze':
        return <BrazeIntegration />
      default:
        return null
    }
  }

  const integrationContent = getIntegrationContent()
  const integrationTitle = getESPTitle(integrationType)

  return (
    <div
      data-testid="optimizeIntegrationSection"
      className="bg-white border-l border-b pt-6 pb-10 w-106"
    >
      {isLoadingCampaignData ? (
        <Spinner />
      ) : (
        <>
          <div className="px-9 flex justify-between">
            <div className="text-2xl font-medium text-coolGray-800">
              {integrationTitle}
            </div>
          </div>
          {integrationContent}
        </>
      )}
    </div>
  )
}

export default OptimizeIntegrationSection
