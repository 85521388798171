import { ComponentProps, useState } from 'react'
import RCSlider, { SliderProps } from 'rc-slider'

import NumericInput from '../numericInput'

import 'rc-slider/assets/index.css'
import styles from './Slider.module.css'

const Slider = (
  props: SliderProps & { minLabel?: string; maxLabel?: string }
) => {
  const currentValue = props.value ?? props.defaultValue
  const [minValue, maxValue]: [number | undefined, number | undefined] =
    typeof currentValue === 'number'
      ? [currentValue ?? props.min, undefined]
      : (currentValue as [number, number]) ?? [props.min, props.max]
  const [min, setMin] = useState(minValue)
  const [max, setMax] = useState(maxValue)
  const [previousValue, setPreviousValue] = useState(currentValue)
  if (previousValue !== currentValue) {
    setPreviousValue(currentValue)
    if (typeof currentValue === 'number') {
      setMin(currentValue)
    } else if (currentValue) {
      setMin(currentValue[0])
      setMax(currentValue[1])
    }
  }

  const isDisabled = props.disabled
  const isInputHidden =
    props.minLabel !== undefined || props.maxLabel !== undefined
  return (
    <div className={styles.container}>
      <div className="flex items-stretch justify-between text-xs mb-4 text-coolGray-800">
        <div>{props.minLabel ?? props.min}</div>
        <div>{props.maxLabel ?? props.max}</div>
      </div>
      <RCSlider
        {...props}
        value={
          props.value || props.value === 0
            ? props.value
            : props.range && max !== undefined
            ? [min, max]
            : min
        }
        onChange={(value) => {
          if (!isDisabled) {
            if (typeof value === 'number') {
              setMin(value)
              props.onChange?.(value)
            } else {
              setMin(value[0])
              setMax(value[1])
              props.onChange?.(value)
            }
          }
        }}
      />
      {!isInputHidden && (
        <div className="bg-coolGray-50 w-min mt-3 flex items-center">
          <SliderInput
            aria-label="min"
            disabled={isDisabled}
            onChange={(value) => {
              if (!isDisabled && value !== undefined) {
                setMin(value)
                if (props.range) {
                  max !== undefined && props.onChange?.([value, max])
                } else {
                  props.onChange?.(value)
                }
              }
            }}
            value={
              props.range
                ? min
                : typeof props.value === 'number'
                ? props.value
                : min
            }
            min={props.min}
            max={props.max}
          />
          {props.range && (
            <>
              <span className="text-coolGray-700">-</span>
              <SliderInput
                disabled={isDisabled}
                aria-label="max"
                onChange={(value) => {
                  setMax(value)
                  value !== undefined && props.onChange?.([min, value])
                }}
                value={max}
                min={props.min}
                max={props.max}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}
const SliderInput = ({
  onChange,
  value,
  min,
  max,
  'aria-label': ariaLabel,
  disabled,
}: Pick<
  ComponentProps<typeof NumericInput>,
  'onChange' | 'value' | 'min' | 'max' | 'aria-label' | 'disabled'
>) => (
  <NumericInput
    aria-label={ariaLabel}
    disabled={disabled}
    isWithoutStyles
    onChange={onChange}
    value={value}
    precision={0}
    min={min}
    className="bg-white m-2 border shadow-bottom outline-none focus:outline-none box-content font-medium
            focus:placeholder-maroon-300 focus:border-maroon-500 text-center text-coolGray-800 text-xs py-1 px-2"
    max={max}
    autoResize={{ paddingPx: 8 }}
  />
)

export default Slider
