import { useState } from 'react'
import cx from 'classnames'

import Avatar from 'common/components/Avatar'
import Widget from 'common/components/Widget'
import { formatDate } from 'common/helpers/formatDate'
import { Clock as ClockIcon } from 'common/icons'
import { AvatarColor } from 'features/profile/AvatarColorPicker'

type Props = {
  name: string
  createdAt: string
  userName?: string
  logoUrl?: string
  avatarColor?: AvatarColor
}

const AccountCard = ({
  name,
  userName,
  logoUrl,
  createdAt,
  avatarColor,
}: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const [firstName, lastName] = userName ? userName.split(' ') : []

  return (
    <div
      role="presentation"
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Widget
        type={isHovered ? 'raised' : 'basic'}
        className={cx('h-full', { 'cursor-pointer': isHovered })}
      >
        <div className="flex justify-between mb-4">
          <img className="h-10" src={logoUrl} alt={`${name}-logo`} />
          <Avatar
            firstName={firstName}
            lastName={lastName}
            className="text-xs w-7 h-7 min-w-7 mr-3"
            color={avatarColor}
          />
        </div>
        <div className="text-coolGray-800 text-base font-medium mb-4 capitalize-first">
          {name}
        </div>
        <div className="flex flex-row justify-start text-black">
          <ClockIcon isDefaultColor={false} />
          <div className="ml-2.5 self-center">{formatDate(createdAt)}</div>
        </div>
      </Widget>
    </div>
  )
}

export default AccountCard
