import cx from 'classnames'

type Props = {
  className?: string
  text: string
  backgroundColor?: string
}

const Tag = ({ className, text, backgroundColor }: Props) => {
  return (
    <span
      className={cx(
        className,
        'text-sm text-white py-1 px-2 whitespace-nowrap',
        backgroundColor ?? 'bg-maroon-500'
      )}
    >
      {text}
    </span>
  )
}

export default Tag
