import { generatePath, useHistory } from 'react-router'
import { styleGuidePath } from 'app/navigation/paths'

import { MenuDivider, MenuItem } from 'common/components/menuButton'
import { Row } from 'common/components/table'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Copy, Edit, SmallTick } from 'common/icons'

import { BrandVoice, markAsDefault } from '../../../store/brandVoiceSlice'

type Props = {
  rowProps: Row<BrandVoice>
  onDuplicate: (brandVoice: BrandVoice) => void
}

const MenuOptions = ({ rowProps, onDuplicate }: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const brandVoiceId = rowProps.original.id
  const { isDefault } = rowProps.original

  return (
    <>
      <MenuItem
        onClick={() => {
          history.push(
            generatePath(styleGuidePath, {
              accountId,
              brandVoiceId,
            })
          )
        }}
      >
        <Edit
          isDefaultColor={false}
          className="text-coolGray-400 mr-4"
          size={4}
        />
        Edit
      </MenuItem>
      <MenuItem onClick={() => onDuplicate(rowProps.original)}>
        <Copy
          isDefaultColor={false}
          className="text-coolGray-400 mr-4"
          size={4}
        />
        Copy
      </MenuItem>

      {!isDefault && (
        <>
          <MenuDivider />
          <MenuItem
            onClick={() =>
              dispatch(
                markAsDefault({
                  accountId: accountId,
                  brandVoiceId: rowProps.original.id,
                  name: rowProps.original.name,
                })
              )
            }
          >
            <SmallTick
              isDefaultColor={false}
              className="text-coolGray-400 mr-4"
              size={4}
            />
            Mark as default
          </MenuItem>
        </>
      )}
    </>
  )
}

export default MenuOptions
