import type { CellProps } from 'common/components/table'

import { getAveragePageValue } from './helpers'
import { PercentageCell } from '.'

const AveragePagePercentageCell = <T extends object>(props: CellProps<T>) => {
  const averageValue = getAveragePageValue(props)

  return <PercentageCell value={averageValue} />
}

export default AveragePagePercentageCell
