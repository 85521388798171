import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'
import { ignoreNewLineEmoji } from 'common/variantsUtils'

import { PersonalizationInfo } from '../../personalizationsPage/interfaces'
import {
  fetchActivePersonalizedCampaigns,
  PersonalizationResponse,
} from '../api'
import { personalizationKeys } from '../queryKeys'

const mapPersonalizationToCard = (
  personalization: PersonalizationResponse
): PersonalizationInfo => {
  return {
    id: personalization?._id,
    name: ignoreNewLineEmoji(personalization?.name),
    createdDate: personalization?.startDate?.toString() ?? '',
    lastModified: '',
    contentType: personalization?.channels[0] ?? '',
    owner: '',
    contributors: [],
    useCase: personalization?.useCase,
    channels: personalization?.channels,
    trainingContent: personalization?.trainingContent,
  }
}

const useGetPersonalizationsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getPersonalizationsQuery = useQuery(
    personalizationKeys.personalizations(accountId),
    () => fetchActivePersonalizedCampaigns(accountId),
    {
      select: (data) => {
        return data.map(mapPersonalizationToCard)
      },
    }
  )

  return getPersonalizationsQuery
}

export default useGetPersonalizationsQuery
