import { AdvancedVisualisationsData } from '../../../../interface'

import LineGraph from './LineGraph'

type Props = AdvancedVisualisationsData

const OpenRatesOverTime = ({
  results,
  average,
  selectedRows,
  variantColorMapping,
}: Props) => {
  return (
    <LineGraph
      results={results}
      average={average}
      selectedRows={selectedRows}
      variantColorMapping={variantColorMapping}
      title="Open rates overtime"
    />
  )
}

export default OpenRatesOverTime
