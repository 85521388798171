import Button from 'common/components/button/Button'

type Props = {
  onClickCancel: () => void
  isEditMode: boolean
  canSubmitForm: boolean
}

export function Footer({ onClickCancel, isEditMode, canSubmitForm }: Props) {
  return (
    <div className="pt-4 px-4 flex justify-end gap-4">
      <Button data-cy="cancel-button" ghost onClick={onClickCancel}>
        Cancel
      </Button>
      <Button
        data-cy={isEditMode ? 'add-user-button' : 'create-team-button'}
        variant="primary"
        type="submit"
        disabled={!canSubmitForm}
      >
        {isEditMode ? 'Add users' : 'Create team'}
      </Button>
    </div>
  )
}
