import { useEffect, useState } from 'react'
import { DistributionType } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'
import { fetchUpliftReport, UpliftType } from 'common/api/reporting'
import type { UpliftData } from 'common/components/UpliftGraph'
import BaseUpliftWidget from 'common/components/UpliftWidget'
import { useAppSelector } from 'common/hooks/redux'
import {
  selectEndDate,
  selectStartDate,
} from 'features/dashboard/store/dashboardSlice'

interface Props {
  title: string
  accountId: string
  upliftType: UpliftType[]
  testingMethod: string[]
  projectIds: string[]
  info: string
  distributionType?: DistributionType
}

const UpliftWidget = ({
  title,
  accountId,
  upliftType,
  projectIds,
  testingMethod,
  distributionType,
  info,
}: Props) => {
  const startDate = useAppSelector(selectStartDate)
  const endDate = useAppSelector(selectEndDate)

  const [results, setResults] = useState<UpliftData[]>([])
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    function fetchData() {
      const params = {
        uplift_type: upliftType,
        project_ids: projectIds,
        testing_method: testingMethod,
        start_date: startDate,
        end_date: endDate,
        distribution_type: distributionType,
      }
      return Promise.all([
        connectInternalApi.get<{
          data: { data: number[]; name: string }[]
          xAxis: { categories: string[] }
        }>(
          `v1/core/reporting/reporting/accounts/${accountId}/uplift/report/graph-data`,
          {
            params,
          }
        ),
        fetchUpliftReport({
          accountId,
          upliftType,
          projectIds,
          testingMethod,
          startDate,
          endDate,
          periodType: 'aggregate',
          reportingLevel: undefined,
          distributionType,
        }),
      ])
    }

    setHasError(false)
    fetchData()
      .then(([graphResponse, reportResponse]) => {
        const {
          data: { data: graphData, xAxis },
        } = graphResponse

        const {
          data: { data: reportData },
        } = reportResponse

        setResults(
          graphData.map((gData) => {
            const average =
              reportData.length > 0
                ? reportData[0].values.find((v) => v.label === gData.name)
                    ?.value || 0
                : 0
            return {
              data: gData.data,
              name: gData.name,
              average,
            }
          })
        )

        setXAxisLabels(xAxis.categories)
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    accountId,
    projectIds,
    upliftType,
    testingMethod,
    startDate,
    endDate,
    distributionType,
  ])

  return (
    <BaseUpliftWidget
      hasError={hasError}
      info={info}
      isLoading={isLoading}
      data={results}
      title={title}
      xAxisLabels={xAxisLabels}
    />
  )
}

export default UpliftWidget
