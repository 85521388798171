import * as Highcharts from 'highcharts/highstock'
import HC_exporting from 'highcharts/modules/exporting'
import HC_fullscreen from 'highcharts/modules/full-screen'
import HC_offline_exporting from 'highcharts/modules/offline-exporting'
import HC_rounded from 'highcharts-rounded-corners'

HC_exporting(Highcharts)
HC_offline_exporting(Highcharts)
HC_fullscreen(Highcharts)
HC_rounded(Highcharts) // if loaded multiple times, the chart will not be displayed
