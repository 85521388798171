import { ComponentProps } from 'react'
import cx from 'classnames'

import Tags from 'common/components/tags'

const ContentTags = ({
  className,
  ...tagsProps
}: ComponentProps<typeof Tags> & {
  className?: string
}) => (
  <div
    className={cx('bg-coolGray-50 p-2 border border-coolGray-300', className)}
  >
    <Tags {...tagsProps} />
  </div>
)

export default ContentTags
