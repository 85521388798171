import { useEffect, useState } from 'react'
import Content from 'app/Content'
import { cloneDeep } from 'lodash'
import moment from 'moment'

import { connectInternalApi } from 'common/api'
import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import ProjectMultiSelect from 'common/components/ProjectMultiSelect'
import Spinner from 'common/components/spinner'
import Welcome from 'common/components/welcome/Welcome'
import Widget from 'common/components/Widget'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { WigdetApiResponseBase } from 'common/interfaces/widgetListInterfaces'

import DateRangePicker from './components/dateRangePicker/DateRangePicker'
import DashboardSidebar from './components/sidebar/Sidebar'
import EngageWidgets from './components/widgets/engage/EngageWidgets'
import IncrementalRevenueWidget from './components/widgets/incrementalRevenue'
import NumberOfCampaignsWidget from './components/widgets/numberOfCampaigns'
import ReactPerformance from './components/widgets/reactPerformance'
import UsageWidget from './components/widgets/usage'
import {
  selectProjects,
  toggleIsSideBarVisible,
  updateIsDashboardLoading,
} from './store/dashboardSlice'
import {
  CampaignsSummary,
  DailyPerformance,
  IncrementalRevenue,
  UsageWidgetTypes,
  WidgetEngage,
} from './interfaces'

const formatIsoDate = (isoDate: string | undefined): string | undefined => {
  return isoDate && moment(isoDate).format('YYYY-MM-DD')
}

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const {
    user_id: userId,
    accountId,
    firstName: userFirstName,
    lastName: userLastName,
  } = useAppSelector((state) => state.authStates)

  // State is loaded individually to avoid chart reloading
  const startDate = useAppSelector((state) => state.dashboard.startDate)
  const endDate = useAppSelector((state) => state.dashboard.endDate)
  const isDashboardLoading = useAppSelector(
    (state) => state.dashboard.isDashboardLoading
  )
  const projectIds = useAppSelector((state) => state.dashboard.projectIds)

  const [hasError, setHasError] = useState<boolean>(false)

  const [widgetEngage, setWidgetEngage] =
    useState<WidgetEngage | undefined>(undefined)
  const [dailyPerformance, setDailyPerformance] =
    useState<DailyPerformance | undefined>(undefined)
  const [campaignsSummary, setCampaignsSummary] =
    useState<CampaignsSummary | undefined>(undefined)
  const [incrementalRevenue, setIncrementalRevenue] =
    useState<IncrementalRevenue | undefined>(undefined)
  const [usageWidget, setUsageWidget] =
    useState<UsageWidgetTypes | undefined>(undefined)
  const [recentCampaigns, setRecentCampaignsWidget] =
    useState<WigdetApiResponseBase | undefined>(undefined)
  const [toDoWidget, setToDoWidget] =
    useState<WigdetApiResponseBase | undefined>(undefined)

  useDocumentTitle('Dashboard | Jacquard')

  useEffect(() => {
    function fetchData() {
      return connectInternalApi.get<WigdetApiResponseBase[]>(
        `v1/core/monorail/accounts/${accountId}/dashboard`,
        {
          params: {
            project_ids: projectIds,
            start_date: formatIsoDate(startDate),
            end_date: formatIsoDate(endDate),
          },
        }
      )
    }

    setHasError(false)
    dispatch(updateIsDashboardLoading(true))

    fetchData()
      .then(({ data }) => {
        const widgetEngage = data.find(
          (widget) => widget.id === 'widget_engage'
        )
        const dailyPerformance = data.find(
          (widget) => widget.id === 'widget_react_performance'
        )
        const campaignsSummary = data.find(
          (widget) => widget.id === 'widget_campaigns_summary'
        )
        const incrementalRevenue = data.find(
          (widget) => widget.id === 'widget_incremental_revenue'
        )
        const usageWidget = data.find((widget) => widget.id === 'widget_usage')
        const recentCampaigns = data.find(
          (widget) => widget.id === 'widget_recent_campaigns'
        )
        const toDoWidget = data.find((widget) => widget.id === 'widget_to_do')
        setWidgetEngage(widgetEngage)
        setDailyPerformance(dailyPerformance)
        setCampaignsSummary(campaignsSummary)
        setIncrementalRevenue(incrementalRevenue)
        setUsageWidget(usageWidget)
        setRecentCampaignsWidget(recentCampaigns)
        setToDoWidget(toDoWidget)
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        dispatch(updateIsDashboardLoading(false))
      })
  }, [accountId, projectIds, startDate, endDate, dispatch])

  const updateProjectIds = (projectIds: string[]) => {
    dispatch(selectProjects(projectIds))
  }

  const firstUse = !dailyPerformance?.tabs?.length
  const isoLang = incrementalRevenue?.configuration?.iso_language || 'en-US'
  const isoCurrency = incrementalRevenue?.configuration?.iso_currency || 'USD'

  if (hasError) {
    return <div>Loading dashboard failed</div>
  }

  return (
    <Content hasSidebar>
      <PageContainer>
        <PageHeader>
          <PageTitle title="Dashboard" />
          {!isDashboardLoading && (
            <div
              className={`flex flex-wrap-reverse 
                        sm:flex-nowrap justify-end flex-1`}
            >
              <div
                className={`flex w-full sm:w-auto justify-end 
                             mb-3 mt-3 sm:my-0`}
              >
                <span className="lg:hidden flex items-center min-w-max">
                  {recentCampaigns && (
                    <button
                      className={`mr-4 bg-blueGray-100 leading-9
                      text-gray-400 h-8 px-3`}
                      onClick={() => dispatch(toggleIsSideBarVisible())}
                      data-cy="recently-created-button"
                    >
                      Recently created
                    </button>
                  )}
                  {toDoWidget && (
                    <button
                      className={`sm:mr-4 bg-blueGray-100 leading-9
                      text-gray-400 h-8 px-3`}
                      onClick={() => dispatch(toggleIsSideBarVisible())}
                    >
                      To-do
                    </button>
                  )}
                </span>
              </div>
              <div className="flex text-coolGray-800 relative -top-3 min-w-max">
                <div className="mr-6">
                  <ProjectMultiSelect
                    className="w-60"
                    data-cy="dashboard-project-select"
                    selectedItems={projectIds}
                    onApply={(selectedItems) => updateProjectIds(selectedItems)}
                    areTestProjectsExluded
                  />
                </div>
                <div>
                  <DateRangePicker />
                </div>
              </div>
            </div>
          )}
        </PageHeader>

        {isDashboardLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="grid grid-flow-col auto-cols-fr gap-6 mb-10">
              <div className="hidden sm:flex mr-6 w-full">
                <Widget className="min-h-full">
                  <Welcome
                    firstName={userFirstName}
                    lastName={userLastName}
                    firstUse={firstUse}
                  />
                </Widget>
              </div>
              {campaignsSummary && campaignsSummary.configuration && (
                <NumberOfCampaignsWidget
                  accountId={accountId}
                  projectIds={projectIds}
                  distributionChannel={
                    campaignsSummary.configuration.distribution_channel
                  }
                />
              )}
              {incrementalRevenue && (
                <IncrementalRevenueWidget
                  accountId={accountId}
                  localLang={isoLang}
                  projectIds={projectIds}
                  currency={isoCurrency}
                  averageOrder={
                    incrementalRevenue.configuration?.average_order_value
                  }
                  openConversionPercentage={
                    incrementalRevenue.configuration?.open_conversion_percentage
                  }
                  clickConversionPercentage={
                    incrementalRevenue.configuration
                      ?.click_conversion_percentage
                  }
                  openValue={incrementalRevenue.configuration?.open_value}
                  clickValue={incrementalRevenue.configuration?.click_value}
                  updateConfig={(order, conversion) => {
                    let configuration = cloneDeep(
                      incrementalRevenue.configuration
                    )

                    configuration = {
                      ...configuration,
                      average_order_value: order,
                      open_conversion_percentage: conversion,
                    }

                    setIncrementalRevenue({
                      ...incrementalRevenue,
                      configuration,
                    })
                  }}
                />
              )}
            </div>
            {widgetEngage?.tabs && widgetEngage.tabs.length > 0 ? (
              <EngageWidgets
                accountId={accountId}
                widgetEngage={widgetEngage}
              />
            ) : undefined}
            {dailyPerformance?.tabs && dailyPerformance.tabs.length > 0 ? (
              <ReactPerformance
                accountId={accountId}
                dailyPerformance={dailyPerformance}
                firstUse={firstUse}
              />
            ) : undefined}
            {usageWidget && (
              <div className="mt-10 flex-1">
                <UsageWidget
                  title="Usage"
                  accountId={accountId}
                  projectIds={projectIds}
                />
              </div>
            )}
            <Footer />
          </>
        )}
      </PageContainer>

      {(toDoWidget || recentCampaigns) && !isDashboardLoading && (
        <DashboardSidebar
          recentCampaigns={recentCampaigns}
          toDoWidget={toDoWidget}
          setRecentCampaignsWidget={setRecentCampaignsWidget}
          setToDoWidget={setToDoWidget}
          userId={userId}
        />
      )}
    </Content>
  )
}

export default Dashboard
