import React from 'react'

import CsvUploader from './CsvUploader'
import SourceButton from './SourceButton'

type Props = {
  fileName: string | undefined
  progressInPercentage: number | undefined
  onStartUpload: (file: File) => void
  onUpload: ({
    data,
    headers,
    fileName,
  }: {
    data: string[][]
    headers: string[]
    fileName: string
  }) => void
}

const SourceUploader = React.forwardRef<{ open: () => void }, Props>(
  ({ fileName, progressInPercentage, onStartUpload, onUpload }, ref) => (
    <div className="flex gap-6 items-stretch">
      <CsvUploader
        ref={ref}
        fileName={fileName}
        progressInPercentage={progressInPercentage}
        onStartUpload={onStartUpload}
        onUpload={onUpload}
      />
      <SourceButton
        title="Database"
        subtitle="Feature is coming soon!"
        isDisabled
      />
    </div>
  )
)

export default SourceUploader
