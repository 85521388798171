import { generatePath, useHistory, useLocation } from 'react-router-dom'
import type { StaticRoute } from 'app/navigation/interfaces'

import Button from 'common/components/button'
import ButtonWithDropdown from 'common/components/ButtonWithDropdown'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { cancelCreateCampaign } from 'features/campaigns/store/campaignSlice'

interface Props {
  staticRoutes: StaticRoute[]
}

const NavItems = ({ staticRoutes }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const { accountId } = useAppSelector((state) => state.authStates)

  const onRouteClick = (key: string, to: string) => {
    if (key === 'campaigns') {
      dispatch(cancelCreateCampaign())
    }
    const currentPath = location.pathname
    const newPath = generatePath(to, { accountId })
    if (currentPath !== newPath) {
      history.push(newPath)
    }
  }

  return (
    <div className="text-coolGray-500 hidden md:block whitespace-nowrap">
      {staticRoutes.map(({ key, name, to, subroutes, onClick }) =>
        subroutes ? (
          <ButtonWithDropdown
            data-cy={`${name}-menu-button-dropdown`}
            overlayClassName="capitalize"
            key={key}
            className="uppercase text-white hover:text-yellow-200 focus:text-yellow-200"
            suffixIcon={false}
            placement="bottomLeft"
            options={subroutes
              .map(({ key, name, to, onClick }) => [
                {
                  key: key,
                  label: name,
                  onClick: () => {
                    onRouteClick(key, to)
                    onClick && onClick(dispatch)
                  },
                },
              ])
              .flat()}
          >
            {name}
          </ButtonWithDropdown>
        ) : (
          <Button
            data-cy={`${name}-nav-button-item`}
            key={key}
            onClick={() => {
              onRouteClick(key, to)
              onClick && onClick(dispatch)
            }}
            className="uppercase text-white hover:text-yellow-200 focus:text-yellow-200"
          >
            {name}
          </Button>
        )
      )}
    </div>
  )
}

export default NavItems
