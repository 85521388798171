import { FC } from 'react'

const Footer: FC = ({ children }) => {
  return (
    <div className="grid grid-cols-3 justify-items-center text-coolGray-800 font-medium">
      {children}
    </div>
  )
}

export default Footer
