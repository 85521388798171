/* eslint-disable max-lines */

import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import cx from 'classnames'
import type { Moment } from 'moment'
import moment from 'moment-timezone'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'
import { showBanner } from 'workflow/common/common.actions'
import { SendOptimization, SuppressionList } from 'workflow/interface'
import apiUtil from 'workflow/utils/Api'
import { cancelSends } from 'workflow/Workflow.actions'

import Button from 'common/components/button/Button'
import Card from 'common/components/Card'
import Checkbox from 'common/components/checkbox'
import ConfirmationCard from 'common/components/ConfirmationCard'
import DatePickerAntd from 'common/components/DatePicker'
import Input from 'common/components/Input'
import Label from 'common/components/Label'
import MultiSelect from 'common/components/MultiSelect'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import TimePicker from 'common/components/TimePicker'
import Tooltip from 'common/components/Tooltip'
import { Time as TimeIcon } from 'common/icons'

import { scheduleAcousticCampaign } from '../../../Workflow.actions'
import AcousticStatus from '../../AcousticStatus'
import { isUncheduledOnAcoustic } from '../../AcousticStatus/helpers'

import Slider from './slider'

import styles from '../Integrations.module.css'

const FormItem = Form.Item
const SELECT_TEMPLATES_TOOLTIP_TEXT =
  "We've retrieved the list of templates from your Acoustic " +
  'account. Select the one you want to use.'
const SUPPRESSION_LIST_TOOLTIP_TEXT = 'Select a suppression list, if required.'
const MAILING_SPECIFIC_TOOLTIP =
  'This feature allows you to specify a parameter that is appended to your ' +
  'tracking URLs and passed to your site analytics package.'
const DATE_TIME_SELECTOR_TOOLTIP_TEXT =
  "Select the appropriate time based on your Acoustic account's timezone:"
const WAIT_TIME_TOOLTIP_TEXT =
  'How long should we wait before sending out the winner? Jacquard ' +
  'recommends a minimum of 4 hours.'
const AUTOMATIC_DEPLOYMENT_TOOLTIP_TEXT =
  'Uncheck if you would prefer to select the winner and deploy it ' +
  'manually, otherwise Jacquard will handle it all for you.'
const SEND_TIME_OPTIMIZATION_TOOLTIP_TEXT =
  'If you select 24 hours, the winner will be sent over a 24-hour ' +
  "period based on each recipient's optimal send time."

const marks = {
  1: '1',
  3: '3',
  6: '6',
  9: '9',
  12: '12',
  24: '24',
  48: '48',
  72: '72',
  99: 'All time',
}
export const mailingSpecificRegex = /^[ A-Za-z0-9#()_'.-]*$/

interface ValueError {
  isInError: boolean
  message: string
}

interface SubjectLine {
  SubjectLineID: string
  SubjectLineText: string
  espVersionListID: string
}

interface TemplateList {
  mailingId: number
  mailingName: string
  subject: string
}

interface State {
  templateValue: {
    value: number | undefined
  } & ValueError
  suppressionListValue: {
    value: number[] | undefined
  }
  mailingSpecificValue: {
    value: string | undefined
  } & ValueError
  dateValue: {
    value: string | undefined
  } & ValueError
  timeValue: {
    value: string | undefined
  } & ValueError
  hoursValue: {
    value: number | undefined
  } & ValueError
  autoDeployValue: {
    value: boolean
  }
  timeOptimizationValue: {
    value: SendOptimization
  }
  shouldShowTooltip: boolean
  cancelConfirmationCardVisible: boolean
  isLoadingSchedule: boolean
  isLoadingOptions: boolean
  scheduleOptions: {
    subjectLines: SubjectLine[] | undefined
    finalListID: number | undefined
    templateList: TemplateList[] | undefined
    suppressionList: SuppressionList[] | undefined
    campaignTimeZone: string | undefined
  }
}

export const initialState: State = {
  templateValue: {
    value: undefined,
    isInError: false,
    message: 'Please select a template.',
  },
  suppressionListValue: {
    value: undefined,
  },
  mailingSpecificValue: {
    value: undefined,
    isInError: false,
    message: 'Your code contains some restricted special characters.',
  },
  dateValue: {
    value: undefined,
    isInError: false,
    message: 'You have to select both, date and time.',
  },
  timeValue: {
    value: undefined,
    isInError: false,
    message: 'Please select time.',
  },
  hoursValue: {
    value: undefined,
    isInError: false,
    message: 'Please select hours.',
  },
  autoDeployValue: {
    value: true,
  },
  timeOptimizationValue: {
    value: 'NONE',
  },
  shouldShowTooltip: false,
  cancelConfirmationCardVisible: false,
  isLoadingSchedule: false,
  isLoadingOptions: true,
  scheduleOptions: {
    suppressionList: undefined,
    campaignTimeZone: undefined,
    subjectLines: undefined,
    finalListID: undefined,
    templateList: undefined,
  },
}
type PropsFromRedux = ConnectedProps<typeof connector>
class AcousticIntegration extends React.Component<PropsFromRedux, any> {
  TimePicker: any

  constructor(props) {
    super(props)
    this.TimePicker = React.createRef()
    this.state = initialState
  }

  componentDidMount() {
    this.fetchOptions()

    // part of a hook for tooltip disappearing on time input focus:
    document.addEventListener('mousedown', this.handleClickOutside)
    // end of hook
  }

  componentDidUpdate(prevProps) {
    const { campaignId } = this.props

    if (prevProps.campaignId !== campaignId) {
      this.fetchOptions()
      this.setState(initialState)
    }
  }

  fetchOptions = (numMonths?: number) => {
    const { campaignId, config } = this.props

    // fetch all time templates if already configured
    const months = numMonths ? numMonths : config ? 100 : 1

    this.setState(initialState)

    apiUtil(`campaigns/${campaignId}/schedule-options?months=${months}`, {
      method: 'GET',
    })
      .then((res) => {
        const {
          SubjectLines,
          finalListID,
          mailingTemplates: templateList,
          suppressionLists,
          timeZone,
        } = res.data
        this.setState({
          scheduleOptions: {
            subjectLines: SubjectLines,
            finalListID: Number(finalListID),
            templateList,
            suppressionList: suppressionLists,
            campaignTimeZone: timeZone || 'Europe/London',
          },
        })

        if (config) {
          const template = templateList.find(
            ({ mailingName }) => mailingName === config.emailTemplate
          )
          const dateValue = moment(config.sendDateTime)
            .utc()
            .format('YYYY-MM-DD')
          const timeValue = moment(config.sendDateTime).utc().format('HH:mm')

          this.setState({
            templateValue: {
              ...this.state.templateValue,
              value: template?.mailingId,
            },
            suppressionListValue: {
              ...this.state.suppressionListValue,
              value: config.suppressionsLists.map(({ _id }) => Number(_id)),
            },
            mailingSpecificValue: {
              ...this.state.mailingSpecificValue,
              value: config.mailingParameter,
            },
            dateValue: {
              ...this.state.dateValue,
              value: dateValue,
            },
            timeValue: {
              ...this.state.timeValue,
              value: timeValue,
            },
            hoursValue: {
              ...this.state.hoursValue,
              value: config.waitTime,
            },
            autoDeployValue: {
              value: config.isAutoDeploy,
            },
            timeOptimizationValue: {
              value: config.sendOptimization,
            },
          })
        }
      })
      .catch((error: { message: string }) => {
        this.props.showBanner({
          content: error.message,
          type: 'error',
        })
      })
      .finally(() => {
        this.setState({ isLoadingOptions: false })
      })
  }

  // part of a hook for tooltip disappearing on time input focus:
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (e) => {
    if (!this.TimePicker.current?.timePickerRef.picker.contains(e.target)) {
      this.setState({ shouldShowTooltip: false })
    }
  }
  // end of hook

  onChangeUpdateState = (value, type) => {
    const { [`${type}Value`]: unMutedState } = this.state
    const finalValue = type === 'hours' ? Number(value) : value
    const optimizationValue = value ? 'NONE' : 'SEND_24HRS'
    this.setState(
      {
        [`${type}Value`]: {
          ...unMutedState,
          value: type === 'timeOptimization' ? optimizationValue : finalValue,
          isInError: false,
        },
      },
      () => {
        if (type === 'autoDeploy' && !value) {
          this.setState({
            timeOptimizationValue: {
              value: 'NONE',
            },
          })
        }
      }
    )
  }

  returnSuppressionListObjects = (listOfIds) => {
    const { suppressionList } = this.state.scheduleOptions
    const list: any[] = []
    if (!listOfIds || !listOfIds.length) {
      return list
    }
    const convListOfIds = listOfIds.map((id) => Number(id))
    return suppressionList.map((suppressionElement) => {
      if (convListOfIds.includes(suppressionElement._id)) {
        list.push(suppressionElement)
      }
      return list
    })[0]
  }

  schedule = () => {
    this.setState({ isLoadingSchedule: true })

    if (this.isFormValid()) {
      const { campaignId, campaignName, projectId, scheduleAcousticCampaign } =
        this.props
      const {
        templateValue: { value: templateValue },
        hoursValue: { value: waitTime },
        timeOptimizationValue: { value: timeOptimization },
        dateValue: { value: date },
        timeValue: { value: time },
        mailingSpecificValue: { value: mailingLevel },
        suppressionListValue: { value: suppressionList },
        autoDeployValue: { value: tobesent },
        scheduleOptions: { subjectLines, finalListID, templateList },
      } = this.state
      const sendDate = `${date}T${time}:00`
      const data = {
        EspName: 'Watson',
        timeScheduled: sendDate,
        finalSendDelay: Number(waitTime),
        finalSendOptimization: timeOptimization,
        emailTemplateID: templateValue.toString(),
        emailTemplateName: templateList.filter(
          (temp) => temp.mailingId === templateValue
        )[0].mailingName,
        finalListID,
        suppressionLists: this.returnSuppressionListObjects(suppressionList),
        campaignID: campaignId,
        campaignName,
        ESPCampaignID: 'dunno',
        projectID: projectId.toString(),
        SubjectLines: subjectLines,
        tobesent,
        ...(mailingLevel && { mailingLevel }),
      }
      return scheduleAcousticCampaign(data, this.scheduleCallback)
    }
    return true
  }

  scheduleCallback = () => {
    this.setState({ isLoadingSchedule: false })
  }

  filterOption = (input, option) => {
    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  disableDate = (current: Moment | null): boolean => {
    return current ? current < moment().subtract(1, 'days') : false
  }

  getDisabledHours = () => {
    const {
      dateValue: { value: dateValue },
    } = this.state
    const { campaignTimeZone } = this.state.scheduleOptions
    const nowWithinTimeZone = moment().tz(campaignTimeZone)
    const isSameDay = moment().isSame(dateValue || nowWithinTimeZone, 'day')
    const startTime = isSameDay
      ? nowWithinTimeZone
      : nowWithinTimeZone.startOf('day')
    return [
      ...Array(startTime.add(isSameDay ? 15 : 0, 'minutes').hour()).keys(),
    ]
  }

  getDisabledMinutes = (current) => {
    const {
      dateValue: { value: dateValue },
    } = this.state
    const { campaignTimeZone } = this.state.scheduleOptions
    const now = moment().tz(campaignTimeZone)
    const isSameDay = moment().isSame(dateValue || now, 'day')
    const startTime = isSameDay ? now : now.startOf('day')
    if (isSameDay && (current > now.hour() || now.minutes() >= 45)) {
      return []
    }
    return [
      ...Array(startTime.add(isSameDay ? 1 : 0, 'minutes').minutes()).keys(),
    ]
  }

  getDefaultTimeValue = () => {
    const { campaignTimeZone } = this.state.scheduleOptions
    let h = moment().tz(campaignTimeZone).hour()
    let m = moment().tz(campaignTimeZone).minutes()
    // This prevent the possibility for the user to pick a time in the past.
    if (m >= 45) {
      m = 0
      h += 1
    }
    return moment(`${h}:${m}`, 'HH:mm')
  }

  isFormValid = () => {
    const {
      templateValue: { value: templateValue },
      dateValue: { value: dateValue },
      timeValue: { value: timeValue },
      hoursValue: { value: hoursValue },
      mailingSpecificValue: { value: mailingValue },
    } = this.state
    if (!templateValue) {
      this.setState({
        templateValue: {
          ...this.state.templateValue,
          isInError: true,
        },
      })
      return false
    }
    if (!mailingSpecificRegex.test(mailingValue)) {
      this.setState({
        mailingSpecificValue: {
          ...this.state.mailingSpecificValue,
          isInError: true,
        },
      })
      return false
    }
    if (!dateValue || dateValue === 'Invalid date') {
      this.setState({
        dateValue: {
          ...this.state.dateValue,
          isInError: true,
        },
      })
      return false
    }
    if (!timeValue || timeValue === 'Invalid date') {
      this.setState({
        timeValue: {
          ...this.state.timeValue,
          isInError: true,
        },
      })
      return false
    }
    if (!hoursValue) {
      this.setState({
        hoursValue: {
          ...this.state.hoursValue,
          isInError: true,
        },
      })
      return false
    }
    return true
  }

  static getHoursLeft() {
    const hours: SelectValue[] = []
    for (let i = 1; i < 13; i++) {
      hours.push({
        label: i > 1 ? `${i} hrs` : `${i} hr`,
        value: `${i}`,
      })
    }
    hours.push({ label: '24 hrs', value: '24' })
    return hours
  }

  onAfterChange = (value) => {
    this.setState({ templateValue: initialState.templateValue })
    this.fetchOptions(value)
  }

  makeTimeZoneTooltip = () => {
    const { campaignTimeZone } = this.state.scheduleOptions
    const offSetInHrs: any = moment().tz(campaignTimeZone).format('ZZ')
    return `${DATE_TIME_SELECTOR_TOOLTIP_TEXT}
    (${campaignTimeZone}) GMT ${Math.round(offSetInHrs / 100)}`
  }

  resubmitSchedule = () => {
    this.setState({ isLoadingSchedule: true })

    const { cancelSends, campaignId, projectId, typeOfIntegration, stack } =
      this.props

    // sends must be cancelled before resubmitting
    cancelSends(
      campaignId,
      projectId,
      typeOfIntegration,
      stack,
      'edit_and_resubmit',
      // schedule again if cancel was successful
      this.schedule
    )
  }

  render() {
    const {
      campaignProgress,
      mailingExists,
      cancelSends,
      campaignId,
      projectId,
      typeOfIntegration,
      stack,
      splitsSent,
      acousticCampaignScheduled,
    } = this.props
    const {
      autoDeployValue: { value: autoDeployValue },
      shouldShowTooltip,
      templateValue: {
        isInError: isTemplateValueInError,
        message: templateMessage,
        value: templateId,
      },
      suppressionListValue: { value: suppressionListValue },
      dateValue: {
        isInError: isDateValueInError,
        message: dateMessage,
        value: dateValue,
      },
      timeValue: { isInError: isTimeValueInError, value: timeValue },
      hoursValue: {
        isInError: isHoursValueInError,
        message: hoursMessage,
        value: hoursValue,
      },
      mailingSpecificValue: {
        isInError: mailingSpecificValueInError,
        message: mailingSpecificValueMessage,
        value: mailingSpecificValue,
      },
      cancelConfirmationCardVisible,
      isLoadingSchedule,
      scheduleOptions: { templateList, campaignTimeZone, suppressionList },
      isLoadingOptions,
    } = this.state
    const {
      final_send_cancelled,
      test_results_received,
      test_scheduled,
      final_send_schedule_started,
    } = campaignProgress

    const diplayCancel =
      !final_send_cancelled &&
      !test_results_received &&
      test_scheduled &&
      !final_send_schedule_started

    const unscheduledOnAcoustic = isUncheduledOnAcoustic(
      campaignProgress,
      splitsSent,
      mailingExists
    )

    const disableInputs = acousticCampaignScheduled && !unscheduledOnAcoustic

    const hasFilledRequiredFields =
      templateId && dateValue && timeValue && hoursValue

    return (
      <>
        <Drawer.Content>
          <Form>
            <div className="mb-20">
              <div className="mb-6 font-medium text-coolGray-500">
                Schedule your Jacquard experiment with your Acoustic account.
              </div>

              <AcousticStatus campaignProgress={campaignProgress} />

              <FormItem
                help={isTemplateValueInError ? templateMessage : undefined}
                className={cx('mt-6 mb-11 required', {
                  error: isTemplateValueInError,
                })}
              >
                <Tooltip
                  overlay={SELECT_TEMPLATES_TOOLTIP_TEXT}
                  trigger="focus"
                  placement="right"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <Spinner data-testid="loader1" isSpinning={isLoadingOptions}>
                    <SingleSelect
                      placeholder="Select a template"
                      className="required"
                      label="Template"
                      isSearchable
                      onChange={(val) =>
                        this.onChangeUpdateState(val?.value, 'template')
                      }
                      value={
                        templateList &&
                        templateList.filter(
                          (temp) => temp.mailingId === templateId
                        )[0]?.mailingId
                      }
                      isDisabled={disableInputs}
                      options={templateList?.map((template) => ({
                        label: template.mailingName,
                        value: template.mailingId,
                      }))}
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                    />
                    <GuidingText
                      text="Templates listed are limited to the last 1
                          month. Need more? Select a longer timespan."
                    />
                    <div className="mt-6 mx-1">
                      <Slider
                        marks={marks}
                        step={undefined}
                        defaultValue={1}
                        onAfterChange={this.onAfterChange}
                        data-testid="Slider"
                        disabled={disableInputs}
                      />
                    </div>
                  </Spinner>
                </Tooltip>
              </FormItem>

              {templateId && (
                <>
                  <FormItem className="mb-6">
                    <Tooltip
                      overlay={SUPPRESSION_LIST_TOOLTIP_TEXT}
                      trigger="focus"
                      placement="right"
                      overlayStyle={{ maxWidth: 226 }}
                    >
                      <Spinner isSpinning={isLoadingOptions}>
                        <MultiSelect
                          placeholder="Select a suppression list"
                          className="required"
                          name="suppressions"
                          label="Suppression list"
                          isLabelOptional
                          onApply={(val) =>
                            this.onChangeUpdateState(val, 'suppressionList')
                          }
                          selectedItems={suppressionListValue}
                          isDisabled={disableInputs}
                          items={suppressionList?.map((suppression) => ({
                            id: suppression._id,
                            label: suppression.name,
                            value: suppression._id,
                          }))}
                          menuPortalTarget={document.body}
                        />
                      </Spinner>
                    </Tooltip>
                  </FormItem>

                  <FormItem
                    help={
                      mailingSpecificValueInError
                        ? mailingSpecificValueMessage
                        : undefined
                    }
                    className={cx('mb-6', {
                      error: mailingSpecificValueInError,
                    })}
                    label={<Label optional>Mailing specific parameter</Label>}
                  >
                    <Tooltip
                      overlay={MAILING_SPECIFIC_TOOLTIP}
                      trigger="focus"
                      placement="right"
                      overlayStyle={{ maxWidth: 226 }}
                    >
                      <Input
                        data-testid="TextInput"
                        type="text"
                        variant="default"
                        placeholder="REPLACES: %%WA_MAILING_LEVEL_CODE%%"
                        value={mailingSpecificValue}
                        onChange={(e) =>
                          this.onChangeUpdateState(
                            e.target.value,
                            'mailingSpecific'
                          )
                        }
                        disabled={disableInputs}
                      />
                    </Tooltip>
                  </FormItem>

                  <Card className="mt-6">
                    <div className="flex text-lg items-center mb-4">
                      <TimeIcon />
                      <div className="font-bold ml-4">
                        Optimization schedule
                      </div>
                    </div>

                    <FormItem
                      help={
                        isDateValueInError || isTimeValueInError
                          ? dateMessage
                          : undefined
                      }
                      className={cx('mb-6 required', {
                        error: isDateValueInError || isTimeValueInError,
                      })}
                      label="When do you want to send
                          the split test emails?"
                    >
                      <div className="flex justify-between">
                        <Tooltip
                          overlay={this.makeTimeZoneTooltip()}
                          trigger="focus"
                          placement="left"
                          overlayStyle={{ maxWidth: 226 }}
                        >
                          <DatePickerAntd
                            value={dateValue ? moment(dateValue) : undefined}
                            disabledDate={this.disableDate}
                            ariaLabel="DatePicker"
                            onChange={(date: Moment | null) => {
                              if (date) {
                                this.onChangeUpdateState(
                                  moment(date, 'DD-MM-YYYY')
                                    .tz(campaignTimeZone)
                                    .format('Y-MM-DD'),
                                  'date'
                                )
                              }
                            }}
                            disabled={disableInputs}
                          />
                        </Tooltip>
                        <Tooltip
                          overlay={this.makeTimeZoneTooltip()}
                          visible={shouldShowTooltip}
                          placement="right"
                          overlayStyle={{ maxWidth: 226 }}
                        >
                          <TimePicker
                            className={styles.integrationTimePicker}
                            disabledHours={this.getDisabledHours}
                            disabledMinutes={this.getDisabledMinutes}
                            data-testid="TimePicker"
                            format="HH:mm"
                            minuteStep={15}
                            defaultOpenValue={this.getDefaultTimeValue()}
                            value={
                              timeValue ? moment(timeValue, 'HH:mm') : undefined
                            }
                            onChange={(val: Moment) =>
                              this.onChangeUpdateState(
                                moment(val, 'HH:mm').format('HH:mm'),
                                'time'
                              )
                            }
                            ref={this.TimePicker}
                            suffixIcon={<TimeIcon size={6} />}
                            disabled={disableInputs}
                          />
                        </Tooltip>
                      </div>
                    </FormItem>

                    <FormItem
                      help={isHoursValueInError ? hoursMessage : undefined}
                      className={cx('mb-4 required', {
                        error: isHoursValueInError,
                      })}
                      label="How long to wait before to send the winner?"
                    >
                      <Tooltip
                        overlay={WAIT_TIME_TOOLTIP_TEXT}
                        trigger="focus"
                        placement="left"
                        overlayStyle={{ maxWidth: 226 }}
                      >
                        <SingleSelect
                          data-testid="SelectThree"
                          className="w-52"
                          placeholder="Select wait hours"
                          menuPlacement="top"
                          value={hoursValue?.toString()}
                          onChange={(val) =>
                            this.onChangeUpdateState(val?.value, 'hours')
                          }
                          isDisabled={disableInputs}
                          options={AcousticIntegration.getHoursLeft()}
                        />
                      </Tooltip>
                    </FormItem>
                    <FormItem className="mb-4">
                      <Tooltip
                        overlay={AUTOMATIC_DEPLOYMENT_TOOLTIP_TEXT}
                        overlayStyle={{ maxWidth: 226 }}
                      >
                        <div>
                          <Checkbox
                            data-testid="autoDeploy"
                            data-cy="autoDeploy-checkbox"
                            label="Automatically deploy the winner?"
                            isChecked={autoDeployValue}
                            onChange={(isChecked: boolean) => {
                              this.onChangeUpdateState(isChecked, 'autoDeploy')
                            }}
                            isDisabled={disableInputs}
                          />
                        </div>
                      </Tooltip>
                    </FormItem>
                    {autoDeployValue && (
                      <FormItem className="mb-4">
                        <Tooltip
                          overlay={SEND_TIME_OPTIMIZATION_TOOLTIP_TEXT}
                          overlayStyle={{ maxWidth: 226 }}
                        >
                          <div>
                            <Checkbox
                              data-testid="TimeOpt"
                              data-cy="TimeOpt-checkbox"
                              label="Apply send time optimization to the
                              winner? (SEND_24HRS)"
                              isChecked={
                                this.state.timeOptimizationValue?.value ===
                                'SEND_24HRS'
                              }
                              onChange={(isChecked: boolean) => {
                                this.onChangeUpdateState(
                                  !isChecked,
                                  'timeOptimization'
                                )
                              }}
                              isDisabled={disableInputs}
                            />
                          </div>
                        </Tooltip>
                      </FormItem>
                    )}
                  </Card>
                </>
              )}

              {diplayCancel && !cancelConfirmationCardVisible && (
                <Card className="mt-6">
                  <div className="pb-4 text-coolGray-800">
                    Cancel the final mailing
                  </div>
                  <div className="pb-6 text-coolGray-400">
                    Jacquard won’t create the final mailing. The splits will be
                    unaffected and Jacquard will collect the split test results.
                  </div>

                  <div className="flex justify-end">
                    <button
                      className="mr-0 px-6 py-2 text-white
                  bg-pink-600 font-medium"
                      onClick={() =>
                        this.setState({
                          cancelConfirmationCardVisible: true,
                        })
                      }
                    >
                      Cancel final
                    </button>
                  </div>
                </Card>
              )}

              {diplayCancel && cancelConfirmationCardVisible && (
                <div className="mt-4 mb-4">
                  <ConfirmationCard
                    isLoading={false}
                    isDisabled={false}
                    cardTitle="Whooooaaaaaa!"
                    cardText={
                      <>
                        Are you sure you want to{' '}
                        <span className="font-bold">
                          cancel the final mailing
                        </span>
                        ? If you do this you will have to setup a new experiment
                        and schedule.
                      </>
                    }
                    buttonText="Yes, cancel"
                    rejectButtonText="Nooo!"
                    onReject={() =>
                      this.setState({
                        cancelConfirmationCardVisible: false,
                      })
                    }
                    onSubmit={() =>
                      cancelSends(
                        campaignId,
                        projectId,
                        typeOfIntegration,
                        stack,
                        'cancel_final'
                      )
                    }
                  />
                </div>
              )}
            </div>
          </Form>
        </Drawer.Content>
        <Drawer.Footer
          className={cx({
            hidden:
              acousticCampaignScheduled &&
              !unscheduledOnAcoustic &&
              !isLoadingSchedule,
          })}
        >
          {isLoadingSchedule && (
            <Button
              className="ant-btn-lg"
              variant="primary"
              loading={true}
              data-cy="acoustic-loading-button"
            >
              Saving...
            </Button>
          )}
          {unscheduledOnAcoustic && !isLoadingSchedule && (
            <Button
              data-cy="acoustic-resubmit-schedule-button"
              data-test-id="EditButton"
              className="ant-btn-lg"
              variant="primary"
              onClick={() => this.resubmitSchedule()}
              disabled={!hasFilledRequiredFields}
            >
              Resubmit schedule
            </Button>
          )}
          {!acousticCampaignScheduled && !isLoadingSchedule && (
            <Button
              data-cy="acoustic-save-schedule-button"
              data-test-id="Submit"
              className="ant-btn-lg"
              variant="primary"
              loading={isLoadingSchedule}
              onClick={() => this.schedule()}
              disabled={!hasFilledRequiredFields}
            >
              Save schedule
            </Button>
          )}
        </Drawer.Footer>
      </>
    )
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    showBanner: (config) => dispatch(showBanner(config)),
    scheduleAcousticCampaign: (collectedData, onResponse) =>
      dispatch(scheduleAcousticCampaign(collectedData, onResponse)),
    cancelSends: (
      campaignId,
      projectId,
      integrationType,
      stack,
      cancelType,
      onSuccess?: () => void
      // eslint-disable-next-line max-params
    ) =>
      dispatch(
        cancelSends(
          campaignId,
          projectId,
          integrationType,
          stack,
          cancelType,
          onSuccess
        )
      ),
  }
}

export function mapStateToProps(state) {
  const {
    scheduleResponse,
    currentCampaignId,
    campaignName,
    projectId,
    mailingExists,
    splitsSent,
    typeOfIntegration,
    stack,
    acousticCampaignScheduled,
    acousticIntegration,
  } = state.campaignStates

  return {
    campaignId: currentCampaignId,
    campaignName,
    projectId,
    scheduleResponse,
    campaignProgress: state.campaignStates.campaignData?.campaign_progress,
    mailingExists,
    splitsSent,
    typeOfIntegration,
    stack,
    acousticCampaignScheduled,
    config: acousticIntegration,
  }
}
const connector = connect(mapStateToProps, mapDispatchToProps)
export default connector(AcousticIntegration)
