import React from 'react'

import { useAppDispatch } from 'common/hooks/redux'

import CreateVariantsCard from '../components/card/CreateVariantsCard'

import { createVariants, setError, setLoading } from './store/optimizelySlice'

export default function CreateVariants({
  isVariantsCreated,
  selectedProject,
  selectedExperiment,
  isLoading,
  projectId,
  campaignId,
  setState,
  currentState,
}) {
  const dispatch = useAppDispatch()

  return !isVariantsCreated && selectedProject && selectedExperiment ? (
    <CreateVariantsCard
      isDisabled={false}
      isLoading={isLoading.variants}
      cardText={`This will create the required variants in your Optimizely
        Experiment and then send all Jacquard variants to that experiment.`}
      cardTitle="Create and send variations"
      buttonText={isLoading.variants ? 'Creating' : 'Create + send'}
      onSubmit={() => {
        dispatch(setLoading({ field: 'variants', value: true }))
        createVariants(projectId, campaignId, {
          optimizely_experiment_id: selectedExperiment,
          optimizely_project_id: selectedProject,
        })
          .then(() => {
            setState({
              ...currentState,
              isVariantsCreated: true,
              campaignUnlinked: false,
            })
          })
          .catch((err) => {
            dispatch(setError(err.message))
          })
          .finally(() =>
            dispatch(setLoading({ field: 'variants', value: false }))
          )
      }}
    />
  ) : null
}
