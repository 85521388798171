import { useEffect, useReducer } from 'react'
import * as Drawer from 'app/IntegrationDrawer'

import Form from 'common/components/Form'
import Spinner from 'common/components/spinner'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import {
  createCordialMessageExperiment,
  deleteCordialMessageExperiment,
  getCordialBatchMessages,
  saveCordialBatchMessageId,
} from './api'
import DrawerContent from './DrawerContent'
import { cordialReducer, initialState } from './reducer'

export default function CordialIntegration() {
  const [cordialIntegrationState, dispatchCordialIntegrationState] = useReducer(
    cordialReducer,
    initialState
  )

  const {
    selectedExperimentType,
    selectedBatchMessage,
    championSendTimeHours,
    championSendTimeMinutes,
    batchMessagesList,
    isExperimentCreated,
    isExperimentDeleted,
    isBatchMessagesLoading,
    isCreateExperimentButtonLoading,
    isDeleteExperimentButtonLoading,
  } = cordialIntegrationState

  const {
    campaignData: {
      project_id,
      _id: campaign_id,
      campaign_data: { integration_data },
    },
  } = useAppSelector((state) => state.campaignStates)

  useEffect(() => {
    dispatchCordialIntegrationState({
      type: 'get_batch_messages',
    })
    getCordialBatchMessages(project_id)
      .then((res) => {
        dispatchCordialIntegrationState({
          type: 'get_batch_messages_success',
          value: res.data.batchMessages,
        })
      })
      .catch((err) => {
        dispatchCordialIntegrationState({
          type: 'get_batch_messages_error',
          value: err.message,
        })
      })

    if (
      !selectedBatchMessage &&
      !isExperimentDeleted &&
      integration_data?.cordial_batch_message_id
    ) {
      dispatchCordialIntegrationState({
        type: 'load_saved_batch_message',
        value: {
          batchMessageId: integration_data?.cordial_batch_message_id,
          experimentType: integration_data?.cordial_experiment_type,
          championSendHours: integration_data?.cordial_champion_send_hours,
          championSendMinutes: integration_data?.cordial_champion_send_minutes,
        },
      })
    }
  }, [
    integration_data?.cordial_batch_message_id,
    integration_data?.cordial_experiment_type,
    integration_data?.cordial_champion_send_hours,
    integration_data?.cordial_champion_send_minutes,
    project_id,
    selectedBatchMessage,
    isExperimentDeleted,
    isExperimentCreated,
  ])

  const createExperiment = () => {
    const data =
      selectedExperimentType === 'split'
        ? { strategy: 'split' }
        : {
            strategy: 'split-with-champion',
            championSendTime: {
              hours: championSendTimeHours,
              minutes: championSendTimeMinutes,
            },
          }
    dispatchCordialIntegrationState({
      type: 'create_experiment',
    })
    saveCordialBatchMessageId(campaign_id, {
      ...data,
      batchMessageId: selectedBatchMessage,
    })
      .then(() => {
        createCordialMessageExperiment(campaign_id, data)
          .then(() => {
            dispatchCordialIntegrationState({
              type: 'create_experiment_success',
            })
            successToast('Experiment successfully created')
          })
          .catch((err) => {
            dispatchCordialIntegrationState({
              type: 'create_experiment_error',
              value: err.message,
            })
            errorToast(err.message)
          })
      })
      .catch((err) => {
        dispatchCordialIntegrationState({
          type: 'create_experiment_error',
          value: err.message,
        })
        errorToast(err.message)
      })
  }

  const deleteExperiment = () => {
    dispatchCordialIntegrationState({
      type: 'delete_experiment',
    })
    deleteCordialMessageExperiment(campaign_id)
      .then(() => {
        dispatchCordialIntegrationState({
          type: 'delete_experiment_success',
        })

        successToast('Experiment successfully deleted')
      })
      .catch((err) => {
        dispatchCordialIntegrationState({
          type: 'delete_experiment_error',
          value: err.message,
        })
        errorToast(err.message)
      })
  }

  const updateSelectedBatchMessage = (message) => {
    dispatchCordialIntegrationState({
      type: 'select_batch_message',
      value: message,
    })
  }

  const updateExperimentType = (type) => {
    dispatchCordialIntegrationState({
      type: 'select_experiment_type',
      value: type,
    })
  }

  const updateChampionSendTimeHours = (hour) => {
    dispatchCordialIntegrationState({
      type: 'select_champion_send_time_hours',
      value: hour,
    })
  }

  const updateChampionSendTimeMinutes = (minute) => {
    dispatchCordialIntegrationState({
      type: 'select_champion_send_time_minutes',
      value: minute,
    })
  }

  const isCreateExperimentButtonDisabled =
    !selectedBatchMessage ||
    !selectedExperimentType ||
    (selectedExperimentType === 'split-with-champion' &&
      !(championSendTimeHours && championSendTimeMinutes))

  return (
    <Drawer.Content>
      <Form>
        <Spinner
          data-testid="cordial-loader"
          isSpinning={isBatchMessagesLoading}
        >
          <div
            data-testid="cordialIntegrationTitle"
            className="text-base font-medium text-gray-400 mb-4"
          >
            Connect your Cordial message to Jacquard.
          </div>
          <DrawerContent
            selectedBatchMessage={selectedBatchMessage}
            selectedExperimentType={selectedExperimentType}
            isExperimentCreated={isExperimentCreated}
            isBatchMessagesLoading={isBatchMessagesLoading}
            isCreateExperimentButtonLoading={isCreateExperimentButtonLoading}
            isDeleteExperimentButtonLoading={isDeleteExperimentButtonLoading}
            batchMessages={batchMessagesList}
            championSendTimeHours={championSendTimeHours}
            championSendTimeMinutes={championSendTimeMinutes}
            createExperiment={createExperiment}
            deleteExperiment={deleteExperiment}
            updateSelectedBatchMessage={updateSelectedBatchMessage}
            updateExperimentType={updateExperimentType}
            isCreateButtonDisabled={isCreateExperimentButtonDisabled}
            updateChampionSendTimeHours={updateChampionSendTimeHours}
            updateChampionSendTimeMinutes={updateChampionSendTimeMinutes}
          />
        </Spinner>
      </Form>
    </Drawer.Content>
  )
}
