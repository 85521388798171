import { generatePath, useHistory, useLocation } from 'react-router-dom'
import type { StaticRoute } from 'app/navigation/interfaces'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  contentPermissions,
  experimentsPermissions,
  projectsPermissions,
  unifiedFlowPermissions,
} from 'common/auth/permissions'
import { getIsAuthorized } from 'common/auth/permissions'
import Button from 'common/components/button'
import ButtonWithDropdown from 'common/components/ButtonWithDropdown'
import { resetCurrentState as resetContentGenerationState } from 'common/components/topics/store/topicsSlice'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Add as AddIcon } from 'common/icons'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import { showTemplates } from 'features/futurama/store/contentSlice'
import { showTemplates as showUnifiedFlowTemplates } from 'features/unifiedFlow/store/unifiedFlowSlice'

interface Props {
  createContentRoutes: StaticRoute[]
}

const CreateButton = ({ createContentRoutes }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const flags = useFlags()
  const { accountId, permissions } = useAppSelector((state) => state.authStates)

  const hasCreatePermission = (key: string) => {
    if (key === 'campaigns') {
      return getIsAuthorized(permissions, experimentsPermissions.create)
    }
    if (key === 'projects-create') {
      return getIsAuthorized(permissions, projectsPermissions.create)
    }
    if (key === 'content') {
      return getIsAuthorized(permissions, contentPermissions.create)
    }
    if (key === 'unified-flow') {
      return getIsAuthorized(
        permissions,
        unifiedFlowPermissions.viewCreationOptions
      )
    }
    return false
  }

  const onRouteClick = (key: string, to: string) => {
    const currentPath = location.pathname
    const newPath = generatePath(to, { accountId })

    if (key === 'campaigns') {
      history.push(generatePath(to, { accountId }))
      dispatch(clickCreateCampaign())
      dispatch(resetContentGenerationState())
    }

    if (key === 'content') {
      history.push(generatePath(to))
      dispatch(showTemplates())
    }

    if (key === 'unified-flow') {
      history.push(generatePath(to))
      dispatch(showUnifiedFlowTemplates())
    }

    if (currentPath !== newPath) {
      history.push(newPath)
    }
  }

  const dropdownRouteOptions = createContentRoutes.map(
    ({ key, name, to, onClick }) => ({
      key,
      label: name === 'experiments' ? 'experiment' : name,
      onClick: () => {
        onRouteClick(key, to)
        onClick && onClick(dispatch)
      },
      isDisabled: !hasCreatePermission(key),
    })
  )

  const createButtonOptions =
    flags.showUnifiedFlow &&
    getIsAuthorized(permissions, unifiedFlowPermissions.viewCreationOptions)
      ? dropdownRouteOptions.filter(
          ({ key }) => key !== 'campaigns' && key !== 'content'
        )
      : dropdownRouteOptions

  return (
    <>
      {flags.showUnifiedFlow &&
      getIsAuthorized(permissions, unifiedFlowPermissions.viewCreationPage) ? (
        <Button
          data-cy="nav-create-button"
          className="uppercase"
          variant="primary"
          prefixIcon={<AddIcon className="h-4 w-4" isDefaultColor={false} />}
          onClick={() => {
            history.push('/unified-flow')
            dispatch(showUnifiedFlowTemplates())
          }}
        >
          Create
        </Button>
      ) : (
        <ButtonWithDropdown
          overlayClassName="capitalize"
          data-cy="nav-create-menu-button"
          className="uppercase"
          variant="primary"
          suffixIcon={false}
          options={createButtonOptions}
          prefixIcon={<AddIcon className="h-4 w-4" isDefaultColor={false} />}
        >
          Create
        </ButtonWithDropdown>
      )}
    </>
  )
}

export default CreateButton
