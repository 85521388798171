import { FC, useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { matchPath, useLocation } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { createProjectPath } from 'app/navigation/paths'
import isEqual from 'lodash/isEqual'

import Spinner from 'common/components/spinner'
import { errorToast } from 'common/components/toastNotification'
import { usePrevious } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import { getProjectOptions, getProjectTypeOptions } from 'features/projects/api'
import { projectKeys } from 'features/projects/api/queryKeys'
import {
  ProjectOptionsConfig,
  ProjectOptionsResponse,
  ProjectTypeOptionsResponse,
} from 'features/projects/interface'

import ProjectIntegrationMetricsCard from './projectIntegrationMetricsCard/ProjectIntegrationMetricsCard'
import AccessCard from './accessCard'
import FixedSplitCard from './fixedSplitCard'
import IntegrationCard from './integrationCard'
import LanguageGenerationCard from './languageGenerationCard'
import MetricsCard from './metricsCard'
import NotificationRecipientsCard from './notificationRecipientsCard'
import ProjectTypeCard from './projectTypeCard'

const ProjectFormCards: FC = () => {
  const location = useLocation()
  const isCreating = !!matchPath(location.pathname, {
    path: createProjectPath,
    strict: true,
  })
  const formState = useFormState()
  const { change: formChange } = useForm()
  const {
    projectType,
    distributionType,
    distributionChannel,
    integrationType,
    testingMethod,
    splitSettingsType,
    selectionMetric,
    languageGenerationMethod,
    trackingMetrics,
    finalMetrics,
  } = formState.values

  const isFormDirty = formState.dirty
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { data: typeSettings, isLoading: isLoadingTypeSettings } =
    useQuery<ProjectTypeOptionsResponse>(
      projectKeys.projectTypeOptions(accountId),
      () => getProjectTypeOptions(accountId).then((result) => result.data),
      {
        onError: () => errorToast('Failed to load project options'),
        refetchOnWindowFocus: false,
      }
    )

  // using isFetching instead of isLoading because of keepPreviousData option
  const { data: settings, isFetching: isLoadingSettings } =
    useQuery<ProjectOptionsResponse>(
      projectKeys.projectOptions({
        accountId,
        projectType,
        distributionChannel,
        distributionType,
        integrationType,
        splitSettingsType,
        languageGenerationMethod,
        testingMethod,
        selectionMetric,
      }),
      () => {
        const config: ProjectOptionsConfig = {
          projectType,
          distributionChannel,
          distributionType,
          integrationType,
          splitSettingsType,
          languageGenerationMethod,
          testingMethod,
          selectionMetric,
        }

        return getProjectOptions(accountId, config).then(
          (result) => result.data
        )
      },
      {
        enabled: projectType !== undefined, // fetch only when the type is selected
        onError: () => errorToast('Failed to load project options'),
        keepPreviousData: true, // fix destroying form when refetching
        refetchOnWindowFocus: false,
      }
    )

  const optimizationMetricSettings = settings?.optimizationMetricSettings
  const integrationConfigurationSettings =
    settings?.integrationConfigurationSettings
  const prevSettings = usePrevious(settings)
  const previousIsDirty = usePrevious(isFormDirty)

  const isInitialEditProjectLoad = !isCreating && !prevSettings
  // remove invalid metrics configured on skinner silently, when user is editing something else
  const shouldCheckInvalidMetrics =
    !isCreating && isFormDirty && !previousIsDirty

  useEffect(() => {
    if (isInitialEditProjectLoad) {
      return
    }

    const { trackingMetricsSettings } = optimizationMetricSettings || {}
    const prevTrackingMetricsSettings =
      prevSettings?.optimizationMetricSettings?.trackingMetricsSettings

    const doTrackingMetricsOptionsMatch = isEqual(
      trackingMetricsSettings?.options,
      prevTrackingMetricsSettings?.options
    )
    const defaultOptions = trackingMetricsSettings?.options
      .filter((option) => option.default)
      .map(({ value }) => value)
    const hasReceivedNewDefaultOptions =
      !trackingMetrics?.length && defaultOptions?.length

    if (
      trackingMetricsSettings?.show &&
      (!doTrackingMetricsOptionsMatch || hasReceivedNewDefaultOptions)
    ) {
      formChange('trackingMetrics', defaultOptions)
    }

    if (!trackingMetricsSettings?.show) {
      formChange('trackingMetrics', undefined)
    }
  }, [
    formChange,
    optimizationMetricSettings,
    trackingMetrics?.length,
    isInitialEditProjectLoad,
    prevSettings,
  ])

  useEffect(() => {
    const { trackingMetricsSettings } = optimizationMetricSettings || {}

    if (shouldCheckInvalidMetrics && trackingMetricsSettings?.show) {
      const availableMetrics = trackingMetricsSettings?.options.map(
        ({ value }) => value
      )
      const validSelectedMetrics = trackingMetrics.filter((metric) =>
        availableMetrics.includes(metric)
      )

      formChange('trackingMetrics', validSelectedMetrics)
    }
  }, [
    formChange,
    optimizationMetricSettings,
    shouldCheckInvalidMetrics,
    trackingMetrics,
  ])

  useEffect(() => {
    const { finalMetricsSettings } = optimizationMetricSettings || {}

    if (shouldCheckInvalidMetrics && finalMetricsSettings?.show) {
      const availableMetrics = finalMetricsSettings?.options.map(
        ({ value }) => value
      )
      const validSelectedMetrics = finalMetrics.filter((metric) =>
        availableMetrics.includes(metric)
      )

      formChange('finalMetrics', validSelectedMetrics)
    }
  }, [
    formChange,
    optimizationMetricSettings,
    shouldCheckInvalidMetrics,
    finalMetrics,
  ])

  useEffect(() => {
    if (isInitialEditProjectLoad) {
      return
    }

    const { finalMetricsSettings } = optimizationMetricSettings || {}
    const prevFinalMetricsSettings =
      prevSettings?.optimizationMetricSettings?.finalMetricsSettings

    const doTrackingMetricsOptionsMatch = isEqual(
      finalMetricsSettings?.options,
      prevFinalMetricsSettings?.options
    )
    const defaultOptions = finalMetricsSettings?.options
      .filter((option) => option.default)
      .map(({ value }) => value)
    const hasReceivedNewDefaultOptions =
      !finalMetrics?.length && defaultOptions?.length

    if (
      finalMetricsSettings?.show &&
      (!doTrackingMetricsOptionsMatch || hasReceivedNewDefaultOptions)
    ) {
      formChange('finalMetrics', defaultOptions)
    }

    if (!finalMetricsSettings?.show) {
      formChange('finalMetrics', undefined)
    }
  }, [
    formChange,
    optimizationMetricSettings,
    prevSettings,
    finalMetrics?.length,
    isInitialEditProjectLoad,
  ])

  useEffect(() => {
    formChange(
      'integrationConfiguration',
      integrationConfigurationSettings?.show &&
        integrationConfigurationSettings?.data
        ? integrationConfigurationSettings.data
        : {}
    )
  }, [integrationConfigurationSettings, formChange, isFormDirty])

  const isLoading = isLoadingTypeSettings || isLoadingSettings
  const { notificationRecipientsSettings, projectAccessSettings } =
    settings || {}

  return (
    <Spinner
      data-testid="form-loader"
      data-cy="project-form-loader"
      isSpinning={isLoading}
    >
      <ProjectTypeCard
        isCreating={isCreating}
        typeSettings={typeSettings?.projectTypeSettings}
        distributionChannelSettings={settings?.distributionChannelSettings}
        distributionTypeSettings={settings?.distributionTypeSettings}
        sandboxModeSettings={settings?.sandboxModeSettings}
      />
      {settings?.integrationSettings.show && (
        <IntegrationCard integrationSettings={settings.integrationSettings} />
      )}
      {settings?.splitSettings.show && (
        <FixedSplitCard
          isCreating={isCreating}
          splitSettings={settings.splitSettings}
        />
      )}
      {settings?.optimizationMetricSettings.show && (
        <MetricsCard
          optimizationMetricSettings={settings.optimizationMetricSettings}
        />
      )}
      {integrationConfigurationSettings?.data && (
        <ProjectIntegrationMetricsCard
          projectIntegrationMetricsSettings={integrationConfigurationSettings}
        />
      )}
      {settings?.languageGenerationSettings.show && (
        <LanguageGenerationCard
          languageGenerationSettings={settings.languageGenerationSettings}
          isCreating={isCreating}
        />
      )}
      {projectAccessSettings?.show && <AccessCard isCreating={isCreating} />}
      {notificationRecipientsSettings?.show && <NotificationRecipientsCard />}
    </Spinner>
  )
}

export default ProjectFormCards
