import React, { useState } from 'react'
import type { RenderMonthProps, SingleDatePickerShape } from 'react-dates'
import { SingleDatePicker } from 'react-dates'
import cx from 'classnames'
import moment from 'moment'

import { Calendar as CalendarIcon } from 'common/icons'

import 'react-dates/initialize'

import Button from '../button'

import 'react-dates/lib/css/_datepicker.css'
import '../DateStyles.css'

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT

type Props = {
  autoFocus?: boolean
  onBlur?: () => void
} & Omit<
  SingleDatePickerShape,
  'focused' | 'onFocusChange' | 'renderMonthText' | 'renderMonthElement'
> &
  RenderMonthProps & { isTodayVisible?: boolean }

const DatePicker = ({
  autoFocus = false,
  isTodayVisible,
  onBlur,
  ...rest
}: Props) => {
  const [isFocused, setIsFocused] = useState(autoFocus)
  const [key, setKey] = React.useState(0)

  return (
    <SingleDatePicker
      displayFormat={DATE_FORMAT}
      focused={isFocused}
      onFocusChange={({ focused }) => {
        setIsFocused(focused)
        if (!focused && onBlur) {
          onBlur()
        }
        // when the user types an invalid date, the date is set to null but the input is not cleared
        // see https://github.com/react-dates/react-dates/issues/2116
        if (!focused && rest.date === null) {
          setKey(key + 1)
        }
      }}
      key={key}
      customInputIcon={
        <CalendarIcon
          isDefaultColor={!rest.disabled}
          className={cx({ 'cursor-not-allowed': rest.disabled })}
        />
      }
      inputIconPosition="after"
      hideKeyboardShortcutsPanel={true}
      css={{ zIndex: 2 }}
      renderCalendarInfo={() =>
        isTodayVisible ? (
          <div className="flex flex-col items-center justify-center p-4">
            <Button
              variant="link"
              disabled={rest.isOutsideRange?.(moment())}
              onClick={() => {
                rest.onDateChange?.(moment())
                setIsFocused(false)
              }}
            >
              Today
            </Button>
          </div>
        ) : null
      }
      {...rest}
    />
  )
}
export default DatePicker
