/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import type { Moment } from 'moment'
import moment from 'moment-timezone'

import Alert from 'common/components/alert'
import Button from 'common/components/button/Button'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import SelectComponent from '../components/select/SelectComponent'

import {
  fetchActivities,
  fetchTimezone,
  scheduleCampaign,
  setError,
  setLoading,
  setTimezone,
  updateActivities,
} from './store/adobeTargetSlice'
import DrawerContent from './DrawerContent'

export const initialValidationErrors: ValidationErrors = {
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  selectActivity: '',
  isOffersCreated: '',
  primaryGoal: '',
}

interface ValidationErrors {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  selectActivity: string
  isOffersCreated: string
  primaryGoal: string
}

interface State {
  startDate: Moment | undefined
  startTime: Moment | undefined
  endDate: Moment | undefined
  endTime: Moment | undefined
  selectedActivity: number | undefined
  isOffersCreated: boolean
  isActivityScheduled: boolean
  errorMessages: ValidationErrors
  selectedPrimaryGoal: string | undefined
  showUnscheduleConfirmation: boolean
  showUnlinkConfirmation: boolean
  campaignUnscheduled: boolean
  campaignUnlinked: boolean
}

export const initialState: State = {
  startDate: undefined,
  startTime: undefined,
  endDate: undefined,
  endTime: undefined,
  selectedActivity: undefined,
  isOffersCreated: false,
  isActivityScheduled: false,
  selectedPrimaryGoal: undefined,
  showUnscheduleConfirmation: false,
  showUnlinkConfirmation: false,
  campaignUnscheduled: false,
  campaignUnlinked: false,
  errorMessages: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    selectActivity: '',
    isOffersCreated: '',
    primaryGoal: '',
  },
}

export default function AdobeTargetIntegration() {
  const dispatch = useAppDispatch()

  const [state, setState] = useState<State>(initialState)
  const {
    endTime,
    endDate,
    startDate,
    startTime,
    errorMessages,
    isOffersCreated,
    selectedActivity,
    isActivityScheduled,
    selectedPrimaryGoal,
    campaignUnscheduled,
    campaignUnlinked,
  } = state

  const { activitiesList, isLoading, timeZone, error } = useAppSelector(
    (state) => state.adobeTarget
  )

  const {
    campaignData: {
      project_id,
      _id: campaign_id,
      campaign_data: { integration_data },
    },
  } = useAppSelector((state) => state.campaignStates)

  useEffect(() => {
    if (!activitiesList && project_id && !campaignUnlinked) {
      dispatch(setLoading({ field: 'activities', value: true }))
      fetchActivities(project_id)
        .then((res) => {
          dispatch(updateActivities(res.data))
          dispatch(setLoading({ field: 'activities', value: false }))
        })
        .catch((err) => {
          dispatch(setLoading({ field: 'activities', value: false }))
          dispatch(setError(err.message))
        })
    }
    if (!timeZone) {
      fetchTimezone(project_id)
        .then((res) => {
          dispatch(setTimezone(res.data))
        })
        .catch((err) => {
          dispatch(setLoading({ field: 'activities', value: false }))
          dispatch(setError(err.message))
        })
    }
    if (
      !selectedActivity &&
      integration_data?.offers &&
      integration_data?.activityId &&
      !campaignUnlinked
    ) {
      setState({
        ...state,
        isOffersCreated: true,
        selectedActivity: integration_data.activityId,
      })
    }

    if (
      timeZone &&
      integration_data?.primaryGoal &&
      integration_data?.campaignTestDate &&
      integration_data?.campaignStartTestDate &&
      (!startDate || !startTime || !endDate || !endTime) &&
      !campaignUnscheduled
    ) {
      const initialStartDatetime = moment
        .utc(integration_data?.campaignStartTestDate)
        .tz(timeZone)
      const initialEndDatetime = moment
        .utc(integration_data?.campaignTestDate)
        .tz(timeZone)
      setState({
        ...state,
        isActivityScheduled: true,
        endDate: initialEndDatetime,
        endTime: initialEndDatetime,
        startTime: initialStartDatetime,
        startDate: initialStartDatetime,
        selectedPrimaryGoal: integration_data?.primaryGoal,
      })
    }
  }, [
    state,
    endDate,
    endTime,
    dispatch,
    timeZone,
    startDate,
    startTime,
    project_id,
    activitiesList,
    selectedActivity,
    campaignUnlinked,
    campaignUnscheduled,
    integration_data?.offers,
    integration_data?.activityId,
    integration_data?.primaryGoal,
    integration_data?.campaignTestDate,
    integration_data?.campaignStartTestDate,
  ])

  const onDateTimeChange = (val: any, type: string): void => {
    setState({
      ...state,
      ...getChangedSchedulerValues({
        state,
        type,
        value: val,
      }),
    })
  }

  const validateInputs = (): boolean => {
    let isFormValid = true
    const validationErrors: ValidationErrors = initialValidationErrors
    // Validate that all required values exist
    if (!selectedActivity) {
      isFormValid = false
      validationErrors.selectActivity = 'Please select an Adobe Target activity'
    }
    if (!startDate || !startTime) {
      isFormValid = false
      validationErrors.startDate =
        'Experiment start date and time fields are required'
    }
    if (!endDate || !endTime) {
      isFormValid = false
      validationErrors.endDate =
        'Experiment end date and time fields are required'
    }
    if (!selectedPrimaryGoal) {
      isFormValid = false
      validationErrors.primaryGoal = 'Primary goal field is required'
    }
    if (!isOffersCreated) {
      isFormValid = false
      validationErrors.isOffersCreated = 'Offers have not been created'
    }
    setState({ ...state, errorMessages: validationErrors })
    return isFormValid
  }

  const onSchedule = () => {
    if (
      validateInputs() &&
      startDate &&
      startTime &&
      endDate &&
      endTime &&
      timeZone
    ) {
      const test_start_time = moment()
        .tz(timeZone)
        .set({
          year: startDate.year(),
          month: startDate.month(),
          date: startDate.date(),
          hours: startTime.hours(),
          minutes: startTime.minutes(),
        })
        .utc()
        .format('YYYY-MM-DD HH:mm')
      const test_end_time = moment()
        .tz(timeZone)
        .set({
          year: endDate.year(),
          month: endDate.month(),
          date: endDate.date(),
          hours: endTime.hours(),
          minutes: endTime.minutes(),
        })
        .utc()
        .format('YYYY-MM-DD HH:mm')
      const schedulePayload = {
        test_start_time,
        test_end_time,
        primary_goal: selectedPrimaryGoal,
      }
      dispatch(setLoading({ field: 'scheduler', value: true }))
      scheduleCampaign(project_id, campaign_id, schedulePayload)
        .then(() => {
          dispatch(setLoading({ field: 'scheduler', value: false }))
          setState({
            ...state,
            isActivityScheduled: true,
            errorMessages: initialValidationErrors,
            campaignUnscheduled: false,
          })
        })
        .catch((err) => {
          dispatch(setLoading({ field: 'scheduler', value: false }))
          dispatch(setError(err.message))
        })
    }
  }

  const getChangedSchedulerValues = ({ state, type, value }) => {
    const { startDate, startTime, endDate, endTime } = state
    switch (type) {
      case 'startDate':
        return {
          startDate: moment(value),
          endDate: moment(value).isSameOrAfter(endDate)
            ? moment(value)
            : endDate,
          startTime: startTime
            ? moment(value).set({
                hours: startTime.hours(),
                minutes: startTime.minutes(),
              })
            : undefined,
        }
      case 'startTime':
        return {
          startDate: startDate
            ? startDate.set({
                hours: value.hours(),
                minutes: value.minutes(),
              })
            : undefined,
          startTime: moment(value),
          endTime: endTime && value.format() > endTime.format(),
        }
      case 'endDate':
        return {
          endDate: moment(value),
          endTime: endTime
            ? moment(value).set({
                day: endTime.day(),
                month: endTime.month(),
                year: endTime.year(),
              })
            : undefined,
        }
      case 'endTime':
        return {
          endDate: endDate
            ? moment(endDate).set({
                hour: value.hour(),
                minute: value.minute(),
              })
            : undefined,
          endTime: moment(value),
        }
      default:
        return {}
    }
  }

  return (
    <>
      <Drawer.Content>
        <Form>
          <Spinner isSpinning={Object.values(isLoading).includes(true)}>
            <div
              data-testid="adobeIntegrationTitle"
              className="text-base font-medium text-gray-400"
            >
              Connect your Adobe Target with your Jacquard experiment.
            </div>
            {isActivityScheduled && (
              <Alert type="success" className="flex items-center mt-6">
                This experiment is currently saved and collecting data from
                Adobe Target.
              </Alert>
            )}
            <SelectComponent
              className="mt-8"
              isLoading={isLoading.activities}
              selectOptions={activitiesList}
              isSelectDisabled={
                isLoading.activities || isActivityScheduled || isOffersCreated
              }
              dataTestId="selectActivity"
              onChange={(val) => setState({ ...state, selectedActivity: val })}
              selectValue={selectedActivity}
              labelTitle="Target Activity"
              bottomText={`This is a draft activity
             that has at least one configured experience.`}
              errorMessage={errorMessages.selectActivity}
              defaultOption="Select an unscheduled activity"
            />
            <DrawerContent
              onDateTimeChange={onDateTimeChange}
              campaignId={campaign_id}
              projectId={project_id}
              isLoading={isLoading}
              currentState={state}
              timeZone={timeZone}
              setState={setState}
            />
          </Spinner>
        </Form>
      </Drawer.Content>
      <Drawer.Footer error={error}>
        <Button
          loading={isLoading.scheduler}
          variant={isActivityScheduled ? 'success' : 'primary'}
          disabled={
            isActivityScheduled ||
            !selectedActivity ||
            !isOffersCreated ||
            !startDate ||
            !startTime ||
            !endDate ||
            !endTime
          }
          data-testid="scheduleBtn"
          onClick={onSchedule}
        >
          {isActivityScheduled ? 'Saved' : 'Save schedule'}
        </Button>
      </Drawer.Footer>
    </>
  )
}
