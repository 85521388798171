import SingleSelect, {
  SelectValue,
  SingleValue,
} from 'common/components/singleSelect'
import Tooltip from 'common/components/Tooltip'

import { selectCustomStyles } from './PhraseeXSelectorStyles'

interface Props {
  label?: string
  className?: string
  placeholder?: string
  datatestId?: string
  isDisabled: boolean
  value?: string
  onChange: (val: SingleValue<SelectValue>) => void
  options: { label: string; value: string; tooltip?: string }[]
}
export const PhraseeXSelector = ({
  label = '',
  className = '',
  placeholder = '',
  datatestId = '',
  isDisabled,
  value,
  onChange,
  options,
}: Props) => {
  const tooltip: string | undefined = options.find(
    (option) => option.value === value
  )?.tooltip

  return (
    <Tooltip
      placement="left"
      overlayClassName="max-w-xs break-words"
      show={tooltip !== undefined}
      data-testid={`tooltip-${datatestId}`}
      overlay={tooltip}
    >
      <SingleSelect
        label={label}
        className={className}
        placeholder={placeholder}
        data-testid={datatestId}
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        options={options}
        menuPortalTarget={document.body}
        menuShouldBlockScroll
        customStyles={selectCustomStyles}
      />
    </Tooltip>
  )
}
