import { generatePath, Link } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  Close,
  Email,
  Facebook,
  Google,
  LandingPage,
  Push,
  Sms,
} from 'common/icons'
import type { IconType } from 'common/icons/Icon'
import {
  ResponseDistributionChannel as BackendDistributionChannel,
  ResponseDistributionChannel,
  ResponseStatus as BackendStatus,
} from 'common/interfaces/campaign'
import {
  getActiveProjects,
  selectDistributionChannelFilter,
  selectProjectFilter,
  selectStatusFilter,
} from 'features/campaigns/store/campaignSlice'
import { CampaignStateFilter } from 'features/campaigns/store/interfaces'

import Item from './components/subNavItem'
import { TRANSITION_DURATION } from './constants'
import { StateFilterType } from './interfaces'
import { closeFilterPanel } from './navigationSlice'
import { campaignsPath } from './paths'
import { staticCampaignSubRoutes } from './staticSubRoutes.campaigns'

function getIconForDistributionChannel(
  channel: ResponseDistributionChannel
): IconType | undefined {
  switch (channel) {
    case 'sms':
      return Sms
    case 'email':
      return Email
    case 'push_notification':
      return Push
    case 'adwords':
      return Google
    case 'facebook':
      return Facebook
    case 'landing_page':
      return LandingPage
    default:
      return undefined
  }
}

const ExperimentFilterPanel = () => {
  const dispatch = useAppDispatch()
  const projects = useAppSelector(getActiveProjects)

  const { filters } = useAppSelector((state) => state.campaigns)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const selectedProjectId = useAppSelector(
    (state) => state.campaigns.filters.projectId
  )
  const selectedCampaignStateFilter =
    filters.distributionChannel || filters.status

  const selectFilterKey = (
    key: CampaignStateFilter,
    type?: StateFilterType
  ) => {
    if (type === 'status') {
      dispatch(selectStatusFilter(key as BackendStatus))
    } else if (type === 'distributionChannel') {
      dispatch(
        selectDistributionChannelFilter(key as BackendDistributionChannel)
      )
    }
  }

  const projectsSubCategories = projects.map(
    ({ id, name, distributionChannel }) => ({
      key: id,
      icon: getIconForDistributionChannel(distributionChannel),
      title: name,
      to: `/campaigns`,
    })
  )

  return (
    <div
      data-cy="experiment-filter-menu"
      className={`duration-${TRANSITION_DURATION} bg-warmGray-50 z-20 overflow-y-auto flex-shrink-0`}
    >
      <div className="w-64 mt-6 ml-6 mr-6">
        <div className="text-coolGray-400 flex justify-between items-center">
          Filters
          <div onClick={() => dispatch(closeFilterPanel())}>
            <Close className="w-3 h-3 cursor-pointer" />
          </div>
        </div>

        <div>
          <div className="mt-2 relative -left-2">
            {staticCampaignSubRoutes.map(
              ({
                icon: subCategoryIcon,
                title: subCategoryTitle,
                key,
                type,
              }) => (
                <Link
                  key={key}
                  to={generatePath(campaignsPath, { accountId })}
                  onClick={() => selectFilterKey(key, type && type)}
                >
                  <Item
                    icon={subCategoryIcon}
                    title={subCategoryTitle}
                    key={subCategoryTitle}
                    isSelected={selectedCampaignStateFilter === key}
                  />
                </Link>
              )
            )}
          </div>
        </div>

        <div
          className={`text-coolGray-400 flex justify-between items-center 
      mt-8 h-8`}
        >
          Projects
        </div>

        <div>
          <div className="mt-2 relative -left-2">
            {projectsSubCategories.map(
              ({ icon: subCategoryIcon, title: subCategoryTitle, key }) => (
                <Link
                  key={key}
                  to={generatePath(campaignsPath, { accountId })}
                  onClick={() =>
                    dispatch(
                      selectProjectFilter({ id: key, title: subCategoryTitle })
                    )
                  }
                >
                  <Item
                    icon={subCategoryIcon}
                    title={subCategoryTitle}
                    key={subCategoryTitle}
                    isSelected={selectedProjectId === key}
                  />
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExperimentFilterPanel
