type Props = {
  isEditMode: boolean
  teamName: string
}

function Header({ isEditMode, teamName }: Props) {
  return (
    <div className="flex pl-6 pb-6">
      <h1 className="mt-2 text-2xl font-bold overflow-ellipsis max-w-xs overflow-hidden">
        {isEditMode ? `Add users to '${teamName}'` : 'Create a new team'}
      </h1>
    </div>
  )
}

export default Header
