import Button from 'common/components/button/Button'
import Card from 'common/components/Card'

interface DeleteExperimentCardProps {
  isLoading: boolean
  deleteExperiment: () => void
}

export default function DeleteExperimentCard({
  isLoading,
  deleteExperiment,
}: DeleteExperimentCardProps) {
  return (
    <Card>
      <div className="font-bold mb-4">Delete experiment</div>
      <div className="mb-4 text-coolGray-400">
        Delete the experiment from your Cordial message. This will not delete
        the message, just the experiment settings.
      </div>
      <div className="justify-end flex flex-wrap">
        <Button
          variant="secondary"
          disabled={false}
          loading={isLoading}
          data-testid="delete-experiment-button"
          onClick={deleteExperiment}
        >
          Delete
        </Button>
      </div>
    </Card>
  )
}
