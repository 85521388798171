import { useState } from 'react'
import { FilterProps, Renderer } from 'react-table'
import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'

import Button from 'common/components/button'
import CheckboxComponent from 'common/components/checkbox'
import { Filters } from 'common/icons'

type Props = {
  defaultFilters: string[]
}

function Filter({ defaultFilters }: Props) {
  return function SelectColumnFilter<D extends {}>({
    column: { filterValue = [], setFilter, preFilteredRows, id, filteredRows },
  }): Renderer<FilterProps<D>> {
    const [checkedItems, setCheckedItems] = useState<string[]>(filterValue)
    const [isOpened, setIsOpened] = useState(false)

    const onClickCheckbox = (key) => {
      if (checkedItems.some((currentKey) => currentKey === key)) {
        setCheckedItems((checkedItems) =>
          checkedItems.filter((item) => item !== key)
        )
      } else {
        setCheckedItems((checkedItems) => [...checkedItems, key])
      }
    }
    const hasTableEnoughHeightToFitDropdown = filteredRows.length > 5
    return (
      <>
        <Dropdown
          trigger={['click']}
          key={`${filteredRows.length}`}
          overlay={
            <CustomMenu
              checkedItems={checkedItems}
              options={[
                {
                  key: 'approved',
                  onClick: onClickCheckbox,
                  label: 'Approved',
                },
                { key: 'dropped', onClick: onClickCheckbox, label: 'Dropped' },
                { key: 'live', onClick: onClickCheckbox, label: 'Live' },
                { key: 'pending', onClick: onClickCheckbox, label: 'Pending' },
              ]}
              onConfirm={() => {
                setIsOpened(false)
                setFilter(checkedItems)
              }}
              onReset={() => setCheckedItems(defaultFilters)}
            />
          }
          visible={isOpened}
          getPopupContainer={
            hasTableEnoughHeightToFitDropdown
              ? (trigger) => {
                  // move the dropdown when scrolling a parent of the table
                  return trigger.parentNode as HTMLElement
                }
              : () => {
                  // Allows to display the dropdown without scrolling
                  // It would be better to have a min height for the table with an empty state
                  return document.body
                }
          }
          onVisibleChange={setIsOpened}
        >
          <Button
            className="absolute top-2"
            variant="icon"
            prefixIcon={<Filters />}
          />
        </Dropdown>
      </>
    )
  }
}

const CustomMenu = ({
  options,
  checkedItems,
  onConfirm,
  onReset,
}: {
  options: any[]
  checkedItems: string[]
  onConfirm: () => void
  onReset: () => void
}) => (
  <div className="shadow box-border z-40">
    <Menu selectable={false}>
      {options.map((option) => (
        <MenuItem
          disabled={option.isDisabled}
          key={option.key}
          className="bg-white pt-4 px-2 pb-2 hover:bg-coolGray-50 text-coolGray-600 font-medium cursor-pointer"
        >
          <CheckboxComponent
            isChecked={checkedItems?.some((key) => key === option.key)}
            onChange={() => option.onClick(option.key)}
            className="mr-2"
            label={option.label}
          />
        </MenuItem>
      ))}
    </Menu>
    <div className="flex justify-between px-2 border-t border-coolGray-200 bg-white">
      <Button variant="link" onClick={onConfirm}>
        OK
      </Button>
      <Button
        onClick={() => {
          onReset()
        }}
        variant="link"
      >
        Reset
      </Button>
    </div>
  </div>
)

export default Filter
