import { useDocumentTitle } from 'common/hooks/custom'
import MainContent from 'features/unifiedFlow/components/MainContent'

import ContentLoadingOverlay from '../components/ContentLoadingOverlay'
import { useWebsocketStatus } from '../hooks'

import useElementState from './hooks/useElementState'
import CenterSection from './centerSection'
import LeftSection from './leftSection'
import RightSection from './rightSection'

const Brief = () => {
  useDocumentTitle('Brief | Jacquard')

  const { isWebsocketLoading, websocketAction } = useWebsocketStatus()

  useElementState()

  return (
    <MainContent className="flex-wrap pb-4" id="main-content-page">
      <ContentLoadingOverlay
        isLoading={isWebsocketLoading}
        action={websocketAction}
      />
      <LeftSection />
      <CenterSection />
      <RightSection />
    </MainContent>
  )
}

export default Brief
