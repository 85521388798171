import { stringToNumber } from 'common/helpers/numeric'

const removeNonNumericChars = (value: string) =>
  value.replace(/[^0-9.]/g, '').trim()

const addPrefix = (value: string, prefix?: string) =>
  prefix && value !== '' ? `${prefix}${removeNonNumericChars(value)}` : value

const addPostfix = (value: string, postfix?: string) =>
  postfix && value !== '' ? `${removeNonNumericChars(value)}${postfix}` : value

const addPostfixAndPrefix = (
  value: string,
  postfix?: string,
  prefix?: string
) => {
  return addPostfix(addPrefix(value, prefix), postfix)
}

const addZeroes = (value: string, precision: number) => {
  const valueSplit = value.split('.')

  return valueSplit.length === 1 || valueSplit[1].length < precision
    ? Number(value).toFixed(precision)
    : value
}

const moveCaretBack = (inputElement: HTMLInputElement, valueLength: number) =>
  setTimeout(() => {
    const caretPosition = valueLength - 1
    inputElement.setSelectionRange(caretPosition, caretPosition)
  }, 0)

const parseValueWithRange = (value: string, min?: number, max?: number) => {
  const numericValue = stringToNumber(value)

  if (min !== undefined && max !== undefined && numericValue !== undefined) {
    return min <= numericValue && max >= numericValue
      ? value
      : max < numericValue
      ? String(max)
      : String(min)
  } else if (min !== undefined && numericValue !== undefined) {
    return min <= numericValue ? value : String(min)
  } else if (max !== undefined && numericValue !== undefined) {
    return max >= numericValue ? value : String(max)
  }

  return value
}

export {
  addPostfixAndPrefix,
  removeNonNumericChars,
  addZeroes,
  parseValueWithRange,
  moveCaretBack,
}
