import { TemplateElement } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import ElementActionsMenu from './ElementActionsMenu'
import ElementCard from './ElementCard'
import ElementCardIcon from './ElementCardIcon'
import ExperimentTypeBadges from './ExperimentTypeBadges'

type Props = {
  element: TemplateElement
  isRemoveOptionShown?: boolean
  className?: string
}

const ElementTemplateCard = ({
  element,
  className,
  isRemoveOptionShown,
}: Props) => {
  const dispatch = useAppDispatch()

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )
  const areElementsDisabled = useAppSelector(
    (state) => state.unifiedFlow.areElementsDisabled
  )

  const { icon, display_name, elements } = element

  const isTemplateElementSelected = element.elements
    .filter(isComponentElement)
    .some((element) => element.element_id === selectedElementId)

  const isTemplateSelected = element.element_id === selectedElementId

  const badgesState = getExperimentBadgesState(element)

  return (
    <div
      className={cx(
        'p-4 border group',
        {
          'border-maroon-500': isTemplateElementSelected || isTemplateSelected,
          'border-coolGray-300': !isTemplateElementSelected,
          'bg-white': !areElementsDisabled,
          'bg-coolGray-100': areElementsDisabled,
        },
        className
      )}
    >
      <div className="flex mb-2">
        {icon && (
          <ElementCardIcon
            icon={icon}
            className={cx({
              'text-maroon-300': isTemplateElementSelected,
            })}
          />
        )}
        <div className="ml-4">
          <div
            className={cx('text-base font-medium overflow-hidden', {
              'text-coolGray-400': areElementsDisabled,
              'text-coolGray-800': !areElementsDisabled,
            })}
          >
            {display_name}
          </div>
          {badgesState && (
            <ExperimentTypeBadges className="mt-1 min-h-5" {...badgesState} />
          )}
        </div>
        <ElementActionsMenu
          isConfigurable={true}
          isRemoveOptionShown={isRemoveOptionShown}
          isSelected={isTemplateSelected}
          element={element}
        />
      </div>
      {elements &&
        elements
          .filter(isComponentElement)
          .map(
            ({
              element_id,
              name,
              display_name,
              status,
              approved,
              nlg_status,
            }) => {
              const isElementDisabled =
                areElementsDisabled || status === 'disabled'
              const hasError = nlg_status === 'error'

              return (
                <ElementCard
                  className="mb-2"
                  key={element_id}
                  title={display_name || name}
                  isDisabled={isElementDisabled}
                  hasSmallTitle={true}
                  showCustomBriefIcon={false}
                  isApproved={approved}
                  isSelected={
                    selectedElementId === element_id && !isElementDisabled
                  }
                  hasError={hasError}
                  onClick={() =>
                    !isElementDisabled &&
                    dispatch(selectElement({ elementId: element_id }))
                  }
                />
              )
            }
          )}
    </div>
  )
}

export default ElementTemplateCard
