import { ReactNode } from 'react'

type Props = {
  onClick: () => void
  children: ReactNode
}

const ListWidgetItem = ({ onClick, children }: Props) => {
  return (
    <li className="flex px-4 py-2 break-words group hover:bg-coolGray-50">
      <button onClick={onClick} className="flex ml-2 text-left gap-1">
        {children}
      </button>
    </li>
  )
}

export default ListWidgetItem
