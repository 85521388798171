import { Variant } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'

export const updateVariantStatus = async (
  variantId: string,
  campaignId: string,
  status: string
): Promise<Variant> => {
  const response = await connectInternalApi.patch<Variant>(
    `/v1/core/monorail/variants/${variantId}`,
    {
      campaign_id: campaignId,
      bandit_status: {
        status,
      },
    }
  )
  return response.data
}

const api = {
  updateVariantStatus,
}

export default api
