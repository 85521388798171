import { PropsWithChildren, ReactElement } from 'react'

import { CellProps, Column, Row } from 'common/components/table'
import { PercentageCell } from 'common/components/table/cells'
import BaseCell from 'common/components/table/cells/Base'
import { getCellValue } from 'common/components/table/helpers'
import Link from 'common/typography/Link'
import { replaceNewLineEmojiAsString } from 'common/variantsUtils'

export interface Data {
  projectName: string | undefined
  campaignName: string | undefined
  sendDate: string | undefined
  campaignId: string | undefined
  winner: ReactElement | undefined
  winnerExport: string
  impressed: number | undefined
  helpful: number | undefined
  curious: number | undefined
  excited: number | undefined
  surprising: number | undefined
  appreciative: number | undefined
  urgent: number | undefined
  openUplift: number | undefined
  clickUplift: number | undefined
}

const roundNumber = (val: number | undefined): string | undefined =>
  val?.toFixed(1)

const RoundedPercentageCell = (
  props: PropsWithChildren<CellProps<Data, number | undefined>>
) => PercentageCell({ ...props, value: roundNumber(props.value) })

const ScoreCell = (
  props: PropsWithChildren<CellProps<Data, number | undefined>>
) => <BaseCell>{roundNumber(props.value)}</BaseCell>

const getScoreCellExportValue = (row: Row<Data>, column: Column<Data>) => {
  const value = getCellValue<Data>(row, column)
  return roundNumber(value)
}

const getUpliftCellExportValue = (row: Row<Data>, column: Column<Data>) => {
  const value = getCellValue<Data>(row, column)
  return value ? `${roundNumber(value)}%` : ''
}

const width = 120

const campaignColumnName = 'Experiment name'
const projectColumnName = 'Project'
const sendDateColumnName = 'Send date'
const winnerColumnName = 'Winner'
const sentimentsColumnName = 'Sentiment scores'
const impressedColumnName = 'Impressed'
const helpfulColumnName = 'Helpful'
const curiousColumnName = 'Curious'
const excitedColumnName = 'Excited'
const surprisingColumnName = 'Surprising'
const appreciativeColumnName = 'Appreciative'
const urgentColumnName = 'Urgent'
export const openUpliftColumnName = 'Open Uplift'
export const clickUpliftColumnName = 'Click Uplift'

export const commonColumnNames = [
  campaignColumnName,
  winnerColumnName,
  sentimentsColumnName,
  impressedColumnName,
  helpfulColumnName,
  curiousColumnName,
  excitedColumnName,
  surprisingColumnName,
  appreciativeColumnName,
  urgentColumnName,
]

export const OPEN_UPLIFT_COLUMN: Column<Data> = {
  Header: openUpliftColumnName,
  accessor: 'openUplift',
  Cell: RoundedPercentageCell,
  align: 'right',
  minWidth: 150,
  width: 150,
  getCellExportValue: getUpliftCellExportValue,
}

export const CLICK_UPLIFT_COLUMN: Column<Data> = {
  Header: clickUpliftColumnName,
  accessor: 'clickUplift',
  Cell: RoundedPercentageCell,
  align: 'right',
  minWidth: 150,
  width: 150,
  getCellExportValue: getUpliftCellExportValue,
}
export const PROJECT_COLUMN: Column<Data> = {
  Header: projectColumnName,
  accessor: 'projectName',
  minWidth: 240,
  isResizable: true,
}

export const SEND_DATE_COLUMN: Column<Data> = {
  Header: sendDateColumnName,
  accessor: 'sendDate',
  minWidth: 240,
  isResizable: true,
}

export const COLUMNS: Column<Data>[] = [
  {
    Header: campaignColumnName,
    accessor: 'campaignName',
    minWidth: 150,
    width: 200,
    maxWidth: 800,
    isResizable: true,
    Cell: (props) => {
      const { campaignId, campaignName } = props.row.original
      return (
        <BaseCell>
          <Link to={`/campaigns/${campaignId}`} className="break-words">
            {campaignName}
          </Link>
        </BaseCell>
      )
    },
  },
  {
    Header: winnerColumnName,
    accessor: 'winner',
    minWidth: 400,
    isResizable: true,
    getCellExportValue: (row: Row<Data>) => {
      return replaceNewLineEmojiAsString(row.original.winnerExport)
    },
  },
  {
    Header: sentimentsColumnName,
    columns: [
      {
        Header: impressedColumnName,
        accessor: 'impressed',
        Cell: ScoreCell,
        align: 'right',
        getCellExportValue: getScoreCellExportValue,
        width,
      },
      {
        Header: helpfulColumnName,
        accessor: 'helpful',
        Cell: ScoreCell,
        align: 'right',
        getCellExportValue: getScoreCellExportValue,
        width,
      },
      {
        Header: curiousColumnName,
        accessor: 'curious',
        Cell: ScoreCell,
        align: 'right',
        getCellExportValue: getScoreCellExportValue,
        width,
      },
      {
        Header: excitedColumnName,
        accessor: 'excited',
        Cell: ScoreCell,
        align: 'right',
        getCellExportValue: getScoreCellExportValue,
        width,
      },
      {
        Header: surprisingColumnName,
        accessor: 'surprising',
        Cell: ScoreCell,
        align: 'right',
        getCellExportValue: getScoreCellExportValue,
        width,
      },
      {
        Header: appreciativeColumnName,
        accessor: 'appreciative',
        Cell: ScoreCell,
        align: 'right',
        getCellExportValue: getScoreCellExportValue,
        width,
      },
      {
        Header: urgentColumnName,
        accessor: 'urgent',
        Cell: ScoreCell,
        align: 'right',
        getCellExportValue: getScoreCellExportValue,
        width,
      },
    ],
  },
]
