import { useLayoutEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getAllComponentElements, getElementHasVariants } from '../../helpers'
import { useContent, useSelectedElement } from '../../hooks'

const useElementState = () => {
  const dispatch = useAppDispatch()

  const { content } = useContent()
  const { data: stateSelectedElement } = useSelectedElement()

  useLayoutEffect(() => {
    let selectedElementId: number | undefined = undefined
    if (stateSelectedElement && isTemplateElement(stateSelectedElement)) {
      const firstElement = stateSelectedElement.elements[0]
      if (getElementHasVariants(firstElement)) {
        selectedElementId = firstElement.element_id
      }
    } else if (
      stateSelectedElement &&
      isComponentElement(stateSelectedElement)
    ) {
      if (getElementHasVariants(stateSelectedElement)) {
        selectedElementId = stateSelectedElement.element_id
      }
    } else {
      const allComponenntElements = getAllComponentElements(content)
      allComponenntElements.some((element) => {
        if (getElementHasVariants(element)) {
          selectedElementId = element.element_id
          return true
        }
        return false
      })
    }

    if (selectedElementId) {
      dispatch(selectElement({ elementId: selectedElementId }))
    }
    // This hook should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useElementState
