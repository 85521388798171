import {
  Campaign as GraphQLResponseCampaigns,
  CampaignFilter,
  Project as GraphQLResponseProjects,
  TestedContentSection,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { gql } from 'graphql-request'

import { CancelTokenSource, connectInternalApi, fetchGraphQL } from 'common/api'
import {
  ResponseDistributionChannel,
  ResponseDistributionType,
  ResponseStatus,
} from 'common/interfaces/campaign'

export interface ResponseCampaign
  extends Pick<
    GraphQLResponseCampaigns,
    | '_id'
    | 'name'
    | 'created'
    | 'status'
    | 'send_date'
    | 'campaign_configuration'
    | 'user_id'
    | 'campaignOwner'
    | 'project'
    | 'grammar_id'
  > {
  _id: string
  name: string
  created: string
  send_date?: string
  campaign_configuration: {
    distribution_channel: ResponseDistributionChannel
    distribution_type?: ResponseDistributionType
  }
  status: ResponseStatus
  user_id: string
  campaignOwner?: { _id: string; fname?: string; lname?: string }
  project: { _id: string; name: string }
  grammar_id: string
  is_mismatching_configuration?: boolean
}

export interface CampaignsResponse {
  campaigns: ResponseCampaign[]
  count: number
}

export async function fetchCampaigns({
  variables,
  source,
  projectScreensFeatureFlag,
}: {
  variables: {
    page: number
    pageSize: number
    filter: CampaignFilter & {
      accountId: string
    }
  }
  source: CancelTokenSource
  projectScreensFeatureFlag?: boolean
}): Promise<CampaignsResponse> {
  const query = gql`
    query campaigns($page: Int, $pageSize: Int, $filter: CampaignFilter) {
      data: campaigns(page: $page, pageSize: $pageSize, filter: $filter) {
        campaigns {
          _id
          name
          campaign_configuration {
            distribution_channel
          }
          created
          status
          send_date
          user_id
          grammar_id
          ${projectScreensFeatureFlag ? 'is_mismatching_configuration' : ''}
          campaignOwner {
            fname
            lname
          }
          project {
            _id
            name
          }
        }
        count
      }
    }
  `

  return fetchGraphQL<CampaignsResponse>(
    {
      query,
      variables,
    },
    {
      cancelToken: source.token,
    }
  )
}

export interface ResponseProjects
  extends Pick<
    GraphQLResponseProjects,
    // TODO add the created field when the type definition is updated
    '_id' | 'name' | 'project_configuration' // | 'created'
  > {
  _id: string
  name: string
  created: string
  project_configuration: {
    campaign_configurations:
      | {
          distribution_channel: ResponseDistributionChannel
          distribution_type: ResponseDistributionType
          testing_method?: {
            tested_content_sections?: { section: TestedContentSection }[]
          }
        }
      | undefined
    test_project?: boolean
  }
  project_type: string
  assigned_grammars: string[]
  deleted: boolean
}

export type ProjectsResponse = ResponseProjects[]

export async function fetchProjects(
  accountId: string,
  source: CancelTokenSource
): Promise<ProjectsResponse> {
  const query = gql`
    query projects($filter: ProjectFilter) {
      data: projects(filter: $filter) {
        _id
        name
        created
        project_configuration {
          campaign_configurations {
            distribution_channel
            distribution_type
            testing_method {
              tested_content_sections {
                section
              }
            }
          }
          test_project
        }
        project_type
        assigned_grammars
        deleted
      }
    }
  `

  const variables = {
    filter: {
      accountId,
      includeDeleted: true,
    },
  }

  return fetchGraphQL<ProjectsResponse>(
    { query, variables },
    {
      cancelToken: source.token,
    }
  )
}

export type ProcessedResults = {
  columns: {
    name: string
    displayName: string
    isEditable: boolean
    total: number
    metric: string
    type: 'percentage' | 'integer'
    relatesTo?: string
  }[]
  finalColumns: {
    displayName: string
    metric: string
  }[]
  rows: {
    id: string
    variant: string
    data: { name: string; value: number | undefined }[]
    error?: { originalVariant: string }
  }[]
}

export type Column = {
  metric: string
  isEditable: boolean
  humanReadableMetric: string
  type: 'percentage' | 'integer'
}

export type ProcessedResultsResponse = {
  status: 'success' | 'warning'
  columns: (Column & {
    relatesTo?: string
  })[]
  finalColumns: Column[]
  variants: {
    status: 'success' | 'missing'
    userInput: string | undefined
    text: string | undefined
    results: { name: string; value: number }[]
    _id: string
  }[]
}

const mapProcessResultsResponse = (
  response: ProcessedResultsResponse
): ProcessedResults => {
  const formatDisplayName = (column: Column) =>
    column.type === 'percentage'
      ? `${column.humanReadableMetric} (%)`
      : column.humanReadableMetric
  const columns = response.columns.map((column) => ({
    name: column.metric,
    displayName: formatDisplayName(column),
    isEditable: column.isEditable,
    metric: column.metric,
    relatesTo: column.relatesTo,
    type: column.type,
    total: response.variants.reduce(
      (acc, variant) =>
        acc +
        (variant.results.find((result) => result.name === column.metric)
          ?.value ?? 0),
      0
    ),
  }))
  const rows = response.variants.map((variant) => ({
    id: variant._id,
    variant:
      // the backend might return a different variant than the one we sent on success when the difference with the variant is small
      (variant.status === 'success' ? variant.text : variant.userInput) ?? '',
    data: variant.results.map((result) => ({
      name: result.name,
      value: result.value,
    })),
    error:
      variant.status === 'success'
        ? undefined
        : { originalVariant: variant.text ?? '' },
  }))
  return {
    columns,
    rows,
    finalColumns: response.finalColumns
      .filter((column) => column.isEditable)
      .map((column) => ({
        displayName: formatDisplayName(column),
        metric: column.metric,
      })),
  }
}

export const processInputResults = ({
  campaignId,
  inputResults,
  inputType,
}: {
  campaignId: string
  inputResults: string
  inputType: 'integer' | 'percentage'
}): Promise<ProcessedResults | undefined> => {
  return connectInternalApi
    .post<ProcessedResultsResponse>(
      `v1/core/main/campaigns/${campaignId}/manual-results/validate`,
      {
        inputString: inputResults,
        inputType,
      }
    )
    .then((results) => {
      return mapProcessResultsResponse(results.data)
    })
}

type SubmitInputResultsItem = {
  userInput: string
  results: { name: string; value: number }[]
  _id: string
  text: string
}

export const submitInputResults = ({
  campaignId,
  inputType,
  winner,
  splits,
}: {
  campaignId: string
  inputType: 'integer' | 'percentage'
  splits: SubmitInputResultsItem[]
  winner?: Omit<SubmitInputResultsItem, 'userInput'>
}) => {
  return connectInternalApi.post<ProcessedResultsResponse>(
    `v1/core/main/campaigns/${campaignId}/manual-results/submit`,
    {
      winner,
      splits,
      inputType,
    }
  )
}

export type Format = 'percentage' | 'integer'

export const loadSplittingResultsColumns = ({
  campaignId,
}: {
  campaignId: string
}) => {
  type Metric = {
    humanReadableMetric: string
    metric: string
    isEditable: boolean
    type: 'percentage' | 'integer'
  }
  return connectInternalApi.get<{ integer: Metric[]; percentage: Metric[] }>(
    `v1/core/main/campaigns/${campaignId}/manual-results/columns`
  )
}

type Winner = {
  results: { name: string; value: number }[]
  _id: string
  text: string
}

export type InitialResultsResponse = {
  columns: (Column & {
    relatesTo?: string
  })[]
  finalColumns: Column[]
  splits: {
    status: 'success' | 'missing'
    userInput: string | undefined
    text: string | undefined
    results: { name: string; value: number }[]
    _id: string
  }[]
  winner: Winner | undefined
}

export type InitialProcessedResults = ProcessedResults & {
  winner?: Winner
}

export const loadInitialResults = ({
  campaignId,
}: {
  campaignId: string
}): Promise<InitialProcessedResults> => {
  return connectInternalApi
    .get<InitialResultsResponse>(
      `v1/core/main/campaigns/${campaignId}/manual-results/state`
    )
    .then((results) => {
      const { columns, finalColumns, splits, winner } = results.data
      return {
        ...mapProcessResultsResponse({
          status: 'success',
          columns,
          finalColumns,
          variants: splits.map((split) => ({ ...split, status: 'success' })),
        }),
        winner: winner && winner?.results?.length > 0 ? winner : undefined,
      }
    })
}
