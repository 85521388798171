import CampaignMetrics from './components/CampaignMetrics'
import MetricsCard from './experimentsMetrics/MetricsCard'

type Props = {
  accountId: string
}

const OptimizationMetrics = ({ accountId }: Props) => {
  return (
    <div data-cy="optimization-metrics-widget" className="mb-6 md:mb-10">
      <div className="text-xl font-medium text-coolGray-800 mb-4">
        Optimization
      </div>
      <div className="flex w-full flex-col gap-6 md:grid md:grid-cols-3 md:gap-6">
        <MetricsCard title="Experiments" />
        <CampaignMetrics accountId={accountId} campaignType="broadcast" />
        <CampaignMetrics accountId={accountId} campaignType="trigger" />
        <div></div>
      </div>
    </div>
  )
}

export default OptimizationMetrics
