import { useState } from 'react'

import Autocomplete from 'common/components/autcomplete'
import { SelectValue } from 'common/components/autcomplete/Autocomplete'
import TableLoader from 'common/components/loaders/TableLoader'
import Tags from 'common/components/tags'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'

type Props = {
  tags: SelectValue[]
  placeholder: string
  title: string
  subtitle: string
  onAddTag: (tag: string) => void
  onRemoveTag: (tag: string) => void
  onInputChange?: (value: string) => string
  'data-cy': string
  isLoading?: boolean
  maxLengthPerTag?: number
  hasTagButtonLabel?: boolean
  tagButtonLabel?: string
  isFreeText?: boolean
  suggestions?: string[]
  className?: string
}
const TagWidget = ({
  title,
  subtitle,
  placeholder,
  tags,
  onAddTag,
  onRemoveTag,
  onInputChange,
  maxLengthPerTag,
  isLoading,
  hasTagButtonLabel,
  tagButtonLabel,
  isFreeText = true,
  suggestions,
  className,
  'data-cy': dataCy,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const onSearch = (search: string) => {
    setSearchValue(search)
  }

  return (
    <Widget data-cy={dataCy} className={className}>
      <WidgetHeader
        title={title}
        subtitle={subtitle}
        actions={
          <div className="inline-flex">
            <Autocomplete
              className="w-60"
              placeholder="Filter"
              data-cy="tag-search"
              options={tags}
              value={
                searchValue
                  ? { value: searchValue, label: searchValue }
                  : undefined
              }
              defaultInputValue={searchValue}
              onSearch={onSearch}
              backspaceRemovesValue={true}
            />
          </div>
        }
      />
      {isLoading ? (
        <TableLoader />
      ) : (
        <Tags
          className="mt-8"
          data-cy="tags"
          isFreeText={isFreeText}
          suggestions={suggestions}
          onAddClick={onAddTag}
          onRemoveClick={onRemoveTag}
          tags={tags}
          onInputChange={onInputChange}
          placeholder={placeholder}
          searchValue={searchValue}
          maxLength={maxLengthPerTag}
          hasTagButtonLabel={hasTagButtonLabel}
          tagButtonLabel={tagButtonLabel}
        />
      )}
    </Widget>
  )
}

export default TagWidget
