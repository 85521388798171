/* eslint-disable max-lines*/
import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import cx from 'classnames'
import arrayMutators from 'final-form-arrays'
import isEmpty from 'lodash/isEmpty'

import Button from 'common/components/button'
import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import Modal from 'common/components/Modal'
import SingleSelect from 'common/components/singleSelect'
import Table, { Column } from 'common/components/table'
import Tooltip from 'common/components/Tooltip'
import { Add as AddIcon, Minus as MinusIcon } from 'common/icons'
import { ReactComponent as RemoveIcon } from 'common/icons/remove/error.svg'
import useDataIngestionWebsocketSubscription from 'features/admin/dataSources/useDataIngestionWebsocketSubscription'

type Props = {
  isOpen: boolean
  fileName: string
  data: string[][]
  headers: string[]
  onCancel: () => void
  onImport: (values: FormValues) => void
}

type FormValues = {
  fileName: string
  dataSeparator: string | undefined
  categoryLevel: string[]
  productIdColumn?: string
  productNameColumn: string | undefined
  delimiter: string | undefined
  categoryColumn: string
  columnsToExclude: string[]
}

const validateNotEmptyString = (value: string | undefined) =>
  value?.trim() === undefined || value.trim().length === 0
    ? 'Required'
    : undefined

const validateNotUndefined = (value: string | undefined) =>
  value === undefined ? 'Required' : undefined

const CsvProductPreview = ({
  isOpen,
  fileName,
  data,
  headers,
  onCancel,
  onImport,
}: Props) => {
  useDataIngestionWebsocketSubscription('product')
  const [formState, setFormState] = useState<FormValues>({
    fileName: fileName.substring(0, fileName.lastIndexOf('.')) || fileName,
    dataSeparator: undefined,
    categoryLevel: ['', ''],
    productNameColumn: undefined,
    delimiter: undefined,
    categoryColumn: '',
    columnsToExclude: [],
  })

  const handleColumnExclude = (column: string) => {
    setFormState((prevState) => {
      if (prevState.columnsToExclude.includes(column)) {
        return {
          ...prevState,
          columnsToExclude: prevState.columnsToExclude.filter(
            (excludedColumn) => excludedColumn !== column
          ),
        }
      }
      return {
        ...prevState,
        columnsToExclude: [...prevState.columnsToExclude, column],
      }
    })
  }

  const columns: Column[] = headers.map((value) => {
    const isDisabled = formState.columnsToExclude.includes(value)

    return {
      Header: value,
      accessor: value,
      width: 200,
      isDisabled,
      columnAction:
        value !== formState.productIdColumn &&
        value !== formState.productNameColumn &&
        !formState.categoryLevel.includes(value) ? (
          <Tooltip
            overlay={
              isDisabled
                ? 'Excluded column from import / use'
                : 'Exclude column from import / use'
            }
            placement="top"
          >
            <Button
              variant="icon"
              data-cy="exclude-column-button"
              onClick={(event) => {
                event.stopPropagation()
                handleColumnExclude(value)
              }}
            >
              <RemoveIcon
                height={16}
                width={16}
                className={cx('group-hover:visible hover:text-maroon-300', {
                  invisible: !isDisabled,
                  'text-maroon-300': isDisabled,
                })}
              />
            </Button>
          </Tooltip>
        ) : null,
    }
  })

  const handleSave = (values: FormValues) => {
    onImport(values)
  }

  const filteredHeaders = headers
    .filter((header) => !formState.columnsToExclude.includes(header))
    .map((value) => {
      return {
        label: value,
        value,
      }
    })

  return (
    <Form<FormValues>
      onSubmit={() => handleSave(formState)}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={formState}
      render={({ handleSubmit, hasValidationErrors }) => {
        return (
          <Modal
            visible={isOpen}
            centered
            onClose={() => {
              onCancel()
            }}
            isResponsive
            className="block"
            style={{
              maxWidth: '80vw',
              minWidth: 700,
            }}
            footer={
              <div className="my-3">
                <Button
                  data-cy="cancel-button"
                  className="mr-4 pointer-events-auto"
                  ghost
                  onClick={() => {
                    onCancel()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={(event) => {
                    handleSubmit(event)
                  }}
                  disabled={hasValidationErrors}
                >
                  Import
                </Button>
              </div>
            }
          >
            <div className="text-2xl flex flex-grow-0 flex-row gap-4 mb-6">
              <div className="font-bold">{fileName}</div>
            </div>

            <form onSubmit={handleSubmit} className="text-sm ">
              <Field<string | undefined>
                name="fileName"
                validate={() => validateNotEmptyString(formState.fileName)}
                render={({ input, meta }) => (
                  <FormItem
                    className="max-w-100"
                    label="Data source name"
                    guidingText="This will be displayed in the data source table."
                    htmlFor="fileName"
                    error={meta.error}
                  >
                    <Input
                      type="text"
                      id="fileName"
                      variant="default"
                      className="text-coolGray-800"
                      value={formState.fileName}
                      name={formState.fileName}
                      onChange={(event) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          fileName: event.target.value,
                        }))
                      }}
                      maxLength={50}
                      required
                    />
                  </FormItem>
                )}
              />
              <div className="w-full border-coolGray-200 border border-solid mb-6" />
              <div className="text-xl font-medium text-coolGray-800">
                Define you data structure
              </div>
              <div className="text-sm text-coolGray-400 mb-10">
                Description to define what his means?
              </div>
              <div className="flex gap-4">
                <Field<string>
                  name="productNameColumn"
                  validate={() =>
                    validateNotUndefined(formState.productNameColumn)
                  }
                  render={({ meta }) => (
                    <FormItem
                      label="Product name column"
                      htmlFor="customerNameColumn"
                      className="max-w-55"
                      error={meta.error}
                    >
                      <SingleSelect
                        id="productNameColumn"
                        onChange={(val) =>
                          setFormState((prevState) => ({
                            ...prevState,
                            productNameColumn: val?.value,
                          }))
                        }
                        value={formState.productNameColumn}
                        isSearchable={true}
                        required
                        options={filteredHeaders}
                      />
                    </FormItem>
                  )}
                />
                <Field<string>
                  name="productIdColumn"
                  render={({ meta }) => (
                    <FormItem
                      label="Product ID (Optional)"
                      htmlFor="customerIdColumn"
                      className="max-w-55"
                      error={meta.error}
                    >
                      <SingleSelect
                        id="productIdColumn"
                        onChange={(val) =>
                          val !== null &&
                          setFormState((prevState) => ({
                            ...prevState,
                            productIdColumn: val.value,
                          }))
                        }
                        value={formState.productIdColumn}
                        isSearchable={true}
                        options={filteredHeaders}
                      />
                    </FormItem>
                  )}
                />
                <Field<string>
                  name="dataSeparator"
                  validate={() => validateNotUndefined(formState.dataSeparator)}
                  render={({ meta }) => (
                    <FormItem
                      label="How your data are separated"
                      htmlFor="dataSeparator"
                      className="max-w-55"
                      error={meta.error}
                    >
                      <SingleSelect
                        id="dataSeparator"
                        onChange={(val) => {
                          setFormState((prevState) => ({
                            ...prevState,
                            dataSeparator: val?.value,
                          }))
                        }}
                        value={formState.dataSeparator}
                        isSearchable={true}
                        options={[
                          { label: 'Column', value: 'columns' },
                          {
                            label: 'Delimiter (e.g. ; or >)',
                            value: 'delimiter',
                          },
                        ]}
                      />
                    </FormItem>
                  )}
                />

                {formState.dataSeparator === 'delimiter' && (
                  <Field<string>
                    name="delimiter"
                    validate={() => validateNotUndefined(formState.delimiter)}
                    render={({ meta }) => (
                      <FormItem
                        label="Delimiter"
                        htmlFor="delimiter"
                        className="max-w-55"
                        error={meta.error}
                      >
                        <SingleSelect
                          id="delimiter"
                          onChange={(val) =>
                            setFormState((prevState) => ({
                              ...prevState,
                              delimiter: val?.value,
                            }))
                          }
                          value={formState.delimiter}
                          isSearchable={true}
                          options={[
                            { label: ';', value: ';' },
                            { label: '>', value: '>' },
                          ]}
                        />
                      </FormItem>
                    )}
                  />
                )}
              </div>

              {formState.dataSeparator === 'columns' && (
                <>
                  <div className="flex gap-4 flex-wrap items-start">
                    <FieldArray name="categoryLevel">
                      {({ fields }) => {
                        return fields.map((name, index) => {
                          return (
                            <div
                              key={name}
                              className="inline-flex items-center gap-4"
                            >
                              <Field<string>
                                name={`${name}`}
                                validate={() =>
                                  validateNotEmptyString(
                                    formState.categoryLevel[index]
                                  )
                                }
                                render={({ meta }) => (
                                  <FormItem
                                    label={`Category level${index + 1}`}
                                    htmlFor={`categoryLevel${index}`}
                                    error={meta.error}
                                    orientation="horizontal"
                                  >
                                    <div className="flex gap-4">
                                      <SingleSelect
                                        id={`categoryLevel${index}`}
                                        className="flex-1 w-55"
                                        onChange={(val) => {
                                          val !== null &&
                                            setFormState((prevState) => ({
                                              ...prevState,
                                              categoryLevel:
                                                prevState.categoryLevel.map(
                                                  (item, i) =>
                                                    i === index
                                                      ? val.value
                                                      : item
                                                ),
                                            }))
                                        }}
                                        value={formState.categoryLevel[index]}
                                        isSearchable={true}
                                        options={headers
                                          .filter((value) =>
                                            fields.value[index] === value
                                              ? true
                                              : !fields.value.includes(value) &&
                                                !formState.columnsToExclude.includes(
                                                  value
                                                )
                                          )
                                          .map((value) => ({
                                            label: value,
                                            value,
                                          }))}
                                      />

                                      {fields.length !== undefined &&
                                        index === fields.length - 1 && (
                                          <div className="h-10 flex gap-1 items-stretch">
                                            <Tooltip overlay="Add sequence column to the table">
                                              <Button
                                                ghost
                                                className="h-full"
                                                onClick={() => {
                                                  setFormState((prevState) => ({
                                                    ...prevState,
                                                    categoryLevel: [
                                                      ...prevState.categoryLevel,
                                                      '',
                                                    ],
                                                  }))
                                                }}
                                                disabled={
                                                  fields.length ===
                                                  Math.min(headers.length, 5)
                                                }
                                                prefixIcon={
                                                  <AddIcon
                                                    size={4}
                                                    isDefaultColor={false}
                                                  />
                                                }
                                              />
                                            </Tooltip>
                                            <Tooltip overlay="Remove a sequence column from the table">
                                              <Button
                                                className="self-end h-full"
                                                disabled={fields.length === 1}
                                                ghost
                                                onClick={() => {
                                                  setFormState((prevState) => ({
                                                    ...prevState,
                                                    categoryLevel:
                                                      prevState.categoryLevel.slice(
                                                        0,
                                                        -1
                                                      ),
                                                  }))
                                                }}
                                                prefixIcon={
                                                  <MinusIcon
                                                    size={4}
                                                    isDefaultColor={false}
                                                  />
                                                }
                                              />
                                            </Tooltip>
                                          </div>
                                        )}
                                    </div>
                                  </FormItem>
                                )}
                              />
                            </div>
                          )
                        })
                      }}
                    </FieldArray>
                  </div>

                  <div className="mt-3">
                    <FieldArray name="categoryLevel">
                      {({ fields }) => {
                        return (
                          <div className="text-xs text-coolGray-600">
                            {fields.value
                              .filter((value) => !isEmpty(value))
                              .join(' / ')}
                          </div>
                        )
                      }}
                    </FieldArray>
                  </div>
                </>
              )}
              {formState.dataSeparator === 'delimiter' && (
                <Field<string>
                  name="categoryColumn"
                  validate={() =>
                    validateNotUndefined(formState.categoryColumn)
                  }
                  render={({ meta }) => (
                    <FormItem
                      label="Category column"
                      htmlFor="categoryColumn"
                      className="max-w-55"
                      error={meta.error}
                    >
                      <SingleSelect
                        id="categoryColumn"
                        onChange={(val) =>
                          val !== null &&
                          setFormState((prevState) => ({
                            ...prevState,
                            categoryColumn: val.value,
                          }))
                        }
                        value={formState.categoryColumn}
                        isSearchable={true}
                        required
                        options={filteredHeaders}
                      />
                    </FormItem>
                  )}
                />
              )}
            </form>

            <Table
              columns={columns}
              data={data}
              className="mt-6 bg-coolGray-50 sm:-ml-6 sm:-mr-6 border-t border-coolGray-200"
            />
          </Modal>
        )
      }}
    />
  )
}

const CsvProductPreviewWithResetState = (props: Props) => (
  <CsvProductPreview key={`${props.isOpen}`} {...props} />
)

export default CsvProductPreviewWithResetState
