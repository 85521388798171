import GuidanceWidget from 'common/components/GuidanceWidget'

type Props = {
  className?: string
}

const BrandVoiceMessage = ({ className }: Props) => {
  return (
    <div className={className}>
      <GuidanceWidget title="Guidance">
        Your Brand Voice settings will be used by Jacquard to ensure the content
        you generate is safe to use. We set some defaults for you, but you can
        update as much or as little as you like.
        <div className="mt-4">
          Need a bit more guidance? Check out our support articles below:
        </div>
        <div className="mt-4">
          <a
            href="https://support.phrasee.co/en/articles/8140365-configuring-your-style-guide"
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            Support Article
          </a>
        </div>
      </GuidanceWidget>
    </div>
  )
}

export default BrandVoiceMessage
