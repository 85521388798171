import * as Highcharts from 'highcharts/highstock'
import merge from 'lodash/merge'

import { ChartInstance } from 'common/components/AutoResizeChart'
import OptionsMenuButton from 'common/components/optionsMenuButton'

import 'fullscreen-api-polyfill' // Safari is using webkit prefix

import { MenuDivider as Divider, MenuItem } from '../../menuButton'

interface Props {
  chart: ChartInstance | null
  title: string
  problematicExports?: ('pdf' | 'print')[]
  exportOptions?: Highcharts.Options
}

const ChartWidgetMenu = ({
  chart,
  title,
  problematicExports = [],
  exportOptions,
}: Props) => {
  const defaultOptions: Highcharts.Options = {
    chart: {
      spacing: [50, 50, 50, 50],
      animation: false,
    },
    title: {
      text: title,
      y: -10, // Move the title above the chart to not overlap
      style: {
        fontSize: '20px',
        fontWeight: '500',
      },
      align: 'left',
    },
  }
  const options: Highcharts.Options = merge({}, defaultOptions, exportOptions)

  const menuOptions = (
    <>
      <MenuItem
        key="1"
        onClick={() => {
          const originalOptions = chart?.chart.options
          if (chart?.chart.options) {
            chart.chart.options = merge({}, originalOptions, options)
          }
          chart?.chart.setTitle(options.title) // needed at least for the LineChart in fullscreen

          chart?.chart.fullscreen.toggle()

          const handleFullScreenChange = () => {
            if (!document.fullscreenElement) {
              // Leaving full-screen mode.
              if (chart?.chart.options && originalOptions) {
                chart.chart.options = originalOptions
              }
              chart?.container.current?.removeEventListener(
                'fullscreenchange',
                handleFullScreenChange
              )
              chart?.chart.setTitle(chart.chart.options.title)
            }
          }
          chart?.container.current?.addEventListener(
            'fullscreenchange',
            handleFullScreenChange
          )
        }}
      >
        View in full screen
      </MenuItem>
      {!problematicExports.includes('print') && (
        <MenuItem
          key="2"
          onClick={() => {
            const originalOptions = chart?.chart.options

            // It seems attaching the event (afterPrint and beforePrint) while the graph is created is not possible so
            // https://api.highcharts.com/highcharts/chart.events.afterPrint was not used.
            const handleAfterPrint = () => {
              window.removeEventListener('afterprint', handleAfterPrint)
              if (chart?.chart.options && originalOptions) {
                chart.chart.options = originalOptions
                chart?.chart.setTitle(originalOptions.title)
                chart?.chart.reflow()
              }
            }

            window.addEventListener('afterprint', handleAfterPrint)

            if (chart?.chart.options) {
              chart.chart.options = merge({}, originalOptions, options)

              chart?.chart.setTitle(options.title)
              chart?.chart.reflow()
            }

            chart?.chart.print()
          }}
        >
          Print chart
        </MenuItem>
      )}
      <Divider />
      <MenuItem
        key="3"
        onClick={() => {
          chart?.chart.exportChartLocal({ type: 'image/png' }, options)
        }}
      >
        Download PNG image
      </MenuItem>
      <MenuItem
        key="4"
        onClick={() => {
          chart?.chart.exportChartLocal(
            {
              type: 'image/jpeg',
              allowHTML: true,
            },
            options
          )
        }}
      >
        Download JPEG image
      </MenuItem>

      {!problematicExports.includes('pdf') && (
        <MenuItem
          key="5"
          onClick={() => {
            chart?.chart.exportChartLocal(
              { type: 'application/pdf', allowHTML: true },
              options
            )
          }}
        >
          Download PDF document
        </MenuItem>
      )}
      <MenuItem
        key="6"
        onClick={() => {
          chart?.chart.exportChartLocal({ type: 'image/svg+xml' }, options)
        }}
      >
        Download SVG vector image
      </MenuItem>
    </>
  )

  return (
    <div className="mr-5">
      <OptionsMenuButton name="widget menu" options={menuOptions} />
    </div>
  )
}

export default ChartWidgetMenu
