import Button from 'common/components/button/Button'

const UploadDisclaimer = () => {
  return (
    <p>
      By checking this box, you confirm that you have read and understood{' '}
      <Button
        variant="link"
        onClick={() => window.open('https://www.jacquard.com/legal/terms/')}
      >
        Terms of Use
      </Button>{' '}
      and{' '}
      <Button
        variant="link"
        onClick={() =>
          window.open('https://www.jacquard.com/legal/privacy-policy/')
        }
      >
        Privacy Policy
      </Button>{' '}
      regarding data import and its legal implications.
    </p>
  )
}

export default UploadDisclaimer
