export const APPROVAL_COMPONENT_ID = 'awaiting_for_approval'

export const VARIANTS_CATEGORIES = {
  PERFORMANCE: 'Performance',
  EXPLORATION: 'Exploration',
  BASELINE: 'Baseline',
  USER_MODIFIED: 'User modified',
} as const

export const SENTIMENTS = {
  IMPRESSED: 'impressed',
  HELPFUL: 'helpful',
  CURIOUS: 'curious',
  EXITED: 'excited',
  SURPRISING: 'surprising',
  APPRECIATIVE: 'appreciative',
  URGENT: 'urgent',
} as const
