import { FC } from 'react'

import Button from 'common/components/button'
import Spinner from 'common/components/spinner'
import { Topic } from 'common/components/topics/interfaces'
import { SmallTick } from 'common/icons'

import Footer from '../../components/footer'
import Option from '../../components/option'

interface Props {
  isLoading: boolean
  replacers: Topic[] | undefined
  onPreviousClick: () => void
  onFinishedClick: () => void
}

const AddSpecifics: FC<Props> = ({
  isLoading,
  replacers,
  onPreviousClick,
  onFinishedClick,
}) => {
  return (
    <div className="h-full flex flex-col justify-between gap-10">
      <div className="w-150 self-center">
        <div className="mt-5 mb-3 flex items-center gap-4 text-2xl font-bold text-coolGray-800">
          <span>Add a few specifics</span>
        </div>
        <div className="mb-9 text-lg text-coolGray-500">
          Based on the topics you selected we found the following items. Please
          choose the ones you would like to activate.
        </div>

        <Spinner isSpinning={isLoading}>
          <div
            className="overflow-y-auto"
            style={{ maxHeight: `calc(100vh - 315px)` }}
          >
            {replacers?.map((replacer) => {
              const { id } = replacer

              return <Option key={id} topic={replacer} />
            })}
          </div>
        </Spinner>
      </div>

      <Footer>
        <Button
          data-cy="previous-button"
          className="mr-auto"
          ghost
          onClick={onPreviousClick}
        >
          Previous
        </Button>
        <div className="col-start-2 self-center">Add topics</div>
        <Button
          data-cy="finished-button"
          className="place-self-end"
          variant="primary"
          prefixIcon={
            <SmallTick isDefaultColor={false} className="text-white" />
          }
          onClick={onFinishedClick}
        >
          Finished
        </Button>
      </Footer>
    </div>
  )
}

export default AddSpecifics
