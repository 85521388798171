import { FC } from 'react'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'
import helpers from 'workflow/utils/helpers'

import CreatableSelect, { getOptionsArray, Options } from './CreatableSelect'

const { isValidEmail } = helpers

export type EmailOptions = Options

interface Props {
  values: Options
  setValues: (val: Options) => void
  options?: Options
}

const EmailsSelect: FC<Props> = (props) => {
  return (
    <>
      <CreatableSelect
        data-cy="select-emails"
        ariaLabel="Select emails"
        isValidNewOption={(value: string) =>
          getOptionsArray(value).every((option) => isValidEmail(option))
        }
        {...props}
      />
      <GuidingText text="To send to multiple email address, separate them by using a comma." />
    </>
  )
}

export default EmailsSelect
