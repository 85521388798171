import escape from 'lodash/escape'

import { Fragment } from 'common/components/topics/interfaces'
import { replaceNewLineEmojiAsLineBreak } from 'common/variantsUtils'

import styles from './string.module.css'

const colors = ['bg-maroon-300', 'bg-amber-200', 'bg-green-200', 'bg-blue-200']

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function highlightTextFragments(
  controlText: string,
  fragments?: Fragment[]
): string {
  if (!fragments) {
    return controlText
  }
  // Prevent angular brackets from being interpreted as HTML tags
  let enhancedText = escape(controlText)

  if (fragments.length > 0) {
    fragments.forEach(({ name }, index) => {
      if (name) {
        const colorClass = colors[index % colors.length]
        const highlightedText = replaceNewLineEmojiAsLineBreak(name)

        enhancedText = enhancedText.replace(
          new RegExp(name, 'g'),
          `<span class="${colorClass} ${styles.highlight}">${highlightedText}</span>`
        )
      }
    })
  } else {
    enhancedText = replaceNewLineEmojiAsLineBreak(enhancedText)
  }

  return `<p class="${styles.sentence}">${enhancedText}</p>`
}
