import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from 'react-error-boundary'

import { captureException } from 'common/services/Logger'

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  onError,
  children,
  ...props
}) => {
  const customOnError =
    onError || captureException('Error boundary caught exception')

  return (
    <ReactErrorBoundary onError={customOnError} {...props}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
