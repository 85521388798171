import { forwardRef } from 'react'
import { TimePicker } from 'antd'
import { TimePickerProps } from 'antd/lib/time-picker/index'

const TimePickerComponent = forwardRef<TimePicker, TimePickerProps>(
  (props, ref) => {
    const { ...rest } = props

    return (
      <TimePicker
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        ref={ref}
        {...rest}
        placeholder={rest.placeholder ?? 'Time'}
      />
    )
  }
)

export default TimePickerComponent
