import { formatCurrency, IsoCurrency } from 'common/helpers/currency'

import BaseCell from './Base'

export type CurrencyCellvalue = {
  currencyValue: number | bigint
  isoCurrency: IsoCurrency
}

type Props = {
  value?: CurrencyCellvalue
}

const CurrencyCell = ({ value }: Props) => {
  return value ? (
    <BaseCell>
      <span>{formatCurrency(value.currencyValue, value.isoCurrency)}</span>
    </BaseCell>
  ) : null
}

export default CurrencyCell
