import { StylesConfig } from 'react-select'

import { SelectValue } from 'common/components/autcomplete/Autocomplete'

const styles: StylesConfig<SelectValue, true> = {
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    display: 'inline',
    backgroundColor: 'white',
    border: 'none',
    boxShadow: 'none',
    caretColor: '#f97316',
    color: '',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    'outline:focus-visible': 'none',
    ...(state.isDisabled
      ? {
          cursor: 'not-allowed',
          pointerEvents: 'auto',
        }
      : {}),
    '&:hover': {
      borderColor: '#af6693',
    },
  }),
  indicatorsContainer: () => {
    return {
      display: 'none',
    }
  },

  menu: (defaultStyles) => {
    return {
      ...defaultStyles,
      margin: 0,
      width: 'auto',
      boxShadow: `0px 10px 15px -3px #0000001A, 
      0px 4px 6px -2px #0000000F`,
      ...{
        paddingBottom: 0,
        border: '1px solid #e4e4e7',
      },
    }
  },
  menuList: (defaultStyles) => {
    return {
      ...defaultStyles,
      padding: 0,
    }
  },
  option: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      whiteSpace: 'nowrap',
      height: 40,
      paddingLeft: 0,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: !state.isFocused ? '' : '#f3f4f6',
      '&:hover': {
        backgroundColor: '#f3f4f6',
        cursor: 'pointer',
      },
    }
  },
  valueContainer: (defaultStyles) => {
    return {
      ...defaultStyles,
      height: '100%',
      color: '#1f2937',
      fontWeight: 500,
      padding: 0,
    }
  },
  group: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#9ca3af',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
    }
  },
  multiValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      backgroundColor: '#fff7ed',
      border: '1px solid #901356',
      borderRadius: '4px',
      paddingLeft: '8px',
      marginRight: '8px',
      marginTop: '4px',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        borderColor: '#EA580C',
        cursor: 'pointer',
      },
    }
  },
  multiValueLabel: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#EA580C',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
    }
  },
  multiValueRemove: (defaultStyles) => {
    return {
      ...defaultStyles,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    }
  },
}

export default styles
