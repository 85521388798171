import HoldingPage from 'common/components/holdingPages/Page'

export const messages = {
  title: 'UH OH! There are no results for the time period selected.',
}

const NoResults = () => {
  return (
    <HoldingPage className="max-w-363">
      <HoldingPage.Title>{messages.title}</HoldingPage.Title>
      <HoldingPage.SubTitle>
        Expand the selected period to unleash some linguistic insights.
      </HoldingPage.SubTitle>
    </HoldingPage>
  )
}

export default NoResults
