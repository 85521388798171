import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'

import { Phrase } from '../interfaces'
import { contentKeys } from '../queryKeys'
import { removeTagFromPhrase } from '..'
type MutationContext = { previousPhrase: Phrase }

const useRemoveTagMutation = (selectedNodeId) => {
  const queryClient = useQueryClient()

  return useMutation<
    unknown,
    unknown,
    { phraseId: number; tagId: number; userId: string },
    MutationContext
  >(removeTagFromPhrase, {
    onMutate: async ({ phraseId, tagId }) => {
      await queryClient.cancelQueries(contentKeys.phrases(selectedNodeId))

      const previousPhrase = queryClient
        .getQueryData<Phrase[]>(contentKeys.phrases(selectedNodeId))
        ?.find((phrase) => phrase.phraseId === phraseId)

      queryClient.setQueryData<Phrase[]>(
        contentKeys.phrases(selectedNodeId),
        (old) => {
          return old?.map((phrase) =>
            phrase.phraseId === phraseId
              ? {
                  ...phrase,
                  tags: phrase.tags?.filter((tag) => tag.tagId !== tagId),
                }
              : phrase
          )
        }
      )

      return { previousPhrase } as MutationContext
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData<Phrase[]>(
        contentKeys.phrases(selectedNodeId),
        (old) => {
          return old?.map((phrase) =>
            phrase.phraseId === context?.previousPhrase.phraseId
              ? context.previousPhrase
              : phrase
          )
        }
      )

      showErrorToast(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.phrases(selectedNodeId))
    },
  })
}

export default useRemoveTagMutation
