import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { Topic } from 'common/components/topics/interfaces'

import { Phrase } from '../interfaces'
import { contentKeys } from '../queryKeys'
import { updatePhrase } from '..'

type MutationContext = { previousPhrase: Phrase }

const useUpdatePhraseMutation = (selectedNodeId) => {
  const queryClient = useQueryClient()

  return useMutation<
    unknown,
    unknown,
    {
      phraseId: number
      updatedPhrase: Phrase
      userId: string
      replacers?: Topic[]
    },
    MutationContext
  >(updatePhrase, {
    onMutate: async ({ phraseId, updatedPhrase }) => {
      await queryClient.cancelQueries(contentKeys.phrases(selectedNodeId))

      const previousPhrase = queryClient
        .getQueryData<Phrase[]>(contentKeys.phrases(selectedNodeId))
        ?.find((phrase) => phrase.phraseId === phraseId)

      queryClient.setQueryData<Phrase[]>(
        contentKeys.phrases(selectedNodeId),
        (old) => {
          return old?.map((phrase) =>
            phrase.phraseId === phraseId ? updatedPhrase : phrase
          )
        }
      )

      return { previousPhrase } as MutationContext
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData<Phrase[]>(
        contentKeys.phrases(selectedNodeId),
        (old) => {
          return old?.map((phrase) =>
            phrase.phraseId === context?.previousPhrase.phraseId
              ? context.previousPhrase
              : phrase
          )
        }
      )

      showErrorToast(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.phrases(selectedNodeId))
    },
  })
}

export default useUpdatePhraseMutation
