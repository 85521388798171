import { Field } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import { DropdownType } from 'common/components/dynamicFields/interfaces'
import FormItem from 'common/components/FormItem'
import SingleSelect, { SingleValue } from 'common/components/singleSelect'

const validateSelectionMetric = (value: SelectValue) => {
  if (!value) {
    return 'Please select a primary goal'
  }
}

type Props = {
  name: string
  configuration: DropdownType
  hasInitialValue?: boolean
  isDisabled?: boolean
  onChange: (value: SingleValue<SelectValue>) => void
}

const SelectionMetric = ({
  name,
  configuration,
  hasInitialValue = true,
  isDisabled = false,
  onChange,
}: Props) => {
  return (
    <Field<SelectValue>
      name={name}
      initialValue={
        hasInitialValue
          ? configuration.options.find((option) => option.default)
          : undefined
      }
      validate={validateSelectionMetric}
    >
      {({ input, meta }) => (
        <FormItem
          label={configuration.label}
          htmlFor={input.name}
          error={meta.touched ? meta.error : undefined}
        >
          <SingleSelect
            data-cy="selection-metric-input"
            id={input.name}
            name={input.name}
            placeholder={configuration.placeholder}
            value={input.value.value}
            onChange={(value) => {
              input.onChange(value)
              onChange(value)
            }}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            options={configuration.options}
            isDisabled={isDisabled}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default SelectionMetric
