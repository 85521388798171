import { forwardRef, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

const Header = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col justify-between p-5 border-b border-coolGray-200"
    >
      {children}
    </div>
  )
})

export default Header
