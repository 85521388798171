import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Spinner from 'common/components/spinner'
import Tabs, { addCountToTabName, Tab } from 'common/components/tabs'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import AllUsers from './all/AllUsers'
import DeactivatedUsers from './all/DeactivatedUsers'
import UsersHeader from './all/Header'
import AddUsersModal from './all/modal/AddUsersModal'
import {
  clickCreateTeam,
  clickInviteUsers,
  clickUpdateTeam,
  hideAddTeamModal,
  hideAddUserModal,
  initializePage,
  selectDeactivatedUsers,
  selectUsers,
  showAddTeamModal,
  showAddUserModal,
} from './store/usersSlice'
import TeamsHeader from './teams/Header'
import AddTeamModal from './teams/modal/AddTeamModal'
import AddTeamModalOld from './teams/modal/AddTeamModalOld'
import Teams from './teams/Teams'
import TeamsNew from './teams/TeamsNew'

import styles from './Users.module.css'

const Users = () => {
  useDocumentTitle('Users | Jacquard')
  const dispatch = useAppDispatch()
  const flags = useFlags()

  useEffect(() => {
    dispatch(initializePage(flags.showNewTeamPage))
  }, [dispatch, flags.showNewTeamPage])

  const {
    isLoading,
    teams,
    assignableRoles,
    isTeamModalVisible,
    isUserModalVisible,
  } = useAppSelector((state) => state.adminUsers)

  const deactivatedUsers = useAppSelector(selectDeactivatedUsers)
  const users = useAppSelector(selectUsers)
  const [selectedTeamId, setSelectedTeamId] = useState<string>()
  const [searchTeamValue, setSearchTeamValue] = useState<string>('')
  const [searchUserValue, setSearchUserValue] = useState<string>('')
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([])

  const teamsTab: Tab = {
    key: 'teams',
    name: addCountToTabName({ tabName: 'Teams', count: teams.length }),
    content: flags.showNewTeamPage ? (
      <TeamsNew
        onEditTeam={({ teamId }) => {
          setSelectedTeamId(teamId)
          dispatch(showAddTeamModal())
        }}
        searchTeamValue={searchTeamValue}
      />
    ) : (
      <Teams
        onEditTeam={({ teamId }) => {
          setSelectedTeamId(teamId)
          dispatch(showAddTeamModal())
        }}
        searchTeamValue={searchTeamValue}
      />
    ),
    isDisabled: false,
  }
  const usersTab: Tab = {
    key: 'all',
    name: addCountToTabName({ tabName: 'All', count: users.length }),
    content: (
      <AllUsers
        searchValue={searchUserValue}
        selectedProjectIds={selectedProjectIds}
        users={users}
        assignableRoles={assignableRoles}
      />
    ),
    isDisabled: false,
  }
  const deactivatedTab: Tab = {
    key: 'deactivated',
    name: addCountToTabName({
      tabName: 'Deactivated',
      count: deactivatedUsers.length,
    }),
    content: (
      <DeactivatedUsers
        searchValue={searchUserValue}
        selectedProjectIds={selectedProjectIds}
        users={deactivatedUsers}
        assignableRoles={assignableRoles}
      />
    ),
    isDisabled: false,
  }
  const tabs: Tab[] = [
    usersTab,
    teamsTab,
    {
      key: 'invitations',
      name: addCountToTabName({ tabName: 'Invitations', count: 0 }),
      content: 'Invitations',
      isDisabled: true,
    },
    {
      key: 'roles',
      name: addCountToTabName({ tabName: 'Roles and permissions', count: 0 }),
      content: 'Roles and permissions',
      isDisabled: true,
    },
    ...(deactivatedUsers.length > 0 ? [deactivatedTab] : []),
  ]

  const defaultActiveKey = usersTab.key
  const [selectedTab, setSelectedTab] = useState(defaultActiveKey)

  return (
    <PageContainer className="px-6 pt-9">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <PageHeader>
            <PageTitle title="Users" />
          </PageHeader>

          <div className="flex justify-between items-stretch relative">
            <Tabs
              tabs={tabs.filter((tab) => !tab.isDisabled)}
              defaultActiveKey={selectedTab}
              onTabClick={(tab) => {
                setSelectedTab(tab)
              }}
              variant="inPage"
            />
            <div
              className={cx(
                'flex gap-3 absolute right-0  items-end',
                styles.header
              )}
            >
              {selectedTab === usersTab.key ||
              selectedTab === deactivatedTab.key ? (
                <>
                  <UsersHeader
                    onAddClick={() => {
                      dispatch(showAddUserModal())
                    }}
                    onSearch={setSearchUserValue}
                    searchValue={searchUserValue}
                    selectedProjectIds={selectedProjectIds}
                    onSelectProjectIds={setSelectedProjectIds}
                    users={users}
                    isAddButtonVisible={selectedTab === usersTab.key}
                  />
                  <AddUsersModal
                    assignableRoles={assignableRoles}
                    isVisible={isUserModalVisible}
                    onClose={() => {
                      dispatch(hideAddUserModal())
                    }}
                    onClickInviteUsers={(users) => {
                      dispatch(clickInviteUsers(users))
                    }}
                  />
                </>
              ) : undefined}
              {selectedTab === teamsTab.key ? (
                <>
                  <TeamsHeader
                    onAddTeamClick={() => {
                      dispatch(showAddTeamModal())
                      setSelectedTeamId(undefined)
                    }}
                    onSearch={setSearchTeamValue}
                    searchTeamValue={searchTeamValue}
                  />
                  {flags.showNewTeamPage ? (
                    <AddTeamModal
                      key={selectedTeamId}
                      selectedTeamId={selectedTeamId}
                      users={users}
                      isVisible={isTeamModalVisible}
                      onClose={() => {
                        dispatch(hideAddTeamModal())
                      }}
                      onClickCreateTeam={(team) => {
                        setSelectedTeamId(undefined)
                        if (selectedTeamId) {
                          dispatch(
                            clickUpdateTeam({
                              team: { ...team, id: selectedTeamId },
                              showNewTeamPage: flags.showNewTeamPage,
                            })
                          )
                        } else {
                          dispatch(
                            clickCreateTeam({
                              team,
                              showNewTeamPage: flags.showNewTeamPage,
                            })
                          )
                        }
                        dispatch(hideAddTeamModal())
                      }}
                    />
                  ) : (
                    <AddTeamModalOld
                      key={selectedTeamId}
                      selectedTeamId={selectedTeamId}
                      isVisible={isTeamModalVisible}
                      onClose={() => {
                        dispatch(hideAddTeamModal())
                      }}
                      onClickCreateTeam={(team) => {
                        setSelectedTeamId(undefined)
                        if (selectedTeamId) {
                          dispatch(
                            clickUpdateTeam({
                              team: { ...team, id: selectedTeamId },
                              showNewTeamPage: flags.showNewTeamPage,
                            })
                          )
                        } else {
                          dispatch(
                            clickCreateTeam({
                              team,
                              showNewTeamPage: flags.showNewTeamPage,
                            })
                          )
                        }
                        dispatch(hideAddTeamModal())
                      }}
                    />
                  )}
                </>
              ) : undefined}
            </div>
          </div>
          <Footer />
        </>
      )}
    </PageContainer>
  )
}

export default Users
