import { useAppSelector } from 'common/hooks/redux'
import useGetTemplatesQuery from 'features/unifiedFlow/api/queries/useGetTemplatesQuery'
import { TemplateInfo } from 'features/unifiedFlow/contentsPage/interfaces'

import AddedComponentCard from './AddedComponentCard'

export type TemplateInfoWithInternalId = TemplateInfo & {
  internalId: string
}

const AddedComponentList = () => {
  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )
  const { data: templates } = useGetTemplatesQuery()

  const templatesToAddWithDetails: TemplateInfoWithInternalId[] | undefined =
    templatesToAdd.map(({ id, internalId }) => {
      const template = templates?.find((template) => template.id === id)
      return {
        ...template!,
        internalId,
      }
    })

  return (
    <div className="grid gap-4 w-full h-fit p-4 sm:p-6">
      {templatesToAddWithDetails?.map((template) => (
        <AddedComponentCard
          key={`${template.internalId}`}
          template={template}
        />
      ))}
    </div>
  )
}

export default AddedComponentList
