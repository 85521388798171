import { ReactNode, useEffect } from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'

import Overlay from 'common/components/Overlay'
import { useAppDispatch } from 'common/hooks/redux'
import { Close as CloseIcon } from 'common/icons'
import { hideIntercom, showIntercom } from 'features/auth/store/authSlice'

import Scrollable from '../scrollable'

import { Footer } from './Footer'

import styles from './Drawer.module.css'

type Props = {
  title: ReactNode
  content: ReactNode
  footer?: ReactNode
  error?: string
  onClose: () => void
  isOpen: boolean
  isOverlayHidden?: boolean
}

function Drawer({
  title,
  onClose,
  content,
  footer,
  error,
  isOpen,
  isOverlayHidden,
}: Props) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isOpen) {
      dispatch(hideIntercom())
    } else {
      dispatch(showIntercom())
    }
    return () => {
      dispatch(showIntercom())
    }
  }, [dispatch, isOpen])

  return isOpen
    ? ReactDOM.createPortal(
        <>
          <div
            className={cx(
              ' right-0 z-70 h-screen font-rob bg-white flex flex-col absolute top-13.5',
              isOverlayHidden && styles.containerWithoutOverlay
            )}
          >
            <Scrollable
              header={
                <div
                  className={cx('flex justify-between px-9 pt-6 pb-3 bg-white')}
                >
                  <div className="text-2xl font-medium text-coolGray-800">
                    {title}
                  </div>
                  <button
                    data-cy="drawer-close-button"
                    className="opacity-50 absolute top-6 right-6"
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </button>
                </div>
              }
              contentClassName="px-9 flex-1-0 pb-6 w-106 "
              content={
                <div
                  className="flex flex-col justify-between pb-4 relative flex-1"
                  style={{ maxWidth: 424 }}
                >
                  {content}
                </div>
              }
              footerClassName="pb-4 flex flex-col items-end justify-end bg-white pt-6 px-6 relative"
              footer={
                footer ? <Footer error={error}>{footer}</Footer> : undefined
              }
            />
          </div>

          {isOverlayHidden ? undefined : <Overlay onClick={() => onClose()} />}
        </>,
        document.body
      )
    : null
}

export default Drawer
