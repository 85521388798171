import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button/Button'
import PageTitle from 'common/components/PageTitle'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import DateRangePicker from '../components/DateRangePicker'
import {
  applyFilter,
  applyProject,
  DateRange,
  Mode,
  selectMode,
} from '../store/insightsSlice'

export const SIDEBAR_BUTTON_TITLE = 'Details'
export const PROJECT_SELECT_LABEL = 'Project'
export const RUN_REPORT_BUTTON_TITLE = 'Run report'

const modes: { label: string; value: Mode }[] = [
  { label: 'Basic report', value: 'basicReport' },
  { label: 'Project vs project', value: 'projectComparison' },
  { label: 'Date vs date', value: 'dateRangeComparison' },
]

interface Props {
  title: string
  projects: SelectValue[]
}

const Header = ({ title, projects }: Props) => {
  const dispatch = useAppDispatch()

  const {
    projectIdToCompare,
    startDateToCompare,
    endDateToCompare,
    project: projectFilter,
    startDate: startDateFilter,
    endDate: endDateFilter,
    mode: selectedMode,
  } = useAppSelector((state) => state.insights)
  const { showInsightsComparison, showInsightsDateRangeComparison } = useFlags()

  const [selectedProject, setSelectedProject] = useState<string>(projectFilter)
  const [selectedProjectToCompare, setSelectedProjectToCompare] =
    useState<string | undefined>(projectIdToCompare)
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: startDateFilter,
    endDate: endDateFilter,
  })
  const [dateRangeToCompare, setDateRangeToCompare] = useState<DateRange>({
    startDate: startDateToCompare,
    endDate: endDateToCompare,
  })

  const applyFilters = () => {
    dispatch(
      applyFilter({
        project: selectedProject,
        projectToCompare: selectedProjectToCompare,
        dateRange,
        dateRangeToCompare,
      })
    )
  }

  return (
    <div className="flex lg:mt-4 mb-10 sm:items-center flex-wrap gap-4 flex-row items-center gap-y-8 w-full">
      <PageTitle title={title} className="flex-1-0 mr-35" />
      <form
        className="flex items-end gap-y-6 gap-x-4 relative -top-3 flex-wrap flex-grow"
        data-testid="header-form"
        onSubmit={(e) => e.preventDefault()}
      >
        {showInsightsComparison && (
          <SingleSelect
            className="w-44"
            data-cy="insights-mode-select"
            label="Mode"
            name="mode"
            placeholder="Select a mode"
            options={
              showInsightsDateRangeComparison
                ? modes
                : modes.filter((mode) => mode.value !== 'dateRangeComparison')
            }
            value={selectedMode}
            onChange={(val) => {
              if (val?.value) {
                dispatch(selectMode(val.value as Mode))
                if (selectedMode === 'projectComparison') {
                  // when the mode changes into basicReport, it needs to make the API calls with the selected project
                  dispatch(applyProject(selectedProject))
                }
              }
            }}
          />
        )}
        <div className="flex-grow max-w-100">
          <SingleSelect
            className="w-full sm:min-w-60 "
            data-cy="insights-project-select"
            label={PROJECT_SELECT_LABEL}
            name="project"
            isSearchable={true}
            placeholder="Select a project"
            options={projects}
            value={selectedProject}
            onChange={(val) => {
              if (val?.value) {
                setSelectedProject(val.value)
              }
            }}
          />
        </div>
        {selectedMode === 'projectComparison' && (
          <div className="flex-grow max-w-100">
            <SingleSelect
              className="w-full sm:min-w-60 "
              data-cy="insights-project-select"
              label={PROJECT_SELECT_LABEL}
              name="project"
              isSearchable={true}
              placeholder="Select a project"
              options={projects}
              value={selectedProjectToCompare}
              onChange={(val) => {
                if (val?.value) {
                  setSelectedProjectToCompare(val.value)
                }
              }}
            />
          </div>
        )}
        <DateRangePicker
          label={
            selectedMode === 'dateRangeComparison'
              ? 'First date range'
              : 'Date range'
          }
          selectedStartDate={dateRange?.startDate}
          selectedEndDate={dateRange?.endDate}
          applyDates={(start, end) =>
            setDateRange({ startDate: start, endDate: end })
          }
        />
        {selectedMode === 'dateRangeComparison' && (
          <DateRangePicker
            label="Second date range"
            selectedStartDate={dateRangeToCompare?.startDate}
            selectedEndDate={dateRangeToCompare?.endDate}
            applyDates={(startDate, endDate) =>
              setDateRangeToCompare({ startDate, endDate })
            }
          />
        )}

        <Button
          data-cy="insights-run-report-button"
          variant="primary"
          type="submit"
          disabled={
            !selectedProject ||
            (selectedMode === 'projectComparison' &&
              !selectedProjectToCompare) ||
            (selectedMode === 'dateRangeComparison' &&
              (!dateRangeToCompare.startDate || !dateRangeToCompare.endDate)) ||
            !dateRange?.startDate ||
            !dateRange?.endDate
          }
          onClick={applyFilters}
        >
          {RUN_REPORT_BUTTON_TITLE}
        </Button>
      </form>
    </div>
  )
}

export default Header
