import { Tag, TagResponse } from 'common/interfaces/tags'

import { connectInternalApi } from './api'

const TEMPORARY_FIXED_CONTENT_UPDATE_ID = 3

export const getTags = async ({
  nodeIds,
  rootNodeId,
}: {
  nodeIds?: string[]
  rootNodeId?: string
}): Promise<Tag[]> => {
  const result = await connectInternalApi.get<{ data: TagResponse[] }>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/tags`,
    { params: { node_ids: nodeIds, root_node_id: rootNodeId } }
  )

  const tags = result.data.data.map((tag) => ({
    ...tag,
    taggedNodes: tag.taggedNodes?.map((node) => ({
      id: node.nodeId.toString(),
      name: node.displayName || node.node,
      parent: '',
    })),
  }))
  return tags
}
