import MultiGraphWrapper from 'common/components/MultiGraphWrapper'

import { WidgetUpliftReportsResponse } from './api'
import ReportItem from './ReportItem'
import UpliftWidget from './UpliftWidget'

type Props = {
  widgetData: WidgetUpliftReportsResponse
}

const UpliftSection = ({ widgetData }: Props) => {
  const openUpliftData = widgetData.graphs?.find(
    (graph) =>
      graph.id === 'widget_open_uplift' ||
      graph.id === 'widget_direct_open_uplift'
  )
  const openGraphConfiguration = openUpliftData?.configuration
  const clickUpliftData = widgetData.graphs?.find(
    (graph) =>
      graph.id === 'widget_click_uplift' ||
      graph.id === 'widget_influenced_open_uplift'
  )
  const clickGraphConfiguration = clickUpliftData?.configuration

  return (
    <ReportItem title="Average uplift" withShadowWrapper>
      <MultiGraphWrapper>
        {openUpliftData && openGraphConfiguration && (
          <UpliftWidget
            name={openUpliftData.display_name}
            tooltip="Average open uplift displayed by period."
            configuration={openGraphConfiguration}
          />
        )}
        {clickUpliftData && clickGraphConfiguration && (
          <UpliftWidget
            name={clickUpliftData?.display_name}
            tooltip="Average click uplift displayed by period."
            configuration={clickGraphConfiguration}
          />
        )}
      </MultiGraphWrapper>
    </ReportItem>
  )
}

export default UpliftSection
