import { useState } from 'react'
import { ResponseVariant } from 'workflow/Insights/Insights'

const useSelectedVariants = (variants: ResponseVariant[]) => {
  const [selectedVariantId, setSelectedVariantId] =
    useState<string | undefined>(undefined)

  return {
    selectedVariantId,
    setSelectedVariantId,
  }
}

export default useSelectedVariants
