import { useAppSelector } from 'common/hooks/redux'

import AverageSentimentsWidget from '../averageSentiment/AverageSentimentsWidget'
import WinningLines from '../winningLines'

import FirstWidgets from './FirstWidgets'
import SecondWidgets from './SecondWidgets'

type Props = {
  startDate: string
  endDate: string
}

const DateComparison = ({ startDate, endDate }: Props) => {
  const {
    project: projectId,
    startDateToCompare,
    endDateToCompare,
  } = useAppSelector((state) => state.insights)
  const accountId = useAppSelector((state) => state.authStates.accountId)
  if (!startDateToCompare || !endDateToCompare) {
    return null
  }
  const dateRangeToCompare = {
    startDate: startDateToCompare,
    endDate: endDateToCompare,
  }

  return (
    <div className="flex flex-col gap-10">
      <div className="grid grid-cols-2 gap-6">
        <FirstWidgets
          projectId={projectId}
          startDate={startDate}
          endDate={endDate}
        />
        <FirstWidgets
          projectId={projectId}
          startDate={startDateToCompare}
          endDate={endDateToCompare}
        />
      </div>
      <div className="grid grid-cols-1">
        <WinningLines
          accountId={accountId}
          projectIds={[projectId]}
          startDate={startDate}
          endDate={endDate}
          dateRangeToCompare={dateRangeToCompare}
          mode="dateRangeComparison"
        />
      </div>
      <div className="grid grid-cols-1">
        <AverageSentimentsWidget
          accountId={accountId}
          projectIds={[projectId]}
          startDate={startDate}
          endDate={endDate}
          dateRangeToCompare={dateRangeToCompare}
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <SecondWidgets
          projectId={projectId}
          startDate={startDate}
          endDate={endDate}
        />
        <SecondWidgets
          projectId={projectId}
          startDate={startDateToCompare}
          endDate={endDateToCompare}
        />
      </div>
    </div>
  )
}

export default DateComparison
