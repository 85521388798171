import { Form } from 'antd'

import RadioGroup from 'common/components/radioGroup'

const FormItem = Form.Item

interface Props {
  cardTitle: string
  value: string | undefined
  options: Option[] | undefined
  onChange: (val: any) => any
  errorMessage: string
  isDisabled: boolean
}

export interface Option {
  id: string | number
  title: string
  subtitle?: string
}

export default function RadioButtonsCard({
  cardTitle,
  onChange,
  options,
  value,
  errorMessage,
  isDisabled,
}: Props) {
  return (
    <div className="mt-4 mb-4 bg-gray-50 p-4 border border-gray-200">
      <FormItem label={cardTitle} help={errorMessage} className="error mb-2">
        <RadioGroup
          name="optimization_type"
          onChange={(value) => onChange(value)}
          direction="vertical"
          className="mt-4"
          isDisabled={isDisabled}
          options={
            options?.map((option) => ({
              label: (
                <span className="pb-4 font-medium">
                  <span>{option.title}</span>
                  <div className="text-xs text-gray-400">{option.subtitle}</div>
                </span>
              ),
              value: option.id.toString(),
            })) || []
          }
          value={value}
        />
      </FormItem>
    </div>
  )
}
