import { FC, useEffect } from 'react'
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import Logger from 'common/services/Logger'
import { ldUserIdentified } from 'features/auth/store/authSlice'

import { setFlags } from './launchDarklySlice'

const LaunchDarklyIdentify: FC = ({ children }) => {
  const { user_id, firstName, lastName, intercomInfo } = useAppSelector(
    (state) => state.authStates
  )

  const ldClient = useLDClient()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (ldClient && user_id && intercomInfo?.email) {
      ldClient
        .identify({
          email: intercomInfo?.email,
          key: user_id,
          firstName,
          lastName,
          custom: {
            companyName: intercomInfo.company?.name || '',
          },
        })
        .then((flags) => {
          dispatch(setFlags(flags))
          dispatch(ldUserIdentified())
        })
        .catch((err) => {
          Logger.error('Cannot identify user for LaunchDarkly', err)
        })
    }
  }, [firstName, ldClient, lastName, user_id, dispatch, intercomInfo])

  return <>{ldClient ? children : null}</>
}

export default withLDProvider({
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ID ?? '',
})(LaunchDarklyIdentify)
