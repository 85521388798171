import { LanguageGenerationMethodValue } from '@phrasee/phrasee-typings/typings/project/project-configuration.types'

import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import MultiSelect, { MultiSelectValue } from 'common/components/MultiSelect'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { DistributionChannel } from 'common/interfaces/projects'

import {
  changeSegmentLength,
  onSegmentLengthBlur,
  selectSegments,
} from '../../store/contentSettingsSlice'
import { Segment } from '../../types'

export const MIN_SEGMENT_LENGTH = 15

const getChannelSegments = (
  distributionChannel: DistributionChannel,
  languageGenerationMethod?: LanguageGenerationMethodValue
): Segment[] => {
  let segments: Segment[] = []
  switch (distributionChannel) {
    case 'email':
      segments = [
        {
          ordinal: 1,
          name: 'Subject Line',
          length: 120,
        },
      ]
      if (languageGenerationMethod !== 'llm') {
        segments = [
          ...segments,
          {
            ordinal: 1,
            name: 'Preheader',
            length: 100,
          },
        ]
      }
      break
    case 'push_notification':
      segments = [
        {
          ordinal: 1,
          name: 'Body',
          length: 70,
        },
      ]
      if (languageGenerationMethod !== 'llm') {
        segments = [
          {
            ordinal: 1,
            name: 'Title',
            length: 50,
          },
          ...segments,
        ]
      }
      break
    case 'sms':
      segments = [
        {
          ordinal: 1,
          name: 'SMS',
          length: 160,
          max: 160,
        },
      ]
      break
    case 'email_in_body':
    case 'web_and_app':
      segments = [
        {
          ordinal: 1,
          name: 'Headline',
          length: 50,
        },
        {
          ordinal: 2,
          name: 'Sub-Headline',
          length: 70,
        },
        {
          ordinal: 3,
          name: 'CTA',
          length: 30,
          max: 30,
        },
      ]
      break
    case 'social_ad':
      segments = [
        {
          ordinal: 1,
          name: 'Primary-Text',
          length: 140,
        },
        {
          ordinal: 2,
          name: 'Headline',
          length: 30,
        },
        {
          ordinal: 3,
          name: 'Description',
          length: 40,
        },
      ]
      break
  }
  return segments
}

type Props = {
  distributionChannel: DistributionChannel
  languageGenerationMethod: LanguageGenerationMethodValue | undefined
}

const Segments = ({ distributionChannel, languageGenerationMethod }: Props) => {
  const dispatch = useAppDispatch()
  const isLlm = languageGenerationMethod === 'llm'
  const selectedSegments = useAppSelector(
    (state) => state.contentSettings.editedContent.regionStructure.segments
  )

  const availableSegments = getChannelSegments(
    distributionChannel,
    languageGenerationMethod
  )
  const segmentOptions: MultiSelectValue[] = availableSegments.map(
    ({ name }) => ({
      'data-cy': `segment-option-${name}`,
      id: name,
      label: name,
      value: name,
    })
  )

  return (
    <div className="flex gap-6">
      <FormItem className="max-w-100" label="Segments">
        <MultiSelect
          data-cy="segments-select"
          data-testid="segments-select"
          name="segments"
          placeholder="Select segments"
          selectedItems={selectedSegments?.map(({ name }) => name) ?? []}
          items={segmentOptions}
          onApply={(values) =>
            dispatch(
              selectSegments(
                availableSegments.filter(({ name }) => values.includes(name))
              )
            )
          }
          isFooterHidden={isLlm}
        />
      </FormItem>

      {selectedSegments?.map(({ name, max, length }) => (
        <FormItem
          key={name}
          className="max-w-29"
          htmlFor={name}
          label={name}
          guidingText={`Min: ${MIN_SEGMENT_LENGTH} ${
            max ? `& max: ${max}` : ''
          }`}
        >
          <Input
            data-cy={`segment-length-${name}`}
            type="text"
            className="text-coolGray-800"
            variant="default"
            id={name}
            name={name}
            value={String(length)}
            readOnly={isLlm}
            onChange={(event) => {
              dispatch(
                changeSegmentLength({
                  name,
                  value: Number(event.target.value.replace(/\D/g, '')),
                })
              )
            }}
            onBlur={() => dispatch(onSegmentLengthBlur(name))}
          />
        </FormItem>
      ))}
    </div>
  )
}

export default Segments
