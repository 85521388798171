import { generatePath, useHistory, useParams } from 'react-router-dom'
import { toneOfVoicePath } from 'app/navigation/paths'

import { queryClient } from 'common/api'
import Footer from 'common/components/layout/Footer'
import LoadingOverlay from 'common/components/LoadingOverlay'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Scrollable from 'common/components/scrollable'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { brandVoiceKeys } from '../api/queryKeys'
import { generateBrandTone } from '../store/brandVoiceSlice'

import SampleContent from './toneOfVoice/components/SampleContent'

const GenerateTones = () => {
  useDocumentTitle('Generate Brand Voice | Jacquard')

  const { accountId, brandVoiceId } =
    useParams<{ accountId: string; brandVoiceId: string }>()

  const dispatch = useAppDispatch()
  const history = useHistory()
  const isLoading = useAppSelector((state) => state.brandVoice.isGenerating)

  return (
    <>
      {isLoading ? (
        <LoadingOverlay
          isLoading={isLoading}
          title="Generating Brand tones"
          phrases={['Preparing your output...']}
          overlayType="local"
          className="relative"
        />
      ) : (
        <Scrollable
          isFullHeight={false}
          content={
            <PageContainer className="max-w-318 sm:mx-auto px-6 pt-12 lg:pt-8">
              <PageHeader>
                <div data-cy="header">
                  <PageTitle title="Brand Voice" />
                  <div className="text-coolGray-400">
                    Configure the below settings to let Jacquard know what we
                    should or should not use in the generation of your content.
                  </div>
                </div>
              </PageHeader>

              <SampleContent
                className="mt-6"
                generateTone={(sampleText) => {
                  dispatch(
                    generateBrandTone({
                      sampleText,
                      accountId,
                      brandVoiceId,
                    })
                  )
                    .then(() => {
                      queryClient.invalidateQueries(
                        brandVoiceKeys.details(accountId, brandVoiceId)
                      )
                    })
                    .then(() =>
                      history.push(
                        generatePath(toneOfVoicePath, {
                          accountId,
                          brandVoiceId,
                        })
                      )
                    )
                }}
              />
              <Footer />
            </PageContainer>
          }
        />
      )}
    </>
  )
}

export default GenerateTones
