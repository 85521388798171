import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import cx from 'classnames'

import { Download } from 'common/icons'
import useContent from 'features/futurama/contentPage/hooks/useContent'

import { contentDownloadCsv, contentDownloadXls } from '../../helpers'

const ExportMenuButton = () => {
  const { content } = useContent()

  const exportFile = (type?: 'csv' | 'xls') => {
    if (type === 'csv') {
      contentDownloadCsv(content)
    } else if (type === 'xls') {
      contentDownloadXls(content)
    }
  }

  return (
    <Menu
      menuClassName="cursor-pointer"
      menuButton={({ open }) => (
        <MenuButton
          aria-label="Export"
          className={cx('hover:text-yellow-200 whitespace-nowrap', {
            'text-yellow-200 ': open,
          })}
        >
          <Download isDefaultColor={false} />
        </MenuButton>
      )}
    >
      <MenuItem key="1" onClick={() => exportFile('csv')}>
        Export all: CSV
      </MenuItem>
      <MenuItem key="2" onClick={() => exportFile('xls')}>
        Export all: XLS
      </MenuItem>
    </Menu>
  )
}

export default ExportMenuButton
