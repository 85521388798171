import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchVariants, VariantsQueryParams } from '../api'
import { demoKeys } from '../queryKeys'

const useGetVariantsQuery = (queryParams: VariantsQueryParams) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { brand, channel, prevVariantsIds } = queryParams
  const doesContainEmptyStrings = Object.values(queryParams).some(
    (value) => value === ''
  )

  const query = useQuery(
    demoKeys.variants({ accountId, brand, channel, prevVariantsIds }),
    () => fetchVariants({ accountId, queryParams }),
    {
      enabled: !!accountId && !doesContainEmptyStrings,
      retry: false,
      refetchOnWindowFocus: false,
      meta: {
        errorMessage: 'The variants could not be loaded',
      },
      select: (data) =>
        data.map((variant) => {
          return {
            personalizedVariant: variant.personalisedVariant,
            ...variant,
          }
        }),
    }
  )

  return query
}

export default useGetVariantsQuery
