import CheckboxComponent from 'common/components/checkbox'
import FormItem from 'common/components/FormItem'

import { CheckboxType, CommonFieldPropsGeneric } from '../interfaces'

type Props = CommonFieldPropsGeneric & {
  value: CheckboxType['value']
  min: CheckboxType['min']
  max: CheckboxType['max']
  options: CheckboxType['options']
}

const Checkbox = ({
  min,
  max,
  label,
  value,
  tooltip,
  options,
  errorCode,
  isDisabled,
  onChange,
}: Props) => {
  const ERROR_MESSAGES = {
    required: 'Please select an option.',
    min: `Please select at least ${min} option${min === 1 ? '' : 's'}.`,
    max: `Selection is limited to ${max} options`,
  }

  const handleOnChange = (optionValue: string, isChecked: boolean) => {
    const updatedValue = isChecked
      ? [...(value || []), optionValue]
      : value?.filter((v) => v !== optionValue)
    onChange(updatedValue)
  }

  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      {options.map(({ label, value: optionValue }) => (
        <CheckboxComponent
          key={optionValue}
          className="inline-flex mr-4"
          onChange={(isChecked) => handleOnChange(optionValue, isChecked)}
          label={label}
          isChecked={value?.includes(optionValue) || false}
          isDisabled={isDisabled}
        />
      ))}
    </FormItem>
  )
}

export default Checkbox
