import Intercom from 'common/components/intercom/Intercom'

interface Props {
  showBookDemo?: boolean
}

const AuthFooter = ({ showBookDemo = true }: Props) => {
  return (
    <>
      <div
        className={`sm:absolute sm:block sm:ml-16  sm:w-auto mt-8
         bottom-12 text-lg flex justify-center w-full`}
      >
        <a href="https://phrasee.co/" target="_blank" rel="noreferrer">
          ⓒ{new Date().getFullYear()} Jacquard
        </a>
      </div>

      <Intercom />
    </>
  )
}

export default AuthFooter
