import Autocomplete from 'common/components/autcomplete'
import Button from 'common/components/button/Button'
import { ReactComponent as AddDefault } from 'common/icons/add/default.svg'

import { Account } from '../..'

type Props = {
  accounts: Account[]
  onSearch: (value: string) => void
  onAdd: () => void
}

const Header = ({ accounts, onSearch, onAdd }: Props) => {
  const options = accounts.map((account) => ({
    label: account.companyName,
    value: account.id,
  }))

  return (
    <>
      <Autocomplete
        className="w-60"
        placeholder="Search"
        data-cy="accounts-search"
        options={options}
        onSearch={onSearch}
        data-testid="accounts-search-box"
      />
      <Button
        data-cy="create-account-button"
        aria-label="Create a new account"
        variant="primary"
        onClick={onAdd}
        className="text-base"
        prefixIcon={<AddDefault width={24} height={24} />}
      >
        Account
      </Button>
    </>
  )
}

export default Header
