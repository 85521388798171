import cloneDeep from 'lodash/cloneDeep'

import { FieldsConfiguration, FieldType } from './interfaces'

export const parseFieldsConfiguration = (
  nodeConfiguration: FieldsConfiguration | undefined
): FieldsConfiguration | undefined => {
  if (!nodeConfiguration) {
    return undefined
  }
  const newNodeConfiguration = cloneDeep(nodeConfiguration)

  newNodeConfiguration.sort((a, b) => a.ordinal - b.ordinal)

  // Populate 'conditions' property where needed
  newNodeConfiguration.forEach((fieldConfig, _, array) => {
    if (
      (fieldConfig.type === FieldType.Radio ||
        fieldConfig.type === FieldType.Dropdown ||
        fieldConfig.type === FieldType.DropdownMulti) &&
      fieldConfig.conditional_fields
    ) {
      fieldConfig.conditional_fields.forEach((conditionalField) => {
        const condition = conditionalField.condition

        conditionalField.fields.forEach((fieldName) => {
          const targetField = array.find((config) => config.name === fieldName)
          if (targetField) {
            const targetFieldConditions = cloneDeep(targetField.conditions)
            const conditionValue = fieldConfig.options.find(
              (option) => option.label === condition
            )?.value

            if (conditionValue) {
              targetField.conditions = [
                ...(targetFieldConditions || []),
                {
                  condition: conditionValue,
                  fieldName: fieldConfig.name,
                },
              ]
            }
          }
        })
      })
    }
  })

  return newNodeConfiguration
}
