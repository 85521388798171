import Sidebar from 'common/components/sidebar'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { toggleIsSideBarVisible } from '../../store/dashboardSlice'

import RecentlyCreated from './RecentlyCreated'
import ToDo from './ToDo'

const DashboardSidebar = ({
  recentCampaigns,
  toDoWidget,
  setRecentCampaignsWidget,
  setToDoWidget,
  userId,
}) => {
  const dispatch = useAppDispatch()
  const { isSideBarVisible } = useAppSelector((state) => state.dashboard)

  return (
    <Sidebar
      isVisible={isSideBarVisible}
      onOverlayClick={() => dispatch(toggleIsSideBarVisible())}
    >
      <>
        {recentCampaigns && (
          <RecentlyCreated setWidget={setRecentCampaignsWidget} />
        )}
        {toDoWidget && <ToDo userId={userId} setWidget={setToDoWidget} />}
      </>
    </Sidebar>
  )
}

export default DashboardSidebar
