import React from 'react'

import SingleSelect from 'common/components/singleSelect'

type Props = Omit<
  React.ComponentProps<typeof SingleSelect>,
  'options' | 'data-cy' | 'name'
>
const FrequencySelect = (props: Props) => {
  return (
    <SingleSelect
      data-cy="frequency-select"
      name="frequency-select"
      options={[
        '1.0',
        '0.9',
        '0.8',
        '0.7',
        '0.6',
        '0.5',
        '0.4',
        '0.3',
        '0.2',
        '0.1',
        '0.0',
      ].map((value) => ({
        value,
        label: value,
      }))}
      {...props}
    />
  )
}

export default FrequencySelect
