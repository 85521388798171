import { useQuery } from '@tanstack/react-query'

import { Node, NodeTreeMetadata } from 'common/interfaces/nodes'

import { getNodes } from '../nodesApi'
import { nodesKeys } from '../queryKeys/nodesKeys'

const useGetNodesQuery = () => {
  const getNodesQuery = useQuery<{
    nodes: Node[]
    metadata: NodeTreeMetadata
  }>(nodesKeys.nodes(), getNodes)

  return getNodesQuery
}

export default useGetNodesQuery
