import cx from 'classnames'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { Bin } from 'common/icons'

import Base from './cells/Base'
import { Column } from './Table'

import styles from './Table.module.css'

export const generateDeleteButtonColumn = <T extends { id?: string | number }>({
  onClick,
  hasTooltip = false,
  buttonLabel,
  isDefaultColor,
  canDelete = () => true,
  isVisibleOnlyOnHover = true,
}: {
  onClick: (row: T) => void
  buttonLabel: string
  hasTooltip?: boolean
  isDefaultColor?: boolean
  canDelete?: (row: T) => boolean
  isVisibleOnlyOnHover?: boolean
}): Column<T> => {
  return {
    id: 'delete',
    accessor: 'id',
    disableSortBy: true,
    align: 'right',
    disableFilters: true,
    Cell: ({ row }) => (
      <Base>
        {canDelete(row.original) && (
          <ButtonWithTooltip
            tooltip={hasTooltip}
            tooltipText={buttonLabel}
            // group and group-hover are not used because adding group to the row of the table component changes the icon in the hovered state
            className={cx(isVisibleOnlyOnHover && styles.rowActions)}
            aria-label={buttonLabel}
            variant="icon"
            size="small"
            onClick={() => onClick(row.original)}
          >
            <Bin isDefaultColor={isDefaultColor} />
          </ButtonWithTooltip>
        )}
      </Base>
    ),
  }
}
