import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import type { Topic } from '../interfaces'

import TopicCard from './TopicCard'

export type SortableTopicCardProps = {
  topic: Topic
  isDisabled?: boolean
  isDragDisabled?: boolean
}

const SortableTopicCard = ({
  topic,
  isDragDisabled,
  ...rest
}: SortableTopicCardProps) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
    isSorting,
  } = useSortable({ id: topic.id, animateLayoutChanges: () => true })

  const style = {
    transform: isSorting ? undefined : CSS.Translate.toString(transform),
    transition,
    cursor: isDragDisabled ? 'default' : 'pointer',
    zIndex: 1,
  }

  return (
    <>
      {isDragging ? (
        <div
          className="p-2 border border-coolGray-400 border-dashed
        w-60 h-16 mr-4 flex-none mt-4 z-10 bg-gray-50"
        ></div>
      ) : (
        <TopicCard
          ref={setNodeRef}
          style={style}
          topic={topic}
          {...attributes}
          {...listeners}
          {...rest}
        />
      )}
    </>
  )
}

export default SortableTopicCard
