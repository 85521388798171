import Button from 'common/components/button'

interface Props {
  onClear: () => void
  onApply: () => void
}

const Footer = ({ onClear, onApply }: Props) => {
  return (
    <div className="flex items-center justify-end px-4 py-2 border-t border-coolGray-200">
      <Button className="mr-4" variant="link" size="medium" onClick={onClear}>
        Clear
      </Button>
      <Button variant="primary" size="medium" onClick={onApply}>
        Apply
      </Button>
    </div>
  )
}

export default Footer
