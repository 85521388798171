import { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AppState,
  Auth0Provider as Auth0ProviderComponent,
} from '@auth0/auth0-react'

const Auth0Provider: FC = ({ children }) => {
  const history = useHistory()

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || ''
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''

  const onRedirectCallback = useCallback(
    (appState: AppState): void => {
      history.push(appState?.returnTo || `${window.location.pathname}`)
    },
    [history]
  )

  const getRedirectUri = () => {
    const url = window.location.href
    const origin = window.location.origin

    if (url.includes('error=access_denied')) {
      if (url.includes('error_description=RESET_PASSWORD')) {
        return `${origin}/expired-password`
      }
      return `${origin}/logout-sso`
    }

    return origin
  }

  return (
    <Auth0ProviderComponent
      audience={audience}
      clientId={clientId}
      domain={domain}
      redirectUri={getRedirectUri()}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="memory"
      useRefreshTokens={true}
    >
      {children}
    </Auth0ProviderComponent>
  )
}

export default Auth0Provider
