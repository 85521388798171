import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'

type Props = {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}

const RegenerateModal = ({ isOpen, onClose, onConfirm }: Props) => {
  return (
    <ConfirmationModal
      data-cy="regenerate-elements-modal"
      title="Reset content"
      confirmationText="Are you sure you want to reset the content for all templates? Once it's gone, it's gone!"
      confirmButtonText="Reset"
      cancelButtonText="Cancel"
      open={isOpen}
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  )
}

export default RegenerateModal
