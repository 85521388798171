import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query'
import LaunchDarklyIdentify from 'app/LaunchDarklyIdentify'
import SourceBranchesReport from 'app/SourceBranchesReport'
import { injectStoreToFetchApi } from 'workflow/utils/Api'

import { injectStore, queryClient } from 'common/api'
import { ToastContainer } from 'common/components/toastNotification'
import { initLogger } from 'common/services/Logger'

import RouterComponent from './app/router/Router'
import { store } from './redux/store'

import './index.css'

injectStore(store)
injectStoreToFetchApi(store)
initLogger()
if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_ENABLE_MOCKS
) {
  const { worker } = require('./mocks/dev/browser')
  // must be started before dispatching actions
  // otherwise requests will be sent before mocking is enabled
  worker.start()
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LaunchDarklyIdentify>
        <QueryClientProvider client={queryClient}>
          <RouterComponent />
          <SourceBranchesReport />
          <ToastContainer />
        </QueryClientProvider>
      </LaunchDarklyIdentify>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
