import cx from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
  'data-testid'?: string
}

const CenterPanel = ({
  className,
  children,
  'data-testid': dataTestId = 'centerPanel',
}: Props) => {
  return (
    <div
      className={cx('flex flex-1 pt-6 mx-6 overflow-x-hidden', className)}
      data-testid={dataTestId}
    >
      {children}
    </div>
  )
}

export default CenterPanel
