import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { Node, NodeTreeMetadata } from 'common/interfaces/nodes'

import { updateNode } from '../api'
import { contentKeys } from '../queryKeys'

type MutationContext = { previousNode: Node }

const useUpdateNodeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    unknown,
    unknown,
    {
      userId: string
      nodeId: number
      updatedName?: string | undefined
      isPrivate?: boolean | undefined
      isDisplayed?: boolean | undefined
    },
    MutationContext
  >(updateNode, {
    onMutate: ({ nodeId, updatedName, isPrivate, isDisplayed }) => {
      queryClient.cancelQueries(contentKeys.nodes())

      const previousNode = queryClient
        .getQueryData<{ nodes: Node[] }>(contentKeys.nodes())
        ?.nodes.find((node) => parseInt(node.id) === nodeId)

      if (updatedName) {
        queryClient.setQueryData<{
          nodes: Node[]
          metadata: NodeTreeMetadata
        }>(contentKeys.nodes(), (old) => {
          if (!old) {
            return old
          }

          return {
            metadata: old.metadata,
            nodes: old.nodes.map((node) =>
              parseInt(node.id) === nodeId
                ? { ...node, name: updatedName }
                : node
            ),
          }
        })
      }

      if (isPrivate !== undefined) {
        queryClient.setQueryData<{
          nodes: Node[]
          metadata: NodeTreeMetadata
        }>(contentKeys.nodes(), (old) => {
          if (!old) {
            return old
          }

          return {
            metadata: old.metadata,
            nodes: old.nodes.map((node) =>
              parseInt(node.id) === nodeId
                ? { ...node, isPrivate: isPrivate }
                : node
            ),
          }
        })
      }

      if (isDisplayed !== undefined) {
        queryClient.setQueryData<{
          nodes: Node[]
          metadata: NodeTreeMetadata
        }>(contentKeys.nodes(), (old) => {
          if (!old) {
            return old
          }

          return {
            metadata: old.metadata,
            nodes: old.nodes.map((node) =>
              parseInt(node.id) === nodeId
                ? { ...node, isDisplayed: isDisplayed }
                : node
            ),
          }
        })
      }

      return { previousNode } as MutationContext
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData<{
        nodes: Node[]
        metadata: NodeTreeMetadata
      }>(contentKeys.nodes(), (old) => {
        if (!old) {
          return old
        }

        return {
          metadata: old.metadata,
          nodes: old.nodes.map((node) =>
            node.id === context?.previousNode.id ? context.previousNode : node
          ),
        }
      })

      showErrorToast(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.nodes())
    },
  })
}

export default useUpdateNodeMutation
