import { connectInternalApi } from 'common/api'

export type RealtimeUpdateTimeUnit =
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'once'

interface SchedulePayload {
  realtime_update_interval?: number
  realtime_update_time_unit?: RealtimeUpdateTimeUnit
  optimization_start_time: string
  optimization_end_time: string
}

interface ScheduleResponse {
  data: SchedulePayload
}

interface InitializePayload {
  campaign_id: string
  optimization_start_time: string
  optimization_end_time: string
  campaign_start_time: string
  campaign_end_time: string
  drop_bad_variants: boolean
  allow_control_drop: boolean
  optimization_mode?: string
  frequency?: { interval_type: RealtimeUpdateTimeUnit; interval_value: number }
  optimization_settings?: {
    mode: string
    expire_days?: number | null
    head_to_head_days?: number | null
    head_to_head_winner_prop?: number | null
    pool_size?: number
  }
  only_created_assets?: boolean
  is_sfmc_engage?: boolean
  performance_metric?: string
}

interface InitializeResponse extends InitializePayload {}

export const getPhraseeXSchedule = (campaignId: string) => {
  return connectInternalApi.get<ScheduleResponse>(
    `v1/otto/entries/${campaignId}`
  )
}

export const updatePhraseeXSchedule = (
  campaignId: string,
  data: SchedulePayload
) => {
  return connectInternalApi.put<{ data: { campaign_token?: string } }>(
    `v1/otto/entries/${campaignId}`,
    data
  )
}

export const initializePhraseeX = (
  campaignId: string,
  data: InitializePayload
) => {
  return connectInternalApi.post<InitializeResponse>(
    `v1/phraseex/campaigns/${campaignId}`,
    data
  )
}

export const updatePhraseeX = (campaignId: string, data: InitializePayload) => {
  return connectInternalApi.put<InitializeResponse>(
    `v1/phraseex/campaigns/${campaignId}`,
    data
  )
}

export const initializePhraseeXPush = (data: InitializePayload) => {
  return connectInternalApi.post<InitializeResponse>(
    `v1/maude/push/initialize`,
    data
  )
}

export const initializePhraseeXPushBroadcast = (data: InitializePayload) => {
  return connectInternalApi.post<InitializeResponse>(
    `v1/maude/broadcast-push/initialize`,
    data
  )
}
