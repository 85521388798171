import { useEffect, useState } from 'react'
import { Variant } from '@phrasee/phrasee-typings/typings/futurama/element'
import { useQueryClient } from '@tanstack/react-query'
import isEqual from 'lodash/isEqual'

import Button from 'common/components/button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import useGenerateVariantsMutation from 'features/futurama/api/mutations/useGenerateVariantsMutation'
import { workflowCardClicked } from 'features/futurama/store/contentSlice'

import { getGenerateWsTopic } from '../../../ContentPage'
import {
  getAllComponentElements,
  getElementGenerationComplete,
  getIsLastComponentElement,
  setVariantsLoadingState,
} from '../../../helpers'
import { useContent, useSelectedElement } from '../../../hooks'

import ContentNextActions from './ContentNextActions'
import CrossChannelActionButtons from './CrossChannelActionButtons'

type Props = {
  selectedRows: Variant[]
  initialSelectedRows: Variant[]
}

const ContentTableActionButtons = ({
  selectedRows,
  initialSelectedRows,
}: Props) => {
  const dispatch = useAppDispatch()
  const { content } = useContent()
  const queryClient = useQueryClient()
  const { data: selectedElement } = useSelectedElement()

  const [hasSelectionChanged, setHasSelectionChanged] = useState(false)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const generateVariantsMutation = useGenerateVariantsMutation()

  const isMultiContent = content?.multi_step || false

  const allComponentElements = getAllComponentElements(content)
  const isElementGenerationComplete = getElementGenerationComplete(
    content,
    selectedElement
  )

  const isLastComponentSelected = getIsLastComponentElement(
    content,
    selectedElement?.element_id
  )

  const nextComponent = !isLastComponentSelected
    ? allComponentElements[
        allComponentElements.findIndex(
          (component) => component.element_id === selectedElement?.element_id
        ) + 1
      ]
    : undefined
  const hasNextComponentSelection = !!nextComponent?.selected_variants?.length

  const generateVariants = () => {
    if (!content) {
      return
    }
    const nextElementId = nextComponent?.element_id
    if (nextElementId) {
      const wsTopic = getGenerateWsTopic(content._id, nextElementId)

      setVariantsLoadingState(queryClient, content._id, nextElementId)

      generateVariantsMutation.mutate({
        accountId,
        wsTopic,
        contentId: content._id,
        elementId: nextElementId,
      })
    }
  }

  useEffect(() => {
    if (!isEqual(initialSelectedRows, selectedRows)) {
      setHasSelectionChanged(true)
    } else {
      setHasSelectionChanged(false)
    }
  }, [initialSelectedRows, selectedRows])

  const getState = () => {
    if (
      selectedRows.length > 0 &&
      isMultiContent &&
      !isLastComponentSelected &&
      !hasNextComponentSelection
    ) {
      return 'generate'
    } else if (
      selectedRows.length > 0 &&
      isMultiContent &&
      !isLastComponentSelected &&
      hasNextComponentSelection &&
      hasSelectionChanged
    ) {
      return 'regenerate'
    } else if (
      selectedRows.length > 0 &&
      isMultiContent &&
      !isLastComponentSelected &&
      hasNextComponentSelection
    ) {
      return 'continue'
    } else if (selectedRows.length > 0 && isLastComponentSelected) {
      return 'cross-channel'
    } else {
      return 'none'
    }
  }

  const state = getState()

  return (
    <div className="flex justify-center items-center">
      {
        {
          generate: (
            <>
              <span className="text-base font-medium text-coolGray-800 mr-4">
                Next up...
              </span>
              <ContentNextActions
                selectedRows={selectedRows}
                isEnabled={isElementGenerationComplete}
              />
              <Button
                className="ml-4"
                data-cy="content-generate-next-step-button"
                disabled={generateVariantsMutation.isLoading}
                loading={generateVariantsMutation.isLoading}
                variant="primary"
                suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
                onClick={generateVariants}
              >
                Generate {nextComponent?.display_name || nextComponent?.name}
              </Button>
            </>
          ),
          regenerate: (
            <>
              <span className="text-base font-medium text-coolGray-800 mr-4">
                You've made changes...
              </span>
              <Button
                ghost
                className="mr-4"
                data-cy="content-regenerate-skip-button"
                //disabled={isGeneratingVariants}
                onClick={() =>
                  dispatch(
                    workflowCardClicked({
                      elementId: nextComponent?.element_id,
                    })
                  )
                }
              >
                Skip
              </Button>
              <Button
                data-cy="content-regenerate-next-step-button"
                disabled={generateVariantsMutation.isLoading}
                loading={generateVariantsMutation.isLoading}
                variant="primary"
                suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
                onClick={generateVariants}
              >
                Regenerate {nextComponent?.display_name || nextComponent?.name}
              </Button>
            </>
          ),
          continue: (
            <>
              <ContentNextActions
                selectedRows={selectedRows}
                isEnabled={isElementGenerationComplete}
              />
              <Button
                className="ml-4"
                data-cy="content-generate-continue-button"
                variant="primary"
                suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
                onClick={() =>
                  dispatch(
                    workflowCardClicked({
                      elementId: nextComponent?.element_id,
                    })
                  )
                }
              >
                Continue
              </Button>
            </>
          ),
          'cross-channel': (
            <>
              <ContentNextActions
                selectedRows={selectedRows}
                isEnabled={isElementGenerationComplete}
              />
              <CrossChannelActionButtons />
            </>
          ),
          none: null,
        }[state]
      }
    </div>
  )
}

export default ContentTableActionButtons
