import CheckBoxSelector from 'common/components/checkbox'
import NumericInput from 'common/components/numericInput'

interface Props {
  label: string
  checkboxDataTestId: string
  isChecked: boolean
  onCheckboxChange: (val: boolean) => void
  inputDataTestId: string
  inputValue: number
  onInputChange: (val: number | undefined) => void
  inputMetric?: string
  minValue: number
  maxValue: number
  defaultValue: number
  isDisabled: boolean
  tooltip?: string
  postfix?: string
}
export const CheckboxWithInput = ({
  label,
  checkboxDataTestId,
  isChecked,
  onCheckboxChange,
  inputDataTestId,
  inputValue,
  onInputChange,
  inputMetric,
  minValue,
  maxValue,
  defaultValue,
  isDisabled,
  tooltip,
  postfix,
}: Props) => {
  return (
    <div className="mb-6">
      <CheckBoxSelector
        tooltip={tooltip ? { label: tooltip } : undefined}
        label={label}
        isDisabled={isDisabled}
        data-testid={checkboxDataTestId}
        isChecked={isChecked}
        onChange={onCheckboxChange}
        className="mb-2"
        disableAlignItemsCenter
      />
      {isChecked && (
        <div className="flex items-baseline ml-10">
          <div>
            <NumericInput
              precision={0}
              className="w-14"
              data-testid={inputDataTestId}
              value={inputValue}
              onChange={(value) => {
                onInputChange(value)
              }}
              min={minValue}
              max={maxValue}
              defaultValue={defaultValue}
              disabled={!isChecked || isDisabled}
              postfix={postfix}
            />
          </div>
          <span className="ml-3 leading-normal font-medium text-coolGray-800">
            {inputMetric}
          </span>
        </div>
      )}
    </div>
  )
}
