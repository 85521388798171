import { Campaign } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'

import { CordialBatchMessage } from './types'

interface CordialBatchMessageResponse {
  batchMessages: CordialBatchMessage[]
}

interface CreateExperimentPayload {
  strategy: string
  championSendTime?: {
    hours: string | undefined
    minutes: string | undefined
  }
}

interface SaveBatchMessageIdPayload {
  batchMessageId: string | undefined
  strategy: string
  championSendTime?: {
    hours: string | undefined
    minutes: string | undefined
  }
}

export async function getCordialBatchMessages(projectId: string) {
  return connectInternalApi.get<CordialBatchMessageResponse>(
    `v1/integrations/cordial/projects/${projectId}/batch-messages`
  )
}

export async function createCordialMessageExperiment(
  campaignId: string,
  data: CreateExperimentPayload
) {
  return connectInternalApi.post<string>(
    `v1/integrations/cordial/campaigns/${campaignId}/experiment`,
    data
  )
}

export async function deleteCordialMessageExperiment(campaignId: string) {
  return connectInternalApi.delete<string>(
    `v1/integrations/cordial/campaigns/${campaignId}/experiment`
  )
}

export async function saveCordialBatchMessageId(
  campaignId: string,
  data: SaveBatchMessageIdPayload
) {
  return connectInternalApi.put<Campaign>(
    `v1/integrations/cordial/campaigns/${campaignId}`,
    data
  )
}
