import cx from 'classnames'

import { SortArrowAsc, SortArrowDesc } from 'common/icons'

type Props = {
  className?: string
  sortedDirection?: 'asc' | 'desc'
}

const SortIcon = ({ className, sortedDirection }: Props) => (
  <span
    className={cx('group-hover:visible', className, {
      'invisible text-coolGray-400': !sortedDirection,
      'text-maroon-300': sortedDirection,
    })}
  >
    {sortedDirection === 'asc' ? (
      <SortArrowAsc size={4} isDefaultColor={false} />
    ) : (
      <SortArrowDesc size={4} isDefaultColor={false} />
    )}
  </span>
)

export default SortIcon
