import { useHistory } from 'react-router'

import { ContentTypeIcon } from 'common/components/ContentTypeIcon'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import MetricItem from 'common/components/MetricItem'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { capitalizeFirstLetter } from 'common/helpers/string'

type Props = {
  title: string
  kpiContent?: KpiContent
  isLoading: boolean
}
export interface KpiContent {
  contentsNumber: number
  categories: CategoriesEntity[]
}
export interface CategoriesEntity {
  label: string
  value: number
}

const CONTENT_TYPES = [
  'Ecommerce',
  'Email',
  'Social',
  'Web',
  'Messaging',
  'Ads',
]

const renderIcon = (contentType) => {
  return <ContentTypeIcon contentType={contentType} />
}

const ContentKpi = ({ title, kpiContent, isLoading }: Props) => {
  const history = useHistory()

  return (
    <div className="w-full h-full">
      {isLoading && (
        <WidgetLoader data-cy="content-kpi-widget-loader" size="small" />
      )}
      {!isLoading && kpiContent && (
        <div>
          <WidgetHeader className="" title={title} />
          <Widget type="default" className="h-full max-h-88">
            <div className="pb-4 text-coolGray-800 font-medium text-sm mr-4">{` ${kpiContent?.contentsNumber} content generated`}</div>
            <div className="grid grid-cols-2 gap-4">
              {CONTENT_TYPES.map((contentType) => {
                const kpi = kpiContent.categories.find(
                  (category) => category.label === contentType
                )
                if (kpi && kpi.value > 0) {
                  return (
                    <MetricItem
                      key={contentType}
                      icon={renderIcon(kpi.label)}
                      text={`${kpi.value} ${capitalizeFirstLetter(
                        contentType
                      )}`}
                      onClick={() => {
                        history.push('/content', {
                          contentTab: contentType.toLowerCase(),
                        })
                      }}
                    />
                  )
                } else {
                  return (
                    <MetricItem
                      key={contentType}
                      icon={renderIcon(contentType)}
                      text={`Create ${contentType}`}
                      onClick={() => {
                        history.push('/content', {
                          templateTab: contentType.toLowerCase(),
                        })
                      }}
                    />
                  )
                }
              })}
            </div>
          </Widget>
        </div>
      )}
    </div>
  )
}

export default ContentKpi
