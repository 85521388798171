import { ReactNode, useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import Button from 'common/components/button'
import { CloseSmall } from 'common/icons'

interface Props {
  title: string
  className?: string
  isVisible?: boolean
  children?: ReactNode
  isHeaderSticky?: boolean
  onClose: () => void
}

function AnimatedOverlay({
  title,
  onClose,
  className,
  children,
  isVisible = false,
  isHeaderSticky,
}: Props) {
  const [shouldShow, setShouldShow] = useState(false)
  const isMountedVisible = useRef(isVisible)

  useEffect(() => {
    let clearTimeoutId: NodeJS.Timeout

    // Adding a slight delay when the component is mounted with isVisible=true, to enable the animation
    if (isMountedVisible.current) {
      setTimeout(() => {
        setShouldShow(isVisible)
      }, 100)
    } else {
      setShouldShow(isVisible)
    }

    return () => {
      isMountedVisible.current = false
      clearTimeout(clearTimeoutId)
    }
  }, [isVisible])

  return (
    <div
      data-cy="animated-overlay"
      className={cx(
        'flex flex-col bg-warmGray-50 fixed inset-0 z-110 transition-transform ease-out duration-300 transform',
        {
          'translate-y-0': shouldShow,
          'translate-y-full': !shouldShow,
        },
        className
      )}
    >
      <div
        className={cx('bg-maroon-500 border-b-1 border-coolGray-200 h-14', {
          'sticky top-0 z-10': isHeaderSticky,
        })}
      >
        <div className="flex items-center px-6 py-2">
          <Button onClick={onClose}>
            <CloseSmall className="text-white" />
          </Button>
          <span className="text-lg font-medium text-white">{title}</span>
        </div>
      </div>
      {children}
    </div>
  )
}

export default AnimatedOverlay
