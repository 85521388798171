import { useState } from 'react'
import cx from 'classnames'

import Button from 'common/components/button'
import Image from 'common/components/image'
import Footer from 'common/components/layout/Footer'
import Overlay from 'common/components/Overlay'
import { useDocumentTitle } from 'common/hooks/custom'
import CreateWidget from 'features/admin/brandVoice/components/CreateWidget'

const EmptyBrandVoice = () => {
  useDocumentTitle('Brand Voice | Jacquard')

  const [isCreateWidgetVisible, setIsCreateWidgetVisible] =
    useState<boolean>(false)

  return (
    <>
      <div className="flex flex-col justify-center items-center w-full h-full bg-warmGray-50">
        <Image
          className="h-52 w-40 mb-12"
          src="/images/logo-secondary.png"
          alt="empty state"
        />
        <h2 className="text-xl font-medium mb-4">Welcome to Brand Voice</h2>
        <p className="text-base text-coolGray-400 font-medium mb-8 max-w-180 text-center">
          Well-defined brand voice ensures consistency in your marketing
          communications, helps build a relationship with your customers, and
          even protects your business from missteps...
        </p>
        <Button
          className="mb-4"
          variant="primary"
          onClick={() => setIsCreateWidgetVisible(true)}
        >
          Get started
        </Button>
        <Footer />
        <Overlay
          className={cx({
            hidden: !isCreateWidgetVisible,
          })}
        >
          {isCreateWidgetVisible && (
            <CreateWidget onClose={() => setIsCreateWidgetVisible(false)} />
          )}
        </Overlay>
      </div>
    </>
  )
}

export default EmptyBrandVoice
