import { FC } from 'react'
import cx from 'classnames'

import BaseCell from 'common/components/table/cells/Base'

type Props = {
  title: string
  value: string | number
  valueClassName: string
}

const Cell: FC<Props> = ({ title, value, valueClassName }) => (
  <BaseCell className="flex flex-col justify-between py-4">
    <div className="mb-1 text-coolGray-500 text-center">{title}</div>
    <div className={cx('text-xl font-bold', valueClassName)}>
      {value.toLocaleString('en')}
    </div>
  </BaseCell>
)

export default Cell
