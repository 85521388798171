import { useState } from 'react'

import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import { Phrase } from 'features/content/api/interfaces'
import useBulkMovePhraseMutation from 'features/content/api/mutations/useBulkMovePhraseMutation'
import useGetPhrasesQuery from 'features/content/api/queries/useGetPhrasesQuery'

import ManagePhrasesModal from './ManagePhrasesModal'

type Props = {
  isOpen: boolean
  onDelete: (phrasesToDelete: Phrase[]) => void
  onClose: () => void
  currentNodeId: string
  selectedRows: Phrase[]
}

const MovePhrasesModal = ({
  isOpen,
  onDelete,
  onClose,
  currentNodeId,
  selectedRows,
}: Props) => {
  const [targetNodeId, setTargetNodeId] = useState<string>('')
  const [duplicates, setDuplicates] = useState<string[]>([])
  const userId = useAppSelector((state) => state.authStates.user_id)

  const movePhrasesBulkMutation = useBulkMovePhraseMutation(currentNodeId)

  const { data: phrases } = useGetPhrasesQuery(targetNodeId, !!targetNodeId)

  const phrasesWithoutDuplicates = selectedRows.filter(
    (row) => !duplicates?.includes(row.phrase)
  )

  const handleMovePhrases = (phrasesToMove: Phrase[]) => {
    const errorList: string[] = []
    const phrasesIdsToMove = phrasesToMove.map((phrase) => phrase.phraseId)

    if (phrasesIdsToMove.length > 0) {
      movePhrasesBulkMutation.mutate(
        {
          userId,
          phraseIds: phrasesIdsToMove,
          targetNodeId: Number(targetNodeId),
        },
        {
          onSuccess: (results) => {
            results.forEach((result, index) => {
              if (result.status === 'rejected') {
                errorList.push(String(phrasesIdsToMove[index]))
              }
            })

            if (errorList.length > 0) {
              errorToast(`Failed to move ${errorList.length} phrases.`)
            } else {
              successToast(
                phrasesIdsToMove.length > 1
                  ? `${phrasesIdsToMove.length} Phrases moved successfully`
                  : 'Phrase moved successfully'
              )
              onClose()
            }
          },
        }
      )
    } else {
      onClose()
    }
  }

  const handleNext = () => {
    const duplicatesBetweenNodes: string[] = []
    const targetNodePhrases = phrases?.map((phrase) =>
      phrase.phrase.toLocaleLowerCase()
    )
    selectedRows
      .map((row) => row.phrase)
      .forEach((phrase) => {
        if (targetNodePhrases?.includes(phrase.toLocaleLowerCase())) {
          duplicatesBetweenNodes.push(phrase)
        }
      })
    if (duplicatesBetweenNodes.length > 0) {
      setDuplicates(duplicatesBetweenNodes)
    } else {
      handleMovePhrases(selectedRows)
    }
  }
  const handleMovePhrasesAndDeleteDuplicates = () => {
    const duplicatePhrases = selectedRows.filter((row) =>
      duplicates?.includes(row.phrase)
    )
    handleMovePhrases(phrasesWithoutDuplicates)
    onDelete(duplicatePhrases)
  }

  const handleMovePhrasesAndKeepDuplicates = () => {
    handleMovePhrases(phrasesWithoutDuplicates)
  }
  return (
    <ManagePhrasesModal
      isOpen={isOpen}
      onCancel={onClose}
      onNext={handleNext}
      onMoveAndDelete={handleMovePhrasesAndDeleteDuplicates}
      onMoveAndKeep={handleMovePhrasesAndKeepDuplicates}
      hasDuplicates={!!duplicates.length}
      onSelectNodeId={setTargetNodeId}
      currentNodeId={currentNodeId}
    />
  )
}

const MovePhrasesModalWithResetState = (props: Props) => (
  <MovePhrasesModal key={`${props.isOpen}`} {...props} />
)

export default MovePhrasesModalWithResetState
