import { TreeNode } from 'common/components/catalog/Catalog'

import { CustomerAttributes } from './api'

const ROOT_KEY = 'personalization'

export const personalizationKeys = {
  all: [ROOT_KEY] as const,
  personalizations: (accountId: string) =>
    [ROOT_KEY, 'personalizations', accountId] as const,
  filters: [ROOT_KEY, 'filters'] as const,
  selectedFilters: [ROOT_KEY, 'selectedFilter'] as const,
  graphs: [ROOT_KEY, 'graphs'] as const,
  variants: ({
    accountId,
    customerAttributes,
    personalizationId,
    productCategories,
  }: {
    accountId: string
    personalizationId: string
    customerAttributes?: CustomerAttributes | undefined
    productCategories?: TreeNode | undefined
  }) =>
    [
      ROOT_KEY,
      'variants',
      accountId,
      personalizationId,
      customerAttributes,
      productCategories,
    ] as const,
  customerAttributes: (accountId: string) =>
    [ROOT_KEY, 'customerAttributes', accountId] as const,
  productCategories: (accountId: string) =>
    [ROOT_KEY, 'productCategories', accountId] as const,
  metrics: ({
    accountId,
    channelId,
    customerAttributes,
    personalizationId,
    productCategories,
  }: {
    accountId: string
    channelId: string | undefined
    personalizationId: string
    customerAttributes: CustomerAttributes | undefined
    productCategories: TreeNode | undefined
  }) =>
    [
      ROOT_KEY,
      'metrics',
      accountId,
      channelId,
      customerAttributes,
      personalizationId,
      productCategories,
    ] as const,
}
