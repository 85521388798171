import { useHistory } from 'react-router-dom'
import { IntegrationAccount } from '@phrasee/phrasee-typings/typings/integrations/accounts'

import { Variant } from 'common/components/button/Button'
import ButtonWithDropdown, {
  ButtonOptionsProps,
} from 'common/components/ButtonWithDropdown'
import { capitalizeFirstLetter } from 'common/helpers/string'
import { useAppSelector } from 'common/hooks/redux'

import useUpdateIntegrationAccountMutation from './api/mutations/useUpdateIntegrationAccountMutation'

type Props = {
  accounts: IntegrationAccount[]
  children: React.ReactNode
  contentId: string
  elementId: number
  variant?: Variant
  classdName?: string
}

const IntegrationSelectionButton = ({
  accounts,
  variant,
  contentId,
  elementId,
  classdName,
  children,
}: Props) => {
  const history = useHistory()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const updateIntegrationAccountMutation = useUpdateIntegrationAccountMutation()

  const submitOptions: ButtonOptionsProps[] = accounts.map((account) => ({
    key: account.id,
    label: capitalizeFirstLetter(account.provider),
    onClick: () =>
      updateIntegrationAccountMutation.mutate({
        accountId,
        contentId,
        elementId,
        updatedElement: { integration_account_id: account.id },
      }),
  }))

  submitOptions.push(
    {
      key: 'divider',
      type: 'divider',
    },
    {
      key: 'add',
      label: 'Add new integration',
      onClick: () => history.push(`/admin/accounts/${accountId}/integrations`),
    }
  )

  return (
    <ButtonWithDropdown
      className={classdName}
      variant={variant}
      options={submitOptions}
    >
      {children}
    </ButtonWithDropdown>
  )
}

export default IntegrationSelectionButton
