import CenterPanel from '../../components/layout/CenterPanel'

import BriefPage from './BriefPage'

const CenterSection = () => {
  return (
    <CenterPanel>
      <BriefPage />
    </CenterPanel>
  )
}

export default CenterSection
