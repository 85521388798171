import React from 'react'

import * as GraphWidget from '../GraphWidget'
import { InfoAction } from '../WidgetHeader'

import SentimentAnalysisChart from './SentimentAnalysisChart'

export const sentimentsDistributionText = `The distribution of sentiments within the language you've tested with Jacquard. Click on each sentiment label in the chart to see which words contribute to each sentiment.`

type Props = {
  series: {
    data: number[]
    name: string
    color: string
  }[]
  onClickSentiment: (sentiment: string) => void
  selectedSentiment: string
}

const SentimentAnalysisWidget = ({
  series,
  onClickSentiment,
  selectedSentiment,
}: Props) => {
  return (
    <div className="m-6">
      <GraphWidget.Widget
        title="Sentiments"
        className="h-full relative"
        actions={
          <div className="inline-flex">
            <InfoAction
              message={
                <div>
                  <p>
                    Jacquard maps out the sentiments of every language variant
                    you generate.
                  </p>
                  <p>
                    This allows you to see what Jacquard is testing and what
                    your audience is engaging with.
                  </p>
                  <p>
                    Try using the tooltips to learn more about each sentiment
                    and see how you could test out successful strategies
                    elsewhere!
                  </p>
                </div>
              }
            />
          </div>
        }
      >
        <span
          className="text-sm text-coolGray-700 opacity-80 -mt-2 z-20"
          style={{ maxWidth: 301 }}
        >
          {sentimentsDistributionText}
        </span>
        <div className="mt-6 lg:-mt-15">
          <SentimentAnalysisChart
            series={series}
            onClickSentiment={onClickSentiment}
            selectedSentiment={selectedSentiment}
          />
        </div>
      </GraphWidget.Widget>
    </div>
  )
}

export default SentimentAnalysisWidget
