import { useEffect, useState } from 'react'

import BarGraph, { BarData } from 'common/components/BarGraph'
import * as GraphWidget from 'common/components/GraphWidget'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { InfoAction } from 'common/components/WidgetHeader'
import { WidgetError } from 'common/components/WidgetMessage'
import barChartColors from 'common/helpers/ui/barChartColors'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import { fetchWordPerformanceReport } from '../api'
import { WidgetProps } from '../interfaces'

const WordPerformanceWidget = ({
  accountId,
  projectIds,
  startDate,
  endDate,
  setHasData,
}: WidgetProps & { setHasData?: (value: boolean) => void }) => {
  const [results, setResults] = useState<BarData[]>([])

  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { chartRefCallback, chart } = useChartRefCallback()

  useEffect(() => {
    setResults([])
    setIsLoading(true)
    setHasError(false)

    fetchWordPerformanceReport({
      accountId,
      projectIds,
      startDate,
      endDate,
      reportingLevel: 'project',
    })
      .then((graphResponse) => {
        const graphData = graphResponse.data.data

        const data = graphData?.[0]?.values
          ?.find(({ name }) => name.toLowerCase() === 'average')
          ?.values.slice(0, 18) // display first 18 words in graph

        const dataWithColors = data?.map(({ value, label }, index) => ({
          color: barChartColors[index],
          name: label,
          value,
        }))

        if (graphData === undefined || graphData.length === 0) {
          setHasData?.(false)
          setResults([])
        } else if (dataWithColors) {
          setHasData?.(true)
          setResults(dataWithColors)
        }
      })
      .catch((e) => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, projectIds, startDate, endDate, setHasData])

  const title = 'Word performance'

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (hasError) {
      return 'error'
    } else if (results && results.length > 0) {
      return 'results'
    } else {
      return 'default'
    }
  }

  const state = getState()

  if (state === 'default') {
    return null
  }

  return (
    <GraphWidget.Widget
      type="inner"
      title={
        <div>
          <div className="text-coolGray-800 font-medium text-xl mb-4 mr-4">
            {title}
          </div>
          <p className="text-coolGray-500 text-sm font-normal">
            The most frequently tested words for this time period, plotted
            against their relative performance.
          </p>
        </div>
      }
      actions={
        (state === 'results' && chart && (
          <div className="inline-flex">
            <ChartWidgetMenu chart={chart} title={title} />
            <InfoAction
              message="The higher the bar is above zero, the more frequently the word is 
              found in high-performing language. This is a good way to start getting a picture of what’s 
              resonating with your audience."
            />
          </div>
        )) ??
        undefined
      }
    >
      {
        {
          error: <WidgetError className="mb-6" />,
          loading: <WidgetLoader data-cy="word-performance-widget-loader" />,
          results: (
            <BarGraph
              data={results}
              ref={chartRefCallback}
              labelRotationDegrees={45}
              labelFontSize="12px"
            />
          ),
        }[state]
      }
    </GraphWidget.Widget>
  )
}

export default WordPerformanceWidget
