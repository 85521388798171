import cx from 'classnames'

import { AvatarColor } from 'features/profile/AvatarColorPicker'

interface Props {
  firstName: string
  lastName?: string
  color?: AvatarColor
  className?: string
}

export const getAcronym = (
  firstName: string,
  lastName: string | undefined
): string => {
  let displayedNames = lastName ? [lastName] : []
  const names = firstName.split(/\s/)
  // don't include middle name
  displayedNames = [names[0], ...displayedNames]

  return displayedNames
    .reduce(
      (response: string, word: string) => (response += word.slice(0, 1)),
      ''
    )
    .toUpperCase()
}

const Avatar = ({ firstName, lastName, color, className }: Props) => {
  return (
    <div
      data-testid="user-avatar"
      data-cy="campaign-nav-item-creator"
      className={cx(
        `flex items-center justify-center	rounded-full text-teal-100`,
        `shadow-sm`,
        { 'bg-yellow-500 ': !color },
        { 'bg-red-300': color === 'red' },
        { 'bg-maroon-300': color === 'orange' },
        { 'bg-amber-400': color === 'amber' },
        { 'bg-emerald-400': color === 'emerald' },
        { 'bg-blue-400': color === 'blue' },
        { 'bg-indigo-400': color === 'indigo' },
        { 'bg-violet-400': color === 'violet' },
        className
      )}
    >
      <span className="text-white">{getAcronym(firstName, lastName)}</span>
    </div>
  )
}

export default Avatar
