import { useRef, useState } from 'react'
import Carousel from 'react-multi-carousel'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button'
import { ChevronLeft, ChevronRight } from 'common/icons'
import { GraphsResponse } from 'features/personalization/api'

import GridView from './GirdView'
import Graph from './Graph'

import 'react-multi-carousel/lib/styles.css'

type Props = {
  data: GraphsResponse
  isDataLoading: boolean
}

const Graphs = ({ data, isDataLoading }: Props) => {
  const flags = useFlags()

  const excludedMetrics = [
    'total sends',
    'total holdout lines served',
    'holdout opens',
    'holdout open rate',
    'holdout clicks',
    'holdout click rate',
  ]
  const filteredData = flags.showPersonalizationHoldoutData
    ? data
    : data.filter(
        (item) => !excludedMetrics.includes(item.displayName.toLowerCase())
      )

  const carouselRef = useRef<Carousel>(null)
  const responsive = {
    xs: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
    sm: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    md: {
      breakpoint: { max: 1600, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    xl: {
      breakpoint: { max: 9000, min: 1600 },
      items: 4,
    },
  }

  const [isPreviousButtonDisabled, setIsPreviousButtonDisabled] =
    useState(false)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false)
  const [isShowAll, setIsShowAll] = useState(false)

  if (isDataLoading && filteredData.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <div className="w-full">
      <div className="">
        <div className="mb-6 flex justify-between">
          <div className="text-xl font-medium text-gray-800 ">Metrics</div>
          <div className="flex items-center gap-4">
            <Button
              variant="link"
              onClick={() => {
                setIsShowAll(!isShowAll)
                setIsPreviousButtonDisabled(!isShowAll)
                setIsNextButtonDisabled(!isShowAll)
              }}
            >
              {isShowAll ? 'Show less' : 'Show all'}
            </Button>
            <div className="flex text-coolGray-400 gap-4">
              <Button
                variant="icon"
                prefixIcon={<ChevronLeft />}
                onClick={() => {
                  carouselRef.current?.previous(1)
                }}
                disabled={isPreviousButtonDisabled}
              />
              <Button
                variant="icon"
                prefixIcon={<ChevronRight />}
                onClick={() => {
                  carouselRef.current?.next(1)
                }}
                disabled={isNextButtonDisabled}
              />
            </div>
          </div>
        </div>
        {isShowAll ? (
          <GridView>
            {filteredData.map(({ displayName: label, value }) => (
              <div key={label} className="" data-testid={label}>
                <Graph label={label} value={value} />
              </div>
            ))}
          </GridView>
        ) : Object.keys(filteredData).length > 0 ? (
          <Carousel
            ref={carouselRef}
            infinite
            showDots={false}
            arrows={false}
            responsive={responsive}
            partialVisbile={true}
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            itemClass="pr-6 last:pr-0"
            className="max-full w-full"
          >
            {filteredData.map(({ displayName: label, value }) => (
              <Graph label={label} key={label} value={value} />
            ))}
          </Carousel>
        ) : null}
      </div>
    </div>
  )
}

export default Graphs
