import { FallbackProps } from 'react-error-boundary'

import GlobalErrorContent from 'common/components/error/GlobalErrorContent'
import AuthFooter from 'features/auth/components/Footer'

const Error: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <div className="relative min-h-screen flex flex-col justify-center">
      <div className="flex justify-center">
        <div>
          <GlobalErrorContent onHomeClick={resetErrorBoundary} />
        </div>
      </div>

      <AuthFooter showBookDemo={false} />
    </div>
  )
}

export default Error
