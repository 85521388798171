import React from 'react'

interface Props {
  text: string
  style?: React.CSSProperties
}

const GuidingText = ({ text, style }: Props) => (
  <div
    className="mt-2 text-coolGray-400 max-w-full guidingText"
    style={{ ...style, lineHeight: 1.5 }}
  >
    {text}
  </div>
)

export default GuidingText
