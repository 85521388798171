import { FieldMetaState } from 'react-final-form'

const PROJECT_NAME_REGEX = /^[0-9a-zA-Z\s\-_']+$/

export const validateProjectName = (
  name: string,
  unavailableNames: string[] | undefined
) => {
  const hasInvalidCharacters =
    name.length && PROJECT_NAME_REGEX.test(name.toString()) === false
  const isTaken = unavailableNames?.includes(name)

  return {
    isValid: !hasInvalidCharacters && !isTaken,
    error: hasInvalidCharacters
      ? 'Jacquard only accepts letters, numbers, spaces, hyphens, apostrophes and underscores in project names.'
      : isTaken
      ? 'This name already exists within your account.'
      : undefined,
  }
}

export const getFieldError = <T>(meta: FieldMetaState<T>): string | undefined =>
  (meta.error || meta.submitError) && meta.touched
    ? meta.error || meta.submitError.message
    : undefined
