import cx from 'classnames'

import CardIcon from '../components/CardIcon'

type Props = {
  title?: string
  text?: string
  icon?: string
  isEmpty?: boolean
  type: 'step' | 'channel'
}

const PreviewCard = ({ title, text, type, icon, isEmpty }: Props) => {
  return (
    <>
      {type === 'step' && (
        <div className="text-xs mb-1 mt-4 font-medium text-coolGray-800">
          {title}
        </div>
      )}
      {!isEmpty && (
        <div
          className={cx('text-xs whitespace-pre-line', {
            'mt-4': type === 'channel',
          })}
        >
          {text ? (
            <span className="text-coolGray-600">{text}</span>
          ) : (
            <span className="text-coolGray-400 block">{`This will be the '${title?.toLowerCase()}' text...`}</span>
          )}
        </div>
      )}
      {isEmpty && (
        <div className="flex flex-col items-center">
          <div className="mt-4 mb-6">
            <CardIcon icon={icon || ''} size={12} />
          </div>
          <div className="text-xs font-medium text-coolGray-800 mb-1">
            Where's the content?!
          </div>
          <div className="text-sm font-normal text-coolGray-400">
            Content will appear here once generated.
          </div>
        </div>
      )}
    </>
  )
}

export default PreviewCard
