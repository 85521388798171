import axios from 'axios'

import { errorToast } from 'common/components/toastNotification'

import { GlobalHandledErrors } from './api'

export const showErrorToast = (error: unknown, errorMessage?: string) => {
  if (
    axios.isAxiosError(error) &&
    !Object.values(GlobalHandledErrors).includes(
      error.response?.data?.statusCode
    )
  ) {
    const message =
      errorMessage ||
      error.response?.data?.message ||
      error.response?.data?.msg ||
      'Something went wrong. Please try again.'

    errorToast(message)
  }
}
