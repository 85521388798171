import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast, successToast } from 'common/components/toastNotification'
import { contentKeys } from 'features/futurama/api/queryKeys'

import { connectContent } from '../api'

const useConnectContentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(connectContent, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (response, variables) => {
      const payloadContentLength = variables.content.length
      const failedContentLength = response.failures?.length || 0
      const successfullySubmittedContentLength =
        payloadContentLength - failedContentLength

      if (payloadContentLength !== failedContentLength && response.content) {
        queryClient.setQueryData(
          contentKeys.content(response.content._id),
          response.content
        )
        successToast(
          `${successfullySubmittedContentLength} asset${
            successfullySubmittedContentLength === 1 ? '' : 's'
          } submitted successfully`
        )
      }
    },
  })
}

export default useConnectContentMutation
