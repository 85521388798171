/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit'

import { restApi } from 'common/api'
import { SelectOption } from 'features/campaigns/integration-options/components/select/SelectComponent'

import './facebook.connect'

interface FacebookState {
  isLoading: {
    fbAuth: boolean
    adAccounts: boolean
    adverts: boolean
    campaigns: boolean
    adSets: boolean
    scheduling: boolean
    fbCancel: boolean
  }
  error: string
  fbUserName: string
  fbUserId: string
  fbConnected: boolean
  adAccounts: SelectOption[] | undefined
  adverts: SelectOption[] | undefined
  campaigns: SelectOption[] | undefined
  adSets: SelectOption[] | undefined
  campaignData: any
  fbStatus: any
  isCampaignScheduled: boolean
  isStatusDataFetching: boolean
  fbBusinessId: string
}

interface CommonPayload {
  project_id: string
}

interface AdvertsPayload {
  project_id: string
  fb_ad_account_id: string | undefined
  fb_campaign_id: string | undefined
  fb_adset_id: string | undefined
}

interface CampaignsPayload {
  project_id: string
  fb_ad_account_id: string | undefined
}

interface AdSetsPayload {
  project_id: string
  fb_ad_account_id: string | undefined
  fb_campaign_id: string | undefined
}

interface SchedulePayload {
  campaignName: string
  campaign_id: string
  create_single_adset: boolean
  fb_end_date: string
  fb_start_date: string
  fb_tz: string
  is_ad_study: boolean
  project_id: string
  sls: any[]
  previousStep: number
  previous_step: number
  currentStep: number
  current_step: number
}

interface CreateCampaignPayload {
  campaign_action: string
  currentStep: number
  current_step: number
  enable_fb_integration: boolean
  fb_ad_account_id: string
  fb_ad_account_name: string
  fb_ad_id: string
  fb_ad_name: string
  fb_adset_id: string
  fb_adset_name: string
  fb_business_id: string
  fb_campaign_id: string
  fb_campaign_name: string
  id: string
  isNoAdBody: boolean
  isSaving: boolean
  name: string
  own_subject_line: string
  previousStep: number
  previous_step: number
  project_id: string
  type: string
}

export const initialState: FacebookState = {
  isLoading: {
    fbAuth: false,
    adAccounts: false,
    adverts: false,
    campaigns: false,
    adSets: false,
    scheduling: false,
    fbCancel: false,
  },
  error: '',
  fbUserName: '',
  fbUserId: '',
  fbConnected: false,
  adAccounts: undefined,
  adverts: undefined,
  campaigns: undefined,
  adSets: undefined,
  campaignData: undefined,
  fbStatus: undefined,
  isCampaignScheduled: false,
  isStatusDataFetching: false,
  fbBusinessId: '',
}

export const facebookSlice = createSlice({
  name: 'facebook',
  initialState,
  reducers: {
    updateFBLogin: (state, action) => {
      state.fbUserName = action.payload.fbUserName
      state.fbUserId = action.payload.fbUserId
      state.fbConnected = action.payload.fbConnected
    },
    updateFBData: (state, action) => {
      state.campaignData = action.payload
    },
    updateAdAccounts: (state, action) => {
      state.adAccounts = action.payload
    },
    updateCampaigns: (state, action) => {
      state.campaigns = action.payload
    },
    updateAdSets: (state, action) => {
      state.adSets = action.payload
    },
    updateAdverts: (state, action) => {
      state.adverts = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading[action.payload.field] = action.payload.value
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    updateFbCampaignStatus: (state, action) => {
      state.fbStatus = action.payload
    },
    updateScheduleStatus: (state, action) => {
      state.isCampaignScheduled = action.payload
    },
    updateStatusDataFetch: (state, action) => {
      state.isStatusDataFetching = action.payload
    },
    updateBusinessId: (state, action) => {
      state.fbBusinessId = action.payload
    },
    clearStatusState: (state) => {
      state.fbStatus = undefined
      state.isStatusDataFetching = false
      state.isCampaignScheduled = false
      state.campaignData = undefined
    },
  },
})

export const {
  setLoading,
  setError,
  updateFBLogin,
  updateAdAccounts,
  updateAdverts,
  updateAdSets,
  updateCampaigns,
  updateFBData,
  updateFbCampaignStatus,
  updateScheduleStatus,
  updateStatusDataFetch,
  clearStatusState,
  updateBusinessId,
} = facebookSlice.actions

export function onFacebookLoginClick(
  projectId: string,
  callback: (fbUserId: string, fbUserName: string, fbConnected: boolean) => void
) {
  ;(window as any).FB.login(
    (response: any) => {
      // Auth response is valid - unpack the user ID and access token
      const {
        authResponse: { userID, accessToken },
      } = response

      ;(window as any).FB.api(userID, () => {
        checkPermissions(userID, accessToken, projectId, callback)
      })
    },
    { scope: process.env.REACT_APP_FULL_FACEBOOK_PERMISSIONS }
  )
}

// eslint-disable-next-line max-params
function checkPermissions(
  userID: string,
  token: string,
  projectId: string,
  callback: (fbUserId: string, fbUserName: string, fbConnected: boolean) => void
) {
  // Check the permissions that the user has granted to the app
  ;(window as any).FB.api('/me/permissions', (permissionsResponse) => {
    checkPermissionsResponse(
      userID,
      token,
      permissionsResponse,
      projectId,
      callback
    )
  })
}

// eslint-disable-next-line max-params
function checkPermissionsResponse(
  userID: string,
  token: string,
  permissionsResponse: any,
  projectId: string,
  callback: (fbUserId: string, fbUserName: string, fbConnected: boolean) => void
) {
  if (
    permissionsResponse.data !== undefined &&
    Array.isArray(permissionsResponse.data)
  ) {
    // Add all the permissions that have been granted
    const permissionsGranted: any[] = []

    permissionsResponse.data.forEach((permission: any) => {
      if (permission.status === 'granted') {
        permissionsGranted.push(permission.permission)
      }
    })

    // Get the required permissions
    const permissionsArr: string[] | undefined =
      process?.env?.REACT_APP_MIN_FACEBOOK_PERMISSIONS?.split(',')

    let allPermissionsGranted = true

    // Check if all the required permissions have been granted.
    permissionsArr?.forEach((permission: string) => {
      if (!permissionsGranted.includes(permission)) {
        allPermissionsGranted = false
      }
    })

    // If all permissions granted, update the FB status.
    if (allPermissionsGranted) {
      updateStatus(userID, token, projectId, callback)
    }
  }
}

// eslint-disable-next-line max-params
function updateStatus(
  userID: string,
  token: string,
  projectId: string,
  callback: (fbUserId: string, fbUserName: string, fbConnected: boolean) => void
) {
  ;(window as any).FB.api(userID, (userDetails) => {
    updateFBStatus(token, userDetails.name, userID, projectId, callback)
  })
}

// eslint-disable-next-line max-params
function updateFBStatus(
  accessToken: string,
  fbUserName: string,
  fbUserId: string,
  projectId: string,
  callback: (fbUserId: string, fbUserName: string, fbConnected: boolean) => void
) {
  const requestBody = {
    fb_token: accessToken,
    project_id: projectId,
  }
  callback(fbUserId, fbUserName, true)
  return restApi.post<any>(`phacebook/fbtoken`, requestBody)
}

export function disconnectProject(data: CommonPayload) {
  return restApi.delete<any>(`phacebook/disconnect`, { data })
}

/**
 * Check if a valid facebook use access token
 * is already associated with the project
 *
 * @param {Object} data - Object containing the project_id and token
 */
export function checkFacebookTokenExists(data: CommonPayload) {
  return restApi.post<any>('phacebook/check', data)
}

export function getFacebookAdAccounts(data: CommonPayload) {
  return restApi.post<any>('phacebook/adaccounts', data)
}

/**
 * Called when the user selects an ad account
 * @param {String} projectId - The Id of the facebook project
 * @param {Object} adAccount - Object containing the selected
 * ad account id and name
 */

export function getFacebookCampaigns(data: CampaignsPayload) {
  return restApi.post<any>('phacebook/campaigns', data)
}

/**
 * Called when the user selects an ad campaign
 * @param {String} project_id - The Id of the facebook project
 * @param {String} fb_ad_account_id - The Id of the facebook ad account
 * @param {Object} fb_campaign_id - An object containing
 * the ad campaign id and name
 */

export function getFacebookAdSets(data: AdSetsPayload) {
  return restApi.post<any>('phacebook/adsets', data)
}

/**
 * Called when the user selects an Ad set
 * @param {String} projectId - The Id of the facebook project
 * @param {String} fb_ad_account_id  - The id of the facebook ad account
 * @param {String} fb_campaign_id - The Id of the facebook ad campaign
 * @param {Object} fb_adset_id - An Object containing
 * the facebook ad set id and name
 */

export function getFacebookAdverts(data: AdvertsPayload) {
  return restApi.post<any>('phacebook/ads', data)
}

export function schedule(data: SchedulePayload) {
  return restApi.post<any>('campaigns/schedule', data)
}

export function getCampaign(data: { campaign_id: string }) {
  return restApi.post<any>('campaigns/view', data)
}

export function getFBStatus(data: { campaign_id: string }) {
  return restApi.post<any>('campaigns/status', data)
}

export function cancelSetup(data: { campaign_id: string }) {
  return restApi.post<any>('phacebook/cancel', data)
}

export function stopCollectData(data: { campaign_id: string }) {
  return restApi.post<any>('phacebook/stop_collect_data', data)
}

export function createCampaign(data: CreateCampaignPayload) {
  return restApi.post<any>('campaigns/create-campaign', data)
}

export default facebookSlice.reducer
