import { useLayoutEffect, useMemo, useState } from 'react'

import AvatarList from 'common/components/AvatarList'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate } from 'common/helpers/formatDate'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'

import { PersonalizationInfo } from './interfaces'

type Props = {
  content: PersonalizationInfo[]
  isLoading: boolean
  hasError: boolean
  onClickItem: (contentId: string) => void
}

function calculateTitleWidth(screenWidth: number): number {
  if (screenWidth < 1024) {
    return 412
  } else if (screenWidth < 1920) {
    return 492
  } else {
    return 792
  }
}

const PersonalizationTable = ({
  content,
  hasError,
  isLoading,
  onClickItem,
}: Props) => {
  const { screenWidth } = useWindowDimensions()
  const [titleWidth, setTitleWidth] = useState<number>(
    calculateTitleWidth(screenWidth)
  )

  useLayoutEffect(() => {
    setTitleWidth(calculateTitleWidth(screenWidth))
  }, [screenWidth])

  const columns: Column<PersonalizationInfo>[] = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'name',
        width: titleWidth,
      },
      {
        Header: 'Type',
        accessor: (row: PersonalizationInfo) => ({
          contentType: row.contentType,
        }),
        width: 160,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <BaseCell>
              <ContentTypeBubble iconType={value.contentType} />
            </BaseCell>
          )
        },
        align: 'left',
      },

      {
        Header: 'Created',
        accessor: 'createdDate',
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        align: 'left',
        width: 160,
      },
      {
        Header: 'Last modified',
        accessor: (row: PersonalizationInfo) =>
          row.lastModified ? row.lastModified : row.createdDate,
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        align: 'right',
        width: 160,
      },
      {
        accessor: 'contributors',
        align: 'left',
        disableSortBy: true,
        width: 110,
        Cell: ({ value }) => (
          <BaseCell>
            <AvatarList userNames={value} />
          </BaseCell>
        ),
      },
    ],
    [titleWidth]
  )

  return (
    <div data-testid="personalization-personalizations-table">
      <TableWidget.Widget
        isLoading={isLoading}
        hasError={hasError}
        columns={columns}
        data={content}
        onRowClick={(content) => onClickItem(content.id)}
        firstUseText="Nothing to see here… There is no personalization to show."
      />
    </div>
  )
}

export default PersonalizationTable
