export const singleBest = {
  label: 'Fast, maximise revenue',
  value: 'single_best',
  tooltip:
    'Aims to find the winning line as quickly as possible. Uses a multi-armed bandit optimization approach. ',
} as const

export const highPerformers = {
  label: 'Fast, repeat sends to same audience',
  value: 'random_high_performers',
  tooltip:
    'Find the top X (usually 3) performing variants. Reduces repetition when sending to the same audience multiple times. Uses a multi-armed bandit optimization approach.',
} as const

export const confidence = {
  label: 'Slow, maximise statistical significance',
  value: 'confidence',
  tooltip:
    'Sends equal proportions to all variants. Requires “drop bad variants” to be switched on. Poor performing variants will be dropped based on statistical significance.',
} as const

export const opensToSends = {
  label: 'Opens to sends',
  value: 'opens-to-sends',
  tooltip: 'Optimize on open rate',
} as const
export const clicksToSends = {
  label: 'Clicks to sends',
  value: 'clicks-to-sends',
  tooltip: 'Optimize on click rate',
} as const
export const clicksToOpens = {
  label: 'Clicks to opens',
  value: 'clicks-to-opens',
  tooltip:
    'Optimize on click rate. Can be used when optimizing in-body text when also optimizing subject line',
} as const
export const phraseeScore = {
  label: 'Jacquard score',
  value: 'phrasee-score',
  tooltip:
    'Uses a combination of opens and clicks. Favours clicks once enough data has been obtained. ',
} as const

export const optimizationModeOptions: [
  typeof singleBest,
  typeof highPerformers,
  typeof confidence
] = [singleBest, highPerformers, confidence]

export const optimizationMetricOptions: [
  typeof opensToSends,
  typeof clicksToSends,
  typeof clicksToOpens,
  typeof phraseeScore
] = [opensToSends, clicksToSends, clicksToOpens, phraseeScore]

export const labelText = {
  frequency: 'How frequently will this message be sent?',
  salesforceMid: 'Salesforce MID',
  optimizationMode: 'Optimization mode',
  optimizationMetric: 'Optimization metric',
  dropVariants: 'Drop bad variants',
  dropHumanControl: 'Allow the human control to be automatically dropped',
  topPerformers: 'Top performers to select a variant from',
  proportionSendToWinner: 'Proportion to send to winner (%)',
  daysBeforeAddingLinesOld:
    'Once a winning variant is found, days to wait before introducing new variants',
  daysBeforeAddingLines: 'Time based',
  minSendsToHumanControl: 'Minimum sends to the human control',
  testAudienceSize: 'Minimum percentage of audience used for testing (1-20%)',
  beSmartWhenToIntroduceVariants: 'Be smart when to introduce new variants',
} as const

export const testId = {
  optimizationModeSelector: 'optimizationModeSelector',
  optimizationMetricSelector: 'optimizationMetricSelector',
  highPerformersSelector: 'highPerformersSelector',
  daysBeforeAddingLinesCheckbox: 'daysBeforeAddingLinesCheckbox',
  daysBeforeAddingLinesInput: 'daysBeforeAddingLinesInput',
  dropVariantsCheckbox: 'dropVariantsCheckbox',
  dropHumanControlCheckbox: 'dropHumanControlCheckbox',
  proportionSentToWinnerInput: 'proportionSentToWinnerInput',
  minSendsToHumanControlCheckbox: 'minSendsToHumanControlCheckbox',
  minSendsToHumanControlInput: 'minSendsToHumanControlInput',
  testAudienceSizeCheckbox: 'testAudienceSizeCheckbox',
  testAudienceSizeInput: 'testAudienceSizeInput',
  beSmartWhenToIntroduceVariantsSelector: 'beSmartWhenToIntroduceVariants',
} as const

export const actionButtonLabels = {
  startingTheAwesome: 'Starting the optimization',
  updateSettings: 'Update Settings',
  startTheAwesome: 'Start optimization',
  awesomeStarted: 'Optimization started',
  restartingExperiment: 'Restart experiment',
}

export const advancedOptionsToolTips = {
  dropVariants:
    'Poor performing variants will be dropped, receiving no further sends. The human control will not be dropped.',
  dropHumanControlCheckbox:
    'If the control is performing badly, allow it to be dropped. Uplift and other performance metrics will use the last known performance metrics prior to it being dropped. ',
  minSendsToHumanControl:
    'Manage the overall percentage of sends that the control receives.',
  daysBeforeAddingLines:
    'Control the length of time that the winning variant is used before new language is tested.',
}

export const DEFAULT_HIGH_PERFORMERS_NUMBER = 3
export const DEFAULT_DAYS_BEFORE_ADDING_LINES = 30
export const DEFAULT_PROPORTION_SENT_TO_WINNER_NUMBER = 80
export const DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_BROADCAST = 2
export const DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_TRIGGER = 5
export const DEFAULT_TEST_AUDIENCE_SIZE_BROADCAST = 0
export const DEFAULT_TEST_AUDIENCE_SIZE_TRIGGER = 20

// Constants for "Be smart when to introduce new variants"

export const optionAutomatic = {
  label: 'Automatic',
  value: 'automatic',
} as const

export const optionHeadToHeadAndWait = {
  label: 'Time based',
  value: 'h2h_and_wait',
} as const

export const optionNoNewLanguage = {
  label: 'Do not introduce new language',
  value: 'no_new_language',
} as const

export const optionAllAvailable = {
  label: 'Continuous testing',
  value: 'use_all_available',
} as const

export const beSmartWhenToIntroduceVariantsOptions = [
  optionAutomatic,
  optionHeadToHeadAndWait,
  optionNoNewLanguage,
  optionAllAvailable,
]
