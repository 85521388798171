import Tags, { Option } from 'common/components/tags/Tags'

type Props = {
  'data-cy'?: string
  title: string
  subtitle: string
  data: Option<string>[]
}

const MetricsItem = ({ 'data-cy': dataCy, title, subtitle, data }: Props) => {
  return (
    <div data-cy="metrics-item">
      <p
        data-cy="metrics-item-title"
        className="mb-2 text-4 text-coolGray-800 font-medium"
      >
        {title}
      </p>
      <p
        data-cy="metrics-item-subtitle"
        className="mb-4 text-3 text-coolGray-400"
      >
        {subtitle}
      </p>
      <Tags data-cy={dataCy} isFreeText={false} tags={data} />
    </div>
  )
}

export default MetricsItem
