import { useState } from 'react'

import CheckboxComponent from 'common/components/checkbox'
import ConfirmationModal from 'common/components/confirmationModal'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  clickAddEndPunctuation,
  clickAddLeadPunctuation,
  clickAddMiddlePunctuation,
  clickCapitablizeAfterColon,
  clickCapitablizeAfterDash,
  clickCapitablizeAfterEllipsis,
  clickCurlyApostrophes,
  clickIncludeEndPunctuation,
  clickRemoveEndPunctuation,
  clickRemoveLeadPunctuation,
  clickRemoveMiddlePunctuation,
  clickSpaceAfterEllipsis,
  clickSpaceBeforeEllipsis,
} from '../../store/contentSettingsSlice'

import PunctuationTag from './PunctuationTag'

const PunctuationWidget = () => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)

  const dispatch = useAppDispatch()
  const punctuation = useAppSelector(
    (state) => state.contentSettings.editedContent.punctuation
  )

  const handleDeleteCancel = () => {
    setIsDeleteModalShown(false)
  }

  const handleDeleteConfirm = () => {}

  const LEAD_PUNCTUATION_SUGGESTIONS = ['-', '–', '—', '!', '|', '…', ':', '.']
  const MID_PUNCTUATION_SUGGESTIONS = ['-', '–', '—', '!', '|', '…', '.']
  const END_PUNCTUATION_SUGGESTIONS = ['!', '|', '…', '.']

  return (
    <>
      <Widget data-cy="punctuation-widget">
        <WidgetHeader
          title="Punctuation"
          subtitle="Some short guideance text can go here..."
        />
        <div style={{ maxWidth: 602 }} className="flex flex-col gap-8">
          <PunctuationTag
            data-cy="lead-punctuation"
            title="Lead punctuation"
            tags={punctuation.leadPunctuations.map((value) => ({
              value,
              label: value,
            }))}
            suggestions={LEAD_PUNCTUATION_SUGGESTIONS}
            onAddClick={(value: string) => {
              dispatch(clickAddLeadPunctuation(value))
            }}
            onRemoveClick={(value: string) => {
              dispatch(clickRemoveLeadPunctuation(value))
            }}
          />
          <PunctuationTag
            title="Middle punctuation"
            data-cy="middle-punctuation"
            tags={punctuation.middlePunctuations.map((value) => ({
              value,
              label: value,
            }))}
            suggestions={MID_PUNCTUATION_SUGGESTIONS}
            onAddClick={(value: string) => {
              dispatch(clickAddMiddlePunctuation(value))
            }}
            onRemoveClick={(value: string) => {
              dispatch(clickRemoveMiddlePunctuation(value))
            }}
          />
          <PunctuationTag
            title="End punctuation"
            data-cy="end-punctuation"
            tags={punctuation.endPunctuations.map((value) => ({
              value,
              label: value,
            }))}
            suggestions={END_PUNCTUATION_SUGGESTIONS}
            onAddClick={(value: string) => {
              dispatch(clickAddEndPunctuation(value))
            }}
            onRemoveClick={(value: string) => {
              dispatch(clickRemoveEndPunctuation(value))
            }}
          />
        </div>
        <div className="mt-8">
          <WidgetHeader
            title="Rules"
            subtitle="Some short guideance text can go here..."
          />
          <div className="grid grid-cols-3 gap-y-6" style={{ maxWidth: 646 }}>
            <CheckboxComponent
              className="col-span-3"
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label="Curly apostrophes"
              isChecked={punctuation.hasCurlyApostrophes}
              onChange={(isChecked: boolean) => {
                dispatch(clickCurlyApostrophes(isChecked))
              }}
            />
            <CheckboxComponent
              className="col-span-3"
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label="Include end punctuation if variant contains “!”, “?”, “…” or “.”"
              isChecked={punctuation.isIncludingEndPunctuation}
              onChange={(isChecked: boolean) => {
                dispatch(clickIncludeEndPunctuation(isChecked))
              }}
            />
            <CheckboxComponent
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label="Space before “…”"
              isChecked={punctuation.hasSpaceBeforeEllipsis}
              onChange={(isChecked: boolean) => {
                dispatch(clickSpaceBeforeEllipsis(isChecked))
              }}
            />
            <CheckboxComponent
              className="col-span-2"
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label="Space after “…”"
              isChecked={punctuation.hasSpaceAfterEllipsis}
              onChange={(isChecked: boolean) => {
                dispatch(clickSpaceAfterEllipsis(isChecked))
              }}
            />
            <CheckboxComponent
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label="Capitalize after “:”"
              isChecked={punctuation.isCapitalizingAfterColon}
              onChange={(isChecked: boolean) => {
                dispatch(clickCapitablizeAfterColon(isChecked))
              }}
            />
            <CheckboxComponent
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label="Capitalize after “-”"
              isChecked={punctuation.isCapitalizingAfterDash}
              onChange={(isChecked: boolean) => {
                dispatch(clickCapitablizeAfterDash(isChecked))
              }}
            />
            <CheckboxComponent
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label="Capitalize after “…”"
              isChecked={punctuation.isCapitalizingAfterEllipsis}
              onChange={(isChecked: boolean) => {
                dispatch(clickCapitablizeAfterEllipsis(isChecked))
              }}
            />
          </div>
        </div>
      </Widget>
      <ConfirmationModal
        open={isDeleteModalShown}
        title="Remove incompatible words row!"
        data-cy="incompatible-words-row-delete-modal"
        confirmationText="Are you sure you want to remove this row?"
        onCancel={() => handleDeleteCancel()}
        onConfirm={() => handleDeleteConfirm()}
      />
    </>
  )
}

export default PunctuationWidget
