import { UserType } from 'features/auth/store/authSlice'

export const getIsUserTypeAuthorized = (
  userType: UserType | '',
  accessLevel?: UserType
): boolean => {
  if (userType === 'superhero') {
    return true
  } else if (userType === 'hero' && accessLevel === 'hero') {
    return true
  } else {
    return false
  }
}
