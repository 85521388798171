import cx from 'classnames'

import LinearLoader from './linearLoader'

interface Props {
  'data-cy'?: string
  size?: 'normal' | 'small'
  className?: string
}

const WidgetLoader: React.FC<Props> = ({
  'data-cy': dataCy,
  size = 'normal',
  className,
}) => {
  return (
    <div
      className={cx(
        'bg-coolGray-50 flex flex-col justify-center items-center border border-coolGray-100',
        {
          'mb-6 px-12 py-14': size === 'normal',
          'px-6 py-5': size === 'small',
        },
        className
      )}
      data-cy={dataCy}
      data-testid="loader"
    >
      <LinearLoader />
      <p
        className={cx('text-coolGray-500', {
          'mt-8': size === 'normal',
          'mt-4': size === 'small',
        })}
      >
        Waking up the server in the far corner…
      </p>
    </div>
  )
}

export default WidgetLoader
