import { useMemo, useState } from 'react'
import cx from 'classnames'
import { sortBy } from 'lodash'

import ActionIcon from 'common/components/ActionIcon'
import Autocomplete from 'common/components/autcomplete'
import Button from 'common/components/button'
import Footer from 'common/components/layout/Footer'
import Overlay from 'common/components/Overlay'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Scrollable from 'common/components/scrollable'
import Spinner from 'common/components/spinner'
import Tooltip from 'common/components/Tooltip'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import { useLocalStorage } from 'common/hooks/useLocalStorage'
import { Add as AddIcon, Grid, ListIcon } from 'common/icons'

import CreateWidget from '../components/CreateWidget'
import { BrandVoice } from '../store/brandVoiceSlice'

import LibraryGrid from './toneOfVoice/components/LibraryGrid'
import LibraryTable from './toneOfVoice/components/LibraryTable'

const BRAND_VOICE_VIEW_KEY = `brand-voice-view`

const Library = () => {
  useDocumentTitle('Brand Voice Library | Jacquard')

  const [isTableView, setIsTableView] = useLocalStorage(
    BRAND_VOICE_VIEW_KEY,
    false
  )

  const [searchValue, setSearchValue] = useState<string>('')
  const [clonedBrandVoice, setClonedBrandVoice] =
    useState<BrandVoice | undefined>()
  const [isCreateWidgetVisible, setIsCreateWidgetVisible] =
    useState<boolean>(false)

  const {
    isLoading,
    editedContent: { brandVoices },
  } = useAppSelector((state) => state.brandVoice)

  const contentFiltered = useMemo(() => {
    const filterByName = ({ name }: BrandVoice) =>
      name
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchValue.replaceAll(' ', '').toLowerCase())

    return sortBy(brandVoices.filter(filterByName), ['name'])
  }, [brandVoices, searchValue])

  const onDuplicate = (brandVoice: BrandVoice) => {
    setIsCreateWidgetVisible(true)
    setClonedBrandVoice(brandVoice)
  }

  return (
    <>
      <Spinner isSpinning={isLoading}>
        <Scrollable
          isFullHeight={true}
          content={
            <PageContainer className="max-w-318 sm:mx-auto px-6 pt-9">
              <PageHeader>
                <div
                  data-cy="header"
                  className="flex justify-between items-center w-full "
                >
                  <PageTitle title="Brand Voice Library" />

                  <div className="flex items-center">
                    <ActionIcon
                      aria-label="Toggle view button"
                      onClick={() => setIsTableView(!isTableView)}
                      className="mr-4"
                    >
                      {isTableView ? (
                        <Tooltip overlay="Tile view">
                          <Grid />
                        </Tooltip>
                      ) : (
                        <Tooltip overlay="List view">
                          <ListIcon />
                        </Tooltip>
                      )}
                    </ActionIcon>
                    <Button
                      data-cy="add-brand-voice-button"
                      data-testid="add-brand-voice-button"
                      aria-label="Brand Voice"
                      variant="primary"
                      onClick={() => {
                        setIsCreateWidgetVisible(true)
                      }}
                      className="text-base mr-4"
                      prefixIcon={<AddIcon isDefaultColor={false} />}
                    >
                      Brand Voice
                    </Button>
                    <Autocomplete
                      data-cy="search-tone"
                      data-testid="search-tone"
                      className="w-60"
                      placeholder="Search"
                      options={brandVoices.map((brandVoice) => ({
                        label: brandVoice.name,
                        value: brandVoice.id,
                      }))}
                      onSearch={(search) => setSearchValue(search)}
                      backspaceRemovesValue={true}
                    />
                  </div>
                </div>
              </PageHeader>
              <div className="mt-6">
                {isTableView ? (
                  <LibraryTable
                    brandVoices={contentFiltered}
                    onDuplicate={onDuplicate}
                  />
                ) : (
                  <LibraryGrid
                    brandVoices={contentFiltered}
                    onDuplicate={onDuplicate}
                  />
                )}
              </div>
              <Footer />
            </PageContainer>
          }
        />
      </Spinner>
      <Overlay
        className={cx({
          hidden: !isCreateWidgetVisible,
        })}
      >
        {isCreateWidgetVisible && (
          <CreateWidget
            clonedBrandVoice={clonedBrandVoice}
            onClose={() => setIsCreateWidgetVisible(false)}
          />
        )}
      </Overlay>
    </>
  )
}

export default Library
