import {
  Account,
  LanguageSettings,
} from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'

export type StyleGuideResponse = LanguageSettings

export type Industry = {
  _id: string
  name: string
  created: Date
  last_updated: Date
}

export const getAccountConfig = async (
  accountId: string
): Promise<{ region_id: string }> => {
  const result = await connectInternalApi.get<{
    data: {
      region_id: string
      brand_voices:
        | {
            name: string
            region_id: string
            language_setttings: LanguageSettings
            created: string
            default: boolean
            last_updated: string
            brand_tones: {
              description: string
              examples: string[]
              instruction: string
              name: string
              _id: string
            }
          }[]
        | null
    }
  }>(`v1/core/monorail/accounts/${accountId}`)
  return result.data.data
}

export const updateAccount = async ({
  accountId,
  data,
}: {
  accountId: string
  data: { region_id: string; language_settings?: LanguageSettings }
}): Promise<{ region_id: string }> => {
  const result = await connectInternalApi.patch<{ region_id: string }>(
    `v1/core/monorail/accounts/${accountId}`,
    data
  )
  return result.data
}

export const getAccountLanguageSettings = async (
  accountId: string,
  brandVoiceId: string = ''
): Promise<StyleGuideResponse> => {
  let url = `v1/core/monorail/accounts/${accountId}/combined_language_settings?return_inheritance=true`
  if (brandVoiceId) {
    url += `&brand_voice_id=${brandVoiceId}`
  }
  const result = await connectInternalApi.get<{ data: StyleGuideResponse }>(url)
  return result.data.data
}

export const updateStyleGuide = async ({
  accountId,
  data,
}: {
  accountId: string
  data: StyleGuideResponse
}) => {
  const result = await connectInternalApi.patch<StyleGuideResponse>(
    `v1/core/monorail/accounts/${accountId}/brand-voice-settings`,
    { language_settings: data }
  )

  return result.data
}

export type AccountResponse = Account

export const updateBrandVoice = async ({
  accountId,
  brandVoiceId,
  data,
}: {
  accountId: string
  brandVoiceId: string
  data: {
    name?: string
    languageSettings?: StyleGuideResponse
    regionId?: string
    isDefault?: boolean
  }
}) => {
  const result = await connectInternalApi.patch<{ data: Account }>(
    `v1/core/monorail/accounts/${accountId}/brand-voice/${brandVoiceId}`,
    {
      language_settings: data.languageSettings,
      region_id: data.regionId,
      name: data.name,
      default: data.isDefault,
    }
  )

  return result.data.data
}

export const getAllIndustries = async (): Promise<Industry[]> => {
  const result = await connectInternalApi.get<{ data: Industry[] }>(
    'v1/core/monorail/industries'
  )
  return result.data.data
}
