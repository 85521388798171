import useProjectsQuery from 'features/campaigns/queries/useProjectsQuery'

const useProjectOptions = ({ optimizationContentType }) => {
  const { data: projects } = useProjectsQuery()

  const projectOptions = projects
    ?.filter(
      (project) =>
        project.project_configuration?.campaign_configurations?.testing_method
          ?.tested_content_sections?.[0]?.section === optimizationContentType &&
        project.deleted !== true
    )
    ?.map((project) => ({
      label: project.name,
      value: project._id,
    }))
    .sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    })

  return projectOptions
}

export default useProjectOptions
