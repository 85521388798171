/* eslint-disable max-len */
import * as types from '../../../redux/actionTypes'
import apiUtil from '../../../workflow/utils/Api'
import { sfmcLoginFail, sfmcLoginSuccess } from '../store/authSlice'

const getUserDetails = (tssd, code, client) => {
  return (dispatch) => {
    const params = {
      code,
      tssd,
      client,
    }
    const apiPath = `auth/sfmc-login/?tssd=${tssd}&code=${code}&client=${client}`
    return apiUtil(apiPath, { method: 'GET', query: params })
      .then((response) => {
        dispatch(sfmcLoginSuccess(response.data))
        dispatch({
          type: types.SFMC_LOGIN_SUCCESS,
          isLoading: false,
          response: response.data,
          tssd,
          code,
        })
      })
      .catch(() => {
        dispatch(sfmcLoginFail())
      })
  }
}
export default getUserDetails
