import { FC } from 'react'
import cx from 'classnames'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'

import FormError from './formError'
import Label from './Label'

interface Props {
  label?: string
  htmlFor?: string
  isOptional?: boolean
  error?: string
  guidingText?: string
  className?: string
  style?: React.CSSProperties
  orientation?: 'vertical' | 'horizontal'
}

const FormItem: FC<Props> = ({
  label,
  htmlFor,
  isOptional,
  error,
  guidingText,
  className,
  style,
  orientation = 'vertical',
  children,
}) => {
  return (
    <div
      className={cx(
        'flex-1',
        {
          'last:mb-0 ': orientation === 'vertical',
          'mb-6': !style && orientation === 'vertical',
        },
        className
      )}
      style={style}
    >
      {label && (
        <Label
          optional={isOptional}
          htmlFor={htmlFor}
          status={error === undefined || error === '' ? 'default' : 'error'}
        >
          {label}
        </Label>
      )}
      {children}
      {error && <FormError>{error}</FormError>}
      {guidingText && <GuidingText text={guidingText} />}
    </div>
  )
}

export default FormItem
