import { escapeRegExp } from 'lodash'

type Props = {
  text: string
  searchValue?: string
}

const HighlightText = ({ text, searchValue }: Props) => {
  const value = escapeRegExp(searchValue?.trim())

  const regex = new RegExp(`(${value})`, 'gi')
  const parts = text.split(regex)

  return (
    <div className="whitespace-pre-wrap">
      {parts.map((part, index) => {
        if (part.match(regex)) {
          return (
            <span key={index} className="text-maroon-600">
              {part}
            </span>
          )
        }
        return part
      })}
    </div>
  )
}

export default HighlightText
