import Alert from 'common/components/alert'
import ConfirmationCard from 'common/components/ConfirmationCard'
import { useAppDispatch } from 'common/hooks/redux'

import DeleteVariantsCard from '../components/card/DeleteVariantsCard'

import { deleteOffers, setError, setLoading } from './store/adobeTargetSlice'
import { initialState } from './AdobeTargetIntegration'

export default function DeleteOffersCard({
  isLoading,
  projectId,
  campaignId,
  setState,
  currentState,
  showUnlinkConfirmation,
  isActivityScheduled,
}) {
  const dispatch = useAppDispatch()

  return (
    <>
      <Alert type="success" className="flex items-center mt-6">
        Create and send offers to Adobe Target.
      </Alert>
      {showUnlinkConfirmation ? (
        <div className="mt-4 mb-4">
          <ConfirmationCard
            isDisabled={false}
            cardText={`Are you sure want to delete variations from 
            Adobe and unlink this Jacquard experiment?`}
            cardTitle="Delete & Unlink?"
            buttonText="Delete, yes please"
            rejectButtonText="Nooo!"
            isLoading={isLoading.offers}
            onSubmit={() => {
              dispatch(setLoading({ field: 'offers', value: true }))
              deleteOffers(projectId, campaignId)
                .then(() => {
                  setState({ ...initialState, campaignUnlinked: true })
                  dispatch(setLoading({ field: 'offers', value: false }))
                })
                .catch((err) => {
                  dispatch(setLoading({ field: 'offers', value: false }))
                  dispatch(setError(err.message))
                })
            }}
            onReject={() =>
              setState({ ...currentState, showUnlinkConfirmation: false })
            }
          />
        </div>
      ) : (
        <DeleteVariantsCard
          isDisabled={isActivityScheduled}
          cardText={`Delete variations from Adobe Target Offer library
            and unlink this Jacquard experiment.
            We will not delete the variations in Jacquard.`}
          buttonText="Delete offers"
          onSubmit={() =>
            setState({ ...currentState, showUnlinkConfirmation: true })
          }
        />
      )}
    </>
  )
}
