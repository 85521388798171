import { ChangeEvent } from 'react'
import cx from 'classnames'

import Input from 'common/components/Input'

const CustomInput = ({
  onChange,
  isDisabled = false,
  hasError,
  ...rest
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  id?: string
  isDisabled?: boolean
  value: string
  name: string
  placeholder: string
  hasError: boolean
  isValid?: boolean
}) => (
  <Input
    {...rest}
    onChange={onChange}
    type="text"
    disabled={isDisabled}
    className={cx(
      'bg-coolGray-50 h-10 border pl-4 focus:outline-none focus-visible:outline-black font-medium disabled:bg-coolGray-100 w-full shadow-md',
      {
        [`border-coolGray-300`]: !hasError,
        [`focus:text-red-400 focus:placeholder-red-500
            border-red-400 text-red-400`]: hasError,
      }
    )}
    hasError={hasError}
  />
)

export default CustomInput
