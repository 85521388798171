import {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
} from 'react'
import cx from 'classnames'

type PropsAddedByRcTooltip = Pick<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onClick'
> // needed for Tooltip: https://github.com/react-component/tooltip#important-note

type Props = {
  children: ReactNode
  className?: string
  style?: CSSProperties
} & PropsAddedByRcTooltip

const Base = ({
  children,
  className,
  style,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onClick,
}: Props) => {
  return (
    <div
      className={cx('flex items-center px-4 child-cell', className)}
      // props added by rc-tooltip
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default Base
