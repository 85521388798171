import { Campaign } from '@phrasee/phrasee-typings/Graphql/interfaces'

export const shouldShowPhraseeXIntegrationPanel = (campaignData: Campaign) => {
  if (!campaignData?.campaign_configuration) {
    return false
  }
  const { distribution_type, distribution_channel, testing_method } =
    campaignData?.campaign_configuration
  return (
    (distribution_type === 'AlwaysOn' || distribution_type === 'broadcast') &&
    distribution_channel === 'email' &&
    testing_method?.type === 'dynamic'
  )
}
