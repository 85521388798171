import { FC, forwardRef, ReactNode } from 'react'
import cx from 'classnames'

import { ReactComponent as TickDefault } from 'common/icons/smallTick/default.svg'
import { ReactComponent as SpinnerDefault } from 'common/icons/spinner/default.svg'

import {
  colorPerVariant,
  disabledClassnamesPerVariant,
  fontSizePerSize,
  heightPerSize,
  paddingPerSize,
  xlButtonDisabled,
  xlButtonEnabled,
} from './Button.styles'

export type Size = 'xl' | 'large' | 'medium' | 'small'
export type Variant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'icon'
  | 'link'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'data-cy'?: string
  'data-testid'?: string
  'aria-label'?: string
  variant?: Variant
  ghost?: boolean
  loading?: boolean
  prefixIcon?: ReactNode
  suffixIcon?: ReactNode
  size?: Size
}

const IconWrapper: FC<{ hasMargin?: boolean; isSuffix?: boolean }> = ({
  hasMargin = true,
  isSuffix = false,
  children,
}) => (
  <span
    className={cx(
      'min-w-max flex',
      { 'mr-2': hasMargin },
      { 'ml-2': isSuffix }
    )}
  >
    {children}
  </span>
)

// forwardRef is needed when Button is used for menuButton prop on MenuButton component
const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    className,
    variant,
    ghost,
    loading,
    'data-cy': dataCy,
    'data-testid': dataTestId,
    disabled,
    prefixIcon,
    suffixIcon,
    type = 'button',
    size = 'large',
    children,
    ...otherProps
  },
  ref
) {
  const hasPadding = variant !== 'icon' && variant !== 'link'
  const hasChildren = !!children

  type VariantCssClasses = {
    background: string
    backgroundGhost: string
    text: string
    border: string
    outline: string
  }

  const color: VariantCssClasses | undefined = variant
    ? colorPerVariant[variant]
    : undefined

  const commonClassnames = cx(
    'inline-flex rounded-full justify-center items-center focus:outline-none whitespace-nowrap font-medium',
    fontSizePerSize[size],
    {
      [paddingPerSize[size]]: hasPadding,
    }
  )
  const xlButtonStyles = disabled ? xlButtonDisabled : xlButtonEnabled

  const normalButtonStyles = disabled
    ? cx(
        commonClassnames,
        variant && disabledClassnamesPerVariant[variant],
        'cursor-not-allowed',
        {
          'text-coolGray-400 bg-coolGray-100': !variant && ghost,
        }
      )
    : cx(commonClassnames, {
        [color?.outline ?? colorPerVariant.primary.outline]: variant !== 'link',
        [cx(color?.background, 'text-black')]:
          variant && !ghost && variant !== 'icon',
        [cx(color?.backgroundGhost, color?.text, color?.border)]:
          (variant && ghost) || variant === 'icon',
        'text-white': variant && !ghost && variant === 'danger',
        'border-coolGray-300 text-coolGray-600 bg-white hover:bg-coolGray-50':
          !variant && ghost,
        'border border-solid': ghost && variant !== 'link',
        'focus-visible:outline-none': variant === 'link', // temporary until Ross come up with a better solution
        'text-maroon-500 hover:text-maroon-300': variant === 'link',
      })

  const buttonClassnames = size === 'xl' ? xlButtonStyles : normalButtonStyles

  return (
    <button
      ref={ref}
      data-cy={dataCy}
      data-testid={dataTestId}
      className={cx(buttonClassnames, className)}
      style={{ height: heightPerSize[size] }}
      disabled={disabled}
      type={type}
      {...otherProps}
    >
      {size === 'xl' && (loading || prefixIcon) ? (
        <>
          <span className="inline-block text-coolGray-400">
            {loading ? (
              <SpinnerDefault
                data-testid="spinner"
                width={26}
                height={26}
                className="animate-spin"
              />
            ) : (
              prefixIcon
            )}
          </span>

          {children}
        </>
      ) : (
        <>
          {!loading && prefixIcon && variant !== 'success' ? (
            <IconWrapper hasMargin={hasChildren}>{prefixIcon}</IconWrapper>
          ) : undefined}
          {!loading && variant === 'success' && !ghost ? (
            <IconWrapper hasMargin={hasChildren}>
              <TickDefault width={24} height={24} />
            </IconWrapper>
          ) : undefined}
          {loading ? (
            <SpinnerDefault
              data-testid="spinner"
              width={22}
              height={22}
              className="mr-4 animate-spin"
            />
          ) : undefined}
          {children}
          {!loading && suffixIcon && (
            <IconWrapper hasMargin={false} isSuffix={true}>
              {suffixIcon}
            </IconWrapper>
          )}
        </>
      )}
    </button>
  )
})

export default Button
