import GuidanceWidget from 'common/components/GuidanceWidget'

type Props = {
  className?: string
}

const StylisticsMessage = ({ className }: Props) => {
  return (
    <div className={className}>
      <GuidanceWidget title="Guidance">
        Stylistic elements, like non-standard punctuation or capitalized words,
        can be used in short-form copy like subject lines and push notifications
        to grab your audience’s attention.
        <div className="mt-4">
          Need a bit more guidance? Check out our support articles below:
        </div>
        <div className="mt-4">
          <a
            href="https://support.phrasee.co/en/articles/8140365-configuring-your-style-guide"
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            Support Article
          </a>
        </div>
      </GuidanceWidget>
    </div>
  )
}

export default StylisticsMessage
