import { useQuery } from '@tanstack/react-query'

import SummaryWidget from 'common/components/SummaryWidget/SummaryWidget'
import { useAppSelector } from 'common/hooks/redux'

import { getSummary } from '../api'

interface Props {
  selectedProjectIds: string[]
}

const SummaryWidgetContainer = ({ selectedProjectIds }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { isLoading, error, data } = useQuery(
    ['summary', accountId, selectedProjectIds],
    () =>
      getSummary({
        accountId,
        projectIds: selectedProjectIds,
      }).then((res) => res.data)
  )

  const items = data
    ? [
        { label: 'Admin', value: data.adminCount },
        { label: 'Users', value: data.userCount },
        { label: 'Viewers', value: data.viewerCount },
        { label: 'Approvers', value: data.approverCount },
        { label: 'Active', value: data.activeCount },
      ]
    : []

  return (
    <SummaryWidget
      className="mt-0 mb-0"
      items={items}
      isLoading={isLoading}
      hasError={!!error}
    />
  )
}

export default SummaryWidgetContainer
