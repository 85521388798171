export const isAcoustic = (integration: string) => {
  return (
    integration === 'ibm' ||
    integration === 'ibm watson' ||
    integration === 'acoustic'
  )
}

export const isSFMC = (integration: string) =>
  integration === 'sfmcv2' || integration === 'salesforce'

export const isDisabledPhraseeXRealtimeESP = (integration: string) =>
  integration === 'braze' ||
  integration === 'responsys' ||
  isSFMC(integration) ||
  integration === 'adobe_campaign_classic'
