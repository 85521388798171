import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import { bindActionCreators } from 'redux'

import Button from 'common/components/button/Button'
import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import Tooltip from 'common/components/Tooltip'
import { stringToNumber } from 'common/helpers/numeric'

import {
  fetchMappMessages,
  updateMappMessageId,
} from '../../../Workflow.actions'

const FormItem = Form.Item

const TOOLTIP_TEXT = {
  draft: `Jacquard has matched the following draft messages based on their 
  External ID`,
  matchSelect: `Jacquard will use this as the base template for creating the 
  remaining messages. Please ensure all settings are set correctly before 
  creating the connection.`,
  buttonDefault: `This will create the Jacquard messages in Mapp. Clicking it 
  multiple times will duplicate the messages.`,
}

interface Props {
  actions: any
  projectId: string
  campaignId: string
  draftMessages: any
  isLoadingMessages: boolean
  isUpdatingMessages: boolean
  subjectLines: any[]
}

interface State {
  selectedMessage: number | undefined
  shouldMakePostUpdate: boolean
}

export const initialState: State = {
  selectedMessage: undefined,
  shouldMakePostUpdate: false,
}

class MappIntegration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const { actions, projectId } = this.props
    actions.fetchMappMessages(projectId, false)
  }

  componentWillUnmount() {
    this.setState({
      selectedMessage: undefined,
    })
  }

  pushToMapp = () => {
    const { selectedMessage } = this.state
    const { projectId, campaignId, actions, subjectLines } = this.props
    subjectLines.forEach((variant: any, index: number) => {
      // if the current variant is human control, we use patch endpoint
      // otherwise post
      const usePatch = variant.ownsl
      actions.updateMappMessageId(
        projectId,
        selectedMessage,
        campaignId,
        variant._id.toString(),
        usePatch
      )
      if (subjectLines.length === index + 1) {
        actions.fetchMappMessages(projectId, true)
      }
    })
  }

  onChangeUpdateState = (val?: number) => {
    this.setState({
      selectedMessage: val,
    })
  }

  returnFiltered = (input: any, option: any) => {
    return (
      option?.props?.children?.props?.title
        ?.toLowerCase()
        .indexOf(input?.toLowerCase()) >= 0
    )
  }

  render() {
    const { selectedMessage } = this.state
    const { draftMessages, isLoadingMessages, isUpdatingMessages } = this.props
    return (
      <>
        <Drawer.Content>
          <Form>
            <Spinner isSpinning={isLoadingMessages}>
              <FormItem className="required" label="Select a draft message:">
                <Tooltip
                  overlay={TOOLTIP_TEXT.matchSelect}
                  trigger="focus"
                  placement="right"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <SingleSelect
                    data-testid="SelectMatch"
                    placeholder="Select a draft message"
                    className="required"
                    isSearchable
                    onChange={(val) =>
                      val?.value &&
                      this.onChangeUpdateState(stringToNumber(val?.value))
                    }
                    value={
                      (draftMessages &&
                        draftMessages.filter(
                          (draftMessage) => draftMessage.id === selectedMessage
                        )[0]?.messageName) ||
                      'Select a template'
                    }
                    options={draftMessages?.map((message) => ({
                      label: message.messageName,
                      value: message.id,
                    }))}
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                  />
                </Tooltip>
              </FormItem>
            </Spinner>
          </Form>
        </Drawer.Content>
        <Drawer.Footer>
          <Tooltip
            overlay={TOOLTIP_TEXT.buttonDefault}
            trigger="hover"
            placement="right"
            overlayStyle={{ maxWidth: 226 }}
          >
            <Button
              data-cy="mapp-push-button"
              variant="primary"
              disabled={
                isLoadingMessages || !selectedMessage || isUpdatingMessages
              }
              loading={isUpdatingMessages}
              onClick={() => this.pushToMapp()}
            >
              Push to Mapp Engage
            </Button>
          </Tooltip>
        </Drawer.Footer>
      </>
    )
  }
}

export function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(
      { fetchMappMessages, updateMappMessageId },
      dispatch
    ),
  }
}

export function mapStateToProps(state: any) {
  const {
    currentCampaignId,
    projectId,
    draftMessages,
    isWaitingState,
    subjectLines,
  } = state.campaignStates
  return {
    campaignId: currentCampaignId,
    projectId,
    draftMessages,
    subjectLines,
    isLoadingMessages:
      isWaitingState.isWaitingFor === 'FETCH_MAPP_MESSAGES' &&
      isWaitingState.isWaiting,
    isUpdatingMessages:
      isWaitingState.isWaitingFor === 'UPDATE_MAPP_MESSAGES' &&
      isWaitingState.isWaiting,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MappIntegration)
