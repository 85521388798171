import SingleSelect from 'common/components/singleSelect'

import RetrieveResultsCard from './RetrieveResultsCard'
import { AirshipExperiment } from './types'

interface AirshipDrawerContentProps {
  isExperimentsLoading: boolean
  experiments: AirshipExperiment[] | undefined
  selectedExperiment: string | undefined
  retrieveResults: () => void
  updateSelectedExperiment: (value: string) => void
  isRetrieveResultsButtonLoading: boolean
  isExperimentSelectDisabled: boolean
}

export default function DrawerContent({
  isExperimentsLoading,
  experiments,
  selectedExperiment,
  updateSelectedExperiment,
  retrieveResults,
  isRetrieveResultsButtonLoading,
  isExperimentSelectDisabled,
}: AirshipDrawerContentProps) {
  const generateExperimentSelectOptions = (experiments) => {
    return experiments.map((experiment) => ({
      value: experiment.id,
      label: experiment.name,
    }))
  }

  return (
    <>
      <SingleSelect
        isLoading={isExperimentsLoading}
        options={
          experiments && experiments.length > 0
            ? generateExperimentSelectOptions(experiments)
            : []
        }
        onChange={(val) => {
          if (val?.value) {
            updateSelectedExperiment(val.value)
          }
        }}
        label="Select experiment"
        menuPortalTarget={document.body}
        placeholder="Select"
        value={selectedExperiment}
        isDisabled={isExperimentSelectDisabled}
        isSearchable
      />
      <div className="mt-2 mb-6 text-coolGray-400">
        This is an experiment previously set up in Airship.
      </div>
      <RetrieveResultsCard
        retrieveResults={retrieveResults}
        isLoading={isRetrieveResultsButtonLoading}
      />
    </>
  )
}
