import {
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ActionIcon from 'common/components/ActionIcon'
import AvatarList from 'common/components/AvatarList'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import OverlappingItems from 'common/components/overlappingItems/OverlappingItems'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate } from 'common/helpers/formatDate'
import { useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { Bin, Copy, Favorite } from 'common/icons'

import useCopyContentMutation from '../api/mutations/useCopyContentMutation'

import { ContentInfo } from './interfaces'

import styles from 'common/components/table/Table.module.css'

type Props = {
  content: ContentInfo[]
  isLoading: boolean
  hasError: boolean
  onDelete: Dispatch<SetStateAction<string>>
}

function calculateTitleWidth(screenWidth: number): number {
  if (screenWidth < 1024) {
    return 412
  } else if (screenWidth < 1920) {
    return 492
  } else {
    return 792
  }
}

const ContentTable = ({ content, hasError, isLoading, onDelete }: Props) => {
  const history = useHistory()
  const onContentClick = (contentId: string) => {
    history.push(`/content/${contentId}`)
  }
  const { screenWidth } = useWindowDimensions()
  const [titleWidth, setTitleWidth] = useState<number>(
    calculateTitleWidth(screenWidth)
  )

  useLayoutEffect(() => {
    setTitleWidth(calculateTitleWidth(screenWidth))
  }, [screenWidth])

  const columns: Column<ContentInfo>[] = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'name',
        width: titleWidth,
      },
      {
        Header: 'Template',
        accessor: (row: ContentInfo) => ({
          elements: row.elements,
          contentType: row.contentType,
        }),
        width: 160,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <BaseCell>
              <OverlappingItems
                size="small"
                items={value.elements.map((element, index) => (
                  <ContentTypeBubble
                    key={index}
                    iconType={element.icon}
                    name={element?.display_name || element?.name}
                  />
                ))}
                itemNames={value.elements.map(
                  (element) => element.display_name ?? element.name
                )}
              />
            </BaseCell>
          )
        },
        align: 'left',
      },

      {
        Header: 'Created',
        accessor: 'createdDate',
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        align: 'left',
        width: 160,
      },
      {
        Header: 'Last modified',
        accessor: (row: ContentInfo) =>
          row.lastModified ? row.lastModified : row.createdDate,
        Cell: ({ value }) => <BaseCell>{formatDate(new Date(value))}</BaseCell>,
        align: 'right',
        width: 160,
      },
      {
        accessor: 'contributors',
        align: 'left',
        disableSortBy: true,
        width: 110,
        Cell: ({ value }) => (
          <BaseCell>
            <AvatarList userNames={value} />
          </BaseCell>
        ),
      },
      {
        accessor: 'id',
        align: 'center',
        width: 216,
        disableSortBy: true,
        Cell: ({ value }) => <RowActions id={value} onDelete={onDelete} />,
      },
    ],
    [titleWidth, onDelete]
  )

  return (
    <div data-testid="content-table">
      <TableWidget.Widget
        isLoading={isLoading}
        hasError={hasError}
        columns={columns}
        data={content}
        onRowClick={(content) => onContentClick(content.id)}
        firstUseText="Nothing to see here… There is no content to show."
      />
    </div>
  )
}

const RowActions = ({
  id,
  onDelete,
}: {
  id: string
  onDelete: Dispatch<SetStateAction<string>>
}) => {
  const flags = useFlags()
  const copyContentMutation = useCopyContentMutation()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return (
    <BaseCell
      className={cx('w-full justify-end space-x-12', styles.rowActions)}
    >
      <ActionIcon
        onClick={(e) => {
          e.stopPropagation()
          onDelete(id)
        }}
      >
        <Bin isDefaultColor={false} className="text-black" />
      </ActionIcon>
      <ActionIcon
        onClick={(e) => {
          e.stopPropagation()
          copyContentMutation.mutate({
            accountId,
            contentId: id,
          })
        }}
      >
        <Copy isDefaultColor={false} className="text-black" />
      </ActionIcon>
      {flags.showFavoriteIcon && (
        <ActionIcon onClick={() => alert('Not implemented yet')}>
          <Favorite isDefaultColor={false} className="text-black" />
        </ActionIcon>
      )}
    </BaseCell>
  )
}

export default ContentTable
