import React from 'react'

type Props = {
  error: any
}

class Error extends React.Component<Props> {
  render() {
    const { error } = this.props
    return (
      <div>
        <div className="common-error">Something went wrong</div>
        {error && error.toString()}
      </div>
    )
  }
}

export default Error
