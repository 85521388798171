import {
  Content,
  NLGAction,
  StepName,
  StepStatus,
} from '@phrasee/phrasee-typings/typings/futurama/content'
import {
  ComponentElement,
  Element,
  NLGStatus,
  TemplateElement,
} from '@phrasee/phrasee-typings/typings/futurama/element'
import { QueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import trimStart from 'lodash/trimStart'
import helpers from 'workflow/utils/helpers'

import { stripForbiddenCharacters } from '../../../common/helpers/stripForbiddenCharacters'
import { isComponentElement, isTemplateElement } from '../api/interfaces'
import { contentKeys } from '../api/queryKeys'

export const getAllTemplateElements = (content?: Content) => {
  const allTemplateElements: TemplateElement[] = []

  content?.elements.forEach((element) => {
    if (isTemplateElement(element)) {
      allTemplateElements.push(element)
    }
  })

  return allTemplateElements
}

export const getAllComponentElements = (content?: Content) => {
  const allComponentElements: ComponentElement[] = []

  content?.elements.forEach((element) => {
    if (isComponentElement(element)) {
      allComponentElements.push(element)
    } else {
      allComponentElements.push(...element.elements.filter(isComponentElement))
    }
  })

  return allComponentElements
}

export const getAllElements = (content?: Content) => {
  const allElements: Element[] = []

  content?.elements.forEach((element) => {
    allElements.push(element)
    if (isTemplateElement(element)) {
      allElements.push(...element.elements)
    }
  })

  return allElements
}

export const getSelectedElement = (content?: Content) => {
  const allElements = getAllElements(content)
  return allElements?.find(({ status }) => status === 'selected')
}

export const getRootSelectedElement = (content?: Content) => {
  if (!content) {
    return
  }

  let selectedElement: Element | undefined
  content?.elements.some((element) => {
    if (element.status === 'selected') {
      selectedElement = element
      return true
    }
    if (isTemplateElement(element)) {
      return element.elements.some((el) => {
        if (el.status === 'selected') {
          selectedElement = element
          return true
        }
        return false
      })
    }
    return false
  })

  return selectedElement
}

export const getFirstComponentElement = (content?: Content) => {
  const allComponentElements = getAllComponentElements(content)
  return allComponentElements.length > 0 ? allComponentElements[0] : undefined
}

export const getElementHasVariants = (element?: Element) => {
  if (!element) {
    return false
  }

  return isComponentElement(element)
    ? element.variants && element.variants?.length > 0
    : element &&
        element.elements.some(
          (el) =>
            isComponentElement(el) && el.variants && el.variants.length > 0
        )
}

export const getContentHasVariants = (content?: Content) =>
  getAllComponentElements(content)?.some(
    (element) => element.variants && element.variants.length > 0
  )

export const setContentLoadingState = (
  queryClient: QueryClient,
  contentId: string,
  action: NLGAction
) => {
  queryClient.setQueryData(
    contentKeys.content(contentId),
    (old: Content | undefined) => {
      if (old) {
        return { ...old, ws_status: { action, status: 'pending' as NLGStatus } }
      }

      return old
    }
  )
}

export const setElementLoadingState = ({
  queryClient,
  contentId,
  elementId,
  action,
}: {
  queryClient: QueryClient
  contentId: string
  elementId: number
  action: NLGAction
}) => {
  queryClient.setQueryData(
    contentKeys.content(contentId),
    (old: Content | undefined) => {
      if (old) {
        return {
          ...old,
          elements: old.elements.map((element) => {
            const clonedElement = cloneDeep(element)
            if (isTemplateElement(clonedElement)) {
              if (clonedElement.element_id === elementId) {
                clonedElement.ws_status = {
                  action,
                  status: 'pending',
                }
              }
              const clonedElements = clonedElement.elements
              clonedElements
                .filter(isComponentElement)
                .forEach((componentElement) => {
                  if (componentElement.element_id === elementId) {
                    componentElement.nlg_status = 'pending'
                  }
                })

              return { ...clonedElement, elements: clonedElements }
            }

            if (clonedElement.element_id === elementId) {
              return {
                ...clonedElement,
                nlg_status: 'pending' as NLGStatus,
                ws_status: { action, status: 'pending' as NLGStatus },
              }
            }

            return element
          }),
        }
      }
      return old
    }
  )
}

export const getElementIdForGeneration = (
  content?: Content,
  selectedElement?: Element
) => {
  if (!content) {
    return
  }

  if (selectedElement) {
    if (isComponentElement(selectedElement)) {
      return selectedElement.element_id
    } else {
      return selectedElement.elements[0].element_id
    }
  }

  return getFirstComponentElement(content)?.element_id
}

export const getElementGenerationComplete = (
  content?: Content,
  element?: Element
) => {
  if (!element || !content) {
    return false
  }

  let rootElement: Element | undefined
  for (const el of content.elements) {
    if (isComponentElement(el)) {
      if (el.element_id === element.element_id) {
        rootElement = el
        break
      }
    } else {
      if (el.element_id === element.element_id) {
        rootElement = el
        break
      }
      const foundElement = el.elements.find(
        (nestedElement) => nestedElement.element_id === element.element_id
      )

      if (foundElement) {
        rootElement = el
        break
      }
    }
  }

  return rootElement && isComponentElement(rootElement)
    ? rootElement.variants && rootElement.variants?.length > 0
    : rootElement &&
        rootElement.elements.every(
          (el) =>
            isComponentElement(el) && el.variants && el.variants.length > 0
        )
}

export const getGenerateWsTopic = (contentId: string, elementId: number) =>
  `generate/${contentId}/${elementId}`

export const getOptimizeElements = (elements: Element[] | undefined) =>
  elements
    ?.filter(isComponentElement)
    .filter((element) => element.status !== 'disabled')
    .filter((element) => !!element.campaign_id) || []

export const parseWorkflowName = (
  value: string
): {
  isValid: boolean
  validationError: string | undefined
  value: string
} => {
  const trimmedValue = trimStart(value)
  const strippedValue = stripForbiddenCharacters(trimmedValue)
  const { isValid, validationError } =
    helpers.validateCampaignName(strippedValue)

  return {
    isValid,
    validationError,
    value: isValid ? strippedValue : strippedValue.slice(0, -1),
  }
}

export const validateWorkflowName = (value: string | undefined) => {
  if (!value?.trim()) {
    return 'Enter a name for your workflow.'
  }
  const { validationError } = helpers.validateCampaignName(value)
  return validationError
}

export const getExperimentBadgesState = (element: Element | undefined) => {
  if (!element) {
    return undefined
  }

  const isAwaitingBrief =
    element.user_input_form_compatible === false &&
    element.settings_customisation === false

  const isOptimizable = isComponentElement(element) && !!element?.campaign_id

  //TODO: HH-5036 this condition is not yet available
  const isPersonalizable =
    element.experiment_type?.personalization === 'enabled'

  if (!isAwaitingBrief && !isOptimizable && !isPersonalizable) {
    return undefined
  }

  return {
    isAwaitingBrief,
    isOptimizable,
    isPersonalizable,
  }
}

export const getStepStatus = (
  stepsStatus: StepStatus[] | undefined,
  stepName: StepName
) => {
  return stepsStatus?.find((stepStatus) => stepStatus.step === stepName)?.status
}
