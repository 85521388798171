import { useState } from 'react'
import cx from 'classnames'
import identity from 'lodash/identity'

import Button from 'common/components/button'
import { useValidationError, Validations } from 'common/hooks/custom'
import { Add as AddIcon, CloseSmall } from 'common/icons'

import ButtonWithTooltip from '../ButtonWithTooltip'
import Tooltip from '../Tooltip'

import Autocomplete from './Autocomplete'

export type Option<T> = { label: string; value: T; isDisabled?: boolean }

type Props<T> = {
  tags: Option<T>[]
  maxLength?: number
  onRemoveClick?: (value: T) => void
  onAddClick?: (value: string) => void
  className?: string
  'data-testid'?: string
  'data-cy'?: string
  isFreeText: boolean
  suggestions?: string[]
  onInputChange?: (value: string) => string
  isDisabled?: boolean
  placeholder?: string
  searchValue?: string
  validations?: Validations
  hasTagButtonLabel?: boolean
  tagButtonLabel?: string
  isInsideTable?: boolean
}

const Tags = <T extends string>({
  tags: options,
  onRemoveClick,
  onAddClick,
  className,
  isDisabled,
  suggestions = [],
  onInputChange = identity,
  maxLength,
  searchValue,
  isFreeText,
  placeholder = 'Add a tag',
  validations,
  tagButtonLabel = 'Tag',
  hasTagButtonLabel,
  isInsideTable,
  'data-testid': dataTestId,
  'data-cy': dataCy,
}: Props<T>) => {
  const {
    error: inputError,
    validate,
    resetError,
  } = useValidationError(validations)
  const [isAdding, setIsAdding] = useState(false)
  const filteredTags = options.filter((tag) =>
    tag.label.toLowerCase().includes((searchValue ?? '').toLocaleLowerCase())
  )
  const uniqueSuggestions = [...new Set(suggestions)]
  const hasUsedAllSuggestions =
    !isFreeText && uniqueSuggestions.length === options.length

  return (
    <div
      className={cx('flex flex-wrap gap-2 items-center', className)}
      data-cy={dataCy}
    >
      {filteredTags.map(({ label, value, isDisabled }) => (
        <Tooltip
          overlay={label}
          key={value}
          show={maxLength === undefined ? false : label.length > maxLength}
          overlayStyle={{ maxWidth: 226 }}
        >
          <div
            data-cy="tag"
            data-testid={dataTestId && `${dataTestId}-${value}`}
            key={value}
            className={cx(
              `pl-2 flex items-center text-sm font-medium border h-6`,
              {
                'text-maroon-600 border-maroon-500 bg-maroon-50 hover:border-maroon-600':
                  !isDisabled,
              },
              {
                'text-coolGray-400 border-coolGray-300 bg-coolGray-50':
                  isDisabled,
              },
              { 'pr-2': !onRemoveClick }
            )}
          >
            <span data-cy="tag-label">
              {maxLength !== undefined && label.length > maxLength
                ? `${label.slice(0, maxLength)}...`
                : label}
            </span>
            {onRemoveClick && (
              <Button
                aria-label="Remove tag"
                data-cy="remove-tag-button"
                size="small"
                ghost
                variant="icon"
                className="pl-0 pr-0"
                onClick={() => onRemoveClick(value)}
                disabled={isDisabled}
                suffixIcon={
                  <CloseSmall
                    data-testid="remove-tag-icon"
                    data-cy="remove-tag-icon"
                    isDefaultColor={false}
                    className={cx({
                      'hover:text-maroon-500 text-maroon-300': !isDisabled,
                    })}
                  />
                }
              />
            )}
          </div>
        </Tooltip>
      ))}
      {onAddClick && (
        <>
          <ButtonWithTooltip
            size="small"
            aria-label="Add tag"
            data-cy="add-tag-button"
            ghost
            variant="icon"
            tooltip={hasUsedAllSuggestions}
            tooltipText="All selections used"
            disabled={isDisabled || hasUsedAllSuggestions}
            className={cx(
              'hover:bg-coolGray-200 h-6 px-1 outline-none focus:outline-none',
              {
                hidden: isAdding,
              }
            )}
            onClick={() => {
              setIsAdding(true)
            }}
            prefixIcon={
              <div className="flex items-center">
                <AddIcon
                  isDefaultColor={false}
                  className="text-coolGray-400 w-4 h-4"
                />
                {hasTagButtonLabel && (
                  <div className="text-sm text-coolGray-400 ml-1.5">
                    {tagButtonLabel}
                  </div>
                )}
              </div>
            }
          />
          {isAdding && (
            <>
              <Autocomplete
                data-cy={dataCy}
                placeholder={placeholder}
                isFreeText={isFreeText}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    const target = event.target as HTMLElement

                    setIsAdding(true)
                    target.focus()
                    event.preventDefault()
                  }
                }}
                onBlur={(inputValue) => {
                  if (inputValue && !inputError) {
                    // prevent adding duplicate tags
                    if (
                      !options.some(
                        (option) =>
                          inputValue.toLowerCase() ===
                          option.label.toLocaleLowerCase()
                      )
                    ) {
                      if (
                        isFreeText ||
                        uniqueSuggestions
                          .map((suggestion) => suggestion.toLocaleLowerCase())
                          .includes(inputValue.toLocaleLowerCase().trim())
                      ) {
                        onAddClick(inputValue)
                      }
                    }
                  }
                  setIsAdding(false)
                  resetError()
                }}
                onInputChange={(value) => {
                  validate(value)

                  return onInputChange(value)
                }}
                options={uniqueSuggestions
                  .filter(
                    (suggestion) =>
                      !options.some((option) => option.label === suggestion)
                  )
                  .map((suggestion) => ({
                    value: suggestion,
                    label: suggestion,
                  }))}
                isInsideTable={isInsideTable}
              />
              {inputError && (
                <div className="text-red-400 text-sm">{inputError}</div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Tags
