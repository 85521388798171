import { Template } from '@phrasee/phrasee-typings'
import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'
import { TemplateInfo } from 'features/futurama/contentsPage/interfaces'

import { getTemplates } from '../api'
import { contentKeys } from '../queryKeys'

const mapTemplateToCard = (template: Template): TemplateInfo => ({
  id: template._id,
  name: template.name,
  displayName: template.display_name,
  contentType: template.content_type,
  icon: template.icon || '',
  description: template.description || '',
  disabled: template.template_status === 'preview',
})

const useGetTemplatesQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getTemplatesQuery = useQuery(
    contentKeys.templates(),
    () => getTemplates(accountId),
    {
      staleTime: 10 * 60 * 1000,
      select: (data) => {
        return data
          .filter((template) => template.template_status !== 'inactive')
          .map(mapTemplateToCard)
      },
    }
  )

  return getTemplatesQuery
}

export default useGetTemplatesQuery
