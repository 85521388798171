import { useCallback, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { SelectValue } from 'common/components/BaseSelect'
import { OnSwapColumns } from 'common/components/table/Table'
import Tabs, { Tab } from 'common/components/tabs'
import {
  InitialProcessedResults,
  loadInitialResults,
  loadSplittingResultsColumns,
  ProcessedResults,
  processInputResults,
  submitInputResults,
} from 'features/campaigns/api'

import SplitResults from './splitResults/SplitResults'
import WinningVariantResults from './winningVariantResults/WinningVariantResults'
import Footer from './Footer'
import { Value } from './InputResultModal'
import TabContent from './TabContent'

type Props = {
  projectName: string | undefined
  campaignName: string
  campaignId: string
  onSubmit: () => void
  onClose: () => void
  selectedMode: Value
  onChangeMode: (value: Value) => void
  modes: SelectValue[]
}

type TabKey = 'winningVariant' | 'splitResults'

const InputResultsTabs: React.FC<Props> = ({
  projectName,
  campaignName,
  campaignId,
  onSubmit,
  onClose,
  selectedMode,
  onChangeMode,
  modes,
}) => {
  const [activeTab, setActiveTab] = useState<TabKey>('splitResults')

  const [inputResults, setInputResults] = useState<string>('')
  const [format, setFormat] = useState<'percentage' | 'integer'>('integer')
  const [updatedProccessedResults, setUpdatedProccessedResults] =
    useState<ProcessedResults | undefined>()
  const [finalTrackedMetrics, setFinalTrackedMetrics] = useState<
    { displayName: string; metric: string; value: number | undefined }[]
  >([])
  const [selectedVariantId, setSelectedVariantId] =
    useState<string | undefined>()

  const handleSuccessProcessInputResults = (
    data: ProcessedResults | InitialProcessedResults
  ) => {
    if (data) {
      data.rows.forEach((row, rowIndex) => {
        row.data = [
          ...row.data,
          ...data.columns
            .filter((column) => !column.isEditable)
            .map((column) => ({
              name: column.metric,
              value: undefined,
            })),
        ]

        calculateNotEditableMetricsPerRow(data, rowIndex)
      })
      setUpdatedProccessedResults(data)

      if ('winner' in data && data.winner) {
        setFinalTrackedMetrics(
          data.winner.results.map((result) => ({
            displayName:
              data.finalColumns.find((column) => column.metric === result.name)
                ?.displayName ?? '',
            metric: result.name,
            value: result.value,
          }))
        )
        setSelectedVariantId(data.winner._id)
      } else {
        setFinalTrackedMetrics(
          data?.finalColumns.map(({ displayName, metric }) => ({
            displayName,
            metric,
            value: undefined,
          })) ?? []
        )
      }
    }
  }

  const {
    isFetching: isProcessingResults,
    refetch: refetchProcessInputResults,
  } = useQuery(
    ['proccessInputs', inputResults],
    () =>
      processInputResults({
        campaignId,
        inputResults,
        inputType: format,
      }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0,
      onSuccess: handleSuccessProcessInputResults,
    }
  )

  const { isLoading: isLoadingFetchColumns, data: metrics } = useQuery(
    ['loadSplitResults', campaignId],
    () =>
      loadSplittingResultsColumns({
        campaignId,
      }).then((response) => response.data)
  )
  const metricsPerFormat = (() => {
    if (!metrics) {
      return []
    }
    const valuesPerFormat =
      format === 'integer'
        ? metrics.integer.map((metric) => metric.humanReadableMetric)
        : metrics.percentage.map((metric) =>
            metric.type === 'percentage'
              ? `${metric.humanReadableMetric} (%)`
              : metric.humanReadableMetric
          )

    return ['Variants', ...valuesPerFormat]
  })()

  const { isLoading: isLoadingFetchResults } = useQuery(
    ['loadInitialResults', campaignId],
    () =>
      loadInitialResults({
        campaignId,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0,
      onSuccess: handleSuccessProcessInputResults,
    }
  )

  const errorsCount =
    updatedProccessedResults?.rows?.reduce(
      (acc, row) => (row.error ? acc + 1 : acc),
      0
    ) ?? 0

  const handleChangeValue = useCallback(
    ({
      name: changedColumn,
      rowIndex,
      value,
    }: {
      name: string
      rowIndex: number
      value: number | undefined
    }) => {
      setUpdatedProccessedResults((prev) => {
        if (!prev) {
          return prev
        }

        const row = prev.rows[rowIndex]
        const colIndex = row.data.findIndex(
          ({ name }) => name === changedColumn
        )
        row.data[colIndex].value = value

        calculateNotEditableMetricsPerRow(prev, rowIndex)
        return {
          ...prev,
        }
      })
    },
    []
  )
  const handleClickKeep = useCallback(({ rowIndex }) => {
    setUpdatedProccessedResults((prev) => {
      if (!prev) {
        return prev
      }
      prev.rows[rowIndex].error = undefined
      return {
        ...prev,
      }
    })
  }, [])

  const handleChangeFormat = useCallback(
    (newFormat: 'percentage' | 'integer') => setFormat(newFormat),
    []
  )

  const handleClickRevert = useCallback(({ rowIndex }) => {
    setUpdatedProccessedResults((prev) => {
      if (!prev) {
        return prev
      }
      const row = prev.rows[rowIndex]
      if (row.error?.originalVariant !== undefined) {
        prev.rows[rowIndex].variant = row.error.originalVariant
      }
      row.error = undefined
      return {
        ...prev,
      }
    })
  }, [])

  const editableMetrics =
    updatedProccessedResults?.columns
      .filter((column) => column.isEditable)
      .map(({ metric }) => metric) ?? []

  const isNextButtonDisabled =
    (updatedProccessedResults === undefined &&
      inputResults.trim().length === 0) ||
    errorsCount > 0 ||
    (updatedProccessedResults &&
      updatedProccessedResults.rows.some((row) =>
        row.data
          .filter(({ name }) => editableMetrics.includes(name))
          .some(({ value }) => value === undefined)
      ))

  const handleSwapColumns: OnSwapColumns = useCallback(
    ({ sourceColumnId, targetColumnId }) => {
      setUpdatedProccessedResults((prev) => {
        if (!prev) {
          return prev
        }
        const sourceColumnIndex = prev.columns.findIndex(
          ({ metric }) => metric === sourceColumnId
        )
        const targetColumnIndex = prev.columns.findIndex(
          ({ metric }) => metric === targetColumnId
        )
        const temp = prev.columns[sourceColumnIndex]
        prev.columns[sourceColumnIndex] = prev.columns[targetColumnIndex]
        prev.columns[targetColumnIndex] = temp

        prev.rows.forEach((row, rowIndex) => {
          const sourceColumnValue = row.data[sourceColumnIndex].name
          const targetColumnName = row.data[targetColumnIndex].name
          row.data[sourceColumnIndex].name = targetColumnName
          row.data[targetColumnIndex].name = sourceColumnValue
          calculateNotEditableMetricsPerRow(prev, rowIndex)
        })
        return {
          ...prev,
        }
      })
    },
    []
  )

  const tabs: Tab<TabKey>[] = [
    {
      key: 'splitResults',
      name: 'Split results',
      content: (
        <TabContent
          content={
            <SplitResults
              modes={modes}
              selectedMode={selectedMode}
              onChangeMode={onChangeMode}
              isProcessingResults={isProcessingResults}
              isLoading={isLoadingFetchColumns || isLoadingFetchResults}
              inputResults={inputResults}
              onChangeResults={setInputResults}
              updatedProccessedResults={updatedProccessedResults}
              onChangeValue={handleChangeValue}
              onClickKeep={handleClickKeep}
              onClickRevert={handleClickRevert}
              format={format}
              onChangeFormat={handleChangeFormat}
              onSwapColumns={handleSwapColumns}
              metrics={metricsPerFormat}
            />
          }
          footer={
            <Footer
              campaignName={campaignName}
              projectName={projectName}
              onClose={onClose}
              onClickNext={() => {
                if (!updatedProccessedResults && !isProcessingResults) {
                  refetchProcessInputResults()
                }
                if (updatedProccessedResults && errorsCount === 0) {
                  setActiveTab('winningVariant')
                }
              }}
              isNextButtonDisabled={isNextButtonDisabled}
              errorsCount={errorsCount}
              isNextButtonLoading={isProcessingResults}
            />
          }
        />
      ),
    },
    {
      key: 'winningVariant',
      name: 'Winning variant results',
      isDisabled:
        isNextButtonDisabled || updatedProccessedResults === undefined,
      content: (
        <TabContent
          content={
            <WinningVariantResults
              finalTrackedMetrics={finalTrackedMetrics}
              variants={
                updatedProccessedResults?.rows.map(({ variant, id }) => ({
                  name: variant,
                  value: id,
                })) ?? []
              }
              selectedVariantId={selectedVariantId}
              onCellChange={({ columnId, value }) => {
                if (columnId === 'variant') {
                  setSelectedVariantId(value as string)
                } else {
                  setFinalTrackedMetrics((prev) => {
                    if (!prev) {
                      return prev
                    }
                    const columnIndex = prev.findIndex(
                      ({ metric }) => metric === columnId
                    )
                    prev[columnIndex].value = value as number

                    return [...prev]
                  })
                }
              }}
            />
          }
          footer={
            <Footer
              campaignName={campaignName}
              projectName={projectName}
              nextButtonName="Save"
              onClose={onClose}
              onClickNext={() => {
                if (!updatedProccessedResults) {
                  throw new Error('Input results are mandatory')
                }
                submitInputResults({
                  campaignId,
                  inputType: format,
                  winner: selectedVariantId
                    ? {
                        _id: selectedVariantId,
                        text:
                          updatedProccessedResults.rows.find(
                            (row) => row.id === selectedVariantId
                          )?.variant ?? '',
                        results: finalTrackedMetrics.map(
                          ({ metric, value }) => ({
                            name: metric,
                            value: value as number,
                          })
                        ),
                      }
                    : undefined,
                  splits: updatedProccessedResults.rows.map((row) => ({
                    text: row.variant,
                    userInput: row.variant,
                    _id: row.id,
                    results: row.data
                      .filter(({ name }) => editableMetrics.includes(name))
                      .map(({ name, value }) => ({
                        name,
                        value: value ?? 0, // the value should never be undefined at this point
                      })),
                  })),
                }).then(() => {
                  onSubmit()
                })
              }}
            />
          }
        />
      ),
    },
  ]
  return (
    <Tabs<TabKey>
      tabs={tabs}
      activeKey={activeTab}
      defaultActiveKey={tabs[0].key}
      variant="modal"
      className="flex-1"
      destroyInactiveTabPane={false}
      onTabClick={setActiveTab}
    />
  )
}

function calculateNotEditableMetricsPerRow(
  processResults: ProcessedResults,
  rowIndex: number
) {
  const row = processResults.rows[rowIndex]

  const numRecipientsValue = processResults.rows[rowIndex].data.find(
    ({ name }) => name === 'num_recipients'
  )?.value

  processResults.columns
    .filter((column) => column.isEditable)
    .forEach(({ metric: changedColumn }) => {
      const value = row.data.find(({ name }) => name === changedColumn)?.value
      const col = processResults.columns.find(
        ({ metric }) => metric === changedColumn
      )

      const colIndex = row.data.findIndex(({ name }) => name === changedColumn)
      row.data[colIndex].value = value

      if (col?.relatesTo) {
        const relatedCol = processResults.columns.find(
          ({ metric }) => metric === col.relatesTo
        )
        if (relatedCol) {
          const relatedColIndex = row.data.findIndex(
            ({ name }) => name === relatedCol.metric
          )
          row.data[relatedColIndex].value =
            value !== undefined && numRecipientsValue
              ? relatedCol.type === 'percentage'
                ? (value / numRecipientsValue) * 100
                : (value * numRecipientsValue) / 100
              : undefined
        }
      }
    })
}

export default InputResultsTabs
