import { useFlags } from 'launchdarkly-react-client-sdk'
import { MachineSummary } from 'workflow/interface'

import CheckboxComponent from 'common/components/checkbox'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget/TableWidget'

const MachineOpensSummary = ({
  machineSummary,
  isMachineOpensVisible,
  onChangeMachineOpensVisible,
}: {
  machineSummary?: MachineSummary
  isMachineOpensVisible?: boolean
  onChangeMachineOpensVisible?: (showMachineOpens: boolean) => void
}) => {
  const { showMachineOpens } = useFlags()

  if (!showMachineOpens || !machineSummary) {
    return null
  }

  const columns = [
    {
      Header: 'Opens',
      accessor: 'title',
      disableSortBy: true,
      Footer: () => (
        <BaseCell className="p-3">
          <CheckboxComponent
            className="col-span-3"
            data-cy="show-machine-opens-checkbox"
            label="Include machine opens in results"
            isChecked={isMachineOpensVisible || false}
            onChange={(isChecked: boolean) => {
              if (onChangeMachineOpensVisible) {
                onChangeMachineOpensVisible(isChecked)
              }
            }}
          />
        </BaseCell>
      ),
    },
    {
      Header: 'Rate',
      accessor: 'rate',
      disableSortBy: true,
      align: 'right',
      Footer: () => {
        return <BaseCell className="p-3">100%</BaseCell>
      },
    },
    {
      Header: 'Total',
      accessor: 'opens',
      disableSortBy: true,
      align: 'right',
      Footer: () => {
        return <BaseCell className="p-3">{machineSummary.total_opens}</BaseCell>
      },
    },
  ]

  const data = [
    {
      title: 'Machine Opens',
      rate: machineSummary.machine_opens_percent,
      opens: machineSummary.total_machine_opens,
    },
    {
      title: 'Other Opens',
      rate: machineSummary.other_opens_percent,
      opens: machineSummary.total_other_opens,
    },
  ]

  return (
    <div className="mt-6">
      <TableWidget.Widget
        columns={columns}
        className="px-4"
        data={data}
        isHeaderHidden={false}
        data-cy="machine-summary-widget"
      />
    </div>
  )
}

export default MachineOpensSummary
