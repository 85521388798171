import { useMutation, useQueryClient } from '@tanstack/react-query'

import { deletePhrasesBulk } from '../api'
import { contentKeys } from '../queryKeys'

const useBulkDeletePhraseMutation = (selectedNodeId: string) => {
  const queryClient = useQueryClient()

  return useMutation(deletePhrasesBulk, {
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.phrases(selectedNodeId))
    },
  })
}

export default useBulkDeletePhraseMutation
