import { FC, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import PasswordInput from 'common/components/PasswordInput'
import SingleSelect from 'common/components/singleSelect'
import Tags from 'common/components/tags'
import { getFieldError } from 'features/projects/helpers'
import {
  IntegrationSettings,
  IntegrationType,
  TestingMethod,
} from 'features/projects/interface'

import TimeZoneSelect from '../../timeZoneSelect'
import FormCard, { FormCardTitle } from '../formCard'

type Props = {
  integrationSettings: IntegrationSettings
}

const IntegrationCard: FC<Props> = ({ integrationSettings }) => {
  const formState = useFormState()
  const { change: formChange } = useForm()
  const { integrationType, testingMethod } = formState.values
  const {
    integrationTypeSettings,
    testingMethodSettings,
    integrationCredentialsSettings,
    integrationSpecificSettings,
  } = integrationSettings

  useEffect(() => {
    if (integrationType) {
      const isSelectedOptionAvailable = integrationTypeSettings.options.some(
        ({ value }) => value === integrationType
      )

      if (!isSelectedOptionAvailable) {
        formChange('integrationType', undefined)
      }
    }
  }, [formChange, integrationType, integrationTypeSettings.options])

  useEffect(() => {
    if (
      testingMethodSettings.show &&
      testingMethodSettings.options.length === 1 &&
      testingMethod === undefined
    ) {
      formChange('testingMethod', testingMethodSettings.options[0].value)
    }
  }, [formChange, testingMethodSettings, testingMethod])

  useEffect(() => {
    if (testingMethod) {
      const isSelectedOptionAvailable = testingMethodSettings.options.some(
        ({ value }) => value === testingMethod
      )

      if (!isSelectedOptionAvailable) {
        formChange('testingMethod', undefined)
      }
    }
  }, [formChange, testingMethod, testingMethodSettings.options])

  useEffect(() => {
    if (
      testingMethodSettings.show &&
      testingMethodSettings.options.length === 1 &&
      testingMethod === undefined
    ) {
      formChange('testingMethod', testingMethodSettings.options[0].value)
    }
  }, [formChange, testingMethodSettings, testingMethod])

  return (
    <FormCard data-cy="integration-card">
      <FormCardTitle title="Integration" />
      <Field<IntegrationType> name="integrationType">
        {({ input, meta }) => (
          <FormItem className="mb-6" error={getFieldError(meta)}>
            <SingleSelect
              data-cy="integration-type-select"
              className="max-w-xl"
              aria-label="Select integration type"
              name="integrationType"
              value={input.value}
              options={integrationTypeSettings?.options}
              onChange={(val) => val && input.onChange(val.value)}
            />
          </FormItem>
        )}
      </Field>

      {testingMethodSettings.show && (
        <Field<TestingMethod> name="testingMethod">
          {({ input, meta }) => (
            <FormItem className="mb-6" error={getFieldError(meta)}>
              <SingleSelect
                data-cy="testing-method-select"
                className="max-w-xs"
                label="Testing methodology"
                name="testingMethod"
                value={input.value}
                options={testingMethodSettings.options}
                onChange={(val) => val && input.onChange(val.value)}
              />
            </FormItem>
          )}
        </Field>
      )}

      {integrationCredentialsSettings?.fields && (
        <>
          {integrationCredentialsSettings.fields.map(
            ({ name, label, type }) => (
              <Field key={name} name={`integrationCredentials.${name}`}>
                {({ input, meta }) => (
                  <FormItem
                    label={label}
                    htmlFor={name}
                    error={getFieldError(meta)}
                  >
                    {
                      {
                        text: (
                          <Input
                            data-cy={`${name}-input`}
                            className="max-w-xs"
                            type="text"
                            variant="default"
                            id={name}
                            name={name}
                            value={input.value}
                            onChange={input.onChange}
                          />
                        ),
                        timezone: (
                          <TimeZoneSelect
                            data-cy={`${name}-select`}
                            value={input.value}
                            onChange={input.onChange}
                          />
                        ),
                        password: (
                          <PasswordInput
                            data-cy={`${name}-input`}
                            className="max-w-xs"
                            id={name}
                            name={name}
                            value={input.value}
                            onChange={input.onChange}
                          />
                        ),
                      }[type]
                    }
                  </FormItem>
                )}
              </Field>
            )
          )}

          <Field name="integrationCredentials">
            {({ meta }) => <FormItem error={getFieldError(meta)} />}
          </Field>
        </>
      )}

      {integrationSpecificSettings?.fields && (
        <>
          {integrationSpecificSettings.fields.map(({ name, label, type }) => {
            const fieldName = `integrationSpecificSettings.${name}`
            const savedValue =
              formState.values?.integrationSpecificSettings?.[name] || []

            return (
              <Field key={name} name={fieldName}>
                {({ input, meta }) => (
                  <FormItem label={label} error={getFieldError(meta)}>
                    {
                      {
                        taggedInput: (
                          <Tags
                            data-cy={`${name}-input`}
                            tags={
                              input.value
                                ? input.value.map((view) => ({
                                    label: view,
                                    value: view,
                                  }))
                                : []
                            }
                            onAddClick={(value) =>
                              formChange(fieldName, [...savedValue, value])
                            }
                            onRemoveClick={(value) =>
                              formChange(
                                fieldName,
                                savedValue.filter((item) => item !== value)
                              )
                            }
                            isFreeText
                          />
                        ),
                      }[type]
                    }
                  </FormItem>
                )}
              </Field>
            )
          })}

          <Field name="integrationSpecificSettings">
            {({ meta }) => <FormItem error={getFieldError(meta)} />}
          </Field>
        </>
      )}
    </FormCard>
  )
}

export default IntegrationCard
