import { FC, useMemo } from 'react'
import momentTZ from 'moment-timezone'

import SingleSelect from 'common/components/singleSelect'

type Props = {
  value: string
  onChange: (value: string) => void
}

const TimezoneSelect: FC<Props> = ({ value, onChange }) => {
  const options = useMemo(
    () => momentTZ.tz.names().map((value) => ({ label: value, value })),
    []
  )

  return (
    <SingleSelect
      className="max-w-xs"
      aria-label="Select timezone"
      value={value}
      options={options}
      onChange={(value) => value && onChange(value.value)}
      isSearchable
    />
  )
}

export default TimezoneSelect
