type Props = {
  message: string
  style?: React.CSSProperties
}
const ValidationError = ({ message, style }: Props) => (
  <label className="error  max-w-full block" style={style}>
    {message}
  </label>
)

export default ValidationError
