import { useCallback } from 'react'

import { connectInternalApi } from 'common/api'
import type { UpliftData } from 'common/components/UpliftGraph'
import BaseUpliftWidget from 'common/components/UpliftWidget'
import { useAppSelector } from 'common/hooks/redux'
import { WidgetGraphConfig } from 'common/interfaces/widgetListInterfaces'

import useFetchGraphData from './hooks/useFetchGraphData'
import { GraphDataResponse } from './interfaces'

type Props = {
  configuration: WidgetGraphConfig
  name: string
  tooltip?: string
}

const UpliftWidget = ({ configuration, name, tooltip = '' }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { projects, campaigns, startDate, endDate } = useAppSelector(
    (state) => state.reports
  )

  const fetchData = useCallback(() => {
    const hasProjectIds = projects.length > 0
    const hasCampaignIds = campaigns.length > 0
    const params = {
      uplift_type: configuration.uplift_types,
      by_level: configuration.by_level,
      reporting_level: configuration.reporting_level,
      testing_method: configuration.testing_method.filter(
        (m) => m !== 'bandit' // TODO: Remove filtering when this is fixed in the BE.
      ),
      project_ids:
        hasProjectIds && !hasCampaignIds ? projects.join(',') : undefined,
      campaign_ids: hasCampaignIds ? campaigns.join(',') : undefined,
      start_date: startDate,
      end_date: endDate,
    }

    return connectInternalApi.get<GraphDataResponse>(
      `v1/core/reporting/reporting/accounts/${accountId}/uplift/report/graph-data`,
      { params }
    )
  }, [projects, campaigns, accountId, startDate, endDate, configuration])

  const {
    hasError,
    data: upliftData,
    isLoading,
    xAxisLabels,
  } = useFetchGraphData<UpliftData>(fetchData)

  return (
    <BaseUpliftWidget
      hasError={hasError}
      title={name}
      info={tooltip}
      isLoading={isLoading}
      data={upliftData}
      xAxisLabels={xAxisLabels}
    />
  )
}

export default UpliftWidget
