import { useEffect, useState } from 'react'
import cx from 'classnames'

import { connectInternalApi } from 'common/api'
import { ReportSummaryResponse } from 'common/api/reporting'
import Checkbox from 'common/components/checkbox'
import * as GraphWidget from 'common/components/GraphWidget'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import SentimentAnalysisChart, {
  Serie,
} from 'common/components/SentimentAnalysis/SentimentAnalysisChart'
import { sentimentsDistributionText } from 'common/components/SentimentAnalysis/SentimentAnalysisWidget'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { InfoAction } from 'common/components/WidgetHeader'
import { WidgetError } from 'common/components/WidgetMessage'
import { formatDate } from 'common/helpers/date'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'
import { DateRange } from 'features/reports/store/insightsSlice'

import { WidgetProps } from '../interfaces'

const AverageSentimentsWidget = ({
  accountId,
  projectIds,
  startDate,
  endDate,
  dateRangeToCompare,
}: WidgetProps & { dateRangeToCompare?: DateRange }) => {
  const [results, setResults] = useState<(Serie & { projectId: string })[]>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isWinnersOnly, setIsWinnersOnly] = useState<boolean>(false)

  const { chart, chartRefCallback } = useChartRefCallback()
  if (projectIds.length > 2) {
    throw new RangeError(
      'Too many projects selected, only 2 are allowed at max'
    )
  }

  useEffect(() => {
    function fetchData({
      startDate,
      endDate,
      projectIds,
      isWinnersOnly,
    }: DateRange & { projectIds: string[]; isWinnersOnly: boolean }) {
      return connectInternalApi.get<ReportSummaryResponse>(
        `v1/core/reporting/reporting/accounts/${accountId}/insights/sentiment/report`,
        {
          params: {
            project_ids: projectIds,
            start_date: startDate,
            end_date: endDate,
            period_type: 'aggregate',
            reporting_level: 'project',
            winners_only: isWinnersOnly,
          },
        }
      )
    }
    setResults([])
    setIsLoading(true)
    setHasError(false)

    const promises = dateRangeToCompare
      ? Promise.all([
          fetchData({ startDate, endDate, projectIds, isWinnersOnly }),
          fetchData({ ...dateRangeToCompare, projectIds, isWinnersOnly }),
        ])
      : Promise.all([
          fetchData({ startDate, endDate, projectIds, isWinnersOnly }),
        ])

    promises
      .then((graphResponses) => {
        if (graphResponses.length > 1) {
          setResults(
            graphResponses.map((response, index) => {
              const {
                data: { data: graphData },
              } = response
              const values = graphData[0].values[1].values
              const formattedDate = `${formatDate(
                index === 0 ? startDate : dateRangeToCompare?.startDate
              )} - ${formatDate(
                index === 0 ? endDate : dateRangeToCompare?.endDate
              )}`
              return {
                projectId: formattedDate,
                name: formattedDate,
                data: values.map(({ value }) => value),
                color: index === 0 ? '#38bdf8' : '#901356',
              }
            })
          )
        } else {
          const {
            data: { data: graphData },
          } = graphResponses[0]

          const valuesPerProject = graphData[0].values.slice(1)

          valuesPerProject &&
            setResults(
              valuesPerProject.map(({ name, values }, index) => ({
                projectId: projectIds[index],
                name: name,
                data: values.map(({ value }) => value),
                color: index === 0 ? '#38bdf8' : '#901356',
              }))
            )
        }
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [
    accountId,
    projectIds,
    startDate,
    endDate,
    isWinnersOnly,
    dateRangeToCompare,
  ])

  const hasResults = !hasError && !isLoading

  const title = 'Average sentiments'

  return (
    <GraphWidget.Widget
      type={
        projectIds.length === 1 && !dateRangeToCompare ? 'inner' : 'default'
      }
      title={
        <div>
          <div className="text-coolGray-800 font-medium text-xl mb-4 mr-4">
            {title}
          </div>
          <p className="text-coolGray-500 text-sm font-normal">
            {sentimentsDistributionText}
          </p>
        </div>
      }
      actions={
        hasResults && (
          <div className="inline-flex">
            <ChartWidgetMenu
              chart={chart}
              title={title}
              exportOptions={{
                legend: { enabled: false },
                chart: {
                  marginTop: 40,
                },
              }}
            />
            <InfoAction
              message="Jacquard calculates the sentiment distribution for each variant 
              it tests. This shows the average usage of these sentiments across all variants tested 
              over the time period you’ve selected. If you want to get a view of the sentiments your 
              audience is engaging with most, select ‘winners only’."
            />
          </div>
        )
      }
    >
      {isLoading && <WidgetLoader data-cy="average-sentiments-widget-loader" />}
      {hasError && <WidgetError className="mb-6" />}
      {hasResults ? (
        <div className="relative">
          <SentimentAnalysisChart
            series={results}
            ref={chartRefCallback}
            isLegendVisible={results.length > 1}
          />
          <div
            className={cx(
              { 'bottom-6': results.length === 1 },
              { 'bottom-0': results.length === 2 },
              'w-max absolute z-10'
            )}
          >
            <Checkbox
              label="Show winners only"
              labelClassName="text-base text-gray-600"
              isChecked={isWinnersOnly}
              onChange={() =>
                setIsWinnersOnly((prevIsWinnersOnly) => !prevIsWinnersOnly)
              }
            />
          </div>
        </div>
      ) : undefined}
    </GraphWidget.Widget>
  )
}

export default AverageSentimentsWidget
