import { FC } from 'react'
import cx from 'classnames'

import DetailsPanel, {
  DETAILS_TRANSITION_DURATION,
} from 'common/components/detailsPanel'

import Buttons from './Buttons'
import Details from './Details'
import Info from './Info'
import User from './User'

interface Props {
  isExpanded: boolean
  setIsExpanded: (val: boolean) => void
  projectName: string
}

const CampaignDetails: FC<Props> = ({
  isExpanded,
  setIsExpanded,
  projectName,
}) => {
  return (
    <DetailsPanel isExpanded={isExpanded} setIsExpanded={setIsExpanded}>
      <Buttons isExpanded={isExpanded} projectName={projectName} />
      <User isExpanded={isExpanded} />
      <div
        className={cx(
          `w-74 transition-opacity duration-${DETAILS_TRANSITION_DURATION}`,
          {
            'opacity-100': isExpanded,
            'opacity-0': !isExpanded,
          }
        )}
      >
        <Details />
        <Info />
      </div>
    </DetailsPanel>
  )
}

export default CampaignDetails
