import Radio, { RadioProps } from '../radioGroup/components/radio'

interface Props extends Omit<RadioProps, 'isChecked'> {
  checked: boolean
  onChange: () => void
}

const TableRadio: React.FC<Props> = ({
  checked = false,
  onChange,
  ...rest
}) => {
  return <Radio isChecked={checked} onChange={onChange} {...rest} />
}

export default TableRadio
