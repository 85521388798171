import Widget from 'common/components/Widget'
import { formatNumber } from 'common/helpers/numeric'
import { LineChart } from 'common/icons'

export function formatPercentage(value: number | string) {
  if (typeof value === 'string' && value.includes('%')) {
    const numberValue = parseFloat(value)
    return `${numberValue.toFixed(2)}%`
  }
  return value
}

const Graph = ({ label, value }: { label: string; value: number }) => {
  return (
    <Widget className="w-full grid h-full m-auto" type="basic">
      <div className="flex justify-between w-full">
        <div className="flex flex-col gap-2">
          <div className="text-coolGray-400 text-sm font-medium">{label}</div>
          <div className="text-gray-800 text-xl font-bold mt-auto">
            {typeof value === 'number'
              ? formatNumber(value)
              : formatPercentage(value)}
          </div>
        </div>
        <LineChart
          isDefaultColor={false}
          className="text-green-400 self-end h-auto items-end"
          size={28}
        />
      </div>
    </Widget>
  )
}
export default Graph
