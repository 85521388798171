import { Content } from '@phrasee/phrasee-typings'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  getElementHasVariants,
  getSelectedElement,
} from 'features/futurama/contentPage/helpers'
import { contentPageInitialize } from 'features/futurama/store/contentSlice'

import { contentKeys } from '../queryKeys'
import { deleteElement } from '..'

const useDeleteElementMutation = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )

  return useMutation(deleteElement, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (content: Content, { elementId: deteledElementId }) => {
      const selectedElement = getSelectedElement(content)
      const hasVariants = getElementHasVariants(selectedElement)
      const contentId = content._id

      queryClient.setQueryData(contentKeys.content(contentId), content)

      if (selectedElementId === deteledElementId) {
        dispatch(
          contentPageInitialize({
            activeContentId: contentId,
            selectedElementId: selectedElement?.element_id,
            showComponentConfiguration: !hasVariants,
          })
        )
      }
    },
  })
}

export default useDeleteElementMutation
