import { generatePath, useHistory } from 'react-router-dom'

import { ContentTypeIcon } from 'common/components/ContentTypeIcon'
import { ResponseStatus } from 'common/interfaces/campaign'
import ListWidget, {
  ListWidgetItem,
} from 'features/homePage/components/listWidget'

import { ResponseCampaign } from '../api'

type Status = Exclude<ResponseStatus, 'all'>

const PROGRESS_MAP: { [key in Status]: string } = {
  not_approved: 'is awaiting your approval.',
  not_completed: 'is not complete yet.',
  missing_results: 'is missing results.',
  completed: 'report is now ready.',
}

const STATUS_MAP: { [key in Status]: string } = {
  not_approved: 'Approve',
  not_completed: 'Finish it now',
  missing_results: 'Upload results',
  completed: 'View report',
}

type Props = {
  campaigns: ResponseCampaign[]
  status: 'error' | 'success' | 'loading'
}

const Todo = ({ campaigns, status }: Props) => {
  const history = useHistory()

  return (
    <ListWidget
      title="To-dos"
      data-cy="todo-widget"
      isLoading={status === 'loading'}
      className="w-full h-full"
      error={
        status === 'error' ? (
          <div className="px-4 py-2">Loading To Do's failed</div>
        ) : null
      }
    >
      <ul>
        {campaigns.map((item, index) => (
          <ListWidgetItem
            key={item.id}
            onClick={() => {
              history.push(generatePath(`/campaigns/${item.id}`))
            }}
            data-cy={`todo-widget-link-${index}`}
          >
            <span className="text-black group-hover:text-maroon-500">
              <ContentTypeIcon
                contentType={item.campaign_configuration.distribution_channel}
              />
            </span>
            <span className="text-coolGray-800 font-medium ml-3 break-all">
              {item.name}
            </span>
            <span className="text-coolGray-500">
              {PROGRESS_MAP[item.status]}
            </span>
            <span className="text-pink-500 group-hover:underline group-hover:text-maroon-500">
              {STATUS_MAP[item.status]}
            </span>
          </ListWidgetItem>
        ))}
      </ul>
    </ListWidget>
  )
}

export default Todo
