import { forwardRef, RefCallback } from 'react'
import HC_more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import HC_no_data from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'

import { ChartInstance } from 'common/components/AutoResizeChart'

import ChartContainer from './ChartContainer'
import type { SentimentAnalysisChartVariables } from './getSentimentAnalysisChartOptions'
import { getSentimentAnalysisChartOptions } from './getSentimentAnalysisChartOptions'

HC_more(Highcharts)
HC_no_data(Highcharts)

type Props = SentimentAnalysisChartVariables & {
  isLoading: boolean
  ref?: RefCallback<ChartInstance>
}

const SentimentAnalysisChart = forwardRef<ChartInstance, Props>(
  ({ selectedSentiment, series, onClickSentiment, isLoading }, ref) => {
    const chartOptions = getSentimentAnalysisChartOptions({
      series,
      selectedSentiment,
      onClickSentiment,
    })

    return (
      <ChartContainer isLoading={isLoading}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          ref={ref}
          immutable={false}
        />
      </ChartContainer>
    )
  }
)

export default SentimentAnalysisChart
