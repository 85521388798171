import { useState } from 'react'
import { Field } from 'react-final-form'

import FormItem from 'common/components/FormItem'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { ChevronDown, ChevronUp } from 'common/icons'
import useGetBrandVoicesQuery from 'features/admin/brandVoice/api/queries/useGetBrandVoicesQuery'

const BrandVoiceSettings = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { data: brandVoices } = useGetBrandVoicesQuery()

  const brandVoicesOptions = brandVoices?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  if (!brandVoices || brandVoices.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col">
      <div
        className="mb-6 py-2 text-coolGray-800 font-medium text-sm cursor-pointer select-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="mr-2.5">Advanced settings</span>
        {isExpanded ? (
          <ChevronUp
            size={4}
            isDefaultColor={false}
            className="text-coolGray-400"
          />
        ) : (
          <ChevronDown
            size={4}
            isDefaultColor={false}
            className="text-coolGray-400"
          />
        )}
      </div>
      {isExpanded && (
        <Field<SelectValue>
          name="brand_voice"
          render={({ input }) => (
            <FormItem label="Brand voice" htmlFor="brand_voice">
              <span className="text-coolGray-500">
                This will set the language settings for the generated content.
                For more details, go to Brand Voice.
              </span>
              <SingleSelect
                className="mt-4"
                data-cy="brand-voice-select"
                name="brand_voice"
                placeholder="Select your brand voice"
                value={input.value.value}
                onChange={input.onChange}
                onBlur={input.onBlur}
                options={brandVoicesOptions}
              />
            </FormItem>
          )}
        />
      )}
    </div>
  )
}

export default BrandVoiceSettings
