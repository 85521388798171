import { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import breakpoints from 'common/breakpoints'
import Intercom from 'common/components/intercom/Intercom'
import TopNavBar from 'common/components/navigation/topNavBar'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useDataIngestionStatusNotification from 'features/admin/dataSources/useDataIngestionStatusNotification'
import { getSelectedAccountName } from 'features/auth/store/authSlice'
import { cancelCreateCampaign } from 'features/campaigns/store/campaignSlice'

import 'common/services/HighchartsPlugin'
import './App.less'

import Drawer from './IntegrationDrawer'

const App: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const {
    accountId,
    firstName,
    lastName,
    intercomInfo,
    user_id: userId,
  } = useAppSelector((state) => state.authStates)
  const accountName = useAppSelector(getSelectedAccountName)
  const { email } = intercomInfo

  useEffect(() => {
    if (window?.heap) {
      window.heap?.identify && window.heap.identify(userId)
      window.heap?.addUserProperties &&
        window.heap.addUserProperties({
          emailAddress: email,
          firstName,
          lastName,
          accountId,
          accountName,
        })
    }
  }, [userId, email, firstName, lastName, accountId, accountName])

  useEffect(() => {
    // handle browser back button click
    return history.listen(() => {
      if (history.action === 'POP') {
        dispatch(cancelCreateCampaign())
      }
    })
  }, [dispatch, history])

  useDataIngestionStatusNotification()

  return (
    <div
      key={accountId}
      style={{ minWidth: breakpoints.xs }}
      className="flex overflow-x-auto "
    >
      <div className="flex flex-col h-screen w-full bg-white overflow-y-hidden">
        <TopNavBar />
        {children}
      </div>
      <Drawer />
      <Intercom />
    </div>
  )
}

// failsafe mechanism to make sure private routes are protected
export default withAuthenticationRequired(App, {
  onRedirecting: () => <Spinner isFullPage />,
})
