import React from 'react'
import cx from 'classnames'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import solidGauge from 'highcharts/modules/solid-gauge'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'

import { GraphData } from './AverageDiversityWidget'

import styles from './AverageDiversity.module.css'

highchartsMore(Highcharts)
solidGauge(Highcharts)

interface Props {
  total: number
  results: GraphData
  selectedTimeIndex: number
}

interface SerieStyle {
  color: string
  radius: string
  innerRadius: string
}

interface SerieData extends SerieStyle {
  y: number
}

interface Serie {
  name: string
  type: 'solidgauge'
  data: SerieData[]
}

const backgroundColor = '#f5f6f8'

const serieStyles: SerieStyle[] = [
  { color: '#2dd4bf', radius: '105%', innerRadius: '90%' },
  { color: '#901356', radius: '85%', innerRadius: '70%' },
]

export default React.forwardRef<ChartInstance, Props>(
  ({ total, results }, ref) => {
    const hasCampaigns = total !== 0

    const tracks: Highcharts.PaneBackgroundOptions[] = results.map((_, i) => {
      return {
        outerRadius: serieStyles[i].radius,
        innerRadius: serieStyles[i].innerRadius,
        backgroundColor: backgroundColor,
        borderWidth: 0,
      }
    })

    const series: Serie[] = results.map(({ label, value }, i) => {
      return {
        name: label,
        type: 'solidgauge',
        data: [
          {
            ...serieStyles[i],
            y: value,
          },
        ],
      }
    })

    const options: Highcharts.Options = {
      chart: {
        type: 'solidgauge',
        height: '100%', // same height as width
        margin: [0, 0, 0, 0],
      },

      credits: {
        enabled: false,
      },

      title: undefined,

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: tracks,
      },

      yAxis: {
        min: 0,
        max: total,
        lineWidth: 0,
        tickPositions: [],
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },

      series: hasCampaigns ? series : undefined,

      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
    }

    return (
      <div className="flex items-center text-coolGray-600">
        <div className={cx(styles.gaugeWrapper, `w-80 m-auto`)}>
          <HighchartsReact
            data-testid="activity-gauge"
            ref={ref}
            highcharts={Highcharts}
            constructorType="chart"
            options={options}
            immutable={true}
          />
          <span className={cx(styles.low)}>Low</span>
          <span className={cx(styles.fair)}>Fair</span>
          <span className={cx(styles.high)}>High</span>
        </div>
      </div>
    )
  }
)
