import cx from 'classnames'

import {
  AlertCircle,
  CheckCircleInverted,
  Info,
  MissingDataInverted,
} from 'common/icons'
export type Type = 'success' | 'error' | 'info' | 'warn'

const iconProps = {
  className: 'mr-2 w-4',
  isDefaultColor: false,
}

type Props = {
  type: Type
  className?: string
  showIcon?: boolean
}

const Alert: React.FC<Props> = ({
  type,
  showIcon,
  children,
  className = '',
}) => {
  let typeClassName: string = ''
  let icon: React.ReactNode = null

  switch (type) {
    case 'success':
      typeClassName = 'bg-RAGGreen text-white border-RAGGreen'
      icon = (
        <CheckCircleInverted
          {...{
            ...iconProps,
            className: `${iconProps.className} text-RAGGreen`,
          }}
        />
      )
      break
    case 'error':
      typeClassName = 'bg-RAGred text-white border-RAGred'
      icon = (
        <AlertCircle
          {...{
            ...iconProps,
            className: `${iconProps.className} text-RAGred`,
          }}
        />
      )
      break
    case 'info':
      typeClassName = 'bg-base-100 text-black border-base-100'
      icon = <Info {...iconProps} />
      break
    case 'warn':
      typeClassName = 'bg-RAGamber text-white border-RAGamber'
      icon = (
        <MissingDataInverted
          {...{
            ...iconProps,
            className: `${iconProps.className} text-RAGamber`,
          }}
        />
      )
      break

    default:
      break
  }

  return (
    <div className={cx('p-4 border font-medium', typeClassName, className)}>
      {showIcon && icon}
      {children}
    </div>
  )
}

export default Alert
