import { useEffect, useRef, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { generatePath, useHistory } from 'react-router-dom'
import { projectSettingsPath } from 'app/navigation/paths'
import RouterPromptStay from 'app/router/RouterPromptStay'

import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import Footer from 'common/components/layout/Footer'
import Scrollable from 'common/components/scrollable'
import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import { ProjectMappedUiFields } from 'common/interfaces/projects'
import { CreateProjectResponse } from 'features/projects/api/interface'
import { getFieldError, validateProjectName } from 'features/projects/helpers'
import useCreateProjectMutation from 'features/projects/mutations/useCreateProjectMutation'
import useProjectsQuery from 'features/projects/queries/useProjectsQuery'
import { showInvalidFormToast } from 'features/projects/utils/errors'

import ProjectFormCards from '../../components/projectFormCards'

const CreateProject = () => {
  const history = useHistory()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { data: projects } = useProjectsQuery(accountId)
  const projectNames = projects?.map(({ name }) => name)
  const nameInputRef = useRef<HTMLInputElement>(null)
  const createProjectMutation = useCreateProjectMutation()

  const [isSuccessfullySaved, setIsSuccessfullySaved] = useState<boolean>(false)

  useEffect(() => {
    nameInputRef.current?.focus()
  })

  const validateName = (value: string | undefined) => {
    if (!value) {
      return 'Please enter project name'
    }

    const { error } = validateProjectName(value, projectNames)
    return error
  }

  return (
    <div className="bg-white flex-grow">
      <Form<ProjectMappedUiFields>
        onSubmit={async (config) => {
          try {
            const response = await createProjectMutation.mutateAsync({
              accountId,
              config,
            })

            if (response.state === 'validationError') {
              showInvalidFormToast()
              return response.validationErrors
            } else {
              return response
            }
          } catch (error) {
            errorToast('Failed to save project')
          }
        }}
        destroyOnUnregister
        render={({
          handleSubmit,
          dirty: isFormDirty,
          form,
          submitting: isSubmitting,
          hasValidationErrors,
        }) => (
          <Scrollable
            content={
              <>
                <form
                  id="create-project-form"
                  className="px-8 py-6"
                  onSubmit={async (event) => {
                    if (hasValidationErrors) {
                      showInvalidFormToast()
                    }

                    handleSubmit(event)?.then((response) => {
                      const data = response as CreateProjectResponse
                      if (data.state === 'success') {
                        setIsSuccessfullySaved(true)
                        const projectId = data.data.project._id as string
                        history.push(
                          generatePath(projectSettingsPath, {
                            accountId,
                            projectId: projectId,
                          })
                        )
                      }
                    })
                  }}
                >
                  <h1 className="mb-3 text-xl font-medium">
                    Create a new project
                  </h1>

                  <Field<string> name="name" validate={validateName}>
                    {({ input, meta }) => (
                      <FormItem
                        label="Project name"
                        htmlFor="name"
                        error={getFieldError(meta)}
                      >
                        <Input
                          ref={nameInputRef}
                          data-cy="project-name"
                          className="max-w-xl"
                          type="text"
                          variant="default"
                          id="name"
                          name="name"
                          placeholder="Project name"
                          value={input.value}
                          onChange={input.onChange}
                          onBlur={input.onBlur}
                        />
                      </FormItem>
                    )}
                  </Field>

                  <ProjectFormCards />
                </form>

                <Footer />
                <RouterPromptStay
                  shouldShow={isFormDirty && !isSuccessfullySaved}
                  onOK={() => Promise.resolve(true)}
                />
              </>
            }
            footer={
              isFormDirty && (
                <BottomToolbar className="justify-end pr-18">
                  <Button
                    data-cy="save-project-button"
                    type="submit"
                    form="create-project-form"
                    variant="primary"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </BottomToolbar>
              )
            }
          />
        )}
      />
    </div>
  )
}

export default CreateProject
