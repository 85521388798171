import { generatePath, useHistory } from 'react-router-dom'

import { ContentTypeIcon } from 'common/components/ContentTypeIcon'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import ListWidget, {
  ListWidgetAction,
  ListWidgetItem,
} from 'features/homePage/components/listWidget'

import { ResponseCampaign } from '../api'

type Props = {
  campaigns: ResponseCampaign[]
  status: 'error' | 'success' | 'loading'
}

const RecentExperiments = ({ campaigns, status }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { accountId } = useAppSelector((state) => state.authStates)

  const createAction: ListWidgetAction = {
    label: 'Create an experiment',
    onClick: () => {
      history.push(generatePath('/campaigns', { accountId }))
      dispatch(clickCreateCampaign())
    },
  }

  return (
    <ListWidget
      title="Recent experiments"
      className="w-full h-full"
      data-cy="recent-experiments-widget"
      action={createAction}
      isLoading={status === 'loading'}
      error={
        status === 'error' ? (
          <div className="px-4 py-2">Loading Recent Experiments failed</div>
        ) : null
      }
    >
      <ul>
        {campaigns.map((item, index) => (
          <ListWidgetItem
            key={item.id}
            onClick={() => {
              history.push(generatePath(`/campaigns/${item.id}`))
            }}
            data-cy={`recent-experiment-widget-link-${index}`}
          >
            <span className="group-hover:text-maroon-300">
              <ContentTypeIcon
                contentType={item.campaign_configuration.distribution_channel}
              />
            </span>
            <span className="text-coolGray-800 ml-3">{item.name}</span>
          </ListWidgetItem>
        ))}
      </ul>
    </ListWidget>
  )
}

export default RecentExperiments
