import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchChannels } from '../api'
import { demoKeys } from '../queryKeys'

const useGetChannelsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const query = useQuery(demoKeys.channel, () => fetchChannels(), {
    enabled: !!accountId,
    retry: false,
    meta: {
      errorMessage: 'The channels could not be loaded',
    },
  })

  return query
}

export default useGetChannelsQuery
