import { useState } from 'react'

import { SelectValue } from 'common/components/singleSelect'
import Widget from 'common/components/Widget'
import { useAppSelector } from 'common/hooks/redux'

import EmojiPerformanceWidget from '../emojiPerformance/EmojiPerformanceWidget'
import SummaryWidgetContainer from '../SummaryWidget/SummaryWidgetContainer'
import WordPerformanceWidget from '../wordPerformance/WordPerformanceWidget'

type Props = {
  projectId: string | undefined
  startDate: string
  endDate: string
  projects: SelectValue[]
}
const FirstWidgets = ({ projectId, startDate, endDate, projects }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const [hasWordData, setHasWordData] = useState(true)

  const projectName = projects.find(({ value: id }) => id === projectId)?.label
  const projectIds = projectId ? [projectId] : []
  return (
    <Widget direction="column">
      <div className="flex flex-col gap-6 ">
        <div className="text-xl text-coolGray-800 font-medium">
          {projectName}
        </div>
        <SummaryWidgetContainer
          project={projectId!}
          startDate={startDate}
          endDate={endDate}
          mode="projectComparison"
        />
      </div>
      {hasWordData && (
        <WordPerformanceWidget
          accountId={accountId}
          projectIds={projectIds}
          startDate={startDate}
          endDate={endDate}
          setHasData={setHasWordData}
        />
      )}
      <EmojiPerformanceWidget
        accountId={accountId}
        projectIds={projectIds}
        startDate={startDate}
        endDate={endDate}
      />
    </Widget>
  )
}

export default FirstWidgets
