import { useQuery } from '@tanstack/react-query'

import { listAccounts } from '../api'
import { integrationsKeys } from '../queryKeys'

const useListAccountsQuery = (accountId: string, provider?: string) => {
  const getIntegrationsQuery = useQuery(
    integrationsKeys.accounts(accountId, provider),
    () => listAccounts({ accountId, provider })
  )
  return getIntegrationsQuery
}

export default useListAccountsQuery
