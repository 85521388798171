import { RevenueInformationResponse } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { SelectValue } from 'common/components/singleSelect'
import { isoCurrencyArray } from 'common/helpers/currency'

import { RevenueSettingsValues } from './interfaces'

export const currencies: SelectValue[] = isoCurrencyArray.map((currency) => ({
  value: currency,
  label: currency,
}))

export const openConversionPercentage: SelectValue = {
  label: 'Open to purchase conversion',
  value: 'open_conversion_percentage',
}
export const clickConversionPercentage: SelectValue = {
  label: 'Click to purchase conversion',
  value: 'click_conversion_percentage',
}
export const valueOfAnOpen: SelectValue = {
  label: 'Value of an open',
  value: 'open_value',
}
export const valueOfaClick: SelectValue = {
  label: 'Value of a click',
  value: 'click_value',
}

export const revenueReportingAllTypes: SelectValue[] = [
  openConversionPercentage,
  clickConversionPercentage,
  valueOfAnOpen,
  valueOfaClick,
]

export const defaultRevenueSettings: RevenueSettingsValues = {
  currency: currencies[0].value,
  type: openConversionPercentage.value,
}

export const mapRevenueInformationResponse = (
  revenueInformationResponse: RevenueInformationResponse
): RevenueSettingsValues => {
  const revenueSettings: RevenueSettingsValues = {
    ...defaultRevenueSettings,
    isSaved: true,
  }

  if (revenueInformationResponse.average_order?.iso_currency) {
    revenueSettings.currency =
      revenueInformationResponse.average_order?.iso_currency
  }
  if (revenueInformationResponse.open_conversion_percentage) {
    revenueSettings.type = openConversionPercentage.value
    revenueSettings.value =
      revenueInformationResponse.open_conversion_percentage
    revenueSettings.averageOrderValue =
      revenueInformationResponse.average_order?.value || undefined
  } else if (revenueInformationResponse.click_conversion_percentage) {
    revenueSettings.type = clickConversionPercentage.value
    revenueSettings.value =
      revenueInformationResponse.click_conversion_percentage
    revenueSettings.averageOrderValue =
      revenueInformationResponse.average_order?.value || undefined
  } else if (revenueInformationResponse.open_value) {
    revenueSettings.type = valueOfAnOpen.value
    revenueSettings.value = revenueInformationResponse.open_value
  } else if (revenueInformationResponse.click_value) {
    revenueSettings.type = valueOfaClick.value
    revenueSettings.value = revenueInformationResponse.click_value
  }

  return revenueSettings
}
