import RCSlider, { SliderProps } from 'rc-slider'

import 'rc-slider/assets/index.css'
import styles from './Slider.module.css'

/**
 * @deprecated
 * TODO replace this by the generic Slider component once we have the design
 */
const Slider = (props: SliderProps) => (
  <div className={styles.container}>
    <RCSlider {...props} />
  </div>
)

export default Slider
