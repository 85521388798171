import { errorToast } from 'common/components/toastNotification'

export type CsvCustomerState = {
  headers: string[]
  progressInPercentage: number | undefined
  data: string[][] | undefined
  fileName: string | undefined
  isPreviewOpen: boolean
  isConfirmationPreviewOpen: boolean
}

export const csvCustomerInitialState: CsvCustomerState = {
  headers: [],
  progressInPercentage: undefined,
  isPreviewOpen: false,
  data: undefined,
  fileName: undefined,
  isConfirmationPreviewOpen: false,
}

export type CsvCustomerActions =
  | { type: 'case1' }
  | { type: 'upload'; headers: string[]; data: any; fileName: string }
  | { type: 'cancelPreview' }
  | { type: 'errorUpload' }
  | { type: 'uploadCompleted' }
  | { type: 'uploadProgress'; percentage: number }
  | { type: 'clickImport' }
  | { type: 'cancelConfirmationPreview' }
  | { type: 'closeConfirmationPreview' }

export const csvCustomerReducer: React.Reducer<
  CsvCustomerState,
  CsvCustomerActions
> = (previousState, action) => {
  switch (action.type) {
    case 'case1': {
      return { ...previousState }
    }
    case 'upload': {
      if (action.data.length > 0) {
        return {
          ...previousState,
          headers: action.headers,
          data: action.data,
          fileName: action.fileName,
          isPreviewOpen: true,
        }
      } else {
        errorToast('No data found in the CSV file')
        return { ...previousState }
      }
    }
    case 'uploadProgress': {
      return {
        ...previousState,
        progressInPercentage: action.percentage,
      }
    }
    case 'clickImport': {
      return {
        ...previousState,
        progressInPercentage: 0,
        isPreviewOpen: false,
      }
    }
    case 'uploadCompleted':
    case 'errorUpload':
    case 'closeConfirmationPreview':
      return { ...csvCustomerInitialState }
    case 'cancelPreview': {
      return { ...previousState, isConfirmationPreviewOpen: true }
    }
    case 'cancelConfirmationPreview': {
      return { ...previousState, isConfirmationPreviewOpen: false }
    }
    default: {
      return previousState
    }
  }
}
