import { NLGAction } from '@phrasee/phrasee-typings'

import LoadingOverlay from 'common/components/LoadingOverlay'

import { useContent } from '../hooks'

const getGeneratePhrases = (templatesCount: number) => {
  let number1, number2
  if (templatesCount <= 2) {
    number1 = 500
    number2 = 100
  } else if (templatesCount <= 5) {
    number1 = 600
    number2 = 200
  } else if (templatesCount <= 8) {
    number1 = 1000
    number2 = 400
  } else {
    number1 = 1500
    number2 = 800
  }

  return [
    `Generating ${number1}+ unique variants…`,
    'Curating variants to match your brand voice…',
    `Discarding ${number2}+ non-compliant variants…`,
    'Running Performance Prediction Engine…',
    'Selecting high-performing variants…',
    'Selecting variants for reverification…',
    'Selecting variants to maximize new data points…',
  ]
}
const briefPhrases = ['Creating optimized brief...']
const topicsPhrases = ['Extracting topics and entities…']

type Props = {
  isLoading: boolean
  action?: NLGAction
}

const ContentLoadingOverlay = ({ isLoading, action }: Props) => {
  const { content } = useContent()

  let phrases: string[] = []
  switch (action) {
    case 'brief':
      phrases = briefPhrases
      break
    case 'topics':
      phrases = topicsPhrases
      break
    default:
      phrases = getGeneratePhrases(content?.elements.length || 0)
      break
  }

  return (
    <LoadingOverlay isLoading={isLoading} phrases={phrases} className="z-100" />
  )
}

export default ContentLoadingOverlay
