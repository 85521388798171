import { useCallback, useMemo, useState } from 'react'

import Badge from 'common/components/Badge'
import Button from 'common/components/button'
import Catalog from 'common/components/catalog/Catalog'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import Table, { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import { errorToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'
import { formatDate } from 'common/helpers/date'
import { useAppSelector } from 'common/hooks/redux'
import {
  ChevronDown as ChevronDownIcon,
  ChevronRight as ChevronRightIcon,
  Spinner as SpinnerIcon,
} from 'common/icons'

import useGetProductCatalogQuery, {
  ProductCatalog,
} from '../api/queries/useGetProductCatalogQuery'
import useDataIngestionWebsocketStatus from '../useDataIngestionWebsocketStatus'

type Props = {
  baseUrl: string
}

const ProductCatalogTable = ({ baseUrl }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const productFileState = useAppSelector(
    (state) => state.dataSource.fileStates.product
  )
  const fileName = useAppSelector(
    (state) => state.dataSource.fileStates.product.fileName
  )
  const isUploading = useAppSelector(
    (state) => state.dataSource.fileStates.product.isUploading
  )

  const productCatalogQuery = useGetProductCatalogQuery({
    baseUrl,
  })
  const productCatalog = productCatalogQuery.data
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false)

  const [processingState, setProcessingState] =
    useState<undefined | 'success' | 'failed' | 'processing'>(undefined)

  useDataIngestionWebsocketStatus({
    topic: 'product',
    onSuccess: () => {
      setProcessingState('success')
      productCatalogQuery.refetch()
      setIsCollapsed(false)
    },
  })

  const generateStatusColumn = useCallback((): Column<ProductCatalog> => {
    return {
      Header: 'Status',
      accessor: 'status',
      align: 'right',
      maxWidth: 200,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <BaseCell>
            <Badge
              text={
                processingState === 'failed'
                  ? 'Import failed'
                  : 'Importing file...'
              }
              variant={processingState === 'failed' ? 'danger' : 'info'}
            />
          </BaseCell>
        )
      },
    }
  }, [processingState])

  const columns: Column<ProductCatalog>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        width: 600,
        minWidth: 200,
        Cell: ({ row }) => {
          return (
            <BaseCell className="bg-coolGray-50">
              <Button
                disabled={isUploading || processingState === 'failed'}
                className="hover:text-maroon-300 focus:outline-none focus-visible:outline-black group -ml-4 max-w-min"
                onClick={() => setIsCollapsed(!isCollapsed)}
                prefixIcon={
                  isUploading || isCollapsed || processingState === 'failed' ? (
                    <ChevronRightIcon size={4} />
                  ) : (
                    <ChevronDownIcon isDefaultColor={false} size={4} />
                  )
                }
              >
                <span className="overflow-hidden truncate">
                  {isUploading ? fileName : row.original.name}
                </span>
              </Button>
            </BaseCell>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Type',
        accessor: 'type',
        width: 80,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            !isUploading && (
              <BaseCell className="uppercase">{row.original.type}</BaseCell>
            )
          )
        },
      },
      {
        Header: 'Created',
        accessor: 'created',
        width: 80,
        minWidth: 110,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            !isUploading && (
              <BaseCell>{formatDate(row.original.created)}</BaseCell>
            )
          )
        },
      },
      ...(isUploading || processingState === 'failed'
        ? [generateStatusColumn()]
        : []),
      {
        Header: 'Actions',
        width: 80,
        minWidth: 110,
        disableSortBy: true,
        ...(isUploading
          ? {
              accessor: 'id',
              align: 'right',
              Cell: (row) => {
                return (
                  <BaseCell>
                    <Tooltip overlay={`Processing file ${fileName}`}>
                      <SpinnerIcon className="animate-spin" />
                    </Tooltip>
                  </BaseCell>
                )
              },
            }
          : {
              ...generateDeleteButtonColumn<ProductCatalog>({
                onClick: (row) => errorToast('Not implemented yet'),
                buttonLabel: 'Delete',
                hasTooltip: true,
                isVisibleOnlyOnHover: false,
                isDefaultColor: false,
              }),
            }),
      },
    ],
    [isCollapsed, fileName, isUploading, processingState, generateStatusColumn]
  )

  const determineStatus = () => {
    if (isUploading || processingState === 'processing') {
      return 'Importing file...'
    }

    switch (processingState) {
      case 'failed':
        return 'Import failed'
      case 'success':
        return 'Import successful'
      default:
        return 'Ready for import'
    }
  }

  const dataWithStatus = {
    ...(productCatalog?.name?.length ? productCatalog : productFileState),
    status: determineStatus(),
  }

  if (!productCatalog) {
    return null
  }
  return (
    <div className="sm:-ml-6 sm:-mr-6">
      <Table
        columns={columns}
        data={[dataWithStatus]}
        className="mt-6"
        rowClassName="bg-coolGray-50 "
      />
      {!isUploading && !isCollapsed && processingState !== 'processing' && (
        <Catalog items={productCatalog.categories} />
      )}
      <ConfirmationModal
        title="Delete this file?"
        confirmationText="Are you sure you want to delete this file?"
        confirmButtonText="Delete file"
        open={isConfirmDeleteOpen}
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onConfirm={() => {
          setIsConfirmDeleteOpen(false)
          errorToast('Not implemented yet')
        }}
      />
    </div>
  )
}

export default ProductCatalogTable
