import { FC } from 'react'
import { Redirect, Switch } from 'react-router-dom'

const CustomSwitch: FC = ({ children }) => {
  return (
    <Switch>
      {children}
      <Redirect to="/404" />
    </Switch>
  )
}

export default CustomSwitch
