import { ComponentProps } from 'react'

import Scrollable from 'common/components/scrollable'

type Props = Pick<ComponentProps<typeof Scrollable>, 'content' | 'footer'>

const TabContent = ({ content, footer }: Props) => (
  <div className="overflow-y-auto h-full">
    <Scrollable
      contentClassName="py-8 px-6 w-full flex items-center"
      content={
        <div className="m-auto flex-1" style={{ maxWidth: 1300 }}>
          {content}
        </div>
      }
      footer={footer}
    />
  </div>
)

export default TabContent
