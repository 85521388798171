import React, { Component } from 'react'

import Button from 'common/components/button'
import Modal from 'common/components/Modal'

interface PropsPassedDown {
  cancelModal: () => void
  onKeepExisting: () => void
  onGenerateNew: () => void
  visible?: boolean
  description: string
}

class RegenerateConfirmationModal extends Component<PropsPassedDown> {
  render() {
    const { visible, cancelModal, onKeepExisting, onGenerateNew, description } =
      this.props
    return (
      <Modal
        title="Are you sure?"
        className="regenerate-confirmation-modal"
        visible={visible}
        onClose={cancelModal}
        footer={null}
        style={{
          width: 520,
        }}
        wrapStyle={{ margin: 80 }}
      >
        <div>
          <div>
            <p>{description}</p>

            <div className="flex justify-end mt-4">
              <Button
                className="mr-4"
                data-cy="keep-existing"
                ghost
                onClick={onKeepExisting}
              >
                Cancel
              </Button>

              <Button
                data-cy="generate-new"
                variant="primary"
                onClick={onGenerateNew}
              >
                Generate new
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default RegenerateConfirmationModal
