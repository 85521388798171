import { memo, useMemo } from 'react'
import * as Highcharts from 'highcharts/highstock'
import highchartsTreemap from 'highcharts/modules/treemap'

import HighchartsReact from 'common/components/AutoResizeChart'
import TabWidget from 'common/components/TabWidget'
import { formatNumber } from 'common/helpers/numeric'

import type { CategoryPerformance, CategoryPerformanceStatus } from './api'

highchartsTreemap(Highcharts)

const ORANGE = '#901356'
const GREEN = '#16A34A'
const RED = '#EF4444'
const GRAY = '#9CA3AF'

type Props = {
  selectedTab: 'opens' | 'clicks'
  onTabClick: (name: 'opens' | 'clicks') => void
  data: CategoryPerformance[] | undefined
  isLoading: boolean
}
const mapQualityToColor: Record<CategoryPerformanceStatus, string> = {
  win: GREEN,
  insufficientData: GRAY,
  draw: ORANGE,
  lose: RED,
}

type ChartItem = {
  name: string
  value: number
  originalValue: number
  color: string
}

const mapDataToChart = (
  categoryPerformance: CategoryPerformance,
  selectedTab: Props['selectedTab']
): ChartItem => ({
  name: categoryPerformance.category.join(' / '),
  value: categoryPerformance.personalized_sends,
  originalValue:
    selectedTab === 'clicks'
      ? categoryPerformance.click_uplift
      : categoryPerformance.open_uplift,
  color: mapQualityToColor[categoryPerformance.status],
})

const CategoryPerformanceTreeMap = ({
  data,
  selectedTab,
  onTabClick,
  isLoading,
}: Props) => {
  const firstTen = useMemo(
    () =>
      data
        ?.sort((a, b) => b.personalized_sends - a.personalized_sends)
        .slice(0, 10)
        ?.map((obj) => mapDataToChart(obj, selectedTab))
        .sort((a, b) => b.value - a.value) ?? [],
    [data, selectedTab]
  )

  const treeMapData = firstTen.map((obj) => ({
    ...obj,
    value: obj.value,
  }))

  const options: Highcharts.Options = useMemo(
    () => ({
      title: undefined,
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      chart: {
        style: {
          fontFamily: 'Outfit',
        },
      },
      tooltip: {
        valueSuffix: '%',
        formatter: function () {
          return `${this.point.name}:
           ${parseFloat(
             formatNumber((this.point as any).originalValue, {
               maximumFractionDigits: 2,
             })
           )}%`
        },
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          borderWidth: 4,
          borderColor: 'white',
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<div style="padding: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${this.point.name}</div>`
            },
            style: {
              color: 'white',
              fontWeight: '700',
              fontSize: '12px',
            },
            useHTML: true,
          },
          states: {
            hover: {
              borderColor: 'white',
            },
          },
          data: treeMapData,
        },
      ],
      lang: {
        noData: '',
      },
    }),
    [treeMapData]
  )
  const tabs = useMemo(
    () =>
      [
        {
          name: 'Opens',
        },
        {
          name: 'Clicks',
        },
      ].map((value) => ({
        ...value,
        content:
          !isLoading && firstTen.every((item) => item.value === 0) ? (
            <div className=" h-100 flex justify-center items-center text-coolGray-500 font-bold text-base">
              No Results
            </div>
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              constructorType="chart"
              options={options}
              immutable={true}
            />
          ),
      })),
    [firstTen, isLoading, options]
  )
  return (
    <TabWidget
      activeTabKey={selectedTab === 'opens' ? '0' : '1'}
      onTabClick={(key) => onTabClick(tabs[key].name.toLowerCase())}
      tabs={tabs}
    />
  )
}

export default memo(CategoryPerformanceTreeMap)
