import cx from 'classnames'

import Button from 'common/components/button/Button'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as Eye } from 'common/icons/eye/default.svg'
import { ReactComponent as EyeNo } from 'common/icons/eyeNo/default.svg'
import {
  hideRightPane,
  showRightPane,
} from 'features/futurama/store/contentSlice'

import { getContentHasVariants } from '../../helpers'
import { useContent } from '../../hooks'

import ExportMenuButton from './headerActionButtons/ExportMenuButton'

const HeaderActions = () => {
  const { content } = useContent()
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const dispatch = useAppDispatch()

  const rightPaneVisibility = useAppSelector(
    (state) => state.content.rightPaneVisibility
  )

  return (
    <div className="flex items-center">
      {rightPaneVisibility !== 'none' && (
        <>
          <Button
            className={cx('text-yellow-200 hover:text-white', {
              hidden: rightPaneVisibility === 'hidden',
              'hidden lg:block': rightPaneVisibility === 'auto',
            })}
            prefixIcon={
              <Tooltip overlay="Hide panel">
                <EyeNo width={24} height={24} />
              </Tooltip>
            }
            onClick={() => dispatch(hideRightPane())}
          />
          <Button
            className={cx('text-white hover:text-yellow-200', {
              hidden: rightPaneVisibility === 'visible',
              'lg:hidden': rightPaneVisibility === 'auto',
            })}
            prefixIcon={
              <Tooltip overlay="Show panel">
                <Eye width={24} height={24} />
              </Tooltip>
            }
            onClick={() => dispatch(showRightPane())}
          />
        </>
      )}
      {selectedElementId && getContentHasVariants(content) && (
        <ExportMenuButton />
      )}
    </div>
  )
}

export default HeaderActions
