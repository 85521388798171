import { useEffect, useState } from 'react'
import { DistributionType } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { showBanner } from 'workflow/common/common.actions'

import { createCancelTokenSource, isCancel } from 'common/api'
import MultiSelect, { MultiSelectValue } from 'common/components/MultiSelect'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  ResponseDistributionChannel,
  ResponseDistributionType,
} from 'common/interfaces/campaign'

import { ChannelOption, fetchProjects as fetchProjectsQuery } from '../../api'

export const projectsSelectText = {
  label: 'Projects',
}

interface Project {
  id: string
  name: string
  distributionChannel: ResponseDistributionChannel
  distributionType?: ResponseDistributionType
  testProject?: boolean
}

interface Props {
  isDisabled: boolean
  onApply: (val: string[]) => void
  selectedProduct: DistributionType | undefined
  selectedChannel: ChannelOption | undefined
  selectedItems: string[]
  className?: string
}

const ProjectsSelect = ({
  selectedChannel,
  selectedProduct,
  ...rest
}: Props) => {
  const dispatch = useAppDispatch()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [projects, setProjects] = useState<Project[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const source = createCancelTokenSource()

    const fetchProjects = async () => {
      setIsLoading(true)

      fetchProjectsQuery(accountId)
        .then((res) => {
          const formattedProjects = res.map(
            ({ _id, name, project_configuration, deleted }) => ({
              id: _id,
              name: deleted ? `${name} (deactivated)` : name,
              distributionChannel:
                project_configuration?.campaign_configurations
                  ?.distribution_channel ?? 'email',
              distributionType:
                project_configuration?.campaign_configurations
                  ?.distribution_type,
              testProject: project_configuration?.test_project,
            })
          )

          setProjects(formattedProjects)
        })
        .catch((err) => {
          if (!isCancel(err)) {
            dispatch(
              showBanner({
                type: 'error',
                content: 'Failed to load project options',
              })
            )
          }
        })
        .finally(() => setIsLoading(false))
    }

    fetchProjects()

    return () => source.cancel()
  }, [dispatch, accountId])

  const filteredOptions = projects
    ?.filter(({ distributionType, distributionChannel, testProject }) => {
      return (
        !testProject &&
        (distributionType && selectedProduct
          ? selectedProduct === distributionType
          : true) &&
        (distributionChannel && selectedChannel
          ? selectedChannel?.id === distributionChannel
          : true)
      )
    })
    .map(({ id, name }) => {
      return { id, value: id, label: name }
    })

  return (
    <MultiSelect<MultiSelectValue>
      name="projects"
      placeholder="No projects"
      label={projectsSelectText.label}
      items={filteredOptions ?? []}
      isLoading={isLoading}
      maxOptions={4}
      {...rest}
    />
  )
}

export default ProjectsSelect
