import cx from 'classnames'

type Props = {
  textLines: string[]
  caption?: string
  className?: string
}

const InfoMessage = ({
  textLines,
  className,
  caption = 'You can add a topic by clicking over here.',
}: Props) => {
  return (
    <div
      className={cx(
        'flex flex-col justify-center items-center h-32',
        className
      )}
    >
      {textLines.map((text, index) => (
        <div key={index} className="text-xl text-coolGray-500 font-medium">
          {text}
        </div>
      ))}

      <div className="text-coolGray-400 mt-1">{caption}</div>
    </div>
  )
}

export default InfoMessage
