import BaseCell from './Base'

type Props = {
  src?: string
  alt?: string
  height?: number
  width?: number
}

const ImageCell = ({ src, alt, height, width }: Props) => {
  return (
    <BaseCell>
      <div className="flex justify-center items-center w-22 h-16">
        {src ? (
          <img src={src} alt={alt} className="max-w-full max-h-full" />
        ) : null}
      </div>
    </BaseCell>
  )
}

export default ImageCell
