import cx from 'classnames'

type Props = {
  name: string | undefined
  hasError: boolean
  isReplacer: boolean
}

const TopicCardName = ({ name, hasError, isReplacer }: Props) => {
  const parsedName = isReplacer && name ? name.replace(';', ', ') : name

  return (
    <div
      className={cx(
        'text-base font-medium overflow-ellipsis overflow-hidden whitespace-nowrap w-44',
        {
          'text-red-600': hasError,
          'text-coolGray-800': !hasError,
        }
      )}
    >
      {parsedName}
    </div>
  )
}

export default TopicCardName
