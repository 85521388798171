import React, {
  memo,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Row } from 'react-table'
import { TableState, useAsyncDebounce } from 'react-table'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'

import Autocomplete from 'common/components/autcomplete'
import Button, { ButtonProps } from 'common/components/button'
import FullScreen from 'common/components/FullScreen'
import TableLoader from 'common/components/loaders/TableLoader'
import Error from 'common/components/table/Error'
import Table, {
  OnCellChange,
  TableHandle,
  TableProps,
} from 'common/components/table/Table'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { WidgetFirstUse } from 'common/components/WidgetMessage'

import TableWidgetMenu from '../menu/TableWidgetMenu'

export type TableWidgetProps<T extends object> = Omit<
  TableProps<T>,
  'data' | 'title'
> & {
  data?: readonly T[]
  isLoading?: boolean
  hasError?: boolean
  fileName?: string
  firstUseText?: string
  initialState?: Partial<TableState<Object>>
  onSelectRow?: (rowIds: T[]) => void
  onRowClick?: (rowId: T) => void
  onCellChange?: OnCellChange<T>
  selectedRowsActionButtons?: ReactNode
  children?: ReactNode
  rowSelection?: 'single' | 'multiple' | 'disabled'
  selectionHeader?: string
  disableSubRowArrow?: boolean
  paginateExpandedRows?: boolean
  generateRowClassName?: <T extends object>({
    row,
    rows,
  }: {
    row: Row<T>
    rows: Row<T>[]
  }) => string
}

const TableWidgetContext = React.createContext<{
  tableHandle: TableHandle<any> | undefined
  allValues: string[]
  onFilterValueChange: (value: string) => void
  onFullscreenClick: () => void
  selectedRowsActionButtons?: ReactNode
}>({
  tableHandle: undefined,
  allValues: [],
  onFilterValueChange: noop,
  onFullscreenClick: noop,
})

const TableWidget = <T extends object>({
  className,
  isLoading = false,
  hasError = false,
  data,
  columns,
  disablePagination,
  fileName,
  firstUseText,
  initialState,
  onSelectRow,
  onSwapColumns,
  onRowClick,
  onCellChange,
  selectedRowsActionButtons,
  autoResetPage = true,
  isHeaderHidden,
  rowSelection = 'disabled',
  selectionHeader,
  children,
  'data-cy': dataCy,
  globalFilter,
  disableSubRowArrow,
  generateRowClassName,
  paginateExpandedRows,
}: TableWidgetProps<T>) => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (hasError) {
      return 'error'
    } else if (data && isFullscreen) {
      return 'fullScreenTable'
    } else if (data && data.length === 0) {
      return 'noData'
    } else if (data) {
      return 'table'
    } else {
      return 'default'
    }
  }

  const state = getState()

  const [tableHandle, setTableHandle] = useState<TableHandle<T>>()

  const ref: React.RefCallback<TableHandle<any>> = useCallback((node) => {
    if (node !== null) {
      setTableHandle(node)
    }
  }, [])

  const onChange = useAsyncDebounce((value: string) => {
    setGlobalFilterValue(value)
  }, 200)

  const [allValues, setAllValues] = useState<string[]>([])
  const handleAllFilterValues = useCallback(
    (values: string[]) => {
      if (!isEqual(allValues, values)) {
        setAllValues(values)
      }
    },
    [allValues]
  )
  const onFullscreenClick = useCallback(() => {
    setIsFullscreen(true)
  }, [])
  const context = useMemo(
    () => ({
      tableHandle,
      allValues,
      onFilterValueChange: onChange,
      onFullscreenClick,
      selectedRowsActionButtons: selectedRowsActionButtons,
    }),
    [
      allValues,
      onChange,
      onFullscreenClick,
      selectedRowsActionButtons,
      tableHandle,
    ]
  )

  let actionButtons: ReactNode = null
  const filteredChildren = React.Children.toArray(children).filter((child) => {
    if (React.isValidElement(child) && child.type === ActionButtons) {
      actionButtons = child
      return false
    }
    return true
  })

  return (
    <Widget
      data-cy={dataCy}
      className={cx(className, { 'border-red-500': state === 'error' })}
      type="table"
    >
      <TableWidgetContext.Provider value={context}>
        {filteredChildren}
        {
          {
            loading: <TableLoader />,
            error: <Error />,
            fullScreenTable: (
              <FullScreen onClose={() => setIsFullscreen(false)}>
                {selectedRowsActionButtons ? (
                  <div className="text-coolGray-800 font-medium text-xl mb-4 ml-6 capitalize-first absolute top-6">
                    {selectedRowsActionButtons}
                  </div>
                ) : undefined}
                <Table
                  columns={columns}
                  data={data!}
                  disablePagination={disablePagination}
                  initialState={initialState}
                  onSelectRow={onSelectRow}
                  onRowClick={onRowClick}
                  onCellChange={onCellChange}
                  autoResetPage={autoResetPage}
                  onSwapColumns={onSwapColumns}
                  globalFilter={globalFilter}
                  globalFilterValue={globalFilterValue}
                  updateAllFilterValues={handleAllFilterValues}
                  isHeaderHidden={isHeaderHidden}
                  rowSelection={rowSelection}
                  disableSubRowArrow={disableSubRowArrow}
                  generateRowClassName={generateRowClassName}
                  paginateExpandedRows={paginateExpandedRows}
                />
              </FullScreen>
            ),
            table: (
              <div className={cx({ 'pb-4': disablePagination })}>
                <Table
                  ref={ref}
                  columns={columns}
                  data={data!}
                  disablePagination={disablePagination}
                  fileName={fileName}
                  initialState={initialState}
                  onSelectRow={onSelectRow}
                  onRowClick={onRowClick}
                  onCellChange={onCellChange}
                  autoResetPage={autoResetPage}
                  onSwapColumns={onSwapColumns}
                  globalFilter={globalFilter}
                  globalFilterValue={globalFilterValue}
                  updateAllFilterValues={handleAllFilterValues}
                  isHeaderHidden={isHeaderHidden}
                  rowSelection={rowSelection}
                  selectionHeader={selectionHeader}
                  disableSubRowArrow={disableSubRowArrow}
                  generateRowClassName={generateRowClassName}
                  paginateExpandedRows={paginateExpandedRows}
                />
              </div>
            ),
            noData: <WidgetFirstUse className="m-6 mt-0" text={firstUseText} />,
            default: null,
          }[state]
        }
        {actionButtons}
      </TableWidgetContext.Provider>
    </Widget>
  )
}

const TableWidgetMemo = memo(TableWidget) as typeof TableWidget

const TableWidgetHeader = ({
  title,
  subtitle,
  titleClassName,
  children,
}: {
  title?: ReactNode
  titleClassName?: string
  subtitle?: string
  children?: ReactNode
}) => {
  const { selectedRowsActionButtons } = useContext(TableWidgetContext)
  return (
    <WidgetHeader
      className={cx('pt-6 px-6', titleClassName)}
      title={selectedRowsActionButtons ? selectedRowsActionButtons : title}
      subtitle={subtitle}
      actions={<div className="inline-flex">{children}</div>}
    />
  )
}

const Filter = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const { allValues, onFilterValueChange } = useContext(TableWidgetContext)

  const onSearch = (search: string) => {
    setSearchValue(search)
    onFilterValueChange(search)
  }
  return (
    <Autocomplete
      className="w-60"
      placeholder="Filter"
      data-cy="table-widget-search"
      options={[...new Set(allValues)].filter(String).map((option) => ({
        label: option,
        value: option,
      }))}
      value={
        searchValue ? { value: searchValue, label: searchValue } : undefined
      }
      defaultInputValue={searchValue}
      onSearch={onSearch}
      backspaceRemovesValue={true}
    />
  )
}

export function ButtonWithResetPaging({ onClick, ...others }: ButtonProps) {
  const { tableHandle } = useContext(TableWidgetContext)
  return (
    <Button
      {...others}
      onClick={(values) => {
        tableHandle?.resetPaging()
        onClick?.(values)
      }}
    />
  )
}

const Menu = ({ hasCsvExport }: { hasCsvExport?: boolean }) => {
  const { tableHandle, onFullscreenClick } = useContext(TableWidgetContext)
  return (
    <TableWidgetMenu
      onFullscreenClick={onFullscreenClick}
      exportCsv={
        hasCsvExport
          ? () => hasCsvExport && tableHandle?.exportCsv()
          : undefined
      }
      columns={tableHandle?.allColumns()}
    />
  )
}

const ActionButtons = ({ children }: { children: ReactNode }) => {
  return <div className="flex self-end p-6">{children}</div>
}

const TableWidgetComponents = {
  Widget: TableWidgetMemo,
  Header: TableWidgetHeader,
  Filter,
  Menu,
  ActionButtons,
}

export default TableWidgetComponents
