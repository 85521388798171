import Button from 'common/components/button'

type Props = {
  projectName: string | undefined
  campaignName: string
  onClickNext?: () => void
  onClose: () => void
  isLoading?: boolean
  nextButtonName?: string
  isNextButtonLoading?: boolean
  isNextButtonDisabled?: boolean
  errorsCount?: number
  isCancelDisabled?: boolean
}
const Footer = ({
  onClose,
  projectName,
  campaignName,
  onClickNext,
  nextButtonName = 'Next',
  isNextButtonLoading: isLoading,
  isNextButtonDisabled: isDisabled,
  errorsCount = 0,
  isCancelDisabled,
}: Props) => {
  return (
    <div className="flex items-stretch justify-between bg-white mt-4 mx-6">
      <Button
        variant="danger"
        ghost
        onClick={() => onClose()}
        disabled={isCancelDisabled}
      >
        Cancel
      </Button>
      <div className="flex items-center text-coolGray-800 font-medium">
        {projectName ? `${projectName} / ` : ''} {campaignName}
      </div>
      <div>
        {errorsCount > 0 && (
          <span className="mr-4 text-red-400 font-medium text-base">
            {errorsCount === 1
              ? `1 Mismatch, please fix 🤯`
              : `${errorsCount} Mismatches, please fix 🤯`}
          </span>
        )}
        {onClickNext && (
          <Button
            variant="primary"
            disabled={isDisabled}
            loading={isLoading}
            onClick={onClickNext}
          >
            {isLoading ? 'Processing...' : nextButtonName}
          </Button>
        )}
      </div>
    </div>
  )
}

export default Footer
