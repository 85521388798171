import {
  forwardRef,
  MutableRefObject,
  Ref,
  RefCallback,
  RefObject,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

import styles from './AutoResizeChart.module.css'

export type ChartInstance = {
  chart: Highcharts.Chart
  container: RefObject<HTMLDivElement>
}

function isRefCallback<T>(ref: Ref<T>): ref is RefCallback<T> {
  return typeof ref === 'function'
}

function isRefObject<T>(ref: Ref<T>): ref is RefObject<T> {
  return typeof ref === 'object'
}

const AutoResizeChart = forwardRef<ChartInstance, HighchartsReact.Props>(
  (props, forwardedRef) => {
    const localRef: MutableRefObject<ChartInstance | null> =
      useRef<ChartInstance>(null)

    const isForwardedRefCallback = isRefCallback(forwardedRef)

    // In case the parent uses a callbackRef, the function bellow will
    // wrap to be able to set the instance of the chart locally
    const localChartCallbackRef = useCallback(
      (node) => {
        if (node !== null) {
          if (isRefCallback(forwardedRef)) {
            forwardedRef(node)
          }
          localRef.current = node
        }
      },
      [forwardedRef]
    )
    const chartRef = isForwardedRefCallback
      ? localChartCallbackRef
      : forwardedRef ?? localRef

    useLayoutEffect(() => {
      const observer = new ResizeObserver(() => {
        if (isForwardedRefCallback && localRef?.current) {
          localRef.current.chart.reflow()
        } else if (isRefObject(chartRef) && chartRef?.current) {
          chartRef.current.chart.reflow()
        }
      })
      const container = isForwardedRefCallback
        ? localRef.current?.container?.current
        : isRefObject(chartRef)
        ? chartRef.current?.container?.current
        : undefined

      if (container) {
        observer.observe(container)
      }
      return () => {
        if (container) {
          observer.unobserve(container)
        }
      }
    }, [chartRef, forwardedRef, isForwardedRefCallback])
    return (
      <div className={styles.container}>
        <HighchartsReact {...props} ref={chartRef} />
      </div>
    )
  }
)

export type HighchartsReactProps = HighchartsReact.Props
export default AutoResizeChart
