export const graphColors = {
  primary: [
    '#A3E635',
    '#4ADE80',
    '#34D399',
    '#2DD4BF',
    '#22D3EE',
    '#38BDF8',
    '#60A5FA',
    '#818CF8',
    '#A78BFA',
    '#C084FC',
    '#E879F9',
    '#F472B6',
    '#FB7185',
    '#F87171',
    '#FBBF24',
    '#FACC15',
    '#94A3B8',
    '#A1A1AA',
  ],
  control: '#F97316',
  secondary: [
    '#65A30D',
    '#16A34A',
    '#059669',
    '#0D9488',
    '#0891B2',
    '#0284C7',
    '#2563EB',
    '#4F46E5',
    '#7C3AED',
    '#9333EA',
    '#C026D3',
    '#DB2777',
    '#E11D48',
    '#DC2626',
    '#D97706',
    '#CA8A04',
    '#475569',
    '#52525B',
  ],
  tertiary: [
    '#D9F99D',
    '#BBF7D0',
    '#A7F3D0',
    '#99F6E4',
    '#A5F3FC',
    '#BAE6FD',
    '#BFDBFE',
    '#C7D2FE',
    '#DDD6FE',
    '#E9D5FF',
    '#F5D0FE',
    '#FBCFE8',
    '#FECDD3',
    '#FECACA',
    '#FDE68A',
    '#FEF08A',
    '#E2E8F0',
    '#E4E4E7',
  ],
  highContrast: [
    '#2DD4BF', // teal 400
    '#901356', // orange 400
    '#60A5FA', // blue 400
    '#A78BFA', // violet 400
    '#F87171', // red 400
    '#F472B6', // pink 400
    '#A3E635', // lime 400
    '#1E40AF', // blue 800
    '#FACC15', // yellow 400
    '#16A34A', // green 600
  ],
} as const

export function getColorForIndex({
  index,
  highContrast = false,
}: {
  index: number
  highContrast?: boolean
}): string {
  const combinedPalette = [
    ...graphColors.primary,
    ...graphColors.secondary,
    ...graphColors.tertiary,
  ]

  if (highContrast) {
    const highContrastColorsCount = graphColors.highContrast.length
    if (index < highContrastColorsCount) {
      return graphColors.highContrast[index]
    } else {
      return combinedPalette[index - highContrastColorsCount]
    }
  } else {
    return combinedPalette[index]
  }
}

export default graphColors
