import React from 'react'
import cx from 'classnames'

import styles from './Spinner.module.css'

export type SpinnerProps = {
  className?: string
  isSpinning?: boolean
  isFullPage?: boolean
  hasWrapperFullHeight?: boolean
  color?: string
  size?: 'small' | 'default'
  'data-testid'?: string
  'data-cy'?: string
}

const Spinner: React.FC<SpinnerProps> = ({
  className,
  isSpinning = true,
  hasWrapperFullHeight = true, // TODO: Remove when we use tailwind 3
  isFullPage,
  children,
  'data-testid': dataTestId = 'loader',
  'data-cy': dataCy = 'loader',
  size = 'default',
  color = '#901356',
}) => {
  const isSmall = size === 'small'

  const circles = [...Array(12)].map((_, index) => {
    return <div key={index} style={{ background: color }}></div>
  })
  const hasChildren = React.Children.count(children) > 0

  if (!hasChildren) {
    return isSpinning ? (
      <div
        className={cx('h-full flex items-center justify-center', className, {
          'h-screen': isFullPage,
        })}
      >
        <div
          className={cx(styles.spinner, {
            [styles['spinner-default']]: !isSmall,
            [styles['spinner-small']]: isSmall,
          })}
          data-testid={dataTestId}
          data-cy={dataCy}
        >
          {circles}
        </div>
      </div>
    ) : null
  }

  return (
    <div className={cx('relative', { 'h-full': hasWrapperFullHeight })}>
      {isSpinning && (
        <div
          className={cx(
            styles.spinner,
            'absolute top-1/2 transform -translate-y-1/2 ml-auto mr-auto left-0 right-0',
            {
              [styles['spinner-default']]: !isSmall,
              [styles['spinner-small']]: isSmall,
            }
          )}
          data-testid={dataTestId}
          data-cy={dataCy}
        >
          {circles}
        </div>
      )}
      <div
        className={cx('transition-opacity h-full', {
          'opacity-50 pointer-events-none select-none': isSpinning,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Spinner
