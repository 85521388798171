import { useState } from 'react'

import Button from 'common/components/button'
import { TreeNode } from 'common/components/catalog/Catalog'
import Spinner from 'common/components/spinner'
import WidgetHeader from 'common/components/WidgetHeader'
import Icon from 'common/icons/Icon'
import { ReactComponent as TableIcon } from 'common/icons/table/default.svg'
import { ReactComponent as TreeChartIcon } from 'common/icons/treeChart/default.svg'
import type { CustomerAttributes } from 'features/personalization/api'

import useGetCategoryPerformanceQuery from './api/queries/useGetCategoryPerformanceQuery'
import CategoryPerformanceTable from './CategoryPerformanceTable'
import CategoryPerformanceTreeMap from './CategoryPerformanceTreeMap'

type Props = {
  personalizationId: string
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}

const CategoryPerformance = ({
  personalizationId,
  customerAttributes,
  productCategories,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<'opens' | 'clicks'>('opens')
  const [viewMode, setViewMode] = useState<'treeMap' | 'table'>('treeMap')
  const personalizationPerformanceQuery = useGetCategoryPerformanceQuery({
    personalizationId,
    metric: selectedTab,
    customerAttributes,
    productCategories,
  })
  if (personalizationPerformanceQuery.data?.data.length === 0) {
    return null
  }

  return (
    <div>
      <Spinner isSpinning={personalizationPerformanceQuery.isLoading}>
        <WidgetHeader
          title="Category Performance"
          subtitle="Total lines for this time period, plotted against product categories."
          actions={
            <div>
              <Button onClick={() => setViewMode('treeMap')}>
                <Icon
                  defaultSvg={TreeChartIcon}
                  state={viewMode === 'treeMap' ? 'selected' : 'default'}
                />
              </Button>
              <Button onClick={() => setViewMode('table')}>
                <Icon
                  defaultSvg={TableIcon}
                  state={viewMode === 'table' ? 'selected' : 'default'}
                />
              </Button>
            </div>
          }
        />
        {viewMode === 'treeMap' ? (
          <CategoryPerformanceTreeMap
            onTabClick={setSelectedTab}
            selectedTab={selectedTab}
            data={personalizationPerformanceQuery.data?.data}
            isLoading={personalizationPerformanceQuery.isLoading}
          />
        ) : (
          <CategoryPerformanceTable
            data={personalizationPerformanceQuery.data?.data ?? []}
          />
        )}
      </Spinner>
    </div>
  )
}

export default CategoryPerformance
