import NumericInput from 'common/components/numericInput'

interface Props {
  label: string
  'data-testid': string
  inputValue: number
  onInputChange: (val: number | undefined) => void
  minValue: number
  maxValue: number
  defaultValue: number
  disabled: boolean
}

export const NumericInputWithSideLabel = ({
  label,
  'data-testid': dataTestId,
  inputValue,
  onInputChange,
  minValue,
  maxValue,
  defaultValue,
  disabled,
}: Props) => {
  return (
    <div className="mb-6 flex items-baseline">
      <div>
        <NumericInput
          precision={0}
          className="w-14"
          data-testid={dataTestId}
          value={inputValue}
          onChange={(value) => {
            onInputChange(value)
          }}
          min={minValue}
          max={maxValue}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
      <label className="font-medium text-coolGray-800 ml-3">{label}</label>
    </div>
  )
}
