import { useEffect, useRef, useState } from 'react'
import { ControlledMenu, MenuItem as MenuItemRM } from '@szhsin/react-menu'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { TextEditableCellProps } from 'common/components/table/cells/TextEditable'
import { Replacer, Topic } from 'common/components/topics/interfaces'

export const validReplacerRegex = /(?<=\[)\w+(?=\])/g

type Props<T extends object> = TextEditableCellProps<T> & {
  replacers?: Topic[]
}

const PhraseCell = <T extends object>({
  value: initialValue,
  parsedValue,
  row: { index },
  column: { id, align },
  onCellChange, // This is a custom function that we supplied to our table instance
  hasAutoFocus,
  replacers,
}: Props<T>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string | undefined>(initialValue)
  const [dropDownFilterValue, setDropDownFilterValue] =
    useState<string | undefined>()
  const [dropDownOffset, setDropDownOffset] = useState<number>(0)
  const [hasFocus, setHasFocus] = useState(false)
  const [inputError, setInputError] = useState<string | undefined>()

  const onChange: (inputValue: string | undefined) => void = (inputValue) => {
    if (isEmpty(inputValue?.trim())) {
      setInputError('Please enter a phrase.')
    } else {
      setInputError(undefined)
    }

    setValue(inputValue)

    const replacerMatches = inputValue?.match(validReplacerRegex)

    if (replacers?.length) {
      replacerMatches?.forEach((match) => {
        const foundReplacer = replacers.find(
          (replacer) => (replacer.original as Replacer).replacer === match
        )
        if (!foundReplacer) {
          setInputError('Please enter a valid replacer.')
        }
      })
    } else if (!replacers && replacerMatches?.length) {
      setInputError('Unable to fetch replacers.')
    }

    const possibleReplacerRegex = /(?<=\[)\w+\b(?!\])/g
    const possilbeReplacerMatches = inputValue?.match(possibleReplacerRegex)

    if (possilbeReplacerMatches?.length) {
      if (!dropDownFilterValue) {
        setDropDownOffset(getDropDownOffsetX())
      }
      setDropDownFilterValue(
        possilbeReplacerMatches[possilbeReplacerMatches.length - 1]
      )
    } else {
      setDropDownFilterValue(undefined)
    }
  }

  const onBlur = () => {
    if (!id) {
      throw new Error('The column id is missing')
    }

    if (!inputError) {
      onCellChange({
        rowIndex: index,
        columnId: id,
        value,
      })
    } else {
      //i added onCellChange here because inside the funcion we have a condition to invalidate the query and delete the empty row if the we have no value
      //if we don't call the function here the empty row will not get deleted
      onCellChange({
        rowIndex: index,
        columnId: id,
        value: initialValue,
      })
      setValue(initialValue)
      setInputError(undefined)
    }
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const hasOverallFocus = hasFocus || hasAutoFocus

  const commonClassName =
    'w-full outline-none bg-transparent px-6 py-3 cursor-pointer border border-transparent'

  const filteredReplacers = replacers?.filter((replacer) =>
    dropDownFilterValue
      ? (replacer.original as Replacer).replacer
          .toLowerCase()
          .includes(dropDownFilterValue?.toLowerCase())
      : true
  )

  const getDropDownOffsetX = () => {
    const paddingX = 24
    const span = document.createElement('span')
    span.className = 'invisible fixed inline-block text-sm'
    const trimmedValue = value?.substring(
      0,
      inputRef.current?.selectionStart ?? 0
    )
    span.innerText =
      trimmedValue?.substring(0, trimmedValue?.lastIndexOf('[')) || ''

    document.body.appendChild(span)
    const offset = span.offsetWidth + paddingX
    document.body.removeChild(span)
    return offset
  }

  return (
    <div
      className="w-full"
      onClick={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
    >
      {hasOverallFocus ? (
        <>
          <input
            ref={inputRef}
            autoFocus={hasOverallFocus}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            onBlur={onBlur}
            className={cx(
              commonClassName,
              `block w-full focus-visible:border-maroon-500 focus:bg-maroon-50 caret-maroon-300`,
              {
                'text-right': align === 'right',
                'text-center': align === 'center',
                'h-full': !inputError,
              }
            )}
          />
          <ControlledMenu
            anchorRef={inputRef}
            state={dropDownFilterValue ? 'open' : 'closed'}
            portal
            captureFocus={false}
            align="start"
            offsetY={-8}
            offsetX={dropDownOffset}
            viewScroll="auto"
            position="anchor"
            overflow="auto"
            boundingBoxPadding="1 8 1 1"
          >
            {filteredReplacers && filteredReplacers.length > 0 ? (
              filteredReplacers.map((replacer) => (
                <div
                  key={replacer.id}
                  className="px-4 py-2 hover:bg-gray-100"
                  onMouseDown={(e) => {
                    e.preventDefault()
                  }}
                  onClick={(e) => {
                    setValue((prev) => {
                      const cursorPosition =
                        inputRef.current?.selectionStart ?? 0
                      const trimmedValue = prev?.substring(0, cursorPosition)
                      const lastBracketIndex =
                        trimmedValue?.lastIndexOf('[') ?? 0
                      const lastBracket =
                        trimmedValue?.substring(lastBracketIndex)
                      const lastBracketLength = lastBracket?.length ?? 0
                      const lastBracketEndIndex =
                        cursorPosition - lastBracketLength
                      const beforeLastBracket = prev?.substring(
                        0,
                        lastBracketEndIndex
                      )
                      const afterLastBracket = prev?.substring(cursorPosition)
                      return `${beforeLastBracket}[${
                        (replacer.original as Replacer).replacer
                      }]${afterLastBracket}`
                    })
                    setDropDownFilterValue(undefined)
                    inputRef.current?.focus()
                  }}
                >
                  {(replacer.original as Replacer).replacer}
                </div>
              ))
            ) : (
              <MenuItemRM>No matches</MenuItemRM>
            )}
          </ControlledMenu>
          {inputError && (
            <div className="text-xs text-red-400">{inputError}</div>
          )}
        </>
      ) : (
        <div
          className={cx(
            commonClassName,
            `flex items-center w-full hover:border-coolGray-400 h-full`,
            {
              'text-right': align === 'right',
              'text-center': align === 'center',
            }
          )}
        >
          {parsedValue || initialValue}
        </div>
      )}
    </div>
  )
}

export default PhraseCell
