import cx from 'classnames'

import Checkbox from 'common/components/checkbox/Checkbox'
import RadioGroup from 'common/components/radioGroup'
import Slider from 'common/components/slider/Slider'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import ContentTags from 'features/projects/views/editProject/views/content/components/ContentTags'

import {
  addUppercaseWord,
  clickSentenceCase,
  clickTitleCase,
  removeUppercaseWord,
  tickUpperCaseVariants,
  updateUppercaseProbability,
} from '../store/styleGuideSlice'

const CapitalizationSettings = () => {
  const dispatch = useAppDispatch()

  const editedCapitalizationSettings = useAppSelector(
    (state) => state.styleGuide.editedContent.capitalization
  )

  const isNeverUppercaseProbability =
    editedCapitalizationSettings.uppercaseProbability === 0

  return (
    <>
      <WidgetHeader title="Capitalization Settings" className="mt-10" />

      <div className="mt-8 mb-3 text-coolGray-800 font-medium">
        Do you want to use title case or sentence case?
      </div>
      <RadioGroup
        data-cy="case-radio"
        name="case"
        value={editedCapitalizationSettings.isTitleCase ? 'title' : 'sentence'}
        direction="vertical"
        options={[
          {
            value: 'sentence',
            label: (
              <span>
                Sentence case{' '}
                <span className="text-coolGray-500">
                  (eg. This is sentence case)
                </span>
              </span>
            ),
          },
          {
            value: 'title',
            label: (
              <span>
                Title case{' '}
                <span className="text-coolGray-500">
                  (eg. This Is Title Case)
                </span>
              </span>
            ),
          },
        ]}
        onChange={(value) => {
          if (value === 'title') {
            dispatch(clickTitleCase())
          } else {
            dispatch(clickSentenceCase())
          }
        }}
      />

      <div className="mt-10 mb-3 text-coolGray-800 font-medium">
        Jacquard can generate SOME words in capital letters.
      </div>
      <div className="sm:w-72">
        <Slider
          minLabel="Never"
          maxLabel="Always"
          min={0}
          max={100}
          onChange={(value) => {
            dispatch(updateUppercaseProbability(value as number))
          }}
          value={editedCapitalizationSettings.uppercaseProbability}
        />
      </div>

      <div
        className={cx('flex flex-row mb-10 mt-8', {
          hidden: isNeverUppercaseProbability,
        })}
      >
        <Checkbox
          label="Sometimes capitalize full variants: E.g OUR BEST EVER SALE"
          labelClassName="font-normal text-coolGray-800 text-sm"
          isChecked={editedCapitalizationSettings.canUppercaseVariants}
          onChange={(value: boolean) => {
            dispatch(tickUpperCaseVariants(value))
          }}
        />
      </div>
      <div>
        <span
          className={cx('text-coolGray-800 font-medium text-sm', {
            hidden: isNeverUppercaseProbability,
          })}
        >
          Add or remove any words you’d like Jacquard to be able to capitalize
        </span>

        <ContentTags
          className={cx('mt-2 sm:max-w-150', {
            hidden: isNeverUppercaseProbability,
          })}
          data-cy="capitalize-tags"
          tags={editedCapitalizationSettings.uppercaseWords.map((value) => ({
            value,
            label: value,
          }))}
          onInputChange={(value) => value.replaceAll(' ', '')}
          onAddClick={(word) => {
            dispatch(addUppercaseWord(word))
          }}
          onRemoveClick={(word) => {
            dispatch(removeUppercaseWord(word))
          }}
          hasTagButtonLabel
          tagButtonLabel="Word"
          placeholder="Add a word"
          isFreeText
        />
      </div>
    </>
  )
}

export default CapitalizationSettings
