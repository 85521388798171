import { useMemo } from 'react'
import copy from 'copy-to-clipboard'
import { showBanner } from 'workflow/common/common.actions'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Copy } from 'common/icons'

const getExperimentSectionData = ({ user_id, projectId, _id, account_id }) => [
  `User id: ${user_id}`,
  `Project id: ${projectId}`,
  `Experiment id: ${_id}`,
  `Account id: ${account_id}`,
]
const ExperimentSection = () => {
  const dispatch = useAppDispatch()
  const campaign = useAppSelector((state) => state.campaignStates)

  const { projectId, campaignData } = campaign
  const { _id, user_id, account_id } = campaignData

  const experimentSectionData = useMemo(
    () => getExperimentSectionData({ projectId, user_id, _id, account_id }),
    [projectId, user_id, _id, account_id]
  )

  const copyToClipboard = () => {
    copy(experimentSectionData.join(', '))
    dispatch(
      showBanner({
        type: 'success',
        content: 'Experiment info copied successfully',
      })
    )
  }

  return (
    <div aria-label="Experiment Information">
      <div className="flex items-center justify-between">
        <p className="mb-2 uppercase font-medium text-sm text-base-700">
          Experiment info
        </p>
        <button
          className="block"
          onClick={() => {
            copyToClipboard()
          }}
        >
          <Copy />
        </button>
      </div>
      <ul className="text-base-700 font-normal opacity-60">
        {experimentSectionData.map((item) => {
          return (
            <li
              key={item}
              data-cy={`campaign-info-${
                item.toLowerCase().replace(' ', '-').split(':')[0]
              }`}
              className="mb-1"
            >
              {item}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ExperimentSection
