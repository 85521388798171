import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/futurama/api/interfaces'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import AddTemplateButton from '../../components/AddTemplateButton'
import ElementCard from '../../components/ElementCard'
import ElementTemplateCard from '../../components/ElementTemplateCard'
import ExperimentTypeBadges from '../../components/ExperimentTypeBadges'
import { useContent } from '../../hooks'

const ElementsList = () => {
  const dispatch = useAppDispatch()

  const { content } = useContent()

  const elements = content?.elements

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  const isRemoveOptionShown = content?.elements && content.elements.length > 1

  return (
    <>
      <div role="tablist" data-testid="elementsContainer">
        {elements &&
          elements.map((element) => {
            if (isComponentElement(element)) {
              const { element_id, name, display_name, icon, status } = element
              const badgesState = getExperimentBadgesState(element)

              const isElementDisabled = status === 'disabled'

              const hasError = element.nlg_status === 'error'

              return (
                <ElementCard
                  className="mb-4"
                  key={element_id}
                  element={element}
                  title={display_name || name}
                  icon={icon}
                  isApproved={element.approved}
                  isDisabled={isElementDisabled}
                  isRemoveOptionShown={isRemoveOptionShown}
                  isSelected={
                    element_id === selectedElementId && !isElementDisabled
                  }
                  hasError={hasError}
                  isConfigurable={true}
                  content={
                    badgesState && <ExperimentTypeBadges {...badgesState} />
                  }
                  onClick={() =>
                    !isElementDisabled &&
                    dispatch(selectElement({ elementId: element_id }))
                  }
                />
              )
            } else if (isTemplateElement(element)) {
              return (
                <ElementTemplateCard
                  className="mb-4"
                  key={element.element_id}
                  element={element}
                  isRemoveOptionShown={isRemoveOptionShown}
                />
              )
            }

            return null
          })}
      </div>
      <AddTemplateButton />
    </>
  )
}

export default ElementsList
