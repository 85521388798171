import { ReactNode } from 'react'
import cx from 'classnames'

import Button, { ButtonProps } from 'common/components/button'
import Tooltip from 'common/components/Tooltip'

import { DETAILS_TRANSITION_DURATION } from '../../constants'

interface Props extends ButtonProps {
  isExpanded: boolean
  title: string
  icon: ReactNode
}

const DetailsButton = ({ isExpanded, title, icon, ...rest }: Props) => {
  return (
    <Tooltip
      placement="left"
      overlay={title}
      overlayClassName={cx({ hidden: isExpanded })}
    >
      <Button
        variant="icon"
        className="flex items-center mt-3 ml-0 opacity-50"
        {...rest}
      >
        <span>{icon}</span>
        <span
          className={cx(
            `pl-3 whitespace-nowrap text-coolGray-900 transition-opacity duration-${DETAILS_TRANSITION_DURATION}`,
            {
              'opacity-100': isExpanded,
              'opacity-0': !isExpanded,
            }
          )}
        >
          {title}
        </span>
      </Button>
    </Tooltip>
  )
}

export default DetailsButton
