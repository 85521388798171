import { IntegrationAccount } from '@phrasee/phrasee-typings/typings/integrations/accounts'

import IntegrationSelectionButton from './IntegrationSelectionButton'

type Props = {
  accounts: IntegrationAccount[]
  contentId: string
  elementId: number
}

const MultipleIntegrations = ({ accounts, contentId, elementId }: Props) => {
  return (
    <>
      <span className="text-coolGray-400 text-sm">
        Select an integration and share your message with your CEP or ESP.
      </span>
      <div className="flex flex-col justify-center items-center mt-4 p-6 border border-coolGray-200 bg-coolGray-50">
        <div className="text-coolGray-400 text-sm">
          Connect an integration and start sharing your message.
        </div>
        <IntegrationSelectionButton
          classdName="mt-4"
          contentId={contentId}
          elementId={elementId}
          accounts={accounts}
          variant="primary"
        >
          Select an integration
        </IntegrationSelectionButton>
      </div>
    </>
  )
}

export default MultipleIntegrations
