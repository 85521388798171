import { Slide, ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './Notification.css'

export const DEFAULT_TOAST_DURATION = 3000

const Container = () => {
  return (
    <ToastContainer
      position="top-center"
      transition={Slide}
      autoClose={DEFAULT_TOAST_DURATION}
      hideProgressBar={true}
      draggable={false}
      limit={5}
      theme="colored"
    />
  )
}

export default Container
