import { Route } from 'react-router-dom'
import Content from 'app/Content'
import {
  createProjectPath,
  projectSettingsPath,
  projectsPath,
} from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'

import BlankScreen from 'common/components/blankScreen'
import { useDocumentTitle } from 'common/hooks/custom'

import CreateProject from './views/createProject'
import EditProject from './views/editProject'
import ProjectsNav from './nav'

const Projects = () => {
  useDocumentTitle('Projects | Jacquard')

  return (
    <div className="flex w-full h-full overflow-y-hidden">
      <ProjectsNav />
      <Content fullPage>
        <CustomSwitch>
          <Route path={projectsPath} exact>
            <BlankScreen type="project" />
          </Route>
          <Route path={createProjectPath} exact>
            <CreateProject />
          </Route>
          <Route path={projectSettingsPath}>
            <EditProject />
          </Route>
        </CustomSwitch>
      </Content>
    </div>
  )
}

export default Projects
