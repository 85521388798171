import { useState } from 'react'
import { ExperimentTypeStatus } from '@phrasee/phrasee-typings/typings/futurama/experiment_type'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Badge from 'common/components/Badge'
import Button from 'common/components/button/Button'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import Tooltip from 'common/components/Tooltip'
import { useAppSelector } from 'common/hooks/redux'
import { Favorite } from 'common/icons'

import ExperimentType from './ExperimentType'
import SelectBox from './SelectBox'

export type ContentTemplateCardProps = {
  templateId: string
  iconType: string
  elementName: string
  header: string
  description: string
  meta?: string
  isFavorited: boolean
  onFavoriteClick: () => void
  disabled?: boolean
  isComingSoon?: boolean
  optimization?: ExperimentTypeStatus
  personalization?: ExperimentTypeStatus
}

const ContentTemplateCard = ({
  templateId,
  elementName,
  iconType,
  header,
  description,
  meta,
  isFavorited,
  onFavoriteClick,
  disabled,
  isComingSoon,
  optimization,
  personalization,
}: ContentTemplateCardProps) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  const [isHovered, setIsHovered] = useState<boolean>(false)
  const flags = useFlags()

  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )
  const templateToAdd = templatesToAdd.find(
    (template) => template.id === templateId
  )
  const templateCount = templatesToAdd.filter(
    (template) => template.id === templateId
  ).length
  const shouldShowSelectBox =
    !disabled && ((templateToAdd && templateCount > 0) || isHovered)

  return (
    <Tooltip
      overlay="Incompatible with selected template(s)"
      placement="top"
      mouseEnterDelay={0.8}
      show={disabled}
      getTooltipContainer={container ? () => container : undefined}
    >
      <div
        ref={setContainer}
        role="presentation"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={cx(
          'h-58 w-full bg-white flex flex-col p-6 border border-transparent hover:border-maroon-300 shadow-md',
          {
            'border-maroon-500': shouldShowSelectBox,
          }
        )}
      >
        <div className="flex mb-4 justify-between items-center">
          <div className="flex justify-center">
            <ContentTypeBubble name={elementName} iconType={iconType} />
            <span
              className={cx(
                'text-base font-medium flex items-center ml-1',
                disabled ? 'text-coolGray-400' : 'text-coolGray-800'
              )}
            >
              <span className="line-clamp-1">{header}</span>
            </span>
          </div>
        </div>
        <div
          className={cx(
            'text-base line-clamp-2',
            disabled ? 'text-coolGray-400' : 'text-coolGray-800'
          )}
        >
          {description}
        </div>
        <div className="flex mt-4">
          {isComingSoon && (
            <Badge
              text="Coming soon"
              variant="info"
              size="small"
              className="mr-2 inline-block border-0"
            />
          )}
          {optimization && (
            <ExperimentType
              name="optimize"
              status={optimization}
              className={cx({ 'opacity-40': disabled })}
            />
          )}
          {personalization && (
            <ExperimentType
              name="personalize"
              status={personalization}
              className={cx({ 'opacity-40': disabled })}
            />
          )}
        </div>
        <div className="flex justify-end mt-3">
          {shouldShowSelectBox && (
            <SelectBox
              isOptimized={optimization === 'enabled'}
              templateId={templateId}
            />
          )}
          {flags.showFavoriteIcon && (isFavorited || isHovered) ? (
            <Button
              onClick={onFavoriteClick}
              className="pr-0 pl-4 h-6"
              variant="icon"
              aria-label="favorite"
            >
              <Favorite
                className="text-coolGray-400"
                state={isFavorited ? 'selected' : 'default'}
                size={5.5}
              />
            </Button>
          ) : null}
        </div>
        {meta && <p className="text-xs text-coolGray-400 mt-1">{meta}</p>}
      </div>
    </Tooltip>
  )
}

export default ContentTemplateCard
