import React from 'react'
import cx from 'classnames'

import Widget from 'common/components/Widget'

interface Props {
  icon: React.ReactNode
  text: string
  hasNotAccess?: boolean
  onClick: () => void
}

const QuickCard = ({ icon, text, hasNotAccess = false, onClick }: Props) => {
  return (
    <Widget
      type="table"
      className={cx(
        'border-l-4 border-t-0 border-r-0 border-b-0 border-maroon-500 flex-1'
      )}
    >
      <button
        onClick={onClick}
        className="flex group place-items-center p-4 bg-white hover:bg-maroon-50 h-full relative"
      >
        {hasNotAccess && (
          <div className="text-coolGray-50 font-bold text-xs py-0.5 px-1 bg-coolGray-500 absolute -top-2 left-4">
            Upgrade
          </div>
        )}
        <div className="group-hover:text-maroon-300 ">{icon}</div>
        <div className="text-coolGray-800 font-medium ml-4">{text}</div>
      </button>
    </Widget>
  )
}

export default QuickCard
