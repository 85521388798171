import { useFlags } from 'launchdarkly-react-client-sdk'

import Footer from 'common/components/layout/Footer'
import { discriminate } from 'common/helpers/typeUtils'
import { useAppSelector } from 'common/hooks/redux'

import SummaryWidgetContainer from './SummaryWidget/SummaryWidgetContainer'
import CampaignsWidget from './CampaignsWidget'
import IncrementalEventsSection from './IncrementalEventsSection'
import IncrementalRevenueWidget from './IncrementalRevenueWidget'
import ReactCampaignRates from './reactCampaignRates'
import UpliftSection from './UpliftSection'

const Content = () => {
  const { performanceRevenueWidget: showPerformanceRevenueWidget } = useFlags()
  const { product, widgetList } = useAppSelector((state) => state.reports)

  const shouldShowSummaryWidget = !!widgetList?.widgets.find(
    (widget) => widget.id === 'widget_summary'
  )

  const upliftData = widgetList?.widgets.find(
    discriminate('id', 'widget_uplift')
  )

  const incrementalEventsData = widgetList?.widgets.find(
    discriminate('id', 'widget_incremental_events')
  )

  const incrementalRevenueData = widgetList?.widgets.find(
    discriminate('id', 'widget_revenue')
  )

  const campaignsData = widgetList?.widgets.find(
    discriminate('id', 'widget_campaigns')
  )

  return (
    <>
      <div className="flex flex-col gap-10">
        {shouldShowSummaryWidget && <SummaryWidgetContainer />}
        {campaignsData && <CampaignsWidget />}
        {product === 'broadcast' && !!upliftData && (
          <UpliftSection widgetData={upliftData} />
        )}
        {product === 'broadcast' && !!incrementalEventsData && (
          <IncrementalEventsSection widgetData={incrementalEventsData} />
        )}
        {showPerformanceRevenueWidget &&
          product === 'broadcast' &&
          incrementalRevenueData && (
            <IncrementalRevenueWidget widgetData={incrementalRevenueData} />
          )}
        {product === 'AlwaysOn' && campaignsData && (
          <ReactCampaignRates campaigns={campaignsData?.campaign_list} />
        )}
      </div>
      <Footer />
    </>
  )
}

export default Content
