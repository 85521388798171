import { FC, useState } from 'react'
import {
  components,
  GroupBase,
  MenuProps,
  NoticeProps,
  OnChangeValue,
} from 'react-select'
import Select from 'react-select/creatable'

import { Option as OptionComponent } from './MultiSelect/MultiSelect'
import type { SelectValue } from './BaseSelect'
import { styles } from './BaseSelectStyles'

interface Props {
  values: Options
  setValues: (val: Options) => void
  isValidNewOption?: (val: string) => boolean
  id?: string
  min?: number
  max?: number
  options?: Options
  ariaLabel?: string
  placeholder?: string
  'data-cy'?: string
  isDisabled?: boolean
}

type Option = SelectValue

export type Options = readonly Option[]

export const getOptionsArray = (value: string): string[] =>
  value.replace(/\s/g, '').split(',')

const createOptions = (value: string): Options =>
  getOptionsArray(value).map((val) => {
    return { label: val, value: val }
  })

const NoOptionsMessage = (props: NoticeProps<Option, true>) => {
  const { children, selectProps } = props

  return (
    <div>
      <components.NoOptionsMessage {...props}>
        {selectProps.inputValue.length ? (
          <div className="text-left text-red-400">
            Please enter valid comma separated options
          </div>
        ) : (
          children
        )}
      </components.NoOptionsMessage>
    </div>
  )
}

const Menu = (props: MenuProps<Option, true>) => {
  const optionSelectedLength = props.getValue().length || 0
  // @ts-ignore
  const { max, min } = props.selectProps
  return (
    <components.Menu {...props}>
      {max && optionSelectedLength >= max ? (
        <div className="p-2 text-red-400">
          Selection is limited to {max} options
        </div>
      ) : (
        <>
          {props.children}
          {min && optionSelectedLength < min && (
            <div className="p-2 text-red-400">
              Please select at least {min} option{min === 1 ? '' : 's'}
            </div>
          )}
        </>
      )}
    </components.Menu>
  )
}

const CreatableSelect: FC<Props> = ({
  id,
  min,
  max,
  options: defaultOptions,
  values,
  placeholder,
  setValues,
  isValidNewOption,
  ariaLabel,
  isDisabled,
  'data-cy': dataCy,
}) => {
  const [options, setOptions] = useState<Options>(defaultOptions ?? [])

  const handleCreate = (inputValue: string) => {
    const newOption = createOptions(inputValue)

    setOptions([...options, ...newOption])
    setValues([...values, ...newOption])
  }

  return (
    <Select<Option, true>
      id={id}
      data-cy={dataCy}
      aria-label={ariaLabel}
      aria-describedby="Select from list of provided options or create your own. To create multiple options, separate them by using a comma."
      styles={styles<Option, true, GroupBase<Option>>({
        optionsPosition: 'left',
        isCreatable: true,
      })}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      placeholder={placeholder}
      options={options}
      value={values}
      // @ts-ignore
      min={min}
      max={max}
      isDisabled={isDisabled}
      onChange={(newValue: OnChangeValue<Option, true>) => setValues(newValue)}
      onCreateOption={handleCreate}
      components={{
        Menu,
        NoOptionsMessage,
        Option: OptionComponent,
      }}
      isValidNewOption={(inputValue: string, value: Options) => {
        if (inputValue === '') {
          return false
        }

        if (max && value.length >= max) {
          return false
        }

        if (isValidNewOption) {
          return isValidNewOption(inputValue)
        }
        return true
      }}
    />
  )
}

export default CreatableSelect
