import { messages } from '../WidgetMessage'

const Error = () => {
  return (
    <div className="flex justify-center items-center border border-red-500 bg-white py-9 shadow-widget">
      <p className="w-123 text-red-400">{messages.error}</p>
    </div>
  )
}

export default Error
