import { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { personalizationPerIdPath } from 'app/navigation/paths'
import cx from 'classnames'

import ActionIcon from 'common/components/ActionIcon'
import Button from 'common/components/button/Button'
import Footer from 'common/components/layout/Footer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import SortMenu, {
  sortByOptions,
  SortOptionsValue,
} from 'common/components/sortMenu/SortMenu'
import Spinner from 'common/components/spinner'
import Tabs, { addCountToTabName, Tab } from 'common/components/tabs'
import { errorToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useLocalStorage } from 'common/hooks/useLocalStorage'
import { Grid, ListIcon, MoreOptions } from 'common/icons'
import { ReactComponent as AddDefault } from 'common/icons/add/default.svg'

import useGetPersonalizationsQuery from '../api/queries/useGetPersonalizationsQuery'
import Page from '../components/Page'

import PersonalizationAutocomplete from './components/Autocomplete'
import PersonalizationGrid from './components/PersonalizationGrid'
import EmptyState from './EmptyState'
import { PersonalizationInfo, PersonalizationTypesByTab } from './interfaces'
import PersonalizationTable from './PersonalizationTable'

const CONTENTS_PAGE_VIEW_KEY = `contents-page-view`

export const contentTypesByTab: PersonalizationTypesByTab = {
  all: [],
  email: [
    'marketing email',
    'email headline',
    'email pre-header',
    'email cta',
    'email subject line',
    'cold email',
  ],
  social: ['facebook ad', 'instagram ad'],
  messaging: ['push title', 'push body', 'sms'],
  articles: [
    'heading',
    'section titles',
    'section paragraphs',
    'bullet points',
    'article',
  ],
  web: ['website call to action', 'website headline', 'website banner'],
  ads: ['google responsive search ad', 'google responsive display ad'],
  ecommerce: ['product description', 'product features'],
}

const ContentsPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory<{ templateTab?: string; tab?: string }>()
  const { location } = history
  useDocumentTitle('Personalization | Jacquard')
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const {
    data: contentData = [],
    error,
    status,
  } = useGetPersonalizationsQuery()

  const [selectedContentTab, setSelectedContentTab] = useState('all')

  const [searchContentValue, setSearchContentValue] = useState<string>('')
  const [sortOptions, setSortOptions] = useState<SortOptionsValue>({
    property: 'modifiedDate',
    isAscending: false,
  })

  const [isTableView, setIsTableView] = useLocalStorage(
    CONTENTS_PAGE_VIEW_KEY,
    false
  )

  useEffect(() => {
    const { tab } = location.state ?? {}

    if (tab) {
      setSelectedContentTab(tab)
    }
  }, [location, dispatch])

  const filterByName = useCallback(
    ({ name }: PersonalizationInfo) =>
      name
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchContentValue.replaceAll(' ', '').toLowerCase()),
    [searchContentValue]
  )

  const filterByPersonalizationType = useCallback(
    ({ contentType: type }: PersonalizationInfo) => {
      return (
        selectedContentTab === 'all' ||
        selectedContentTab.toLowerCase() === (type ? type.toLowerCase() : '')
      )
    },
    [selectedContentTab]
  )

  const contentFiltered = useMemo(() => {
    const filteredData = (contentData as any)
      .filter(filterByPersonalizationType)
      .filter(filterByName)

    const sortedData: any = sortByOptions(filteredData, sortOptions)

    return sortedData
  }, [contentData, filterByPersonalizationType, filterByName, sortOptions])

  const generateTabContent = useCallback(
    (personalization: PersonalizationInfo[]) => {
      if (personalization.length === 0) {
        if (searchContentValue !== '') {
          return (
            <div className="text-5xl font-bold text-coolGray-800 leading-normal">
              There are no personalizations matching your search, please try
              again
            </div>
          )
        }
        return (
          <div className="text-5xl font-bold text-coolGray-800 leading-normal">
            There are no personalizations
          </div>
        )
      } else if (isTableView) {
        return (
          <PersonalizationTable
            content={personalization}
            isLoading={false}
            hasError={false}
            onClickItem={(id) => {
              history.push(
                generatePath(personalizationPerIdPath, {
                  id,
                  accountId,
                })
              )
            }}
          />
        )
      } else {
        return (
          <PersonalizationGrid
            contents={contentFiltered}
            onClickItem={(id) => {
              history.push(
                generatePath(personalizationPerIdPath, {
                  id,
                  accountId,
                })
              )
            }}
          />
        )
      }
    },
    [isTableView, searchContentValue, history, accountId, contentFiltered]
  )

  const tabs: Tab[] = [
    {
      key: 'all',
      name: addCountToTabName({
        tabName: 'All',
        count: contentData.length,
      }),
      content: generateTabContent(contentFiltered),
      isDisabled: false,
    },
    ...Object.keys(contentTypesByTab)
      .filter((category) => category !== 'all')
      .map((category) => ({
        key: category,
        name: addCountToTabName({
          tabName: category,
          count:
            contentData.filter((item) => {
              return (
                category === 'all' ||
                category.toLowerCase() ===
                  (item.contentType ? item.contentType.toLowerCase() : '')
              )
            }).length || 0,
        }),
        content: generateTabContent(contentFiltered),
        isDisabled: true,
      })),
  ]

  const getState = () => {
    if (status === 'loading') {
      return 'loading'
    } else if (status === 'error') {
      return 'error'
    } else if (status === 'success' && contentData.length === 0) {
      return 'empty'
    } else {
      return 'success'
    }
  }

  const state = getState()
  return (
    <>
      <Page className="lg:px-40 px-6 pt-6 ">
        {
          {
            empty: <EmptyState />,
            success: (
              <div className="flex flex-col w-full h-full bg-warmGray-50 max-w-screen-lg sm:mx-auto">
                <PageHeader>
                  <PageTitle title="Personalized Campaigns" />
                </PageHeader>
                <div className="flex justify-between items-stretch relative">
                  <Tabs
                    data-testid="content-tabs"
                    className="w-full"
                    tabs={tabs.filter((tab) => !tab.isDisabled)}
                    activeKey={selectedContentTab}
                    onTabClick={(tab) => {
                      setSelectedContentTab(tab)
                    }}
                    variant="filter"
                    moreIcon={<MoreOptions />}
                  />

                  <div
                    className={cx(
                      'flex absolute right-0 items-center sm:top-0'
                    )}
                  >
                    <ActionIcon
                      aria-label="Toggle view button"
                      onClick={() => setIsTableView(!isTableView)}
                    >
                      {isTableView ? (
                        <Tooltip overlay="Tile view">
                          <Grid />
                        </Tooltip>
                      ) : (
                        <Tooltip overlay="List view">
                          <ListIcon />
                        </Tooltip>
                      )}
                    </ActionIcon>
                    <Tooltip overlay="Sort">
                      <SortMenu
                        className="text-base pt-3"
                        onSortClick={setSortOptions}
                      />
                    </Tooltip>
                    <Button
                      data-cy="add-content-button"
                      data-testid="add-content-button"
                      aria-label="Personalization"
                      variant="primary"
                      onClick={() => errorToast('Not yet implemented')}
                      className="text-base"
                      prefixIcon={<AddDefault width={24} height={24} />}
                      disabled={true}
                    >
                      Personalization
                    </Button>
                    <PersonalizationAutocomplete
                      onSearch={setSearchContentValue}
                      data-cy="content-search"
                      aria-label="content search"
                      options={contentData.map(
                        (personalization: PersonalizationInfo) => ({
                          value: personalization.id,
                          label: personalization.name,
                        })
                      )}
                    />
                  </div>
                </div>
                <Footer />
              </div>
            ),
            loading: (
              <div className="w-full">
                <Spinner data-testid="contents-loader" />
              </div>
            ),
            error: (
              <div data-testid="error-message">Error: {String(error)}</div>
            ), // TODO: Add error component
          }[state]
        }
      </Page>
    </>
  )
}

export default ContentsPage
