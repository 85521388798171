import LinearLoader from './linearLoader'

const TableLoader = () => {
  return (
    <div
      data-testid="loader"
      className="bg-coolGray-50 border-coolGray-100 border-t"
    >
      <LinearLoader backgroundColor="transparent" />
      <div className="h-full flex flex-col justify-center items-center py-18">
        <p className="text-coolGray-500 font-bold mt-8">
          “Above all else, show the data.”
        </p>
        <p className="text-coolGray-500">Edward R. Tufte</p>
      </div>
    </div>
  )
}

export default TableLoader
