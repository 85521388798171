import ConfirmationModal from 'common/components/confirmationModal'

export type Mode = 'stay' | 'leave'

type Props = {
  isOpen: boolean
  isSaving: boolean
  onConfirm: () => void
  onCancel: () => void
  mode?: Mode
  confirmationText?: string
}

const CONFIG_PER_MODE = {
  leave: {
    cancel: 'Yes leave',
    confirm: 'Save',
  },
  stay: {
    cancel: 'No, keep editing',
    confirm: 'Yes, leave',
  },
} as const

const PageLeaveModal = ({
  isOpen,
  isSaving,
  onConfirm,
  onCancel,
  mode = 'leave',
  confirmationText = 'You have unsaved changes, are you sure you want to leave without saving? All unsaved content will be lost.',
}: Props) => {
  const config = CONFIG_PER_MODE[mode]
  return (
    <ConfirmationModal
      open={isOpen}
      isLoading={isSaving}
      confirmationText={confirmationText}
      title="Leave without saving?"
      cancelButtonText={config.cancel}
      confirmButtonText={config.confirm}
      onConfirm={onConfirm}
      onCancel={onCancel}
      data-testid="page-leave-modal"
    />
  )
}

export default PageLeaveModal
