import { useState } from 'react'
import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'

import CheckboxComponent from 'common/components/checkbox'

import Footer from './Footer'

import 'rc-dropdown/assets/index.css'

export const experimentType = {
  optimize: 'optimize',
  personalize: 'personalize',
}

interface Option {
  value: string
  label: string
  onClick: (value: string) => void
  isDisabled?: boolean
}

interface MenuProps {
  options: Option[]
  checkedItems: string[]
  onApply: () => void
  onClear: () => void
}

const CustomMenu = ({ options, checkedItems, onApply, onClear }: MenuProps) => (
  <div className="shadow-lg box-border py-2 z-40 w-75 bg-white border-1 border-coolGray-300">
    <Menu selectable={false}>
      {options.map((option) => (
        <MenuItem
          disabled={option.isDisabled}
          key={option.value}
          className="pt-2 px-4 pb-2 hover:bg-coolGray-50 text-coolGray-800 font-medium cursor-pointer"
        >
          <CheckboxComponent
            isChecked={checkedItems?.some((value) => value === option.value)}
            onChange={() => option.onClick(option.value)}
            className="mr-2 capitalize"
            label={option.label}
            isDisabled={option.isDisabled}
          />
        </MenuItem>
      ))}
    </Menu>
    <Footer onClear={onClear} onApply={onApply} />
  </div>
)

interface Props {
  animation?: string
  placement?: string
  overlayClassName?: string
  children: React.ReactNode
  getPopupContainer: ((node: HTMLElement) => HTMLElement) | undefined
  onApply: (items: string[]) => void
}

export const ExperimentMenu = ({
  animation = 'slide-up',
  placement = 'bottomLeft',
  children,
  getPopupContainer,
  onApply,
}: Props) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  const [isOpened, setIsOpened] = useState(true)

  const onClickCheckbox = (value: string) => {
    if (checkedItems.some((currentValue) => currentValue === value)) {
      setCheckedItems((checkedItems) =>
        checkedItems.filter((item) => item !== value)
      )
    } else {
      setCheckedItems((checkedItems) => [...checkedItems, value])
    }
  }

  return (
    <Dropdown
      trigger={['click']}
      animation={animation}
      placement={placement}
      getPopupContainer={getPopupContainer}
      align={{
        offset: [-16, 5],
      }}
      overlay={
        <CustomMenu
          checkedItems={checkedItems}
          options={Object.keys(experimentType).map((option) => ({
            value: option,
            label: option,
            onClick: onClickCheckbox,
          }))}
          onClear={() => {
            setCheckedItems([])
            onApply([])
          }}
          onApply={() => {
            setIsOpened(false)
            onApply(checkedItems)
          }}
        />
      }
      visible={isOpened}
      onVisibleChange={setIsOpened}
    >
      <span data-cy="tab-experiments" className="capitalize py-2">
        {children}
      </span>
    </Dropdown>
  )
}

export default ExperimentMenu
