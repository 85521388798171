import { ReactNode } from 'react'

import { ResultColumn } from '../useWinnerVariantInfo'

import Cell from './Cell'

type Props = {
  results: ResultColumn[]
  children: ReactNode
}

const Results = ({ results, children }: Props) => {
  return (
    <>
      {results.map(({ key, title, value }) => {
        return (
          <Cell
            key={key}
            title={title.replace('Final ', '')}
            value={value!}
            valueClassName="text-maroon-500"
          />
        )
      })}
      {children}
    </>
  )
}

export default Results
