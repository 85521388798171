import { Fragment } from 'react'
import { ColumnInstance } from 'react-table'

import CheckboxComponent from 'common/components/checkbox'
import OptionsMenuButton from 'common/components/optionsMenuButton'

import { MenuDivider, MenuHeader, MenuItem, SubMenu } from '../../menuButton'

export const SUB_MENU_EXPORT_LABEL = 'Export'
export const SUB_MENU_SHOW_HIDE_COLUMN_LABEL = 'Show / hide columns'

interface Props<T extends object> {
  columns: ColumnInstance<T>[] | undefined
  onFullscreenClick: () => void
  exportCsv?: () => void
}

const TableWidgetMenu = <T extends object>({
  columns,
  onFullscreenClick,
  exportCsv,
}: Props<T>) => {
  const options = (
    <>
      <MenuItem key="1" onClick={() => onFullscreenClick()}>
        View in full screen
      </MenuItem>
      {exportCsv ? (
        <SubMenu label={SUB_MENU_EXPORT_LABEL} direction="left">
          <MenuItem onClick={() => exportCsv()}>Export to CSV</MenuItem>
        </SubMenu>
      ) : undefined}
      {columns && (
        <SubMenu label={SUB_MENU_SHOW_HIDE_COLUMN_LABEL} direction="left">
          {columns
            .filter(
              (column) =>
                !!column.isHidable &&
                typeof column.Header === 'string' &&
                column.Header.length > 0
            )
            .map((column) => (
              <Fragment key={column.Header as string}>
                {column.parent?.columns?.[0]?.id === column.id && (
                  <>
                    <MenuDivider />
                    <MenuHeader>{column.parent.Header}</MenuHeader>
                  </>
                )}
                <MenuItem
                  key={column.Header as string}
                  onClick={(e) => {
                    e.keepOpen = true
                    // stop it from propagating to root menu's onItemClick
                    e.stopPropagation = true
                    column.toggleHidden()
                  }}
                >
                  <CheckboxComponent
                    isChecked={column.isVisible}
                    onChange={() => {
                      column.toggleHidden()
                    }}
                  />
                  {column.Header}
                </MenuItem>
              </Fragment>
            ))}
        </SubMenu>
      )}
    </>
  )

  return (
    <div className="mr-5">
      <OptionsMenuButton name="widget menu" options={options} />
    </div>
  )
}

export default TableWidgetMenu
