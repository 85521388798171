import { split } from 'lodash'

export const stripForbiddenCharacters = (value: string) => {
  //see https://www.unicode.org/Public/UCD/latest/ucd/PropertyValueAliases.txt

  // To see the non-printable unicode characters https://www.soscisurvey.de/tools/view-chars.php
  const onlyValidCharacters =
    /[\p{L}\p{M}\p{N}\p{P}\p{S}(\r\n?|\n)\p{Emoji_Presentation}\u0020]/gu
  return split(value, '') // iterate through grapheme clusters, see https://stackoverflow.com/questions/68602529/javascript-iterate-over-unicode-when-emoji-has-a-skincolor
    .filter((char) => char.match(onlyValidCharacters))
    .join('')
}

export const stripToValidateName = (value: string) => {
  //see https://www.unicode.org/Public/UCD/latest/ucd/PropertyValueAliases.txt

  // To see the non-printable unicode characters https://www.soscisurvey.de/tools/view-chars.php
  const onlyValidCharacters = /[\p{L}\p{M}\p{Pd}\u0020\u0027]/gu
  return value.match(onlyValidCharacters)?.join('') ?? ''
}
