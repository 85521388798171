/* eslint-disable max-lines */
import { LanguageGenerationMethodValue } from '@phrasee/phrasee-typings/typings/project/project-configuration.types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { RootState } from 'redux/store'

import { errorToast } from 'common/components/toastNotification'
import { removeMaybe } from 'common/helpers/typeUtils'
import { DistributionChannel } from 'common/interfaces/projects'
import { getProjectConfig } from 'features/projects/api'

import { fetchProjectCombinedLanguage, updateProjectSettings } from '../api'
import { MIN_SEGMENT_LENGTH } from '../components/segments/Segments'
import {
  InitializePageResponse,
  LanguageSettingsResponse,
  Segment,
} from '../types'

type AlternativeWord = {
  id: number
  originalWord: string
  partOfSpeech: { label: string; value: string }[]
  replacements: string[]
}

type Synonym = {
  id: number
  headToken: string
  partOfSpeech: { label: string; value: string }[]
  isInherited: boolean
  output: { value: string; isInherited: boolean }[]
}

type EmojiItem = {
  value: string
  isInherited: boolean
}

type Emoji = {
  value: string
  isInherited: boolean
  values: EmojiItem[]
}

type ForbiddenWord = { value: string; isInherited: boolean }
export type IncompatibleWord = { value: string; isInherited: boolean }

type Content = {
  regionStructure: {
    brandVoiceId: string | undefined
    regionId: string | undefined
    currency: string | undefined
    dateFormat: string | undefined
    segments: Segment[] | undefined
  }
  punctuation: {
    leadPunctuations: string[]
    middlePunctuations: string[]
    endPunctuations: string[]
    hasCurlyApostrophes: boolean
    isIncludingEndPunctuation: boolean
    hasSpaceBeforeEllipsis: boolean
    hasSpaceAfterEllipsis: boolean
    isCapitalizingAfterColon: boolean
    isCapitalizingAfterDash: boolean
    isCapitalizingAfterEllipsis: boolean
  }
  stylistics: {
    stylisticsOptions: string[]
    wrapLeaders: boolean
    wrapVariants: boolean
    wrappingProbability: number
    capitalizeWords: string[]
    capitalizeLeaders: boolean
    capitalizeVariants: boolean
    capitalizationProbability: number
    titleCase: boolean
  }
  alternativeWords: (AlternativeWord & { hasError: boolean })[]
  forbiddenWords: ForbiddenWord[]
  incompatibleWords: IncompatibleWord[][]
  emojiSettings: {
    isUsingDoubleEmoji: boolean
    leftFrequency: number
    rightFrequency: number
  }
  emojis: Emoji[]
  synonyms: (Synonym & { hasError: boolean })[]
}
export interface State {
  isSaving: boolean
  isSavingRegion: boolean
  rawContent: InitializePageResponse | undefined
  editedContent: Content
  distributionChannel: DistributionChannel | undefined
  languageGenerationMethod: LanguageGenerationMethodValue | undefined
  isEmojiInheritanceDisabled: boolean
}

export const initialState: State = {
  isSaving: false,
  isSavingRegion: false,
  isEmojiInheritanceDisabled: false,
  rawContent: undefined,
  distributionChannel: undefined,
  languageGenerationMethod: undefined,
  editedContent: {
    regionStructure: {
      brandVoiceId: undefined,
      regionId: undefined,
      currency: undefined,
      dateFormat: undefined,
      segments: undefined,
    },
    punctuation: {
      leadPunctuations: [],
      middlePunctuations: [],
      endPunctuations: [],
      hasCurlyApostrophes: false,
      isIncludingEndPunctuation: false,
      hasSpaceAfterEllipsis: false,
      hasSpaceBeforeEllipsis: false,
      isCapitalizingAfterColon: false,
      isCapitalizingAfterDash: false,
      isCapitalizingAfterEllipsis: false,
    },
    stylistics: {
      stylisticsOptions: [],
      wrapLeaders: false,
      wrapVariants: false,
      wrappingProbability: 0,
      capitalizeWords: [],
      capitalizeLeaders: false,
      capitalizeVariants: false,
      capitalizationProbability: 0,
      titleCase: false,
    },
    alternativeWords: [],
    forbiddenWords: [],
    incompatibleWords: [],
    synonyms: [],
    emojiSettings: {
      isUsingDoubleEmoji: false,
      leftFrequency: 0,
      rightFrequency: 0,
    },
    emojis: [],
  },
}

const isSynonymValid = (synonym: Synonym) => {
  return (
    synonym.headToken &&
    synonym.partOfSpeech?.length > 0 &&
    synonym.output.length > 0 &&
    !synonym.output.some((word) => !word)
  )
}
const isAlternativeWordValid = (alternativeWord: AlternativeWord) => {
  return (
    alternativeWord.originalWord?.length > 0 &&
    alternativeWord.partOfSpeech?.length > 0
  )
}

const getDuplicatedAlternativeWords = (alternativeWords: AlternativeWord[]) => {
  const concatPartOfSpeech = (current: AlternativeWord) =>
    current.partOfSpeech.map((word) => word.value).join('-')

  const originalWordsWithPartOfSpeech = alternativeWords.reduce(
    (accumulator, current) => {
      return {
        ...accumulator,
        [`${current.originalWord}-${concatPartOfSpeech(current)}`]:
          (accumulator[
            `${current.originalWord}-${concatPartOfSpeech(current)}`
          ] ?? 0) + 1,
      }
    },
    {}
  )

  const isDuplicateWord = (word: AlternativeWord) => {
    return (
      originalWordsWithPartOfSpeech[
        `${word.originalWord}-${concatPartOfSpeech(word)}`
      ] > 1
    )
  }

  const duplicateAlternativeWords = alternativeWords.filter((word) =>
    isDuplicateWord(word)
  )
  return duplicateAlternativeWords
}

const getDuplicatedSynonyms = (synonyms: Synonym[]) => {
  const concatPartOfSpeech = (current: Synonym) =>
    current.partOfSpeech.map((word) => word.value).join('-')

  const originalWordsWithPartOfSpeech = synonyms.reduce(
    (previous, current) => ({
      ...previous,
      [`${current.headToken}-${concatPartOfSpeech(current)}`]:
        (previous[`${current.headToken}-${concatPartOfSpeech(current)}`] ?? 0) +
        1,
    }),
    {}
  )

  const duplicateSynonimsWords = synonyms.filter(
    (word) =>
      originalWordsWithPartOfSpeech[
        `${word.headToken}-${concatPartOfSpeech(word)}`
      ] > 1
  )

  return duplicateSynonimsWords
}

export const initializePage = createAsyncThunk<
  InitializePageResponse & {
    isEmojiInheritanceDisabled: boolean
  },
  {
    projectId: string
    isEmojiInheritanceDisabled: boolean
  },
  { state: RootState }
>(
  'admin/content/initializePage',
  async ({ projectId, isEmojiInheritanceDisabled }, { getState }) => {
    const state = getState()
    const { accountId } = state.authStates

    const projectConfig = (await getProjectConfig(accountId, projectId)).data
      .project
    const languageSettings = await fetchProjectCombinedLanguage(
      projectId,
      projectConfig?.brand_voice_id
    )

    return {
      projectConfig,
      languageSettings,
      isEmojiInheritanceDisabled,
    }
  }
)

const processInitalizePageResponse = ({
  projectConfig,
  languageSettings: languageSettingsResponse,
  isEmojiInheritanceDisabled,
}: InitializePageResponse & {
  isEmojiInheritanceDisabled: boolean
}): Content => {
  const punctuation = languageSettingsResponse?.punctuation
  const punctuationOptions = punctuation?.punctuation_options || {}
  const capitalization = languageSettingsResponse?.capitalization
  const emojiFrequency = languageSettingsResponse?.emoji_frequency

  const alternativeWords =
    languageSettingsResponse?.vocabulary?.word_replacements?.map(
      (wordReplacement, index) => {
        return {
          originalWord:
            wordReplacement?.phrase ??
            wordReplacement?.pattern
              ?.flat(Infinity)
              ?.map((pos) => pos.LOWER)
              .join(' '),
          partOfSpeech: wordReplacement?.pattern
            ?.flat(Infinity)
            ?.map((pos) => ({
              label: pos.LOWER,
              value: pos.POS?.toLowerCase() ?? '',
            })),
          id: index,
          replacements: wordReplacement?.replacements?.filter(String) ?? [],
          hasError: false,
        }
      }
    ) ?? []

  const synonyms =
    languageSettingsResponse?.syns?.map((synonym, index) => {
      return {
        id: index,
        headToken:
          synonym?.phrase ||
          synonym?.pattern
            ?.flat(Infinity)
            ?.map((pos) => pos.LOWER)
            .join(' '),
        partOfSpeech: synonym?.pattern?.flat(Infinity)?.map((pos) => ({
          label: pos.LOWER,
          value: pos.POS?.toLowerCase() ?? '',
        })),
        output:
          synonym?.replacements?.map((word) => ({
            value: word.value.trim(),
            isInherited: word.inherited,
          })) ?? [],
        hasError: false,
      }
    }) ?? []

  return {
    regionStructure: {
      regionId: projectConfig?.region_id ?? undefined,
      brandVoiceId: projectConfig?.brand_voice_id ?? undefined,
      currency: languageSettingsResponse?.iso_currency,
      dateFormat: languageSettingsResponse?.date_format,
      segments: languageSettingsResponse.content_structure,
    },
    punctuation: {
      leadPunctuations: punctuationOptions.lead_punctuation ?? [],
      endPunctuations: punctuationOptions.end_punctuation ?? [],
      middlePunctuations: punctuationOptions.mid_punctuation ?? [],
      hasCurlyApostrophes: punctuationOptions.curly_apostrophe ?? false,
      isIncludingEndPunctuation:
        punctuation?.force_final_punc_after_midpunc ?? false,
      hasSpaceAfterEllipsis: punctuation?.space_after_elps ?? false,
      hasSpaceBeforeEllipsis: punctuation?.space_before_elps ?? false,
      isCapitalizingAfterColon:
        languageSettingsResponse?.capitalization?.caps_after_colon ?? false,
      isCapitalizingAfterDash:
        languageSettingsResponse?.capitalization?.caps_after_dash ?? false,
      isCapitalizingAfterEllipsis:
        languageSettingsResponse?.capitalization?.caps_after_elps ?? false,
    },
    stylistics: {
      stylisticsOptions: punctuation?.nonfunctional_options ?? [],
      wrapLeaders: punctuation?.wrap_leaders ?? false,
      wrapVariants: punctuation?.wrap_variants ?? false,
      wrappingProbability: Math.floor(
        (punctuation?.wrapping_probability ?? 0) * 100
      ),
      capitalizeWords: capitalization?.uppercase_words ?? [],
      capitalizeLeaders: capitalization?.uppercase_leaders ?? false,
      capitalizeVariants: capitalization?.uppercase_variants ?? false,
      capitalizationProbability: Math.floor(
        (capitalization?.uppercase_probability ?? 0) * 100
      ),
      titleCase: capitalization?.title_case ?? false,
    },
    alternativeWords,
    synonyms,
    forbiddenWords: (
      languageSettingsResponse?.vocabulary?.forbidden_words ?? []
    ).map((word) => ({ value: word.value, isInherited: word.inherited })),
    incompatibleWords: (
      languageSettingsResponse?.vocabulary?.incompatible_words ?? []
    ).map((wordsRow) => {
      return wordsRow.map((word) => ({
        value: word.value,
        isInherited: word.inherited,
      }))
    }),
    emojiSettings: {
      isUsingDoubleEmoji: emojiFrequency?.double_emojis ?? false,
      leftFrequency: (emojiFrequency?.left_emojis ?? 0) * 10,
      rightFrequency: (emojiFrequency?.right_emojis ?? 0) * 10,
    },
    emojis:
      languageSettingsResponse?.emojis_list
        ?.filter(removeMaybe)
        ?.map((emoji) => {
          return isEmojiInheritanceDisabled
            ? {
                value: emoji.lookup ?? '',
                isInherited: false,
                values:
                  emoji.values?.filter(removeMaybe).map((item: EmojiItem) => ({
                    value: item,
                    inherited: false,
                  })) ?? [],
              }
            : {
                value: emoji.lookup.value ?? '',
                isInherited: emoji.lookup.inherited ?? false,
                values:
                  emoji.values?.filter(removeMaybe).map((item) => ({
                    value: item.value,
                    isInherited: item.inherited,
                  })) ?? [],
              }
        })
        .filter(Boolean) ?? [],
  }
}

export const saveRegion = createAsyncThunk<
  LanguageSettingsResponse,
  {
    projectId: string
    regionId: string | undefined
    brandVoiceId: string | undefined
    isEmojiInheritanceDisabled: boolean
  },
  { state: RootState }
>(
  'admin/content/saveRegion',
  async (
    { projectId, regionId, brandVoiceId, isEmojiInheritanceDisabled },
    { getState }
  ) => {
    const { contentSettings } = getState()
    const rawLanguageSettings =
      contentSettings.rawContent?.languageSettings || {}
    const rawEmojisList = rawLanguageSettings?.emojis_list
    const isEmojisListWithoutInheritance = isEmojiInheritanceDisabled
    return updateProjectSettings(projectId, {
      regionId: regionId ?? null,
      brandVoiceId: brandVoiceId ?? null,
      // must include language settings, otherwise punctuation setting will no longer be returned
      languageSettings: {
        ...rawLanguageSettings,
        // TODO: remove check for isInherited when the feature flag is permanently on
        emojis_list: [
          ...rawEmojisList?.map((emoji) => ({
            lookup: isEmojisListWithoutInheritance
              ? emoji?.lookup
              : emoji?.lookup?.value,
            values: emoji.values.map((item) =>
              isEmojisListWithoutInheritance ? item : item?.value
            ),
          })),
        ],
        syns: rawLanguageSettings?.syns?.map((synonym) => ({
          phrase: synonym?.phrase,
          pattern: [
            [
              ...synonym?.pattern?.[0]?.map((pos) => ({
                LOWER: pos.LOWER,
                POS: pos.POS,
              })),
            ],
          ],
          replacements: synonym?.replacements?.map(
            (replacement) => replacement?.value
          ),
        })),
        vocabulary: {
          ...rawLanguageSettings.vocabulary,
          forbidden_words:
            rawLanguageSettings?.vocabulary?.forbidden_words?.map(
              (word) => word.value
            ),
          incompatible_words:
            rawLanguageSettings?.vocabulary?.incompatible_words?.map(
              (incompatibleWordsRow) => {
                return incompatibleWordsRow.map((word) => word.value)
              }
            ),
        },
        industry: undefined,
        iso_currency: undefined,
        // language should be set to blank string, so that it gets inherited from parent template
        iso_language: '',
      },
    })
  }
)

export const saveContent = createAsyncThunk<
  LanguageSettingsResponse,
  {
    projectId: string
    isEmojiInheritanceDisabled: boolean
  },
  { state: RootState }
>(
  'admin/content/save',
  async ({ projectId, isEmojiInheritanceDisabled }, { getState }) => {
    const state = getState()
    const { editedContent, rawContent } = state.contentSettings
    const rawLanguageSettings = rawContent?.languageSettings || {}

    const isAlternativeWordEmptyRow = (alternativeWord: AlternativeWord) =>
      !alternativeWord.originalWord.trim().length &&
      !alternativeWord.replacements.length
    const alternativeWordsWithoutEmptyRows =
      editedContent.alternativeWords.filter(
        (synonym) => !isAlternativeWordEmptyRow(synonym)
      )

    const isSynonymEmptyRow = (synonym: Synonym) =>
      !synonym.headToken.trim().length && !synonym.output.length
    const synonymsWithoutEmptyRows = editedContent.synonyms.filter(
      (synonym) => !isSynonymEmptyRow(synonym)
    )

    if (!editedContent.regionStructure.currency) {
      return Promise.reject('Must select a currency.')
    }
    if (!editedContent.regionStructure.dateFormat) {
      return Promise.reject('Must select a date format.')
    }
    if (!editedContent.regionStructure.segments?.length) {
      return Promise.reject('Must select at least one segment.')
    }
    if (
      alternativeWordsWithoutEmptyRows.some(
        (alternativeWord) => !isAlternativeWordValid(alternativeWord)
      )
    ) {
      return Promise.reject(
        'Some alternative words are invalid, please fill the original word and part of speech.'
      )
    }
    if (
      getDuplicatedAlternativeWords(alternativeWordsWithoutEmptyRows).length > 0
    ) {
      return Promise.reject(
        'Some alternative words are duplicated, please make sure that each combination of original word and part of speech is unique.'
      )
    }
    if (getDuplicatedSynonyms(synonymsWithoutEmptyRows).length > 0) {
      return Promise.reject(
        'Some synonyms are duplicated, please make sure that each combination of head token word and part of speech is unique.'
      )
    }

    if (synonymsWithoutEmptyRows.some((synonym) => !isSynonymValid(synonym))) {
      return Promise.reject(
        'Some synonyms are invalid, please fill all the columns.'
      )
    }

    const incompatibleWordsWithoutEmptyRows =
      editedContent.incompatibleWords.filter(
        (incompatibleWordRow) => incompatibleWordRow.length > 0
      )

    const content = {
      ...rawLanguageSettings,
      capitalization: {
        ...rawLanguageSettings.capitalization,
        caps_after_colon: editedContent.punctuation.isCapitalizingAfterColon,
        caps_after_dash: editedContent.punctuation.isCapitalizingAfterDash,
        caps_after_elps: editedContent.punctuation.isCapitalizingAfterEllipsis,
        uppercase_words: editedContent.stylistics.capitalizeWords,
        uppercase_leaders: editedContent.stylistics.capitalizeLeaders,
        uppercase_variants: editedContent.stylistics.capitalizeVariants,
        uppercase_probability:
          editedContent.stylistics.capitalizationProbability / 100,
        title_case: editedContent.stylistics.titleCase,
      },
      punctuation: {
        ...rawLanguageSettings.punctuation,
        space_before_elps: editedContent.punctuation.hasSpaceBeforeEllipsis,
        space_after_elps: editedContent.punctuation.hasSpaceAfterEllipsis,
        punctuation_options: {
          ...rawLanguageSettings.punctuation?.punctuation_option,
          lead_punctuation: editedContent.punctuation.leadPunctuations,
          mid_punctuation: editedContent.punctuation.middlePunctuations,
          end_punctuation: editedContent.punctuation.endPunctuations,
          curly_apostrophe: editedContent.punctuation.hasCurlyApostrophes,
        },
        force_final_punc_after_midpunc:
          editedContent.punctuation.isIncludingEndPunctuation,
        nonfunctional_options: editedContent.stylistics.stylisticsOptions,
        wrap_leaders: editedContent.stylistics.wrapLeaders,
        wrap_variants: editedContent.stylistics.wrapVariants,
        wrapping_probability:
          editedContent.stylistics.wrappingProbability / 100,
        emdash_spaces: rawLanguageSettings?.punctuation?.emdash_spaces ?? false,
      },
      vocabulary: {
        ...rawLanguageSettings.vocabulary,
        puns: false,
        colloquialisms: false,
        forbidden_words: editedContent.forbiddenWords.map((word) => word.value),
        incompatible_words: incompatibleWordsWithoutEmptyRows.map(
          (incompatibleWordsRow) => {
            return incompatibleWordsRow.map((word) => word.value)
          }
        ),
        word_replacements: [
          ...alternativeWordsWithoutEmptyRows.map((synonym) => ({
            phrase: synonym.originalWord,
            pattern: [
              [
                ...synonym.partOfSpeech.map((pos) => ({
                  LOWER: pos.label,
                  ...(pos.value === ''
                    ? undefined
                    : { POS: pos.value.toUpperCase() }),
                })),
              ],
            ],
            replacements: synonym?.replacements ?? [],
          })),
        ],
      },
      emoji_frequency: {
        ...rawLanguageSettings.emoji_frequency,
        left_emojis: editedContent.emojiSettings.leftFrequency / 10,
        right_emojis: editedContent.emojiSettings.rightFrequency / 10,
        double_emojis: editedContent.emojiSettings.isUsingDoubleEmoji,
      },
      emojis_list: [
        ...editedContent.emojis
          .map((emoji) => ({
            lookup: emoji.value,
            values: [...emoji.values.map((item: EmojiItem) => item.value)],
          }))
          .filter((emoji) => emoji?.lookup?.trim() !== ''),
      ],
      iso_currency: editedContent.regionStructure.currency,
      date_format: editedContent.regionStructure.dateFormat,
      content_structure: editedContent.regionStructure.segments,
      syns: synonymsWithoutEmptyRows.map((synonym) => ({
        phrase: synonym.headToken,
        pattern: [
          [
            ...synonym.partOfSpeech.map((pos) => ({
              LOWER: pos.label,
              ...(pos.value === ''
                ? undefined
                : { POS: pos.value.toUpperCase() }),
            })),
          ],
        ],
        replacements: synonym?.output.map((v) => v.value) ?? [],
      })),
      industry: undefined,
    }

    const { projectConfig } = await updateProjectSettings(projectId, {
      brandVoiceId: editedContent.regionStructure.brandVoiceId ?? null,
      regionId: editedContent.regionStructure.regionId ?? null,
      languageSettings: content,
    })

    const languageSettings = await fetchProjectCombinedLanguage(projectId)

    return {
      projectConfig,
      languageSettings,
    }
  }
)

export const contentSettingsSlice = createSlice({
  name: 'admin/contentSettings',
  initialState,
  reducers: {
    addForbiddenWord: (state, action: PayloadAction<ForbiddenWord>) => {
      const newForbiddenWord: any = {
        value: action.payload.value.toLowerCase(),
        inherited: false,
      }
      state.editedContent.forbiddenWords = [
        ...state.editedContent.forbiddenWords,
        newForbiddenWord,
      ]
    },
    removeForbiddenWord: (state, action: PayloadAction<string>) => {
      state.editedContent.forbiddenWords =
        state.editedContent.forbiddenWords.filter(
          (word) => word?.value !== action.payload
        )
    },
    resetContent: (state) => {
      if (state.rawContent) {
        state.editedContent = processInitalizePageResponse({
          ...state.rawContent,
          isEmojiInheritanceDisabled: state.isEmojiInheritanceDisabled,
        })
      }
    },
    clickSpaceBeforeEllipsis: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.hasSpaceBeforeEllipsis = action.payload
    },
    clickSpaceAfterEllipsis: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.hasSpaceAfterEllipsis = action.payload
    },
    clickCapitablizeAfterColon: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.isCapitalizingAfterColon = action.payload
    },
    clickCapitablizeAfterDash: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.isCapitalizingAfterDash = action.payload
    },
    clickCapitablizeAfterEllipsis: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.isCapitalizingAfterEllipsis =
        action.payload
    },
    clickCurlyApostrophes: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.hasCurlyApostrophes = action.payload
    },
    clickIncludeEndPunctuation: (state, action: PayloadAction<boolean>) => {
      state.editedContent.punctuation.isIncludingEndPunctuation = action.payload
    },
    clickAddMiddlePunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.middlePunctuations.push(action.payload)
    },
    clickRemoveMiddlePunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.middlePunctuations =
        state.editedContent.punctuation.middlePunctuations.filter(
          (punctuation) => punctuation !== action.payload
        )
    },
    clickAddLeadPunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.leadPunctuations.push(action.payload)
    },
    clickRemoveLeadPunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.leadPunctuations =
        state.editedContent.punctuation.leadPunctuations.filter(
          (punctuation) => punctuation !== action.payload
        )
    },
    clickAddEndPunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.endPunctuations.push(action.payload)
    },
    clickRemoveEndPunctuation: (state, action: PayloadAction<string>) => {
      state.editedContent.punctuation.endPunctuations =
        state.editedContent.punctuation.endPunctuations.filter(
          (punctuation) => punctuation !== action.payload
        )
    },
    clickWrapLeaders: (state, action: PayloadAction<boolean>) => {
      state.editedContent.stylistics.wrapLeaders = action.payload
    },
    clickWrapVariants: (state, action: PayloadAction<boolean>) => {
      state.editedContent.stylistics.wrapVariants = action.payload
    },
    clickCapitalizeLeaders: (state, action: PayloadAction<boolean>) => {
      state.editedContent.stylistics.capitalizeLeaders = action.payload
    },
    clickCapitalizeVariants: (state, action: PayloadAction<boolean>) => {
      state.editedContent.stylistics.capitalizeVariants = action.payload
    },
    addStylistic: (state, action: PayloadAction<string>) => {
      state.editedContent.stylistics.stylisticsOptions = [
        ...state.editedContent.stylistics.stylisticsOptions,
        action.payload.replaceAll('\\\\', '\\').toLowerCase(),
      ]
    },
    removeStylistic: (state, action: PayloadAction<string>) => {
      state.editedContent.stylistics.stylisticsOptions =
        state.editedContent.stylistics.stylisticsOptions.filter(
          (word) => word !== action.payload
        )
    },
    addCapitalisedWord: (state, action: PayloadAction<string>) => {
      state.editedContent.stylistics.capitalizeWords = [
        ...state.editedContent.stylistics.capitalizeWords,
        action.payload.toLowerCase(),
      ]
    },
    removeCapitalisedWord: (state, action: PayloadAction<string>) => {
      state.editedContent.stylistics.capitalizeWords =
        state.editedContent.stylistics.capitalizeWords.filter(
          (word) => word !== action.payload
        )
    },
    updateWrappingProbability: (state, action: PayloadAction<number>) => {
      state.editedContent.stylistics.wrappingProbability = action.payload
    },
    updateCapitalisationProbability: (state, action: PayloadAction<number>) => {
      state.editedContent.stylistics.capitalizationProbability = action.payload
    },
    updateTitleCase: (state, action: PayloadAction<boolean>) => {
      state.editedContent.stylistics.titleCase = action.payload
    },

    addAlternativeWordsRow: (state) => {
      state.editedContent.alternativeWords = [
        {
          originalWord: '',
          partOfSpeech: [],
          replacements: [],
          id: 0,
          hasError: false,
        },
        ...state.editedContent.alternativeWords.map((synonym) => ({
          ...synonym,
          id: synonym.id + 1,
        })),
      ]
    },
    updateAlternativeWordsCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const synonym = state.editedContent.alternativeWords[rowIndex]
      synonym[columnId] = value
      synonym.hasError = synonym.hasError
        ? !isAlternativeWordValid(synonym)
        : false
    },
    deleteAlternativeWordsRow: (state, action: PayloadAction<number>) => {
      state.editedContent.alternativeWords =
        state.editedContent.alternativeWords
          .filter((_, index) => index !== action.payload)
          .map((synonym, index) => ({ ...synonym, id: index }))
    },
    deactivateAlternativeWordsRows: (
      state,
      action: PayloadAction<number[]>
    ) => {
      const isRowDeactivated = (rowIndex: number) =>
        action.payload.includes(rowIndex)

      state.editedContent.alternativeWords =
        state.editedContent.alternativeWords.map((synonym) =>
          isRowDeactivated(synonym.id)
            ? {
                ...synonym,
                replacements: [],
              }
            : synonym
        )
    },

    selectCurrency: (state, action: PayloadAction<string>) => {
      state.editedContent.regionStructure.currency = action.payload
    },
    selectDateFormat: (state, action: PayloadAction<string>) => {
      state.editedContent.regionStructure.dateFormat = action.payload
    },
    selectSegments: (state, action: PayloadAction<Segment[]>) => {
      const selectedSegments = action.payload
      const { segments } = state.editedContent.regionStructure

      state.editedContent.regionStructure.segments = selectedSegments.map(
        (segment, index) => {
          const configuredSegment = segments?.find(
            (selected) => selected.name === segment.name
          )
          const ordinal = index + 1

          if (configuredSegment) {
            return { ...segment, length: configuredSegment.length, ordinal }
          } else {
            return { ...segment, ordinal }
          }
        }
      )
    },
    changeSegmentLength: (
      state,
      action: PayloadAction<{ name: string; value: number }>
    ) => {
      const { segments } = state.editedContent.regionStructure
      const { name, value } = action.payload
      const segment = segments?.find((segment) => segment.name === name)
      if (!segment) {
        throw new Error('Trying to update an unselected segment')
      }

      segment.length = value
    },
    onSegmentLengthBlur: (state, action: PayloadAction<string>) => {
      const { segments } = state.editedContent.regionStructure
      const segment = segments?.find(
        (segment) => segment.name === action.payload
      )
      if (!segment) {
        throw new Error('Trying to update an unselected segment')
      }

      if (segment.length <= MIN_SEGMENT_LENGTH) {
        segment.length = MIN_SEGMENT_LENGTH
      } else if (segment.max && segment.length > segment.max) {
        segment.length = segment.max
      }
    },
    updateEmojiSettingsLeftFrequency: (
      state,
      action: PayloadAction<number>
    ) => {
      state.editedContent.emojiSettings.leftFrequency = action.payload
      if (
        action.payload === 0 &&
        state.editedContent.emojiSettings.rightFrequency === 0
      ) {
        state.editedContent.emojiSettings.isUsingDoubleEmoji = false
      }
    },
    updateEmojiSettingsRightFrequency: (
      state,
      action: PayloadAction<number>
    ) => {
      state.editedContent.emojiSettings.rightFrequency = action.payload
      if (
        action.payload === 0 &&
        state.editedContent.emojiSettings.leftFrequency === 0
      ) {
        state.editedContent.emojiSettings.isUsingDoubleEmoji = false
      }
    },
    clickIsUsingDoubleEmoji: (state, action: PayloadAction<boolean>) => {
      state.editedContent.emojiSettings.isUsingDoubleEmoji = action.payload
    },
    addIncompatibleWord: (
      state,
      action: PayloadAction<{
        value: string
        index: number
      }>
    ) => {
      const { value, index } = action.payload

      const wordsArray: string[] = state.editedContent.incompatibleWords[
        index
      ].map((word) => word?.value)

      const isWordAlreadyIncluded = wordsArray.includes(value.toLowerCase())
      if (!isWordAlreadyIncluded) {
        const newWord = {
          value: value.toLowerCase(),
          isInherited: false,
        }

        state.editedContent.incompatibleWords[index].push(newWord)
      }
    },
    removeIncompatibleWord: (
      state,
      action: PayloadAction<{ value: string; index: number }>
    ) => {
      const wordIndex = state.editedContent.incompatibleWords[
        action.payload.index
      ]
        .map((word) => word?.value)
        .indexOf(action.payload.value)
      state.editedContent.incompatibleWords[action.payload.index].splice(
        wordIndex,
        1
      )
    },
    addIncompatibleWordsRow: (state) => {
      state.editedContent.incompatibleWords.unshift([])
    },
    removeIncompatibleWordsRow: (state, action: PayloadAction<number>) => {
      state.editedContent.incompatibleWords.splice(action.payload, 1)
    },

    addSynonymRow: (state) => {
      state.editedContent.synonyms = [
        {
          headToken: '',
          partOfSpeech: [],
          output: [],
          id: 0,
          hasError: false,
          isInherited: false,
        },
        ...state.editedContent.synonyms.map((synonym) => ({
          ...synonym,
          id: synonym.id + 1,
        })),
      ]
    },
    deleteSynonymRow: (state, action: PayloadAction<number>) => {
      state.editedContent.synonyms = state.editedContent.synonyms
        .filter((_, index) => index !== action.payload)
        .map((synonym, index) => ({ ...synonym, id: index }))
    },
    updateSynonymCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const synonym = state.editedContent.synonyms[rowIndex]
      synonym[columnId] = value
      synonym.hasError = synonym.hasError ? !isSynonymValid(synonym) : false
    },

    addEmojiRow: (state) => {
      state.editedContent.emojis = [
        {
          value: '',
          isInherited: false,
          values: [],
        },
        ...state.editedContent.emojis,
      ]
    },
    updateEmojiCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const emoji = state.editedContent.emojis[rowIndex]
      emoji[columnId] = value
    },
    deactivateEmojis: (state, action: PayloadAction<Emoji[]>) => {
      const selectedEmojis = action.payload
      state.editedContent.emojis = state.editedContent.emojis.map((emoji) => {
        const isSelectedEmoji = selectedEmojis.some((selectedEmoji) => {
          return selectedEmoji.value === emoji.value
        })
        if (!isSelectedEmoji) {
          return { ...emoji }
        }

        return {
          ...emoji,
          values: emoji.isInherited
            ? [
                ...emoji.values.filter((value) => {
                  return value.isInherited
                }),
              ]
            : [],
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializePage.fulfilled, (state, action) => {
      state.rawContent = action.payload
      state.isEmojiInheritanceDisabled =
        action.payload.isEmojiInheritanceDisabled
      state.distributionChannel =
        action.payload.projectConfig.mappedUIFields.distributionChannel
      state.languageGenerationMethod =
        action.payload.projectConfig.mappedUIFields.languageGenerationMethod
      state.editedContent = processInitalizePageResponse(action.payload)
    })
    builder.addCase(saveRegion.pending, (state) => {
      state.isSavingRegion = true
    })
    builder.addCase(
      saveRegion.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.isSavingRegion = false
        state.editedContent.regionStructure.regionId = action.payload
      }
    )
    builder.addCase(saveRegion.rejected, (state) => {
      state.isSavingRegion = false
      errorToast('Failed to save template')
    })
    builder.addCase(saveContent.pending, (state) => {
      state.isSaving = true
    })
    builder.addCase(saveContent.fulfilled, (state, action) => {
      state.isSaving = false
      state.rawContent = action.payload
      state.editedContent = processInitalizePageResponse({
        ...action.payload,
        isEmojiInheritanceDisabled: state.isEmojiInheritanceDisabled,
      })
    })
    builder.addCase(saveContent.rejected, (state) => {
      state.isSaving = false
      const duplicatedAlternativeWords = getDuplicatedAlternativeWords(
        state.editedContent.alternativeWords
      )
      const duplicatedSynonyms = getDuplicatedSynonyms(
        state.editedContent.synonyms
      )
      state.editedContent.alternativeWords =
        state.editedContent.alternativeWords.map((alternativeWord) => ({
          ...alternativeWord,
          hasError:
            !isAlternativeWordValid(alternativeWord) ||
            duplicatedAlternativeWords.includes(alternativeWord),
        }))
      state.editedContent.synonyms = state.editedContent.synonyms.map(
        (synonym) => ({
          ...synonym,
          hasError:
            !isSynonymValid(synonym) || duplicatedSynonyms.includes(synonym),
        })
      )
    })
  },
})

/*
 * Actions
 */
export const {
  addForbiddenWord,
  removeForbiddenWord,
  resetContent,
  clickSpaceBeforeEllipsis,
  clickSpaceAfterEllipsis,
  clickCapitablizeAfterColon,
  clickCapitablizeAfterDash,
  clickCapitablizeAfterEllipsis,
  clickCurlyApostrophes,
  clickIncludeEndPunctuation,
  clickAddLeadPunctuation,
  clickRemoveLeadPunctuation,
  clickAddEndPunctuation,
  clickRemoveEndPunctuation,
  clickAddMiddlePunctuation,
  clickRemoveMiddlePunctuation,
  clickWrapLeaders,
  clickWrapVariants,
  clickCapitalizeLeaders,
  clickCapitalizeVariants,
  addStylistic,
  removeStylistic,
  addCapitalisedWord,
  removeCapitalisedWord,
  updateWrappingProbability,
  updateCapitalisationProbability,
  updateTitleCase,
  addAlternativeWordsRow,
  updateAlternativeWordsCell,
  deleteAlternativeWordsRow,
  deactivateAlternativeWordsRows,
  selectCurrency,
  selectDateFormat,
  selectSegments,
  changeSegmentLength,
  clickIsUsingDoubleEmoji,
  updateEmojiSettingsLeftFrequency,
  updateEmojiSettingsRightFrequency,
  addIncompatibleWord,
  removeIncompatibleWord,
  removeIncompatibleWordsRow,
  addIncompatibleWordsRow,
  onSegmentLengthBlur,
  addSynonymRow,
  deleteSynonymRow,
  updateSynonymCell,
  addEmojiRow,
  deactivateEmojis,
  updateEmojiCell,
} = contentSettingsSlice.actions

/*
 * Selectors
 */
export const selectHasBeenEdited = (state: RootState): boolean =>
  !isEqual(selectOriginalContent(state), state.contentSettings.editedContent)

export const selectOriginalContent = (state: RootState): Content | undefined =>
  state.contentSettings.rawContent &&
  processInitalizePageResponse({
    ...state.contentSettings.rawContent,
    isEmojiInheritanceDisabled:
      state.contentSettings.isEmojiInheritanceDisabled,
  })

export const selectEmojisForTable = (state: RootState) =>
  state.contentSettings.editedContent.emojis.map((emoji) => ({
    ...emoji,
    triggerWords: emoji.values.map((value) => value.value),
    isDisabled: emoji.isInherited,
  }))

export const selectSynonymTags = (state: RootState) =>
  state.contentSettings.editedContent.synonyms.map((synonym) => ({
    ...synonym,
    output: synonym.output.map((value) => ({
      label: value.value,
      value: value.value,
      isDisabled: value.isInherited,
    })),
  }))

export default contentSettingsSlice.reducer
