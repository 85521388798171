import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { successToast } from 'common/components/toastNotification'
import { Node, NodeTreeMetadata } from 'common/interfaces/nodes'

import { deleteNode } from '../api'
import { contentKeys } from '../queryKeys'

type MutationContext = { nodes: Node[] }

const useDeleteNodeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteNode, {
    onMutate: ({ nodeId }) => {
      queryClient.cancelQueries(contentKeys.nodes())

      const previousNodes = queryClient.getQueryData<{ nodes: Node[] }>(
        contentKeys.nodes()
      )

      queryClient.setQueryData<{
        nodes: Node[]
        metadata: NodeTreeMetadata
      }>(contentKeys.nodes(), (old) => {
        if (!old) {
          return old
        }

        return {
          metadata: old.metadata,
          nodes: old.nodes.filter((node) => parseInt(node.id) !== nodeId),
        }
      })

      return previousNodes as MutationContext
    },
    onSuccess: () => successToast('Node deleted successfully'),
    onError: (error) => {
      queryClient.setQueryData<{
        nodes: Node[]
        metadata: NodeTreeMetadata
      }>(contentKeys.nodes(), (old) => {
        if (!old) {
          return old
        }

        return {
          metadata: old.metadata,
          nodes: old.nodes,
        }
      })

      showErrorToast(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.nodes())
    },
  })
}

export default useDeleteNodeMutation
