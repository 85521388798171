import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { updateBrandTonesForBrandVoice } from '../api'
import { brandVoiceKeys } from '../queryKeys'

const useUpdateBrandTonesForBrandVoiceMutation = (
  onDoneEditing: Function,
  accountId: string,
  brandVoiceId: string
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateBrandTonesForBrandVoice,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandVoiceKeys.details(accountId, brandVoiceId),
      })
      onDoneEditing()
    },
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
  })
}

export default useUpdateBrandTonesForBrandVoiceMutation
