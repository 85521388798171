import {
  accountSettingsPermissions,
  routesPermissions,
} from 'common/auth/permissions'
import {
  Account,
  Campaigns as CampaignsIcon,
  Edit as EditIcon,
  Insights,
  Performance,
  Reports as ReportsIcon,
  Settings,
  Users,
} from 'common/icons'
import Admin from 'features/admin'
import AdminAccountSettings from 'features/admin/accountSettings'
import BrandVoiceRedirect from 'features/admin/brandVoice/BrandVoiceRedirect'
import DataSources from 'features/admin/dataSources'
import AdminRegionSettings from 'features/admin/regions/RegionSettings'
import AdminUsers from 'features/admin/users'
import Campaigns from 'features/campaigns/Campaigns'
import ContentNodes from 'features/content/nodes'
import DemoPage from 'features/demo/DemoPage'
import FuturamaContentsPage from 'features/futurama/contentsPage'
import Personalization from 'features/personalization'
import Projects from 'features/projects'
import ReportsInsights from 'features/reports/insights'
import ReportsPerformance from 'features/reports/performance'
import Reports from 'features/reports/Reports'
import UnifiedFlow from 'features/unifiedFlow'

import type { StaticRoute, Subroute } from './interfaces'
import { dispatchClickNavItem } from './navigationSlice'
import {
  adminAccountPath,
  brandVoicePath,
  createProjectPath,
  dataSourcesPath,
  demoPath,
  projectsPath,
  reportsInsightsPath,
  reportsPath,
  reportsPerformancePath,
  reportsPersonalizationPath,
  selectedCampaignPath,
  unifiedFlowPaths,
} from './paths'
import { accountSettingsSubroutes } from './staticSubRoutes.accountSettings'

const projectsCreateRoute: StaticRoute = {
  key: 'projects-create',
  component: Campaigns,
  path: createProjectPath,
  name: 'project',
  to: createProjectPath,
  icon: CampaignsIcon,
  accessLevel: 'hero',
  accessPermission: routesPermissions.projectsPageView,
  accessFlags: { flags: ['projectScreens'], type: 'hide' },
  shouldValidateAccountAccess: true,
}

const projectSubRoute: Subroute = {
  key: 'projects',
  component: Projects,
  name: 'projects',
  to: projectsPath,
  icon: CampaignsIcon,
  accessPermission: routesPermissions.projectsPageView,
  accessFlags: { flags: ['projectScreens'], type: 'hide' },
  shouldValidateAccountAccess: true,
}

export const campaignsRoute: StaticRoute = {
  key: 'campaigns',
  component: Campaigns,
  path: ['/accounts/:accountId/campaigns', selectedCampaignPath],
  name: 'experiments',
  to: '/accounts/:accountId/campaigns',
  icon: CampaignsIcon,
  accessLevel: 'hero',
  accessPermission: routesPermissions.experimentsView,
  shouldValidateAccountAccess: true,
}

export const personalizationSubRoute: Subroute = {
  key: 'personalization',
  component: Personalization,
  icon: EditIcon,
  name: 'Personalized Campaigns',
  to: reportsPersonalizationPath,
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showPersonalizationDataImport'],
    type: 'hide',
  },
}
export const reportsRoute: StaticRoute = {
  key: 'reports',
  component: Reports,
  path: ['/reports', reportsPath],
  name: 'reports',
  to: reportsPath,
  icon: ReportsIcon,
  accessLevel: 'hero',
  accessPermission: routesPermissions.reportsView,
  shouldValidateAccountAccess: true,
  accessFlags: {
    flags: ['showReportsPage'],
    type: 'disable',
  },
  subroutes: [
    {
      icon: Performance,
      key: 'performance',
      name: 'Performance',
      component: ReportsPerformance,
      to: reportsPerformancePath,
      onClick: dispatchClickNavItem,
      accessFlags: {
        flags: ['showReportsPage'],
        type: 'hide',
      },
    },
    {
      icon: Insights,
      key: 'insights',
      name: 'Insights',
      component: ReportsInsights,
      to: reportsInsightsPath,
      onClick: dispatchClickNavItem,
      accessFlags: {
        flags: ['showReportsPage'],
        type: 'hide',
      },
    },
    personalizationSubRoute,
  ],
}

export const adminUsersSubroute: Subroute = {
  icon: Users,
  key: 'users',
  name: 'users',
  component: AdminUsers,
  to: '/admin/users',
  accessPermission: routesPermissions.usersView,
  onClick: dispatchClickNavItem,
}

export const dataSourcesSubroute: Subroute = {
  icon: Settings,
  key: 'data-sources',
  name: 'Data sources',
  component: DataSources,
  to: dataSourcesPath,
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showPersonalizationDataImport'],
    type: 'hide',
  },
  accessPermission: routesPermissions.dataSourcesCreate,
}

const adminDemoRoute: Subroute = {
  icon: EditIcon,
  key: 'demo',
  name: 'Personalized Campaigns Demo',
  to: demoPath,
  component: DemoPage,
  accessPermission: routesPermissions.demoPageView,
  onClick: dispatchClickNavItem,
  shouldValidateAccountAccess: false,
}

export const brandVoiceSubroute: Subroute = {
  icon: Settings,
  key: 'brand-voice',
  name: 'Brand Voice',
  component: BrandVoiceRedirect,
  to: brandVoicePath,
  onClick: dispatchClickNavItem,
  accessPermission: routesPermissions.brandVoiceSettingsView,
}

export const adminRegionSettingsRoute: Subroute = {
  icon: Settings,
  key: 'region',
  name: 'region',
  component: AdminRegionSettings,
  to: '/admin/language/region',
  onClick: dispatchClickNavItem,
  accessPermission: routesPermissions.contentView,
}

export const adminSettingsRoute: Subroute = {
  icon: Settings,
  key: 'settings',
  name: 'settings',
  component: AdminAccountSettings,
  to: adminAccountPath,
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showAdminAccountSettingsPage'],
    type: 'hide',
  },
  tabbedNavRoutes: accountSettingsSubroutes,
  accessPermission: accountSettingsPermissions.accountSettingsView,
}

export const futuramaContentRouteForTopNav: StaticRoute = {
  key: 'content',
  path: '/content',
  component: FuturamaContentsPage,
  icon: EditIcon,
  name: 'Content',
  to: '/content',
  accessLevel: 'superhero',
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showFuturamaPage'],
    type: 'hide',
  },
}

export const unifiedFlowRoute: StaticRoute = {
  key: 'unified-flow',
  path: unifiedFlowPaths.home,
  component: UnifiedFlow,
  icon: EditIcon,
  name: 'Unified flow',
  to: unifiedFlowPaths.home,
  accessLevel: 'superhero',
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showUnifiedFlow'],
    type: 'hide',
  },
}

export const languageSubroute: Subroute = {
  icon: EditIcon,
  key: 'language',
  name: 'language',
  component: ContentNodes,
  to: '/admin/language/region',
  onClick: dispatchClickNavItem,
  accessPermission: routesPermissions.languageView,
}

export const createContentRoutes: StaticRoute[] = [
  campaignsRoute,
  projectsCreateRoute,
  unifiedFlowRoute,
  futuramaContentRouteForTopNav,
]

export const adminRoutes: StaticRoute = {
  key: 'admin',
  path: '/admin',
  component: Admin,
  name: 'admin',
  to: adminAccountPath,
  icon: Account,
  accessLevel: 'superhero',
  accessFlags: {
    flags: ['showAdminPage'],
    type: 'disable',
  },
  subroutes: [
    projectSubRoute,
    brandVoiceSubroute,
    adminUsersSubroute,
    adminSettingsRoute,
    languageSubroute,
    dataSourcesSubroute,
    adminDemoRoute,
  ],
}

export const topNavStaticRoutes: StaticRoute[] = [
  campaignsRoute,
  futuramaContentRouteForTopNav,
  unifiedFlowRoute,
  reportsRoute,
  adminRoutes,
]
