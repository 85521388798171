import ActionBar from 'workflow/ActionBar'

import { ReactComponent as MagicIcon } from 'common/icons/magic2/default.svg'
import { ReactComponent as RefreshIcon } from 'common/icons/refresh/default.svg'

type Props = {
  actionType: 'generate' | 'refresh'
  isLoading: boolean
  isSaving?: boolean
  isDisabled: boolean
  isSaveButtonShown: boolean
  onMagicButtonClick: () => void
  onSaveButtonClick: () => void
}

const GenerateActionBar = ({
  isLoading,
  isDisabled,
  isSaving = false,
  isSaveButtonShown,
  actionType,
  onMagicButtonClick,
  onSaveButtonClick,
}: Props) => {
  const nextInfoMessage =
    actionType === 'generate' ? 'Next up...' : 'A refresh is needed'
  const name = actionType === 'generate' ? 'Generate' : 'Refresh'
  const Icon = actionType === 'generate' ? MagicIcon : RefreshIcon

  return (
    <ActionBar
      nextInfoMessage={!isLoading && nextInfoMessage}
      saveButton={{
        isDisabled,
        isLoading: isSaving,
        onClick: onSaveButtonClick,
        isDisplayed: isSaveButtonShown,
      }}
      nextButton={{
        isDisabled: isDisabled,
        name: isLoading ? 'Generate' : name,
        onClick: onMagicButtonClick,
        isLoading: isLoading,
        icon: !isLoading ? <Icon width={16} height={16} /> : null,
        variant: isLoading ? 'success' : 'primary',
      }}
    />
  )
}
export default GenerateActionBar
