import MessageComponent from 'workflow/common/MessageComponent'

import LogoutButton from 'auth/LogoutButton'

const LogoutSso = (): JSX.Element => {
  return (
    <>
      <MessageComponent
        isErrorStaticPage={false}
        textPrimary="Oops, it appears you don't have access. 
          Please sign out and log in with Jacquard credentials."
        textSecondary={<LogoutButton />}
      />
    </>
  )
}

export default LogoutSso
