import React, { ReactNode } from 'react'
import { Select } from 'antd'

const { Option } = Select

interface Props {
  value: string | number
  children: ReactNode
  label?: string | number
  className?: string
  disabled?: boolean
}

const SelectOption = ({ value, children, ...rest }: Props) => {
  return (
    <Option key={value} value={value} {...rest}>
      {children}
    </Option>
  )
}

export default SelectOption
