import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchBrands } from '../api'
import { demoKeys } from '../queryKeys'

const useGetBrandsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const query = useQuery(demoKeys.brands, () => fetchBrands(), {
    enabled: !!accountId,
    retry: false,
    select: (data) => {
      return data.map((brand) => {
        return {
          ...brand,
          label: brand.name,
        }
      })
    },
    meta: {
      errorMessage: 'Brand information could not be loaded',
    },
  })

  return query
}

export default useGetBrandsQuery
