import cx from 'classnames'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import MenuButton, { MenuItem } from 'common/components/menuButton'
import Base from 'common/components/table/cells/Base'
import {
  Bin,
  Clipboard,
  MoreOptions,
  Refresh,
  Spinner,
  Switch,
} from 'common/icons'

type Props = {
  onCopy?: () => void
  onRefresh?: () => void
  onDelete?: () => void
  onRequestChange?: () => void
  isRefreshing?: boolean
}

const ActionCell = ({
  onCopy,
  onRefresh,
  onDelete,
  onRequestChange,
  isRefreshing,
}: Props) => {
  return (
    <Base className="group invisible text-coolGray-400 group-hover:visible">
      {onRefresh && (
        <ButtonWithTooltip
          className={cx({ 'group visible': isRefreshing })}
          tooltip
          tooltipText={isRefreshing ? 'Refreshing...' : 'Refresh'}
          aria-label="Refresh"
          size="small"
          prefixIcon={
            isRefreshing ? (
              <Spinner className="animate-spin text-maroon-300" />
            ) : (
              <Refresh
                isDefaultColor={false}
                className="hover:text-maroon-300"
              />
            )
          }
          onClick={!isRefreshing ? onRefresh : undefined}
        />
      )}
      {onRequestChange && (
        <ButtonWithTooltip
          tooltip
          tooltipText="Request a change"
          aria-label="Request change"
          size="small"
          prefixIcon={<Switch />}
          onClick={onRequestChange}
        />
      )}
      {(onCopy || onDelete) && (
        <MenuButton
          portal
          buttonProps={{
            'aria-label': 'More options',
            children: (
              <MoreOptions
                isDefaultColor={false}
                className="hover:text-maroon-300"
              />
            ),
          }}
          options={
            <>
              {onCopy && (
                <MenuItem key="1" onClick={onCopy} aria-label="Copy">
                  <Clipboard
                    isDefaultColor={false}
                    className="mr-4 text-coolGray-400"
                  />
                  Copy to clipboard
                </MenuItem>
              )}
              {onDelete && (
                <MenuItem key="2" onClick={onDelete} aria-label="Delete">
                  <Bin
                    isDefaultColor={false}
                    className="mr-4 text-coolGray-400"
                  />
                  Delete
                </MenuItem>
              )}
            </>
          }
        />
      )}
    </Base>
  )
}

export default ActionCell
