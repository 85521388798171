export enum IsoCurrency {
  GBP = 'GBP',
  EUR = 'EUR',
  USD = 'USD',
}

export const isoCurrencyArray = Object.values(IsoCurrency)

export const currencyToSymbol = {
  [IsoCurrency.GBP]: '£',
  [IsoCurrency.EUR]: '€',
  [IsoCurrency.USD]: '$',
}

export const isoCurrencyToLanguage = {
  [IsoCurrency.GBP]: 'en-GB',
  [IsoCurrency.EUR]: 'de-DE',
  [IsoCurrency.USD]: 'en-US',
}

export const formatCurrency = (
  value: number | bigint,
  isoCurrency: IsoCurrency = IsoCurrency.GBP
) => {
  const isoLanguage = isoCurrencyToLanguage[isoCurrency]
  if (!isoLanguage) {
    throw new Error(`IsoLanguage not found for iso currency '${isoCurrency}'`)
  }

  return new Intl.NumberFormat(isoLanguage, {
    style: 'currency',
    currency: isoCurrency,
  }).format(value)
}
