import SingleSelect, {
  SelectValue,
  SingleValue,
} from 'common/components/singleSelect'

import { selectCustomStyles, VariantStatus } from './statusSelectStyles'

type Props = Pick<
  React.ComponentProps<typeof SingleSelect>,
  | 'label'
  | 'className'
  | 'placeholder'
  | 'data-testid'
  | 'isDisabled'
  | 'value'
  | 'onChange'
  | 'options'
>

export const StatusSelect = ({
  label,
  className,
  placeholder,
  'data-testid': dataTestId,
  isDisabled,
  value,
  onChange,
  options,
}: Props) => {
  return (
    <SingleSelect
      label={label}
      className={className}
      placeholder={placeholder}
      data-testid={dataTestId}
      isDisabled={isDisabled}
      value={value}
      onChange={onChange}
      options={options}
      customStyles={selectCustomStyles({
        variantStatus: value as VariantStatus,
      })}
      isInsideTable={true}
      isMinimized={true}
    />
  )
}

export type { SingleValue, SelectValue }
export default StatusSelect
