import cx from 'classnames'

import { Radio as RadioIconComponent } from 'common/icons'
import { IconType } from 'common/icons/Icon'

import { RadioLabel } from '../../RadioGroup'

import styles from './Radio.module.css'

export type RadioIcon =
  | IconType
  | React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined
      }
    >

export type RadioVariant = 'normal' | 'withBackground' | 'large'

export interface RadioProps {
  isChecked: boolean
  name: string
  label: RadioLabel
  variant?: RadioVariant
  icon?: RadioIcon
  onChange: () => void
  isDisabled?: boolean
  className?: string
  'data-cy'?: string
}

const Radio = ({
  label,
  isDisabled,
  variant = 'normal',
  'data-cy': dataCy,
  isChecked,
  onChange,
  icon: Icon,
  className,
  name,
}: RadioProps) => {
  const isVariantWithBackground = variant === 'withBackground'
  return (
    <label
      style={{ gridTemplateRows: '32px auto' }}
      className={cx(
        'group cursor-pointer text-coolGray-800 ',
        {
          'flex items-center': ['normal', 'withBackground'].includes(variant),
          'border px-3 py-2': isVariantWithBackground,
          'border-coolGray-300': isVariantWithBackground && !isChecked,
          [`relative grid justify-items-center gap-2 border-2 border-coolGray-300 
                py-4 px-4 bg-white font-medium
                `]: variant === 'large',
          'border-maroon-500 text-maroon-500 bg-maroon-50':
            isChecked && ['large', 'withBackground'].includes(variant),
          'cursor-not-allowed': isDisabled,
          'hover:text-maroon-600': !isDisabled,
          'hover:bg-maroon-50':
            ['large', 'withBackground'].includes(variant) && !isDisabled,
          'text-maroon-300': isChecked && !isDisabled,
          'bg-coolGray-100 text-coolGray-400':
            variant === 'large' && isDisabled,
        },
        className
      )}
    >
      <input
        data-cy={dataCy}
        className="sr-only"
        type="radio"
        checked={isChecked}
        name={name}
        onChange={onChange}
        disabled={isDisabled}
      />{' '}
      {Icon && (
        <Icon
          className={cx('w-8 h-8', {
            'group-hover:text-maroon-600': !isDisabled,
            'text-maroon-300': isChecked,
            'text-coolGray-400': !isChecked,
          })}
          size={8}
          state={isChecked ? 'selected' : 'default'}
        />
      )}
      <RadioIconComponent
        className={cx({
          [styles.iconDisabled]: isDisabled && !isChecked,
          [cx(styles.iconWhite, 'group-hover:text-maroon-600')]:
            !isDisabled && !isChecked,
          'text-maroon-300': isChecked,
          'text-coolGray-400': !isChecked,
          'absolute top-2 right-2': variant === 'large',
        })}
        state={isChecked ? 'selected' : 'default'}
        isDefaultColor={false}
      />
      <span
        className={cx(' leading-normal ', {
          'mt-2 text-center': variant === 'large',
          'ml-2': ['normal', 'withBackground'].includes(variant),
          'text-coolGray-800': variant === 'normal',
        })}
      >
        {label}
      </span>
    </label>
  )
}

export default Radio
