import { ChangeEvent, useState } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import TextArea from 'antd/lib/input/TextArea'
import { toneOfVoicePath } from 'app/navigation/paths'
import cx from 'classnames'

import Button from 'common/components/button'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useLocalStorage } from 'common/hooks/useLocalStorage'
import { Magic2 as MagicHatIcon } from 'common/icons'
import useGetBrandVoiceDetailsQuery from 'features/admin/brandVoice/api/queries/useGetBrandVoiceDetailsQuery'
import { getTonesWithColorInfo } from 'features/admin/brandVoice/store/brandVoiceSlice'

import BrandVoiceMessage from './BrandVoiceMessage'
import CharactersCounter from './CharactersCounter'

const MIN_CHARACTERS = 500
const MAX_CHARACTERS = 5000

type Props = {
  className?: string
  generateTone: (value: string) => void
}

const SampleContent = ({ className, generateTone }: Props) => {
  const history = useHistory()

  const { accountId, brandVoiceId } =
    useParams<{ accountId: string; brandVoiceId: string }>()

  const { data: brandVoice } = useGetBrandVoiceDetailsQuery(
    accountId,
    brandVoiceId
  )

  const brandVoiceTones = getTonesWithColorInfo(brandVoice)
  const toneCount = brandVoiceTones ? brandVoiceTones.length : 0

  const storageKey = `sample-${brandVoiceId}`
  const [value, setValue] = useLocalStorage(storageKey, '')

  const [numberOfCharacters, setNumberOfCharacters] = useState<number>(
    value ? value.length : 0
  )

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    const trimmedValue = value.trim().replace(/[\r\n]/gm, '')
    if (brandVoiceId) {
      setValue(trimmedValue)
    }
    setNumberOfCharacters(trimmedValue.length)
  }

  return (
    <Widget
      type="basic"
      className={cx(
        'grid grid-cols-1 sm:grid-cols-3 grid-flow-col gap-x-6 gap-y-0',
        className
      )}
    >
      <WidgetHeader
        title="Example content"
        subtitle="Enter content that you feel represents your brands tone of voice. You can add in blog posts, faq’s pages, webpage copy etc."
        className="col-start-1 row-span-1 col-span-2"
      />
      <BrandVoiceMessage className="col-start-1 sm:col-start-3 row-span-2 col-span-2 mb-4" />
      <div className="col-start-1 row-span-1 col-span-2">
        <TextArea
          data-cy="brand-voice-textarea"
          data-testid="brand-voice-textarea"
          placeholder="The more you enter the better results we can generate."
          onChange={handleChange}
          autoSize={{ minRows: 13, maxRows: 50 }}
          defaultValue={value}
        />
        <div className="flex justify-end items-center my-4">
          <CharactersCounter
            className="mr-4 text-base"
            minValue={MIN_CHARACTERS}
            maxValue={MAX_CHARACTERS}
            value={numberOfCharacters}
          />
          {toneCount > 0 ? (
            <Button
              data-cy="cancel-button"
              data-testid="cancel-button"
              aria-label="Cancel"
              ghost={true}
              onClick={() => {
                history.push(
                  generatePath(toneOfVoicePath, {
                    accountId,
                    brandVoiceId,
                  })
                )
              }}
              className="text-base mr-4"
            >
              Cancel
            </Button>
          ) : undefined}
          <Button
            data-cy="generate-tone-button"
            data-testid="generate-tone-button"
            aria-label="Generate tone"
            variant="primary"
            disabled={
              numberOfCharacters < MIN_CHARACTERS ||
              numberOfCharacters > MAX_CHARACTERS
            }
            onClick={() => {
              generateTone(value ?? '')
            }}
            className="text-base"
            prefixIcon={<MagicHatIcon isDefaultColor={false} />}
          >
            Generate Tone
          </Button>
        </div>
      </div>
    </Widget>
  )
}

export default SampleContent
