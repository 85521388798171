import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import ActionIcon from 'common/components/ActionIcon'
import { Close as CloseIcon } from 'common/icons'

declare global {
  interface File {
    preview: string
  }
}

type Props = {
  logo?: File
  onDrop: (file: File) => void
  onClear: () => void
}

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as const,
  height: '100%',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#d1d5db',
  borderStyle: 'dashed',
  color: '#9ca3af',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#901356',
}

const acceptStyle = {
  borderColor: '#901356',
}

const rejectStyle = {
  borderColor: '#EF4444',
}

const MAX_FILE_SIZE_BYTES = 5242880

function LogoUploader({ logo, onClear, onDrop: onDropCallback }: Props) {
  const [file, setFile] = useState<File | undefined>()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const acceptedFile = acceptedFiles.map((file) => {
        file.preview = URL.createObjectURL(file)
        return file
      })[0]

      setFile(acceptedFile)

      onDropCallback(acceptedFile)
    },
    [onDropCallback]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    multiple: false,
    maxSize: MAX_FILE_SIZE_BYTES,
    onDrop,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  useEffect(() => {
    if (logo) {
      setFile({ ...logo, preview: URL.createObjectURL(logo) })
    }
  }, [logo])

  useEffect(() => {
    return () => file && URL.revokeObjectURL(file.preview)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const activeDragText = isDragReject
    ? 'Unsupported file type or size'
    : 'Drop the image here ...'

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {file ? (
        <>
          <img
            src={file.preview}
            className="w-auto h-full"
            alt="logo"
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
          />
          <ActionIcon
            className="absolute top-0 right-0"
            onClick={(e) => {
              e.stopPropagation()
              setFile(undefined)
              onClear()
            }}
          >
            <CloseIcon />
          </ActionIcon>
        </>
      ) : isDragActive ? (
        <p>{activeDragText}</p>
      ) : (
        <p>Drag 'n' drop the image here, or click to select one</p>
      )}
    </div>
  )
}

export default LogoUploader
