import { useLayoutEffect } from 'react'
import { Element } from '@phrasee/phrasee-typings'

import { useAppSelector } from 'common/hooks/redux'
import { useAppDispatch } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getRootSelectedElement } from '../../helpers'
import { useContent, useSelectedElement } from '../../hooks'
import { BRIEF_ELEMENT_ID } from '../leftSection/ElementsList'

const useElementState = () => {
  const { content } = useContent()
  const dispatch = useAppDispatch()
  const { data: selectedElement } = useSelectedElement()
  const previousStep = useAppSelector((state) => state.unifiedFlow.previousStep)

  useLayoutEffect(() => {
    if (['generate', 'launch', 'results'].includes(previousStep ?? '')) {
      dispatch(selectElement({ elementId: BRIEF_ELEMENT_ID }))
    } else if (!selectedElement) {
      const rootSelectedElement = getRootSelectedElement(content)
      const elementId = rootSelectedElement?.element_id ?? BRIEF_ELEMENT_ID

      dispatch(selectElement({ elementId: elementId }))
    } else {
      const rootSelectedElement: Element | undefined = content?.elements?.find(
        (element) => {
          if (isComponentElement(element)) {
            return element.element_id === selectedElement.element_id
          } else {
            return (
              element.element_id === selectedElement.element_id ||
              element.elements?.some(
                (subElement) =>
                  subElement.element_id === selectedElement.element_id
              )
            )
          }
        }
      )

      if (rootSelectedElement && rootSelectedElement?.status !== 'disabled') {
        dispatch(selectElement({ elementId: rootSelectedElement.element_id }))
      } else {
        dispatch(selectElement({ elementId: BRIEF_ELEMENT_ID }))
      }
    }
    // This hook should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useElementState
