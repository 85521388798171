import React from 'react'
import cx from 'classnames'

interface Props {
  text: string
  className?: string
  style?: React.CSSProperties
}

const GuidingText = ({ className = 'mt-2', text, style }: Props) => (
  <div
    className={cx(className, `text-coolGray-400`)}
    style={{ ...style, lineHeight: 1.5 }}
  >
    {text}
  </div>
)

export default GuidingText
