import { FC, useEffect } from 'react'

import { Close } from 'common/icons'

interface Props {
  onClose: () => void
}

const FullScreen: FC<Props> = ({ onClose, children }) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscapeKey)

    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [onClose])

  return (
    <div
      className="fixed inset-0 h-screen w-screen overflow-auto bg-white z-70"
      role="dialog"
    >
      <div className="flex justify-end">
        <button aria-label="Close" onClick={onClose} className="p-3">
          <Close />
        </button>
      </div>
      <div>{children}</div>
    </div>
  )
}
export default FullScreen
