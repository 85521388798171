const Resizer = (props) => {
  return (
    <div
      {...props}
      className="inline-block absolute right-0 top-0 h-full border-r-2 border-gray-200"
    />
  )
}

export default Resizer
