import { useEffect, useMemo, useRef, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { useQueryClient } from '@tanstack/react-query'
import RouterPromptStay from 'app/router/RouterPromptStay'
import { ValidationErrors } from 'final-form'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import { SelectValue } from 'common/components/BaseSelect'
import { parseFieldsConfiguration } from 'common/components/dynamicFields/helpers'
import {
  ConditionalValue,
  FieldType,
} from 'common/components/dynamicFields/interfaces'
import { successToast } from 'common/components/toastNotification'
import { Topic } from 'common/components/topics/interfaces'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import useGenerateBriefMutation from 'features/unifiedFlow/api/mutations/useGenerateBriefMutation'
import useGenerateCustomBriefMutation from 'features/unifiedFlow/api/mutations/useGenerateCustomBriefMutation'
import useGenerateCustomVariantsMutation from 'features/unifiedFlow/api/mutations/useGenerateCustomVariantsMutation'
import useGenerateVariantsMutation from 'features/unifiedFlow/api/mutations/useGenerateVariantsMutation'
import useUpdateContentMutation from 'features/unifiedFlow/api/mutations/useUpdateContentMutation'
import { userInputFormHasChanged } from 'features/unifiedFlow/store/unifiedFlowSlice'

import {
  getStepStatus,
  parseWorkflowName,
  setContentLoadingState,
  setElementLoadingState,
  validateWorkflowName,
} from '../../helpers'
import { useContent, useSelectedElement, useTopics } from '../../hooks'
import { generateWsTopic } from '../../hooks/useWebsocketStatus'
import useCheckVariants from '../hooks/useCheckVariants'

import useBrandVoiceItem from './brandVoiceSettings/hooks/useBrandVoiceItem'
import BrandVoiceChangeOverlay from './BrandVoiceChangeOverlay'
import BriefTitle from './BriefTitle'
import DynamicForm from './DynamicForm'
import FormLeaveModal from './FormLeaveModal'
import GeneratedBrief from './GeneratedBrief'
import GeneratedTopics from './GeneratedTopics'
import RefreshBriefOverlay from './RefreshBriefOverlay'
import RegenerateModal from './RegenerateModal'

export type FormSubmitAction = (
  formValues: Record<string, ConditionalValue<FieldType> | undefined>
) => Promise<void>
export type PageState =
  | 'formChangedWithTopics'
  | 'formChangedWithBrief'
  | 'formChangedWithoutTopics'
  | 'brandVoiceChanged'
  | 'topicsChanged'
  | 'generateBrief'
  | 'generateVariants'
  | 'showGeneratedContentWithoutTopics'
  | 'showGeneratedContent'
  | 'generateWithoutTopics'

const BriefPage = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { content } = useContent()
  const { hasVariants } = useCheckVariants()
  const [resetWarningModalState, setResetWarningModalState] = useState<{
    isOpen: boolean
    onConfirm?: () => void
  }>({
    isOpen: false,
    onConfirm: undefined,
  })
  const isModalOpenRef = useRef(false)

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const activeContentId = useAppSelector(
    (state) => state.unifiedFlow.activeContentId
  )

  const { data: selectedElement } = useSelectedElement()
  const { data: { topics, fragments } = {} } = useTopics(
    activeContentId,
    selectedElement?.element_id
  )

  const updateContentMutation = useUpdateContentMutation()
  const generateBriefMutation = useGenerateBriefMutation()
  const generateCustomBriefMutation = useGenerateCustomBriefMutation()
  const generateVariantsMutation = useGenerateVariantsMutation()
  const generateCustomVariantsMutation = useGenerateCustomVariantsMutation()

  const [selectedTopics, setSelectedTopics] = useState<Topic[]>(topics ?? [])
  const [hasFormChanged, setHasFormChanged] = useState(false)
  const [hasBrandVoiceChanged, setHasBrandVoiceChanged] = useState(false)

  const shouldUseTopics = content?.requires_ner

  const isGlobalSettings = selectedElement === undefined

  const userInputForm = !isGlobalSettings
    ? cloneDeep(
        selectedElement?.user_input?.user_input_form?.filter(
          (field) => field.name !== 'content_name'
        )
      )
    : cloneDeep(content?.user_input.user_input_form)
  const parsedConfiguration = parseFieldsConfiguration(userInputForm)

  const generatedBrief = !isGlobalSettings
    ? selectedElement?.user_input?.generated_brief
    : content?.user_input.generated_brief

  const brandVoiceId =
    content?.elements.length === 1
      ? content.elements[0].user_input?.brand_voice_id
      : selectedElement?.user_input?.brand_voice_id

  const revertTopics = () => {
    setSelectedTopics(topics ?? [])
  }

  const generateBrief: FormSubmitAction = async (formValues) => {
    if (!content || !userInputForm) {
      return
    }

    if (hasVariants && isGlobalSettings && !isModalOpenRef.current) {
      setResetWarningModalState({
        isOpen: true,
        onConfirm: () => generateBrief(formValues),
      })
      return
    }

    const wsTopic = isGlobalSettings
      ? generateWsTopic({
          contentId: content._id,
          action: 'brief',
        })
      : generateWsTopic({
          contentId: content._id,
          elementId: selectedElement?.element_id,
          action: 'brief',
        })

    if (userInputForm) {
      userInputForm.forEach((field) => {
        if (formValues[field.name] != null) {
          field.value = formValues[field.name]
        } else {
          delete field.value
        }
      })
    }
    const selectedBrandVoice = formValues.brand_voice as SelectValue | undefined

    if (!isGlobalSettings && selectedElement?.element_id) {
      setElementLoadingState({
        queryClient,
        contentId: content._id,
        elementId: selectedElement?.element_id,
        action: 'brief',
      })
      generateCustomBriefMutation.mutate({
        accountId,
        contentId: content._id,
        elementId: selectedElement?.element_id,
        userInputForm,
        wsTopic,
        brandVoiceId: selectedBrandVoice?.value,
      })
    } else {
      setContentLoadingState(queryClient, content._id, 'brief')
      generateBriefMutation.mutate({
        accountId,
        contentId: content._id,
        userInputForm,
        wsTopic,
        brandVoiceId: selectedBrandVoice?.value,
      })
    }
  }

  const generateVariants: FormSubmitAction = async (formValues) => {
    if (!content) {
      return
    }

    if (hasVariants && isGlobalSettings && !isModalOpenRef.current) {
      setResetWarningModalState({
        isOpen: true,
        onConfirm: () => generateVariants(formValues),
      })
      return
    }

    if (userInputForm) {
      userInputForm.forEach((field) => {
        if (formValues[field.name] != null) {
          field.value = formValues[field.name]
        } else {
          delete field.value
        }
      })
    }

    const wsTopic = isGlobalSettings
      ? generateWsTopic({
          contentId: content._id,
          action: 'variants',
        })
      : generateWsTopic({
          contentId: content._id,
          elementId: selectedElement?.element_id,
          action: 'variants',
        })

    dispatch(userInputFormHasChanged({ hasChanged: false }))

    if (!isGlobalSettings && selectedElement?.element_id) {
      setElementLoadingState({
        queryClient,
        contentId: content._id,
        elementId: selectedElement?.element_id,
        action: 'variants',
      })
      generateCustomVariantsMutation.mutate({
        accountId,
        topics: selectedTopics,
        contentId: content._id,
        elementId: selectedElement?.element_id,
        wsTopic,
        ...(!shouldUseTopics && { userInputForm }),
      })
    } else {
      setContentLoadingState(queryClient, content._id, 'variants')
      generateVariantsMutation.mutate({
        topics: selectedTopics,
        accountId,
        wsTopic,
        contentId: content._id,
        ...(!shouldUseTopics && { userInputForm }),
      })
    }
  }

  const updateWorkflowName = (value: string) => {
    const { value: parsedValue, isValid } = parseWorkflowName(value)
    if (!content || !isValid) {
      return
    }

    const userInputForm = cloneDeep(content.user_input.user_input_form)

    let previousName = ''
    userInputForm.forEach((field) => {
      if (field.name === 'workflow_name') {
        previousName = field.value as string
        field.value = parsedValue
      }
    })

    if (previousName !== value) {
      updateContentMutation.mutate(
        {
          contentId: content._id,
          accountId,
          userInputForm,
          updatedName: parsedValue,
        },
        {
          onSuccess: () => {
            successToast('Workflow name updated successfully')
          },
        }
      )
    }
  }

  const hasTopics = topics !== undefined
  const briefStepsStatus = getStepStatus(content?.steps_status, 'brief')
  const isBriefStepsStatusReadOnly = briefStepsStatus === 'read-only'

  const getState = (): PageState => {
    const hasTopicsChanged = hasTopics && !isEqual(topics, selectedTopics)

    if (hasBrandVoiceChanged && hasVariants) {
      return 'brandVoiceChanged'
    } else if (hasFormChanged && !shouldUseTopics && hasVariants) {
      return 'formChangedWithoutTopics'
    } else if (hasFormChanged && shouldUseTopics && hasTopics) {
      return 'formChangedWithTopics'
    } else if (hasFormChanged && shouldUseTopics && generatedBrief) {
      return 'formChangedWithBrief'
    } else if (hasTopicsChanged && hasVariants) {
      return 'topicsChanged'
    } else if (!shouldUseTopics && hasVariants) {
      return 'showGeneratedContentWithoutTopics'
    } else if (!shouldUseTopics && !hasVariants && !hasTopics) {
      return 'generateWithoutTopics'
    } else if (!hasVariants && generatedBrief && hasTopics) {
      return 'generateVariants'
    } else if (hasVariants) {
      return 'showGeneratedContent'
    } else {
      return 'generateBrief'
    }
  }

  const state = getState()

  useEffect(() => {
    isModalOpenRef.current = resetWarningModalState.isOpen
  }, [resetWarningModalState.isOpen])

  useEffect(() => {
    if (
      state === 'topicsChanged' ||
      state === 'brandVoiceChanged' ||
      state === 'formChangedWithTopics' ||
      state === 'formChangedWithBrief' ||
      state === 'formChangedWithoutTopics'
    ) {
      dispatch(userInputFormHasChanged({ hasChanged: true, isGlobalSettings }))
    } else {
      dispatch(userInputFormHasChanged({ hasChanged: false }))
    }
  }, [dispatch, isGlobalSettings, state])

  useEffect(() => {
    const isWaitingForVariants = content?.elements?.some(
      (element) =>
        isComponentElement(element) && element.nlg_status === 'pending'
    )
    if (!isWaitingForVariants) {
      setSelectedTopics(topics ?? [])
    }
  }, [content?.elements, topics])

  const serializedUserInputForm = JSON.stringify(userInputForm)
  const formInitialValues: any = useMemo(() => {
    return userInputForm?.reduce(
      (acc, state) => ({ ...acc, [state.name]: state.value }),
      {}
    )
    // UserInputForm might contain properties with array values, that causes the deep comparison to fail and the form to re-initialize
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedUserInputForm])

  const brandVoiceItem = useBrandVoiceItem(brandVoiceId)

  if (brandVoiceItem && formInitialValues) {
    formInitialValues.brand_voice = brandVoiceItem
  }

  const getSubmitAction = (formValues): FormSubmitAction => {
    const submitAction = formValues?.submitAction

    switch (submitAction) {
      case 'formChangedWithTopics':
      case 'formChangedWithBrief':
      case 'brandVoiceChanged':
      case 'generateBrief':
      case 'refreshBrief':
        return generateBrief
      case 'generateWithoutTopics':
      case 'formChangedWithoutTopics':
      case 'topicsChanged':
      case 'generateVariants':
        return generateVariants
      default:
        return async () => {}
    }
  }

  return (
    <div className="flex flex-col flex-1">
      {userInputForm && (
        <Form
          onSubmit={(values) => getSubmitAction(values)(values)}
          initialValues={formInitialValues}
          key={selectedElement?.element_id}
          validate={(values) => {
            const errors: ValidationErrors = {}
            const workflowNameValidationError = validateWorkflowName(
              values['workflow_name']
            )

            if (!!workflowNameValidationError) {
              errors.workflow_name = workflowNameValidationError
            }

            return errors
          }}
          render={({ handleSubmit, submitting, dirty, submitSucceeded }) => (
            <form
              onSubmit={handleSubmit}
              onBlur={(e) => {
                if (
                  e.target.name === 'workflow_name' &&
                  e.target.value !== ''
                ) {
                  updateWorkflowName(e.target.value)
                }
              }}
            >
              <FormSpy
                onChange={(props) => {
                  const dirtyFields = Object.keys(props.dirtyFields).filter(
                    (field) => field !== 'workflow_name'
                  )
                  const isDirty = dirtyFields.length > 0
                  setHasFormChanged(isDirty)

                  const brandVoiceChanged =
                    dirtyFields.includes('brand_voice') &&
                    dirtyFields.length === 1
                  setHasBrandVoiceChanged(brandVoiceChanged)
                }}
              />
              <div className="flex flex-col mb-6 border border-coolGray-300 p-6 bg-white">
                <BriefTitle />
                {parsedConfiguration && (
                  <DynamicForm
                    fieldsConfiguration={parsedConfiguration}
                    isDisabled={submitting || isBriefStepsStatusReadOnly}
                    pageState={state}
                    isLoading={generateVariantsMutation.isLoading}
                    brandVoiceId={brandVoiceId}
                  />
                )}
              </div>
              <div className="relative">
                {state === 'formChangedWithTopics' && <RefreshBriefOverlay />}
                {state === 'brandVoiceChanged' && <BrandVoiceChangeOverlay />}
                {generatedBrief && (
                  <GeneratedBrief
                    brief={generatedBrief}
                    pageState={state}
                    fragments={fragments}
                  />
                )}
                <GeneratedTopics
                  topics={selectedTopics}
                  pageState={state}
                  setSelectedTopics={setSelectedTopics}
                  revertTopics={revertTopics}
                  isReadOnly={isBriefStepsStatusReadOnly}
                  isGeneratingVariants={generateVariantsMutation.isLoading}
                />
              </div>
              <RouterPromptStay
                shouldShow={
                  !!content &&
                  dirty &&
                  !submitting &&
                  !submitSucceeded &&
                  !isBriefStepsStatusReadOnly
                }
                onOK={() => Promise.resolve(true)}
              />
            </form>
          )}
        />
      )}
      <FormLeaveModal />
      <RegenerateModal
        isOpen={resetWarningModalState.isOpen}
        onConfirm={() => {
          resetWarningModalState.onConfirm?.()
          setResetWarningModalState({ isOpen: false })
        }}
        onClose={() => setResetWarningModalState({ isOpen: false })}
      />
    </div>
  )
}

export default BriefPage
