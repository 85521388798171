import { useMemo } from 'react'
import filter from 'lodash/filter'
import { ResponseVariant } from 'workflow/Insights/Insights'

import Alert from 'common/components/alert'
import { Column } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'
import { Info as InfoIcon } from 'common/icons'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'

import ActionCell from '../ActionsCell'
import useTableActions from '../hooks/useTableActions'

import ApprovalsTableActionButtons from './ApprovalsTableActionButtons'

type Props = {
  isReadOnly?: boolean
}

const ApprovalsTable = ({ isReadOnly = false }: Props) => {
  const { data: selectedElement } = useSelectedComponentElement()
  const { campaignData } = useAppSelector((state) => state.campaignStates)
  const variantsIdsToBeApproved = useAppSelector(
    (state) => state.campaignStates.variantsToBeApproved
  )

  const variantsToBeApproved = useMemo(() => {
    return filter(campaignData.sl, ({ _id }) =>
      variantsIdsToBeApproved.includes(_id)
    )
  }, [campaignData, variantsIdsToBeApproved])

  const elementId = selectedElement?.element_id
  const { onRequestChange } = useTableActions()

  const columns: Column<ResponseVariant>[] = useMemo(
    () => [
      {
        Header: 'Variants',
        accessor: 'text',
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: '_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => (
          <ActionCell onRequestChange={() => onRequestChange(row.original)} />
        ),
      },
    ],
    [onRequestChange]
  )

  const variantsToBeApprovedLength = variantsIdsToBeApproved.length

  return (
    <TableWidget.Widget<ResponseVariant>
      key={elementId}
      data={variantsToBeApproved}
      columns={columns}
      autoResetPage={false}
    >
      <TableWidget.Header
        title={
          <div className="w-full">
            <h2 className="mb-2.5">New lines for selection</h2>
            <Alert
              type="info"
              className="flex items-center py-2.5 px-4 text-sm w-full"
            >
              <InfoIcon
                isDefaultColor={false}
                size={5}
                className="mr-2 text-black"
              />
              <span className="text-black">
                {`${variantsToBeApprovedLength} new ${
                  variantsToBeApprovedLength === 1 ? 'variant' : 'variants'
                } have been generated. Please review and action.`}
              </span>
            </Alert>
          </div>
        }
      />
      <TableWidget.ActionButtons>
        <ApprovalsTableActionButtons isReadOnly={isReadOnly} />
      </TableWidget.ActionButtons>
    </TableWidget.Widget>
  )
}

export default ApprovalsTable
