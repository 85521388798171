import { useEffect } from 'react'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'

import ApprovalsTable from '../approvalsTable'
import TweakVariantModal from '../tweakVariantModal'

import OptimizeResultsTable from './OptimizeResultsTable'

type Props = {
  isReadOnly: boolean
}

const OptimizeResults = ({ isReadOnly = false }: Props) => {
  const dispatch = useAppDispatch()
  const { data: selectedElement } = useSelectedComponentElement()

  const variantsToBeApproved = useAppSelector(
    (state) => state.campaignStates.variantsToBeApproved
  )
  const {
    isLoading,
    isWaitingState: { isWaiting },
    lineBeingTweaked,
  } = useAppSelector((state) => state.campaignStates)

  useEffect(() => {
    if (!!selectedElement?.campaign_id) {
      dispatch(fetchCampaignData(selectedElement?.campaign_id))
    }
  }, [dispatch, selectedElement?.campaign_id])

  return isLoading || isWaiting ? (
    <Spinner className="m-auto" />
  ) : (
    <>
      <div
        className="flex flex-col w-full gap-y-8"
        data-testid="optimizeResults"
      >
        {!!variantsToBeApproved.length && (
          <ApprovalsTable isReadOnly={isReadOnly} />
        )}
        <OptimizeResultsTable isReadOnly={isReadOnly} />
      </div>
      <TweakVariantModal
        key={lineBeingTweaked?._id?.toString()}
        campaignType="linguo"
      />
    </>
  )
}

export default OptimizeResults
