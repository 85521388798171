import { generatePath } from 'react-router'
import { unifiedFlowPaths } from 'app/navigation/paths'
import cx from 'classnames'

import Button from 'common/components/button'
import { SubNavItem } from 'common/components/navigation/subNavBar'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Menu as MenuIcon } from 'common/icons'
import {
  hideLeftPanel,
  showLeftPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getStepStatus } from '../../helpers'
import useContent from '../../hooks/useContent'

import ApprovalCounterBadge from './ApprovalCounterBadge'
import RightPanelButton from './RightPanelButton'

type Props = {
  className?: string
}

const HeaderNavigation = ({ className }: Props) => {
  const dispatch = useAppDispatch()

  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )

  const isLeftPanelVisible = useAppSelector(
    (state) => state.unifiedFlow.isLeftPanelVisible
  )
  const { content } = useContent()

  const briefStepsStatus = getStepStatus(content?.steps_status, 'brief')
  const generationStepsStatus = getStepStatus(
    content?.steps_status,
    'generation'
  )
  const launchStepsStatus = getStepStatus(content?.steps_status, 'launch')
  const resultsStepsStatus = getStepStatus(content?.steps_status, 'results')

  if (!content) {
    return null
  }
  return (
    <div
      className={cx(
        'flex justify-between items-center bg-maroon-700 py-2.5 px-6 lg:py-0 lg:px-0',
        className
      )}
    >
      <Button
        className="py-0 pr-1 pl-0 lg:hidden"
        prefixIcon={
          <Tooltip overlay={isLeftPanelVisible ? 'Hide panel' : 'Show panel'}>
            <MenuIcon isDefaultColor={false} className="text-white" />
          </Tooltip>
        }
        onClick={() => {
          isLeftPanelVisible
            ? dispatch(hideLeftPanel())
            : dispatch(showLeftPanel())
        }}
      />
      <div
        className={cx('flex', {
          'mr-4': rightPanelVisibility === 'none',
        })}
      >
        <div className="flex items-center justify-end font-medium gap-8 text-sm">
          <SubNavItem
            key="unified-flow-content-brief"
            to={generatePath(unifiedFlowPaths.brief, {
              contentId: content._id,
            })}
            title="1. Brief"
            isDisabled={briefStepsStatus === 'disabled'}
          />
          <ApprovalCounterBadge>
            <SubNavItem
              key="unified-flow-content-generate"
              to={generatePath(unifiedFlowPaths.generate, {
                contentId: content._id,
              })}
              title="2. Generate"
              isDisabled={generationStepsStatus === 'disabled'}
            />
          </ApprovalCounterBadge>
          <SubNavItem
            key="unified-flow-content-launch"
            to={generatePath(unifiedFlowPaths.launch, {
              contentId: content._id,
            })}
            title="3. Launch"
            isDisabled={launchStepsStatus === 'disabled'}
          />
          {resultsStepsStatus !== 'hidden' && (
            <SubNavItem
              key="unified-flow-content-results"
              to={generatePath(unifiedFlowPaths.results, {
                contentId: content._id,
              })}
              isDisabled={resultsStepsStatus === 'disabled'}
              title="4. Results"
            />
          )}
        </div>
        <RightPanelButton />
      </div>
    </div>
  )
}

export default HeaderNavigation
