import { useMemo, useState } from 'react'

import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal'
import { Column } from 'common/components/table'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useFocusSelectCell } from 'common/hooks/table'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'

import {
  addEndNode,
  addPathwayRow,
  deletePathwayRow,
  removeEndNode,
  selectStartNode,
} from '../../store/nodeSlice'

import EndTags from './components/endTags'

type Row = {
  id: number
  startNode: string
  endNodes: string[]
}

const NodeConfigurationWidget = () => {
  const { SelectCellWithFocus, editableCellFocus, editableCellLoseFocus } =
    useFocusSelectCell()
  const dispatch = useAppDispatch()
  const editedProjectNodes = useAppSelector(
    (state) => state.projectNodes.editedData.nodeSettings
  )
  const nodes = useAppSelector((state) => state.projectNodes.nodes)

  const selectedStartNodes = editedProjectNodes.availableNodes.map(
    ({ startNode }) => startNode?.displayName
  )

  const [rowIdToDelete, setRowIdToDelete] =
    useState<number | undefined>(undefined)
  const [resetFilterKey, setResetFilterKey] = useState(0)

  const columns: Column<Row>[] = useMemo(() => {
    const getStartNodeOptions = (selectedNodeName?: string) => {
      const availableStartNodes = nodes.filter(
        ({ name }) =>
          !selectedStartNodes.includes(name) || name === selectedNodeName
      )

      return availableStartNodes.map(({ name }) => ({
        value: name,
        label: name,
      }))
    }

    return [
      {
        accessor: 'startNode',
        Header: 'Start node',
        disableSortBy: true,
        Cell: (props) => {
          const { value, row, column } = props

          return (
            <SelectCellWithFocus<Row>
              data-cy="start-node-select"
              className="pl-6 w-full"
              value={value}
              row={row}
              column={column}
              singleSelectProps={{
                name: 'start-node-select',
                options: getStartNodeOptions(value),
                isInsideTable: true,
                isSearchable: true,
              }}
              onCellChange={(value) => {
                if (value.value && typeof value.value === 'string') {
                  dispatch(
                    selectStartNode({ index: row.id, nodeName: value.value })
                  )
                  editableCellLoseFocus()
                  setResetFilterKey((prev) => prev + 1)
                }
              }}
            />
          )
        },
      },
      {
        accessor: 'endNodes',
        Header: 'End nodes',
        disableSortBy: true,
        shouldOverflow: true,
        Cell: ({ value, row }) => {
          const hasSelectedNodes = value.length
          const startNode = nodes.find(
            ({ name }) => name === row.values.startNode
          )

          return (
            <EndTags
              startNodeId={startNode?.id}
              tags={
                hasSelectedNodes
                  ? value.map((value) => ({
                      value: value,
                      label: value,
                    }))
                  : [{ value: 'all', label: 'All' }]
              }
              onAddClick={(value: string) => {
                dispatch(addEndNode({ index: row.id, nodeName: value }))
                setResetFilterKey((prev) => prev + 1)
              }}
              onRemoveClick={
                hasSelectedNodes
                  ? (nodeName: string) => {
                      dispatch(removeEndNode({ index: row.id, nodeName }))
                      setResetFilterKey((prev) => prev + 1)
                    }
                  : undefined
              }
            />
          )
        },
      },
      {
        ...generateDeleteButtonColumn<Row>({
          onClick: (row) => {
            setRowIdToDelete(row.id)
          },
          buttonLabel: 'Delete',
        }),
        width: 50,
      },
    ]
  }, [
    dispatch,
    nodes,
    selectedStartNodes,
    SelectCellWithFocus,
    editableCellLoseFocus,
  ])

  const handleDeleteCancel = () => {
    setRowIdToDelete(undefined)
  }

  const handleDeleteConfirm = () => {
    setRowIdToDelete(undefined)
    if (rowIdToDelete !== undefined) {
      dispatch(deletePathwayRow(rowIdToDelete))
    }
  }

  const tableData = editedProjectNodes.availableNodes.map(
    ({ id, startNode, endNodes }) => ({
      id,
      startNode: startNode.displayName,
      endNodes: endNodes.map(({ displayName }) => displayName),
    })
  )

  return (
    <>
      <TableWidget.Widget
        data-cy="node-configuration-widget"
        className="mb-8"
        columns={columns}
        data={tableData}
      >
        <TableWidget.Header
          title="Node configuration"
          subtitle="Here you can view and edit phrase within the selected node."
        >
          <Button
            className="mr-4"
            variant="primary"
            data-cy="add-pathway-button"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => {
              dispatch(addPathwayRow())
              editableCellFocus()
              setResetFilterKey((prev) => prev + 1)
            }}
            disabled={tableData.length === nodes.length}
          >
            Pathway
          </Button>
          <TableWidget.Filter key={`${resetFilterKey}`} />
        </TableWidget.Header>
      </TableWidget.Widget>

      <ConfirmationModal
        data-cy="pathway-row-delete-modal"
        open={rowIdToDelete !== undefined}
        title="Remove pathway row!"
        confirmationText="Are you sure you want to remove this row?"
        onCancel={() => handleDeleteCancel()}
        onConfirm={() => handleDeleteConfirm()}
      />
    </>
  )
}

export default NodeConfigurationWidget
