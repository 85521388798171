import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import cx from 'classnames'
import csvParser from 'papaparse'

import Spinner from 'common/components/spinner'
import { errorToast } from 'common/components/toastNotification'

type Props = {
  onUpload: ({
    data,
    headers,
    fileName,
  }: {
    data: string[][]
    headers: string[]
    fileName: string
  }) => void
  onStartUpload: (file: File) => void
  progressInPercentage: number | undefined
  fileName: string | undefined
  description?: string
}

const MAX_FILE_SIZE_BYTES = 10737418240 // 10 Gb in bytes

const uploadCsv = ({
  file,
  onUpload,
  onStartUpload,
}: {
  file: File
  onUpload: ({
    data,
    headers,
    fileName,
  }: {
    data: string[][]
    headers: string[]
    fileName: string
  }) => void
  onStartUpload: any
}) => {
  onStartUpload(file)
  return new Promise<string[][]>((resolve, reject) => {
    // big files work only using the step function but it does not inform when all the rows are parsed
    const LIMIT_SIZE = 50000000 // 50 MB
    const partialFile = new File([file.slice(0, LIMIT_SIZE)], file.name)
    csvParser.parse<string[]>(partialFile, {
      header: false,
      skipEmptyLines: 'greedy',
      preview: 20,
      complete: function (results, file) {
        const data = results.data
        const errors = results.errors
        if (errors.length > 0) {
          return reject(new Error(errors[0].message ?? 'Unknown error'))
        }
        const headers = data[0]
        if (!headers.length) {
          return reject(
            new Error(
              'The headers could not be retrieved in the file. Please check that the first row should contain the headers.'
            )
          )
        }
        const hasEmtpyHeaders = headers.some((header) => !header.trim())
        const areHeadersUnique = headers
          .map((header) => header.trim().toLowerCase())
          .every(
            (header, index, cleanedHeaders) =>
              cleanedHeaders.indexOf(header) === index
          )
        if (!areHeadersUnique) {
          return reject(new Error('The column headers should be unique.'))
        }
        if (hasEmtpyHeaders) {
          return reject(new Error('The column headers should not be empty.'))
        }
        onUpload({
          data: data.slice(1, 11).map((row) => {
            return row.reduce((acc, value, index) => {
              return {
                ...acc,
                [data[0][index]]: value,
              }
            }, {})
          }) as any,
          headers: data[0],
          fileName: file?.name ?? '',
        })
        return resolve(results.data)
      },
      error: function (error, file) {
        errorToast(
          `An error occured while uploading the CSV file ${file?.name ?? ''}`
        )
        reject(error)
      },
    })
  })
}

const CsvUploader = forwardRef<{ open: () => void }, Props>(
  (
    {
      fileName,
      onStartUpload,
      onUpload,
      progressInPercentage,
      description = '',
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false)
    const [uploadingFileName, setUploadingFileName] = useState('')

    const onDrop = useCallback(
      (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (acceptedFiles.length > 0) {
          const csvFile = acceptedFiles[0]
          setUploadingFileName(csvFile.name)
          setIsLoading(true)
          uploadCsv({
            file: csvFile,
            onUpload,
            onStartUpload,
          })
            .catch((error: Error) => errorToast(error.message))
            .finally(() => setIsLoading(false))
        }
        if (fileRejections.length > 0) {
          fileRejections.forEach((file) => {
            file.errors.forEach((error) => {
              if (error.code === 'file-too-large') {
                errorToast(`Error: ${error.message}`)
              }

              if (error.code === 'file-invalid-type') {
                errorToast(`Error: ${error.message}`)
              }
            })
          })
        }
      },
      [onStartUpload, onUpload]
    )
    const isDisabled = isLoading || progressInPercentage !== undefined
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      open,
    } = useDropzone({
      accept: {
        'text/csv': ['.csv'],
        'application/vnd.ms-excel': ['.csv'], // for Windows
      },
      multiple: false,
      maxSize: MAX_FILE_SIZE_BYTES,
      onDrop,
      noClick: isDisabled,
      noDrag: isDisabled,
      noKeyboard: isDisabled,
    })
    useImperativeHandle(ref, () => ({
      open() {
        open()
      },
    }))

    const componentPerState = {
      default: (
        <div className="p-6 bg-coolGray-50 border border-coolGray-300 border-dashed flex flex-1 flex-col justify-items-center items-center gap-4">
          <div className="flex gap-4 items-center">
            <span className="text-coolGray-800 font-bold text-base">
              {fileName ?? 'CSV upload'}
            </span>
          </div>
          {progressInPercentage !== undefined ? (
            <span>{progressInPercentage}% uploaded...</span>
          ) : (
            <p className="items-center text-base text-coolGray-400 font-normal">
              Drag and drop your file here or{' '}
              <span className="text-maroon-500">choose a file </span> to upload.
              {description ? ` ${description}` : ''}
            </p>
          )}
        </div>
      ),
      dragActive: (
        <div
          className={cx(
            'p-6 bg-coolGray-50 border border-dashed flex flex-1 flex-col justify-items-center items-center gap-4',
            { 'border-coolGray-400': isDragAccept },
            { 'border-red-400 text-red-400': isDragReject }
          )}
        >
          <div className="flex gap-4 items-center">
            <span className="text-coolGray-800 font-bold text-base">
              CSV upload
            </span>
          </div>
          <span className={cx({ 'text-maroon-600': isDragAccept })}>
            {isDragAccept
              ? 'Oooh yes feed me... i’m hungry!'
              : 'Nope, that’s not a .CSV file'}
          </span>
        </div>
      ),
      loading: (
        <div className="flex flex-1 flex-col items-center justify-center h-full ">
          <div className="mb-8">
            <Spinner />
          </div>
          <div>Processing file {uploadingFileName}…</div>
        </div>
      ),
    } as const

    return (
      <div className="border-t-1 border-coolGray-100 bg-white flex flex-1 flex-col items-stretch place-content-stretch">
        <div
          className={cx(
            'text-coolGray-400 flex flex-col items-stretch justify-items-stretch h-full',
            {
              'cursor-pointer': !isDisabled,
              'cursor-not-allowed': isDisabled,
            }
          )}
          {...getRootProps({})}
        >
          <input {...getInputProps()} />
          {
            componentPerState[
              isLoading ? 'loading' : isDragActive ? 'dragActive' : 'default'
            ]
          }
        </div>
      </div>
    )
  }
)

export default CsvUploader
