import useGetBrandsQuery from '../api/queries/useGetBrandsQuery'
import useGetChannelsQuery from '../api/queries/useGetChannelsQuery'

const useDemoData = () => {
  const { data: channels, ...channelsQuery } = useGetChannelsQuery()
  const { data: brands, ...brandsQuery } = useGetBrandsQuery()

  return {
    brands,
    channels,
    isLoading: brandsQuery.isLoading || channelsQuery.isLoading,
    isError: brandsQuery.isError || channelsQuery.isError,
  }
}

export default useDemoData
