/* eslint-disable max-lines */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import cx from 'classnames'
import remove from 'lodash/remove'
import moment from 'moment'
import { isUndefined } from 'util'
import ActionBar from 'workflow/ActionBar'

import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import { replaceNewLineEmojiAsString } from 'common/variantsUtils'
import { cancelCreateCampaign } from 'features/campaigns/store/campaignSlice'

import { showBanner } from '../common/common.actions'
import {
  CampaignNavigationSteps,
  CampaignSetupUiPayback,
  DistributionChannel,
  DistributionChannelType,
  InputElement,
  Project,
  SetupStepPayload,
  SplitSizeData,
  Step,
} from '../interface'
import { RegenerateWarningModal } from '../pcl'
import helpers from '../utils/helpers'
import {
  fetchCampaignData,
  getProjectList,
  getUIObjectFromProject,
  retrieveDistributionChannel,
  retrieveSplitSizeData,
  saveCampaign,
  setDistributionChannel,
  setProjectId,
} from '../Workflow.actions'

import OptionPicker from './OptionPicker/OptionPicker'
import SplitCalculatorV3 from './SplitCalculator/SplitCalculatorV3'
import GuidingText from './UiBuilder/GuidingText'
import UiBuilder from './UiBuilder/UiBuilder.component'
import SplitSize from './SplitSize'

const { enumSelector } = helpers
interface IProps {
  saveCampaign: (
    data: SetupStepPayload,
    componentRef?: any
  ) => (dispatch: any) => void
  cancelCreateCampaign: () => void
  userId: number
  showBanner: any
  isWaitingForSaveAndContinue: boolean
  isWaitingForSaveAndExit: boolean
  isWaitingForSaveAndStay: boolean
  history: any
  fetchCampaignData: (id: any) => (dispatch: any) => void
  match: any
  setDistributionChannel: (val: DistributionChannelType) => void
  setProjectId: (val: string) => void
}
interface IPropsPassedDown {
  nameRetriever: (name: string) => void
  // TODO - Define an interface that match with a existing campaign.
  campaignData?: any
  campaignName?: string
  campaignValidationRules: any
  isNewCampaign: boolean
  disabled: boolean
}
enum SaveTypes {
  EXIT = 'save_and_exit',
  CONTINUE = 'save_and_continue',
  STAY = 'save_and_stay',
}
interface IStateCampaignSetup {
  typeSelected: boolean
  distributionChannelType?: DistributionChannelType | null
  projectsList: Project[]
  projectSelected: string
  projectResolved: boolean
  dataFromCampaignProvided: boolean
  UIElements: CampaignSetupUiPayback
  calculatorFields: InputElement[]
  // Used for ANTD async validator.
  form: any
  splitSizeResult?: SplitSizeData
  distributionChannels: DistributionChannel[]
  split_number?: number
  split_size?: number
  passedInProjectId?: string | null
  passedInDistributionChannel?: string | null
  initialSplitNumber?: number
  num_splits?: number
  regenerateModalVisible?: boolean
  saveType: SaveTypes
  campaign_name: string
  processingSplitResult: boolean
  disabledButton: boolean
  [key: string]: any
}

// Those 2 variables are used in updateFormValue() to put a delay when user
// enter the average_engagement_rate and estimated_audience_number.
let delayTimer: any
const TIMER_TO_GET_SPLIT_RESULT = 300
const initState = {
  distributionChannelType: undefined,
  dataFromCampaignProvided: false,
  projectsList: [],
  UIElements: {
    conditional_fields: [],
    split_calculation_required_fields: [],
    split_calculator_version: undefined,
    campaign_name_limit: undefined,
    human_control: {
      display: true,
      is_optional: false,
      integrationType: undefined,
    },
  },
  calculatorFields: [],
  splitSizeResult: {} as SplitSizeData,
  typeSelected: false,
  projectSelected: '',
  projectResolved: false,
  split_size: undefined,
  split_number: undefined,
  regenerateModalVisible: false,
  saveType: SaveTypes.CONTINUE,
  campaign_name: '',
  processingSplitResult: false,
  disabledButton: true,
}
class CampaignSetup extends Component<
  IProps & IPropsPassedDown,
  IStateCampaignSetup
> {
  triggerCalculation

  constructor(props: any) {
    super(props)
    const search = new URLSearchParams(props.location.search)
    this.state = {
      form: props.form,
      passedInProjectId: search.get('projectId'),
      passedInDistributionChannel: search.get('distributionChannel'),
      initialSplitNumber: props.campaignData.num_splits,
      num_splits: props.campaignData.num_splits,
      distributionChannels: [],
      ...initState,
    }
  }

  componentDidMount(): void {
    this.fetchDistributionChannel()
  }

  // Method use to prefill the form when we are loadign an existing campaign.
  // With dataFromCampaignProvided we ensure that this is execute only once ( Might do a refactor later ).
  componentDidUpdate(prevProps: any): void {
    const {
      campaignData,
      match: {
        params: { campaignId },
      },
    } = this.props
    const {
      match: {
        params: { campaignId: prevCampaignId },
      },
    } = prevProps
    const {
      dataFromCampaignProvided,
      passedInDistributionChannel,
      passedInProjectId,
      typeSelected,
      disabledButton,
    } = this.state
    if (this.hasErrors() && !disabledButton) {
      this.setState({ disabledButton: true })
      this.hasNoSplitSizeResultOrError()
    }
    if (campaignId !== prevCampaignId && !campaignId) {
      this.setState({
        ...this.state,
        ...initState,
      })
    }
    if (!dataFromCampaignProvided) {
      if (campaignData && campaignData._id) {
        this.setState({
          dataFromCampaignProvided: true,
        })
        this.manuallyFillTheUIWithCampaignData()
      }
    }
    if (passedInDistributionChannel) {
      this.pickOptionCallBack(passedInDistributionChannel)
    }
    if (passedInProjectId && typeSelected) {
      this.projectSelectHandler(passedInProjectId)
    }
  }

  setValidationButtonText = (campaignData: any): string => {
    let activeStep: Step[]
    if (campaignData && campaignData.steps) {
      activeStep = campaignData.steps.filter((step: any) => step.active)

      return activeStep?.length &&
        activeStep[0].step !== CampaignNavigationSteps.setup
        ? 'Update'
        : 'Next'
    }
    return 'Next'
  }

  shouldShowRegenerateWarning = (newData: any) => {
    const { campaignData: oldData } = this.props

    // Check against 'num_splits' if there is no calculator
    const newDataSplitNumber =
      newData?.data?.split_number || newData?.data?.num_splits
    const splitNumberChanged = newDataSplitNumber !== oldData?.num_splits
    return (
      splitNumberChanged && oldData?.campaign_progress?.text_variants_generated
    )
  }

  updateCampaignData = (type: SaveTypes, checkShowWarning: boolean) => {
    const data = this.buildThePayload(type)
    const { saveCampaign } = this.props

    if (checkShowWarning && this.shouldShowRegenerateWarning(data)) {
      return this.setState({ saveType: type, regenerateModalVisible: true })
    } else {
      this.setState({ saveType: type })
      saveCampaign(data)
    }
  }

  regenerateModalKeepExistingClick = () => {
    this.setState({ regenerateModalVisible: false })
    const {
      campaignData: { project_id, _id: campaign_id },
      history,
      fetchCampaignData,
    } = this.props
    const { saveType } = this.state
    if (saveType === SaveTypes.CONTINUE) {
      fetchCampaignData(campaign_id)
    } else if (saveType === SaveTypes.EXIT) {
      history.push(`/dashboard/${project_id}`)
    }
  }

  regenerateModalNewClick = () => {
    this.setState({ regenerateModalVisible: false })
    const { saveType } = this.state
    this.updateCampaignData(saveType, false)
  }

  async manuallyFillTheUIWithCampaignData(): Promise<void> {
    const { userId, showBanner, campaignData } = this.props
    const projectList = await getProjectList(
      campaignData.campaign_configuration?.distribution_channel ?? 'email',
      userId,
      showBanner
    )

    this.setState({
      ...projectList,
      projectSelected: campaignData?.project_id,
    })
    const UIElements = await getUIObjectFromProject(
      campaignData?.project_id,
      showBanner
    )

    const isSplitCalculatorV3 = this.isSplitCalculatorV3(
      campaignData?.campaign_configuration?.testing_method
        ?.split_calculator_version
    )
    if (isSplitCalculatorV3) {
      const { UIElements: UIElementsV3, calculatorFields } =
        this.extractCalculatorFields(UIElements)
      this.setState({
        projectResolved: true,
        UIElements: UIElementsV3,
        calculatorFields,
        ...this.hydrateCalculatorFields(calculatorFields, campaignData),
      })
      this.addValueToUiElementsV3(campaignData)
      this.triggerCalculation?.({ maxSplitNumber: campaignData?.num_splits })
    } else {
      this.setState({
        projectResolved: true,
        UIElements: UIElements || {
          conditional_fields: [],
          split_calculation_required_fields: [],
        },
        ...(UIElements &&
          UIElements.split_calculation_required_fields && {
            [UIElements.split_calculation_required_fields[0]]:
              campaignData[UIElements.split_calculation_required_fields[0]],
            [UIElements.split_calculation_required_fields[1]]:
              campaignData[UIElements.split_calculation_required_fields[1]],
          }),
      })
      this.addValueToUiElements(campaignData)
      const splitNumber = isSplitCalculatorV3
        ? campaignData?.num_splits
        : undefined
      this.calculateSplitSize(true, splitNumber)
    }
  }

  hydrateCalculatorFields(calculatorFields, campaignData) {
    let result = {}
    calculatorFields?.forEach((field) => {
      if (campaignData[field.field_id] !== undefined) {
        result = {
          ...result,
          [field.field_id]: campaignData[field.field_id],
        }
      }
    })

    return result
  }

  /** ********************** */
  // UI handling Method -
  /** ********************** */
  async pickOptionCallBack(type: string): Promise<void> {
    const { userId, showBanner, nameRetriever, setDistributionChannel } =
      this.props
    const distributionChannelType = enumSelector<DistributionChannelType>(
      type,
      DistributionChannelType
    )
    this.setState({
      passedInDistributionChannel: undefined,
      typeSelected: false,
      projectSelected: '',
      split_number: undefined,
      split_size: undefined,
    })
    nameRetriever('New Experiment ...')

    const projectList = await getProjectList(
      distributionChannelType,
      userId,
      showBanner
    )

    setDistributionChannel(distributionChannelType)
    this.setState({ ...projectList })

    if (projectList.projectsList.length === 1) {
      const singleProject = projectList.projectsList[0]['_id']
      this.projectSelectHandler(singleProject)
    }
  }

  updateSetupState = (stateValue: object): void => {
    this.setState(stateValue, () => this.hasNoSplitSizeResultOrError())
  }

  updateFormValue = async (
    value: string,
    key: string,
    UiBuilderState: any
  ): Promise<void> => {
    const {
      state,
      state: { UIElements },
    } = this
    const { shouldShowHumanControlWarning } = UiBuilderState
    const { nameRetriever } = this.props
    const obj: any = {}
    obj[key] = value
    const newObject = {
      ...obj,
      shouldShowHumanControlWarning,
    }
    this.setState(newObject, () => this.hasNoSplitSizeResultOrError())
    if (key === 'campaign_name') {
      nameRetriever(value)
    }
    // Check if both has been filled to make the call to connect and get the split size result.
    if (UIElements.split_calculation_required_fields.includes(key)) {
      // adding extra check so calculateSplitSize() is not triggered repeatedly
      // when `change` event is followed with `blur` event on this input boxes
      const selectedKeyInfo = Object.entries(state).find(
        (item) => item[0] === key
      )
      const previousValue = selectedKeyInfo ? selectedKeyInfo[1] : ''
      // further processing should happen only when the value has changed
      if (value !== previousValue) {
        clearTimeout(delayTimer)
        delayTimer = setTimeout(() => {
          this.calculateSplitSize()
        }, TIMER_TO_GET_SPLIT_RESULT)
      }
    }
  }

  hasErrors(): boolean {
    const { state } = this
    const { getFieldsError, isFieldTouched, getFieldValue } = state.form
    const { calculatorFields } = state

    const fields = getFieldsError()
    const requiredFields = calculatorFields?.map((element) => element.field_id)

    if (this.isSplitCalculatorV3(state.UIElements.split_calculator_version)) {
      const selectionMetric = getFieldValue('selection_metric')
      if (selectionMetric === 'open_rate') {
        delete fields.baseline_click_rate
        if (!requiredFields.includes('baseline_open_rate')) {
          delete fields.baseline_open_rate
        }
      } else if (selectionMetric === 'click_rate') {
        delete fields.baseline_open_rate
        if (!requiredFields.includes('baseline_click_rate')) {
          delete fields.baseline_click_rate
        }
      } else if (selectionMetric === 'phrasee_score') {
        if (!requiredFields.includes('baseline_open_rate')) {
          delete fields.baseline_open_rate
        }
        if (!requiredFields.includes('baseline_click_rate')) {
          delete fields.baseline_click_rate
        }
      }

      delete fields.selection_metric
    }

    return Object.keys(fields).some((field) => {
      if (this.isConditionalFieldNotRequired(field)) {
        return false
        // We need to check the the field hasn't been touched first and then that there is no value apply ( in case of existing campaign )
      } else if (!isFieldTouched(field) && getFieldValue(field) === undefined) {
        return true
      }

      return fields[field]
    })
  }

  hasNoSplitSizeResultOrError(): void {
    const { campaignData } = this.props
    const { splitSizeResult, UIElements } = this.state
    const hasErrors = this.hasErrors()
    const splitCalcVersion =
      campaignData?.campaign_configuration?.testing_method
        ?.split_calculator_version

    const hasSplitCalc =
      splitCalcVersion !== 'none' &&
      (this.state['list_size'] !== undefined ||
        this.state['baseline_open_rate'] !== undefined)

    if (!UIElements?.human_control?.display && !hasErrors) {
      this.setState({ disabledButton: false })
    } else if (hasSplitCalc && isUndefined(splitSizeResult)) {
      this.setState({ disabledButton: true })
    } else if (splitSizeResult?.split_result?.length === 0 || hasErrors) {
      this.setState({ disabledButton: true })
    } else {
      this.setState({ disabledButton: false })
    }
  }

  async calculateSplitSize(
    initialLoad?: boolean,
    newSplitNumber?: number
  ): Promise<void> {
    const {
      projectSelected,
      UIElements,
      initialSplitNumber,
      form,
      split_number,
    } = this.state
    const { showBanner, campaignData } = this.props

    let splitSizeData = {}
    let splitSizeResultData: SplitSizeData | undefined

    let splitInformationsFilled = false
    if (this.isSplitCalculatorV3(UIElements.split_calculator_version)) {
      if (newSplitNumber && split_number && newSplitNumber > split_number) {
        splitSizeData = {
          ...splitSizeData,
          min_split_number: newSplitNumber,
        }
      }

      if (
        (newSplitNumber && split_number && newSplitNumber < split_number) ||
        (newSplitNumber && !split_number)
      ) {
        splitSizeData = {
          ...splitSizeData,
          max_split_number: newSplitNumber,
        }
      }

      splitInformationsFilled = !!this.state.list_size
      UIElements.split_calculation_required_fields.forEach((field) => {
        splitSizeData = {
          ...splitSizeData,
          [field]: (this.state as any)[field],
        }
      })
    } else {
      splitInformationsFilled =
        UIElements.split_calculation_required_fields.every((field) => {
          splitSizeData = {
            ...splitSizeData,
            [field]: (this.state as any)[field],
          }
          return !!(this.state as any)[field]
        })
    }
    // We are checking if the input changed and is filled before to execute the calculation. the Else case is for the case were we are loading an existing campaign.
    if (
      splitInformationsFilled ||
      (campaignData[UIElements.split_calculation_required_fields[0]] &&
        campaignData[UIElements.split_calculation_required_fields[1]])
    ) {
      // This will show the placeholder for the split size component.
      this.setState({
        processingSplitResult: true,
        splitSizeResult: {
          headline: 'Calculating your split size',
          is_full_test: false,
          is_head_to_head: false,
          other_split_options: [],
          show_other_split_options: false,
          split_number: 0,
          split_result: '',
          split_size: 0,
          total_split_audience: '0',
        },
      })
      // If it is an existing campaign
      if (!splitInformationsFilled) {
        splitSizeData = {
          [UIElements.split_calculation_required_fields[0]]:
            campaignData[UIElements.split_calculation_required_fields[0]],
          [UIElements.split_calculation_required_fields[1]]:
            campaignData[UIElements.split_calculation_required_fields[1]],
        }
      }
      try {
        form.setFields({
          list_size: {
            value: form.getFieldValue('list_size'),
            errors: undefined,
            touched: true,
          },
        })
        splitSizeResultData = await retrieveSplitSizeData(
          projectSelected || campaignData.project_id,
          splitSizeData
        )
      } catch (err: any) {
        if (!initialLoad && err.name === 'INVALID AUDIENCE_SIZE') {
          form.setFields({
            list_size: {
              value: form.getFieldValue('list_size'),
              errors: [err.message],
              touched: true,
            },
          })
        } else {
          showBanner({ content: err.message, type: 'error' })
        }
      }

      if (splitSizeResultData && splitSizeResultData.split_result) {
        const engagementRateValue =
          splitSizeResultData.campaign_data?.expected_engagement_rates[0]
            ?.true_value
        if (engagementRateValue && !this.state.baseline_open_rate) {
          this.setState({
            baseline_open_rate: engagementRateValue,
          })
          form.setFieldsValue({ baseline_open_rate: engagementRateValue })
        }

        this.setState({
          processingSplitResult: false,
          splitSizeResult: splitSizeResultData,
          ...(initialLoad && {
            initialSplitNumber: undefined,
          }),

          split_number: initialSplitNumber || splitSizeResultData.split_number,
          split_size: splitSizeResultData.split_size,
        })
      } else {
        this.setState({
          splitSizeResult: undefined,
          processingSplitResult: false,
        })
      }
    } else {
      // Remove the Split size component
      this.setState({
        splitSizeResult: undefined,
        processingSplitResult: false,
      })
    }
    this.hasNoSplitSizeResultOrError()
  }

  // Callback use in the SplitSize component to override the split Number coming from the back if the user select a option in the dropdown.
  updateSplitNumberCallback(splitNumber: number): void {
    this.setState({ initialSplitNumber: undefined, split_number: splitNumber })
    if (
      this.isSplitCalculatorV3(this.state.UIElements.split_calculator_version)
    ) {
      this.calculateSplitSize(false, splitNumber)
    }
  }

  async fetchDistributionChannel(): Promise<void> {
    const { userId, showBanner } = this.props
    const distributionChannels = await retrieveDistributionChannel(
      userId,
      showBanner
    )
    this.setState({ distributionChannels })
  }

  buildThePayload(type: string): SetupStepPayload {
    const {
      projectSelected,
      split_number,
      split_size,
      form,
      num_splits,
      calculatorCampaignData,
    } = this.state
    const {
      campaignData: { _id: campaign_id, name },
    } = this.props
    const inputsValue: any = {}
    const numSplit: number | undefined = split_number
    // We retrieve all the key from the form.
    const keyValue = Object.keys(form.getFieldsValue())
    // We then map over all the different key to retrieve the state from the state component.
    // ( Doing this here to avoid to use the form getValueField method which transform the string in the process)
    keyValue.forEach((key) => {
      if ((this.state as any)[key]) {
        inputsValue[key] = (this.state as any)[key]
      } else if (typeof form.getFieldValue(key) === 'string') {
        inputsValue[key] = form
          .getFieldValue(key)
          .replace(/(?:\r|\n|\r\n)/g, '〽')
      }
    })

    const data: SetupStepPayload = {
      save_and_exit: type === SaveTypes.EXIT,
      save_and_continue: type === SaveTypes.CONTINUE,
      save_and_stay: type === SaveTypes.STAY,
      step: CampaignNavigationSteps.setup,
      data: {
        name: name || undefined,
        ...inputsValue,
        campaign_id,
        campaign_name: this.props.campaignName || name,
        project_id: projectSelected,
        split_number: numSplit || undefined,
        split_size: split_size || undefined,
        num_splits: num_splits || undefined,
        campaign_data: calculatorCampaignData,
      },
    }

    return data
  }

  isConditionalFieldNotRequired = (field: string) => {
    return this.state.UIElements.conditional_fields.find(
      (conditionalField) =>
        conditionalField.field_id === field &&
        conditionalField.rules?.some((rule) => rule.required === false)
    )
  }

  addValueToUiElementsV3(campaignData: any): void {
    const { UIElements, form, calculatorFields } = this.state
    const fields = UIElements.conditional_fields.concat(calculatorFields || [])

    const modifiedUiELement = fields
      .filter((f) => f?.field_id !== 'selection_metric')
      .map((field) => {
        const obj: any = {}
        let tmpValue
        if (field.property_name === 'own_subject_line') {
          tmpValue = replaceNewLineEmojiAsString(
            campaignData[field.property_name]
          )
        } else if (field.property_name === 'iso_send_date') {
          tmpValue = moment(campaignData[field.property_name])
        }
        obj[field?.property_name] =
          tmpValue || campaignData[field?.property_name]
        return obj
      })

    form.setFieldsValue({ selection_metric: campaignData['selection_metric'] })
    form.setFieldsValue({ campaign_name: campaignData.name })

    modifiedUiELement.forEach((element) => {
      form.setFieldsValue(element)
    })
  }

  addValueToUiElements(campaignData: any): void {
    const { UIElements, form } = this.state
    const modifiedUiELement = UIElements.conditional_fields.map((field) => {
      const obj: any = {}
      let tmpValue
      if (field.property_name === 'own_subject_line') {
        tmpValue = replaceNewLineEmojiAsString(
          campaignData[field.property_name]
        )
      } else if (field.property_name === 'iso_send_date') {
        tmpValue = moment(campaignData[field.property_name])
      }
      obj[field.property_name] = tmpValue || campaignData[field.property_name]
      return obj
    })

    // eslint-disable-next-line array-callback-return
    modifiedUiELement.map((element) => {
      form.setFieldsValue(element)
    })
    form.setFieldsValue({ campaign_name: campaignData.name })
  }

  async projectSelectHandler(projectId: string): Promise<void> {
    const { form, projectSelected } = this.state
    const { showBanner, nameRetriever, setProjectId } = this.props

    setProjectId(projectId)

    nameRetriever('New Experiment ...')
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({
      projectResolved: false,
      passedInProjectId: undefined,
      projectSelected: projectId,
      splitSizeResult: undefined,
      list_size: undefined,
      baseline_open_rate: undefined,
      UIElements: {
        ...initState.UIElements,
      },
    })
    const UIElements = await getUIObjectFromProject(projectId, showBanner)
    if (projectId !== projectSelected) {
      // First we reset all the input
      form.resetFields()
      // Then we reset the state
      const keyValue = Object.keys(form.getFieldsValue())
      keyValue.map((key) => {
        const tmpObj: any = {}
        tmpObj[key] = undefined
        return this.setState({ ...tmpObj })
      })
    }
    // eslint-disable-next-line react/no-access-state-in-setstate
    if (UIElements) {
      const { UIElements: extractedUIElements, calculatorFields } =
        this.extractCalculatorFields(UIElements)

      this.setState({
        projectResolved: true,
        UIElements: extractedUIElements,
        calculatorFields,
      })

      // Set default selection metric if it exists
      if (calculatorFields) {
        calculatorFields
          .find((field) => field.field_id === 'selection_metric')
          ?.elements?.forEach((element) => {
            if (element.default) {
              this.setState({ selection_metric: element.value })
              form.setFieldsValue({ selection_metric: element.value })
            }
          })
      }
    } else {
      this.setState({
        passedInProjectId: undefined,
        projectSelected: '',
        UIElements: {
          ...initState.UIElements,
        },
      })
    }

    // TODO: This is a patch until the firefox bug is fixed https://github.com/JedWatson/react-select/issues/4906
    ;(document.activeElement as HTMLElement).blur()
  }

  isSplitCalculatorV3(split_calculator_version?: number) {
    return split_calculator_version === 3
  }

  extractCalculatorFields(UIElements: CampaignSetupUiPayback): any {
    if (this.isSplitCalculatorV3(UIElements.split_calculator_version)) {
      const calculatorFields = remove(
        UIElements.conditional_fields,
        (field) => {
          return field.usage === 'split_calculator'
        }
      )

      return { UIElements, calculatorFields }
    }

    return { UIElements }
  }

  /** ********************** */
  // Render Method -
  /** ********************** */

  render() {
    const { state } = this
    const {
      typeSelected,
      projectsList,
      projectSelected,
      projectResolved,
      UIElements,
      splitSizeResult,
      distributionChannels,
      form,
      dataFromCampaignProvided,
      distributionChannelType,
      split_number,
      initialSplitNumber,
      regenerateModalVisible,
      processingSplitResult,
      disabledButton,
      shouldShowHumanControlWarning,
      calculatorFields,
    } = state
    const {
      campaignData,
      isWaitingForSaveAndContinue,
      isNewCampaign,
      campaignName,
      disabled,
      showBanner,
    } = this.props

    const preSelectedChannel =
      campaignData.campaign_configuration &&
      campaignData.campaign_configuration.distribution_channel
        ? campaignData.campaign_configuration.distribution_channel
        : undefined
    const shouldSpin = projectSelected.length > 0 && !projectResolved

    const { split_calculation_required_fields } = UIElements

    return (
      <Form
        layout="horizontal"
        colon={false}
        className={cx(
          'w-full h-full overflow-y-auto flex flex-col flex-auto bg-white'
        )}
        hideRequiredMark={true}
      >
        {distributionChannels?.length > 0 ? (
          <>
            <div
              className="p-8 overflow-x-auto w-full pb-24"
              style={{ flex: '1 1 0' }}
            >
              {!isNewCampaign || (isNewCampaign && campaignName?.length) ? (
                <OptionPicker
                  tooltipText="Selected channel can't be changed once the experiment has been created."
                  readonly={disabled}
                  disabled={dataFromCampaignProvided}
                  preSelected={
                    preSelectedChannel || distributionChannelType || undefined
                  }
                  distributionChannels={distributionChannels}
                  pickOption={this.pickOptionCallBack.bind(this)}
                />
              ) : null}
              {typeSelected && projectsList?.length > 0 ? (
                <div className="w-156 mt-5">
                  <Form.Item label="Project" className="required m-b--20">
                    <>
                      <SingleSelect
                        data-cy="campaign-setup-select-project"
                        isSearchable
                        placeholder="Select your project"
                        onChange={(val) =>
                          val?.value && this.projectSelectHandler(val.value)
                        }
                        size="large"
                        value={projectSelected}
                        isDisabled={
                          dataFromCampaignProvided || shouldSpin || disabled
                        }
                        isLoading={shouldSpin}
                        options={projectsList
                          .sort((a: Project, b: Project) => {
                            return a.name
                              .toLowerCase()
                              .localeCompare(b.name.toLowerCase())
                          })
                          .map(({ _id, name }: Project) => {
                            return { label: name, value: _id }
                          })}
                      />
                      <GuidingText
                        text={
                          dataFromCampaignProvided
                            ? "The project can't be changed once the experiment has been created. You can move compatible experiments between projects on the dashboard."
                            : ''
                        }
                      />
                    </>
                  </Form.Item>
                  <RegenerateWarningModal
                    cancelModal={() =>
                      this.setState({ regenerateModalVisible: false })
                    }
                    visible={regenerateModalVisible}
                    onKeepExisting={() =>
                      this.regenerateModalKeepExistingClick()
                    }
                    onGenerateNew={() => this.regenerateModalNewClick()}
                    description="The recommended number of splits has changed, you will need to generate a new set of subject lines."
                  />
                  {projectSelected && projectResolved ? (
                    <React.Fragment>
                      {/* The Campaign input ( Should come from the back end ) */}
                      <UiBuilder
                        elements={UIElements.conditional_fields}
                        valueRetriever={this.updateFormValue}
                        humanControl={UIElements?.human_control}
                        form={form}
                        disabled={disabled}
                        inputsState={state}
                      />
                      {calculatorFields && calculatorFields.length > 0 ? (
                        <SplitCalculatorV3
                          fields={calculatorFields}
                          form={form}
                          projectId={projectSelected || campaignData.project_id}
                          campaignId={campaignData?._id}
                          splitNumber={split_number || initialSplitNumber}
                          showBanner={showBanner}
                          disabled={disabled}
                          updateSetupState={this.updateSetupState.bind(this)}
                          setTriggerCalculationFunc={(func) => {
                            this.triggerCalculation = func
                          }}
                          requiredFields={split_calculation_required_fields}
                        />
                      ) : splitSizeResult &&
                        Object.keys(splitSizeResult).length ? (
                        <SplitSize
                          value={initialSplitNumber || split_number}
                          isLoading={processingSplitResult}
                          data={splitSizeResult}
                          isDisabled={disabled}
                          updateSplitSizeNumber={this.updateSplitNumberCallback.bind(
                            this
                          )}
                        />
                      ) : undefined}
                    </React.Fragment>
                  ) : undefined}
                </div>
              ) : undefined}
            </div>
            {!disabled ? (
              <ActionBar
                cancelButton={{
                  isDisplayed: isNewCampaign,
                  onClick: () => {
                    this.props.cancelCreateCampaign()
                  },
                }}
                nextInfoMessage="Language generation"
                nextButton={{
                  isDisabled:
                    !campaignName?.length ||
                    disabledButton ||
                    shouldShowHumanControlWarning,
                  name: this.setValidationButtonText(campaignData),
                  onClick: () =>
                    this.updateCampaignData(SaveTypes.CONTINUE, true),
                  isLoading: isWaitingForSaveAndContinue,
                }}
              />
            ) : undefined}
          </>
        ) : (
          <Spinner />
        )}
      </Form>
    )
  }
}

function mapStateToProps(state: any, ownsProps: any): any {
  const {
    campaignStates: { isWaitingState },
  } = state
  return {
    userId: state.authStates.user_id,
    nameRetriever: ownsProps.nameRetriever,
    campaignData: ownsProps.campaignData,
    campaignValidationRules: ownsProps.campaignValidationRules,
    // connected state
    isWaitingForSaveAndContinue:
      isWaitingState.isWaiting &&
      isWaitingState.isWaitingFor === 'SaveAndContinue',
    isWaitingForSaveAndExit:
      isWaitingState.isWaiting && isWaitingState.isWaitingFor === 'SaveAndExit',
    isWaitingForSaveAndStay:
      isWaitingState.isWaiting && isWaitingState.isWaitingFor === 'SaveAndStay',
    campaignName: state.campaignStates.campaignName,
    isNewCampaign: state.campaigns.isNewCampaign,
    disabled: state.campaignStates?.campaignData?.steps?.[0]?.disabled ?? false,
  }
}

function mapDispatchToProps(dispatch: any): any {
  return {
    saveCampaign: (data: any) => dispatch(saveCampaign(data)),
    cancelCreateCampaign: () => dispatch(cancelCreateCampaign()),
    showBanner: (config: any) => dispatch(showBanner(config)),
    fetchCampaignData: (id: any) => dispatch(fetchCampaignData(id)),
    setProjectId: (val: string) => dispatch(setProjectId(val)),
    setDistributionChannel: (val: DistributionChannelType) =>
      dispatch(setDistributionChannel(val)),
  }
}

const CampaignSetupForm: any = Form.create({ name: 'campaign_setup' })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
      CampaignSetup
    )
  )
)

export default CampaignSetupForm
