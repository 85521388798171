import {
  DistributionChannel,
  DistributionType,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { AxiosResponse } from 'axios'
import { gql } from 'graphql-request'

import { connectInternalApi, fetchGraphQL } from 'common/api'
import {
  BaseParams,
  ReportingLevel,
  ReportSummaryResponse,
} from 'common/api/reporting'
import { IsoCurrency } from 'common/helpers/currency'
import {
  ResponseDistributionChannel,
  ResponseDistributionType,
} from 'common/interfaces/campaign'
import {
  WidgetGraphResponse,
  WigdetApiResponseBase,
} from 'common/interfaces/widgetListInterfaces'

export type Filters = 'product' | 'channel' | 'project' | 'campaigns'

export type Option<T = string> = { name: string; id: T }
export type ProductOption = Option<DistributionType>
export type ChannelOption = Option<DistributionChannel>

type GetPerformanceFiltersResponse = {
  filters: Filters[]
  products: ProductOption[]
  channels: ChannelOption[]
}

interface ResponseProject {
  _id: string
  name: string
  project_configuration: {
    campaign_configurations:
      | {
          distribution_channel: ResponseDistributionChannel
          distribution_type: ResponseDistributionType
        }
      | undefined
    test_project?: boolean
  }
  deleted: boolean
}

export async function fetchFilters(
  distributionTypes?: ResponseDistributionType[]
) {
  const query = gql`
    query getUserProductTypesAndChannels($distributionTypes: [String]) {
      data: getUserProductTypesAndChannels(
        distributionTypes: $distributionTypes
      ) {
        products {
          name
          id
        }
        channels {
          name
          id
        }
        filters
      }
    }
  `

  const variables = { distributionTypes }

  return fetchGraphQL<GetPerformanceFiltersResponse>({
    query,
    variables,
  })
}

export async function fetchProjects(
  accountId: string
): Promise<ResponseProject[]> {
  const query = gql`
    query projects($filter: ProjectFilter) {
      data: projects(filter: $filter) {
        _id
        name
        project_configuration {
          campaign_configurations {
            distribution_channel
            distribution_type
          }
          test_project
        }
        deleted
      }
    }
  `

  const variables = {
    filter: {
      testingMethods: [
        'split_test_partial',
        'split_test_full',
        'head_to_head',
        'dynamic',
      ],
      accountId,
      includeDeleted: true,
    },
  }

  return fetchGraphQL<ResponseProject[]>({ query, variables })
}

export const fetchIncrementalRevenueSummaryReport = ({
  accountId,
  channel,
  testingMethod,
  reportingLevel,
  projectIds,
  campaignIds,
  openConversionPercentage,
  clickConversionPercentage,
  openValue,
  clickValue,
  averageOrderValue,
  startDate,
  endDate,
}: {
  accountId: string
  channel: ChannelOption | undefined
  testingMethod: string[]
  campaignIds: string[]
  reportingLevel: ReportingLevel
  openConversionPercentage?: number
  clickConversionPercentage?: number
  openValue?: number
  clickValue?: number
  averageOrderValue?: number
} & BaseParams): Promise<AxiosResponse<ReportSummaryResponse>> => {
  const optionalParams =
    startDate && endDate ? { start_date: startDate, end_date: endDate } : {}
  const hasCampaignIds = campaignIds && campaignIds.length > 0
  const hasProjectIds = projectIds && projectIds.length > 0

  return connectInternalApi.get(
    `v1/core/reporting/reporting/accounts/${accountId}/incremental-revenue/report/summary`,
    {
      params: {
        testing_method: testingMethod.join(','),
        channel: channel ? channel.id : undefined,
        campaign_ids: hasCampaignIds ? campaignIds : undefined,
        project_ids: hasProjectIds && !hasCampaignIds ? projectIds : undefined,
        reporting_level: reportingLevel,
        conversion_rate: openConversionPercentage,
        click_conversion_rate: clickConversionPercentage,
        open_value: openValue,
        click_value: clickValue,
        average_order_value: averageOrderValue,
        ...optionalParams,
      },
    }
  )
}

type CampaignSummaryBase = {
  id: string
  name: string
  status?: string
}

export type EmailCampaignSummary = CampaignSummaryBase & {
  inbody_click_uplift?: number
  open_uplift?: number
  click_uplift?: number
  incremental_opens?: number
  incremental_clicks?: number
}

export type PushCampaignSummary = CampaignSummaryBase & {
  direct_open_uplift?: number
  influenced_open_uplift?: number
  incremental_direct_open?: number
  incremental_influenced_opens?: number
  total_opens_uplift?: number
  incremental_total_opens?: number
}

export type SmsCampaignSummary = CampaignSummaryBase & {
  click_uplift?: number
  incremental_clicks?: number
}

export type CampaignSummaryResponse = {
  data: {
    email_campaigns: EmailCampaignSummary[]
    push_campaigns: PushCampaignSummary[]
    sms_campaigns: SmsCampaignSummary[]
  }
}

type EmailProject = {
  id: string
  name: string
  number_of_campaigns: number
  open_uplift: number
  click_uplift: number
  incremental_opens: number
  incremental_clicks: number
}

type PushProject = {
  id: string
  name: string
  number_of_campaigns: number
  direct_open_uplift: number
  influenced_open_uplift: number
  incremental_direct_opens: number
  incremental_influenced_opens: number
}

type SmsProject = {
  id: string
  name: string
  number_of_campaigns: number
  click_uplift: number
  incremental_clicks: number
}

type ProjectSummaryResponse = {
  email_projects: EmailProject[]
  push_projects: PushProject[]
  sms_projects: SmsProject[]
}

type AccountSummaryResponse = {
  email: {
    number_of_campaigns: number
    open_uplift: number
    click_uplift: number
    inbody_click_uplift?: number
    incremental_opens?: number
    incremental_clicks?: number
  }
  push: {
    number_of_campaigns: number
    direct_open_uplift: number
    influenced_open_uplift: number
    incremental_direct_opens?: number
    incremental_influenced_opens?: number
  }
  sms: {
    number_of_campaigns: number
    click_uplift: number
    incremental_clicks?: number
  }
}

export const fetchCampaignSummaryReport = <T extends ReportingLevel>({
  accountId,
  reportingLevel,
  projectIds,
  campaignIds,
  startDate,
  endDate,
  distributionType,
}: {
  accountId: string
  reportingLevel: T
  distributionType: DistributionType | undefined
  campaignIds?: string[]
} & BaseParams): Promise<
  AxiosResponse<
    T extends 'campaign'
      ? CampaignSummaryResponse
      : T extends 'project'
      ? { data: ProjectSummaryResponse }
      : { data: AccountSummaryResponse }
  >
> => {
  const optionalParams =
    startDate && endDate ? { start_date: startDate, end_date: endDate } : {}
  const hasCampaignIds = campaignIds && campaignIds.length > 0
  const hasProjectIds = projectIds && projectIds.length > 0

  return connectInternalApi.get(
    `v1/core/reporting/reporting/accounts/${accountId}/report/summary`,
    {
      params: {
        campaign_ids: hasCampaignIds ? campaignIds : undefined,
        project_ids: hasProjectIds && !hasCampaignIds ? projectIds : undefined,
        reporting_level: reportingLevel,
        distribution_type: distributionType,
        ...optionalParams,
      },
    }
  )
}

interface WidgetSummaryResponse extends WigdetApiResponseBase {
  id: 'widget_summary'
}

export interface WidgetUpliftReportsResponse extends WidgetGraphResponse {
  id: 'widget_uplift'
}

export interface WidgetIncrementalEventsResponse extends WidgetGraphResponse {
  id: 'widget_incremental_events'
}

interface WidgetIncrementalConfiguration {
  open_conversion_percentage?: number
  click_conversion_percentage?: number
  open_value?: number
  click_value?: number
  average_order_value?: number
  iso_currency?: IsoCurrency
  iso_language?: string
}

interface WidgetIncrementalTab extends WigdetApiResponseBase {
  configuration?: WidgetIncrementalConfiguration
  testing_method: string[]
  channel?: DistributionChannel
}

export interface WidgetIncrementalRevenueResponse
  extends WigdetApiResponseBase {
  id: 'widget_revenue'
  tabs: WidgetIncrementalTab[]
}

export type CampaignListItem = {
  id: string
  display_name: string
  status: 'Live' | 'Not Live'
}

export interface WidgetCampaignsResponse extends WigdetApiResponseBase {
  id: 'widget_campaigns'
  campaign_list: CampaignListItem[]
}

export type WidgetListResponse = {
  widgets: (
    | WidgetSummaryResponse
    | WidgetUpliftReportsResponse
    | WidgetIncrementalEventsResponse
    | WidgetIncrementalRevenueResponse
    | WidgetCampaignsResponse
  )[]
}

export const fetchWidgetList = ({
  accountId,
  projectIds,
  campaignIds,
  startDate,
  endDate,
}: {
  accountId: string
  projectIds: string[]
  campaignIds?: string[]
  startDate?: string
  endDate?: string
}): Promise<AxiosResponse<WidgetListResponse>> => {
  return connectInternalApi.get(
    `v1/core/monorail/accounts/${accountId}/performance-reports`,
    {
      params: { projectIds, campaignIds, startDate, endDate },
      data: {
        projectIds,
      },
    }
  )
}
