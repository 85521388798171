import Badge from 'common/components/Badge'
import Base from 'common/components/table/cells/Base'

type Props = {
  isRecommended: boolean
}

const RecommendedCell = ({ isRecommended }: Props) => {
  return (
    <Base>
      {isRecommended ? (
        <Badge size="small" variant="neutral" text="Recommended" />
      ) : null}
    </Base>
  )
}

export default RecommendedCell
