import { ReactNode } from 'react'
import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'

type Props = {
  items: ReactNode[]
  itemNames: string[]
  size: 'small' | 'medium'
}

const OverlappingItems = ({ items, itemNames, size }: Props) => {
  return (
    <div className="flex items-center flex-row">
      {items.length < 4
        ? items.map((item, index) => {
            const isLast = index === items.length - 1
            return isLast ? (
              item
            ) : (
              <div className="-mr-2" key={index}>
                {item}
              </div>
            )
          })
        : items.map((item, index) => {
            if (index < 2) {
              return (
                <div className="-mr-2" key={index}>
                  {item}
                </div>
              )
            }
            if (index === 2) {
              const remainingItems = itemNames.slice(2)
              return (
                <Tooltip key={index} overlay={remainingItems.join(', ')}>
                  <div>
                    <span
                      className={cx(
                        'flex items-center justify-center rounded-full border-2 border-white bg-coolGray-50 text-coolGray-400 font-medium',
                        {
                          'w-8 h-8 text-xs': size === 'small',
                          'w-10 h-10 text-base': size === 'medium',
                        }
                      )}
                    >
                      +{items.length - index}
                    </span>{' '}
                  </div>
                </Tooltip>
              )
            }
            return null
          })}
    </div>
  )
}

export default OverlappingItems
