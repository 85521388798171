import React from 'react'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import solidGauge from 'highcharts/modules/solid-gauge'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'

import { GraphData } from './NumberOfCampaignsWidget'

highchartsMore(Highcharts)
solidGauge(Highcharts)

interface Props {
  total: number
  results: GraphData
  selectedTimeIndex: number
  ref: React.RefCallback<ChartInstance>
}

interface SerieStyle {
  color: string
  radius: string
  innerRadius: string
}

interface SerieData extends SerieStyle {
  y: number
}

interface Serie {
  name: string
  type: 'solidgauge'
  data: SerieData[]
}

const backgroundColor = '#f3f4f6'

const serieStyles: SerieStyle[] = [
  { color: '#0369a1', radius: '105%', innerRadius: '90%' },
  { color: '#0284c7', radius: '85%', innerRadius: '70%' },
  { color: '#0ea5e9', radius: '65%', innerRadius: '50%' },
  { color: '#38bdf8', radius: '45%', innerRadius: '30%' },
  { color: '#7dd3fc', radius: '25%', innerRadius: '10%' },
  { color: '#bae6fd', radius: '5%', innerRadius: '-10%' },
]

export default React.forwardRef<ChartInstance, Props>(
  ({ total, results }, ref) => {
    const noCampaigns = total === 0

    const tracks: Highcharts.PaneBackgroundOptions[] = results.map((_, i) => {
      return {
        outerRadius: serieStyles[i].radius,
        innerRadius: serieStyles[i].innerRadius,
        backgroundColor: backgroundColor,
        borderWidth: 0,
      }
    })

    const series: Serie[] = results.map(({ label, value }, i) => {
      return {
        name: label,
        type: 'solidgauge',
        data: [
          {
            ...serieStyles[i],
            y: value,
          },
        ],
      }
    })

    const options: Highcharts.Options = {
      chart: {
        type: 'solidgauge',
        height: '100%', // same height as width
        margin: [0, 0, 0, 0],
      },

      credits: {
        enabled: false,
      },

      title: undefined,

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: tracks,
        size: '95%', // remove whitespace around chart
      },

      yAxis: {
        min: 0,
        max: total,
        lineWidth: 0,
        tickPositions: [],
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
        },
      },

      series: noCampaigns ? undefined : series,

      exporting: {
        buttons: {
          contextButton: {
            enabled: false,
          },
        },
      },
    }

    return (
      <div className="flex items-center text-coolGray-600">
        <div className="max-w-35 w-1/2">
          <HighchartsReact
            data-testid="activity-gauge"
            ref={ref}
            highcharts={Highcharts}
            constructorType="chart"
            options={options}
            immutable={true}
          />
        </div>
        <ul className="ml-8">
          {results.map(({ label, value }, index) => {
            const color = serieStyles[index].color
            return (
              <li key={label} className="flex items-center text-lg">
                <div
                  className="h-2.5 w-2.5 mr-3"
                  style={{
                    backgroundColor: noCampaigns ? backgroundColor : color,
                  }}
                />
                <span className="whitespace-nowrap text-base">
                  {value} {label}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
)
