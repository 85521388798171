import cx from 'classnames'

type Props = {
  className?: string
  children: React.ReactNode
}

const CalculatorCard = ({ className, children }: Props) => {
  return (
    <div
      className={cx(
        'flex border border-maroon-500 py-5 px-4 bg-maroon-50',
        className
      )}
    >
      {children}
    </div>
  )
}

export default CalculatorCard
