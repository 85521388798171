import Button from 'common/components/button'
import { SmallTick, Thumb } from 'common/icons'
import { ContentWithProgress } from 'features/futurama/api/queries/useGetContentQuery'

interface Props {
  progress: ContentWithProgress['approval_progress']
  onApprove: () => void
}

const ApproveButton = ({ progress, onApprove }: Props) => {
  return (
    <>
      {{
        approved: (
          <Button
            data-cy="approve-button"
            className="bg-green-500 text-white"
            prefixIcon={<SmallTick isDefaultColor={false} size={4} />}
            disabled
          >
            Approved
          </Button>
        ),
        rejected: (
          <Button
            data-cy="approve-button"
            ghost
            prefixIcon={
              <Thumb
                className="text-coolGray-400"
                isDefaultColor={false}
                size={4}
              />
            }
            disabled
          >
            Approve
          </Button>
        ),
      }[progress] || (
        <Button
          data-cy="approve-button"
          className="bg-blue-500 text-white hover:bg-blue-600"
          prefixIcon={
            <Thumb isDefaultColor={false} className="text-white" size={4} />
          }
          onClick={onApprove}
        >
          Approve
        </Button>
      )}
    </>
  )
}

export default ApproveButton
