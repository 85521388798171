import { useEffect, useState } from 'react'

import { ItemType } from 'common/components/SummaryWidget//SummaryItem'
import SummaryWidget from 'common/components/SummaryWidget/SummaryWidget'
import { formatNumber } from 'common/helpers/numeric'
import { useAppSelector } from 'common/hooks/redux'
import { getAllProjects } from 'features/campaigns/store/campaignSlice'

import { fetchCampaignSummaryReport } from '../api'

export const labels = {
  totalCampaignsCount: 'Total Experiments',
  variantsCount: 'Live Variants',
  project: 'No. Projects',
  campaign: 'No. Experiments',
  openUplift: 'Open uplift',
  clickUplift: 'Click uplift',
  incrementalOpens: 'Incremental opens',
  incrementalClicks: 'Incremental clicks',
  inBodyClickUplift: 'In-Body Click Uplift',
  directOpenUplift: 'Direct Open uplift',
  totalOpensUplift: 'Total Opens uplift',
  incrementalDirectOpens: 'Incremental Direct Opens',
  incrementalTotalOpens: 'Incremental Total Opens',
}

const SummaryWidgetContainer = () => {
  const projects = useAppSelector(getAllProjects)
  const {
    product,
    projects: selectedProjects,
    campaigns: selectedCampaigns,
    channel,
    startDate,
    endDate,
  } = useAppSelector((state) => state.reports)
  const nbProjects =
    selectedProjects.length === 0 ? projects.length : selectedProjects.length
  const isEngage = product === 'broadcast'

  const [items, setItems] = useState<ItemType[]>([])
  const formatPercentage = (value: number | undefined) =>
    value ? `${value}%` : value

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    ;(async function () {
      setIsLoading(true)
      setHasError(false)

      try {
        const result = await fetchCampaignSummaryReport({
          accountId,
          reportingLevel: 'account',
          projectIds: selectedProjects,
          campaignIds: selectedCampaigns,
          startDate,
          endDate,
          distributionType: product,
        })

        const channelKey = channel?.name?.toLowerCase()
        if (!channelKey) {
          throw new Error('Channel key missing')
        }

        const channelData = result.data.data[channelKey]

        if (!channelData.length) {
          setItems([])
          return
        }

        const [
          {
            number_of_live_variants,
            click_uplift,
            incremental_clicks,
            incremental_opens,
            number_of_campaigns,
            open_uplift,
            inbody_click_uplift,
            direct_open_uplift,
            total_opens_uplift,
            incremental_direct_open,
            incremental_total_opens,
          },
        ] = channelData

        const hasInbodyClickUplift = !!inbody_click_uplift
        const responsiveHideClass = 'hidden sm:block'

        const commonEnagageItems = [
          {
            label: labels.project,
            value: nbProjects,
            className: responsiveHideClass,
          },
          {
            label: labels.campaign,
            value: number_of_campaigns && formatNumber(number_of_campaigns),
            className: hasInbodyClickUplift ? responsiveHideClass : '',
          },
        ]

        const commonReactItems = [
          {
            label: labels.totalCampaignsCount,
            value: number_of_campaigns && formatNumber(number_of_campaigns),
            className: hasInbodyClickUplift ? responsiveHideClass : '',
          },
          {
            label: labels.variantsCount,
            value:
              number_of_live_variants && formatNumber(number_of_live_variants),
            className: responsiveHideClass,
          },
        ]

        const commonItems = isEngage ? commonEnagageItems : commonReactItems

        if (channelKey === 'email') {
          setItems([
            ...commonItems,
            {
              label: labels.openUplift,
              value: formatPercentage(open_uplift),
            },
            {
              label: labels.clickUplift,
              value: formatPercentage(click_uplift),
            },
            ...(hasInbodyClickUplift
              ? [
                  {
                    label: labels.inBodyClickUplift,
                    value: formatPercentage(inbody_click_uplift),
                  },
                ]
              : []),
            {
              label: labels.incrementalOpens,
              value: incremental_opens && formatNumber(incremental_opens),
            },
            {
              label: labels.incrementalClicks,
              value: incremental_clicks && formatNumber(incremental_clicks),
            },
          ])
        } else if (channelKey === 'push') {
          setItems([
            ...commonItems,
            {
              label: labels.directOpenUplift,
              value: formatPercentage(direct_open_uplift),
            },
            {
              label: labels.totalOpensUplift,
              value: total_opens_uplift && formatNumber(total_opens_uplift),
            },
            {
              label: labels.incrementalDirectOpens,
              value:
                incremental_direct_open &&
                formatNumber(incremental_direct_open),
            },
            {
              label: labels.incrementalTotalOpens,
              value:
                incremental_total_opens &&
                formatNumber(incremental_total_opens),
            },
          ])
        } else if (channelKey === 'sms') {
          setItems([
            ...commonItems,
            {
              label: labels.clickUplift,
              value: formatPercentage(click_uplift),
            },
            {
              label: labels.incrementalClicks,
              value: incremental_clicks && formatNumber(incremental_clicks),
            },
          ])
        }
      } catch {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [
    accountId,
    product,
    isEngage,
    channel,
    selectedProjects,
    selectedCampaigns,
    startDate,
    endDate,
    nbProjects,
  ])

  return (
    <SummaryWidget items={items} isLoading={isLoading} hasError={hasError} />
  )
}

export default SummaryWidgetContainer
