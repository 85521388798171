interface Props {
  className: string
  kpiTitle: string
  kpiValue: string | number
}

export const PerformanceIndicator = ({
  className,
  kpiTitle,
  kpiValue,
}: Props) => {
  return (
    <div className={className}>
      <div className="font-bold text-4xl text-maroon-500 break-all mb-2">
        {kpiValue.toLocaleString()}
      </div>
      <div className="text-base text-coolGray-400"> {kpiTitle}</div>
    </div>
  )
}
