import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { hideLeftPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

import LeftPanel from '../../components/layout/LeftPanel'

import ElementsList from './ElementsList'

const LeftSection = () => {
  const dispatch = useAppDispatch()

  const isLeftPanelVisible = useAppSelector(
    (state) => state.unifiedFlow.isLeftPanelVisible
  )

  return (
    <LeftPanel
      isVisible={isLeftPanelVisible}
      onOutsideClick={() => dispatch(hideLeftPanel())}
    >
      <ElementsList />
    </LeftPanel>
  )
}

export default LeftSection
