/* eslint-disable max-lines */

import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import type { Moment } from 'moment'
import moment from 'moment-timezone'

import Button from 'common/components/button/Button'
import DatePickerAntd from 'common/components/DatePicker'
import SingleSelect from 'common/components/singleSelect'
import TimePicker from 'common/components/TimePicker'
import Tooltip from 'common/components/Tooltip'
import { Time as TimeIcon } from 'common/icons'

import { showBanner } from '../../../common/common.actions'
import apiUtil from '../../../utils/Api'
import {
  fetchUnscheduledCampaigns,
  resetReduxForm,
  setDateForScheduleCampaign,
  setFinalCampaignId,
  setSplitCampaignId,
  setTimeBeforeFinalDate,
  setTimeForScheduleCampaign,
  updateSubjectLineSailthruCampaign,
  updateSummary,
} from '../../../Workflow.actions'

import styles from '../Integrations.module.css'

const FormItem = Form.Item

const UPDATE_ZEPHYR_SCRIPT_TOOLTIP_TEXT = `This will update your splits email 
with the Zephyr script for testing purposes.`
const SPLIT_TEST_SELECTOR_TOOLTIP_TEXT = `Select a draft campaign that has all 
required fields completed, including the From Name and Design. You can use the 
first email in an AB Split campaign.`
const FINAL_SEND_SELECTOR_TOOLTIP_TEXT = `Select a draft campaign that has all 
required fields completed, including the From Name and Design. You can use the 
second email in an AB Split campaign.`
const DATE_SELECTOR_TOOLTIP_TEXT =
  'Select the date when you want to send the test.'
const TIME_SELECTOR_TOOLTIP_TEXT = `Select a time in the future. Scheduled time 
will use your Sailthru account timezone.`
const WAIT_TIME_TOOLTIP_TEXT =
  'Select how long you want to wait before sending the winner.'

const Placeholder = ({ text }) => (
  <span className={styles.integrationPlaceholder}>{text}</span>
)

class SailthruIntegration extends React.Component<any, any> {
  myRef: any

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      errorInput: false,
      errorMessage: '',
      scheduleInProgress: false,
      shouldShowTooltip: false,
      waitHours: undefined,
      waitMinutes: undefined,
    }
  }

  componentDidMount() {
    const { projectId } = this.props
    this.props.fetchUnscheduledCampaigns(projectId)
    // part of a hook for tooltip disappearing on time input focus:
    document.addEventListener('mousedown', this.handleClickOutside)
    // end of hook
    this.props.resetReduxForm()
  }

  // part of a hook for tooltip disappearing on time input focus:
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (e) => {
    if (
      !this.myRef.current.picker ||
      !this.myRef.current.picker.input.contains(e.target)
    ) {
      this.setState({ shouldShowTooltip: false })
    }
  }

  isFormValid = () => {
    const { scheduleDate, scheduleTime, finalCampaign, splitCampaign } =
      this.props.sailthruIntegration
    const { waitHours, waitMinutes } = this.state
    // We need to do below as 0 will be converted to falsy value
    const minutes = waitMinutes !== undefined
    return (
      scheduleDate &&
      scheduleTime &&
      finalCampaign &&
      splitCampaign &&
      waitHours &&
      minutes
    )
  }

  handleSubmit = () => {
    const {
      sailthruIntegration,
      projectId,
      currentCampaignId,
      updateSummary,
      showBanner,
    } = this.props
    const { finalCampaign, splitCampaign, hoursBeforeFinalDateTime } =
      sailthruIntegration
    const { waitMinutes, waitHours } = this.state
    if (finalCampaign && splitCampaign && finalCampaign === splitCampaign) {
      this.setState({
        errorInput: true,
        errorMessage:
          'The final campaign must be different from the split campaign.',
      })
      return
    }
    if (this.isFormValid()) {
      this.setState({ errorInput: false, scheduleInProgress: true })
      const scheduleDateTime = this.createTheFinalScheduleTime()
      apiUtil('nedward/schedule', {
        method: 'POST',
        body: {
          split_campaign_date_time: scheduleDateTime,
          final_campaign_id: finalCampaign.toString(),
          split_campaign_id: splitCampaign.toString(),
          time_before_to_schedule_final: {
            waitHours: Number(waitHours),
            waitMinutes: Number(waitMinutes),
          },
          project_id: projectId,
          campaign_id: currentCampaignId,
        },
      })
        .then(
          (response) => {
            updateSummary({
              ...response.data,
              isScheduled: true,
              hoursBeforeFinal: hoursBeforeFinalDateTime,
            })
          },
          (err) => {
            showBanner({ content: err.message, type: 'error' })
          }
        )
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
        .finally(() => this.setState({ scheduleInProgress: false }))
    } else {
      this.setState({
        errorInput: true,
        errorMessage:
          'You need to complete all the inputs to schedule your experiments.',
      })
    }
  }

  handleUpdateZephyrScript = () => {
    const { scheduleDate, scheduleTime, finalCampaign, splitCampaign } =
      this.props.sailthruIntegration
    const { waitHours, waitMinutes } = this.state
    if (finalCampaign && splitCampaign && finalCampaign === splitCampaign) {
      this.setState({
        errorInput: true,
        errorMessage:
          'The final campaign must be different from the split campaign.',
      })
      return
    }
    if (
      scheduleDate &&
      scheduleTime &&
      finalCampaign &&
      splitCampaign &&
      waitHours &&
      (waitMinutes || waitMinutes === 0)
    ) {
      this.setState({ errorInput: false, scheduleInProgress: true })
      const { projectId, currentCampaignId: campaignId } = this.props
      const scheduleDateTime = this.createTheFinalScheduleTime()
      const params = {
        scheduleDateTime,
        scheduleTime,
        finalCampaign,
        splitCampaign,
        hoursBeforeFinalDateTime: {
          waitHours: Number(waitHours),
          waitMinutes: Number(waitMinutes),
        },
        projectId,
        campaignId,
      }
      this.props.updateSubjectLineSailthruCampaign(params).then(() => {
        this.setState({ scheduleInProgress: false })
      })
    } else {
      this.setState({
        errorInput: true,
        errorMessage:
          'You need to complete all the inputs to update your Subjectline.',
      })
    }
  }

  selectWaitTime = (val, type) => {
    const { setHoursBeforeFinalDate } = this.props
    const { waitHours, waitMinutes } = this.state
    let params: any = undefined
    if (type === 'hours') {
      params = {
        waitHours: val,
        waitMinutes: waitMinutes || 0,
      }
    }
    if (type === 'minutes') {
      params = {
        waitHours,
        waitMinutes: val,
      }
    }
    this.setState({ ...params }, () => {
      setHoursBeforeFinalDate(params)
    })
  }

  static getHoursLeft() {
    const hours: any = []
    for (let i = 1; i < 13; i++) {
      hours.push({
        label: i > 1 ? `${i} hrs` : `${i} hr`,
        value: `${i}`,
      })
    }
    return hours
  }

  static getMinutesLeft() {
    const minutes: any = []
    for (let i = 0; i < 60; i++) {
      if (i % 15 === 0 || i === 0) {
        minutes.push({
          label: i === 0 ? '00' : i.toString(),
          value: `${i}`,
        })
      }
    }
    return minutes
  }

  getDefaultTimeValue = () => {
    let h = moment().hour()
    let m = moment().minutes()
    // This prevent the possibility for the user to pick a time in the past.
    if (m >= 45) {
      m = 0
      h += 1
    }
    return moment(`${h}:${m}`, 'HH:mm')
  }

  // Private Functions
  createTheFinalScheduleTime = () => {
    const { scheduleDate, scheduleTime } = this.props.sailthruIntegration

    scheduleDate.hour(scheduleTime._d.getHours())
    scheduleDate.minute(scheduleTime._d.getMinutes())
    return scheduleDate.toString()
  }

  render() {
    const {
      sailthruIntegration,
      unscheduledCampaigns,
      isLoading,
      setFinalCampaignId,
      setDateForScheduleCampaign,
      setTimeForScheduleCampaign,
      setSplitCampaignId,
    } = this.props
    const {
      errorInput,
      errorMessage,
      scheduleInProgress,
      shouldShowTooltip,
      waitMinutes,
      waitHours,
    } = this.state
    let errorInputElement
    if (errorInput) {
      errorInputElement = (
        <div>
          <label className="label text-red-600">{errorMessage}</label>
        </div>
      )
    }

    return (
      <>
        <Drawer.Content>
          <Form>
            <FormItem className="mb-8">
              <Tooltip
                overlay={SPLIT_TEST_SELECTOR_TOOLTIP_TEXT}
                trigger="focus"
                placement="right"
                overlayStyle={{ maxWidth: 226 }}
              >
                <SingleSelect
                  label="Select split test campaign"
                  isSearchable
                  menuPortalTarget={document.body}
                  menuShouldBlockScroll={true}
                  data-testid="Select"
                  placeholder={
                    <Placeholder text="Select an unscheduled campaign" />
                  }
                  onChange={(val) => setSplitCampaignId(val?.value)}
                  value={sailthruIntegration.splitCampaign}
                  options={unscheduledCampaigns.map((item) => ({
                    label: item.name,
                    value: item.blast_id,
                  }))}
                />
              </Tooltip>
            </FormItem>
            <FormItem className="mb-14">
              <Tooltip
                overlay={FINAL_SEND_SELECTOR_TOOLTIP_TEXT}
                trigger="focus"
                placement="right"
                overlayStyle={{ maxWidth: 226 }}
              >
                <SingleSelect
                  label="Select final campaign"
                  isSearchable
                  menuPortalTarget={document.body}
                  menuShouldBlockScroll={true}
                  data-testid="Select"
                  placeholder={
                    <Placeholder text="Select an unscheduled campaign" />
                  }
                  value={sailthruIntegration.finalCampaign}
                  onChange={(val) => setFinalCampaignId(val?.value)}
                  menuPosition="fixed"
                  options={sailthruIntegration.listCampaigns.map((item) => ({
                    label: item.name,
                    value: item.blast_id,
                  }))}
                />
              </Tooltip>
            </FormItem>
            <FormItem
              label="When do you want to send the split test emails?"
              className="mb-8"
            >
              <div className="flex justify-between">
                <Tooltip
                  overlay={DATE_SELECTOR_TOOLTIP_TEXT}
                  trigger="focus"
                  placement="left"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <DatePickerAntd
                    data-test-id="DatePicker"
                    aria-label="DatePicker"
                    onChange={(date: Moment | null) => {
                      if (date) {
                        setDateForScheduleCampaign(date)
                      }
                    }}
                    value={sailthruIntegration.scheduleDate}
                    allowClear
                  />
                </Tooltip>
                <Tooltip
                  overlay={TIME_SELECTOR_TOOLTIP_TEXT}
                  visible={shouldShowTooltip}
                  placement="right"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <TimePicker
                    data-test-id="TimePicker"
                    className={styles.integrationTimePicker}
                    format="HH:mm"
                    suffixIcon={<TimeIcon size={6} />}
                    onChange={(val: Moment | null) =>
                      setTimeForScheduleCampaign(val)
                    }
                    minuteStep={15}
                    defaultOpenValue={this.getDefaultTimeValue()}
                    ref={this.myRef}
                  />
                </Tooltip>
              </div>
            </FormItem>
            <FormItem
              label="How long to wait before to send the winner?"
              className="mb-14"
            >
              <div className="flex">
                <Tooltip
                  overlay={WAIT_TIME_TOOLTIP_TEXT}
                  trigger="focus"
                  placement="left"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <SingleSelect
                    className="w-36"
                    data-testid="Select"
                    menuPlacement="top"
                    placeholder={<Placeholder text="Select wait hours" />}
                    value={waitHours}
                    onChange={(val) => this.selectWaitTime(val?.value, 'hours')}
                    options={SailthruIntegration.getHoursLeft()}
                  />
                </Tooltip>
                <div style={{ margin: '0 10px' }}> </div>
                <Tooltip
                  overlay={WAIT_TIME_TOOLTIP_TEXT}
                  trigger="focus"
                  placement="right"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <SingleSelect
                    className="flex-1"
                    data-testid="Select"
                    placeholder={<Placeholder text="Select wait minutes" />}
                    value={waitMinutes}
                    menuPlacement="top"
                    isDisabled={!waitHours}
                    onChange={(val) =>
                      this.selectWaitTime(val?.value, 'minutes')
                    }
                    options={SailthruIntegration.getMinutesLeft()}
                  />
                </Tooltip>
              </div>
            </FormItem>
            {errorInputElement}
          </Form>
        </Drawer.Content>
        <br />
        <Drawer.Footer>
          <Tooltip
            overlay={UPDATE_ZEPHYR_SCRIPT_TOOLTIP_TEXT}
            trigger="hover"
            placement="right"
            overlayStyle={{ maxWidth: 226 }}
          >
            <Button
              data-cy="sailthrough-test-zephyr-script-button"
              data-test-id="Submit"
              className="mr-4"
              variant="secondary" // TODO check the answer from Ross
              loading={isLoading}
              onClick={() => this.handleUpdateZephyrScript()}
            >
              Test Zephyr Script
            </Button>
          </Tooltip>
          <Button
            data-cy="sailthrough-schedule-button"
            data-test-id="Submit"
            className="ant-btn-lg"
            variant="primary"
            loading={scheduleInProgress}
            onClick={() => this.handleSubmit()}
          >
            Schedule Experiment
          </Button>
        </Drawer.Footer>
      </>
    )
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    showBanner: (config) => dispatch(showBanner(config)),
    setSplitCampaignId: (splitId) => dispatch(setSplitCampaignId(splitId)),
    setFinalCampaignId: (finalCampaignId) =>
      dispatch(setFinalCampaignId(finalCampaignId)),
    setHoursBeforeFinalDate: (hours) => dispatch(setTimeBeforeFinalDate(hours)),
    setDateForScheduleCampaign: (date) =>
      dispatch(setDateForScheduleCampaign(date)),
    setTimeForScheduleCampaign: (time) =>
      dispatch(setTimeForScheduleCampaign(time)),
    updateSummary: (summaryObject) => dispatch(updateSummary(summaryObject)),
    fetchUnscheduledCampaigns: (id) => dispatch(fetchUnscheduledCampaigns(id)),
    updateSubjectLineSailthruCampaign: (params) =>
      dispatch(updateSubjectLineSailthruCampaign(params)),
    resetReduxForm: () => dispatch(resetReduxForm()),
  }
}

export function mapStateToProps(state) {
  return {
    projectId: state.campaignStates.projectId,
    currentCampaignId: state.campaignStates.currentCampaignId,
    isLoading: state.campaignStates.isLoading,
    sailthruIntegration: state.campaignStates.sailthruIntegration,
    unscheduledCampaigns:
      state.campaignStates.sailthruIntegration.listCampaigns,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SailthruIntegration)
