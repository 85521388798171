import { FC, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { isEqual } from 'lodash'

import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import Modal from 'common/components/Modal'
import Scrollable from 'common/components/scrollable'
import Spinner from 'common/components/spinner'
import Table, { Column } from 'common/components/table'
import { formatDate } from 'common/helpers/date'
import { fetchProjectCampaigns } from 'features/projects/api'

type Campaign = {
  id: string
  name: string
  created: string
  sendDate: string
}

const columns: Column<Campaign>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Date of creation',
    accessor: 'created',
  },
  {
    Header: 'Date of send',
    accessor: 'sendDate',
  },
]

type Props = {
  accountId: string
  projectId: string
  isOpen: boolean
  onCancelClick: () => void
  onApplyClick: () => void
  selectedIds: string[]
  onRowSelect: (ids: string[]) => void
}

const SelectCampaignsModal: FC<Props> = ({
  accountId,
  projectId,
  isOpen,
  onCancelClick,
  onApplyClick,
  selectedIds,
  onRowSelect,
}) => {
  const query = useQuery(
    ['projectUpdateCampaigns', accountId, projectId],
    ({ signal }) => {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()

      const promise = fetchProjectCampaigns(accountId, projectId, source).then(
        (data) => data.campaigns
      )

      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by TanStack Query')
      })

      return promise
    },
    {
      select: (data): Campaign[] =>
        data.map(({ _id, name, created, send_date }) => ({
          id: _id,
          name,
          created: formatDate(created) ?? '',
          sendDate: formatDate(send_date) ?? '',
        })),
    }
  )

  const getUiStatus = () => {
    const { status, data } = query

    if (status === 'success' && !data?.length) {
      return 'noData'
    } else {
      return status
    }
  }

  const handleSelectRow = useCallback(
    (rows: Campaign[]) => {
      const ids = rows.map(({ id }) => id)

      if (!isEqual(ids, selectedIds)) {
        onRowSelect(ids)
      }
    },
    [selectedIds, onRowSelect]
  )

  return (
    <Modal
      key={String(isOpen)}
      data-cy="select-campaigns-modal"
      bodyStyle={{ width: 700 }}
      visible={isOpen}
      closable={false}
      footer={null}
      hasMargin={false}
      centered
    >
      <div className="py-1">
        <Scrollable
          header={
            <div className="flex p-4">
              <h1 className="mt-2 text-2xl font-bold text-coolGray-800 rounded-full">
                Apply changes to selected existing experiments
              </h1>
            </div>
          }
          contentClassName="min-w-149 max-h-149"
          content={
            {
              loading: <Spinner />,
              noData: (
                <div className="p-4">Project has no unsent experiments</div>
              ),
              success: (
                <>
                  <div className="mb-4 mx-4 text-base text-coolGray-800">
                    <div>
                      The changes you have made to the project settings will be
                      applied to all new experiments you set up from now.
                    </div>
                    <div>
                      Select the existing/future experiments that you would like
                      to apply these settings to.
                    </div>
                  </div>
                  <Table
                    data-cy="select-campaigns-table"
                    columns={columns}
                    data={query.data}
                    onSelectRow={handleSelectRow}
                    rowSelection="multiple"
                    disablePagination
                  />
                </>
              ),
              error: (
                <div className="p-4 text-red-400">
                  Failed to load experiments
                </div>
              ),
            }[getUiStatus()]
          }
          footer={
            <BottomToolbar className="justify-between">
              <Button data-cy="cancel-button" ghost onClick={onCancelClick}>
                Cancel
              </Button>
              <Button
                data-cy="apply-button"
                variant="primary"
                disabled={!selectedIds.length}
                onClick={onApplyClick}
              >
                Apply
              </Button>
            </BottomToolbar>
          }
        />
      </div>
    </Modal>
  )
}

export default SelectCampaignsModal
