import React from 'react'
import * as Highcharts from 'highcharts/highstock'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'
import { yAxisConfig } from 'common/helpers/ui/graph'

export interface ReactPerformanceResult {
  click_rate: number
  day: string
  open_rate: number
}

interface Props {
  results: ReactPerformanceResult[]

  ref: React.RefCallback<ChartInstance>
}

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
})

export default React.forwardRef<ChartInstance, Props>(({ results }, ref) => {
  const options: Highcharts.Options = {
    title: undefined,
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        theme: {
          stroke: 'none',
        },
      },
    },
    navigator: {
      series: {
        color: '#61bec7',
        type: 'areaspline',
      },
    },
    xAxis: {
      type: 'datetime',
      categories: results.map((r) => r.day),
      labels: {
        formatter: function (a) {
          return Highcharts.dateFormat('%e %b %y', new Date(a.value).valueOf())
        },
        style: {
          color: '#6b7280',
          fontSize: '12px',
          fontWeight: '500',
          opacity: 0.8,
        },
      },
    },
    yAxis: {
      ...yAxisConfig,
      labels: {
        format: '{value}%',
        style: {
          color: '#6b7280',
          fontSize: '12px',
          fontWeight: '500',
          opacity: 0.8,
        },
        padding: 0,
        align: 'right',
        reserveSpace: true,
      },
    },
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Roboto',
      },
      spacing: [10, 0, 10, 0],
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: false,
      borderRadius: 5,
      padding: 16,
      backgroundColor: '#ffffff',
      shadow: true,
      formatter: function () {
        const series = this.points?.map(
          (point) =>
            `<span style="color:${point.color}; font-size: 16px; margin-right: 6px;">
              ■
            </span>
            ${point.y}% ${point.series.options.name}</br>
          `,
          ''
        )
        return series
      },
    },
    legend: {
      y: 14,
      enabled: true,
      useHTML: true,
      itemStyle: {
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#1f2937',
        opacity: 0.8,
        lineHeight: '24px',
      },
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0,
      symbolPadding: 15,
      margin: 0,
      padding: 22,
      verticalAlign: 'bottom',
    },
    plotOptions: {
      series: {
        ...({ borderRadiusTopLeft: 3, borderRadiusTopRight: 3 } as any),
        stickyTracking: false,
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: 'column',
        name: 'Open rate',
        data: results.map((result) => result.open_rate),
        color: '#0369a1',
      },
      {
        type: 'column',
        name: 'Click rate',
        data: results.map((result) => result.click_rate),
        color: '#0ea5e9',
      },
    ],
  }
  return (
    <HighchartsReact
      ref={ref}
      highcharts={Highcharts}
      constructorType="chart"
      options={options}
      immutable={true}
    />
  )
})
