import { AirshipExperiment } from './types'

interface State {
  selectedExperiment: string | undefined
  experimentsList: AirshipExperiment[] | undefined
  isExperimentsLoading: boolean
  isRetrieveResultsButtonLoading: boolean
}

type AirshipReducerActions =
  | { type: 'get_experiments' }
  | { type: 'get_experiments_success'; value: AirshipExperiment[] }
  | { type: 'get_experiments_error' }
  | { type: 'select_experiment'; value: string }
  | { type: 'retrieve_results' }
  | { type: 'retrieve_results_success' }
  | { type: 'retrieve_results_error' }

export const initialState: State = {
  experimentsList: undefined,
  selectedExperiment: undefined,
  isExperimentsLoading: false,
  isRetrieveResultsButtonLoading: false,
}

export const airshipReducer = (state: State, action: AirshipReducerActions) => {
  switch (action.type) {
    case 'get_experiments':
      return { ...state, isExperimentsLoading: true }
    case 'get_experiments_success':
      return {
        ...state,
        experimentsList: action.value,
        isExperimentsLoading: false,
      }
    case 'get_experiments_error':
      return { ...state, isExperimentsLoading: false }
    case 'select_experiment':
      return { ...state, selectedExperiment: action.value }
    case 'retrieve_results':
      return { ...state, isRetrieveResultsButtonLoading: true }
    case 'retrieve_results_success':
      return { ...state, isRetrieveResultsButtonLoading: false }
    case 'retrieve_results_error':
      return {
        ...state,
        isRetrieveResultsButtonLoading: false,
      }
  }
}
