import { useEffect } from 'react'
import { useRef } from 'react'
import isEmpty from 'lodash/isEmpty'

import { isProduction } from 'common/helpers/environment'
import { useAppSelector } from 'common/hooks/redux'

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

const Intercom = () => {
  const intercomInfo = useAppSelector((state) => state.authStates.intercomInfo)
  const isIntercomVisible = useAppSelector(
    (state) => state.authStates.isIntercomVisible
  )
  const isIntercomInitialized = useRef(false)

  const enableIntercom = !isEmpty(intercomInfo) && isProduction

  useEffect(() => {
    if (enableIntercom && isIntercomVisible && !isIntercomInitialized.current) {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        ...(intercomInfo as any),
        vertical_padding: 32,
      })
      isIntercomInitialized.current = true

      return () => {
        isIntercomInitialized.current = false
        window.Intercom('shutdown')
      }
    }
    if (!isIntercomVisible && isIntercomInitialized.current) {
      isIntercomInitialized.current = false
      window.Intercom('shutdown')
    }
  }, [enableIntercom, intercomInfo, isIntercomVisible])

  return null
}

export default Intercom
