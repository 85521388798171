import cx from 'classnames'

import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { updateIndustry } from '../store/styleGuideSlice'

type Props = {
  className?: string
}

const IndustryWidget = ({ className }: Props) => {
  const dispatch = useAppDispatch()
  const {
    isLoading,
    industries,
    editedContent: { industry: selectedIndustry },
  } = useAppSelector((state) => state.styleGuide)

  return (
    <Spinner isSpinning={isLoading}>
      <Widget
        type="basic"
        className={cx(
          'grid grid-cols-1 sm:grid-cols-3 grid-flow-col gap-x-6 gap-y-0',
          className
        )}
      >
        <WidgetHeader
          title="Industry"
          subtitle="This will add more context to the content you generate."
          className="col-start-1 row-span-1 col-span-2"
        />
        <div className="mt-2 flex justify-between col-start-1">
          <SingleSelect
            className="flex-none w-full sm:w-80"
            data-cy="industry-select"
            name="industry"
            placeholder="Select your industry"
            onChange={(value) =>
              value?.value && dispatch(updateIndustry(value.value))
            }
            isLoading={isLoading}
            menuPortalTarget={document.body}
            options={industries}
            value={selectedIndustry}
          />
        </div>
      </Widget>
    </Spinner>
  )
}

export default IndustryWidget
