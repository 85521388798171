import { useQueries, useQueryClient } from '@tanstack/react-query'
import flattenDeep from 'lodash/flattenDeep'
import isArray from 'lodash/isArray'
import apiUtil from 'workflow/utils/Api'

import { contentKeys } from '../../api/queryKeys'
import { getAllComponentElements } from '../helpers'

import { useContent } from './index'
const useVariantsToApproveCountQuery = () => {
  const { content } = useContent()
  const queryClient = useQueryClient()

  const allComponentElements = getAllComponentElements(content)

  const queries = allComponentElements
    .filter((element) => !!element.campaign_id)
    .map((element) => ({
      queryKey: [contentKeys.campaign(element.campaign_id as string)],
      queryFn: () =>
        apiUtil(`campaigns/${element.campaign_id}?includeDeleted=true`, {
          method: 'GET',
        }),
      staleTime: 60 * 1000 * 10, // 10min,
    }))

  const invalidateAll = () => {
    queries.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey)
    })
  }
  const results = useQueries({
    queries: queries,
  })

  const variantsCollection = flattenDeep(
    results
      .filter(
        (item) => isArray(item?.data?.data?.sl) && item.data.data.sl.length > 0
      )
      .map((item) => item.data.data.sl)
  )

  const variantsToBeApprovedCollection = variantsCollection.filter(
    (variant) => variant?.bandit_status?.status === 'pending'
  )

  return {
    isLoading: results.some((item) => item.isLoading),
    variantsToBeApprovedCount: variantsToBeApprovedCollection?.length || 0,
    invalidateAll,
  }
}

export default useVariantsToApproveCountQuery
