import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Element } from '@phrasee/phrasee-typings'
import { ValidationErrors } from 'final-form'

import Button from 'common/components/button'
import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import Modal from 'common/components/Modal'
import { useAppSelector } from 'common/hooks/redux'
import useUpdateElementMutation from 'features/futurama/api/mutations/useUpdateElementMutation'
import useGetTemplateQuery from 'features/futurama/api/queries/useGetTemplateQuery'

import { useContent } from '../../hooks'

const MAX_CONTENT_NAME_LENGTH = 30

type Props = {
  element: Element
  onClose: () => void
}

const ContentRenameModal = ({ element, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const updateElementMutation = useUpdateElementMutation()
  const { content } = useContent()
  const { data: contentTemplate } = useGetTemplateQuery(content?.template_id)

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const handleSave = async (values) => {
    const newContentName = values.content_name

    if (content && element) {
      setIsLoading(true)

      try {
        await updateElementMutation.mutateAsync({
          accountId,
          contentId: content?._id,
          elementId: element.element_id,
          updatedElement: {
            display_name: newContentName,
            name: newContentName,
          },
        })
        onClose()
      } catch (error) {
        return { content_name: error.response?.data?.message }
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Modal
      visible={true}
      centered
      closable={false}
      footer={null}
      data-cy="rename-content-modal"
      style={{ minWidth: 500 }}
    >
      <div className="flex w-109">
        <h1 className="mt-2 text-2xl font-bold text-coolGray-800">
          Rename content
        </h1>
      </div>

      <div className="mt-4 text-lg text-coolGray-500">
        <Form
          onSubmit={handleSave}
          initialValues={{
            content_name: element?.display_name,
          }}
          validate={(values) => {
            const errors: ValidationErrors = {}
            if (values.content_name?.length > MAX_CONTENT_NAME_LENGTH) {
              errors.content_name = `Content name should be up to ${MAX_CONTENT_NAME_LENGTH} characters`
            }

            return errors
          }}
          render={({ handleSubmit, hasValidationErrors, pristine, values }) => (
            <form onSubmit={handleSubmit} className="text-sm">
              <Field<string>
                name="content_name"
                render={({ input, meta }) => (
                  <FormItem
                    label="Content name"
                    guidingText={`This item in using content template '${contentTemplate?.display_name}'`}
                    htmlFor="content_name"
                    error={
                      meta.error ||
                      (!meta.dirtySinceLastSubmit && meta.submitError) ||
                      undefined
                    }
                  >
                    <Input
                      type="text"
                      id="content_name"
                      variant="default"
                      className="text-coolGray-800"
                      value={input.value}
                      name={input.name}
                      onChange={input.onChange}
                      disabled={isLoading}
                    />
                  </FormItem>
                )}
              />
              <div className="flex justify-end">
                <Button
                  ghost
                  className="mr-4 text-base text-coolGray-400 border border-coolGray-100 "
                  onClick={onClose}
                  data-cy="rename-content-modal-cancel-button"
                  disabled={isLoading}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="primary"
                  loading={isLoading}
                  disabled={
                    isLoading ||
                    hasValidationErrors ||
                    pristine ||
                    !values.content_name
                  }
                  data-cy="rename-content-modal-save-button"
                >
                  Save
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </Modal>
  )
}

export default ContentRenameModal
