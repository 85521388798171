import { generatePath, Redirect, Route } from 'react-router-dom'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import { adminLanguagePath, regionSettingsPath } from 'app/navigation/paths'
import { languageSubroutes } from 'app/navigation/staticSubRoutes.language'
import CustomSwitch from 'app/router/Switch'
import { useFlags } from 'launchdarkly-react-client-sdk'

import PageContainer from 'common/components/PageContainer'

import LanguageSubNav from './LanguageSubNav'

const Language = () => {
  const flags = useFlags()

  const routes = languageSubroutes
    .filter(({ accessFlags }) =>
      isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
    )
    .map((route) => {
      return { ...route, to: generatePath(route.to) }
    })

  return (
    <>
      <LanguageSubNav subroutes={languageSubroutes} />
      <PageContainer>
        <CustomSwitch>
          {routes.map(({ key, to, component: Component }) => (
            <Route key={key} path={to}>
              <Component />
            </Route>
          ))}
          <Redirect
            exact
            from={adminLanguagePath}
            to={generatePath(regionSettingsPath)}
          />
        </CustomSwitch>
      </PageContainer>
    </>
  )
}

export default Language
