import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CellProps } from 'react-table'

import Button from 'common/components/button'
import Modal from 'common/components/Modal'
import { Column, OnCellChange } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget/TableWidget'
import { formatDate } from 'common/helpers/formatDate'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { toggleDeletionWarningWithRelatedProjectsModalVisibility } from 'features/admin/brandVoice/store/brandVoiceSlice'

import { BrandVoiceAttachedProjectsBE } from '../api'
import useGetAllAttachedProjectsForBrandVoice from '../api/queries/useGetAllAttachedProjectsForBrandVoice'

const DeletionWarningWithRelatedProjectsModal = () => {
  const dispatch = useAppDispatch()
  const isDeletionWarningWithRelatedProjectsModalVisible = useAppSelector(
    (state) => state.brandVoice.isDeletionWarningWithRelatedProjectsModalVisible
  )
  const { brandVoiceId } = useParams<{ brandVoiceId: string }>()
  const { data: linkedProjects } =
    useGetAllAttachedProjectsForBrandVoice(brandVoiceId)

  const columns: Column<BrandVoiceAttachedProjectsBE>[] = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Title',
        minWidth: 300,
        disableSortBy: true,
      },
      {
        disableSortBy: true,
        width: 120,
        accessor: 'last_updated',
        Header: 'Last modified',
        Cell: (
          props: CellProps<BrandVoiceAttachedProjectsBE> & {
            onCellChange: OnCellChange
          }
        ) => (
          <span className="ml-4 my-auto">
            {formatDate(new Date(props.value))}
          </span>
        ),
      },
    ],
    []
  )

  return (
    <Modal
      closable={false}
      visible={isDeletionWarningWithRelatedProjectsModalVisible}
      style={{ minWidth: 500, maxHeight: 300 }}
    >
      <div
        className="mb-2"
        data-testid="deletionWarningWithRelatedProjectsModal"
      >
        <h2 className=" text-2xl mb-2">
          <span className="text-base-700 font-bold">
            Can’t delete brand voice!
          </span>
        </h2>
        <p className="text-lg text-base-300">
          As this project is assigned to projects it can’t be deleted right now.
        </p>
        <p className="text-lg text-base-300 mt-4">
          Please contact us to remove this brand voice from the projects listed
          below and then it can be deleted.
        </p>
      </div>
      <TableWidget.Widget columns={columns} data={linkedProjects}>
        <TableWidget.Header
          title="Assigned projects using Brand Voice ‘name’"
          subtitle={`${linkedProjects.length}${
            linkedProjects.length === 1 ? ' project found' : ' projects found'
          } `}
        />
      </TableWidget.Widget>
      <div className="flex justify-end">
        <Button
          className="mt-10"
          ghost
          onClick={() =>
            dispatch(
              toggleDeletionWarningWithRelatedProjectsModalVisibility(false)
            )
          }
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default DeletionWarningWithRelatedProjectsModal
