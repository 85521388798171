import IncrementalRevenueGraph from './IncrementalRevenueGraph'

interface Props {
  label: string
  value: string
  data: number[]
}

const IncrementalRevenueWidgetItem = ({ label, value, data }: Props) => {
  return (
    <div className="h-full flex flex-col justify-around">
      <div className="text-coolGray-800">
        <span className="mr-2 text-sm whitespace-nowrap">{label}</span>
        <span className="text-2xl font-bold">{value}</span>
      </div>
      <IncrementalRevenueGraph data={data} />
    </div>
  )
}

export default IncrementalRevenueWidgetItem
