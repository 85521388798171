import SelectComponent from '../components/select/SelectComponent'

import ChampionSendCard from './ChampionSendCard'
import CreateExperimentCard from './CreateExperimentCard'
import DeleteExperimentCard from './DeleteExperimentCard'
import { CordialBatchMessage, CordialExperimentType } from './types'

interface CordialDrawerContentProps {
  selectedBatchMessage: string | undefined
  selectedExperimentType: CordialExperimentType
  isExperimentCreated: boolean
  isBatchMessagesLoading: boolean
  isCreateExperimentButtonLoading: boolean
  isDeleteExperimentButtonLoading: boolean
  batchMessages: CordialBatchMessage[] | undefined
  championSendTimeHours: string | undefined
  championSendTimeMinutes: string | undefined
  createExperiment: () => void
  deleteExperiment: () => void
  updateSelectedBatchMessage: (value: string) => void
  updateExperimentType: (value: string) => void
  isCreateButtonDisabled: boolean
  updateChampionSendTimeHours: (value: string) => void
  updateChampionSendTimeMinutes: (value: string) => void
}

export default function DrawerContent({
  selectedBatchMessage,
  selectedExperimentType,
  isExperimentCreated,
  isBatchMessagesLoading,
  isCreateExperimentButtonLoading,
  isDeleteExperimentButtonLoading,
  batchMessages,
  championSendTimeHours,
  championSendTimeMinutes,
  createExperiment,
  deleteExperiment,
  updateSelectedBatchMessage,
  updateExperimentType,
  isCreateButtonDisabled,
  updateChampionSendTimeHours,
  updateChampionSendTimeMinutes,
}: CordialDrawerContentProps) {
  const generateBatchMessageSelectOptions = (batchMessages) => {
    return batchMessages.map((message) => ({
      id: message.bmID,
      name: message.name,
    }))
  }

  return (
    <>
      <SelectComponent
        isLoading={isBatchMessagesLoading}
        isSelectDisabled={isExperimentCreated}
        selectOptions={
          batchMessages && batchMessages.length > 0
            ? generateBatchMessageSelectOptions(batchMessages)
            : []
        }
        dataTestId="selectDraftMessage"
        onChange={(val) => updateSelectedBatchMessage(val)}
        labelTitle="Select a draft message"
        bottomText="This is a draft message previously set up in Cordial."
        defaultOption="Select"
        selectValue={selectedBatchMessage}
      />
      <SelectComponent
        isSelectDisabled={isExperimentCreated || !selectedBatchMessage}
        selectOptions={[
          { id: 'split', name: 'Split' },
          { id: 'split-with-champion', name: 'Split with champion' },
        ]}
        dataTestId="selectExperimentType"
        onChange={(val) => updateExperimentType(val)}
        labelTitle="Select an experiment type"
        defaultOption="Select"
        selectValue={selectedExperimentType}
      />

      {selectedExperimentType &&
        selectedExperimentType === 'split-with-champion' && (
          <>
            <ChampionSendCard
              isExperimentCreated={isExperimentCreated}
              championSendTimeHours={championSendTimeHours}
              championSendTimeMinutes={championSendTimeMinutes}
              updateChampionSendTimeHours={updateChampionSendTimeHours}
              updateChampionSendTimeMinutes={updateChampionSendTimeMinutes}
            />
          </>
        )}

      <div className="mt-2 font-medium">Scheduling</div>
      <div className="mt-2 mb-6 text-gray-400">
        All scheduling for your batch message experiment will be managed inside
        Cordial.
      </div>
      {isExperimentCreated ? (
        <DeleteExperimentCard
          deleteExperiment={deleteExperiment}
          isLoading={isDeleteExperimentButtonLoading}
        />
      ) : (
        <CreateExperimentCard
          createExperiment={createExperiment}
          isCreateButtonDisabled={isCreateButtonDisabled}
          isLoading={isCreateExperimentButtonLoading}
        />
      )}
    </>
  )
}
