import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  hideRightPanel,
  showRightPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import RightPanel from '../../components/layout/RightPanel'

import CampaignDetails from './CampaignDetails/CampaignDetails'

const RightSection = () => {
  const dispatch = useAppDispatch()

  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )

  useEffect(() => {
    dispatch(showRightPanel())
  }, [dispatch])

  return (
    <RightPanel
      visibility={rightPanelVisibility}
      onOutsideClick={() => dispatch(hideRightPanel())}
      className="z-10"
    >
      <CampaignDetails />
    </RightPanel>
  )
}

export default RightSection
