import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { isEqual } from 'lodash'

/**
 * a type-safe version of the `usePrevious` hook described here:
 * @see {@link https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state}
 */
export function usePrevious<T>(
  value: T
): MutableRefObject<T | undefined>['current'] {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

// TODO: Rename so that it's clear that it negates the result
export function useShallowCompare<T>(val: T): boolean {
  const prevVal = usePrevious<T>(val)
  return prevVal !== val
}

// TODO: Rename so that it's clear that it negates the result
export function useDeepCompare<T>(val: T): boolean {
  const prevVal = usePrevious<T>(val)
  return !isEqual(prevVal, val)
}

export function useDocumentTitle(title) {
  useEffect(() => {
    document.title = title
  }, [title])
}

export type Validations =
  | { validate: (value?: string) => boolean; message: string }[]
  | undefined

export function useValidationError(validations: Validations) {
  const [error, setError] = useState<string | undefined>(undefined)

  const validate = useCallback(
    (value?: string) => {
      setError(undefined)
      validations?.forEach((validation) => {
        if (validation.validate(value)) {
          setError(validation.message)
        }
      })
    },
    [validations]
  )

  return { error, validate, resetError: () => setError(undefined) }
}
