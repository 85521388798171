import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import unset from 'lodash/unset'

import CheckboxComponent from '../checkbox/Checkbox'

import { TreeNode } from './Catalog'
import MenuItem from './MenuItem'

const removeEmptyNodes = (
  selectedItemsUpdated: TreeNode,
  parentPath: string[]
) => {
  if (isEmpty(get(selectedItemsUpdated, [...parentPath]))) {
    parentPath.forEach((_, index) => {
      const path = parentPath.slice(0, parentPath.length - index)
      const parent = get(selectedItemsUpdated, path)

      if (isEmpty(parent)) {
        const parentOfParentPath = parentPath.slice(
          0,
          parentPath.length - index
        )
        unset(selectedItemsUpdated, parentOfParentPath)
      }
    })
  }
}

type Props = {
  searchResults: { path: string[]; value: string }[]
  items: TreeNode
  selectedItems: TreeNode
  setSelectedItems: (items: TreeNode) => void
}

function hasAllChildrenSelected(
  item: { path: string[]; value: string },
  items: TreeNode,
  selectedItems: TreeNode
) {
  const parentSelectedItem = get(selectedItems, item.path)
  const parentItem = get(items, item.path)
  return parentSelectedItem === undefined
    ? isEqual(selectedItems[item.value], items[item.value])
    : Array.isArray(parentSelectedItem)
    ? parentSelectedItem.length === parentItem.length
    : isEqual(parentSelectedItem[item.value], parentItem[item.value])
}

function isIndeterminate({
  isChecked,
  item,
  items,
  selectedItems,
}: {
  isChecked: boolean
  item: { path: string[]; value: string }
  items: TreeNode
  selectedItems: TreeNode
}) {
  const parentItem = get(items, item.path)
  const isLeaf = parentItem === undefined ? false : Array.isArray(parentItem)
  return (
    isChecked && !isLeaf && !hasAllChildrenSelected(item, items, selectedItems)
  )
}

const SearchResults = ({
  searchResults,
  items,
  selectedItems,
  setSelectedItems,
}: Props) => {
  return (
    <div className="max-h-150 overflow-y-auto">
      <div className="grid auto-rows-fr">
        {searchResults.length === 0 && (
          <div className="p-6 text-coolGray-800 font-medium flex justify-center">
            No results found
          </div>
        )}
        {searchResults.map((item, index) => {
          const parentSelectedItem = get(selectedItems, item.path)
          const isChecked =
            parentSelectedItem === undefined
              ? selectedItems[item.value] !== undefined
              : Array.isArray(parentSelectedItem)
              ? parentSelectedItem.some((current) => current === item.value)
              : parentSelectedItem[item.value] !== undefined
          return (
            <div key={index} className=" flex h-full">
              <MenuItem className="hover:bg-blueGray-200 hover:font-medium cursor-pointer truncate text-left">
                <div className="flex items-center">
                  <CheckboxComponent
                    isChecked={isChecked}
                    indeterminate={isIndeterminate({
                      isChecked,
                      item,
                      items,
                      selectedItems,
                    })}
                    onChange={() => {
                      const selectedItemsUpdated =
                        cloneDeep(selectedItems) ?? {}
                      const parentPath = item.path
                      const parentClicked = get(items, parentPath)
                      if (Array.isArray(parentClicked)) {
                        if (isChecked) {
                          set(
                            selectedItemsUpdated,
                            parentPath,
                            (parentSelectedItem ?? []).filter(
                              (i) => i !== item.value
                            )
                          )
                        } else {
                          set(selectedItemsUpdated, parentPath, [
                            ...(get(selectedItems, parentPath) ?? []),
                            item.value,
                          ])
                        }
                      } else {
                        if (isChecked) {
                          if (parentSelectedItem === undefined) {
                            delete selectedItemsUpdated[item.value]
                          } else {
                            const clonedChildren = clone(parentSelectedItem)
                            delete clonedChildren[item.value]
                            set(
                              selectedItemsUpdated,
                              parentPath,
                              clonedChildren
                            )
                          }
                        } else {
                          set(
                            selectedItemsUpdated,
                            [...parentPath, item.value],
                            get(items, [...parentPath, item.value])
                          )
                        }
                      }

                      removeEmptyNodes(selectedItemsUpdated, parentPath)

                      setSelectedItems(selectedItemsUpdated)
                    }}
                  />
                  <div>
                    <div className="font-bold text-coolGray-800">
                      {item.value}
                    </div>
                    {item.path.length > 0 && (
                      <div className="text-coolGray-400 capitalize">
                        {item.path.join(' / ')} /
                      </div>
                    )}
                  </div>
                </div>
              </MenuItem>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SearchResults
