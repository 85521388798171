import { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'

import CheckboxComponent from 'common/components/checkbox'
import { Topic } from 'common/components/topics/interfaces'
import { InfoAction } from 'common/components/WidgetHeader'
import { useDeepCompare } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ChevronDown, ChevronUp } from 'common/icons'

import AddTopicButton from './components/addTopicButton'
import DroppableContainer from './components/DroppableContainer'
import NoTopicsOrNoMatch from './components/NoTopicsOrNoMatch'
import SortableTopicCard from './components/SortableTopicCard'
import TopicCard from './components/TopicCard'
import TopicsError from './components/TopicsError'
import {
  allowConstantsClicked,
  loadError,
  toggleTopicIsConstant,
  updateTopics,
} from './store/topicsSlice'

type Props = {
  topics: Topic[]
  fragmentsCount?: number
  isCollapsed?: boolean
  isDisabled?: boolean
  hasError?: boolean
  isCollapsible?: boolean
  onCollapseClick?: () => void
  onChange: (topics: Topic[]) => void
  className?: string
}

const MAX_CONSTANTS_LENGTH = 2
const SUBJECT_LINE_LENGTH_THRESHOLD = 100

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
}

const Topics = ({
  topics: initialTopics,
  fragmentsCount = 0,
  isDisabled,
  isCollapsed,
  hasError = false,
  isCollapsible = false,
  onChange,
  onCollapseClick,
  className,
}: Props) => {
  const dispatch = useAppDispatch()

  const [activeTopic, setActiveTopic] = useState<Topic | undefined>()
  const hasErrorLoadingTopics = useAppSelector(
    (state) => state.topics.hasErrorLoadingTopics
  )
  const isConfigurationMenuOpen = useAppSelector(
    (state) => state.topics.isConfigurationMenuOpen
  )
  const shouldAllowConstants = useAppSelector(
    (state) => state.topics.shouldAllowConstants
  )
  const contentStructure = useAppSelector(
    (state) => state.campaignStates.combinedLanguageSettings.contentStructure
  )
  const subjectLineLengthConfig = contentStructure?.find(
    (item) => item.name === 'Subject Line'
  )?.length
  const maxConstantsLength =
    subjectLineLengthConfig &&
    subjectLineLengthConfig < SUBJECT_LINE_LENGTH_THRESHOLD
      ? 1
      : MAX_CONSTANTS_LENGTH

  const currentTopics = useAppSelector((state) => state.topics.topics)
  const topics = useMemo(
    () => currentTopics.filter((topic) => !topic.isConstant),
    [currentTopics]
  )
  const constants = useMemo(
    () => currentTopics.filter((topic) => topic.isConstant),
    [currentTopics]
  )

  const isTopicsDragDisabled =
    constants.length >= maxConstantsLength ||
    !shouldAllowConstants ||
    isConfigurationMenuOpen ||
    isDisabled

  const isConstantsDragDisabled = isConfigurationMenuOpen || isDisabled

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  })

  const sensors = useSensors(pointerSensor)

  const onDragEnd = ({ collisions, over, active }: DragEndEvent) => {
    if (!over) {
      return
    }

    if (
      collisions?.find((c) => c.id === 'droppable-constants') &&
      active.data.current?.sortable.containerId === 'topics-context'
    ) {
      dispatch(
        toggleTopicIsConstant({
          topicId: active.id as string,
          isConstant: true,
        })
      )
    } else if (
      collisions?.find((c) => c.id === 'droppable-topics') &&
      active.data.current?.sortable.containerId === 'constants-context'
    ) {
      dispatch(
        toggleTopicIsConstant({
          topicId: active.id as string,
          isConstant: false,
        })
      )
    }

    setActiveTopic(undefined)
  }

  const onDragStart = ({ active }: DragStartEvent) => {
    const topic =
      topics.find((t) => t.id === active.id) ??
      constants.find((t) => t.id === active.id)

    setActiveTopic(topic)
  }

  const onDragCancel = () => {
    setActiveTopic(undefined)
  }

  const onAllowConstantsClick = (isChecked: boolean) => {
    dispatch(allowConstantsClicked(isChecked))
  }

  const hasInitialTopicsChanged = useDeepCompare(initialTopics)

  useEffect(() => {
    if (hasInitialTopicsChanged) {
      dispatch(updateTopics(initialTopics))
    } else if (!isEqual(currentTopics, initialTopics)) {
      onChange(currentTopics)
    }
  }, [
    currentTopics,
    dispatch,
    hasInitialTopicsChanged,
    initialTopics,
    onChange,
  ])

  useEffect(() => {
    dispatch(loadError(hasError))
  }, [dispatch, hasError])

  const getState = () => {
    if (hasErrorLoadingTopics) {
      return 'error'
    } else if (topics.length > 0 || constants.length > 0) {
      return 'topics'
    } else if (
      topics.length === 0 &&
      constants.length === 0 &&
      fragmentsCount > 0
    ) {
      return 'noMatch'
    } else {
      return 'noTopics'
    }
  }

  const state = getState()

  return (
    <DndContext
      onDragStart={onDragStart}
      onDragCancel={onDragCancel}
      onDragEnd={onDragEnd}
      sensors={sensors}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
    >
      <div
        className={cx(
          'p-6 pt-8 text-coolGray-800 relative overflow-hidden',
          className
        )}
      >
        {isCollapsed ? (
          <div className="flex">
            <div className="text-base font-medium mr-6">Topics</div>
            <div className="flex flex-wrap -mt-4">
              {topics.map(({ category, name }, index) => (
                <div
                  key={index}
                  className="text-base text-coolGray-500 font-medium mr-6 mt-4"
                >
                  {category}: {name}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="absolute right-6 top-4 flex items-center">
              <AddTopicButton isDisabled={isDisabled} />
              <InfoAction message="Use Jacquard’s topic search to add topics that match the content of your control variant." />
            </div>
            <div className="text-base font-medium ">Topics</div>
            <div className="mt-1 text-coolGray-400 mb-4">
              Topics let Jacquard know about the specifics of your content.
            </div>
            {
              {
                noTopics: <NoTopicsOrNoMatch />,
                noMatch: <NoTopicsOrNoMatch />,
                error: <TopicsError />,
                topics: (
                  <>
                    <SortableContext
                      id="topics-context"
                      items={topics.map((t) => t.id)}
                      strategy={rectSortingStrategy}
                      disabled={isTopicsDragDisabled}
                    >
                      <DroppableContainer
                        id="droppable-topics"
                        className="flex flex-wrap -mt-4 overflow-hidden min-h-10"
                      >
                        {topics.map((topic) => (
                          <SortableTopicCard
                            topic={topic}
                            key={topic.id}
                            isDisabled={isDisabled}
                            isDragDisabled={isTopicsDragDisabled}
                          />
                        ))}
                      </DroppableContainer>
                    </SortableContext>
                    <div className="mt-8">
                      <CheckboxComponent
                        label="Allow constants"
                        isChecked={shouldAllowConstants}
                        onChange={onAllowConstantsClick}
                        isDisabled={isDisabled}
                        className="w-max"
                      />
                      <div
                        className={cx('mt-1 text-coolGray-400 mb-4', {
                          'mb-8': shouldAllowConstants,
                        })}
                      >
                        Constants allow you to always have the elected topics
                        within the generated variants.
                      </div>
                    </div>
                    {shouldAllowConstants && (
                      <SortableContext
                        id="constants-context"
                        items={constants.map((t) => t.id)}
                        strategy={rectSortingStrategy}
                        disabled={isConstantsDragDisabled}
                      >
                        <DroppableContainer
                          id="droppable-constants"
                          className="flex flex-wrap -mt-4 mb-4 min-h-24 px-4 pb-4 border
                            border-dashed border-coolGray-400 relative"
                        >
                          <div
                            className="absolute text-coolGray-400 text-base font-medium
                            top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          >
                            {constants.length === 0
                              ? 'Drag your constants here'
                              : ''}
                          </div>
                          {constants.map((constant) => (
                            <SortableTopicCard
                              topic={constant}
                              key={constant.id}
                              isDisabled={isDisabled}
                            />
                          ))}
                        </DroppableContainer>
                      </SortableContext>
                    )}
                    {createPortal(
                      <DragOverlay dropAnimation={dropAnimationConfig}>
                        {activeTopic ? (
                          <TopicCard
                            style={{ boxShadow: '0 25px 30px 0 #9ca3af' }}
                            topic={activeTopic}
                          />
                        ) : null}
                      </DragOverlay>,
                      document.body
                    )}
                  </>
                ),
              }[state]
            }
          </>
        )}
        {isCollapsible && (
          <div
            className="absolute right-6 bottom-4 cursor-pointer"
            onClick={onCollapseClick}
          >
            {isCollapsed ? <ChevronDown /> : <ChevronUp />}
          </div>
        )}
      </div>
    </DndContext>
  )
}

export default Topics
