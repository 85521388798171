import { useState } from 'react'
import cx from 'classnames'

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & { isResponsive?: boolean; className?: string }

const Image = ({ alt, isResponsive, className, onLoad, ...rest }: Props) => {
  // HH-4751 - In Firefox, the alt text is displayed while the image is fetching.
  // I added this state to prevent this flickering effect.
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const handleOnLoad = (e) => {
    setIsImageLoaded(true)
    onLoad?.(e)
  }

  return (
    <img
      className={cx(
        { 'max-w-full max-h-full h-auto': isResponsive },
        { 'text-transparent': !isImageLoaded },
        className
      )}
      onLoad={handleOnLoad}
      alt={alt}
      {...rest}
    />
  )
}

export default Image
