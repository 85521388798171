import { FC } from 'react'

import Button from './button/Button'
import Modal, { ModalProps } from './Modal'

type Props = {
  onOk?: () => void
  onCancel?: () => void
  okText?: string
  cancelText?: string
  isLoading?: boolean
} & ModalProps

const CampaignConfirmationModal: FC<Props> = ({
  onCancel,
  onOk,
  okText = 'OK',
  cancelText = 'Close',
  isLoading = false,
  children,
  ...others
}) => {
  return (
    <Modal
      onClose={onCancel}
      footer={[
        <Button
          ghost
          key="close"
          className="mr-6"
          onClick={onCancel}
          disabled={isLoading}
        >
          {cancelText}
        </Button>,
        <Button variant="primary" key="save" onClick={onOk} loading={isLoading}>
          {okText}
        </Button>,
      ]}
      {...others}
    >
      {children}
    </Modal>
  )
}

export default CampaignConfirmationModal
