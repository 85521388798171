import GridContainer from 'common/components/grid/GridContainer'

import { PersonalizationInfo } from '../interfaces'

import PersonalizationCard from './PersonalizationCard'

type Props = {
  contents: PersonalizationInfo[]
  onClickItem: (contentId: string) => void
}

const PersonalizationGrid = ({
  contents: personalizations,
  onClickItem,
}: Props) => {
  return (
    <GridContainer>
      {personalizations?.map((personalization) => (
        <div
          key={personalization.id}
          onClick={() => onClickItem(personalization.id)}
          className="cursor-pointer"
          tabIndex={0}
        >
          <PersonalizationCard
            title={personalization.name}
            createdDate={new Date(personalization.createdDate)}
            users={personalization.contributors}
            contentType={personalization.contentType}
          />
        </div>
      ))}
    </GridContainer>
  )
}

export default PersonalizationGrid
