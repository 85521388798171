import { Link } from 'react-router-dom'

import HoldingPage from '../holdingPages/Page'

type Props = {
  onHomeClick?: () => void
}

const GlobalErrorContent = ({ onHomeClick }: Props) => {
  return (
    <HoldingPage>
      <HoldingPage.Title className="mb-8">
        Unexpected page load error.
      </HoldingPage.Title>
      <HoldingPage.Text className="max-w-125">
        Try refreshing the page, going to the{' '}
        <Link
          to="/"
          className="text-maroon-500 hover:underline"
          onClick={onHomeClick}
        >
          homepage
        </Link>{' '}
        or chat with us.
      </HoldingPage.Text>
    </HoldingPage>
  )
}

export default GlobalErrorContent
