import { FC, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import FormItem from 'common/components/FormItem'
import NumericInput from 'common/components/numericInput'
import SingleSelect from 'common/components/singleSelect'
import { formatNumber } from 'common/helpers/numeric'
import { getFieldError } from 'features/projects/helpers'
import {
  SplitSettings,
  SplitType,
  SplitTypeOption,
} from 'features/projects/interface'

import FormCard, { FormCardTitle } from '../formCard'

type Props = {
  isCreating: boolean
  splitSettings: SplitSettings
}

const FixedSplitSettingsCard: FC<Props> = ({ isCreating, splitSettings }) => {
  const formState = useFormState()
  const { change: formChange } = useForm()
  const { splitSettingsType, splitMin, splitMax } = formState.values

  const minSplitsField = splitSettings?.splitSettingsInputs?.splitMin
  const maxSplitsField = splitSettings?.splitSettingsInputs?.splitMax
  const audienceSizeField = splitSettings?.splitSettingsInputs?.splitSize

  const splitTypeOptions = splitSettings.options?.map((option) =>
    option.value ? option : { ...option, value: 'none' }
  ) as SplitTypeOption[]
  const maxSplitsOptions = splitMin
    ? maxSplitsField?.options?.filter(({ value }) => value >= splitMin)
    : maxSplitsField?.options

  useEffect(() => {
    const isSelectedOptionAvailable = splitSettings.options?.some(
      ({ value }) => value === splitSettingsType
    )
    const shouldAutoSelect = !splitSettingsType || !isSelectedOptionAvailable

    if (splitSettings?.show && shouldAutoSelect) {
      formChange(
        'splitSettingsType',
        splitTypeOptions.length === 1 ? splitTypeOptions[0].value : 'none'
      )
    }
  }, [formChange, splitSettings, splitSettingsType, splitTypeOptions])

  useEffect(() => {
    if (splitMin && minSplitsField?.options) {
      const isSelectedOptionAvailable = minSplitsField?.options.some(
        ({ value }) => value === splitMin
      )

      if (!isSelectedOptionAvailable) {
        formChange('splitMin', undefined)
      }
    }
  }, [formChange, splitMin, minSplitsField?.options])

  useEffect(() => {
    if (splitMax && maxSplitsField?.options) {
      const isSelectedOptionAvailable = maxSplitsField?.options.some(
        ({ value }) => value === splitMax
      )

      if (!isSelectedOptionAvailable) {
        formChange('splitMax', undefined)
      }
    }
  }, [formChange, splitMax, maxSplitsField?.options])

  useEffect(() => {
    if (splitMax && splitMin > splitMax) {
      formChange('splitMax', undefined)
    }
  }, [formChange, splitMin, splitMax])

  const getInitialSplitTypeValue = () => {
    if (isCreating) {
      return splitTypeOptions.length === 1 ? splitTypeOptions[0].value : 'none'
    } else {
      return undefined
    }
  }

  return (
    <FormCard data-cy="fixed-split-card">
      <FormCardTitle title="Fixed split settings" />

      <div className="flex gap-6">
        <Field<SplitType>
          name="splitSettingsType"
          initialValue={getInitialSplitTypeValue()}
        >
          {({ input, meta }) => (
            <FormItem
              className="max-w-xs"
              label="Type of split"
              error={getFieldError(meta)}
            >
              <SingleSelect
                data-cy="split-type-select"
                data-testid="split-type-select"
                aria-label="Select type of split"
                options={splitTypeOptions}
                value={input.value}
                onChange={(val) => val && input.onChange(val.value)}
              />
            </FormItem>
          )}
        </Field>

        {minSplitsField && (
          <Field<number> name="splitMin">
            {({ input, meta }) => (
              <FormItem
                className="max-w-35"
                label="Min number of splits"
                error={getFieldError(meta)}
              >
                <SingleSelect
                  data-cy="min-splits-select"
                  aria-label="Select min number of splits"
                  options={minSplitsField.options?.map((option) => ({
                    ...option,
                    value: String(option.value),
                  }))}
                  value={input.value ? input.value.toString() : undefined}
                  onChange={(val) => val && input.onChange(Number(val.value))}
                />
              </FormItem>
            )}
          </Field>
        )}
        {maxSplitsField && (
          <Field<number> name="splitMax">
            {({ input, meta }) => (
              <FormItem
                className="max-w-35"
                label="Max number of splits"
                error={getFieldError(meta)}
              >
                <SingleSelect
                  data-cy="max-splits-select"
                  aria-label="Select max number of splits"
                  options={maxSplitsOptions?.map((option) => ({
                    ...option,
                    value: String(option.value),
                  }))}
                  value={input.value ? input.value.toString() : undefined}
                  onChange={(val) => val && input.onChange(Number(val.value))}
                />
              </FormItem>
            )}
          </Field>
        )}
        {audienceSizeField && (
          <Field<number> name="splitSize">
            {({ input, meta }) => (
              <FormItem
                label="Audience size per split"
                htmlFor="audience-size"
                error={getFieldError(meta)}
              >
                <NumericInput
                  data-cy="audience-size-input"
                  id="audience-size"
                  precision={0}
                  formatValue={(value) => formatNumber(Number(value))}
                  value={input.value}
                  onChange={input.onChange}
                />
              </FormItem>
            )}
          </Field>
        )}
      </div>
    </FormCard>
  )
}

export default FixedSplitSettingsCard
