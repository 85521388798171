import PageContainer from 'common/components/PageContainer'

import GlobalRevenueReporting from './GlobalRevenueReporting'
import ProjectRevenueExceptions from './ProjectRevenueExceptions'

const RevenueReporting = () => {
  return (
    <PageContainer className="max-w-318 sm:mx-auto pt-10 sm:pl-4">
      <GlobalRevenueReporting />
      <ProjectRevenueExceptions />
    </PageContainer>
  )
}

export default RevenueReporting
