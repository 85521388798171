import { TabbedNavRoute } from 'app/navigation/interfaces'

import SubNavBar, {
  SubNavItem,
  SubNavProps,
} from 'common/components/navigation/subNavBar'

type Props = {
  subroutes: Pick<TabbedNavRoute, 'name' | 'to'>[]
} & SubNavProps

const AccountSettingsNavBar = ({ subroutes, ...rest }: Props) => {
  return (
    <SubNavBar {...rest}>
      {subroutes.map(({ name, to }) => (
        <SubNavItem key={name} title={name} to={to} />
      ))}
    </SubNavBar>
  )
}

export default AccountSettingsNavBar
