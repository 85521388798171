import Card from 'common/components/Card'
import { copyAndReplaceNewLineEmoji } from 'common/helpers/copyAndReplaceNewLineEmoji'
import { Copy, SmallTick } from 'common/icons'

type Props = {
  isCopied: boolean
  text: string
  setIsCopied: (value: boolean) => void
}

const CopyCard: React.FC<Props> = ({ isCopied, setIsCopied, text }) => {
  const copyToClipboard = () => {
    copyAndReplaceNewLineEmoji(text)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return (
    <>
      {isCopied ? (
        <div className="text-green-700 bg-green-50 border-green-400 p-4 border min-h-24 items-center flex">
          <SmallTick className="w-10 mr-4" />
          <span className="font-medium text-left leading-normal">
            Copied to clipboard
          </span>
        </div>
      ) : (
        <Card
          className={`hover:bg-maroon-50 hover:border-maroon-300 
    hover:text-maroon-300 min-h-24 justify-center`}
        >
          <button
            className="flex items-center justify-center h-full focus:outline-none"
            onClick={() => {
              copyToClipboard()
            }}
          >
            <Copy className="flex-shrink-0 mr-4" />
            <span className="break-all">{text}</span>
          </button>
        </Card>
      )}
    </>
  )
}

export default CopyCard
