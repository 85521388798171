import { Field } from 'react-final-form'

import FormItem from 'common/components/FormItem'
import SingleSelect from 'common/components/singleSelect/SingleSelect'

type Props = {
  name: string
  label: string
  options: { value: string; label: string }[]
  className?: string
  isDisabled?: boolean
}

const SingleSelectField = ({
  name,
  label,
  options,
  className,
  isDisabled,
}: Props) => (
  <Field<string>
    name={name}
    render={({ input, meta }) => (
      <FormItem
        label={label}
        htmlFor={name}
        className={className}
        error={meta.error}
      >
        <SingleSelect
          id={name}
          data-cy={name}
          onChange={(val) => {
            input.onChange(val?.value)
          }}
          value={input.value}
          isSearchable={true}
          required
          isDisabled={isDisabled}
          options={options}
        />
      </FormItem>
    )}
  />
)

export default SingleSelectField
