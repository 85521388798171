import ErrorPage from 'common/components/error/ErrorPage'
import Spinner from 'common/components/spinner'
import Page from 'features/personalization/components/Page'

import DemoContent from './components/DemoContent'
import DemoHeader from './components/DemoHeader'
import useDemoData from './hooks/useDemoData'

export type FormValues = {
  sector: string
  channel: string
  journey: string
  saleEvent?: string
  product: string
  profile: string
  season: string
  weather: string
}

const DemoPage = () => {
  const { isLoading, isError } = useDemoData()

  return (
    <>
      {isLoading ? (
        <div className="w-full">
          <Spinner data-testid="demo-loader" />
        </div>
      ) : isError ? (
        <ErrorPage />
      ) : (
        <Page className="px-6 md:px-15">
          <div className="h-full w-full sm:mx-auto ">
            <DemoHeader />
            <DemoContent />
          </div>
        </Page>
      )}
    </>
  )
}

export default DemoPage
