import { createContext, useContext, useState } from 'react'

import { CampaignType, State, TweakVariant } from '../types'

type TweakVariantContextType = State & {
  updateState: (newState: Partial<State>) => void
  feedbackEditEnabled: boolean
  feedbackRejectEnabled: boolean
  newlinesInVariants: {
    min: number
    max: number
  }
  campaignType: CampaignType | undefined
}

const getInitialState = (
  props: Pick<TweakVariant, 'tweakingLine' | 'feedbackSettings'>
): State => {
  const { tweakingLine, feedbackSettings } = props

  return {
    nfmReason: 1,
    nfmReasonDetails: '',
    nfmNoReasonDetails: false,
    variantText: tweakingLine.text,
    feedbackSettings: feedbackSettings,
    selectedLine: {
      old_sl_id: '',
      sort_order: 0,
      text: '',
      text_before_tweak: '',
      api_id: '',
      grammar_nfm_used: 0,
      ownsl: '',
      _id: '',
      ...tweakingLine,
    },
    showTweakingReason: false,
    noTweakingReason: false,
    tweakingReason: '',
    whyDislike: {
      1: 'Against our brand voice',
      2: 'Legal or compliance issue',
    },
    selectedState: feedbackSettings?.reject?.enabled ? 'replace' : 'edit',
  }
}

const TweakVariantContext = createContext<TweakVariantContextType>({
  ...getInitialState({
    tweakingLine: { text: '' },
    feedbackSettings: undefined,
  }),
  feedbackEditEnabled: false,
  feedbackRejectEnabled: false,
  newlinesInVariants: { max: 0, min: 0 },
  campaignType: undefined,
  updateState: (newState: Partial<State>) => undefined,
})

type TweakVariantProviderProps = {
  children: React.ReactNode
  campaignType: CampaignType
} & Pick<
  TweakVariant,
  'tweakingLine' | 'feedbackSettings' | 'newlinesInVariants'
>

const TweakVariantProvider = ({
  children,
  newlinesInVariants,
  campaignType,
  ...restProps
}: TweakVariantProviderProps) => {
  const [state, setState] = useState<State>(getInitialState(restProps))

  const updateState = (newState: Partial<State>) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...newState,
      }
    })
  }

  const value = {
    ...state,
    updateState,
    campaignType,
    feedbackEditEnabled: state.feedbackSettings?.edit?.enabled ?? false,
    feedbackRejectEnabled: state.feedbackSettings?.reject?.enabled ?? false,
    newlinesInVariants: newlinesInVariants,
  }

  return (
    <TweakVariantContext.Provider value={value}>
      {children}
    </TweakVariantContext.Provider>
  )
}

const useTweakVariant = () => {
  const context = useContext(TweakVariantContext)
  if (context === undefined) {
    throw new Error(
      'useTweakVariant must be used within a TweakVariantProvider'
    )
  }
  return context
}

export { TweakVariantProvider, useTweakVariant }
