import { FC, MouseEventHandler } from 'react'
import cx from 'classnames'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  'data-cy'?: string
  'data-test-id'?: string
  isSelected: boolean
}

const Button: FC<ButtonProps> = ({
  className,
  'data-cy': dataCy,
  'data-test-id': dataTestId,
  disabled,
  isSelected,
  children,
  ...otherProps
}) => {
  return (
    <button
      data-cy={dataCy}
      data-test-id={dataTestId}
      className={cx(
        'inline-flex items-center focus:outline-none focus-visible:outline-black',
        'py-2 px-6 font-medium border border-coolGray-300 ',
        {
          'cursor-not-allowed': disabled,
          'text-white bg-coolGray-300 hover:text-coolGray-400 hover:bg-coolGray-100':
            disabled,
          'bg-maroon-300 text-white': !disabled && isSelected,
          'bg-coolGray-100 text-coolGray-600': !disabled && !isSelected,
        },
        className
      )}
      style={{ height: '40px' }}
      disabled={disabled}
      type="button"
      {...otherProps}
    >
      {children}
    </button>
  )
}

type Props = {
  selectedValue: 'yes' | 'no'
  onClick: MouseEventHandler<HTMLButtonElement>
  isDisabled: boolean
  values?: [string, string]
}
const YesNoButton = ({
  selectedValue,
  onClick,
  isDisabled,
  values = ['Yes', 'No'],
}: Props) => {
  return (
    <div className="inline-block">
      <Button
        className="text-base border-r-0"
        value="yes"
        isSelected={selectedValue === 'yes'}
        onClick={onClick}
        disabled={isDisabled}
      >
        {values[0]}
      </Button>
      <Button
        className="text-base border-l-0"
        value="no"
        isSelected={selectedValue === 'no'}
        onClick={onClick}
        disabled={isDisabled}
      >
        {values[1]}
      </Button>
    </div>
  )
}
export default YesNoButton
