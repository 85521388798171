import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import Button from 'common/components/button/Button'
import { errorToast, successToast } from 'common/components/toastNotification'
import { Close } from 'common/icons'
import { ReactComponent as LogoBlack } from 'common/icons/logo/logo-black.svg'

import useRequestPasswordChange from './api/mutations/useRequestPasswordChange'

const ExpiredPassword = () => {
  const { logout } = useAuth0()
  const [hasLinkBeenSent, setHasLinkBeenSent] = useState(false)
  const [isNotificationShowing, setIsNotificationShowing] = useState(false)
  const {
    mutate: requestPasswordChange,
    isLoading,
    isSuccess,
  } = useRequestPasswordChange()

  const handleClick = () => {
    requestPasswordChange(undefined, {
      onSuccess: () => {
        setHasLinkBeenSent(true)
        setIsNotificationShowing(true)
        successToast('Please, check your inbox', { autoClose: 3000 })
      },
      onError: (error) => {
        const errorMessage =
          error instanceof Error
            ? error.message
            : 'There was an error sending the password reset link. Please try again.'
        errorToast(errorMessage)
      },
    })
  }

  const referrer = document.referrer
  // If a user enters the /expired-password route directly, they will be redirected to the home page.
  if (!referrer) {
    return <Redirect to="/" />
  }

  return (
    <div
      style={{
        backgroundImage: 'url("/images/Pattern_JCQRD.svg")',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      className="min-h-screen relative flex justify-center items-center bg-maroon-500"
    >
      <div className="relative">
        {isNotificationShowing && isSuccess && (
          <div className="absolute -top-16 w-full flex justify-between items-center px-6 py-1 bg-white border border-coolGray-300 rounded-md">
            <span className="text-base">Please, check your inbox</span>
            <Button onClick={() => setIsNotificationShowing(false)}>
              <Close />
            </Button>
          </div>
        )}
        <div className="max-w-152 p-6 flex flex-col gap-8 bg-white border border-coolGray-300 rounded-md">
          <div className="flex justify-center  ">
            <LogoBlack />
          </div>
          <h2 className="font-bold text-4xl">Your password has expired!</h2>
          <p className="text-base">
            For your security, our passwords must be updated every 90 days.
            Please click the button below to send a reset link to your email.
          </p>
          <Button
            variant="primary"
            className="w-44 uppercase"
            title="sendLink"
            onClick={handleClick}
            disabled={isLoading}
          >
            {isLoading
              ? 'Sending...'
              : hasLinkBeenSent
              ? 'Send a new link'
              : 'Send me the link'}
          </Button>
          <p className="text-base">
            Once you receive the email, follow the instructions to set up your
            new password. If you do not receive the email shortly, check your
            spam folder or try resending the link.
          </p>
          <div className="pt-6 flex justify-end">
            <Button
              ghost
              onClick={() =>
                logout({
                  returnTo: `${window.location.origin}/`,
                })
              }
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExpiredPassword
