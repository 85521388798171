import DistributionChannel from 'common/components/DistributionChannel'
import { useAppSelector } from 'common/hooks/redux'

import styles from './CardFan.module.css'

const CardsFan = () => {
  const selectedCampaigns = useAppSelector(
    (state) => state.campaigns.selectedCampaigns
  )
  const campaigns = useAppSelector((state) => state.campaigns.campaigns)

  const count = selectedCampaigns.length
  const displayedSelectedCampaigns = selectedCampaigns.slice(0, 6)
  const selectedCampaignsData = campaigns.filter((campaign) =>
    displayedSelectedCampaigns.includes(campaign.id)
  )

  return (
    <div className="h-84 mb-16">
      {count ? (
        <div className={styles.cards}>
          {selectedCampaignsData.map(
            ({ id, name, distributionChannel, status }) => {
              return (
                <div key={id} className={styles.card}>
                  <div
                    className={`h-8 pb-3 text-xs text-coolGray-800 
                    opacity-50`}
                  >
                    <DistributionChannel value={distributionChannel} />
                    <span> / </span>
                    <div className="inline-block mr-1 capitalize-first">
                      {status?.replace(/_/g, ' ') ?? 'No status'}
                    </div>
                  </div>

                  <div
                    className="mr-6 text-2xl lg:text-2xl text-coolGray-800 
                    line-clamp-5 font-medium break-words"
                  >
                    {name}
                  </div>
                </div>
              )
            }
          )}
        </div>
      ) : undefined}
    </div>
  )
}

export default CardsFan
