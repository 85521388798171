import { ChangeEvent } from 'react'
import helpers from 'workflow/utils/helpers'

import FormItem from 'common/components/FormItem'

import CustomInput from './Input'

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
}

const Email = ({ onChange, value }: Props) => {
  const hasError = value?.length > 0 && !helpers.isValidEmail(value)
  return (
    <div className="flex justify-between">
      <FormItem
        error={
          hasError
            ? 'Email address is not valid. eg. robin@jacquard.com'
            : undefined
        }
      >
        <CustomInput
          onChange={onChange}
          name="email"
          value={value}
          placeholder="eg. greg@jacquard.com"
          hasError={hasError}
          isValid={value?.length > 0 && helpers.isValidEmail(value)}
        />
      </FormItem>
    </div>
  )
}

export default Email
