export const brandVoiceKeys = {
  all: ['brandVoice'] as const,
  list: (accountId: string) =>
    [...brandVoiceKeys.all, 'list', accountId] as const,
  details: (accountId: string, brandVoiceId: string) =>
    [...brandVoiceKeys.all, 'details', accountId, brandVoiceId] as const,
  attachedProjects: (accountId: string, brandVoiceId: string) =>
    [
      ...brandVoiceKeys.all,
      'attachedProjects',
      accountId,
      brandVoiceId,
    ] as const,
  delete: (accountId: string, brandVoiceId: string) =>
    [...brandVoiceKeys.all, 'delete', accountId, brandVoiceId] as const,
}

export const brandToneKeys = {
  all: ['brandTone'] as const,
  list: () => [...brandToneKeys.all, 'list'] as const,
}
