import React from 'react'
import { generatePath, useLocation, useParams } from 'react-router-dom'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import { brandVoiceSubroutes } from 'app/navigation/staticSubRoutes.brandVoice'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ButtonWithDropdown from 'common/components/ButtonWithDropdown'
import SubNavBar, {
  SubNavItem,
  SubNavProps,
} from 'common/components/navigation/subNavBar'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { MoreOptions } from 'common/icons'
import {
  toggleDeleteConfirmationModalVisibility,
  toggleDeleteDefaultBrandVoiceAlertVisibility,
  toggleDeletionWarningWithRelatedProjectsModalVisibility,
} from 'features/admin/brandVoice/store/brandVoiceSlice'

import useGetAllAttachedProjectsForBrandVoice from '../api/queries/useGetAllAttachedProjectsForBrandVoice'
import useGetBrandVoiceDetailsQuery from '../api/queries/useGetBrandVoiceDetailsQuery'
import { createBrandVoiceBackLink } from '../helpers'

type Props = SubNavProps & {
  accountId: string
}

const SubNavigation = ({ accountId, ...rest }: Props) => {
  const dispatch = useAppDispatch()
  const { brandVoiceId } = useParams<{ brandVoiceId: string }>()
  const { data: linkedProjects, isFetching } =
    useGetAllAttachedProjectsForBrandVoice(brandVoiceId)

  const flags = useFlags()
  const { pathname } = useLocation()
  const {
    editedContent: { brandVoices },
  } = useAppSelector((state) => state.brandVoice)

  const { data: brandVoice } = useGetBrandVoiceDetailsQuery(
    accountId,
    brandVoiceId
  )

  if (!flags.showBrandTonePage) {
    return null
  }

  const currentBrandVoice = brandVoices.find((brandVoice) => {
    return brandVoice.id === brandVoiceId
  })

  const handleDeleteBrandVoice = () => {
    if (currentBrandVoice && currentBrandVoice.isDefault) {
      dispatch(toggleDeleteDefaultBrandVoiceAlertVisibility(true))
    } else if (linkedProjects.length > 0) {
      dispatch(toggleDeletionWarningWithRelatedProjectsModalVisibility(true))
    } else {
      dispatch(toggleDeleteConfirmationModalVisibility(true))
    }
  }

  const toneCount = brandVoice?.tones.length ?? 0

  const backLink = createBrandVoiceBackLink(
    pathname,
    {
      brandVoiceId,
      accountId,
    },
    { toneCount }
  )

  const routes =
    brandVoiceId !== undefined
      ? brandVoiceSubroutes
          .filter(({ accessFlags }) =>
            isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
          )
          .map((route) => {
            return {
              ...route,
              to: generatePath(route.to, { accountId, brandVoiceId }),
            }
          })
      : []

  return (
    <SubNavBar {...rest} title={currentBrandVoice?.name} backLink={backLink}>
      {routes.map(({ name, to }) => (
        <SubNavItem
          key={name}
          title={name}
          to={generatePath(to, { accountId, brandVoiceId })}
        />
      ))}
      <ButtonWithDropdown
        data-testid="subNavigationActionsDropdown"
        loading={isFetching}
        prefixIcon={
          <MoreOptions isDefaultColor={false} className="text-white" />
        }
        suffixIcon={false}
        options={[
          {
            key: 'delete',
            label: 'Delete Brand Voice',
            onClick: handleDeleteBrandVoice,
          },
        ]}
      />
    </SubNavBar>
  )
}

export default SubNavigation
