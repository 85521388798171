import Checkbox from 'common/components/checkbox/Checkbox'
import RadioGroup from 'common/components/radioGroup'
import Slider from 'common/components/slider/Slider'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  addCapitalisedWord,
  addStylistic,
  clickCapitalizeLeaders,
  clickCapitalizeVariants,
  clickWrapLeaders,
  clickWrapVariants,
  removeCapitalisedWord,
  removeStylistic,
  updateCapitalisationProbability,
  updateTitleCase,
  updateWrappingProbability,
} from '../store/contentSettingsSlice'

import ContentTags from './ContentTags'

// hardcoded list of possible stylistics, may be pulled from a Linguo endpoint at a later date
const stylisticsOptions = [
  '*',
  '**',
  '***',
  '//',
  '\\',
  '///',
  '// \\',
  '\\ //',
  '[ ]',
  '[[ ]]',
  '~',
  '~~',
  '<',
  '>',
  '< >',
  '> <',
  '<<',
  '>>',
  '<< >>',
  '>> <<',
]

const StylisticsWidget = () => {
  const dispatch = useAppDispatch()

  const editedStylistics = useAppSelector(
    (state) => state.contentSettings.editedContent.stylistics
  )

  const stylistics = editedStylistics.stylisticsOptions.map((word) => ({
    label: word.replaceAll('\\', '\\\\'),
    value: word,
  }))

  const capitalizations = editedStylistics.capitalizeWords.map((word) => ({
    label: word,
    value: word,
  }))

  return (
    <Widget data-cy="stylistics-widget">
      <WidgetHeader
        title="Stylistics"
        subtitle="Stylistics widget placeholder text here"
      />
      <ContentTags
        className="w-1/2"
        isFreeText={false}
        data-cy="stylistics-tags"
        onAddClick={(value: string) => {
          dispatch(addStylistic(value))
        }}
        onRemoveClick={(value: string) => {
          dispatch(removeStylistic(value))
        }}
        tags={stylistics}
        placeholder="Stylistics placeholder text"
        maxLength={50}
        suggestions={stylisticsOptions.map((option) =>
          option.replaceAll('\\', '\\\\')
        )}
      />
      <div className="mt-8 mb-3.5 text-coolGray-800 font-medium">
        Wrap settings
      </div>
      <div className="flex flex-row mb-10">
        <Checkbox
          className="mr-16"
          label="Leaders"
          labelClassName="font-normal"
          isChecked={editedStylistics.wrapLeaders}
          onChange={(value: boolean) => dispatch(clickWrapLeaders(value))}
        />
        <Checkbox
          label="Full variant"
          labelClassName="font-normal"
          isChecked={editedStylistics.wrapVariants}
          onChange={(value: boolean) => dispatch(clickWrapVariants(value))}
        />
      </div>
      <div className="mt-0.5 mb-3 text-coolGray-800 font-medium">
        Wrapping probability (%)
      </div>
      <div className="w-72">
        <Slider
          min={0}
          max={100}
          onChange={(value) => {
            dispatch(updateWrappingProbability(value as number))
          }}
          value={editedStylistics.wrappingProbability}
        />
      </div>
      <div className="relative flex py-5 items-center">
        <div className="flex-grow border-t border-coolGray-300 border-dashed"></div>
      </div>
      <div className="mt-1.5 mb-2 text-coolGray-800 font-medium">
        Words for capitalisation
      </div>
      <ContentTags
        className="w-1/2"
        isFreeText
        data-cy="stylistics-capitalisation-tags"
        onAddClick={(value: string) => {
          dispatch(addCapitalisedWord(value))
        }}
        onRemoveClick={(value: string) => {
          dispatch(removeCapitalisedWord(value))
        }}
        tags={capitalizations}
        onInputChange={(value) => value.replaceAll(' ', '')}
        placeholder="Stylistics placeholder text"
        maxLength={50}
      />
      <div className="mt-8 mb-3.5 text-coolGray-800 font-medium">
        Section capitalisation settings
      </div>
      <div className="flex flex-row mb-10">
        <Checkbox
          className="mr-16"
          label="Leaders"
          labelClassName="font-normal"
          isChecked={editedStylistics.capitalizeLeaders}
          onChange={(value: boolean) => dispatch(clickCapitalizeLeaders(value))}
        />
        <Checkbox
          label="Full variant"
          labelClassName="font-normal"
          isChecked={editedStylistics.capitalizeVariants}
          onChange={(value: boolean) =>
            dispatch(clickCapitalizeVariants(value))
          }
        />
      </div>
      <div className="mb-3 text-coolGray-800 font-medium">
        Capitalisation probability (%)
      </div>
      <div className="w-72">
        <Slider
          min={0}
          max={100}
          onChange={(value) =>
            dispatch(updateCapitalisationProbability(value as number))
          }
          value={editedStylistics.capitalizationProbability}
        />
      </div>

      <div className="mt-8 mb-3 text-coolGray-800 font-medium">
        Do you want to use title case or sentence case?
      </div>
      <RadioGroup
        data-cy="case-radio"
        name="case"
        value={editedStylistics.titleCase ? 'title' : 'sentence'}
        direction="vertical"
        options={[
          {
            value: 'sentence',
            label: (
              <span>
                Sentence case{' '}
                <span className="text-coolGray-500">
                  (eg. This is sentence case)
                </span>
              </span>
            ),
          },
          {
            value: 'title',
            label: (
              <span>
                Title case{' '}
                <span className="text-coolGray-500">
                  (eg. This Is Title Case)
                </span>
              </span>
            ),
          },
        ]}
        onChange={(value) => dispatch(updateTitleCase(value === 'title'))}
      />
    </Widget>
  )
}

export default StylisticsWidget
