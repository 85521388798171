import { AxiosResponse } from 'axios'

import { restApi } from 'common/api'
import { AvatarColor } from 'features/profile/AvatarColorPicker'

export interface Member {
  id: string
  email: string
  firstName: string
  lastName: string
  roles: string[]
  blocked?: boolean
}

export interface Team {
  id: string
  name: string
  members: Member[] | string[] | ApiMember[]
}

export interface TeamWithColour extends Team {
  colour: AvatarColor
}

export interface ApiMember {
  _id: string
  email: string
  fname: string
  lname: string
  roles: string[]
  blocked?: boolean
}

export const getTeams = async (
  accountId: string
): Promise<AxiosResponse<Team[]>> => {
  return restApi.get<Team[]>(`accounts/${accountId}/teamslists`)
}

export const createTeam = async ({
  accountId,
  name,
  members,
  showNewTeamPage,
}: {
  accountId: string
  name: string
  members: Member[] | string[] | ApiMember[]
  showNewTeamPage: boolean
}): Promise<AxiosResponse<Team>> => {
  const memberIds = members.map((member) => ({ _id: member.id })) ?? []
  const payload = {
    name,
    members: showNewTeamPage ? memberIds : members,
  }
  return restApi.post<Team>(`accounts/${accountId}/teamslists`, payload)
}

export const updateTeam = async ({
  accountId,
  teamId,
  name,
  members,
  showNewTeamPage,
}: {
  accountId: string
  teamId: string
  name?: string
  members?: Member[] | string[] | ApiMember[]
  showNewTeamPage: boolean
}): Promise<AxiosResponse<Team>> => {
  const memberIds = (members ?? []).map((member) => ({ _id: member.id })) ?? []
  const payload = {
    name,
    members: showNewTeamPage ? memberIds : members,
  }
  return restApi.patch<Team>(
    `accounts/${accountId}/teamslists/${teamId}`,
    payload
  )
}

export const deleteTeam = async ({
  accountId,
  teamId,
}: {
  accountId: string
  teamId: string
}): Promise<AxiosResponse<Team[]>> => {
  return restApi.delete<Team[]>(`accounts/${accountId}/teamslists/${teamId}`)
}

export const deleteTeamMember = async ({
  accountId,
  teamId,
  id,
}: {
  accountId: string
  teamId: string
  id: string
}) => {
  return restApi.delete(
    `accounts/${accountId}/teamslists/${teamId}/members/${id}`
  )
}

export const sendApprovalEmail = async ({
  accountId,
  campaignId,
  teamslists,
}: {
  accountId: string
  campaignId: string
  teamslists: string[]
}) => {
  return restApi.post(`accounts/${accountId}/teamslists/send`, {
    campaignId,
    teamslists,
  })
}
