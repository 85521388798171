import { FC } from 'react'

import Image from 'common/components/image'

type Props = {
  name: string
}

const Error: FC<Props> = ({ name }) => {
  return (
    <div className="pt-6">
      <Image src="/images/diver.svg" alt="diver" />
      <div className="px-9 mt-16 text-coolGray-800">
        <div className="text-4xl font-bold">Well this is embarassing.</div>
        <div className="text-base font-medium my-6 uppercase">
          {name} list load error
        </div>
        <div className="font-light">
          Try refreshing the page if that doesn’t work chat with us, we can fix
          just about anything.
        </div>
      </div>
    </div>
  )
}

export default Error
