import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CampaignConfiguration } from '@phrasee/phrasee-typings'
import { Upload } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import { DynamicOptimizationResultType } from 'workflow/interface'

import Button from 'common/components/button/Button'
import { MissingData as MissingDataIcon } from 'common/icons'

import helpers from '../../utils/helpers'
import {
  downloadCSVTemplate,
  loadResultsDetails,
  uploadCSVTemplate,
} from '../../Workflow.actions'

type LoadResultsFunction = (
  campaignId: string,
  primaryGoal?: string,
  resultType?: DynamicOptimizationResultType
) => any

const WILLIE_END_POINT = process.env.REACT_APP_WILLIE_END_POINT
const { Dragger } = Upload
interface Props {
  campaignId: string
  isUploading?: boolean
  isDownloading?: boolean
  csv?: string
  campaignName: string
  error?: string
  onCloseDrawer: Function
  downloadCSVTemplate: Function
  uploadCSVTemplate: Function
  loadResults: LoadResultsFunction
  distributionChannel: string
  testedContentSection?: string
  campaignConfiguration?: CampaignConfiguration
  dynamicOptimizationResultType?: DynamicOptimizationResultType
}
export enum FileActions {
  upload = 'upload',
  download = 'download',
}
let windowRefCloseTimer: any = undefined

export const InputResultsMethods = () => {
  const shouldUpdateFileStatus = (props: Props, fileList: any) => {
    return (
      props.isUploading === false &&
      fileList.length > 0 &&
      fileList[0]?.status === 'uploading' &&
      !props.error
    )
  }
  const moveFile = (
    action: string,
    props: Props,
    updateAction: Function,
    csvFile: File | null
    // eslint-disable-next-line max-params
  ) => {
    const { uploadCSVTemplate, downloadCSVTemplate, campaignId } = props
    updateAction({
      isDownloadAction: action === FileActions.download,
      isUploadAction: action === FileActions.upload,
    })
    return action === FileActions.upload
      ? uploadCSVTemplate(campaignId, csvFile)
      : downloadCSVTemplate(campaignId)
  }

  const openLegacyUploadTab = (
    campaignId: string,
    loadResults: LoadResultsFunction,
    onCloseDrawer: Function
  ) => {
    clearInterval(windowRefCloseTimer)
    const windowRef = window.open(
      `${WILLIE_END_POINT}/users/input-results/${campaignId}`,
      'InputResultsLegacy',
      'status,width=800'
    )
    // if windowRef is null, the popup window was blocked
    if (windowRef !== null) {
      // handle closing of popup tab
      windowRefCloseTimer = setInterval(() => {
        if (windowRef.closed) {
          onCloseDrawer()
          loadResults(campaignId)
          clearInterval(windowRefCloseTimer)
        }
      }, 1000)
    }
  }
  return {
    shouldUpdateFileStatus,
    moveFile,
    openLegacyUploadTab,
  }
}

const InputResults: React.FunctionComponent<Props> = (props: Props) => {
  // State
  const {
    csv,
    onCloseDrawer,
    campaignName,
    isUploading,
    error,
    isDownloading,
    campaignId,
    loadResults,
    distributionChannel,
    testedContentSection,
    campaignConfiguration,
    dynamicOptimizationResultType,
  } = props

  const [fileList, updateFileList] = useState([] as any)
  const [currentAction, updateAction] = useState({
    isDownloadAction: null,
    isUploadAction: null,
  })
  const methods = InputResultsMethods()
  const isBanditCampaign = helpers.isBanditCampaign(campaignConfiguration)
  // we show legacy results button for only subject line and push campaigns
  const showLegacyResultsButton =
    ((distributionChannel === 'email' &&
      testedContentSection === 'subject_line') ||
      (distributionChannel === 'push_notification' &&
        testedContentSection === 'push_notification')) &&
    !isBanditCampaign
  useEffect(() => {
    if (csv && currentAction.isDownloadAction && !isDownloading) {
      updateAction({
        isDownloadAction: null,
        isUploadAction: null,
      })
      helpers.downloadCsvDeprecated(campaignName, csv)
    }
    if (error && !!fileList.length && fileList[0]?.status !== 'error') {
      updateFileList([
        {
          ...fileList[0],
          status: 'error',
        },
      ])
    }
    if (methods.shouldUpdateFileStatus(props, fileList)) {
      const newFile = {
        ...fileList[0],
        status: 'done',
      }
      updateFileList([newFile])
      loadResults(campaignId, undefined, dynamicOptimizationResultType)
      onCloseDrawer()
    }
  }, [
    csv,
    currentAction.isDownloadAction,
    isDownloading,
    error,
    fileList,
    methods,
    props,
    campaignName,
    onCloseDrawer,
    loadResults,
    campaignId,
    dynamicOptimizationResultType,
  ])

  return (
    <Drawer.Content>
      <p className="text-coolGray-400">
        Merge the results exported from your delivery platform into the exported
        template and upload.
      </p>
      <p className="mt-6 mb-1 font-bold">1. Download blank results template</p>
      <Button
        data-cy="download-btn"
        data-test-id="download-btn"
        variant="primary"
        loading={isDownloading}
        onClick={() =>
          methods.moveFile(FileActions.download, props, updateAction, null)
        }
      >
        Download template
      </Button>
      <p className="mt-6 mb-1 font-bold">2. Upload results</p>
      <Dragger
        data-cy="file-upload"
        name="file"
        data-test-id="file-upload"
        disabled={isUploading}
        multiple={false}
        accept=".csv"
        customRequest={(data) =>
          methods.moveFile(FileActions.upload, props, updateAction, data.file)
        }
        fileList={fileList}
        onChange={(data) => updateFileList([data.file])}
        showUploadList={{
          showDownloadIcon: false,
          showRemoveIcon: false,
        }}
      >
        <p className="mb-5">
          <MissingDataIcon size={8} className="text-maroon-300" />
        </p>
        <p className="ant-upload-text">
          Click or drag CSV file to this area to upload
        </p>
      </Dragger>
      {showLegacyResultsButton && (
        <Button
          data-cy="input-results-legacy-button"
          variant="primary"
          onClick={(e) =>
            methods.openLegacyUploadTab(campaignId, loadResults, onCloseDrawer)
          }
          className="mt-6"
          data-test-id="legacy-results-upload-button"
        >
          Looking for the old results upload? Click here.
        </Button>
      )}
    </Drawer.Content>
  )
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadCSVTemplate: (id: string, csv: File) =>
      dispatch(uploadCSVTemplate(id, csv)),
    downloadCSVTemplate: (id: string) => dispatch(downloadCSVTemplate(id)),
    loadResults: (
      campaignId: string,
      primaryGoal?: string,
      resultType?: DynamicOptimizationResultType
    ) => dispatch(loadResultsDetails(campaignId, primaryGoal, resultType)),
  }
}
const mapStateToProps = (state: any) => {
  return {
    campaignName: state.campaignStates.campaignName,
    csv: state.campaignStates.csv,
    campaignId: state.campaignStates.currentCampaignId,
    isUploading:
      state.campaignStates.isWaitingState.isWaiting &&
      state.campaignStates.isWaitingState.isWaitingFor === 'uploadFile',
    isDownloading:
      state.campaignStates.isWaitingState.isWaiting &&
      state.campaignStates.isWaitingState.isWaitingFor === 'downloadCSV',
    error: state.campaignStates.error,
    distributionChannel:
      state.campaignStates.campaignData?.campaign_configuration
        ?.distribution_channel,
    testedContentSection:
      state.campaignStates.campaignData?.campaign_configuration?.testing_method
        ?.tested_content_sections?.[0]?.section,
    campaignConfiguration:
      state.campaignStates.campaignData?.campaign_configuration,
    dynamicOptimizationResultType:
      state.campaignStates.dynamicOptimizationResultType,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InputResults)
