import { IsoCurrency } from 'common/helpers/currency'
import { formatNumber } from 'common/helpers/numeric'

import { CellProps, TableInstance } from '../'

import { CurrencyCellvalue } from '.'

export const getAveragePageValue = <T extends object>({
  page,
  column,
}: CellProps<T>): string => {
  let averageValue = '0'
  let totalRows = 0
  if (page && page.length > 0) {
    const sum = page.reduce((acc, current) => {
      const id = column.id as string
      if (
        typeof current.original[id] === 'number' &&
        current.original[id] !== null &&
        current.original[id] !== undefined
      ) {
        totalRows++
        return acc + current.original[id]
      }

      return acc
    }, 0)

    if (totalRows) {
      averageValue = formatNumber(sum / totalRows, {
        maximumFractionDigits: 2,
      })
    }
  }
  return averageValue
}

export const getTotalPageValue = <T extends object>({
  page,
  column,
}: TableInstance<T>): number => {
  let sum: number = 0
  if (page && page.length > 0) {
    sum = page.reduce((acc, current) => {
      const id = column.id as string
      return typeof current.original[id] === 'number'
        ? acc + current.original[id]
        : acc
    }, 0)
  }
  return sum
}

export const getTotalCurrencyPerPage = <T extends object>({
  page,
  column,
}: TableInstance<T>): CurrencyCellvalue => {
  let sum: number = 0
  let isoCurrency: IsoCurrency = IsoCurrency.USD

  if (page && page.length > 0) {
    sum = page.reduce((acc, current) => {
      const id = column.id as string
      isoCurrency = current.original[id]['isoCurrency']
      return acc + current.original[id]['currencyValue']
    }, 0)
  }

  return { currencyValue: sum, isoCurrency }
}

export const getAverageCurrencyPerPage = <T extends object>({
  page,
  column,
}: TableInstance<T>): CurrencyCellvalue => {
  let averageValue: number | undefined
  let totalRows = 0
  let isoCurrency: IsoCurrency = IsoCurrency.USD

  if (page && page.length > 0) {
    const sum = page.reduce((acc, current) => {
      const id = column.id as string
      const currencyValue = current.original[id]['currencyValue']
      isoCurrency = current.original[id]['isoCurrency']
      if (
        typeof currencyValue === 'number' &&
        currencyValue !== null &&
        currencyValue !== undefined
      ) {
        totalRows++
        return acc + currencyValue
      }

      return acc
    }, 0)

    if (totalRows) {
      averageValue = sum / totalRows
    }
  }

  return { currencyValue: averageValue || 0, isoCurrency }
}
