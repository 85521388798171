interface Props {
  title: string
  sentimentFeatures: string[]
}

const SentimentAnalysisTooltip = ({ title, sentimentFeatures }: Props) => (
  <div className="text-xs">
    <div className="font-medium text-white">{title}</div>
    <div className="text-coolGray-200 mt-2">
      Common features of this sentiment:
    </div>
    <ul className="text-coolGray-50 list-disc ml-4 mt-1 font-medium ">
      {sentimentFeatures.map((feature) => (
        <li key={feature}>{feature}</li>
      ))}
    </ul>
  </div>
)

export default SentimentAnalysisTooltip
