import { MouseEventHandler, ReactNode } from 'react'
import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'

import Button from './Button'

interface ButtonWithTooltipProps {
  tooltip: boolean
  title: string
  onClick: MouseEventHandler<HTMLButtonElement>
  icon: ReactNode
  disabled?: boolean
  'data-cy'?: string
  'data-test-id'?: string
  className?: string
}

/**
 * @deprecated TODO: use the other ButtonWithTooltip component
 * Not sure why we have 2
 */
const ButtonWithTooltip = ({
  tooltip,
  title,
  onClick,
  icon,
  disabled,
  'data-cy': dataCy,
  'data-test-id': dataTestId,
  className,
}: ButtonWithTooltipProps) => {
  return (
    <>
      {tooltip ? (
        <Button
          data-cy={dataCy}
          data-test-id={dataTestId}
          onClick={onClick}
          disabled={disabled}
          className={cx('pl-0', className)}
        >
          <span>{icon}</span>
          <span className="pl-3 text-coolGray-900">{title}</span>
        </Button>
      ) : (
        <Tooltip overlay={title}>
          <Button
            aria-label={title}
            data-cy={dataCy}
            data-test-id={dataTestId}
            onClick={onClick}
            disabled={disabled}
            className={className}
          >
            <span>{icon}</span>
          </Button>
        </Tooltip>
      )}
    </>
  )
}

export default ButtonWithTooltip
