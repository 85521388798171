import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button'
import { useAppDispatch } from 'common/hooks/redux'
import { Add } from 'common/icons'
import { showTemplates } from 'features/unifiedFlow/store/unifiedFlowSlice'

type Props = {
  isDisabled?: boolean
}

const AddTemplateButton = ({ isDisabled }: Props) => {
  const dispatch = useAppDispatch()
  const flags = useFlags()

  if (!flags?.showAddTemplateUf) {
    return null
  }

  return (
    <div className="self-center">
      <Button
        data-cy="add-more-template-button"
        size="small"
        ghost
        disabled={isDisabled}
        prefixIcon={
          <Add isDefaultColor={false} className="text-black w-4 h-4" />
        }
        onClick={() => dispatch(showTemplates())}
      >
        Template
      </Button>
    </div>
  )
}

export default AddTemplateButton
