import { useStackOneHub } from '@stackone/react-hub'

import { useAppSelector } from 'common/hooks/redux'
import { getSelectedAccountName } from 'features/auth/store/authSlice'

import { getReactHubToken, IntegrationProvider } from '../../api'

import IntegrationCard from './IntegrationCard'

type Props = {
  integrations: IntegrationProvider[]
  onSuccess: () => void
}

const IntegrationsList = ({ integrations, onSuccess }: Props) => {
  const { startConnect } = useStackOneHub()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const accountName = useAppSelector(getSelectedAccountName)
  const firstName = useAppSelector((state) => state.authStates.firstName)
  const lastName = useAppSelector((state) => state.authStates.lastName)

  const onClick = async (id: string) => {
    if (accountName) {
      const sessionToken = await getReactHubToken({
        provider: id,
        accountId,
        accountName,
        userName: `${firstName} ${lastName}`,
      })
      startConnect({
        sessionToken,
        onSuccess,
      })
    }
  }

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
      {integrations.map(({ id, name, logoUrl }) => (
        <IntegrationCard
          key={id}
          name={name}
          logoUrl={logoUrl}
          onClick={() => onClick(id)}
        />
      ))}
    </div>
  )
}

export default IntegrationsList
