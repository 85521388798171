import { useFlags } from 'launchdarkly-react-client-sdk'

import MultiGraphWrapper from 'common/components/MultiGraphWrapper'
import { useAppSelector } from 'common/hooks/redux'

import ReportItem from '../performance/ReportItem'

import AverageDiversityWidget from './averageDiversity/AverageDiversityWidget'
import AverageSentimentsWidget from './averageSentiment/AverageSentimentsWidget'
import DiversityOvertimeWidget from './diversityOvertime/DiversityOvertimeWidget'
import EmojiPerformanceWidget from './emojiPerformance/EmojiPerformanceWidget'
import OpenRateByLengthWidget from './openRateByLength/OpenRateByLengthWidget'
import SentimentsOvertimeWidget from './sentimentsOvertime/SentimentsOvertimeWidget'
import SummaryWidgetContainer from './SummaryWidget/SummaryWidgetContainer'
import WordPerformanceWidget from './wordPerformance/WordPerformanceWidget'
import WinningLines from './winningLines'

type Props = {
  project: string
  startDate: string
  endDate: string
}

const BasicReport = ({ project, startDate, endDate }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const flags = useFlags()
  return (
    <div className="flex flex-col gap-10">
      <SummaryWidgetContainer
        project={project}
        startDate={startDate}
        endDate={endDate}
        mode="basicReport"
      />

      <ReportItem withShadowWrapper>
        <MultiGraphWrapper>
          <WordPerformanceWidget
            accountId={accountId}
            projectIds={[project]}
            startDate={startDate}
            endDate={endDate}
          />
          <EmojiPerformanceWidget
            accountId={accountId}
            projectIds={[project]}
            startDate={startDate}
            endDate={endDate}
          />
        </MultiGraphWrapper>
      </ReportItem>

      <WinningLines
        accountId={accountId}
        projectIds={[project]}
        startDate={startDate}
        endDate={endDate}
        mode="basicReport"
      />
      <ReportItem withShadowWrapper>
        <MultiGraphWrapper>
          <AverageSentimentsWidget
            accountId={accountId}
            projectIds={[project]}
            startDate={startDate}
            endDate={endDate}
          />
          <SentimentsOvertimeWidget
            accountId={accountId}
            projectIds={[project]}
            startDate={startDate}
            endDate={endDate}
          />
        </MultiGraphWrapper>
      </ReportItem>
      {flags.showInsightsDiversity && (
        <ReportItem withShadowWrapper>
          <MultiGraphWrapper>
            <AverageDiversityWidget
              accountId={accountId}
              projectIds={[project]}
              startDate={startDate}
              endDate={endDate}
            />
            <DiversityOvertimeWidget
              accountId={accountId}
              projectIds={[project]}
              startDate={startDate}
              endDate={endDate}
            />
          </MultiGraphWrapper>
        </ReportItem>
      )}
      <OpenRateByLengthWidget
        type="default"
        accountId={accountId}
        projectIds={[project]}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  )
}

export default BasicReport
