export const formatNumber = (
  value: number,
  options?: Intl.NumberFormatOptions | undefined
): string => {
  return value.toLocaleString('en', options)
}

export const stringToNumber = (value?: string) => {
  return value && !isNaN(Number(value)) ? Number(value) : undefined
}

export const handlePercentValue = (
  type: 'percent' | 'float',
  value: number | undefined
) => {
  if (value === 0) {
    return 0
  } else if (!value) {
    return undefined
  }
  if (type === 'percent') {
    return Math.ceil(value * 100)
  }
  return value / 100
}
