import HoldingPage from 'common/components/holdingPages/Page'

const Welcome = () => {
  return (
    <HoldingPage className="max-w-212">
      <HoldingPage.Title>Welcome to reporting.</HoldingPage.Title>
      <HoldingPage.SubTitle>
        Apply a few filters, click the “Run report” button… we will give our
        database a little tickle and squeeze and produce a neat report.
      </HoldingPage.SubTitle>
    </HoldingPage>
  )
}

export default Welcome
