import { useEffect, useReducer, useRef } from 'react'
import RouterPromptStay from 'app/router/RouterPromptStay'

import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Spinner from 'common/components/spinner'
import { errorToast, infoToast } from 'common/components/toastNotification'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import useGetCustomerAttributesQuery from './api/queries/useGetCustomerAttributesQuery'
import useGetProductCatalogQuery from './api/queries/useGetProductCatalogQuery'
import CsvCustomerPreview from './components/CsvCustomerPreview'
import {
  csvCustomerInitialState,
  csvCustomerReducer,
  CsvCustomerState,
} from './components/csvCustomerReducer'
import CsvProductPreview from './components/CsvProductPreview'
import {
  csvProductInitialState,
  csvProductReducer,
  CsvProductState,
} from './components/csvProductReducer'
import CustomerAttributesTable from './components/CustomerAttributesTable'
import ProductCatalogTable from './components/ProductCatalogTable'
import SourceUploader from './components/SourceUploader'
import UploadButtonWithDropdown from './components/UploadButtonWithDropdown'
import UploadConfirmationModal from './components/UploadConfirmationModal'
import {
  customerUploadStart,
  fileNameConfirmed,
  fileProcessCancel,
  productUploadStart,
} from './store/dataSourceSlice'
import { Uploader } from './upload'

const DataSources = () => {
  const dispatch = useAppDispatch()
  useDocumentTitle('Data sources| Jacquard')
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const dataIngestionBaseUrl = useAppSelector(
    (state) => state.authStates.accounts
  )?.find((account) => account.id === accountId)?.data_ingestion_base_url

  if (!dataIngestionBaseUrl) {
    throw new Error('The data ingestion base URL is not defined')
  }

  const customerAttributesQuery = useGetCustomerAttributesQuery({
    baseUrl: dataIngestionBaseUrl,
    accountId,
  })
  const productCatalogQuery = useGetProductCatalogQuery({
    baseUrl: dataIngestionBaseUrl,
  })

  const csvUploaderCustomerRef = useRef<{ open: () => void }>(null)
  const csvUploaderProductRef = useRef<{ open: () => void }>(null)

  const fileRef = useRef<File | undefined>(undefined)
  const uploaderRef = useRef<Uploader | undefined>(undefined)

  const [csvCustomerState, csvCustomerDispatch] = useReducer(
    csvCustomerReducer,
    csvCustomerInitialState
  )
  const [csvProductState, csvProductDispatch] = useReducer(
    csvProductReducer,
    csvProductInitialState
  )

  const csvCustomerUploaderStateRef = useRef<CsvCustomerState | null>(null)
  useEffect(() => {
    csvCustomerUploaderStateRef.current = csvCustomerState
  }, [csvCustomerState])

  const csvProductUploaderStateRef = useRef<CsvProductState | null>(null)
  useEffect(() => {
    csvProductUploaderStateRef.current = csvProductState
  }, [csvProductState])

  useEffect(() => {
    return () => {
      uploaderRef.current?.abort()
    }
  }, [])

  const getState = () => {
    if (customerAttributesQuery.isLoading || productCatalogQuery.isLoading) {
      return 'loading'
    } else if (customerAttributesQuery.isError || productCatalogQuery.isError) {
      return 'error'
    } else {
      return 'success'
    }
  }

  const state = getState()

  return (
    <PageContainer className="px-6">
      {
        {
          loading: <Spinner />,
          success: (
            <>
              <PageHeader className="mb-8">
                <div className="flex justify-between flex-1">
                  <div className="pt-9">
                    <PageTitle title="Data Sources" />
                    <div className="text-coolGray-400">
                      Configure the below settings to let Jacquard know what we
                      should or should not use in the generation of your
                      content.
                    </div>
                  </div>
                </div>
              </PageHeader>
              <div className="flex flex-col gap-8">
                <Widget>
                  <WidgetHeader
                    title="Customer attributes"
                    actions={
                      <UploadButtonWithDropdown
                        onClick={() => {
                          csvUploaderCustomerRef.current?.open()
                        }}
                        description="Attributes"
                      />
                    }
                  />
                  <SourceUploader
                    ref={csvUploaderCustomerRef}
                    fileName={csvCustomerState.fileName}
                    progressInPercentage={csvCustomerState.progressInPercentage}
                    onStartUpload={(file) => {
                      fileRef.current = file
                    }}
                    onUpload={({ data, headers, fileName }) => {
                      csvCustomerDispatch({
                        type: 'upload',
                        data,
                        headers,
                        fileName,
                      })
                      dispatch(
                        customerUploadStart({
                          fileName,
                          data,
                        })
                      )
                    }}
                  />

                  <CustomerAttributesTable baseUrl={dataIngestionBaseUrl} />
                </Widget>
                <Widget>
                  <WidgetHeader
                    title="Product catalog"
                    actions={
                      <UploadButtonWithDropdown
                        onClick={() => {
                          csvUploaderProductRef.current?.open()
                        }}
                        description="Categories"
                      />
                    }
                  />
                  <SourceUploader
                    ref={csvUploaderProductRef}
                    fileName={undefined}
                    progressInPercentage={undefined}
                    onStartUpload={(file) => {
                      fileRef.current = file
                    }}
                    onUpload={({ data, headers, fileName }) => {
                      csvProductDispatch({
                        type: 'upload',
                        data,
                        headers,
                        fileName,
                      })
                      dispatch(
                        productUploadStart({
                          fileName,
                          data,
                        })
                      )
                    }}
                  />

                  <ProductCatalogTable baseUrl={dataIngestionBaseUrl} />
                </Widget>
              </div>

              <Footer />
              {csvCustomerState.isPreviewOpen && (
                <CsvCustomerPreview
                  isOpen={csvCustomerState.isPreviewOpen}
                  fileName={csvCustomerState.fileName ?? ''}
                  headers={csvCustomerState.headers}
                  data={csvCustomerState.data ?? []}
                  onCancel={() => {
                    csvCustomerDispatch({ type: 'cancelPreview' })
                  }}
                  onImport={(values) => {
                    infoToast(
                      'The upload has started. You will be notified when it is completed. Meanwhile, you can carry on with your work.'
                    )

                    csvCustomerDispatch({
                      type: 'clickImport',
                    })
                    dispatch(
                      fileNameConfirmed({
                        fileName: values.fileName,
                        type: 'customer',
                      })
                    )
                    const file = fileRef.current
                    if (file) {
                      uploaderRef.current = new Uploader({
                        baseUrl: dataIngestionBaseUrl,
                        fileName: values.fileName,
                        file: file,
                        accountId,
                      })
                        .onProgress(({ percentage: newPercentage }) => {
                          if (
                            newPercentage === 100 &&
                            csvCustomerUploaderStateRef.current !== null &&
                            csvCustomerUploaderStateRef.current.data
                          ) {
                            csvCustomerDispatch({ type: 'uploadCompleted' })
                          } else if (newPercentage !== 100) {
                            csvCustomerDispatch({
                              type: 'uploadProgress',
                              percentage: newPercentage,
                            })
                          }
                        })
                        .onError(() => {
                          csvCustomerDispatch({ type: 'errorUpload' })
                          errorToast(
                            'Something went wrong while uploading the file. Please try again or contact support.'
                          )
                        })
                      uploaderRef.current.start({
                        source: 'customer',
                        data: {
                          customerIdString: values.customerIdColumn,
                          columnsToExclude: values.columnsToExclude,
                        },
                      })
                    }
                  }}
                />
              )}
              {csvProductState.isPreviewOpen && (
                <CsvProductPreview
                  isOpen={csvProductState.isPreviewOpen}
                  fileName={csvProductState.fileName}
                  headers={csvProductState.headers}
                  data={csvProductState.data ?? []}
                  onCancel={() => {
                    csvProductDispatch({ type: 'cancelPreview' })
                  }}
                  onImport={(values) => {
                    infoToast(
                      'The upload has started. You will be notified when it is completed. Meanwhile, you can carry on with your work.'
                    )

                    csvProductDispatch({
                      type: 'clickImport',
                    })
                    dispatch(
                      fileNameConfirmed({
                        fileName: values.fileName,
                        type: 'product',
                      })
                    )
                    const file = fileRef.current
                    if (file) {
                      uploaderRef.current = new Uploader({
                        baseUrl: dataIngestionBaseUrl,
                        fileName: values.fileName,
                        file: file,
                        accountId,
                      })
                        .onProgress(({ percentage: newPercentage }) => {
                          if (
                            newPercentage === 100 &&
                            csvProductUploaderStateRef.current !== null &&
                            csvProductUploaderStateRef.current.data
                          ) {
                            csvProductDispatch({ type: 'uploadCompleted' })
                          } else if (newPercentage !== 100) {
                            csvProductDispatch({
                              type: 'uploadProgress',
                              percentage: newPercentage,
                            })
                          }
                        })
                        .onError(() => {
                          csvProductDispatch({ type: 'errorUpload' })
                          errorToast(
                            'Something went wrong while uploading the file. Please try again or contact support.'
                          )
                        })
                      uploaderRef.current.start({
                        source: 'product',
                        data: {
                          productIdColumnName: values.productIdColumn,
                          productNameColumnName: values.productNameColumn,
                          separatedBy: values.dataSeparator,
                          categoryColumnName: values.categoryColumn,
                          categoryDelimiter: values.delimiter,
                          categoryLevelsColumnNames: values.categoryLevel,
                          columnsToExclude: values.columnsToExclude,
                        },
                      })
                    }
                  }}
                />
              )}
            </>
          ),
          error: <ErrorPage />,
        }[state]
      }

      <UploadConfirmationModal
        onCancel={() => {
          csvCustomerDispatch({ type: 'cancelConfirmationPreview' })
        }}
        onConfirm={() => {
          csvCustomerDispatch({ type: 'closeConfirmationPreview' })
          dispatch(fileProcessCancel({ type: 'customer' }))
          fileRef.current = undefined
        }}
        isOpen={csvCustomerState.isConfirmationPreviewOpen}
      />
      <UploadConfirmationModal
        onCancel={() => {
          csvProductDispatch({ type: 'cancelConfirmationPreview' })
        }}
        onConfirm={() => {
          csvProductDispatch({ type: 'closeConfirmationPreview' })
          dispatch(fileProcessCancel({ type: 'product' }))
          fileRef.current = undefined
        }}
        isOpen={csvProductState.isConfirmationPreviewOpen}
      />

      <RouterPromptStay
        shouldShow={
          csvCustomerState.progressInPercentage !== undefined ||
          csvProductState.progressInPercentage !== undefined
        }
        onOK={() => Promise.resolve(true)}
        confirmationText="If you navigate away from this page, the current file upload process will be interrupted."
      />
    </PageContainer>
  )
}

export default DataSources
