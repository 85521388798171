import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import MainContent from 'features/unifiedFlow/components/MainContent'

import useElementState from './hooks/useElementState'
import CenterSection from './centerSection'
import LeftSection from './leftSection'
import RightSection from './rightSection'

const Results = () => {
  useDocumentTitle('Results | Jacquard')
  useElementState()

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  return (
    <MainContent className="pb-4" id="main-content-page">
      <LeftSection />
      {!!selectedElementId && (
        <>
          <CenterSection />
          <RightSection />
        </>
      )}
    </MainContent>
  )
}

export default Results
