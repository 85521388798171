import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { isEqual } from 'lodash'

import Catalog, { TreeNode } from 'common/components/catalog/Catalog'
import FormItem from 'common/components/FormItem'
import SingleSelect from 'common/components/singleSelect'
import { errorToast } from 'common/components/toastNotification'
import { CustomerAttributes } from 'features/personalization/api'
import useGetFiltersQuery from 'features/personalization/api/queries/useGetFiltersQuery'

import CustomerAttributesDropdown from './CustomerAttributesDropdown'

type Props = {
  customerAttributes: TreeNode
  selectedCustomerAttributes: TreeNode
  onClickCustomerAttributes: ({
    treeNode,
    mapped,
  }: {
    treeNode: TreeNode
    mapped?: CustomerAttributes
  }) => void
  productCategories: TreeNode
  selectedProductCategories: TreeNode
  onClickProductCategories: ({ treeNode }: { treeNode: TreeNode }) => void
  campaignType?: string
  channels?: string[]
  storedTypedAttributes: CustomerAttributes
  hasAttributesChanged: boolean
}

const CATALOG_ALL = { id: 'all', value: 'all', label: 'All' }
const CATALOG_CUSTOM = {
  id: 'custom',
  value: 'custom',
  label: 'Custom',
}

const Filters = ({
  customerAttributes,
  selectedCustomerAttributes,
  onClickCustomerAttributes,
  productCategories,
  selectedProductCategories,
  onClickProductCategories,
  campaignType,
  channels,
  storedTypedAttributes,
  hasAttributesChanged,
}: Props) => {
  const filtersQuery = useGetFiltersQuery()

  const [isCustomerAttributesOpened, setIsCustomerAttributesOpened] =
    useState(false)
  const [customerAttributesSearch, setCustomerAttributesSearch] = useState('')

  const [isProductCategoriesOpened, setIsProductCategoriesOpened] =
    useState(false)
  const [productCategoriesSearch, setProductCategoriesSearch] = useState('')

  return (
    <div>
      <Form<{
        campaignType: string
        channel: string
        customerAttributes: string
      }>
        initialValues={{
          campaignType: campaignType,
          channel: channels?.[0] ?? '',
        }}
        keepDirtyOnReinitialize
        onSubmit={() => {
          errorToast('Not implemented yet')
        }}
        render={({ handleSubmit }) => (
          <form className="flex gap-6 pt-12 lg:pt-8" onSubmit={handleSubmit}>
            <Field<string>
              name="campaignType"
              render={({ input, meta }) => (
                <FormItem
                  label="Campaign Type"
                  htmlFor="campaignType"
                  className="max-w-75"
                  error={meta.error}
                >
                  <SingleSelect
                    id="campaignType"
                    data-cy="campaignType"
                    onChange={(val) => input.onChange(val?.value)}
                    value={input.value}
                    isSearchable={true}
                    required
                    isDisabled
                    options={
                      filtersQuery.data?.useCases?.map(
                        ({ value, displayName }) => ({
                          label: displayName,
                          value,
                        })
                      ) ?? []
                    }
                  />
                </FormItem>
              )}
            />
            <Field<string>
              name="channel"
              render={({ input, meta }) => (
                <FormItem
                  label="Channels"
                  htmlFor="channel"
                  className="max-w-75"
                  error={meta.error}
                >
                  <SingleSelect
                    id="channel"
                    data-cy="channel"
                    onChange={(val) => input.onChange(val?.value)}
                    value={input.value}
                    isSearchable={true}
                    isDisabled
                    required
                    options={
                      filtersQuery.data?.channels?.map(
                        ({ value, displayName }) => ({
                          label: displayName,
                          value,
                        })
                      ) ?? []
                    }
                  />
                </FormItem>
              )}
            />
            <Field<string>
              name="customerAttributes"
              render={({ meta }) => (
                <FormItem
                  label="Customer Attributes"
                  htmlFor="customerAttributes"
                  className="max-w-75"
                  error={meta.error}
                >
                  <CustomerAttributesDropdown
                    id="CustomerAttributes"
                    data-cy="customerAttributes"
                    options={[CATALOG_ALL, CATALOG_CUSTOM]}
                    value={hasAttributesChanged ? CATALOG_ALL : CATALOG_CUSTOM}
                    inputValue={customerAttributesSearch}
                    isOpened={isCustomerAttributesOpened}
                    onClearClick={() => {
                      setIsCustomerAttributesOpened(false)
                      setCustomerAttributesSearch('')
                    }}
                    onMenuOpen={() => {
                      setIsCustomerAttributesOpened(true)
                      setIsProductCategoriesOpened(false)
                    }}
                    onClose={() => setIsCustomerAttributesOpened(false)}
                    onInputChange={(value, actionMeta) => {
                      if (actionMeta.action === 'input-change') {
                        setCustomerAttributesSearch(value)
                      }
                    }}
                  />
                </FormItem>
              )}
            />
            <Field<string>
              name="productCategory"
              render={({ meta }) => (
                <FormItem
                  label="Product Categories"
                  htmlFor="productCategory"
                  className="max-w-75"
                  error={meta.error}
                >
                  <CustomerAttributesDropdown
                    id="productCategories"
                    data-cy="productCategories"
                    options={[CATALOG_ALL, CATALOG_CUSTOM]}
                    value={
                      isEqual(selectedProductCategories, productCategories)
                        ? CATALOG_ALL
                        : CATALOG_CUSTOM
                    }
                    inputValue={productCategoriesSearch}
                    isOpened={isProductCategoriesOpened}
                    onClearClick={() => {
                      setIsProductCategoriesOpened(false)
                      setProductCategoriesSearch('')
                    }}
                    onMenuOpen={() => {
                      setIsProductCategoriesOpened(true)
                      setIsCustomerAttributesOpened(false)
                    }}
                    onClose={() => setIsProductCategoriesOpened(false)}
                    onInputChange={(value, actionMeta) => {
                      if (actionMeta.action === 'input-change') {
                        setProductCategoriesSearch(value)
                      }
                    }}
                  />
                </FormItem>
              )}
            />
          </form>
        )}
      />
      {isCustomerAttributesOpened && (
        <div className="h-full">
          <Catalog
            title="Filter by customer attributes"
            items={customerAttributes}
            selectedItems={selectedCustomerAttributes}
            onApply={({ node, mapped }) =>
              onClickCustomerAttributes({ treeNode: node, mapped })
            }
            searchValue={customerAttributesSearch}
            storedTypedAttributes={storedTypedAttributes}
          />
        </div>
      )}
      {isProductCategoriesOpened && (
        <div className="h-full">
          <Catalog
            title="Filter by product categories"
            items={productCategories}
            selectedItems={selectedProductCategories}
            onApply={({ node: treeNode }) =>
              onClickProductCategories({ treeNode })
            }
            searchValue={productCategoriesSearch}
          />
        </div>
      )}
    </div>
  )
}
export default Filters
