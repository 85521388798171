import { Role } from '@phrasee/phrasee-typings/typings/user/user'
import { AxiosResponse } from 'axios'

import { connectInternalApi, restApi } from 'common/api'

export interface GetUserResponse {
  _id: string
  first_name: string
  last_name: string
  email: string
  phoneNumber?: string
  jobTitle?: string
  projectIds: string[]
  role: string
  roles: string[]
  isSuspended: boolean
  created: string
  last_login_at?: string
}

export interface CreateUserResponse {
  _id: string
  first_name: string
  last_name: string
  email: string
  phoneNumber?: string
  jobTitle?: string
  projectIds: string[]
  role: Role
  roles: string[]
  blocked: boolean
  created: string
  last_login_at?: string
}

export interface UpdatedUserResponse {
  _id: string
  assigned_projects: string[]
  created: string
  isSuspended: boolean
  createdAt: string
  email: string
  first_name: string
  fname: string
  job_title: string
  last_login_at: string
  last_name: string
  last_updated: string
  lname: string
  phonenumber: string
  role: string
  roles: string[]
}

export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
  jobTitle?: string
  projectIds: string[]
  role: string
  roles: string[]
  isSuspended: boolean
  createdAt: string
  lastLoginAt?: string
}

export interface GetAssignableRolesResponse {
  data: {
    canAssign: Role[]
  }
}

export const getUsers = async (
  accountId: string,
  permissions: string[] = []
): Promise<AxiosResponse<GetUserResponse[]>> => {
  return connectInternalApi.get<GetUserResponse[]>(
    `v1/core/marge/accounts/${accountId}/users`
  )
}

export const getAssignableRoles = async (
  userId: string
): Promise<AxiosResponse<GetAssignableRolesResponse>> => {
  return connectInternalApi.get<GetAssignableRolesResponse>(
    `v1/core/monorail/roles?assignableByUserID=${userId}`
  )
}

export const createUser = async ({
  accountId,
  firstName,
  lastName,
  role,
}: {
  accountId: string
  firstName: string
  lastName: string
  role: string[]
}): Promise<AxiosResponse<User>> => {
  return restApi.post<User>(`accounts/${accountId}/users`, {
    firstName,
    lastName,
    role,
  })
}

export type InviteUser = Partial<User> &
  Pick<User, 'firstName' | 'lastName' | 'email' | 'role' | 'roles'>

export type InviteUserResponse = {
  _id: string
  first_name: string
  last_name: string
  email: string
  assigned_projects: string[]
  role: string
  roles: string[]
  job_title?: string
  account_id: string
  blocked: boolean
  created: string
}

export const inviteUsers = async ({
  accountId,
  user,
}: {
  accountId: string
  user: InviteUser
}): Promise<AxiosResponse<InviteUserResponse>> => {
  return connectInternalApi.post<InviteUserResponse>(
    `v1/core/marge/users`,
    {
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      roles: user.roles,
      assigned_projects: user.projectIds,
    },
    { params: { account_id: accountId } }
  )
}

export const deleteUser = async ({
  accountId,
  userId,
}: {
  accountId: string
  userId: string
}) => {
  return restApi.delete<User>(`accounts/${accountId}/users/${userId}`)
}

export const activateOrDeactivateUser = async ({
  accountId,
  userId,
  action,
}: {
  accountId: string
  userId: string
  action: 'activate' | 'deactivate'
}): Promise<AxiosResponse<{ success: boolean }>> => {
  return connectInternalApi.post(
    `v1/core/marge/users/${userId}/${action}`,
    {},
    {
      params: {
        account_id: accountId,
      },
    }
  )
}

export const resetPassword = async ({
  accountId,
  userId,
}: {
  accountId: string
  userId: string
}): Promise<void> => {
  return (
    await connectInternalApi.post<void>(
      `v1/core/marge/users/${userId}/reset`,
      undefined,
      { params: { account_id: accountId } }
    )
  ).data
}

export const reinviteUser = async ({
  accountId,
  userId,
}: {
  accountId: string
  userId: string
}): Promise<void> => {
  return (
    await connectInternalApi.post<void>(
      `v1/core/marge/users/${userId}/re-invite`,
      undefined,
      { params: { account_id: accountId } }
    )
  ).data
}

export const updateUserRole = async ({
  accountId,
  userId,
  roles,
}: {
  accountId: string
  userId: string
  roles: string[]
}): Promise<AxiosResponse<CreateUserResponse>> => {
  return connectInternalApi.patch<CreateUserResponse>(
    `v1/core/marge/users/${userId}`,
    {
      roles,
    },
    { params: { account_id: accountId } }
  )
}

export const updateUser = async ({
  accountId,
  user,
}: {
  accountId: string
  user: Partial<User> & Pick<Partial<UpdatedUserResponse>, 'email' | '_id'>
}): Promise<AxiosResponse<UpdatedUserResponse>> => {
  const { _id: userId, ...rest } = user
  return connectInternalApi.patch<UpdatedUserResponse>(
    `v1/core/marge/users/${userId}`,
    {
      ...rest,
    },
    { params: { account_id: accountId } }
  )
}

export interface Summary {
  adminCount: number
  userCount: number
  viewerCount: number
  approverCount: number
  activeCount: number
}
export const getSummary = async ({
  accountId,
  projectIds,
}: {
  accountId: string
  projectIds: string[]
}): Promise<AxiosResponse<Summary>> => {
  return connectInternalApi.get<Summary>(
    `v1/core/marge/accounts/${accountId}/users/summaryCounts`,
    {
      params: {
        projectIds,
      },
    }
  )
}
