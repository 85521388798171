import { SENTIMENTS } from 'features/unifiedFlow/contentPage/generate/constants'

interface Props {
  sentiment: string
}

const impressed = [
  'Emphatic adjectives around products or promotions (e.g. amazing prices)',
  'Positive direct address (e.g. 20% off because you’re awesome)',
  'Capitalization',
  'Exclamative phrases (e.g. OH WOW!)',
]

const helpful = [
  'Informational or instructional (e.g. save on your trip)',
  'Practical and direct language (e.g. we’re here to help',
  'Focus on benefit to the recipient (e.g. treat yourself)',
]

const curious = [
  'Questions (e.g. have you heard?)',
  'Use of ellipses and hanging sentences (e.g. too hot to miss…',
  'Intrigue over directness and specificity (e.g. we think you’ll like this)',
  'Use of social proof (e.g. find out why everyone’s talking about…)',
]

const excited = [
  'Capitalization',
  'Exclamation points',
  'Enthusiastic language (e.g. we’ve got some amazing news)',
  'Building anticipation (e.g. the countdown is on…)',
]

const surprising = [
  'Mystery and intrigue (e.g uncover today’s discount)',
  'Hyperbole (e.g unbelievable products inside)',
  'Colloquial exclamations (e.g omg!)',
]

const appreciative = [
  'Politeness strategies (e.g. a little thanks from us)',
  'Assumption of customer satisfaction (e.g. no need to thank us)',
  'A “you’ve earned it” tone (e.g. you deserve a treat)',
]

const urgent = [
  'Promo duration specifics (ends in 2 days)',
  'Limited-time language (save before it’s too late)',
  'Limited-range language (save on shoes before they’re gone!)',
  'Use of exclamation marks',
]

const SentimentDescription = ({ sentiment }: Props) => {
  return (
    <div
      className="flex flex-col border-coolGray-300 border shadow-sm p-2.5 px-4"
      data-testid="sentimentDescription"
    >
      <span className="text-xs font-medium leading-4 mb-2 capitalize">
        {sentiment}
      </span>
      <div className="text-xs">
        <span className="text-coolGray-600">
          Common features of this sentiment:
        </span>
        <ol className="ml-2 list-disc list-inside">
          <>
            {{
              [SENTIMENTS.APPRECIATIVE]: appreciative,
              [SENTIMENTS.CURIOUS]: curious,
              [SENTIMENTS.EXITED]: excited,
              [SENTIMENTS.HELPFUL]: helpful,
              [SENTIMENTS.IMPRESSED]: impressed,
              [SENTIMENTS.SURPRISING]: surprising,
              [SENTIMENTS.URGENT]: urgent,
            }[sentiment]?.map((text: string, index: number) => {
              return <li key={`sentiment-description-${index}`}>{text}</li>
            })}
          </>
        </ol>
      </div>
    </div>
  )
}

export default SentimentDescription
