import { generatePath } from 'react-router'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import { useFlags } from 'launchdarkly-react-client-sdk'

import SubNavBar, { SubNavItem } from 'common/components/navigation/subNavBar'

import { allRoutes, commonRoutes, llmRoutes } from '../../routes'

type Props = {
  accountId: string
  projectId: string
  title: string
  regionId: string | undefined
  languageGenerationMethod: string | undefined
}

const ProjectNav = ({
  accountId,
  projectId,
  title,
  regionId,
  languageGenerationMethod,
}: Props) => {
  const flags = useFlags()

  const projectRoutes =
    languageGenerationMethod === 'linguo'
      ? allRoutes
      : [...commonRoutes, ...llmRoutes]

  return (
    <SubNavBar title={title}>
      {projectRoutes
        .filter(({ accessFlags }) =>
          isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
        )
        .map(({ title, to }) => (
          <SubNavItem
            key={to}
            title={title}
            to={generatePath(to, {
              accountId,
              projectId,
            })}
          />
        ))}
    </SubNavBar>
  )
}

export default ProjectNav
