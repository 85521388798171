type Props = {
  title?: string
  isHidden?: boolean
  withShadowWrapper?: boolean
}

const ReportItem: React.FC<Props> = ({
  title,
  isHidden,
  withShadowWrapper,
  children,
}) => {
  return (
    <>
      {isHidden ? null : (
        <div>
          {title && (
            <h3 className="mb-6 font-medium text-xl text-coolGray-800">
              {title}
            </h3>
          )}
          {withShadowWrapper ? (
            <div className="bg-white shadow-widget">{children}</div>
          ) : (
            children
          )}
        </div>
      )}
    </>
  )
}

export default ReportItem
