import React from 'react'
import ContentLoader from 'react-content-loader'

const TextLoader: React.FC = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 263 61"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="mb-6"
    >
      <rect x="0" y="0" rx="2" ry="2" width="263" height="14" />
      <rect x="0" y="22" rx="2" ry="2" width="220" height="14" />
      <rect x="0" y="47" rx="2" ry="2" width="120" height="14" />
    </ContentLoader>
  )
}

export default TextLoader
