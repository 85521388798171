import { Redirect, Route } from 'react-router-dom'
import Content from 'app/Content'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import { adminSubRoute } from 'app/navigation/staticSubRoutes.admin'
import CustomSwitch from 'app/router/Switch'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { canAccessAccount, getIsAuthorized } from 'common/auth/permissions'
import { useAppSelector } from 'common/hooks/redux'

const Admin = () => {
  const flags = useFlags()
  const { permissions, accountCategory } = useAppSelector(
    (state) => state.authStates
  )

  const subroutes = adminSubRoute.filter(
    ({ accessFlags, accessPermission, shouldValidateAccountAccess }) => {
      const isAuthorized = flags.rolesAndPermissions
        ? getIsAuthorized(permissions, accessPermission)
        : true

      const hasAccountAccess =
        accessPermission && shouldValidateAccountAccess
          ? canAccessAccount(accountCategory, accessPermission)
          : true

      return (
        isAuthorized &&
        hasAccountAccess &&
        isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
      )
    }
  )

  return (
    <Content fullPage className="xl:pl-0">
      <CustomSwitch>
        {subroutes.map(({ key, to, component: Component }) => (
          <Route key={key} path={to}>
            <Component />
          </Route>
        ))}
        {subroutes?.length && (
          <Redirect exact from="/admin" to={subroutes[0].to} />
        )}
      </CustomSwitch>
    </Content>
  )
}

export default Admin
