import { Content } from '@phrasee/phrasee-typings/typings/futurama/content'
import {
  ComponentElement,
  Element,
  TemplateElement,
} from '@phrasee/phrasee-typings/typings/futurama/element'
import { NLGStatus } from '@phrasee/phrasee-typings/typings/futurama/element'
import { QueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'

import { contentKeys } from 'features/futurama/api/queryKeys'

import { isComponentElement, isTemplateElement } from '../api/interfaces'

export const getAllComponentElements = (content?: Content) => {
  const allComponentElements: ComponentElement[] = []

  content?.elements.forEach((element) => {
    if (isComponentElement(element)) {
      allComponentElements.push(element)
    } else {
      allComponentElements.push(...element.elements.filter(isComponentElement))
    }
  })

  return allComponentElements
}

export const getAllTemplateElements = (content?: Content) => {
  const allTemplateElements: TemplateElement[] = []

  content?.elements.forEach((element) => {
    if (isTemplateElement(element)) {
      allTemplateElements.push(element)
    }
  })

  return allTemplateElements
}

export const getAllElements = (content?: Content) => {
  const allElements: Element[] = []

  content?.elements.forEach((element) => {
    allElements.push(element)
    if (isTemplateElement(element)) {
      allElements.push(...element.elements)
    }
  })

  return allElements
}

export const getSelectedElement = (content?: Content) => {
  const allElements = getAllElements(content)
  return allElements?.find(({ status }) => status === 'selected')
}

export const getFirstComponentElement = (content?: Content) => {
  const allComponentElements = getAllComponentElements(content)
  return allComponentElements.length > 0 ? allComponentElements[0] : undefined
}

export const getIsLastComponentElement = (
  content: Content | undefined,
  elementId: number | string | undefined
) => {
  if (!content || !elementId) {
    return false
  }

  let isLast = false

  content.elements.forEach((element) => {
    if (isTemplateElement(element)) {
      element.elements.forEach((componentElement, index, array) => {
        if (
          isComponentElement(componentElement) &&
          componentElement.element_id === elementId &&
          array.length - 1 === index
        ) {
          isLast = true
        }
      })
    } else if (element.element_id === elementId) {
      isLast = true
    }
  })

  return isLast
}

export const setVariantsLoadingState = (
  queryClient: QueryClient,
  contentId: string,
  elementId: number
) => {
  queryClient.setQueryData(
    contentKeys.content(contentId),
    (old: Content | undefined) => {
      if (old) {
        return {
          ...old,
          elements: old.elements.map((element) => {
            if (isTemplateElement(element)) {
              const clonedElements = cloneDeep(element.elements)
              clonedElements
                .filter(isComponentElement)
                .forEach((componentElement) => {
                  if (componentElement.element_id === elementId) {
                    componentElement.nlg_status = 'pending'
                  }
                })

              return { ...element, elements: clonedElements }
            }

            if (element.element_id === elementId) {
              return { ...element, nlg_status: 'pending' as NLGStatus }
            }

            return element
          }),
        }
      }
      return old
    }
  )
}

export const getContentHasVariants = (content?: Content) =>
  getAllComponentElements(content)?.some(
    (element) => element.variants && element.variants.length > 0
  )

export const getElementHasVariants = (element?: Element) => {
  if (!element) {
    return false
  }

  return isComponentElement(element)
    ? element.variants && element.variants?.length > 0
    : element &&
        element.elements.some(
          (el) =>
            isComponentElement(el) && el.variants && el.variants.length > 0
        )
}

export const getElementGenerationComplete = (
  content?: Content,
  element?: Element
) => {
  if (!element || !content) {
    return false
  }

  let rootElement: Element | undefined
  for (const el of content.elements) {
    if (isComponentElement(el)) {
      if (el.element_id === element.element_id) {
        rootElement = el
        break
      }
    } else {
      if (el.element_id === element.element_id) {
        rootElement = el
        break
      }
      const foundElement = el.elements.find(
        (nestedElement) => nestedElement.element_id === element.element_id
      )

      if (foundElement) {
        rootElement = el
        break
      }
    }
  }

  return rootElement && isComponentElement(rootElement)
    ? rootElement.variants && rootElement.variants?.length > 0
    : rootElement &&
        rootElement.elements.every(
          (el) =>
            isComponentElement(el) && el.variants && el.variants.length > 0
        )
}

export const getIsAtleastOneElementGenerationComplete = (content?: Content) => {
  if (!content) {
    return false
  }

  return content.elements.some((element) => {
    if (isComponentElement(element)) {
      return element.variants && element.variants?.length > 0
    } else {
      return element.elements.every(
        (el) => isComponentElement(el) && el.variants && el.variants.length > 0
      )
    }
  })
}

export const getElementIdForGeneration = (
  content?: Content,
  selectedElement?: Element
) => {
  if (!content) {
    return
  }

  if (selectedElement) {
    if (isComponentElement(selectedElement)) {
      return selectedElement.element_id
    } else {
      return selectedElement.elements[0].element_id
    }
  }

  return getFirstComponentElement(content)?.element_id
}
