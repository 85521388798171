import { useEffect, useRef, useState } from 'react'
import { ComponentElement } from '@phrasee/phrasee-typings'

import { ExtendedVariant } from './ContentTable'

const getSelectedRows = (
  variants?: ExtendedVariant[],
  selectedComponent?: ComponentElement
) => {
  const selectedRows: ExtendedVariant[] = []

  variants?.forEach((variant) => {
    if (
      selectedComponent?.selected_variants?.length &&
      selectedComponent.selected_variants.includes(variant.variant_id)
    ) {
      selectedRows.push(variant)
    }
    if (variant.subRows) {
      variant.subRows.forEach((subVariant) => {
        if (
          selectedComponent?.selected_variants?.length &&
          selectedComponent.selected_variants.includes(subVariant.variant_id)
        ) {
          selectedRows.push(subVariant)
        }
      })
    }
  })

  return selectedRows
}

const useSelectedRows = (
  variants?: ExtendedVariant[],
  selectedComponent?: ComponentElement
) => {
  const [selectedRows, setSelectedRows] = useState<ExtendedVariant[]>(
    getSelectedRows(variants, selectedComponent)
  )
  const initialSelectedRows = useRef<ExtendedVariant[]>(selectedRows)

  useEffect(() => {
    const rows = getSelectedRows(variants, selectedComponent)

    setSelectedRows(rows)
    initialSelectedRows.current = rows

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponent?.element_id])

  useEffect(() => {
    const rows = getSelectedRows(variants, selectedComponent)

    setSelectedRows(rows)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants])

  return {
    selectedRows,
    initialSelectedRows: initialSelectedRows.current,
    setSelectedRows,
  }
}

export default useSelectedRows
