import { useState } from 'react'
import { components, GroupBase, OptionProps, SingleValue } from 'react-select'

import useGetNodesQuery from 'common/api/queries/useGetNodesQuery'
import Autocomplete from 'common/components/autcomplete'
import Button from 'common/components/button/Button'
import HighlightText from 'common/components/HighlightText'
import Modal from 'common/components/Modal'
import { buildBreadcrumbs } from 'common/components/nodes/Breadcrumbs'
import useDebounce from 'common/hooks/useDebounce'
import { NodeFilterOption as FilterOption } from 'common/interfaces/nodes'

import { moveAutocompleteCustomStyles } from './filters/FilterStyles'

interface Props {
  isOpen: boolean
  onCancel: () => void
  isLoading?: boolean
  onNext: () => void
  onMoveAndDelete: () => void
  onMoveAndKeep: () => void
  hasDuplicates?: boolean
  onSelectNodeId: (val: string) => void
  currentNodeId: string
}

const containsSearchValue = (text: string, searchValue?: string) => {
  if (!searchValue) {
    return false
  }

  return text
    .replaceAll(' ', '')
    .toLowerCase()
    .includes(searchValue.replaceAll(' ', '').toLowerCase())
}

export const Option = ({
  isSelected,
  children,
  data,
  ...rest
}: OptionProps<FilterOption, false, GroupBase<FilterOption>>) => {
  const { path, highlightedLabel } = data
  return (
    <components.Option isSelected={isSelected} data={data} {...rest}>
      <div
        className="flex items-center px-4 py-2 "
        role="option"
        aria-selected={isSelected}
      >
        <div>
          <div className="font-medium text-coolGray-800 mb-1 ">
            {highlightedLabel}
          </div>
          <div className="font-normal text-coolGray-500 ">{path}</div>
        </div>
      </div>
    </components.Option>
  )
}

const ManagePhrasesModal = ({
  isOpen,
  onCancel,
  isLoading,
  onNext,
  onMoveAndDelete,
  onMoveAndKeep,
  hasDuplicates,
  onSelectNodeId,
  currentNodeId,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string | undefined>('')
  const [targetNodePath, setTargetNodePath] = useState<string | null>()
  const [options, setOptions] = useState<FilterOption[]>([])

  const { data: nodes } = useGetNodesQuery()

  const getOptions = () => {
    const newOptions: FilterOption[] = []

    nodes?.nodes
      .filter((node) => node.id !== currentNodeId)
      .forEach((node) => {
        if (!containsSearchValue(node.name, searchValue)) {
          return
        }
        const { breadcrumbsString } = buildBreadcrumbs(nodes.nodes, node.id)

        newOptions.push({
          label: node.name,
          highlightedLabel: (
            <HighlightText text={node.name} searchValue={searchValue} />
          ),
          value: node.id,
          path: breadcrumbsString
            .substring(0, breadcrumbsString.lastIndexOf('/'))
            .trim(),
        })
      })

    newOptions.sort((a, b) => a.label.localeCompare(b.label))
    setOptions(newOptions)
  }

  const debouncedGetOptions = useDebounce(getOptions, 500)

  const onSearch = (value: string) => {
    if (value === '') {
      setTargetNodePath(null)
    }
    setSearchValue(value)
    debouncedGetOptions()
  }

  const onChange = (option: SingleValue<FilterOption>) => {
    if (option) {
      const { value, path, label } = option
      setTargetNodePath(`${path}/ ${label}`)
      onSelectNodeId(value)
    }
  }
  return (
    <Modal
      visible={isOpen}
      centered
      closable={false}
      footer={null}
      style={{ maxWidth: 500 }}
    >
      <div className="flex">
        <h1 className="mt-2 text-2xl font-bold text-coolGray-800">
          {hasDuplicates
            ? 'One or more of the phrases are duplicates and can’t be moved into this node…'
            : 'Move phrases to...'}
        </h1>
      </div>

      <div className="mt-4 mb-4 text-lg text-coolGray-500">
        {hasDuplicates
          ? 'Either move all non-duplicated phrases and delete duplicates or only move non-duplicated phrases and keep the duplicates in this node.'
          : 'Where would you like to move these phrases?'}
      </div>

      {!hasDuplicates && (
        <div>
          <p className="font-medium mb-2"> Node name</p>

          <Autocomplete<FilterOption>
            placeholder="Find a node"
            data-cy="nodes-search"
            options={options ? options : []}
            onSearch={onSearch}
            data-testid="nodes-search-box"
            showSearchIcon={false}
            components={{ Option }}
            value={
              searchValue
                ? { value: searchValue, label: searchValue }
                : undefined
            }
            customStyles={moveAutocompleteCustomStyles({
              availableOptions:
                searchValue !== undefined && searchValue.length > 0,
            })}
            className="mb-2"
            onChange={onChange}
          />
          <div className="font-normal text-coolGray-500 mb-10 ml-2">
            {targetNodePath}
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <Button
          ghost
          className="mr-4 text-base text-coolGray-400 border border-coolGray-100 "
          onClick={onCancel}
          data-testid="confirm-cancel-button"
          data-cy="confirm-cancel-button"
        >
          Cancel
        </Button>

        {hasDuplicates ? (
          <>
            <Button
              variant="primary"
              className="mr-4"
              onClick={() => {
                onMoveAndDelete()
              }}
              loading={isLoading}
              data-cy="move-and-delete-button"
            >
              Move and delete
            </Button>

            <Button
              variant="primary"
              className="mr-4"
              onClick={() => {
                onMoveAndKeep()
              }}
              loading={isLoading}
              data-cy="move-and-keep-button"
            >
              Move and keep
            </Button>
          </>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              onNext()
            }}
            loading={isLoading}
            data-cy="move-phrases-next-button"
          >
            Next
          </Button>
        )}
      </div>
    </Modal>
  )
}

const ManagePhrasesModalWithResetState = (props: Props) => (
  <ManagePhrasesModal key={`${props.isOpen}`} {...props} />
)

export default ManagePhrasesModalWithResetState
