/* eslint-disable max-lines */
/* eslint-disable react/jsx-key */
// jsx-key rule is disabled due to "'key' is specified more than once,
// so this usage will be overwritten." error
import {
  forwardRef,
  memo,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import type {
  CellProps,
  CellValue,
  Column,
  ColumnInstance,
  Row,
  TableInstance,
  TableOptions,
} from 'react-table'
import {
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useExportData } from 'react-table-plugins'
import {
  Menu,
  MenuButton,
  MenuHeader,
  MenuItem as MenuItemRM,
} from '@szhsin/react-menu'
import cx from 'classnames'
import { isNumber } from 'lodash'
import merge from 'lodash/merge'

import type { CheckboxProps } from 'common/components/checkbox'
import Pagination from 'common/components/table/pagination'

import Tooltip from '../Tooltip'

import BaseCell from './cells/Base'
import DefaultCell from './cells/Default'
import DefaultRow from './rows/DefaultRow'
import GroupRows from './rows/GroupRows'
import TableCheckbox from './Checkbox'
import { extendPageRowIndex } from './helpers'
import { getExportFileBlob } from './helpers'
import TableRadio from './Radio'
import Resizer from './Resizer'
import SortIcon from './SortIcon'

import '@szhsin/react-menu/dist/core.css'
import '@szhsin/react-menu/dist/index.css'
import styles from './Table.module.css'

export type OnCellChange<T = unknown> = ({
  rowIndex,
  columnId,
  value,
  rowOriginal,
}: {
  rowIndex: number
  columnId: string
  value: number | string | undefined
  rowOriginal?: T
}) => void
export type OnSwapColumns = (args: {
  sourceColumnId: string
  targetColumnId: string
}) => void

export type TableData = {
  className?: string
  hasData?: boolean
  hasError?: boolean
  isHighlighted?: boolean
}
export type TableProps<T extends TableData> = TableOptions<T> & {
  disablePagination?: boolean
  fileName?: string
  rowSelection?: 'single' | 'multiple' | 'disabled'
  selectionHeader?: string
  onSelectRow?: (rows: T[]) => void
  onRowClick?: (row: T) => void
  onCellChange?: OnCellChange<T>
  onSwapColumns: OnSwapColumns
  updateAllFilterValues?: (values: string[]) => void
  isHeaderHidden?: boolean
  getCheckboxProps?: (row: Row<T>) => Pick<CheckboxProps, 'backgroundColor'>
  className?: string
  rowClassName?: string
  globalFilterValue?: string
  disableSubRowArrow?: boolean
  paginateExpandedRows?: boolean
}

export type TableHandle<T extends object> = {
  exportCsv: () => void
  allColumns: () => ColumnInstance<T>[]
  resetPaging: () => void
}

function Table<T extends TableData>(
  {
    columns,
    data,
    onCellChange,
    disablePagination,
    'data-cy': dataCy,
    fileName,
    rowSelection = 'disabled',
    selectionHeader,
    onSelectRow,
    onRowClick,
    onSwapColumns,
    globalFilter,
    globalFilterValue,
    updateAllFilterValues,
    isHeaderHidden = false,
    getCheckboxProps,
    className,
    rowClassName,
    disableSubRowArrow,
    generateRowClassName,
    paginateExpandedRows,
    ...others
  }: TableProps<T>,
  ref: Ref<TableHandle<T>>
) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    pageCount,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    exportData,
    allColumns,
    selectedFlatRows,
    state: {
      pageIndex,
      pageSize,
      columnResizing: { isResizingColumn },
    },
    flatRows,
    rows,
    setGlobalFilter,
    toggleAllRowsSelected,
  } = useTable(
    {
      ...(globalFilter && { globalFilter }),
      columns,
      data,
      pageCount: data.length,
      manualPagination: !!disablePagination,
      initialState: {
        globalFilter: globalFilterValue,
        pageIndex: 0,
        ...others?.initialState,
      },
      selectSubRows: false,
      paginateExpandedRows,
      defaultColumn: {
        Cell: DefaultCell,
        Filter: DefaultColumnFilter,
      },
      // onCellChange isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer
      onCellChange,
      getExportFileBlob: (params) => getExportFileBlob<T>(params),
      getExportFileName: () => fileName ?? 'table_data',
      ...others,
    },

    useFilters,
    useGlobalFilter,
    useGroupBy,
    ...(onSwapColumns !== undefined ? [] : [useSortBy]),
    useExpanded,
    usePagination,
    useRowSelect,
    useExportData,
    useFlexLayout,
    useResizeColumns,
    (hooks) => {
      if (rowSelection !== 'disabled') {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            className: rowSelection === 'single' ? '' : 'w-min flex-0 min-w-0',
            Header: ({ getToggleAllRowsSelectedProps }) => {
              if (rowSelection === 'multiple') {
                return <TableCheckbox {...getToggleAllRowsSelectedProps()} />
              } else if (rowSelection === 'single') {
                return <div>{selectionHeader}</div>
              }

              return null
            },
            Cell: ({ row }) => (
              <>
                {rowSelection === 'multiple' ? (
                  <BaseCell>
                    <TableCheckbox
                      {...merge(
                        row.getToggleRowSelectedProps(),
                        getCheckboxProps?.(row)
                      )}
                      isDisabled={row.original.isDisabled}
                    />
                  </BaseCell>
                ) : (
                  <BaseCell className="pr-0">
                    <TableRadio
                      {...row.getToggleRowSelectedProps()}
                      onChange={() => {
                        if (selectedFlatRows.length) {
                          toggleAllRowsSelected(false)
                        }
                        row.toggleRowSelected()
                      }}
                      isDisabled={row.original.isDisabled}
                      checked={row.original.checked}
                    />
                  </BaseCell>
                )}
              </>
            ),
          },
          ...columns,
        ])
      }
    }
  )

  useEffect(() => {
    if (globalFilterValue !== undefined) {
      setGlobalFilter(globalFilterValue)
      gotoPage(0)
    }
  }, [globalFilterValue, gotoPage, setGlobalFilter])

  function flattenValues(value) {
    if (Array.isArray(value)) {
      return value.flatMap(flattenValues)
    } else if (typeof value === 'object' && value !== null) {
      return Object.values(value).flatMap(flattenValues)
    } else {
      return [value]
    }
  }

  const allValues = flatRows
    .map((row) => {
      const values = { ...row.values }
      columns
        .filter((c) => c.disableFilters)
        .map((c) => c.id)
        .forEach((id) => {
          if (id) {
            delete values[id]
          }
        })
      return values
    })
    .map((value) => (isNumber(value) ? value.toString() : value))
    .flatMap(flattenValues)
    .filter((value) => typeof value === 'string')

  useEffect(() => {
    updateAllFilterValues?.(allValues)
  }, [allValues, updateAllFilterValues])
  const canSwapColumns = onSwapColumns !== undefined

  const menuOptions = (columnId: string) => [
    <MenuHeader
      className="text-left normal-case text-coolGray-400 text-sm py-2"
      key="columnTitle"
    >
      Column title
    </MenuHeader>,
    ...columns
      .filter(({ accessor, isEditable }) => accessor !== columnId && isEditable)
      .map(({ Header, accessor: targetColumnId }) => (
        <MenuItemRM
          key={Header?.toString() ?? ''}
          className="font-normal hover:bg-coolGray-100 py-2"
          onClick={() => {
            onSwapColumns({
              sourceColumnId: columnId,
              targetColumnId: targetColumnId?.toString() ?? '', // TODO change the ?? ''
            })
          }}
        >
          {Header}
        </MenuItemRM>
      )),
  ]

  useEffect(() => {
    if (onSelectRow) {
      onSelectRow(selectedFlatRows.map((row) => row.original))
    }
  }, [onSelectRow, selectedFlatRows])

  useImperativeHandle(ref, () => ({
    exportCsv() {
      exportData('csv', true)
    },
    allColumns() {
      return allColumns
    },
    resetPaging: () => gotoPage(0),
  }))

  const [hoveredColumnIndex, setHoveredColumnIndex] = useState<number>()

  const showFooter = useMemo(
    () => columns.some((column) => !!column.Footer),
    [columns]
  )

  const tableHasSubColumns = headerGroups && headerGroups.length > 1

  const columnPadding = 'px-4 py-4 first:pl-6 last:pr-6'

  const hasColumnAction = columns.some((column) =>
    column.hasOwnProperty('columnAction')
  )

  const extendedPage = extendPageRowIndex(page)

  return (
    <>
      <div className={cx('overflow-x-auto', className)}>
        <div
          {...getTableProps()}
          data-cy={dataCy}
          className={cx('w-full text-coolGray-800', styles.table, {
            'cursor-col-resize select-none': isResizingColumn,
          })}
        >
          {!isHeaderHidden && (
            <div data-cy={`${dataCy}-header`}>
              {headerGroups.map((group) => (
                <div
                  {...group.getHeaderGroupProps()}
                  data-cy={`${dataCy}-header-row`}
                  key={group.id}
                >
                  {group.headers.map((column, index) => {
                    const {
                      canSort,
                      parent,
                      columns,
                      align,
                      isSorted,
                      isSortedDesc,
                      isResizable,
                      placeholderOf,
                      getResizerProps,
                      isEditable,
                      isDisabled,
                      columnAction,
                    } = column

                    const canShowColumnAction =
                      hasColumnAction &&
                      !(onSwapColumns !== undefined && isEditable)

                    const columnHeader = column.render('Header')

                    const childrenCount = columns?.length
                    const hasSubcolumns = childrenCount && childrenCount > 1
                    const isSubcolumn = !!parent
                    const isPlaceHolder = !!placeholderOf
                    const sortedDirection = isSorted
                      ? isSortedDesc
                        ? 'desc'
                        : 'asc'
                      : undefined

                    return (
                      <div
                        {...column.getHeaderProps()}
                        className={cx('group relative', {
                          'flex-none w-min': column.id === 'selection',
                          'bg-coolGray-50':
                            hoveredColumnIndex === index &&
                            canSort &&
                            !hasSubcolumns &&
                            !isPlaceHolder &&
                            !isResizingColumn,
                          'text-center': hasSubcolumns,
                          [columnPadding]: !hasSubcolumns && !isPlaceHolder,
                          'pb-8':
                            tableHasSubColumns &&
                            !isSubcolumn &&
                            !hasSubcolumns &&
                            !isPlaceHolder,
                          'top-3': hasSubcolumns || isSubcolumn,
                          'text-coolGray-400': isDisabled,
                          flex: canShowColumnAction,
                        })}
                        onMouseOver={() => setHoveredColumnIndex(index)}
                        onMouseOut={() => setHoveredColumnIndex(undefined)}
                      >
                        <div
                          {...(canSwapColumns || isDisabled
                            ? {}
                            : column.getSortByToggleProps())}
                          className={cx(
                            'whitespace-nowrap font-bold flex items-center',
                            {
                              'justify-center': align === 'center',
                              'justify-end': align === 'right',
                              'relative top-3 z-10': hasSubcolumns,
                              'min-w-0': hasColumnAction,
                            },
                            column.className
                          )}
                          data-cy={`${dataCy}-header-cell`}
                        >
                          {canSort && (isSubcolumn || align === 'right') && (
                            <SortIcon
                              className={cx('mr-2', {
                                hidden: isDisabled,
                              })}
                              sortedDirection={sortedDirection}
                            />
                          )}
                          {canSwapColumns && isEditable ? (
                            <div className="relative w-full">
                              <Tooltip overlay={`Swap ${columnHeader}`}>
                                <Menu
                                  menuClassName="cursor-pointer"
                                  menuButton={({ open }) => (
                                    <MenuButton
                                      className={cx(
                                        'hover:bg-coolGray-100 whitespace-nowrap w-full',
                                        'font-bold py-2 absolute left-0 outline-none px-0',
                                        {
                                          'text-maroon-300 bg-coolGray-100':
                                            open,
                                          '-top-2': !hasColumnAction,
                                          'text-right': align === 'right',
                                          'text-center': align === 'center',
                                        }
                                      )}
                                    >
                                      {columnHeader}
                                    </MenuButton>
                                  )}
                                >
                                  {menuOptions(column.id)}
                                </Menu>
                              </Tooltip>
                            </div>
                          ) : (
                            <>
                              <div
                                className={
                                  column.id !== 'selection'
                                    ? 'truncate overflow-hidden inline-block'
                                    : ''
                                }
                                title={
                                  typeof columnHeader === 'string'
                                    ? columnHeader
                                    : undefined
                                }
                                style={{
                                  ...(!isDisabled && { maxWidth: '80%' }),
                                }}
                              >
                                {columnHeader}
                              </div>
                              {column.canFilter ? (
                                <span className="ml-2">
                                  {column.render('Filter')}
                                </span>
                              ) : null}
                            </>
                          )}
                          {canShowColumnAction && columnAction}
                          {canSort && !isSubcolumn && align !== 'right' && (
                            <SortIcon
                              className={cx('ml-2', {
                                hidden: column.isDisabled,
                              })}
                              sortedDirection={sortedDirection}
                            />
                          )}
                        </div>
                        {isResizable && <Resizer {...getResizerProps()} />}
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          )}

          <div {...getTableBodyProps()} data-cy={`${dataCy}-body`}>
            {extendedPage.map((row, rowIndex) => {
              if (row.isGrouped) {
                return (
                  <GroupRows<T>
                    key={row.id}
                    onRowClick={onRowClick}
                    dataCy={dataCy}
                    rowClassName={rowClassName}
                    isResizingColumn={isResizingColumn}
                    isHeaderHidden={isHeaderHidden}
                    hoveredColumnIndex={hoveredColumnIndex}
                    rowIndex={rowIndex}
                    row={row}
                    rows={rows}
                    depth={row.depth}
                    prepareRow={prepareRow}
                  />
                )
              }

              prepareRow(row)
              return (
                <DefaultRow<T>
                  onRowClick={onRowClick}
                  dataCy={dataCy}
                  rowClassName={rowClassName}
                  isResizingColumn={isResizingColumn}
                  isHeaderHidden={isHeaderHidden}
                  hoveredColumnIndex={hoveredColumnIndex}
                  rowIndex={rowIndex}
                  row={row}
                  rows={rows}
                  depth={row.depth}
                  disableSubRowArrow={disableSubRowArrow}
                  generateRowClassName={generateRowClassName}
                />
              )
            })}
          </div>

          {showFooter && (
            <div>
              {footerGroups.map((group) => (
                <div {...group.getFooterGroupProps()} key={group.id}>
                  {group.headers.map((column, index) => {
                    const { isResizable, getResizerProps, getFooterProps } =
                      column

                    return (
                      <div
                        {...getFooterProps()}
                        className={cx(
                          'flex relative border-t-2 border-coolGray-200 font-bold',
                          styles.common,
                          {
                            'justify-end': column.align === 'right',
                            'bg-coolGray-50':
                              hoveredColumnIndex === index && !isResizingColumn,
                          }
                        )}
                        key={column.id}
                      >
                        {column.render('Footer')}
                        {isResizable && <Resizer {...getResizerProps()} />}
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {!disablePagination && (
        <Pagination
          pageSize={pageSize}
          pageIndex={pageIndex}
          setPageSize={setPageSize}
          pageCount={pageCount}
          totalRowCount={rows.length}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
        />
      )}
    </>
  )
}

export type { CellProps, Column, CellValue, Row, TableInstance }
export default memo(forwardRef(Table))

function DefaultColumnFilter() {
  return null
}
