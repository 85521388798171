import { useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import { getTags } from 'common/api/tagsApi'
import Button from 'common/components/button'
import HighlightText from 'common/components/HighlightText'
import { CellProps, Column, OnCellChange } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { TableData } from 'common/components/table/Table'
import Tags from 'common/components/tags'
import { errorToast, successToast } from 'common/components/toastNotification'
import TableWidget from 'common/components/widget/tableWidget'
import { stringToNumber } from 'common/helpers/numeric'
import { useAppSelector } from 'common/hooks/redux'
import { useFocusEditableCell } from 'common/hooks/table'
import { Tag } from 'common/interfaces/tags'
import { Phrase } from 'features/content/api/interfaces'
import {
  useAddTagToPhraseMutation,
  useCreatePhraseMutation,
  useCreateTagMutation,
  useRemoveTagMutation,
  useUpdatePhraseMutation,
} from 'features/content/api/mutations'
import useBulkDeletePhraseMutation from 'features/content/api/mutations/useBulkDeletePhraseMutation'
import useGetPhrasesQuery from 'features/content/api/queries/useGetPhrasesQuery'
import { contentKeys } from 'features/content/api/queryKeys'
import useGetReplacersQuery from 'features/content/queries/useGetConfiguredReplacersQuery'

import DateRange from './cells/DateRange'
import FrequencySelect from './cells/FrequencySelect'
import PhraseCell from './cells/PhraseCell'
import PhrasesTableFilter, { getCanSearch } from './filters/PhrasesTableFilter'
import AddPhraseButton from './AddPhraseButton'
import AddPhrasesModal from './AddPhrasesModal'
import DeletePhraseModal from './DeletePhrasesModal'
import MovePhrasesModal from './MovePhrasesModal'

type Props = {
  selectedNodeId: string
  rootNodeId: string
}

const PhrasesTable = ({ selectedNodeId, rootNodeId }: Props) => {
  const queryClient = useQueryClient()
  const {
    TextEditableCellWithFocus,
    editableCellFocus,
    editableCellLoseFocus,
  } = useFocusEditableCell({ EditableCell: PhraseCell })
  const userId = useAppSelector((state) => state.authStates.user_id)
  const createPhraseMutation = useCreatePhraseMutation(selectedNodeId)
  const updatePhraseMutation = useUpdatePhraseMutation(selectedNodeId)
  const createTagMutation = useCreateTagMutation()
  const addTagToPhraseMutation = useAddTagToPhraseMutation(selectedNodeId)
  const removeTagMutation = useRemoveTagMutation(selectedNodeId)
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const [isAddPhrasesModalOpen, setIsAddPhrasesModalOpen] = useState(false)
  const highlightedPhraseId = useAppSelector(
    (state) => state.nodes.highlightedPhraseId
  )
  const [isDeletePhrasesModalOpen, setIsDeletePhrasesModalOpen] =
    useState(false)
  const [isMovePhrasesModalOpen, setIsMovePhrasesModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<Phrase[]>([])

  const deletePhrasesBulkMutation = useBulkDeletePhraseMutation(selectedNodeId)

  const handleSelectRow = (rows) => {
    if (!isEqual(rows, selectedRows)) {
      setSelectedRows(rows)
    }
  }

  const { replacers } = useGetReplacersQuery()

  const { data: tags } = useQuery<Tag[]>(contentKeys.tags({ rootNodeId }), () =>
    getTags({ rootNodeId })
  )
  const { isLoading, error, data: phrases } = useGetPhrasesQuery(selectedNodeId)

  const canSearch = getCanSearch(searchValue)

  const handleDeletePhrases = async (phrasesToDelete) => {
    const errorList: string[] = []
    const phrasesIdsToDelete = phrasesToDelete.map((phrase) => phrase.phraseId)

    if (phrasesIdsToDelete.length > 0) {
      deletePhrasesBulkMutation.mutate(
        {
          userId,
          phraseIds: phrasesIdsToDelete,
        },
        {
          onSuccess: (results) => {
            results.forEach((result, index) => {
              if (result.status === 'rejected') {
                errorList.push(String(phrasesIdsToDelete[index]))
              }
            })

            if (errorList.length > 0) {
              if (errorList.length === 1) {
                errorToast('Failed to delete 1 phrase.')
              } else {
                errorToast(`Failed to delete ${errorList.length} phrases.`)
              }
            } else {
              successToast(
                phrasesIdsToDelete.length > 1
                  ? `${phrasesIdsToDelete.length} Phrases deleted successfully`
                  : 'Phrase deleted successfully'
              )
            }
          },
        }
      )
    }
  }

  const columns: Column<Phrase>[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'phraseId',
        width: 60,
      },
      {
        Header: 'Phrase',
        accessor: 'phrase',
        shouldOverflow: true,
        minWidth: 300,
        Cell: (
          props: React.PropsWithChildren<CellProps<Phrase, string>> & {
            onCellChange: OnCellChange
          }
        ) => {
          const replacersObject = { replacers }
          const value = props.cell.value
          let parsedValue = <>{value}</>
          if (canSearch && searchValue) {
            parsedValue = (
              <HighlightText text={value} searchValue={searchValue} />
            )
          }

          return (
            <TextEditableCellWithFocus
              {...props}
              {...replacersObject}
              parsedValue={parsedValue}
            />
          )
        },
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        shouldOverflow: true,
        width: 200,
        Cell: ({ value, row }) => (
          <BaseCell className="py-3">
            <Tags
              data-cy="phrases-table-tags"
              isFreeText
              isInsideTable={true}
              hasTagButtonLabel
              onRemoveClick={(value) => {
                const tagId = stringToNumber(value)
                if (tagId) {
                  removeTagMutation.mutate({
                    tagId,
                    phraseId: row.original.phraseId,
                    userId,
                  })
                }
              }}
              onAddClick={(value) => {
                const tag = tags?.find((tag) => tag.tag === value)
                if (tag) {
                  addTagToPhraseMutation.mutate({
                    tag,
                    phraseId: row.original.phraseId,
                    userId,
                  })
                } else {
                  createTagMutation.mutate(
                    {
                      tag: value,
                      userId,
                    },
                    {
                      onSuccess: (tag) => {
                        addTagToPhraseMutation.mutate({
                          tag,
                          phraseId: row.original.phraseId,
                          userId,
                        })
                      },
                    }
                  )
                }
              }}
              suggestions={tags?.map((tag) => tag.tag)}
              validations={[
                {
                  validate: (value?: string) => !!value?.includes(' '),
                  message:
                    'Please use underscores in place of spaces (e.g. A_B).',
                },
              ]}
              tags={
                value?.map((tag) => ({
                  label: tag.tag,
                  value: tag.tagId.toString(),
                })) || []
              }
            />
          </BaseCell>
        ),
      },
      {
        Header: 'Frequency',
        accessor: 'frequency',
        minWidth: 110,
        Cell: ({ value, row }) => (
          <BaseCell className="flex items-center w-26">
            <FrequencySelect
              className="w-full sm:w-60 font-medium"
              isInsideTable={true}
              value={value}
              onChange={(val) => {
                updatePhraseMutation.mutate({
                  replacers,
                  userId,
                  phraseId: row.original.phraseId,
                  updatedPhrase: {
                    ...row.original,
                    frequency: val?.value || '0',
                  },
                })
              }}
            />
          </BaseCell>
        ),
      },
      {
        Header: 'Date range',
        accessor: 'dateRange',
        Cell: DateRange,
      },
    ],
    [
      TextEditableCellWithFocus,
      addTagToPhraseMutation,
      canSearch,
      createTagMutation,
      removeTagMutation,
      replacers,
      searchValue,
      tags,
      updatePhraseMutation,
      userId,
    ]
  )

  const wordMatches = useMemo(() => new Set<string>(), [])

  const filteredPhrases: (Phrase & TableData)[] | undefined = useMemo(() => {
    if (!searchValue || !canSearch) {
      return phrases
    }

    const searchValueLower = searchValue.trim().toLowerCase()

    return phrases?.filter((phrase) => {
      const phraseLower = phrase.phrase.toLowerCase()
      const phraseWords = phrase.phrase.split(' ')

      let isMatch = false
      for (const word of phraseWords) {
        if (word.toLowerCase().includes(searchValueLower)) {
          wordMatches.add(word.replace(/[{}().,?!'"]/g, ''))
          isMatch = true
        }

        if (phraseLower.includes(searchValueLower)) {
          isMatch = true
        }
      }

      if (phrase.tags) {
        for (const { tag } of phrase.tags) {
          const tagWords = tag.split(' ')

          for (const word of tagWords) {
            if (word.toLowerCase().includes(searchValueLower)) {
              wordMatches.add(word.replace(/[{}().,?!'"]/g, ''))
              isMatch = true
            }
          }
        }
      }
      return isMatch
    })
  }, [canSearch, phrases, searchValue, wordMatches])

  useEffect(() => {
    if (filteredPhrases && highlightedPhraseId) {
      const timeoutId = setTimeout(() => {
        const highlightedDiv = document.querySelector(
          '[data-highlighted="true"]'
        ) as HTMLElement
        highlightedDiv?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 300)
      return () => clearTimeout(timeoutId)
    }
  }, [filteredPhrases, highlightedPhraseId])

  let pageIndex = 0
  if (filteredPhrases && highlightedPhraseId) {
    filteredPhrases.forEach((phrase, index) => {
      if (phrase.phraseId === highlightedPhraseId) {
        pageIndex = Math.floor(index / 50)
        phrase.isHighlighted = true
      }
    })
  }

  return (
    <>
      <TableWidget.Widget<Phrase>
        selectedRowsActionButtons={
          selectedRows.length > 0 ? (
            <div style={{ minHeight: '52px' }}>
              <Button
                className="text-xl"
                variant="link"
                onClick={() => {
                  setIsMovePhrasesModalOpen(true)
                }}
              >
                Move phrases to...
              </Button>
              <Button
                className="text-xl ml-8"
                variant="link"
                onClick={() => setIsDeletePhrasesModalOpen(true)}
              >
                Delete rows
              </Button>
            </div>
          ) : undefined
        }
        firstUseText="No phrases found for the selected node."
        columns={columns}
        data={filteredPhrases}
        initialState={{ pageSize: 50, pageIndex }}
        rowSelection="multiple"
        onSelectRow={handleSelectRow}
        isLoading={isLoading}
        hasError={!!error}
        autoResetPage={false}
        onCellChange={({ rowIndex, columnId, value }) => {
          if (columnId === 'phrase' || columnId === 'dateRange') {
            const phrase = filteredPhrases?.[rowIndex]

            if (phrase && value !== phrase.phrase && phrase?.phraseId) {
              updatePhraseMutation.mutate({
                userId,
                phraseId: phrase.phraseId,
                replacers,
                updatedPhrase: {
                  ...phrase,
                  ...(columnId === 'phrase'
                    ? { phrase: value as string }
                    : { dateRange: value as string }),
                },
              })
            } else if (phrase && phrase?.phraseId === 0) {
              const hasValue = !isEmpty((value as string)?.trim())
              if (hasValue) {
                createPhraseMutation.mutate({
                  userId,
                  phrase: {
                    ...phrase,
                    phrase: value as string,
                  },
                  nodeId: Number(selectedNodeId),
                  replacers,
                })
              } else {
                queryClient.invalidateQueries(
                  contentKeys.phrases(selectedNodeId)
                )
              }
              editableCellLoseFocus()
            }
          }
        }}
        disablePagination={canSearch}
      >
        <TableWidget.Header
          title="Phrases"
          subtitle={
            selectedRows.length > 0
              ? undefined
              : 'Here you can view and edit phrase within the selected node.'
          }
        >
          <AddPhraseButton
            selectedNodeId={selectedNodeId}
            editableCellFocus={editableCellFocus}
            showAddModal={() => setIsAddPhrasesModalOpen(true)}
          />
          <PhrasesTableFilter
            searchValue={searchValue}
            onSearch={setSearchValue}
            matches={wordMatches}
          />
        </TableWidget.Header>
      </TableWidget.Widget>
      <AddPhrasesModal
        key={`${isAddPhrasesModalOpen}`}
        isOpen={isAddPhrasesModalOpen}
        selectedNodeId={selectedNodeId}
        close={() => setIsAddPhrasesModalOpen(false)}
        tags={tags ?? []}
      />
      <DeletePhraseModal
        isOpen={isDeletePhrasesModalOpen}
        onClose={() => {
          setIsDeletePhrasesModalOpen(false)
        }}
        onConfirm={() => {
          handleDeletePhrases(selectedRows)
          setIsDeletePhrasesModalOpen(false)
        }}
      />
      <MovePhrasesModal
        isOpen={isMovePhrasesModalOpen}
        onClose={() => setIsMovePhrasesModalOpen(false)}
        onDelete={handleDeletePhrases}
        currentNodeId={selectedNodeId}
        selectedRows={selectedRows}
      />
    </>
  )
}

export default PhrasesTable
