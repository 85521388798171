import { Fragment } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/futurama/api/interfaces'
import { workflowCardClicked } from 'features/futurama/store/contentSlice'

import { START_HERE_COMPONENT_ID } from '../../content/Content'
import { getElementHasVariants } from '../../helpers'
import { useContentOrTemplate } from '../../hooks'

import AddWorkflowCardButton from './AddWorkflowCardButton'
import VerticalLine from './VerticalLine'
import WorkflowCard from './WorkflowCard'
import WorkflowTemplateCard from './WorkflowTemplateCard'

const WorkflowContent = () => {
  const dispatch = useAppDispatch()

  const { template, content } = useContentOrTemplate()
  const elements = template?.elements || content?.elements

  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const areElementsDisabled = useAppSelector(
    (state) => state.content.areElementsDisabled
  )

  const hasTemplate = !!template
  const areComponentsConfigurable =
    content?.elements && content.elements.length > 1

  return (
    <>
      <WorkflowCard
        title="Start here"
        description={content?.name}
        icon="startFlag"
        isSelected={selectedElementId === START_HERE_COMPONENT_ID}
        onClick={() =>
          dispatch(workflowCardClicked({ elementId: START_HERE_COMPONENT_ID }))
        }
      />
      <VerticalLine />
      {elements &&
        elements.map((element) => {
          if (isComponentElement(element)) {
            const { element_id, name, display_name, icon, status } = element
            const isElementDisabled =
              hasTemplate || areElementsDisabled || status === 'disabled'

            return (
              <Fragment key={element_id}>
                <WorkflowCard
                  element={element}
                  title={display_name || name}
                  icon={icon}
                  isDisabled={isElementDisabled}
                  isSelected={
                    element_id === selectedElementId && !isElementDisabled
                  }
                  isConfigurable={areComponentsConfigurable}
                  onClick={() =>
                    !isElementDisabled &&
                    dispatch(
                      workflowCardClicked({
                        elementId: element_id,
                        showComponentConfiguration:
                          !getElementHasVariants(element),
                      })
                    )
                  }
                />
                <VerticalLine />
              </Fragment>
            )
          } else if (isTemplateElement(element)) {
            return (
              <Fragment key={element.element_id}>
                <WorkflowTemplateCard
                  key={element.element_id}
                  element={element}
                  isConfigurable={areComponentsConfigurable}
                />
                <VerticalLine />
              </Fragment>
            )
          }

          return null
        })}
      <AddWorkflowCardButton isDisabled={hasTemplate || areElementsDisabled} />
    </>
  )
}

export default WorkflowContent
