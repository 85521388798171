import { TabbedNavRoute } from 'app/navigation/interfaces'

import SubNavBar, {
  SubNavItem,
  SubNavProps,
} from 'common/components/navigation/subNavBar'

type Props = {
  subroutes: TabbedNavRoute[]
} & SubNavProps

const LanguageSubNav = ({ subroutes, ...rest }: Props) => {
  return (
    <SubNavBar {...rest}>
      {subroutes.map(({ name, to }) => (
        <SubNavItem key={name} title={name} to={to} />
      ))}
    </SubNavBar>
  )
}

export default LanguageSubNav
