import { FC } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { projectSettingsPath } from 'app/navigation/paths'
import cx from 'classnames'

import DistributionChannel from 'common/components/DistributionChannel'
import { formatDate } from 'common/helpers/date'
import { useAppSelector } from 'common/hooks/redux'
import { CreatedDate, Sent } from 'common/icons'

import { Project } from '../../interface'

type Props = {
  data: Project
  isSelected: boolean
}

const Link: FC<Props> = ({ data, isSelected }) => {
  const {
    id,
    distributionChannel,
    distributionType,
    name,
    created,
    lastUpdated,
  } = data

  const { accountId } = useAppSelector((state) => state.authStates)

  return (
    <RouterLink
      data-cy="projects-nav-item"
      key={id}
      to={generatePath(projectSettingsPath, {
        accountId,
        projectId: id,
      })}
      className={cx(
        'block group px-6 py-4 border-b border-coolGray-200 hover:bg-maroon-50',
        {
          'bg-maroon-50 hover:bg-maroon-50 border-maroon-500': isSelected,
        }
      )}
    >
      <div className="pb-1 text-sm text-coolGray-400 capitalize">
        <DistributionChannel
          data-cy="projects-nav-item-channel"
          value={distributionChannel}
        />
        {distributionType && (
          <span data-cy="projects-nav-item-distribution-type">
            {` / ${distributionType}`}
          </span>
        )}
      </div>

      <div
        data-cy="project-nav-item-name"
        className={cx('mr-2 mb-2 text-base tracking-tight', {
          'text-coolGray-800': !isSelected,
          'text-coolGray-800 group-hover:text-coolGray-800': isSelected,
        })}
      >
        {name}
      </div>

      <div className="flex items-center text-xs text-coolGray-400">
        <div className="flex items-center">
          <CreatedDate size={4} />
          <span data-cy="project-nav-created-date" className="ml-2">
            {formatDate(created)}
          </span>
        </div>
        <div className="flex items-center ml-4">
          <Sent size={4} />
          <span data-cy="project-nav-last-updated-date" className="ml-2">
            {formatDate(lastUpdated)}
          </span>
        </div>
      </div>
    </RouterLink>
  )
}

export default Link
