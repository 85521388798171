import { useForm, useFormState } from 'react-final-form'

import Button from 'common/components/button'
import DynamicFields from 'common/components/dynamicFields'
import { FieldsConfiguration } from 'common/components/dynamicFields/interfaces'
import { ArrowRight as ArrowRightIcon } from 'common/icons'

import BrandVoiceSettings from './brandVoiceSettings'
import { PageState } from './BriefPage'

type Props = {
  isDisabled: boolean
  isLoading: boolean
  fieldsConfiguration: FieldsConfiguration
  pageState: PageState
  brandVoiceId?: string
}

const DynamicForm = ({
  isDisabled,
  fieldsConfiguration,
  isLoading,
  pageState,
  brandVoiceId,
}: Props) => {
  const form = useForm()
  const formState = useFormState()

  return (
    <>
      <div className="w-150">
        <DynamicFields
          fieldsConfiguration={fieldsConfiguration}
          isDisabled={isDisabled}
        />
        {brandVoiceId && <BrandVoiceSettings />}
      </div>
      {pageState === 'generateBrief' && (
        <div className="flex pt-6 justify-end items-center">
          <Button
            type="submit"
            data-cy="content-generate-brief-button"
            variant="primary"
            disabled={formState.invalid || isLoading}
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
            loading={isLoading}
            onClick={() => {
              form.change('submitAction', 'generateBrief')
            }}
          >
            Generate brief
          </Button>
        </div>
      )}
      {(pageState === 'formChangedWithBrief' ||
        pageState === 'formChangedWithTopics') && (
        <div className="flex pt-6 justify-end items-center">
          <Button
            ghost
            className="mr-4"
            disabled={isLoading}
            onClick={() => form?.reset()}
          >
            Revert
          </Button>
          <Button
            type="submit"
            data-cy="content-refresh-brief-button"
            variant="primary"
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
            loading={isLoading}
            disabled={formState.invalid || isLoading}
            onClick={() => {
              form.change('submitAction', 'refreshBrief')
            }}
          >
            Refresh brief
          </Button>
        </div>
      )}
      {pageState === 'generateWithoutTopics' && (
        <div className="flex pt-6 justify-end items-center">
          <Button
            type="submit"
            data-cy="content-generate-brief-button"
            variant="primary"
            disabled={formState.invalid || isLoading}
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
            loading={isLoading}
            onClick={() => {
              form.change('submitAction', 'generateWithoutTopics')
            }}
          >
            Generate
          </Button>
        </div>
      )}
      {pageState === 'formChangedWithoutTopics' && (
        <div className="flex pt-6 justify-end items-center">
          <Button
            ghost
            className="mr-4"
            disabled={isLoading}
            onClick={() => form?.reset()}
          >
            Revert
          </Button>
          <Button
            type="submit"
            data-cy="content-regenerate-variants-button"
            variant="primary"
            loading={isLoading}
            disabled={formState.invalid || isLoading}
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
            onClick={() => {
              form.change('submitAction', 'generateWithoutTopics')
            }}
          >
            Regenerate
          </Button>
        </div>
      )}
      {pageState === 'brandVoiceChanged' && (
        <div className="flex pt-6 justify-end items-center">
          <Button
            ghost
            className="mr-4"
            disabled={isLoading}
            onClick={() => form?.reset()}
          >
            Revert
          </Button>
          <Button
            type="submit"
            data-cy="content-regenerate-button"
            variant="primary"
            loading={isLoading}
            disabled={formState.invalid || isLoading}
            onClick={() => {
              form.change('submitAction', 'brandVoiceChanged')
            }}
          >
            Regenerate
          </Button>
        </div>
      )}
    </>
  )
}

export default DynamicForm
