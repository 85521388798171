import { useState } from 'react'
import { isInclusivelyBeforeDay } from 'react-dates'
import moment from 'moment'

import { TreeNode, TypedEntryState } from 'common/components/catalog/Catalog'
import DateRange, {
  FocusedInputType,
  isInclusivelyAfterDay,
} from 'common/components/DateRange'

export type SelectedDateRange = {
  value: { startDate: string; endDate: string }
}

type Props = {
  depth: number
  items: TreeNode | string[]
  selectedPath: string[]
  typedEntryState: TypedEntryState<string>
  onValueChange: ({ value }: { value: string[] }) => void
}

const CustomerAttributesDateRangePicker = ({
  depth,
  items,
  selectedPath,
  onValueChange,
  typedEntryState,
}: Props) => {
  const {
    value: [startDate, endDate],
  } = typedEntryState[selectedPath[depth]]

  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    items[selectedPath[depth]]

  const [focusedInput, setFocusedInput] =
    useState<FocusedInputType | null>(null)

  return (
    <div className="pb-13 min-w-60">
      <div className="p-4 w-full">
        <DateRange
          label="Date range"
          startDate={moment(startDate)}
          endDate={moment(endDate)}
          startDateId="customer-attribute-start-date"
          endDateId="customer-attribute-end-date"
          onDatesChange={({ startDate: newStartDate, endDate: newEndDate }) => {
            newStartDate &&
              newEndDate &&
              onValueChange({
                value: [newStartDate.toISOString(), newEndDate.toISOString()],
              })
          }}
          displayFormat={() => 'MMM DD YYYY'}
          maxDate={moment()}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          isOutsideRange={(day) =>
            isInclusivelyBeforeDay(
              day,
              moment(defaultStartDate).subtract(1, 'days')
            ) ||
            isInclusivelyAfterDay(day, moment(defaultEndDate).add(1, 'days'))
          }
          onOutsideClick={() =>
            onValueChange({
              value: [startDate, endDate],
            })
          }
        />
      </div>
    </div>
  )
}

export default CustomerAttributesDateRangePicker
