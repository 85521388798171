import { useMemo } from 'react'
import { IntegrationAccount } from '@phrasee/phrasee-typings'

import Avatar from 'common/components/Avatar'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate } from 'common/helpers/formatDate'
import { avatarColors } from 'features/profile/AvatarColorPicker'

type Props = { accounts: IntegrationAccount[] }

const AccountsList = ({ accounts }: Props) => {
  const columns: Column<IntegrationAccount>[] = useMemo(
    () => [
      {
        Header: 'Provider',
        accessor: 'provider',
        Cell: ({ value }) => (
          <BaseCell>
            <span className="capitalize-first">{value}</span>
          </BaseCell>
        ),
        width: 400,
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ value }) => <BaseCell>{formatDate(value)}</BaseCell>,
        align: 'right',
        width: 100,
      },
      {
        accessor: 'userName',
        Cell: ({ value, row }) => {
          const [firstName, lastName] = value ? value.split(' ') : []

          return (
            <BaseCell>
              <Avatar
                firstName={firstName}
                lastName={lastName}
                className="text-xs w-7 h-7"
                color={avatarColors[row.index % avatarColors.length]}
              />
            </BaseCell>
          )
        },
        align: 'left',
        width: 80,
      },
    ],
    []
  )

  return <TableWidget.Widget columns={columns} data={accounts} />
}

export default AccountsList
