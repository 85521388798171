import { useRouteMatch } from 'react-router'

import PersonalizationReport from '../../../common/components/personalizationReport/PersonalizationReport'

const PersonalizationReportWithParam = () => {
  const match = useRouteMatch<{ id: string }>()
  const personalizationId = match.params.id

  return (
    <PersonalizationReport
      personalizationId={personalizationId}
      mode="report"
    />
  )
}

export default PersonalizationReportWithParam
