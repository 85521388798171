import includes from 'lodash/includes'

import { VARIANTS_CATEGORIES } from 'features/unifiedFlow/contentPage/generate/constants'

import InsightsCard from './InsightsCard'

const Exploration = () => {
  return (
    <InsightsCard
      variant="highlighted"
      hasOutline={true}
      title="Exploration"
      content={
        <>
          By testing new and diverse language variants and features you’re more
          likely to find high-performing language.
        </>
      }
    />
  )
}

const Performance = () => {
  return (
    <InsightsCard
      variant="highlighted"
      hasOutline={true}
      title="Performance"
      content={
        <div className="flex items-end">
          <p className="text-xs">
            <span>In</span> <b className="text-sm">64%</b>
            <span>
              {' '}
              of experiments the winning variant is within the lines predicted
              to perform well.
            </span>
          </p>
        </div>
      }
    />
  )
}

const Baseline = () => {
  return (
    <InsightsCard
      hasOutline={true}
      title="Baseline"
      content={
        <>
          To avoid confirmation bias it’s important to retest variants and
          features that have performed sub-optimally in previous experiments.
        </>
      }
    />
  )
}

const UserModified = () => {
  return (
    <InsightsCard
      variant="default"
      hasOutline={true}
      title="Notification"
      className="border-orange-600 bg-orange-50"
      content={
        <div className="flex items-end">
          <p className="text-xs">
            Edits can affect the predicted performance of a variant. Experiments
            are designed to minimise bias and maximise learnings.
          </p>
        </div>
      }
    />
  )
}

interface Props {
  category?: string
}

const InsightsCategories = ({ category }: Props) => {
  const getState = () => {
    if (category && includes(Object.values(VARIANTS_CATEGORIES), category)) {
      return category
    } else {
      return 'all'
    }
  }

  const state = getState()

  return (
    <>
      {
        {
          [VARIANTS_CATEGORIES.PERFORMANCE]: <Performance />,
          [VARIANTS_CATEGORIES.EXPLORATION]: <Exploration />,
          [VARIANTS_CATEGORIES.BASELINE]: <Baseline />,
          [VARIANTS_CATEGORIES.USER_MODIFIED]: <UserModified />,
          all: (
            <>
              <Performance />
              <Exploration />
              <Baseline />
            </>
          ),
        }[state]
      }
    </>
  )
}

export default InsightsCategories
