import { useState } from 'react'
import { Field } from 'react-final-form'
import helpers, { CAMPAIGN_NAME_LIMIT } from 'workflow/utils/helpers'

import FormItem from 'common/components/FormItem'
import Input from 'common/components/Input'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'

import './DatePickerStyles.css'

const validateName = (value: string | undefined) => {
  if (!value?.trim()) {
    return 'Enter a name for your experiment.'
  }
}

type Props = {
  name: string
  initialValue?: string
  isDisabled?: boolean
}

const ExperimentName = ({ name, initialValue, isDisabled }: Props) => {
  const [error, setError] = useState<string | undefined>()
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

  return (
    <Field<string>
      initialValue={initialValue}
      name={name}
      validate={validateName}
      parse={(value) => {
        const strippedValue = stripForbiddenCharacters(value)
        const { isValid, validationError } =
          helpers.validateCampaignName(strippedValue)

        if (isValid) {
          setError(undefined)
          return strippedValue
        } else {
          setError(validationError)
          return strippedValue.slice(0, -1)
        }
      }}
    >
      {({ input, meta }) => (
        <FormItem
          error={meta.touched ? meta.error || error : undefined}
          htmlFor={input.name}
          label="Experiment name"
          className="flex-none mb-4"
        >
          <Input
            data-cy="edit-experimentName-input"
            type="text"
            variant="default"
            className="w-full focus:bg-maroon-50"
            value={input.value}
            onChange={input.onChange}
            onBlur={(e) => {
              setIsInputFocused(false)
              input.onBlur(e)
            }}
            onFocus={() => setIsInputFocused(true)}
            name={input.name}
            id={input.name}
            placeholder="Give your experiment a memorable name"
            maxLength={CAMPAIGN_NAME_LIMIT}
            disabled={isDisabled}
          />
          {isInputFocused && (
            <p className="h-4 text-coolGray-400 mt-2">
              {input.value.length} / {CAMPAIGN_NAME_LIMIT}
            </p>
          )}
        </FormItem>
      )}
    </Field>
  )
}

export default ExperimentName
