import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'

import type { IncrementalEventsData } from 'common/components/IncrementalEventsGraph'
import type { UpliftData } from 'common/components/UpliftGraph'

import { GraphDataResponse } from '../interfaces'

type GraphData = IncrementalEventsData | UpliftData

const mapUpliftResponse = <T extends GraphData>(
  upliftDataResponse: GraphDataResponse
): T[] => {
  return upliftDataResponse.data.map((dataGroup) => {
    return {
      name: dataGroup.name,
      average: dataGroup.data.length > 0 ? dataGroup.data[0].average : 0,
      data: dataGroup.data.length > 0 ? dataGroup.data[0].data : [],
    }
  }) as T[]
}

export default function useFetchGraphData<T extends GraphData>(
  fetchFunc: () => Promise<AxiosResponse<GraphDataResponse>>
): {
  hasError: boolean
  isLoading: boolean
  data: T[]
  xAxisLabels: string[]
} {
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<T[]>([])
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([])

  useEffect(() => {
    ;(async function () {
      setHasError(false)
      setIsLoading(true)
      try {
        const response = await fetchFunc()
        setData(mapUpliftResponse(response.data))
        setXAxisLabels(response.data.xAxis.categories)
      } catch {
        setHasError(true)
      }
      setIsLoading(false)
    })()
  }, [fetchFunc])

  return { hasError, isLoading, data, xAxisLabels }
}
