import { createSlice, PayloadAction } from '@reduxjs/toolkit'
const initialState = {
  flags: {},
}

export const ldReducer = createSlice({
  name: 'launchDarklyFlags',
  initialState,
  reducers: {
    setFlags: (state, action: PayloadAction<any>) => {
      state.flags = action.payload
    },
  },
})

export const { setFlags } = ldReducer.actions
export default ldReducer.reducer
