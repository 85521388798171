import { useQuery } from '@tanstack/react-query'

import { getReplacers } from '../api'
import { contentKeys } from '../api/queryKeys'

const useGetReplacersQuery = () => {
  const { data, status } = useQuery(contentKeys.replacers(), getReplacers, {
    staleTime: 20 * 60 * 1000,
  })

  return {
    replacers: data,
    status,
  }
}

export default useGetReplacersQuery
