import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'

import { TreeNode } from 'common/components/catalog/Catalog'
import { useAppSelector } from 'common/hooks/redux'

import { getProductCatalog } from '../api'
import { keys } from '../queryKeys'

export type ProductCatalog = {
  id: string
  name: string
  created: string
  categories: any
  type: string
  status: 'processing' | 'success' | 'failed' | undefined
}
function hasChildrenWithEmptyValues(node: Object) {
  return Object.values(node).every((value) => {
    if (typeof value === 'object') {
      return isEmpty(value)
    }
    return false
  })
}

export function mapObjectToTreeNode(object: Object): TreeNode {
  return Object.entries(object)
    .sort((a, b) => a[0].localeCompare(b[0], 'en', { numeric: true }))
    .reduce((previousValue, [key, value]) => {
      return {
        ...previousValue,
        [key]:
          hasChildrenWithEmptyValues(value) || Object.keys(value).length === 0
            ? Object.keys(value).sort((a, b) =>
                a.localeCompare(b, 'en', { numeric: true })
              )
            : mapObjectToTreeNode(value),
      }
    }, {})
}

const useGetProductCatalogQuery = ({ baseUrl }: { baseUrl: string }) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getProductCatalogQuery = useQuery(
    keys.productCatalog(accountId),
    () => getProductCatalog({ accountId, baseUrl }),
    {
      select: (data) => {
        if (!data) {
          return undefined
        }
        return {
          id: data._id,
          name: data.file_name,
          created: data.created,
          categories: mapObjectToTreeNode(data.categories.productCategories),
          type: data.type,
        }
      },
      enabled: !!accountId,
      initialData: {
        categories: { productCategories: [] },
        _id: '',
        file_name: '',
        created: '',
        type: '',
      },
      retry: false,
      meta: {
        errorMessage: 'The catalog could not be loaded',
      },
    }
  )

  return getProductCatalogQuery
}

export default useGetProductCatalogQuery
