import { StaticRoute } from 'app/navigation/interfaces'
import {
  projectContentPath,
  projectNodesPath,
  projectSettingsPath,
} from 'app/navigation/paths'

import Content from './views/content'
import Nodes from './views/nodes'
import Settings from './views/settings'

type Route = Pick<StaticRoute, 'to' | 'accessFlags'> & {
  title: string
  component: () => JSX.Element | null
}

export const commonRoutes: Route[] = [
  {
    title: 'Settings',
    to: projectSettingsPath,
    component: Settings,
  },
]

export const linguoRoutes: Route[] = [
  {
    title: 'Content',
    to: projectContentPath,
    component: Content,
    accessFlags: {
      flags: ['showAdminContentSettingsPage'],
      type: 'hide',
    },
  },
  {
    title: 'Nodes + rules',
    to: projectNodesPath,
    component: Nodes,
  },
]

export const llmRoutes: Route[] = [
  {
    title: 'Content',
    to: projectContentPath,
    component: Content,
    accessFlags: {
      flags: ['showAdminContentSettingsPage'],
      type: 'hide',
    },
  },
]

export const allRoutes: Route[] = [...commonRoutes, ...linguoRoutes]
