import { useEffect } from 'react'

function updateIntercomForBottomToolbar() {
  if (window.Intercom) {
    window.Intercom('update', {
      vertical_padding: 96, // 32px above the toolbar
    })
  }
}

function updateIntercomDefault() {
  if (window.Intercom) {
    window.Intercom('update', {
      vertical_padding: 32,
    })
  }
}

export function useMoveIntercomButton() {
  useEffect(() => {
    updateIntercomForBottomToolbar()
    return () => updateIntercomDefault()
  }, [])
}
