/* eslint-disable max-lines */
import {
  Content,
  Element,
  SplitCalculationFixedSettings,
  SplitCalculationResponseDto,
  SplitCalculationType,
} from '@phrasee/phrasee-typings'
import {
  ContentCreationDto,
  OptimizeMessageConfiguration,
} from '@phrasee/phrasee-typings/typings/futurama/content_creation'
import { Template } from '@phrasee/phrasee-typings/typings/futurama/template'
import { IntegrationTypeValue } from '@phrasee/phrasee-typings/typings/project/project-configuration.types'
import isArray from 'lodash/isArray'

import { connectInternalApi } from 'common/api'
import { FieldConfiguration } from 'common/components/dynamicFields/interfaces'
import { Node, Replacer, Topic } from 'common/components/topics/interfaces'

import type { TemplateToAdd } from '../store/unifiedFlowSlice'

const TEMPORARY_FIXED_CONTENT_UPDATE_ID = 3

export type SplitCalculatorConfiguration = {
  splitCalculation: {
    version: number
    fields: FieldConfiguration[]
    type: SplitCalculationType
    fixedSplitSettings?: SplitCalculationFixedSettings
  }
  experimentMainFields: FieldConfiguration[]
  integrationType?: IntegrationTypeValue
}

export type ContentLibraryElementResponse = {
  id: string
  name: string
  project: string
  template: string
  campaign_id: string
  experimentType: string
  owner: string
  createdDate: string
  sendDate: string | null
  status: string
}

export type ContentLibraryEntryResponse = {
  id: string
  name: string
  status: string
  createdDate: string
  is_legacy: boolean
  elements: ContentLibraryElementResponse[]
}

type ContentLibraryResponse = ContentLibraryEntryResponse[]

export const getTemplates = async (accountId: string): Promise<Template[]> => {
  const result = await connectInternalApi.get<Template[]>(
    `v1/core/content-api/accounts/${accountId}/templates`
  )
  return result.data
}

export const getContent = async ({
  accountId,
  contentId,
}: {
  accountId: string
  contentId: string
}): Promise<Content> => {
  const result = await connectInternalApi.get<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}`
  )

  return result.data
}

export const createContent = async ({
  accountId,
  payload,
}: {
  accountId: string
  payload: ContentCreationDto
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/`,
    {
      ...payload,
    }
  )

  return result.data
}

export const updateContent = async ({
  contentId,
  accountId,
  userInputForm,
  updatedName,
  forceNer,
}: {
  contentId: string
  accountId: string
  userInputForm: FieldConfiguration[]
  updatedName?: string
  forceNer?: boolean
}) => {
  const result = await connectInternalApi.patch<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}`,
    {
      user_input: { user_input_form: userInputForm },
      ...(updatedName
        ? { name: updatedName, display_name: updatedName }
        : undefined),
    },
    {
      params: {
        ...(forceNer && { force_ner: forceNer }),
      },
    }
  )

  return result.data
}

export const deleteContent = async ({
  accountId,
  contentId,
}: {
  accountId: string
  contentId: string
}) => {
  const result = await connectInternalApi.delete<{
    status: number
    message: string
  }>(`v1/core/content-api/accounts/${accountId}/contents/${contentId}`)

  return {
    id: contentId,
    data: result.data,
  }
}

export const copyContent = async ({
  accountId,
  contentId,
}: {
  accountId: string
  contentId: string
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}`
  )

  return result.data
}

export const updateElement = async ({
  accountId,
  contentId,
  elementId,
  updatedElement,
}: {
  accountId: string
  contentId: string
  elementId: number
  updatedElement: Partial<Element>
}) => {
  const result = await connectInternalApi.patch<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}`,
    { ...updatedElement }
  )

  return result.data
}

export const updateElements = async ({
  accountId,
  contentId,
  templates,
}: {
  accountId: string
  contentId: string
  templates: TemplateToAdd[]
}) => {
  let order = 1
  const templatesPayload: { template_id: string; order: number }[] = []

  templates.forEach((template) => {
    templatesPayload.push({ template_id: template.id, order })
    order++
  })

  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements`,
    { templates: templatesPayload }
  )

  return result.data
}

export const deleteElement = async ({
  accountId,
  contentId,
  elementId,
}: {
  accountId: string
  contentId: string
  elementId: number
}) => {
  const result = await connectInternalApi.delete(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}`
  )

  return result.data
}

export const approveElement = async ({
  accountId,
  content_id,
  element_id,
}: {
  accountId: string
  content_id: string
  element_id: number
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${content_id}/element/${element_id}/approval`
  )

  return result.data
}

export const generateBrief = async ({
  accountId,
  contentId,
  userInputForm,
  wsTopic,
  brandVoiceId,
}: {
  accountId: string
  contentId: string
  userInputForm: FieldConfiguration[]
  wsTopic: string
  brandVoiceId?: string
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/brief`,
    {
      user_input_form: userInputForm,
      brand_voice_id: brandVoiceId,
      wsTopic,
    }
  )

  return result.data
}

export const generateCustomBrief = async ({
  accountId,
  contentId,
  elementId,
  userInputForm,
  wsTopic,
  brandVoiceId,
}: {
  accountId: string
  contentId: string
  elementId: number
  userInputForm: FieldConfiguration[]
  wsTopic: string
  brandVoiceId?: string
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/brief`,
    {
      user_input_form: userInputForm,
      brand_voice_id: brandVoiceId,
      wsTopic,
    }
  )

  return result.data
}

export const generateVariants = async ({
  contentId,
  accountId,
  wsTopic,
  topics,
  userInputForm,
}: {
  contentId: string
  accountId: string
  wsTopic: string
  topics?: Topic[]
  userInputForm?: FieldConfiguration[]
}) => {
  const nodes: Node[] = []
  const replacers: Replacer[] = []

  topics?.forEach((topic) => {
    if (topic.type === 'topic') {
      nodes.push({
        nodeId: topic.original.nodeId,
        node: topic.original.node,
        nodeName: topic.name,
        displayName: topic.name,
        isConstant: topic.isConstant,
      })
    } else {
      replacers.push({
        replacerId: topic.original.replacerId,
        replacer: topic.original.replacer,
        displayName: topic.original.displayName,
        isConstant: topic.isConstant,
        replacerDefinition: {
          ...topic.original.replacerDefinition,
          form_definition: topic.configuration,
        },
        value: isArray(topic.name) ? topic.name.join(';') : topic.name,
      })
    }
  })

  await connectInternalApi.post(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/variants`,
    {
      contentUpdateId: TEMPORARY_FIXED_CONTENT_UPDATE_ID,
      wsTopic,
      ...(nodes.length > 0 && { nodes }),
      ...(replacers.length > 0 && { replacers }),
      user_input_form: userInputForm,
    }
  )
}

export const generateCustomVariants = async ({
  contentId,
  elementId,
  accountId,
  wsTopic,
  topics,
  userInputForm,
  optimizationConfiguration,
}: {
  contentId: string
  elementId: number
  accountId: string
  wsTopic: string
  topics?: Topic[]
  userInputForm?: FieldConfiguration[]
  optimizationConfiguration?: OptimizeMessageConfiguration
}) => {
  const nodes: Node[] = []
  const replacers: Replacer[] = []

  topics?.forEach((topic) => {
    if (topic.type === 'topic') {
      nodes.push({
        nodeId: topic.original.nodeId,
        node: topic.original.node,
        nodeName: topic.name,
        displayName: topic.name,
        isConstant: topic.isConstant,
      })
    } else {
      replacers.push({
        replacerId: topic.original.replacerId,
        replacer: topic.original.replacer,
        displayName: topic.original.displayName,
        isConstant: topic.isConstant,
        replacerDefinition: {
          ...topic.original.replacerDefinition,
          form_definition: topic.configuration,
        },
        value: isArray(topic.name) ? topic.name.join(';') : topic.name,
      })
    }
  })

  await connectInternalApi.post(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants`,
    {
      contentUpdateId: TEMPORARY_FIXED_CONTENT_UPDATE_ID,
      wsTopic,
      ...(nodes.length > 0 && { nodes }),
      ...(replacers.length > 0 && { replacers }),
      optimizationConfiguration,
      user_input_form: userInputForm,
    }
  )
}

export const getSplitCalculatorConfiguration = async ({
  projectId,
}: {
  projectId: string
}): Promise<SplitCalculatorConfiguration> => {
  const result = await connectInternalApi.get<SplitCalculationResponseDto>(
    `v1/optimization/projects/${projectId}/split-calculator/config`
  )

  return {
    splitCalculation: {
      version: result.data.split_calculation.version,
      fields: result.data.split_calculation.fields || [],
      type: result.data.split_calculation.type as SplitCalculationType,
      fixedSplitSettings: result.data.split_calculation.fixed_split_settings,
    },
    experimentMainFields: result.data.experiment_main_fields,
  }
}

export const deleteVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
}): Promise<void> => {
  await connectInternalApi.delete(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`
  )
}

export const generateMoreLikeThisVariants = async ({
  accountId,
  contentId,
  elementId,
  variantId,
  wsTopic,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
  wsTopic: string
}): Promise<void> => {
  await connectInternalApi.put(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`,
    {
      contentUpdateId: TEMPORARY_FIXED_CONTENT_UPDATE_ID,
      wsTopic,
    },
    {
      params: {
        more_like_this: true,
      },
    }
  )
}

export const replaceVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
  wsTopic,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
  wsTopic: string
}): Promise<void> => {
  await connectInternalApi.put(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`,
    {
      contentUpdateId: TEMPORARY_FIXED_CONTENT_UPDATE_ID,
      wsTopic,
    }
  )
}

export const selectVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
}): Promise<Content> => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}/approval`
  )

  return result.data
}

export const updateVariant = async ({
  accountId,
  contentId,
  elementId,
  variantId,
  updatedVariantText,
}: {
  accountId: string
  contentId: string
  elementId: number
  variantId: number
  updatedVariantText: string
}): Promise<void> => {
  await connectInternalApi.patch(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/variants/${variantId}`,
    { text: updatedVariantText }
  )
}

export const submitForApproval = async ({
  accountId,
  contentId,
}: {
  accountId: string
  contentId: string
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/approval`
  )

  return result.data
}

export const selectElement = async ({
  accountId,
  contentId,
  elementId,
}: {
  accountId: string
  contentId: string
  elementId: number
}) => {
  const result = await connectInternalApi.post<Content>(
    `v1/core/content-api/accounts/${accountId}/contents/${contentId}/elements/${elementId}/selected`
  )

  return result.data
}

export const getContentLibrary = async (accountId: string) => {
  const result = await connectInternalApi.get<ContentLibraryResponse>(
    `v1/core/content-api/accounts/${accountId}/contents/`
  )

  return result.data
}
