import { Content, Element } from '@phrasee/phrasee-typings'

import { useAppSelector } from 'common/hooks/redux'
import useGetContentQuery from 'features/futurama/api/queries/useGetContentQuery'

import { getAllComponentElements, getAllTemplateElements } from '../helpers'

const useSelectedElement = () => {
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )

  return useGetContentQuery(activeContentId, {
    select: (content: Content) => {
      let selectedElement: Element | undefined
      const allComponentElements = getAllComponentElements(content)

      selectedElement = allComponentElements.find(
        (element) => element.element_id === selectedElementId
      )

      if (!selectedElement) {
        const allTemplateElements = getAllTemplateElements(content)

        selectedElement = allTemplateElements.find(
          (element) => element.element_id === selectedElementId
        )
      }

      return selectedElement
    },
  })
}
export default useSelectedElement
