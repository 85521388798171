import { useRef } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { OptimizeMessageConfiguration } from '@phrasee/phrasee-typings/typings/futurama/content_creation'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { SplitSizeData } from 'workflow/interface'

import Button from 'common/components/button'
import { useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import useGenerateCustomVariantsMutation from 'features/unifiedFlow/api/mutations/useGenerateCustomVariantsMutation'
import useUpdateElementMutation from 'features/unifiedFlow/api/mutations/useUpdateElementMutation'
import { getFormValue } from 'features/unifiedFlow/components/templateSelection/helpers'
import { setElementLoadingState } from 'features/unifiedFlow/contentPage/helpers'
import {
  useContent,
  useSelectedElement,
} from 'features/unifiedFlow/contentPage/hooks'
import { generateWsTopic } from 'features/unifiedFlow/contentPage/hooks/useWebsocketStatus'

const { REACT_APP_DEFAULT_DATE_FORMAT } = process.env

type Props = {
  isDisabled: boolean
  optimizationSettingsConfiguration: OptimizeMessageConfiguration
  splitCalculationResult?: SplitSizeData
  onReset: () => void
}

const ExperimentSettingsFooter = ({
  isDisabled,
  splitCalculationResult,
  optimizationSettingsConfiguration,
  onReset,
}: Props) => {
  const form = useForm()
  const formState = useFormState()
  const queryClient = useQueryClient()
  const initialSplitNumberRef = useRef<number | undefined>(
    splitCalculationResult?.split_number
  )

  const { content } = useContent()
  const { data: selectedElement } = useSelectedElement()

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const updateElementMutation = useUpdateElementMutation()
  const generateCustomVariantsMutation = useGenerateCustomVariantsMutation()

  const getConfiguration = () => {
    const ownSubjectLine = getFormValue({
      formState,
      name: 'controlSubjectLine',
    })
    const campaignName = getFormValue({
      formState,
      name: 'experimentName',
    })
    const sendDate = getFormValue({
      formState,
      name: 'sendDate',
    })
    const selectionMetric = getFormValue({
      formState,
      name: 'selectionMetric',
    })?.value
    const listSize =
      optimizationSettingsConfiguration?.split_calculation?.type === 'fixed'
        ? optimizationSettingsConfiguration.split_calculation?.list_size
        : getFormValue({
            formState,
            name: 'listSize',
          })
    const openRate = getFormValue({
      formState,
      name: 'openRate',
    })
    const clickRate = getFormValue({
      formState,
      name: 'clickRate',
    })
    const splitNumber = getFormValue({
      formState,
      name: 'splitNumber',
    })

    const isISOFormat = moment(sendDate, moment.ISO_8601, true).isValid()

    const configuration: OptimizeMessageConfiguration = {
      ...optimizationSettingsConfiguration,
      own_subject_line: ownSubjectLine,
      campaign_name: campaignName,
      iso_send_date: isISOFormat
        ? sendDate
        : moment
            .parseZone(sendDate, REACT_APP_DEFAULT_DATE_FORMAT)
            .toISOString(),
      split_calculation: {
        ...optimizationSettingsConfiguration.split_calculation,
        ...(selectionMetric && { selection_metric: selectionMetric }),
        ...(splitNumber && { split_number: splitNumber }),
        ...(listSize && { list_size: listSize }),
        ...(openRate && { baseline_open_rate: openRate }),
        ...(clickRate && { baseline_click_rate: clickRate }),
        ...(splitCalculationResult && {
          configuration: splitCalculationResult,
        }),
      },
    }

    return configuration
  }

  const onSave = () => {
    if (
      content &&
      selectedElement &&
      isComponentElement(selectedElement) &&
      selectedElement.optimization_settings
    ) {
      const configuration = getConfiguration()

      updateElementMutation.mutate({
        accountId,
        contentId: content?._id,
        elementId: selectedElement?.element_id,
        updatedElement: {
          optimization_settings: {
            template_id: selectedElement.optimization_settings.template_id,
            type: selectedElement.optimization_settings.type,
            configuration,
          },
        },
      })
    }
  }

  const onRegenerate = async () => {
    if (selectedElement && content) {
      const configuration = getConfiguration()
      const wsTopic = generateWsTopic({
        contentId: content._id,
        elementId: selectedElement?.element_id,
        action: 'variants',
      })

      setElementLoadingState({
        queryClient,
        contentId: content._id,
        elementId: selectedElement.element_id,
        action: 'variants',
      })
      generateCustomVariantsMutation.mutate({
        accountId,
        optimizationConfiguration: configuration,
        contentId: content._id,
        elementId: selectedElement?.element_id,
        wsTopic,
      })
    }
  }

  const onResetClick = () => {
    form.setConfig('keepDirtyOnReinitialize', false)
    form.reset()
    form.setConfig('keepDirtyOnReinitialize', true)
    onReset()
  }

  const getState = () => {
    const dirtyFields = formState.dirtyFields
    const splitNumber = getFormValue({
      formState,
      name: 'splitNumber',
    })

    if (splitNumber !== initialSplitNumberRef.current) {
      return 'regenerate'
    } else if (!isEmpty(dirtyFields)) {
      return 'save'
    } else {
      return 'noChanges'
    }
  }

  const state = getState()

  return (
    <div className="mt-auto bg-white border-coolGray-300 border-t-1 flex justify-end p-6 gap-4 h-22">
      {
        {
          regenerate: (
            <>
              <Button
                ghost
                disabled={
                  isDisabled || generateCustomVariantsMutation.isLoading
                }
                onClick={onResetClick}
              >
                Revert
              </Button>
              <Button
                data-cy="content-regenerate-variants-button"
                variant="primary"
                disabled={
                  isDisabled ||
                  formState.pristine ||
                  formState.invalid ||
                  generateCustomVariantsMutation.isLoading
                }
                onClick={onRegenerate}
                loading={generateCustomVariantsMutation.isLoading}
              >
                Regenerate
              </Button>
            </>
          ),
          save: (
            <>
              <Button
                ghost
                disabled={isDisabled || updateElementMutation.isLoading}
                onClick={onResetClick}
              >
                Revert
              </Button>
              <Button
                data-cy="content-regenerate-variants-button"
                variant="primary"
                disabled={
                  isDisabled ||
                  formState.pristine ||
                  formState.invalid ||
                  updateElementMutation.isLoading
                }
                loading={updateElementMutation.isLoading}
                onClick={onSave}
              >
                Save
              </Button>
            </>
          ),
          noChanges: null,
        }[state]
      }
    </div>
  )
}

export default ExperimentSettingsFooter
