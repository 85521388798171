import cx from 'classnames'

import { Fragment } from 'common/components/topics/interfaces'
import { highlightTextFragments } from 'common/helpers/string'

import { PageState } from './BriefPage'
import RefreshBriefOverlay from './RefreshBriefOverlay'

type Props = {
  brief: string
  pageState: PageState
  fragments?: Fragment[]
}

const GeneratedBrief = ({ brief, pageState, fragments }: Props) => {
  const enhancedBrief = highlightTextFragments(brief, fragments)

  return (
    <>
      {(pageState === 'generateVariants' ||
        pageState === 'topicsChanged' ||
        pageState === 'formChangedWithBrief' ||
        pageState === 'formChangedWithTopics' ||
        pageState === 'brandVoiceChanged' ||
        pageState === 'showGeneratedContent') && (
        <div
          className={cx('relative flex flex-col border p-6 bg-white', {
            'border-transparent': pageState === 'formChangedWithBrief',
            'border-b-0 border-b-none':
              pageState === 'generateVariants' ||
              pageState === 'showGeneratedContent' ||
              pageState === 'brandVoiceChanged' ||
              pageState === 'topicsChanged',
          })}
        >
          {pageState === 'formChangedWithBrief' && <RefreshBriefOverlay />}
          <div className="text-sm font-medium text-maroon-500">
            Jacquard has generated your brief
          </div>
          <div className="text-sm text-coolGray-500">
            We will use this Jacquard engineered brief to generate your content.
          </div>
          <div
            className="mt-6 text-base text-coolGray-800"
            dangerouslySetInnerHTML={{ __html: enhancedBrief }}
          />
        </div>
      )}
    </>
  )
}

export default GeneratedBrief
