import { generatePath, Redirect, Route } from 'react-router-dom'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import { adminAccountPath } from 'app/navigation/paths'
import { accountSettingsSubroutes } from 'app/navigation/staticSubRoutes.accountSettings'
import CustomSwitch from 'app/router/Switch'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { canAccessAccount, getIsAuthorized } from 'common/auth/permissions'
import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'

import AccountSettingsNavBar from './AccountSettingsNavBar'

const AccountSettings = () => {
  useDocumentTitle('Account settings | Jacquard')

  const flags = useFlags()
  const { permissions, accountCategory } = useAppSelector(
    (state) => state.authStates
  )

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const routes = accountSettingsSubroutes
    .filter(
      ({ accessFlags, accessPermission, shouldValidateAccountAccess }) => {
        const isAuthorized = flags.rolesAndPermissions
          ? getIsAuthorized(permissions, accessPermission)
          : true
        const hasAccountAccess =
          accessPermission && shouldValidateAccountAccess
            ? canAccessAccount(accountCategory, accessPermission)
            : true

        return (
          isAuthorized &&
          hasAccountAccess &&
          isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
        )
      }
    )
    .map((route) => {
      return { ...route, to: generatePath(route.to, { accountId }) }
    })

  return (
    <>
      <AccountSettingsNavBar subroutes={routes} title="Account settings" />
      <PageContainer>
        <CustomSwitch>
          {routes.map(({ key, to, component: Component }) => (
            <Route key={key} path={to}>
              <Component />
            </Route>
          ))}
          <Redirect from={adminAccountPath} to={routes[0].to} />
        </CustomSwitch>

        <Footer />
      </PageContainer>
    </>
  )
}

export default AccountSettings
