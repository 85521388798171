import { useRouteMatch } from 'react-router'
import { useParams } from 'react-router-dom'
import { styleGuidePath, toneOfVoicePath } from 'app/navigation/paths'
import get from 'lodash/get'

import Button from 'common/components/button'
import Modal from 'common/components/Modal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import useDeleteBrandVoiceMutation from '../api/mutations/useDeleteBrandVoiceMutation'
import { toggleDeleteConfirmationModalVisibility } from '../store/brandVoiceSlice'
const DeleteConfirmationModal = () => {
  const dispatch = useAppDispatch()
  const isDeleteConfirmationModalVisible = useAppSelector(
    (state) => state.brandVoice.isDeleteConfirmationModalVisible
  )

  const match = useRouteMatch<{
    brandVoiceId: string
  }>({
    path: [styleGuidePath, toneOfVoicePath],
  })

  const currentBrandVoiceId = get(match, 'params.brandVoiceId', '')
  const { accountId } = useParams<{ accountId: string }>()

  const deleteMutation = useDeleteBrandVoiceMutation(currentBrandVoiceId)

  const handleDeleteBrandVoice = () => {
    if (!currentBrandVoiceId) {
      return null
    }
    deleteMutation.mutate({
      accountId,
      brandVoiceId: currentBrandVoiceId,
    })
  }
  return (
    <Modal
      closable={false}
      visible={isDeleteConfirmationModalVisible}
      centered
      style={{ minWidth: 500 }}
    >
      <div className="flex" data-testid="deleteConfirmationModal">
        <h2 className=" text-2xl mb-2">
          <span className="text-base-700 font-bold">
            Hold up! Deleting Brand Voice?
          </span>
        </h2>
      </div>
      <div className="mt-4">
        <p className="text-lg text-base-300">
          Deleting this brand voice will remove all customized tone and style
          settings. This action is irreversible and any existing content using
          this brand voice will now be using your ‘default’ voice.
        </p>
        <p className="text-lg text-base-300 mt-4">
          Are you sure you want to proceed with deleting this brand voice?
        </p>
      </div>
      <div className="flex flex-wrap justify-end mt-10">
        <Button
          ghost
          size="large"
          key="cancel"
          className="mr-4"
          onClick={() =>
            dispatch(toggleDeleteConfirmationModalVisibility(false))
          }
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          size="large"
          key="delete"
          data-testid="deleteConfirmationButton"
          loading={deleteMutation.isLoading}
          onClick={handleDeleteBrandVoice}
        >
          Yes, delete it
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteConfirmationModal
