import { useEffect, useMemo, useState } from 'react'
import { Field, useForm } from 'react-final-form'

import Button from 'common/components/button/Button'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import ToggleButtonGroup from 'common/components/toggleButtonGroup'
import { useAppDispatch } from 'common/hooks/redux'
import { Close as CloseIcon } from 'common/icons'
import {
  removeTemplate,
  templateSetupComplete,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import {
  getExperimentFormStatus,
  getIsContentFormValid,
  getTemplatePrefixedName,
} from '../helpers'

import { TemplateInfoWithInternalId } from './AddedComponentList'
import ToggleButtonGroupTabs from './ToggleButtonGroupTabs'

type Props = {
  template: TemplateInfoWithInternalId
}

const AddedComponentCard = ({ template }: Props) => {
  const form = useForm()
  const dispatch = useAppDispatch()
  const [tabToShow, setTabToShow] = useState<string | undefined>(undefined)

  const handleToggleGroupClick = (name) => {
    setTabToShow(name)
  }

  const { internalId, icon, optimization, personalization, displayName } =
    template

  const items = useMemo(
    () => [
      {
        name: 'content',
        disabled: false,
      },
      ...(optimization?.status !== 'disabled'
        ? [
            {
              name: 'optimize',
              disabled: optimization?.status === 'blocked',
              tooltipMessage: 'Talk to us about upgrading',
            },
          ]
        : []),
      ...(personalization?.status !== 'disabled'
        ? [
            {
              name: 'personalize',
              disabled: personalization?.status === 'blocked',
              tooltipMessage: 'Talk to us about upgrading',
            },
          ]
        : []),
    ],
    [optimization?.status, personalization?.status]
  )

  const onChange = (name: string) => {
    handleToggleGroupClick(name)
    if (name === 'optimize') {
      const status = getExperimentFormStatus(form, template)
      dispatch(
        templateSetupComplete({
          internalId: template.internalId,
          status: status,
        })
      )
    } else if (name === 'content') {
      const isContentFormValid = getIsContentFormValid(form, template)
      dispatch(
        templateSetupComplete({
          internalId: template.internalId,
          status: isContentFormValid ? 'complete' : 'incomplete',
        })
      )
    }
  }

  useEffect(() => {
    const activeItems = items.filter((item) => !item.disabled)
    if (activeItems.length === 1 && !tabToShow) {
      setTabToShow(activeItems[0].name)
    } else if (activeItems.length > 1 && !tabToShow) {
      setTabToShow(activeItems[1].name)
    }
  }, [items, tabToShow])

  return (
    <div className="bg-white flex flex-col p-6 border border-coolGray-300">
      <div className="flex items-center mb-4">
        <ContentTypeBubble name={displayName} iconType={icon} />
        <div className="text-base font-medium flex ml-4 text-coolGray-800">
          {displayName}
        </div>
        <div className="flex ml-auto">
          <Button
            onClick={() => {
              dispatch(removeTemplate({ internalId }))
            }}
            className="px-0 ml-4 h-4"
            variant="icon"
            aria-label="close"
          >
            <CloseIcon className="text-coolGray-400" state="default" size={4} />
          </Button>
        </div>
      </div>
      <Field<string>
        name={getTemplatePrefixedName(template, 'experimentType')}
        initialValue={tabToShow}
      >
        {({ input }) => (
          <ToggleButtonGroup
            items={items}
            value={tabToShow}
            onChange={(name) => {
              input.onChange(name)
              onChange(name)
            }}
            className="capitalize"
          />
        )}
      </Field>
      <ToggleButtonGroupTabs tabToShow={tabToShow} template={template} />
    </div>
  )
}

export default AddedComponentCard
