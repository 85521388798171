import { Content } from '@phrasee/phrasee-typings'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { generateMoreLikeThisVariants } from '../api'
import { contentKeys } from '../queryKeys'

const useMoreLikeThisVariantsMutation = (contentId: Content['_id']) => {
  const queryClient = useQueryClient()

  return useMutation(generateMoreLikeThisVariants, {
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.content(contentId))
    },
  })
}

export default useMoreLikeThisVariantsMutation
