import cx from 'classnames'

export type ItemType = {
  label: string
  value: number | string | undefined
  className?: string
  shouldTruncate?: boolean
}

type Props = ItemType

const Item = ({ label, value, className, shouldTruncate }: Props) => {
  return (
    <div className={cx(className, { 'min-w-0': shouldTruncate })}>
      <div className="text-base md:text-lg text-coolGray-400 font-medium mb-3">
        {label}
      </div>
      <div
        className={cx('text-xl md:text-3xl text-coolGray-800 font-medium', {
          truncate: shouldTruncate,
        })}
      >
        {value ?? '-'}
      </div>
    </div>
  )
}

export default Item
