import {
  differenceInCalendarWeeks,
  differenceInDays,
  differenceInMonths,
  format,
  isThisWeek,
  isToday,
  isYesterday,
} from 'date-fns'

export const formatDate = (dateInput: Date | string) => {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput
  const now = new Date()
  const minutesDifference = Math.round(
    (now.getTime() - date.getTime()) / (1000 * 60)
  )
  const daysDifference = differenceInDays(now, date)
  const monthsDifference = differenceInMonths(now, date)

  if (minutesDifference < 1) {
    return 'Just now'
  } else if (minutesDifference < 60) {
    return `${minutesDifference} minutes ago`
  } else if (isToday(date)) {
    return format(date, 'h:mm a')
  } else if (isYesterday(date)) {
    return 'Yesterday'
  } else if (isThisWeek(date)) {
    return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`
  } else if (differenceInCalendarWeeks(now, date) === 1) {
    return 'Last week'
  } else if (differenceInCalendarWeeks(now, date) <= 3) {
    const diffInWeeks = differenceInCalendarWeeks(now, date)
    return `${diffInWeeks} weeks ago`
  } else if (monthsDifference === 0) {
    return `${daysDifference} days ago`
  } else if (daysDifference < 365) {
    return format(date, 'MMM d')
  } else {
    return 'Over a year ago'
  }
}
