import Button from 'common/components/button/Button'

export interface Props {
  isDisabled?: boolean
  cardTitle: string
  cardText: string
  buttonText: string
  dataTestId: string
  onSubmit: () => void
}

export default function UnscheduleCampaignCard({
  cardTitle,
  cardText,
  buttonText,
  isDisabled = false,
  dataTestId,
  onSubmit,
}: Props) {
  return (
    <div className="mt-4 mb-4 bg-coolGray-50 p-4 border border-blueGray-300">
      <div className="font-medium mb-4 text-coolGray-800">{cardTitle}</div>
      <div className="mb-4 text-coolGray-400">{cardText}</div>
      <div className="content-end flex flex-wrap">
        <Button
          data-cy="unschedule-campaign-button"
          variant="secondary"
          className="ml-auto"
          disabled={isDisabled}
          data-test-id={dataTestId}
          onClick={() => onSubmit()}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
