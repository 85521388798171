import TagWidget from 'common/components/widget/TagWidget/TagWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  clickAddForbiddenWord,
  clickRemoveForbiddenWord,
} from '../store/styleGuideSlice'

type Props = {
  className?: string
}

const NeverGenerateWidget = ({ className }: Props) => {
  const dispatch = useAppDispatch()

  const forbiddenWords = useAppSelector(
    (state) => state.styleGuide.editedContent.vocabulary.forbiddenWords
  )

  return (
    <TagWidget
      title="Never generate"
      subtitle="Add words or emojis here that you never want to appear in your content generations. Jacquard inherently prohibits offensive terms from being generated, so there is no need to specify them as exclusions here."
      data-cy="never-generate-widget"
      className={className}
      onInputChange={(value) => value.replaceAll(' ', '')}
      placeholder="Never generate word..."
      tags={forbiddenWords.map((word) => ({
        value: word,
        label: word,
      }))}
      maxLengthPerTag={50}
      onAddTag={(value: string) => {
        dispatch(clickAddForbiddenWord(value))
      }}
      onRemoveTag={(value: string) => {
        dispatch(clickRemoveForbiddenWord(value))
      }}
      hasTagButtonLabel={true}
      tagButtonLabel="Word"
    />
  )
}

export default NeverGenerateWidget
