import moment from 'moment'

import { connectInternalApi } from 'common/api'
import { parseReplacer } from 'common/components/topics/helpers'
import { Replacer, Topic } from 'common/components/topics/interfaces'
import { stringToNumber } from 'common/helpers/numeric'
import { Node, NodeResponse } from 'common/interfaces/nodes'
import { Tag } from 'common/interfaces/tags'

import { validReplacerRegex } from '../nodes/components/cells/PhraseCell'

import {
  ContentUpdate,
  CreatedNode,
  NodeMetadata,
  Phrase,
  PhraseResponse,
} from './interfaces'

const TEMPORARY_FIXED_CONTENT_UPDATE_ID = 3

const getValidReplacerIds = (value?: string, replacers?: Topic[]) => {
  const replacerMatches = value?.match(validReplacerRegex)
  const validReplacers = new Set<number>()

  if (replacers?.length) {
    replacerMatches?.forEach((match) => {
      const foundReplacer = replacers.find(
        (replacer) => (replacer.original as Replacer).replacer === match
      )
      if (foundReplacer && foundReplacer.type === 'replacer') {
        validReplacers.add(foundReplacer.original.replacerId)
      }
    })
  }

  return Array.from(validReplacers)
}

export const getEmptyNodes = async (): Promise<Node[]> => {
  const result = await connectInternalApi.get<NodeResponse>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/trees/5`,
    {
      params: {
        empty_nodes: true,
      },
    }
  )
  result.data.data.sort((a, b) => (a.node > b.node ? 1 : -1))

  const nodes: Node[] = result.data.data.map((node) => ({
    id: node.node_id.toString(),
    parent: node.node_parent_id.toString(),
    name: node.display_name || node.node,
    isPrivate: node.private,
    isDisplayed: node.display,
  }))

  return nodes
}

export const getNode = async (nodeId: string): Promise<NodeMetadata> => {
  const result = await connectInternalApi.get<{ metadata: NodeMetadata }>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/nodes/${nodeId}`,
    {
      params: {
        include_metadata: true,
      },
    }
  )

  return result.data.metadata
}

export const updateNode = async ({
  userId,
  nodeId,
  updatedName,
  isPrivate,
  isDisplayed,
}: {
  userId: string
  nodeId: number
  updatedName?: string
  isPrivate?: boolean
  isDisplayed?: boolean
}): Promise<void> => {
  await connectInternalApi.patch(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/nodes/${nodeId}`,

    {
      userId,
      ...(updatedName ? { displayName: updatedName } : undefined),
      ...(isPrivate !== undefined ? { private: isPrivate } : undefined),
      ...(isDisplayed !== undefined ? { display: isDisplayed } : undefined),
    }
  )
}

export const getPhrases = async (nodeId?: string): Promise<Phrase[]> => {
  const result = await connectInternalApi.get<{ data: PhraseResponse[] }>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases`,
    {
      params: {
        node_id: nodeId,
      },
    }
  )

  return result.data.data
    .sort((a, b) => b.phraseId - a.phraseId)
    .map((phrase) => {
      const startDate =
        phrase.startDateDay && phrase.startDateMonth
          ? moment(
              `${phrase.startDateDay} ${phrase.startDateMonth}`,
              'D M'
            ).format('DD/MM')
          : ''
      const endDate =
        phrase.endDateDay && phrase.endDateMonth
          ? moment(`${phrase.endDateDay} ${phrase.endDateMonth}`, 'D M').format(
              'DD/MM'
            )
          : ''

      const dateRange = startDate && endDate ? `${startDate} - ${endDate}` : ''

      const tags =
        phrase.tags?.map((tag) => ({
          tagId: tag.tagId,
          tag: tag.tag,
        })) || []

      return {
        ...phrase,
        frequency: phrase.frequency.toFixed(1),
        startDate,
        endDate,
        dateRange,
        tags,
      }
    })
}

export const createTag = async ({
  tag,
  userId,
}: {
  tag: string
  userId: string
}): Promise<Tag> => {
  const result = await connectInternalApi.post<Tag>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/tags`,
    { tag, userId }
  )

  return result.data
}

export const createPhrasesBulk = async ({
  nodeId,
  userId,
  phrases,
  replacers,
  tags,
  tagOptions,
}: {
  nodeId: number
  userId: string
  phrases: Partial<Phrase>[]
  replacers?: Topic[]
  tags: string[]
  tagOptions: Tag[]
}): Promise<{
  failedPhrases: string[]
  successfulPhrases: PhraseResponse[]
}> => {
  const result = await connectInternalApi.post(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases`,
    {
      userId,
      frequency: 1,
      phrases: phrases.map((phrase) => ({
        phrase: phrase.phrase,
        replacers: getValidReplacerIds(phrase.phrase, replacers),
      })),
      nodeId: nodeId,
    }
  )
  const isTagExisting = (tag: string) =>
    tagOptions.some((tagOption) => tagOption.tag === tag)

  const createdTags = await Promise.all(
    tags
      .filter((tag) => !isTagExisting(tag))
      .map((tag) => createTag({ tag, userId }))
  )

  const tagsToAdd = [
    ...createdTags,
    ...tags
      .filter(isTagExisting)
      .map((tag) => tagOptions.find((tagOption) => tagOption.tag === tag)),
  ]
  await Promise.all(
    result.data.successfulPhrases.flatMap((phrase) =>
      tagsToAdd
        .filter(Boolean)
        .map((tag) =>
          addTagToPhrase({ phraseId: phrase.phraseId, tag: tag!, userId })
        )
    )
  )

  return result.data
}

export const deletePhrasesBulk = async ({
  userId,
  phraseIds,
}: {
  userId: string
  phraseIds: number[]
}): Promise<PromiseSettledResult<void>[]> => {
  const promises = phraseIds.map((phraseId) =>
    deletePhrase({ userId, phraseId })
  )
  return await Promise.allSettled(promises)
}

export const movePhrasesBulk = async ({
  userId,
  phraseIds,
  targetNodeId,
}: {
  userId: string
  phraseIds: number[]
  targetNodeId: number
}): Promise<PromiseSettledResult<void>[]> => {
  const promises = phraseIds.map((phraseId) =>
    movePhrase({ userId, phraseId, targetNodeId })
  )
  return await Promise.allSettled(promises)
}

export const createPhrase = async ({
  nodeId,
  phrase,
  userId,
  replacers,
}: {
  nodeId: number
  userId: string
  phrase: Partial<Phrase>
  replacers?: Topic[]
}): Promise<void> => {
  await connectInternalApi.post(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases`,
    {
      userId,
      frequency: stringToNumber(phrase.frequency),
      phrase: phrase.phrase,
      nodeId: nodeId,
      startDate: phrase.startDate || moment().toISOString(), // TODO: remove this when the API is fixed to accept null values for start and end dates,
      endDate: phrase.endDate || moment().add(3, 'years').toISOString(),
      replacers: getValidReplacerIds(phrase.phrase, replacers),
    }
  )
}

export const updatePhrase = async ({
  phraseId,
  updatedPhrase,
  userId,
  replacers,
}: {
  phraseId: number
  userId: string
  updatedPhrase: Phrase
  replacers?: Topic[]
}): Promise<void> => {
  const [startDate, endDate = ''] = updatedPhrase.dateRange.split(' - ')
  const [startDay, startMonth = ''] = startDate.split('/')
  const [endDay, endMonth = ''] = endDate.split('/')

  await connectInternalApi.patch(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases/${phraseId}`,
    {
      userId,
      frequency: stringToNumber(updatedPhrase.frequency),
      phrase: updatedPhrase.phrase,
      replacers: getValidReplacerIds(updatedPhrase?.phrase, replacers),
      startDateDay: startDay ? Number(startDay) : null,
      startDateMonth: startMonth ? Number(startMonth) : null,
      endDateDay: endDay ? Number(endDay) : null,
      endDateMonth: endMonth ? Number(endMonth) : null,
    }
  )
}

export const deletePhrase = async ({
  phraseId,
  userId,
}: {
  phraseId: number
  userId: string
}): Promise<void> => {
  await connectInternalApi.delete(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases/${phraseId}`,
    {
      data: {
        userId,
      },
    }
  )
}

export const movePhrase = async ({
  phraseId,
  userId,
  targetNodeId,
}: {
  phraseId: number
  userId: string
  targetNodeId: number
}): Promise<void> => {
  await connectInternalApi.patch(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases/${phraseId}`,
    {
      userId,
      nodeId: targetNodeId,
    }
  )
}

export const addTagToPhrase = async ({
  phraseId,
  tag,
  userId,
}: {
  phraseId: number
  tag: Tag
  userId: string
}): Promise<void> => {
  await connectInternalApi.post(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases/${phraseId}/tags/${tag.tagId}`,
    {
      userId,
    }
  )
}

export const removeTagFromPhrase = async ({
  phraseId,
  tagId,
  userId,
}: {
  phraseId: number
  tagId: number
  userId: string
}): Promise<void> => {
  await connectInternalApi.delete(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/phrases/${phraseId}/tags/${tagId}`,
    {
      data: {
        userId,
      },
    }
  )
}

export const getDraftVersions = async (): Promise<ContentUpdate[]> => {
  const result = await connectInternalApi.get<ContentUpdate[]>(
    `v1/language/content-updates?status=draft`
  )

  return result.data
}

export const createNode = async ({
  displayName,
  parentNodeId,
  isPrivate,
  isDisplayed,
  userId,
}: {
  displayName: string
  parentNodeId: number
  isPrivate: boolean
  isDisplayed: boolean
  userId: string
}): Promise<CreatedNode> => {
  const result = await connectInternalApi.post<CreatedNode>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/nodes`,
    {
      displayName,
      parentNodeId,
      private: isPrivate,
      display: isDisplayed,
      userId,
    }
  )

  return result.data
}

export const moveNode = async ({
  nodeId,
  userId,
  targetParentNodeId,
}: {
  nodeId: number
  userId: string
  targetParentNodeId: number
}): Promise<void> => {
  await connectInternalApi.patch(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/nodes/${nodeId}`,
    {
      userId,
      parentNodeId: targetParentNodeId,
    }
  )
}

export const deleteNode = async ({
  userId,
  nodeId,
}: {
  nodeId: number
  userId: string
}): Promise<void> => {
  await connectInternalApi.delete(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/nodes/${nodeId}`,
    {
      data: {
        userId,
      },
    }
  )
}

export const getReplacers = async (): Promise<Topic[]> => {
  const result = await connectInternalApi.get<{ data: Replacer[] }>(
    `v1/language/content-updates/${TEMPORARY_FIXED_CONTENT_UPDATE_ID}/replacers`
  )

  return result.data.data
    .filter(
      ({ replacerDefinition, inputType }) =>
        inputType === 'settings' ||
        (inputType === 'manual' && Object.keys(replacerDefinition).length)
    )
    .map((replacer) => parseReplacer(replacer))
}
