import { useCallback, useMemo } from 'react'
import { Variant } from '@phrasee/phrasee-typings/typings/futurama/element'
import { useQueryClient } from '@tanstack/react-query'
import copy from 'copy-to-clipboard'
import isEqual from 'lodash/isEqual'

import { CellProps, Column, OnCellChange } from 'common/components/table'
import { successToast } from 'common/components/toastNotification'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'
import { useFocusEditableCell } from 'common/hooks/table'
import useDeleteVariantMutation from 'features/futurama/api/mutations/useDeleteVariantMutation'
import useMoreLikeThisVariantsMutation from 'features/futurama/api/mutations/useMoreLikeThisVariantsMutation'
import useReplaceVariantMutation from 'features/futurama/api/mutations/useReplaceVariantMutation'
import useSelectVariantMutation from 'features/futurama/api/mutations/useSelectVariantMutation'
import useUpdateVariantMutation from 'features/futurama/api/mutations/useUpdateVariantMutation'

import { getGenerateWsTopic } from '../../ContentPage'
import { setVariantsLoadingState } from '../../helpers'
import { useSelectedComponentElement, useVariants } from '../../hooks'

import { ActionCells } from './ActionsCell'
import ContentTableActionButtons from './contentTableActionButtons'
import useSelectedRows from './useSelectedRows'

export type ExtendedVariant = Variant & {
  isDisabled?: boolean
  checked?: boolean
  subRows?: ExtendedVariant[]
}

const ContentTable = () => {
  const queryClient = useQueryClient()
  const { TextEditableCellWithFocus } = useFocusEditableCell()

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )

  const { data: variants } = useVariants(activeContentId, { withSubrows: true })
  const { data: selectedElement } = useSelectedComponentElement()

  const elementId = selectedElement?.element_id

  const { selectedRows, initialSelectedRows, setSelectedRows } =
    useSelectedRows(variants, selectedElement)

  const replaceVariantMutation = useReplaceVariantMutation(activeContentId)
  const moreLikeThisVariantsMutation = useMoreLikeThisVariantsMutation(
    activeContentId || ''
  )
  const selectVariantMutation = useSelectVariantMutation(activeContentId)

  const isLoading =
    replaceVariantMutation.isLoading || moreLikeThisVariantsMutation.isLoading

  const variantsWithModifiedState = useMemo(() => {
    return variants?.map((variant) => ({
      ...variant,
      isDisabled: isLoading,
      checked: selectedElement?.selected_variants?.includes(variant.variant_id),
      subRows: variant.subRows?.map((subRow) => ({
        ...subRow,
        isDisabled: isLoading,
        checked: selectedElement?.selected_variants?.includes(
          subRow.variant_id
        ),
      })),
    }))
  }, [variants, isLoading, selectedElement?.selected_variants])

  const handleReplaceVariants = useCallback(
    async (variantId: number) => {
      if (activeContentId && elementId) {
        const wsTopic = getGenerateWsTopic(activeContentId, elementId)
        setVariantsLoadingState(queryClient, activeContentId, elementId)

        replaceVariantMutation.mutate({
          accountId,
          contentId: activeContentId,
          elementId,
          variantId,
          wsTopic,
        })
      }
    },
    [accountId, activeContentId, elementId, queryClient, replaceVariantMutation]
  )

  const deleteVariantMutation = useDeleteVariantMutation(activeContentId)
  const updateVariantMutation = useUpdateVariantMutation(activeContentId)

  const handleDeleteVariants = useCallback(
    (variantToDelete: Variant) => {
      if (activeContentId && elementId) {
        deleteVariantMutation.mutate(
          {
            accountId,
            contentId: activeContentId,
            elementId: elementId,
            variantId: variantToDelete.variant_id,
          },
          {
            onSuccess: () => {
              successToast('Line deleted successfully')
            },
          }
        )
      }
    },
    [accountId, activeContentId, deleteVariantMutation, elementId]
  )

  const onCopy = useCallback((text: string) => {
    copy(text)
    successToast('Variant copied successfully')
  }, [])

  const handleUpdateVariant = (variant: Variant, text: string) => {
    if (activeContentId && elementId) {
      if (text) {
        updateVariantMutation.mutate({
          accountId,
          contentId: activeContentId,
          elementId,
          variantId: variant.variant_id,
          updatedVariantText: text,
        })
      } else {
        deleteVariantMutation.mutate(
          {
            accountId,
            contentId: activeContentId,
            elementId: elementId,
            variantId: variant.variant_id,
          },
          {
            onSuccess: () => {
              successToast('Line deleted successfully')
            },
          }
        )
      }
    }
  }

  const handleMoreLikeThisVariants = useCallback(
    (variantId: number) => {
      if (activeContentId && elementId) {
        const wsTopic = getGenerateWsTopic(activeContentId, elementId)
        setVariantsLoadingState(queryClient, activeContentId, elementId)

        moreLikeThisVariantsMutation.mutate({
          accountId,
          contentId: activeContentId,
          elementId,
          variantId,
          wsTopic,
        })
      }
    },
    [
      accountId,
      activeContentId,
      elementId,
      moreLikeThisVariantsMutation,
      queryClient,
    ]
  )

  const columns: Column<Variant>[] = useMemo(
    () => [
      {
        accessor: 'text',
        disableSortBy: true,
        disableFilters: true,
        Cell: (
          props: CellProps<Variant> & {
            onCellChange: OnCellChange<Variant>
          }
        ) => {
          return (
            <TextEditableCellWithFocus
              inputType="textarea"
              className="py-4 pl-2"
              {...props}
            />
          )
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'variant_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => (
          <ActionCells
            onMoreLikeThis={() =>
              handleMoreLikeThisVariants(row.original.variant_id)
            }
            isMoreLikeThisEnabled={row.depth === 0}
            onCopy={() => onCopy(row.values.text)}
            onRefresh={() => handleReplaceVariants(row.original.variant_id)}
            onDelete={() => handleDeleteVariants(row.original)}
            isRefreshing={
              replaceVariantMutation.variables?.variantId ===
                row.original.variant_id && replaceVariantMutation.isLoading
            }
            isLoadingMoreLikeThis={
              moreLikeThisVariantsMutation.variables?.variantId ===
                row.original.variant_id &&
              moreLikeThisVariantsMutation.isLoading
            }
          />
        ),
      },
    ],
    [
      TextEditableCellWithFocus,
      replaceVariantMutation.variables?.variantId,
      replaceVariantMutation.isLoading,
      moreLikeThisVariantsMutation.variables?.variantId,
      moreLikeThisVariantsMutation.isLoading,
      handleMoreLikeThisVariants,
      onCopy,
      handleReplaceVariants,
      handleDeleteVariants,
    ]
  )

  const handleSelectRow = (rows: ExtendedVariant[]) => {
    const variantId = rows[0]?.variant_id
    const parsedRows: Variant[] = rows.map(
      ({ isDisabled, checked, ...row }) => row
    )
    if (
      !isEqual(parsedRows, selectedRows) &&
      activeContentId &&
      elementId &&
      variantId &&
      variantId !== selectedRows[0]?.variant_id
    ) {
      selectVariantMutation.mutate({
        accountId,
        contentId: activeContentId,
        elementId,
        variantId,
      })
      setSelectedRows(parsedRows)
    }
  }

  return (
    <TableWidget.Widget<ExtendedVariant>
      key={elementId}
      data={variantsWithModifiedState}
      columns={columns}
      autoResetPage={false}
      onSelectRow={handleSelectRow}
      rowSelection="single"
      selectionHeader="Select your favorite"
      onCellChange={({ columnId, value, rowOriginal }) => {
        if (columnId === 'text') {
          const variant = rowOriginal
          if (variant && value !== variant.text && variant?.variant_id) {
            handleUpdateVariant(variant, String(value))
          }
        }
      }}
    >
      <TableWidget.Header
        title={
          <div style={{ height: '38px' }}>
            {selectedElement?.display_name || selectedElement?.name}
          </div>
        }
        subtitle={selectedElement?.description}
      />
      <TableWidget.ActionButtons>
        <ContentTableActionButtons
          selectedRows={selectedRows}
          initialSelectedRows={initialSelectedRows}
        />
      </TableWidget.ActionButtons>
    </TableWidget.Widget>
  )
}

export default ContentTable
