/* eslint-disable max-len */
import React from 'react'
import { connect } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import Spinner from 'common/components/spinner'

import getUserDetails from './sfmc-auth.actions'

class SfmcLoginCallback extends React.Component<any, any> {
  constructor(props) {
    super(props)
    const search = new URLSearchParams(props.location.search)
    this.state = {
      tssd: search.get('tssd'),
      code: search.get('code'),
      client: search.get('client'),
    }
  }

  async componentDidMount() {
    const { tssd, code, client } = this.state
    const { getUserDetails: getUserDetailsProps } = this.props
    getUserDetailsProps(tssd, code, client)
    this.checkSfmcUserState()
  }

  componentDidUpdate() {
    this.checkSfmcUserState()
  }

  checkSfmcUserState = () => {
    const { sfmcLoginFailed } = this.props
    /* this is 3 way logic, null on first load and true or false once we get a response from getUserDetails */
    if (sfmcLoginFailed !== null) {
      sfmcLoginFailed ? this.redirectToSignout() : this.redirectToDashboard()
    }
  }

  redirectToDashboard = () => {
    const { flags } = this.props
    const newHomePageFf = flags['showHomePage']
    const { history } = this.props
    newHomePageFf ? history.push('/') : history.push('/dashboard')
  }

  redirectToSignout = () => {
    const { history } = this.props
    history.push('/logout-sso')
  }

  render() {
    return <Spinner isFullPage />
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getUserDetails: (tssd, code, client_id) =>
      dispatch(getUserDetails(tssd, code, client_id)),
  }
}
function mapStateToProps(state) {
  return {
    sfmcLoginFailed: state.authStates.sfmcLoginFailed,
  }
}

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SfmcLoginCallback)

// We want to retrieve the flags from Dark Launch so set it to false
const ldConsumerOptions = {
  clientOnly: false,
}

const SfmcLoginCallbackWithLDConsumer =
  withLDConsumer(ldConsumerOptions)(ConnectedComponent)

export default SfmcLoginCallbackWithLDConsumer
