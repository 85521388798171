import Widget from 'common/components/Widget'
import { useAppSelector } from 'common/hooks/redux'

import AverageDiversityWidget from '../averageDiversity/AverageDiversityWidget'
import DiversityOvertimeWidget from '../diversityOvertime/DiversityOvertimeWidget'
import OpenRateByLengthWidget from '../openRateByLength/OpenRateByLengthWidget'
import SentimentsOvertimeWidget from '../sentimentsOvertime/SentimentsOvertimeWidget'

type Props = {
  projectId: string | undefined
  startDate: string
  endDate: string
}
const SecondWidgets = ({ projectId, startDate, endDate }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const projectIds = projectId ? [projectId] : []
  return (
    <Widget direction="column">
      <SentimentsOvertimeWidget
        accountId={accountId}
        projectIds={projectIds}
        startDate={startDate}
        endDate={endDate}
      />
      <AverageDiversityWidget
        accountId={accountId}
        projectIds={projectIds}
        startDate={startDate}
        endDate={endDate}
      />
      <DiversityOvertimeWidget
        accountId={accountId}
        projectIds={projectIds}
        startDate={startDate}
        endDate={endDate}
      />
      <OpenRateByLengthWidget
        type="inner"
        accountId={accountId}
        projectIds={projectIds}
        startDate={startDate}
        endDate={endDate}
      />
    </Widget>
  )
}

export default SecondWidgets
