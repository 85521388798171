import React from 'react'
import cx from 'classnames'

import Alert from 'common/components/alert'
import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import { stringToNumber } from 'common/helpers/numeric'

import { SplitSizeData } from '../interface'

type Props = {
  data?: SplitSizeData
  isLoading?: boolean
  isDisabled?: boolean
  className?: string
  value?: number
  updateSplitSizeNumber: (splitNumber: number) => void
}

const SplitSize = ({
  data,
  className,
  updateSplitSizeNumber,
  isLoading = false,
  isDisabled = false,
  value,
}: Props) => {
  if (isLoading) {
    return (
      <div style={{ height: '146px' }}>
        <Spinner isSpinning={isLoading} />
      </div>
    )
  }
  if (!data) {
    return null
  }
  const {
    headline,
    show_other_split_options,
    other_split_options,
    split_result,
    warning,
  } = data

  return (
    <div
      className={cx(
        'text-sm p-4 border border-maroon-500 border-dashed bg-white',
        className
      )}
    >
      <span
        className="text-coolGray-800 font-medium mr-1"
        data-test-id="splitsize-headline"
      >
        {headline}
      </span>
      <span
        className="font-medium text-maroon-600"
        data-test-id="informations-container"
      >
        {split_result}
      </span>
      {warning && (
        <Alert type="warn" className="flex items-center mt-6" showIcon={true}>
          {warning}
        </Alert>
      )}
      {show_other_split_options ? (
        <React.Fragment>
          <p className="py-4 text-coolGray-600">
            Unable to test the recommended number of splits? No problem.
          </p>

          <div className="flex items-center font-medium text-coolGray-800">
            <p>Select the right number:</p>
            <SingleSelect
              className="regular w-24 ml-4"
              isDisabled={isDisabled}
              onChange={(val) => {
                updateSplitSizeNumber(stringToNumber(val?.value) ?? 0)
              }}
              value={value?.toString()}
              menuPlacement="top"
              placeholder="Select an option"
              options={other_split_options.map((option: number) => ({
                label: option.toString(),
                value: option.toString(),
              }))}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default SplitSize
