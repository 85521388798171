import { Element } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import { ContentIcon } from 'common/components/ContentTypeBubble'
import { ContentWithProgress } from 'features/futurama/api/queries/useGetContentQuery'

import ApproveButton from './ApproveButton'
import RejectButton from './RejectButton'
export interface Props {
  title: string
  contentType: string
  elements: Element[]
  progress: ContentWithProgress['approval_progress']
  onApprove: () => void
  onReject: () => void
}

const ContentApprovalCard = ({
  title,
  contentType,
  elements,
  progress,
  onApprove,
  onReject,
}: Props) => {
  return (
    <div className="p-4 border-dashed border-2 border-blue-500">
      <div className="p-4 bg-coolGray-50">
        <span className="flex flex-row justify-between items-center">
          <span className="flex gap-3">
            <ContentIcon
              className={progress === 'rejected' ? 'text-red-400' : ''}
              key="content type"
              iconType={contentType}
            />
            <h2
              className={cx('text-base font-medium', {
                'text-coolGray-800': progress !== 'rejected',
                'text-red-400': progress === 'rejected',
              })}
            >
              {title}
            </h2>
          </span>
          <span className="flex gap-4">
            <RejectButton progress={progress} onReject={onReject} />
            <ApproveButton progress={progress} onApprove={onApprove} />
          </span>
        </span>
        <div className="border-dashed border-t-1 border-coolGray-300 my-4" />
        <div className="flex flex-col gap-4">
          {elements.map((element) => (
            <div key={element.element_id}>
              <h3 className="text-xs font-medium text-coolGray-800 mb-1">
                {element.name}
              </h3>
              <p className="text-sm font-normal text-coolGray-600">
                {element.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default ContentApprovalCard
