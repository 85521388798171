import { useMemo } from 'react'
import cx from 'classnames'
import Highcharts, { PlotSunburstLevelsOptions } from 'highcharts'
import highchartsSunburst from 'highcharts/modules/sunburst'
import { graphColors } from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/graphsColorPalette'

import HighchartsReact from 'common/components/AutoResizeChart'

import { Node } from '../../interfaces/nodes'

import styles from './NodesGraph.module.css'

highchartsSunburst(Highcharts)

type Props = {
  nodes: Node[]
  selectedRootId: string | undefined
  onNodeClick: (nodeId?: string, rootNodeId?: string) => void
}

// Hardcoded for now
const SELECTED_ROOT_NODE_ID = '5'
const MAX_NUMBER_OF_LEVELS = 4

const countLevels = (nodes: Node[], nodeId = SELECTED_ROOT_NODE_ID) => {
  const children = nodes.filter((obj) => obj.parent === nodeId)

  const maxLevels = children.reduce((maxLevels, child) => {
    const levels = countLevels(nodes, child.id)
    return Math.max(maxLevels, levels)
  }, 0)

  return maxLevels + 1
}

const getElementLevel = (nodes: Node[], nodeId = SELECTED_ROOT_NODE_ID) => {
  if (nodeId === SELECTED_ROOT_NODE_ID) {
    return 1
  }
  const node = nodes.find((item) => item.id === nodeId)
  if (node?.parent) {
    return 1 + getElementLevel(nodes, node?.parent)
  }
}

const NodesGraph = ({ nodes, selectedRootId, onNodeClick }: Props) => {
  const levels = useMemo(() => {
    const numberOfLevels = countLevels(nodes, selectedRootId)
    const currentLevel = getElementLevel(nodes, selectedRootId)

    const levelOptions: PlotSunburstLevelsOptions[] = [
      {
        level: 2,
        colorByPoint: true,
      },
      {
        level: 3,
        colorVariation: {
          key: 'brightness',
          to: -0.5,
        },
      },
      {
        level: 4,
        colorVariation: {
          key: 'brightness',
          to: 0.5,
        },
      },
    ]
    if (numberOfLevels > MAX_NUMBER_OF_LEVELS) {
      for (
        let i = currentLevel + MAX_NUMBER_OF_LEVELS;
        i < numberOfLevels + currentLevel;
        i++
      ) {
        levelOptions.push({
          level: i,
          levelSize: {
            value: 0,
          },
          dataLabels: {
            enabled: false,
          },
        })
      }
    }
    return levelOptions
  }, [nodes, selectedRootId])

  const options: Highcharts.Options = useMemo(
    () => ({
      plotOptions: {
        sunburst: {
          size: '100%',
          borderWidth: 2,
          point: {
            events: {
              click: function (event) {
                const rootNode = (event as any).point.series.rootNode
                const clickedNodeId = event.point.options.id

                onNodeClick(clickedNodeId, rootNode)
              },
            },
          },
        },
      },
      chart: {
        height: '800',
        style: {
          fontFamily: 'Roboto',
        },
        backgroundColor: 'transparent',
        events: {
          click: function (event) {
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur()
            }
          },
        },
      },

      credits: {
        enabled: false,
      },
      colors: ['#F9FAFB', ...graphColors.primary],
      title: undefined,
      series: [
        {
          type: 'sunburst',
          data: nodes,
          name: 'Root',
          allowTraversingTree: true,
          turboThreshold: 0,
          rootId: selectedRootId,
          cursor: 'pointer',
          breadcrumbs: {
            floating: true,
          },
          dataLabels: {
            format: '{point.name}',
            filter: {
              property: 'outerArcLength',
              operator: '>',
              value: 16,
            },
            style: {
              fontSize: '16px',
              textOutline: '0px',
              fontWeight: 'normal',
            },
            rotationMode: 'circular',
          },
          levels,
        },
      ],
      tooltip: {
        headerFormat: '',
        pointFormat: '<b>{point.name}</b>',
      },
    }),
    [levels, nodes, onNodeClick, selectedRootId]
  )

  return (
    <div
      data-cy="nodes-graph"
      data-testid="nodes-graph"
      className={cx('w-full', styles.container)}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default NodesGraph
