import cx from 'classnames'

import Alert from 'common/components/alert'
import Steps, { Step } from 'common/components/Steps'
import { Tick as TickIcon } from 'common/icons'
import { ReactComponent as Spinner } from 'common/icons/spinner/default.svg'
import { ReactComponent as Time } from 'common/icons/time/default.svg'

type Props = {
  campaignProgress: {
    test_creation_started: boolean
    test_creation_completed: boolean
    test_schedule_started: boolean
    test_scheduled: boolean
    test_results_received: boolean
  }
}

const SfmcIntegrationStatus = ({ campaignProgress }: Props) => {
  const iconStyle = 'inline-block h-6 w-6 min-w-6'

  const {
    test_creation_started,
    test_creation_completed,
    test_schedule_started,
    test_scheduled,
    test_results_received,
  } = campaignProgress

  if (
    (!test_creation_started &&
      !test_creation_completed &&
      !test_schedule_started &&
      !test_scheduled) ||
    test_results_received
  ) {
    return null
  }

  if (test_scheduled) {
    return (
      <Alert type="success" className="mb-6">
        <div>
          <TickIcon isDefaultColor={false} className="text-white" />
          <span className="ml-4">Jacquard experiment scheduled</span>
        </div>
        <div className="mt-4">
          This experiment is currently scheduled to collect data from Salesforce
          Marketing Cloud.
        </div>
      </Alert>
    )
  }

  return (
    <Alert type="success" className="mb-6">
      <Steps>
        <Step
          className={cx({ 'text-white': !test_creation_completed })}
          title="Jacquard setting up experiment on Salesforce Marketing Cloud"
          icon={
            test_creation_completed ? (
              <TickIcon isDefaultColor={false} />
            ) : (
              <Spinner className={cx(iconStyle, 'text-white')} />
            )
          }
        />
        <Step
          className={cx('text-white', {
            'text-coolGray-800':
              test_schedule_started && test_creation_completed,
          })}
          title="Jacquard experiment scheduled"
          icon={
            test_schedule_started && test_creation_completed ? (
              <Spinner className={cx(iconStyle, 'text-white')} />
            ) : (
              <Time className={cx(iconStyle, 'text-white')} />
            )
          }
        />
      </Steps>
      {test_schedule_started && (
        <div>
          You can leave and come back later once the experiment is setup. We
          will pop you over an email once it's ready.
        </div>
      )}
    </Alert>
  )
}

export default SfmcIntegrationStatus
