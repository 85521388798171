import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import RouterPromptStay from 'app/router/RouterPromptStay'

import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import { RegionChangeConfirmationModal } from 'common/components/confirmationModal'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import Scrollable from 'common/components/scrollable'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import { errorToast } from 'common/components/toastNotification'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import EmojisWidget from './components/EmojisWidget'
import ForbiddenWordsWidget from './components/ForbiddenWordsWidget'
import IncompatibleWordsWidget from './components/IncompatibleWordsWidget'
import LocationsWidget from './components/LocationsWidget'
import PronounsWidget from './components/PronounsWidget'
import SynonymsWidget from './components/SynonymsWidget'
import {
  initializePage,
  resetRegion,
  retrieveNodes,
  retrieveRegionsList,
  saveRegion,
  selectHasBeenEdited,
} from './store/regionsSlice'

const contentUpdateId = 3 // hardcoded for now

const RegionSettings = () => {
  useDocumentTitle('Region settings | Jacquard')
  const dispatch = useAppDispatch()

  const [regionSelected, setRegionSelected] =
    useState<string | undefined>(undefined)
  const [confirmationModalObj, setConfirmationModalObj] = useState<{
    showModal: boolean
    newRegion?: string
  }>({ showModal: false, newRegion: '' })

  // TODO: use useRegionsListQuery
  const regionsListQuery = useQuery(
    ['admin/regionsList'],
    () =>
      dispatch(retrieveRegionsList())
        .unwrap()
        .then((res) => {
          if (res[0]?.id) {
            setRegionSelected(res[0].id)
            return res
          }
        }),
    { refetchOnWindowFocus: false, refetchOnReconnect: false }
  )

  const regionsList = useAppSelector((state) => state.regions.regionsList)

  const regionQuery = useQuery(
    ['admin/region', regionSelected],
    () => dispatch(initializePage(regionSelected!)).unwrap(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: !!regionSelected,
    }
  )

  const pronounsNodesQuery = useQuery(
    ['pronounsNodes', contentUpdateId],
    () => dispatch(retrieveNodes(contentUpdateId)).unwrap(),
    { refetchOnWindowFocus: false }
  )

  const isSavingRegion = useAppSelector((state) => state.regions.isSavingRegion)
  const hasBeenEdited = useAppSelector(selectHasBeenEdited)

  const onChange = (val: SelectValue) => {
    if (hasBeenEdited) {
      setConfirmationModalObj({
        showModal: true,
        newRegion: val.value,
      })
    } else {
      setRegionSelected(val.value)
    }
  }

  if (regionsListQuery.error || pronounsNodesQuery.error) {
    return <ErrorPage />
  }

  if (regionQuery.error || pronounsNodesQuery.error) {
    return <ErrorPage />
  }

  if (
    regionsListQuery.isLoading ||
    regionQuery.isLoading ||
    pronounsNodesQuery.isLoading
  ) {
    return <Spinner />
  }
  return (
    <>
      <Scrollable
        content={
          <PageContainer className="max-w-318 sm:mx-auto pl-6 pt-8">
            <PageHeader>
              {confirmationModalObj.showModal && (
                <RegionChangeConfirmationModal
                  onConfirm={() => {
                    setConfirmationModalObj({ showModal: false, newRegion: '' })
                    setRegionSelected(confirmationModalObj.newRegion ?? '')
                  }}
                  onCancel={() => {
                    setConfirmationModalObj({
                      showModal: false,
                      newRegion: confirmationModalObj.newRegion,
                    })
                  }}
                />
              )}
              <div data-cy="header">
                <div className="text-xl text-coolGray-800 font-medium">
                  Settings
                </div>
                <div className="text-coolGray-400">
                  Configure the rules and settings for the selected region.
                </div>
              </div>
            </PageHeader>
            <div className="mb-5 mt-5">
              <SingleSelect
                className="w-36 flex-none"
                data-cy="region-select"
                name="region"
                label="Select your region"
                placeholder="Select your region"
                onChange={(value) => value && onChange(value)}
                value={regionSelected}
                options={regionsList}
              />
            </div>
            <div className="flex flex-col text-coolGray-400 gap-8 mt-5">
              <ForbiddenWordsWidget />
              <IncompatibleWordsWidget />
              <PronounsWidget />
              <LocationsWidget />
              <SynonymsWidget />
              <EmojisWidget />
            </div>
            <Footer />
          </PageContainer>
        }
        footer={
          hasBeenEdited ? (
            <BottomToolbar className="justify-center">
              <div className="max-w-318 xl:max-w-363 w-full flex justify-between">
                <Button
                  data-cy="cancel-button"
                  variant="danger"
                  className="mr-4 pointer-events-auto"
                  ghost
                  disabled={isSavingRegion}
                  onClick={() => dispatch(resetRegion())}
                >
                  Reset
                </Button>
                <Button
                  data-cy="save-button"
                  variant="success"
                  ghost
                  loading={isSavingRegion}
                  disabled={isSavingRegion}
                  onClick={() =>
                    dispatch(saveRegion())
                      .unwrap()
                      .catch((e) => {
                        errorToast(e.message)
                      })
                  }
                >
                  Save
                </Button>
              </div>
            </BottomToolbar>
          ) : undefined
        }
      />
      <RouterPromptStay
        shouldShow={hasBeenEdited}
        isSaving={false}
        onOK={() => {
          dispatch(resetRegion())
          return Promise.resolve(true)
        }}
      />
    </>
  )
}

export default RegionSettings
