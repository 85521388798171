import { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { ReadyState } from 'react-use-websocket'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'
import { Content as ContentType, NLGAction } from '@phrasee/phrasee-typings'
import { useQueryClient } from '@tanstack/react-query'
import { unifiedFlowPaths } from 'app/navigation/paths'

import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import useWebsockets, { WsResponseBase } from 'common/hooks/useWebsockets'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'
import { contentKeys } from 'features/unifiedFlow/api/queryKeys'
import { toggleElementsDisabled } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getAllElements } from '../helpers'

import useContent from './useContent'

const REFETCH_INTERVAL = 3 * 60000

interface WsResponse extends WsResponseBase {
  content?: ContentType
  elementId?: number
  errorMessage?: string
}

export const generateWsTopic = ({
  contentId,
  elementId,
  action,
}: {
  contentId: string
  elementId?: number
  action: NLGAction
}) => {
  let topic =
    elementId !== undefined
      ? `generate/${contentId}/${elementId}`
      : `generate/${contentId}`

  if (action !== undefined) {
    topic += `/${action}`
  }

  return topic
}

const useWebsocketStatus = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const [isLoading, setIsLoading] = useState(false)
  const [action, setAction] = useState<NLGAction>()
  const {
    lastJsonMessage,
    sendJsonMessage,
    readyState,
  }: {
    lastJsonMessage: WsResponse
    sendJsonMessage: SendJsonMessage
    readyState: ReadyState
  } = useWebsockets()

  const { content, refetch } = useContent()

  useEffect(() => {
    if (content && lastJsonMessage) {
      const elementId = lastJsonMessage.elementId
        ? Number(lastJsonMessage.elementId)
        : undefined
      const selectedElement = getAllElements(content).find(
        (element) => element.element_id === elementId
      )
      const action = selectedElement
        ? selectedElement.ws_status?.action
        : content.ws_status?.action
      const wsTopic = generateWsTopic({
        contentId: content._id,
        elementId,
        action: action || 'variants',
      })

      if (lastJsonMessage.topics?.includes(wsTopic)) {
        if (lastJsonMessage.status === 'success') {
          queryClient.setQueryData(
            contentKeys.content(content._id),
            lastJsonMessage.content
          )

          sendJsonMessage({
            action: 'unsubscribe',
            topics: [wsTopic],
          })

          if (action === 'variants') {
            history.push(
              generatePath(unifiedFlowPaths.generate, {
                contentId: content._id,
              })
            )
          }
        } else if (lastJsonMessage.status === 'error' && content) {
          queryClient.invalidateQueries(contentKeys.content(content._id))
          errorToast(lastJsonMessage.errorMessage)
          dispatch(toggleElementsDisabled(false))
        }
      }
    }
    // Respond only to lastJsonMessage changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])

  useEffect(() => {
    setIsLoading(false)
    setAction(undefined)

    if (content && readyState === ReadyState.OPEN) {
      if (content.ws_status?.status === 'pending') {
        setIsLoading(true)
        setAction(content.ws_status?.action)
        const wsTopic = generateWsTopic({
          contentId: content._id,
          action: content.ws_status?.action,
        })

        sendJsonMessage({
          action: 'subscribe',
          topics: [wsTopic],
        })
      }
      const allElements = getAllElements(content)
      allElements.forEach((element) => {
        if (
          (isComponentElement(element) && element.nlg_status === 'pending') ||
          (isTemplateElement(element) &&
            element.ws_status?.status === 'pending')
        ) {
          const wsTopic = generateWsTopic({
            contentId: content._id,
            elementId: element.element_id,
            action: element.ws_status?.action || 'variants',
          })
          setIsLoading(true)
          setAction('variants')
          sendJsonMessage({
            action: 'subscribe',
            topics: [wsTopic],
          })
        }
      })
    }
  }, [content, readyState, sendJsonMessage])

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined
    if (isLoading) {
      interval = setInterval(() => {
        refetch()
      }, REFETCH_INTERVAL)
    } else {
      clearInterval(interval)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isLoading, refetch])

  return {
    isWebsocketLoading: isLoading,
    websocketAction: action,
  }
}

export default useWebsocketStatus
