import isNumber from 'lodash/isNumber'

import Badge from 'common/components/Badge'
import { TextWithLineBreaks } from 'common/variantsUtils'

type Props = {
  index: number | undefined
  text: string
  isControlVariant: boolean
}

const VariantCell = ({ index, text, isControlVariant }: Props) => {
  return (
    <div className="flex items-center px-4 child-cell">
      {isControlVariant ? (
        <>
          <Badge
            size="small"
            className="mr-2 uppercase"
            text="Control"
            variant="neutral"
          />
          <TextWithLineBreaks text={text} />
        </>
      ) : isNumber(index) ? (
        <TextWithLineBreaks text={`${index + 1}. ${text}`} />
      ) : (
        <TextWithLineBreaks text={text} />
      )}
    </div>
  )
}

export default VariantCell
