import { ComponentProps } from 'react'
import { DropdownIndicatorProps } from 'react-select'

import BaseSelect from 'common/components/BaseSelect'
import { ArrowDropdownDown, Close, DropdownToggle } from 'common/icons'

const ClearButton = ({ onClearClick }: { onClearClick: () => void }) => (
  <button
    onClick={(e) => {
      // blur the input of react-select
      ;(document.activeElement as HTMLElement)?.blur()
      onClearClick()
    }}
  >
    <Close size={4} className="mr-2" />
  </button>
)

const DropdownButton = ({
  isOpened,
  onClose,
}: {
  isOpened: boolean
  onClose: () => void
}) => (
  <span style={{ paddingRight: 2 }}>
    {isOpened ? (
      <button onClick={onClose}>
        <ArrowDropdownDown size={7} className="text-coolGray-500" />
      </button>
    ) : (
      <DropdownToggle size={7} className="text-coolGray-500" />
    )}
  </span>
)

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { isOpened, onClearClick, onClose } = props.selectProps as Props
  return (
    <div {...props.innerProps}>
      {props.selectProps.inputValue ? (
        <ClearButton onClearClick={onClearClick} />
      ) : (
        <DropdownButton isOpened={isOpened} onClose={onClose} />
      )}
    </div>
  )
}

type Props = ComponentProps<typeof BaseSelect> & {
  isOpened: boolean
  onClearClick: () => void
  onClose: () => void
}

const CustomerAttributesDropdown = (props: Props) => {
  return (
    <div>
      <BaseSelect
        isDisabled={false}
        defaultInputValue={props.inputValue}
        isClearable={false}
        menuIsOpen={false}
        components={{ DropdownIndicator }}
        {...props}
      />
    </div>
  )
}

export default CustomerAttributesDropdown
