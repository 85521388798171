import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'
import { RootState } from 'redux/store'

export interface DashboardState {
  startDate: string | undefined
  endDate: string | undefined
  projectIds: string[]
  isDashboardLoading: boolean
  isSideBarVisible: boolean
}

const initialState: DashboardState = {
  startDate: moment().subtract(12, 'months').toISOString(),
  endDate: moment().toISOString(),
  projectIds: [],
  isDashboardLoading: true,
  isSideBarVisible: false,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    applyDateRange: (
      state,
      action: PayloadAction<{ start: Moment | null; end: Moment | null }>
    ) => {
      state.startDate = action.payload.start?.toISOString()
      state.endDate = action.payload.end?.toISOString()
      state.isDashboardLoading = true
    },
    updateIsDashboardLoading: (state, action: PayloadAction<boolean>) => {
      state.isDashboardLoading = action.payload
    },
    toggleIsSideBarVisible: (state) => {
      state.isSideBarVisible = !state.isSideBarVisible
    },
    selectProjects: (state, action: PayloadAction<string[]>) => {
      state.projectIds = action.payload
      state.isDashboardLoading = true
    },
    resetState: () => initialState,
  },
})

export const {
  applyDateRange,
  selectProjects,
  updateIsDashboardLoading,
  toggleIsSideBarVisible,
  resetState,
} = dashboardSlice.actions

export const selectStartDate = (state: RootState) => state.dashboard.startDate
export const selectEndDate = (state: RootState) => state.dashboard.endDate

export const selectProjectIds = (state: RootState) => state.dashboard.projectIds

export default dashboardSlice.reducer
