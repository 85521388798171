/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react'
import {
  graphConfig,
  Highcharts,
} from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/Graphs'

import HighchartsReact from 'common/components/AutoResizeChart'
import { useDeepCompare, useShallowCompare } from 'common/hooks/custom'
import { replaceNewLineEmojiAsLineBreak } from 'common/variantsUtils'

import { AdvancedVisualisationsData } from '../../../../interface'

const MAX_DATA_POINTS = 35
const StackedAreaGraph = ({
  results,
  average,
  selectedRows,
  variantColorMapping,
}: AdvancedVisualisationsData) => {
  const updatedResults: any = results.map((result) => ({
    ...result,
    type: 'area',
    color: variantColorMapping?.get(result.id)?.color || '#000',
    name: replaceNewLineEmojiAsLineBreak(result.name),
  }))
  const firstDate = average ? average[0][0] : 0
  const dayIntervalInMms = 1000 * 3600 * 24
  const tickPositions = average?.map((position) => position[0])
  const isDataTooBig = average ? average.length > MAX_DATA_POINTS : false
  const selecedRowsIds = selectedRows?.map((row: any) => row._id)
  const filteredVariants = updatedResults.filter((variant: any) =>
    selecedRowsIds?.includes(variant.id)
  )
  const hasChangedSelectedRows = useShallowCompare(selectedRows)
  const hasResultsDataChanged = useDeepCompare(updatedResults)
  const [options, setOptions] = useState<Partial<Highcharts.Options>>({
    ...graphConfig,
    navigator: {
      series: {
        data: average,
        color: '#61bec7',
        type: 'areaspline',
      },
    },
    xAxis: {
      // interval in mms (24hrs)
      tickInterval: isDataTooBig ? dayIntervalInMms : undefined,
      startOnTick: true,
      minPadding: 0.01,
      type: 'datetime',
      labels: {
        formatter: (chart: any) => {
          return Highcharts.dateFormat(
            isDataTooBig ? '%b %e' : '%b %e - %H:%M',
            chart.value
          )
        },
        style: {
          ...(graphConfig.xAxis as Highcharts.XAxisOptions)?.labels?.style,
        },
      },
      gridLineColor: '#e4e4e4',
      tickPositions: isDataTooBig ? undefined : tickPositions,
    },
    yAxis: {
      ...graphConfig.yAxis,
      tickInterval: undefined,
      labels: {
        formatter: function (axis) {
          return Highcharts.numberFormat(axis.value as number, 0)
        },
      },
    },
    chart: {
      type: 'area',
      style: {
        fontFamily: 'Roboto',
      },
      margin: [0, 0, 30, 70],
    },
    tooltip: {
      padding: 16,
      shared: false,
      split: false,
      useHTML: false,
      borderRadius: 5,
      backgroundColor: '#ffffff',
      shadow: true,
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
      },
      series: {
        stacking: 'normal',
        pointStart: firstDate,
        dataGrouping: {
          enabled: true,
          forced: true,
        },
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [],
  })

  useEffect(() => {
    if (hasChangedSelectedRows) {
      setOptions({ series: filteredVariants })
    } else if (hasResultsDataChanged) {
      setOptions({ series: updatedResults })
    }
  }, [
    hasChangedSelectedRows,
    hasResultsDataChanged,
    filteredVariants,
    updatedResults,
  ])

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={options}
    />
  )
}

export default StackedAreaGraph
