import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { isEqual } from 'lodash'

import { TreeNode } from 'common/components/catalog/Catalog'
import ErrorPage from 'common/components/error/ErrorPage'
import CategoryPerformance from 'common/components/personalizationReport/CategoryPerformance'
import Spinner from 'common/components/spinner'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { CustomerAttributes, Variant } from 'features/personalization/api'
import Filters from 'features/personalization/components/report/Filters'
import Header from 'features/personalization/personalizationsPage/Header'
import type { PersonalizationInfo } from 'features/personalization/personalizationsPage/interfaces'

import useGetFiltersQuery from './api/queries/useGetFiltersQuery'
import useGetMetricsQuery from './api/queries/useGetMetricsQuery'
import useGetPersonalizationsQuery from './api/queries/useGetPersonalizationsQuery'
import useGetVariantsQuery from './api/queries/useGetVariantsQuery'
import Graphs from './graphs/Graphs'
import VariantTable from './VariantTable'

type Props = {
  personalizationId: string
  mode: 'report' | 'results'
}

const PersonalizationReport = ({ personalizationId, mode }: Props) => {
  const filtersQuery = useGetFiltersQuery()
  const productCategories = filtersQuery.data?.productCategories
  const initialCustomerAttributes = filtersQuery.data?.initialCustomerAttributes

  const [selectedCustomerAttributes, setSelectedCustomerAttributes] =
    useState<TreeNode | undefined>(undefined)
  const [selectedProductCategories, setSelectedProductCategories] =
    useState<TreeNode | undefined>(undefined)

  const [mappedCustomerAttributes, setMappedCustomerAttributes] =
    useState<CustomerAttributes>([])

  const personalizationsQuery = useGetPersonalizationsQuery()
  const currentPersonalization: PersonalizationInfo | undefined =
    personalizationsQuery.data?.find(
      (personalization) => personalization.id === personalizationId
    )
  // We use the first channel for now until we support multiple channels
  const channelId = currentPersonalization?.channels[0]

  const filteredCustomerAttributesTemp: CustomerAttributes | undefined =
    initialCustomerAttributes !== undefined
      ? initialCustomerAttributes.reduce(
          (acc: CustomerAttributes, initialItem) => {
            const mappedItem = mappedCustomerAttributes.find(
              (item) => item.name === initialItem.name
            )

            if (
              mappedItem &&
              Object.keys(selectedCustomerAttributes ?? {}).includes(
                initialItem.name
              )
            ) {
              if (!isEqual(initialItem, mappedItem)) {
                acc.push(mappedItem)
              }
            }

            return acc
          },
          []
        )
      : undefined

  const filteredCustomerAttributes = isEqual(
    initialCustomerAttributes,
    mappedCustomerAttributes
  )
    ? undefined
    : filteredCustomerAttributesTemp?.length === 0
    ? []
    : filteredCustomerAttributesTemp

  const hasAttributesChanged = useMemo(
    () =>
      compareAttributeArrays(
        mappedCustomerAttributes,
        initialCustomerAttributes
      ),
    [initialCustomerAttributes, mappedCustomerAttributes]
  )

  const filteredProductCategories = isEqual(
    productCategories,
    selectedProductCategories
  )
    ? undefined
    : Object.keys(selectedProductCategories ?? {}).length === 0
    ? {}
    : selectedProductCategories

  const metricsQuery = useGetMetricsQuery({
    channelId,
    personalizationId,
    customerAttributes: filteredCustomerAttributes,
    productCategories: filteredProductCategories,
  })

  const variantsQuery = useGetVariantsQuery({
    personalizationId,
    customerAttributes: filteredCustomerAttributes,
    productCategories: filteredProductCategories,
  })

  // Will filter the variants on the frontend until we wire up the backend calling
  const filteredVariants: Variant[] =
    variantsQuery.data?.variants.filter(
      (variant) => variant.channel === currentPersonalization?.channels[0]
    ) ?? []

  useEffect(() => {
    setSelectedCustomerAttributes(filtersQuery.data?.customerAttributes)
    setSelectedProductCategories(filtersQuery.data?.productCategories ?? {})
    setMappedCustomerAttributes(
      filtersQuery.data?.initialCustomerAttributes ?? []
    )
  }, [filtersQuery.data])

  const getState = () => {
    if (
      personalizationsQuery.isLoading ||
      filtersQuery.isLoading ||
      metricsQuery.isLoading ||
      variantsQuery.isLoading
    ) {
      return 'loading'
    } else if (
      personalizationsQuery.isError ||
      filtersQuery.isError ||
      metricsQuery.isError ||
      variantsQuery.isError
    ) {
      return 'error'
    } else {
      return 'success'
    }
  }
  const state = getState()

  return (
    <>
      {
        {
          success: (
            <>
              {mode === 'report' && (
                <Header
                  trainingContent={currentPersonalization?.trainingContent}
                />
              )}

              <div
                className={cx({
                  'flex flex-1 bg-warmGray-50 overflow-y-auto':
                    mode === 'report',
                  'flex flex-col gap-8 w-full': mode === 'results',
                })}
              >
                <div className="flex flex-col flex-1 w-full bg-coolGray-50 gap-8 px-6 pb-8 h-fit">
                  {mode === 'results' && (
                    <Widget type="basic">
                      <WidgetHeader
                        title="Results: Subject lines"
                        subtitle="View your results based on inputs and dynamic optimization."
                      />
                      <div className="flex flex-col flex-1 w-full gap-0 px-6">
                        <Filters
                          campaignType={currentPersonalization?.useCase}
                          channels={currentPersonalization?.channels}
                          customerAttributes={
                            filtersQuery.data?.customerAttributes ?? {}
                          }
                          selectedCustomerAttributes={
                            selectedCustomerAttributes ?? {}
                          }
                          onClickCustomerAttributes={({ treeNode, mapped }) => {
                            setSelectedCustomerAttributes(treeNode)
                            mapped && setMappedCustomerAttributes(mapped)
                          }}
                          productCategories={
                            filtersQuery.data?.productCategories ?? {}
                          }
                          selectedProductCategories={
                            selectedProductCategories ?? {}
                          }
                          onClickProductCategories={({ treeNode }) => {
                            setSelectedProductCategories(treeNode)
                          }}
                          storedTypedAttributes={mappedCustomerAttributes}
                          hasAttributesChanged={hasAttributesChanged}
                        />
                        <Graphs
                          data={metricsQuery.data ?? []}
                          isDataLoading={metricsQuery.isLoading}
                        />
                      </div>
                    </Widget>
                  )}
                  {mode === 'report' && (
                    <>
                      <Filters
                        campaignType={currentPersonalization?.useCase}
                        channels={currentPersonalization?.channels}
                        customerAttributes={
                          filtersQuery.data?.customerAttributes ?? {}
                        }
                        selectedCustomerAttributes={
                          selectedCustomerAttributes ?? {}
                        }
                        onClickCustomerAttributes={({ treeNode, mapped }) => {
                          setSelectedCustomerAttributes(treeNode)
                          mapped && setMappedCustomerAttributes(mapped)
                        }}
                        productCategories={
                          filtersQuery.data?.productCategories ?? {}
                        }
                        selectedProductCategories={
                          selectedProductCategories ?? {}
                        }
                        onClickProductCategories={({ treeNode }) => {
                          setSelectedProductCategories(treeNode)
                        }}
                        storedTypedAttributes={mappedCustomerAttributes}
                        hasAttributesChanged={hasAttributesChanged}
                      />
                      <Graphs
                        data={metricsQuery.data ?? []}
                        isDataLoading={metricsQuery.isLoading}
                      />
                    </>
                  )}
                  <CategoryPerformance
                    personalizationId={personalizationId}
                    customerAttributes={filteredCustomerAttributes}
                    productCategories={filteredProductCategories}
                  />

                  <VariantTable
                    channelId={channelId}
                    count={variantsQuery.data?.totalCount ?? 0}
                    data={filteredVariants ?? []}
                  />
                </div>
              </div>
            </>
          ),
          loading: (
            <div className="w-full">
              <Spinner data-testid="contents-loader" />
            </div>
          ),
          error: <ErrorPage />,
        }[state]
      }
    </>
  )
}

export default PersonalizationReport

function compareAttributeArrays(
  arr1: CustomerAttributes | undefined,
  arr2: CustomerAttributes | undefined
) {
  const a1 = [...(arr1 ?? [])].sort((a, b) => a.name.localeCompare(b.name))
  const a2 = [...(arr2 ?? [])].sort((a, b) => a.name.localeCompare(b.name))

  return isEqual(a1, a2)
}
