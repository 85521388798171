/* eslint-disable max-lines */
import React from 'react'
import { connect } from 'react-redux'
import { Cascader, Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import type { Moment } from 'moment'
import moment from 'moment-timezone'
import { RESET_ENGAGE_SFMC_STATE } from 'redux/actionTypes'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'
import helpers from 'workflow/utils/helpers'

import Alert from 'common/components/alert'
import Button from 'common/components/button/Button'
import Card from 'common/components/Card'
import Checkbox from 'common/components/checkbox'
import DatePickerAntd from 'common/components/DatePicker'
import SingleSelect from 'common/components/singleSelect'
import Spinner from 'common/components/spinner'
import TimePicker from 'common/components/TimePicker'
import { Time as TimeIcon } from 'common/icons'

import {
  cancelSends,
  fetchSendDefinitions,
  resetActionsStates,
  scheduleSFMCCampaign,
} from '../../../Workflow.actions'
import HelpError from '../HelpError'
import SuccessButton from '../SuccessButton'

import AmpScript from './AmpScript'
import SfmcIntegrationStatus from './SfmcIntegrationStatus'

import styles from '../Integrations.module.css'

const { shouldShowNextHour } = helpers
const FormItem = Form.Item
const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT
const MAX_UIE_EMAIL_LENGTH = 50
const uieEmailLengthErrorMessage = `Your user-initiated email's name must be less than ${MAX_UIE_EMAIL_LENGTH} characters`
const campaignNameLengthErrorMessage = `Your experiment name must be less than ${MAX_UIE_EMAIL_LENGTH} characters long, please edit it and try again.`

const hasValidLength = (value: string | undefined) =>
  !!(value && value.length < MAX_UIE_EMAIL_LENGTH)

export const initialState = {
  emailValue: {
    value: undefined,
    isInError: false,
    message: 'Please select an option.',
  },
  dateValue: {
    value: undefined,
    isInError: false,
    message: 'You have to select both, date and time.',
  },
  timeValue: {
    value: undefined,
    isInError: false,
    message: 'Please select time.',
  },
  hoursValue: {
    value: undefined,
    isInError: false,
    message: 'Please select hours.',
  },
  autoDeployValue: {
    value: true,
  },
  showCancelConfirmation: false,
  hasUieLengthError: false,
  hasCampaignNameLengthError: false,
}

class SfmcIntegration extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const {
      token,
      businessUnit,
      stack,
      projectId,
      fetchSendDefinitionsAction,
    } = this.props
    fetchSendDefinitionsAction(businessUnit, token, stack, projectId)
  }

  componentWillUnmount() {
    const { resetSfmcState } = this.props

    resetSfmcState()
  }

  onChangeUpdateState = (value, type) => {
    const { [`${type}Value`]: unMutedState } = this.state
    const finalValue = type === 'hours' ? Number(value) : value

    this.setState({
      [`${type}Value`]: {
        ...unMutedState,
        value: finalValue,
        isInError: false,
      },
    })
  }

  createResources = () => {
    if (this.isFormValid('creation')) {
      const {
        token,
        campaignId,
        campaignName,
        subjectLines,
        projectId,
        timeZone,
        businessUnit,
        splitListSize,
        stack,
        hasApprovals: has_approvals,
        scheduleSFMCCampaignAction,
      } = this.props
      const {
        emailValue: { value: sendDefinitionValues },
        hoursValue: { value: waitTime },
        autoDeployValue: { value: scheduleFinal },
        dateValue: { value: date },
        timeValue: { value: time },
      } = this.state
      const sendDefinitionValue = [...sendDefinitionValues].reverse()[0]
      const data: any = {
        campaign_id: campaignId,
        campaign_name: campaignName,
        subject_lines: subjectLines,
        project_id: projectId,
        timezone: timeZone,
        business_unit: businessUnit,
        splits_list_size: splitListSize,
        stack,
        send_definition_customer_key: sendDefinitionValue,
        schedule_final: scheduleFinal,
        has_approvals,
        type: 'resource_creation',
      }
      if (date && time) {
        data.send_date = `${date}T${time}:00`
      }
      if (waitTime) {
        data.wait_time = waitTime
      }
      return scheduleSFMCCampaignAction(data, token)
    }
    return true
  }

  handleClick = () => {
    if (this.isFormValid('schedule')) {
      const {
        token,
        campaignId,
        campaignName,
        subjectLines,
        projectId,
        timeZone,
        businessUnit,
        splitListSize,
        stack,
        hasApprovals: has_approvals,
        scheduleSFMCCampaignAction,
        campaignProgress,
      } = this.props
      const {
        emailValue: { value: sendDefinitionValues },
        hoursValue: { value: waitTime },
        autoDeployValue: { value: scheduleFinal },
        dateValue: { value: date },
        timeValue: { value: time },
      } = this.state
      const sendDate = `${date}T${time}:00`
      const data: any = {
        campaign_id: campaignId,
        campaign_name: campaignName,
        send_date: sendDate,
        subject_lines: subjectLines,
        project_id: projectId,
        timezone: timeZone,
        business_unit: businessUnit,
        splits_list_size: splitListSize,
        stack,
        wait_time: waitTime,
        schedule_final: scheduleFinal,
        has_approvals,
      }
      if (campaignProgress.test_creation_completed) {
        data.type = 'resource_schedule'
      }
      if (sendDefinitionValues) {
        data.send_definition_customer_key = [
          ...sendDefinitionValues,
        ].reverse()[0]
      }
      return scheduleSFMCCampaignAction(data, token)
    }
    return true
  }

  deleteResources = () => {
    const { cancelSends, campaignId, stack, projectId } = this.props
    return cancelSends(
      campaignId,
      projectId,
      'sfmcv2',
      stack,
      'complete_cancel'
    )
  }

  cancelCampaign = async () => {
    const { cancelSends, campaignId, stack, projectId } = this.props
    await cancelSends(campaignId, projectId, 'sfmcv2', stack, 'cancel_final')
    this.setState(initialState)
  }

  filterSearch = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )
  }

  disableDate = (current: Moment | null): boolean => {
    const { timeZone } = this.props
    return current
      ? current.tz(timeZone) <
          moment().tz(timeZone).subtract(1, 'days').endOf('day') ||
          current.tz(timeZone) > moment().tz(timeZone).add(26, 'days')
      : false
  }

  getSelection = () => {
    const { timeZone } = this.props
    const nowHour = moment().tz(timeZone).add(30, 'minutes').hour()
    const nowMinutes = moment().tz(timeZone).add(30, 'minutes').minutes()
    const additionalHourValue = shouldShowNextHour(nowMinutes) ? 1 : 0
    const h = nowHour + additionalHourValue
    return moment(`${h}:${nowMinutes}`, 'HH:mm')
  }

  getDisabledHours = () => {
    const {
      dateValue: { value: selectedDate },
    } = this.state
    if (selectedDate && moment(selectedDate) > moment()) {
      return []
    }
    const { timeZone } = this.props
    const nowHourIncludingTimeZoneAndExtraTime = moment()
      .tz(timeZone)
      .add(30, 'minutes')
      .hour()
    const nowMinutesIncludingTimeZoneAndExtraTime = moment()
      .tz(timeZone)
      .add(30, 'minutes')
      .minutes()
    const hoursToDisable = shouldShowNextHour(
      nowMinutesIncludingTimeZoneAndExtraTime
    )
      ? nowHourIncludingTimeZoneAndExtraTime + 1
      : nowHourIncludingTimeZoneAndExtraTime
    // if selected date is in the future, do not disable hours.
    return [...Array(hoursToDisable).keys()]
  }

  handleClickCreateAssets = () => {
    const { campaignProgress } = this.props
    return !campaignProgress.test_creation_completed
      ? this.createResources()
      : this.deleteResources()
  }

  getDisabledMinutes = () => {
    const {
      dateValue: { value: selectedDate },
      timeValue: { value: selectedTime },
    } = this.state
    // If selected date is in future do not disable anything and return.
    if (selectedDate && moment(selectedDate) > moment()) {
      return []
    }
    const { timeZone } = this.props
    const extraTime = 30
    const nowMinutesIncludingTimeZoneAndExtraTime = moment()
      .tz(timeZone)
      .add(extraTime, 'minutes')
      .minutes()
    const nowHourIncludingTimeZoneAndExtraTime = moment()
      .tz(timeZone)
      .add(extraTime, 'minutes')
      .hour()
    // When user chooses a future hour, we need to figure
    // out disabled minutes for the new hour:
    const selectedHours = selectedTime && Number(selectedTime.split(':')[0])
    if (
      selectedHours &&
      selectedHours - nowHourIncludingTimeZoneAndExtraTime >= 1
    ) {
      return []
    }
    const minutesToDisable = shouldShowNextHour(
      nowMinutesIncludingTimeZoneAndExtraTime
    )
      ? 0
      : nowMinutesIncludingTimeZoneAndExtraTime
    // If NOT, figure out the UI.
    return [...Array(minutesToDisable).keys()]
  }

  isFormValid = (type = 'schedule') => {
    const {
      emailValue: { value: emailValue },
      dateValue: { value: dateValue },
      timeValue: { value: timeValue },
      hoursValue: { value: hoursValue },
    } = this.state
    const { campaignProgress } = this.props
    if (!emailValue && !campaignProgress.test_creation_completed) {
      this.setState({
        emailValue: {
          ...this.state.emailValue,
          isInError: true,
        },
      })
      return false
    }
    if ((!dateValue || dateValue === 'Invalid date') && type === 'schedule') {
      this.setState({
        dateValue: {
          ...this.state.dateValue,
          isInError: true,
        },
      })
      return false
    }
    if ((!timeValue || timeValue === 'Invalid date') && type === 'schedule') {
      this.setState({
        timeValue: {
          ...this.state.timeValue,
          isInError: true,
        },
      })
      return false
    }
    if (!hoursValue && type === 'schedule') {
      this.setState({
        hoursValue: {
          ...this.state.hoursValue,
          isInError: true,
        },
      })
      return false
    }
    return true
  }

  render() {
    const {
      sendDefinitionName,
      emailList,
      isWaiting,
      isLoadingSchedule,
      timeZone,
      sendDate,
      isSendDefinitionLoading,
      hasApprovals,
      campaignProgress,
      isScheduleButtonDisabled,
      isCancelingSchedule,
      isLoadingResourceCreation,
      isTestScheduled,
      hasTestScheduleStarted,
      waitTime,
      engageSfmcError,
      ampScript,
      campaignId,
      campaignName,
      projectId,
      token,
    } = this.props
    const {
      autoDeployValue: { value: autoDeployValue },
      showCancelConfirmation,
      emailValue,
      emailValue: { isInError: isEmailValueInError, message: emailMessage },
      dateValue: {
        isInError: isDateValueInError,
        message: dateMessage,
        value: dateValue,
      },
      timeValue: { isInError: isTimeValueInError, value: timeValue },
      hoursValue: {
        isInError: isHoursValueInError,
        message: hoursMessage,
        value: hoursValue,
      },
      hasUieLengthError,
      hasCampaignNameLengthError,
    } = this.state

    const {
      test_creation_completed: hasTestCreationCompleted,
      test_creation_started: hasTestCreationStarted,
      final_send_cancelled: finalSendCancelled,
      test_cancelled: testCancelled,
      test_results_received: testResultsReceived,
      final_send_schedule_started: finalSendScheduleStarted,
      final_send_scheduled: finalSendScheduled,
      errors,
    } = campaignProgress

    const testScheduledErrorMessage = errors?.test_scheduled_error_message

    const hasEmailSelected =
      (Array.isArray(emailValue.value) && emailValue.value.length > 0) ||
      sendDefinitionName

    const shouldShowCancel =
      (!finalSendCancelled || !testCancelled) &&
      !testResultsReceived &&
      isTestScheduled &&
      !finalSendScheduleStarted

    const isScheduleDisabled =
      hasTestScheduleStarted || isTestScheduled || isLoadingResourceCreation

    return (
      <React.Fragment>
        {!isWaiting ? (
          <>
            <Drawer.Content>
              <Form>
                <div className="text-coolGray-500 font-medium text-base mb-6">
                  Schedule your split test in Salesforce Marketing Cloud
                  account.
                </div>

                <SfmcIntegrationStatus campaignProgress={campaignProgress} />

                {(hasApprovals || ampScript) && (
                  <AmpScript
                    ampScript={ampScript}
                    projectId={projectId}
                    campaignName={campaignName}
                    token={token}
                    campaignId={campaignId}
                  />
                )}
                <FormItem
                  data-test-id="uie-form-item"
                  help={
                    isEmailValueInError ? (
                      <HelpError>{emailMessage}</HelpError>
                    ) : undefined
                  }
                  label="User-initiated email"
                >
                  {hasTestCreationStarted ? (
                    <SingleSelect
                      data-test-id="Select"
                      isDisabled={true}
                      value="1"
                      onChange={() => undefined}
                      options={[{ label: sendDefinitionName, value: '1' }]}
                    />
                  ) : (
                    <div id="test">
                      <Spinner isSpinning={isSendDefinitionLoading}>
                        <Cascader
                          data-test-id="Cascader"
                          options={emailList}
                          onChange={(val, selectedOptions) => {
                            const label =
                              selectedOptions &&
                              (selectedOptions[selectedOptions?.length - 1]
                                .label as string)
                            if (hasValidLength(label)) {
                              this.setState({ hasUieLengthError: false })
                            } else {
                              this.setState({ hasUieLengthError: true })
                            }
                            if (hasValidLength(campaignName)) {
                              this.setState({
                                hasCampaignNameLengthError: false,
                              })
                            } else {
                              this.setState({
                                hasCampaignNameLengthError: true,
                              })
                            }
                            this.onChangeUpdateState(val, 'email')
                          }}
                          showSearch={{ filter: this.filterSearch }}
                          fieldNames={{ value: 'customer_key' }}
                          placeholder="Select an email"
                        />
                      </Spinner>
                    </div>
                  )}
                  <GuidingText
                    className="mt-3"
                    text="User-initiated emails that contain more than one
                   target recipents data extension cannot be selected."
                  />
                </FormItem>
                {!hasTestCreationCompleted &&
                  !isSendDefinitionLoading &&
                  !emailList[0]?.children && (
                    <Alert
                      data-test-id="uieAlert"
                      type="warn"
                      className="mt-3 relative -top-3 mb-6"
                    >
                      <span className="leading-6">
                        We can currently only list the last 3 months of
                        user-initiated emails.
                      </span>
                    </Alert>
                  )}
                {hasEmailSelected && (
                  <Card className="mb-6">
                    <div className="mb-4">
                      <TimeIcon className="mr-4 text-gray-400" />
                      <span className="text-base font-medium text-coolGray-800">
                        Optimization schedule
                      </span>
                    </div>
                    <FormItem
                      label="When would you like to send the split test emails?"
                      help={
                        isDateValueInError || isTimeValueInError ? (
                          <HelpError>{dateMessage}</HelpError>
                        ) : undefined
                      }
                    >
                      <div className="flex justify-between">
                        <DatePickerAntd
                          data-test-id="DatePicker"
                          ariaLabel="DatePicker"
                          allowClear={false}
                          disabledDate={this.disableDate}
                          value={
                            sendDate
                              ? moment(sendDate)
                              : dateValue
                              ? moment(dateValue, 'Y-MM-DD')
                              : undefined
                          }
                          disabled={isScheduleDisabled}
                          onChange={(date: Moment | null) => {
                            if (date) {
                              this.onChangeUpdateState(
                                moment(date, DATE_FORMAT).format('Y-MM-DD'),
                                'date'
                              )
                            }
                          }}
                        />
                        <TimePicker
                          data-test-id="TimePicker"
                          allowClear={false}
                          className={styles.integrationTimePicker}
                          suffixIcon={<TimeIcon size={6} />}
                          format="HH:mm"
                          disabledHours={
                            timeZone ? this.getDisabledHours : () => []
                          }
                          disabledMinutes={
                            timeZone ? this.getDisabledMinutes : () => []
                          }
                          hideDisabledOptions
                          defaultOpenValue={
                            timeZone ? this.getSelection() : undefined
                          }
                          value={
                            sendDate
                              ? moment(sendDate).tz(timeZone)
                              : timeValue
                              ? moment(timeValue, 'HH:mm')
                              : undefined
                          }
                          onChange={(val: Moment | null) => {
                            if (val) {
                              this.onChangeUpdateState(
                                moment(val, 'HH:mm').format('HH:mm'),
                                'time'
                              )
                            }
                          }}
                          minuteStep={15}
                          disabled={isScheduleDisabled}
                        />
                      </div>
                    </FormItem>
                    <FormItem
                      help={
                        isHoursValueInError ? (
                          <HelpError>{hoursMessage}</HelpError>
                        ) : undefined
                      }
                      className="w-44"
                      label="How long to wait before sending the winner?"
                    >
                      <SingleSelect
                        data-testid="Select"
                        placeholder="Select wait hours"
                        menuPlacement="top"
                        onChange={(val) =>
                          this.onChangeUpdateState(val?.value, 'hours')
                        }
                        isDisabled={isScheduleDisabled}
                        value={(waitTime || hoursValue)?.toString()}
                        options={new Array(11)
                          .fill(undefined)
                          .map((_, index) => ({
                            label: `${index + 2} hrs`,
                            value: `${index + 2}`,
                          }))}
                      />
                    </FormItem>
                    <div className="mb-6">
                      <div className="font-medium text-coolGray-800">
                        Account Timezone: {timeZone}
                      </div>
                      <GuidingText
                        className="mt-0"
                        text="Input your experiment time details
                    based on your Salesforce Account timezone."
                      />
                    </div>
                    <Checkbox
                      label="Automatically deploy the winner?"
                      isChecked={
                        isTestScheduled ? finalSendScheduled : autoDeployValue
                      }
                      isDisabled={isScheduleDisabled}
                      onChange={(isChecked: boolean) => {
                        this.onChangeUpdateState(isChecked, 'autoDeploy')
                      }}
                    />
                  </Card>
                )}
                {!isTestScheduled && !hasTestScheduleStarted ? (
                  !hasTestCreationCompleted ? (
                    <Card className="mb-6 flex flex-col items-start">
                      <div className="text-coolGray-800 font-medium">
                        Test mode{' '}
                        <span className="text-coolGray-400 ml-1">
                          (Optional)
                        </span>
                      </div>
                      <div className="font-medium text-coolGray-500 my-4">
                        Looking to test your email first before scheduling this
                        experiment?
                      </div>
                      <ol
                        className="text-coolGray-400 font-medium mb-4
                   list-decimal ml-4"
                      >
                        <li className="pl-3">
                          Click the “Create email assets” button to generate all
                          the required email assets within Salesforce.
                        </li>
                        <li className="pl-3">
                          Then go into Salesforce and test away.
                        </li>
                        <li className="pl-3">
                          When you are happy come back here and schedule this
                          experiment.
                        </li>
                      </ol>
                      <Button
                        data-cy="sfmc-create-resources-button"
                        data-test-id="create-resources"
                        className="ant-btn-lg self-end"
                        variant="primary"
                        disabled={
                          hasCampaignNameLengthError ||
                          hasUieLengthError ||
                          hasTestCreationStarted
                        }
                        loading={
                          isLoadingResourceCreation || isCancelingSchedule
                        }
                        onClick={() => this.handleClickCreateAssets()}
                      >
                        Create email assets
                      </Button>
                    </Card>
                  ) : (
                    <Card className="mb-6 flex flex-col items-start">
                      <div className="text-coolGray-800 font-medium">
                        Done testing?
                      </div>
                      <div className="font-medium text-coolGray-500 my-4">
                        You can clear your data and assets for this experiment
                        if you like. We won’t affect any other experiments you
                        might have.
                      </div>
                      <Button
                        data-cy="sfmc-delete-resources-button"
                        data-test-id="delete-resources"
                        className="ant-btn-lg self-end bg-pink-600
                        border-pink-600"
                        variant="danger"
                        loading={
                          isLoadingResourceCreation || isCancelingSchedule
                        }
                        onClick={() => this.handleClickCreateAssets()}
                      >
                        Delete assets
                      </Button>
                    </Card>
                  )
                ) : undefined}
                {shouldShowCancel && !showCancelConfirmation && (
                  <Card className="mb-6 flex flex-col items-start">
                    <div className="text-coolGray-800 font-medium">
                      Cancel experiment?
                    </div>
                    <div className="font-medium text-coolGray-500 my-4">
                      This will stop importing data from Salesforce and cancel
                      this experiment. This will not delete any data you have
                      already received.
                    </div>
                    <Button
                      data-cy="sfmc-cancel-campaign-button"
                      data-test-id="cancel-campaign"
                      className="ant-btn-lg self-end bg-pink-600
                      border-pink-600"
                      variant="danger"
                      onClick={() =>
                        this.setState({ showCancelConfirmation: true })
                      }
                    >
                      Cancel
                    </Button>
                  </Card>
                )}
                {shouldShowCancel && showCancelConfirmation && (
                  <div
                    className="flex flex-col
              p-6 border border-coolGray-200"
                  >
                    <div>
                      <span className="text-2xl text-red-600 font-bold">
                        Whooooaaaaaa!
                      </span>
                    </div>
                    <div className="my-4">
                      Are you sure you want to{' '}
                      <span className="font-bold">cancel this experiment</span>?
                      If you do this you will have to setup a new schedule.
                    </div>
                    <div className="self-end">
                      <Button
                        ghost
                        data-cy="sfmc-no-button"
                        className="mr-4"
                        onClick={() =>
                          this.setState({ showCancelConfirmation: false })
                        }
                      >
                        Nooo!
                      </Button>
                      <Button
                        data-cy="sfmc-yes-button"
                        variant="danger"
                        loading={isCancelingSchedule}
                        onClick={this.cancelCampaign}
                      >
                        Yes, cancel
                      </Button>
                    </div>
                  </div>
                )}
              </Form>
            </Drawer.Content>
            <Drawer.Footer
              error={
                hasUieLengthError
                  ? uieEmailLengthErrorMessage
                  : hasCampaignNameLengthError
                  ? campaignNameLengthErrorMessage
                  : testScheduledErrorMessage || engageSfmcError
              }
            >
              {isTestScheduled ? (
                <SuccessButton text="Experiment scheduled" />
              ) : (
                <Button
                  data-test-id="Submit"
                  data-cy="sfmc-submit-button"
                  variant={
                    !isScheduleButtonDisabled && !isLoadingResourceCreation
                      ? 'primary'
                      : 'secondary'
                  }
                  disabled={
                    isScheduleButtonDisabled ||
                    isLoadingResourceCreation ||
                    hasUieLengthError ||
                    hasCampaignNameLengthError ||
                    hasTestScheduleStarted ||
                    (hasTestCreationStarted && !hasTestCreationCompleted)
                  }
                  loading={isLoadingSchedule}
                  onClick={() => this.handleClick()}
                >
                  {isLoadingSchedule ? 'Scheduling' : 'Schedule experiment'}
                </Button>
              )}
            </Drawer.Footer>
          </>
        ) : (
          <Spinner />
        )}
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    resetSfmcState: () => {
      dispatch(resetActionsStates(RESET_ENGAGE_SFMC_STATE))
    },
    // eslint-disable-next-line max-params
    fetchSendDefinitionsAction: (clientId, token, stack, projectId) =>
      dispatch(fetchSendDefinitions(clientId, token, stack, projectId)),
    scheduleSFMCCampaignAction: (collectedData, token) =>
      dispatch(scheduleSFMCCampaign(collectedData, token)),
    cancelSends: async (
      campaignId,
      projectId,
      token,
      typeOfIntegration,
      stack,
      cancelType
      // eslint-disable-next-line max-params
    ) => {
      dispatch({ type: RESET_ENGAGE_SFMC_STATE })
      await dispatch(
        cancelSends(
          campaignId,
          projectId,
          token,
          typeOfIntegration,
          stack,
          cancelType
        )
      )
    },
  }
}

function mapStateToProps(state) {
  const {
    campaignData,
    currentCampaignId,
    campaignName,
    projectId,
    isLoading,
    emailList,
    scheduleResponse,
    isSendDefinitionLoading,
    isWaitingState,
    isScheduleButtonDisabled,
    engageSfmcError,
    ampScript,
  } = state.campaignStates
  const { token, sfmcUserInfo } = state.authStates
  return {
    token,
    emailList,
    isWaiting: isLoading,
    isLoading,
    engageSfmcError,
    isScheduleButtonDisabled,
    isLoadingSchedule:
      isWaitingState.isWaitingFor === 'schedule' && isWaitingState.isWaiting,
    isLoadingResourceCreation:
      isWaitingState.isWaitingFor === 'resource_creation' &&
      isWaitingState.isWaiting,
    isCancelingSchedule:
      isWaitingState.isWaitingFor === 'cancel' && isWaitingState.isWaiting,
    sfmcUserId: sfmcUserInfo && sfmcUserInfo.sfmc_user_id,
    campaignId: currentCampaignId,
    campaignName,
    sendDefinitionName: campaignData && campaignData.send_definition_name,
    sendDate: campaignData.send_date,
    waitTime: campaignData.wait_time,
    numSplits: campaignData.num_splits,
    subjectLines: campaignData.sl,
    campaignProgress: (campaignData && campaignData.campaign_progress) || {},
    projectId,
    splitListSize: campaignData && campaignData.total_split_size,
    ampScript,
    hasApprovals:
      campaignData.campaign_configuration &&
      campaignData.campaign_configuration.integration_options &&
      campaignData.campaign_configuration.integration_options.has_approvals,
    scheduleResponse,
    isSendDefinitionLoading,
    hasTestScheduleStarted:
      campaignData?.campaign_progress?.test_schedule_started,
    isTestScheduled: campaignData?.campaign_progress?.test_scheduled,
    ...(state.sfmcUserStates.business_unit && {
      sfmcUserId: state.sfmcUserStates.sfmcUserId,
      timeZone: state.sfmcUserStates.zoneinfo,
      businessUnit: state.sfmcUserStates.business_unit,
      stack: state.sfmcUserStates.stack,
    }),
    ...(!state.sfmcUserStates.business_unit &&
      sfmcUserInfo && {
        sfmcUserId: sfmcUserInfo.sfmc_user_id,
        timeZone: sfmcUserInfo.zoneinfo,
        businessUnit: sfmcUserInfo.business_unit,
        stack: sfmcUserInfo.stack,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SfmcIntegration)
