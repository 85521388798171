import { Field } from 'react-final-form'
import moment from 'moment'

import DatePicker from 'common/components/datePicker/DatePicker'
import { FieldConfiguration } from 'common/components/dynamicFields/interfaces'
import FormItem from 'common/components/FormItem'

const { REACT_APP_DEFAULT_DATE_FORMAT } = process.env
const validateDate = (value: string | undefined) => {
  if (!value) {
    return 'Select a date.'
  }
}

type Props = {
  name: string
  config: FieldConfiguration
  isFocused?: boolean
  isDisabled?: boolean
}

const SendDate = ({ name, config, isFocused, isDisabled }: Props) => {
  return (
    <Field<string>
      name={name}
      validate={config.required ? validateDate : undefined}
    >
      {({ input, meta }) => (
        <FormItem
          label={config.label}
          htmlFor={input.name}
          error={meta.touched ? meta.error : undefined}
          className="w-full"
        >
          <DatePicker
            autoFocus={isFocused}
            onDateChange={(date) =>
              input.onChange(
                date ? date.format(REACT_APP_DEFAULT_DATE_FORMAT) : null
              )
            }
            date={input.value ? moment(input.value) : null}
            id={input.name}
            isOutsideRange={(date) => date.isBefore(moment().startOf('day'))}
            numberOfMonths={1}
            onBlur={input.onBlur}
            isTodayVisible
            disabled={isDisabled}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default SendDate
