export const isUncheduledOnAcoustic = (
  campaignProgress: any,
  splitsSent?: boolean,
  mailingExists?: boolean
) => {
  const { test_scheduled, test_schedule_started } = campaignProgress

  return (
    !splitsSent && !mailingExists && test_schedule_started && test_scheduled
  )
}
