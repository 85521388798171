import { Content } from '@phrasee/phrasee-typings'
import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'
import { ignoreNewLineEmoji } from 'common/variantsUtils'

import { ContentInfo } from '../../contentsPage/interfaces'
import { getContents } from '../api'
import { contentKeys } from '../queryKeys'

const mapContentToCard = (content: Content): ContentInfo => ({
  id: content?._id,
  name: ignoreNewLineEmoji(content?.name),
  createdDate: content?.created_date?.toString() ?? '',
  lastModified: content?.last_modified?.toString() ?? '',
  owner: content?.owner_id?.toString() ?? '',
  contributors: content?.contributors_user_id?.map(
    (user) => user?.userInitials!
  ) ?? [''],
  contentType: content?.content_type,
  elements: content?.elements,
})

const useGetContentsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  // FIXME it seems that accountId should be part of the query key to refresh when the account changes

  const getContentsQuery = useQuery(
    contentKeys.contents(),
    () => getContents(accountId),
    {
      // TODO: add filtering of active content in backend
      select: (data) =>
        data.filter((item) => item.status === 'active').map(mapContentToCard),
    }
  )

  return getContentsQuery
}

export default useGetContentsQuery
