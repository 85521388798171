import { Content } from '@phrasee/phrasee-typings'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { contentKeys } from '../queryKeys'
import { deleteContent } from '..'

const useDeleteContentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteContent, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (result) => {
      queryClient.setQueryData<Content[]>(
        contentKeys.contents(),
        (contents) => {
          if (contents) {
            return contents.filter((item) => item._id !== result.id)
          }
        }
      )
    },
  })
}

export default useDeleteContentMutation
