import Auth0Provider from 'auth/Auth0Provider'
import LogoutButton from 'auth/LogoutButton'

export const NoAccountsError = () => {
  return (
    <Auth0Provider>
      <div className="mb-2">
        There are no accounts assigned to your user, please log out and contact
        Jacquard for support.
      </div>
      <LogoutButton />
    </Auth0Provider>
  )
}
