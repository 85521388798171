import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'

import CenterPanel from '../../components/layout/CenterPanel'
import { getStepStatus } from '../../helpers'

import ContentTable from './contentTable'
import OptimizeResults from './optimizeResults'

const CenterSection = () => {
  const { data: selectedElement } = useSelectedComponentElement()
  const { content } = useContent()
  const getState = () => {
    if (!selectedElement) {
      return 'empty'
    } else if (selectedElement?.campaign_id) {
      return 'optimizeResults'
    } else {
      return 'contentTable'
    }
  }

  const state = getState()
  const briefStepsStatus = getStepStatus(content?.steps_status, 'brief')
  const isBriefStepsStatusReadOnly = briefStepsStatus === 'read-only'

  return (
    <CenterPanel className="pl-0 overflow-auto">
      {
        {
          empty: null,
          optimizeResults: (
            <OptimizeResults isReadOnly={isBriefStepsStatusReadOnly} />
          ),
          contentTable: (
            <ContentTable isReadOnly={isBriefStepsStatusReadOnly} />
          ),
        }[state]
      }
    </CenterPanel>
  )
}

export default CenterSection
