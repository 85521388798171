import Button from 'common/components/button/Button'
import { ArrowRight } from 'common/icons'

import { CampaignTypes } from './CampaignMetrics'

type Props = {
  campaignType: CampaignTypes
}

const UpgradeNotice = ({ campaignType }: Props) => {
  const openContactUsPage = () => {
    window.location.href = 'https://phrasee.co/contact/'
  }

  return (
    <div
      data-cy={`upgrade-${campaignType}-widget`}
      className="max-h-full p-6 box-border shadow-widget rounded-md bg-white"
    >
      <div className="flex flex-col justify-between items-start gap-4">
        <div className="flex items-center text-coolGray-50 font-bold text-xs rounded py-0.5 px-1 bg-coolGray-500">
          Upgrade
        </div>

        <div className="font-medium text-2xl text-coolGray-800">
          Elevate marketing with {campaignType} campaigns
        </div>

        <div className="font-normal text-base text-coolGray-500 pb-2">
          Inspire and engage customers with timely messaging. Craft compelling
          {` ${campaignType}`} copy and optimize in real-time for peak
          performance.
        </div>

        <Button
          variant="primary"
          data-cy={`upgrade-${campaignType}-project-button`}
          onClick={() => openContactUsPage()}
          suffixIcon={<ArrowRight size={4.5} isDefaultColor={false} />}
        >
          Upgrade now
        </Button>
      </div>
    </div>
  )
}

export default UpgradeNotice
