import { KeyboardEvent, TextareaHTMLAttributes, useState } from 'react'
import cx from 'classnames'

export const allowTabCharacter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
  if (e.key === 'Tab') {
    e.preventDefault()
    const start = e.currentTarget.selectionStart
    const end = e.currentTarget.selectionEnd
    e.currentTarget.value = `${e.currentTarget.value.substring(
      0,
      start
    )}\t${e.currentTarget.value.substring(end)}`
    e.currentTarget.selectionStart = e.currentTarget.selectionEnd = start + 1
  }
}

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  allowTab?: boolean
  showCharacterCount?: boolean
  className?: string
}

const Textarea: React.FC<Props> = ({
  allowTab = false,
  showCharacterCount = false,
  className,
  onKeyDown,
  onChange,
  children,
  maxLength,
  ...rest
}) => {
  const [characterCount, setCharacterCount] = useState(
    typeof rest.value === 'string' ? rest.value?.length : 0
  )

  return (
    <div className="relative">
      {showCharacterCount && (
        <div className="absolute right-0 -top-6 text-coolGray-400 text-sm">
          {characterCount} / {maxLength}
        </div>
      )}
      <textarea
        className={cx(
          `border border-coolGray-300 focus:border-maroon-500 caret-maroon-300 
         placeholder-coolGray-400 outline-none p-4 text-coolGray-800 w-full
         bg-coolGray-50 disabled:cursor-not-allowed disabled:border-coolGray-300`,
          { 'hover:border-maroon-300 ': !rest.disabled },
          className
        )}
        onChange={(e) => {
          if (showCharacterCount) {
            setCharacterCount(e.currentTarget.value.length)
          }
          onChange?.(e)
        }}
        onKeyDown={(e) => {
          if (allowTab) {
            allowTabCharacter(e)
          }
          onKeyDown?.(e)
        }}
        maxLength={maxLength}
        {...rest}
      />
    </div>
  )
}

export default Textarea
