import { IntegrationAccount } from '@phrasee/phrasee-typings/typings/integrations/accounts'

import Image from 'common/components/image'

import IntegrationSelectionButton from './IntegrationSelectionButton'

type Props = {
  account: IntegrationAccount
  accounts: IntegrationAccount[]
  contentId: string
  elementId: number
}

const IntegrationSelection = ({
  account,
  accounts,
  contentId,
  elementId,
}: Props) => {
  const { logoUrl, accountName, provider } = account

  return (
    <div>
      <span className="text-sm text-coolGray-400">
        Select an integration and share your message with your CEP or ESP.
      </span>
      <div className="flex mt-4 gap-6">
        <div className="flex justify-center items-center border border-gray-300 bg-coolGray-50 h-20 w-20 px-2 ">
          <Image isResponsive src={logoUrl} alt={`${accountName}-logo`} />
        </div>
        <div className="flex flex-col items-start">
          <div className="text-xl text-coolGray-800 font-medium capitalize-first mb-1">
            {provider}
          </div>
          <span className="text-sm text-coolGray-400">
            Connect your <span className="capitalize-first">{provider}</span>{' '}
            account with your Jacquard content.
          </span>
          <IntegrationSelectionButton
            classdName="mt-1"
            contentId={contentId}
            elementId={elementId}
            accounts={accounts}
            variant="link"
          >
            Change integration
          </IntegrationSelectionButton>
        </div>
      </div>
    </div>
  )
}

export default IntegrationSelection
