import Emoji from 'common/emoji'
import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'

type Props = {
  winnerVariant: string
  className?: string
}

const WinnerVariantInfo: React.FC<Props> = ({
  winnerVariant,
  className = '',
}: Props) => {
  return (
    <div className={`flex items-center gap-6 ${className}`}>
      <div className="w-15 h-15 rounded-full bg-emerald-200">
        <Emoji symbol="🏆" className="relative bottom-3 text-5xl" />
      </div>

      <div className="flex-grow text-base">
        <p className="text-maroon-500 font-bold">
          And the winner at the end of the test was...
        </p>
        <p className="text-coolGray-800 font-medium">
          {replaceNewLineEmojiAsElement(winnerVariant as string)}
        </p>
      </div>
    </div>
  )
}

export default WinnerVariantInfo
