import MultiGraphWrapper from 'common/components/MultiGraphWrapper'
import TabWidget from 'common/components/TabWidget'
import { useAppSelector } from 'common/hooks/redux'

import { WidgetEngage } from '../../../interfaces'
import { selectProjectIds } from '../../../store/dashboardSlice'

import IncrementalEventsWidget from './incrementalEvents'
import UpliftWidget from './uplift'

const UPLIFT_INFO = `Track average uplift performance across your
channels and their key metrics.`

type Props = {
  accountId: string
  widgetEngage: WidgetEngage
}

const EngageWidgets: React.FC<Props> = ({ widgetEngage, accountId }) => {
  const projectIds = useAppSelector(selectProjectIds)

  const engageWidgets = (engageWidgets: WidgetEngage | undefined) => {
    return engageWidgets?.tabs?.map((tab) => {
      const widgetGraphs = tab?.graphs?.map((graph) => {
        const upliftW = (
          <UpliftWidget
            key="upliftWidget"
            title={graph.display_name}
            accountId={accountId}
            upliftType={graph?.configuration?.uplift_types || []}
            testingMethod={graph.configuration.testing_method}
            distributionType={graph?.configuration?.distribution_type}
            projectIds={projectIds}
            info={UPLIFT_INFO}
          />
        )
        const IncrementalEW = (
          <IncrementalEventsWidget
            key="incrementalWidget"
            title={graph.display_name}
            accountId={accountId}
            eventTypes={graph?.configuration?.event_types || []}
            testingMethod={graph.configuration.testing_method}
            distributionType={graph?.configuration?.distribution_type}
            projectIds={projectIds}
          />
        )

        if (tab.display_name === 'Uplift') {
          return upliftW
        } else {
          return IncrementalEW
        }
      })
      const graphTab = () => (
        <MultiGraphWrapper>{widgetGraphs}</MultiGraphWrapper>
      )
      return {
        name: tab.display_name,
        content: graphTab(),
      }
    })
  }

  return (
    <div data-cy="performance-widget">
      <div
        className={`text-xl font-medium
                  text-coolGray-800 mb-4`}
      >
        {widgetEngage.display_name}
      </div>
      <TabWidget tabs={engageWidgets(widgetEngage)} />
    </div>
  )
}

export default EngageWidgets
