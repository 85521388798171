import Button from 'common/components/button/Button'
import { ReactComponent as EmailFlyIcon } from 'common/icons/emailFly/default.svg'

type Props = {
  onClickCancel: () => void
  canSubmitForm: boolean
}

export function Footer({ onClickCancel, canSubmitForm }: Props) {
  return (
    <div className="pt-6 px-6 flex justify-end text-base">
      <Button
        className="mr-4"
        ghost
        onClick={() => {
          onClickCancel()
        }}
        data-cy="cancel-add-user"
      >
        Cancel
      </Button>

      <Button
        variant="primary"
        disabled={!canSubmitForm}
        prefixIcon={<EmailFlyIcon width={24} height={24} />}
        type="submit"
        loading={false}
        data-cy="create-user-button"
      >
        Invite user
      </Button>
    </div>
  )
}
