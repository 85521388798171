const InlineError = () => {
  return (
    <div className="px-9 py-6 text-coolGray-800">
      <p className="text-base font-medium">
        Oh no… unable to retrieve more experiments!
      </p>
      <p className="mt-6 font-thin">
        Try refreshing the page if that doesn’t work chat with us, we can fix
        just about anything.
      </p>
    </div>
  )
}

export default InlineError
