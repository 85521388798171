import {
  Account,
  LanguageSettings,
} from '@phrasee/phrasee-typings/Graphql/interfaces'

import { connectInternalApi } from 'common/api'
import { removeMaybeAsList } from 'common/helpers/typeUtils'

import type { BrandVoice, Tone } from '../store/brandVoiceSlice'

export type BrandVoiceBE = {
  id: string
  name: string
  region_id: string
  default: boolean
  created: string
  last_updated: string
  brand_tones: BrandToneResponse[]
}

type BrandToneResponse = {
  _id: string
  name: string
  description: string
  examples: string[]
}

type BrandTonesListResponse = {
  _id: string
  description: string
  name: string
}[]

type BrandVoiceResponse = {
  msg: string
  brandVoices: BrandVoiceBE[]
}

export type BrandVoiceAttachedProjectsBE = {
  last_updated: string
  name: string
  _id: string
}

export const mapBrandVoice = (brandVoice: BrandVoiceBE): BrandVoice => ({
  id: brandVoice.id,
  name: brandVoice.name,
  regionId: brandVoice.region_id.toLowerCase(),
  isDefault: brandVoice.default,
  createdDate: brandVoice.created,
  lastModified: brandVoice.last_updated,
  tones: [],
})

export const createBrandVoice = async ({
  accountId,
  brandVoice,
  clonedBrandVoice,
}: {
  accountId: string
  brandVoice: Omit<BrandVoice, 'id' | 'createdDate'>
  clonedBrandVoice?: BrandVoice
}): Promise<BrandVoice[]> => {
  const payload = {
    name: brandVoice.name,
    ...(clonedBrandVoice
      ? {
          source_brand_voice_id: clonedBrandVoice.id,
        }
      : {
          region_id: brandVoice.regionId,
          default: brandVoice.isDefault,
        }),
  }
  const result = await connectInternalApi.post<{ data: Account }>(
    `v1/core/monorail/accounts/${accountId}/brand-voice`,
    payload
  )
  const brandVoices = removeMaybeAsList(
    result.data.data.brand_voices ?? []
  ) as any

  return brandVoices.map(mapBrandVoice)
}

type BrandVoiceDetailsResponse = {
  msg: string
  brandVoice: BrandVoiceBE & { language_settings: LanguageSettings }
}

export const getBrandVoices = async ({
  accountId,
}: {
  accountId: string
}): Promise<BrandVoice[]> => {
  const result = await connectInternalApi.get<BrandVoiceResponse>(
    `v1/core/monorail/accounts/${accountId}/brand-voice`
  )
  const brandVoices = result.data.brandVoices ?? []

  return brandVoices.map(mapBrandVoice)
}

export const getBrandVoiceDetails = async ({
  accountId,
  brandVoiceId,
}: {
  accountId: string
  brandVoiceId: string
}): Promise<BrandVoice> => {
  const result = await connectInternalApi.get<BrandVoiceDetailsResponse>(
    `v1/core/monorail/accounts/${accountId}/brand-voice/${brandVoiceId}`
  )
  const brandVoice = result.data.brandVoice
  return {
    id: brandVoice.id,
    name: brandVoice.name,
    regionId: brandVoice.region_id,
    isDefault: brandVoice.default,
    createdDate: brandVoice.created,
    lastModified: brandVoice.last_updated,
    tones: brandVoice.brand_tones.map((tone, index) => ({
      id: tone._id,
      order: index,
      title: tone.name,
      description: tone.description,
      isUsed: true,
      language: tone.examples[0],
    })),
  }
}

export const colorMapping: {
  [key in string]: string
} = {
  accessible: 'orange',
  calm: 'emerald',
  casual: 'blue',
  confident: 'yellow',
  conversational: 'green',
  curious: 'indigo',
  direct: 'blue',
  energetic: 'lime',
  empathetic: 'rose',
  exclusive: 'amber',
  expressive: 'yellow',
  formal: 'pink',
  friendly: 'pink',
  functional: 'blue',
  general: 'sky',
  humorous: 'lime',
  inquisitive: 'cyan',
  inspirational: 'teal',
  'laid-back': 'amber',
  lively: 'green',
  mature: 'rose',
  'matter-of-fact': 'blue',
  optimistic: 'purple',
  passionate: 'red',
  professional: 'blue',
  serious: 'fuchsia',
  specific: 'yellow',
  youthful: 'pink',
}

export const getBrandToneColor = (brandToneName: string): string => {
  const defaultToneColor = 'blue'
  return colorMapping[brandToneName]
    ? colorMapping[brandToneName]
    : defaultToneColor
}

export const fetchAllBrandTones = async (): Promise<Tone[]> => {
  const result = await connectInternalApi.get<BrandTonesListResponse>(
    `v1/core/monorail/brand-tones`
  )
  const responseBrandTones = removeMaybeAsList(result.data ?? [])
  const tones = responseBrandTones.map((tone, index) => ({
    id: tone._id,
    order: index,
    title: tone.name,
    description: tone.description,
    color: getBrandToneColor(tone.name),
  }))
  return tones
}

export const updateBrandTonesForBrandVoice = async ({
  accountId,
  brandVoiceId,
  brandToneIds,
}: {
  accountId: string
  brandVoiceId: string
  brandToneIds: string[]
}): Promise<BrandVoiceBE[]> => {
  const result = await connectInternalApi.patch<BrandVoiceResponse>(
    `v1/core/monorail/accounts/${accountId}/brand-voice/${brandVoiceId}/brand-tones`,
    {
      brandToneIds,
    }
  )

  return result.data.brandVoices
}

export const generateBrandTone = async (
  sampleText: string
): Promise<BrandToneResponse[]> => {
  const result = await connectInternalApi.post<BrandToneResponse[]>(
    `v1/core/monorail/brand-tones`,
    {
      sample_text: sampleText,
    }
  )

  return result.data
}

export const fetchAllAttachedProjectsForBrandVoice = async ({
  accountId,
  brandVoiceId,
}: {
  accountId: string
  brandVoiceId: string
}): Promise<{
  projects: BrandVoiceAttachedProjectsBE[]
}> => {
  const result = await connectInternalApi.get<{
    projects: BrandVoiceAttachedProjectsBE[]
  }>(
    `v1/core/monorail/accounts/${accountId}/brand-voice/${brandVoiceId}/projects`
  )
  return result.data
}

export const deleteBrandVoice = async ({
  accountId,
  brandVoiceId,
}: {
  accountId: string
  brandVoiceId: string
}) => {
  const result = await connectInternalApi.delete(
    `v1/core/monorail/accounts/${accountId}/brand-voice/${brandVoiceId}`
  )
  return result.data
}
