import { useState } from 'react'
import { useForm } from 'react-final-form'

import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import { useAppDispatch } from 'common/hooks/redux'
import { ArrowLeft as ArrowLeftIcon } from 'common/icons'
import { templateSetupComplete } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useExperimentForm } from '../context/ExperimentFormContext'
import { getIsExperimentFormValid } from '../helpers'

const SetUpExperimentFooter = () => {
  const {
    state: { template },
    dispatch,
  } = useExperimentForm()
  const form = useForm()
  const appDispatch = useAppDispatch()

  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)

  const isExperimentFormValid = getIsExperimentFormValid(form, template)

  const closeExperimentForm = () => {
    dispatch({
      type: 'set_experiment_form_visible',
      value: false,
    })
  }

  const onBackArrowClick = () => {
    if (isExperimentFormValid) {
      closeExperimentForm()
    } else {
      setIsConfirmModalShown(true)
    }
  }

  const onDoneClick = () => {
    if (template) {
      appDispatch(
        templateSetupComplete({
          internalId: template.internalId,
          status: 'complete',
        })
      )
    }
    closeExperimentForm()
  }

  const onConfirm = () => {
    if (template) {
      appDispatch(
        templateSetupComplete({
          internalId: template.internalId,
          status: 'incomplete',
        })
      )
    }
    setIsConfirmModalShown(false)
    closeExperimentForm()
  }

  return (
    <div className="flex justify-between my-2 p-6 w-full">
      <Button ghost={true} onClick={onBackArrowClick}>
        <ArrowLeftIcon size={4} />
      </Button>
      <Button
        ghost={true}
        onClick={onDoneClick}
        disabled={!isExperimentFormValid}
      >
        Done
      </Button>
      <ConfirmationModal
        open={isConfirmModalShown}
        title="Unsaved changes!"
        data-cy="experiment-form-confirmation-modal"
        confirmationText="Your setup is incomplete. Continue editing or leave and lose unsaved changes?"
        cancelButtonText="Leave"
        confirmButtonText="Continue editing"
        confirmButtonVariant="primary"
        onConfirm={() => setIsConfirmModalShown(false)}
        onCancel={onConfirm}
      />
    </div>
  )
}

export default SetUpExperimentFooter
