const barChartColors = [
  '#e0f2fe',
  '#bae6fd',
  '#7dd3fc',
  '#38bdf8',
  '#0ea5e9',
  '#0284c7',
  '#0369a1',
  '#075985',
  '#0c4a6e',
  '#164e63',
  '#155e75',
  '#0e7490',
  '#0891b2',
  '#06b6d4',
  '#22d3ee',
  '#67e8f9',
  '#a5f3fc',
  '#cffafe',
]

export default barChartColors
