import { StylesConfig } from 'react-select'

import { SelectValue } from 'common/components/BaseSelect'
import { NodeFilterOption } from 'common/interfaces/nodes'

export const phrasesAutocompleteCustomStyles: StylesConfig<SelectValue, false> =
  {
    control: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: 0,
        paddingLeft: '8px',
        boxShadow: 'none',
      }
    },
  }

const autocompleteOptionCustomStyles = {
  option: (defaultStyles, state) => {
    return {
      minHeight: 56,
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: state.isFocused ? '#f3f4f6' : '',
      '&:hover': {
        backgroundColor: '#f3f4f6',
        cursor: 'pointer',
      },
    }
  },
}

export const nodesAutocompleteCustomStyles = ({
  availableOptions,
}: {
  availableOptions?: boolean
}): StylesConfig<SelectValue, false> => {
  return {
    ...phrasesAutocompleteCustomStyles,
    ...autocompleteOptionCustomStyles,
    menu: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        display: availableOptions ? 'block' : 'none',
        top: 45,
        right: 0,
        width: '510px',
      }
    },
  }
}

export const moveAutocompleteCustomStyles = ({
  availableOptions,
}: {
  availableOptions?: boolean
}): StylesConfig<NodeFilterOption, false> => {
  return {
    ...autocompleteOptionCustomStyles,
    control: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        paddingLeft: '8px',
      }
    },
    menu: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        display: availableOptions ? 'block' : 'none',
      }
    },
  }
}

export const selectCustomStyles: StylesConfig<SelectValue, false> = {
  control: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      backgroundColor: 'transparent',
      border: 'none',
      borderRight: '1px solid #E5E7EB',
      borderRadius: 0,
      boxShadow: 'none',
    }
  },
  singleValue: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: '#9CA3AF',
    }
  },
  dropdownIndicator: (defaultStyles, state) => {
    return {
      ...defaultStyles,
      color: '#9CA3AF',
    }
  },
}
