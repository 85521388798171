import { useQuery } from '@tanstack/react-query'

import { Replacer } from 'common/components/topics/interfaces'

import { getReplacers } from '../api'
import { nodesKeys } from '../queryKeys/nodesKeys'

const useGetReplacersQuery = () => {
  const getNodesQuery = useQuery(nodesKeys.replacers(), getReplacers, {
    select: (data: Replacer[]): { name: string; id: string }[] =>
      data.map((replacer) => ({
        name:
          replacer.displayName ??
          replacer.replacer.toLowerCase().replaceAll('_', ' '),
        id: replacer.replacer,
      })),
  })

  return getNodesQuery
}

export default useGetReplacersQuery
