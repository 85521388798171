import { FC } from 'react'
import cx from 'classnames'

import Overlay from '../Overlay'

interface Props {
  isVisible: boolean
  onOverlayClick: () => void
}

const Sidebar: FC<Props> = ({ children, isVisible, onOverlayClick }) => {
  return (
    <>
      <Overlay
        className={cx('lg:hidden', {
          hidden: !isVisible,
        })}
        onClick={() => onOverlayClick()}
      />
      <div
        role="complementary"
        className={cx(
          'flex flex-col fixed bg-gray-50 h-full top-0 right-0 lg:relative shadow-left lg:shadow-none transition-transform duration-300 ease-in transform box-border lg:block lg:w-82 lg:ml-14 w-82 lg:translate-x-0 z-30 overflow-y-auto lg:overflow-y-hidden',
          {
            'translate-x-full w-0': !isVisible,
          }
        )}
      >
        {children}
      </div>
    </>
  )
}

export default Sidebar
