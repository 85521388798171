import React from 'react'

import { WidgetError } from 'common/components/WidgetMessage'

interface ComponentManagerState {
  hasError: boolean | null
}

class AdvancedVisualisationErrorBoundary extends React.Component<
  {},
  ComponentManagerState
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <WidgetError className="mt-6 mx-6" />
    }
    // React does not accept to return undefined, see https://reactjs.org/docs/conditional-rendering.html#preventing-component-from-rendering
    return children ? children : null
  }
}

export default AdvancedVisualisationErrorBoundary
