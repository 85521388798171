import React from 'react'
import classNames from 'classnames'
interface Props {
  icon: React.ReactNode
  text: string
  onClick?: () => void
}

const MetricItem = ({ icon, text, onClick }: Props) => {
  return (
    <div
      className={classNames(
        `flex group place-items-center p-4 bg-coolGray-50 hover:bg-coolGray-100`,
        {
          'cursor-pointer': onClick,
        }
      )}
      onClick={onClick}
    >
      <div className="text-black group-hover:text-maroon-500">{icon}</div>
      <div className="text-coolGray-800 font-medium ml-3 ">{text}</div>
    </div>
  )
}

export default MetricItem
