import HoldingPage from 'common/components/holdingPages/Page'

export const messages = {
  title: 'You don’t seem to have any teams.',
}

const NoResults = () => {
  return (
    <HoldingPage className="max-w-363">
      <HoldingPage.Title>{messages.title}</HoldingPage.Title>
      <HoldingPage.SubTitle>
        Create a team so you can add multiple users to projects, experiments and
        reports simply and easily.
      </HoldingPage.SubTitle>
    </HoldingPage>
  )
}

export default NoResults
