import { ReactNode } from 'react'

import GridContainer from 'common/components/grid/GridContainer'

type Props = {
  children: ReactNode
}

const GridView = ({ children }: Props) => {
  return <GridContainer>{children}</GridContainer>
}

export default GridView
