import { useState } from 'react'
import { useForm, useFormState } from 'react-final-form'

import Badge from 'common/components/Badge'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import { TextareaType } from 'common/components/dynamicFields/interfaces'
import Spinner from 'common/components/spinner'
import { useAppSelector } from 'common/hooks/redux'
import useGetSplitCalculatorConfigurationQuery from 'features/unifiedFlow/api/queries/useGetSplitCalculatorConfigurationQuery'
import { selectTemplatesToAddWithOptimization } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useExperimentForm } from '../context/ExperimentFormContext'
import {
  getExperimentPrefixedName,
  getInitialExperimentNameValue,
  getPrefixedName,
  getSelectedProjectId,
} from '../helpers'
import SplitCalculator from '../splitCalculator'

import ControlSubjectLine from './ControlSubjectLine'
import ExperimentName from './ExperimentName'
import Project from './Project'
import SendDate from './SendDate'

import './DatePickerStyles.css'

const SetUpExperiment = () => {
  const [isDatePickerFocused, setIsDatePickerFocused] = useState<boolean>(false)
  const {
    state: { template },
  } = useExperimentForm()

  const form = useForm()
  const formState = useFormState()

  const templatesToAddWithOptimization = useAppSelector(
    selectTemplatesToAddWithOptimization
  )

  const selectedProjectId =
    template && getSelectedProjectId(formState, template)

  const { data: projectSplitCalculatorConfiguration, isLoading } =
    useGetSplitCalculatorConfigurationQuery({
      projectId: selectedProjectId,
    })

  const controlSubjectLineConfig =
    projectSplitCalculatorConfiguration?.experimentMainFields?.find(
      (field) => field.name === 'own_subject_line'
    )
  const dateConfig =
    projectSplitCalculatorConfiguration?.experimentMainFields?.find(
      (field) => field.name === 'iso_send_date'
    )

  const splitCalculatorConfiguration =
    projectSplitCalculatorConfiguration?.splitCalculation
  const integrationType = projectSplitCalculatorConfiguration?.integrationType

  const resetCalculatorFields = () => {
    const registeredFields = form.getRegisteredFields()
    const fieldNames = [
      'splitNumber',
      'openRate',
      'clickRate',
      'selectionMetric',
      'listSize',
    ]

    fieldNames.forEach((fieldName) => {
      const prefixedName = getPrefixedName(fieldName, template)
      if (registeredFields.includes(prefixedName)) {
        form.change(prefixedName, undefined)
      }
    })
  }

  if (!template) {
    return null
  }

  const getState = () => {
    if (!selectedProjectId) {
      return 'noData'
    } else if (isLoading && selectedProjectId) {
      return 'loading'
    } else {
      return 'data'
    }
  }

  const state = getState()

  return (
    <div className="SetUpExperiment flex flex-col w-full">
      <div className="flex items-center mb-4">
        <ContentTypeBubble
          name={template?.displayName}
          iconType={template?.icon || ''}
        />
        <div className="text-base font-medium flex ml-4 text-coolGray-800">
          {template?.displayName}
        </div>
        <Badge
          text="Optimize"
          variant="optimize"
          size="small"
          className="capitalize ml-auto"
        />
      </div>
      <ExperimentName
        name={getExperimentPrefixedName(template, 'experimentName')}
        initialValue={getInitialExperimentNameValue({
          templatesToAddWithOptimization,
          template,
          formState,
        })}
      />
      <div className="grid grid-cols-3 gap-4">
        <Project
          name={getExperimentPrefixedName(template, 'project')}
          optimizationContentType={template?.optimization?.contentType}
          data-testid="project-select"
          onChange={() => {
            resetCalculatorFields()
            setIsDatePickerFocused(true)
          }}
        />
        {dateConfig && (
          <SendDate
            name={getExperimentPrefixedName(template, 'sendDate')}
            config={dateConfig}
            isFocused={isDatePickerFocused}
          />
        )}
      </div>
      {
        {
          noData: null,
          loading: <Spinner />,
          data: (
            <>
              {controlSubjectLineConfig && (
                <ControlSubjectLine
                  name={getExperimentPrefixedName(
                    template,
                    'controlSubjectLine'
                  )}
                  config={controlSubjectLineConfig as TextareaType}
                  integrationType={integrationType}
                />
              )}
              {splitCalculatorConfiguration && (
                <SplitCalculator
                  template={template}
                  configuration={splitCalculatorConfiguration}
                />
              )}
            </>
          ),
        }[state]
      }
    </div>
  )
}

export default SetUpExperiment
