import React, { useState } from 'react'
import cx from 'classnames'

type Props = {
  title: string
  value?: string | number
  modal?: JSX.Element
}

const ReportingBox = ({ title, value, modal }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const clonedModal = modal
    ? React.cloneElement(modal, {
        visible: isModalOpen,
        onClose: () => setIsModalOpen(false),
      })
    : null

  return (
    <>
      <div
        className={cx(
          'flex-1 p-4 border border-coolGray-300 mr-6 last:mr-0 bg-white h-24.5',
          { 'cursor-pointer': clonedModal }
        )}
        onClick={() => setIsModalOpen((prev) => !prev)}
      >
        <div className="text-base sm:text-lg font-medium text-coolGray-400">
          {title}
        </div>
        <div className="text-xl sm:text-3xl font-medium text-coolGray-800">
          {value}
        </div>
      </div>
      {clonedModal}
    </>
  )
}

export default ReportingBox
