import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router'

import { useAppSelector } from 'common/hooks/redux'
import useCopyContentMutation from 'features/futurama/api/mutations/useCopyContentMutation'

import { ContentInfo } from '../interfaces'
import SavedContentCard from '../SavedContentCard/SavedContentCard'

type Props = {
  contents: ContentInfo[]
  onDelete: Dispatch<SetStateAction<string>>
}

const ContentGrid = ({ contents, onDelete }: Props) => {
  const history = useHistory()
  const onContentClick = (contentId: string) => {
    history.push(`/content/${contentId}`)
  }
  const copyContentMutation = useCopyContentMutation()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-y-6 gap-x-4 sm:gap-x-6">
      {contents?.map((content: ContentInfo) => (
        <div
          key={content.id}
          onClick={() => onContentClick(content.id)}
          className="cursor-pointer"
          aria-label="Saved content card"
        >
          <SavedContentCard
            elements={content.elements}
            title={content.name}
            createdDate={new Date(content.createdDate)}
            users={content.contributors}
            onDelete={(e) => {
              e.stopPropagation()
              onDelete(content.id)
            }}
            onDuplicate={(e) => {
              e.stopPropagation()
              copyContentMutation.mutate({
                accountId,
                contentId: content.id,
              })
            }}
            onAddFavorite={() => {
              alert('Not implemented yet')
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default ContentGrid
