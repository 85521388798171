import { useDocumentTitle } from 'common/hooks/custom'
import MainContent from 'features/unifiedFlow/components/MainContent'

import { VariantInsightsProvider } from './context/VariantInsightsContext'
import useElementState from './hooks/useElementState'
import useRightPanelVisibilityState from './hooks/useRightPanelVisibilityState'
import CenterSection from './centerSection'
import LeftSection from './leftSection'
import RightSection from './rightSection'

const Generate = () => {
  useDocumentTitle('Generate | Jacquard')
  useElementState()
  useRightPanelVisibilityState()

  return (
    <VariantInsightsProvider>
      <MainContent className="flex-wrap pb-4" id="main-content-page">
        <LeftSection />
        <CenterSection />
        <RightSection />
      </MainContent>
    </VariantInsightsProvider>
  )
}

export default Generate
