import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  hideRightPanel,
  showRightPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useContent } from '../../hooks'
import useGetLogsQuery from '../centerSection/api/queries/useGetLogsQuery'

const useRightPanelVisibilityState = ({ showOptimizeView }) => {
  const { content } = useContent()
  const dispatch = useAppDispatch()

  const { logsState } = useGetLogsQuery(content?._id)

  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )

  useEffect(() => {
    if (showOptimizeView) {
      dispatch(showRightPanel('none'))
    } else if (
      !showOptimizeView &&
      logsState === 'hasLogs' &&
      rightPanelVisibility === 'none'
    ) {
      dispatch(showRightPanel('auto'))
    } else if (!showOptimizeView && logsState === 'empty') {
      dispatch(hideRightPanel('none'))
    }
  }, [logsState, dispatch, rightPanelVisibility, showOptimizeView])
}

export default useRightPanelVisibilityState
