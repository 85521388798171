import { useState } from 'react'
import cx from 'classnames'

import Button from 'common/components/button'
import Checkbox from 'common/components/checkbox'
import Modal from 'common/components/Modal'
import Scrollable from 'common/components/scrollable'
import { MetricOption } from 'features/projects/interface'

import styles from './AddMetricsModal.module.css'

export type ModalOption<T> = MetricOption<T> & { isDisabled?: boolean }
type Props<T extends string> = {
  'data-cy': string
  title: string
  applyButtonText: string
  isOpen: boolean
  onAddClick: (selectedValues: T[]) => void
  onCancelClick: () => void
  options: ModalOption<T>[]
  selectedValues: T[]
}

const AddMetricsModal = <T extends string>({
  'data-cy': dataCy,
  title,
  applyButtonText,
  isOpen,
  onAddClick,
  onCancelClick,
  options,
  selectedValues: appliedValues,
}: Props<T>) => {
  const [selectedValues, setSelectedValues] = useState<T[]>(appliedValues)

  const selectOption = (value: T): void => {
    setSelectedValues((current) => [...current, value])
  }

  const unselectOption = (value: T): void => {
    setSelectedValues(selectedValues.filter((item) => item !== value))
  }

  return (
    <Modal
      data-cy={`${dataCy}-modal`}
      visible={isOpen}
      centered
      hasMargin={false}
      closable={false}
      footer={null}
      destroyOnClose
    >
      <div className="flex py-4">
        <Scrollable
          header={
            <div className="px-4 pb-4 flex">
              <h1 className="mt-2 text-2xl font-bold text-coolGray-800">
                {title}
              </h1>
            </div>
          }
          contentClassName={cx('w-123', styles.content)}
          content={
            <div className="pl-6">
              {options.map(({ label, value, isDisabled }) => (
                <Checkbox
                  key={value}
                  data-cy={`${dataCy}-checkbox-${value}`}
                  className="py-3 min-w-full hover:bg-gray-50"
                  label={label}
                  isDisabled={isDisabled}
                  isChecked={selectedValues.some(
                    (curentValue) => curentValue === value
                  )}
                  onChange={(isChecked) =>
                    isChecked ? selectOption(value) : unselectOption(value)
                  }
                />
              ))}
            </div>
          }
          footer={
            <div className="pt-4 px-4 flex justify-end gap-4">
              <Button
                data-cy={`${dataCy}-modal-cancel-button`}
                ghost
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                data-cy={`${dataCy}-modal-apply-button`}
                variant="primary"
                onClick={() => onAddClick(selectedValues)}
              >
                {applyButtonText}
              </Button>
            </div>
          }
        />
      </div>
    </Modal>
  )
}

const AddMetricsModalWithResetState = <T extends string>(props: Props<T>) => (
  <AddMetricsModal<T> key={`${props.isOpen}`} {...props} />
)
export default AddMetricsModalWithResetState
