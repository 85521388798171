import get from 'lodash/get'

import Avatar from 'common/components/Avatar'
import { useAppSelector } from 'common/hooks/redux'

const UserSection = () => {
  const campaignData = useAppSelector(
    (state) => state.campaignStates.campaignData
  )

  const firstName = get(campaignData, 'user.fname', undefined)
  const lastName = get(campaignData, 'user.lname', undefined)

  const {
    profile: { avatarColor },
    user_id: currentUserId,
  } = useAppSelector((state) => state.authStates)

  if (!firstName && !lastName) {
    return null
  }

  return (
    <div aria-label="User Information">
      <div className="flex items-center">
        <Avatar
          className="w-10 h-10"
          firstName={firstName}
          lastName={lastName}
          color={
            currentUserId === campaignData?.user_id ? avatarColor : undefined
          }
        />

        <div className="flex flex-col ml-2.5">
          <p className="font-relais font-medium text-xl text-base-700">
            {firstName} {lastName}
          </p>
          <p className="mt-1 text-xs font-normal text-gray-700">Owner</p>
        </div>
      </div>
      <hr className="border-dashed my-4 border-coolGray-300" />
    </div>
  )
}

export default UserSection
