import { Form } from 'antd'
import { BusinessUnit, CampaignUnit, FolderUnit } from 'workflow/interface'

import Label from 'common/components/Label'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import Tooltip from 'common/components/Tooltip'

import { EpsilonSeedList } from '../EpsilonIntegration.types'

export type OptionType = React.ReactElement<
  string | any | React.JSXElementConstructor<any>
>
const FormItem = Form.Item

interface Props {
  onChange: (val: string) => void
  selectedOption: string
  selectorOptions:
    | BusinessUnit[]
    | FolderUnit[]
    | CampaignUnit[]
    | EpsilonSeedList[]
    | undefined
  selectorOptionName: string
  selectorOptionId: string
  error?: string
  isDisabled: boolean
  label: string
  placeholder: string
  testId: string
  defaultValue: string
  tooltipContent?: string
  optionalLabel?: boolean
}
export const EpsilonSelector = ({
  onChange,
  selectedOption,
  selectorOptions,
  error,
  isDisabled,
  selectorOptionName,
  selectorOptionId,
  label,
  placeholder,
  testId,
  defaultValue,
  tooltipContent,
  optionalLabel,
}: Props) => {
  const options: SelectValue[] =
    selectorOptions?.map((option) => ({
      label: option[selectorOptionName],
      value: option[selectorOptionId],
    })) ?? []

  return (
    <FormItem
      help={error || null}
      className={error ? 'error required' : 'required'}
    >
      <Label className="mb-2" children={label} optional={optionalLabel} />
      <Tooltip
        overlay={tooltipContent}
        trigger="hover"
        placement="right"
        overlayStyle={{ maxWidth: 226 }}
      >
        <SingleSelect
          isClearable
          backspaceRemovesValue
          isSearchable
          isOptionDisabled={(option) => option.value === defaultValue}
          data-testid={testId}
          placeholder={placeholder}
          className="w-88"
          onChange={(val) => onChange(val?.value || '')}
          value={
            selectedOption && selectorOptions?.length
              ? selectedOption
              : defaultValue
          }
          options={[
            {
              label: 'Select',
              value: defaultValue,
            },
            ...options,
          ]}
          isDisabled={isDisabled}
          menuPortalTarget={document.body}
        />
      </Tooltip>
    </FormItem>
  )
}

export default EpsilonSelector
