import { unifiedFlowPaths } from 'app/navigation/paths'

import Button from 'common/components/button'
import useApproveElementMutation from 'features/unifiedFlow/api/mutations/useApproveElementMutation'
import {
  useContent,
  useSelectedElement,
} from 'features/unifiedFlow/contentPage/hooks'

type Props = {
  isReadOnly?: boolean
}

const ApprovalsTableActionButtons = ({ isReadOnly = false }: Props) => {
  const { content } = useContent()
  const approveElementMutation = useApproveElementMutation({
    redirectTo: unifiedFlowPaths.results,
  })
  const { data: selectedElement } = useSelectedElement()

  const handleApprove = () => {
    if (selectedElement && content) {
      approveElementMutation.mutate({
        accountId: content.account_id,
        element_id: selectedElement.element_id,
        content_id: content?._id,
      })
    }
  }

  return (
    <Button
      disabled={approveElementMutation.isLoading || isReadOnly}
      loading={approveElementMutation.isLoading}
      className="ml-4"
      variant="primary"
      onClick={handleApprove}
    >
      Approve
    </Button>
  )
}

export default ApprovalsTableActionButtons
