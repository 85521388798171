/* eslint-disable max-lines */
import { useEffect, useReducer, useState } from 'react'
import {
  CampaignData,
  TestedContentSection,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { Form, TimePicker } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import isEqual from 'lodash/isEqual'
import moment, { Moment } from 'moment'
import { GET_MID_LOADING } from 'redux/actionTypes'
import GuidingText from 'workflow/CampaignSetup/UiBuilder/GuidingText'
import { MidObject } from 'workflow/interface'
import helpers from 'workflow/utils/helpers'
import {
  fetchCampaignData,
  getMID,
  loadResultsDetails,
} from 'workflow/Workflow.actions'

import Card from 'common/components/Card'
import DatePickerAntd from 'common/components/DatePicker'
import Label from 'common/components/Label'
import SingleSelect from 'common/components/singleSelect'
import { handlePercentValue } from 'common/helpers/numeric'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Time as TimeIcon } from 'common/icons'

import AdvancedOptions from './components/advancedOptions/AdvancedOptions'
import {
  confidence,
  highPerformers,
  optionHeadToHeadAndWait,
  singleBest,
} from './components/advancedOptions/constants'
import OptimizationSettings from './components/advancedOptions/OptimizationSettings'
import CopyCard from './components/CopyCard'
import { getTestModeDescription, TestModeAlert } from './components/testMode'
import {
  advancedSettingsReducer,
  initialAdvancedOptionsState,
} from './advancedOptionsReducer'
import {
  initializePhraseeX,
  initializePhraseeXPush,
  initializePhraseeXPushBroadcast,
  RealtimeUpdateTimeUnit,
  updatePhraseeX,
  updatePhraseeXSchedule,
} from './api'
import Button from './Button'
import {
  createAdvancedSettingsPayload,
  isDisabledEndDate,
  submissionError,
} from './helpers'
import {
  AdvancedSettingsPayload,
  OptimizationSettingsConstraints,
} from './types'

import styles from 'workflow/CampaignSummary/IntegrationOptions/Integrations.module.css'

const TIME_FORMAT = 'HH:mm'
const PHRASEEX_ENDPOINT = process.env.REACT_APP_PHRASEEX_ENDPOINT || ''
const START_TIME_ADDED_MINUTES = 30

export const getIsoDate = (
  date: Moment | null,
  time?: string | null
): string => {
  const [hours, minutes] = time?.split(':') || []

  return (
    moment(date)
      .set({
        hours: parseInt(hours),
        minute: parseInt(minutes),
        seconds: 0,
        milliseconds: 0,
      })
      .utc()
      .toISOString() || ''
  )
}

// For SFMC React, the recurring schedule should run between 1am and 4am UTC
export const generateSFMCReactRecurringSchedule = (): string => {
  return moment()
    .add(1, 'day')
    .utc()
    .set({
      hours: Math.floor(Math.random() * 4 + 1),
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    })
    .toISOString()
}

// When it come to engage experiments we first want the scheduler to run 45 min after the start date, and every hour.
// That's what the two next method are intended for
export const generateSFMCReactRecurringScheduleForBroadcast = (): string => {
  return moment()
    .add(1, 'hour')
    .utc()
    .set({
      minutes: Math.floor(Math.random() * 12) * 5,
      seconds: 0,
      milliseconds: 0,
    })
    .toISOString()
}
export const generateSFMCReactFirstSchedulerTickForBroadcast = (
  startDateTime: string
): string => {
  return moment(startDateTime)
    .add(45, 'minutes')
    .utc()
    .set({
      seconds: 0,
      milliseconds: 0,
    })
    .toISOString()
}

const MIDLIST_ERROR_CASE = {
  emptyMid: `Please reach out to your customer services representative \n
    to configure the Salesforce Integration.`,
  httpFailed: `Failed to fetch MID (Business Unit) information, \n
  please refresh the page and try again`,
}

const PhraseeXIntegration = () => {
  const dispatch = useAppDispatch()
  const flags = useFlags()
  const [error, setError] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isScheduled, setIsScheduled] = useState<boolean>(false)
  const [isUrlCopied, setIsUrlCopied] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [startTime, setStartTime] = useState<Moment | null>()
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [endTime, setEndTime] = useState<Moment | null>()
  const [isDynamicOptimizationPaused, setIsDynamicOptimizationPaused] =
    useState<boolean>(false)
  const [frequency, setFrequency] =
    useState<RealtimeUpdateTimeUnit | undefined>()
  // Flag which determines when to show "Update Settings" button.
  const [areAdvancedSettingsUpdateable, setAreAdvancedSettingsUpdateable] =
    useState(false)
  const [originalBanditData, setOriginalBanditData] =
    useState<AdvancedSettingsPayload | undefined>(undefined)
  const [mid, setMid] = useState<string>('')
  const [midListError, setMidListError] = useState<string>('')
  const [hasMadeMidCall, setHasMadeMidCall] = useState<boolean>(false)
  const projectId = useAppSelector((state) => state.campaignStates.projectId)

  const campaignConfiguration = useAppSelector(
    (state) => state.campaignStates?.campaignData?.campaign_configuration
  )
  const testTrackedMetrics = campaignConfiguration?.test_tracked_metrics as
    | { name: string; type: string }[]
    | undefined

  const hasTrackedClickMetrics = testTrackedMetrics?.some((metric) =>
    metric?.name.includes('click')
  )

  const testedContentSections = campaignConfiguration?.testing_method
    ?.tested_content_sections as
    | {
        section: string
        language_source: string
      }[]
    | undefined

  const campaignsTestedContentSection = testedContentSections?.[0].section as
    | TestedContentSection
    | undefined

  const isInBody = campaignsTestedContentSection === 'in_body'

  const campaignDistributionType = useAppSelector(
    (state) =>
      state.campaignStates?.campaignData?.campaign_configuration
        ?.distribution_type
  )

  const selectionMetric = campaignConfiguration?.testing_method
    ?.optimization_method?.selection_metric?.name as string | undefined

  const [advancedSettingsState, dispatchAdvancedSettingsState] = useReducer(
    advancedSettingsReducer,
    initialAdvancedOptionsState({
      selectionMetric,
      testedContentSection: campaignsTestedContentSection,
      isInBody,
      hasTrackedClickMetrics,
      isBroadcastCampaign: campaignDistributionType === 'broadcast',
      showDOAutomaticOptions: flags.showDoAutomaticVariantIntroduction,
    })
  )

  const campaignSchedules = useAppSelector(
    (state) =>
      state.campaignStates.campaignData.campaign_data?.bandit_data?.schedules ||
      []
  )
  const isCampaignScheduledAlready = campaignSchedules.length > 0
  const midList = useAppSelector((state) => state.campaignStates?.midList || [])
  const isLoadingMid = useAppSelector(
    (state) =>
      state.campaignStates.isWaitingState.isWaiting &&
      state.campaignStates.isWaitingState.isWaitingFor === GET_MID_LOADING
  )
  const campaignMid = useAppSelector(
    (state) =>
      state.campaignStates.campaignData.campaign_data?.bandit_data?.client_id
  )
  const appMidListError = useAppSelector(
    (state) => state.campaignStates?.midListError
  )

  const campaignId = useAppSelector(
    (state) => state.campaignStates.campaignData._id as string
  )

  const { phrasee_x_initialised: isPhraseeXInitialised } = useAppSelector(
    (state) => state.campaignStates.campaignData.campaign_progress
  )

  const { campaignTokenEnabled, campaignToken } = useAppSelector((state) => ({
    campaignTokenEnabled:
      state.campaignStates.campaignData.campaign_configuration
        ?.campaign_token_enabled,
    campaignToken:
      state.campaignStates.campaignData.campaign_data?.campaign_token,
  }))

  const isCachingEnabled: boolean = useAppSelector(
    (state) =>
      !!state.campaignStates.campaignData.campaign_configuration
        ?.realtime_api_settings?.caching
  )

  const campaignData = useAppSelector(
    (state) => state.campaignStates?.campaignData?.campaign_data
  )

  const isRealTimeApiEnabled = useAppSelector(
    (state) =>
      state.campaignStates?.campaignData?.campaign_configuration
        ?.realtime_api_enabled
  )

  const isASaleforcePhraseeXIntegration = useAppSelector((state) => {
    return (
      state.campaignStates?.campaignData?.campaign_configuration
        ?.integration_options?.type === 'sfmcv2' &&
      state.campaignStates?.campaignData?.campaign_configuration?.testing_method
        ?.optimization_method.selection_type === 'bandit'
    )
  })

  const isASaleforcePushPhraseeXIntegration = useAppSelector((state) => {
    return (
      state.campaignStates?.campaignData?.campaign_configuration
        ?.integration_options?.type === 'sfmcv2' &&
      state.campaignStates?.campaignData?.campaign_configuration?.testing_method
        ?.optimization_method.selection_type === 'bandit' &&
      state.campaignStates?.campaignData?.campaign_configuration
        ?.distribution_channel === 'push_notification'
    )
  })

  const shouldShowTestMode = helpers.shouldShowTestMode(campaignConfiguration)

  const productType = useAppSelector(
    (state) =>
      state.campaignStates.campaignData.campaign_configuration?.product_type
  )

  const typeOfIntegration = useAppSelector(
    (state) =>
      state.campaignStates?.campaignData?.campaign_configuration
        ?.integration_options?.type
  )

  const shouldDisplayRealTimeApiUrl =
    isRealTimeApiEnabled && typeOfIntegration !== 'cordial'

  const frequencies: { name: string; value: RealtimeUpdateTimeUnit }[] = [
    ...(campaignDistributionType === 'broadcast'
      ? [{ name: 'Once', value: 'minutes' as RealtimeUpdateTimeUnit }]
      : []),
    { name: 'Daily', value: 'days' },
    { name: 'Weekly', value: 'weeks' },
    { name: 'Monthly', value: 'months' },
  ]

  const shouldSaveSchedule =
    typeOfIntegration !== 'adobe_campaign_classic' && isRealTimeApiEnabled

  const hasIntegrationConfiguration = !!useAppSelector(
    (state) =>
      state.campaignStates?.campaignData?.campaign_configuration
        ?.integration_options?.configuration
  )

  const getPhraseeXEndpoint = () => {
    const baseUrl = `${PHRASEEX_ENDPOINT}?campaign_id=${campaignId}`
    let endpoint = baseUrl
    if (typeOfIntegration === 'iterable') {
      endpoint += `&recipient_id={{#urlEncode}}{{email}}{{/urlEncode}}&delivery_id={{campaignId}}`
    } else if (
      isCachingEnabled ||
      helpers.hasClicksUnsubsConversionsAsDataSyncSource(campaignConfiguration)
    ) {
      endpoint += `&recipient_id=<unique customer ID>&delivery_id=<unique delivery ID>`
    }
    if (campaignTokenEnabled && campaignToken) {
      endpoint += `&campaign_token=${campaignToken}`
    }
    return endpoint
  }

  const phraseeXEndpoint = getPhraseeXEndpoint()

  const scheduleHandler = async (isUpdating: boolean) => {
    const startDateTime = getIsoDate(startDate, startTime?.format(TIME_FORMAT))
    const endDateTime = getIsoDate(endDate, endTime?.format(TIME_FORMAT))

    const { optimization_start_time } = campaignData || {}

    const submissionErrorText = submissionError(
      optimization_start_time,
      startDateTime,
      endDateTime,
      campaignDistributionType
    )
    if (submissionErrorText) {
      setError(submissionErrorText)
      return
    }

    try {
      const payload = {
        campaign_id: campaignId,
        optimization_start_time: startDateTime,
        optimization_end_time: endDateTime,
        campaign_start_time: startDateTime,
        campaign_end_time: endDateTime,
        dynamic_optimization_paused: isDynamicOptimizationPaused,
        ...createAdvancedSettingsPayload(
          advancedSettingsState,
          campaignDistributionType,
          flags.showDoAutomaticVariantIntroduction
        ),
      }

      const PhraseeXSchedulePayload = {
        optimization_start_time: startDateTime,
        optimization_end_time: endDateTime,
      }

      setIsLoading(true)
      setError(undefined)
      if (!isPhraseeXInitialised) {
        await initializePhraseeX(campaignId, payload)
      } else if (isUpdating) {
        await updatePhraseeX(campaignId, payload)
        if (shouldSaveSchedule) {
          await updatePhraseeXSchedule(campaignId, {
            ...PhraseeXSchedulePayload,
            realtime_update_interval: frequency === 'minutes' ? 15 : 1,
            realtime_update_time_unit: frequency!,
          })
        }
      }

      // this fetches campaignData after user scheduled new campaign, so when user closed integration drawer and reopened it again - drawer displays  newly created campaign and is not empty
      dispatch(fetchCampaignData(campaignId))

      setIsLoading(false)
      setIsScheduled(true)
    } catch (err: any) {
      setError(err?.response?.data?.name || err?.message)
      setIsLoading(false)
    }
  }

  const scheduleSFMCPhraseeX = async () => {
    setIsLoading(true)
    setError(undefined)
    const startDateTime = getIsoDate(startDate, startTime?.format(TIME_FORMAT))
    const endDateTime = getIsoDate(endDate, endTime?.format(TIME_FORMAT))

    const { optimization_start_time } = campaignData || {}
    const submissionErrorText = submissionError(
      optimization_start_time,
      startDateTime,
      endDateTime,
      campaignDistributionType
    )
    if (submissionErrorText) {
      setError(submissionErrorText)
      return
    }

    const payloadData = {
      campaign_id: campaignId,
      dynamic_optimization_paused: isDynamicOptimizationPaused,
      schedules:
        campaignDistributionType === 'broadcast'
          ? [
              {
                recurring: true,
                interval_type: 'hours',
                interval_value: 1,
                start_utc_iso_date_time: startDateTime,
                end_utc_iso_date_time: endDateTime,
                utc_iso_date_time:
                  generateSFMCReactRecurringScheduleForBroadcast(),
              },
              {
                utc_iso_date_time:
                  generateSFMCReactFirstSchedulerTickForBroadcast(
                    startDateTime
                  ),
              },
              {
                utc_iso_date_time: moment(startDateTime)
                  .subtract(15, 'minutes')
                  .toISOString(),
              },
            ]
          : [
              {
                recurring: true,
                interval_type: 'days',
                interval_value: 1,
                start_utc_iso_date_time: startDateTime,
                end_utc_iso_date_time: endDateTime,
                utc_iso_date_time: generateSFMCReactRecurringSchedule(),
              },
              {
                utc_iso_date_time: moment(startDateTime)
                  .subtract(30, 'minutes')
                  .toISOString(),
              },
            ],
      ...(campaignDistributionType === 'broadcast' && {
        is_sfmc_engage: true,
      }),
      interval_value: campaignDistributionType === 'broadcast' ? 45 : 1,
      interval_type: frequency!,
      optimization_start_time: startDateTime,
      optimization_end_time: endDateTime,
      campaign_start_time: startDateTime,
      campaign_end_time: endDateTime,
      client_id: mid,
      ...createAdvancedSettingsPayload(
        advancedSettingsState,
        campaignDistributionType,
        flags.showDoAutomaticVariantIntroduction
      ),
    }

    if (!isPhraseeXInitialised) {
      try {
        if (
          isASaleforcePushPhraseeXIntegration &&
          campaignDistributionType === 'broadcast'
        ) {
          await initializePhraseeXPushBroadcast(payloadData)
        } else if (isASaleforcePushPhraseeXIntegration) {
          await initializePhraseeXPush(payloadData)
        } else {
          await initializePhraseeX(campaignId, payloadData)
        }
        dispatch(loadResultsDetails(campaignId))
      } catch (err: any) {
        setError(err?.response?.data?.name || err?.message)
        setIsLoading(false)
      }
    } else {
      try {
        await updatePhraseeX(campaignId, payloadData)
      } catch (err: any) {
        setError(err?.response?.data?.name || err?.message)
        setIsLoading(false)
      }
    }
    dispatch(fetchCampaignData(campaignId))
    setIsLoading(false)
  }

  useEffect(() => {
    if (campaignData?.hasOwnProperty('bandit_data')) {
      setIsScheduled(true)
      const { optimization_start_time, optimization_end_time, bandit_data } =
        campaignData
      const {
        allow_control_drop,
        drop_bad_variants,
        interval_type,
        optimization_settings,
        performance_metric,
        dynamic_optimization_paused,
        language_controller,
        optimization_metric,
        optimization_audience,
      } = bandit_data

      setIsDynamicOptimizationPaused(!!dynamic_optimization_paused)
      optimization_start_time && setStartDate(moment(optimization_start_time))
      optimization_end_time && setEndDate(moment(optimization_end_time))
      optimization_start_time && setStartTime(moment(optimization_start_time))
      optimization_end_time && setEndTime(moment(optimization_end_time))
      const frequency = frequencies.find((f) => f.value === interval_type)
      setFrequency(
        (interval_type as RealtimeUpdateTimeUnit) ||
          (frequency?.value as RealtimeUpdateTimeUnit) ||
          ('days' as RealtimeUpdateTimeUnit)
      )

      drop_bad_variants !== undefined &&
        dispatchAdvancedSettingsState({
          type: 'set_is_drop_bad_variants_enabled',
          value: drop_bad_variants,
        })
      allow_control_drop !== undefined &&
        dispatchAdvancedSettingsState({
          type: 'set_is_drop_human_control_enabled',
          value: allow_control_drop,
        })
      performance_metric &&
        dispatchAdvancedSettingsState({
          type: 'set_optimization_metric',
          value: hasIntegrationConfiguration ? 'generic' : performance_metric,
        })
      optimization_metric &&
        optimization_audience &&
        dispatchAdvancedSettingsState({
          type: 'set_integration_optimization_metrics',
          value: {
            integrationOptimizationAudience: optimization_audience,
            integrationOptimizationMetric: optimization_metric,
          },
        })

      if (optimization_settings) {
        const {
          mode,
          head_to_head_days,
          head_to_head_winner_prop,
          pool_size,
          min_hc_percent,
          test_audience_size,
        } = optimization_settings

        dispatchAdvancedSettingsState({
          type: 'set_optimization_mode',
          value: mode,
        })

        const languageControllerMode = language_controller
          ? language_controller.mode
          : !!head_to_head_days
          ? optionHeadToHeadAndWait.value
          : undefined

        if (flags.showDoAutomaticVariantIntroduction) {
          dispatchAdvancedSettingsState({
            type: 'set_be_smart_when_to_introduce_variants_value',
            value: languageControllerMode,
          })
        } else {
          dispatchAdvancedSettingsState({
            type: 'set_is_days_before_adding_lines_enabled',
            value: !!head_to_head_days,
          })
        }

        dispatchAdvancedSettingsState({
          type: 'set_days_before_adding_lines',
          value: head_to_head_days,
        })

        dispatchAdvancedSettingsState({
          type: 'set_proportion_send_to_winner_number',
          value:
            head_to_head_winner_prop !== null
              ? handlePercentValue('percent', head_to_head_winner_prop)
              : undefined,
        })

        dispatchAdvancedSettingsState({
          type: 'set_high_performers_number',
          value: pool_size,
        })

        dispatchAdvancedSettingsState({
          type: 'set_high_performers_number',
          value: pool_size,
        })
        dispatchAdvancedSettingsState({
          type: 'set_is_min_sends_to_human_control_enabled',
          value: !!min_hc_percent,
          isBroadcastCampaign: campaignDistributionType === 'broadcast',
        })
        dispatchAdvancedSettingsState({
          type: 'set_min_sends_to_human_control_value',
          value:
            min_hc_percent !== undefined
              ? Math.trunc(min_hc_percent * 100)
              : undefined,
        })

        dispatchAdvancedSettingsState({
          type: 'set_test_audience_size_enabled',
          value: !!test_audience_size,
          isBroadcastCampaign: campaignDistributionType === 'broadcast',
        })
        dispatchAdvancedSettingsState({
          type: 'set_test_audience_size_value',
          value:
            test_audience_size !== undefined
              ? Math.trunc(test_audience_size * 100)
              : undefined,
        })

        // Save original bandit data to track user changes in Advanced settings.
        // Each mode has different sets of possible combinations, that's why we don't want to define properties if they are not included.
        const defaultAdvancedSettingsPayload = {
          allow_control_drop,
          drop_bad_variants,
          performance_metric,
          optimization_settings: {
            min_hc_percent:
              min_hc_percent !== undefined
                ? Math.trunc(min_hc_percent * 100)
                : undefined,
            test_audience_size:
              test_audience_size !== undefined
                ? Math.trunc(test_audience_size * 100)
                : undefined,
          },
          ...(flags.showDoAutomaticVariantIntroduction && {
            language_controller: {
              mode: languageControllerMode,
            },
          }),
        }
        let optimizationSettingsModeConfig: OptimizationSettingsConstraints = {
          mode: 'single_best',
          head_to_head_days,
        }
        if (mode === 'random_high_performers') {
          optimizationSettingsModeConfig = {
            mode: 'random_high_performers',
            pool_size,
          }
        } else if (mode === 'confidence') {
          optimizationSettingsModeConfig = {
            mode: 'confidence',
            head_to_head_winner_prop: handlePercentValue(
              'percent',
              head_to_head_winner_prop
            ),
            head_to_head_days,
          }
        }
        let integrationConfigurationMetrics
        if (hasIntegrationConfiguration) {
          integrationConfigurationMetrics = {
            integration_audience_metric: optimization_audience ?? undefined,
            integration_optimization_metric: optimization_metric ?? undefined,
          }
        }
        setOriginalBanditData({
          ...defaultAdvancedSettingsPayload,
          ...(hasIntegrationConfiguration && integrationConfigurationMetrics),
          optimization_settings: {
            ...defaultAdvancedSettingsPayload['optimization_settings'],
            ...optimizationSettingsModeConfig,
          },
        })
      }

      // Backward compatibility, in case a campaign has old data structure for "optimization mode"
      if (bandit_data.optimization_mode) {
        dispatchAdvancedSettingsState({
          type: 'set_optimization_mode',
          value: advancedSettingsState.optimizationMode,
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData])

  // for new (not yet initialised) PhraseeX react campaigns dropBadVariants is set to true, for engage campaigns it stays false
  useEffect(() => {
    if (!isPhraseeXInitialised && productType === 'react') {
      dispatchAdvancedSettingsState({
        type: 'set_is_drop_bad_variants_enabled',
        value: true,
      })
    }
  }, [isPhraseeXInitialised, productType])

  // If the campaign distribution type is broadcast we default the frequency to once
  useEffect(() => {
    if (campaignDistributionType === 'broadcast' && !frequency) {
      setFrequency('minutes')
    }
  }, [campaignDistributionType, frequency])
  // Hook to track when "Advanced settings" has changed. Enables update button if data has changed.
  useEffect(() => {
    // Update is possible only when campaign is scheduled and campaign has 'bandit_data'
    if (isScheduled && originalBanditData) {
      let currentUserAdvancedOptions: AdvancedSettingsPayload | undefined =
        undefined

      // Each mode has different sets of possible combinations
      if (advancedSettingsState.optimizationMode === singleBest.value) {
        currentUserAdvancedOptions = {
          allow_control_drop: advancedSettingsState.isDropHumanControlEnabled,
          drop_bad_variants: advancedSettingsState.isDropBadVariantsEnabled,
          performance_metric: advancedSettingsState.optimizationMetric,
          optimization_settings: {
            mode: advancedSettingsState.optimizationMode,
            min_hc_percent:
              advancedSettingsState.minSendsToHumanControlInputValue,
            test_audience_size: advancedSettingsState.testAudienceSizeValue,
            head_to_head_days: advancedSettingsState.daysBeforeAddingLines,
          },
          ...(hasIntegrationConfiguration
            ? {
                integration_optimization_metric:
                  advancedSettingsState.integrationOptimizationMetric,
                integration_audience_metric:
                  advancedSettingsState.integrationOptimizationAudience,
              }
            : {}),
          ...(flags.showDoAutomaticVariantIntroduction && {
            language_controller: {
              mode: advancedSettingsState.beSmartWhenToIntroduceVariants,
            },
          }),
        }
      }
      if (advancedSettingsState.optimizationMode === highPerformers.value) {
        currentUserAdvancedOptions = {
          allow_control_drop: advancedSettingsState.isDropHumanControlEnabled,
          drop_bad_variants: advancedSettingsState.isDropBadVariantsEnabled,
          performance_metric: advancedSettingsState.optimizationMetric,
          optimization_settings: {
            mode: advancedSettingsState.optimizationMode,
            min_hc_percent:
              advancedSettingsState.minSendsToHumanControlInputValue,
            test_audience_size: advancedSettingsState.testAudienceSizeValue,
            pool_size: advancedSettingsState.highPerformersNumber,
          },
          ...(hasIntegrationConfiguration
            ? {
                integration_optimization_metric:
                  advancedSettingsState.integrationOptimizationMetric,
                integration_audience_metric:
                  advancedSettingsState.integrationOptimizationAudience,
              }
            : {}),
          ...(flags.showDoAutomaticVariantIntroduction && {
            language_controller: {
              mode: advancedSettingsState.beSmartWhenToIntroduceVariants,
            },
          }),
        }
      }
      if (advancedSettingsState.optimizationMode === confidence.value) {
        currentUserAdvancedOptions = {
          optimization_settings: {
            mode: advancedSettingsState.optimizationMode,
            min_hc_percent:
              advancedSettingsState.minSendsToHumanControlInputValue,
            test_audience_size: advancedSettingsState.testAudienceSizeValue,
            head_to_head_days: advancedSettingsState.daysBeforeAddingLines,
            head_to_head_winner_prop:
              advancedSettingsState.proportionSendToWinnerNumber,
          },
          allow_control_drop: advancedSettingsState.isDropHumanControlEnabled,
          drop_bad_variants: advancedSettingsState.isDropBadVariantsEnabled,
          performance_metric: advancedSettingsState.optimizationMetric,
          ...(hasIntegrationConfiguration
            ? {
                integration_optimization_metric:
                  advancedSettingsState.integrationOptimizationMetric,
                integration_audience_metric:
                  advancedSettingsState.integrationOptimizationAudience,
              }
            : {}),
          ...(flags.showDoAutomaticVariantIntroduction && {
            language_controller: {
              mode: advancedSettingsState.beSmartWhenToIntroduceVariants,
            },
          }),
        }
      }
      if (!isEqual(originalBanditData, currentUserAdvancedOptions)) {
        setAreAdvancedSettingsUpdateable(true)
      } else {
        setAreAdvancedSettingsUpdateable(false)
      }
    }
  }, [
    advancedSettingsState.daysBeforeAddingLines,
    advancedSettingsState.highPerformersNumber,
    advancedSettingsState.isDropBadVariantsEnabled,
    advancedSettingsState.isDropHumanControlEnabled,
    advancedSettingsState.minSendsToHumanControlInputValue,
    advancedSettingsState.optimizationMetric,
    advancedSettingsState.optimizationMode,
    advancedSettingsState.proportionSendToWinnerNumber,
    advancedSettingsState.testAudienceSizeValue,
    advancedSettingsState.integrationOptimizationMetric,
    advancedSettingsState.integrationOptimizationAudience,
    isScheduled,
    originalBanditData,
    advancedSettingsState.beSmartWhenToIntroduceVariants,
    flags.showDoAutomaticVariantIntroduction,
    hasIntegrationConfiguration,
  ])

  useEffect(() => {
    if (isCampaignScheduledAlready && campaignMid) {
      setMid(campaignMid)
    } else {
      if (midList?.length && !mid) {
        setMid(midList[0].mid)
      }
      if (appMidListError) {
        setMidListError(MIDLIST_ERROR_CASE.httpFailed)
      }
    }
    if (!midList?.length && !appMidListError && !isLoadingMid) {
      if (!hasMadeMidCall) {
        !campaignMid &&
          dispatch(getMID(projectId)).then(() => setHasMadeMidCall(true))
      } else {
        setMidListError(MIDLIST_ERROR_CASE.emptyMid)
      }
    }
  }, [
    dispatch,
    midList,
    projectId,
    appMidListError,
    hasMadeMidCall,
    campaignSchedules,
    campaignMid,
    isCampaignScheduledAlready,
    mid,
    isLoadingMid,
  ])

  const disabledStartDate = (current: Moment | null) => {
    return !!current && current < moment().add(-1, 'days').endOf('day')
  }
  const disabledEndDate = (current: Moment | null) => {
    return isDisabledEndDate(current, campaignDistributionType, startDate)
  }

  const getDisabledStartHours = (): number[] => {
    const now = moment()
    const isSameDay = now.isSame(startDate || now, 'day')
    if (!isSameDay) {
      return []
    }

    const nextHour = moment(now).add(1, 'hour').startOf('hour')
    const allowedTime = moment(now).add(START_TIME_ADDED_MINUTES, 'minutes')

    const duration = moment.duration(nextHour.diff(allowedTime))
    const diffInMinutes = duration.asMinutes()
    const nextSelectableTime =
      diffInMinutes >= START_TIME_ADDED_MINUTES ? allowedTime : nextHour

    return isSameDay ? [...Array(nextSelectableTime.hour()).keys()] : []
  }

  const getDisabledStartMinutes = (selectedHour: number): number[] => {
    const now = moment()
    const isSameDay = now.isSame(startDate || now, 'day')
    const allowedTime = moment(now).add(START_TIME_ADDED_MINUTES, 'minutes')

    if (isSameDay && selectedHour <= allowedTime.hour()) {
      return [...Array(allowedTime.minutes()).keys()]
    }

    return []
  }

  const getDisabledEndHours = (): number[] => {
    const now = moment()
    const isSameDay = now.isSame(endDate || now, 'day')
    if (!isSameDay) {
      return []
    }

    const nextHour = moment(now).add(1, 'hour').startOf('hour')
    const allowedTime = moment(now).add(START_TIME_ADDED_MINUTES, 'minutes')

    const duration = moment.duration(nextHour.diff(allowedTime))
    const diffInMinutes = duration.asMinutes()
    const nextSelectableTime =
      diffInMinutes >= START_TIME_ADDED_MINUTES ? allowedTime : nextHour

    return [...Array(nextSelectableTime.hour()).keys()]
  }

  const getDisabledEndMinutes = (selectedHour: number): number[] => {
    const now = moment()
    const isSameDay = now.isSame(endDate || now, 'day')
    const allowedTime = moment(now).add(START_TIME_ADDED_MINUTES, 'minutes')

    if (isSameDay && selectedHour <= allowedTime.hour()) {
      return [...Array(allowedTime.minutes()).keys()]
    }

    return []
  }

  const checkIsOptimizationUpdating = (campaignData: CampaignData) => {
    const { optimization_start_time, optimization_end_time } =
      campaignData || {}

    const hasStartDateChanged =
      startDate?.format('YYYY-MM-DD') !==
      moment(optimization_start_time)?.format('YYYY-MM-DD')

    const hasEndDateChanged =
      endDate?.format('YYYY-MM-DD') !==
      moment(optimization_end_time)?.format('YYYY-MM-DD')

    const hasStartTimeChanged =
      moment(optimization_start_time)?.format(TIME_FORMAT) !==
      startTime?.format(TIME_FORMAT)

    const hasEndTimeChanged =
      moment(optimization_end_time)?.format(TIME_FORMAT) !==
      endTime?.format(TIME_FORMAT)
    return (
      isScheduled &&
      isPhraseeXInitialised &&
      (hasStartDateChanged ||
        hasEndDateChanged ||
        hasStartTimeChanged ||
        hasEndTimeChanged)
    )
  }

  const isTestModeUpdating =
    isDynamicOptimizationPaused !==
    campaignData?.bandit_data?.dynamic_optimization_paused

  const isOptimizationUpdating = checkIsOptimizationUpdating(campaignData)

  const isValidIntegrationConfiguration =
    !hasIntegrationConfiguration ||
    (hasIntegrationConfiguration &&
      advancedSettingsState.integrationOptimizationAudience &&
      advancedSettingsState.integrationOptimizationMetric)

  const areInputsUpdateable =
    (areAdvancedSettingsUpdateable && isValidIntegrationConfiguration) ||
    isOptimizationUpdating ||
    (campaignData?.bandit_data?.dynamic_optimization_paused !== undefined &&
      isTestModeUpdating)

  const isCtaEnabled = !!(
    (startDate &&
      startTime &&
      endDate &&
      endTime &&
      isValidIntegrationConfiguration) ||
    areInputsUpdateable
  )

  const canRescheduleSchedule = (scheduleTime: string, isEndTime: boolean) => {
    if (campaignDistributionType === 'AlwaysOn' && isEndTime) {
      return true
    }

    if (
      isLoading ||
      (isPhraseeXInitialised &&
        !(moment(scheduleTime)?.diff(moment(), 'seconds') > 0))
    ) {
      return false
    }
    return true
  }

  const isExperimentBeingRestarted = () => {
    const endDateTime = getIsoDate(endDate, endTime?.format(TIME_FORMAT))
    const now = getIsoDate(moment())
    const { optimization_end_time } = campaignData || {}

    if (
      isPhraseeXInitialised &&
      endDateTime > optimization_end_time &&
      optimization_end_time < now
    ) {
      return true
    }
    return false
  }

  const canRescheduleOptimizationStart = canRescheduleSchedule(
    campaignData?.optimization_start_time,
    false
  )

  const canRescheduleOptimizationEnd = canRescheduleSchedule(
    campaignData?.optimization_end_time,
    true
  )

  const getMidDisplayName = (midObject: MidObject) => {
    if (midObject.mid && midObject.name) {
      return `${midObject.mid} - ${midObject.name}`
    } else if (midObject.mid && !midObject.name) {
      return `${midObject.mid}`
    }

    return ''
  }
  const getMidValues = () => {
    const midElement = midList.find((midObject: MidObject) => {
      return mid ? midObject.mid === mid : midObject
    })
    return midElement ? getMidDisplayName(midElement) : `${mid}`
  }

  const testModeDescription = getTestModeDescription(isScheduled, campaignData)

  const detectedTimezone = moment.tz.guess()

  // TODO: Use scheduler component
  return (
    <>
      <Drawer.Content>
        <div className="text-coolGray-500 font-medium mb-6 text-base">
          Schedule your dynamic optimization experiment.
        </div>
        {isASaleforcePhraseeXIntegration && (
          <Form.Item label="Salesforce MID">
            {isCampaignScheduledAlready ? (
              <p>{getMidValues()}</p>
            ) : (
              <SingleSelect
                isLoading={isLoadingMid}
                data-testid="selectMid"
                isDisabled={mid?.length <= 1 || campaignMid}
                placeholder="Select a MID"
                options={midList?.map((midObject: MidObject) => ({
                  label: getMidDisplayName(midObject),
                  value: midObject.mid,
                }))}
                value={mid}
                onChange={(value) => value && setMid(value.value)}
              />
            )}
            {midListError && (
              <span className="text-sm font-normal">{midListError}</span>
            )}
          </Form.Item>
        )}
        {isScheduled && frequency && shouldShowTestMode && (
          <TestModeAlert
            campaignData={campaignData}
            isPaused={isDynamicOptimizationPaused}
            setIsPaused={setIsDynamicOptimizationPaused}
            testModeDescription={testModeDescription}
          />
        )}
        <OptimizationSettings
          advancedSettingsState={advancedSettingsState}
          dispatchAdvancedSettings={dispatchAdvancedSettingsState}
        />
        <Card className="my-6">
          <div className="flex items-center text-coolGray-400 mb-4">
            <TimeIcon size={6} />
            <span className="text-coolGray-800 font-medium text-base ml-4">
              Optimization schedule
            </span>
          </div>
          <Label>Start schedule</Label>
          <div className="flex mb-6 gap-4">
            <DatePickerAntd
              allowClear={false}
              ariaLabel="startDatePicker"
              disabled={!canRescheduleOptimizationStart}
              disabledDate={disabledStartDate}
              value={startDate}
              onChange={(date: Moment | null) => {
                if (date) {
                  setStartDate(date)
                  setEndDate(moment(date).add(4, 'days'))
                }
              }}
            />
            <TimePicker
              allowClear={false}
              data-testid="startTimePicker"
              className={cx(styles.integrationTimePicker, 'w-27')}
              disabled={!canRescheduleOptimizationStart}
              suffixIcon={<TimeIcon size={6} />}
              format={TIME_FORMAT}
              minuteStep={15}
              value={startTime ?? undefined}
              disabledHours={getDisabledStartHours}
              disabledMinutes={getDisabledStartMinutes}
              onChange={(time: Moment) => {
                setStartTime(time)
              }}
              placeholder="Time"
            />
          </div>
          <Label>End schedule</Label>
          <div className="flex mb-6 gap-4">
            <DatePickerAntd
              allowClear={false}
              ariaLabel="endDatePicker"
              disabled={!canRescheduleOptimizationEnd}
              disabledDate={disabledEndDate}
              value={endDate}
              onChange={(date: Moment | null) => {
                if (date) {
                  setEndDate(date)
                }
              }}
            />
            <TimePicker
              allowClear={false}
              data-testid="endTimePicker"
              className={cx(styles.integrationTimePicker, 'w-27')}
              disabled={!canRescheduleOptimizationEnd}
              suffixIcon={<TimeIcon size={6} />}
              format={TIME_FORMAT}
              minuteStep={15}
              value={endTime ?? undefined}
              onChange={(time: Moment) => {
                setEndTime(time)
              }}
              placeholder="Time"
              disabledHours={getDisabledEndHours}
              disabledMinutes={getDisabledEndMinutes}
            />
          </div>
          <div className="mb-2 font-medium">
            Detected Timezone: {detectedTimezone}{' '}
            {moment.tz(detectedTimezone).zoneAbbr()}
          </div>
        </Card>
        <AdvancedOptions
          advancedSettingsState={advancedSettingsState}
          dispatchAdvancedSettings={dispatchAdvancedSettingsState}
        />
        {shouldDisplayRealTimeApiUrl && (
          <>
            <Label>Copy this URL in to your provider</Label>
            <CopyCard
              isCopied={isUrlCopied}
              text={phraseeXEndpoint}
              setIsCopied={setIsUrlCopied}
            />
            <GuidingText
              className="mt-3"
              text="This allows your provider to connect to the correct experiment within Jacquard."
            />
          </>
        )}
      </Drawer.Content>
      <Drawer.Footer error={error}>
        <Button
          isDisabled={!isCtaEnabled}
          isUpdateable={areInputsUpdateable}
          isLoading={isLoading}
          isScheduled={isScheduled}
          scheduleHandler={
            isASaleforcePhraseeXIntegration
              ? scheduleSFMCPhraseeX
              : scheduleHandler
          }
          isExperimentBeingRestarted={isExperimentBeingRestarted()}
        />
      </Drawer.Footer>
    </>
  )
}

export default PhraseeXIntegration
