import {
  ComponentElement,
  Element,
  TemplateElement,
} from '@phrasee/phrasee-typings/typings/futurama/element'

export function isComponentElement(
  element: Element
): element is ComponentElement {
  return element.element_type === 'component'
}

export function isTemplateElement(
  element: Element
): element is TemplateElement {
  return element.element_type === 'template'
}
