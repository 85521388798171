import { SyntheticEvent } from 'react'

import Button, { ButtonProps, Variant } from '../button/Button'
import Modal from '../Modal'

export interface ConfirmationModalProps {
  open: boolean
  onCancel: () => void
  isLoading?: boolean
  onConfirm: (e: SyntheticEvent<HTMLButtonElement, MouseEvent>) => void
  title: string
  hasConfirmationSlider?: boolean
  confirmationText: string | JSX.Element
  cancelButtonText?: string
  confirmButtonText?: string
  confirmButtonProps?: ButtonProps
  'data-cy'?: string
  'data-testid'?: string
  confirmButtonVariant?: Variant
}

export const confirmModalText = {
  confirm: 'Yes, delete it!',
}

const ConfirmationModal = ({
  open,
  onCancel,
  isLoading,
  onConfirm,
  title,
  confirmationText,
  cancelButtonText = 'Noooo, cancel',
  confirmButtonText = confirmModalText.confirm,
  confirmButtonProps,
  hasConfirmationSlider = false,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  confirmButtonVariant = 'danger',
}: ConfirmationModalProps) => {
  return (
    <Modal
      visible={open}
      centered
      closable={false}
      footer={null}
      data-cy={dataCy}
      data-testid={dataTestId}
    >
      <div className="flex">
        <h1 className="mt-2 text-2xl font-bold text-black">{title}</h1>
      </div>

      <div className="mt-4 mb-10 text-lg text-coolGray-500">
        {confirmationText}
      </div>

      <div className="flex justify-end">
        <Button
          ghost
          className="mr-4 text-base text-coolGray-400 border border-coolGray-100 "
          onClick={onCancel}
          data-testid="confirm-cancel-button"
          data-cy="confirm-cancel-button"
        >
          {cancelButtonText}
        </Button>

        <Button
          variant={confirmButtonVariant}
          onClick={onConfirm}
          loading={isLoading}
          data-cy="confirm-confirm-button"
          {...confirmButtonProps}
        >
          {confirmButtonText}
        </Button>
      </div>
    </Modal>
  )
}

const ConfirmationModalWithResetState = (props: ConfirmationModalProps) => (
  <ConfirmationModal key={`${props.open}`} {...props} />
)
export default ConfirmationModalWithResetState
