import { Route } from 'react-router'
import {
  personalizationPerIdPath,
  reportsPersonalizationPath,
} from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'

import PersonalizationReportWithParam from './personalizationsPage/PersonalizationReportWithParam'
import PersonalizationsPage from './personalizationsPage'

const Personalization = () => {
  return (
    <div className="flex flex-col h-full w-full relative bg-coolGray-100 overflow-y-auto">
      <CustomSwitch>
        <Route key="view-experiments" path={reportsPersonalizationPath} exact>
          <PersonalizationsPage />
        </Route>
        <Route key="view-experiment" path={personalizationPerIdPath}>
          <PersonalizationReportWithParam />
        </Route>
      </CustomSwitch>
    </div>
  )
}

export default Personalization
