/* eslint-disable max-lines */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { RootState } from 'redux/store'

import { getTags } from 'common/api/tagsApi'
import type { ProjectConfig } from 'common/interfaces/projects'
import { Tag } from 'common/interfaces/tags'
import { getProjectConfig } from 'features/projects/api'

import {
  fetchProjectCombinedLanguage,
  getNodes,
  getReplacers,
  updateProjectSettings,
} from '../api/api'
import type { Node } from '../interfaces'

type ResponsePathwayNode = {
  id: number
  node: string
  display_name: string
}

type PathwayNode = {
  id: string
  node: string
  displayName: string
}

type Pathway = {
  id: number
  startNode: PathwayNode
  endNodes: PathwayNode[]
}

type DefaultReplacer = {
  id: number
  replacer: string
  value: string
}

// Hardcoded for now
const SELECTED_ROOT_NODE_ID = '5'
type MergeTag = {
  id: number
  replacer: string
  tag: string
  hasError: boolean
}

type Data = {
  nodeSettings: {
    availableNodes: Pathway[]
    defaultNodes: string[]
    privateNodes: string[]
    specificityWeighting: number
  }
  defaultReplacers: DefaultReplacer[]
  activeTags: string[]
  replacerSettings: {
    isUsingListReplacers: boolean
    possibleConjunctions: string[]
    isUsingOxfordComma: boolean
    range: number[]
  }
  mergeTags: MergeTag[]
}
export type InitializePageResponse = {
  projectConfig: ProjectConfig
  languageSettings: LanguageSettingsResponse
  nodes: Node[]
  tags: Tag[]
}

export interface State {
  status: 'loading' | 'error' | 'idle'
  isSaving: boolean
  rawData: Omit<InitializePageResponse, 'nodes'> | undefined
  editedData: Data
  nodes: Node[]
  tags: Tag[]
}

export const initialState: State = {
  status: 'idle',
  isSaving: false,
  rawData: undefined,
  editedData: {
    nodeSettings: {
      availableNodes: [],
      defaultNodes: [],
      privateNodes: [],
      specificityWeighting: 0,
    },
    defaultReplacers: [],
    mergeTags: [],
    activeTags: [],
    replacerSettings: {
      isUsingListReplacers: false,
      possibleConjunctions: [],
      isUsingOxfordComma: false,
      range: [1, 3],
    },
  },
  nodes: [],
  tags: [],
}
export const isMergeTagValid = (mergeTag: MergeTag) => {
  return mergeTag.replacer && mergeTag.tag.length
}

export const initializePage = createAsyncThunk<
  InitializePageResponse,
  string,
  { state: RootState }
>('admin/nodeSettings/initializePage', async (projectId, { getState }) => {
  const state = getState()
  const { accountId } = state.authStates

  const languageSettings = await fetchProjectCombinedLanguage(projectId)
  const nodes = await getNodes()
  const tags = await getTags({
    rootNodeId: SELECTED_ROOT_NODE_ID,
  })
  const projectConfig = (await getProjectConfig(accountId, projectId)).data
    .project

  return { languageSettings, projectConfig, nodes, tags }
})

const processInitializePageResponse = ({
  projectConfig,
  languageSettings: languageSettingsResponse,
  nodes,
  tags,
}: InitializePageResponse): Data => {
  const nodeConfiguration = languageSettingsResponse?.node_configuration

  const mapNode = ({
    id,
    node,
    display_name,
  }: ResponsePathwayNode): PathwayNode => ({
    id: String(id),
    node,
    displayName: display_name,
  })
  const replacerSettings = languageSettingsResponse?.replacer_settings

  return {
    nodeSettings: {
      availableNodes:
        nodeConfiguration?.available_nodes?.map(
          ({ start_node, end_nodes }, index) => ({
            id: index,
            startNode: mapNode(start_node),
            endNodes: end_nodes.map((node) => mapNode(node)),
          })
        ) ?? [],
      defaultNodes:
        nodeConfiguration?.default_nodes
          ?.map((defaultNode) => {
            const retrievedNode = nodes.find(
              (node) => String(node.id) === String(defaultNode.id)
            )
            if (retrievedNode) {
              return retrievedNode.name
            } else {
              return undefined
            }
          })
          ?.filter(Boolean) ?? [],
      privateNodes:
        nodeConfiguration?.private_nodes
          ?.map((nodeId) => {
            return nodes.find((node) => node.id === String(nodeId))?.name
          })
          .filter(Boolean) ?? [],
      specificityWeighting: languageSettingsResponse?.leaf_weight ?? 0,
    },
    defaultReplacers:
      languageSettingsResponse?.default_replacers?.map(
        ({ replacer, replacer_id, value }) => ({
          id: replacer_id,
          replacer,
          value,
        })
      ) ?? [],
    activeTags:
      languageSettingsResponse?.active_tags?.map(({ tag }) => tag) ?? [],
    replacerSettings: {
      isUsingListReplacers:
        (replacerSettings?.minimum_items !== undefined &&
          replacerSettings?.maximum_items !== undefined) ??
        false,
      possibleConjunctions:
        replacerSettings?.conjunctions?.map(({ text }) => text) ?? [],
      isUsingOxfordComma: replacerSettings?.oxford_comma ?? false,
      range: [
        replacerSettings?.minimum_items ?? 1,
        replacerSettings?.maximum_items ?? 3,
      ],
    },
    mergeTags:
      languageSettingsResponse?.merge_tags?.map(
        ({ replacer, value }, index) => ({
          id: index,
          replacer,
          tag: value,
          hasError: false,
        })
      ) ?? [],
  }
}

type LanguageSettingsResponse = any

export const saveNodeSettings = createAsyncThunk<
  LanguageSettingsResponse,
  { projectId: string },
  { state: RootState }
>('admin/nodeSettings/save', async ({ projectId }, { getState }) => {
  const state = getState()
  const { nodes, tags } = state.projectNodes
  const { editedData: editedContent, rawData: rawContent } = state.projectNodes
  const rawNodeConfiguration =
    rawContent?.languageSettings.node_configuration || {}

  const replacers = await getReplacers()

  const mapNode = ({
    id,
    node,
    displayName,
  }: PathwayNode): ResponsePathwayNode => ({
    id: Number(id),
    node,
    display_name: displayName,
  })
  const defaultReplacersWithoutEmptyLines =
    editedContent.defaultReplacers.filter(
      ({ replacer, value }) => replacer.length > 0 && value.length > 0
    )
  const mergeTagsWithoutEmptyLines = editedContent.mergeTags.filter(
    ({ replacer, tag }) => replacer.length > 0 || tag.length > 0
  )

  const content = {
    ...rawContent?.languageSettings,
    node_configuration: {
      ...rawNodeConfiguration,
      default_nodes:
        editedContent.nodeSettings.defaultNodes
          .map((defaultNode) => {
            const retrievedNode = nodes.find(
              (node) =>
                node.name.toLowerCase() === defaultNode.toLocaleLowerCase()
            )
            if (retrievedNode) {
              return {
                id: Number.parseInt(retrievedNode.id),
                node: retrievedNode.node,
                display_name: retrievedNode.name,
              }
            } else {
              return undefined
            }
          })
          .filter(Boolean) ?? [],
      private_nodes:
        editedContent.nodeSettings.privateNodes
          .map((privateNode) => {
            const nodeIdString = nodes.find(
              (node) =>
                node.name.toLowerCase() === privateNode.toLocaleLowerCase()
            )?.id
            if (nodeIdString) {
              return Number.parseInt(nodeIdString)
            } else {
              return undefined
            }
          })
          .filter(Boolean) ?? [],
      available_nodes: editedContent.nodeSettings.availableNodes
        .filter(({ startNode }) => !!startNode.displayName)
        .map((pathway) => ({
          ...pathway,
          start_node: mapNode(pathway.startNode),
          end_nodes: pathway.endNodes.map((node) => mapNode(node)),
        })),
    },
    replacer_settings: {
      conjunctions: editedContent.replacerSettings.possibleConjunctions.map(
        (value) => ({ text: value, probability: 1 })
      ),
      oxford_comma: editedContent.replacerSettings.isUsingOxfordComma,
      ...(editedContent.replacerSettings.isUsingListReplacers
        ? {
            minimum_items: editedContent.replacerSettings.range[0],
            maximum_items: editedContent.replacerSettings.range[1],
          }
        : {}),
    },
    leaf_weight: editedContent.nodeSettings.specificityWeighting,
    default_replacers: defaultReplacersWithoutEmptyLines.map((item) => ({
      replacer_id: item.id,
      replacer: item.replacer,
      value: item.value,
    })),
    merge_tags: mergeTagsWithoutEmptyLines.map((item) => {
      return {
        replacer_id: replacers.find(
          (replacer) => replacer.replacer === item.replacer
        )?.replacerId,
        replacer: item.replacer,
        value: item.tag,
      }
    }),
    active_tags: editedContent.activeTags.map((item) => {
      const tagId = tags.find(({ tag }) => item === tag)?.tagId

      if (tagId) {
        return {
          tag_id: tagId,
          tag: item,
        }
      } else {
        return undefined
      }
    }),
    industry: undefined,
  }
  if (editedContent.nodeSettings.defaultNodes.length === 0) {
    return Promise.reject(
      'You must have at least one default node configured on a project before saving.'
    )
  }
  if (
    mergeTagsWithoutEmptyLines.some((mergeTag) => !isMergeTagValid(mergeTag))
  ) {
    return Promise.reject('Merge tag entry incomplete')
  }

  return updateProjectSettings(projectId, {
    languageSettings: content,
  })
})

export const nodeSettingsSlice = createSlice({
  name: 'admin/nodeSettings',
  initialState,
  reducers: {
    resetContent: (state) => {
      if (state.rawData) {
        state.editedData = processInitializePageResponse({
          ...state.rawData,
          nodes: state.nodes,
          tags: state.tags,
        })
      }
    },
    addPathwayRow: (state) => {
      state.editedData.nodeSettings.availableNodes = [
        {
          id: 0,
          startNode: { id: '0', node: '', displayName: '' },
          endNodes: [],
        },
        ...state.editedData.nodeSettings.availableNodes.map((pathway) => ({
          ...pathway,
          id: pathway.id + 1,
        })),
      ]
    },
    deletePathwayRow: (state, action: PayloadAction<number>) => {
      state.editedData.nodeSettings.availableNodes =
        state.editedData.nodeSettings.availableNodes
          .filter(({ id }) => id !== action.payload)
          .map((pathway, index) => ({ ...pathway, id: index }))
    },
    selectStartNode: (
      state,
      action: PayloadAction<{ index: string; nodeName: string }>
    ) => {
      const { index, nodeName } = action.payload
      const node = state.nodes.find(({ name }) => nodeName === name)

      if (!node) {
        throw new Error('Node not found')
      }

      const pathwayNode: PathwayNode = {
        id: node.id,
        node: node.node,
        displayName: node.name,
      }
      const pathway: Pathway = {
        id: Number(index),
        startNode: pathwayNode,
        endNodes: [],
      }

      state.editedData.nodeSettings.availableNodes[index] = pathway
    },
    addEndNode: (
      state,
      action: PayloadAction<{ index: string; nodeName: string }>
    ) => {
      const { index, nodeName } = action.payload
      const node = state.nodes.find(({ name }) => nodeName === name)

      if (!node) {
        throw new Error('Node not found')
      }

      const pathwayNode: PathwayNode = {
        id: node.id,
        node: node.node,
        displayName: node.name,
      }

      state.editedData.nodeSettings.availableNodes[index].endNodes.push(
        pathwayNode
      )
    },
    removeEndNode: (
      state,
      action: PayloadAction<{ index: string; nodeName: string }>
    ) => {
      const { index, nodeName } = action.payload

      state.editedData.nodeSettings.availableNodes[index].endNodes =
        state.editedData.nodeSettings.availableNodes[index].endNodes.filter(
          ({ displayName }) => displayName !== nodeName
        )
    },
    addDefaultNode: (state, action: PayloadAction<string>) => {
      state.editedData.nodeSettings.defaultNodes = [
        ...state.editedData.nodeSettings.defaultNodes,
        action.payload,
      ]
    },
    removeDefaultNode: (state, action: PayloadAction<string>) => {
      state.editedData.nodeSettings.defaultNodes =
        state.editedData.nodeSettings.defaultNodes.filter(
          (word) => word !== action.payload
        )
    },
    addPrivateNode: (state, action: PayloadAction<string>) => {
      state.editedData.nodeSettings.privateNodes = [
        ...state.editedData.nodeSettings.privateNodes,
        action.payload,
      ]
    },
    removePrivateNode: (state, action: PayloadAction<string>) => {
      state.editedData.nodeSettings.privateNodes =
        state.editedData.nodeSettings.privateNodes.filter(
          (word) => word !== action.payload
        )
    },
    updateSpecificityWeighting: (state, action: PayloadAction<number>) => {
      state.editedData.nodeSettings.specificityWeighting = action.payload
    },
    addActiveTag: (state, action: PayloadAction<string>) => {
      state.editedData.activeTags = [
        ...state.editedData.activeTags,
        action.payload,
      ]
    },
    removeActiveTag: (state, action: PayloadAction<string>) => {
      state.editedData.activeTags = state.editedData.activeTags.filter(
        (tag) => tag !== action.payload
      )
    },
    updateListReplacerRange: (state, action: PayloadAction<number[]>) => {
      state.editedData.replacerSettings.range = action.payload
    },
    removePossibleConjunction: (state, action: PayloadAction<string>) => {
      state.editedData.replacerSettings.possibleConjunctions =
        state.editedData.replacerSettings.possibleConjunctions.filter(
          (word) => word !== action.payload
        )
    },
    addPossibleConjunction: (state, action: PayloadAction<string>) => {
      state.editedData.replacerSettings.possibleConjunctions = [
        ...state.editedData.replacerSettings.possibleConjunctions,
        action.payload,
      ]
    },
    clickOxfordComma: (state, action: PayloadAction<boolean>) => {
      state.editedData.replacerSettings.isUsingOxfordComma = action.payload
    },
    clickListReplacers: (state, action: PayloadAction<boolean>) => {
      state.editedData.replacerSettings.isUsingListReplacers = action.payload
    },
    addDefaultReplacerRow: (state) => {
      state.editedData.defaultReplacers = [
        {
          id: 0,
          replacer: '',
          value: '',
        },
        ...state.editedData.defaultReplacers.map((replacer) => ({
          ...replacer,
          id: replacer.id + 1,
        })),
      ]
    },
    deleteDefaultReplacerRow: (state, action: PayloadAction<number>) => {
      state.editedData.defaultReplacers = state.editedData.defaultReplacers
        .filter(({ id }) => id !== action.payload)
        .map((replacer, index) => ({ ...replacer, id: index }))
    },
    updateDefaultReplacerCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const defaultReplacer = state.editedData.defaultReplacers[rowIndex]
      defaultReplacer[columnId] = value
    },
    addMergeTag: (state) => {
      state.editedData.mergeTags = [
        {
          id: 0,
          replacer: '',
          tag: '',
          hasError: false,
        },
        ...state.editedData.mergeTags.map((mergeTag) => ({
          ...mergeTag,
          id: mergeTag.id + 1,
        })),
      ]
    },
    deleteMergeTagRow: (state, action: PayloadAction<number>) => {
      state.editedData.mergeTags = state.editedData.mergeTags
        .filter(({ id }) => id !== action.payload)
        .map((mergeTag, index) => ({ ...mergeTag, id: index }))
    },
    updateMergeTagCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const mergeTag = state.editedData.mergeTags[rowIndex]
      mergeTag[columnId] = value
      mergeTag.hasError = mergeTag.hasError ? !isMergeTagValid(mergeTag) : false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializePage.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(initializePage.fulfilled, (state, action) => {
      state.rawData = action.payload
      state.nodes = action.payload.nodes
      state.tags = action.payload.tags
      state.editedData = processInitializePageResponse(action.payload)
      state.status = 'idle'
    })
    builder.addCase(initializePage.rejected, (state) => {
      state.status = 'error'
    })
    builder.addCase(saveNodeSettings.fulfilled, (state, action) => {
      state.isSaving = false
      if (state.rawData) {
        state.rawData.languageSettings = action.payload.languageSettings
        state.rawData.projectConfig = action.payload.projectConfig
        state.editedData = processInitializePageResponse({
          ...action.payload,
          nodes: state.nodes,
        })
      }
    })
    builder.addCase(saveNodeSettings.rejected, (state, action) => {
      state.isSaving = false
    })
    builder.addCase(saveNodeSettings.pending, (state, action) => {
      state.isSaving = true
    })
  },
})

/*
 * Actions
 */
export const {
  addPathwayRow,
  selectStartNode,
  addEndNode,
  removeEndNode,
  addDefaultNode,
  removeDefaultNode,
  addPrivateNode,
  removePrivateNode,
  updateSpecificityWeighting,
  addActiveTag,
  removeActiveTag,
  addMergeTag,
  deleteMergeTagRow,
  updateMergeTagCell,
  resetContent,
  deletePathwayRow,
  updateListReplacerRange,
  removePossibleConjunction,
  addPossibleConjunction,
  clickOxfordComma,
  clickListReplacers,
  addDefaultReplacerRow,
  deleteDefaultReplacerRow,
  updateDefaultReplacerCell,
} = nodeSettingsSlice.actions

/*
 * Selectors
 */
export const selectHasBeenEdited = (state: RootState): boolean =>
  !isEqual(selectOriginalContent(state), state.projectNodes.editedData)

export const selectOriginalContent = (state: RootState): Data | undefined =>
  state.projectNodes.rawData &&
  processInitializePageResponse({
    ...state.projectNodes.rawData,
    nodes: state.projectNodes.nodes,
  })

export default nodeSettingsSlice.reducer
