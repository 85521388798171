import { useEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { SplitCalculatorConfiguration } from 'features/unifiedFlow/api'
import { updateTemplateSplitConfiguration } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { TemplateInfoWithInternalId } from '../../addedTemplates/AddedComponentList'

import CalculatorCard from './components/CalculatorCard'

type Props = {
  configuration: SplitCalculatorConfiguration['splitCalculation']
  template?: TemplateInfoWithInternalId
}

const Fixed = ({ template, configuration }: Props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (template?.internalId) {
      dispatch(
        updateTemplateSplitConfiguration({
          internalId: template.internalId,
          splitConfiguration: {
            type: configuration.type,
            listSize: configuration.fixedSplitSettings?.size,
            splitNumber: configuration.fixedSplitSettings?.number,
            splitCalculationResult: {
              is_full_test: false,
              is_head_to_head: false,
              split_number: configuration.fixedSplitSettings?.number || 0,
              split_size: configuration.fixedSplitSettings?.size || 0,
              total_split_audience: '',
              headline: '',
              split_result: configuration.fixedSplitSettings?.text || '',
              show_other_split_options: false,
              other_split_options: [],
            },
          },
        })
      )
    }
  }, [dispatch, configuration, template?.internalId])

  return (
    <CalculatorCard className="items-center justify-between">
      <div className="text-sm font-medium text-coolGray-800 mr-6">
        Based on your preconfigured split settings, you will test:
        <span className="text-maroon-600 ml-1">
          {configuration.fixedSplitSettings?.text}
        </span>
      </div>
    </CalculatorCard>
  )
}

export default Fixed
