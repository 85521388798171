import { useEffect } from 'react'
import { ReadyState } from 'react-use-websocket'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'

import { useAppSelector } from 'common/hooks/redux'
import useWebsockets from 'common/hooks/useWebsockets'

export type WsResponse = {
  status: 'processing' | 'success' | 'failed'
  filename: string
  topics: string[]
}

const useDataIngestionWebsocketStatus = ({
  topic,
  onSuccess,
  onFailure,
}: {
  topic: string
  onSuccess?: ({
    accountId,
    wsResponse,
  }: {
    accountId: string
    wsResponse: WsResponse
  }) => void
  onFailure?: ({
    accountId,
    wsResponse,
  }: {
    accountId: string
    wsResponse: WsResponse
  }) => void
}) => {
  const {
    lastJsonMessage: wsResponse,
    sendJsonMessage,
  }: {
    lastJsonMessage: WsResponse | null
    sendJsonMessage: SendJsonMessage
    readyState: ReadyState
  } = useWebsockets()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const wsTopic = `csv-ingestion-status/${topic}/${accountId}`

  useEffect(() => {
    if (wsResponse?.topics?.includes(wsTopic)) {
      if (wsResponse.status === 'success') {
        onSuccess?.({ accountId, wsResponse })
        sendJsonMessage({ action: 'unsubscribe', topics: [wsTopic] })
      }
      if (wsResponse.status === 'failed') {
        onFailure?.({ accountId, wsResponse })
        sendJsonMessage({ action: 'unsubscribe', topics: [wsTopic] })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsResponse])

  return { lastJsonMessage: wsResponse, sendJsonMessage }
}

export default useDataIngestionWebsocketStatus
