import ActionIcon from 'common/components/ActionIcon'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  MenuHide as MenuHideIcon,
  MenuShow as MenuShowIcon,
} from 'common/icons'
import { toggleNavCollapse } from 'features/campaigns/store/campaignSlice'

const ToggleCampaignListButton = () => {
  const dispatch = useAppDispatch()
  const { isCampaignNavCollapsed } = useAppSelector((state) => state.campaigns)

  return (
    <span className="hidden md:inline-block mr-6">
      <ActionIcon
        key={isCampaignNavCollapsed ? 'collapsedKey' : 'expandedKey'} // Hack to hide the tooltip when collapsing/showing the campaign list
        tooltip={
          isCampaignNavCollapsed
            ? 'Show experiments list'
            : 'Hide experiments list'
        }
        onClick={() => dispatch(toggleNavCollapse())}
      >
        {isCampaignNavCollapsed ? <MenuShowIcon /> : <MenuHideIcon />}
      </ActionIcon>
    </span>
  )
}

export default ToggleCampaignListButton
