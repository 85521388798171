import { useEffect } from 'react'
import CampaignSummary from 'workflow/CampaignSummary/CampaignSummary'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import PersonalizationReport from 'common/components/personalizationReport/PersonalizationReport'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'

import CenterPanel from '../../components/layout/CenterPanel'
import { useContent } from '../../hooks'

const CenterSection = () => {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.campaignStates.isLoading)
  const { content } = useContent()
  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  const elements = content?.elements ?? []
  const componentElements = elements?.filter(isComponentElement)

  const selectedComponentElement = componentElements?.find(
    (element) => element.element_id === selectedElementId
  )

  const currentCampaignId = selectedComponentElement?.campaign_id

  useEffect(() => {
    if (currentCampaignId) {
      dispatch(fetchCampaignData(currentCampaignId))
    }
  }, [dispatch, currentCampaignId])

  return (
    <CenterPanel className="pl-0 overflow-auto">
      {isLoading && !selectedComponentElement?.personalization_id ? (
        <Spinner className="m-auto" />
      ) : (
        <>
          {!selectedComponentElement?.personalization_id && (
            <CampaignSummary
              updatedCampaignId={currentCampaignId}
              key={currentCampaignId}
              shouldFetchOnMount={false}
            />
          )}
          {selectedComponentElement?.personalization_id && (
            <PersonalizationReport
              personalizationId={selectedComponentElement.personalization_id}
              mode="results"
            />
          )}
        </>
      )}
    </CenterPanel>
  )
}

export default CenterSection
