import { SelectValue } from 'common/components/singleSelect'
import { useAppSelector } from 'common/hooks/redux'

import AverageSentimentsWidget from '../averageSentiment/AverageSentimentsWidget'
import WinningLines from '../winningLines'

import FirstWidgets from './FirstWidgets'
import SecondWidgets from './SecondWidgets'

type Props = {
  startDate: string
  endDate: string
  projects: SelectValue[]
}

const ProjectComparison = ({ startDate, endDate, projects }: Props) => {
  const {
    project: projectId,
    projectIdToCompare,
    startDateToCompare,
    endDateToCompare,
  } = useAppSelector((state) => state.insights)
  const accountId = useAppSelector((state) => state.authStates.accountId)
  if (!projectIdToCompare) {
    return null
  }

  return (
    <div className="flex flex-col gap-10">
      <div className="grid grid-cols-2 gap-6">
        <FirstWidgets
          projects={projects}
          projectId={projectId}
          startDate={startDate}
          endDate={endDate}
        />
        <FirstWidgets
          projects={projects}
          projectId={projectIdToCompare}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="grid grid-cols-1">
        <WinningLines
          accountId={accountId}
          projectIds={[projectId, projectIdToCompare]}
          startDate={startDate}
          endDate={endDate}
          dateRangeToCompare={{
            startDate: startDateToCompare,
            endDate: endDateToCompare,
          }}
          mode="projectComparison"
        />
      </div>
      <div className="grid grid-cols-1">
        <AverageSentimentsWidget
          accountId={accountId}
          projectIds={[projectId, projectIdToCompare]}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <SecondWidgets
          projects={projects}
          projectId={projectId}
          startDate={startDate}
          endDate={endDate}
        />
        <SecondWidgets
          projects={projects}
          projectId={projectIdToCompare}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  )
}

export default ProjectComparison
