import { Fragment, useState } from 'react'
import { Role } from '@phrasee/phrasee-typings/typings/user/user'
import { parseISO } from 'date-fns'
import isEqual from 'lodash/isEqual'

import Avatar from 'common/components/Avatar'
import Button from 'common/components/button/Button'
import Drawer from 'common/components/drawer/Drawer'
import FormItem from 'common/components/FormItem'
import ProjectMultiSelect from 'common/components/ProjectMultiSelect'
import { successToast } from 'common/components/toastNotification'
import { formatDate, formatDateToAgo } from 'common/helpers/date'
import { stripToValidateName } from 'common/helpers/stripForbiddenCharacters'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as EmailFlyIcon } from 'common/icons/emailFly/default.svg'
import { ReactComponent as LogoutIcon } from 'common/icons/logout/default.svg'
import { AvatarColor } from 'features/profile/AvatarColorPicker'

import { clickSaveUser } from '../../store/usersSlice'
import { reinviteUser, resetPassword, User } from '../api'
import CustomInput from '../Input'
import RoleSelect from '../RoleSelect'

import ActionCard from './ActionCard'

type Props = {
  user: User | undefined
  assignableRoles: Role[] | undefined
  onClose: () => void
  color: AvatarColor | undefined
}

const UserDrawer = ({ user, onClose, color, assignableRoles }: Props) => {
  const dispatch = useAppDispatch()

  const { accountId } = useAppSelector((state) => state.authStates)
  const id = user?.id ?? ''
  const [firstName, setFirstName] = useState(user?.firstName ?? '')
  const [lastName, setLastName] = useState(user?.lastName ?? '')
  const [roles, setRoles] = useState(user?.roles ?? [])
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber ?? '')
  const [jobTitle, setJobTitle] = useState(user?.jobTitle ?? '')
  const [projectIds, setProjectIds] = useState(user?.projectIds ?? [])

  const [isLoading, setIsLoading] = useState(false)

  const originalForm = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    role: user?.role,
    phoneNumber: user?.phoneNumber ?? '',
    jobTitle: user?.jobTitle ?? '',
    projectIds: user?.projectIds,
  }
  const currentForm: Partial<User> = {
    firstName,
    lastName,
    roles,
    phoneNumber,
    jobTitle,
    projectIds,
    id,
  }

  const hasChanged = !isEqual(originalForm, currentForm)
  const isFormValid = [firstName, lastName, roles[0] ?? ''].every(
    (value) => value.trim().length > 0
  )
  const isResetPassword = user?.lastLoginAt !== undefined

  const handleSubmit = () => {
    ;(async () => {
      try {
        setIsLoading(true)
        if (!user?.id) {
          throw new Error('Cannot save user without id')
        }
        await dispatch(clickSaveUser({ ...currentForm, email: user.email }))
        successToast('User Saved')
        onClose()
      } catch (e) {
        alert('Not implemented yet')
      } finally {
        setIsLoading(false)
      }
    })()
  }

  if (!user) {
    // if we want to add some animation to the drawer when it appears it needs to be mounted
    return (
      <Drawer
        title=""
        content=""
        onClose={onClose}
        isOpen={false}
        isOverlayHidden={true}
      />
    )
  }
  const Title = (
    <div className="flex flex-col gap-y-4">
      <Avatar
        firstName={firstName}
        lastName={lastName}
        className="text-xl w-12 h-12 mr-3"
        color={color}
      />
      <div className="font-medium text-coolGray-800 text-2xl overflow-ellipsis max-w-xs overflow-hidden whitespace-nowrap">{`${firstName} ${lastName}`}</div>
      <div className="text-base text-coolGray-500">
        Invited {formatDate(user.createdAt, 'd MMMM yyyy')} -{' '}
        <span className="lowercase">
          {formatDateToAgo(parseISO(user.createdAt))}
        </span>
      </div>
    </div>
  )
  const content = (
    <form
      onSubmit={(event) => {
        event.preventDefault()
      }}
      className="flex flex-col flex-1 mt-9"
    >
      <fieldset>
        <div className="flex flex-col gap-y-3 ">
          <Fragment>
            <FormItem label="First name">
              <CustomInput
                onChange={(event) => {
                  setFirstName(stripToValidateName(event.target.value))
                }}
                name="firstName"
                value={firstName}
                hasError={false}
                placeholder="eg. Greg"
              />
            </FormItem>
            <FormItem label="Last name">
              <CustomInput
                onChange={(event) => {
                  setLastName(stripToValidateName(event.target.value))
                }}
                name="lastName"
                value={lastName}
                hasError={false}
                placeholder="eg. Davis"
              />
            </FormItem>
            <FormItem label="Email">
              <CustomInput isDisabled value={user.email} name="email" />
            </FormItem>
            <div className="flex gap-x-4 mb-6">
              <div style={{ width: 150 }}>
                <FormItem label="Role">
                  <RoleSelect
                    className="font-medium"
                    isSearchable={true}
                    assignableRoles={assignableRoles}
                    value={roles[0]}
                    onChange={(val) => {
                      if (val?.value) {
                        setRoles([val.value])
                      }
                    }}
                    menuPortalTarget={document.body}
                  />
                </FormItem>
              </div>
              <FormItem label="Phone number">
                <CustomInput
                  onChange={(event) => {
                    setPhoneNumber(event.target.value)
                  }}
                  name="phoneNumber"
                  value={phoneNumber}
                  hasError={false}
                  placeholder="eg. +44 7843302926"
                />
              </FormItem>
            </div>
            <FormItem label="Job title">
              <CustomInput
                onChange={(event) => {
                  setJobTitle(event.target.value)
                }}
                name="jobTitle"
                value={jobTitle}
                hasError={false}
                placeholder="eg. Manager"
              />
            </FormItem>

            <FormItem label="Projects">
              <ProjectMultiSelect
                data-cy="invite-user-project-select"
                isSelectAllEnabled={true}
                isFooterHidden={true}
                isLabelHidden={true}
                selectedItems={projectIds}
                placeholder="Select"
                onApply={(val) => {
                  setProjectIds(val)
                }}
                useAllAccountProjects={true}
                menuPortalTarget={document.body}
              />
            </FormItem>
            {isResetPassword ? (
              <ActionCard
                title="Reset user password"
                subTitle="This will reset the users password and send them a password reset email."
                actionButton={{
                  icon: LogoutIcon,
                  text: 'Reset password',
                }}
                onClick={() => resetPassword({ accountId, userId: user.id })}
                successMessage="Password Reset sent"
              />
            ) : (
              <ActionCard
                title="Resend the invite email"
                subTitle="This will resend this user the original invite to join the Jacquard app. Once recieved they can simply click the email link to sign up."
                actionButton={{
                  icon: EmailFlyIcon,
                  text: 'Resend the invite',
                }}
                onClick={() => reinviteUser({ accountId, userId: user.id })}
                successMessage="Invite Sent"
              />
            )}
          </Fragment>
        </div>
      </fieldset>
    </form>
  )
  const footer = hasChanged ? (
    <div className="flex justify-end">
      <Button
        ghost
        className="mr-4 text-base text-coolGray-400 border border-coolGray-100 "
        onClick={onClose}
        data-cy="edit-user-cancel-button"
      >
        Cancel
      </Button>

      <Button
        variant="primary"
        className="text-base"
        onClick={() => {
          handleSubmit()
        }}
        loading={isLoading}
        disabled={!isFormValid}
        data-cy="edit-user-save-button"
      >
        Save
      </Button>
    </div>
  ) : undefined
  return (
    <Drawer
      title={Title}
      content={content}
      footer={footer}
      onClose={onClose}
      isOpen={user !== undefined}
      isOverlayHidden={true}
    />
  )
}

export default UserDrawer
