import { UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'

type Props = {
  id: UniqueIdentifier
  className: string
}

const DroppableContainer: React.FC<Props> = ({ id, className, children }) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id,
    data: {
      type: 'container',
    },
    disabled: true,
  })

  const style = {
    cursor: 'default',
  }

  return (
    <div
      className={className}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  )
}

export default DroppableContainer
