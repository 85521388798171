import { InputNumber } from 'antd'
import kebabCase from 'lodash/kebabCase'

import { InputElement } from '../../interface'
import GuidingText from '../UiBuilder/GuidingText'
import {
  MAX_FLOAT_VALUE,
  MIN_FLOAT_VALUE,
} from '../UiBuilder/UiBuilder.component'

import FormItem from './FormItem'
import { FormInstance } from './interfaces'

type Props = {
  field: InputElement
  form: FormInstance
  onChange: (value: string) => void
  className?: string
  disabled?: boolean
}

const Rate: React.FC<Props> = ({
  field,
  form,
  className,
  disabled,
  onChange,
}) => {
  const { getFieldDecorator } = form

  return (
    <FormItem field={field} form={form} className={className}>
      <div>
        {getFieldDecorator(field.property_name, {
          rules: field.rules,
        })(
          <InputNumber
            disabled={disabled}
            data-cy={`campaign-setup-${kebabCase(field.field_id)}`}
            id={field.field_id}
            style={{ width: 135 }}
            size="large"
            placeholder={field.placeholder}
            precision={2}
            min={field.min || MIN_FLOAT_VALUE}
            max={field.max || MAX_FLOAT_VALUE}
            step={field.step || 1}
            decimalSeparator="."
            onChange={(value) => {
              if (
                value &&
                value >= MIN_FLOAT_VALUE &&
                value <= MAX_FLOAT_VALUE
              ) {
                onChange(value?.toString() ?? '')
              }
            }}
            formatter={(value) => {
              const newValue = String(value).replace(/[^\d.]/g, '')
              return `${newValue}%`
            }}
            parser={(value) => value?.replace(/[^\d.]/g, '') ?? ''}
          />
        )}
        {field.tooltip && <GuidingText text={field.tooltip} />}
      </div>
    </FormItem>
  )
}

export default Rate
