import cx from 'classnames'

interface BannerProps {
  icon: React.ReactChild
  text: string
  heading?: string
  cta?: string
  altColor?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const PhraseeBanner = (props: BannerProps) => {
  const { icon, text, cta, onClick, heading, altColor } = props
  return (
    <div
      onClick={onClick}
      className={cx(
        `flex border-1 border-amber-400
        items-center text-maroon-600
        bg-maroon-50 mb-6 py-4`,
        {
          'cursor-pointer': onClick,
          'text-amber-700': altColor,
        }
      )}
    >
      <span className="px-4 text-sm font-medium">{icon}</span>
      <div className="flex-row">
        <div className="flex-1">
          {heading && (
            <span
              className={`${cx('text-sm font-bold', {
                'text-amber-700': altColor,
              })}`}
            >
              {heading}
            </span>
          )}
        </div>
        <div className="flex-1">
          <span
            className={`${cx('text-sm font-medium', {
              'text-amber-700': altColor,
            })}`}
          >
            {text}
          </span>
        </div>
      </div>
      <div className="flex-1 flex-row text-right">
        <span className="text-sm font-medium self-end pr-4">{cta}</span>
      </div>
    </div>
  )
}

export default PhraseeBanner
