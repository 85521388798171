import { useState } from 'react'
import cx from 'classnames'

import Image from 'common/components/image'
import Widget from 'common/components/Widget'

type Props = {
  name: string
  logoUrl?: string
  onClick?: () => void
}

const IntegrationCard = ({ name, logoUrl, onClick }: Props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <div
      role="presentation"
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <Widget
        type={isHovered ? 'raised' : 'basic'}
        className={cx('h-full flex flex-col items-center', {
          'cursor-pointer': isHovered,
        })}
      >
        <div className="mb-4 h-20">
          <Image isResponsive src={logoUrl} alt={`${name}-logo`} />
        </div>
        <div className="text-coolGray-800 text-base font-medium mb-2">
          {name}
        </div>
      </Widget>
    </div>
  )
}

export default IntegrationCard
