import { useEffect } from 'react'
import { useParams } from 'react-router'

import ErrorPage from 'common/components/error/ErrorPage'
import Spinner from 'common/components/spinner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import useGetContentQuery from '../api/queries/useGetContentQuery'
import { contentPageInitialize } from '../store/contentSlice'

import {
  APPROVAL_COMPONENT_ID,
  START_HERE_COMPONENT_ID,
} from './content/Content'
import ContentPage from './ContentPage'
import { getElementHasVariants, getSelectedElement } from './helpers'

const ContentView = () => {
  const dispatch = useAppDispatch()
  const { contentId } = useParams<{ contentId: string }>()
  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )

  const { status, data: content } = useGetContentQuery(contentId)

  useEffect(() => {
    const selectedElement = getSelectedElement(content)

    const getElementId = (): string | number => {
      let elementId: number | string = START_HERE_COMPONENT_ID
      if (content?.content_progress === 'ready') {
        elementId = APPROVAL_COMPONENT_ID
      } else {
        if (selectedElement?.element_id) {
          elementId = selectedElement?.element_id
        }
      }

      return elementId
    }
    if (content && !activeContentId && !selectedElementId) {
      const elementId = getElementId()
      const hasVariants = getElementHasVariants(selectedElement)

      dispatch(
        contentPageInitialize({
          activeContentId: contentId,
          selectedElementId: elementId,
          showComponentConfiguration:
            typeof elementId === 'number' && !hasVariants,
        })
      )
    }
  }, [activeContentId, content, contentId, dispatch, selectedElementId])

  const getState = () => {
    if (status === 'error') {
      return 'error'
    } else if (!!content && !!activeContentId && !!selectedElementId) {
      return 'success'
    } else {
      return 'loading'
    }
  }

  const state = getState()

  return (
    <>
      {
        {
          loading: <Spinner />,
          success: <ContentPage />,
          error: <ErrorPage />,
        }[state]
      }
    </>
  )
}

export default ContentView
