import React, { RefCallback } from 'react'
import * as Highcharts from 'highcharts/highstock'

import HighchartsReact, {
  ChartInstance,
} from 'common/components/AutoResizeChart'
import { yAxisConfig } from 'common/helpers/ui/graph'

export interface UpliftData {
  data: number[]
  name: string
  average: number
}

interface Props {
  data: UpliftData[]
  xAxisLabels: string[]
  ref: RefCallback<ChartInstance>
}

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
})

const colorPerIndex = {
  0: '#0369a1',
  1: '#0284c7',
  2: '#0ea5e9',
  3: '#38bdf8',
} as const

export default React.forwardRef<ChartInstance, Props>(
  ({ data, xAxisLabels }, ref) => {
    const options: Highcharts.Options = {
      title: undefined,
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          theme: {
            stroke: 'none',
          },
        },
      },
      navigator: {
        series: {
          color: '#61bec7',
          type: 'areaspline',
        },
      },
      xAxis: {
        labels: {
          style: {
            color: '#6b7280',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 0.8,
          },
        },
        categories: xAxisLabels,
      },
      yAxis: {
        ...yAxisConfig,
        labels: {
          format: '{value}%',
          style: {
            color: '#6b7280',
            fontSize: '12px',
            fontWeight: '500',
            opacity: 0.8,
          },
          padding: 0,
          align: 'right',
          reserveSpace: true,
        },
      },
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Roboto',
        },
        spacing: [10, 0, 10, 0],
      },
      tooltip: {
        shared: true,
        split: false,
        useHTML: true,
        borderRadius: 5,
        backgroundColor: '#ffffff',
        shadow: true,
        formatter: function () {
          const series = this.points?.map(
            (point) =>
              `<span style="color:${
                point.color
              }; font-size: 16px; margin-right: 6px;">■</span>
            ${point.y} ${(point.series.options as any).labelTooltip}</br>`,
            ''
          )
          const hasNegativeBar = this.points?.some(({ y }) => y && y < 0)

          if (hasNegativeBar && series?.length) {
            const message = `<div style="margin-top: 6px">Negative lift may occur on non-primary metrics for experiments.</div>`
            return [...series, message]
          } else {
            return series
          }
        },
      },
      legend: {
        y: 14,
        enabled: true,
        useHTML: true,
        itemStyle: {
          fontSize: '14px',
          color: '#1f2937',
          opacity: 0.8,
          lineHeight: '22px',
        },
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 0,
        symbolPadding: 8,
        margin: 0,
        padding: 24,
        verticalAlign: 'bottom',
      },
      plotOptions: {
        series: {
          ...({
            borderRadiusTopLeft: 3,
            borderRadiusTopRight: 3,
          } as any),
          stickyTracking: false,
          dataGrouping: {},
          lineWidth: 2,
          marker: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        column: {
          // fixes gaps in axis when value is 0
          minPointLength: 2,
        },
      },
      series: data.map((r, index) => ({
        type: 'column',
        name: `<span style='font-size: 14px; 
      color: #6b7280; 
      font-weight: normal'>
        ${r.name}
      </span>
      <div style='
        text-align: left !important;
        font-family: Roboto;
        font-weight: bold;
        text-align: center;
        color: #1f2937;'
      >${r.average}%</div>`,
        data: r.data,
        labelTooltip: r.name,
        color: colorPerIndex[index],
      })),
    }
    return (
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        constructorType="chart"
        options={options}
        immutable={true}
      />
    )
  }
)
