import { Content } from '@phrasee/phrasee-typings'
import {
  Element,
  Variant,
} from '@phrasee/phrasee-typings/typings/futurama/element'
import cloneDeep from 'lodash/cloneDeep'
import cond from 'lodash/cond'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import range from 'lodash/range'
import stubString from 'lodash/stubString'
import stubTrue from 'lodash/stubTrue'
import moment from 'moment'
import { SubjectLine } from 'workflow/interface'
import helpers from 'workflow/utils/helpers'
interface MappedVariant<T extends string | number> {
  text: string
  id: T
  sortOrder: number
  parentVariantId?: T
}

interface ExportElementData {
  name: string
  variants: MappedVariant<string | number>[]
  selectedVariants: number[]
}

const getChildVariants = (
  variants: MappedVariant<string | number>[],
  parentVariant: MappedVariant<string | number>
) =>
  variants
    ?.filter((variant) => variant.parentVariantId === parentVariant.id)
    ?.sort((a, b) => a.sortOrder - b.sortOrder)

const sortVariantsBySelections = <T extends string | number>(
  a: MappedVariant<T>,
  b: MappedVariant<T>,
  selectedVariants: T[]
) => {
  const aIsSelected = selectedVariants?.includes(a.id)
  const bIsSelected = selectedVariants?.includes(b.id)

  if (aIsSelected && !bIsSelected) {
    return -1
  } else if (!aIsSelected && bIsSelected) {
    return 1
  } else {
    return 0
  }
}

const getSortedComponentElements = (elements: ExportElementData[]) =>
  elements.map((element) => ({
    ...element,
    variants: flatMap(element.variants, (variant) =>
      isNil(variant.parentVariantId)
        ? [variant, ...getChildVariants(element?.variants, variant)]
        : []
    ).sort((a, b) => sortVariantsBySelections(a, b, element.selectedVariants)),
  }))

const getRows = (
  sortedComponentElements: ExportElementData[],
  clonedComponentElements: ExportElementData[]
) => {
  const maxVariantsLength = Math.max(
    ...clonedComponentElements.map((element) => element.variants?.length || 0)
  )

  return map(range(maxVariantsLength), (rowIndex) => {
    let hasSelection = false

    const rows = map(sortedComponentElements, (element) => {
      const { selectedVariants, variants } = element

      const selectedVariantId = get(selectedVariants, rowIndex)
      const matchedVariant = find(variants, { id: selectedVariantId })

      return cond([
        [
          () => !!selectedVariantId && !!matchedVariant,
          () => {
            hasSelection = true
            return matchedVariant?.text
          },
        ],
        [
          () => !!get(variants, rowIndex),
          () => get(variants, [rowIndex, 'text'], ''),
        ],
        [stubTrue, stubString],
      ])()
    })

    return hasSelection ? [...rows, 'selected'] : rows
  })
}

export const getFileContent = (elements: ExportElementData[]) => {
  const clonedComponentElements = cloneDeep(elements)

  const headers = clonedComponentElements.map(({ name }) => name)

  const sortedComponentElements = getSortedComponentElements(
    clonedComponentElements
  )

  const dataRows = getRows(sortedComponentElements, clonedComponentElements)

  return [headers, ...dataRows]
}

export const getFileName = (element: Element, content: Content | undefined) => {
  return `${content?.display_name}_${
    element.display_name || element.name
  }_${moment().format('MM/DD/YYYY')}`
}

export const optimizationVariantMapper = ({
  text,
  _id,
  sort_order,
}: SubjectLine) => ({
  text,
  id: _id,
  sortOrder: sort_order,
  parentVariantId: null,
})

export const contentVariantMapper = ({
  text,
  variant_id,
  sortOrder,
  parent_variant_id,
}: Variant) => ({
  text,
  id: variant_id,
  sortOrder,
  parentVariantId: parent_variant_id,
})

export const elementDataFormatter = ({
  name,
  element,
  variantsPath,
  variantsMapperHandler,
}: {
  name: string
  element: unknown
  variantsPath: string
  variantsMapperHandler: Function
}) => {
  return {
    name,
    variants: get(element, variantsPath, []).map((variant: Variant) =>
      variantsMapperHandler(variant)
    ),
    selectedVariants: get(element, 'selected_variants', []),
  }
}

export const downloadCsvFile = (
  element: Element,
  data: ExportElementData[],
  content: Content | undefined
) => {
  helpers.downloadCsv(getFileName(element, content), getFileContent(data))
}

export const downloadXlsFile = (
  element: Element,
  data: ExportElementData[],
  content: Content | undefined
) => {
  helpers.downloadXls(getFileName(element, content), getFileContent(data))
}
