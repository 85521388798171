import { generatePath, useHistory, useLocation } from 'react-router'
import { StaticRoute } from 'app/navigation/interfaces'

import ButtonWithDropdown from 'common/components/ButtonWithDropdown'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Menu as MenuIcon } from 'common/icons'

interface Props {
  staticRoutes: StaticRoute[]
}

const ResponsiveMenu = ({ staticRoutes }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()

  const { accountId } = useAppSelector((state) => state.authStates)

  const onRouteClick = (to: string) => {
    const currentPath = location.pathname
    const newPath = generatePath(to, { accountId })
    if (currentPath !== newPath) {
      history.push(newPath)
    }
  }

  return (
    <ButtonWithDropdown
      overlayClassName="capitalize"
      data-cy="responsive-menu-button-dropdown"
      options={staticRoutes
        .map(({ key, name, to, onClick, subroutes }) => {
          if (subroutes) {
            return subroutes.map((subroute) => ({
              key: subroute.key,
              label: subroute.name,
              onClick: () => {
                onRouteClick(subroute.to)
                subroute.onClick?.(dispatch)
              },
            }))
          }
          return [
            {
              key: key,
              label: name,
              onClick: () => {
                onRouteClick(to)
                onClick && onClick(dispatch)
              },
            },
          ]
        })
        .flat()}
      suffixIcon={false}
      autoCapitalize="true"
      className="md:w-6 ml-2 focus:outline-none focus-visible:outline-black md:hidden text-white hover:text-yellow-200"
    >
      <MenuIcon isDefaultColor={false} />
    </ButtonWithDropdown>
  )
}

export default ResponsiveMenu
