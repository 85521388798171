import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { contentKeys } from '../queryKeys'
import { copyContent } from '..'

const useCopyContentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(copyContent, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: () => {
      queryClient.invalidateQueries(contentKeys.contents())
    },
  })
}

export default useCopyContentMutation
