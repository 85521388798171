import { useEffect, useRef, useState } from 'react'
import { Column, Row } from 'react-table'
import cx from 'classnames'

import { useValidationError, Validations } from 'common/hooks/custom'

import type { OnCellChange } from '../Table'

// TODO: Create shared component for this input
export const commonClassName =
  'w-full outline-none bg-transparent px-6 cursor-pointer border border-transparent'

export type TextEditableCellProps<T extends object> = {
  value: string | undefined
  parsedValue?: JSX.Element
  row: Row<T>
  column: Column<T>
  onCellChange: OnCellChange<T>
  hasAutoFocus?: boolean
  validations?: Validations
  className?: string
  inputType?: 'input' | 'textarea'
}

const TextEditableCell = <T extends object>({
  value: initialValue,
  parsedValue,
  row: { index, original },
  column: { id, align },
  onCellChange, // This is a custom function that we supplied to our table instance
  hasAutoFocus,
  validations,
  className,
  inputType = 'input',
}: TextEditableCellProps<T>) => {
  const [value, setValue] = useState<string | undefined>(initialValue)
  const [hasFocus, setHasFocus] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const {
    error: inputError,
    validate,
    resetError,
  } = useValidationError(validations)

  const onChange: (value: string | undefined) => void = (value) => {
    validate(value)
    setValue(value)
  }

  const onBlur = () => {
    if (!id) {
      throw new Error('The column id is missing')
    }

    if (!inputError) {
      onCellChange({
        rowIndex: index,
        columnId: id,
        value,
        rowOriginal: original,
      })
    } else {
      setValue(initialValue)
      resetError()
    }
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const hasOverallFocus = hasFocus || hasAutoFocus

  useEffect(() => {
    if (textAreaRef.current && hasOverallFocus) {
      textAreaRef.current.style.height = '0px'

      const textAreaHeight = textAreaRef.current?.scrollHeight
      textAreaRef.current.style.height = `${textAreaHeight + 2}px`
    }
  }, [hasOverallFocus, textAreaRef, value])

  return (
    <div
      className="w-full"
      onClick={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
    >
      {hasOverallFocus ? (
        <>
          {inputType === 'textarea' ? (
            <textarea
              autoFocus={hasOverallFocus}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              onBlur={onBlur}
              ref={textAreaRef}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onBlur()
                }
              }}
              className={cx(
                commonClassName,
                `block w-full focus-visible:border-maroon-500 focus:bg-maroon-50 caret-maroon-300 resize-none overflow-hidden`,
                {
                  'text-right': align === 'right',
                  'text-center': align === 'center',
                },
                className
              )}
            />
          ) : (
            <input
              autoFocus={hasOverallFocus}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              onBlur={onBlur}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  onBlur()
                }
              }}
              className={cx(
                commonClassName,
                `block w-full focus-visible:border-maroon-500 focus:bg-maroon-50 caret-maroon-300`,
                {
                  'text-right': align === 'right',
                  'text-center': align === 'center',
                  'h-full': !inputError,
                },
                className
              )}
            />
          )}

          {inputError && (
            <div className="text-xs text-red-400">{inputError}</div>
          )}
        </>
      ) : (
        <div
          className={cx(
            commonClassName,
            `flex items-center w-full hover:border-coolGray-400 h-full whitespace-pre-line`,
            {
              'text-right': align === 'right',
              'text-center': align === 'center',
            },
            className
          )}
        >
          {parsedValue || value}
        </div>
      )}
    </div>
  )
}

export default TextEditableCell
