import isEmpty from 'lodash/isEmpty'

import { connectInternalApi } from 'common/api'
import { TreeNode } from 'common/components/catalog/Catalog'

export interface PersonalizationResponse {
  _id: string
  name: string
  useCase: string
  channels: string[]
  status: string
  startDate: string
  endDate: string
  optimisationId: string
  accountId: string
  control: { id: string; text: string }
  trainingContent?: 'contextual' | 'personalized'
}

type FilterItem = {
  displayName: string
  value: string
}

export type DateAttribute = {
  name: string
  type: 'date'
  startDate: string
  endDate: string
}

export type NumberAttribute = {
  name: string
  type: 'number'
  min: number
  max: number
}

export type ListAttribute = {
  name: string
  type: 'list'
  values: string[]
}

export type CustomerAttributes = (
  | DateAttribute
  | NumberAttribute
  | ListAttribute
)[]

type FiltersResponse = {
  useCases: FilterItem[]
  productCategories: TreeNode
  customerAttributes: CustomerAttributes
  channels: FilterItem[]
}

export type GraphsResponse = { displayName: string; value: number }[]

interface BaseVariant {
  variantText: string
  channel: string
  sends: number
}

export interface EmailVariant extends BaseVariant {
  clicks: number
  opens: number
}

export interface PushVariant extends BaseVariant {
  totalOpens: number
  directOpens: number
}

export type Variant = EmailVariant | PushVariant

interface VariantsResponse {
  filteredCount: number
  items: Variant[]
  totalCount: number
}

export type VariantsData = {
  totalCount: number
  filteredCount: number
  variants: Variant[]
}

export async function fetchActivePersonalizedCampaigns(accountId: string) {
  const result = await connectInternalApi.get<PersonalizationResponse[]>(
    `v1/accounts/${accountId}/personalization`
  )

  return result.data
}

export const fetchFilters = async (accountId: string) => {
  const result = await connectInternalApi.get<FiltersResponse>(
    `v1/accounts/${accountId}/personalization/filters`
  )

  return result.data
}

export const fetchMetrics = async ({
  accountId,
  channelId,
  personalizationId,
  customerAttributes,
  productCategories,
}: {
  accountId: string
  channelId?: string
  personalizationId: string
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}) => {
  const data: {
    channel?: string
    product_categories?: TreeNode
    customer_attributes?: CustomerAttributes
  } = {
    channel: channelId,
  }

  if (productCategories) {
    data.product_categories = productCategories
  }
  if (customerAttributes) {
    data.customer_attributes = customerAttributes
  }

  const result = await connectInternalApi.post<GraphsResponse>(
    `v1/accounts/${accountId}/personalization/${personalizationId}/metrics`,
    data
  )

  return result.data
}

export const fetchVariants = async ({
  accountId,
  personalizationId,
  customerAttributes,
  productCategories,
}: {
  accountId: string
  personalizationId: string
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}) => {
  const data: {
    product_categories?: TreeNode
    customer_attributes?: CustomerAttributes
  } = {}

  if (productCategories) {
    data.product_categories = productCategories
  }
  if (customerAttributes) {
    data.customer_attributes = customerAttributes
  }

  const result = await connectInternalApi.post<VariantsResponse>(
    `v1/accounts/${accountId}/personalization/${personalizationId}/variants`,
    data
  )

  return result
}
export type CategoryPerformanceStatus =
  | 'insufficientData'
  | 'lose'
  | 'win'
  | 'draw'

export type CategoryPerformance = {
  category: string[]
  click_uplift: number
  control_click_rate: number
  control_clicks: number
  control_open_rate: number
  control_opens: number
  control_sends: number
  open_uplift: number
  personalized_click_rate: number
  personalized_clicks: number
  personalized_open_rate: number
  personalized_opens: number
  personalized_sends: number
  status: CategoryPerformanceStatus
}
export type Others = Pick<
  CategoryPerformance,
  'open_uplift' | 'click_uplift' | 'status'
>

export type CategoryPerformanceResponse = {
  data: CategoryPerformance[]
  others: Others
}

export const getCategoryPerformance = async ({
  accountId,
  personalizationId,
  metric,
  customerAttributes,
  productCategories,
}: {
  accountId: string
  personalizationId: string
  metric: 'opens' | 'clicks'
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}): Promise<CategoryPerformanceResponse> => {
  const result = await connectInternalApi.post<CategoryPerformanceResponse>(
    `v1/accounts/${accountId}/personalization/${personalizationId}/category-performance`,
    {
      metric,
      customer_attributes: customerAttributes,
      product_categories: isEmpty(productCategories)
        ? undefined
        : productCategories,
    }
  )

  return result.data
}
