import {
  accountSettingsPermissions,
  routesPermissions,
} from 'common/auth/permissions'
import { Edit as EditIcon, Settings, Users } from 'common/icons'
import AdminAccountSettings from 'features/admin/accountSettings'
import BrandVoiceRedirect from 'features/admin/brandVoice/BrandVoiceRedirect'
import DataSources from 'features/admin/dataSources'
import Language from 'features/admin/language'
import AdminUsers from 'features/admin/users'
import DemoPage from 'features/demo/DemoPage'

import type { Subroute } from './interfaces'
import { dispatchClickNavItem } from './navigationSlice'
import {
  adminAccountPath,
  adminLanguagePath,
  brandVoicePath,
  dataSourcesPath,
  demoPath,
} from './paths'
import { accountSettingsSubroutes } from './staticSubRoutes.accountSettings'
import { languageSubroutes } from './staticSubRoutes.language'

const brandVoiceSubroute: Subroute = {
  icon: Settings,
  key: 'brand-voice',
  name: 'Brand Voice',
  component: BrandVoiceRedirect,
  to: brandVoicePath,
  onClick: dispatchClickNavItem,
  accessPermission: routesPermissions.brandVoiceSettingsView,
  shouldValidateAccountAccess: false,
}

const dataSourcesSubroute: Subroute = {
  icon: EditIcon,
  key: 'data-sources',
  name: 'data sources',
  component: DataSources,
  to: dataSourcesPath,
  accessFlags: {
    flags: ['showPersonalizationDataImport'],
    type: 'hide',
  },
  onClick: dispatchClickNavItem,
  accessPermission: routesPermissions.dataSourcesCreate,
  shouldValidateAccountAccess: false,
}

const adminUsersSubroute: Subroute = {
  icon: Users,
  key: 'users',
  name: 'users',
  component: AdminUsers,
  to: '/admin/users',
  accessPermission: routesPermissions.usersView,
  onClick: dispatchClickNavItem,
  shouldValidateAccountAccess: false,
}

const adminAccountRoute: Subroute = {
  icon: Settings,
  key: 'account',
  name: 'account',
  component: AdminAccountSettings,
  to: adminAccountPath,
  onClick: dispatchClickNavItem,
  accessFlags: {
    flags: ['showAdminAccountSettingsPage'],
    type: 'hide',
  },
  accessPermission: accountSettingsPermissions.accountSettingsView,
  tabbedNavRoutes: accountSettingsSubroutes,
}

const adminLanguageRoute: Subroute = {
  icon: Settings,
  key: 'language',
  name: 'language',
  component: Language,
  to: adminLanguagePath,
  onClick: dispatchClickNavItem,
  tabbedNavRoutes: languageSubroutes,
  accessPermission: routesPermissions.languageView,
  shouldValidateAccountAccess: false,
}

const adminDemoRoute: Subroute = {
  icon: EditIcon,
  key: 'demo',
  name: 'Personalized Campaigns Demo',
  to: demoPath,
  component: DemoPage,
  accessPermission: routesPermissions.demoPageView,
  onClick: dispatchClickNavItem,
  shouldValidateAccountAccess: false,
}

export const adminSubRoute: Subroute[] = [
  brandVoiceSubroute,
  dataSourcesSubroute,
  adminUsersSubroute,
  adminAccountRoute,
  adminLanguageRoute,
  adminDemoRoute,
]
