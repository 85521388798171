import { Settings } from 'common/icons'
import EditTones from 'features/admin/brandVoice/views/EditTones'
import GenerateTones from 'features/admin/brandVoice/views/GenerateTones'

import { Subroute, TabbedNavRoute } from './interfaces'
import { dispatchClickNavItem } from './navigationSlice'
import { editToneOfVoicePath, generateToneOfVoicePath } from './paths'

const generateSubRoute: Subroute = {
  icon: Settings,
  key: 'generate',
  name: 'Generate',
  component: GenerateTones,
  to: generateToneOfVoicePath,
  onClick: dispatchClickNavItem,
}

const editSubroute: Subroute = {
  icon: Settings,
  key: 'edit',
  name: 'Edit',
  component: EditTones,
  to: editToneOfVoicePath,
  onClick: dispatchClickNavItem,
}

export const toneOfVoiceSubroutes: TabbedNavRoute[] = [
  generateSubRoute,
  editSubroute,
]
