import {
  generatePath,
  Redirect,
  Route,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import Content from 'app/Content'
import { selectedCampaignPath } from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'
import ComponentManager from 'workflow/WorkflowManager'

import ErrorBoundary from 'common/components/ErrorBoundary'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'

import EditCampaignsPage from './components/edit/SelectedCampaigns'
import { PageError } from './components/errors'
import CampaignNav from './components/nav/CampaignNav'

const DPFE_CAMPAIGNS_PATH = '/campaign/:campaignId?'
const PREVIOUS_CAMPAIGNS_PATH = '/campaigns/:campaignId?'

const Campaigns = () => {
  const match = useRouteMatch<{
    campaignId: string
  }>({
    path: [selectedCampaignPath, DPFE_CAMPAIGNS_PATH, PREVIOUS_CAMPAIGNS_PATH],
  })
  const { accountId } = useAppSelector((state) => state.authStates)
  const selectedCampaignRoute =
    accountId &&
    generatePath(selectedCampaignPath, {
      accountId,
      campaignId: match?.params.campaignId,
    })

  const history = useHistory()
  const { isEditing, isNewCampaign } = useAppSelector(
    (state) => state.campaigns
  )
  const pathname = history.location.pathname

  useDocumentTitle(
    !isNewCampaign ? 'Experiments | Jacquard' : 'Create Experiment | Jacquard'
  )

  return (
    <ErrorBoundary FallbackComponent={PageError} resetKeys={[pathname]}>
      <div className="flex w-full h-full overflow-y-hidden">
        <CampaignNav />
        <CustomSwitch>
          <Route
            path={selectedCampaignPath}
            children={({ location, match, history }) => {
              const campaignId = match?.params.campaignId

              return (
                <Content fullPage>
                  {isEditing ? (
                    <EditCampaignsPage />
                  ) : (
                    <ComponentManager
                      key={campaignId} // Re-mount component on campaignId change, otherwise it has to be refactored
                      match={match}
                      location={location}
                      history={history}
                    />
                  )}
                </Content>
              )
            }}
          />
          <Redirect
            exact
            from={DPFE_CAMPAIGNS_PATH}
            to={selectedCampaignRoute}
          />
          <Redirect
            exact
            from={PREVIOUS_CAMPAIGNS_PATH}
            to={selectedCampaignRoute}
          />
        </CustomSwitch>
      </div>
    </ErrorBoundary>
  )
}

export default Campaigns
