import { InputElement } from '../../interface'

import { FormInstance } from './interfaces'

export const extractFields = (fields: InputElement[]) => {
  const selectionMetricField = fields.find(
    (f) => f.field_id === 'selection_metric'
  )
  const listSizeField = fields.find((f) => f.field_id === 'list_size')
  const openRateField = fields.find((f) => f.field_id === 'baseline_open_rate')
  const clickRateField = fields.find(
    (f) => f.field_id === 'baseline_click_rate'
  )

  if (!selectionMetricField || !listSizeField) {
    throw new Error(
      `Experiment setup step 1, unable to extract all necessary
      split calculator fields`
    )
  }

  return {
    selectionMetricField,
    listSizeField,
    openRateField,
    clickRateField,
  }
}

export const getFieldError = (form: FormInstance, fieldId: string) => {
  const { getFieldError, isFieldTouched } = form
  return isFieldTouched(fieldId) && getFieldError(fieldId)
}

export const hasCalculatorFieldErrors = async (
  form: FormInstance,
  requiredFields: string[]
) => {
  const { validateFields } = form

  try {
    await validateFields(requiredFields)
  } catch (error: any) {
    if (error.errors) {
      for (const [key] of Object.entries(error.errors)) {
        const { message, field } = error.errors[key].errors[0]

        if (message && field) {
          form.setFields({
            [key]: {
              value: form.getFieldValue(field),
              errors: [message],
              touched: true,
            },
          })
          return true
        }
      }
    }
  }

  let listSizeError = getFieldError(form, 'list_size')

  // If there error is the following, we remove it as it should
  // not be a blocker for the api request [HH-928]
  const nonBlockingErrors = [
    'Please enter an audience size bigger than',
    'There will be insufficient data to optimize successfully on clicks, please use opens instead',
  ]

  if (listSizeError && nonBlockingErrors.includes(listSizeError[0])) {
    listSizeError = false
    form.setFields({
      list_size: {
        value: form.getFieldValue('list_size'),
        errors: undefined,
        touched: true,
      },
    })
  }

  return (
    requiredFields.some((field) => !!getFieldError(form, field)) ||
    !!listSizeError
  )
}
