import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { isEqual } from 'lodash'

import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal'
import NumericInput from 'common/components/numericInput'
import SingleSelect, {
  SelectValue,
  SingleValue,
} from 'common/components/singleSelect'
import { currencyToSymbol } from 'common/helpers/currency'
import { useAppSelector } from 'common/hooks/redux'
import { Bin } from 'common/icons'
import { Project } from 'features/campaigns/api/interface'

import {
  deleteGlobalSettings,
  deleteProjectSettings,
  saveGlobalSettings,
  saveProjectSettings,
} from './api'
import Error from './Error'
import {
  clickConversionPercentage,
  currencies,
  openConversionPercentage,
  revenueReportingAllTypes,
  valueOfaClick,
  valueOfAnOpen,
} from './helpers'
import {
  RevenueSettingsValues,
  RevenueSettingsValuesProps,
  Scope,
} from './interfaces'

export const revenueReportingLabels = {
  project: 'Project',
  currency: 'Currency',
  type: 'Type',
  value: 'Value',
  averageOrderValue: 'Average order value',
}

const RevenueSettings: React.FC<RevenueSettingsValuesProps> = ({
  className,
  settings,
  isLoading,
  scope,
  index,
  onSave,
  onDelete,
  disallowedProjectIds = [],
}) => {
  const [hasError, setHasError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isSuccessButtonShown, setIsSuccessButtonShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [revenueReportingTypes, setRevenueReportingTypes] = useState<
    SelectValue[]
  >(revenueReportingAllTypes)
  const [revenueSettingsValues, setRevenueSettingsValues] =
    useState<RevenueSettingsValues>(settings)

  const { projects } = useAppSelector((state) => state.campaigns)

  const mappedProjects = useMemo(
    () =>
      projects
        .filter(
          (proj) =>
            proj.distributionChannel !== 'facebook' &&
            proj.distributionChannel !== 'landing_page' &&
            !disallowedProjectIds.includes(proj.id)
        )
        .map((proj) => ({
          label: proj.name,
          value: proj.id,
        })),
    [disallowedProjectIds, projects]
  )

  const { currency, type, value, averageOrderValue, projectId, isSaved } =
    revenueSettingsValues

  const prefix = currency && currencyToSymbol[currency]

  const shouldShowAverageOrderValue =
    type === openConversionPercentage.value ||
    type === clickConversionPercentage.value

  const isSaveButtonShown =
    !isEqual(settings, revenueSettingsValues) && !isLoading && isEditing

  const canSave = () => {
    const canSaveSettings = !!(
      (value && shouldShowAverageOrderValue && averageOrderValue) ||
      (value && !shouldShowAverageOrderValue)
    )
    if (scope === 'project') {
      return !!projectId && canSaveSettings
    }

    return canSaveSettings
  }

  const updateValue = (newValue: Partial<RevenueSettingsValues>) => {
    setIsEditing(true)
    setRevenueSettingsValues((previousValues) => ({
      ...previousValues,
      ...newValue,
    }))
  }

  const onTypeChange = (newValue: SingleValue<SelectValue>) => {
    setIsEditing(true)
    setRevenueSettingsValues((previousValues) => ({
      ...previousValues,
      value: undefined,
      type: newValue!.value,
      averageOrderValue: undefined,
    }))
  }

  const setTypeOptions = (project: Project) => {
    if (project.distributionChannel === 'push_notification') {
      setRevenueReportingTypes([openConversionPercentage, valueOfAnOpen])
      updateValue({ type: isSaved ? type : openConversionPercentage.value })
    } else if (project.distributionChannel === 'sms') {
      setRevenueReportingTypes([clickConversionPercentage, valueOfaClick])
      updateValue({ type: isSaved ? type : clickConversionPercentage.value })
    } else {
      setRevenueReportingTypes(revenueReportingAllTypes)
    }
  }

  const onProjectChange = (project?: string) => {
    const selectedProject = projects.find((p) => p.id === project)

    if (selectedProject) {
      setTypeOptions(selectedProject)
      updateValue({ projectId: selectedProject.id })
    }
  }

  const handleSave = async () => {
    setIsSaving(true)
    setHasError(false)

    try {
      const response: RevenueSettingsValues =
        scope === 'global'
          ? await saveGlobalSettings(revenueSettingsValues)
          : await saveProjectSettings(revenueSettingsValues, projectId)

      setIsEditing(false)
      setIsSuccessButtonShown(true)
      onSave({ ...response, projectId, isSaved: true })
      setTimeout(() => {
        setIsSuccessButtonShown(false)
      }, 3000)
    } catch {
      setHasError(true)
    } finally {
      setIsSaving(false)
    }
  }

  const handleDelete = async (scope: Scope) => {
    setHasError(false)
    try {
      if (scope === 'project') {
        await deleteProjectSettings(projectId!)
      } else {
        await deleteGlobalSettings()
      }

      if (onDelete) {
        onDelete()
      }
    } catch {
      setHasError(true)
    } finally {
      setIsDeleteModalShown(false)
    }
  }

  useEffect(() => {
    setRevenueSettingsValues(settings)
  }, [settings])

  useEffect(() => {
    onProjectChange(settings.projectId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects])

  const namePostfix = scope === 'project' ? `-${index}` : ''

  return (
    <>
      {hasError && <Error />}
      <div className={cx('flex', className)}>
        {scope === 'project' && (
          <SingleSelect
            className="min-w-50 mr-4 flex-auto"
            data-cy={`${scope}-revenue-project-select${namePostfix}`}
            name={`${scope}-revenue-project-select${namePostfix}`}
            label={revenueReportingLabels.project}
            onChange={(newValue) => onProjectChange(newValue?.value)}
            isDisabled={!!projectId && isSaved}
            value={projectId}
            isSearchable={true}
            options={mappedProjects}
          />
        )}

        <SingleSelect
          className="w-24 flex-none"
          data-cy={`${scope}-revenue-currency-select${namePostfix}`}
          name={`${scope}-revenue-currency-select${namePostfix}`}
          label={revenueReportingLabels.currency}
          onChange={(newValue) => updateValue({ currency: newValue!.value })}
          value={currency}
          options={currencies}
        />

        <SingleSelect
          className="w-64 flex-none ml-4"
          data-cy={`${scope}-revenue-type-select${namePostfix}`}
          name={`${scope}-revenue-type-select${namePostfix}`}
          label={revenueReportingLabels.type}
          onChange={onTypeChange}
          value={type}
          options={revenueReportingTypes}
        />
        <span className="mx-4 text-xl pb-2 self-end text-maroon-500">=</span>
        <div className="mr-4">
          <NumericInput
            className="w-24"
            label={revenueReportingLabels.value}
            id={`${scope}-revenue-configuration-value${namePostfix}`}
            data-cy={`${scope}-revenue-configuration-value${namePostfix}`}
            prefix={!shouldShowAverageOrderValue ? prefix : ''}
            postfix={shouldShowAverageOrderValue ? '%' : ''}
            placeholder={
              shouldShowAverageOrderValue ? `1.00%` : `${prefix}00.01`
            }
            value={value}
            onChange={(val) => updateValue({ value: val })}
            min={shouldShowAverageOrderValue ? 0 : undefined}
            max={shouldShowAverageOrderValue ? 100 : undefined}
          />
        </div>
        <div
          className={cx({ invisible: !shouldShowAverageOrderValue })}
          data-testid="average-order-value-wrapper"
        >
          <NumericInput
            className="w-35 mr-4"
            label={revenueReportingLabels.averageOrderValue}
            id={`${scope}-revenue-average-order-value${namePostfix}`}
            data-cy={`${scope}-revenue-average-order-value${namePostfix}`}
            prefix={prefix}
            placeholder={`${prefix}50.00`}
            value={averageOrderValue}
            onChange={(val) => updateValue({ averageOrderValue: val })}
          />
        </div>
        {isSuccessButtonShown ? (
          <div className="w-32 self-end mr-4">
            <Button variant="success" />
          </div>
        ) : (
          <div
            className={cx('w-32 self-end mr-4', {
              invisible: !isSaveButtonShown,
            })}
            data-testid="save-button-wrapper"
          >
            <Button
              variant="primary"
              disabled={!canSave()}
              loading={isSaving}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        )}
        <Button
          variant="icon"
          className={cx('self-end mb-2 cursor-pointer', {
            invisible: !isSaved,
          })}
          data-testid="remove-exception-icon"
          onClick={() => setIsDeleteModalShown(true)}
        >
          <Bin />
        </Button>
        {scope === 'project' ? (
          <ConfirmationModal
            open={isDeleteModalShown}
            title="Remove exception!"
            data-cy="project-revenue-exception-delete-modal"
            confirmationText="Are you sure you want to remove this exception? If you remove this exception the project will default to global revenue settings."
            onCancel={() => setIsDeleteModalShown(false)}
            onConfirm={() => handleDelete('project')}
          />
        ) : (
          <ConfirmationModal
            open={isDeleteModalShown}
            title="Remove global settings!"
            data-cy="global-revenue-settings-delete-modal"
            confirmationText="Are you sure you want to remove the global settings? This will disable all revenue reporting."
            onCancel={() => setIsDeleteModalShown(false)}
            onConfirm={() => handleDelete('global')}
          />
        )}
      </div>
    </>
  )
}

export default RevenueSettings
