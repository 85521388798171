import { useMemo } from 'react'
import { Row } from 'react-table'
import cx from 'classnames'

import { LineArrowRight } from 'common/icons'

import BaseCell from '../cells/Base'
import Resizer from '../Resizer'
import type { TableData, TableProps } from '../Table'

import styles from '../Table.module.css'

export type DefaultRowProps<T extends object> = Pick<
  TableProps<T>,
  'onRowClick' | 'rowClassName' | 'isHeaderHidden'
> & {
  isResizingColumn?: string
  row: Row<T>
  rows: Row<T>[]
  dataCy?: string
  hoveredColumnIndex?: number
  rowIndex: number
  depth: number
  disableSubRowArrow?: boolean
  generateRowClassName?: ({
    row,
    rows,
  }: {
    row: Row<T>
    rows: Row<T>[]
  }) => string
}

const DefaultRow = <T extends TableData>({
  row,
  rows,
  onRowClick,
  dataCy,
  rowClassName,
  isResizingColumn,
  isHeaderHidden,
  hoveredColumnIndex,
  rowIndex,
  depth,
  disableSubRowArrow,
  generateRowClassName,
}: DefaultRowProps<T>) => {
  const generatedRowClassNames = useMemo(() => {
    return generateRowClassName?.({ row, rows })
  }, [generateRowClassName, row, rows])

  return (
    <div
      {...row.getRowProps()}
      onClick={(event) => {
        const element = event.target as HTMLElement
        if (onRowClick && isElementClickable(element)) {
          onRowClick(row.original)
        }
      }}
      data-cy={`${dataCy}-body-row`}
      {...(row.original?.isHighlighted ? { 'data-highlighted': true } : {})}
      className={cx('group min-h-13', rowClassName, generatedRowClassNames, {
        'cursor-auto': !isResizingColumn,
        'bg-red-50': row.original.hasError,
        'hover:bg-coolGray-50': !row.original.hasError,
        'bg-amber-50': row.original.isHighlighted,
        'cursor-pointer': !!onRowClick,
      })}
      key={`body-row-${row.id}`}
    >
      {depth > 0 && !disableSubRowArrow && (
        <div
          className={cx(
            'flex justify-center items-center pl-6 border-coolGray-200 border-t',
            {
              'bg-maroon-50': row.isSelected && !row.values.hasError,
            }
          )}
        >
          <LineArrowRight />
        </div>
      )}
      {row.cells.map((cell, index) => {
        const {
          column: {
            id: columnId,
            align,
            isResizable,
            shouldOverflow,
            getResizerProps,
            isEditable,
            isDisabled,
            canSort,
            className,
          },
          value,
        } = cell

        return (
          <div
            {...cell.getCellProps()}
            key={`${columnId}-${index}`}
            className={cx(
              'relative border-coolGray-200 flex items-stretch',
              className,
              styles.common,
              {
                'flex-none w-min': columnId === 'selection',
                'border-t': isHeaderHidden ? rowIndex !== 0 : true,
                'bg-emerald-50 group-hover:bg-coolGray-50':
                  isEditable === false && // only when false, not undefined
                  !row.isSelected &&
                  hoveredColumnIndex !== index &&
                  !row.original.hasError,
                [styles.cell]: !shouldOverflow,
                'justify-end': align === 'right',
                'justify-center': align === 'center',
                'bg-maroon-50': row.isSelected && !row.values.hasError,
                'bg-coolGray-50':
                  hoveredColumnIndex === index && !isResizingColumn && canSort,
                'text-coolGray-400': isDisabled,
              }
            )}
            data-cy={`${dataCy}-body-cell`}
          >
            {(value === undefined || value === null) &&
            !isEditable &&
            columnId !== 'selection' ? (
              <BaseCell>-</BaseCell>
            ) : (
              cell.render('Cell')
            )}
            {isResizable && <Resizer {...getResizerProps()} />}
          </div>
        )
      })}
    </div>
  )
}

export default DefaultRow

function isElementClickable(element: Element | null) {
  if (element === null) {
    return false
  }
  if (['button', 'svg', 'input'].includes(element.tagName.toLowerCase())) {
    return false
  }
  if (
    [
      element.getAttribute('role'),
      element.parentElement?.getAttribute('role') === 'cell',
    ].includes('cell')
  ) {
    return true
  }
  return isElementClickable(element.parentElement)
}
