import { formatNumber } from 'common/helpers/numeric'

import { CellValue } from '../'

import BaseCell from './Base'

const NumericCell = ({ value }: CellValue) => {
  return (
    <BaseCell>
      <span>{formatNumber(value, { maximumFractionDigits: 2 })}</span>
    </BaseCell>
  )
}

export default NumericCell
