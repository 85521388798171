import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js'

interface FetchAccessTokenSilently {
  (
    options: GetTokenSilentlyOptions & {
      detailedResponse: true
    }
  ): Promise<GetTokenSilentlyVerboseResponse>
  (options?: GetTokenSilentlyOptions | undefined): Promise<any>
  (options: GetTokenSilentlyOptions): Promise<any>
}

/**
 * a wrapper class which allows to use
 * Auth0 token fetching outside of function components
 */

class TokenFetcher {
  private tokenFetcher: FetchAccessTokenSilently =
    (): Promise<GetTokenSilentlyVerboseResponse> =>
      new Promise((resolve, reject) =>
        resolve({ id_token: '', access_token: '', expires_in: 0 })
      )

  setTokenFetcher(tokenFetcherRef: FetchAccessTokenSilently): void {
    this.tokenFetcher = tokenFetcherRef
  }

  getAccessToken(): Promise<any> {
    return this.tokenFetcher()
  }
}

const tokenFetcher = new TokenFetcher()

export default tokenFetcher
