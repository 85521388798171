import React, { useState } from 'react'

import ConfirmationCard from 'common/components/ConfirmationCard'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import UnscheduleCampaignCard from '../components/card/UnscheduleCampaignCard'
import Scheduler from '../components/scheduler/Scheduler'

import {
  getCampaign,
  setError,
  setLoading,
  stopCollectData,
  updateFBData,
  updateScheduleStatus,
} from './store/facebookSlice'

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT || 'YYYY-MM-DD'

export default function ScheduleCard({
  startDate,
  startTime,
  endDate,
  endTime,
  errorMessages,
  isCampaignScheduled,
  onDatesChange,
  isLoading,
}) {
  const dispatch = useAppDispatch()

  const {
    facebook: { fbStatus },
    campaignStates: { currentCampaignId },
  } = useAppSelector((state) => state)
  const [showConfirmation, setShowConfirmation] = useState(false)

  return (
    <>
      <Scheduler
        minuteStep={15}
        endDate={endDate}
        endTime={endTime}
        timeFormat="HH:mm"
        dateFormat={DATE_FORMAT}
        errorMessages={errorMessages}
        integrationType="Facebook"
        isEndDateDisabled={isCampaignScheduled}
        isEndTimeDisabled={isCampaignScheduled}
        isStartDateDisabled={isCampaignScheduled}
        isStartTimeDisabled={isCampaignScheduled}
        startDate={startDate}
        startTime={startTime}
        onChange={onDatesChange}
        title="Data collection"
        endTimeTitle="When will you end the Ad?"
        startTimeTitle="When will you start the Ad?"
      />
      {isCampaignScheduled &&
        fbStatus?.status?.campaign_active &&
        !fbStatus?.status?.campaign_completed && (
          <>
            {showConfirmation ? (
              <div className="mt-4 mb-4">
                <ConfirmationCard
                  isLoading={isLoading.scheduler}
                  isDisabled={false}
                  cardText={`Are you sure you want to Stop collecting data?
              If you do this you will have to setup a new schedule`}
                  cardTitle="Wooooaaaaa!"
                  buttonText="Yes, stop collecting"
                  rejectButtonText="Nooo!"
                  onReject={() => setShowConfirmation(false)}
                  onSubmit={() => {
                    dispatch(setLoading({ field: 'fbCancel', value: true }))
                    stopCollectData({ campaign_id: currentCampaignId }).then(
                      () => {
                        dispatch(
                          setLoading({ field: 'fbCampaign', value: true })
                        )
                        getCampaign({
                          campaign_id: currentCampaignId,
                        })
                          .then((res) => {
                            dispatch(updateFBData(res?.data?.data))
                            dispatch(updateScheduleStatus(false))
                          })
                          .catch((err) => dispatch(setError(err.message)))
                          .finally(() =>
                            dispatch(
                              setLoading({ field: 'fbCampaign', value: false })
                            )
                          )
                          .catch((err) => dispatch(setError(err.message)))
                          .finally(() =>
                            dispatch(
                              setLoading({ field: 'fbCancel', value: false })
                            )
                          )
                      }
                    )
                  }}
                />
              </div>
            ) : (
              <UnscheduleCampaignCard
                dataTestId="unscheduleBtn"
                cardText={`This will stop importing data from Facebook.
              This will not delete any data you have already received`}
                cardTitle="Stop collecting data"
                buttonText="Stop collecting data"
                onSubmit={() => setShowConfirmation(true)}
              />
            )}
          </>
        )}
    </>
  )
}
