import { useQuery } from '@tanstack/react-query'

import { getSplitCalculatorConfiguration } from '../api'
import { contentKeys } from '../queryKeys'

const useGetSplitCalculatorConfigurationQuery = ({
  projectId,
}: {
  projectId?: string
}) => {
  const getSplitCalculatorConfigurationQuery = useQuery(
    contentKeys.projectConfiguration(projectId!),
    () => getSplitCalculatorConfiguration({ projectId: projectId! }),
    {
      enabled: !!projectId,
      staleTime: Infinity,
    }
  )

  return getSplitCalculatorConfigurationQuery
}

export default useGetSplitCalculatorConfigurationQuery
