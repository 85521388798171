import { Field } from 'react-final-form'
import { InputNumber } from 'antd'

import { NumericType } from 'common/components/dynamicFields/interfaces'
import FormItem from 'common/components/FormItem'

const validateListSize = (value: number, allValues, meta) => {
  if (!value) {
    return 'Please enter your expected audience size'
  }
  const {
    data: { error },
  } = meta

  return error
}

type Props = {
  name: string
  isDisabled?: boolean
  configuration: NumericType
  onChange: (value?: number) => void
}

const ListSize = ({
  name,
  configuration,
  onChange,
  isDisabled = false,
}: Props) => {
  return (
    <Field<number>
      name={name}
      validate={configuration.required ? validateListSize : undefined}
    >
      {({ input, meta }) => (
        <FormItem
          error={meta.touched ? meta.error : undefined}
          htmlFor={input.name}
          label={configuration.label}
        >
          <InputNumber
            data-cy="list-size-input"
            id={input.name}
            min={0}
            className="w-full"
            placeholder={configuration.placeholder}
            precision={0}
            formatter={(value) =>
              `${value}`
                .replace(/[^\d]/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) =>
              value?.replace(/[^\d]/g, '').replace(/\$\s?|(,*)/g, '') ?? ''
            }
            onChange={(value) => {
              input.onChange(value)
              onChange(value)
            }}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            value={input.value}
            disabled={isDisabled}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default ListSize
