import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import RouterPromptStay from 'app/router/RouterPromptStay'

import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Pathway from 'common/components/nodes/Pathway'
import NodesGraph from 'common/components/nodesGraph'
import PageContainer from 'common/components/PageContainer'
import Scrollable from 'common/components/scrollable'
import Spinner from 'common/components/spinner'
import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import AlwaysOnTags from './components/alwaysOnTags'
import DefaultReplacersWidget from './components/defaultReplacersWidget/DefaultReplacersWidget'
import MergeTagsWidget from './components/MergeTagsWidget'
import NodeConfiguration from './components/nodeConfigurationWidget'
import NodeSetttingsWidget from './components/NodeSetttingsWidget'
import NodesFilter from './components/nodesFilter/NodesFilter'
import ReplacerSetttingsWidget from './components/ReplacerSetttingsWidget'
import {
  initializePage,
  resetContent,
  saveNodeSettings,
  selectHasBeenEdited,
} from './store/nodeSlice'

// Hardcoded for now
const SELECTED_ROOT_NODE_ID = '5'

const Nodes = () => {
  const [selectedNodeId, setSelectedNodeId] = useState<string>()
  const [selectedRootId, setSelectedRootId] = useState<string>()

  const { projectId } = useParams<{ projectId: string }>()
  const status = useAppSelector((state) => state.projectNodes.status)
  const isSaving = useAppSelector((state) => state.projectNodes.isSaving)
  const nodes = useAppSelector((state) => state.projectNodes.nodes)
  const hasBeenEdited = useAppSelector(selectHasBeenEdited)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(initializePage(projectId))
  }, [projectId, dispatch])

  const getHasChildren = (nodeId?: string) => {
    return nodes.some((node) => node.parent === nodeId)
  }

  const onNodeClick = (nodeId?: string, rootNodeId?: string) => {
    setSelectedRootId((prevRootId) => {
      const newSelectedNodeId = nodeId === prevRootId ? rootNodeId : nodeId
      const newSelectedNode = nodes.find(
        (node) => node.id === newSelectedNodeId
      )

      const shouldSelectNode = !newSelectedNode?.isPrivate

      if (shouldSelectNode) {
        setSelectedNodeId(newSelectedNodeId)
      }
      return getHasChildren(newSelectedNodeId)
        ? rootNodeId
        : nodes.find((node) => node.id === newSelectedNodeId)?.parent
    })
  }

  const updateSelectedNodeId = (nodeId?: string) => {
    const newSelectedRootId = nodeId
      ? getHasChildren(nodeId)
        ? nodeId
        : nodes.find((node) => node.id === nodeId)?.parent
      : undefined

    setSelectedNodeId(nodeId)
    setSelectedRootId(newSelectedRootId)
  }

  return (
    <>
      {
        {
          loading: <Spinner />,
          idle: <>...</>,
        }[status]
      }

      {
        {
          loading: <Spinner />,
          idle: (
            <>
              <Scrollable
                isFullHeight={false}
                content={
                  <PageContainer className="max-w-318 sm:mx-auto pt-12 px-6">
                    <div className="flex justify-between items-center">
                      <Pathway
                        selectedNodeId={selectedNodeId || SELECTED_ROOT_NODE_ID}
                        nodes={nodes}
                        updateSelectedNodeId={updateSelectedNodeId}
                      />
                      <NodesFilter
                        updateSelectedNodeId={updateSelectedNodeId}
                      />
                    </div>
                    <NodesGraph
                      nodes={nodes}
                      selectedRootId={selectedRootId}
                      onNodeClick={onNodeClick}
                    />
                    <NodeConfiguration />
                    <NodeSetttingsWidget nodes={nodes} />
                    <DefaultReplacersWidget />
                    <ReplacerSetttingsWidget />
                    <MergeTagsWidget />
                    <AlwaysOnTags />
                    <Footer />
                  </PageContainer>
                }
                footer={
                  hasBeenEdited ? (
                    <BottomToolbar className="justify-center">
                      <div className="max-w-318 xl:max-w-363 w-full flex justify-between">
                        <Button
                          data-cy="cancel-button"
                          variant="danger"
                          className="mr-4 pointer-events-auto"
                          ghost
                          disabled={isSaving}
                          onClick={() => dispatch(resetContent())}
                        >
                          Reset
                        </Button>
                        <Button
                          data-cy="save-button"
                          variant="success"
                          ghost
                          loading={isSaving}
                          disabled={isSaving}
                          onClick={() =>
                            dispatch(saveNodeSettings({ projectId }))
                              .unwrap()
                              .catch((e) => {
                                errorToast(e.message)
                              })
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </BottomToolbar>
                  ) : undefined
                }
              />
              <RouterPromptStay
                shouldShow={hasBeenEdited}
                isSaving={false}
                onOK={() => {
                  dispatch(resetContent())
                  return Promise.resolve(true)
                }}
              />
            </>
          ),
          error: <ErrorPage />,
        }[status]
      }
    </>
  )
}

export default Nodes
