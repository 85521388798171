type Props = {
  children?: React.ReactNode
}
const TemplatesConfigPanelHeader = ({ children }: Props) => {
  return (
    <div className="bg-white flex overflow-x-hidden relative">{children}</div>
  )
}

export default TemplatesConfigPanelHeader
