import { Content } from '@phrasee/phrasee-typings'
import { Variant } from '@phrasee/phrasee-typings/typings/futurama/element'

import { useAppSelector } from 'common/hooks/redux'
import useGetContentQuery from 'features/futurama/api/queries/useGetContentQuery'

import { getAllComponentElements } from '../helpers'

const useVariants = (
  contentId: string | undefined,
  { withSubrows }: { withSubrows: boolean } = { withSubrows: false }
) => {
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )

  return useGetContentQuery<(Variant & { subRows?: Variant[] })[]>(contentId, {
    select: (content: Content) => {
      const allComponentElements = getAllComponentElements(content)

      const variants =
        allComponentElements.find(
          (element) => element.element_id === selectedElementId
        )?.variants || []

      if (withSubrows) {
        return variants
          .map((variant) => {
            const subRows = variants
              .filter((v) => v.parent_variant_id === variant.variant_id)
              .sort((a, b) => a.sortOrder - b.sortOrder)

            return {
              ...variant,
              subRows,
              ...(subRows.length > 0 && { expanded: true }),
            }
          })
          .filter((v) => !v.parent_variant_id)
      }

      return variants
    },
  })
}

export default useVariants
