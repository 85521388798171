import { useState } from 'react'
import { Field } from 'react-final-form'
import { useFlags } from 'launchdarkly-react-client-sdk'

import FormItem from 'common/components/FormItem'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { ChevronDown, ChevronUp } from 'common/icons'
import useGetBrandVoicesQuery from 'features/admin/brandVoice/api/queries/useGetBrandVoicesQuery'

const BrandVoiceSettings = () => {
  const flags = useFlags()
  const [isExpanded, setIsExpanded] = useState(false)

  const { data: brandVoices } = useGetBrandVoicesQuery()

  const brandVoicesOptions = brandVoices?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  if (!flags.showBrandTonePage || !brandVoices || brandVoices.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col">
      <div
        className="self-end mb-6 py-2 text-coolGray-800 font-medium cursor-pointer select-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <ChevronUp size={4} /> : <ChevronDown size={4} />}
        <span className="ml-2">Advanced settings</span>
      </div>
      {isExpanded && (
        <div className="border border-coolGray-300 p-6 mb-6">
          <Field<SelectValue>
            name="brand_voice"
            render={({ input, meta }) => (
              <FormItem label="Brand voice" htmlFor="brand_voice">
                <span className="text-coolGray-500">
                  This will set the language settings for the generated content.
                  For more details, go to Brand Voice.
                </span>
                <SingleSelect
                  className="mt-4 w-75"
                  data-cy="brand-voice-select"
                  name="brand_voice"
                  placeholder="Select your brand voice"
                  value={input.value.value}
                  onChange={input.onChange}
                  onBlur={input.onBlur}
                  options={brandVoicesOptions}
                />
              </FormItem>
            )}
          />
        </div>
      )}
    </div>
  )
}

export default BrandVoiceSettings
