import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { contentKeys } from '../queryKeys'
import { ContentLibraryEntryResponse, deleteContent } from '..'

const useDeleteContentMutation = () => {
  const queryClient = useQueryClient()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useMutation(
    ({ accountId, contentId }: { accountId: string; contentId: string }) =>
      deleteContent({ accountId, contentId }),
    {
      onMutate: async ({ contentId }) => {
        await queryClient.cancelQueries(contentKeys.contentLibrary(accountId))

        const previousContent: ContentLibraryEntryResponse[] | undefined =
          queryClient.getQueryData(contentKeys.contentLibrary(accountId))

        queryClient.setQueryData(
          contentKeys.contentLibrary(accountId),
          previousContent?.filter((content) => content.id !== contentId)
        )
      },
      onError: () => {
        errorToast('Something went wrong. Please try again.')
      },
      onSettled: () => {
        queryClient.invalidateQueries(contentKeys.contentLibrary(accountId))
      },
    }
  )
}

export default useDeleteContentMutation
