import { DashboardMode } from '@phrasee/phrasee-typings/Graphql/interfaces'

import WidgetLoader from 'common/components/loaders/WidgetLoader'
import Widget from 'common/components/Widget'
import WidgetHeader, { InfoAction } from 'common/components/WidgetHeader'
import { WidgetIncrementalError } from 'common/components/WidgetMessage'
import { useAppSelector } from 'common/hooks/redux'

import { CampaignTypes } from './CampaignMetrics'
import { PerformanceIndicator } from './PerformanceIndicator'
import UpgradeNotice from './UpgradeNotice'

interface Props {
  campaignType: CampaignTypes
  hasError: boolean
  isLoading: boolean
  totalIncrementalOpens: number
  totalIncrementalClicks: number
  totalProjects: number
  totalCampaigns: number
  averageClicksUplift: number
  averageOpensUplift: number
}

const KPICard = ({
  campaignType,
  hasError,
  isLoading,
  totalProjects,
  totalIncrementalOpens,
  totalIncrementalClicks,
  totalCampaigns,
  averageClicksUplift,
  averageOpensUplift,
}: Props) => {
  const { dashboardMode } = useAppSelector((state) => state.authStates)
  const hasToolTip = !isLoading && totalCampaigns !== 0

  const hasMetrics = !isLoading && !hasError
  const hasNoProjects = totalProjects === 0

  const hasNoData = (dashboardMode: DashboardMode | undefined): boolean => {
    return (
      (dashboardMode === 'Incremental' &&
        totalIncrementalClicks === 0 &&
        totalIncrementalOpens === 0) ||
      (dashboardMode === 'Average_uplift' &&
        averageClicksUplift === 0 &&
        averageOpensUplift === 0)
    )
  }

  return (
    <>
      {hasNoProjects && !isLoading ? (
        <UpgradeNotice campaignType={campaignType} />
      ) : (
        <Widget className="max-h-full">
          <WidgetHeader
            data-cy={`${campaignType}-KPI-Widget`}
            className="text-coolGray-800"
            title={
              hasError || hasNoData(dashboardMode) ? (
                <span
                  data-cy={`number-of-${campaignType}-experiments-widget-title`}
                >
                  {campaignType === 'broadcast' ? 'Broadcasts' : 'Triggers'}
                </span>
              ) : (
                <span
                  data-cy={`number-of-${campaignType}-experiments-widget-title`}
                >
                  {`${totalCampaigns === 0 ? '' : totalCampaigns} `}
                  {campaignType === 'broadcast' ? 'Broadcasts' : 'Triggers'}
                </span>
              )
            }
            actions={
              hasToolTip && (
                <InfoAction
                  message={
                    dashboardMode === 'Incremental'
                      ? `This widget displays your total incremental opens and clicks for all active ${campaignType} email projects for the past 12 months. If you want to dive deeper into your KPIs, head to the Reports page.`
                      : `This widget displays your average uplift for opens and clicks for all active ${campaignType} email projects for the past 12 months. If you want to dive deeper into your KPIs, head to the Reports page.`
                  }
                />
              )
            }
          />
          {isLoading && !hasError && (
            <WidgetLoader
              data-cy="number-of-triggers-widget-loader"
              size="small"
            />
          )}
          {hasError && <WidgetIncrementalError />}
          {hasMetrics && (
            <>
              <div>
                {hasNoData(dashboardMode) && (
                  <div className="display box-border text-sm p-4 gap-4 bg-gray-50 border-solid border border-gray-300 text-center">
                    Run your first{' '}
                    <span className="font-bold"> "Experiment"</span>, then your
                    data will start to appear here.
                  </div>
                )}
              </div>
              <div>
                <div className="flex flex-row gap-6 pt-3">
                  <PerformanceIndicator
                    className="flex flex-1 flex-col pt-10 border-r-1 border-dashed border-coolGray-300 pr-6"
                    kpiTitle={
                      dashboardMode === 'Incremental'
                        ? 'Incremental opens'
                        : 'Average uplift for opens'
                    }
                    kpiValue={
                      dashboardMode === 'Incremental'
                        ? totalIncrementalOpens
                        : `${averageOpensUplift}%`
                    }
                  />
                  <PerformanceIndicator
                    className="flex flex-1 flex-col pt-10 max-h-screen"
                    kpiTitle={
                      dashboardMode === 'Incremental'
                        ? 'Incremental clicks'
                        : 'Average uplift for clicks'
                    }
                    kpiValue={
                      dashboardMode === 'Incremental'
                        ? totalIncrementalClicks
                        : `${averageClicksUplift}%`
                    }
                  />
                </div>
              </div>
            </>
          )}
        </Widget>
      )}
    </>
  )
}

export default KPICard
