import { useQuery } from '@tanstack/react-query'

import { getBrandVoiceDetails } from '../../api'
import { brandVoiceKeys } from '../../api/queryKeys'

const useGetBrandVoiceDetailsQuery = (
  accountId: string,
  brandVoiceId: string
) => {
  return useQuery({
    queryKey: brandVoiceKeys.details(accountId, brandVoiceId),
    queryFn: () => getBrandVoiceDetails({ accountId, brandVoiceId }),
    enabled: !!accountId && !!brandVoiceId,
  })
}

export default useGetBrandVoiceDetailsQuery
