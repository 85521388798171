import { TreeNode, TypedEntryState } from 'common/components/catalog/Catalog'
import Slider from 'common/components/slider/Slider'

type Props = {
  items: TreeNode | string[]
  selectedPath: string[]
  depth: number
  onValueChange: ({ value }: { value: number[] }) => void
  typedEntryState: TypedEntryState<number>
}

const CustomerAttributesSlider = ({
  items,
  selectedPath,
  depth,
  onValueChange,
  typedEntryState,
}: Props) => {
  const {
    min: defaultMinNumber,
    max: defaultMaxNumber,
  }: { min: number; max: number } = items[selectedPath[depth]]

  const {
    value: [minNumber, maxNumber],
  } = typedEntryState[selectedPath[depth]]

  return (
    <div className="min-w-60">
      <div className="p-4">
        <Slider
          defaultValue={[defaultMinNumber, defaultMaxNumber]}
          min={defaultMinNumber}
          max={defaultMaxNumber}
          range
          allowCross={false}
          value={[minNumber, maxNumber]}
          onChange={(value) => {
            onValueChange({
              value: [value[0], value[1]],
            })
          }}
        />
      </div>
    </div>
  )
}

export default CustomerAttributesSlider
