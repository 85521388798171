import cx from 'classnames'

interface Props {
  htmlFor?: string
  className?: string
  optional?: boolean
  orientation?: 'horizontal' | 'vertical'
  status?: 'error' | 'default'
}

const Label: React.FC<Props> = ({
  htmlFor,
  className,
  status = 'default',
  optional = false,
  orientation = 'vertical',
  children,
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={cx(
        'block font-medium',
        { 'text-coolGray-800': status === 'default' },
        { 'text-red-400': status === 'error' },
        { 'mr-4': orientation === 'horizontal' },
        { 'mb-1': orientation === 'vertical' },
        className
      )}
    >
      {children}
      {optional && <span className="text-coolGray-400"> (optional)</span>}
    </label>
  )
}

export default Label
