import LoadingOverlay from 'common/components/LoadingOverlay'
import { useAppSelector } from 'common/hooks/redux'

const phrases = [
  'Preparing your experiment',
  'Generating millions of variations',
  'Optimizing to your audience',
  'Refining your experiment',
  'Finalizing the details',
  "Don't worry, we're almost ready to roll!",
  'Finishing the last touches...',
  'Polishing to perfection',
  "Sorry for the wait, it'll be worth it!",
]

const ContentGenerationLoader = () => {
  const nlgStatus = useAppSelector((state) => state.contentGeneration.nlgStatus)

  return (
    <LoadingOverlay
      title="Generating variants"
      isLoading={nlgStatus === 'pending'}
      phrases={phrases}
      overlayType="local"
    />
  )
}

export default ContentGenerationLoader
