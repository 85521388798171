import { useMemo } from 'react'

import useGetBrandVoicesQuery from 'features/admin/brandVoice/api/queries/useGetBrandVoicesQuery'

const useBrandVoiceItem = (brandVoiceId?: string) => {
  const { data: brandVoices } = useGetBrandVoicesQuery()

  const defaultBrandVoiceItem = useMemo(() => {
    const defaultBrandVoice = brandVoices?.find(
      (brandVoice) => brandVoice.isDefault
    )

    return defaultBrandVoice
      ? { value: defaultBrandVoice.id, label: defaultBrandVoice.name }
      : undefined
  }, [brandVoices])

  const initialBrandVoiceItem = useMemo(() => {
    const initialBrandVoice = brandVoices?.find(
      (brandVoice) => brandVoice.id === brandVoiceId
    )

    return initialBrandVoice
      ? { value: initialBrandVoice.id, label: initialBrandVoice.name }
      : undefined
  }, [brandVoiceId, brandVoices])

  return initialBrandVoiceItem || defaultBrandVoiceItem
}

export default useBrandVoiceItem
