import ContentTemplateCard from '../../contentsPage/ContentTemplateCard/ContentTemplateCard'
import { ContentTypesByTab, TemplateInfo } from '../../contentsPage/interfaces'

import useTemplateTypesBlocked from './hooks/useTemplateTypesBlocked'

type Props = {
  templates: TemplateInfo[]
  contentTypes: ContentTypesByTab
  experimentTypes: string[] | undefined
}

const TemplatesGrid = ({ templates, contentTypes }: Props) => {
  const typesOfTemplatesBlocked = useTemplateTypesBlocked(templates)

  return (
    <div>
      {Object.keys(contentTypes).map((type) => {
        const filterByContentType = (template: TemplateInfo): boolean =>
          template.contentType.toLowerCase() === type

        const templatesOfType = templates.filter(filterByContentType)

        if (templatesOfType.length === 0) {
          return null
        }
        return (
          <div key={type} className="mb-8">
            <h2 className="text-lg font-medium text-coolGray-800 mb-4 capitalize-first">
              {type}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {templatesOfType.map(
                ({
                  id,
                  icon,
                  displayName,
                  description,
                  disabled,
                  optimization,
                  personalization,
                }) => {
                  const isTemplateDisabled =
                    disabled ||
                    (type !== 'ecommerce' &&
                      typesOfTemplatesBlocked === 'non-ecommerce') ||
                    (type === 'ecommerce' &&
                      typesOfTemplatesBlocked === 'ecommerce')

                  return (
                    <div
                      key={id}
                      onClick={() => {}}
                      className={isTemplateDisabled ? '' : 'cursor-pointer'}
                    >
                      <ContentTemplateCard
                        templateId={id}
                        elementName={displayName}
                        iconType={icon}
                        header={displayName}
                        description={description}
                        isFavorited={false}
                        onFavoriteClick={() => alert('not implemented')}
                        disabled={isTemplateDisabled}
                        isComingSoon={disabled}
                        optimization={optimization?.status}
                        personalization={personalization?.status}
                      />
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TemplatesGrid
