import Button from 'common/components/button/Button'
import Modal from 'common/components/CampaignConfirmationModal'
import Tooltip from 'common/components/Tooltip'
import { useAppSelector } from 'common/hooks/redux'
import { Folder as FolderIcon } from 'common/icons'

export const TOOLTIP_TEXT =
  'The existing mailings are still scheduled in Acoustic,' +
  ' you need to unschedule them first.'
export const TOOLTIP_TEXT_CANCEL = 'The final has already been cancelled.'

const CancelModal: any = (props: any) => {
  const {
    shouldOpenCancelModal,
    handleCancel,
    handleCancelSends,
    finalSendCancelled,
  } = props
  const splitsSent = useAppSelector((state) => state.campaignStates.splitsSent)
  const mailingExists = useAppSelector(
    (state) => state.campaignStates.mailingExists
  )
  const folderName = useAppSelector((state) => state.campaignStates.folderName)

  return (
    <Modal
      data-test-id="CancelModal"
      closable
      onCancel={handleCancel}
      footer={false}
      title="Cancel options"
      visible={shouldOpenCancelModal}
    >
      <p className="mb-4">
        Acoustic does not provide API support for unscheduling and deleting a
        mailing once it is scheduled. To make changes, you will need to
        unschedule the existing mailings in the folder: <FolderIcon size={4} />{' '}
        {folderName}
      </p>

      {!splitsSent && (
        <p data-test-id="noSplitsSent" className="mb-4">
          <b className="block pb-2 text-lg text-maroon-300">
            Edit and resubmit the experiment
          </b>
          <span className="block pb-2">
            If the mailings have not been sent you can make changes and resubmit
            the experiment provided you have unscheduled the existing mailings.
          </span>
          <Tooltip
            data-test-id="Tooltip"
            overlay={mailingExists ? TOOLTIP_TEXT : null}
            trigger="hover"
            placement="right"
            show={mailingExists}
          >
            <Button
              data-cy="edit-resubmit-button"
              data-test-id="EditButton"
              disabled={mailingExists}
              variant={mailingExists ? undefined : 'primary'}
              onClick={() => handleCancelSends('edit_and_resubmit')}
            >
              Edit and resubmit
            </Button>
          </Tooltip>
        </p>
      )}

      <p className="mb-4">
        <b className="block pb-2 text-lg text-maroon-300">Cancel the final</b>
        <span className="block pb-2">
          Jacquard won't create the final mailing. The splits will be unaffected
          and Jacquard will collect the split test results.
        </span>
        <Tooltip
          data-test-id="TooltipForCancel"
          overlay={finalSendCancelled ? TOOLTIP_TEXT_CANCEL : null}
          trigger="hover"
          placement="right"
          show={finalSendCancelled}
        >
          <Button
            data-cy="cancel-final-button"
            data-test-id="CancelFinal"
            variant={finalSendCancelled ? undefined : 'primary'}
            disabled={finalSendCancelled}
            onClick={() => handleCancelSends('cancel_final')}
          >
            Cancel final
          </Button>
        </Tooltip>
      </p>
    </Modal>
  )
}

export default CancelModal
