import { useQuery } from '@tanstack/react-query'

import { TreeNode } from 'common/components/catalog/Catalog'
import { useAppSelector } from 'common/hooks/redux'
import { CustomerAttributes } from 'features/personalization/api'

import { getCategoryPerformance } from '../api'
import { personalizationKeys } from '../queryKeys'

export type ProductCategory = {
  id: string
  name: string
  created: string
  categories: any
  type: string
}
const useGetCategoryPerformanceQuery = ({
  personalizationId,
  metric,
  productCategories,
  customerAttributes,
}: {
  personalizationId: string
  metric: 'opens' | 'clicks'
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getCustomerAttributesQuery = useQuery(
    personalizationKeys.categoryPerformance({
      accountId,
      personalizationId,
      metric,
      customerAttributes,
      productCategories,
    }),
    () =>
      getCategoryPerformance({
        accountId,
        personalizationId,
        metric,
        customerAttributes,
        productCategories,
      }),
    {
      enabled: !!accountId && !!personalizationId && !!metric,
      retry: false,
      refetchOnWindowFocus: false,
      meta: {
        errorMessage: 'The category performance could not be loaded',
      },
    }
  )

  return getCustomerAttributesQuery
}

export default useGetCategoryPerformanceQuery
