import PageLeaveModal from 'common/components/pageLeaveModal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  inputFormLeave,
  toggleOpenUnsavedModal,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

const FormLeaveModal = () => {
  const dispatch = useAppDispatch()
  const openUnsavedModal = useAppSelector(
    (state) => state.unifiedFlow.openUnsavedModal
  )
  return (
    <PageLeaveModal
      isOpen={openUnsavedModal || false}
      isSaving={false}
      mode="stay"
      onConfirm={() => dispatch(inputFormLeave())}
      onCancel={() => dispatch(toggleOpenUnsavedModal(false))}
    />
  )
}

export default FormLeaveModal
