import { renderToStaticMarkup } from 'react-dom/server'
import { FormatterCallbackFunction, YAxisOptions } from 'highcharts/highstock'

import { ReactComponent as CheckboxDeselectedSvg } from 'common/icons/checkbox/deselected.svg'
import { ReactComponent as CheckboxSelectedSvg } from 'common/icons/checkbox/selected.svg'

export function getLabelWithCheckbox({
  isTextColored,
}: {
  isTextColored?: boolean
} = {}): FormatterCallbackFunction<any> {
  return function (this) {
    const checkboxSelectedSvgMarkup = renderToStaticMarkup(
      <CheckboxSelectedSvg />
    )
    const checkboxDeselectedSvgMarkup = renderToStaticMarkup(
      <CheckboxDeselectedSvg />
    )
    const checkboxStyle = `color:${this.color}; width: 24px;  height: 24px; margin-right: 8px;`

    return `
  <span style="display: flex; align-items: center;">
    <span style="${checkboxStyle}">${
      this.visible ? checkboxSelectedSvgMarkup : checkboxDeselectedSvgMarkup
    }</span>
    <span style="color: ${isTextColored ? this.color : 'text-gray-600'}">${
      this.name
    }</span>
  </span>`
  }
}

const gridLineColor = '#e5e7eb'

export const yAxisConfig: YAxisOptions = {
  title: {
    text: undefined,
  },
  opposite: false,
  gridLineColor,
  gridLineDashStyle: 'LongDash',
  // style 0 axis
  plotLines: [
    {
      dashStyle: 'Solid',
      color: gridLineColor,
      width: 1,
      value: 0,
    },
  ],
}
