import LinearLoader from './linearLoader'

interface Props {
  'data-cy'?: string
}

const HorizontalWidgetLoader: React.FC<Props> = ({ 'data-cy': dataCy }) => {
  return (
    <div
      className="bg-coolGray-50 flex justify-center items-center border border-coolGray-100 py-5"
      data-cy={dataCy}
      data-testid="loader"
    >
      <div className="w-123">
        <LinearLoader />
      </div>
    </div>
  )
}

export default HorizontalWidgetLoader
