import CheckboxGroup from 'common/components/checkbox/CheckboxGroup'

type Props = {
  data: any
  defaultValue: string | undefined
  disabled?: boolean
  onChange: Function
}

const SingleSelectRadioCheckbox = ({
  data,
  defaultValue,
  disabled,
  onChange,
}: Props) => {
  return (
    <CheckboxGroup
      onChange={(e) => {
        const newValue = e.target.checked ? e.target.value : false

        onChange(data.name, data.type, newValue, data.required, {
          max_limit: data.max_limit,
        })
      }}
      options={data.option_list.map((option) => ({
        label: option[0],
        value: option[1] ?? option[0],
        isChecked:
          option[1] !== undefined
            ? defaultValue === option[1]
            : defaultValue === option[0],

        isDisabled: disabled,
      }))}
    />
  )
}

export default SingleSelectRadioCheckbox
