const TypeOfBanner = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
}

const TypeOfModal = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export { TypeOfBanner, TypeOfModal }
