import { Form, FormSpy } from 'react-final-form'
import RouterPromptStay from 'app/router/RouterPromptStay'
import cloneDeep from 'lodash/cloneDeep'

import DynamicFields from 'common/components/dynamicFields'
import { parseFieldsConfiguration } from 'common/components/dynamicFields/helpers'
import { FieldsConfiguration } from 'common/components/dynamicFields/interfaces'
import { useAppSelector } from 'common/hooks/redux'
import useUpdateContentMutation from 'features/futurama/api/mutations/useUpdateContentMutation'

import useContentOrTemplate from '../../hooks/useContentOrTemplate'

import useBrandVoiceItem from './brandVoiceSettings/hooks/useBrandVoiceItem'
import { FormSubmitAction, PageState } from './StartHere'

type Props = {
  fieldsConfiguration: FieldsConfiguration
  brandVoiceId?: string
  pageState: PageState
  children: React.ReactNode
  onChange: (hasChanged: boolean) => void
  refreshTopics: FormSubmitAction
  generateTopics: FormSubmitAction
  generateVariants: FormSubmitAction
  generateVariantsWithoutTopics: FormSubmitAction
  regenerateVariantsWithoutTopics: FormSubmitAction
}

const GenerationInputForm = ({
  fieldsConfiguration,
  brandVoiceId,
  pageState,
  children,
  refreshTopics,
  generateTopics,
  generateVariants,
  generateVariantsWithoutTopics,
  regenerateVariantsWithoutTopics,
  onChange,
}: Props) => {
  const { content, template } = useContentOrTemplate()
  const parsedConfiguration = parseFieldsConfiguration(fieldsConfiguration)
  const generationNoInput = parsedConfiguration?.find(
    (field) => field.name === 'split_num' && field.type === 'dropdown'
  )
  if (
    generationNoInput &&
    (pageState === 'showGeneratedContentWithoutTopics' ||
      pageState === 'showGeneratedContent' ||
      pageState === 'showContent')
  ) {
    generationNoInput.isDisabled = true
  }

  const brandVoiceItem = useBrandVoiceItem(brandVoiceId)

  const initialValues: any = fieldsConfiguration.reduce(
    (acc, state) => ({ ...acc, [state.name]: state.value }),
    {}
  )
  if (brandVoiceItem) {
    initialValues.brand_voice = brandVoiceItem
  }

  const updateContentMutation = useUpdateContentMutation()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const updateContentName = async (value: string) => {
    if (!content) {
      return
    }

    //TODO: remove uperInputForm from request body when backend supports it
    const userInputForm = cloneDeep(content.user_input.user_input_form)

    userInputForm.forEach((field) => {
      if (field.name === 'content_name') {
        field.value = value
      }
    })

    await updateContentMutation.mutate({
      contentId: content._id,
      accountId,
      userInputForm,
      updatedName: value,
    })
  }

  const getSubmitAction = (): FormSubmitAction => {
    switch (pageState) {
      case 'showTemplate':
        return generateTopics
      case 'showTemplateWithoutTopics':
        return generateVariantsWithoutTopics
      case 'formChangedWithoutTopics':
        return regenerateVariantsWithoutTopics
      case 'formChanged':
        return refreshTopics
      case 'showContent':
      case 'showContentWithoutTopics':
      case 'topicsChanged':
        return generateVariants
      default:
        return async () => {}
    }
  }

  return (
    <Form
      onSubmit={getSubmitAction()}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, dirty }) => (
        <form
          onSubmit={handleSubmit}
          onBlur={(e) => {
            if (e.target.name === 'content_name' && e.target.value !== '') {
              updateContentName(e.target.value)
            }
          }}
        >
          <FormSpy
            onChange={(props) => {
              const dirtyFields = Object.keys(props.dirtyFields)

              const isDirty =
                dirtyFields.length > 0 &&
                !dirtyFields.every(
                  (field) => field === 'content_name' || field === 'split_num'
                )

              onChange(isDirty)
            }}
          />
          {parsedConfiguration && (
            <DynamicFields
              fieldsConfiguration={parsedConfiguration}
              isDisabled={submitting}
            />
          )}
          {children}
          <RouterPromptStay
            shouldShow={
              (!!template && dirty && !submitting) ||
              (!!content && dirty && !submitting)
            }
            onOK={() => Promise.resolve(true)}
          />
        </form>
      )}
    />
  )
}

export default GenerationInputForm
