import { FC } from 'react'
import cx from 'classnames'

import { useMoveIntercomButton } from 'common/components/intercom/useMoveIntercomButton'

type Props = {
  className?: string
}

const BottomToolbar: FC<Props> = ({ children, className }) => {
  useMoveIntercomButton()

  return (
    <div
      className={cx(
        'sticky bottom-0 py-4 px-6 bg-white flex shadow-top',
        className
      )}
    >
      {children}
    </div>
  )
}

export default BottomToolbar
