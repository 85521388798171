import { Content } from '@phrasee/phrasee-typings/typings/futurama/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { updateElement } from 'features/unifiedFlow/api'
import { contentKeys } from 'features/unifiedFlow/api/queryKeys'

type MutationContext = { previousContent: Content }

const useUpdateIntegrationAccountMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(updateElement, {
    onMutate: async ({ contentId, elementId, updatedElement }) => {
      await queryClient.cancelQueries(contentKeys.content(contentId))

      const previousContent = queryClient.getQueryData<Content>(
        contentKeys.content(contentId)
      )

      queryClient.setQueryData<Content>(
        contentKeys.content(contentId),
        (old) => {
          if (old) {
            const updatedElements = old?.elements?.map((element) => {
              if (element.element_id === elementId) {
                return {
                  ...element,
                  integration_account_id: updatedElement.integration_account_id,
                }
              }

              return element
            })

            return { ...old, elements: updatedElements }
          }
        }
      )
      return { previousContent } as MutationContext
    },
    onError: (_, { contentId }, context) => {
      if (contentId) {
        queryClient.setQueryData<Content>(
          contentKeys.content(contentId),
          (old) => {
            if (!old) {
              return old
            }

            return (context as MutationContext)?.previousContent
          }
        )
      }
      showErrorToast('Something went wrong. Please try again.')
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
    },
  })
}

export default useUpdateIntegrationAccountMutation
