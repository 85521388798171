import { Dispatch, SetStateAction, useEffect, useState } from 'react'

function getStorageValue<T>(key: string, defaultValue: T) {
  const saved = localStorage.getItem(key)
  if (!saved) {
    return defaultValue
  }

  return JSON.parse(saved)
}

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    return getStorageValue<T>(key, defaultValue)
  })

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
