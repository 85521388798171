import * as Drawer from 'app/IntegrationDrawer'

import Button from 'common/components/button/Button'

import { Status } from '../ResponsysIntegration'

interface Props {
  status: Status
  isScheduled: boolean
  scheduleCampaign: () => void
}

export default function Footer({
  status,
  isScheduled,
  scheduleCampaign,
}: Props) {
  const isLoading = status === 'loading'
  return (
    <Drawer.Footer>
      {!isScheduled && status !== 'success' && (
        <Button
          data-test-id="schedule-campaign"
          loading={isLoading}
          disabled={isLoading}
          variant="primary"
          onClick={() => scheduleCampaign()}
        >
          {isLoading ? 'Saving' : 'Save schedule'}
        </Button>
      )}
      {status === 'success' && (
        <Button variant="success">Schedule saved</Button>
      )}
    </Drawer.Footer>
  )
}
