import { useEffect } from 'react'
import {
  DateRangePicker,
  DateRangePickerShape,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from 'react-dates'
import cx from 'classnames'
import moment, { Moment } from 'moment'

import 'react-dates/initialize'

import Tooltip from './Tooltip'

import 'react-dates/lib/css/_datepicker.css'
import './DateStyles.css'

export { isInclusivelyAfterDay }
export type FocusedInputType = FocusedInputShape
export type DateRangePickerType = DateRangePickerShape
export type SelectorDate = Moment | null

interface DefaultOption {
  id: string
  title: string
  start: SelectorDate
  end: SelectorDate
}

export const defaultOptions: DefaultOption[] = [
  {
    id: '3-months',
    title: 'Last 3 months',
    start: moment().subtract(3, 'months'),
    end: moment(),
  },
  {
    id: '6-months',
    title: 'Last 6 months',
    start: moment().subtract(6, 'months'),
    end: moment(),
  },
  {
    id: '12-months',
    title: 'Last 12 months',
    start: moment().subtract(12, 'months'),
    end: moment(),
  },
]

type FooterProps = {
  onCancel: () => void
  onApply: () => void
  isApplyDisabled: boolean
  areDefaultOptionsDisabled?: boolean
  applyDefaultOption?: (start: SelectorDate, end: SelectorDate) => void
}

export type TooltipProps = {
  hasTooltip?: boolean
  tooltipText?: string
}

type CustomProps = TooltipProps & {
  label?: string
  footer?: FooterProps
  onOutsideClick?: () => void
}

const doDaysMatch = (date1: SelectorDate, date2: SelectorDate) => {
  return date1 === date2 || date1?.isSame(date2, 'day')
}

const DateRange = ({
  focusedInput,
  label,
  footer,
  startDate,
  endDate,
  onOutsideClick,
  disabled,
  hasTooltip = false,
  tooltipText,
  ...rest
}: DateRangePickerShape & CustomProps) => {
  useEffect(() => {
    if (!focusedInput && onOutsideClick) {
      onOutsideClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedInput])

  return (
    <Tooltip show={hasTooltip} overlay={tooltipText}>
      <div>
        {label && <div className="mb-1 font-medium">{label}</div>}
        <DateRangePicker
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          firstDayOfWeek={1}
          renderCalendarInfo={() => {
            if (footer) {
              const {
                onCancel,
                onApply,
                isApplyDisabled,
                applyDefaultOption,
                areDefaultOptionsDisabled,
              } = footer

              return (
                <div
                  className={cx('flex items-center px-4 pb-4', {
                    'justify-between': applyDefaultOption,
                    'justify-end': !applyDefaultOption,
                  })}
                >
                  {applyDefaultOption && (
                    <div>
                      {defaultOptions.map(({ id, title, start, end }) => {
                        const isSelected =
                          doDaysMatch(startDate, start) &&
                          doDaysMatch(endDate, end)

                        return (
                          <button
                            data-testid={`option-${id}`}
                            data-cy={`default-date-option-${id}`}
                            key={title}
                            className={cx(
                              `mr-2 last:mr-0 px-2 py-1 text-xs 
                            text-white disabled:bg-coolGray-300 
                            disabled:cursor-not-allowed`,
                              { 'bg-maroon-300': !isSelected },
                              { 'bg-maroon-600': isSelected }
                            )}
                            onClick={() => applyDefaultOption(start, end)}
                            disabled={areDefaultOptionsDisabled}
                          >
                            {title}
                          </button>
                        )
                      })}
                    </div>
                  )}

                  <div>
                    <button
                      data-testid="cancel"
                      data-cy="date-selector-cancel"
                      className="mr-4 py-1 px-2 text-maroon-500
                    font-medium"
                      onClick={() => onCancel()}
                    >
                      Cancel
                    </button>
                    <button
                      data-testid="apply-dates"
                      data-cy="date-selector-apply"
                      className="py-1 px-2 bg-maroon-500 text-white
                  font-medium disabled:bg-coolGray-300
                  disabled:cursor-not-allowed"
                      disabled={isApplyDisabled}
                      onClick={() => onApply()}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              )
            }
          }}
          disabled={disabled}
          hideKeyboardShortcutsPanel={true}
          {...rest}
        />
      </div>
    </Tooltip>
  )
}

export default DateRange
