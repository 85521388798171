import TagWidget from 'common/components/widget/TagWidget/TagWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { addLocation, removeLocation } from '../store/regionsSlice'

const LocationsWidget = () => {
  const regionEdited = useAppSelector((state) => state.regions).regionEdited
  const locations = regionEdited.locations.map((location) => ({
    label: location,
    value: location,
  }))
  const dispatch = useAppDispatch()

  const handleAddLocation = (value: string) => dispatch(addLocation(value))
  const handleRemoveLocation = (value: string) =>
    dispatch(removeLocation(value))

  return (
    <TagWidget
      title="Locations"
      subtitle="Enter locations that require the word 'the' to proceed it in a sentence. For example, 'the French Alps'."
      data-cy="locations-widget"
      placeholder="Location..."
      tags={locations}
      maxLengthPerTag={50}
      onAddTag={handleAddLocation}
      onRemoveTag={handleRemoveLocation}
    />
  )
}

export default LocationsWidget
