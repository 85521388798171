import { isComponentElement } from 'features/unifiedFlow/api/interfaces'

import RightPanelEmpty from '../../components/layout/RightPanelEmpty'
import { useSelectedElement } from '../../hooks'

import ExperimentSettings from './experimentSettings'

const RightSection = () => {
  const { data: selectedElement } = useSelectedElement()

  const shouldShowExperimentSettings =
    selectedElement &&
    isComponentElement(selectedElement) &&
    selectedElement.campaign_id

  return (
    <>
      {shouldShowExperimentSettings ? (
        <ExperimentSettings key={selectedElement?.element_id} />
      ) : (
        <RightPanelEmpty />
      )}
    </>
  )
}

export default RightSection
