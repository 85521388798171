import { useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'

const useDebounce = <T extends (...args: any[]) => any>(
  callback: T,
  wait: number = 300
) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (...args: Parameters<T>) => {
      ref.current?.(...args)
    }

    return debounce(func, wait)
  }, [wait])

  useEffect(() => {
    return () => {
      debouncedCallback.cancel()
    }
  }, [debouncedCallback])

  return debouncedCallback
}

export default useDebounce
