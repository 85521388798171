import { useMemo } from 'react'
import cond from 'lodash/cond'
import sortBy from 'lodash/sortBy'
import stubTrue from 'lodash/stubTrue'
import { ResponseVariant } from 'workflow/Insights/Insights'
import helpers from 'workflow/utils/helpers'

import { Column } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'
import type { Sentiments } from 'features/reports/insights/api'
import { VARIANTS_CATEGORIES } from 'features/unifiedFlow/contentPage/generate/constants'
import { useVariantInsights } from 'features/unifiedFlow/contentPage/generate/context/VariantInsightsContext'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'

import ActionCell from '../ActionsCell'
import ContentTableActionButtons from '../contentTable/contentTableActionButtons'
import useTableActions from '../hooks/useTableActions'
import RecommendedCell from '../RecommendedCell'

import useSelectedVariants from './useSelectedVariants'
import VariantCell from './VariantCell'

const REASON_INCLUDED_MAPPER = {
  'Ranked high': VARIANTS_CATEGORIES.PERFORMANCE,
  Diversity: VARIANTS_CATEGORIES.EXPLORATION,
  'Ranked low': VARIANTS_CATEGORIES.BASELINE,
} as const

const categoryOrder = [
  VARIANTS_CATEGORIES.EXPLORATION,
  VARIANTS_CATEGORIES.PERFORMANCE,
  VARIANTS_CATEGORIES.BASELINE,
  VARIANTS_CATEGORIES.USER_MODIFIED,
]

type ReasonKey = keyof typeof REASON_INCLUDED_MAPPER

const getVariantCategory = (reason: ReasonKey | undefined, isEdited: boolean) =>
  cond([
    [() => !!reason, () => REASON_INCLUDED_MAPPER[reason as ReasonKey]],
    [() => isEdited, () => VARIANTS_CATEGORIES.USER_MODIFIED],
    [stubTrue, () => undefined],
  ])()

type ExtendedResponseVariant = ResponseVariant & {
  category: string
  recommended: boolean
  sentiments: Sentiments
}

type Props = {
  isReadOnly?: boolean
}

const OptimizeResultsTable = ({ isReadOnly }: Props) => {
  const { data: selectedElement } = useSelectedComponentElement()
  const { setVariantState, tableRef } = useVariantInsights()
  const { campaignData, subjectLines } = useAppSelector(
    (state) => state.campaignStates
  )

  const variantsToBeApproved = useAppSelector(
    (state) => state.campaignStates.variantsToBeApproved
  )
  const { selectedVariantId, setSelectedVariantId } =
    useSelectedVariants(subjectLines)

  const variantsWithModifiedState = useMemo(() => {
    const isBanditCampaign = helpers.isBanditCampaign(campaignData)
    const isLinguoCampaign = helpers.isLinguoCampaign(campaignData)

    const allowedStatues =
      isBanditCampaign && isLinguoCampaign
        ? ['live', 'approved', 'created']
        : isBanditCampaign
        ? ['live', 'approved', 'created']
        : []

    const filteredData = subjectLines
      .filter(({ _id }) => !variantsToBeApproved.includes(_id))
      .filter(
        (variant) =>
          allowedStatues.length === 0 ||
          allowedStatues.includes(variant?.bandit_status?.status)
      )
      .map((variant) => {
        const isEdited = !!variant?.why_tweak
        const category = getVariantCategory(variant?.reason_included, isEdited)
        const recommended = variant?.sort_order === 1
        const isSelected = selectedVariantId === variant._id

        return {
          ...variant,
          recommended,
          isHighlighted: isSelected,
          isEdited,
          ...(category ? { category } : {}),
        }
      })

    return sortBy(filteredData, [
      (item) => {
        return item.category
          ? categoryOrder.indexOf(item.category)
          : categoryOrder.length
      },
      (item) => (item.recommended === true ? 0 : 1),
    ])
  }, [campaignData, subjectLines, selectedVariantId, variantsToBeApproved])

  const elementId = selectedElement?.element_id

  const { onCopy, onRequestChange } = useTableActions()

  const columns: Column<ExtendedResponseVariant>[] = useMemo(
    () => [
      {
        Header: 'Variants',
        accessor: 'text',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <VariantCell
              index={row.pageRowIndex}
              text={row.original.text}
              isControlVariant={row.original.ownsl}
            />
          )
        },
      },
      {
        id: 'recommended',
        disableSortBy: true,
        accessor: 'recommended',
        align: 'right',
        width: 125,
        disableFilters: true,
        className: 'flex-auto',
        Cell: ({ row }) => {
          return <RecommendedCell isRecommended={row.original.recommended} />
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: '_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => {
          if (row.original?.bandit_status?.status === 'live') {
            return null
          }

          return (
            <ActionCell
              onCopy={() => onCopy(row.values.text)}
              onRequestChange={
                row.original.ownsl
                  ? undefined
                  : () => onRequestChange(row.original)
              }
              isRefreshing={false}
            />
          )
        },
      },
      {
        accessor: 'category',
      },
    ],
    [onCopy, onRequestChange]
  )

  const handleRowClick = (variant: ExtendedResponseVariant) => {
    setSelectedVariantId(variant._id)
    setVariantState({
      category: variant.category,
      sentiments: variant?.sentiments,
    })
  }

  return (
    <div className="w-full" style={{ height: 'max-content' }} ref={tableRef}>
      <TableWidget.Widget<ExtendedResponseVariant>
        key={elementId}
        data={variantsWithModifiedState}
        columns={columns}
        autoResetPage={false}
        onRowClick={handleRowClick}
        initialState={{
          groupBy: ['category'],
          hiddenColumns: isReadOnly ? ['actions', 'category'] : ['category'],
        }}
      >
        <TableWidget.Header
          title={
            <div style={{ height: '38px' }}>
              {selectedElement?.display_name || selectedElement?.name}
            </div>
          }
          subtitle={selectedElement?.description}
        />
        {variantsToBeApproved.length === 0 && (
          <TableWidget.ActionButtons>
            <ContentTableActionButtons isReadOnly={isReadOnly} />
          </TableWidget.ActionButtons>
        )}
      </TableWidget.Widget>
    </div>
  )
}

export default OptimizeResultsTable
