import { FC, useState } from 'react'

import { Team } from 'common/api/teamsApi'
import Modal from 'common/components/Modal'
import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import AddTeamFormContent from './addTeamFormContent/AddTeamFormContentOld'
import AddMultipleTeamContent from './AddMultipleTeamContent'

interface Props {
  isVisible: boolean
  onClickCreateTeam: (val: Omit<Team, 'id'>) => void
  selectedTeamId: string | undefined
  onClose: () => void
}
const DEFAULT_EMAILS = ['', '', '']

const AddTeamModal: FC<Props> = ({
  isVisible,
  onClickCreateTeam,
  onClose,
  selectedTeamId,
}) => {
  const { teams } = useAppSelector((state) => state.adminUsers)
  const selectedTeam = teams.find((team) => team.id === selectedTeamId)
  const isEditMode = selectedTeamId !== undefined

  const [name, setName] = useState<string>(selectedTeam?.name ?? '')
  const [emails, setEmails] = useState<string[]>(DEFAULT_EMAILS)

  const resetState = () => {
    setName('')
    setEmails(DEFAULT_EMAILS)
  }
  const [isMultipleView, setIsMultipleView] = useState<boolean>(false)

  const handleSubmit = () => {
    const newMembers = emails.filter((email) => !!email.trim())
    const members = (selectedTeam?.members as any) ?? []
    onClickCreateTeam({
      members: [...members, ...newMembers],
      name,
    })
    resetState()
  }

  const handleClose = () => {
    onClose()
    setIsMultipleView(false)
    resetState()
  }

  return (
    <Modal
      data-cy="add-team-modal"
      visible={isVisible}
      closable={false}
      footer={null}
      hasMargin={false}
      centered
    >
      {isMultipleView ? (
        <AddMultipleTeamContent
          onClose={handleClose}
          onClickSingleView={() => setIsMultipleView(false)}
          onSyncWithForm={(emailsAsString) => {
            const emails = emailsAsString
              .trim()
              .split(',')
              .map((email) => email.trim())
              .filter((email) => email.length > 0)
            if (emails.length > 10) {
              errorToast('You can add only 10 users maximum at a time', {
                autoClose: 10000,
              })
            }
            setEmails(emails.slice(0, 10))
            setIsMultipleView(false)
          }}
          emails={emails}
          key={emails.join('-')}
        />
      ) : (
        <AddTeamFormContent
          isEditMode={isEditMode}
          selectedTeam={selectedTeam}
          teamName={name}
          teams={teams}
          onTeamNameChange={setName}
          emails={emails}
          updateEmails={setEmails}
          onClickSetMultipleUser={() => setIsMultipleView(true)}
          onSubmit={handleSubmit}
          onClickCancel={handleClose}
        />
      )}
    </Modal>
  )
}

export default AddTeamModal
