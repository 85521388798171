import { useMutation } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { Tag } from 'common/interfaces/tags'

import { createTag } from '..'

const useCreateTagMutation = () => {
  return useMutation<Tag, unknown, { tag: string; userId: string }>({
    mutationFn: ({ tag, userId }) => createTag({ tag, userId }),
    onError: (error) => {
      showErrorToast(error)
    },
  })
}

export default useCreateTagMutation
