import React from 'react'
import * as Highcharts from 'highcharts/highstock'

import HighchartsReact from 'common/components/AutoResizeChart'

export interface Props {
  data: number[]
}

Highcharts.setOptions({
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
})

const IncrementalRevenueGraph: React.FC<Props> = ({ data }) => {
  const options: Highcharts.Options = {
    title: undefined,
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        theme: {
          stroke: 'none',
        },
      },
    },
    navigator: {
      series: {
        color: '#61bec7',
        type: 'areaspline',
      },
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      visible: false,
    },
    chart: {
      type: 'area',
      style: {
        fontFamily: 'Roboto',
      },
      height: 40,
      margin: [0, 0, 0, 0],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },

    plotOptions: {
      series: {
        stickyTracking: false,
        dataGrouping: {},
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: 'area',
        data,
        color: '#10b981',
        fillColor: '#ecfdf5',
      },
    ],
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="chart"
      options={options}
      immutable={true}
    />
  )
}

export default IncrementalRevenueGraph
