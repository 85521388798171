import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { Topic } from 'common/components/topics/interfaces'

import { Phrase } from '../interfaces'
import { contentKeys } from '../queryKeys'
import { createPhrase } from '..'

const useCreatePhraseMutation = (selectedNodeId) => {
  const queryClient = useQueryClient()

  const updateNodeWithNewPhrase = (phrase) =>
    queryClient.setQueryData<Phrase[]>(
      contentKeys.phrases(selectedNodeId),
      (old) => {
        if (old !== undefined) {
          return old?.map((oldPhrase) =>
            oldPhrase.phraseId === 0 ? phrase : oldPhrase
          )
        }
      }
    )

  return useMutation<
    unknown,
    unknown,
    { nodeId: number; phrase: Phrase; userId: string; replacers?: Topic[] }
  >(createPhrase, {
    onMutate: async ({ phrase }) => {
      // Update the cache with the new phrase name before the re-rendering caused by cancelQueries, to avoid flickering
      updateNodeWithNewPhrase(phrase)

      await queryClient.cancelQueries(contentKeys.phrases(selectedNodeId))

      updateNodeWithNewPhrase(phrase)
    },
    onError: (error) => {
      queryClient.setQueryData<Phrase[]>(
        contentKeys.phrases(selectedNodeId),
        (old) => {
          return old?.filter((phrase) => phrase.phraseId !== 0)
        }
      )

      showErrorToast(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries(contentKeys.phrases(selectedNodeId))
    },
  })
}

export default useCreatePhraseMutation
