import cx from 'classnames'

import { IconType } from 'common/icons/Icon'

type Props = {
  onClick: () => void
  title: string
  icon: IconType
  isSelected: boolean
  className?: string
  'data-testid'?: string
}

const Item = ({
  onClick,
  title,
  icon: Icon,
  isSelected,
  className,
  'data-testid': dataTestid,
}: Props) => {
  return (
    <div
      data-testid={dataTestid}
      data-cy={`${title.toLocaleLowerCase()}-variant-button`}
      className={cx(
        `flex-1 flex flex-row border group font-medium items-center cursor-pointer py-2 px-4`,
        className,
        {
          'border-maroon-500 text-maroon-300': isSelected,
          'border-coolGray-200 hover:border-maroon-300 hover:text-maroon-300':
            !isSelected,
        }
      )}
      onClick={onClick}
    >
      {isSelected ? (
        <div className="w-5 h-5 bg-maroon-500 rounded-full  flex justify-center items-center mr-4">
          <div
            className=" bg-white rounded-full"
            style={{ width: 8, height: 8 }}
          />
        </div>
      ) : (
        <div className="w-5 h-5 bg-coolGray-400 rounded-full  flex justify-center items-center mr-4">
          <div
            className="bg-white rounded-full"
            style={{ width: 16, height: 16 }}
          />
        </div>
      )}
      <div
        className={cx('mr-2 text-base font-normal', {
          'text-maroon-500': isSelected,
          'text-black	': !isSelected,
        })}
      >
        {title}
      </div>
      <div>
        <span className="group-hover:hidden">
          <Icon state={isSelected ? 'selected' : 'default'} size={6} />
        </span>
        <span className="hidden group-hover:inline-block">
          <Icon state="selected" size={6} />
        </span>
      </div>
    </div>
  )
}

export default Item
