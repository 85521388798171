import cx from 'classnames'

type Props = {
  className?: string
}

const Footer = ({ className }: Props) => {
  return (
    <div
      className={cx(
        `flex justify-center items-center text-coolGray-300 h-24`,
        className
      )}
    ></div>
  )
}

export default Footer
