import { FC } from 'react'
import cx from 'classnames'
import Checkbox from 'rc-checkbox'

import 'rc-checkbox/assets/index.css'
import styles from './AvatarColorPicker.module.css'

type Props = {
  selectedColor: AvatarColor
  onSelect: (color: AvatarColor) => void
}

export const avatarColors = [
  'red',
  'orange',
  'amber',
  'emerald',
  'blue',
  'indigo',
  'violet',
] as const

export type AvatarColor = typeof avatarColors[number]

const AvatarColorPicker: FC<Props> = ({ selectedColor, onSelect }) => {
  return (
    <div className="flex">
      {avatarColors.map((key) => {
        return (
          <span
            key={key}
            className={cx(styles.checkbox, 'mr-6')}
            onClick={() => onSelect(key)}
          >
            <Checkbox
              className={cx(key)}
              name={key}
              value={selectedColor}
              checked={selectedColor === key}
            />
          </span>
        )
      })}
    </div>
  )
}

export default AvatarColorPicker
