import { ReactNode } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  AiContent as AiContentIcon,
  LanguageSetup as LanguageSetupIcon,
  Reaction as ReactionIcon,
} from 'common/icons'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import { showTemplates } from 'features/futurama/store/contentSlice'
import ListWidget, {
  ListWidgetAction,
  ListWidgetItem,
} from 'features/homePage/components/listWidget'
type ToDoItem = {
  id: string
  prefixIcon: ReactNode
  name: string
  progress: string
  action: ListWidgetAction
}

const TodoEmpty = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const defaultToDoList: ToDoItem[] = [
    {
      id: 'todo-1',
      prefixIcon: <LanguageSetupIcon isDefaultColor={false} />,
      name: 'Setup your brand voice',
      progress: 'in a few simple steps.',
      action: {
        label: 'Get started',
        onClick: () => {
          history.push(generatePath(`admin/accounts/${accountId}/brand-voice`))
        },
      },
    },
    {
      id: 'todo-2',
      prefixIcon: <ReactionIcon isDefaultColor={false} />,
      name: 'Create your first Experiment',
      progress: 'for optimization.',
      action: {
        label: 'Create Experiment',
        onClick: () => {
          history.push(generatePath('/campaigns', { accountId }))
          dispatch(clickCreateCampaign())
        },
      },
    },
    {
      id: 'todo-3',
      prefixIcon: <AiContentIcon isDefaultColor={false} />,
      name: 'Start generating content',
      progress: 'assisted with AI',
      action: {
        label: 'Generate Content',
        onClick: () => {
          history.push(generatePath('/content'))
          dispatch(showTemplates())
        },
      },
    },
  ]

  return (
    <ListWidget
      title="To-dos"
      data-cy="todo-widget"
      className="w-full"
      isLoading={false}
      error={null}
    >
      <ul>
        {defaultToDoList.map(
          ({ id, prefixIcon, name, progress, action }: ToDoItem, index) => (
            <ListWidgetItem
              key={id}
              onClick={action.onClick}
              data-cy={`todo-default-widget-link-${index}`}
            >
              <span className="text-black group-hover:text-maroon-500">
                {prefixIcon}
              </span>
              <span className="text-coolGray-800 font-medium ml-3">{name}</span>
              <span className="text-coolGray-500"> {progress}</span>
              <span className="text-pink-500 group-hover:underline group-hover:text-maroon-500">
                {action.label}
              </span>
            </ListWidgetItem>
          )
        )}
      </ul>
    </ListWidget>
  )
}

export default TodoEmpty
