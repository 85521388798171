import { Fragment, useEffect, useState } from 'react'

import { connectInternalApi } from 'common/api'
import Modal from 'common/components/Modal'
import Tooltip from 'common/components/Tooltip'
import { ReactComponent as InfoDefault } from 'common/icons/info/default.svg'

import './App.less'

function SourceBranchesReport() {
  const environment = process.env.REACT_APP_ENVIRONMENT ?? ''
  const isVisible = !['ca', 'prod'].includes(environment)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [branchInfo, setBranchInfo] = useState<any[]>([])

  useEffect(() => {
    const fetch = async () => {
      connectInternalApi
        .get(`v1/info/branch_name_report_v2_new?environment=${environment}`)
        .then((response) => {
          setBranchInfo(response.data)
        })
    }
    if (isVisible) {
      fetch()
    }
  }, [isVisible, environment])

  if (!isVisible) {
    return null
  }

  return (
    <div className="absolute top-5 right-0 z-110 ">
      <>
        <div data-test-id="source-branch-info-icon">
          <Tooltip overlay="Source branch info" placement="left">
            <InfoDefault
              className="cursor-pointer"
              width={24}
              height={24}
              onClick={() => setIsModalVisible(true)}
            />
          </Tooltip>
        </div>
        <Modal
          title="Deployed Git Branches"
          visible={isModalVisible}
          zIndex={1000000000}
          width="800px"
          footer={null}
          onClose={() => setIsModalVisible(false)}
        >
          <div
            className="grid "
            style={{ gridTemplateColumns: 'repeat(4, minmax(0, auto))' }}
          >
            <b>Repository</b>

            <b>Branch</b>

            <b>Version</b>

            <b>Last Deployment</b>

            {branchInfo &&
              branchInfo
                .sort((a, b) =>
                  a?.repository_name?.localeCompare(b?.repository_name)
                )
                .map((branchObj) => {
                  return (
                    <Fragment key={branchObj.repository_name}>
                      <div>{branchObj.repository_name}</div>
                      <div>{branchObj.branch_name}</div>
                      <div>{branchObj.version}</div>
                      <div>{branchObj.timestamp}</div>
                    </Fragment>
                  )
                })}
          </div>
        </Modal>
      </>
    </div>
  )
}

export default SourceBranchesReport
