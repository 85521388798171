import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  ThunkDispatch,
} from '@reduxjs/toolkit'

import breakpoints from 'common/breakpoints'

interface NavigationState {
  isNavCollapsed: boolean
  isFilterNavCollapsed: boolean
}

const initialState: NavigationState = {
  isNavCollapsed: window.matchMedia(`(max-width: ${breakpoints.md}px)`).matches,
  isFilterNavCollapsed: true,
}

export const clickNavItem = createAsyncThunk(
  'navigation/clickNavItem',
  (_, thunkAPI) => {
    const isSmall = window.matchMedia(
      `(max-width: ${breakpoints.md}px)`
    ).matches
    if (isSmall) {
      thunkAPI.dispatch(collapseNav())
    }
  }
)

export const dispatchClickNavItem = (
  dispatch: ThunkDispatch<unknown, void, AnyAction>
) => dispatch(clickNavItem())

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    collapseNav: (state) => {
      state.isNavCollapsed = true
    },
    expandNav: (state) => {
      state.isNavCollapsed = false
    },
    toggleNav: (state) => {
      state.isNavCollapsed = !state.isNavCollapsed
    },
    openFilterPanel: (state) => {
      state.isFilterNavCollapsed = false
      state.isNavCollapsed = true
    },
    closeFilterPanel: (state) => {
      state.isFilterNavCollapsed = true
    },
  },
})

export const {
  collapseNav,
  expandNav,
  toggleNav,
  closeFilterPanel,
  openFilterPanel,
} = navigationSlice.actions

export default navigationSlice.reducer
