import { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import {
  canAccessAccount,
  getIsAuthorized,
  routesPermissions,
  usersPermissions,
} from 'common/auth/permissions'
import Button from 'common/components/button'
import Modal from 'common/components/Modal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  AiContent as AiContentIcon,
  Reaction as ReactionIcon,
  Reports as ReportsIcon,
  Users as UsersIcon,
} from 'common/icons'
import { showAddUserModal } from 'features/admin/users/store/usersSlice'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import { showTemplates } from 'features/futurama/store/contentSlice'

import QuickCard from './QuickCard'

const QuickCards = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { accountId, permissions, accountCategory } = useAppSelector(
    (state) => state.authStates
  )

  const [showNewUserMassage, setShowNewUserMassage] = useState(false)
  const hasNotAccess = !canAccessAccount(
    accountCategory,
    routesPermissions.experimentsView
  )
  const handleOnClick = (path: string) => {
    if (hasNotAccess) {
      return () => {
        window.open('https://phrasee.co/pricing/')
      }
    } else {
      return () => {
        history.push(generatePath(path, { accountId }))
        dispatch(clickCreateCampaign())
      }
    }
  }

  return (
    <>
      <div className="flex gap-6 w-full  mt-12 lg:mt-0">
        <QuickCard
          icon={<ReactionIcon isDefaultColor={false} size={8} />}
          text="Create an experiment"
          hasNotAccess={hasNotAccess}
          onClick={handleOnClick('/campaigns')}
        />
        <QuickCard
          icon={<ReportsIcon isDefaultColor={false} size={8} />}
          text="Create a report"
          hasNotAccess={hasNotAccess}
          onClick={handleOnClick('/reports/performance')}
        />
        <QuickCard
          icon={<AiContentIcon isDefaultColor={false} size={8} />}
          text="Draft content"
          onClick={() => {
            history.push(generatePath('/content'))
            dispatch(showTemplates())
          }}
        />
        <QuickCard
          icon={<UsersIcon isDefaultColor={false} size={8} />}
          text="Invite users"
          onClick={() => {
            if (getIsAuthorized(permissions, usersPermissions.create)) {
              history.push(generatePath('/admin/users'))
              dispatch(showAddUserModal())
            } else {
              setShowNewUserMassage(true)
            }
          }}
        />
      </div>
      <Modal
        data-cy="info-invite-users"
        visible={showNewUserMassage}
        closable={false}
        footer={null}
        hasMargin={true}
        centered
      >
        <div>
          <h1 className="text-2xl font-bold text-coolGray-800 mb-7 inline">
            Invite users
          </h1>
        </div>
        <div className="mt-4 mb-14 text-lg text-coolGray-500">
          Contact your team admin to invite a new user.
        </div>
        <div className="flex justify-end">
          <Button
            variant="primary"
            className="text-base"
            onClick={() => {
              setShowNewUserMassage(false)
            }}
            data-cy="limit-reached-cancel-button"
          >
            Ok
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default QuickCards
