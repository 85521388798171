/* eslint-disable max-lines */
import { useFlags } from 'launchdarkly-react-client-sdk'
import helpers from 'workflow/utils/helpers'

import Checkbox from 'common/components/checkbox'
import NumericInput from 'common/components/numericInput'
import { SelectValue, SingleValue } from 'common/components/singleSelect'
import { useAppSelector } from 'common/hooks/redux'

import {
  AdvancedSettingsActions,
  AdvancedSettingsState,
} from '../../advancedOptionsReducer'
import { BeSmartWhenToIntroduceVariants } from '../../types'

import { CheckboxWithInput } from './CheckboxWithInput'
import {
  advancedOptionsToolTips,
  beSmartWhenToIntroduceVariantsOptions,
  DEFAULT_DAYS_BEFORE_ADDING_LINES,
  DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_BROADCAST,
  DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_TRIGGER,
  DEFAULT_TEST_AUDIENCE_SIZE_TRIGGER,
  optionHeadToHeadAndWait,
  testId,
} from './constants'
import { labelText } from './constants'
import { highPerformers as highPerformersConst } from './constants'
import { PhraseeXSelector } from './PhraseeXSelector'

interface Props {
  advancedSettingsState: AdvancedSettingsState
  dispatchAdvancedSettings: (acton: AdvancedSettingsActions) => void
}

export const AdvancedOptions = ({
  advancedSettingsState,
  dispatchAdvancedSettings,
}: Props) => {
  const flags = useFlags()
  const {
    daysBeforeAddingLines,
    isAdvancedOptionsDisabled,
    isDropBadVariantsEnabled,
    isDropHumanControlEnabled,
    isMinSendsToHumanControlEnabled,
    minSendsToHumanControlInputValue,
    optimizationMode,
    isTestAudienceSizeEnabled,
    testAudienceSizeValue,
    beSmartWhenToIntroduceVariants,
    isDaysBeforeAddingLinesEnabled,
  } = advancedSettingsState

  const campaignData = useAppSelector(
    (state) => state.campaignStates?.campaignData
  )

  const isEngageCampaign = helpers.isEngageCampaign(campaignData)
  const isBroadcastCampaign =
    campaignData?.campaign_configuration?.distribution_type === 'broadcast'

  const createSelectionsForNonHighPerformers = () => {
    if (
      !flags.showDoAutomaticVariantIntroduction &&
      optimizationMode !== highPerformersConst.value &&
      !isEngageCampaign
    ) {
      return (
        <>
          <CheckboxWithInput
            tooltip={advancedOptionsToolTips.daysBeforeAddingLines}
            label={labelText.daysBeforeAddingLinesOld}
            checkboxDataTestId={testId.daysBeforeAddingLinesCheckbox}
            isChecked={isDaysBeforeAddingLinesEnabled}
            onCheckboxChange={() => {
              dispatchAdvancedSettings({
                type: 'set_is_days_before_adding_lines_enabled',
                value: !isDaysBeforeAddingLinesEnabled,
              })
            }}
            inputDataTestId={testId.daysBeforeAddingLinesInput}
            inputValue={Number(daysBeforeAddingLines)}
            onInputChange={(value) => {
              dispatchAdvancedSettings({
                type: 'set_days_before_adding_lines',
                value,
              })
            }}
            inputMetric="Days"
            minValue={1}
            maxValue={365}
            defaultValue={DEFAULT_DAYS_BEFORE_ADDING_LINES}
            isDisabled={isAdvancedOptionsDisabled}
          />
        </>
      )
    }
  }

  const displayAdvancedOptions = () => {
    return (
      <div className="mt-8">
        {!isEngageCampaign && (
          <>
            <Checkbox
              label={labelText.dropVariants}
              isDisabled={isAdvancedOptionsDisabled}
              data-testid={testId.dropVariantsCheckbox}
              isChecked={isDropBadVariantsEnabled}
              tooltip={{ label: advancedOptionsToolTips.dropVariants }}
              onChange={(value) => {
                dispatchAdvancedSettings({
                  type: 'set_is_drop_bad_variants_enabled',
                  value,
                })
              }}
              className="mt-6 flex"
            />
            {flags.showDoAutomaticVariantIntroduction && (
              <>
                {optimizationMode !== highPerformersConst.value && (
                  <PhraseeXSelector
                    label={labelText.beSmartWhenToIntroduceVariants}
                    className="mt-6 mb-6"
                    datatestId={testId.beSmartWhenToIntroduceVariantsSelector}
                    isDisabled={isAdvancedOptionsDisabled}
                    value={beSmartWhenToIntroduceVariants}
                    onChange={(singleValue: SingleValue<SelectValue>) =>
                      dispatchAdvancedSettings({
                        type: 'set_be_smart_when_to_introduce_variants_value',
                        value:
                          singleValue?.value as BeSmartWhenToIntroduceVariants,
                      })
                    }
                    options={beSmartWhenToIntroduceVariantsOptions}
                  />
                )}
                {beSmartWhenToIntroduceVariants ===
                  optionHeadToHeadAndWait.value &&
                  optimizationMode !== highPerformersConst.value && (
                    <div className="flex items-baseline flex-wrap">
                      <span className="ml-10 leading-normal font-normal text-coolGray-800">
                        Once a winning variant is found, wait
                      </span>
                      <div>
                        <NumericInput
                          precision={0}
                          className="ml-2 w-14"
                          data-testid={testId.daysBeforeAddingLinesInput}
                          value={Number(daysBeforeAddingLines)}
                          onChange={(value) => {
                            dispatchAdvancedSettings({
                              type: 'set_days_before_adding_lines',
                              value,
                            })
                          }}
                          disabled={isAdvancedOptionsDisabled}
                          min={1}
                          max={365}
                        />
                      </div>
                      <span className="ml-10 leading-normal font-normal text-coolGray-800">
                        days before introducing new variants
                      </span>
                    </div>
                  )}
              </>
            )}
            <Checkbox
              label={labelText.dropHumanControl}
              isDisabled={isAdvancedOptionsDisabled}
              data-testid={testId.dropHumanControlCheckbox}
              isChecked={isDropHumanControlEnabled}
              onChange={() =>
                dispatchAdvancedSettings({
                  type: 'set_is_drop_human_control_enabled',
                  value: !isDropHumanControlEnabled,
                })
              }
              className="mt-6 mb-6"
              disableAlignItemsCenter
              tooltip={{
                label: advancedOptionsToolTips.dropHumanControlCheckbox,
              }}
            />
          </>
        )}
        {createSelectionsForNonHighPerformers()}
        <CheckboxWithInput
          label={labelText.minSendsToHumanControl}
          tooltip={advancedOptionsToolTips.minSendsToHumanControl}
          checkboxDataTestId={testId.minSendsToHumanControlCheckbox}
          isChecked={isMinSendsToHumanControlEnabled}
          inputDataTestId={testId.minSendsToHumanControlInput}
          inputValue={Number(minSendsToHumanControlInputValue)}
          onCheckboxChange={() =>
            dispatchAdvancedSettings({
              type: 'set_is_min_sends_to_human_control_enabled',
              value: !isMinSendsToHumanControlEnabled,
              isBroadcastCampaign: isBroadcastCampaign,
            })
          }
          onInputChange={(value) => {
            dispatchAdvancedSettings({
              type: 'set_min_sends_to_human_control_value',
              value: value,
            })
          }}
          minValue={1}
          maxValue={50}
          postfix="%"
          defaultValue={
            isBroadcastCampaign
              ? DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_BROADCAST
              : DEFAULT_MIN_SENDS_TO_HUMAN_CONTROL_VALUE_TRIGGER
          }
          isDisabled={isAdvancedOptionsDisabled}
        />
        {!isEngageCampaign && (
          <CheckboxWithInput
            label={labelText.testAudienceSize}
            checkboxDataTestId={testId.testAudienceSizeCheckbox}
            isChecked={isTestAudienceSizeEnabled}
            inputDataTestId={testId.testAudienceSizeInput}
            inputValue={Number(testAudienceSizeValue)}
            onCheckboxChange={() =>
              dispatchAdvancedSettings({
                type: 'set_test_audience_size_enabled',
                value: !isTestAudienceSizeEnabled,
                isBroadcastCampaign: isBroadcastCampaign,
              })
            }
            onInputChange={(value) => {
              dispatchAdvancedSettings({
                type: 'set_test_audience_size_value',
                value: value,
              })
            }}
            minValue={1}
            maxValue={20}
            postfix="%"
            defaultValue={DEFAULT_TEST_AUDIENCE_SIZE_TRIGGER}
            isDisabled={isAdvancedOptionsDisabled}
          />
        )}
      </div>
    )
  }
  return displayAdvancedOptions()
}

export default AdvancedOptions
