import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import cx from 'classnames'

interface Props {
  to: string
  children: ReactNode
  'data-cy'?: string
  className?: string
}

const Link = ({ to, children, className, 'data-cy': dataCy }: Props) => {
  return (
    <RouterLink to={to} className={cx('text-sm', className)} data-cy={dataCy}>
      {children}
    </RouterLink>
  )
}

export default Link
