import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  hideRightPane,
  showRightPane,
} from 'features/futurama/store/contentSlice'

import { getContentHasVariants } from '../helpers'
import { useContentOrTemplate, useIsMultistep } from '../hooks'

import MultiStepPreview from './MultiStepPreview'

export type PageState = 'showPreview' | 'hidePreview'

const Preview = () => {
  const dispatch = useAppDispatch()

  const rightPaneVisibility = useAppSelector(
    (state) => state.content.rightPaneVisibility
  )

  const { isMultistep } = useIsMultistep()
  const { content } = useContentOrTemplate()

  const hasVariants = getContentHasVariants(content)
  const isCustom = content?.elements.length && content?.elements.length > 1

  const getState = (): PageState => {
    if ((isMultistep && hasVariants) || isCustom) {
      return 'showPreview'
    } else {
      return 'hidePreview'
    }
  }

  const state = getState()

  useEffect(() => {
    if (state === 'showPreview' && rightPaneVisibility === 'none') {
      dispatch(showRightPane('auto'))
    } else if (state !== 'showPreview' && rightPaneVisibility !== 'none') {
      dispatch(hideRightPane('none'))
    }
  }, [state, dispatch, rightPaneVisibility])

  return (
    <>
      {
        {
          showPreview: <MultiStepPreview />,
          hidePreview: <></>,
        }[state]
      }
    </>
  )
}

export default Preview
