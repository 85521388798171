import { useState } from 'react'

import Button from 'common/components/button/Button'
import SingleSelect from 'common/components/singleSelect'
import {
  ChevronLeft,
  ChevronLeftStart,
  ChevronRight,
  ChevronRightEnd,
} from 'common/icons'

const ROWS_PER_PAGE = ['5', '10', '20', '30', '40', '50']

function Pagination({
  pageSize,
  pageIndex,
  setPageSize,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  pageCount,
  canNextPage,
  totalRowCount,
}) {
  const [hasRowsPerPageChanged, setHasRowsPerPageChanged] = useState(false)
  const firstPageRow = pageIndex * pageSize + 1
  const lastPageRow = pageIndex * pageSize + pageSize

  if (totalRowCount <= pageSize && !hasRowsPerPageChanged) {
    return null
  }

  return (
    <div
      data-cy="table-pagination"
      data-testid="table-pagination"
      className="py-3 px-6 flex justify-end items-center text-coolGray-800"
    >
      <SingleSelect
        data-cy="rows-select"
        label="Rows per page"
        name=""
        placeholder={pageSize}
        options={ROWS_PER_PAGE.map((option) => {
          return { label: option, value: option }
        })}
        value={pageSize.toString()}
        onChange={(e) => {
          setHasRowsPerPageChanged(true)
          setPageSize(Number(e?.value))
        }}
        orientation="horizontal"
      />
      <span className="mr-6 ml-6">
        <span data-cy="first-page-row-number">
          {totalRowCount && firstPageRow}
        </span>
        -
        <span data-cy="last-page-row-number">
          {Math.min(lastPageRow, totalRowCount)}
        </span>{' '}
        of
        <span className="ml-1" data-cy="total-row-count">
          {totalRowCount}
        </span>
      </span>
      <Button
        data-cy="first-page-button"
        variant="icon"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        className="mr-2"
      >
        <ChevronLeftStart />
      </Button>
      <Button
        data-cy="previous-page-button"
        variant="icon"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        className="mr-2"
      >
        <ChevronLeft />
      </Button>
      <Button
        data-cy="next-page-button"
        variant="icon"
        aria-label="next"
        onClick={() => nextPage()}
        disabled={!canNextPage}
        className="mr-2"
      >
        <ChevronRight />
      </Button>
      <Button
        data-cy="last-page-button"
        variant="icon"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        className="mr-2"
      >
        <ChevronRightEnd />
      </Button>
    </div>
  )
}

export default Pagination
