import { useLayoutEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { selectElementAndFetchCampaignData } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getOptimizeElements } from '../../helpers'
import { useContent } from '../../hooks'

const useElementState = () => {
  const dispatch = useAppDispatch()
  const { content } = useContent()

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  const optimizeElements = getOptimizeElements(content?.elements)

  useLayoutEffect(() => {
    if (!selectedElementId) {
      const firstEnabledElement = optimizeElements.find(
        (element) => element.status !== 'disabled'
      )
      if (firstEnabledElement) {
        dispatch(selectElementAndFetchCampaignData(firstEnabledElement))
      }
    } else {
      const optimizedElement = optimizeElements.find(
        (element) => element.element_id === selectedElementId
      )

      if (optimizedElement) {
        dispatch(selectElementAndFetchCampaignData(optimizedElement))
      } else {
        const firstEnabledElement = optimizeElements.find(
          (element) => element.status !== 'disabled'
        )
        if (firstEnabledElement) {
          dispatch(selectElementAndFetchCampaignData(firstEnabledElement))
        }
      }
    }
    // This hook should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useElementState
