import { unifiedFlowPaths } from 'app/navigation/paths'
import cx from 'classnames'

import SubNavBar from 'common/components/navigation/subNavBar'
import { useAppSelector } from 'common/hooks/redux'

import useContent from '../../hooks/useContent'

import HeaderActions from './HeaderActions'
import HeaderNavigation from './HeaderNavigation'

const Header = () => {
  const { content } = useContent()

  const rightPanelVisibility = useAppSelector(
    (state) => state.unifiedFlow.rightPanelVisibility
  )
  const workflowName = content?.display_name

  return (
    <>
      <SubNavBar title={workflowName} backLink={unifiedFlowPaths.home}>
        <div className="flex items-center">
          <HeaderNavigation
            className={cx('hidden lg:flex', {
              'mr-8': rightPanelVisibility === 'none',
            })}
          />
          <HeaderActions />
        </div>
      </SubNavBar>
      <HeaderNavigation className="lg:hidden" />
    </>
  )
}

export default Header
