import cx from 'classnames'

type Props = {
  className?: string
}

const Card: React.FC<Props> = ({ className, children }) => {
  return (
    <div
      className={cx('bg-coolGray-50 p-4 border border-coolGray-300', className)}
    >
      {children}
    </div>
  )
}

export default Card
