/* eslint-disable max-lines */
import React, { Component } from 'react'
import { Form, InputNumber } from 'antd'
import cx from 'classnames'
import moment, { Moment } from 'moment'
import ControlInput from 'workflow/common/controlInput'

import Alert from 'common/components/alert'
import DatePickerAntd from 'common/components/DatePicker'
import Input from 'common/components/Input'
import Select from 'common/components/Select'
import { default as Option } from 'common/components/SelectOption'
import { isYesterdayOrBefore } from 'common/helpers/date'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'

import { IHumanControl, InputContentType, InputElement } from '../../interface'

import GuidingText from './GuidingText'

const FormItem = Form.Item
export const MIN_FLOAT_VALUE = 0
export const MAX_FLOAT_VALUE = 100
const ON_PASTE_WARNING =
  "Line breaks aren't allowed for this type of content. Please edit your copy."
const DOUBLE_PERCENTAGE_WARNING = (
  <>
    You've included %%, please make sure your AmpScript is formatted correctly.{' '}
    <a href="https://help.salesforce.com/s/articleView?id=sf.mc_es_available_personalization_strings.htm&type=5">
      You can find available tags here.
    </a>
  </>
)

interface PropsUiBuilder {
  elements?: InputElement[]
  distributionChannelType?: string
  humanControl: IHumanControl
  form: any
  valueRetriever(value: string, key: string, state: StateUiBuilder): void
  validator?: () => Promise<void>
  disabled: boolean
  inputsState: Record<string, unknown>
}

interface StateUiBuilder {
  shouldShowHumanControlWarning: boolean
  controlWarning: string
}

const initialState: StateUiBuilder = {
  shouldShowHumanControlWarning: false,
  controlWarning: '',
}

export default class UiBuilder extends Component<
  PropsUiBuilder,
  StateUiBuilder
> {
  constructor(props: PropsUiBuilder) {
    super(props)
    this.state = initialState
  }

  componentDidMount(): void {
    this.props.form.validateFields()
  }

  countNumberOfBreakLine = (text: any) => {
    return text?.match(/\n|↵/g)?.length || 0
  }

  handleOnPasteWarning = () => {
    this.setState((state: StateUiBuilder) => {
      return {
        ...state,
        shouldShowHumanControlWarning: true,
        controlWarning: `${ON_PASTE_WARNING}`,
      }
    })
  }

  handleOnChange = (
    value: string,
    originalValue: string,
    element: InputElement
  ) => {
    const { valueRetriever } = this.props
    const targetValue = stripForbiddenCharacters(originalValue)

    if (this.countNumberOfBreakLine(targetValue) === 0) {
      this.setState(
        {
          shouldShowHumanControlWarning: false,
          controlWarning: '',
        },
        () => valueRetriever(value, element.field_id, this.state)
      )
    } else {
      valueRetriever(value, element.field_id, this.state)
    }
  }

  chooseComponent(element: InputElement, index: number): any {
    const {
      humanControl,
      form: { getFieldError, isFieldTouched },
    } = this.props
    const fieldError =
      isFieldTouched(element.field_id) && getFieldError(element.field_id)
    if (
      element.field_id === 'own_subject_line' &&
      humanControl?.display &&
      humanControl?.is_optional
    ) {
      // eslint-disable-next-line no-unused-expressions
      element?.rules?.forEach(function (rule) {
        // eslint-disable-next-line no-param-reassign
        rule.required = false
      })
    }
    return (
      <FormItem
        key={index}
        label={element.title}
        validateStatus={fieldError ? 'error' : ''}
        help={fieldError || ''}
        data-test={element.field_id}
        className={cx(`${this.isRequired(element) ? 'required' : ''}`, {
          'inline-block':
            element.field_id === 'list_size' ||
            element.field_id === 'baseline_open_rate',
          ' w-1/2 mr-4': element.field_id === 'list_size',
          ' w-1/3': element.field_id === 'baseline_open_rate',
        })}
      >
        {this.chooseInput(element, index)}
      </FormItem>
    )
  }

  // eslint-disable-next-line class-methods-use-this
  isRequired(element: InputElement): boolean {
    if (element.rules) {
      return element.rules.some((rule) => {
        return rule.required
      })
    }
    return false
  }

  disabledDateTime = (current: Moment | null, allowPast: any) => {
    return allowPast || current === null ? null : isYesterdayOrBefore(current)
  }

  // This function receive an element object coming from back end and decide which input it should render.
  chooseInput(element: InputElement, index: number): JSX.Element {
    const { getFieldDecorator, getFieldValue } = this.props.form
    const { disabled, humanControl } = this.props
    const { rules } = element
    const { shouldShowHumanControlWarning, controlWarning } = this.state
    let returnedInput: JSX.Element

    switch (element.type) {
      case InputContentType.textArea:
        const minRows =
          element?.minMaxRows?.min && element?.minMaxRows?.min > 1
            ? element?.minMaxRows?.min
            : 1.334 // equals 38px

        returnedInput = (
          <>
            <div key={index}>
              {getFieldDecorator(element.property_name, {
                rules,
              })(
                <ControlInput
                  rules={rules}
                  data-cy="campaign-setup-campaign-own-sl"
                  id={element.field_id}
                  className="hover:border-maroon-300 focus:border-maroon-500 outline-none shadow-none"
                  autoSize={{ minRows }}
                  onLineBreaksWarning={(): void => this.handleOnPasteWarning()}
                  maxRows={element?.minMaxRows?.max}
                  placeholder={element.placeholder}
                  onChange={(value, originalValue) => {
                    this.handleOnChange(value, originalValue, element)
                  }}
                  disabled={disabled}
                />
              )}
              {element.tooltip && <GuidingText text={element.tooltip} />}
            </div>
            {shouldShowHumanControlWarning && (
              <Alert className="flex items-center" type="warn" showIcon>
                {controlWarning}
              </Alert>
            )}
            {humanControl?.integrationType === 'sfmcv2' &&
              getFieldValue('own_subject_line')?.includes('%%') && (
                <Alert className="flex item-center mt-4" type="warn" showIcon>
                  {DOUBLE_PERCENTAGE_WARNING}
                </Alert>
              )}
          </>
        )
        break
      case InputContentType.text:
        returnedInput = (
          <div key={index}>
            {getFieldDecorator(element.property_name, {
              rules,
            })(
              <Input
                data-cy="campaign-setup-campaign-name"
                id={element.field_id}
                type="text"
                className="text-base"
                variant="default"
                placeholder={element.placeholder}
                onChange={(event) =>
                  this.props.valueRetriever(
                    event.target.value,
                    element.field_id,
                    this.state
                  )
                }
                disabled={disabled}
              />
            )}
            {element.tooltip && <GuidingText text={element.tooltip} />}
          </div>
        )
        break
      case InputContentType.number:
        returnedInput = (
          <div key={index}>
            {getFieldDecorator(element.property_name, {
              rules,
            })(
              <InputNumber
                data-cy={element['data-cy'] ?? 'campaign-setup-list-size'}
                id={element.field_id}
                style={{ width: 135 }}
                min={element.min ?? 0}
                max={element.max}
                placeholder={element.placeholder}
                precision={0}
                decimalSeparator="."
                formatter={(value) =>
                  `${value}`
                    .replace(/[^\d]/g, '')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) =>
                  value?.replace(/[^\d]/g, '').replace(/\$\s?|(,*)/g, '') ?? ''
                }
                onChange={(value) => {
                  this.props.valueRetriever(
                    value !== undefined && value !== null
                      ? value.toString()
                      : '',
                    element.field_id,
                    this.state
                  )
                }}
                disabled={disabled}
              />
            )}
            {element.tooltip && <GuidingText text={element.tooltip} />}
          </div>
        )
        break
      case InputContentType.float:
        returnedInput = (
          <div key={index}>
            {getFieldDecorator(element.property_name, {
              rules,
            })(
              <InputNumber
                data-cy="campaign-setup-baseline-open-rate"
                id={element.field_id}
                style={{ width: 135 }}
                size="large"
                placeholder={element.placeholder}
                precision={2}
                min={element.min || MIN_FLOAT_VALUE}
                max={element.max || MAX_FLOAT_VALUE}
                step={element.step || 1}
                decimalSeparator="."
                onChange={(event) => {
                  if (
                    event &&
                    event >= MIN_FLOAT_VALUE &&
                    event <= MAX_FLOAT_VALUE
                  ) {
                    this.props.valueRetriever(
                      event ? event.toString() : '',
                      element.field_id,
                      this.state
                    )
                  }
                }}
                formatter={(value) => {
                  const newValue = String(value).replace(/[^\d.]/g, '')
                  return `${newValue}%`
                }}
                parser={(value) => value?.replace(/[^\d.]/g, '') ?? ''}
                disabled={disabled}
              />
            )}
            {element.tooltip && (
              <GuidingText text={element.tooltip} style={{ width: 303 }} />
            )}
          </div>
        )
        break
      case InputContentType.dropdown:
        returnedInput = (
          <div key={index}>
            {getFieldDecorator(element.property_name, {
              rules,
            })(
              <Select
                data-cy="campaign-setup-language-model"
                id={element.field_id}
                showSearch
                filterOption={(input: string, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={element.placeholder}
                size="large"
                onChange={async (event: any): Promise<void> => {
                  this.props.valueRetriever(
                    event ? event.toString() : '',
                    element.field_id,
                    this.state
                  )
                }}
                disabled={disabled}
              >
                {element.elements &&
                  element.elements.map(
                    (grammar: { id: string; name: string }) => {
                      return (
                        <Option key={grammar.id} value={grammar.id}>
                          {grammar.name}
                        </Option>
                      )
                    }
                  )}
              </Select>
            )}
            {element.tooltip && (
              <GuidingText text={element.tooltip} style={{ width: 303 }} />
            )}
          </div>
        )
        break
      case InputContentType.date:
        returnedInput = (
          <div key={index}>
            {getFieldDecorator(element.property_name, {
              rules,
            })(
              <DatePickerAntd
                data-cy="campaign-setup-send-date"
                openDirection="up"
                disabledDate={(current) =>
                  this.disabledDateTime(current, element.allow_past) ?? false
                }
                value={
                  this.props.inputsState[element.field_id]
                    ? moment(
                        this.props.inputsState[element.field_id] as
                          | string
                          | undefined,
                        'YYYY-MM-DD'
                      )
                    : undefined
                }
                onChange={(currentDate) => {
                  const transformedDateString = currentDate
                    ? currentDate.format('YYYY-MM-DD')
                    : ''

                  this.props.valueRetriever(
                    transformedDateString,
                    element.field_id,
                    this.state
                  )
                }}
                disabled={disabled}
              />
            )}
            {element.tooltip && <GuidingText text={element.tooltip} />}
          </div>
        )
        break
      default:
        returnedInput = (
          <div key={index}>
            {getFieldDecorator(element.property_name, {
              rules,
            })(
              <Input
                type="text"
                id={element.field_id}
                variant="default"
                className="text-base"
                placeholder={element.placeholder}
                onChange={(event) =>
                  this.props.valueRetriever(
                    event.target.value,
                    element.field_id,
                    this.state
                  )
                }
                disabled={disabled}
              />
            )}
            {element.tooltip && <GuidingText text={element.tooltip} />}
          </div>
        )
        break
    }
    return returnedInput
  }

  // eslint-disable-next-line class-methods-use-this
  hasErrors(fieldsError: any): boolean {
    return Object.keys(fieldsError).some((field) => fieldsError[field])
  }

  render() {
    const { elements } = this.props
    return (
      <React.Fragment>
        {elements &&
          elements.length > 0 &&
          elements.map((element, index) => {
            return this.chooseComponent(element, index)
          })}
      </React.Fragment>
    )
  }
}
