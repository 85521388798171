import { Field } from 'react-final-form'
import { TestedContentSection } from '@phrasee/phrasee-typings/Graphql/interfaces'

import FormItem from 'common/components/FormItem'
import SingleSelect, {
  SelectValue,
  SingleValue,
} from 'common/components/singleSelect'

import useProjectOptions from './hooks/useProjectOptions'

const validateProject = (value: SelectValue) => {
  if (!value) {
    return 'Select your project.'
  }
}

type Props = {
  name: string
  isDisabled?: boolean
  'data-testid'?: string
  optimizationContentType?: TestedContentSection
  onChange?: (value: SingleValue<SelectValue>) => void
}

const Project = ({
  name,
  isDisabled,
  'data-testid': dataTestId,
  optimizationContentType,
  onChange,
}: Props) => {
  const projectOptions = useProjectOptions({ optimizationContentType })

  return (
    <Field<SelectValue> name={name} validate={validateProject}>
      {({ input, meta }) => (
        <FormItem
          label="Project"
          error={meta.touched ? meta.error : undefined}
          className="w-full col-span-2"
        >
          <SingleSelect
            isSearchable
            isDisabled={isDisabled}
            className="w-full"
            data-cy="edit-project-select"
            data-testid={dataTestId}
            id={input.name}
            name={input.name}
            placeholder="Select your project"
            value={input.value.value}
            onChange={(e) => {
              if (onChange) {
                onChange(e)
              }
              input.onChange(e)
            }}
            onBlur={input.onBlur}
            options={projectOptions}
            menuPortalTarget={document.body}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default Project
