import { ComponentProps, useEffect, useState } from 'react'
import { ActionMeta, SingleValue } from 'react-select'
import { Column, Row } from 'react-table'
import cx from 'classnames'

import SingleSelect, { SelectValue } from 'common/components/singleSelect'

import type { OnCellChange } from '../Table'

export type SingleSelectCellProps<T extends {}> = {
  value: string
  row: Row<T>
  column: Column<T>
  singleSelectProps: ComponentProps<typeof SingleSelect>
  className?: string
  onCellChange?: OnCellChange
  hasAutoFocus?: boolean
  onBlur?: () => void
}

const SingleSelectCell = <T extends {}>({
  value: initialValue,
  row: { index },
  column: { id },
  onCellChange, // This is a custom function that we supplied to our table instance
  singleSelectProps,
  className,
  hasAutoFocus,
  onBlur,
}: SingleSelectCellProps<T>) => {
  const [value, setValue] = useState<string | undefined>(initialValue)
  const [hasFocus, setHasFocus] = useState(false)

  const onChange: (
    value: SingleValue<SelectValue>,
    actionMeta: ActionMeta<SelectValue>
  ) => void = (value, actionMeta) => {
    setValue(value?.value)
    singleSelectProps?.onChange?.(value, actionMeta)
    if (onCellChange && value?.value) {
      if (!id) {
        throw new Error('The column id is missing')
      }
      onCellChange({ rowIndex: index, columnId: id, value: value.value })
    }
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const hasOverallFocus = hasFocus || hasAutoFocus

  return (
    <SingleSelect
      value={value}
      {...singleSelectProps}
      onChange={onChange}
      isInsideTable={true}
      className={cx(className, `w-full h-full child-cell pr-2`)}
      onFocus={() => setHasFocus(true)}
      onBlur={() => {
        setHasFocus(false)
        onBlur?.()
      }}
      autoFocus={hasOverallFocus}
      openMenuOnFocus
    />
  )
}

export default SingleSelectCell
