import { useEffect, useState } from 'react'
import { union } from 'lodash'

import { fetchUpliftReport } from 'common/api/reporting'
import { Column } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget'
import { InfoAction } from 'common/components/WidgetHeader'
import { useAppSelector } from 'common/hooks/redux'
import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'
import { getNonTestProjects } from 'features/campaigns/store/campaignSlice'
import { DateRange, Mode } from 'features/reports/store/insightsSlice'

import {
  fetchCampaigns,
  fetchCampaignsVariants,
  ResponseCampaign,
} from '../api'
import { WidgetProps } from '../interfaces'

import {
  CLICK_UPLIFT_COLUMN,
  COLUMNS,
  Data,
  OPEN_UPLIFT_COLUMN,
  PROJECT_COLUMN,
  SEND_DATE_COLUMN,
} from './Columns'

function WinningLines({
  accountId,
  projectIds,
  startDate,
  endDate,
  mode,
  dateRangeToCompare,
}: WidgetProps & { mode: Mode; dateRangeToCompare?: DateRange }) {
  const [data, setData] = useState<Data[]>()
  const [columns, setColumns] = useState<Column<Data>[]>()
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const projects = useAppSelector(getNonTestProjects)

  useEffect(() => {
    const fetchCampaignsAndUplift = () => {
      const promisesFetchCampaignFirstDateRange = projectIds.map((projectId) =>
        fetchCampaigns(projectId, startDate, endDate, accountId)
      )
      const promisesFetchCampaign =
        dateRangeToCompare?.startDate && dateRangeToCompare?.endDate
          ? projectIds
              .map((projectId) =>
                fetchCampaigns(
                  projectId,
                  dateRangeToCompare.startDate!,
                  dateRangeToCompare.endDate!,
                  accountId
                )
              )
              .concat(promisesFetchCampaignFirstDateRange)
          : promisesFetchCampaignFirstDateRange
      const fetchUpliftReportByDateRange = ({
        startDate,
        endDate,
      }: DateRange) => {
        return fetchUpliftReport({
          accountId,
          upliftType: [
            'email_opens',
            'email_clicks',
            'push_direct_opens',
            'push_influenced_opens',
            'sms_clicks',
            'inemail_clicks',
            'conversions',
            'revenue',
          ],
          projectIds: projectIds,
          testingMethod: ['split_test_partial', 'split_test_full'],
          startDate,
          endDate,
          periodType: 'aggregate',
          reportingLevel: 'campaign',
        })
      }
      const fetchAllUpliftReport = async () => {
        const results = await (dateRangeToCompare
          ? Promise.all([
              fetchUpliftReportByDateRange({ startDate, endDate }),
              fetchUpliftReportByDateRange(dateRangeToCompare),
            ])
          : Promise.all([fetchUpliftReportByDateRange({ startDate, endDate })]))
        const values = results.map((result) => result.data.data[0].values)
        return union(...values)
      }

      return Promise.all([
        Promise.all(promisesFetchCampaign),
        fetchAllUpliftReport(),
      ])
    }

    setData([])
    setIsLoading(true)
    setHasError(false)

    fetchCampaignsAndUplift()
      .then(([campaignResponses, uplift]) => {
        const campaigns = campaignResponses.reduce<ResponseCampaign[]>(
          (allCampaigns, { campaigns }) => allCampaigns.concat(campaigns),
          []
        )
        const upliftData = uplift.length ? uplift : []
        const campaignIds = campaigns.map(({ _id }) => _id)

        fetchCampaignsVariants({ campaignIds, winnersOnly: true })
          .then((variants) => {
            let hasOpenUplift = false
            let hasClickUplift = false

            const rows = variants.map(
              ({ campaign_id, text, sentiments, send_date }) => {
                const { name: campaignName, _id: campaignId } =
                  campaigns.find(({ _id }) => _id === campaign_id) ?? {}
                const campaign = campaigns.find(
                  ({ _id: id }) => id === campaign_id
                )
                const projectId = campaign?.project_id
                const project = projects.find(({ id }) => id === projectId)
                const campaignUplift = upliftData.find(
                  ({ name }) => name === campaignName
                )?.values

                const openUplift = campaignUplift?.find(({ label }) =>
                  label.toLowerCase().includes('opens')
                )?.value
                const clickUplift = campaignUplift?.find(({ label }) =>
                  label.toLowerCase().includes('clicks')
                )?.value

                if (openUplift !== undefined) {
                  hasOpenUplift = true
                }
                if (clickUplift !== undefined) {
                  hasClickUplift = true
                }

                const row: Data = {
                  winner: replaceNewLineEmojiAsElement(text),
                  winnerExport: text,
                  campaignName,
                  projectName: project?.name,
                  sendDate: send_date,
                  campaignId,
                  openUplift,
                  clickUplift,
                  impressed: sentiments.impressed?.score,
                  helpful: sentiments.helpful?.score,
                  curious: sentiments.curious?.score,
                  excited: sentiments.excited?.score,
                  surprising: sentiments.surprising?.score,
                  appreciative: sentiments.appreciative?.score,
                  urgent: sentiments.urgent?.score,
                }
                return row
              }
            )

            const updatedColumns = [...COLUMNS]
            if (mode === 'projectComparison') {
              updatedColumns.unshift(PROJECT_COLUMN)
            }
            if (mode === 'dateRangeComparison') {
              updatedColumns.unshift(SEND_DATE_COLUMN)
            }
            const colToSpliceStart = mode === 'basicReport' ? 2 : 3
            if (hasOpenUplift && hasClickUplift) {
              updatedColumns.splice(
                colToSpliceStart,
                0,
                OPEN_UPLIFT_COLUMN,
                CLICK_UPLIFT_COLUMN
              )
            } else if (hasOpenUplift) {
              updatedColumns.splice(colToSpliceStart, 0, OPEN_UPLIFT_COLUMN)
            } else if (hasClickUplift) {
              updatedColumns.splice(colToSpliceStart, 0, CLICK_UPLIFT_COLUMN)
            }

            setColumns(updatedColumns)
            setData(rows)
          })
          .catch(() => setHasError(true))
          .finally(() => setIsLoading(false))
      })
      .catch(() => {
        setHasError(true)
        setIsLoading(false)
      })
  }, [
    accountId,
    projectIds,
    startDate,
    endDate,
    projects,
    mode,
    dateRangeToCompare,
  ])

  return (
    <TableWidget.Widget
      isLoading={isLoading}
      hasError={hasError}
      columns={columns}
      data={data}
      fileName="winning_lines_report"
    >
      <TableWidget.Header title="Winning variants">
        <TableWidget.Menu hasCsvExport={true} />
        <InfoAction message="Take a look at all the winning variants for this time period and take a deeper dive into the sentiments behind them." />
      </TableWidget.Header>
    </TableWidget.Widget>
  )
}

export default WinningLines
