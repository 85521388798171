import { useCallback } from 'react'

import { connectInternalApi } from 'common/api'
import type { IncrementalEventsData } from 'common/components/IncrementalEventsGraph'
import BaseIncrementalEventsWidget from 'common/components/IncrementalEventsWidget'
import { useAppSelector } from 'common/hooks/redux'
import { WidgetGraphConfig } from 'common/interfaces/widgetListInterfaces'

import useFetchGraphData from './hooks/useFetchGraphData'
import { GraphDataResponse } from './interfaces'

type Props = {
  configuration: WidgetGraphConfig
  name: string
  tooltip?: string
}

const IncrementalEventsWidget = ({
  configuration,
  name,
  tooltip = '',
}: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { projects, campaigns, startDate, endDate } = useAppSelector(
    (state) => state.reports
  )

  const fetchData = useCallback(() => {
    const hasProjectIds = projects.length > 0
    const hasCampaignIds = campaigns.length > 0
    const params = {
      event_type: configuration.event_types,
      by_level: configuration.by_level,
      start_date: startDate,
      end_date: endDate,
      reporting_level: configuration.reporting_level,
      testing_method: configuration.testing_method.filter(
        (m) => m !== 'bandit' // TODO: Remove filtering when this is fixed in the BE.
      ),
      project_ids:
        hasProjectIds && !hasCampaignIds ? projects.join(',') : undefined,
      campaign_ids: hasCampaignIds ? campaigns.join(',') : undefined,
    }

    return connectInternalApi.get<GraphDataResponse>(
      `v1/core/reporting/reporting/accounts/${accountId}/incremental/report/graph-data`,
      { params }
    )
  }, [accountId, endDate, projects, campaigns, startDate, configuration])

  const {
    hasError,
    data: incrementalEventsData,
    isLoading,
    xAxisLabels,
  } = useFetchGraphData<IncrementalEventsData>(fetchData)

  return (
    <BaseIncrementalEventsWidget
      hasError={hasError}
      title={name}
      info={tooltip}
      isLoading={isLoading}
      data={incrementalEventsData}
      xAxisLabels={xAxisLabels}
    />
  )
}

export default IncrementalEventsWidget
