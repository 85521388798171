import { useEffect, useMemo, useRef, useState } from 'react'
import {
  ActionMeta,
  components,
  ContainerProps,
  GroupBase,
  LoadingIndicatorProps,
  MenuProps,
  MultiValue,
  MultiValueProps,
  OptionProps,
  OptionsOrGroups,
} from 'react-select'
import cx from 'classnames'
import { isEqual } from 'lodash'

import { Checkbox } from 'common/icons'

import BaseSelect, { Orientation, SelectRef, SelectValue } from '../BaseSelect'

import DropdownFooter from './Footer'

export type MultiSelectValue = SelectValue & {
  id: string
  'data-cy'?: string
}

const SELECT_ALL_OPTION: MultiSelectValue = {
  id: 'selectAll',
  value: 'selectAll',
  label: 'Select all',
}
interface Props<T> {
  items: OptionsOrGroups<T, GroupBase<T>>
  selectedItems?: string[]
  loadMore?: () => void
  isLoading?: boolean
  isDisabled?: boolean
  name: string
  id?: string
  label?: string
  isLabelOptional?: boolean
  placeholder: string
  onApply: (val: string[]) => void
  minOptions?: number
  maxOptions?: number
  onSearch?: (val: string) => void
  orientation?: Orientation
  className?: string
  menuPortalTarget?: HTMLElement
  isSelectAllEnabled?: boolean
  isFooterHidden?: boolean
  formatGroupLabel?: (data: GroupBase<T>) => JSX.Element
  'data-cy'?: string
  'data-testid'?: string
}
const Menu = <T,>(props: MenuProps<T, true, GroupBase<T>>) => {
  // @ts-ignore
  const { footer } = props.selectProps

  return (
    <components.Menu
      {...props}
      innerProps={{ ...props.innerProps, 'data-cy': 'select-menu' } as any}
    >
      {props.children}
      {footer}
    </components.Menu>
  )
}

const SelectContainer = <T,>(props: ContainerProps<T, true, GroupBase<T>>) => {
  const dataCy = props.selectProps['data-cy']
  const dataTestId = props.selectProps['data-testid']

  return (
    <components.SelectContainer
      {...props}
      innerProps={
        {
          ...props.innerProps,
          'data-cy': dataCy,
          'data-testid': dataTestId,
        } as any
      }
    />
  )
}

// ValueContainer would be easier to implement but it disables
// closing menu on outside click
const MultiValueComponent = <T,>(
  props: MultiValueProps<T, true, GroupBase<T>>
) => {
  // @ts-ignore
  // fixes rendering multiple 'x items selected'
  if (props.index > 0) {
    return null
  }
  // @ts-ignore
  const { name, isFocused } = props.selectProps
  const { length: count } = props.getValue()

  if (isFocused) {
    return null
  } else if (count === 1) {
    return <div className="line-clamp-1">{props.children}</div>
  } else {
    return <div>{`${count} ${name} selected`}</div>
  }
}

// TODO: move component to BaseSelect
export const Option = <T,>(props: OptionProps<T, true, GroupBase<T>>) => {
  const { isSelected } = props

  return (
    <components.Option
      {...props}
      innerProps={{ ...props.innerProps, 'data-cy': 'select-option' } as any}
    >
      <div
        className={cx('px-4 py-2 flex hover:bg-gray-100 cursor-pointer', {
          'text-coolGray-800': !isSelected,
          'text-maroon-500': isSelected,
        })}
        aria-checked={isSelected}
        role="checkbox"
        aria-label={props.label}
      >
        <div className="w-6 h-6">
          <Checkbox
            state={isSelected ? 'selected' : 'default'}
            className={cx('w-6 h-6 mr-4', {
              'text-selected-icon': isSelected,
              'text-gray-200': !isSelected,
            })}
          />
        </div>
        <div data-cy="multiselect-option" data-testid="option" className="ml-4">
          {props.children}
        </div>
      </div>
    </components.Option>
  )
}

export const LoadingIndicator = <T,>(
  props: LoadingIndicatorProps<T, true, GroupBase<T>>
) => (
  <components.LoadingIndicator
    {...props}
    innerProps={{
      ...props.innerProps,
      // @ts-ignore
      'data-testid': 'loader',
    }}
  />
)
function isGroup<T extends MultiSelectValue>(
  object: T | GroupBase<T>
): object is GroupBase<T> {
  return 'options' in object && Array.isArray(object.options)
}

function isOption<T extends MultiSelectValue>(
  object: T | GroupBase<T>
): object is T {
  return 'id' in object
}

function MultiSelectPrivate<T extends MultiSelectValue>({
  items,
  selectedItems: appliedSelection = [],
  id,
  name,
  loadMore,
  onApply,
  onSearch,
  minOptions,
  maxOptions,
  menuPortalTarget,
  isSelectAllEnabled,
  isFooterHidden,
  formatGroupLabel,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  ...rest
}: Readonly<Props<T>>) {
  const [selectedItems, setSelectedItems] = useState<string[]>(appliedSelection)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const ref = useRef<SelectRef<T, true, GroupBase<T>> | null>(null)

  const selectedItemsWithoutSelectAll = selectedItems.filter(
    (item) => item !== SELECT_ALL_OPTION.id
  )
  useEffect(() => {
    onSearch && onSearch(searchValue)
  }, [searchValue, onSearch])

  const isUnderMaxOptionsLimit = maxOptions
    ? selectedItems.length <= maxOptions
    : true
  const isApplyDisabled =
    !isUnderMaxOptionsLimit ||
    (minOptions && selectedItems.length && selectedItems.length < minOptions) ||
    isEqual(appliedSelection, selectedItems)

  const sortedOptions: T[] = useMemo(() => {
    const selectedOptions: T[] = []
    const nonSelectedOptions: T[] = []

    if (items.every(isOption)) {
      items.forEach((item) =>
        appliedSelection.includes(item.id)
          ? selectedOptions.push(item)
          : nonSelectedOptions.push(item)
      )
    }
    if (items.every(isGroup)) {
      items.forEach((group) => {
        group.options.forEach((option) =>
          appliedSelection.includes(option.id)
            ? selectedOptions.push(option)
            : nonSelectedOptions.push(option)
        )
      })
    }

    return [
      ...(isSelectAllEnabled ? [SELECT_ALL_OPTION as T] : []),
      ...selectedOptions,
      ...nonSelectedOptions,
    ]
  }, [appliedSelection, isSelectAllEnabled, items])

  const onChange = (values: MultiValue<T>, actionMeta: ActionMeta<T>) => {
    if (actionMeta.action === 'deselect-option') {
      if (actionMeta.option?.id === SELECT_ALL_OPTION.id) {
        setSelectedItems([])
        return
      } else {
        values = values.filter((value) => value.id !== SELECT_ALL_OPTION.id)
      }
    }
    const selectedIds = values.some(
      (value) => value.id === SELECT_ALL_OPTION.id
    )
      ? items.every(isOption)
        ? [SELECT_ALL_OPTION.id, ...items.map(({ id }) => id)]
        : []
      : values.map(({ id }) => id)

    // if a selected campaign is not displayed after search, it should stay in selectedItems array
    // fixes clearing previously selected campaigns when selecting an option after BE search
    const hasNoOptions = sortedOptions.length === 0
    const selectedFilteredOutOptionsIds =
      sortedOptions.every(isOption) || hasNoOptions
        ? selectedItems.filter(
            (selectedId) => !sortedOptions.find(({ id }) => selectedId === id)
          )
        : selectedItems

    const newSelectedIds = [...selectedIds, ...selectedFilteredOutOptionsIds]
    setSelectedItems(newSelectedIds)
  }

  const hasNoOptions = sortedOptions.length === 0
  return (
    <BaseSelect<T, true>
      id={id}
      ref={ref}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false)
        if (isFooterHidden) {
          onApply(selectedItemsWithoutSelectAll)
        }
      }}
      isMulti
      menuPortalTarget={menuPortalTarget}
      closeMenuOnSelect={false}
      loadingMessage={() => 'Loading...'}
      value={
        sortedOptions.every(isOption) || hasNoOptions
          ? sortedOptions.filter(({ id }) => selectedItems.includes(id))
          : sortedOptions
      }
      options={
        sortedOptions.some(isGroup)
          ? sortedOptions.filter(isGroup)
          : sortedOptions
      }
      onChange={onChange}
      hideSelectedOptions={false}
      onMenuScrollToBottom={loadMore}
      backspaceRemovesValue={false}
      inputValue={searchValue}
      formatGroupLabel={formatGroupLabel}
      onInputChange={(newValue, actionMeta) => {
        // don't clear search when selecting
        if (actionMeta.action === 'input-change') {
          setSearchValue(newValue)
        } else if (actionMeta.action === 'menu-close') {
          setSearchValue('')
        }
      }}
      // passing footer to Menu directly using an arrow function causes
      // rerendering which results in scroll to top on every option select
      /*
        // @ts-ignore */
      footer={
        !isFooterHidden && (
          <DropdownFooter
            count={selectedItems.length}
            clear={() => {
              setSelectedItems([])
              onApply([])
            }}
            name={name}
            onApply={() => {
              onApply(selectedItemsWithoutSelectAll)
              ref.current?.blur()
            }}
            isApplyDisabled={isApplyDisabled}
            errorMessage={
              isUnderMaxOptionsLimit
                ? undefined
                : `Selection is limited to ${maxOptions} options`
            }
          />
        )
      }
      name={name}
      data-cy={dataCy}
      data-testid={dataTestId}
      isFocused={isFocused}
      components={{
        // Note that the reference of the components should not change
        Menu,
        Option,
        MultiValue: MultiValueComponent,
        LoadingIndicator,
        SelectContainer,
      }}
      {...rest}
    />
  )
}

// based on https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
const MultiSelect = <T extends MultiSelectValue>(props: Props<T>) => (
  <MultiSelectPrivate key={props.selectedItems?.join('-')} {...props} />
)

export default MultiSelect
