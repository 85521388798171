import cx from 'classnames'

import { mapContentTypeToIcon } from 'common/components/ContentTypeIcon'

const CardIcon = ({
  icon,
  className,
  size,
}: {
  icon: string
  className?: string
  size?: number
}) => {
  const props = {
    height: 20,
    className: cx('text-coolGray-400', className),
    size,
  }

  const Icon = mapContentTypeToIcon[icon]
  return Icon ? <Icon isDefaultColor={false} {...props} /> : null
}

export default CardIcon
