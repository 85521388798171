import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button'
import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { useAppDispatch } from 'common/hooks/redux'
import { Add } from 'common/icons'
import { showTemplates } from 'features/futurama/store/contentSlice'

type Props = {
  isDisabled?: boolean
}

const AddWorkflowCardButton = ({ isDisabled }: Props) => {
  const flags = useFlags()
  const dispatch = useAppDispatch()

  return (
    <div className="self-center">
      {flags.crossChannelWorkflow ? (
        <Button
          data-cy="add-more-content-button"
          ghost
          disabled={isDisabled}
          prefixIcon={
            <Add isDefaultColor={false} className="text-black w-4 h-4" />
          }
          onClick={() => dispatch(showTemplates())}
        >
          Content
        </Button>
      ) : (
        <ButtonWithTooltip
          tooltip={true}
          tooltipText="Coming soon: customizable workflows."
          className="w-9"
          placement="bottom"
          ghost
          prefixIcon={
            <Add isDefaultColor={false} className="text-black w-4 h-4" />
          }
          disabled
        />
      )}
    </div>
  )
}

export default AddWorkflowCardButton
