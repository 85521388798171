import { AnyAction } from '@reduxjs/toolkit'

import { SFMC_LOGIN_SUCCESS } from '../../../redux/actionTypes'

const initialState = {
  email: '',
  sfmcUserId: '',
  sfmcUserCollectionId: '',
}

export default function sfmcLoginReducer(
  state = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case SFMC_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        timezone: action.response.sfmc_user.timezone,
        business_unit: action.response.sfmc_user.business_unit,
        email: action.response.sfmc_user.email,
        zoneinfo: action.response.sfmc_user.zoneinfo,
        sfmcUserId: action.response.sfmc_user.sfmc_user_id,
        sfmcUserCollectionId: action.response.sfmc_user_session_id,
        stack: action.response.sfmc_user.stack,
      }
    default:
      return state
  }
}
