import { useEffect, useMemo, useState } from 'react'
import { DistributionChannel } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { Column, Row } from 'common/components/table'
import {
  AveragePagePercentageCell,
  NumericCell,
  PercentageCell,
  TotalPageValueCell,
} from 'common/components/table/cells'
import BaseCell from 'common/components/table/cells/Base'
import {
  getAverageColumnValue,
  getCellValue,
  getColumnSum,
} from 'common/components/table/helpers'
import TableWidget from 'common/components/widget/tableWidget'
import { InfoAction } from 'common/components/WidgetHeader'
import { useAppSelector } from 'common/hooks/redux'
import Link from 'common/typography/Link'

import {
  CampaignSummaryResponse,
  EmailCampaignSummary,
  fetchCampaignSummaryReport,
  PushCampaignSummary,
  SmsCampaignSummary,
} from './api'

export const columnNames = {
  status: 'Status',
  openUplift: 'Open uplift',
  clickUplift: 'Click uplift',
  currentOpenUplift: 'Current open uplift',
  totalOpenUplift: 'Total open uplift',
  currentClickUplift: 'Current click uplift',
  inbodyClickUplift: 'In-body click uplift',
  incrementalOpens: 'Incremental opens',
  incrementalClicks: 'Incremental clicks',
  incrementalTotalOpens: 'Incremental total opens',
  directOpenUplift: 'Direct open uplift',
  influencedOpenUplift: 'Influenced open uplift',
  incrementalDirectOpens: 'Incremental direct opens',
  incrementalInfluencedOpens: 'Incremental influenced opens',
}

type TableItem = EmailCampaignSummary & PushCampaignSummary & SmsCampaignSummary

const channelToField: Partial<
  Record<DistributionChannel, keyof CampaignSummaryResponse['data']>
> = {
  email: 'email_campaigns',
  push_notification: 'push_campaigns',
  sms: 'sms_campaigns',
}

const getUpliftCellExportValue = (
  row: Row<TableItem>,
  column: Column<TableItem>
) => {
  const value = getCellValue<TableItem>(row, column)
  return value === undefined ? '' : `${value}%`
}

const CampaignsWidget = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [tableData, setTableData] = useState<TableItem[]>()

  const { channel, startDate, endDate, projects, campaigns, product } =
    useAppSelector((state) => state.reports)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  useEffect(() => {
    ;(async () => {
      if (channel) {
        try {
          setHasError(false)
          setIsLoading(true)

          const result = await fetchCampaignSummaryReport({
            accountId,
            distributionType: product,
            campaignIds: campaigns,
            projectIds: projects,
            reportingLevel: 'campaign',
            startDate,
            endDate,
          })

          const tableDataResults = result.data.data
          const selector = channelToField[channel.id]

          if (selector) {
            setTableData(tableDataResults[selector])
          }
        } catch (e) {
          setHasError(true)
        }
        setIsLoading(false)
      }
    })()
  }, [channel, startDate, endDate, accountId, campaigns, projects, product])

  const columns: Column<TableItem>[] = useMemo(() => {
    const isReact = product === 'AlwaysOn'
    let columnsArray: Column<TableItem>[] = [
      {
        Header: 'Experiment name',
        accessor: 'name',
        minWidth: 200,
        isResizable: true,
        Cell: (props) => {
          const { id, name } = props.row.original
          return (
            <BaseCell>
              <Link to={`/campaigns/${id}`}>{name}</Link>
            </BaseCell>
          )
        },
        Footer: ({ page }) => {
          return (
            <BaseCell>
              <span>
                {page.length > 1
                  ? `Avg. Totals for ${page.length} experiments`
                  : `Avg. Totals for ${page.length} experiment`}
              </span>
            </BaseCell>
          )
        },
        footerExportValue: `Avg. Totals for ${tableData?.length} campaigns`,
      },
    ]

    if (isReact) {
      columnsArray = [
        ...columnsArray,
        {
          Header: columnNames.status,
          accessor: 'status',
          width: 60,
        } as Column<TableItem>,
      ]
    }

    if (channel?.id === 'email') {
      const hasInbodyClickUplift = tableData?.some(
        (row) => row.inbody_click_uplift
      )
      let emailHeaders: Column<TableItem>[] = [
        {
          Header: isReact
            ? columnNames.currentOpenUplift
            : columnNames.openUplift,
          accessor: 'open_uplift',
          align: 'right',
          Cell: PercentageCell,
          Footer: AveragePagePercentageCell,
          getCellExportValue: getUpliftCellExportValue,
          footerExportValue: `${getAverageColumnValue<TableItem>(
            tableData,
            'open_uplift'
          )}%`,
        },
        {
          Header: isReact
            ? columnNames.currentClickUplift
            : columnNames.clickUplift,
          accessor: 'click_uplift',
          align: 'right',
          Cell: PercentageCell,
          Footer: AveragePagePercentageCell,
          getCellExportValue: getUpliftCellExportValue,
          footerExportValue: `${getAverageColumnValue<TableItem>(
            tableData,
            'click_uplift'
          )}%`,
        },
      ]

      if (hasInbodyClickUplift) {
        emailHeaders = [
          ...emailHeaders,
          {
            Header: columnNames.inbodyClickUplift,
            accessor: 'inbody_click_uplift',
            align: 'right',
            Cell: PercentageCell,
            Footer: AveragePagePercentageCell,
            footerExportValue: `${getAverageColumnValue<TableItem>(
              tableData,
              'inbody_click_uplift'
            )}%`,
          },
        ]
      }
      emailHeaders = [
        ...emailHeaders,
        {
          Header: columnNames.incrementalOpens,
          accessor: 'incremental_opens',
          align: 'right',
          Cell: NumericCell,
          Footer: TotalPageValueCell,
          footerExportValue: getColumnSum<TableItem>(
            tableData,
            'incremental_opens'
          ),
        },
        {
          Header: columnNames.incrementalClicks,
          accessor: 'incremental_clicks',
          align: 'right',
          Cell: NumericCell,
          Footer: TotalPageValueCell,
          footerExportValue: getColumnSum<TableItem>(
            tableData,
            'incremental_clicks'
          ),
        },
      ]

      columnsArray = [...columnsArray, ...emailHeaders]
    }

    if (channel?.id === 'push_notification') {
      const pushHeaders: Column<TableItem>[] = [
        {
          Header: columnNames.directOpenUplift,
          accessor: 'direct_open_uplift',
          align: 'right',
          Cell: PercentageCell,
          Footer: AveragePagePercentageCell,
          footerExportValue: `${getAverageColumnValue<TableItem>(
            tableData,
            'direct_open_uplift'
          )}%`,
        },
        {
          Header: columnNames.influencedOpenUplift,
          accessor: 'influenced_open_uplift',
          align: 'right',
          Cell: PercentageCell,
          Footer: AveragePagePercentageCell,
          footerExportValue: `${getAverageColumnValue<TableItem>(
            tableData,
            'influenced_open_uplift'
          )}%`,
        },
        {
          Header: columnNames.totalOpenUplift,
          accessor: 'total_opens_uplift',
          align: 'right',
          Cell: PercentageCell,
          Footer: AveragePagePercentageCell,
          footerExportValue: `${getAverageColumnValue<TableItem>(
            tableData,
            'total_opens_uplift'
          )}%`,
        },
        {
          Header: columnNames.incrementalDirectOpens,
          accessor: 'incremental_direct_open',
          align: 'right',
          Cell: NumericCell,
          Footer: TotalPageValueCell,
          footerExportValue: getColumnSum<TableItem>(
            tableData,
            'incremental_direct_open'
          ),
        },
        {
          Header: columnNames.incrementalInfluencedOpens,
          accessor: 'incremental_influenced_opens',
          align: 'right',
          Cell: NumericCell,
          Footer: TotalPageValueCell,
          footerExportValue: getColumnSum<TableItem>(
            tableData,
            'incremental_influenced_opens'
          ),
        },
        {
          Header: columnNames.incrementalTotalOpens,
          accessor: 'incremental_total_opens',
          align: 'right',
          Cell: NumericCell,
          Footer: TotalPageValueCell,
          footerExportValue: `${getAverageColumnValue<TableItem>(
            tableData,
            'incremental_total_opens'
          )}%`,
        },
      ]
      columnsArray = [...columnsArray, ...pushHeaders]
    }

    if (channel?.id === 'sms') {
      const smsHeaders: Column<TableItem>[] = [
        {
          Header: columnNames.clickUplift,
          accessor: 'click_uplift',
          align: 'right',
          Cell: PercentageCell,
          Footer: AveragePagePercentageCell,
          footerExportValue: `${getAverageColumnValue<TableItem>(
            tableData,
            'click_uplift'
          )}%`,
        },
        {
          Header: columnNames.incrementalClicks,
          accessor: 'incremental_clicks',
          align: 'right',
          Cell: NumericCell,
          Footer: TotalPageValueCell,
          footerExportValue: getColumnSum<TableItem>(
            tableData,
            'incremental_clicks'
          ),
        },
      ]
      columnsArray = [...columnsArray, ...smsHeaders]
    }

    return columnsArray
  }, [channel, tableData, product])

  return (
    <TableWidget.Widget
      isLoading={isLoading}
      hasError={hasError}
      columns={columns}
      data={tableData}
      fileName="campaigns_performance_report"
    >
      <TableWidget.Header title="Experiments">
        <TableWidget.Menu hasCsvExport />
        <InfoAction message="A breakdown of all of the experiments and their performance metrics within the selected time period." />
      </TableWidget.Header>
    </TableWidget.Widget>
  )
}

export default CampaignsWidget
