import { connectInternalApi } from 'common/api'
import { AccountCategory } from 'features/auth/store/authSlice'

import { DashboardMode } from './Accounts'
import { Account } from './'

export interface AccountResponse {
  _id: string
  name: string
  users_count: number
  campaigns_count: number
  projects_count: number
  logo_url?: string
  deleted: boolean
  archived: boolean
  industry?: string
  account_category: AccountCategory
  dashboardMode: DashboardMode
  privacy_regions: PrivacyRegions
}

export type PrivacyRegion = 'US' | 'EU' | 'UK'

export type PrivacyRegions = PrivacyRegion[]

export const mapAccountResponseToAccount = (
  account: AccountResponse
): Account => ({
  id: account._id,
  companyName: account.name,
  usersCount: account.users_count,
  campaignsCount: account.campaigns_count,
  projectsCount: account.projects_count,
  logoUrl: account.logo_url,
  industry: account.industry,
  accountCategory: account.account_category,
  dashboardMode: account.dashboardMode,
  status: account.deleted
    ? 'deactivated'
    : account.archived
    ? 'archived'
    : 'active',
  privacyRegions: account.privacy_regions,
})

const mapAccountToAccountResponse = (
  account: Partial<Account>
): Partial<AccountResponse> => ({
  name: account.companyName,
  industry: account.industry,
  deleted: account.status === 'deactivated',
  archived: account.status === 'archived',
})

export const getAccountsList = async (userId: string): Promise<Account[]> => {
  const response = await connectInternalApi.get<AccountResponse[]>(
    `v1/core/monorail/users/${userId}/accounts/reports/admin-account-list/`
  )
  return response.data.map(mapAccountResponseToAccount)
}

const updateAccountStatus = async (
  accountId: string,
  data: Partial<Account>
) => {
  const response = await connectInternalApi.patch(
    `v1/core/monorail/accounts/${accountId}`,
    mapAccountToAccountResponse(data)
  )

  return mapAccountResponseToAccount(response.data)
}

const createOrUpdateAccountWithLogo = async ({
  accountId,
  accountName,
  industry,
  accountCategory,
  dashboardMode,
  logo,
  privacyRegions,
}: {
  accountId?: string
  accountName: string
  industry: string
  accountCategory: string
  dashboardMode: DashboardMode
  logo: File
  privacyRegions: PrivacyRegions
}) => {
  const formData = new FormData()
  formData.append('name', accountName)
  formData.append('industry', industry)
  formData.append('account_category', accountCategory)
  formData.append('dashboardMode', dashboardMode)
  formData.append('file', logo)
  formData.append('privacy_regions', JSON.stringify(privacyRegions))

  // TODO: Fix it, this will be overwritten by the interceptor
  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  const response = accountId
    ? await connectInternalApi.patch(
        `v1/core/monorail/accounts/${accountId}`,
        formData,
        {
          headers,
        }
      )
    : await connectInternalApi.post(`v1/core/monorail/accounts/`, formData, {
        headers,
      })

  return mapAccountResponseToAccount(response.data.data)
}

const createOrUpdateAccountWithoutLogo = async ({
  accountId,
  accountName,
  industry,
  accountCategory,
  dashboardMode,
  privacyRegions,
}: {
  accountId?: string
  accountName: string
  industry: string
  accountCategory: string
  dashboardMode: DashboardMode
  privacyRegions: PrivacyRegions
}) => {
  const data = {
    name: accountName,
    industry: industry,
    account_category: accountCategory,
    dashboardMode: dashboardMode,
    privacy_regions: privacyRegions,
  }

  const response = accountId
    ? await connectInternalApi.patch(
        `v1/core/monorail/accounts/${accountId}`,
        data
      )
    : await connectInternalApi.post(`v1/core/monorail/accounts/`, data)
  return mapAccountResponseToAccount(response.data.data)
}

const addUserToAccount = async ({
  accountId,
  userId,
}: {
  accountId?: string
  userId?: string
}) => {
  const response = await connectInternalApi.post(
    `v1/core/monorail/users/${userId}/accounts/${accountId}/`
  )
  return response.data
}

const removeUserFromAccount = async ({
  accountId,
  userId,
}: {
  accountId?: string
  userId?: string
}) => {
  await connectInternalApi.delete(
    `v1/core/monorail/users/${userId}/accounts/${accountId}/`
  )
}

const api = {
  getAccountsList,
  createOrUpdateAccountWithLogo,
  createOrUpdateAccountWithoutLogo,
  updateAccountStatus,
  addUserToAccount,
  removeUserFromAccount,
}

export default api
