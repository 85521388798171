import { Campaign } from '@phrasee/phrasee-typings/Graphql/interfaces'

export const shouldShowThePhraseeXIntegrationPanel = (
  campaignData: Campaign
) => {
  const { realtime_api_enabled, integration_options, testing_method } =
    campaignData?.campaign_configuration!
  return (
    !realtime_api_enabled &&
    integration_options?.type === 'sfmcv2' &&
    testing_method?.optimization_method?.selection_type === 'bandit'
  )
}
