import { StylesConfig } from 'react-select'
import { StatusMutator } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { SelectValue } from 'common/components/BaseSelect'

const colors = {
  live: {
    backgroundColor: '#FFEEF2',
    borderColor: '#7A004B',
    textColor: '#7A004B',
    dropdownIndicator: '#7A004B',
  },
  pending: {
    backgroundColor: '#fffbeb',
    borderColor: '#fbbf24',
    textColor: '#d97706',
    dropdownIndicator: '#fbbf24',
  },
  approved: {
    backgroundColor: '#f0fdf4',
    borderColor: '#22c55e',
    textColor: '#059669',
    dropdownIndicator: '#4ade80',
  },
  dropped: {
    backgroundColor: '#fafafa',
    borderColor: '#a1a1aa',
    textColor: '#57534e',
    dropdownIndicator: '#a1a1aa',
  },
}

export type VariantStatus = Exclude<StatusMutator, 'created' | 'rejected'>

export const selectCustomStyles = ({
  variantStatus,
}: {
  variantStatus: VariantStatus
}): StylesConfig<SelectValue, false> => {
  return {
    control: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        width: '86px',
        height: '28px',
        minHeight: '28px',
        border: `1px solid ${colors[variantStatus].borderColor}`,
        borderRadius: '0',
        backgroundColor: colors[variantStatus].backgroundColor,
      }
    },
    singleValue: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        color: colors[variantStatus].textColor,
        marginLeft: 0,
        padding: '0.25rem 0 0.25rem 0.5rem',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
      }
    },
    indicatorsContainer: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        padding: 0,
      }
    },
    dropdownIndicator: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        color: colors[variantStatus].dropdownIndicator,
        paddingRight: '0.15rem',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: '12px',
      }
    },
    container: (defaultStyles) => ({
      ...defaultStyles,
      width: '86px',
    }),
  }
}
