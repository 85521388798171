import { useAppSelector } from 'common/hooks/redux'

import useGetContentQuery from '../../api/queries/useGetContentQuery'

const useContent = () => {
  const activeContentId = useAppSelector(
    (state) => state.unifiedFlow.activeContentId
  )

  const contentQuery = useGetContentQuery(activeContentId)

  return {
    content: contentQuery.data,
    refetch: contentQuery.refetch,
  }
}

export default useContent
