import Checkbox from 'common/components/checkbox/Checkbox'
import Label from 'common/components/Label'
import Slider from 'common/components/slider/Slider'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  updateStylisticsOptions,
  updateStylisticsWrappingProbability,
} from '../store/styleGuideSlice'

import CapitalizationSettings from './CapitalizationSettings'
import StylisticsMessage from './StylisticsMessage'

type Props = {
  className?: string
}

const StylisticsWidget = ({ className }: Props) => {
  const dispatch = useAppDispatch()

  const { wrappingProbability, options: stylisticsOptions } = useAppSelector(
    (state) => state.styleGuide.editedContent.stylistics
  )

  const stylisticsOptionLabels = [
    { label: '** Don’t miss this ** Our biggest sale', value: '** **' },
    { label: '[[ Don’t miss this ]] Our biggest sale', value: '[[ ]]' },
    { label: '[ Don’t miss this ] Our biggest sale', value: '[ ]' },
    { label: '< Don’t miss this > Our biggest sale', value: '< >' },
  ]

  const isStylisticsOptionChecked = (value) => {
    return wrappingProbability > 0 && stylisticsOptions.includes(value)
  }

  return (
    <Widget
      data-cy="stylistics-widget"
      className={`grid grid-cols-1 sm:grid-cols-3 grid-flow-col gap-x-6 gap-y-4 ${className}`}
    >
      <WidgetHeader
        title="Stylistics"
        subtitle="Select which stylistic elements you want to use and how much you want Jacquard to use them when generating your copy."
        className="col-start-1 row-span-1 col-span-2"
      />
      <StylisticsMessage className="col-start-1 sm:col-start-3 row-span-2 col-span-2" />
      <div className="col-start-1 row-span-1 col-span-2">
        <Label className="mt-6">
          How often should Jacquard use these in generations?
        </Label>
        <div className="text-coolGray-400 sm:w-72 pt-2">
          <Slider
            minLabel="Never"
            maxLabel="All the time"
            min={0}
            max={100}
            value={wrappingProbability}
            defaultValue={30}
            onChange={(value) => {
              dispatch(updateStylisticsWrappingProbability(value as number))
            }}
          />
        </div>
        <Label className="mt-6">
          Select the stylistics you want us to use:
        </Label>
        <div className=" gap-y-6 mt-4" style={{ maxWidth: 646 }}>
          {stylisticsOptionLabels.map((labelInfo) => (
            <Checkbox
              className="mb-3"
              key={labelInfo.value}
              data-testid="straight-split-test"
              data-cy="straight-split-test-checkbox"
              label={labelInfo.label}
              isChecked={isStylisticsOptionChecked(labelInfo.value)}
              onChange={(isChecked: boolean) => {
                dispatch(
                  updateStylisticsOptions({ isChecked, value: labelInfo.value })
                )
              }}
              isDisabled={wrappingProbability === 0}
            />
          ))}
        </div>
        <CapitalizationSettings />
      </div>
    </Widget>
  )
}

export default StylisticsWidget
