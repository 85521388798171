import { useState } from 'react'

import IncrementalRevenueConfigurationModal from './IncrementalRevenueConfigurationModal'
import IncrementalRevenueWidgetContent from './IncrementalRevenueWidgetContent'

export type GraphData = {
  label: string
  value: number
}[]
interface Props {
  accountId: string
  localLang: string
  currency: string
  projectIds: string[]
  averageOrder: number | undefined
  openConversionPercentage: number | undefined
  clickConversionPercentage: number | undefined
  openValue: number | undefined
  clickValue: number | undefined
  updateConfig: (
    order: number | undefined,
    conversion: number | undefined
  ) => void
}

const IncrementalRevenueWidget = ({
  accountId,
  openConversionPercentage,
  clickConversionPercentage,
  openValue,
  clickValue,
  averageOrder,
  updateConfig,
  localLang,
  currency,
  projectIds,
}: Props) => {
  const [isConfigModalOpened, setIsConfigModalOpened] = useState<boolean>(false)

  return (
    <>
      <IncrementalRevenueWidgetContent
        accountId={accountId}
        projectIds={projectIds}
        openConversionPercentage={openConversionPercentage}
        clickConversionPercentage={clickConversionPercentage}
        openValue={openValue}
        clickValue={clickValue}
        averageOrder={averageOrder}
        setIsConfigModalOpened={setIsConfigModalOpened}
        localLang={localLang}
        currency={currency}
      />

      <IncrementalRevenueConfigurationModal
        isVisible={isConfigModalOpened}
        defaultAverageOrder={averageOrder}
        defaultOpenConversionPercentage={openConversionPercentage}
        onClose={() => setIsConfigModalOpened(false)}
        updateConfig={updateConfig}
        currency={currency}
      />
    </>
  )
}

export default IncrementalRevenueWidget
