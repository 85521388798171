import { TreeNode } from 'common/components/catalog/Catalog'
import { CustomerAttributes } from 'features/personalization/api'

const ROOT_PATH = 'data-sources'

export const keys = {
  customerAttributes: (accountId: string) => [
    ROOT_PATH,
    'customer-attributes',
    accountId,
  ],
  productCatalog: (accountId: string) => [
    ROOT_PATH,
    'product-catalog',
    accountId,
  ],
  categoryPerformance: ({
    accountId,
    customerAttributes,
    personalizationId,
    productCategories,
  }: {
    accountId: string
    personalizationId: string
    customerAttributes: CustomerAttributes | undefined
    productCategories: TreeNode | undefined
  }) => [
    ROOT_PATH,
    'category-performance',
    accountId,
    personalizationId,
    customerAttributes,
    productCategories,
  ],
  account: ({ accountId, userId }: { accountId: string; userId: string }) => [
    ROOT_PATH,
    'account-info',
    accountId,
    userId,
  ],
} as const
