import { useCallback, useState } from 'react'
import React from 'react'

import { ChartInstance } from 'common/components/AutoResizeChart'

export function useChartRefCallback() {
  const [chartState, setChartState] = useState<ChartInstance | null>(null)

  const chartRefCallback: React.RefCallback<ChartInstance> = useCallback(
    (node) => {
      if (node !== null) {
        setChartState(node)
      }
    },
    []
  )
  return { chart: chartState, chartRefCallback }
}
