import { Form } from 'react-final-form'

import AddedComponentList from '../addedTemplates/AddedComponentList'
import { useExperimentForm } from '../context/ExperimentFormContext'
import SetUpExperiment from '../setupExperiment/SetUpExperiment'
import SetupExperimentFooter from '../setupExperiment/SetupExperimentFooter'
import SlidingPanel from '../slidingPanel'
import TemplatesToAddBar from '../TemplatesToAddBar'
import WorkFlowName from '../WorkFlowName'

import TemplatesConfigPanelFooter from './TemplatesConfigPanelFooter'
import TemplatesConfigPanelHeader from './TemplatesConfigPanelHeader'
import TemplatesConfigPanelMain from './TemplatesConfigPanelMain'

export const setFormError = ([name, error], state) => {
  const { fields } = state
  const touched = !!error

  const field = fields[name]
  if (field) {
    field.data.error = error
    field.touched = touched
  }
}

const TemplatesConfigPanel = () => {
  const {
    state: { isExperimentFormVisible },
  } = useExperimentForm()

  return (
    <div className="flex w-full">
      <Form
        initialValues={{
          workflowName: undefined,
        }}
        mutators={{
          setError: setFormError,
        }}
        onSubmit={() => {}}
        render={({ handleSubmit }) => (
          <form
            id="workflow-form"
            data-testid="workflow-form"
            onSubmit={handleSubmit}
            className="flex border-l border-coolGray-300 overflow-hidden w-full"
          >
            <SlidingPanel
              isHiding={isExperimentFormVisible}
              className="flex-col w-full"
              isActive={!isExperimentFormVisible}
            >
              <TemplatesConfigPanelHeader>
                <WorkFlowName />
              </TemplatesConfigPanelHeader>
              <TemplatesConfigPanelMain className="bg-coolGray-100">
                <AddedComponentList />
              </TemplatesConfigPanelMain>
              <TemplatesConfigPanelFooter>
                <TemplatesToAddBar />
              </TemplatesConfigPanelFooter>
            </SlidingPanel>

            <SlidingPanel
              isHiding={isExperimentFormVisible}
              className="flex-col w-full"
              isActive={isExperimentFormVisible}
            >
              <TemplatesConfigPanelHeader>
                <div className="flex p-6 text-xl font-medium text-coolGray-800">
                  Set up your experiment
                </div>
              </TemplatesConfigPanelHeader>
              <TemplatesConfigPanelMain className="bg-white py-6 px-4 sm:px-6">
                <SetUpExperiment />
              </TemplatesConfigPanelMain>
              <TemplatesConfigPanelFooter>
                <SetupExperimentFooter />
              </TemplatesConfigPanelFooter>
            </SlidingPanel>
          </form>
        )}
      />
    </div>
  )
}

export default TemplatesConfigPanel
