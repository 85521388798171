function Header() {
  return (
    <div className="flex pl-6 pb-6">
      <h1 className="mt-2 text-2xl font-bold overflow-ellipsis max-w-xs overflow-hidden">
        Invite new user
      </h1>
    </div>
  )
}

export default Header
